import React from 'react';

import { Box, FormLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeleteIcon, PaperIcon } from '@app/common/icons';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { FileType } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { styles } from '@app/pages/exchange/components/form/style';
import { FormFieldName } from '@app/pages/exchange/components/form/types';

interface PurchaseAdditionalBlockProps {
  files: FileType[];
  onFileSelectClick(): void;
  onFileDeleteClick(file: FileType): void;
}

export const PurchaseAdditionalBlock: React.FC<PurchaseAdditionalBlockProps> = ({
  files,
  onFileSelectClick,
  onFileDeleteClick,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container item direction="column" wrap="nowrap">
      <Box mt={5} sx={styles.hr} />
      <Box pt={4} pb={4}>
        <Typography sx={styles.header}>{t('currencyPurchase_blockTitle')}</Typography>
      </Box>
      <Box pt={4} pb={2}>
        <FormLabel component="legend">{t('groundsForBuyingCurrency')}</FormLabel>
      </Box>
      <Box pt={2} pb={2}>
        <TextAreaField
          defaultValue={''}
          name={FormFieldName.currencyOperationReason}
          textFieldProps={{
            multiline: true,
            fullWidth: true,
            rows: 2,
          }}
          maxLength={420}
        />
      </Box>
      <Box pt={4} pb={4}>
        <FormLabel component="legend">{t('currencyPurchase_downloadBlockTitle')}</FormLabel>
      </Box>
      <Grid container direction={isMobile ? 'column' : 'row'}>
        <Grid
          container
          item
          xs={isMobile ? false : 8}
          direction="column"
          wrap="nowrap"
          alignItems="center"
          sx={styles.dropZoneContainer}
        >
          <Box pb={1}>
            <Typography variant="body2" component="span" color="textPrimary">
              {t('importPaymentsDialog_dragFileInfoText')}{' '}
            </Typography>
            <Typography
              variant="body2"
              component={isMobile ? 'div' : 'span'}
              sx={styles.selectFileFromDiskText}
              onClick={onFileSelectClick}
            >
              {t('importPaymentsDialog_selectFileFromDiskText')}
            </Typography>
          </Box>
          <Typography variant="caption" sx={styles.maxFileSize}>
            {t('maxFileSizeShortText')} 8 MB
          </Typography>
        </Grid>

        <Grid container item xs={isMobile ? false : 4} direction="column" wrap="nowrap">
          {files.length ? (
            <>
              {files.map((file, i) => {
                return (
                  <Box key={`${file.file.name}_${i}`} pt={2} pl={isMobile ? 0 : 4} width="100%">
                    <Grid item container wrap="nowrap">
                      <Grid item xs={8} container alignItems="center" wrap="nowrap">
                        <PaperIcon fontSize="small" sx={styles.icon} />
                        <Ellipsis
                          text={file.file.name}
                          styles={{
                            color: 'colors.gray500',
                            fontSize: '0.75rem',
                            paddingLeft: '8px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={4} alignItems="center" justifyContent="flex-end">
                        <Typography variant="caption" sx={styles.fileSize}>
                          {file.size} MB
                        </Typography>
                        <DeleteIcon fontSize="small" sx={styles.iconDelete} onClick={() => onFileDeleteClick(file)} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box pt={2} pl={isMobile ? 0 : 4} width="100%">
              <Grid container wrap="nowrap">
                <PaperIcon fontSize="small" sx={styles.icon} />
                <Typography variant="caption" sx={styles.noAttachedFilesLabel}>
                  {t('noAttachedFiles')}
                </Typography>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { authApiClient, getClientId, ApiError, FrontLanguageEnum, TYPE2FA } from '@app/core/api';

interface UserValidate2faFnProps {
  language: FrontLanguageEnum;
  code: string;
}

const validate2faFn = async ({ language, code }: UserValidate2faFnProps): Promise<any> => {
  const response = await authApiClient.authControllerEnable2faValidate(getClientId(), {
    params: { type2FA: TYPE2FA.Totp, twoFactorCode: code, language },
  });

  if (response.status !== 201) {
    throw new ApiError(response.request.status, 'Cant enable GA');
  }

  return response.data;
};

export const useValidate2fa = (): UseMutationResult<any, ApiError, UserValidate2faFnProps> => {
  return useMutation<any, ApiError, UserValidate2faFnProps>(validate2faFn);
};

import {
  getClientId,
  overdraftsApiClient,
  ApiError,
  GetOverdraftsListRequestDto,
  GetOverdraftsListResponseDto,
} from '@app/core/api';
import { TableColumnConfig } from '@app/core/types';
import { fieldsCreditTable } from '@app/pages/overdrafts/tableFields';

export const getOverdraftsFn = async (
  getOverdraftsListRequestDto: GetOverdraftsListRequestDto
): Promise<GetOverdraftsListResponseDto> => {
  const result = await overdraftsApiClient.overdraftControllerGetCountDocumentByStatus(
    getClientId(),
    getOverdraftsListRequestDto
  );
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error overdraftsControllerGetList!');
  }
  return result.data;
};

export const overdraftsControllerUpdateFn = async (
  overdraftsTableColumnsData: TableColumnConfig[]
): Promise<TableColumnConfig[]> => {
  return fieldsCreditTable;
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import {
  convertApiEnumToLocal,
  getClientId,
  loansTranchesApiApiClient,
  loansTranchesLoanLinesApiClient,
  ApiError,
  CollateralListResponseDto,
  GetOneLoanResponseDto,
  GetRepaymentTemplateRequestDto,
  GetUahRepaymentTemplateResponseDto,
  InternalScheduleSortingRuleDtoFieldEnum,
  PaginationRequestDto,
  SchedulesListResponseDto,
} from '@app/core/api';
import { DEFAULT_FIRST_PAGE } from '@app/core/constants';
import { DocumentStatus, TablePaginationType, Operation, OperationsTypeEnum } from '@app/core/types';

const getOneLoan = async (uuid: string): Promise<GetOneLoanResponseDto> => {
  const result = await loansTranchesApiApiClient.loansControllerGetOne(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get loan!');
  }
  return result.data;
};

export const useGetLoanByUuid = (uuid: string): UseQueryResult<GetOneLoanResponseDto, ApiError> => {
  return useQuery<GetOneLoanResponseDto, ApiError>('loan_getOneDocument', () => getOneLoan(uuid), {
    enabled: false,
  });
};

export type GetRecentOperationsResult = {
  operations: Array<Operation>;
  pagination: TablePaginationType;
};

export const getLoanOperationFn = async (
  uuid: string,
  pagination: PaginationRequestDto
): Promise<GetRecentOperationsResult> => {
  const result = await loansTranchesLoanLinesApiClient.loansControllerGetRecentOperations(getClientId(), {
    isLoanLine: false,
    uuid,
    pagination,
  });
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get loan operations!');
  }
  const operations: Array<Operation> = result.data.operations.map((operation) => {
    const { type, status } = operation;
    return {
      ...operation,
      type: convertApiEnumToLocal(OperationsTypeEnum, type),
      status: convertApiEnumToLocal(DocumentStatus, status),
    };
  });

  return {
    operations,
    pagination: result.data.pagination,
  };
};

export const getLoanUahRepaymentTemplateFn = async (
  getRepaymentTemplateRequestDto: GetRepaymentTemplateRequestDto
): Promise<GetUahRepaymentTemplateResponseDto> => {
  const result = await loansTranchesLoanLinesApiClient.loansControllerGetUahRepaymentTemplate(
    getClientId(),
    getRepaymentTemplateRequestDto
  );
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get loan UahRepaymentTemplate!');
  }
  return result.data;
};

export const getCollateral = async (
  uuid: string,
  pagination: PaginationRequestDto
): Promise<CollateralListResponseDto> => {
  const result = await loansTranchesApiApiClient.loansControllerGetCollateral(getClientId(), {
    pagination,
    uuid,
  });

  return result.data;
};

export const getScheduleOfPayments = async (uuid: string): Promise<SchedulesListResponseDto> => {
  const result = await loansTranchesApiApiClient.loansControllerGetSchedules(getClientId(), {
    loanId: uuid,
    sort: { desc: false, field: InternalScheduleSortingRuleDtoFieldEnum.PaymentDate },
    pagination: { page: DEFAULT_FIRST_PAGE, rowsPerPage: 300 },
  });

  return result.data;
};

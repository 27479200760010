import React from 'react';

import { Box, Button, Grid, IconButton, Typography, SxProps } from '@mui/material';
import { ModalProps } from '@mui/material/Modal/Modal';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';

import { styles } from './styles';
import { ModalCommon } from '../Modal';

export interface ConfirmPopupProps {
  open: boolean;
  questionText: string;
  icon?: React.ReactNode;
  modalProps?: ModalProps;
  classes?: {
    paper?: SxProps<Theme>;
    questionTextContainer?: SxProps<Theme>;
    questionText?: SxProps<Theme>;
  };
  children?: React.ReactNode;
  buttonsProps?: {
    confirmBtnText?: string;
    cancelBtnText?: string;
  };
  showCancelBtn?: boolean;
  showConfirmBtn?: boolean;
  onClose(): void;
  onConfirm(): void;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  open,
  questionText,
  icon,
  modalProps,
  showCancelBtn = true,
  showConfirmBtn = true,
  onClose,
  onConfirm,
  buttonsProps,
  classes,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <ModalCommon open={open} onClose={onClose} {...modalProps}>
      <Box
        sx={{
          ...styles.root,
        }}
      >
        <Box sx={styles.iconBg}>{icon}</Box>
        <Box pb={3} width="100%">
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
            <Box sx={{ ...styles.questionTextContainer, ...classes?.questionTextContainer }}>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  textAlign: 'center',
                  ...styles.questionText,
                  ...classes?.questionText,
                }}
              >
                {questionText}
              </Typography>
            </Box>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        {children}
        <Box mb={7} />
        <Grid container justifyContent="center">
          {showCancelBtn ? (
            <Box pr={6}>
              <Button variant="outlined" color="primary" onClick={onClose}>
                {buttonsProps?.cancelBtnText || t('no')}
              </Button>
            </Box>
          ) : null}
          {showConfirmBtn ? (
            <Button variant="contained" color="primary" onClick={onConfirm}>
              {buttonsProps?.confirmBtnText || t('yes')}
            </Button>
          ) : null}
        </Grid>
      </Box>
    </ModalCommon>
  );
};

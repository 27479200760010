import React from 'react';

import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

import { Select } from '@app/core/components/Select';
import { StatusText } from '@app/core/components/StatusText';
import { bankBranchList } from '@app/pages/exchangeRate/components/BankRates/components/BankBranchSelect/constants';
import { styles } from '@app/src/pages/exchangeRate/PageContent/styles';

interface BankBranchSelectProps {
  setBranch(branch: string | undefined): void;
  error: boolean;
}

export const BankBranchSelect: React.FC<BankBranchSelectProps> = ({ setBranch, error }) => {
  const onSelectBranch = (
    event: (Event & { target: { value: unknown; name: string } }) | React.ChangeEvent<HTMLInputElement>
  ) => {
    setBranch(typeof event.target.value === 'string' ? event.target.value : undefined);
  };

  const { t } = useTranslation();

  return (
    <>
      <Select
        name="bankBranch"
        error={error}
        variant="outlined"
        defaultValue={'1300'}
        onChange={onSelectBranch}
        sx={styles.bankBranchSelect}
        startAdornment={
          <Box pr={1}>
            <Typography variant="body2" color="textSecondary">
              {t('exchange_bankBranch')}:
            </Typography>
          </Box>
        }
      >
        {bankBranchList.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            <b>{item.details}</b>
          </MenuItem>
        ))}
      </Select>
      {error ? <StatusText>{t('bankBranchError')}</StatusText> : null}
    </>
  );
};

export const styles = {
  caption: {
    fontWeight: 700,
  },
  name: {
    color: 'colors.gray800',
  },
  signingAuthority: {
    color: 'colors.gray300',
  },
};

import React from 'react';

import { ListSubheader, styled, Typography, useTheme } from '@mui/material';
import { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete/Autocomplete';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { AccountStatusEnum } from '@app/core/api';

import { useStyles } from '../styles';

const Dashed = styled('span')({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backgroundImage: (theme: Theme) => `linear-gradient(to right, ${theme.palette.colors.gray100} 50%, transparent 50%)`,
  backgroundPosition: 'bottom',
  backgroundSize: '12px 1px',
  backgroundRepeat: 'repeat-x',
  width: '100%',
  height: '12px',
});

type GroupHeaderProps = {
  params: AutocompleteRenderGroupParams;
};

export const GroupHeader: React.FC<GroupHeaderProps> = ({ params }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      {params.group === AccountStatusEnum.Closed ? (
        <ListSubheader component="div" sx={classes.groupHeader} disableSticky>
          <Typography variant="caption" sx={classes.groupLabel}>
            {t('closed')}
          </Typography>
          <Dashed />
        </ListSubheader>
      ) : null}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, getClientId, swiftApiClient } from '@app/core/api';

const generateSwiftOrderNumberFn = async (): Promise<string> => {
  const result = await swiftApiClient.swiftControllerAutogenerateOrderNumber(getClientId());
  return result.data.generatedDocumentNumber;
};

export const useGenerateSwiftOrderNumber = (): UseMutationResult<string, ApiError, any> => {
  return useMutation<string, ApiError, any>(generateSwiftOrderNumberFn);
};

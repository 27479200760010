import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  CreateBudgetaryPaymentRequestDto,
  CreatePaymentOrderResponseDto,
  getClientId,
  paymentOrdersApiClient,
} from '@app/core/api';

const createStatePayment = async (
  paymentData: CreateBudgetaryPaymentRequestDto
): Promise<CreatePaymentOrderResponseDto> => {
  const result = await paymentOrdersApiClient.paymentOrderControllerCreateStatePayment(getClientId(), paymentData);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error create state payment!');
  }

  return result.data;
};

export const useCreateStatePayment = (): UseMutationResult<
  CreatePaymentOrderResponseDto,
  ApiError,
  CreateBudgetaryPaymentRequestDto
> => {
  return useMutation<CreatePaymentOrderResponseDto, ApiError, CreateBudgetaryPaymentRequestDto>(createStatePayment);
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { filesApiClient, ApiError, SendToEmailQueryDto } from '@app/core/api';

const sendToEmailQueryFn = async (data: SendToEmailQueryDto): Promise<object> => {
  const result = await filesApiClient.fileGenerationControllerSendPDFDocument(data);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error send email!');
  }
  return result.data;
};

export const useSendToEmail = (): UseMutationResult<object, ApiError, SendToEmailQueryDto> => {
  return useMutation<object, ApiError, SendToEmailQueryDto>(sendToEmailQueryFn);
};

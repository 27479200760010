import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { RowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';
import { TotalAmounts } from '../../../../../../types';
import { TableRowContent } from '../../../../../ComplexTable';
import { Loader } from '../../../../../Loader';
import { OperationDetails } from '../../../../../OperationDetails';
import { OperationRow } from '../../types';

const StatementsOperationDetails: React.FC<{ data: OperationRow }> = ({ data }) => {
  return <OperationDetails operation={data.operation} />;
};

interface StatementsBodyContentProps {
  loading?: boolean;
  operationsCount?: number;
  totalAmounts?: TotalAmounts;
  getRowModel(): RowModel<OperationRow>;
}

export const StatementsBodyContent: React.FC<StatementsBodyContentProps> = ({
  loading,
  totalAmounts,
  operationsCount,
  getRowModel,
}): JSX.Element => {
  const { t } = useTranslation();
  if (loading) {
    return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
  }
  const rows = getRowModel().rows;
  if (!rows.length) {
    if (totalAmounts && !operationsCount) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('no_operations_found')}</Typography>
        </Grid>
      );
    }
    return (
      <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
        <Typography variant="body2">{t('empty_table_status')}</Typography>
      </Grid>
    );
  }
  return (
    <>
      {rows.map((row, i) => {
        return (
          <TableRowContent<OperationRow>
            key={row.id}
            row={row}
            styles={styles}
            resizable
            ExpandedComponent={StatementsOperationDetails}
          />
        );
      })}
    </>
  );
};

import React, { useState } from 'react';

import {
  CurrencyOperationsSortingRuleDto,
  CurrencyOperationStatusEnum,
  GetCountDocumentByStatusDto,
  GetCurrencyOperationWithAvailableActionsDto,
  TotalAmountByCurrencyDto,
} from '@app/core/api';
import { TableColumnConfig, PaginationChangeType, TablePaginationType } from '@app/core/types';
import { CurrencyExchangeTablePreference } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/CurrencyExchangeTablePreference';
import { TableContent } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableContent';

export interface CurrencyExchangeTableProps {
  currencyOperations: GetCurrencyOperationWithAvailableActionsDto[];
  columnsConfig: TableColumnConfig[];
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  totalAmounts?: Array<TotalAmountByCurrencyDto>;
  countByStatus?: GetCountDocumentByStatusDto;
  onPaginationChange(pagination: PaginationChangeType): void;
  columnsConfigMutationFn(currencyTableColumnsData: TableColumnConfig[]): Promise<TableColumnConfig[]>;
  onSuccessConfigurationSaved(tableColumnsConfig: TableColumnConfig[]): void;
  onSort(rule: CurrencyOperationsSortingRuleDto): void;
  onDeleteDocumentSuccess(): void;
  onSignDocumentSuccess(): void;
  onSendToSignDocumentsSuccess(): void;
  onFilterChange(filter?: CurrencyOperationStatusEnum): void;
}

export const CurrencyExchangeTable: React.FC<CurrencyExchangeTableProps> = ({
  currencyOperations,
  pagination,
  loading,
  queryKey,
  columnsConfig,
  totalAmounts,
  countByStatus,
  onPaginationChange,
  columnsConfigMutationFn,
  onSuccessConfigurationSaved,
  onSort,
  onDeleteDocumentSuccess,
  onSignDocumentSuccess,
  onSendToSignDocumentsSuccess,
  onFilterChange,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShow = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <TableContent
        columnsConfig={columnsConfig}
        currencyOperations={currencyOperations}
        onSettingsButtonClick={handleShow}
        pagination={pagination}
        loading={loading}
        queryKey={queryKey}
        totalAmounts={totalAmounts}
        countByStatus={countByStatus}
        onPaginationChange={onPaginationChange}
        onSort={onSort}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        onSignDocumentSuccess={onSignDocumentSuccess}
        onFilterChange={onFilterChange}
        onSendToSignDocumentsSuccess={onSendToSignDocumentsSuccess}
      />

      {showModal ? (
        <CurrencyExchangeTablePreference
          show={showModal}
          columnsConfig={columnsConfig}
          columnsConfigMutationFn={columnsConfigMutationFn}
          handleShow={handleShow}
          onSuccessConfigurationSaved={onSuccessConfigurationSaved}
        />
      ) : null}
    </>
  );
};

import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ErrorSmallIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { DocumentHistory } from '@app/pages/documents/components/DocumentHistory';
import { useGetCurrencyDocumentByUuid } from '@app/pages/documents/show/query';
import { styles } from '@app/pages/documents/show/style';
import { ViewCurrencyDocument } from '@app/pages/documents/show/View';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const ShowCurrencyDocumentPage: React.FC = () => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { uuid = 'no-uuid' } = useParams();
  const { data: currencyDocument, refetch: refetchCurrencyDocument } = useGetCurrencyDocumentByUuid(uuid);

  useEffect(() => {
    refetchCurrencyDocument();
  }, [refetchCurrencyDocument]);

  const updateHandler = () => {
    refetchCurrencyDocument();
  };

  return (
    <PageWrapper>
      <PageHeader title={currencyDocument?.originalName || ''} showNavigateButton backLink={-1} />
      <PageContainer>
        <Grid container>
          {currencyDocument && userProfile ? (
            <>
              <Grid item xs={9}>
                {currencyDocument.rejectMessage ? (
                  <Grid container alignItems="center" sx={styles.rejectContainer}>
                    <ErrorSmallIcon sx={styles.rejectReasonIcon} />
                    <Typography variant="body2" sx={styles.rejectReasonText}>
                      {currencyDocument.rejectMessage}
                    </Typography>
                  </Grid>
                ) : null}
                <ViewCurrencyDocument document={currencyDocument} update={updateHandler} />
              </Grid>
              <Grid item xs={3}>
                <Box pl={10}>
                  {currencyDocument ? (
                    <DocumentHistory document={currencyDocument} userProfile={userProfile} edit={false} />
                  ) : null}
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </PageContainer>
    </PageWrapper>
  );
};

export enum SafetyTableColumns {
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  agreementNumber = 'agreementNumber',
  securityDescription = 'securityDescription',
  lastEvaluationDate = 'lastEvaluationDate',
  amount = 'amount',
  currencyCode = 'currencyCode',
  state = 'state',
}

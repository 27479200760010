import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, OutlinedInput, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SearchSmallIcon } from '@app/common/icons';
import { GetAccountResponseDto } from '@app/core/api';
import {
  useGetGetRecentDocuments,
  useGetDocumentsCount,
  useGetRecentOperations,
  useGetStatementsMultipleFiles,
} from '@app/core/hooks';
import { isMobile } from '@app/core/utils';

import { FilterPanel } from './components/FilterPanel';
import { Documents } from './Documents';
import { DesktopOperationsList } from './Operations/DesktopOperationsList';
import { MobileOperationsList } from './Operations/MobileOperationsList';
import { styles } from './styles';
import { DateRange, DocumentStatus, OperationsFilter, OperationsTypeEnum } from '../../types';

interface OperationsTableProps {
  accounts?: GetAccountResponseDto[];
  dateRange?: DateRange;
}

export const OperationsTable: React.FC<OperationsTableProps> = ({ accounts, dateRange }) => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [showClearSearchIcon, setShowClearSearchIcon] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<OperationsFilter>(OperationsFilter.type_all);
  const [operationType, setOperationType] = useState<OperationsTypeEnum | undefined>(undefined);
  const [documentStatus, setDocumentStatus] = useState<DocumentStatus | undefined>(undefined);
  const { t } = useTranslation();

  const { data, mutate } = useGetDocumentsCount();

  const updateDocumentsCount = () => {
    mutate({
      accounts: accounts?.map((account) => {
        return { id: account.account.id.toString(), currencyCode: account.account.currency };
      }),
    });
  };
  useEffect(() => {
    mutate({
      accounts: accounts?.map((account) => {
        return { id: account.account.id.toString(), currencyCode: account.account.currency };
      }),
    });
  }, [mutate, accounts]);

  const onFilterChangeHandler = (filter: OperationsFilter) => {
    switch (filter) {
      case OperationsFilter.type_all:
        setOperationType(undefined);
        break;
      case OperationsFilter.type_incoming:
        setOperationType(OperationsTypeEnum.deposit);
        break;
      case OperationsFilter.type_outgoing:
        setOperationType(OperationsTypeEnum.withdrawal);
        break;
      case OperationsFilter.status_processing:
        setDocumentStatus(DocumentStatus.Processing);
        break;
      case OperationsFilter.status_rejected:
        setDocumentStatus(DocumentStatus.Rejected);
        break;
      case OperationsFilter.status_toSign:
        setDocumentStatus(DocumentStatus.ToSign);
        break;
      case OperationsFilter.status_onSigning:
        setDocumentStatus(DocumentStatus.OnSigning);
        break;
      case OperationsFilter.status_saved:
        setDocumentStatus(DocumentStatus.Saved);
        break;
      default:
        break;
    }
    setCurrentFilter(filter);
  };

  const isDocument = (filter: OperationsFilter) => {
    return [
      OperationsFilter.status_toSign,
      OperationsFilter.status_onSigning,
      OperationsFilter.status_processing,
      OperationsFilter.status_rejected,
      OperationsFilter.status_saved,
    ].includes(filter);
  };

  const onSearchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    if (isMobile && e.target.value.length) {
      setShowClearSearchIcon(true);
    } else {
      setShowClearSearchIcon(false);
    }
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      setSearchText(searchInputValue);
    }
  };
  const onSearchClickHandler = () => {
    setSearchText(searchInputValue);
  };

  const updateDocumentCount = () => {
    updateDocumentsCount();
  };

  const onClearSearchClickHandler = () => {
    setSearchText('');
    setSearchInputValue('');
    setShowClearSearchIcon(false);
    updateDocumentsCount();
  };

  const OperationsListComponent = isMobile ? MobileOperationsList : DesktopOperationsList;

  return (
    <Box sx={styles.root}>
      <Toolbar disableGutters sx={styles.toolBar}>
        <Grid item container={isMobile} sx={styles.horizontalScroll}>
          <FilterPanel countData={data} onFilterChange={onFilterChangeHandler} />
        </Grid>

        {!isDocument(currentFilter) ? (
          <Box pt={4} pb={4} width={isMobile ? '100%' : 'auto'}>
            <OutlinedInput
              onChange={onSearchChangeHandler}
              onKeyPress={onKeyPressHandler}
              sx={styles.searchInput}
              placeholder={t('search')}
              fullWidth={isMobile}
              value={searchInputValue}
              endAdornment={
                <InputAdornment position="end">
                  {isMobile && showClearSearchIcon ? (
                    <IconButton onClick={onClearSearchClickHandler} size="large">
                      <CrossIcon sx={styles.searchIcon} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={onSearchClickHandler} size="large">
                      <SearchSmallIcon sx={styles.searchIcon} />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </Box>
        ) : null}
      </Toolbar>
      {isDocument(currentFilter) && documentStatus ? (
        <Documents
          accounts={accounts}
          documentStatus={documentStatus}
          updateDocumentCount={updateDocumentCount}
          getGetRecentDocuments={useGetGetRecentDocuments}
          getStatementsMultipleFiles={useGetStatementsMultipleFiles}
        />
      ) : (
        <OperationsListComponent
          getRecentOperations={useGetRecentOperations}
          accounts={accounts}
          operationType={operationType}
          searchText={searchText}
          dateRange={dateRange}
        />
      )}
    </Box>
  );
};

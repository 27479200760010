import React from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmPopup } from '@app/core/components/ConfirmPopup';
import { ReactComponent as CloudIconSvg } from '@app/themes/default/assets/icons/cloud.svg';

interface LeavePopupProps {
  open: boolean;
  onConfirm(): void;
  onCancel(): void;
}

export const LeaveTemplatePopup: React.FC<LeavePopupProps> = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ConfirmPopup
      open={open}
      onConfirm={onConfirm}
      onClose={onCancel}
      questionText={t('confirmLeaveTemplatePopupMessage')}
      icon={<CloudIconSvg />}
      classes={{
        questionTextContainer: {
          width: '85%',
          textAlign: 'center',
        },
      }}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { CreateOperationAdditionalInfoDto, ResultStatusEnum, SwiftOperationDto } from '@app/core/api';
import { SignMultipleSwift } from '@app/core/components';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useGetSwiftSigners, useGetSwiftByUuid } from '@app/core/hooks';
import { useEditSwiftPayment } from '@app/core/hooks/useEditSwiftPaymen';
import { useSendToSignSwift } from '@app/core/hooks/useSendToSignSwift';
import { MutationStatus } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { LocalPaymentUrlQuery } from '@app/pages/payment/create/types';
import { SwiftPaymentBetweenMyAccountForm } from '@app/pages/swift/SwiftPayment/components/BetweenMyAccounts';
import { makeEditSwiftPaymentSubmitData } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/helper';
import { SimpleSwiftDefaultValues, SwiftFormData } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface EditPaymentProps {
  paymentData: SwiftFormData;
  onEditSuccess(operation: SwiftOperationDto): void;
  onEditError(additionalInfo?: CreateOperationAdditionalInfoDto): void;
}

export const LoanSwiftEditPage: React.FC = () => {
  const urlQueryValues = useLocation().search;
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const [createPaymentResultOperation, setCreatePaymentResultOperation] = useState<SwiftOperationDto | null>();
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<SimpleSwiftDefaultValues>();
  const { showLoader } = useUIState();

  const navigate = useNavigate();

  const { notify } = useNotify();

  const { lid = 'no-Uid', rt } = parse(urlQueryValues) as LocalPaymentUrlQuery;

  const { data, refetch } = useGetSwiftByUuid(lid);

  useEffect(() => {
    refetch();
  }, [refetch, rt, lid]);

  const { permissions } = useAppSelector((state): ProfileState => state.profile);

  const { data: peopleToSigne } = useGetSwiftSigners();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setDefaultValues({
        senderAccountId: data?.sender.accountId,
        senderAccount: data?.sender.account,
        senderAddress: data?.sender.address,
        senderName: data?.sender.name,
        recipientSwiftBic: data?.recipient.swiftBic,
        recipientAccount: data?.recipient.account,
        recipientAccountId: data?.recipient.account,
        recipientAddress: data?.recipient.address,
        recipientBankName: data?.recipient.bankName,
        recipientCountryCode: data?.recipient.countryCode,
        recipientName: data?.recipient.name,
        paymentReference: data?.paymentReference,
        amount: data?.amount,
      });
    }
  }, [data]);

  const onSuccessSignHandler = () => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(-1);
  };

  const onCloseModalHandler = (status: MutationStatus) => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    if (createPaymentResultOperation && status !== 'success') {
      navigate(RouteList.swift_edit.replace(':uuid', createPaymentResultOperation.uuid));
    } else {
      navigate(-1);
    }
  };

  const { data: createSwiftOperationResultData, mutateAsync, reset: resetCreatePayment } = useEditSwiftPayment();

  const { data: sendToSignPaymentResultData, mutate: sendToSignMutation } = useSendToSignSwift();

  const editPayment = ({ paymentData, onEditSuccess, onEditError }: EditPaymentProps) => {
    mutateAsync(makeEditSwiftPaymentSubmitData({ paymentData, uuid: lid }))
      .then((result) => {
        if (result.operation) {
          onEditSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onEditError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onEditError();
      });
  };

  const onSaveAndSignClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        showLoader(false);
        setCreatePaymentResultOperation(operation);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        sendToSignMutation([operation.uuid]);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess() {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(RouteList.swift);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.swift);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  return (
    <PageWrapper>
      <PageHeader
        title={t('swiftCreationHeaderContent_title')}
        showNavigateButton={!isMobile}
        backLink={-1}
        showLeavePopup={showLeavePopup}
        confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
      />
      <PageContainer>
        <Box p={isMobile ? 0 : 3} width="100%">
          <Grid container wrap="nowrap">
            <Grid item container xs={isMobile ? false : 9}>
              <SwiftPaymentBetweenMyAccountForm
                signPermissions={permissions.sign}
                additionalInfo={createSwiftOperationResultData?.additionalInfo}
                onSaveAndSignClick={onSaveAndSignClickHandler}
                onSaveClick={onSaveClickHandler}
                onSendToSign={onSaveAndSendToSignClickHandler}
                defaultValues={defaultValues}
              />
            </Grid>
            {!isMobile && userProfile ? (
              <Grid item xs={3}>
                <Box pl={10}>
                  <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        {createPaymentResultOperation && showSignPayment ? (
          <SignMultipleSwift
            operations={[createPaymentResultOperation]}
            onSuccessPopupCancel={onSuccessSignHandler}
            onClose={onCloseModalHandler}
          />
        ) : null}
      </PageContainer>
    </PageWrapper>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, depositsApiClient, getClientId, GetListRequestDto, GetListResponseDto } from '@app/core/api';

const getDepositsListQueryFn = async (filterData: GetListRequestDto): Promise<GetListResponseDto> => {
  const response = await depositsApiClient.depositsControllerGetList(getClientId(), filterData);
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant get deposits!');
  }
  return response.data;
};

export const useGetDepositsList = (): UseMutationResult<GetListResponseDto, ApiError, GetListRequestDto> => {
  return useMutation<GetListResponseDto, ApiError, GetListRequestDto>(getDepositsListQueryFn);
};

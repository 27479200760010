import { string, object, SchemaOf } from 'yup';

type SchemaObject = {
  lei?: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaObject> => {
  return object().shape({
    lei: string().test((val) => (val ? val.length === 8 || val.length === 10 : true)),
  });
};

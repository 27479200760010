import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { DeleteIcon, EditIcon } from '@app/common/icons';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { IBanText } from '@app/core/components/IBanText';
import { styles } from '@app/pages/counterparties/components/CounterpartiesTable/components/styles';
import { CounterpartiesColumns, CounterpartiesColumnsEnum } from '@app/pages/counterparties/types';

interface MakeTableColumnsProps {
  t(text: string): string;
  handleShow(id: number): void;
  deleteCounterpartyModal(id: number): void;
}

export const makeTableColumns = ({
  t,
  handleShow,
  deleteCounterpartyModal,
}: MakeTableColumnsProps): ColumnDef<CounterpartiesColumns, any>[] => {
  return [
    {
      accessorKey: CounterpartiesColumnsEnum.account,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper
                {...props}
                tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}
              >
                <Typography variant="caption">{t('account')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<CounterpartiesColumns, string>) => {
        const accountNumber = getValue();
        return accountNumber.length === 29 ? (
          <Typography variant="body2" component="div">
            <IBanText text={accountNumber} />
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ color: 'colors.gray400' }}>
            {accountNumber}
          </Typography>
        );
      },
    },
    {
      accessorKey: CounterpartiesColumnsEnum.lei,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}>
              <Typography variant="caption">{`${t('paymentCreationLocalPayment_edrpou')} / ${t(
                'paymentCreationLocalPayment_passport'
              )}`}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue, row }: CellContext<CounterpartiesColumns, string>) => {
        return <Typography variant="body2">{getValue() || row.original.passport}</Typography>;
      },
    },
    {
      accessorKey: CounterpartiesColumnsEnum.name,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}>
              <Typography variant="caption">{t('counterparties_name')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CounterpartiesColumns, string>) => {
        return <Typography variant="body2">{getValue()}</Typography>;
      },
    },
    {
      accessorKey: CounterpartiesColumnsEnum.bank,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('paymentCreationLocalPayment_bankName')}</Typography>
          </div>
        </Grid>
      ),
    },
    {
      id: 'edit_action',
      cell: ({ row }: CellContext<CounterpartiesColumns, string>) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <IconButton size="small" onClick={() => handleShow(row.original.id)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Grid>
      ),
    },
    {
      id: 'delete_action',
      cell: ({ row }: CellContext<CounterpartiesColumns, string>) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <IconButton size="small" onClick={() => deleteCounterpartyModal(row.original.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      ),
    },
  ];
};

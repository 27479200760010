export const styles = {
  root: {
    backgroundColor: 'colors.primaryTextColor',
    border: '1px solid',
    borderColor: 'colors.gray100',
    borderRadius: '8px',
    padding: `8px 12px`,
  },
  errorLight: {
    borderColor: 'colors.error',
  },
};

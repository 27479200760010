import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { LoansLinesContent } from '@app/pages/loansLines/components/LoansLinesContent';

export const LoansLinesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('loansLines')} useDesktopStyle />
        <PageContainer>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{
              width: '100%',
            }}
          >
            <LoansLinesContent />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

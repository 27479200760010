import React from 'react';

import style from './style.module.scss';

interface IProps {
  show?: boolean;
}
export const MainLoader: React.FC<IProps> = ({ show = true }) => {
  if (show) {
    return (
      <div className={style.container}>
        <div className={style.loading}>
          <div className={style.dot} />
          <div className={style.dot} />
          <div className={style.dot} />
          <div className={style.dot} />
          <div className={style.dot} />
        </div>
      </div>
    );
  }
  return null;
};

import { AccountStatusEnum } from './client';

export const ApiErrorCodes = {
  DUPLICATE_TEMPLATE_NAME: 409,
};

export const ACCOUNT_STATUS_ORDER = {
  [AccountStatusEnum.Active]: 0,
  [AccountStatusEnum.Closed]: 1,
  [AccountStatusEnum.Blocked]: 2,
};

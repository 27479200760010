import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  depositsApiClient,
  getClientId,
  GetDepositRecentOperationsRequestDto,
  GetDepositRecentOperationsResponseDto,
} from '@app/core/api';

const getDepositRecentOperationsQueryFn = async (
  getDepositRecentOperationsRequestDto: GetDepositRecentOperationsRequestDto
): Promise<GetDepositRecentOperationsResponseDto> => {
  const response = await depositsApiClient.depositsControllerGetRecentOperations(
    getClientId(),
    getDepositRecentOperationsRequestDto
  );
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error in depositsControllerGetRecentOperations!');
  }
  return response.data;
};

export const useGetDepositRecentOperations = (): UseMutationResult<
  GetDepositRecentOperationsResponseDto,
  ApiError,
  GetDepositRecentOperationsRequestDto
> => {
  return useMutation<GetDepositRecentOperationsResponseDto, ApiError, GetDepositRecentOperationsRequestDto>(
    getDepositRecentOperationsQueryFn
  );
};

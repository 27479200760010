import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SheetState {
  visibleGroupDates: string[];
  setVisibleGroupDates(value: string[]): void;
}
const SheetContext = createContext<SheetState | undefined>(undefined);

const SheetProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [visibleGroupDates, setVisibleGroupDates] = useState<string[]>([]);

  return <SheetContext.Provider value={{ visibleGroupDates, setVisibleGroupDates }}>{children}</SheetContext.Provider>;
};

const useSheetState = (): SheetState => {
  const state = useContext(SheetContext);
  if (state === undefined) {
    throw new Error('useSheetState must be used within a SheetProvider');
  }
  return state;
};

export { SheetProvider, useSheetState };

import React from 'react';

import { Theme, SxProps } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { PaymentOrderTypeEnum } from '@app/core/api';

import { DocumentCategory } from '../../../../types';
import { SelectOptionType } from '../../../Select';
import { SelectField } from '../SelectField';

export type DocumentCategorySelectFieldProps = {
  name: string;
  styles?: SxProps<Theme>;
};

export const DocumentCategorySelectField: React.FC<DocumentCategorySelectFieldProps> = ({ name, styles }) => {
  const { t } = useTranslation();
  const operationsListItems: SelectOptionType[] = [
    {
      id: 'not_selected',
      name: t('not_selected'),
      value: undefined,
    },
    {
      id: t(`documentCategory_${DocumentCategory.memorialOrder}`),
      name: t(`documentCategory_${DocumentCategory.memorialOrder}`),
      value: PaymentOrderTypeEnum.MemorialOrderFlag,
    },
    {
      id: t(`documentCategory_${DocumentCategory.paymentOrder}`),
      name: t(`documentCategory_${DocumentCategory.paymentOrder}`),
      value: PaymentOrderTypeEnum.PaymentOrderFlag,
    },
  ];

  return <SelectField name={name} options={operationsListItems} sx={styles} defaultValue="" />;
};

import React from 'react';

import { Grid, Typography } from '@mui/material';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '@app/common/icons';
import { LoanDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { formatAmount } from '@app/core/utils';
import { LoansTableColumns } from '@app/pages/loans/types';
import { styles } from '@app/pages/loansLines/show/components/TranchesTable/styles';
import { RouteList } from '@app/src/constants/routeList';

interface MakeTableColumnsProps {
  t(text: string): string;
}

export const makeTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<LoanDto, any>[] => {
  return [
    {
      accessorKey: LoansTableColumns.agreementStartDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: LoansTableColumns.agreementEndDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: LoansTableColumns.agreementNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_loanAgreementNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: LoansTableColumns.productType,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`loans_loanName`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: LoansTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: LoansTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: LoansTableColumns.interestRate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`loans_interestRate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanDto, string>) => {
        return `${getValue()}%`;
      },
    },
    {
      id: 'actions',
      accessorKey: LoansTableColumns.uuid,
      header: () => <></>,
      cell: ({ row }: CellContext<LoanDto, any>) => {
        return (
          <Grid container justifyContent="flex-end" alignItems="center">
            <Link to={`${RouteList.loans}/${row.original.uuid}`}>
              <ArrowRightIcon sx={styles.linkArrow} />
            </Link>
          </Grid>
        );
      },
    },
  ];
};

import React, { useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FilterIcon, DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { StatementTypeEnum, OperationTypeEnum, OutputFormatEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { StatementsTable } from '@app/core/components/Statements/components/StatementsTable';
import { makeSubFilter } from '@app/core/components/Statements/helpers';
import {
  OperationsFromPeriodResult,
  StatementsSubFilterEnum,
  StatementsTableColumnsEnum,
} from '@app/core/components/Statements/types';
import { TableSkeleton } from '@app/core/components/TableSkeleton';
import { DEFAULT_FIRST_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetOperationsFile } from '@app/core/hooks';
import { ColumnFilterType, CurrencyEnum, PaginationChangeType, SortingRule, OperationsTypeEnum } from '@app/core/types';
import { resetFormData } from '@app/pages/operations/constants';
import { LocalCurrencyOperationsFilter } from '@app/pages/operations/desktop/components/LocalCurrencyOperationsFilter';
import { localCurrencyOperationsFilterSchema } from '@app/pages/operations/desktop/components/LocalCurrencyOperationsFilter/validationSchema';
import { OperationsFilterWatch } from '@app/pages/operations/desktop/OperationsFilterWatch';
import { makeDataForGetStatementsFile, makeDataForSubmit } from '@app/pages/operations/helpers';
import { getUAHStatements, operationsControllerUpdateUahOperationsFn } from '@app/pages/operations/query';
import { styles } from '@app/pages/operations/styles';
import { OperationsFilter, OperationsFilterFormData } from '@app/pages/operations/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

type LocalOperationsTableProps = {
  defaultValues: DefaultValues<OperationsFilterFormData>;
};
export const LocalOperationsTable: React.FC<LocalOperationsTableProps> = ({ defaultValues }) => {
  const { operationsTableConfig } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const columnFilter = useRef<ColumnFilterType<StatementsSubFilterEnum>>({});
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const sortRuleRef = useRef<SortingRule>({ field: StatementsTableColumnsEnum.operationDate, desc: true });
  const {
    documentNumber: defaultDocumentNumber,
    dateRange: defaultDateRange,
    dateRangeSelect,
    ...otherDefaultValues
  } = defaultValues;
  const [fullItems, setFullItems] = useState<boolean>(
    Object.entries(otherDefaultValues).filter(([key, value]) => {
      return !isEmpty(value);
    }).length > 0
  );
  const newLocalOperationsTabsItems: MenuItem<OperationsTypeEnum>[] = [
    {
      id: 1,
      labelText: t('outgoing'),
      value: OperationsTypeEnum.withdrawal,
    },
    {
      id: 2,
      labelText: t('incoming'),
      value: OperationsTypeEnum.deposit,
    },
  ];

  const methods = useForm<OperationsFilterFormData>({
    mode: 'onChange',
    resolver: yupResolver(localCurrencyOperationsFilterSchema(t)),
    defaultValues,
  });

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<OperationsFromPeriodResult, unknown, OperationsFilter>(getUAHStatements);
  const { getOperationsFile } = useGetOperationsFile();

  const operationTypeRef = useRef<OperationsTypeEnum>(OperationsTypeEnum.withdrawal);

  const updateTable = () => {
    mutate({
      ...makeDataForSubmit(methods.getValues()),
      subFilter: makeSubFilter(columnFilter.current),
      pagination: paginationRef.current,
      sortBy: sortRuleRef.current,
      operationType: operationTypeRef.current,
    });
  };

  const handleOperationTypeChange = (operationType: OperationsTypeEnum): void => {
    operationTypeRef.current = operationType;
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (methods.formState.isDirty && methods.formState.isValid) {
      updateTable();
    }
  };

  const onSubmit = () => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    updateTable();
  };

  const resetFormHandler = () => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    columnFilter.current = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    methods.reset(resetFormData);
    mutationReset();
    history.replaceState(null, '', '?');
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateTable();
    }
  };

  const onSortHandler = (rule: SortingRule) => {
    sortRuleRef.current = rule;
    updateTable();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateTable();
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const onDeleteDocumentSuccessHandler = () => {
    updateTable();
  };

  const onSignDocumentsSuccessHandler = () => {
    updateTable();
  };

  const onDownloadOperationsFileHandler = ({
    operationsUuids,
    outputType,
    format,
  }: {
    operationsUuids: string[];
    outputType: string;
    format?: string;
  }) => {
    methods.trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        getOperationsFile({
          operationsUuids,
          format,
          outputType,
          getOperationsFileValues: makeDataForGetStatementsFile({
            outputType: outputType as OutputFormatEnum,
            documentType: StatementTypeEnum.UahOperation,
            formData: methods.getValues(),
            subFilter: makeSubFilter(columnFilter.current),
            operationType: operationTypeRef.current as unknown as OperationTypeEnum,
          }),
        });
      }
    });
  };

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<StatementsSubFilterEnum>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (submit) {
      methods.trigger().then((valid) => {
        if (isLoading) {
          return;
        }
        if (valid) {
          updateTable();
        }
      });
    }
  };

  const onCancelDocumentSuccessHandler = () => {
    updateTable();
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    methods.trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        updateTable();
      }
    });
  };

  return (
    <>
      <OperationsFilterWatch formMethods={methods} onFilterReady={updateTable} />
      <HorizontalMenu onChange={handleOperationTypeChange} menuItems={newLocalOperationsTabsItems} />
      <Grid item container direction="column" wrap="nowrap">
        <Box pb={4} mt={7}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FilterIcon />
              <Typography variant="h6" component="span" sx={styles.captionFilter}>
                {t('filters')}
              </Typography>
            </Grid>
            <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
              {t('additionalParameters')}
              {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
            </Button>
          </Grid>
        </Box>
        <Box pb={4}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              onKeyPress={onKeyPressHandler}
              role="presentation"
              style={styles.verticalWrapper}
            >
              <LocalCurrencyOperationsFilter fullItems={fullItems} resetFormHandler={resetFormHandler} />
            </form>
          </FormProvider>
        </Box>
        {operationsTableConfig.length > 0 ? (
          <StatementsTable
            operationsFromPeriod={data}
            loading={isLoading}
            columnsConfigMutationFn={operationsControllerUpdateUahOperationsFn}
            onSort={onSortHandler}
            onPaginationChange={onPaginationChangeHandler}
            queryKey={StatementTypeEnum.UahOperation}
            onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
            currencyOptions={[CurrencyEnum.UAH]}
            columnFilter={columnFilter.current}
            columnsConfig={operationsTableConfig}
            onSendToSignDocumentsSuccess={onSignDocumentsSuccessHandler}
            onSignDocumentsSuccess={onSignDocumentsSuccessHandler}
            onDownloadOperationsFile={onDownloadOperationsFileHandler}
            onColumnFilterChange={onColumnFilterChangeHandler}
            onCancelDocumentSuccess={onCancelDocumentSuccessHandler}
            onClearSubFilter={onClearSubFilterHandler}
          />
        ) : (
          <TableSkeleton showActionsColumn showSelectionColumn />
        )}
      </Grid>
    </>
  );
};

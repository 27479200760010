import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { CopyIcon, SuccessSmallSvgIcon } from '@app/common/icons';
import { GetOneLoanLineResponseDto, GetOneLoanResponseDto } from '@app/core/api';
import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/chevron-down.svg';

import { styles } from './style';
import { IBanText } from '../IBanText';

interface LoanLinkedAccountProps {
  loan: GetOneLoanResponseDto | GetOneLoanLineResponseDto;
}

export const LoanLinkedAccount: React.FC<LoanLinkedAccountProps> = ({ loan }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const onCopy = (value: string) => {
    copy(value);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1500);
  };

  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2" sx={styles.info} onClick={handleClick}>
        <Box component="span">{t('linkedAccounts')}</Box>
        <ChevronDownIconSvg />
      </Typography>
      <Popover
        onClick={handlePopoverClick}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        sx={{
          '& .MuiPopover-paper': {
            background: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box component="div" m={2}>
          <Grid container alignItems="center" sx={styles.tooltipContent}>
            {copyAlert ? (
              <Grid container alignItems="center" sx={styles.alertCopy}>
                <SuccessSmallSvgIcon fontSize="small" />
                <Typography variant="caption" sx={styles.alertCopyText}>
                  {t('copy_account')}
                </Typography>
              </Grid>
            ) : null}

            {loan.accountOutstanding ? (
              <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
                <Grid item>
                  <Typography variant="body2" component="span">
                    {t('loanOutstanding')}
                  </Typography>
                </Grid>
                <Grid item sx={styles.rowRight}>
                  <Grid container>
                    <IBanText text={loan.accountOutstanding} />
                    <CopyIcon
                      sx={[styles.buttonPopoverSvgIcon, styles.buttonPopoverSvgIconFill]}
                      onClick={() => {
                        onCopy(loan.accountOutstanding);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {loan.accountInterestAccrued ? (
              <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
                <Grid item>
                  <Typography variant="body2" component="span">
                    {t('interestAccrued')}
                  </Typography>
                </Grid>
                <Grid item sx={styles.rowRight}>
                  <Grid container>
                    <IBanText text={loan.accountInterestAccrued} />
                    <CopyIcon
                      sx={[styles.buttonPopoverSvgIcon, styles.buttonPopoverSvgIconFill]}
                      onClick={() => {
                        onCopy(loan.accountInterestAccrued);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {loan.accountOutstandingOverdue ? (
              <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
                <Grid item>
                  <Typography variant="body2" component="span">
                    {t('outstandingOverdue')}
                  </Typography>
                </Grid>
                <Grid item sx={styles.rowRight}>
                  <Grid container>
                    <IBanText text={loan.accountOutstandingOverdue} />
                    <CopyIcon
                      sx={[styles.buttonPopoverSvgIcon, styles.buttonPopoverSvgIconFill]}
                      onClick={() => {
                        onCopy(loan.accountOutstandingOverdue!);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {loan.accountInterestOverdue ? (
              <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
                <Grid item>
                  <Typography variant="body2" component="span">
                    {t('loanInterestOverdue')}
                  </Typography>
                </Grid>
                <Grid item sx={styles.rowRight}>
                  <Grid container>
                    <IBanText text={loan.accountInterestOverdue} />
                    <CopyIcon
                      sx={[styles.buttonPopoverSvgIcon, styles.buttonPopoverSvgIconFill]}
                      onClick={() => {
                        onCopy(loan.accountInterestOverdue!);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

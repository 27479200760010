import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useReactTable, SortingState, ColumnSort, getCoreRowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { UserSessionDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE, statementsTableRowsPerPageOptions } from '@app/core/constants';
import { PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable/columns';
import {
  buildTableRowsData,
  VisitsRow,
} from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable/helpers';
import { styles } from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable/style';

interface TableContentProps {
  visitsData: UserSessionDto[];
  pagination?: TablePaginationType;
  loading: boolean;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
}

export const VisitsTable: React.FC<TableContentProps> = ({
  visitsData,
  pagination,
  loading,
  onSort,
  onPaginationChange,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return buildTableRowsData(visitsData, t);
  }, [t, visitsData]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
      }),
    ];
  }, [t]);

  const table = useReactTable<VisitsRow>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: visitsData.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const BodyContent: JSX.Element[] = table.getRowModel().rows.map((row, i) => {
    return <TableRowContent<VisitsRow> key={row.id} row={row} styles={styles} />;
  });

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<VisitsRow> {...{ table, styles }} /> }}
      body={{ content: BodyContent }}
      footer={{
        content: (
          <TablePagination
            totalCount={pagination?.totalCount || 0}
            page={pagination?.page || DEFAULT_FIRST_PAGE}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            labelRowsPerPage={t('showOnPageShort')}
            rowsPerPageOptions={statementsTableRowsPerPageOptions}
            onChange={onPaginationChange}
            show={!loading}
          />
        ),
      }}
    />
  );
};

export const styles = {
  paper: {
    backgroundColor: 'white',
    padding: '28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '824px',
  },
  wrapHeight: {
    maxHeight: '80%',
    overflow: 'auto',
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
    marginRight: 0,
  },
  caption: {
    fontWeight: 700,
  },
  labelInput: {
    color: 'colors.gray300',
    fontWeight: 600,
  },
  captionSaveModal: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
  },
  buttonSaveModal: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  closedCaption: {
    position: 'relative',
    fontSize: '0.75rem',
    color: 'colors.gray200',
    marginBottom: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 0,
      width: '100%',
      height: '1px',
      borderBottom: `1px dashed`,
      borderColor: 'colors.gray50',
    },
    '& span': {
      position: 'relative',
      zIndex: 1,
      backgroundColor: 'white',
    },
  },
  leiLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  sendButton: {
    marginTop: '20px',
    '& > span': {
      fontWeight: 600,
    },
  },
  action: {
    display: 'flex',
    color: 'colors.primary500',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  CrossIcon,
  DownChevronSmallIcon,
  EraserIcon,
  FilterSmallIcon,
  SearchSmallIcon,
  UpChevronSmallIcon,
} from '@app/common/icons';
import { Tooltip } from '@app/core/components';

import { styles } from './styles';
import { DocumentStatus, CurrencyEnum } from '../../../../../../types';
import { parseIBAN } from '../../../../../../utils';
import { Checkbox } from '../../../../../Checkbox';
import {
  HeaderSortedWrapper,
  SelectColumnFilter,
  DateSelectColumnFilter,
  TextFieldColumnFilter,
} from '../../../../../ComplexTable';
import { Ellipsis } from '../../../../../Ellipsis';
import { SettingsButton } from '../../../../../SettingsButton';
import { StatementsSubFilterEnum } from '../../../../types';
import { OperationFieldEnum, OperationRow } from '../../types';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  showShort: boolean;
  currencyOptions: CurrencyEnum[];
  t(text: string): string;
  onSearchButtonClick(): void;
  onSettingsButtonClick(): void;
  onCloseSubFilter(): void;
  onClearSubFilter(): void;
}

type StatusFieldProps = {
  status: DocumentStatus;
};

const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.status,
          ...(status === DocumentStatus.Executed && styles.statusExecuted),
          ...(status === DocumentStatus.Rejected && styles.statusRejected),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(`${status}`)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  showSubFilters,
  showShort,
  t,
  currencyOptions,
  onSearchButtonClick,
  onSettingsButtonClick,
  onCloseSubFilter,
  onClearSubFilter,
}: MakeTableColumnsProps): ColumnDef<OperationRow, any>[] => {
  return [
    {
      id: 'selection',
      enableResizing: false,
      size: 40,
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.documentNumber,
      size: 130,
      minSize: 130,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Tooltip
                  color="primary"
                  title={t(`statementsTable_${OperationFieldEnum.documentNumber}`)}
                  arrow
                  placement="top"
                  withBorder
                >
                  <Typography variant="caption">
                    {showShort
                      ? t('statementsTableColumn_documentNumber_short')
                      : t('statementsTableColumn_documentNumber')}
                  </Typography>
                </Tooltip>
              </HeaderSortedWrapper>
            </div>
            {showSubFilters ? (
              <TextFieldColumnFilter<OperationRow>
                name={StatementsSubFilterEnum.documentNumber}
                column={props.column}
                table={props.table}
              />
            ) : null}
          </Grid>
        );
      },
    },
    {
      accessorKey: OperationFieldEnum.documentDate,
      size: 112,
      enableResizing: false,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.documentDate}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort ? t('statementsTableColumn_documentDate_short') : t('statementsTableColumn_documentDate')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <DateSelectColumnFilter
              name={StatementsSubFilterEnum.documentDate}
              column={props.column}
              table={props.table}
              submit
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string>) => {
        const value = getValue();
        return <>{format(new Date(value.split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: OperationFieldEnum.executionDate,
      size: 112,
      enableResizing: false,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.executionDate}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t(`statementsTable_${OperationFieldEnum.executionDate}_short`)
                    : t(`statementsTable_${OperationFieldEnum.executionDate}`)}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <DateSelectColumnFilter
              name={StatementsSubFilterEnum.executionDate}
              column={props.column}
              table={props.table}
              submit
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string>) => {
        const value = getValue();
        if (value) {
          return <>{format(new Date(value.split('T')[0]), 'dd.MM.yyyy')}</>;
        }
        return '';
      },
    },
    {
      accessorKey: OperationFieldEnum.counterpartyName,
      size: 124,
      minSize: 124,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.counterpartyName}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_counterpartyName_short')
                    : t('statementsTableColumn_counterpartyName')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.recipientAccountName}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string | number | React.ReactNode>) => {
        const value = getValue();
        return <Ellipsis text={value} />;
      },
    },
    {
      accessorKey: OperationFieldEnum.accountNumber,
      size: 180,
      minSize: 80,
      maxSize: 260,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.accountNumber}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {props.header.column.getSize() < 110
                    ? t('statementsTableColumn_accountNumber_short')
                    : t('statementsTableColumn_accountNumber')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.accountNumber}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string>) => {
        const value = getValue();
        const { accountPart1, accountPart2 } = parseIBAN(value);
        return (
          <Ellipsis
            text={
              <>
                <Box component="span" sx={styles.accountNumberPart1}>
                  {accountPart1}
                </Box>
                <Box component="span" sx={styles.accountNumberPart2}>
                  {accountPart2}
                </Box>
              </>
            }
          />
        );
      },
    },
    {
      accessorKey: OperationFieldEnum.counterpartyLei,
      size: 112,
      minSize: 90,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.counterpartyLei}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_counterpartyLei_short')
                    : t('statementsTableColumn_counterpartyLei')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.LEI}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.paymentReference,
      size: 200,
      minSize: 90,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.paymentReference}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_paymentReference_short')
                    : t('statementsTableColumn_paymentReference')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.paymentReference}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string | number | React.ReactNode>) => {
        // const value = getValue();
        return <span>{getValue()}</span>;
      },
    },
    {
      accessorKey: OperationFieldEnum.currency,
      size: 80,
      enableResizing: false,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.currency}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort ? t('statementsTableColumn_currency_short') : t('statementsTableColumn_currency')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <SelectColumnFilter<OperationRow>
              submit
              name={StatementsSubFilterEnum.currency}
              column={props.column}
              table={props.table}
              options={currencyOptions.map((items) => ({ id: items, name: items, value: items }))}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.amount,
      size: 116,
      minSize: 70,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.amount}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort ? t('statementsTableColumn_amount_short') : t('statementsTableColumn_amount')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.amount}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.debit,
      size: 116,
      minSize: 80,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.debit}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort ? t('statementsTableColumn_withdrawal_short') : t('statementsTableColumn_withdrawal')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.withdrawal}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.credit,
      size: 116,
      minSize: 80,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.credit}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort ? t('statementsTableColumn_deposit_short') : t('statementsTableColumn_deposit')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.deposit}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.counterpartyBankCode,
      size: 96,
      minSize: 90,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.counterpartyBankCode}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_counterpartyBankCode_short')
                    : t('statementsTableColumn_counterpartyBankCode')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.bankCode}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: OperationFieldEnum.counterpartyBank,
      size: 118,
      minSize: 118,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.counterpartyBank}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_counterpartyBank_short')
                    : t('statementsTableColumn_counterpartyBank')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.bankName}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string | number | React.ReactNode>) => {
        const value = getValue();
        return <Ellipsis text={value} />;
      },
    },
    {
      accessorKey: OperationFieldEnum.counterpartyAccount,
      size: 118,
      minSize: 118,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.counterpartyAccount}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_counterpartyAccount_short')
                    : t('statementsTableColumn_counterpartyAccount')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<OperationRow>
              name={StatementsSubFilterEnum.recipientAccountNumber}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string>) => {
        const value = getValue();
        const { accountPart1, accountPart2 } = parseIBAN(value);

        return (
          <Ellipsis
            text={
              <>
                <Box component="span" sx={styles.accountNumberPart1}>
                  {accountPart1}
                </Box>
                <Box component="span" sx={styles.accountNumberPart2}>
                  {accountPart2}
                </Box>
              </>
            }
          />
        );
      },
    },
    {
      accessorKey: OperationFieldEnum.status,
      size: 100,
      minSize: 90,
      header: (props) => (
        <Grid container direction="row" justifyContent="flex-start" alignSelf={showSubFilters ? 'flex-start' : 'none'}>
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.status}`)}
                arrow
                placement="top"
                withBorder
              >
                <Typography variant="caption">
                  {showShort
                    ? t('statementsTableColumn_documentStatus_short')
                    : t('statementsTableColumn_documentStatus')}
                </Typography>
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, DocumentStatus>) => {
        const value = getValue();
        return <StatusField status={value} />;
      },
    },
    {
      accessorKey: OperationFieldEnum.arcDate,
      size: 112,
      enableResizing: false,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Tooltip
                color="primary"
                title={t(`statementsTable_${OperationFieldEnum.arcDate}`)}
                arrow
                placement="top"
                withBorder
              >
                {showShort || showSubFilters ? (
                  <Typography variant="caption">{t('statementsTable_arcDate_short')}</Typography>
                ) : (
                  <Typography variant="caption">{t('statementsTable_arcDate')}</Typography>
                )}
              </Tooltip>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <DateSelectColumnFilter
              name={StatementsSubFilterEnum.arcDate}
              column={props.column}
              table={props.table}
              submit
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationRow, string>) => {
        const value = getValue();
        if (value) {
          return <>{format(new Date(value.split('T')[0]), 'dd.MM.yyyy')}</>;
        }
        return '';
      },
    },
    {
      id: 'actions',
      enableResizing: false,
      size: 96,
      minSize: 96,
      header: ({ table }) => {
        return (
          <Grid container justifyContent="flex-end">
            {showSubFilters ? (
              <IconButton onClick={onClearSubFilter} size="large">
                <EraserIcon fontSize="small" />
              </IconButton>
            ) : (
              <>
                {table.options.meta?.columnFilterSate?.columnFilter &&
                Object.keys(table.options.meta.columnFilterSate.columnFilter).length > 0 ? (
                  <IconButton onClick={onSearchButtonClick} size="large">
                    <FilterSmallIcon sx={styles.filterIconBtn} fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton sx={styles.searchIconBtn} onClick={onSearchButtonClick} size="large">
                    <SearchSmallIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            )}

            {showSubFilters ? (
              <IconButton size="small" onClick={onCloseSubFilter}>
                <CrossIcon fontSize="small" />
              </IconButton>
            ) : (
              <SettingsButton onClick={onSettingsButtonClick} />
            )}
          </Grid>
        );
      },
      cell: ({ row }) => {
        return (
          <Grid container justifyContent="flex-end">
            <Box
              component="span"
              sx={styles.chevron}
              {...{
                onClick: () => row.toggleExpanded(),
              }}
            >
              {row.getIsExpanded() ? (
                <UpChevronSmallIcon fontSize="small" />
              ) : (
                <DownChevronSmallIcon fontSize="small" />
              )}
            </Box>
          </Grid>
        );
      },
    },
  ];
};

import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { CrossIcon } from '@app/common/icons';
import {
  CreateDocumentInfo,
  CurrencyOperationsExchangeRateTypeEnum,
  CurrencyOperationsExchangeTypeEnum,
  GeneratedDocumentNumberResponseDto,
  GetAccountResponseDto,
  GetCurrencyOperationAccountsDto,
  GetDocumentResponseDto,
  CurrencyOperationStatusEnum,
  OperationHistoryItemDto,
  AvailableActionsDto,
  SignatureStatusDto,
  ApiError,
  CreateOperationAdditionalInfoDto,
  CreateOperationAdditionalInfoDtoCodeEnum,
} from '@app/core/api';
import { ConfirmPopup } from '@app/core/components/ConfirmPopup';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { ddMMYYYYFormat } from '@app/core/constants';
import { FileType } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { InfoBlock } from '@app/pages/exchange/components/form/components/InfoBlock';
import { DesktopExchangeFormContent } from '@app/pages/exchange/components/form/DesktopExchangeFormContent';
import { getDocumentNumberTranslationKeyTitle } from '@app/pages/exchange/components/form/helpers';
import { MobileExchangeFormContent } from '@app/pages/exchange/components/form/MobileExchangeFormContent';
import { styles } from '@app/pages/exchange/components/form/style';
import { CurrencyFormData, CurrencyFormDefaultValues, FormFieldName } from '@app/pages/exchange/components/form/types';
import { validationSchema } from '@app/pages/exchange/components/form/validationSchema';
import { generateOrderNumberFn, getCurrencyAccountsQueryFn } from '@app/pages/exchange/query';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';
import { ReactComponent as CloudIconSvg } from '@app/themes/default/assets/icons/cloud.svg';

const acceptedFileTypes = ['pdf'];

export type EditData = {
  formData: CurrencyFormData;
  files: FileType[];
  currencyExchangeType: CurrencyOperationsExchangeTypeEnum;
};

interface CurrencyFormProps {
  uuid?: string;
  currencyExchangeType: CurrencyOperationsExchangeTypeEnum;
  defaultValues?: CurrencyFormDefaultValues;
  status?: CurrencyOperationStatusEnum;
  documents?: Array<GetDocumentResponseDto>;
  userHistory?: Array<OperationHistoryItemDto>;
  createDocumentInfo?: CreateDocumentInfo;
  personToSign: Array<SignatureStatusDto>;
  signDocumentsButtonEnabled: boolean;
  signPermissions: AvailableActionsDto;
  additionalInfo?: CreateOperationAdditionalInfoDto | null;
  onSave(editData: EditData): void;
  onSendToSign(editData: EditData): void;
  onSaveAndSign(editData: EditData, signFromAnotherPerson?: boolean): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
  onUploadFiles?(files: FileType[], onSuccess: () => void): void;
  onDeleteFile?(document: GetDocumentResponseDto): void;
  onSignCurrencyDocumentFiles?(): void;
}

export const CurrencyForm: React.FC<CurrencyFormProps> = ({
  uuid,
  currencyExchangeType,
  defaultValues,
  documents,
  userHistory,
  createDocumentInfo,
  personToSign,
  status,
  signDocumentsButtonEnabled,
  signPermissions,
  additionalInfo,
  onSave,
  onSaveAndSign,
  onChange,
  onUploadFiles,
  onDeleteFile,
  onSignCurrencyDocumentFiles = () => {},
  onSendToSign,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedWithdrawalAccount, setSelectedWithdrawalAccount] = useState<GetAccountResponseDto | undefined>();
  const [withdrawalAccounts, setWithdrawalAccounts] = useState<GetAccountResponseDto[]>([]);
  const [depositAccounts, setDepositAccounts] = useState<GetAccountResponseDto[]>([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [exchangeRateDisable, setExchangeRateDisable] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [files, setFiles] = useState<FileType[]>([]);
  const currentDocumentDateRef = useRef<Date | undefined>();
  const documentNumberRef = useRef<HTMLElement>(null);

  const { userProfile } = useAppSelector((state): ProfileState => state.profile);

  const { data: orderNumberData } = useQuery<GeneratedDocumentNumberResponseDto, ApiError>(
    'generateOrderNumber',
    generateOrderNumberFn
  );

  const { data: withdrawalAccountsData } = useQuery<GetAccountResponseDto[], ApiError>('currencyAccountsQuery', () =>
    getCurrencyAccountsQueryFn({
      exchangeType: currencyExchangeType,
      withdrawalAccounts: true,
    })
  );

  const { data: depositAccountsData, mutate: mutateDepositAccounts } = useMutation<
    GetAccountResponseDto[],
    ApiError,
    GetCurrencyOperationAccountsDto
  >(getCurrencyAccountsQueryFn);

  const methods = useForm<CurrencyFormData>({
    resolver: yupResolver(validationSchema(t, currencyExchangeType)),
    mode: 'onChange',
    defaultValues: {
      [FormFieldName.temp_documentValueDate]: '',
      [FormFieldName._userConfirmCheck]: true,
    },
  });

  const {
    control,
    trigger,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues.documentNumber) {
        setValue(FormFieldName.documentNumber, defaultValues.documentNumber);
      }
      if (defaultValues.documentDate) {
        setValue(FormFieldName.documentDate, defaultValues.documentDate);
      }

      setValue(FormFieldName.amount, defaultValues.amount);

      currentDocumentDateRef.current = defaultValues.documentDate;
      setValue(FormFieldName.temp_documentDate, defaultValues.temp_documentDate);
      if (defaultValues.documentValueDate) {
        setValue(FormFieldName.documentValueDate, defaultValues.documentValueDate);
      }
      if (defaultValues.temp_documentValueDate) {
        setValue(FormFieldName.temp_documentValueDate, defaultValues.temp_documentValueDate);
      }
      if (defaultValues.exchangeRate) {
        setValue(FormFieldName.exchangeRate, defaultValues.exchangeRate);
      }
      if (defaultValues.exchangeRateType) {
        setValue(FormFieldName.exchangeRateType, defaultValues.exchangeRateType);
      }
      if (defaultValues.currencyOperationReason) {
        setValue(FormFieldName.currencyOperationReason, defaultValues.currencyOperationReason);
      }
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (withdrawalAccountsData) {
      setWithdrawalAccounts(withdrawalAccountsData);
      if (defaultValues) {
        const activeAccount = withdrawalAccountsData.find(
          (account) => account.account.id === JSON.parse(defaultValues.withdrawalAccount).id
        );
        if (activeAccount) {
          setValue(FormFieldName.withdrawalAccount, JSON.stringify(activeAccount));
        }
      }
    }
  }, [withdrawalAccountsData, defaultValues, setValue]);

  useEffect(() => {
    onChange({ formDirty: isDirty });
  }, [isDirty, onChange]);

  const watchWithdrawalAccount = useWatch({ control, name: FormFieldName.withdrawalAccount });
  const watchDepositAccount = useWatch({ control, name: FormFieldName.depositAccount });

  useEffect(() => {
    if (watchWithdrawalAccount) {
      const withdrawalAccount = JSON.parse(watchWithdrawalAccount) as GetAccountResponseDto;
      setSelectedWithdrawalAccount(withdrawalAccount);
      setValue(FormFieldName.depositAccount, '');
      if (currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion) {
        mutateDepositAccounts({
          exchangeType: currencyExchangeType,
          withdrawalAccounts: false,
          withdrawalAccountCurrencyCode: withdrawalAccount.account.currency,
        });
      } else {
        mutateDepositAccounts({
          exchangeType: currencyExchangeType,
          withdrawalAccounts: false,
        });
      }
    }
  }, [currencyExchangeType, mutateDepositAccounts, setValue, watchWithdrawalAccount]);

  useEffect(() => {
    if (depositAccountsData && selectedWithdrawalAccount) {
      setDepositAccounts(
        depositAccountsData.filter(({ account }) => account.id !== selectedWithdrawalAccount.account.id)
      );
      if (defaultValues) {
        const activeAccount = depositAccountsData.find(
          (account) => account.account.id === JSON.parse(defaultValues.depositAccount).id
        );
        if (activeAccount) {
          setValue(FormFieldName.depositAccount, JSON.stringify(activeAccount));
        }
      }
    }
  }, [defaultValues, depositAccountsData, selectedWithdrawalAccount, setValue]);

  const onSaveHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSave({ formData: getValues(), files, currencyExchangeType });
      }
    });
  };

  const onSendToSignHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSendToSign({ formData: getValues(), files, currencyExchangeType });
      }
    });
  };

  const onSaveAndSignHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign({ formData: getValues(), files, currencyExchangeType });
      }
    });
  };

  const onSignFromAnotherPersonHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign({ formData: getValues(), files, currencyExchangeType }, true);
      }
    });
  };

  const watchDocumentDate = useWatch({ control, name: FormFieldName.documentDate });

  useEffect(() => {
    if (
      watchDocumentDate &&
      currentDocumentDateRef.current &&
      format(watchDocumentDate, ddMMYYYYFormat.format) !== format(currentDocumentDateRef.current, ddMMYYYYFormat.format)
    ) {
      currentDocumentDateRef.current = watchDocumentDate;
      setValue(FormFieldName.temp_documentValueDate, '');
      setValue(FormFieldName.documentValueDate, undefined);
    }
  }, [getValues, setValue, watchDocumentDate]);

  const watchExchangeRateType = useWatch({ control, name: FormFieldName.exchangeRateType });
  useEffect(() => {
    if (watchExchangeRateType && watchExchangeRateType === CurrencyOperationsExchangeRateTypeEnum.Bank) {
      setValue(FormFieldName.exchangeRate, '');
      setExchangeRateDisable(true);
    } else {
      setExchangeRateDisable(false);
    }
  }, [setValue, watchExchangeRateType]);

  const watchUserConfirmCheck = useWatch({ control, name: FormFieldName._userConfirmCheck });

  const buttonDisabled = !Boolean(watchUserConfirmCheck);

  const handleLoadFile = (file: File) => {
    const extension = file.name.split('.').pop();
    if (extension && acceptedFileTypes.includes(extension.toLocaleLowerCase())) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setFiles([
            ...files,
            { file: file, name: file.name, result: reader.result, size: (file.size / (1024 * 1024)).toFixed(2) },
          ]);
        }
      };
      reader.readAsText(file);
    }
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleLoadFile(file);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const onFileSelectClickHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0) {
          handleLoadFile(fileInputRef.current.files[0]);
        }
      }
    }
  };

  const onFileDeleteClickHandler = (fileForDelete: FileType) => {
    setFiles(files.filter((file) => file.file.name !== fileForDelete.file.name));
  };

  const onCancelButtonHandler = () => {
    if (!showConfirmPopup && isDirty) {
      setShowConfirmPopup(true);
    } else {
      navigate(RouteList.exchange);
    }
  };

  const onConfirmLeaveHandler = () => {
    setShowConfirmPopup(false);
    navigate(-1);
  };

  const onCancelLeaveHandler = () => {
    setShowConfirmPopup(false);
  };

  const onUploadFilesClickHandler = () => {
    if (onUploadFiles) {
      onUploadFiles(files, () => {
        setFiles([]);
      });
    }
  };

  const getWithdrawalAccountPlaceholder = (): string => {
    switch (currencyExchangeType) {
      case CurrencyOperationsExchangeTypeEnum.Purchase:
        return t('withdrawalAccountLabelForPurchase');
      default:
        return t('currencyExchange_selectWithdrawalAccountPlaceholder');
    }
  };

  const depositAccountPlaceholder =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Sale
      ? t('currencyExchange_selectDepositAccountPlaceholder')
      : t('depositAccountLabelForPurchase');
  const amountLabel =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Purchase ? t('purchaseAmount') : t('salleAmount');

  const depositAccount: GetAccountResponseDto | undefined = watchDepositAccount
    ? JSON.parse(watchDepositAccount)
    : undefined;
  const currency =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Sale
      ? selectedWithdrawalAccount?.account.currency || ''
      : depositAccount?.account.currency || '';

  const conversionRateLabel =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion
      ? t('exchangeRateLabel_conversion')
      : t('conversionRate');

  const ExchangeFormContent = isMobile ? MobileExchangeFormContent : DesktopExchangeFormContent;

  useEffect(() => {
    if (additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <>
      <Box p={isMobile ? 0 : 3} width="100%">
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item container xs={isMobile ? false : 9}>
            <FormProvider {...methods}>
              <form style={{ ...styles.form, ...(isMobile && styles.formMobile) }}>
                <Grid
                  container
                  direction="column"
                  sx={{
                    ...styles.formContent,
                    ...(isMobile && styles.formContentMobile),
                    ...(oveDragZone && styles.dropContainerActive),
                  }}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item container xs={11}>
                      <Box ref={documentNumberRef}>
                        <DocumentNumberField
                          name={FormFieldName.documentNumber}
                          title={t(getDocumentNumberTranslationKeyTitle(currencyExchangeType))}
                          defaultValue={
                            defaultValues?.documentNumber || orderNumberData?.generatedDocumentNumber || 'auto1234'
                          }
                          validateErrorMessage={t('currencyExchange_validateDocumentNumberErrorMessage')}
                          error={
                            additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed
                          }
                        />
                      </Box>
                    </Grid>
                    {isMobile ? (
                      <Grid item xs={1}>
                        <IconButton size="small" onClick={onCancelButtonHandler}>
                          <CrossIcon />
                        </IconButton>
                      </Grid>
                    ) : null}
                  </Grid>
                  <ExchangeFormContent
                    uuid={uuid}
                    currencyExchangeType={currencyExchangeType}
                    watchDocumentDate={watchDocumentDate}
                    withdrawalAccounts={withdrawalAccounts}
                    depositAccounts={depositAccounts}
                    withdrawalAccountPlaceholder={getWithdrawalAccountPlaceholder()}
                    depositAccountPlaceholder={depositAccountPlaceholder}
                    amountLabel={amountLabel}
                    currency={currency}
                    conversionRateLabel={conversionRateLabel}
                    exchangeRateDisable={exchangeRateDisable}
                    selectedWithdrawalAccount={selectedWithdrawalAccount}
                    files={files}
                    buttonDisabled={buttonDisabled}
                    signPermissions={signPermissions}
                    onFileSelectClick={onFileSelectClickHandler}
                    onFileDeleteClick={onFileDeleteClickHandler}
                    onSave={onSaveHandler}
                    onSendToSign={onSendToSignHandler}
                    onCancelButtonClick={onCancelButtonHandler}
                    onSaveAndSign={onSaveAndSignHandler}
                    onUploadFilesClick={onUploadFilesClickHandler}
                    onSaveAndSignFromAnotherPerson={onSignFromAnotherPersonHandler}
                  />
                </Grid>
                <input
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  id="upload"
                  name="upload"
                  type="file"
                  accept=".pdf, .PDF"
                  onChange={fileSelected}
                />
              </form>
            </FormProvider>
          </Grid>
          {!isMobile && userProfile ? (
            <Box sx={{ width: '220px' }}>
              <Box pl={2}>
                <InfoBlock
                  status={status}
                  documents={documents}
                  userHistory={userHistory}
                  personToSign={personToSign}
                  createDocumentInfo={createDocumentInfo}
                  showLinkedBlock={documents && documents?.length >= 1}
                  onSignDocumentsClick={onSignCurrencyDocumentFiles}
                  onDeleteFile={onDeleteFile}
                  signDocumentsButtonEnabled={signDocumentsButtonEnabled}
                  userProfile={userProfile}
                  edit
                  uuid={uuid || ''}
                />
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Box>

      {showConfirmPopup ? (
        <ConfirmPopup
          open
          onConfirm={onConfirmLeaveHandler}
          onClose={onCancelLeaveHandler}
          questionText={t('confirmLeaveCreateExchangePopupMessage')}
          icon={<CloudIconSvg />}
        />
      ) : null}
    </>
  );
};

import React, { useState } from 'react';

import { Box, Button, Grid, Hidden, IconButton, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  CopyIcon,
  CsvIcon,
  DeleteIcon,
  EditIcon,
  PreviewIcon,
  SignatureIcon,
  XlsIcon,
  XmlIcon,
  PrintIcon,
  SendIcon,
  DownloadIcon,
  ArrowDownSelectSmallIcon,
  PdfIcon,
  MoreIcon,
} from '@app/common/icons';
import { InternalSwiftTotalAmountDto, RepaymentTypeEnum, SwiftOperationWithAvailableButtons } from '@app/core/api';
import { ConfirmEditOperationPopup } from '@app/core/components/ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { formatAmount } from '@app/core/utils';
import { DeleteSwiftOperationsDialog } from '@app/pages/swift/components/SwiftTable/components/DeleteSwiftOperations';
import { SignSwiftDocumentDialog } from '@app/pages/swift/components/SwiftTable/components/SignSwiftOperations';
import { getActiveButtonsConfig } from '@app/pages/swift/components/SwiftTable/components/TableToolbar/activeButtons';
import { styles } from '@app/pages/swift/components/SwiftTable/components/TableToolbar/styles';
import { SendToSignSwiftDocumentDialog } from '@app/pages/swift/components/SwiftTable/SendToSignSwiftOperations';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface TableToolbarProps {
  onDeleteDocumentSuccess(): void;
  selectedOperation: SwiftOperationWithAvailableButtons[];
  operationsCount?: number;
  totalAmounts?: InternalSwiftTotalAmountDto[];
  onSignDocumentSuccess(): void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  selectedOperation,
  operationsCount,
  onDeleteDocumentSuccess,
  totalAmounts,
  onSignDocumentSuccess,
}) => {
  const { permissions, swiftInfo } = useAppSelector((state): ProfileState => state.profile);
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const [showSendToSignDialog, setShowSendToSignDialog] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const onDeleteDocumentHandler = () => {
    setShowDeleteDocumentDialog(true);
    handleCloseMenu();
  };

  const onSubmitHandler = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onCancelDeleteDocument = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onDeleteDocumentSuccessHandler = () => {
    setShowDeleteDocumentDialog(false);
    onDeleteDocumentSuccess();
  };

  const onEditClickHandler = () => {
    const { uuid, signed } = selectedOperation[0];
    if (signed) {
      setShowConfirmEditPopup(true);
    } else {
      if (selectedOperation && swiftInfo.swiftBic !== selectedOperation[0].recipient.swiftBic) {
        navigate(RouteList.swift_edit.replace(':uuid', uuid));
      } else if (selectedOperation && swiftInfo.swiftBic === selectedOperation[0].recipient.swiftBic) {
        const urlTo = queryString.stringifyUrl({
          url: RouteList.loan_swift_edit,
          query: {
            lid: uuid,
            rt: RepaymentTypeEnum.Outstanding,
          },
        });
        navigate(urlTo);
      }
    }
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    const { uuid } = selectedOperation[0];
    if (selectedOperation && swiftInfo.swiftBic !== selectedOperation[0].recipient.swiftBic) {
      navigate(RouteList.swift_edit.replace(':uuid', uuid));
    } else if (selectedOperation && swiftInfo.swiftBic === selectedOperation[0].recipient.swiftBic) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.loan_swift_edit,
        query: {
          lid: uuid,
          rt: RepaymentTypeEnum.Outstanding,
        },
      });
      navigate(urlTo);
    }
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const onPreviewClickHandler = () => {
    if (selectedOperation) {
      const { uuid } = selectedOperation[0];
      navigate(RouteList.swift_show.replace(':uuid', uuid));
    }
  };

  const onCopyClickHandler = () => {
    handleCloseMenu();
    if (selectedOperation) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.swift_create,
        query: {
          copyFrom: selectedOperation[0].uuid,
        },
      });
      navigate(urlTo);
    }
  };

  const onSignClickHandler = () => {
    setShowSignatureDialog(true);
    handleCloseMenu();
  };

  const onSendToSignClickHandler = () => {
    setShowSendToSignDialog(true);
    handleCloseMenu();
  };

  const onCancelSendToSignDocument = () => {
    setShowSendToSignDialog(false);
  };

  const onSendToSignSuccessHandler = () => {
    setShowSendToSignDialog(false);
    onSignDocumentSuccess();
  };

  const onSignSuccessHandler = () => {
    setShowSignatureDialog(false);
    onSignDocumentSuccess();
  };

  const onCancelSignDocument = () => {
    setShowSignatureDialog(false);
  };

  const onPrintClickHandler = () => {
    handleCloseMenu();
    window.open(`${selectedOperation[0].link}`, '_blank');
  };

  const onDownloadHandlerClickHandler = () => {
    handleCloseMenu();
    window.open(`${selectedOperation[0].link}&format=download`, '_blank');
  };

  const handleOpenSubMenuButton = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const activeButtonsConfig = getActiveButtonsConfig(selectedOperation, permissions);

  return (
    <>
      <Box pb={1}>
        {selectedOperation && selectedOperation.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
          >
            <Grid item container alignItems="center" justifyContent="flex-start" wrap="nowrap">
              <Typography variant="body2" sx={styles.selectedCount}>
                {selectedOperation.length} {t('selected')}
              </Typography>
              <Box sx={styles.divider} />
              {appConfig.enableCurrencyOperationsDownloadButtons ? (
                <>
                  <Typography variant="body2" sx={styles.selectedCount}>
                    {selectedOperation.length} {t('selected')}
                  </Typography>
                  <Box sx={styles.divider} />
                  <IconButton
                    size="small"
                    color="default"
                    sx={{ ...styles.actionIcon, ...styles.selected }}
                    onClick={() => {}}
                  >
                    <CsvIcon />
                  </IconButton>
                  <Box sx={styles.divider} />
                  <IconButton
                    size="small"
                    color="default"
                    sx={{ ...styles.actionIcon, ...styles.selected }}
                    onClick={() => {}}
                  >
                    <XlsIcon />
                  </IconButton>
                  <Box sx={styles.divider} />
                  <IconButton
                    size="small"
                    color="default"
                    sx={{ ...styles.actionIcon, ...styles.selected }}
                    onClick={() => {}}
                  >
                    <XmlIcon />
                  </IconButton>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSignClickHandler}
                    startIcon={<SignatureIcon />}
                  >
                    <Hidden lgDown>
                      <Typography sx={styles.selectedText}>{t('sign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sendToSign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSendToSignClickHandler}
                    startIcon={<SendIcon />}
                  >
                    <Hidden lgDown>
                      <Typography sx={styles.selectedText}>{t('toSign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.edit ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onEditClickHandler} startIcon={<EditIcon />}>
                    {activeButtonsConfig.edit.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('change')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.view ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onPreviewClickHandler}
                    startIcon={<PreviewIcon />}
                  >
                    {activeButtonsConfig.view.short ? null : (
                      <Hidden xlDown>
                        <Typography sx={styles.selectedText}>{t('preview')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.copy ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onCopyClickHandler} startIcon={<CopyIcon />}>
                    {activeButtonsConfig.copy.short ? null : (
                      <Hidden xlDown>
                        <Typography sx={styles.selectedText}>{t('copy')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.delete ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onDeleteDocumentHandler}
                    startIcon={<DeleteIcon />}
                  >
                    {activeButtonsConfig.delete.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('delete')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.print ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onPrintClickHandler} startIcon={<PrintIcon />}>
                    {activeButtonsConfig.print.short ? null : (
                      <Hidden xlDown>
                        <Typography sx={styles.selectedText}>{t('print')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.download ? (
                <Button
                  variant="text"
                  sx={styles.buttonRoot}
                  onClick={onDownloadHandlerClickHandler}
                  startIcon={<DownloadIcon />}
                >
                  {activeButtonsConfig.download.short ? null : (
                    <Hidden xlDown>
                      <Typography sx={styles.selectedText}>{t('download')}</Typography>
                    </Hidden>
                  )}
                </Button>
              ) : null}
              {activeButtonsConfig.moreMenu ? (
                <>
                  <Button
                    variant="text"
                    sx={{
                      ...styles.buttonRoot,
                      '& .MuiButton-endIcon': styles.downloadButtonEndIcon,
                    }}
                    onClick={handleOpenSubMenuButton}
                    startIcon={<MoreIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('more')}</Typography>
                    </Hidden>
                  </Button>
                  <Popover
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {activeButtonsConfig.moreMenu.copy ? (
                      <>
                        <MenuItem onClick={onCopyClickHandler}>
                          <ListItemIcon>
                            <CopyIcon />
                          </ListItemIcon>
                          <Typography variant="inherit">{t('copy')}</Typography>
                        </MenuItem>
                      </>
                    ) : null}
                    {activeButtonsConfig.moreMenu.delete ? (
                      <>
                        <MenuItem onClick={onDeleteDocumentHandler}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <Typography variant="inherit">{t('delete')}</Typography>
                        </MenuItem>
                      </>
                    ) : null}
                    {activeButtonsConfig.moreMenu.print ? (
                      <>
                        <MenuItem onClick={onPrintClickHandler}>
                          <ListItemIcon>
                            <PrintIcon />
                          </ListItemIcon>
                          <Typography variant="inherit">{t('print')}</Typography>
                        </MenuItem>
                      </>
                    ) : null}
                    {activeButtonsConfig.moreMenu.download ? (
                      <MenuItem onClick={onDownloadHandlerClickHandler}>
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('download')}</Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="space-between" sx={styles.actionsContainer}>
            <Grid item container xs={6} alignItems="center" justifyContent="flex-start">
              {appConfig.enableCurrencyOperationsDownloadButtons ? (
                <>
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => {}}>
                    <CsvIcon />
                  </IconButton>
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => {}}>
                    <XlsIcon />
                  </IconButton>
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => {}}>
                    <XmlIcon />
                  </IconButton>
                </>
              ) : null}
            </Grid>
            <Grid item container xs={6} alignItems="center" justifyContent="flex-end">
              {operationsCount ? (
                <Grid item>
                  <Typography variant="body2" component="span" sx={styles.operationsCountTitle}>
                    {t('statementsTablePanelDocuments')}
                  </Typography>{' '}
                  <Typography variant="body2" component="span" sx={styles.operationsCount}>
                    {operationsCount}
                  </Typography>
                </Grid>
              ) : null}
              {totalAmounts && operationsCount ? (
                <>
                  <Typography variant="body2" component="span" sx={styles.totalAmountTitle}>
                    {t('statementsTablePanelTotalAmount')}
                  </Typography>
                  {totalAmounts?.map((amount, i) => {
                    return (
                      <Box pl={2} key={`totalAmounts_${i.toString()}`}>
                        <Typography variant="body2" component="span" sx={styles.totalAmount}>{` ${formatAmount(
                          amount.total
                        )}`}</Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={styles.totalAmountCurrency}
                        >{` ${amount.currency}`}</Typography>
                      </Box>
                    );
                  })}
                </>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Box>
      {showDeleteDocumentDialog ? (
        <DeleteSwiftOperationsDialog
          onSubmit={onSubmitHandler}
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          selectedDocuments={selectedOperation || []}
        />
      ) : null}
      {showSignatureDialog && selectedOperation ? (
        <SignSwiftDocumentDialog
          selectedDocuments={selectedOperation}
          onSubmit={onSignSuccessHandler}
          onCancelDeleteDocument={onCancelSignDocument}
        />
      ) : null}
      {showSendToSignDialog && selectedOperation ? (
        <SendToSignSwiftDocumentDialog
          selectedDocuments={selectedOperation}
          onSubmitSuccess={onSendToSignSuccessHandler}
          onCancel={onCancelSendToSignDocument}
        />
      ) : null}
      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

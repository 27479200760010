import React from 'react';

import { Grid, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { DateRange } from '@app/core/types';
import {
  BalanceColumn,
  CurrencyColumn,
  DateColumn,
  ActionColumn,
} from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable/ColumnContainer';
import {
  TurnoversFieldEnum,
  TurnoversRow,
} from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/types';

interface MakeTableColumnsProps {
  accountId: string;
  dateRange: DateRange;
  t(text: string): string;
}

export const makeTableColumns = ({
  t,
  accountId,
  dateRange,
}: MakeTableColumnsProps): ColumnDef<TurnoversRow, any>[] => {
  return [
    {
      accessorKey: TurnoversFieldEnum.date,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper<TurnoversRow> {...props}>
            <Typography variant="caption">{t('statementsTableColumn_operationDate')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <DateColumn>{getValue()}</DateColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.currency,
      header: (props) => (
        <Grid container justifyContent="center">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('statementsTableColumn_currency')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <CurrencyColumn>{getValue()}</CurrencyColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.openingBalance,
      header: (props) => (
        <Grid container justifyContent="flex-end">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('selectedAccount_inputBalance')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <BalanceColumn>{getValue()}</BalanceColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.deposit,
      header: (props) => (
        <Grid container justifyContent="flex-end">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('statementsTableColumn_deposit')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <BalanceColumn>{getValue()}</BalanceColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.withdrawal,
      header: (props) => (
        <Grid container justifyContent="flex-end">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('statementsTableColumn_withdrawal')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <BalanceColumn>{getValue()}</BalanceColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.closingBalance,
      header: (props) => (
        <Grid container justifyContent="flex-end">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('selectedAccount_outputBalance')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<TurnoversRow, string>) => {
        return <BalanceColumn>{getValue()}</BalanceColumn>;
      },
    },
    {
      accessorKey: TurnoversFieldEnum.action,
      header: () => <></>,
      cell: () => <ActionColumn accountId={accountId} dateRange={dateRange} />,
    },
  ];
};

import React from 'react';

import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const SystemDate: React.FC = () => {
  const { systemDate } = useAppSelector((state): ProfileState => state.profile);

  return <>{systemDate}</>;
};

import { UserSessionDto } from '@app/core/api';

export enum CompanyProfileMenuFilter {
  Data = 'Data',
  Rules = 'Rules',
  History = 'History',
  Tariff = 'Tariff',
  Users = 'Users',
  Settings = 'Settings',
}

export type CompanyVisitsGroupType = {
  date: string;
  visits: UserSessionDto[];
};

import React from 'react';

import { Switch as MuiSwitch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch/Switch';

import { styles } from './style';

export const Switch: React.FC<SwitchProps> = (props) => {
  return (
    <MuiSwitch
      sx={{
        'MuiSwitch-root': styles.root,
        'MuiSwitch-switchBase': styles.switchBase,
        'MuiSwitch-thumb': styles.thumb,
        'MuiSwitch-track': styles.track,
        'Mui-checked	': styles.checked,
      }}
      {...props}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { DepositDtoDepositTypeEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetDepositRecentOperations } from '@app/core/hooks/useGetDepositRecentOperations';
import { useOneGetDeposit } from '@app/core/hooks/useGetOneDeposit';
import { PaginationChangeType } from '@app/core/types';
import { DepositCard } from '@app/pages/deposits/show/components/DepositCard';
import { DepositOperationsTable } from '@app/pages/deposits/show/components/DepositOperationsTable';
import { DepositMenuItems } from '@app/pages/deposits/types';
import { RouteList } from '@app/src/constants/routeList';

export const DepositPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<DepositMenuItems>(DepositMenuItems.operations);
  const navigate = useNavigate();
  const { uuid = 'no-uuid' } = useParams();
  const { t } = useTranslation();
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { data: depositData, mutate } = useOneGetDeposit();
  const { data: recentOperationsData, mutate: mutateRecentOperations } = useGetDepositRecentOperations();

  const menuItems: MenuItem<DepositMenuItems>[] = [
    {
      id: 1,
      value: DepositMenuItems.operations,
      labelText: t('operations'),
    },
  ];

  useEffect(() => {
    mutate(uuid);
    mutateRecentOperations({
      uuid,
      pagination: paginationRef.current,
    });
  }, [mutate, mutateRecentOperations, uuid]);

  const paginationChangeHandler = (pagination: PaginationChangeType) => {
    paginationRef.current = pagination;
    mutateRecentOperations({
      uuid,
      pagination: paginationRef.current,
    });
  };

  const payClickHandler = () => {
    if (depositData) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.payment_create,
        query: {
          did: depositData.uuid,
        },
      });
      navigate(urlTo);
    }
  };

  const showTopUpDeposit =
    depositData?.depositType === DepositDtoDepositTypeEnum.DepositFacility && depositData.currency === 'UAH';

  const onTabChangeHandler = (tab: DepositMenuItems) => {
    setCurrentTab(tab);
  };

  return (
    <PageWrapper>
      <PageHeader title={`${t('deposit')} № ${depositData?.agreementNumber || ''}`} showNavigateButton backLink={-1}>
        {showTopUpDeposit ? (
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={payClickHandler}>
              {t('topUpDeposit')}
            </Button>
          </Box>
        ) : null}
      </PageHeader>
      <Grid container direction="column">
        {depositData ? <DepositCard deposit={depositData} /> : null}
        <Box mt={7}>
          <HorizontalMenu<DepositMenuItems> onChange={onTabChangeHandler} menuItems={menuItems} />
        </Box>
        <Box mt={4}>
          {currentTab === DepositMenuItems.operations && (
            <DepositOperationsTable
              data={recentOperationsData?.operations || []}
              onPaginationChange={paginationChangeHandler}
              pagination={recentOperationsData?.pagination}
            />
          )}
        </Box>
      </Grid>
    </PageWrapper>
  );
};

import React from 'react';

import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ModalPopupCommon } from '@app/core/components/Modal/ModalPopupCommon';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersModal/styles';
import { ReactComponent as CloseIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';

export interface DeleteUserModalProps {
  show: boolean;
  userId: number;
  closeUserModal?(): void;
  onClose(): void;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ show, userId, closeUserModal, onClose }) => {
  const { t } = useTranslation();

  const clearClose = () => {
    onClose();
  };

  const onSubmit = () => {
    if (closeUserModal) {
      closeUserModal();
    }
  };

  return (
    <ModalPopupCommon open={show} questionPaper>
      <>
        <Grid justifyContent="flex-end" container>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography component="div" variant="body1" sx={styles.captionSaveModal}>
          {t('user_delete_dialog')}
        </Typography>
        <Grid justifyContent="center" container>
          <Button variant="outlined" size="medium" color="primary" sx={styles.buttonSaveModal} onClick={clearClose}>
            {t('no')}
          </Button>
          <Button variant="contained" size="medium" color="primary" sx={styles.buttonSaveModal} onClick={onSubmit}>
            {t('yes')}
          </Button>
        </Grid>
      </>
    </ModalPopupCommon>
  );
};

import React from 'react';

import { FormControl, IconButton, MenuItem, Select as MuiSelect, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { setMonth, startOfMonth, setYear, format } from 'date-fns';
import { CaptionProps, useNavigation, useDayPicker } from 'react-day-picker';

import { ReactComponent as Left } from '@app/themes/default/assets/icons/16x16/chevron/left.svg';
import { ReactComponent as Right } from '@app/themes/default/assets/icons/16x16/chevron/right.svg';

import { defaultFromDate, defaultToDate } from './constants';
import { formatMonthCaption, getDropdownMonths, getDropdownYears } from './helpers';

export const YearMonthSelectCaption: React.FC<CaptionProps> = ({ displayMonth }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const { fromDate, toDate, locale } = useDayPicker();

  const months = getDropdownMonths({
    fromDate: fromDate || defaultFromDate,
    toDate: toDate || defaultToDate,
  });

  const years = getDropdownYears({
    fromDate: fromDate || defaultFromDate,
    toDate: toDate || defaultToDate,
  });

  const handleMonthChange = (e: SelectChangeEvent<number>) => {
    const selectedMonth = Number(e.target.value);
    const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);
    goToMonth(newMonth);
  };

  const handleYearChange = (e: SelectChangeEvent<number>) => {
    const newMonth = setYear(startOfMonth(displayMonth), Number(e.target.value));
    goToMonth(newMonth);
  };

  return (
    <Box className="caption">
      <IconButton
        size="small"
        type="button"
        className="month_switch"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <Left />
      </IconButton>

      <FormControl>
        <MuiSelect<number>
          variant="standard"
          disableUnderline
          className="month-year-select"
          name="months"
          onChange={handleMonthChange}
          value={displayMonth.getMonth()}
        >
          {months.map((month: Date) => (
            <MenuItem key={month.getMonth()} value={month.getMonth()}>
              {formatMonthCaption(month, locale)}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
      <FormControl>
        <MuiSelect
          variant="standard"
          disableUnderline
          className="month-year-select"
          name="years"
          onChange={handleYearChange}
          value={displayMonth.getFullYear()}
        >
          {years.map((year) => (
            <MenuItem key={year.getFullYear()} value={year.getFullYear()}>
              {format(year, 'yyyy', { locale })}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>

      <IconButton
        size="small"
        type="button"
        className="month_switch"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <Right className="arrow" style={{ fill: '#B3B8C0' }} />
      </IconButton>
    </Box>
  );
};

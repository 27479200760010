import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import {
  getAccessToken,
  getClientId,
  currencyDocumentsApiClient,
  ApiError,
  GetDocumentWithShortOperationsInfoResponseDto,
} from '@app/core/api';

const getCurrencyDocumentByUuidFn = async (uuid: string): Promise<GetDocumentWithShortOperationsInfoResponseDto> => {
  const result = await currencyDocumentsApiClient.documentsControllerGetDocument(getClientId(), uuid, getAccessToken());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the payment order");
  }
  return result.data;
};

export const useGetCurrencyDocumentByUuid = (
  uuid: string
): UseQueryResult<GetDocumentWithShortOperationsInfoResponseDto, ApiError> => {
  return useQuery<GetDocumentWithShortOperationsInfoResponseDto, ApiError>(
    'currency_getOneDocument',
    () => getCurrencyDocumentByUuidFn(uuid),
    {
      enabled: false,
    }
  );
};

import React, { useState } from 'react';

import { Box, Button, Collapse, FormControl, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, NotifySmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  FrontLanguageEnum,
  PaymentOrderStatusEnum,
  GetOneSwiftOperationResponseDto,
  GetOneSwiftOperationResponseDtoStatusEnum,
} from '@app/core/api';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useSendRemindSwiftSign } from '@app/core/hooks/useSendRemindSwiftSign';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftOrderHistory/style';
import { getActionTranslationKeyByActionTypeEnum } from '@app/pages/swift/SwiftPayment/helper';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

type PaymentInfoProps = {
  order: GetOneSwiftOperationResponseDto;
  edit: boolean;
};

export const SwiftOrderHistory: React.FC<PaymentInfoProps> = ({ order, edit }): JSX.Element => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { notify } = useNotify();

  const { mutate } = useSendRemindSwiftSign();

  const { status, signInfo, userHistory, createDocumentInfo, uuid } = order;
  const { personToSign } = signInfo;

  const sortedUserHistory = userHistory.sort((a, b) => a.id - b.id);

  const onclickHistoryHandler = () => {
    setHistoryOpen(!historyOpen);
  };

  const onNotifyButtonClick = () => {
    mutate(
      { uuid, language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          notify({
            notifyProps: {
              title: t('success'),
              message: t('remindSuccessMessage'),
              severity: 'success',
            },
          });
        },
        onError: () => {
          notify({
            notifyProps: {
              title: t('error'),
              message: t('remindErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };

  return (
    <Box>
      {userProfile ? (
        <PeopleToSignList
          status={status as unknown as PaymentOrderStatusEnum}
          signInfo={personToSign}
          userProfile={userProfile}
        />
      ) : null}
      {status === GetOneSwiftOperationResponseDtoStatusEnum.OnSigning && !edit ? (
        <>
          <Box mt={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={onNotifyButtonClick}
                endIcon={<NotifySmallIcon fontSize="small" />}
              >
                {t('paymentInfo_alarmNotifyButton')}
              </Button>
            </FormControl>
          </Box>
        </>
      ) : null}

      <Box pt={6}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={styles.historyHeaderContainer}
          className={!historyOpen ? 'close' : ''}
          onClick={onclickHistoryHandler}
        >
          <Typography variant="body2" component="span" sx={styles.historyTitle}>
            {t('paymentInfo_historyTitle')}
          </Typography>
          {historyOpen ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
        </Grid>
        <Collapse in={historyOpen} unmountOnExit={false}>
          <Box sx={styles.historyBodyContainer} p={4}>
            <Box>
              <Typography variant="caption" sx={styles.historyItemTitle}>
                {t('paymentInfo_documentCreated_title')}
              </Typography>
              <Box mt={2}>
                <Grid container justifyContent="space-between">
                  <Typography variant="caption" sx={styles.historyItemName}>
                    {t('paymentInfo_documentCreated_date')}
                  </Typography>
                  <Typography variant="caption" sx={styles.historyItemValue}>
                    {format(new Date(createDocumentInfo.createDocumentDate), FULL_DATE_TIME_FORMAT)}
                  </Typography>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container justifyContent="space-between">
                  <Typography variant="caption" sx={styles.historyItemName}>
                    {t('paymentInfo_documentCreated_creator')}
                  </Typography>
                  <Typography variant="caption" sx={styles.historyItemValue}>
                    {`${createDocumentInfo.lastName} ${createDocumentInfo.firstName}`}
                  </Typography>
                </Grid>
              </Box>
            </Box>

            {userHistory.length ? (
              <Box mt={4}>
                <Typography variant="caption" sx={styles.historyItemTitle}>
                  {t('paymentInfo_documentChanges_title')}
                </Typography>
                {sortedUserHistory.map((historyItem) => {
                  return (
                    <Box key={historyItem.id} mb={4}>
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_date')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {format(new Date(historyItem.createdAt), FULL_DATE_TIME_FORMAT)}
                          </Typography>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_creator')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {historyItem.lastName} {historyItem.firstName}
                          </Typography>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_changes')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {t(getActionTranslationKeyByActionTypeEnum(historyItem.actionType))}
                          </Typography>
                        </Grid>
                      </Box>
                      {historyItem?.signatureBelongsToUser ? (
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('signature')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {historyItem?.signatureBelongsToUser.lastName || ''}{' '}
                              {historyItem?.signatureBelongsToUser.firstName || ''}
                            </Typography>
                          </Grid>
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

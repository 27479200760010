import React from 'react';

import { FormControlLabel } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from '../../Checkbox';

interface CheckboxFieldProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  disabledValue?: boolean | undefined;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  defaultChecked,
  disabledValue,
  label,
  onChange,
  ...otherProps
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={({ field: { value, onChange: onChangeControl } }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                name={name}
                onChange={(e, checked) => {
                  onChangeControl(checked);
                  if (onChange) {
                    onChange(e, checked);
                  }
                }}
              />
            }
            label={label}
            disabled={Boolean(disabledValue)}
            {...otherProps}
          />
        );
      }}
    />
  );
};

import React from 'react';

import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { PageContent } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const PaymentsTemplatesPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('payments_templ')} showNavigateButton backLink={-1} useDesktopStyle>
          <Grid item>
            {permissions.uahTemplates.create ? (
              <Link to={RouteList.template_creation}>
                <Button variant="contained" color="primary">
                  {t('payments_templ_create_button')}
                </Button>
              </Link>
            ) : null}
          </Grid>
        </PageHeader>
        <PageContainer>
          <PageContent />
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

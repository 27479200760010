import { SortingRule } from '@app/core/types';

export type CountryCodesFields = {
  page?: number;
  rowsPerPage?: number;
  numeric?: string;
  alpha2?: string;
  alpha3?: string;
  name?: string;
  sortBy?: SortingRule;
};

export enum CountryCodesColumnEnum {
  id = 'id',
  numeric = 'numeric',
  alpha2 = 'alpha2',
  alpha3 = 'alpha3',
  name = 'name',
}

import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { OverdraftContent } from '@app/pages/overdrafts/components/OverdraftContent';
import { styles } from '@app/pages/overdrafts/style';

export const OverdraftsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('overdrafts')} useDesktopStyle />
        <PageContainer>
          <Grid container direction="column" wrap="nowrap" sx={styles.horizontalWrapper}>
            <OverdraftContent />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

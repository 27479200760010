export const styles = {
  captionFilter: {
    color: 'colors.primary600',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    marginLeft: '4px',
  },
  actionTableButtons: {
    '& svg': {
      fill: 'colors.primary500',
    },
    '& button': {
      marginRight: '8px',
    },
  },
  questionWrap: {
    paddingTop: '24px',
    maxWidth: '240px',
  },
  wrapper: {
    padding: '24px 20px',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
  },
  bankBranchSelect: {
    minWidth: '350px',
  },
};

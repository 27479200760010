import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, GetUnreadMessageCounterResponseDto } from '@app/core/api';

export const getUnreadMessagesCounterFn = async (): Promise<GetUnreadMessageCounterResponseDto> => {
  const result = await messagesApiClient.messagesControllerGetUnreadMessageCounter(getClientId());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get counter");
  }
  return result.data;
};

export const useGetUnreadMessagesCounter = (): UseMutationResult<GetUnreadMessageCounterResponseDto, ApiError, any> => {
  return useMutation<GetUnreadMessageCounterResponseDto, ApiError, any>(getUnreadMessagesCounterFn);
};

import React, { useEffect, useRef } from 'react';

import { Box, Button, CardActions, CardContent, FormLabel, Grid, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIcon, KeyIcon } from '@app/common/icons';
import { PaymentOrderErrorMessagesEnum } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { StatusText } from '@app/core/components/StatusText';
import { Tooltip } from '@app/core/components/Tooltip';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { makeCreateTemplateData } from '@app/pages/payment/components/StatePaymentForm/helpers';
import {
  FormFieldName,
  StatePaymentFormProps,
  StatePaymentFormData,
} from '@app/pages/payment/components/StatePaymentForm/types';
import { TaxesListSelect } from '@app/pages/payment/components/TaxesListSelect';
import { styles } from '@app/pages/payment/create/StatePayment/style';

export const DesktopStatePaymentForm: React.FC<StatePaymentFormProps> = ({
  defaultValues,
  showBalance,
  countedBalance,
  senderAccountsData,
  permissions,
  onSaveAndSignClickHandler,
  onSendToSign,
  onSaveClickHandler,
  onCancelHandler,
  notEnoughBalance,
  elsePersonCheckHandler,
  elsePersonCheck,
  taxesList,
  generatedOrderNumber,
  additionalInfo,
  errorSeverity,
  blockAction,
}) => {
  const { t } = useTranslation();
  const { getValues, control } = useFormContext<StatePaymentFormData>();
  const nowDate = new Date();
  const watchDocumentDate = useWatch({ control, name: FormFieldName.documentDate });
  const documentNumberRef = useRef<HTMLElement>(null);

  const createTemplateData = () => {
    return makeCreateTemplateData(getValues());
  };

  useEffect(() => {
    if (additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid sx={styles.formBody}>
      <Grid container sx={styles.documentNumber}>
        <Grid item xs={8} sx={styles.flexbox}>
          <Box ref={documentNumberRef}>
            <DocumentNumberField
              name={FormFieldName.documentNumber}
              title={t('paymentCreationLocalPayment_paymentOrder')}
              defaultValue={generatedOrderNumber || 'Auto123'}
              validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
              error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={styles.bookmarkBlock}>
          <SavePaymentTemplate createTemplateData={createTemplateData} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <CardContent sx={styles.cardContent}>
            <Grid container direction="column" wrap="nowrap">
              <Box mb={6}>
                <Grid container alignItems="flex-start">
                  <Box sx={{ width: '186px' }}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <DateSelectField
                        name={FormFieldName.documentDate}
                        selectedDate={nowDate}
                        // datePickerProps={{
                        //   disabled: { before: nowDate },
                        // }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: '186px' }}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
                    <Box pt={2} pl={1} pr={8}>
                      <DateSelectField
                        name={FormFieldName.documentValuationDate}
                        datePickerProps={{
                          disabled: {
                            before: watchDocumentDate || nowDate,
                            after: addDays(watchDocumentDate || nowDate, 29),
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Tooltip
                      color="primary"
                      title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                      arrow
                      placement="right"
                    >
                      <Box mt={9}>
                        <InfoIcon fontSize="small" sx={styles.info} />
                      </Box>
                    </Tooltip>
                  </Box>
                </Grid>
              </Box>
              <Box mb={5}>
                <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
                <Box pt={2}>
                  <AccountSelectField
                    placeholder={t('selectUahAccountPlaceholder')}
                    name={FormFieldName.senderAccount}
                    options={senderAccountsData?.accounts}
                    errorSeverity={errorSeverity}
                    defaultAccount={senderAccountsData?.defaultAccount}
                  />
                </Box>
              </Box>
              <Box sx={styles.hr} mb={5} />
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_recipientName')}</FormLabel>
                    <Box pt={2} pr={8} mb={3}>
                      <InputField
                        name={FormFieldName.recipientName}
                        placeholder={t('paymentCreationSatePayment_recipientNamePlaceholder')}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_recipientLei')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        name={FormFieldName.recipientLei}
                        placeholder={t('paymentCreationLocalPayment_edrpouPlaceholder')}
                        type={FormatType.number}
                        maxLength={8}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_recipientAccount')}</FormLabel>
                    <Box pt={2} pr={8} mb={3}>
                      <RecipientAccountSelect
                        name={FormFieldName.recipientAccount}
                        defaultValue={defaultValues?.recipientAccount}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_bankName')}</FormLabel>
                    <Box pt={2}>
                      <BankName
                        name={FormFieldName.recipientBankName}
                        iban={getValues(FormFieldName.recipientAccount)}
                        defaultValue={defaultValues?.recipientBankName}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_taxesList')}</FormLabel>
                    <Box pt={2} pr={8} mb={3}>
                      <TaxesListSelect
                        name={FormFieldName.paymentCode}
                        options={taxesList || []}
                        placeholder={t('paymentCreationSatePayment_taxesListPlaceholder')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationSatePayment_paymentInfo')}</FormLabel>
                    <Box pt={2}>
                      <TextAreaField
                        name={FormFieldName.paymentInfo}
                        textFieldProps={{
                          multiline: true,
                          fullWidth: true,
                          rows: 1,
                          placeholder: t('paymentCreationStatePayment_paymentInfoPlaceholder'),
                        }}
                        maxLength={416}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <CheckboxField
                      label={
                        <Typography variant="caption">
                          {t('paymentCreationSatePayment_elsePersonLeiFlagLabel')}
                        </Typography>
                      }
                      defaultChecked={false}
                      name={FormFieldName.elsePersonFlag}
                      sx={styles.transactionPresentedLabel}
                      onChange={elsePersonCheckHandler}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {elsePersonCheck ? (
                      <FormatInputField
                        name={FormFieldName.elsePersonLei}
                        placeholder={t('paymentCreationSatePayment_elsePersonLeiPlaceholder')}
                        type={FormatType.number}
                        maxLength={10}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                    <Box sx={styles.flexbox}>
                      <Box pt={2}>
                        <FormatInputField type={FormatType.amount} name={FormFieldName.amount} placeholder={'0.00'} />
                        {notEnoughBalance ? (
                          <StatusText severity="error" absolute>
                            {t('notEnoughBalance')}
                          </StatusText>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box pt={7} sx={styles.balance}>
                      <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                      <Typography sx={styles.balanceText}>{showBalance ? `${countedBalance} UAH` : '0.00'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Box>
              <Box mb={6}>
                <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
                <Box pt={2}>
                  <TextAreaField
                    name={FormFieldName.paymentReference}
                    textFieldProps={{
                      multiline: true,
                      fullWidth: true,
                      rows: 2,
                      disabled: true,
                    }}
                    maxLength={420}
                  />
                </Box>
              </Box>
            </Grid>
          </CardContent>
          <CardActions sx={styles.cardAction}>
            <Button color="primary" onClick={onCancelHandler}>
              {t('paymentCreationFormControl_cancel')}
            </Button>
            <Box sx={styles.cardAction}>
              <Button
                sx={styles.submitBtn}
                variant="outlined"
                color="primary"
                disabled={blockAction}
                onClick={onSaveClickHandler}
              >
                {t('paymentCreationFormControl_save')}
              </Button>
              {permissions.sign.paymentOrder.allowToSign ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSaveAndSignClickHandler}
                  disabled={notEnoughBalance || blockAction}
                >
                  {t('paymentCreationFormControl_send')}
                  <KeyIcon sx={styles.keyIcon} />
                </Button>
              ) : null}
              {permissions.sign.paymentOrder.allowToSendToSign ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSendToSign}
                  disabled={notEnoughBalance || blockAction}
                >
                  {t('paymentCreationFormControl_sendToSign')}
                </Button>
              ) : null}
            </Box>
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

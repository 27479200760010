import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, configurationApiClient, getClientId, GetDocumentNumbersConfigsResponseDto } from '@app/core/api';

const getNumberConfigQueryFn = async (): Promise<GetDocumentNumbersConfigsResponseDto> => {
  const response = await configurationApiClient.clientConfigControllerGetDocumentNumberConfigs(getClientId());
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant get number config!');
  }
  return response.data;
};

export const useGetNumberConfig = (): UseMutationResult<GetDocumentNumbersConfigsResponseDto, ApiError, any> => {
  return useMutation<GetDocumentNumbersConfigsResponseDto, ApiError, any>(getNumberConfigQueryFn);
};

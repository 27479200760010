import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { getCurrencyMenuItems } from '@app/core/utils';
import { AccountsListComponent } from '@app/pages/accounts/components/AccountsList';
import { FilterPanel } from '@app/pages/accounts/components/FilterPanel';
import { AccountsPageProps } from '@app/pages/accounts/types';
import { RouteList } from '@app/src/constants/routeList';

export const AccountsMobilePage: React.FC<AccountsPageProps> = ({
  onCurrencyMenuChangeHandler,
  activeAccountStatus,
  accountsByStatusCounts,
  accounts,
  onStatusFilterChangeHandler,
  onSearchChange,
  currencies,
}) => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        padding: 0,
      }}
    >
      <Box pl={4} pr={4}>
        <PageHeader
          title={t('allAccounts')}
          showNavigateButton
          backLink={-1}
          styles={{
            title: {
              color: 'colors.primaryTextColor',
            },
            burgerButton: {
              color: 'colors.primaryTextColor',
            },
          }}
        >
          <Link to={RouteList.payment_create}>
            <Button variant="contained" color="primary">
              {t('dashboardCreatePaymentBtn')}
            </Button>
          </Link>
        </PageHeader>
      </Box>
      <PageContainer direction="column">
        <>
          <Box pt={4} pb={4} pl={4} pr={4}>
            <HorizontalMenu menuItems={getCurrencyMenuItems(t, currencies)} onChange={onCurrencyMenuChangeHandler} />
          </Box>
          <Grid
            container
            direction="column"
            sx={{
              backgroundColor: 'white',
              padding: '0 16px 0 16px',
              borderRadius: '8px',
              minHeight: '85vh',
            }}
          >
            <Box
              component="div"
              pb={4}
              pt={6}
              sx={{
                width: '350px',
                overflow: 'scroll',
              }}
            >
              <FilterPanel
                activeCount={accountsByStatusCounts.active}
                blockedCount={accountsByStatusCounts.blocked}
                closedCount={accountsByStatusCounts.closed}
                selectItem={activeAccountStatus}
                onFilterChange={onStatusFilterChangeHandler}
                onSearchChange={onSearchChange}
              />
            </Box>
            {accounts.length ? <AccountsListComponent accounts={accounts} /> : null}
          </Grid>
        </>
      </PageContainer>
    </PageWrapper>
  );
};

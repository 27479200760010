import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, paymentOrdersApiClient, GetSignersResponseDto } from '@app/core/api';

export const getUahPaymentSignersFn = async (): Promise<GetSignersResponseDto> => {
  const result = await paymentOrdersApiClient.paymentOrderSignControllerGetSigners(getClientId());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the signers");
  }
  return result.data;
};

export const useGetUahPaymentSigners = (): UseQueryResult<GetSignersResponseDto> => {
  return useQuery<GetSignersResponseDto, ApiError>('peopleToSigne', getUahPaymentSignersFn, {
    enabled: true,
    refetchOnMount: true,
  });
};

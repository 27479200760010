export const styles = {
  root: {
    position: 'relative',
  },
  mainAccountBlock: {
    minHeight: '128px',
    padding: '18px 20px 18px 16px',
    marginBottom: '8px',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
  },
  accountLeft: {
    flex: 1.3,
  },
  accountLeftInfo: {
    position: 'relative',
    paddingLeft: '20px',
    width: '100%',
    borderLeft: `1px solid`,
    borderColor: 'colors.gray100',
    '@media (max-width: 1279.95px)': {
      borderLeft: 'none',
      paddingLeft: '5px',
    },
  },
  accountCenter: {
    flex: 1,
    minWidth: '336px',
  },
  accountRight: {
    flex: 1,
    textAlign: 'end',
  },
  accountNameText: {
    maxWidth: '217px',
  },
  shakeAnimate: {
    animation: 'tremor 1000ms ease-in-out normal none running infinite',
  },
  tableContainer: {
    width: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    borderSpacing: `0 1px`,
  },
  tableHeader: {},
  tableBody: {},
  tableFooter: {},
  row: {
    backgroundColor: 'colors.gray25',
    padding: '10px 16px',
    borderRadius: '8px',
  },
  editorInput: {
    width: '100%',
  },
  editorButton: {
    marginLeft: '4px',
    marginTop: '8px',
    padding: '4px',
  },
  editIcon: {
    color: 'colors.gray300',
  },
  editorIconDone: {
    color: 'colors.success',
  },
  editorIconCancel: {
    color: 'colors.gray200',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
};

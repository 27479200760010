export const styles = {
  root: {
    maxWidth: '630px',
  },
  item: {
    padding: '12px',
    borderBottom: `1px solid`,
    borderColor: 'colors.gray100',
  },
  editEmail: {
    marginLeft: 'auto',
    '& svg': {
      width: '16px',
      height: '16px',
      fill: 'colors.gray300',
    },
  },
  editorInput: {
    width: '100%',
    maxWidth: '270px',
  },
  editorButton: {
    marginLeft: '4px',
    marginTop: '4px',
    padding: '4px',
  },
  editorIconDone: {
    fill: 'colors.success',
  },
  editorIconCancel: {
    fill: 'colors.gray200',
  },
};

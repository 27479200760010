export const resetFormData = {
  operationAccounts: [],
  dateRangeSelect: '',
  subFilterDocumentNumber: '',
  // subFilterOperationDate: '',
  // subFilterDocumentDate: '',
  subFilterRecipientAccountName: '',
  subFilterLEI: '',
  subFilterPaymentReference: '',
  subFilterAccountNumber: '',
  // subFilterCurrency: '',
  subFilterDeposit: '',
  subFilterWithdrawal: '',
  subFilterBankCode: '',
  subFilterBankName: '',
  subFilterRecipientAccountNumber: '',
  amountMin: '',
  amountMax: '',
  LEI: '',
  counterPartyName: '',
  counterPartyAccountNumber: '',
  paymentReference: '',
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  getClientId,
  dashboardApiClient,
  ApiError,
  UpdateAccountConfigRequestDto,
  AccountsResponseDto,
} from '@app/core/api';

const submitSelectedAccountsQueryFn = async (
  selectAccountsData: UpdateAccountConfigRequestDto
): Promise<AccountsResponseDto> => {
  const response = await dashboardApiClient.dashboardControllerAccountConfig(getClientId(), selectAccountsData);
  return response.data;
};

export const useSubmitSelectedAccounts = (): UseMutationResult<
  AccountsResponseDto,
  ApiError,
  UpdateAccountConfigRequestDto
> => {
  return useMutation<AccountsResponseDto, ApiError, UpdateAccountConfigRequestDto>(submitSelectedAccountsQueryFn);
};

import React, { useRef, useState } from 'react';

import { Box, IconButton, Popover, Typography } from '@mui/material';
import { add, isBefore } from 'date-fns';
import { ActiveModifiers } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '@app/common/icons';
import { DateRangeDto } from '@app/core/api';
import { DatePicker } from '@app/core/components/Form/components/DatePicker';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';

type DateRange = {
  from: Date | undefined;
  to: Date | undefined;
  enteredTo?: Date | undefined;
};

interface SheetHeaderProps {
  setDateFilter(filter: DateRangeDto): void;
}

export const SheetHeader: React.FC<SheetHeaderProps> = ({ setDateFilter }) => {
  const nowDate = new Date();
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    enteredTo: undefined,
    from: undefined,
    to: undefined,
  });
  const anchorItemRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const onPopoverClose = () => {
    setCalendarOpen(false);
  };

  const onCalendarClickHandler = () => {
    setCalendarOpen(true);
  };

  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date) => {
    const isRangeSelected = from && to;
    return !from || isRangeSelected;
  };

  const onDayClickHandler = (date: Date, activeModifiers: ActiveModifiers) => {
    const { from, to } = dateRange;
    if (activeModifiers.disabled) {
      return;
    }
    const day = add(date, { hours: 12 });
    if (isSelectingFirstDay(from, to, day)) {
      setDateRange({
        from: day,
        to: undefined,
        enteredTo: day,
      });
    } else {
      const fromDate: Date = isBefore(day, from!) ? day : from!;
      const toDate: Date = isBefore(day, from!) ? from! : day;
      setDateRange({
        from: fromDate,
        to: toDate,
        enteredTo: toDate,
      });
      setDateFilter({ startDate: fromDate.toISOString(), endDate: toDate.toISOString() });
      setCalendarOpen(false);
    }
  };
  const { from, enteredTo } = dateRange!;
  const disabledDays = { after: nowDate, before: new Date(2018, 0, 1) };
  const selectedDays = { from, to: enteredTo };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '32px',
      }}
    >
      <IconButton
        sx={{
          color: 'colors.primary500',
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translate(0, -50%)',
        }}
        onClick={onCalendarClickHandler}
        size="small"
        ref={anchorItemRef}
      >
        <CalendarIcon />
      </IconButton>
      <Typography variant="body2" component="span" color="textSecondary">
        {dateRange.from && dateRange.to
          ? `${formatISODateString(dateRange.from.toISOString(), DATE_FORMAT)} - ${formatISODateString(
              dateRange.to.toISOString(),
              DATE_FORMAT
            )}`
          : null}
      </Typography>
      <Popover
        open={calendarOpen}
        anchorEl={anchorItemRef.current}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePicker
          mode="range"
          showOutsideDays
          onDayClick={onDayClickHandler}
          selected={selectedDays}
          disabled={disabledDays}
        />
      </Popover>
    </Box>
  );
};

import React, { useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  linearProgressClasses,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { ModalCommon } from '@app/core/components';

import { styles } from './styles';

const ProgressBar: React.FC<LinearProgressProps> = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  height: '8px',
  backgroundColor: theme.palette.colors.gray25,
  borderRadius: '20px',
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '20px',
    backgroundColor: theme.palette.colors.primary500,
  },
}));

type ProgressPopupProps = {
  current: number;
  total: number;
  beforeProgressText: string;
  afterProgressText?: string;
};

export const ProgressPopup: React.FC<ProgressPopupProps> = ({
  current,
  total,
  beforeProgressText,
  afterProgressText,
}) => {
  const { t } = useTranslation();
  const progress = (current * 100) / total;

  return (
    <ModalCommon open>
      <Box sx={styles.root}>
        <Box pb={8} width="100%">
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
            <Box sx={styles.titleContainer}>
              <Typography variant="body1" component="div" sx={styles.title}>
                {t('progressSigningTitle')}
              </Typography>
            </Box>
            {/*<IconButton size="small" onClick={onClose}>*/}
            {/*  <CrossIcon fontSize="small" />*/}
            {/*</IconButton>*/}
          </Grid>
        </Box>
        <Box sx={styles.progressContainer}>
          <Typography variant="body2" sx={styles.progressLabel}>
            {beforeProgressText}
          </Typography>
          <Box mt={2} mb={2}>
            <ProgressBar variant="determinate" value={progress} />
          </Box>
          <Typography variant="body2" sx={styles.progressCountLabel}>
            {afterProgressText}
          </Typography>
        </Box>
      </Box>
    </ModalCommon>
  );
};

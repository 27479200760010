import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import useInView from 'react-cool-inview';

import { useSheetState } from '@app/context/SheetContext';
import { Operation, OperationGroupType } from '@app/core/types';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { OperationCard } from '@app/pages/operations/mobile/components/OperationCard';
import { styles } from '@app/pages/operations/mobile/styles';

interface OperationGroupProps {
  operationsGroup: OperationGroupType;
  onCheck(document: Operation, selected: boolean): void;
}

export const OperationsGroup: React.FC<OperationGroupProps> = ({ operationsGroup, onCheck }) => {
  const { visibleGroupDates, setVisibleGroupDates } = useSheetState();

  const { observe } = useInView({
    onEnter: () => {
      setVisibleGroupDates([...visibleGroupDates, operationsGroup.date]);
    },
    onLeave: () => setVisibleGroupDates([...visibleGroupDates.filter((item) => item !== operationsGroup.date)]),
  });

  const cards = useMemo(() => {
    return operationsGroup.operations.map((operation, key) => (
      <OperationCard key={`${operation.uuid}_${key}`} operation={operation} onCheck={onCheck} />
    ));
  }, [onCheck, operationsGroup.operations]);

  return (
    <div ref={observe}>
      <Box mt={2} mb={2}>
        <Typography align="center" variant="body2" sx={styles.titleText}>
          {formatISODateString(operationsGroup.date, DATE_FORMAT)}
        </Typography>
      </Box>
      {cards}
    </div>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  accountsApiClient,
  ApiError,
  GetAccountsForCurrencyExchangeDto,
  GetAccountResponseDto,
  getClientId,
} from '@app/core/api';

const getAccountsForCurrencyExchangeFn = async (
  getAccountsForCurrencyExchangeDto?: GetAccountsForCurrencyExchangeDto
): Promise<GetAccountResponseDto[]> => {
  const result = await accountsApiClient.accountsControllerGetAccountsForCurrencyExchange(
    getClientId(),
    getAccountsForCurrencyExchangeDto
  );
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "accountsControllerGetAccountsForCurrencyExchange: Can't get users");
  }
  return result.data;
};

export const useGetAccountsForCurrencyExchange = (): UseMutationResult<
  GetAccountResponseDto[],
  ApiError,
  GetAccountsForCurrencyExchangeDto
> => {
  return useMutation<GetAccountResponseDto[], ApiError, GetAccountsForCurrencyExchangeDto>(
    getAccountsForCurrencyExchangeFn
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Button, FormLabel, Grid, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccountStatusEnum, CurrencyEnum, DefaultAccountTypeEnum, GetAccountResponseDto } from '@app/core/api';
import { useGetCurrentAccountsByCurrency } from '@app/core/hooks/useGetCurrentAccountsByCurrency';
import { useSetDefaultAccount } from '@app/core/hooks/useSetDefaultAccount';
import { parseJSON } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { AccountSelectField } from '@app/src/core/components';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { DefaultNationalityAccountFormData } from './types';

export const DefaultNationalityAccountSetting: React.FC = ({}) => {
  const { t } = useTranslation();
  const { data: senderAccountsData, mutate } = useGetCurrentAccountsByCurrency();
  const methods = useForm<DefaultNationalityAccountFormData>({ mode: 'onSubmit' });
  const { getValues, setValue, setError, formState, clearErrors, control } = methods;
  const [defaultAccountTitle, setDefaultAccountTitle] = useState<string>(t('accountSelected'));
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const watchDefaultAccount = useWatch({ control, name: 'operationAccounts' });

  useEffect(() => {
    mutate(CurrencyEnum.Uah);
  }, [mutate]);

  useEffect(() => {
    if (senderAccountsData && senderAccountsData.defaultAccount) {
      setValue('operationAccounts', JSON.stringify(senderAccountsData.defaultAccount));
      if (senderAccountsData.defaultAccount.account.status === AccountStatusEnum.Blocked) {
        setError('operationAccounts', { message: t('accountBlocked') });
        setDefaultAccountTitle(t('selectAnotherAccount'));
      }
      if (senderAccountsData.defaultAccount.account.status === AccountStatusEnum.Closed) {
        setError('operationAccounts', { message: t('accountClosed') });
        setDefaultAccountTitle(t('selectAnotherAccount'));
      }
    }
  }, [senderAccountsData, setError, setValue, t]);

  useEffect(() => {
    const selectedAccount: GetAccountResponseDto = parseJSON(watchDefaultAccount) as unknown as GetAccountResponseDto;
    if (!watchDefaultAccount || selectedAccount.account.status === AccountStatusEnum.Active) {
      clearErrors();
    }
  }, [clearErrors, watchDefaultAccount]);

  const { mutate: mutateDefaultAccount } = useSetDefaultAccount();

  const onSaveClickHandler = () => {
    mutateDefaultAccount(
      {
        accountId: getValues('operationAccounts') ? JSON.parse(getValues('operationAccounts')).account.id : undefined,
        type: DefaultAccountTypeEnum.UahOperation,
        currencyCode: CurrencyEnum.Uah,
      },
      {
        onSuccess: () => {
          mutate(CurrencyEnum.Uah);
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Grid container alignItems="center" sx={styles.root}>
          <Grid item container alignItems="center" justifyContent="space-between" sx={styles.rowAccounts}>
            <Grid>
              {senderAccountsData?.defaultAccount ? (
                <Typography variant="caption" sx={styles.accountNotSelectStatus}>
                  {defaultAccountTitle}
                </Typography>
              ) : (
                <Typography variant="caption" sx={styles.accountNotSelectStatus}>
                  {t('accountNotSelected')}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={formState.errors.operationAccounts ? styles.rowWithError : styles.row}
          >
            <Box sx={{ width: '100%' }}>
              <FormLabel component="legend">{t('accounts')}</FormLabel>
              <Grid container wrap="nowrap" justifyContent="space-between" pt={2}>
                <Grid item xs={10}>
                  <AccountSelectField
                    name="operationAccounts"
                    options={senderAccountsData?.accounts}
                    placeholder={t('paymentCreationSelectAccountPlaceholder')}
                    withEmpty
                    defaultAccount={senderAccountsData?.defaultAccount}
                  />
                </Grid>
                <Grid item container justifyContent="flex-end" xs={2}>
                  <Box>
                    <Button
                      sx={styles.submitBtn}
                      color="primary"
                      variant="contained"
                      disabled={!permissions.accounts.setDefaultAccount}
                      onClick={onSaveClickHandler}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

import React, { useEffect, useRef } from 'react';

import { Box, Grid, IconButton, TableSortLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ArrowDownSmallIcon } from '@app/common/icons';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableCell } from '@app/core/components/ComplexTable/components/TableCell';
import { TableRow } from '@app/core/components/ComplexTable/components/TableRow';
import { SortOrder } from '@app/core/types';
import { userKeysDataFn } from '@app/pages/profile/desktop/components/Keys/query';
import { styles } from '@app/pages/profile/desktop/components/Keys/styles';
import {
  KeyStatus,
  SecureColumnEnum,
  SecureKeyQueryConfigType,
  SecureKeyType,
} from '@app/pages/profile/desktop/components/Keys/types';
import { ReactComponent as CSVIcon } from '@app/themes/default/assets/icons/24x24/csv.svg';
import { ReactComponent as PDFIcon } from '@app/themes/default/assets/icons/24x24/pdf.svg';
import { ReactComponent as PrintIcon } from '@app/themes/default/assets/icons/24x24/print.svg';
import { ReactComponent as XSLIcon } from '@app/themes/default/assets/icons/24x24/xls.svg';
import { ReactComponent as XMLIcon } from '@app/themes/default/assets/icons/24x24/xml.svg';

export const Keys: React.FC = (): JSX.Element => {
  const columnSort = useRef<SecureColumnEnum>(SecureColumnEnum.secureColumnClosedDate);
  const orderSort = useRef<SortOrder>(SortOrder.asc);
  const queryConfig = useRef<SecureKeyQueryConfigType>();

  const { data, refetch } = useQuery<SecureKeyType[]>(
    'userKeysData',
    () => userKeysDataFn(queryConfig ? queryConfig.current : undefined),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleRequestSort = (name: SecureColumnEnum) => {
    if (columnSort.current === name) {
      const isAsc = orderSort.current === 'asc';
      orderSort.current = isAsc ? SortOrder.desc : SortOrder.asc;
    } else {
      columnSort.current = name;
      orderSort.current = SortOrder.asc;
    }
    queryConfig.current = { sort: { order: orderSort.current, column: name } };
    refetch();
  };

  const { t } = useTranslation();

  const topContent = (
    <Box mb={2} sx={styles.actionTableButtons}>
      <IconButton size="small">
        <CSVIcon />
      </IconButton>
      <IconButton size="small">
        <PDFIcon />
      </IconButton>
      <IconButton size="small">
        <XSLIcon />
      </IconButton>
      <IconButton size="small">
        <XMLIcon />
      </IconButton>
      <IconButton size="small">
        <PrintIcon />
      </IconButton>
    </Box>
  );

  const HeadContent = (
    <TableRow hover={false} sx={styles.tableRowHead}>
      <TableCell sx={{ ...styles.tableCellIdKey, ...styles.tableCell }}>
        <Typography variant="caption">{t('keyID')}</Typography>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Typography variant="caption">{t('keyType')}</Typography>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Typography
          variant="caption"
          sx={styles.sortName}
          onClick={() => handleRequestSort(SecureColumnEnum.secureColumnOpeningDate)}
        >
          <TableSortLabel
            IconComponent={ArrowDownSmallIcon}
            active={columnSort.current === SecureColumnEnum.secureColumnOpeningDate}
            direction={columnSort.current === SecureColumnEnum.secureColumnOpeningDate ? orderSort.current : 'asc'}
          >
            {t(SecureColumnEnum.secureColumnOpeningDate)}
          </TableSortLabel>
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Typography
          variant="caption"
          onClick={() => handleRequestSort(SecureColumnEnum.secureColumnActivateDate)}
          sx={styles.sortName}
        >
          <TableSortLabel
            IconComponent={ArrowDownSmallIcon}
            active={columnSort.current === SecureColumnEnum.secureColumnActivateDate}
            direction={columnSort.current === SecureColumnEnum.secureColumnActivateDate ? orderSort.current : 'asc'}
          >
            {t(SecureColumnEnum.secureColumnActivateDate)}
          </TableSortLabel>
        </Typography>
      </TableCell>
      <TableCell sx={{ ...styles.tableCellDate, ...styles.tableCell }}>
        <Typography
          variant="caption"
          onClick={() => handleRequestSort(SecureColumnEnum.secureColumnClosedDate)}
          sx={styles.sortName}
        >
          <TableSortLabel
            IconComponent={ArrowDownSmallIcon}
            active={columnSort.current === SecureColumnEnum.secureColumnClosedDate}
            direction={columnSort.current === SecureColumnEnum.secureColumnClosedDate ? orderSort.current : 'asc'}
          >
            {t(SecureColumnEnum.secureColumnClosedDate)}
          </TableSortLabel>
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Typography variant="caption">{t('keyStatus')}</Typography>
      </TableCell>
    </TableRow>
  );

  const BodyContent = (
    <>
      {data
        ? data.map((row) => {
            return (
              <TableRow hover={false} sx={styles.item} key={row.id}>
                <TableCell sx={styles.tableCellIdKey}>
                  <Typography variant="body2" sx={styles.fontMiddleWeight}>
                    {row.id}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography variant="body2">{row.type}</Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography variant="body2">{row.openingDate}</Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography variant="body2">{row.activateDate}</Typography>
                </TableCell>
                <TableCell sx={{ ...styles.tableCellDate, ...styles.tableCell }}>
                  <Typography variant="body2" sx={styles.fontMiddleWeight}>
                    {row.closedDate}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Grid container alignItems="center">
                    {row.status === KeyStatus.active ? (
                      <Box mr={1} sx={styles.statusKeyActive} />
                    ) : (
                      <Box mr={1} sx={styles.statusKeyClose} />
                    )}
                    <Typography variant="caption" color="textSecondary">
                      {t(`${row.status}_status`)}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })
        : null}
    </>
  );

  return (
    <>
      {data ? (
        <ComplexTable
          top={{
            content: topContent,
          }}
          head={{
            content: HeadContent,
          }}
          body={{
            content: BodyContent,
          }}
        />
      ) : null}
    </>
  );
};

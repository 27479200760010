import React from 'react';

import { Grid, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { RatesByCurrency } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { BankRatesColumnEnum } from '@app/pages/exchangeRate/types';

interface TableColumnProps {
  t(text: string): string;
}

export const makeTableColumns = ({ t }: TableColumnProps): ColumnDef<RatesByCurrency, any>[] => {
  return [
    {
      accessorKey: BankRatesColumnEnum.date,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t('exchangeRateTableColumn_date')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
    },
    {
      accessorKey: BankRatesColumnEnum.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('exchangeRateTableColumn_currency')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: BankRatesColumnEnum.currencyCode,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('exchangeRateTableColumn_currencyCode')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: BankRatesColumnEnum.currencyName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('exchangeRateTableColumn_currencyName')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: BankRatesColumnEnum.exchangeRateBuy,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('exchangeRateTableColumn_exchangeRateBuy')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: BankRatesColumnEnum.exchangeRateSell,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('exchangeRateTableColumn_exchangeRateSell')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
  ];
};

export const styles = {
  root: {
    borderRadius: '50px',
    padding: '2px 8px',
    height: '20px',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    marginRight: '8px',
  },
  toSign: {
    border: `1px solid #F1A40D`,
    backgroundColor: '#fefbef',
    color: '#F1A40D',
  },
  rejected: {
    border: `1px solid`,
    borderColor: 'colors.error',
    backgroundColor: '#FAF0F1',
    color: 'colors.error',
  },
  executed: {
    border: `1px solid`,
    borderColor: 'colors.success',
    backgroundColor: '#EFF9EE',
    color: 'colors.success',
  },
  saved: {
    border: `1px solid #F1A40D`,
    backgroundColor: '#fefbef',
    color: '#F1A40D',
  },
  onSigning: {
    border: `1px solid #F1A40D`,
    backgroundColor: '#fefbef',
    color: '#F1A40D',
  },
  processing: {
    border: `1px solid #F1A40D`,
    backgroundColor: '#fefbef',
    color: '#F1A40D',
  },
};

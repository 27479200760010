import React from 'react';

import { useTranslation } from 'react-i18next';

import { UserDtoSystemAccessEnum } from '@app/core/api';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { SelectOptionType } from '@app/core/components/Select';

export type UsersAccessPermissionsSelectProps = {
  name: string;
  defaultValue?: UserDtoSystemAccessEnum;
};

export const UsersAccessPermissionsSelect: React.FC<UsersAccessPermissionsSelectProps> = ({ name, defaultValue }) => {
  const { t } = useTranslation();
  const permissionsListItems: SelectOptionType[] = [
    {
      id: t(`user_${UserDtoSystemAccessEnum.MonitoringRole}`),
      name: t(`user_${UserDtoSystemAccessEnum.MonitoringRole}`),
      value: UserDtoSystemAccessEnum.MonitoringRole,
    },
    {
      id: t(`user_${UserDtoSystemAccessEnum.Creator}`),
      name: t(`user_${UserDtoSystemAccessEnum.Creator}`),
      value: UserDtoSystemAccessEnum.Creator,
    },
  ];

  return (
    <SelectField
      fullWidth
      name={name}
      options={permissionsListItems}
      defaultValue={
        defaultValue
          ? permissionsListItems[permissionsListItems.findIndex((item) => item.value === defaultValue)].value
          : permissionsListItems[0].value
      }
    />
  );
};

export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: '70px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
  table: {
    flexWrap: 'nowrap',
  },
  searchActive: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: '4px',
  },
  activeSearchAlign: {
    display: 'flex',
    flexDirection: 'row!important' as 'row',
  },
  cell_swift: {
    maxWidth: '320px',
    minWidth: '100px',
  },
  cell_location: {
    maxWidth: '320px',
    minWidth: '140px',
  },
  cell_name: {
    minWidth: '340px',
  },
  cell_actions: {
    maxWidth: '40px',
  },
};

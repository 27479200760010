export const styles = {
  movementContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 20px',
    backgroundColor: 'colors.primary25',
    borderRadius: '8px',
    '& > :not(:last-child)': {
      borderBottom: `1px solid`,
      borderColor: 'colors.gray50',
    },
  },
  movementItem: {
    padding: '6px 0',
  },
  totalHeader: {
    fontSize: '0.75rem',
    color: 'colors.gray400',
  },
  deposit: {
    color: 'colors.success',
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  withdrawal: {
    color: 'colors.error',
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    right: '80px',
    position: 'absolute',
    marginTop: '-60px',
    marginBottom: '16px',
  },
  icon: {
    color: 'colors.primary500',
  },
  label: {
    color: 'colors.primary500',
    marginRight: '4px',
  },
  from: {
    marginRight: '16px',
    width: '125px',
  },
  to: {
    marginRight: '26px',
    width: '125px',
  },
  button: {
    padding: 0,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
};

export const styles = {
  caption: {
    fontWeight: 700,
  },
  captionSaveModal: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
  },
  buttonSaveModal: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  closedCaption: {
    position: 'relative',
    fontSize: '0.75rem',
    color: 'colors.gray200',
    marginBottom: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 0,
      width: '100%',
      height: '1px',
      borderBottom: `1px dashed`,
      borderColor: 'colors.gray50',
    },
    '& span': {
      position: 'relative',
      zIndex: 1,
      backgroundColor: 'white',
    },
  },
  sendButton: {
    marginTop: '20px',
    '& > span': {
      fontWeight: 600,
    },
  },
  wrapHeight: {
    maxHeight: '50%',
    overflow: 'auto',
  },
  isDragging: {
    backgroundColor: 'colors.primary25',
  },
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, ButtonGroup, ClickAwayListener, Grid, Grow, Paper, Popper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { DownChevronIcon, KeyIcon, SignatureIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import {
  ApiError,
  AddImportOrdersRequestDto,
  CreatePaymentOrderResultDto,
  ImportOrderDto,
  ImportOrdersResponseDto,
  ImportOrderWithValidationDto,
  CreateStatusEnum,
} from '@app/core/api';
import { ConfirmPopup } from '@app/core/components/ConfirmPopup';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { ImportPaymentsDialog } from '@app/core/components/ImportPaymentsDialog';
import { paymentOrderImportOrdersFn } from '@app/core/components/ImportPaymentsDialog/query';
import { StatusLargePopup } from '@app/core/components/StatusLargePopup';
import { CurrencyEnum } from '@app/core/types';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import { ConfirmSendToSignImportOrders } from '@app/pages/payment/import/components/ConfirmSendToSignImportOrders';
import { ConfirmSignImportOrders } from '@app/pages/payment/import/components/ConfirmSignImportOrders';
import { ImportResultTable } from '@app/pages/payment/import/components/ImportResultTable';
import { SignImportPayment } from '@app/pages/payment/import/components/SignImportPayment';
import { MenuItemsEnum } from '@app/pages/payment/import/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';
import { ReactComponent as CloudIconSvg } from '@app/themes/default/assets/icons/cloud.svg';

export const PaymentImportPage: React.FC = () => {
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<MenuItemsEnum>(MenuItemsEnum.all);
  const [showImportDialog, setShowImportDialog] = useState<boolean>(true);
  const [showSuccessImportModal, setShowSuccessImportModal] = useState<boolean>(false);
  const [showErrorImportModal, setShowErrorImportModal] = useState<boolean>(false);
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [orders, setOrders] = useState<Array<ImportOrderWithValidationDto>>();
  const [importOrders, setImportOrders] = useState<ImportOrdersResponseDto>();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openAnotherPerson, setOpenAnotherPerson] = useState(false);
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const [showConfirmSignOrdersDialog, setShowConfirmSignOrdersDialog] = useState<boolean>(false);
  const [showConfirmSendToSignOrdersDialog, setShowConfirmSendToSignOrdersDialog] = useState<boolean>(false);

  const linkForInstruction =
    'https://storage.googleapis.com/crystal-dgb-assets/instructions/Функціонал "Імпорт платежів".pdf';

  const {
    data: importedOrdersData,
    mutate,
    isLoading,
    status,
  } = useMutation<Array<CreatePaymentOrderResultDto>, ApiError, AddImportOrdersRequestDto>(paymentOrderImportOrdersFn);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const menuItems: MenuItem<MenuItemsEnum>[] = [
    {
      id: 1,
      labelText: `${t('paymentImport_menuItemAll')} (${importOrders?.totalCount || 0})`,
      value: MenuItemsEnum.all,
    },
    {
      id: 2,
      labelText: `${t('paymentImport_menuItemCorrect')} (${importOrders?.correctCount || 0})`,
      value: MenuItemsEnum.correct,
    },
    {
      id: 3,
      labelText: `${t('paymentImport_menuItemError')} (${importOrders?.invalidCount || 0})`,
      value: MenuItemsEnum.error,
    },
  ];

  const saveOrders = () => {
    if (importOrders && importOrders.correctOrders) {
      const ordersToSubmit: Array<ImportOrderDto> = importOrders.correctOrders.map((order) => {
        return {
          ...order.data,
        };
      });

      mutate(
        { orders: ordersToSubmit, status: CreateStatusEnum.Saved },
        { onSuccess: () => setShowSuccessImportModal(true) }
      );
    }
  };

  const onCancelPopup = () => {
    setShowImportDialog(false);
    navigate(-1);
  };

  useEffect(() => {
    if (status === 'error') {
      setShowErrorImportModal(true);
    }
  }, [status]);

  const onCancelButtonHandler = () => {
    setLeavePopupOpen(true);
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(-1);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  useEffect(() => {
    if (importOrders) {
      switch (selectedMenu) {
        case MenuItemsEnum.correct:
          setOrders(importOrders.correctOrders);
          setCount(importOrders.correctCount);
          setAmount(importOrders.totalCorrectOrdersAmount);
          break;
        case MenuItemsEnum.error:
          setOrders(importOrders.invalidOrders);
          setCount(importOrders.invalidCount);
          setAmount(importOrders.totalInvalidOrdersAmount);
          break;
        case MenuItemsEnum.all:
        default:
          setOrders([
            ...(importOrders.correctOrders ? importOrders.correctOrders : []),
            ...(importOrders.invalidOrders ? importOrders.invalidOrders : []),
          ]);
          setCount(importOrders.totalCount);
          setAmount(importOrders.totalOrdersAmount);
          break;
      }
    }
  }, [importOrders, selectedMenu]);

  const handleMenuChange = (menuItem: MenuItemsEnum): void => {
    setSelectedMenu(menuItem);
  };

  const onImportSuccessResultHandler = (importResponse: ImportOrdersResponseDto) => {
    setShowImportDialog(false);
    setImportOrders(importResponse);
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessImportModal(false);
    navigate(-1);
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorImportModal(false);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorImportModal(false);
    saveOrders();
  };

  const onDeleteDocumentsHandler = (ordersForDelete: ImportOrderWithValidationDto[]) => {
    if (importOrders) {
      const newImportOrders: ImportOrdersResponseDto = { ...importOrders };
      const uuidsForDelete: string[] = ordersForDelete.map((order) => order.data.uuid);

      if (importOrders.correctOrders) {
        newImportOrders.correctOrders = importOrders.correctOrders.reduce((acc, order) => {
          if (uuidsForDelete.includes(order.data.uuid)) {
            newImportOrders.totalCorrectOrdersAmount -= order.data.amount;
            newImportOrders.totalOrdersAmount -= order.data.amount;
            newImportOrders.correctCount -= 1;
            newImportOrders.totalCount -= 1;
          } else {
            acc.push(order);
          }

          return acc;
        }, [] as ImportOrderWithValidationDto[]);
      }

      if (importOrders.invalidOrders) {
        newImportOrders.invalidOrders = importOrders.invalidOrders.reduce((acc, order) => {
          if (uuidsForDelete.includes(order.data.uuid)) {
            newImportOrders.totalInvalidOrdersAmount -= order.data.amount;
            newImportOrders.invalidCount -= 1;
            newImportOrders.totalOrdersAmount -= order.data.amount;
            newImportOrders.totalCount -= 1;
          } else {
            acc.push(order);
          }

          return acc;
        }, [] as ImportOrderWithValidationDto[]);
      }

      setImportOrders(newImportOrders);
    }
  };

  const onSaveAndSignClick = () => {
    if (importOrders && importOrders.correctOrders) {
      const ordersToSubmit: Array<ImportOrderDto> = importOrders.correctOrders.map((order) => {
        return {
          ...order.data,
        };
      });

      mutate(
        { orders: ordersToSubmit, status: CreateStatusEnum.Saved },
        { onSuccess: () => setShowConfirmSignOrdersDialog(true) }
      );
    }
  };

  const onSendToSignClick = () => {
    if (importOrders && importOrders.correctOrders) {
      const ordersToSubmit: Array<ImportOrderDto> = importOrders.correctOrders.map((order) => {
        return {
          ...order.data,
        };
      });

      mutate(
        { orders: ordersToSubmit, status: CreateStatusEnum.Saved },
        { onSuccess: () => setShowConfirmSignOrdersDialog(true) }
      );
    }
  };

  const onCloseSignModal = () => {
    setShowSignModal(false);
  };

  const onSignSuccessHandler = () => {
    setShowSignModal(false);
    navigate(-1);
  };

  const handleToggle = () => {
    setOpenAnotherPerson(!openAnotherPerson);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
  };

  const onCloseConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignOrdersDialog(false);
  };

  const onCloseConfirmSendToSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignOrdersDialog(false);
  };

  const onAgreeConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignOrdersDialog(false);
    setShowSignModal(true);
  };

  const onSuccessSendToSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSendToSignOrdersDialog(false);
    navigate(-1);
  };

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader
          title={t('paymentImport_pageHeaderTitle')}
          showNavigateButton
          showLeavePopup
          confirmQuestionText={t('confirmLeaveImportPaymentsPopupMessage')}
          backLink={-1}
        >
          <Grid container justifyContent="flex-end" alignItems="center">
            <Box mr={3}>
              <Button variant="outlined" color="primary" onClick={onCancelButtonHandler}>
                {t('paymentImport_cancelButtonText')}
              </Button>
            </Box>
            {}
            <ButtonGroup variant="contained" ref={anchorRef}>
              <Button sx={styles.signButton} variant="contained" color="primary" onClick={saveOrders}>
                {t('paymentImport_saveButtonText')}
              </Button>
              <Button size="small" onClick={handleToggle}>
                <DownChevronIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={openAnotherPerson}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-end"
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'right top',
                  }}
                >
                  <Paper sx={{ marginBottom: '8px' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <>
                        {permissions.sign.paymentOrder.allowToSign && (
                          <Button variant="text" color="primary" onClick={onSaveAndSignClick}>
                            {t('paymentImport_saveAndSignButtonText')}
                            <KeyIcon sx={styles.keyIcon} />
                          </Button>
                        )}
                        {permissions.sign.paymentOrder.allowToSendToSign && (
                          <Button variant="text" color="primary" onClick={() => {}}>
                            {t('paymentCreationFormControl_sendToSign')}
                            <KeyIcon sx={styles.keyIcon} />
                          </Button>
                        )}
                      </>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </PageHeader>
        <PageContainer>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{
              width: '100%',
            }}
          >
            <Box pb={4} mt={7}>
              <Grid container justifyContent="space-between">
                <HorizontalMenu<MenuItemsEnum> onChange={handleMenuChange} menuItems={menuItems} />
              </Grid>
            </Box>
            {orders ? (
              <ImportResultTable
                count={count}
                amount={amount}
                currency={CurrencyEnum.UAH}
                orders={orders}
                onDeleteDocuments={onDeleteDocumentsHandler}
              />
            ) : null}
          </Grid>
        </PageContainer>
      </Grid>

      {showImportDialog ? (
        <ImportPaymentsDialog
          show
          onCancel={onCancelPopup}
          onImportSuccessResult={onImportSuccessResultHandler}
          linkForInstruction={linkForInstruction}
        />
      ) : null}

      <ConfirmPopup
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onClose={onCancelLeaveHandler}
        questionText={t('confirmLeaveImportPaymentsPopupMessage')}
        icon={<CloudIconSvg />}
      />

      <StatusLargePopup
        show={showSuccessImportModal}
        text={t('importPaymentsDialog_success_import_message', { count: importedOrdersData?.length })}
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusLargePopup
        show={showErrorImportModal}
        text={t('importPaymentsDialog_error_import_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
      {showConfirmSignOrdersDialog && importedOrdersData && importOrders ? (
        <ConfirmSignImportOrders
          selectedCount={importOrders.totalCount}
          toSignDocuments={importedOrdersData}
          onClose={onCloseConfirmSignPaymentMultipleOrderDialog}
          onAgreeSign={onAgreeConfirmSignPaymentMultipleOrderDialog}
        />
      ) : null}
      {showConfirmSendToSignOrdersDialog && importedOrdersData && importOrders ? (
        <ConfirmSendToSignImportOrders
          selectedDocuments={importOrders.totalCount}
          toSignDocuments={importedOrdersData}
          onClose={onCloseConfirmSendToSignPaymentMultipleOrderDialog}
          onSubmitSuccess={onSuccessSendToSignPaymentMultipleOrderDialog}
        />
      ) : null}
      {importedOrdersData && showSignModal ? (
        <SignImportPayment
          onClose={onCloseSignModal}
          onSuccessPopupCancel={onSignSuccessHandler}
          operations={importedOrdersData}
        />
      ) : null}
    </PageWrapper>
  );
};

import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { BankRates } from '@app/pages/exchangeRate/components/BankRates';
import { BankBranchSelect } from '@app/pages/exchangeRate/components/BankRates/components/BankBranchSelect';
import { NbuRates } from '@app/pages/exchangeRate/components/NbuRates';
import { ExchangeSource } from '@app/pages/exchangeRate/types';

export const PageContent: React.FC = () => {
  const [currentBank, setCurrentBank] = useState<ExchangeSource>(ExchangeSource.nbu);
  const [branch, setBranch] = useState<string>('1300');
  const [bankBranchError, setBankBranchError] = useState<boolean>(false);
  const { t } = useTranslation();

  const onFilterChangeHandler = (filter: ExchangeSource) => {
    setCurrentBank(filter);
  };

  const onBankBranchChange = (bankBranch: string) => {
    setBranch(bankBranch);
    setBankBranchError(false);
  };

  const menuItems: MenuItem<ExchangeSource>[] = [
    {
      id: 1,
      value: ExchangeSource.nbu,
      labelText: t(`exchange_tab_${ExchangeSource.nbu}`),
    },
    {
      id: 2,
      value: ExchangeSource.bank,
      labelText: t(`exchange_tab_${ExchangeSource.bank}`),
    },
  ];

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{
        height: '100%',
      }}
    >
      <Grid container>
        <Grid item container>
          <Box pt={1} mr={3}>
            <HorizontalMenu<ExchangeSource> onChange={onFilterChangeHandler} menuItems={menuItems} />
          </Box>
          <Box>
            {currentBank === ExchangeSource.bank ? (
              <BankBranchSelect error={bankBranchError} setBranch={onBankBranchChange} />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {currentBank === ExchangeSource.nbu && <NbuRates />}
      {currentBank === ExchangeSource.bank && <BankRates bankBranch={branch} setBankBranchError={setBankBranchError} />}
    </Grid>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { CrossIcon, ErrorSmallIcon } from '@app/common/icons';
import { EditedAccountNameDto, GetAccountResponseDto } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { Loader } from '@app/core/components/Loader';
import { ModalPopupCommon } from '@app/core/components/Modal/ModalPopupCommon';
import { StrictModeDroppable } from '@app/core/components/StrictModeDroppable';
import { onDragEndHandler } from '@app/core/helpers';
import { useScreenOrientation } from '@app/core/hooks';
import { useGetAllAccounts } from '@app/core/hooks/useGetAllAccounts';
import { useSubmitSelectedAccounts } from '@app/core/hooks/useSubmitSelectedAccounts';
import { CurrencyEnum } from '@app/core/types';
import { getCurrencyMenuItems, isMobile } from '@app/core/utils';
import { AccountBalanceItem } from '@app/pages/dashboard/view/PageContent/AccountBalances/AccountBalancesModal/AccountBalanceItem';
import { useStyles } from '@app/src/pages/dashboard/view/PageContent/AccountBalances/AccountBalancesModal/styles';

export interface AccountBalancesModalProps {
  show: boolean;
  onSave(): void;
  onClose(): void;
}

export const AccountBalancesModal: React.FC<AccountBalancesModalProps> = ({ show, onSave, onClose }) => {
  const currencyFilter = useRef<CurrencyEnum | null>(null);
  const { data, mutate: getAllAccountsMutate, isLoading: isLoadingAccountsList } = useGetAllAccounts();
  const { mutate, isLoading, isSuccess } = useSubmitSelectedAccounts();
  const [accountsOrderedList, setAccountsOrderedList] = useState<GetAccountResponseDto[]>([]);

  useEffect(() => {
    getAllAccountsMutate(undefined);
  }, [getAllAccountsMutate]);

  const { t } = useTranslation();
  const [edited, setEdited] = useState<EditedAccountNameDto[]>([]);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [orders, setOrders] = useState<string[]>([]);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);

  const initialFavoritesArray = (accounts: GetAccountResponseDto[]) => {
    return accounts.reduce((acc, account) => {
      if (account.account.favorite) {
        return [...acc, account.account.id];
      }
      return acc;
    }, [] as string[]);
  };

  useEffect(() => {
    if (data) {
      setAccountsOrderedList(data.accounts);
      setOrders(data.accounts.map((item) => item.account.id));
      setFavorites(initialFavoritesArray(data.accounts));
    }
  }, [data, setOrders]);

  const onDragEnd = (result: DropResult) => {
    onDragEndHandler<GetAccountResponseDto>(result, accountsOrderedList, (newState) => {
      setAccountsOrderedList(newState);
      setOrders(newState.map((item) => item.account.id));
    });
  };

  const updateEditsNames = (accountId: string, editName: string) => {
    setEdited((prevState: EditedAccountNameDto[]) => [
      ...prevState.filter((item) => item.id !== accountId),
      { id: accountId, name: editName },
    ]);
  };
  const updateSelectedDocuments = (accountId: string, selected: boolean) => {
    if (selected) {
      setFavorites((prevState) => [...prevState, accountId]);
    } else {
      setFavorites((prevState) => prevState.filter((item) => item !== accountId));
    }
  };
  const handleEdithName = (id: string, newName: string) => {
    updateEditsNames(id, newName);
  };
  const handleAddToFavorites = (id: string, selected: boolean) => {
    updateSelectedDocuments(id, selected);
  };

  const [alertAnimation, setAlertAnimation] = useState<boolean>(false);
  const timeForAnimation = 1000;

  const equalsAccountLists = (a: GetAccountResponseDto[], b: GetAccountResponseDto[]) =>
    JSON.stringify(a) === JSON.stringify(b);

  const onHandleShow = () => {
    if (favorites.length < 1) {
      onClose();
    } else if (
      data &&
      (edited.length ||
        initialFavoritesArray(data.accounts).length !== favorites.length ||
        !equalsAccountLists(data.accounts, accountsOrderedList))
    ) {
      setShowSaveModal(true);
    } else {
      onClose();
    }
  };

  const clearClose = () => {
    onClose();
  };

  const onSubmit = () => {
    if (favorites.length < 1) {
      setAlertAnimation(true);
      setTimeout(() => setAlertAnimation(false), timeForAnimation);
    } else {
      mutate({ edited, favorites, orders });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onSave();
    }
  }, [isSuccess, onSave]);

  const onMenuChangeHandler = (filter: CurrencyEnum) => {
    currencyFilter.current = filter;
    getAllAccountsMutate(undefined);
  };

  const { isPortrait } = useScreenOrientation();

  const isMobilePortrait = isMobile && isPortrait;

  const classes = useStyles(timeForAnimation);

  const renderAccountsList: GetAccountResponseDto[] = accountsOrderedList.filter((item) => {
    return currencyFilter.current ? item.account.currency === currencyFilter.current : true;
  });

  return (
    <ModalPopupCommon open={show} questionPaper={showSaveModal}>
      {showSaveModal ? (
        <>
          <Grid justifyContent="flex-end" container>
            <IconButton aria-label="close" size="small" onClick={() => setShowSaveModal(false)}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Typography component="div" variant="body1" sx={classes.captionSaveModal}>
            {t('corrections_massage')}
          </Typography>
          <Grid justifyContent="center" container>
            <Button variant="outlined" size="medium" color="primary" sx={classes.buttonSaveModal} onClick={onSubmit}>
              {t('no')}
            </Button>
            <Button variant="contained" size="medium" color="primary" sx={classes.buttonSaveModal} onClick={clearClose}>
              {t('yes')}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Box pb={5}>
            {isMobilePortrait ? (
              <>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">{t('settings')}</Typography>
                  <IconButton size="small" onClick={onHandleShow}>
                    <CrossIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Box sx={classes.hr} mt={4} mb={5} />
                <HorizontalMenu
                  menuItems={getCurrencyMenuItems(t, data?.currencies || [])}
                  onChange={onMenuChangeHandler}
                />
              </>
            ) : (
              <Grid container justifyContent="space-between" alignItems="center">
                <HorizontalMenu
                  menuItems={getCurrencyMenuItems(t, data?.currencies || [])}
                  onChange={onMenuChangeHandler}
                />
                <IconButton size="small" onClick={onHandleShow}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Box>
          {data ? (
            <>
              {favorites.length < 1 && !isLoadingAccountsList && data ? (
                <Box mt={-2} mb={3}>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ ...classes.alertZeroFavorite, ...(alertAnimation && classes.tremorAnimate) }}
                  >
                    <ErrorSmallIcon fontSize="small" sx={classes.errorIcon} />
                    <Typography variant="caption" sx={classes.alertZeroFavoriteText}>
                      {t('min_selected_accounts')}
                    </Typography>
                  </Grid>
                </Box>
              ) : null}

              {accountsOrderedList ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                      <Box {...provided.droppableProps} ref={provided.innerRef} sx={classes.wrapHeight}>
                        {renderAccountsList.map((balance, index) => {
                          const presentInEdited = edited.find((item) => item.id === balance.account.id);
                          return (
                            <Draggable
                              draggableId={balance.account.id.toString()}
                              key={balance.account.id}
                              index={index}
                            >
                              {(providedDraggable, snapshotDraggable) => (
                                <div {...providedDraggable.draggableProps} ref={providedDraggable.innerRef}>
                                  <AccountBalanceItem
                                    key={balance.account.id}
                                    data={balance}
                                    selected={favorites.includes(balance.account.id as never)}
                                    editedValue={presentInEdited ? presentInEdited.name : undefined}
                                    onEditName={handleEdithName}
                                    onAddToFavorites={handleAddToFavorites}
                                    providedHandleProps={providedDraggable.dragHandleProps || undefined}
                                    styles={snapshotDraggable.isDragging ? classes.isDragging : undefined}
                                    isDragDisabled={Boolean(currencyFilter.current)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              ) : null}
            </>
          ) : null}

          <Grid justifyContent="flex-end" container>
            <Button variant="contained" size="small" color="primary" sx={classes.sendButton} onClick={onSubmit}>
              {t('save')}
              {isLoading ? (
                <Loader
                  styles={{
                    right: '-40px',
                    top: '75px',
                    left: 'auto',
                    zoom: 0.3,
                    '& div > div': {
                      background: 'white',
                    },
                  }}
                />
              ) : null}
            </Button>
          </Grid>
        </>
      )}
    </ModalPopupCommon>
  );
};

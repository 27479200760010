import React from 'react';

import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';

import { styles } from './style';
import { ModalPopupCommon } from '../../../../../Modal';

interface ConfirmTableConfigPopupProps {
  onClose(): void;
  onSubmit(): void;
}

export const ConfirmTableConfigPopup: React.FC<ConfirmTableConfigPopupProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalPopupCommon open questionPaper>
      <>
        <Grid justifyContent="flex-end" container>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Typography component="div" variant="body1" sx={styles.captionSaveModal}>
          {t('corrections_massage')}
        </Typography>
        <Grid justifyContent="center" container>
          <Button variant="outlined" size="medium" color="primary" sx={styles.buttonSaveModal} onClick={onClose}>
            {t('no')}
          </Button>
          <Button variant="contained" size="medium" color="primary" sx={styles.buttonSaveModal} onClick={onSubmit}>
            {t('yes')}
          </Button>
        </Grid>
      </>
    </ModalPopupCommon>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserStateStatusEnum } from '@app/core/api';
import { Switch } from '@app/core/components/Form/components/Switch';
import { Loader } from '@app/core/components/Loader';
import { useGetCompanyUsers } from '@app/core/hooks';
import { ColumnFilterType, SortingRule } from '@app/core/types';
import { CompanyUsersTableColumnsEnum } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/types';
import { SettingsUsersTable } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/styles';

export const ProfileSettingsItemUsers: React.FC = () => {
  const { t } = useTranslation();
  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);
  const columnFilter = useRef<ColumnFilterType<CompanyUsersTableColumnsEnum>>({});
  const [activeUsersEnabled, setActiveUsersEnabled] = useState<boolean>(false);
  const { data, mutate, isLoading } = useGetCompanyUsers();

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const updateList = () => {
    mutate({ ...columnFilter.current });
  };

  const onSortHandler = (rule: SortingRule) => {
    updateList();
  };

  const users = activeUsersEnabled
    ? data?.users.filter((user) => user.status === UserStateStatusEnum.Active)
    : data?.users;

  const onActiveUsersSwitch = () => {
    setActiveUsersEnabled(!activeUsersEnabled);
  };

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<CompanyUsersTableColumnsEnum>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    if (submit) {
      updateList();
    }
  };

  const onSearchButtonClickHandler = () => {
    setShowSubFilters(true);
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    updateList();
  };

  const onCloseSubFilterHandler = () => {
    setShowSubFilters(false);
  };

  return (
    <>
      {!users || !data || isLoading ? (
        <Box
          sx={{
            position: 'relative',
            marginTop: '20px',
            width: '100%',
            height: '150px',
          }}
        >
          {isLoading ? <Loader /> : null}
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item container xs={6} alignItems="center">
                <Typography
                  variant="body2"
                  sx={activeUsersEnabled ? styles.switchTextInactive : styles.switchTextActive}
                >
                  {t('all')}
                </Typography>
                <Box pl={1}>
                  <Switch checked={activeUsersEnabled} onChange={onActiveUsersSwitch} />
                </Box>
                <Typography
                  variant="body2"
                  sx={activeUsersEnabled ? styles.switchTextActive : styles.switchTextInactive}
                >
                  {t('active')}
                </Typography>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
                <Typography variant="caption" color="textSecondary">
                  {t('user_length')}
                </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '4px', color: 'colors.primary700', fontWeight: 600 }}>
                  {data.activeCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <SettingsUsersTable
            data={users}
            onSort={onSortHandler}
            columnFilter={columnFilter.current}
            showSubFilters={showSubFilters}
            onColumnFilterChange={onColumnFilterChangeHandler}
            onSearchButtonClick={onSearchButtonClickHandler}
            onClearSubFilter={onClearSubFilterHandler}
            onCloseSubFilter={onCloseSubFilterHandler}
          />
        </>
      )}
    </>
  );
};

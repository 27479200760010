export const styles = {
  captionFilter: {
    color: 'colors.primary600',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    marginLeft: '4px',
  },
  fullWidth: {
    width: `100%`,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
};

import { DocumentStatus, Operation } from '../../../../types';

export enum OperationFieldEnum {
  documentNumber = 'documentNumber',
  executionDate = 'executionDate',
  documentDate = 'documentDate',
  counterpartyName = 'counterpartyName',
  counterpartyAccount = 'counterpartyAccount',
  counterpartyLei = 'counterpartyLei',
  counterpartyBankCode = 'counterpartyBankCode',
  counterpartyBank = 'counterpartyBank',
  paymentReference = 'paymentReference',
  accountNumber = 'accountNumber',
  amount = 'amount',
  currency = 'currency',
  credit = 'credit',
  debit = 'debit',
  status = 'status',
  arcDate = 'arcDate',
}

export type OperationRow = {
  documentNumber: string;
  executionDate?: string;
  documentDate: string;
  counterpartyName: string;
  counterpartyAccount: string;
  counterpartyLei: string;
  counterpartyBankCode: string;
  counterpartyBank: string;
  paymentReference: string;
  accountNumber: string;
  currency: string;
  amount: string;
  credit: string;
  debit: string;
  operation: Operation;
  status: DocumentStatus;
  arcDate?: string | null;
};

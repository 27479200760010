export type TableColumnConfig<T = string> = {
  columnType: T;
  enabled: boolean;
  order: number;
  edit: boolean;
};

export type ColumnFilterType<T extends keyof any> = {
  [key in T]?: any;
};

export enum TableToolbarActionsEnum {
  sign = 'sign',
  multiSign = 'multiSign',
  signFromAnother = 'signFromAnother',
  multiSignFromAnother = 'multiSignFromAnother',
  sendToSign = 'sendToSign',
  edit = 'edit',
  view = 'view',
  cancel = 'cancel',
  download = 'download',
  copy = 'copy',
  template = 'template',
  print = 'print',
  printMultiple = 'printMultiple',
  delete = 'delete',
}

export type TableToolbarBtnConfType = {
  short: boolean;
};

export type TableToolbarActiveButtonsConfig = {
  [key in TableToolbarActionsEnum]?: TableToolbarBtnConfType;
} & {
  moreMenu?: {
    [key in TableToolbarActionsEnum]?: boolean;
  };
};

export type TableToolbarEnabledActionsType = {
  [TableToolbarActionsEnum.sign]?: boolean;
  [TableToolbarActionsEnum.multiSign]?: boolean;
  [TableToolbarActionsEnum.sendToSign]?: boolean;
  [TableToolbarActionsEnum.edit]?: boolean;
  [TableToolbarActionsEnum.view]?: boolean;
  [TableToolbarActionsEnum.cancel]?: boolean;
  [TableToolbarActionsEnum.download]?: boolean;
  [TableToolbarActionsEnum.copy]?: boolean;
  [TableToolbarActionsEnum.template]?: boolean;
  [TableToolbarActionsEnum.print]?: boolean;
  [TableToolbarActionsEnum.printMultiple]?: boolean;
  [TableToolbarActionsEnum.delete]?: boolean;
  [TableToolbarActionsEnum.signFromAnother]?: boolean;
  [TableToolbarActionsEnum.multiSignFromAnother]?: boolean;
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CategoryInfoDto,
  ChangeStatusRequestDtoStatusEnum,
  ExtendedMessageDto,
  MessageStatus,
  MessageType,
  PaginationResponseDto,
  ResultResponseDto,
} from '@app/core/api';
import { SelectOptionType } from '@app/core/components';
import { ComplexTable, TablePagination } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { Loader } from '@app/core/components/Loader';
import {
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { useChangeMessagesStatus, useSendMessagesToBank } from '@app/core/hooks';
import { useGetUnreadMessagesCounter } from '@app/core/hooks/useGetUnreadMessagesCounter';
import { ColumnFilterType, PaginationChangeType, SortingRule } from '@app/core/types';
import { SendToBankMessagesDialog } from '@app/pages/letters/LettersListContent/LettersTable/components/SendToBankMessagesDialog';
import { SendToSignMessagesDialog } from '@app/pages/letters/LettersListContent/LettersTable/components/SendToSignMessagesDialog';
import { SignMessageDialog } from '@app/pages/letters/LettersListContent/LettersTable/components/SignMessgeDialog';
import { LettersSubFilterEnum } from '@app/pages/letters/types';
import { updateUnreadMessagesCounter } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppDispatch } from '@app/src/store';

import { makeTableColumns } from './components/columns';
import { DeleteMessagesDialog } from './components/DeleteMessagesDialog';
import { styles } from './components/styles';
import { TableToolbar } from './components/TableToolbar';

export interface LettersTableProps {
  messages: ExtendedMessageDto[];
  messageType: MessageType;
  pagination?: PaginationResponseDto;
  loading?: boolean;
  columnFilter: ColumnFilterType<LettersSubFilterEnum>;
  categoryList: CategoryInfoDto[];
  showStatusSubFilter: boolean;
  messageStatusOptionsItems: SelectOptionType[];
  emptyMessageTable: string;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onColumnFilterChange(updater: ColumnFilterType<LettersSubFilterEnum>, submit?: boolean): void;
  updateList(): void;
  onClearSubFilter(): void;
}

export const LettersTable: React.FC<LettersTableProps> = ({
  messages,
  messageType,
  pagination,
  loading,
  columnFilter,
  categoryList,
  showStatusSubFilter,
  messageStatusOptionsItems,
  onSort,
  onPaginationChange,
  onColumnFilterChange,
  updateList,
  emptyMessageTable,
  onClearSubFilter,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notify } = useNotify();
  const { showLoader } = useUIState();
  const dispatch = useAppDispatch();
  const [showSignMessagesDialog, setShowSignMessagesDialog] = useState(false);
  const [showSendToSignMessagesDialog, setShowSendToSignMessagesDialog] = useState(false);
  const [showSendToBankMessagesDialog, setShowSendToBanknMessagesDialog] = useState(false);
  const [showDeleteMessagesDialog, setShowDeleteMessagesDialog] = useState(false);
  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);
  const { mutateAsync: mutateAsyncChangeMessageStatus, isLoading: isLoading2 } = useChangeMessagesStatus();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return messages;
  }, [messages]);
  const { mutate } = useGetUnreadMessagesCounter();

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        showSubFilters,
        t,
        categoryList,
        showStatusSubFilter,
        messageStatusOptionsItems,
        onSearchButtonClick() {
          setShowSubFilters(true);
        },
        onCloseSubFilter() {
          setShowSubFilters(false);
        },
        onClearSubFilter,
        onLetterLinkClick(uuid: string) {
          navigate(RouteList.letters_show.replace(':uuid', uuid));
        },
      }),
    ];
  }, [categoryList, messageStatusOptionsItems, navigate, showStatusSubFilter, showSubFilters, t, onClearSubFilter]);

  const table = useReactTable<ExtendedMessageDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: messages.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    meta: {
      columnFilterSate: {
        columnFilter,
        onColumnFilterChange,
      },
    },
  });

  useEffect(() => {
    if (messages.length && table.getIsSomeRowsSelected()) {
      table.resetRowSelection();
    }
  }, [messages, table]);

  useEffect(() => {
    showLoader(isLoading2);
  }, [isLoading2, showLoader]);

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const selectedMessages: ExtendedMessageDto[] = table
    .getSelectedRowModel()
    .flatRows.map((operationRow) => operationRow.original);

  const onDeleteHandler = () => {
    setShowDeleteMessagesDialog(true);
  };

  const onDeleteSuccessHandler = () => {
    setShowDeleteMessagesDialog(false);
    updateList();
  };

  const onCancelDeleteMessagesHandler = () => {
    setShowDeleteMessagesDialog(false);
  };

  const onMarkAsReadHandler = () => {
    const messagesToMarkAsRead = selectedMessages.filter((item) => item.status === MessageStatus.New);
    mutateAsyncChangeMessageStatus({
      status: ChangeStatusRequestDtoStatusEnum.Read,
      uuids: messagesToMarkAsRead.map((mess) => mess.uuid),
    })
      .then((result: ResultResponseDto) => {
        mutate(
          {},
          {
            onSuccess: (res) => {
              dispatch(updateUnreadMessagesCounter(res.count));
            },
          }
        );

        if (result.errors && result.errors.length) {
          notify({
            notifyProps: {
              title: t('error'),
              severity: 'error',
            },
          });
          return;
        }
        updateList();
      })
      .catch((e) => {
        notify({
          notifyProps: {
            title: t('error'),
            severity: 'error',
          },
        });
      });
  };

  const onSendToBankHandler = () => {
    setShowSendToBanknMessagesDialog(true);
  };

  const onSendToBankSuccess = () => {
    setShowSendToBanknMessagesDialog(false);
    updateList();
  };

  const onCloseSendToBankPopupHandler = () => {
    setShowSendToBanknMessagesDialog(false);
  };

  const onSignHandler = () => {
    setShowSignMessagesDialog(true);
  };

  const onSendToSignHandler = () => {
    setShowSendToSignMessagesDialog(true);
  };

  const onSendToSignSuccess = () => {
    updateList();
    setShowSendToSignMessagesDialog(false);
  };

  const onCloseSendToSignMessagesPopupHandler = () => {
    setShowSendToSignMessagesDialog(false);
  };

  const onEditHandler = () => {
    navigate(RouteList.letters_edit.replace(':uuid', selectedMessages[0].uuid));
  };

  const onSuccessSignMessagesCancelHandler = () => {
    setShowSignMessagesDialog(false);
    updateList();
  };

  const onCloseSignMessagesPopupHandler = () => {
    setShowSignMessagesDialog(false);
  };

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    if (!messages.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t(emptyMessageTable)}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<ExtendedMessageDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <>
      <Grid container direction="column" wrap="nowrap" sx={styles.root}>
        <TableToolbar
          selectedMessages={selectedMessages}
          messageType={messageType}
          onDelete={onDeleteHandler}
          onMarkAsRead={onMarkAsReadHandler}
          onSendToBank={onSendToBankHandler}
          onSign={onSignHandler}
          onSendToSign={onSendToSignHandler}
          onEdit={onEditHandler}
        />
        <ComplexTable
          head={{ content: <TableHeadContent<ExtendedMessageDto> {...{ table, styles }} /> }}
          body={{ content: buildBodyContent() }}
          footer={{
            content: (
              <TablePagination
                totalCount={pagination?.totalCount || 0}
                page={pagination?.page || 1}
                rowsPerPage={pagination?.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
                labelRowsPerPage={t('showOnPageShort')}
                rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
                onChange={onPaginationChange}
                show={showPagination}
              />
            ),
          }}
        />
      </Grid>
      {showSignMessagesDialog ? (
        <SignMessageDialog
          selectedMessages={selectedMessages}
          onSuccessPopupCancel={onSuccessSignMessagesCancelHandler}
          onClose={onCloseSignMessagesPopupHandler}
        />
      ) : null}
      {showSendToSignMessagesDialog ? (
        <SendToSignMessagesDialog
          selectedMessages={selectedMessages}
          onSuccessPopupCancel={onSendToSignSuccess}
          onClose={onCloseSendToSignMessagesPopupHandler}
        />
      ) : null}
      {showSendToBankMessagesDialog ? (
        <SendToBankMessagesDialog
          selectedMessages={selectedMessages}
          onSuccessPopupCancel={onSendToBankSuccess}
          onClose={onCloseSendToBankPopupHandler}
        />
      ) : null}
      {showDeleteMessagesDialog ? (
        <DeleteMessagesDialog
          selectedMessages={selectedMessages}
          onDeleteSuccess={onDeleteSuccessHandler}
          onCancelDelete={onCancelDeleteMessagesHandler}
        />
      ) : null}
    </>
  );
};

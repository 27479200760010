import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ColumnSort, getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { LoanSortingFieldsEnum, UserSessionDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
} from '@app/core/constants';
import { PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/columns';
import {
  buildTableRowsData,
  VisitsRow,
} from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/helpers';
import { styles } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/style';

interface TableContentProps {
  visitsData: Array<UserSessionDto>;
  pagination?: TablePaginationType;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onClearSubFilter(): void;
}

export const VisitsTable: React.FC<TableContentProps> = ({
  visitsData,
  pagination,
  onSort,
  onPaginationChange,
  onClearSubFilter,
}) => {
  const { t } = useTranslation();
  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return buildTableRowsData(visitsData, t);
  }, [t, visitsData]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        showSubFilters,
        t,
        onSearchButtonClick: () => setShowSubFilters(true),
        onCloseSubFilter: () => setShowSubFilters(false),
        onClearSubFilter,
      }),
    ];
  }, [showSubFilters, t, onClearSubFilter]);

  const table = useReactTable<VisitsRow>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: visitsData.length > 0,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as LoanSortingFieldsEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const BodyContent: JSX.Element[] = table.getRowModel().rows.map((row, i) => {
    return <TableRowContent<VisitsRow> key={row.id} row={row} styles={styles} />;
  });

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);
  return (
    <ComplexTable
      head={{ content: <TableHeadContent<VisitsRow> {...{ table, styles }} /> }}
      body={{ content: BodyContent }}
      footer={{
        content: (
          <TablePagination
            totalCount={pagination?.totalCount || 0}
            page={pagination?.page || DEFAULT_FIRST_PAGE}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            labelRowsPerPage={t('showOnPageShort')}
            rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
            onChange={onPaginationChange}
            show={showPagination}
          />
        ),
      }}
    />
  );
};

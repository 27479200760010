import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StarFilledIcon, StarIcon } from '@app/common/icons';
import { CreateTemplateRequestDto, DocumentTemplateDto } from '@app/core/api';
import { SaveTemplateDialog } from '@app/core/components/SaveTemplateDialog';
import { cropName } from '@app/core/utils';
import { PaymentFormData } from '@app/pages/payment/components/LocalPaymentEditForm/types';

interface SavePaymentTemplateProps {
  showText?: boolean;
  createTemplateData(): Omit<CreateTemplateRequestDto, 'templateName'>;
}

export const SavePaymentTemplate: React.FC<SavePaymentTemplateProps> = ({ showText = true, createTemplateData }) => {
  const { trigger } = useFormContext<PaymentFormData>();
  const { t } = useTranslation();

  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(false);
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');

  const onClickHandler = () => {
    trigger().then((valid: boolean) => {
      if (valid) {
        setShowSaveTemplateDialog(true);
      }
    });
  };

  const onCancelSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  const onSuccessSaveTemplateHandler = (saveResultTemplateData: DocumentTemplateDto) => {
    setShowSaveTemplateDialog(false);
    setTemplateName(saveResultTemplateData.templateName);
    setShowNewName(true);
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        {showText ? (
          <Box
            component="span"
            sx={{
              fontSize: '1rem',
              color: 'colors.gray300',
              marginRight: '14px',
            }}
          >
            {cropName(showNewName ? templateName : t('addToTemplate'), 17)}
          </Box>
        ) : null}
        {showNewName ? (
          <StarFilledIcon
            sx={{
              color: 'colors.warning',
              cursor: 'pointer',
            }}
          />
        ) : (
          <StarIcon
            onClick={onClickHandler}
            sx={{
              color: 'colors.gray300',
              cursor: 'pointer',
            }}
          />
        )}
      </Grid>
      {showSaveTemplateDialog ? (
        <SaveTemplateDialog
          open
          documentTemplateData={createTemplateData()}
          onCancel={onCancelSaveTemplateHandler}
          onSuccessSaveTemplate={onSuccessSaveTemplateHandler}
        />
      ) : null}
    </>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DocumentTemplateDto, TemplateSortingRuleDto } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetPaymentTemplates } from '@app/core/hooks';
import { PaginationChangeType } from '@app/core/types';
import { DeleteTemplatePopup } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/DeleteTemplatePopup';
import { PaymentsTemplatesFilter } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesFilter';
import { PaymentsTemplatesTable } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesTable';
import { PaymentsTemplatesFilterFormData } from '@app/pages/paymentsTemplates/PaymentsTemplates/types';
import { validationSchema } from '@app/pages/paymentsTemplates/PaymentsTemplates/validationSchema';

export const PageContent: React.FC = () => {
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [templateUuid, setTemplateUuid] = useState<string | undefined>(undefined);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { data, mutate, isLoading } = useGetPaymentTemplates();
  const { t } = useTranslation();
  const sortRuleRef = useRef<TemplateSortingRuleDto | undefined>();
  const methods = useForm<PaymentsTemplatesFilterFormData>({
    resolver: yupResolver(validationSchema(t)),
    mode: 'onChange',
  });
  const { handleSubmit, getValues, reset: formReset } = methods;

  const submitFilter = () => {
    const { lei, amountMin, amountMax, templateName } = getValues();

    mutate({
      lei: lei.length ? lei : undefined,
      amountMin: amountMin.length ? amountMin : undefined,
      amountMax: amountMax.length ? amountMax : undefined,
      templateName: templateName.length ? templateName : undefined,
      pagination: paginationRef.current,
      orderBy: sortRuleRef.current,
    });
  };

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
    });
  }, [mutate]);

  const templates: Array<DocumentTemplateDto> = data?.templates || [];

  const onSubmit = () => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    submitFilter();
  };

  const resetFormHandler = () => {
    formReset({
      templateName: '',
      lei: '',
      amountMin: '',
      amountMax: '',
    });
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitFilter();
    }
  };

  const onSortHandler = (rule: TemplateSortingRuleDto) => {
    sortRuleRef.current = rule;
    submitFilter();
  };

  const onConfirmDeleteHandler = () => {
    setDeletePopupOpen(false);
    mutate({ pagination: paginationRef.current });
  };

  const onCancelDeleteHandler = () => {
    setDeletePopupOpen(false);
  };

  const onDeleteDocumentHandler = (uuid: string | undefined) => {
    setDeletePopupOpen(true);
    setTemplateUuid(uuid);
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    submitFilter();
  };

  const pagination = data?.pagination;

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={onKeyPressHandler}
          role="presentation"
          style={{
            width: '100%',
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{
              height: '100%',
            }}
          >
            <PaymentsTemplatesFilter resetFormHandler={resetFormHandler} />
            <PaymentsTemplatesTable
              loading={isLoading}
              templates={templates}
              pagination={pagination}
              onSort={onSortHandler}
              onPaginationChange={onPaginationChangeHandler}
              onDeleteButtonClick={onDeleteDocumentHandler}
            />
          </Grid>
        </form>
      </FormProvider>

      <DeleteTemplatePopup
        open={deletePopupOpen}
        onConfirm={onConfirmDeleteHandler}
        onCancel={onCancelDeleteHandler}
        uuid={templateUuid}
      />
    </>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { UserDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { ColumnFilterType, SortingRule } from '@app/core/types';
import { CompanyUsersTableColumnsEnum } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/types';
import { makeTableColumns } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/columns';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/styles';
import { UsersTableDetails } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/UsersTableDetails';

type SettingsUsersTableProps = {
  data: UserDto[];
  columnFilter: ColumnFilterType<CompanyUsersTableColumnsEnum>;
  showSubFilters: boolean;
  onSearchButtonClick(): void;
  onSort?(rule: SortingRule): void;
  onColumnFilterChange(updater: ColumnFilterType<CompanyUsersTableColumnsEnum>, submit?: boolean): void;
  onCloseSubFilter(): void;
  onClearSubFilter(): void;
};

export const SettingsUsersTable: React.FC<SettingsUsersTableProps> = ({
  data,
  columnFilter,
  showSubFilters,
  onSearchButtonClick,
  onSort,
  onColumnFilterChange,
  onCloseSubFilter,
  onClearSubFilter,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        showSubFilters,
        t,
        onSearchButtonClick,
        onCloseSubFilter,
        onClearSubFilter,
      }),
    ];
  }, [showSubFilters, t, onSearchButtonClick, onCloseSubFilter, onClearSubFilter]);

  const table = useReactTable<UserDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: data.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    meta: {
      columnFilterSate: {
        columnFilter,
        onColumnFilterChange,
      },
    },
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<UserDto> key={row.id} row={row} styles={styles} ExpandedComponent={UsersTableDetails} />;
    });
  };

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<UserDto> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
    />
  );
};

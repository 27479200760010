import React from 'react';

import { parse } from 'query-string';
import { DefaultValues } from 'react-hook-form/dist/types/form';

import { PaymentOrderStatusEnum } from '@app/core/api';
import { StatementsFilterField } from '@app/core/components/Statements/types';
import { isMobile } from '@app/core/utils';
import { OperationsViewDesktop } from '@app/pages/operations/desktop';
import { OperationsViewMobile } from '@app/pages/operations/mobile';
import { OperationsFilterFormData } from '@app/pages/operations/types';

export const OperationsPage: React.FC = () => {
  const {
    dateFrom,
    dateTo,
    dateRangeSelect,
    operationAccounts,
    LEI,
    documentNumber,
    documentStatus,
    counterPartyName,
    counterPartyAccountNumber,
    counterPartyBankCode,
    amountMin,
    amountMax,
    paymentReference,
    hideCommission,
  } = parse(location.search);

  const defaultValues: DefaultValues<OperationsFilterFormData> = {
    documentNumber: documentNumber as string,
    [StatementsFilterField.dateRange]: dateFrom
      ? {
          from: new Date(dateFrom as string),
          to: new Date((dateTo as string) || (dateFrom as string)),
        }
      : undefined,
    [StatementsFilterField.dateRangeSelect]: dateRangeSelect as string,
    LEI: LEI as string,
    documentStatus: documentStatus as PaymentOrderStatusEnum,
    counterPartyName: counterPartyName as string,
    counterPartyAccountNumber: counterPartyAccountNumber as string,
    counterPartyBankCode: counterPartyBankCode as string,
    amountMin: amountMin as string,
    amountMax: amountMax as string,
    paymentReference: paymentReference as string,
    hideCommission: Boolean(hideCommission),
  };

  const StatementsView = isMobile ? OperationsViewMobile : OperationsViewDesktop;
  return <StatementsView defaultValues={defaultValues} />;
};

import React, { useState } from 'react';

import { Box, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeleteIcon } from '@app/common/icons';
import { GetDocumentResponseDto } from '@app/core/api';
import { ConfirmPopup } from '@app/core/components/ConfirmPopup';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { Tooltip } from '@app/core/components/Tooltip';
import { getDocumentStatusIcon } from '@app/pages/exchange/components/form/components/InfoBlock/helpers';
import { UserInfo } from '@app/pages/exchange/components/form/components/InfoBlock/UserInfo';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

type DocumentInfoProps = {
  document: GetDocumentResponseDto;
  showDeleteButton?: boolean;
  onDeleteFile?(document: GetDocumentResponseDto): void;
};
export const DocumentInfo: React.FC<DocumentInfoProps> = ({
  document,
  showDeleteButton = false,
  onDeleteFile = () => {},
}) => {
  const { t } = useTranslation();
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);

  const onDeleteHandler = () => {
    setShowConfirmPopup(true);
  };

  const onConfirmPopupHandler = () => {
    setShowConfirmPopup(false);
    onDeleteFile(document);
  };

  const onCancelPopupHandler = () => {
    setShowConfirmPopup(false);
  };
  return (
    <>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item xs={9} alignItems="center" justifyContent="flex-start" wrap="nowrap">
          {getDocumentStatusIcon(document.status)}
          <Box ml={2} />
          <Ellipsis text={document.originalName} />
        </Grid>
        <Grid item container xs={3} alignItems="center" justifyContent="flex-end" wrap="nowrap">
          {showDeleteButton ? (
            <Tooltip arrow placement="top" color="primary" title={t('detachDocumentTooltip')}>
              <IconButton size="small" color="default" onClick={onDeleteHandler}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              {document.signInfo.personToSign.map((person, i) => {
                return (
                  <Box key={i}>
                    {person.main ? <UserInfo person={person.main} status={person.status} /> : null}
                    {person.attorney ? <UserInfo person={person.attorney} status={person.status} /> : null}
                  </Box>
                );
              })}
            </>
          )}
        </Grid>
      </Grid>
      <Box mb={4} />

      {showConfirmPopup ? (
        <ConfirmPopup
          open
          onConfirm={onConfirmPopupHandler}
          onClose={onCancelPopupHandler}
          questionText={t('confirmDetachFilePopupMessage')}
          icon={<DeleteIcoBgSVG />}
        />
      ) : null}
    </>
  );
};

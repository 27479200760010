import { Theme } from '@mui/material';

export const useStyles = ({ breakpoints, palette: { colors } }: Theme) => ({
  date: {
    width: '100%',
    color: colors.gray400,
    fontWeight: 400,
  },
  accountNumber: {
    width: '100%',
    color: colors.gray400,
  },
  counterPartyName: {
    width: '100%',
    color: colors.primary600,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  amountDeposit: {
    fontSize: '20px',
    color: colors.success,
    fontWeight: 600,
  },
  amountWithdrawal: {
    fontSize: '20px',
    color: colors.error,
    fontWeight: 600,
  },
  amountDocument: {
    fontSize: '20px',
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
    color: colors.primary600,
    fontWeight: 600,
  },
  detailsInfoDescription: {
    color: colors.gray300,
    fontWeight: 600,
  },
  detailsInfoValue: {
    color: colors.primary700,
    fontWeight: 600,
  },
});

import React, { useMemo } from 'react';

import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { DateRange, PaginationChangeType, SortingRule, TablePaginationType, CurrencyEnum } from '@app/core/types';
import { makeTableColumns } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable/columns';
import { buildTableRowsData } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable/helpers';
import { styles } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable/style';
import { Turnover, TurnoversRow } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/types';

interface TurnoversTableProps {
  accountId: string;
  turnovers: Turnover[];
  operationsCount?: number;
  totalAmount?: number;
  currency?: CurrencyEnum;
  pagination?: TablePaginationType;
  dateRange: DateRange;
  loading?: boolean;
  onSort(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
}

export const TurnoversTable: React.FC<TurnoversTableProps> = ({
  accountId,
  turnovers,
  pagination,
  dateRange,
  loading,
  onSort,
  onPaginationChange,
}) => {
  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);
  const { t } = useTranslation();
  const data = useMemo(() => {
    return buildTableRowsData(turnovers);
  }, [turnovers]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        accountId,
        dateRange,
      }),
    ];
  }, [accountId, dateRange, t]);

  const table = useReactTable<TurnoversRow>({
    columns,
    data,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const BodyContent: JSX.Element[] = table.getRowModel().rows.map((row) => {
    return <TableRowContent<TurnoversRow> key={row.id} row={row} styles={styles} />;
  });

  return (
    <>
      <ComplexTable
        head={{ content: <TableHeadContent<TurnoversRow> {...{ table, styles }} /> }}
        body={{ content: BodyContent }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

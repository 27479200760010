import React, { ReactElement } from 'react';

import { SuccessSmallIcon, WaitingSmallIcon, WarningSmallIcon } from '@app/common/icons';
import { PaymentOrderStatusEnum, SignatureStatusDtoStatusEnum } from '@app/core/api';

export const getCurrentDocumentStatusIcon = (status: PaymentOrderStatusEnum): ReactElement => {
  switch (status) {
    case PaymentOrderStatusEnum.ToSign:
      return <WaitingSmallIcon fontSize="small" />;
    case PaymentOrderStatusEnum.Rejected:
      return <WarningSmallIcon fontSize="small" />;
    case PaymentOrderStatusEnum.Executed:
      return <SuccessSmallIcon fontSize="small" />;
    case PaymentOrderStatusEnum.Saved:
      return <WaitingSmallIcon fontSize="small" />;
    case PaymentOrderStatusEnum.OnSigning:
      return <WaitingSmallIcon fontSize="small" />;
    case PaymentOrderStatusEnum.Processing:
    default:
      return <WaitingSmallIcon fontSize="small" />;
  }
};

export const getCurrentPersonInfoStatusIcon = (status: SignatureStatusDtoStatusEnum): ReactElement => {
  switch (status) {
    case SignatureStatusDtoStatusEnum.Signed:
      return <SuccessSmallIcon fontSize="small" />;
    case SignatureStatusDtoStatusEnum.ToSign:
    case SignatureStatusDtoStatusEnum.OnSigning:
    default:
      return <WaitingSmallIcon fontSize="small" />;
  }
};

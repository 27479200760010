import { addDays, isDate, parse } from 'date-fns';
import { date, object, SchemaOf, string } from 'yup';

import { DATE_FORMAT } from '@app/core/utils';
import { BetweenMyAccountFormFieldName } from '@app/pages/payment/components/BetweenMyAccountEditForm/types';

type SchemaValidationObject = {
  [BetweenMyAccountFormFieldName.recipientAccount]: string;
  [BetweenMyAccountFormFieldName.amount]: string;
  [BetweenMyAccountFormFieldName.senderAccount]: string;
  [BetweenMyAccountFormFieldName.temp_paymentDocumentDate]: Date;
  [BetweenMyAccountFormFieldName.paymentReference]: string;
};

type NewPaymentValidationSchemaProps = {
  t: (key: string) => string;
};

export const validationSchema = ({ t }: NewPaymentValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  const maxDocumentDate = addDays(new Date(), 30);
  const parseDateString = (value: string, originalValue: string) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, DATE_FORMAT, new Date());
  };

  return object().shape({
    [BetweenMyAccountFormFieldName.temp_paymentDocumentDate]: date()
      .required(t('paymentCreationLocalPayment_field_required'))
      .typeError(t('paymentDocumentDate_correct_date'))
      .transform(parseDateString)
      .max(maxDocumentDate, t('paymentDocumentDate_correct_date')),
    [BetweenMyAccountFormFieldName.senderAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [BetweenMyAccountFormFieldName.recipientAccount]: string().required(
      t('paymentCreationLocalPayment_field_required')
    ),
    [BetweenMyAccountFormFieldName.amount]: string()
      .min(1, t('paymentCreationLocalPayment_field_required'))
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(
        BetweenMyAccountFormFieldName.amount,
        t('amountMustBeMoreThen_001'),
        (amount: string | undefined): boolean => {
          if (amount) {
            return parseFloat(amount) >= 0.01;
          }
          return false;
        }
      ),
    [BetweenMyAccountFormFieldName.paymentReference]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .trim()
      .min(3, t('paymentCreationLocalPayment_field_minLetters'))
      .test('purpose', t('paymentCreationLocalPayment_field_minLetters'), (val) => {
        if (val && val.length === 3) {
          return !/\s/g.test(val.slice(0, 2));
        }
        return true;
      }),
  });
};

import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import { UseMutationOptions, UseMutationResult } from 'react-query/types/react/types';

import {
  getClientId,
  currencyOperationsApiClient,
  ApiError,
  SignBatchResponseDto,
  SignBatchRequestDto,
} from '@app/core/api';
import { SignApiError } from '@app/src/logger/SignApiError';
import { LogTagErrorName, LogTagErrorSource, LogTagErrorType } from '@app/src/logger/types';

const logSignApiError = (error: ApiError) => {
  Sentry.captureException(new SignApiError(error.message), {
    tags: {
      [LogTagErrorName.errorType]: LogTagErrorType.SignCurrencyExchangeApiError,
      [LogTagErrorName.errorSource]: LogTagErrorSource.api,
    },
    extra: {
      statusCode: error.code,
      message: error.message,
    },
  });
};

type SignCurrencyProps = {
  signBatchRequestDto: SignBatchRequestDto;
  signatureBelongsToUser?: number;
};

const submitSignCurrencyExchangeFn = async ({
  signBatchRequestDto,
  signatureBelongsToUser,
}: SignCurrencyProps): Promise<SignBatchResponseDto> => {
  const result = await currencyOperationsApiClient.currencyOperationSignControllerSignBatchCurrencyOperations(
    getClientId(),
    signBatchRequestDto,
    signatureBelongsToUser
  );

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Sign API response with error. StatusCode:' + result.request.status);
  }

  return result.data;
};

export const useMultiCurrencyExchangeSign = (
  options?: UseMutationOptions<SignBatchResponseDto, ApiError, SignCurrencyProps>
): UseMutationResult<SignBatchResponseDto, ApiError, SignCurrencyProps> => {
  return useMutation<SignBatchResponseDto, ApiError, SignCurrencyProps>(submitSignCurrencyExchangeFn, {
    onError: (error: ApiError) => {
      logSignApiError(error);
    },
    ...options,
  });
};

import React from 'react';

import { MenuItem } from '@mui/material';
import { RegisterOptions } from 'react-hook-form';

import { GetAccountResponseDto } from '@app/core/api';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { SelectOption } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/components/SenderAccountSelect/SelectOption';
import { SenderAccountInfo } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/components/SenderAccountSelect/SenderAccountInfo';

type SenderAccountSelectProps = {
  name: string;
  options: GetAccountResponseDto[] | undefined;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: string;
};

export const SenderAccountSelect: React.FC<SenderAccountSelectProps> = ({ name, options, rules, defaultValue }) => {
  return (
    <>
      <SelectField
        fullWidth
        name={name}
        defaultValue={defaultValue || ''}
        renderValue={(selected: any) => {
          return <SelectOption selectOption={selected} />;
        }}
      >
        {options?.map(({ account }) => {
          return (
            <MenuItem key={account.id} value={JSON.stringify(account)}>
              <SenderAccountInfo account={account} />
            </MenuItem>
          );
        })}
      </SelectField>
    </>
  );
};

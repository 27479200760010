import React from 'react';

import { Grid, Typography, Box } from '@mui/material';

import { styles } from './styles';
import { Operation } from '../../../../../../types';
import { DATE_FORMAT, formatISODateString } from '../../../../../../utils';
import { Checkbox } from '../../../../../Checkbox';
import {
  AccountNameItem,
  AccountNumberItem,
  AmountDocumentItem,
  CounterPartyName,
  PaymentReference,
} from '../../../../../ComplexTable';
import { IBanText } from '../../../../../IBanText';

export interface DocumentCardProps {
  document: Operation;
  selected?: boolean;
  onCheck(document: Operation, selected: boolean): void;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ document, selected = false, onCheck }) => {
  const { documentNumber, paymentReference, amount, currency, details } = document;

  const { name: senderName, accountNumber: senderAccountNumber } = details.senderAccount;
  const { name: recipientName } = details.recipientAccount;
  const { date } = details;

  const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(document, e.target.checked);
  };

  return (
    <Grid container direction="column" sx={styles.root}>
      <Grid item container justifyContent="space-between">
        <Grid>
          <Typography variant="body2" component="span" sx={styles.documentNumber}>
            {documentNumber}
          </Typography>
          <Typography variant="body2" component="span" sx={styles.date}>
            {date ? formatISODateString(date, DATE_FORMAT) : ''}
          </Typography>
        </Grid>
        <Grid item>
          <AmountDocumentItem amount={amount} currency={currency} />
        </Grid>
      </Grid>
      <Box sx={styles.accountContainer}>
        <AccountNameItem text={senderName} />
        <AccountNumberItem text={<IBanText text={senderAccountNumber} />} />
      </Box>
      <Box sx={styles.selectCheckboxContainer}>
        <Checkbox checked={selected} onChange={onCheckHandler} sx={styles.selectCheckbox} />
      </Box>
      <Box sx={styles.accountContainer}>
        <CounterPartyName text={recipientName} />
        <PaymentReference text={paymentReference} />
      </Box>
    </Grid>
  );
};

export const styles = {
  root: {
    border: `1px solid`,
    borderColor: 'colors.gray50',
    borderRadius: '8px',
  },
  headNotificationsTable: {
    backgroundColor: 'colors.gray25',
    padding: `12px 16px`,
  },
  bodyNotificationsTable: {
    borderTop: `1px solid`,
    borderColor: 'colors.gray50',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  item: {
    borderTop: `1px solid`,
    backgroundColor: 'colors.gray50',
    padding: `8px 4px}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
};

import {
  SwiftOperationWithAvailableButtons,
  SwiftOperationWithAvailableButtonsStatusEnum,
  UserResourceDto,
} from '@app/core/api';
import { makeTableToolbarActions } from '@app/core/helpers';
import { TableToolbarActiveButtonsConfig, TableToolbarEnabledActionsType } from '@app/core/types';

export const getActiveButtonsConfig = (
  selectedOperations: SwiftOperationWithAvailableButtons[],
  permissions: UserResourceDto
): TableToolbarActiveButtonsConfig => {
  const docsForSign = selectedOperations.filter(
    (item) =>
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.Saved ||
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.ToSign
  );
  const single: boolean = selectedOperations.length === 1;
  const documentCountAccept = selectedOperations.length <= 10;
  const signEnabled: boolean = docsForSign.length >= 1 && docsForSign.length <= 10;
  const showSignButton: boolean =
    signEnabled &&
    selectedOperations.filter(
      (item) =>
        (item.status === SwiftOperationWithAvailableButtonsStatusEnum.ToSign ||
          item.status === SwiftOperationWithAvailableButtonsStatusEnum.Saved) &&
        item.actions.allowToSign
    ).length >= 1;
  const showDeleteButton: boolean =
    permissions.swift.delete &&
    selectedOperations.filter(
      (item) =>
        item.status === SwiftOperationWithAvailableButtonsStatusEnum.ToSign ||
        item.status === SwiftOperationWithAvailableButtonsStatusEnum.Saved
    ).length >= 1;

  const documentStatus = selectedOperations[0]?.status;

  const showSendToSignButton: boolean = selectedOperations.filter((item) => item.actions.allowToSendToSign).length >= 1;

  const enabledActions: TableToolbarEnabledActionsType = {
    sign: showSignButton,
    sendToSign: showSendToSignButton && documentCountAccept,
    edit: false,
    view: permissions.swift.view && single,
    copy: permissions.swift.copy && single,
    delete: showDeleteButton && documentCountAccept,
    print: permissions.swift.print && single,
    download: permissions.swift.download && single,
  };
  if (
    permissions.swift.edit &&
    single &&
    (documentStatus === SwiftOperationWithAvailableButtonsStatusEnum.ToSign ||
      documentStatus === SwiftOperationWithAvailableButtonsStatusEnum.OnSigning ||
      documentStatus === SwiftOperationWithAvailableButtonsStatusEnum.Saved)
  ) {
    enabledActions.edit = true;
  }
  return makeTableToolbarActions(enabledActions);
};

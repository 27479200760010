import { OperationHistoryItemDtoActionTypeEnum } from '@app/core/api';

export const getActionTranslationKeyByActionTypeEnum = (status: OperationHistoryItemDtoActionTypeEnum): string => {
  switch (status) {
    case OperationHistoryItemDtoActionTypeEnum.AddFile:
      return 'actionType_AddFile';
    case OperationHistoryItemDtoActionTypeEnum.DetachFile:
      return 'actionType_DetachFile';
    case OperationHistoryItemDtoActionTypeEnum.Created:
      return 'documentChangeAction_Created';
    case OperationHistoryItemDtoActionTypeEnum.Edited:
      return 'documentChangeAction_Edited';
    case OperationHistoryItemDtoActionTypeEnum.Deleted:
      return 'documentChangeAction_Deleted';
    case OperationHistoryItemDtoActionTypeEnum.ToSign:
      return 'documentChangeAction_ToSign';
    case OperationHistoryItemDtoActionTypeEnum.OnSigning:
      return 'documentChangeAction_OnSigning';
    case OperationHistoryItemDtoActionTypeEnum.Signed:
      return 'documentChangeAction_Signed';
    case OperationHistoryItemDtoActionTypeEnum.Executed:
      return 'documentStatus_executed';
    case OperationHistoryItemDtoActionTypeEnum.Rejected:
      return 'documentStatus_rejected';
    case OperationHistoryItemDtoActionTypeEnum.WaitingForDocument:
      return 'documentStatus_waitingForDocument';
    case OperationHistoryItemDtoActionTypeEnum.OperationAttached:
      return 'documentChangeAction_operationAttached';
    case OperationHistoryItemDtoActionTypeEnum.OperationDetach:
      return 'documentChangeAction_operationDetach';
    case OperationHistoryItemDtoActionTypeEnum.Accepted:
      return 'documentStatus_accepted';
    case OperationHistoryItemDtoActionTypeEnum.SignedFromAnotherUser:
      return 'SIGNED_FROM_ANOTHER_USER';
    case OperationHistoryItemDtoActionTypeEnum.RemovalOfSignature:
      return 'REMOVAL_OF_SIGNATURE';
    case OperationHistoryItemDtoActionTypeEnum.Processing:
    default:
      return 'documentChangeAction_Processing';
  }
};

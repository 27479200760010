import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { getMenuItems } from '@app/pages/profile/helper';
import { MobileHistoryOfVisitsPage } from '@app/pages/profile/mobile/components/HistoryOfVisits';
import { MobileSecurityPage } from '@app/pages/profile/mobile/components/Security';
import { MobileUserData } from '@app/pages/profile/mobile/components/UserData';
import { UserProfileMenuFilter } from '@app/pages/profile/types';

export const ProfileViewMobile: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeMobileMenuItem, setActiveMobileMenuItem] = useState<UserProfileMenuFilter>(
    (searchParams.get('tab') as UserProfileMenuFilter) || UserProfileMenuFilter.Data
  );
  const { t } = useTranslation();
  const menuItems: MenuItem<UserProfileMenuFilter>[] = getMenuItems(t);

  const onMobileProfiledMenuChange = (selected: UserProfileMenuFilter) => {
    setSearchParams(`tab=${selected}`);
    setActiveMobileMenuItem(selected);
  };

  return (
    <PageWrapper
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        height: '100vh',
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          height: '100vh',
        }}
      >
        <PageHeader
          title={t('myProfile')}
          styles={{
            title: {
              color: '#FFFFFF',
            },
            closeButton: {
              color: '#FFFFFF',
            },
            burgerButton: {
              color: '#FFFFFF',
            },
          }}
        />
        <Box
          sx={{
            overflowX: 'auto',
            width: '100%',
          }}
        >
          <HorizontalMenu<UserProfileMenuFilter>
            onChange={onMobileProfiledMenuChange}
            menuItems={menuItems}
            styles={{
              itemDefault: {
                color: 'colors.gray200',
                fontWeight: 600,
                marginRight: '12px',
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
              },
              itemSelected: {
                color: 'colors.primary600',
                fontWeight: 600,
                fontSize: '1rem',
                marginRight: '12px',
                whiteSpace: 'nowrap',
              },
            }}
          />
        </Box>
        {activeMobileMenuItem === UserProfileMenuFilter.Data && <MobileUserData />}
        {activeMobileMenuItem === UserProfileMenuFilter.Security && <MobileSecurityPage />}
        {activeMobileMenuItem === UserProfileMenuFilter.History && <MobileHistoryOfVisitsPage />}
      </Grid>
    </PageWrapper>
  );
};

export const styles = {
  root: {
    backgroundColor: '#F3F9FF',
    boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
    padding: '0 20px',
  },
  row: {
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:not(:last-child)': {
      backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '12px 1px',
      backgroundRepeat: 'repeat-x',
    },
  },
  actionButton: {
    marginLeft: '16px',
    color: 'colors.primary700',
  },
  actionIcon: {
    paddingTop: '3px',
    marginLeft: '16px',
    color: 'colors.primary700',
  },
};

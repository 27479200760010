import React from 'react';

import { Box, Button, FormControl, FormLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InputField } from '@app/core/components/Form/controls/InputField';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';

interface SwiftCounterpartiesFilterProps {
  resetFormHandler?(): void;
}

export const SwiftCounterpartiesFilter: React.FC<SwiftCounterpartiesFilterProps> = ({ resetFormHandler }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      sx={{
        padding: `16px 20px`,
        backgroundColor: 'colors.gray25',
        borderRadius: '8px',
      }}
    >
      <Grid item container justifyContent="space-between" spacing={5}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel component="legend">{t('swiftCounterpartiesName')}</FormLabel>
            <Box pt={2}>
              <InputField name="name" fullWidth placeholder={t('enterName_placeholder')} />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend">{t('country')}</FormLabel>
          <Box pt={2}>
            <CountrySelect placeholder={t('countrySelect_placeholder')} name="country" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel component="legend">{t('account')}</FormLabel>
            <Box pt={2}>
              <RecipientAccountSelect name="account" placeholder="UA" />
            </Box>
          </FormControl>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={6}>
            <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
            <Box pt={2}>
              <InputField name="bic" placeholder={'00000000'} fullWidth />
            </Box>
          </Grid>
          <Grid item container alignItems="flex-start" justifyContent="flex-end" xs={6}>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                paddingTop: '28px',
                maxWidth: '240px',
              }}
            >
              <Button color="primary" onClick={resetFormHandler}>
                {t('clear')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { Grid, GridProps } from '@mui/material';

export const PageContainer: React.FC<GridProps> = ({ children, ...otherProps }) => {
  return (
    <Grid
      container
      sx={{
        width: '100%',
        position: 'relative',
        minHeight: 'calc(100vh - 100px)',
      }}
      {...otherProps}
    >
      {children}
    </Grid>
  );
};

import { isMobile } from '@app/core/utils';

export const styles = {
  checkBox: {
    paddingRight: '4px',
  },
  accountName: {
    color: 'colors.primary600',
    fontWeight: 400,
  },
  closed: {
    color: 'colors.gray300',
  },
  accountNumber: {
    color: 'colors.gray400',
    fontWeight: 400,
  },
  currencyActive: {
    color: 'colors.gray800',
  },
  currencyClosed: {
    color: 'colors.gray300',
  },
  chipRoot: {
    width: '100%',
    justifyContent: 'space-between',
  },
  groupHeader: {
    overflow: 'hidden',
    display: 'flex',
    marginTop: '12px',
    marginBottom: '12px',
  },
  groupLabel: {
    flexGrow: 1,
    flexShrink: 0,
    color: 'colors.gray400',
    fontWeight: 400,
    paddingRight: '2px',
  },
  dashed: {
    width: '100%',
    height: '12px',
    backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '12px 1px',
    backgroundRepeat: 'repeat-x',
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    borderRadius: '3px',
    width: '100%',
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  inputContainer: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  popperDisablePortal: {
    margin: 0,
    position: 'relative',
  },
  listbox: {
    maxHeight: '40vh',
    overflow: 'auto',
  },
  selectContentRoot: {
    margin: '16px 0px',
  },
  endAdornment: {
    position: 'absolute',
    right: '20px',
    top: 'calc(50% - 8px)',
  },
  searchIcon: {
    fill: 'currentColor',
    color: 'colors.gray300',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
  selectPanel: {
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedCountLabel: {
    color: 'colors.gray600',
    fontWeight: 400,
  },
  selectedCount: {
    color: 'colors.primary600',
  },
  resetButton: {
    padding: 0,
    justifyContent: 'flex-end',
    fontWeight: 400,
  },
  select: {
    backgroundColor: 'white',
  },
  selectPlaceholder: {
    color: 'colors.gray300',
    fontWeight: 400,
  },
  chipContainer: {
    width: '100%',
    position: 'relative',
    marginRight: '32px',
    backgroundColor: 'white',
  },
  scrollContainer: {
    width: '100%',
    position: 'absolute',
    overflowX: 'auto',
    top: 0,
    left: 0,
    transform: ' translate(0,-25%)',
  },
  chip: {
    backgroundColor: () => {
      return isMobile ? 'colors.mobileAccountChipBackgroundColor' : 'colors.desktopAccountChipBackgroundColor';
    },
    borderRadius: '8px',
    border: () =>
      `1px solid ${isMobile ? 'colors.mobileAccountChipBorderColor' : 'colors.desktopAccountChipBorderColor'}`,
    marginLeft: '4px',
    color: () => (isMobile ? 'colors.mobileAccountChipTextColor' : 'colors.desktopAccountChipTextColor'),
    fontWeight: 600,
  },
  option: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: '6px',
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',
    paddingBottom: '6px',
    paddingLeft: '16px',
    paddingRight: '16px',
    '@media (max-width: 1279.95px)': {
      minHeight: 'auto',
    },
    '&[aria-disabled="true"]': {
      opacity: 0.38,
      pointerEvents: 'none',
    },
  },
  selectAllFormControlLabel: {
    marginLeft: 0,
    paddingBottom: '6px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  errorText: {
    color: () => (isMobile ? 'colors.mobileAccountErrorTextColor' : ''),
  },
  chipText: {
    color: () => (isMobile ? 'colors.mobileAccountChipTextColor' : 'colors.desktopAccountChipTextColor'),
  },
};

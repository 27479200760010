import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DownChevronSmallIcon, FilterIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ApiError,
  DealStateEnum,
  GetLoanLinesListRequestDto,
  GetLoanLinesListResponseDto,
  InternalLoanSortingRuleDto,
  LoanLineDto,
} from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useProfileControllerSendRequest } from '@app/core/hooks';
import { TableColumnConfig, PaginationChangeType } from '@app/core/types';
import { makeDataFromSubmit } from '@app/pages/loansLines/components/helpers';
import { LoansLinesFilter } from '@app/pages/loansLines/components/LoansLinesFilter';
import { LoansTable } from '@app/pages/loansLines/components/LoansLinesTable';
import { getLoansLinesListFn, loansControllerUpdateFn } from '@app/pages/loansLines/query';
import { loansLinesTableFields } from '@app/pages/loansLines/tableFields';
import { LoansLinesFilterFormData, LoansLinesTypeEnum } from '@app/pages/loansLines/types';
import { requestTopic } from '@app/src/config';
import { ReactComponent as OverdraftPlaceholder } from '@app/themes/default/assets/icons/overdraftPlaceholder.svg';

const defaultValues: LoansLinesFilterFormData = {
  agreementStartDateFrom: undefined,
  agreementStartDateTo: undefined,
  agreementEndDateFrom: undefined,
  agreementEndDateTo: undefined,
  agreementNumber: '',
  limitFrom: '',
  limitTo: '',
  usedLimitFrom: '',
  usedLimitTo: '',
  currency: '',
  interestRateFrom: '',
  interestRateTo: '',
  temp_agreementStartDateFrom: '',
  temp_agreementStartDateTo: '',
  temp_agreementEndDateFrom: '',
  temp_agreementEndDateTo: '',
};

export const LoansLinesContent: React.FC = () => {
  const [fullItems, setFullItems] = useState(false);
  const { t } = useTranslation();
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const dealStateRef = useRef<DealStateEnum[]>([DealStateEnum.New, DealStateEnum.Active]);
  const sortRuleRef = useRef<InternalLoanSortingRuleDto | undefined>();

  const { notify } = useNotify();

  const loansTabsItems: MenuItem<LoansLinesTypeEnum>[] = [
    {
      id: 1,
      labelText: t('open'),
      value: LoansLinesTypeEnum.open,
    },
    {
      id: 2,
      labelText: t('closed'),
      value: LoansLinesTypeEnum.closed,
    },
  ];

  const { refetch, isSuccess } = useProfileControllerSendRequest(requestTopic.loanLine);

  const methods = useForm<LoansLinesFilterFormData>({ mode: 'onChange', defaultValues });
  const { handleSubmit, reset: formReset, formState, getValues } = methods;

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<GetLoanLinesListResponseDto, ApiError, GetLoanLinesListRequestDto>(getLoansLinesListFn);
  const loansLinesOperations: Array<LoanLineDto> = data?.loanLines || [];
  const pagination = data?.pagination;

  const [activeTab, setActiveTab] = useState<LoansLinesTypeEnum>(LoansLinesTypeEnum.open);

  const updateList = () => {
    mutate({
      ...makeDataFromSubmit(getValues()),
      pagination: paginationRef.current,
      sort: sortRuleRef.current,
      states: dealStateRef.current,
    });
  };

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
      sort: sortRuleRef.current,
      states: dealStateRef.current,
    });
  }, [mutate]);

  const handleChange = (currentTab: LoansLinesTypeEnum): void => {
    setActiveTab(currentTab);
    if (currentTab === LoansLinesTypeEnum.open) {
      dealStateRef.current = [DealStateEnum.New, DealStateEnum.Active];
    }
    if (currentTab === LoansLinesTypeEnum.closed) {
      dealStateRef.current = [DealStateEnum.Closed, DealStateEnum.Deleted, DealStateEnum.WillBeClosed];
    }
    updateList();
  };

  const onSubmit = () => {
    updateList();
  };

  const resetFormHandler = () => {
    formReset();
    mutationReset();
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateList();
    }
  };

  const onSortHandler = (rule: InternalLoanSortingRuleDto) => {
    sortRuleRef.current = rule;
    updateList();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    if (data?.loanLines.length) {
      updateList();
    }
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const configurationSavedHandler = (tableColumnsData: TableColumnConfig[]) => {
    // dispatch(updateOperationsTableConfig(tableColumnsData));
  };

  const sendRequest = () => {
    refetch();
  };
  useEffect(() => {
    if (isSuccess) {
      notify({
        notifyProps: {
          title: t('success'),
          message: t('sendRequestSuccess'),
          severity: 'success',
        },
      });
    }
  }, [isSuccess, notify, t]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={onKeyPressHandler}
        role="presentation"
        style={{
          height: '100%',
        }}
      >
        <HorizontalMenu<LoansLinesTypeEnum> onChange={handleChange} menuItems={loansTabsItems} />
        {loansLinesOperations.length || formState.isDirty ? (
          <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            sx={{
              height: '100%',
            }}
          >
            <Box pb={4} mt={7}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <FilterIcon />
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{
                      color: 'colors.primary600',
                      fontWeight: 'bold',
                      verticalAlign: 'bottom',
                      marginLeft: '4px',
                    }}
                  >
                    {t('filters')}
                  </Typography>
                </Grid>
                <Button
                  color="primary"
                  sx={{
                    fontSize: '0.75rem',
                    '& svg': {
                      marginLeft: '4px',
                      fill: 'colors.primary500',
                    },
                    '& span': {
                      fontWeight: 600,
                    },
                  }}
                  onClick={handleShowMoreItems}
                >
                  {t('additionalParameters')}
                  {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
                </Button>
              </Grid>
            </Box>
            <Box pb={4}>
              <LoansLinesFilter
                fullItems={fullItems}
                namesFieldWithError={Object.keys(formState.errors)}
                resetFormHandler={resetFormHandler}
              />
            </Box>
            <LoansTable
              loading={isLoading}
              loansLines={loansLinesOperations}
              columnsConfig={loansLinesTableFields}
              columnsConfigMutationFn={loansControllerUpdateFn}
              pagination={pagination}
              onSort={onSortHandler}
              onPaginationChange={onPaginationChangeHandler}
              onSuccessConfigurationSaved={configurationSavedHandler}
            />
          </Grid>
        ) : (
          <>
            {!isLoading ? (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: 500,
                }}
                direction="column"
              >
                <OverdraftPlaceholder />
                {activeTab === LoansLinesTypeEnum.open ? (
                  <>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {t('loanLinesPlaceholderTitle')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'colors.gray400',
                      }}
                    >
                      {t('loanLinesPlaceholderDescription')}
                    </Typography>
                    <Box mt={5}>
                      <Button size="small" variant="contained" color="primary" onClick={sendRequest}>
                        {t('submitApplication')}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t('loanLinesClosedPlaceholderTitle')}
                  </Typography>
                )}
              </Grid>
            ) : null}
          </>
        )}
      </form>
    </FormProvider>
  );
};

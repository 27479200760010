import React, { useState } from 'react';

import { Box, Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, DownloadIcon, MoreIcon, StarIcon, UpChevronSmallIcon } from '@app/common/icons';
import { EmailDocumentTypeEnum, PaymentOrderTypeEnum } from '@app/core/api';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { DetailsInfoItem } from './DetailsInfoItem';
import { styles } from './styles';
import { Operation, OperationsTypeEnum, CurrencyEnum } from '../../../../../types';
import { DATE_FORMAT, formatISODateString } from '../../../../../utils';
import {
  AccountNameItem,
  AccountNumberItem,
  AmountItem,
  CounterPartyName,
  PaymentReference,
} from '../../../../ComplexTable';
import { IBanText } from '../../../../IBanText';
import { CopyOperationButton } from '../../../../OperationDetails/Actions/CopyOperationButton';
import { PrintButton } from '../../../../OperationDetails/Actions/PrintButton';
import { SaveTemplateDialog } from '../../../../SaveTemplateDialog';
import { makeTemplateDataFromOperation } from '../../../../SaveTemplateDialog/helpers';
import { ShareDocumentButton } from '../../../../ShareDocumentButton';

export interface OperationCardProps {
  operation: Operation;
}

export const OperationCard: React.FC<OperationCardProps> = ({ operation }) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const [extended, setExtended] = useState<boolean>(false);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { uuid, documentNumber, account, paymentReference, type, amount, currency, details, date, documentType, link } =
    operation;
  const { senderAccount, recipientAccount } = details;
  const { name: accountName, number: accountNumber } = account;
  const { name: senderName, accountNumber: senderAccountNumber } = details.senderAccount;
  const { name: recipientName } = details.recipientAccount;
  const { date: detailsDate } = details;

  const handleExpand = () => {
    setExtended(!extended);
  };

  const addToTemplateClickHandler = () => {
    setShowSaveTemplateDialog(true);
  };

  const onCancelSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  const downloadButtonClickHandler = () => {
    window.open(link, '_blank');
    handleCloseMoreMenu();
  };

  return (
    <>
      <Grid container direction="column" sx={styles.root}>
        <Grid
          container
          direction="column"
          onClick={handleExpand}
          sx={{ ...styles.cardMainContainer, ...(extended && styles.cardExpanded) }}
        >
          <Grid item container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" component="span" sx={styles.documentNumber}>
                {documentNumber}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.date}>
                {date ? formatISODateString(date, DATE_FORMAT) : ''}
              </Typography>
            </Grid>
            <Grid item>
              <AmountItem type={type} amount={amount} currency={currency} />
            </Grid>
          </Grid>
          <Box sx={styles.accountContainer}>
            <AccountNameItem text={senderName} />
            <AccountNumberItem text={<IBanText text={senderAccountNumber} />} />
          </Box>
          <Box sx={styles.chevronContainer}>
            {extended ? (
              <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
            ) : (
              <DownChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
            )}
          </Box>
          <Box sx={styles.accountContainer}>
            <CounterPartyName text={recipientName} />
            <PaymentReference text={paymentReference} />
          </Box>
        </Grid>
        {extended ? (
          <Grid container direction="column" sx={styles.extendedInfoContainer}>
            {currency === CurrencyEnum.UAH ? (
              <Box mb={2} width="100%">
                <Grid container alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" component="span" sx={styles.detailsInfoDescription}>
                    {t('mobileOperationCard_availableActions')}
                  </Typography>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onMenuBtnClickHandler}
                    endIcon={<MoreIcon />}
                  />
                  <Popover
                    anchorEl={moreMenuAnchorEl}
                    keepMounted
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={handleCloseMoreMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {permissions.uahOperations.copy &&
                    type === OperationsTypeEnum.withdrawal &&
                    documentType === PaymentOrderTypeEnum.PaymentOrderFlag ? (
                      <MenuItem sx={styles.menuItem}>
                        <CopyOperationButton withLabel styles={styles.actionButton} operation={operation} />
                      </MenuItem>
                    ) : null}
                    {permissions.uahOperations.print &&
                    (type === OperationsTypeEnum.withdrawal || type === OperationsTypeEnum.deposit) &&
                    documentType ? (
                      <MenuItem sx={styles.menuItem}>
                        <ShareDocumentButton
                          withLabel
                          styles={styles.actionButton}
                          link={link}
                          type={EmailDocumentTypeEnum.PaymentOrder}
                          uuid={uuid}
                        />
                      </MenuItem>
                    ) : null}
                    {permissions.uahOperations.print &&
                    (type === OperationsTypeEnum.withdrawal || type === OperationsTypeEnum.deposit) ? (
                      <MenuItem sx={styles.menuItem}>
                        <PrintButton withLabel styles={styles.actionButton} link={link} />
                      </MenuItem>
                    ) : null}
                    {permissions.uahOperations.download &&
                    (type === OperationsTypeEnum.withdrawal || type === OperationsTypeEnum.deposit) ? (
                      <MenuItem sx={styles.menuItem}>
                        <Button
                          sx={styles.actionButton}
                          startIcon={<DownloadIcon />}
                          onClick={downloadButtonClickHandler}
                        >
                          <Typography variant="body1" component="span">
                            {t('download')}
                          </Typography>
                        </Button>
                      </MenuItem>
                    ) : null}
                    {permissions.uahTemplates.create &&
                    type === OperationsTypeEnum.withdrawal &&
                    documentType === PaymentOrderTypeEnum.PaymentOrderFlag ? (
                      <MenuItem sx={styles.menuItem}>
                        <IconButton
                          size="small"
                          color="default"
                          sx={styles.actionButton}
                          onClick={addToTemplateClickHandler}
                        >
                          <StarIcon />
                        </IconButton>
                        <Typography variant="body1" component="span">
                          {t('addToTemplate')}
                        </Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                </Grid>
                <Box sx={styles.divider} />
              </Box>
            ) : null}

            <DetailsInfoItem
              description={t(
                documentType === PaymentOrderTypeEnum.MemorialOrderFlag
                  ? 'documentCategory_memorialOrder'
                  : 'paymentCreationLocalPayment_paymentOrder'
              )}
              value={documentNumber}
            />
            <DetailsInfoItem
              description={t('paymentCreationLocalPayment_documentDate')}
              value={formatISODateString(detailsDate, DATE_FORMAT)}
            />
            <DetailsInfoItem
              description={t('operationDetailShare_spend')}
              value={date ? `${formatISODateString(date, DATE_FORMAT)}` : ''}
            />
            <Box sx={styles.divider} />
            <DetailsInfoItem description={t('operationDetail_senderAccountName')} value={senderAccount.name} />
            <DetailsInfoItem
              description={t('operationDetail_lei')}
              value={senderAccount.LEI || senderAccount.passportId || ''}
            />
            <DetailsInfoItem description={t('operationDetail_senderBank')} value={senderAccount.bankName} />
            <DetailsInfoItem description={t('operationDetail_account')} value={senderAccount.accountNumber} />
            <Box sx={styles.divider} />
            <DetailsInfoItem description={t('operationDetail_recipient')} value={recipientAccount.name} />
            <DetailsInfoItem
              description={t('operationDetail_recipientLei')}
              value={recipientAccount.LEI || recipientAccount.passportId || ''}
            />
            <DetailsInfoItem description={t('operationDetail_recipientBank')} value={recipientAccount.bankName} />
            <DetailsInfoItem description={t('operationDetail_account')} value={recipientAccount.accountNumber} />
            <Box sx={styles.divider} />
            <Box mb={2}>
              <Grid container direction="column" justifyContent="space-between">
                <Box mb={1}>
                  <Typography variant="body2" component="p" sx={styles.detailsInfoDescription}>
                    {t('paymentCreationLocalPayment_purpose')}
                  </Typography>
                </Box>
                <Typography variant="body2" component="p" sx={styles.detailsInfoValue}>
                  {paymentReference}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {showSaveTemplateDialog ? (
        <SaveTemplateDialog
          open
          documentTemplateData={makeTemplateDataFromOperation(operation)}
          onCancel={onCancelSaveTemplateHandler}
          onSuccessSaveTemplate={onCancelSaveTemplateHandler}
        />
      ) : null}
    </>
  );
};

import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { getCurrencyMenuItems } from '@app/core/utils';
import { AccountsListComponent } from '@app/pages/accounts/components/AccountsList';
import { FilterPanel } from '@app/pages/accounts/components/FilterPanel';
import { AccountsPageProps } from '@app/pages/accounts/types';
import { RouteList } from '@app/src/constants/routeList';

export const AccountsDesktopPage: React.FC<AccountsPageProps> = ({
  onCurrencyMenuChangeHandler,
  activeAccountStatus,
  accountsByStatusCounts,
  accounts,
  onStatusFilterChangeHandler,
  onSearchChange,
  currencies,
}) => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader title={t('allAccounts')} showNavigateButton backLink={-1}>
        <Link to={RouteList.payment_create}>
          <Button variant="contained" color="primary">
            {t('dashboardCreatePaymentBtn')}
          </Button>
        </Link>
      </PageHeader>
      <PageContainer direction="column">
        <>
          <Box pt={4} pb={4}>
            <HorizontalMenu menuItems={getCurrencyMenuItems(t, currencies)} onChange={onCurrencyMenuChangeHandler} />
          </Box>
          <Grid container direction="column">
            <Box component="div" pb={4}>
              <FilterPanel
                activeCount={accountsByStatusCounts.active}
                blockedCount={accountsByStatusCounts.blocked}
                closedCount={accountsByStatusCounts.closed}
                selectItem={activeAccountStatus}
                onFilterChange={onStatusFilterChangeHandler}
                onSearchChange={onSearchChange}
              />
            </Box>
            {accounts.length ? <AccountsListComponent accounts={accounts} /> : null}
          </Grid>
        </>
      </PageContainer>
    </PageWrapper>
  );
};

import { GetLoansListRequestDto } from '@app/core/api';
import { LoansFilterFormData } from '@app/pages/loans/types';

export const makeDataFromSubmit = (
  formData: LoansFilterFormData
): Omit<GetLoansListRequestDto, 'sort' | 'pagination'> => {
  const {
    agreementStartDateFrom,
    agreementStartDateTo,
    agreementEndDateFrom,
    agreementEndDateTo,
    agreementNumber,
    amountFrom,
    amountTo,
    currency,
    interestRateFrom,
    interestRateTo,
  } = formData;

  const agreementStartDate =
    agreementStartDateFrom || agreementStartDateTo
      ? { startDate: agreementStartDateFrom?.toISOString(), endDate: agreementStartDateTo?.toISOString() }
      : undefined;

  const agreementEndDate =
    agreementEndDateFrom || agreementEndDateTo
      ? { startDate: agreementEndDateFrom?.toISOString(), endDate: agreementEndDateTo?.toISOString() }
      : undefined;

  const interestRate =
    interestRateFrom?.length && interestRateTo?.length
      ? { from: parseFloat(interestRateFrom), to: parseFloat(interestRateTo) }
      : undefined;
  const amount =
    amountFrom?.length && amountTo?.length ? { from: parseFloat(amountFrom), to: parseFloat(amountTo) } : undefined;
  return {
    agreementStartDate,
    agreementEndDate,
    amount,
    interestRate,
    currency: currency?.length ? currency : undefined,
    agreementNumber: agreementNumber?.length ? agreementNumber : undefined,
  };
};

import React, { useState } from 'react';

import { Button, Grid, Hidden, ListItemIcon, MenuItem, Popover, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  ArrowDownSelectSmallIcon,
  BackCancelIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon,
  CopyIcon,
  CsvIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PdfIcon,
  PreviewIcon,
  PrintIcon,
  SendIcon,
  SignatureIcon,
  StarIcon,
  XlsIcon,
  XmlIcon,
} from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ApiError,
  FileLinkAdditionalInfoDtoCodeEnum,
  FileLinkResponseDto,
  StatementTypeEnum,
  OutputFormatEnum,
  CurrencyEnum,
  PaymentTemplateTypeEnum,
} from '@app/core/api';
import { Checkbox } from '@app/core/components';
import { DocumentStatus, Operation } from '@app/core/types';
import { calculateTotal, formatAmount } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { ConfirmEditOperationPopup } from '../../../../../ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { GetMultipleStatementsFilesProps } from '../../../../types';
import { getActiveButtonsConfig } from '../../helpers';

export interface ActionsPanelProps {
  selectedDocuments: Operation[];
  rowsCount: number;
  documentStatus: DocumentStatus;
  onSelectAllClick(selectAll: boolean): void;
  onSignClick(): void;
  onMultiSignClick(): void;
  onCopyClick(): void;
  onDeleteClick(): void;
  onAddToTemplatesClick(): void;
  onSendToSignClick(): void;
  onCancelDocument(): void;
  getStatementsMultipleFiles(): UseMutationResult<FileLinkResponseDto, ApiError, GetMultipleStatementsFilesProps>;
  onSignFromAnotherClick(): void;
  onMultiSignFromAnotherClick(): void;
}

export const ActionsPanel: React.FC<ActionsPanelProps> = ({
  selectedDocuments,
  rowsCount,
  documentStatus,
  onSignClick,
  onMultiSignClick,
  onSelectAllClick,
  onCopyClick,
  onDeleteClick,
  onAddToTemplatesClick,
  onSendToSignClick,
  onCancelDocument,
  getStatementsMultipleFiles,
  onSignFromAnotherClick,
  onMultiSignFromAnotherClick,
}) => {
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { uuid, link, signed, currency, templateType } = selectedDocuments[0];
  const { permissions } = useAppSelector((state): ProfileState => state.profile);

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  const onPrintClickHandler = () => {
    handleCloseMoreMenu();
    window.open(link, '_blank');
  };

  const onCopyClickHandler = () => {
    handleCloseMoreMenu();
    onCopyClick();
  };

  const onDeleteClickHandler = () => {
    handleCloseMoreMenu();
    onDeleteClick();
  };

  const onTemplateClickHandler = () => {
    handleCloseMoreMenu();
    onAddToTemplatesClick();
  };

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setDownloadMenuAnchorEl(null);
  };

  const { totalUah, totalEur, totalUsd } = calculateTotal(selectedDocuments || []);

  const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectAllClick(e.target.checked);
  };

  const { t } = useTranslation();

  const onPreviewClickHandler = () => {
    handleCloseMoreMenu();
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_view.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_view.replace(':uuid', uuid));
  };

  const navigateToEditPayment = () => {
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_edit.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_edit.replace(':uuid', uuid));
  };

  const onEditClickHandler = () => {
    handleCloseMoreMenu();
    if (signed && currency === CurrencyEnum.Uah) {
      setShowConfirmEditPopup(true);
    } else {
      navigateToEditPayment();
    }
  };

  const { mutateAsync } = getStatementsMultipleFiles();

  const fileSelectHandler = (outputType: OutputFormatEnum, format?: string) => {
    const uids = selectedDocuments.map((document) => document.uuid);
    mutateAsync({
      outputType,
      operationsType: StatementTypeEnum.UahOperation,
      ordersUids: uids,
    }).then((responseData) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        if (format) {
          window.open(`${responseData.link}?format=${format}&type=payment-order`, '_blank');
          return;
        }
        window.location.href = `${responseData.link}?format=download&type=payment-order`;
      }
    });
  };

  const handleClickDownloadButton = (event: React.MouseEvent<HTMLElement>) => {
    setDownloadMenuAnchorEl(event.currentTarget);
  };

  const fileSelectMenuItemHandler = (typeFile: OutputFormatEnum) => {
    setDownloadMenuAnchorEl(null);
    fileSelectHandler(typeFile);
  };

  const onPrintMultipleClickHandler = () => {
    handleCloseMoreMenu();
    fileSelectHandler(OutputFormatEnum.Pdf, 'print');
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    navigateToEditPayment();
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const onCancelDocumentHandler = () => {
    setMoreMenuAnchorEl(null);
    onCancelDocument();
  };

  const activeButtonsConfig = getActiveButtonsConfig(documentStatus, selectedDocuments, permissions);

  return (
    <>
      <Grid container justifyContent="space-between" sx={styles.root} alignItems="center" wrap="nowrap">
        <Grid item container xs={9} alignItems="center" style={{ flexWrap: 'nowrap' }}>
          <Checkbox
            indeterminate={selectedDocuments.length > 0 && selectedDocuments.length < rowsCount}
            icon={<CheckboxUncheckedIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            indeterminateIcon={<CheckboxIndeterminateIcon />}
            checked={Boolean(selectedDocuments.length)}
            onChange={onCheckHandler}
          />
          <Typography variant="body2" component="span" sx={styles.selectedText}>
            {`${selectedDocuments.length} ${t('checked')}`}
          </Typography>
          <Box sx={styles.divider} />
          {activeButtonsConfig.sign || activeButtonsConfig.multiSign ? (
            <>
              <Button
                variant="text"
                sx={styles.buttonRoot}
                onClick={activeButtonsConfig.multiSign ? onMultiSignClick : onSignClick}
                startIcon={<SignatureIcon />}
              >
                <Hidden lgDown>
                  <Typography sx={styles.selectedText}>{t('sign')}</Typography>
                </Hidden>
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.signFromAnother || activeButtonsConfig.multiSignFromAnother ? (
            <>
              <Button
                variant="text"
                sx={styles.buttonRoot}
                onClick={activeButtonsConfig.multiSign ? onMultiSignFromAnotherClick : onSignFromAnotherClick}
                startIcon={<SignatureIcon />}
              >
                <Hidden lgDown>
                  <Typography sx={styles.selectedText}>{t('signedByPerson')}</Typography>
                </Hidden>
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.sendToSign ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onSendToSignClick} startIcon={<SendIcon />}>
                <Hidden lgDown>
                  <Typography sx={styles.selectedText}>{t('toSign')}</Typography>
                </Hidden>
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.edit ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onEditClickHandler} startIcon={<EditIcon />}>
                {activeButtonsConfig.edit.short ? null : (
                  <Hidden lgDown>
                    <Typography sx={styles.selectedText}>{t('change')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.view ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onPreviewClickHandler} startIcon={<PreviewIcon />}>
                {activeButtonsConfig.view.short ? null : (
                  <Hidden lgDown>
                    <Typography sx={styles.selectedText}>{t('preview')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.cancel ? (
            <>
              <Button
                variant="text"
                sx={styles.buttonRoot}
                onClick={onCancelDocumentHandler}
                startIcon={<BackCancelIcon />}
              >
                {activeButtonsConfig.cancel.short ? null : (
                  <Hidden lgDown>
                    <Typography variant="inherit">{t('reject')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.download ? (
            <>
              <>
                <Button
                  variant="text"
                  sx={styles.buttonRoot}
                  onClick={handleClickDownloadButton}
                  startIcon={<DownloadIcon />}
                  endIcon={<ArrowDownSelectSmallIcon />}
                />
                <Popover
                  anchorEl={downloadMenuAnchorEl}
                  keepMounted
                  open={Boolean(downloadMenuAnchorEl)}
                  onClose={handleCloseDownloadMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Csv)}>
                    <ListItemIcon>
                      <CsvIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">CSV</Typography>
                  </MenuItem>
                  <>
                    <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Pdf)}>
                      <ListItemIcon>
                        <PdfIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">PDF</Typography>
                    </MenuItem>
                  </>
                  <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Xlsx)}>
                    <ListItemIcon>
                      <XlsIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">XLS</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Xml)}>
                    <ListItemIcon>
                      <XmlIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">XML</Typography>
                  </MenuItem>
                </Popover>
              </>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.copy ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onCopyClickHandler} startIcon={<CopyIcon />}>
                {activeButtonsConfig.copy.short ? null : (
                  <Hidden lgDown>
                    <Typography variant="inherit">{t('copy')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.template ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onTemplateClickHandler} startIcon={<StarIcon />}>
                {activeButtonsConfig.template.short ? null : (
                  <Hidden lgDown>
                    <Typography variant="inherit">{t('addToTemplate')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.print ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onPrintClickHandler} startIcon={<PrintIcon />}>
                {activeButtonsConfig.print.short ? null : (
                  <Hidden lgDown>
                    <Typography sx={styles.selectedText}>{t('print')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.printMultiple ? (
            <>
              <Button
                variant="text"
                sx={styles.buttonRoot}
                onClick={onPrintMultipleClickHandler}
                startIcon={<PrintIcon />}
              >
                {activeButtonsConfig.printMultiple.short ? null : (
                  <Hidden lgDown>
                    <Typography sx={styles.selectedText}>{t('print')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}
          {activeButtonsConfig.delete ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onDeleteClickHandler} startIcon={<DeleteIcon />}>
                {activeButtonsConfig.delete.short ? null : (
                  <Hidden lgDown>
                    <Typography sx={styles.selectedText}>{t('delete')}</Typography>
                  </Hidden>
                )}
              </Button>
              <Box sx={styles.divider} />
            </>
          ) : null}

          {activeButtonsConfig.moreMenu ? (
            <>
              <Button variant="text" sx={styles.buttonRoot} onClick={onMenuBtnClickHandler} startIcon={<MoreIcon />}>
                <Hidden lgDown>
                  <Typography>{t('more')}</Typography>
                </Hidden>
              </Button>

              <Popover
                anchorEl={moreMenuAnchorEl}
                keepMounted
                open={Boolean(moreMenuAnchorEl)}
                onClose={handleCloseMoreMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {activeButtonsConfig.moreMenu.edit ? (
                  <MenuItem sx={styles.menuItem} onClick={onEditClickHandler}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('change')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.view ? (
                  <MenuItem sx={styles.menuItem} onClick={onPreviewClickHandler}>
                    <ListItemIcon>
                      <PreviewIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('preview')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.cancel ? (
                  <MenuItem sx={styles.menuItem} onClick={onCancelDocumentHandler}>
                    <ListItemIcon>
                      <BackCancelIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('reject')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.copy ? (
                  <MenuItem sx={styles.menuItem} onClick={onCopyClickHandler}>
                    <ListItemIcon>
                      <CopyIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('copy')}</Typography>
                  </MenuItem>
                ) : null}

                {activeButtonsConfig.moreMenu.template ? (
                  <MenuItem sx={styles.menuItem} onClick={onTemplateClickHandler}>
                    <ListItemIcon>
                      <StarIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('addToTemplate')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.print ? (
                  <MenuItem sx={styles.menuItem} onClick={onPrintClickHandler}>
                    <ListItemIcon>
                      <PrintIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('print')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.printMultiple ? (
                  <MenuItem sx={styles.menuItem} onClick={onPrintMultipleClickHandler}>
                    <ListItemIcon>
                      <PrintIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('print')}</Typography>
                  </MenuItem>
                ) : null}
                {activeButtonsConfig.moreMenu.delete ? (
                  <MenuItem sx={styles.menuItem} onClick={onDeleteClickHandler}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('delete')}</Typography>
                  </MenuItem>
                ) : null}
              </Popover>
            </>
          ) : null}
        </Grid>
        <Grid item xs={3} sx={styles.totalAmountContainer}>
          <Typography variant="body2" component="span" sx={styles.amountText}>
            {t('amount')}:
          </Typography>
          {totalUah > 0 ? (
            <>
              <Typography variant="body2" component="span" sx={styles.amountValue}>
                {formatAmount(totalUah)}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.amountText}>
                UAH
              </Typography>
            </>
          ) : null}
          {totalUsd > 0 ? (
            <>
              <Typography variant="body2" component="span" sx={styles.amountValue}>
                {formatAmount(totalUsd)}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.amountText}>
                USD
              </Typography>
            </>
          ) : null}
          {totalEur > 0 ? (
            <>
              <Typography variant="body2" component="span" sx={styles.amountValue}>
                {formatAmount(totalEur)}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.amountText}>
                EUR
              </Typography>
            </>
          ) : null}
        </Grid>
      </Grid>

      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

import React, { ReactNode, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format, subDays } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AvailableActionsDto,
  CreateOperationAdditionalInfoDto,
  GetAccountResponseDto,
  GetInternationalBankResponseDto,
  SWIFTCommissionTypeEnum,
  SWIFTUrgencyEnum,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGenerateSwiftOrderNumber } from '@app/core/hooks/useGenerateSwiftOrderNumber';
import { useGetCurrencyOperationsAccount } from '@app/core/hooks/useGetCurrencyAccounts';
import { useGetInternationalBanksList } from '@app/core/hooks/useGetInternationalBanksList';
import { isMobile, formatAmount } from '@app/core/utils';
import { calculateMaxValuationDate } from '@app/pages/payment/create/LocalPayment/helpers';
import { DesktopSwiftPaymentForm } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/DesktopSwiftPaymentForm';
import { MobileSwiftPaymentForm } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/MobileSwiftPaymentForm';
import { validationSchema } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/validationSchema';
import { DefaultValues, SwiftFormData, SwiftFormFields } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface SwiftPaymentFormProps {
  defaultValues?: DefaultValues;
  signPermissions: AvailableActionsDto;
  shareButton?: ReactNode;
  additionalInfo?: CreateOperationAdditionalInfoDto | null;
  onSave(paymentData: SwiftFormData): void;
  onSaveAndSign(paymentData: SwiftFormData, signFromAnotherPerson?: boolean): void;
  onSendToSign(paymentData: SwiftFormData): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const SwiftPaymentForm: React.FC<SwiftPaymentFormProps> = ({
  defaultValues,
  signPermissions,
  additionalInfo,
  shareButton,
  onSave,
  onSaveAndSign,
  onSendToSign,
  onChange,
}) => {
  const [currencyAccounts, setCurrencyAccounts] = useState<GetAccountResponseDto[]>([]);
  const defaultDocumentDate = new Date();
  const maxDocumentDate = addDays(defaultDocumentDate, 30);
  const minDocumentDate = subDays(defaultDocumentDate, 30);
  const [maxValuationDate, setMaxValuationDate] = useState<Date>(calculateMaxValuationDate(defaultDocumentDate));
  const [minValuationDate, setMinValuationDate] = useState<Date>(defaultDocumentDate);
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('');
  const { clientProfile } = useAppSelector((state): ProfileState => state.profile);
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutateAsync: mutateAsyncGetInternationalBanksList } = useGetInternationalBanksList();
  const { data: generatedSwiftOrderNumber, mutate: mutateSwiftOrderNumber } = useGenerateSwiftOrderNumber();
  const { data: accounts, mutate } = useGetCurrencyOperationsAccount();

  useEffect(() => {
    mutate({});
    mutateSwiftOrderNumber({});
  }, [mutate, mutateSwiftOrderNumber]);

  useEffect(() => {
    if (accounts) {
      setCurrencyAccounts(accounts);
    }
  }, [accounts]);

  const { t } = useTranslation();

  const methods = useForm<SwiftFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema({ t })),
  });

  const {
    trigger,
    setValue,
    control,
    getValues,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    onChange({ formDirty: isDirty });
  }, [isDirty, onChange]);

  const amount = useWatch({ control, name: SwiftFormFields.amount });

  const onCancelHandler = () => {
    if (isDirty) {
      setLeavePopupOpen(true);
    } else {
      navigate(RouteList.swift);
    }
  };

  const onSaveAndSignHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign(getValues());
      }
    });
  };

  const onSignFromAnotherPersonHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign(getValues(), true);
      }
    });
  };

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSave(getValues());
      }
    });
  };

  const onSendToSignHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSendToSign(getValues());
      }
    });
  };

  const watchDateFrom = useWatch({
    control,
    name: SwiftFormFields.documentDate,
  });

  const watchBalance = useWatch({ control, name: [`${SwiftFormFields.senderAccount}`, `${SwiftFormFields.amount}`] });

  useEffect(() => {
    const [watchBalanceSenderAccount, watchBalanceAmount] = watchBalance;
    if (watchBalanceSenderAccount && watchBalanceAmount) {
      const {
        account: { amount: selectedAccountAmount },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      const watchBalanceNotEmpty = selectedAccountAmount && watchBalanceAmount;
      if (watchBalanceNotEmpty) {
        setBalance(selectedAccountAmount);
        setShowBalance(true);
      }
    }
  }, [watchBalance]);

  useEffect(() => {
    const [watchBalanceSenderAccount] = watchBalance;
    if (watchBalanceSenderAccount) {
      const {
        account: { currency: selectedAccountCurrency },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      setCurrency(selectedAccountCurrency);
    }
  }, [watchBalance]);

  useEffect(() => {
    if (clientProfile) {
      if (clientProfile.address) {
        setValue(SwiftFormFields.senderAddress, clientProfile.address);
      }

      const name =
        clientProfile.privateEnterpreuner === 1 ? clientProfile.shortName || '' : clientProfile.fullName || '';
      setValue(SwiftFormFields.senderName, name);
    }
  }, [clientProfile, setValue]);

  useEffect(() => {
    if (defaultValues) {
      setValue(SwiftFormFields.recipientName, defaultValues.recipientAccountName);
      setValue(SwiftFormFields.recipientAccount, defaultValues.recipientAccountNumber);
      setValue(SwiftFormFields.amount, `${defaultValues.amount}`);
      setValue(SwiftFormFields.paymentReference, defaultValues.paymentReference);
      setValue(SwiftFormFields.recipientBank, defaultValues.recipientBankName);
      setValue(SwiftFormFields.recipientAddress, defaultValues.recipientAddress);
      setValue(SwiftFormFields.recipientSwiftBic, defaultValues.swiftBic, {
        shouldValidate: false,
      });
      setValue(SwiftFormFields.urgency, defaultValues.urgency as SWIFTUrgencyEnum);
      setValue(SwiftFormFields.commissionType, defaultValues.commissionType as SWIFTCommissionTypeEnum);

      if (defaultValues.documentDate) {
        setValue(SwiftFormFields.documentDate, new Date(defaultValues.documentDate));
      }
      if (defaultValues.documentValueDate) {
        setValue(SwiftFormFields.documentValueDate, new Date(defaultValues.documentValueDate));
        setValue(
          SwiftFormFields.temp_documentValueDate,
          format(new Date(defaultValues.documentValueDate), ddMMYYYYFormat.format)
        );
      }
      if (defaultValues.recipientCorrespondentBankName) {
        setValue(SwiftFormFields.recipientCorrespondentBank, defaultValues.recipientCorrespondentBankName);
      }
      if (defaultValues.swiftBicCorrespondent) {
        setValue(SwiftFormFields.recipientCorrespondentSwiftBic, defaultValues.swiftBicCorrespondent);
      }
      if (defaultValues.operationCode) {
        setValue(SwiftFormFields.operationCode, `${defaultValues.operationCode}`);
      }
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (currencyAccounts && defaultValues?.senderAccountId) {
      const activeAccount = currencyAccounts.find(({ account }) => account.id === defaultValues.senderAccountId);
      if (activeAccount) {
        setValue(SwiftFormFields.senderAccount, JSON.stringify(activeAccount));
      }
    }
  }, [currencyAccounts, defaultValues, setValue]);

  const countedBalance = formatAmount(balance - Number(amount));

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(RouteList.swift);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  const recipientSwiftBicWatch = useWatch({ control, name: SwiftFormFields.recipientSwiftBic });
  const correspondentSwiftBicWatch = useWatch({ control, name: SwiftFormFields.recipientCorrespondentSwiftBic });

  useEffect(() => {
    if (
      recipientSwiftBicWatch &&
      recipientSwiftBicWatch !== defaultValues?.swiftBic &&
      recipientSwiftBicWatch.length >= 8
    ) {
      mutateAsyncGetInternationalBanksList({ swift: recipientSwiftBicWatch }).then(
        (result: GetInternationalBankResponseDto) => {
          if (result.rows.length) {
            setValue(SwiftFormFields.recipientBank, result.rows[0].name);
          } else {
            setValue(SwiftFormFields.recipientBank, '');
          }
        }
      );
    }
  }, [defaultValues?.swiftBic, mutateAsyncGetInternationalBanksList, recipientSwiftBicWatch, setValue]);

  useEffect(() => {
    if (
      correspondentSwiftBicWatch &&
      correspondentSwiftBicWatch !== defaultValues?.swiftBicCorrespondent &&
      correspondentSwiftBicWatch.length >= 4
    ) {
      mutateAsyncGetInternationalBanksList({ swift: correspondentSwiftBicWatch }).then(
        (result: GetInternationalBankResponseDto) => {
          if (result.rows.length) {
            setValue(SwiftFormFields.recipientCorrespondentBank, result.rows[0].name);
          } else {
            setValue(SwiftFormFields.recipientCorrespondentBank, '');
          }
        }
      );
    }
  }, [
    mutateAsyncGetInternationalBanksList,
    correspondentSwiftBicWatch,
    setValue,
    defaultValues?.swiftBicCorrespondent,
  ]);

  useEffect(() => {
    if (watchDateFrom) {
      setMaxValuationDate(calculateMaxValuationDate(watchDateFrom));
      setMinValuationDate(watchDateFrom);
      setValue(SwiftFormFields.documentValueDate, undefined);
    }
  }, [setValue, watchDateFrom]);
  const SwiftForm = isMobile ? MobileSwiftPaymentForm : DesktopSwiftPaymentForm;

  return (
    <>
      <FormProvider {...methods}>
        <form style={{ width: '100%' }}>
          <SwiftForm
            generatedSwiftOrderNumber={generatedSwiftOrderNumber || 'Auto123'}
            maxDocumentDate={maxDocumentDate}
            minDocumentDate={minDocumentDate}
            maxValuationDate={maxValuationDate}
            minValuationDate={minValuationDate}
            currencyAccounts={currencyAccounts}
            recipientCountry={defaultValues?.recipientCountry}
            currency={currency}
            showBalance={showBalance}
            countedBalance={countedBalance}
            signPermissions={signPermissions}
            additionalInfo={additionalInfo}
            shareButton={shareButton}
            isShowCorrespondentBank={Boolean(defaultValues?.swiftBicCorrespondent)}
            onCancelClick={onCancelHandler}
            onSaveClick={onSaveClickHandler}
            onSaveAndSignClick={onSaveAndSignHandler}
            onSendToSignClick={onSendToSignHandler}
            onSaveAndSignFromAnotherPerson={onSignFromAnotherPersonHandler}
          />
        </form>
      </FormProvider>
      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

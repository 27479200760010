import React, { useEffect, useState } from 'react';

import { IconButton, OutlinedInput, Typography, Box } from '@mui/material';
import { useRifm } from 'rifm';

import { LeftChevronIcon, RightChevronIcon } from '@app/common/icons';

import { styles } from './styles';

export interface TablePageInputProps {
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onChangePage(page: number): void;
}
export const TablePageInput: React.FC<TablePageInputProps> = ({ totalCount, page, rowsPerPage, onChangePage }) => {
  const [value, setValue] = useState<string>(page.toString());
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const numberFormat = (str: string): string => {
    const parsedStr = str.slice(0, 10).replace(/[^\d]+/gi, '');
    if (parsedStr.length) {
      const number = parseInt(parsedStr, 10);
      if (number > totalPages) {
        return `${totalPages}`;
      }
      if (number < 1) {
        return '';
      }
      return `${number}`;
    }
    return '';
  };

  useEffect(() => {
    setValue(page.toString());
  }, [page]);

  const rifm = useRifm({
    value,
    onChange: setValue,
    format: numberFormat,
  });

  const handleBackButtonClick = () => {
    if (page > 1) {
      onChangePage(page - 1);
      setValue(`${page - 1}`);
    }
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
    setValue(`${page + 1}`);
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      e.stopPropagation();
      if (value.length) {
        onChangePage(parseInt(value, 10));
      }
    }
  };

  const firstPageClickHandler = () => {
    onChangePage(1);
    setValue(`1`);
  };
  const lastPageClickHandler = () => {
    onChangePage(totalPages);
    setValue(`${totalPages}`);
  };

  const onBlurInputHandler = () => {
    setValue(`${page}`);
  };

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return (
    <Box sx={styles.root}>
      <IconButton
        size="small"
        onClick={handleBackButtonClick}
        disabled={isFirstPage}
        aria-label="previous page"
        sx={styles.navigateButton}
      >
        <LeftChevronIcon />
      </IconButton>
      <IconButton size="small" onClick={firstPageClickHandler} disabled={isFirstPage}>
        <Typography variant="body1" component="span" sx={isFirstPage ? styles.pageLabelInactive : styles.pageLabel}>
          1
        </Typography>
      </IconButton>

      <OutlinedInput
        onKeyPress={onKeyPressHandler}
        value={rifm.value}
        onChange={rifm.onChange}
        onBlur={onBlurInputHandler}
        margin="dense"
        sx={styles.pageInput}
      />
      <IconButton size="small" onClick={lastPageClickHandler} disabled={isLastPage}>
        <Typography variant="body1" component="span" sx={isLastPage ? styles.pageLabelInactive : styles.pageLabel}>
          {totalPages > 0 ? totalPages : ''}
        </Typography>
      </IconButton>

      <IconButton
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= totalPages}
        aria-label="last page"
        sx={styles.navigateButton}
      >
        <RightChevronIcon />
      </IconButton>
    </Box>
  );
};

export type DocumentsColumns = {
  documentNumber: string;
  data: string;
  type: string;
  name: string;
  comment: string;
  connections: string;
  status: string;
  id: number;
};

export enum DocumentsColumnsEnum {
  documentNumber = 'documentNumber',
  data = 'createdAt',
  link = 'link',
  name = 'originalName',
  comment = 'comment',
  relateOperations = 'relateOperations',
  status = 'status',
}

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  counterpartiesApiClient,
  CounterpartyResponseDto,
  getClientId,
  UpdateCounterpartyRequestDto,
} from '@app/core/api';

interface UpdateCounterpartyRequestProps {
  counterpartyData: UpdateCounterpartyRequestDto;
  id: number;
}

const editCounterpartyFn = async ({
  counterpartyData,
  id,
}: UpdateCounterpartyRequestProps): Promise<CounterpartyResponseDto> => {
  const response = await counterpartiesApiClient.counterpartiesControllerUpdateCounterparty(
    id,
    getClientId(),
    counterpartyData
  );
  return response.data;
};

export const useEditCounterparty = (): UseMutationResult<
  CounterpartyResponseDto,
  ApiError,
  UpdateCounterpartyRequestProps
> => {
  return useMutation<CounterpartyResponseDto, ApiError, UpdateCounterpartyRequestProps>(editCounterpartyFn);
};

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@app/pages/statements/mobile/styles';

export const EmptyListText: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Grid container justifyContent="center">
      <Box mt={4} mr={10} ml={10}>
        <Typography variant="body2" align="center" component="div" sx={styles.titleText}>
          {t('mobileStatementsEmptyList')}
        </Typography>
      </Box>
    </Grid>
  );
};

import { GetAccountResponseDto, UpdateOwnPaymentRequestDto } from '@app/core/api';
import { BetweenMyAccountFormData } from '@app/pages/payment/components/BetweenMyAccountEditForm/types';

type EditOwnPaymentData = {
  uuid: string;
  paymentData: BetweenMyAccountFormData;
};

export const makeEditOwnPaymentSubmitData = ({ uuid, paymentData }: EditOwnPaymentData): UpdateOwnPaymentRequestDto => {
  const {
    paymentDocumentNumber,
    paymentDocumentDate,
    paymentDocumentValueDate,
    amount,
    senderAccount: senderAccountInfo,
    recipientAccount: recipientAccountInfo,
    paymentReference,
  } = paymentData;

  const {
    account: { accountNumber: senderAccount },
  }: GetAccountResponseDto = JSON.parse(senderAccountInfo);

  const {
    account: { accountNumber: recipientAccount },
  }: GetAccountResponseDto = JSON.parse(recipientAccountInfo);

  return {
    uuid,
    order: {
      paymentDocumentNumber,
      paymentDocumentDate: paymentDocumentDate.toISOString(),
      paymentDocumentValueDate: paymentDocumentValueDate?.toISOString(),
      amount: parseFloat(String(amount)),
      senderAccount,
      recipientAccount,
      paymentReference,
    },
  };
};

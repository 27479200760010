import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import { CommissionType } from '@app/pages/swift/types';

interface CommissionTypeSelectProps {
  name: string;
  placeholder?: string;
}

export const CommissionTypeSelect: React.FC<CommissionTypeSelectProps> = ({ name, placeholder }) => {
  const { t } = useTranslation();
  const options = Object.values(CommissionType).map((type) => {
    return { id: type, name: t(`swiftCommission_${type}`), value: type };
  });

  return (
    <SelectField
      name={name}
      options={options}
      fullWidth
      defaultValue=""
      renderValue={(selected: any) => {
        if (!selected && placeholder) {
          return (
            <Typography variant="body2" sx={styles.selectPlaceholder}>
              {placeholder}
            </Typography>
          );
        }
        return t(`swiftCommission_${selected}`);
      }}
      displayEmpty={Boolean(placeholder)}
    />
  );
};

import React from 'react';

import { GetSwiftResponseDto } from '@app/core/api';
import { useAddSwiftCounterparty } from '@app/core/hooks/useAddSwiftCounterparty';
import { SwiftCounterpartyModal } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartyModal';
import { SwiftCounterpartyFormData } from '@app/pages/counterpartiesSwift/types';

interface AddCounterpartyProps {
  onClose(): void;
  onSuccessAddCounterparty(data: GetSwiftResponseDto): void;
}

export const AddSwiftCounterparty: React.FC<AddCounterpartyProps> = ({ onClose, onSuccessAddCounterparty }) => {
  const { mutate } = useAddSwiftCounterparty();

  const onSaveCounterpartyHandler = (value: SwiftCounterpartyFormData) => {
    mutate({ ...value, countryCode: value.countryCode.numeric }, { onSuccess: onSuccessAddCounterparty });
  };

  return <SwiftCounterpartyModal onSaveCounterparty={onSaveCounterpartyHandler} show onClose={onClose} />;
};

import React from 'react';

import { Typography, Box } from '@mui/material';

import { AccountStatusEnum } from '@app/core/api';
import { styles } from '@app/pages/accounts/components/FilterPanel/AccountsNavigationMenu/styles';

export type NavigationItem = {
  value: AccountStatusEnum;
  text: React.ReactNode;
};

type MenuItemProps = {
  value: AccountStatusEnum;
  text: React.ReactNode;
  disabled: boolean;
  selected: boolean;
  count: number;
  onClick(status: AccountStatusEnum): void;
};

const MenuItem: React.FC<MenuItemProps> = ({ value, text, disabled, selected, count, onClick }) => {
  const onClickHandler = () => {
    if (onClick && !disabled) {
      onClick(value);
    }
  };

  return (
    <Box sx={styles.itemWrapper}>
      <Typography
        sx={{ ...styles.itemText, ...(selected && styles.selected), ...(disabled && styles.disabled) }}
        variant={selected ? 'body2' : 'caption'}
        component="span"
        onClick={onClickHandler}
      >
        <Box
          component="span"
          sx={{
            ...styles.bullet,
            ...(value === AccountStatusEnum.Blocked && styles.bulletBlock),
            ...(value === AccountStatusEnum.Closed && styles.bulletClose),
          }}
        />
        {text} ({count})
      </Typography>
    </Box>
  );
};

export interface NavigationMenuProps {
  items: NavigationItem[];
  activeCount: number;
  blockedCount: number;
  closedCount: number;
  selectItem: AccountStatusEnum;
  onSelect(currentFilter: AccountStatusEnum): void;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({
  items,
  activeCount,
  blockedCount,
  closedCount,
  selectItem,
  onSelect,
}) => {
  const countSwitch = (status: AccountStatusEnum) => {
    switch (status) {
      case AccountStatusEnum.Blocked:
        return blockedCount;
      case AccountStatusEnum.Closed:
        return closedCount;
      default:
        return activeCount;
    }
  };

  const handleMenuItemClick = (status: AccountStatusEnum) => {
    onSelect(status);
  };

  return (
    <Box sx={styles.root}>
      {items.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          text={item.text}
          count={countSwitch(item.value)}
          disabled={false}
          selected={selectItem === item.value}
          onClick={handleMenuItemClick}
        />
      ))}
    </Box>
  );
};

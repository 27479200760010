import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { profileApiClient, ApiError, UserProfileDto } from '@app/core/api';

const getUserDataFn = async (): Promise<UserProfileDto> => {
  const result = await profileApiClient.profileControllerGetUserProfile();

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get user data!");
  }

  return result.data;
};

export const useGetUserData = (): UseMutationResult<UserProfileDto, ApiError, any> => {
  return useMutation<UserProfileDto, ApiError, any>(getUserDataFn);
};

import { useMutation, UseMutationResult } from 'react-query';

import { dashboardApiClient, getClientId, ApiError, OperationTypeEnum, convertApiEnumToLocal } from '@app/core/api';
import {
  DocumentStatus,
  DateRange,
  PaginationChangeType,
  TablePaginationType,
  Operation,
  OperationsTypeEnum,
} from '@app/core/types';

type GetRecentOperationsParams = {
  accountIds?: Array<string>;
  operationType?: string;
  queryText?: string;
  pagination: PaginationChangeType;
  dateRange?: DateRange;
};

type GetRecentOperationsResult = {
  operations: Array<Operation>;
  pagination: TablePaginationType;
};

export const getRecentOperationsFn = async ({
  accountIds,
  queryText,
  operationType,
  pagination,
  dateRange,
}: GetRecentOperationsParams): Promise<GetRecentOperationsResult> => {
  const response = await dashboardApiClient.dashboardControllerGetRecentOperations(getClientId(), {
    accountIds,
    queryText,
    operationType: operationType as OperationTypeEnum,
    pagination,
    dateRange: dateRange
      ? {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        }
      : undefined,
  });

  if (response.status !== 201) {
    throw new ApiError(response.request.status, "Can't get recent operations.");
  }
  const operations: Array<Operation> = response.data.operations.map((operation) => {
    const { type, status } = operation;
    return {
      ...operation,
      type: convertApiEnumToLocal(OperationsTypeEnum, type),
      status: convertApiEnumToLocal(DocumentStatus, status),
    };
  });

  return {
    operations,
    pagination: response.data.pagination,
  };
};

export const useGetRecentOperations = (): UseMutationResult<
  GetRecentOperationsResult,
  ApiError,
  GetRecentOperationsParams
> => useMutation<GetRecentOperationsResult, ApiError, GetRecentOperationsParams>(getRecentOperationsFn);

import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { AccountDto } from '@app/core/api';
import { parseIBAN } from '@app/core/utils';
import { styles } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/components/SenderAccountSelect/style';

type AccountInfoProps = {
  account: AccountDto;
};

export const SenderAccountInfo: React.FC<AccountInfoProps> = ({ account }) => {
  const { accountPart1, accountPart2 } = parseIBAN(account.accountNumber);
  return (
    <Grid container justifyContent="flex-start" sx={styles.infoContainer}>
      <Grid item xs={5}>
        <Typography sx={styles.accountType} variant="body2">
          {account.accountName}
        </Typography>
      </Grid>
      <Grid container item xs={5}>
        <Typography sx={styles.accountName} variant="body2">
          {accountPart1}
        </Typography>
        <Typography sx={styles.iban} variant="body2">
          {accountPart2}
        </Typography>
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import {
  GetStatementConfigResponseDto,
  StatementTemplateTypeEnum,
  StatementTypeEnum,
  UpdateStatementConfigDto,
} from '@app/core/api';
import { ExpandedTableRow, IBanText, TableCell } from '@app/core/components';
import { getTranslKeyForPeriodType } from '@app/pages/profile/desktop/components/Monitoring/components/StatementsConfigs/helpers';
import { MonitoringStatementsForm } from '@app/pages/profile/desktop/components/Monitoring/components/StatementsConfigs/MonitoringStatementsForm';
import { MonitoringFormData } from '@app/pages/profile/desktop/components/Monitoring/types';

import { styles } from './styles';

interface StatementsConfigRowProps {
  statementsConfig: GetStatementConfigResponseDto;
  open: boolean;
  onOpenExtendedContent(id: string | undefined): void;
  onSave(uuid: string, updateStatementConfigDto: UpdateStatementConfigDto): void;
  onCancel(): void;
  onDelete?(uuid: string): void;
}

export const StatementsConfigRow: React.FC<StatementsConfigRowProps> = ({
  statementsConfig,
  open,
  onOpenExtendedContent,
  onSave,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  const handleExpand = () => {
    onOpenExtendedContent(open ? undefined : statementsConfig.uuid);
  };

  const onSaveHandler = (formData: MonitoringFormData) => {
    const { operationAccounts, periodType, outputType } = formData;

    onSave(statementsConfig.uuid, {
      periodType,
      time: '12:00:00',
      requestData: {
        operationAccounts: operationAccounts.map((account) => ({
          id: account.account.id,
          currencyCode: account.account.currency,
        })),
        outputType,
        documentType: StatementTypeEnum.Statement,
        templateType: StatementTemplateTypeEnum.Default,
      },
    });
  };
  return (
    <ExpandedTableRow
      open={open}
      onExpand={handleExpand}
      extendedContend={
        <MonitoringStatementsForm
          config={statementsConfig}
          onCancel={onCancel}
          onSave={onSaveHandler}
          onDelete={onDelete}
        />
      }
      sx={styles.tableRow}
    >
      <TableCell sx={{ ...styles.tableCell, ...styles.tableCellAccounts }}>
        {statementsConfig.requestData.accountNumber[0] ? (
          <IBanText text={statementsConfig.requestData.accountNumber[0].accountNumber} />
        ) : null}
        {statementsConfig.requestData.accountNumber[1] ? (
          <>
            {', '}
            <IBanText text={statementsConfig.requestData.accountNumber[1].accountNumber} />
          </>
        ) : null}
        {statementsConfig.requestData.accountNumber.length > 2 ? ',...' : null}
      </TableCell>
      <TableCell sx={{ ...styles.tableCell }}>{t(getTranslKeyForPeriodType(statementsConfig.periodType))}</TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.tableCellDelivery }}>
        {statementsConfig.deliveryTypes.join(', ')}
      </TableCell>
      <TableCell component="div" sx={{ ...styles.tableCell, ...styles.actionCell }}>
        {open ? (
          <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
        ) : (
          <DownChevronSmallIcon sx={styles.chevronIcon} fontSize="small" />
        )}
      </TableCell>
    </ExpandedTableRow>
  );
};

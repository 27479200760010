import React, { useState } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GetCurrencyOperationDto, CurrencyOperationStatusEnum, GetCountDocumentByStatusDto } from '@app/core/api';
import { FilterMenuItem } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/FilterPanel/FilterMenuItem';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/FilterPanel/styles';

export interface FilterPanelProps {
  currencyOperations: GetCurrencyOperationDto[];
  countByStatus?: GetCountDocumentByStatusDto;
  onFilterChange(filter?: CurrencyOperationStatusEnum): void;
}

type NavigationItem = {
  id: number;
  value?: CurrencyOperationStatusEnum;
  text: React.ReactNode;
  divide: boolean;
};

export const FilterPanel: React.FC<FilterPanelProps> = ({ onFilterChange, currencyOperations, countByStatus }) => {
  const [selected, setSelected] = useState<CurrencyOperationStatusEnum | undefined>(undefined);
  const { t } = useTranslation();

  const menuItemsDesktop = (): NavigationItem[] => {
    const items = [
      {
        id: 1,
        value: undefined,
        text: t('all'),
        divide: false,
      },
      {
        id: 2,
        value: CurrencyOperationStatusEnum.Executed,
        text: `${t('documentStatus_executed')} (${countByStatus?.executed || 0})`,
        divide: true,
      },
      {
        id: 3,
        value: CurrencyOperationStatusEnum.WaitingForDocument,
        text: `${t('documentStatus_waitingForDocument')} (${countByStatus?.waitingForDocument || 0})`,
        divide: false,
      },
      {
        id: 4,
        value: CurrencyOperationStatusEnum.ToSign,
        text: `${t('toSign')} (${countByStatus?.toSign || 0})`,
        divide: false,
      },
      {
        id: 6,
        value: CurrencyOperationStatusEnum.Saved,
        text: `${t('documentStatus_saved')} (${countByStatus?.saved || 0})`,
        divide: false,
      },
      {
        id: 7,
        value: CurrencyOperationStatusEnum.Processing,
        text: `${t('documentStatus_processing')} (${countByStatus?.processing || 0})`,
        divide: false,
      },
      {
        id: 8,
        value: CurrencyOperationStatusEnum.Rejected,
        text: `${t('rejected')} (${countByStatus?.rejected || 0})`,
        divide: false,
      },
    ];

    if (countByStatus && countByStatus.onSigning > 0) {
      items.push({
        id: 5,
        value: CurrencyOperationStatusEnum.OnSigning,
        text: `${t('onSigning')} (${countByStatus?.onSigning || 0})`,
        divide: false,
      });
    }

    return items;
  };

  const menuItems: NavigationItem[] = menuItemsDesktop().sort((a: NavigationItem, b: NavigationItem) => a.id - b.id);
  const handleMenuItemClick = (currentFilter?: CurrencyOperationStatusEnum) => {
    setSelected(currentFilter);
    onFilterChange(currentFilter);
  };

  return (
    <Box sx={styles.root}>
      {menuItems.map((item) => (
        <FilterMenuItem
          key={item.id}
          value={item.value}
          text={item.text}
          disabled={false}
          isSelected={selected === item.value}
          divide={item.divide}
          onClick={handleMenuItemClick}
        />
      ))}
    </Box>
  );
};

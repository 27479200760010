import React, { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

import { styles } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/ColumnContainer/style';

export const DateColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="body2" sx={styles.date}>
      {children}
    </Typography>
  );
};

export const VisitColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant="body2" sx={styles.visits}>
      {children}
    </Typography>
  );
};

import React, { useRef, useState } from 'react';

import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps, Popover } from '@mui/material';
import { format, isValid, parse, add } from 'date-fns';
import { ActiveModifiers, DayPickerSingleProps } from 'react-day-picker';
import { Rifm } from 'rifm';

import { CalendarIcon } from '@app/common/icons';

import { DatePicker } from './DatePicker';
import { ddMMYYYYFormat } from '../../../constants';
import { appendHyphen, formatDateWithAppend, REGEX_INT } from '../../../utils';

export interface DateSelectInputProps {
  selectedDate?: Date;
  datePickerProps?: Omit<DayPickerSingleProps, 'mode'>;
  inputProps?: OutlinedInputProps;
  onChange(date: Date): void;
}

export const DateSelectInput: React.FC<DateSelectInputProps> = ({
  selectedDate,
  datePickerProps,
  inputProps,
  onChange,
}) => {
  const [strValue, setStrValue] = useState<string>(selectedDate ? format(selectedDate, ddMMYYYYFormat.format) : '');
  const [value, setValue] = useState<Date | undefined>(selectedDate);
  const inputItemRef = useRef<HTMLDivElement>(null);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const onPopoverClose = () => {
    setCalendarOpen(false);
  };

  const onCalendarClickHandler = () => {
    setCalendarOpen(!calendarOpen);
  };
  const rifmOnChangeController = (str: string) => {
    setStrValue(str);
  };

  const onBlurInputHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const dateStr = event.target.value;
    const newDate = parse(dateStr, ddMMYYYYFormat.format, new Date());
    if (isValid(newDate)) {
      setStrValue(format(newDate, ddMMYYYYFormat.format));
      setValue(newDate);
      onChange(newDate);
    }
  };

  const onDayClickHandler = (date: Date, activeModifiers: ActiveModifiers) => {
    if (activeModifiers.disabled) {
      return;
    }
    const resultDate = add(date, { hours: 12 });
    setValue(resultDate);
    setStrValue(format(resultDate, ddMMYYYYFormat.format));
    setCalendarOpen(false);
    onChange(resultDate);
  };

  return (
    <>
      <Rifm
        value={strValue}
        onChange={rifmOnChangeController}
        accept={REGEX_INT}
        mask
        format={(v) => formatDateWithAppend(v, ddMMYYYYFormat.hyphen)}
        append={(v) => appendHyphen(v, ddMMYYYYFormat.hyphen)}
        replace={ddMMYYYYFormat.mask}
      >
        {({ value: newRifmValue, onChange: rifmOnChange }) => (
          <OutlinedInput
            value={newRifmValue}
            ref={inputItemRef}
            onChange={rifmOnChange}
            onBlur={onBlurInputHandler}
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={onCalendarClickHandler}>
                  <CalendarIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            {...inputProps}
          />
        )}
      </Rifm>
      <Popover
        open={calendarOpen}
        anchorEl={inputItemRef.current}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePicker
          defaultMonth={value || new Date()}
          selected={value}
          showOutsideDays
          onDayClick={onDayClickHandler}
          mode="single"
          {...datePickerProps}
        />
      </Popover>
    </>
  );
};

export enum LogTagName {
  userId = 'user.id',
  clientId = 'client.id',
}
export enum LogTagErrorName {
  errorType = 'error.type',
  errorSource = 'error.source',
}

export enum LogTagErrorType {
  SignError = 'SignError',
  SignApiError = 'SignApiError',
  SignCurrencyExchangeApiError = 'SignCurrencyExchangeApiError',
  CryptoLibraryError = 'CryptoLibraryError',
}

export enum LogTagErrorSource {
  cryptoLibrary = 'cryptoLibrary',
  internalValidations = 'internalValidations',
  form = 'form',
  api = 'api',
}

import React, { useEffect, useState } from 'react';

import { Box, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { CurrencySystemInfoDto } from '@app/core/api';
import { RouteList } from '@app/src/constants/routeList';

import { CurrencyBankInfo } from './CurrencyBankInfo';
import { CurrencyNBUInfo } from './CurrencyNBUInfo';
import { CurrencyTab } from './CurrencyTab';
import { CurrencyTabPanel } from './CurrencyTabPanel';
import { getExchangeRates } from './query';

export const Currency: React.FC = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { data, refetch } = useQuery<CurrencySystemInfoDto>('getExchangeRates', getExchangeRates, {
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // setValue(newValue);
    if (newValue !== 2) {
      setValue(newValue);
    }
  };

  const nbuRates = data?.nbu;
  const bankRates = data?.bank;

  const navigateToExchangeRate = () => {
    navigate(RouteList.exchange_rate);
  };

  return (
    <Box
      sx={{
        paddingTop: '12px',
        width: '100%',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          minHeight: 'initial',
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
          },
        }}
      >
        <CurrencyTab label={t('bankShortName')} />
        <CurrencyTab label={t('NBU')} />
        <CurrencyTab label={t('otherCurrencyVariants')} onClick={navigateToExchangeRate} />
      </Tabs>
      <CurrencyTabPanel value={value} index={0}>
        {bankRates ? <CurrencyBankInfo rates={bankRates} /> : null}
      </CurrencyTabPanel>
      <CurrencyTabPanel value={value} index={1}>
        {nbuRates ? <CurrencyNBUInfo rates={nbuRates} /> : null}
      </CurrencyTabPanel>
      <CurrencyTabPanel value={value} index={2} />
    </Box>
  );
};

import {
  AvailableActionsDto,
  CountryCodeDto,
  CreateTemplateRequestDto,
  DocumentTemplateDto,
  GetDefaultAccountsResponseDto,
  PaymentOrderAdditionalInfoDto,
} from '@app/core/api';
import { PassportTabsEnum } from '@app/core/components/PassportTabs';
import { Counterparty } from '@app/core/types';

export enum FormFieldName {
  paymentDocumentNumber = 'paymentDocumentNumber',
  documentDate = 'documentDate',
  documentValuationDate = 'documentValuationDate',
  senderAccount = 'senderAccount',
  recipientName = 'recipientName',
  recipientLei = 'recipientLei',
  recipientAccount = 'recipientAccount',
  nonResidentFlag = 'nonResidentFlag',
  amount = 'amount',
  vat = 'vat',
  purpose = 'purpose',
  countryCode = 'countryCode',
  recipientPassport = 'recipientPassport',
  idPassport = 'idPassport',
  recipientNonLeiFlag = 'recipientNonLeiFlag',
  realSenderName = 'realSenderName',
  realSenderTIN = 'realSenderTIN',
  realSenderNonResidentFlag = 'realSenderNonResidentFlag',
  realSenderCountryCode = 'realSenderCountryCode',
  realSenderNonLeiFlag = 'realSenderNonLeiFlag',
  realSenderPassport = 'realSenderPassport',
  realRecipientName = 'realRecipientName',
  realRecipientTIN = 'realRecipientTIN',
  realRecipientNonResidentFlag = 'realRecipientNonResidentFlag',
  realRecipientCountryCode = 'realRecipientCountryCode',
  realRecipientNonLeiFlag = 'realRecipientNonLeiFlag',
  realRecipientPassport = 'realRecipientPassport',
  realSenderPresent = 'realSenderPresent',
  realRecipientPresent = 'realRecipientPresent',
  _vatSwitch = '_vatSwitch',
  _bankName = '_bankName',
  temp_documentValuationDate = 'temp_documentValuationDate',
  temp_documentDate = 'temp_documentDate',
}

export type DefaultValues = {
  senderAccountId?: string;
  documentNumber?: string;
  recipientAccountName?: string;
  recipientAccountNumber?: string;
  recipientLei?: string;
  recipientPassport?: string;
  amount?: number;
  paymentReference?: string;
  recipientBankName?: string;
  paymentDocumentDate?: string;
  paymentDocumentValueDate?: string;
  nonResidentFlag?: boolean;
  recipientNonLeiFlag: boolean;
  countryCode?: string;
  documentType?: string;
  realSenderName?: string;
  realSenderTIN?: string;
  realSenderNonResidentFlag?: boolean;
  realSenderCountryCode?: string;
  realSenderNonLeiFlag: boolean;
  realSenderPassport?: string;
  realRecipientName?: string;
  realRecipientTIN?: string;
  realRecipientNonResidentFlag?: boolean;
  realRecipientCountryCode?: string;
  realRecipientNonLeiFlag: boolean;
  realRecipientPassport?: string;
};

export type PaymentFormData = {
  paymentDocumentNumber: string;
  documentDate: Date;
  [FormFieldName.documentValuationDate]?: Date;
  senderAccount: string;
  recipientName: string;
  [FormFieldName.recipientLei]?: string;
  recipientAccount: string;
  nonResidentFlag: boolean;
  amount: string;
  vat: number;
  purpose: string;
  countryCode?: CountryCodeDto;
  idPassport?: string;
  [FormFieldName.recipientNonLeiFlag]: boolean;
  [FormFieldName.recipientPassport]?: string;
  [FormFieldName.realSenderName]?: string;
  [FormFieldName.realSenderTIN]?: string;
  [FormFieldName.realSenderNonResidentFlag]?: boolean;
  [FormFieldName.realSenderCountryCode]?: CountryCodeDto;
  [FormFieldName.realSenderNonLeiFlag]: boolean;
  [FormFieldName.realSenderPassport]?: string;
  [FormFieldName.realRecipientName]?: string;
  [FormFieldName.realRecipientTIN]?: string;
  [FormFieldName.realRecipientNonResidentFlag]?: boolean;
  [FormFieldName.realRecipientCountryCode]?: CountryCodeDto;
  [FormFieldName.realRecipientNonLeiFlag]: boolean;
  [FormFieldName.realRecipientPassport]?: string;
  [FormFieldName.realSenderPresent]: boolean;
  [FormFieldName.realRecipientPresent]: boolean;
  _vatSwitch: boolean;
  _bankName: string;
  [FormFieldName.temp_documentDate]: string;
  [FormFieldName.temp_documentValuationDate]?: string;
};

export interface LocalPaymentEditFormProps {
  defaultValues: DefaultValues;
  defaultDocumentDate: Date;
  maxDocumentDate: Date;
  minDocumentDate: Date;
  maxValuationDate: Date;
  minValuationDate: Date;
  senderAccountsData: GetDefaultAccountsResponseDto | undefined;
  passportActiveTab: PassportTabsEnum;
  vatEnabled: boolean;
  showBalance: boolean;
  countedBalance: string;
  signPermissions: AvailableActionsDto;
  recipientAccountDisabled: boolean;
  additionalInfo?: PaymentOrderAdditionalInfoDto | null;
  onSelectCounterpartyHandler(counterparty: Counterparty): void;
  clearRecipientHandler(): void;
  passportTabsChangeHandler(activeTab: PassportTabsEnum): void;
  vatSwitchHandler(): void;
  onCancelClick(): void;
  onSaveClick(): void;
  onSaveAndSignClick(): void;
  onSendToSign(): void;
  onSaveAndSignFromAnotherPerson(): void;
  onTemplateChangeHandler?(templateData: DocumentTemplateDto): void;
  documentType?: string;
  createTemplateData(): Omit<CreateTemplateRequestDto, 'templateName'>;
}

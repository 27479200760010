import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  CreateSwiftOperationRequestDto,
  CreateSwiftOperationResponseDto,
  getClientId,
  swiftApiClient,
} from '@app/core/api';

const createSwiftPaymentFn = async (
  paymentData: CreateSwiftOperationRequestDto
): Promise<CreateSwiftOperationResponseDto> => {
  const result = await swiftApiClient.swiftControllerCreateSwiftOperation(getClientId(), paymentData);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "swiftControllerCreateSwiftOperation error. Can't create operation.");
  }

  return result.data;
};

export const useCreateSwiftPayment = (): UseMutationResult<
  CreateSwiftOperationResponseDto,
  ApiError,
  CreateSwiftOperationRequestDto
> => {
  return useMutation<CreateSwiftOperationResponseDto, ApiError, CreateSwiftOperationRequestDto>(createSwiftPaymentFn);
};

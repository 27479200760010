import React, { useEffect, useRef } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetTurnovers } from '@app/core/hooks/useGetTurnovers';
import { DateRange, PaginationChangeType, SortingRule, AccountSelectType } from '@app/core/types';
import { formatAmount } from '@app/core/utils';
import { TurnoversTable } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable';
import { styles } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/style';

interface TurnoversProps {
  account: AccountSelectType;
  dateRange: DateRange;
}

export const Turnovers: React.FC<TurnoversProps> = ({ account, dateRange }) => {
  const { t } = useTranslation();
  const sortByRef = useRef<SortingRule>();
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });

  const { data, mutate, isLoading } = useGetTurnovers();

  useEffect(() => {
    mutate({
      accountId: account.account.id,
      dateRange: {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      },
      sortBy: sortByRef.current,
      pagination: paginationRef.current,
    });
  }, [account, dateRange, mutate]);

  const onSortHandler = (rule: SortingRule) => {
    sortByRef.current = rule;
    mutate({
      accountId: account.account.id,
      dateRange: {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      },
      sortBy: sortByRef.current,
      pagination: paginationRef.current,
    });
  };

  const paginationChangeHandler = (pagination: PaginationChangeType) => {
    paginationRef.current = pagination;
    mutate({
      accountId: account.account.id,
      dateRange: {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      },
      sortBy: sortByRef.current,
      pagination: paginationRef.current,
    });
  };

  const turnovers = data?.balances || [];
  const pagination = data?.pagination;
  const total = data?.total;

  return turnovers.length && dateRange ? (
    <>
      <Box mb={1}>
        <Grid container direction="column" sx={styles.movementContainer}>
          {data?.total ? (
            <Grid container sx={styles.movementItem}>
              <Grid item xs={3}>
                <Typography sx={styles.totalHeader}>{t('selectedAccount_resultTurnoversHeader')}:</Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid container item justifyContent="flex-end" xs={2}>
                <Box pr={5}>
                  <Typography sx={styles.deposit}>
                    + {formatAmount(data.total.deposit)} {account.account.currency}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Box pl={4}>
                  <Typography sx={styles.withdrawal}>
                    - {formatAmount(data.total.withdrawal)} {account.account.currency}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}

          {data?.today ? (
            <Grid container sx={styles.movementItem}>
              <Grid item xs={3}>
                <Typography sx={styles.totalHeader}>{t('for_today')}:</Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid container item justifyContent="flex-end" xs={2}>
                <Box pr={5}>
                  <Typography sx={styles.deposit}>
                    + {formatAmount(data.today.deposit)} {account.account.currency}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Box pl={4}>
                  <Typography sx={styles.withdrawal}>
                    - {formatAmount(data.today.withdrawal)} {account.account.currency}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box pt={4}>
        <TurnoversTable
          accountId={account.account.id}
          turnovers={turnovers}
          pagination={pagination}
          dateRange={dateRange}
          loading={Boolean(isLoading)}
          onPaginationChange={paginationChangeHandler}
          onSort={onSortHandler}
        />
      </Box>
    </>
  ) : (
    <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
      {!total ? <Typography variant="body2">{t('empty_turnover_operation')}</Typography> : null}
    </Grid>
  );
};

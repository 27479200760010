import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, paymentOrdersApiClient, ApiError } from '@app/core/api';

export const generatePaymentOrderNumberFn = async (): Promise<string> => {
  const result = await paymentOrdersApiClient.paymentOrderControllerGeneratePaymentOrderNumber(getClientId());
  if (result.status !== 200) {
    throw new ApiError(
      result.request.status,
      "paymentOrderControllerGeneratePaymentOrderNumber error. Can't generate document number."
    );
  }
  return result.data.generatedDocumentNumber;
};

export const useGeneratePaymentOrderNumber = (): UseQueryResult<string, ApiError> => {
  return useQuery('generatePaymentOrderNumber', generatePaymentOrderNumberFn, {
    enabled: true,
    refetchOnMount: true,
  });
};

import React from 'react';

import { CounterpartyRequestDto } from '@app/core/api';
import { useAddCounterparty } from '@app/core/hooks/useAddCounterparty';
import { CounterpartiesModal } from '@app/pages/counterparties/components/CounterpartiesModal';
import { CounterpartyFormData, FormMode } from '@app/pages/counterparties/types';

interface AddCounterpartyProps {
  onClose(): void;
  onSuccessAddCounterparty(data: CounterpartyRequestDto): void;
}

export const AddCounterparty: React.FC<AddCounterpartyProps> = ({ onClose, onSuccessAddCounterparty }) => {
  const { mutate } = useAddCounterparty();

  const onSaveCounterpartyHandler = (value: CounterpartyFormData) => {
    mutate(value, { onSuccess: onSuccessAddCounterparty });
  };

  return (
    <CounterpartiesModal show mode={FormMode.create} onClose={onClose} onSaveCounterparty={onSaveCounterpartyHandler} />
  );
};

import { parseDigits } from '../utils';

export const dateRangeFormat = 'dd.MM.yyyy';

type DateFormat = {
  format: string;
  hyphen: string;
  mask(str: string): string;
};

export const ddMMYYYYFormat: DateFormat = {
  format: dateRangeFormat,
  hyphen: '.',
  mask: (str: string): string => {
    const digits = parseDigits(str);
    const days = digits.slice(0, 2).padEnd(2, '_');
    const months = digits.slice(2, 4).padEnd(2, '_');
    const years = digits.slice(4, 8).padEnd(4, '_');
    return `${days}.${months}.${years}`;
  },
};

export const tokenLifetime = 15 * 60 * 1000;

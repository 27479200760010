import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { ModalProps } from '@mui/material/Modal/Modal';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { ReactComponent as ErrorSvgIconSvg } from '@app/themes/default/assets/icons/error.svg';
import { ReactComponent as IconBgErrorSvg } from '@app/themes/default/assets/icons/modalBgError.svg';
import { ReactComponent as IconBgSuccessSvg } from '@app/themes/default/assets/icons/modalBgSuccess.svg';
import { ReactComponent as SuccessSvgIconSvg } from '@app/themes/default/assets/icons/success.svg';

import { styles } from './styles';
import { ModalCommon } from '../Modal';

interface StatusLargePopupProps {
  show?: boolean;
  text: React.ReactNode;
  icon?: React.ReactNode;
  status: 'success' | 'error';
  buttonText?: string;
  modalProps?: ModalProps;
  onOk(): void;
  onClose(): void;
}

export const StatusLargePopup: React.FC<StatusLargePopupProps> = ({
  show = false,
  text,
  icon,
  status,
  buttonText,
  modalProps,
  onOk,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <ModalCommon open={show} {...modalProps}>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>{icon || (status === 'success' ? <IconBgSuccessSvg /> : <IconBgErrorSvg />)}</Box>
        <Grid container direction="column" alignItems="center">
          <Grid justifyContent="flex-end" container>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Box mt={3}>{status === 'success' ? <SuccessSvgIconSvg /> : <ErrorSvgIconSvg />}</Box>
          <Box mt={3}>
            <Typography
              variant="h6"
              sx={{
                ...(status === 'success' && styles.successText),
                ...(status === 'error' && styles.errorText),
              }}
            >
              {text}
            </Typography>
          </Box>
          <Box mt={7} mb={2}>
            <Button variant="outlined" color="primary" onClick={onOk}>
              {buttonText || t('ok_button')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

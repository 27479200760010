export const styles = {
  mainContent: {
    marginTop: '12px',
  },
  paper: {
    backgroundColor: 'white',
    padding: '32px 28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '570px',
    minHeight: '230px',
    overflow: 'hidden',
    position: 'relative',
  },
  successText: {
    color: 'colors.success',
  },
  errorText: {
    color: 'colors.error',
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
};

import React from 'react';

import { Theme, styled, SxProps } from '@mui/material';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { ThemeEnum } from '../../types';
import { isMobile } from '../../utils';

export type Color = 'inherit' | 'primary' | 'secondary' | 'default';

interface TooltipCommonProps extends TooltipProps {
  color: Color;
  withBorder?: boolean;
  styles?: {
    tooltip?: SxProps<Theme>;
    arrow?: SxProps<Theme>;
  };
}

export const Tooltip = styled(({ className, color, withBorder, ...props }: TooltipCommonProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme, color, withBorder, styles }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px 8px',
    backgroundColor:
      color === 'secondary'
        ? theme.palette.colors.tooltipSecondaryBackground
        : theme.palette.colors.tooltipDefaultBackground,
    color:
      color === 'secondary'
        ? theme.palette.colors.tooltipSecondaryTextColor
        : theme.palette.colors.tooltipDefaultTextColor,
    border:
      theme.themeName === ThemeEnum.light || isMobile || withBorder
        ? `1px solid ${theme.palette.colors.gray100}`
        : 'initial',
    fontSize: isMobile ? '0.825rem' : '0.75rem',
    ...styles?.tooltip,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color:
      color === 'secondary'
        ? theme.palette.colors.tooltipSecondaryBackground
        : theme.palette.colors.tooltipDefaultBackground,
    '&::before': {
      border:
        theme.themeName === ThemeEnum.light || isMobile || withBorder
          ? `1px solid ${theme.palette.colors.gray100}`
          : 'initial',
    },
    ...styles?.arrow,
  },
}));

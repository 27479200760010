import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { CellContext, ColumnDef, HeaderContext } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CollateralDto, DealStateEnum } from '@app/core/api';

import { SafetyTableColumns } from './types';
import { formatAmount } from '../../utils';
import { HeaderSortedWrapper } from '../ComplexTable';
import { Ellipsis } from '../Ellipsis';

interface MakeTableColumnsProps {
  t(text: string): string;
}
type StatusFieldProps = {
  status: string;
};

const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Box
        component="span"
        mr={1}
        sx={{
          display: 'inline-block',
          height: '8px',
          width: '8px',
          borderRadius: '8px',
          backgroundColor: 'colors.warning',
          ...((status === DealStateEnum.New || status === DealStateEnum.Active) && {
            backgroundColor: 'colors.success',
          }),
          ...((status === DealStateEnum.Deleted || status === DealStateEnum.Closed) && {
            backgroundColor: 'colors.error',
          }),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(status)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<CollateralDto, any>[] => {
  return [
    {
      accessorKey: SafetyTableColumns.agreementStartDate,
      header: (props: HeaderContext<CollateralDto, string>) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper<CollateralDto> {...props}>
              <Typography variant="caption">{t(`startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, string>) => {
        const value = getValue();
        return <>{value ? format(new Date(value.split('T')[0]), 'dd.MM.yyyy') : ''}</>;
      },
    },
    {
      accessorKey: SafetyTableColumns.agreementEndDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, string>) => {
        const value = getValue();
        return <>{value ? format(new Date(value.split('T')[0]), 'dd.MM.yyyy') : ''}</>;
      },
    },
    {
      accessorKey: SafetyTableColumns.agreementNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`agreementNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: SafetyTableColumns.securityDescription,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`securityDescription`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: SafetyTableColumns.lastEvaluationDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`lastEvaluationDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, string>) => {
        const value = getValue();
        return <>{value ? format(new Date(value.split('T')[0]), 'dd.MM.yyyy') : ''}</>;
      },
    },
    {
      accessorKey: SafetyTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: SafetyTableColumns.currencyCode,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: SafetyTableColumns.state,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`status`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<CollateralDto, string>) => {
        return <StatusField status={getValue()} />;
      },
    },
  ];
};

import { addDays, isDate, parse } from 'date-fns';
import { date, object, SchemaOf, string } from 'yup';

import { AccountStatusEnum, GetAccountResponseDto } from '@app/core/api';
import { DATE_FORMAT, isValidUAIBAN, parseJSON } from '@app/core/utils';
import { FormFieldName } from '@app/pages/payment/components/LocalPaymentEditForm/types';

type SchemaValidationObject = {
  [FormFieldName.recipientAccount]: string;
  [FormFieldName.recipientLei]?: string;
  [FormFieldName.recipientName]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.senderAccount]: string;
  [FormFieldName.idPassport]?: string;
  [FormFieldName.recipientPassport]?: string;
  [FormFieldName.temp_documentDate]: Date;
  [FormFieldName.purpose]: string;
  [FormFieldName.realSenderName]?: string;
  [FormFieldName.realSenderTIN]?: string;
  [FormFieldName.realRecipientName]?: string;
  [FormFieldName.realRecipientTIN]?: string;
};

type NewPaymentValidationSchemaProps = {
  t: (key: string) => string;
};

export const validationSchema = ({ t }: NewPaymentValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  const maxDocumentDate = addDays(new Date(), 30);
  const parseDateString = (value: string, originalValue: string) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, DATE_FORMAT, new Date());
  };

  return object().shape({
    [FormFieldName.recipientAccount]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(
        FormFieldName.recipientAccount,
        t('paymentCreationLocalPayment_recipient_error_format'),
        (iban: string | undefined): boolean => {
          if (iban) {
            return isValidUAIBAN(iban);
          }
          return false;
        }
      )
      .test(
        FormFieldName.recipientAccount,
        t('paymentCreationLocalPayment_sender_recipient_identical_error'),
        (iban: string | undefined, context): boolean => {
          const account = parseJSON(context.parent[FormFieldName.senderAccount]) as unknown as GetAccountResponseDto;
          return iban !== account?.account?.accountNumber;
        }
      ),
    [FormFieldName.recipientLei]: string().when([FormFieldName.recipientNonLeiFlag], {
      is: false,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .test(
          'recipientLei',
          t('paymentCreationLocalPayment_lei_error'),
          (val) => val?.length === 8 || val?.length === 10
        ),
    }),
    [FormFieldName.recipientName]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.amount]: string()
      .min(1, t('paymentCreationLocalPayment_field_required'))
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(FormFieldName.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
        if (amount) {
          return parseFloat(amount) >= 0.01;
        }
        return false;
      }),
    [FormFieldName.senderAccount]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test('senderAccount', (value, { createError, path }) => {
        if (value) {
          const account = parseJSON(value) as unknown as GetAccountResponseDto;
          if (account.account.status === AccountStatusEnum.Closed) {
            return createError({
              path,
              message: t('warningClosedAccount'),
            });
          }
          if (account.account.status === AccountStatusEnum.Blocked) {
            return createError({
              path,
              message: t('warningBlockedAccount'),
            });
          }
        }
        return true;
      }),
    [FormFieldName.idPassport]: string().min(10, t('paymentCreationLocalPayment_correct_passport')),
    [FormFieldName.recipientPassport]: string().when([FormFieldName.recipientNonLeiFlag], {
      is: true,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .min(9, t('paymentCreationLocalPayment_correct_passport')),
    }),
    [FormFieldName.temp_documentDate]: date()
      .required(t('paymentCreationLocalPayment_field_required'))
      .typeError(t('paymentDocumentDate_correct_date'))
      .transform(parseDateString)
      .max(maxDocumentDate, t('paymentDocumentDate_correct_date')),
    [FormFieldName.purpose]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .trim()
      .min(3, t('paymentCreationLocalPayment_field_minLetters'))
      .test('purpose', t('paymentCreationLocalPayment_field_minLetters'), (val) => {
        if (val && val.length === 3) {
          return !/\s/g.test(val.slice(0, 2));
        }
        return true;
      }),
    [FormFieldName.realSenderName]: string().when([FormFieldName.realSenderPresent], {
      is: true,
      then: string().required(t('paymentCreationLocalPayment_field_required')),
    }),
    [FormFieldName.realSenderTIN]: string().when([FormFieldName.realSenderPresent], {
      is: true,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .test(
          'recipientLei',
          t('paymentCreationLocalPayment_lei_error'),
          (val) => val?.length === 8 || val?.length === 10
        ),
    }),
    [FormFieldName.realRecipientName]: string().when([FormFieldName.realRecipientPresent], {
      is: true,
      then: string().required(t('paymentCreationLocalPayment_field_required')),
    }),
    [FormFieldName.realRecipientTIN]: string().when([FormFieldName.realRecipientPresent], {
      is: true,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .test(
          'recipientLei',
          t('paymentCreationLocalPayment_lei_error'),
          (val) => val?.length === 8 || val?.length === 10
        ),
    }),
  });
};

import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { StatusText } from '@app/core/components';
import { BicInput, BicInputProps } from '@app/core/components/BicInput';

interface BICFieldProps extends Omit<BicInputProps, 'onChange' | 'value' | 'defaultValue'> {
  name: string;
  defaultValue?: string;
}

export const BICField: React.FC<BICFieldProps> = ({ name, defaultValue = '', ...otherProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value: currentValue, onChange }, fieldState: { error } }) => (
        <>
          <BicInput name={name} value={currentValue} onChange={onChange} {...otherProps} />
          {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
        </>
      )}
    />
  );
};

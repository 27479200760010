import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { PageContent } from '@app/pages/dashboard/view/PageContent';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const DashboardPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader title={t('accountsAndTransactions')} useDesktopStyle>
        {permissions.uahOperations.create ? (
          <Link to={RouteList.payment_create}>
            <Button variant="contained" color="primary">
              {t('dashboardCreatePaymentBtn')}
            </Button>
          </Link>
        ) : null}
      </PageHeader>
      <PageContainer>
        <PageContent />
      </PageContainer>
    </PageWrapper>
  );
};

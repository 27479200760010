import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  AvailableActionsDto,
  CreatePaymentOrderResultDto,
  PaymentOrderStatusEnum,
  PaymentOrderAdditionalInfoDto,
  ResultStatusEnum,
  SignPermissionsDto,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { SignPaymentOrder } from '@app/core/components/SignPaymentOrder';
import { useGetOrderByUuid } from '@app/core/hooks';
import { useEditPaymentOrder } from '@app/core/hooks/useEditPaymentOrder';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { isMobile } from '@app/core/utils';
import { LocalPaymentEditForm } from '@app/pages/payment/components/LocalPaymentEditForm';
import { DefaultValues, PaymentFormData } from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { PaymentInfo } from '@app/pages/payment/components/PaymentInfo';
import { makeEditPaymentSubmitData } from '@app/pages/payment/edit/LocalPayment/helpers';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface EditPaymentProps {
  paymentData: PaymentFormData;
  onEditSuccess(operation: CreatePaymentOrderResultDto): void;
  onEditError(additionalInfo?: PaymentOrderAdditionalInfoDto): void;
}

export const EditLocalPaymentPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const { uuid = 'no-uuid' } = useParams();
  const showLeavePopupRef = useRef<boolean>(false);
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [editPaymentResultOperation, setEditPaymentResultOperation] = useState<CreatePaymentOrderResultDto | null>();
  const [defaultValues, setDefaultValues] = useState<DefaultValues>({
    recipientNonLeiFlag: false,
    realSenderNonLeiFlag: false,
    realRecipientNonLeiFlag: false,
  });
  const [signPermissions, setSignPermissions] = useState<AvailableActionsDto>({
    ...permissions.sign.paymentOrder,
    allowSignFromAnotherPerson: permissions.sign.paymentOrder.signFromAnotherPerson,
  });
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean>();
  const { data: paymentOrder, refetch: refetchPaymentOrder } = useGetOrderByUuid(uuid);
  const { data: editPaymentResultData, mutateAsync, reset: resetCreatePayment } = useEditPaymentOrder();
  const { data: sendToSignPaymentResultData, mutate: mutateSendToSign } = useSendToSignPaymentOrder();

  const editPayment = ({ paymentData, onEditSuccess, onEditError }: EditPaymentProps) => {
    mutateAsync(
      makeEditPaymentSubmitData({
        uuid,
        paymentData,
      })
    )
      .then((result) => {
        if (result.operation) {
          onEditSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onEditError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onEditError();
      });
  };

  useEffect(() => {
    refetchPaymentOrder();
  }, [refetchPaymentOrder]);

  useEffect(() => {
    if (paymentOrder) {
      const recipientNonLeiFlag = paymentOrder.details.recipientAccount.nonLeiFlag;
      setDefaultValues({
        senderAccountId: paymentOrder.account.id,
        documentNumber: paymentOrder.documentNumber,
        recipientAccountName: paymentOrder.details.recipientAccount.name,
        recipientAccountNumber: paymentOrder.details.recipientAccount.accountNumber,
        recipientLei: recipientNonLeiFlag ? undefined : paymentOrder.details.recipientAccount.LEI,
        recipientPassport: recipientNonLeiFlag ? paymentOrder.details.recipientAccount.passport : undefined,
        amount: paymentOrder.amount,
        paymentReference: paymentOrder.paymentReference,
        recipientBankName: paymentOrder.details.recipientAccount.bankName,
        paymentDocumentDate: paymentOrder.details.date,
        nonResidentFlag: paymentOrder.nonResidentFlag,
        recipientNonLeiFlag,
        documentType: paymentOrder.documentType,
        countryCode: paymentOrder.countryCode,
        realSenderName: paymentOrder.realSenderName,
        realSenderTIN: paymentOrder.realSenderTIN,
        realSenderNonResidentFlag: paymentOrder.realSenderNonResidentFlag,
        realSenderCountryCode: paymentOrder.realSenderCountryCode,
        realSenderNonLeiFlag: paymentOrder.realSenderNonLeiFlag,
        realSenderPassport: paymentOrder.realSenderPassport,
        realRecipientName: paymentOrder.realRecipientName,
        realRecipientTIN: paymentOrder.realRecipientTIN,
        realRecipientNonResidentFlag: paymentOrder.realRecipientNonResidentFlag,
        realRecipientCountryCode: paymentOrder.realRecipientCountryCode,
        realRecipientNonLeiFlag: paymentOrder.realRecipientNonLeiFlag,
        realRecipientPassport: paymentOrder.realRecipientPassport,
      });
      setSignPermissions(paymentOrder.actions);
    }
  }, [paymentOrder]);

  const onSaveHandler = (paymentData: PaymentFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess() {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(-1);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignHandler = (paymentData: PaymentFormData, signFromAnotherPers?: boolean) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        setSignFromAnotherPerson(signFromAnotherPers);
        showLoader(false);
        setEditPaymentResultOperation(operation);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignHandler = (paymentData: PaymentFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        mutateSendToSign(operation.uuid);
        resetCreatePayment();
        showLoader(false);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(-1);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  const onSuccessPopupCancelHandler = () => {
    setShowSignPayment(false);
    navigate(RouteList.operations);
  };

  const onCloseModalHandler = () => {
    setEditPaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(RouteList.operations);
  };

  const onCancelHandler = () => {
    setLeavePopupOpen(true);
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(RouteList.operations);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };

  return (
    <>
      <PageWrapper
        sx={{
          ...(isMobile && {
            backgroundColor: 'initial',
            borderRadius: 0,
            height: '100vh',
            padding: 0,
          }),
        }}
      >
        <PageHeader
          title={t('paymentEditHeaderContent_title')}
          showNavigateButton
          showLeavePopup={showLeavePopup}
          confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
          backLink={-1}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container xs={isMobile ? false : 9}>
                <LocalPaymentEditForm
                  defaultValues={defaultValues}
                  signPermissions={signPermissions}
                  additionalInfo={editPaymentResultData?.additionalInfo}
                  onCancel={onCancelHandler}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onChange={onChangeHandler}
                  onSaveAndSendToSign={onSaveAndSendToSignHandler}
                />
              </Grid>
              {!isMobile ? (
                <Box sx={{ width: '220px' }}>
                  <Box pl={2}>{paymentOrder ? <PaymentInfo paymentOrder={paymentOrder} edit /> : null}</Box>
                </Box>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>

      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
      {editPaymentResultOperation && showSignPayment ? (
        <SignPaymentOrder
          documentToSign={JSON.stringify(editPaymentResultOperation)}
          uuid={editPaymentResultOperation.uuid}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessPopupCancelHandler}
          onClose={onCloseModalHandler}
        />
      ) : null}
    </>
  );
};

import { UserResourceDto } from '@app/core/api';

import { Operation, DocumentStatus } from '../../../../types';

export type ActiveButtons = {
  sign: boolean;
  signFromAnother: boolean;
  multiSign: boolean;
  multiSignFromAnother: boolean;
  sendToSign: boolean;
  moreMenu: boolean;
  moreMenuButtons: {
    delete: boolean;
    cancel: boolean;
    print: boolean;
    copy: boolean;
    template: boolean;
    edit: boolean;
    view: boolean;
    printMultiple: boolean;
    download: boolean;
  };
};

export const activeButtons = (
  documentStatus: DocumentStatus,
  selectedDocuments: Operation[],
  permissions: UserResourceDto
): ActiveButtons => {
  const selectedDocumentsLength = selectedDocuments.length;
  const single: boolean = selectedDocumentsLength === 1;
  const signEnabled: boolean = selectedDocuments.filter((item) => item.actions?.allowToSign).length >= 1;
  const signFromAnotherPersonEnabled: boolean =
    selectedDocuments.filter((item) => item.actions?.allowSignFromAnotherPerson).length >= 1;
  const multiSignEnabled: boolean = selectedDocuments.length > 1 && selectedDocuments.length <= 10;
  const multiSignFromAnotherEnabled: boolean = selectedDocuments.length > 1 && selectedDocuments.length <= 10;

  const sendToSign = selectedDocuments.filter((item) => item.actions?.allowToSendToSign).length >= 1;

  const config: ActiveButtons = {
    sign: single && signEnabled,
    signFromAnother: single && signFromAnotherPersonEnabled,
    multiSign: multiSignEnabled && signEnabled,
    multiSignFromAnother: multiSignFromAnotherEnabled && signFromAnotherPersonEnabled,
    sendToSign,
    moreMenu: true,
    moreMenuButtons: {
      print: single,
      printMultiple: !single,
      delete: false,
      cancel: false,
      copy: true,
      template: true,
      edit: false,
      view: single,
      download: true,
    },
  };
  if (documentStatus === DocumentStatus.ToSign) {
    config.moreMenuButtons.delete = true;
    config.moreMenuButtons.edit = single;
  }
  if (documentStatus === DocumentStatus.OnSigning) {
    config.moreMenuButtons.edit = single;
  }

  if (documentStatus === DocumentStatus.Saved) {
    config.moreMenuButtons.delete = true;
    config.moreMenuButtons.edit = single;
  }
  if (documentStatus === DocumentStatus.Processing) {
    config.moreMenuButtons.cancel = permissions.uahOperations.cancel && single;
  }
  if (documentStatus === DocumentStatus.Processing || documentStatus === DocumentStatus.OnSigning) {
    config.sign = false;
    config.signFromAnother = false;
    config.multiSign = false;
    config.multiSignFromAnother = false;
  }
  if (documentStatus === DocumentStatus.Rejected) {
    config.sign = false;
    config.signFromAnother = false;
    config.multiSign = false;
    config.multiSignFromAnother = false;
    config.moreMenuButtons.copy = false;
    config.moreMenuButtons.print = false;
    config.moreMenuButtons.printMultiple = false;
    config.moreMenuButtons.template = false;
    config.moreMenuButtons.print = true;
    config.moreMenuButtons.edit = single;
    config.moreMenuButtons.view = single;
  }
  return config;
};

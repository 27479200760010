import { TransactionAccount } from './accounts';

export type NewDocument = {
  id: number;
  documentNumber: string;
  date: string;
  valueDate: string; // дата валютирования
  senderAccount: TransactionAccount;
  recipientAccount: TransactionAccount;
  countryCode?: string;
  amount: number;
  currency: string;
  vat?: number;
  paymentReference: string; // Призначення платежу
};

export enum DocumentCategory {
  paymentOrder = 'paymentOrder', // Платіжна інструкція
  memorialOrder = 'memorialOrder', // Мемориальный ордер
}

export enum DocumentStatus {
  ToSign = 'TO_SIGN',
  Rejected = 'REJECTED',
  Executed = 'EXECUTED',
  Saved = 'SAVED',
  Canceled = 'CANCELED',
  OnSigning = 'ON_SIGNING',
  Processing = 'PROCESSING',
  Accepted = 'ACCEPTED',
}

export type Document = {
  id: number;
  link: string;
  status: DocumentStatus;
  documentNumber: string;
  date: string;
  valueDate: string; // дата валютирования
  senderAccount: TransactionAccount;
  recipientAccount: TransactionAccount;
  amount: number;
  currency: string;
  countryCode?: string;
  vat?: number; // ПДВ
  paymentReference: string; // Призначення платежу
};

import React from 'react';

import { Box, Grid, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeleteIcon, MailIcon } from '@app/common/icons';
import { styles } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesTable/components/TableToolbar/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { ExtendedMessageDto } from '@app/src/core/api';
import { useAppSelector } from '@app/src/store';

interface TableToolbarProps {
  selectedOperation?: ExtendedMessageDto[];
}

export const TableToolbar: React.FC<TableToolbarProps> = ({ selectedOperation }) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();

  return (
    <Box pb={1}>
      {selectedOperation && selectedOperation.length ? (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
        >
          <Grid item container xs={6} alignItems="center" justifyContent="flex-start">
            <Typography variant="body2" sx={styles.selectedCount}>
              {selectedOperation.length} {t('selected')}
            </Typography>
            <Box sx={styles.divider} />
            <IconButton size="small" color="default" sx={{ ...styles.actionIcon, ...styles.selected }}>
              <MailIcon />
            </IconButton>
            <Box sx={styles.divider} />
            {permissions.uahTemplates.delete ? (
              <IconButton size="small" color="default" sx={{ ...styles.actionIcon, ...styles.selected }}>
                <DeleteIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

import React, { useState } from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { DownloadIcon, KeyIcon, LinkIcon, DeleteIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  getClientId,
  DocumentOperationType,
  DocumentStatusEnum,
  GetDocumentWithShortOperationsInfoResponseDto,
} from '@app/core/api';
import { SignCurrencyDocumentsDialog } from '@app/core/components/SignCurrencyDocumentsDialog';
import { useUmca } from '@app/core/hooks';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { DeleteSingleCurrencyDocumentModal } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/DeleteSingleCurrencyDocumentModal';
import { sendToSignCurrencyDocumentsQueryFn } from '@app/pages/documents/edit/View/query';
import { styles } from '@app/pages/documents/show/style';
import { SwiftFormFields } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { UmcaPopup } from '@app/src/common/components/UmcaPopup';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface ViewProps {
  document: GetDocumentWithShortOperationsInfoResponseDto;
  update(): void;
}
export const ViewCurrencyDocument: React.FC<ViewProps> = ({ document, update }) => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSignCurrencyDocumentsDialog, setShowSignCurrencyDocumentsDialog] = useState<boolean>(false);
  const [showUmcaPopup, setShowUmcaPopup] = useState<boolean>(false);
  const { installed: umcaInstalled, isError: umcaError, errorMessage: umcaErrorMessage } = useUmca();

  const { mutate: sendToSignMutation } = useMutation(sendToSignCurrencyDocumentsQueryFn, {
    onSuccess: () => {
      update();
      notify({
        notifyProps: {
          title: t('success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      notify({
        notifyProps: {
          title: t('payment_save_error'),
          message: t('send_to_sign_payment_error'),
          severity: 'error',
        },
      });
    },
  });

  const downloadClickHandler = () => {
    window.open(`${document.link}?clientId=${getClientId()}`, '_blank');
  };

  const onCloseHandler = () => {
    navigate(-1);
  };

  const onDeleteButtonClickHandler = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteDocumentModal = () => {
    setShowDeleteModal(false);
  };

  const onDeleteDocumentSuccess = () => {
    setShowDeleteModal(false);
    navigate(-1);
  };

  const onSendAndSignClickHandler = () => {
    if (umcaInstalled) {
      setShowSignCurrencyDocumentsDialog(true);
    } else {
      if (umcaError) {
        setShowUmcaPopup(true);
      }
    }
  };

  const onCloseSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
  };

  const onSuccessSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
    update();
  };

  const closeUmcaPopup = () => {
    setShowUmcaPopup(false);
  };

  const sendToSignHandler = () => {
    sendToSignMutation(document.uuid);
  };

  const activeDelete =
    permissions.documents.delete &&
    (document.status === DocumentStatusEnum.Saved || document.status === DocumentStatusEnum.ToSign);

  return (
    <>
      <Grid sx={styles.tabBody}>
        <Grid item>
          <Grid container direction="column">
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    <Grid container>
                      <Typography sx={styles.header}>
                        {t('currencyDocument_documentNumber')} {document.documentNumber}
                      </Typography>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item container justifyContent="flex-end" xs={4}>
                  {activeDelete ? (
                    <Box>
                      <IconButton size="small" onClick={onDeleteButtonClickHandler}>
                        <Box component="span" sx={styles.deleteText}>
                          {t('deleteDocument')}
                        </Box>
                        <DeleteIcon sx={styles.actionIcon} />
                      </IconButton>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
              <Box mb={6} mt={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('downloadDate')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField
                        disabled
                        defaultValue={formatISODateString(document?.createdAt, DATE_FORMAT)}
                        variant="outlined"
                        name={SwiftFormFields.documentDate}
                      />
                    </Box>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={6}>
                      <Box pt={4}>
                        <FormLabel component="legend">{t('currencyDocumentsSection')}</FormLabel>
                        <Box pt={2} pr={8}>
                          <TextField
                            disabled
                            defaultValue={t('currencyTransactions')}
                            variant="outlined"
                            name={SwiftFormFields.documentDate}
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pt={10}>
                        {document.relateOperations.length ? (
                          <>
                            <FormLabel component="legend">{t('releteOperations')}</FormLabel>
                            {document.relateOperations.map((relateOperation, index) => {
                              const uuid = relateOperation.uuid;
                              const typeText =
                                relateOperation.operationType === DocumentOperationType.CurrencyOperation
                                  ? t('currencyExchange')
                                  : 'SWIFT';
                              const link = (
                                relateOperation.operationType === DocumentOperationType.CurrencyOperation
                                  ? RouteList.exchange_show
                                  : RouteList.swift_show
                              ).replace(':uuid', uuid);
                              return (
                                <Box key={index} mt={2}>
                                  <Grid
                                    container
                                    item
                                    alignItems="center"
                                    wrap="nowrap"
                                    sx={styles.relatedOperation}
                                    onClick={() => {
                                      navigate(link);
                                    }}
                                  >
                                    <LinkIcon fontSize="small" />
                                    <Typography variant="body2" align="center">
                                      {` ${formatISODateString(
                                        relateOperation.documentDate,
                                        DATE_FORMAT
                                      )} - ${typeText} №${relateOperation.documentNumber} - ${relateOperation.amount} ${
                                        relateOperation.currency
                                      }`}
                                    </Typography>
                                  </Grid>
                                </Box>
                              );
                            })}
                          </>
                        ) : (
                          <Box mt={2}>
                            <FormLabel component="legend">{t('noReleteOperationsPlaceholder')}</FormLabel>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mb={6} mt={6}>
                  <FormLabel component="legend">{t('currencyDocuments_documentComment')}</FormLabel>
                  <Box pt={2}>
                    <TextField
                      disabled
                      defaultValue={document.comment}
                      variant="outlined"
                      name={SwiftFormFields.documentDate}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={styles.cardAction}>
              <Button variant="contained" color="primary" onClick={onCloseHandler}>
                {t('close')}
              </Button>
              <Box sx={styles.cardAction}>
                {permissions.documents.download ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={styles.submitBtn}
                    endIcon={<DownloadIcon />}
                    onClick={downloadClickHandler}
                  >
                    {t('download')}
                  </Button>
                ) : null}
                {document.actions.allowToSendToSign ? (
                  <Button variant="contained" color="primary" onClick={sendToSignHandler}>
                    {t('paymentCreationFormControl_sendToSign')}
                  </Button>
                ) : null}
                {document.actions.allowToSign ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSendAndSignClickHandler}
                    disabled={document.status === DocumentStatusEnum.Processing}
                  >
                    {t('paymentCreationFormControl_send')}
                    <KeyIcon sx={styles.keyIcon} />
                  </Button>
                ) : null}
              </Box>
            </CardActions>
          </Grid>
        </Grid>
      </Grid>

      {showDeleteModal ? (
        <DeleteSingleCurrencyDocumentModal
          document={document}
          relateOperations={document.relateOperations}
          onClose={closeDeleteDocumentModal}
          onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        />
      ) : null}
      {showSignCurrencyDocumentsDialog ? (
        <SignCurrencyDocumentsDialog
          toSignDocuments={[document]}
          onClose={onCloseSignCurrencyDocumentsDialog}
          onSuccessPopupCancel={onSuccessSignCurrencyDocumentsDialog}
        />
      ) : null}
      {showUmcaPopup ? <UmcaPopup errorMessage={t(umcaErrorMessage)} onClose={closeUmcaPopup} /> : null}
    </>
  );
};

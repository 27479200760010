import React from 'react';

import { useTranslation } from 'react-i18next';

import { DocumentOperationType } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';

export enum OperationsTabsEnum {
  swift = 'swift',
  currency = 'currency',
}

interface OperationsTabsProps {
  defaultActiveTab: DocumentOperationType;
  onChange(tab: DocumentOperationType): void;
}

export const OperationsTabs: React.FC<OperationsTabsProps> = ({ defaultActiveTab, onChange }) => {
  const { t } = useTranslation();

  const newPassportTabsItems: MenuItem<DocumentOperationType>[] = [
    {
      id: 1,
      labelText: t('relateOperationsTab_currencyOperation'),
      value: DocumentOperationType.CurrencyOperation,
    },
    {
      id: 2,
      labelText: t('relateOperationsTab_swift'),
      value: DocumentOperationType.Swift,
    },
  ];

  const passportTypeChange = (currentTab: DocumentOperationType): void => {
    onChange(currentTab);
  };
  return (
    <HorizontalMenu<DocumentOperationType>
      onChange={passportTypeChange}
      menuItems={newPassportTabsItems}
      defaultActiveItem={defaultActiveTab}
      styles={{
        itemDefault: {
          userSelect: 'none',
          cursor: 'pointer',
          fontWeight: 600,
          marginRight: '8px',
          fontSize: '14px',
        },
      }}
    />
  );
};

import React, { PropsWithChildren } from 'react';

import { Grid, IconButton, Box } from '@mui/material';
import { format } from 'date-fns';
import { stringifyUrl } from 'query-string';
import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon } from '@app/common/icons';
import { dateRangeFormat } from '@app/core/constants';
import { DateRange } from '@app/core/types';
import { styles } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/TurnoversTable/ColumnContainer/style';
import { RouteList } from '@app/src/constants/routeList';

export const BalanceColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return <Box sx={styles.balance}>{children}</Box>;
};

export const CurrencyColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return <Box sx={styles.currency}>{children}</Box>;
};

export const DateColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return <Box sx={styles.date}>{children}</Box>;
};

type ActionColumnProps = {
  accountId: string;
  dateRange: DateRange;
};

export const ActionColumn: React.FC<ActionColumnProps> = ({ accountId, dateRange }) => {
  const navigate = useNavigate();

  const navigateClickHandler = () => {
    const startDate = format(new Date(dateRange.startDate), dateRangeFormat);
    const endDate = format(new Date(dateRange.endDate), dateRangeFormat);

    const navigateLink = stringifyUrl({
      url: RouteList.statements,
      query: {
        accountId,
        startDate,
        endDate,
      },
    });
    navigate(navigateLink);
  };

  return (
    <Grid container justifyContent="flex-end">
      <IconButton size="small" sx={styles.navigateButton} onClick={navigateClickHandler}>
        <ArrowRightIcon />
      </IconButton>
    </Grid>
  );
};

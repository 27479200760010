export const styles = {
  caption: {
    fontWeight: 700,
  },
  captionSaveModal: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
  },
  relateOperationsContainer: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
    padding: '0 25px 0 25px',
  },
  buttonSaveModal: {
    marginLeft: '12px',
    marginRight: '12px',
    transition: 'none',
  },
  closedCaption: {
    position: 'relative',
    fontSize: '0.75rem',
    color: 'colors.gray200',
    marginBottom: 4,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 0,
      width: '100%',
      height: '1px',
      borderBottom: `1px dashed`,
      borderColor: 'colors.gray50',
    },
    '& span': {
      position: 'relative',
      zIndex: 1,
      backgroundColor: 'white',
    },
  },
  sendButton: {
    marginTop: '20px',
    minWidth: '110px',
    '& > span': {
      fontWeight: 600,
    },
  },
  alertZeroFavorite: {
    backgroundColor: 'colors.errorBackground',
    border: `1px solid`,
    borderColor: 'colors.error',
    borderRadius: '4px',
    padding: '12px',
  },
  alertZeroFavoriteText: {
    fontWeight: 600,
    color: 'colors.error',
    marginLeft: '8px',
  },
  wrapHeight: {
    maxHeight: '50%',
    overflow: 'auto',
  },
  isDragging: {
    backgroundColor: 'colors.primary25',
  },
  buttonsLoader: {
    right: '-40px',
    top: '75px',
    left: 'auto',
    zoom: 0.3,
    '& div > div': {
      background: 'white',
    },
  },
  hr: {
    borderBottom: `solid 1px`,
    borderColor: 'colors.gray100',
  },
  warning: {
    color: 'colors.error',
    fontWeight: 600,
  },
  bold: {
    fontWeight: 600,
  },
  // deleteIcon: {
  //   marginLeft: '8px',
  // },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '12px 12px 28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '75vh',
  },
  title: {
    color: 'colors.gray500',
    fontWeight: 600,
  },
  text: {
    color: 'colors.gray600',
  },
  value: {
    fontWeight: 600,
  },
  docsForDeleteCount: {
    fontWeight: 600,
    color: 'colors.primary600',
  },
  container: {
    padding: `0 56px 24px`,
  },
  itemRow: {
    paddingBottom: '16px',
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
};

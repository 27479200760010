import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DepositDto, DepositDtoDepositTypeEnum } from '@app/core/api';
import { IBanText } from '@app/core/components/IBanText';
import { StatusBadge } from '@app/core/components/StatusBadge';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { DepositInfo } from '@app/pages/deposits/show/components/DepositInfo';
import { style } from '@app/pages/deposits/show/style';
import { RouteList } from '@app/src/constants/routeList';

interface DepositCardProps {
  deposit: DepositDto;
}

export const DepositCard: React.FC<DepositCardProps> = ({ deposit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onDepositLineLinkClick = () => {
    navigate(RouteList.deposits_show_deposit_line_page.replace(':uuid', deposit.depositLineUuid || ''));
  };
  return (
    <Grid container direction="column" sx={style.paper}>
      <Grid item container>
        <Grid item xs={4}>
          <Box pl={5} pt={5}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Typography sx={style.title}>{t(deposit?.productType || '')}</Typography>
              <Box pl={2}>
                <StatusBadge status={deposit.state} />
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" justifyContent="center">
            {deposit.depositType === DepositDtoDepositTypeEnum.DepositFacility && (
              <>
                <Box pt={5}>
                  <Typography sx={style.title}>
                    {t('activeTranchesQuantity')}
                    {` - ${deposit.activeTranchesQuantity}`}
                  </Typography>
                </Box>
              </>
            )}
            {deposit.depositType === DepositDtoDepositTypeEnum.Tranche && (
              <>
                <Box pt={5}>
                  <Typography variant="caption" sx={style.depositLineLink} onClick={onDepositLineLinkClick}>
                    {t('depositLine')}
                    {` № ${deposit.agreementNumber}`}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Box pr={5} pt={5}>
            <DepositInfo deposit={deposit} />
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Box mt={10} pl={5} sx={style.infoBlock}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Box mt={1}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('deposits_period')}
                </Typography>
                <Typography sx={style.bold}>
                  {deposit.term} {t('shorts_days')}
                </Typography>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('startDate')}
                </Typography>
                <Typography sx={style.bold}>
                  {deposit ? formatISODateString(deposit.agreementStartDate, DATE_FORMAT) : ''}
                </Typography>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('endDate')}
                </Typography>
                <Typography sx={style.bold}>{formatISODateString(deposit.agreementEndDate, DATE_FORMAT)}</Typography>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('accountDepositOutstanding')}
                </Typography>
                <Typography variant="body2" component="div">
                  <IBanText
                    styles={{
                      accountNumberPart1: { color: 'colors.gray600' },
                      accountNumberPart2: { color: 'colors.gray600' },
                    }}
                    text={deposit.returnAccountNumber || ''}
                  />
                </Typography>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('currency')}
                </Typography>
                <Typography sx={style.bold}>
                  <Typography component="span">{deposit.currency}</Typography>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid item sx={style.limitBlock}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box mt={3} sx={style.debt}>
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('dateInterestReceival')}
                </Typography>
                <Typography variant="body2" component="span" sx={style.bold}>
                  {formatISODateString(deposit.dateInterestReceival, DATE_FORMAT)}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={3}>
              <Grid container direction="column" alignItems="flex-end">
                <Typography variant="caption" component="span" sx={style.light}>
                  {t('deposits_amount')}
                </Typography>
                <Typography variant="body1" component="span" sx={style.bold}>
                  {deposit.amount.toFixed(2)}{' '}
                  <Typography variant="caption" component="span">
                    {deposit.currency}
                  </Typography>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container justifyContent="space-between" xs={6} sx={style.leftBlock}>
          <Typography component="span" sx={style.bold}>
            {t('yield')}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between" xs={6} sx={style.rightBlock}>
          <Typography component="span" sx={style.bold}>
            {t('parameters')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container justifyContent="space-between" xs={6} sx={style.leftBlock}>
          <Typography variant="body2" component="span" sx={style.light}>
            {t('yieldEntirePeriod')}
          </Typography>
          <Typography variant="body2" component="span">
            {'100500.00'} {deposit.currency || ''}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between" xs={6} sx={style.rightBlock}>
          <Typography variant="body2" component="span" sx={style.light}>
            {t('autolongation')}
          </Typography>
          <Typography variant="body2" component="span">
            {deposit.autoprolong ? t('yes') : t('no')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container justifyContent="space-between" xs={6} sx={style.leftBlock}>
          <Typography variant="body2" component="span" sx={style.light}>
            {t('interestAccrued')}
          </Typography>
          <Typography variant="body2" component="span">
            {deposit.interestAccrued.toFixed(2) || ''} {deposit.currency || ''}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between" xs={6} sx={style.rightBlock}>
          <Typography variant="body2" component="span" sx={style.light}>
            {t('interestRate')}
          </Typography>
          <Typography variant="body2" component="span">
            {deposit.interestRate.toFixed(2) || ''}
            {'%'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container justifyContent="space-between" xs={6} sx={style.leftBlock} />
        <Grid item container justifyContent="space-between" xs={6} sx={style.rightBlock}>
          <Typography variant="body2" component="span" sx={style.light}>
            {t('repayInterestWithDeposit')}
          </Typography>
          <Typography variant="body2" component="span">
            {deposit.repayInterestWithDeposit ? t('yes') : t('no')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

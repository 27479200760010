import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorSmallIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useGetOrderByUuid } from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { PaymentInfo } from '@app/pages/payment/components/PaymentInfo';
import { ShowBetweenMyAccountPaymentView } from '@app/pages/payment/show/BetweenMyAccount/ShowBetweenMyAccountPaymentView';
import { ShowMobileBetweenMyAccountPaymentView } from '@app/pages/payment/show/BetweenMyAccount/ShowMobileBetweenMyAccountPaymnetView';
import { styles } from '@app/pages/payment/show/BetweenMyAccount/styles';

export const ShowBetweenMyAccountPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const { uuid = 'no-uuid' } = useParams();
  const { data, refetch } = useGetOrderByUuid(uuid);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const documentNumber = data?.documentNumber ? data.documentNumber : '';

  const Component = isMobile ? ShowMobileBetweenMyAccountPaymentView : ShowBetweenMyAccountPaymentView;
  return (
    <PageWrapper
      sx={{
        ...(isMobile && {
          backgroundColor: 'initial',
          borderRadius: 0,
          height: '100vh',
          padding: 0,
        }),
      }}
    >
      <PageHeader
        title={`${t('showPayment_pageTitle')} № ${documentNumber}`}
        showNavigateButton={!isMobile}
        backLink={-1}
      />
      <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
        <Grid container direction="column">
          <Box pt={4} mb={2}>
            <Grid item container justifyContent="space-between">
              <Grid item xs={12}>
                <Box p={isMobile ? 0 : 3}>
                  {data ? (
                    <Grid container wrap="nowrap" justifyContent="space-between">
                      <Grid item xs={isMobile ? false : 9} width="100%">
                        {data.rejectMessage ? (
                          <Grid container alignItems="center" sx={styles.rejectContainer}>
                            <ErrorSmallIcon sx={styles.rejectReasonIcon} />
                            <Typography variant="body2" sx={styles.rejectReasonText}>
                              {data.rejectMessage}
                            </Typography>
                          </Grid>
                        ) : null}
                        <Component paymentOrder={data} />
                      </Grid>
                      {isMobile ? null : (
                        <Box sx={{ width: '220px' }}>
                          <Box pl={2}>
                            <PaymentInfo paymentOrder={data} edit={false} />
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </PageContainer>
    </PageWrapper>
  );
};

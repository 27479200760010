import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  currencyOperationsApiClient,
  DocumentOperationType,
  getClientId,
  GetOperationsWithoutFilesResponseDto,
  swiftApiClient,
} from '@app/core/api';
import { PaginationChangeType } from '@app/core/types';

interface CurrencyDocumentsRequestProps {
  pagination: PaginationChangeType;
  operationType: DocumentOperationType;
}

const getCurrencyDocumentsListQueryFn = async ({
  operationType,
  pagination,
}: CurrencyDocumentsRequestProps): Promise<GetOperationsWithoutFilesResponseDto> => {
  if (operationType === DocumentOperationType.CurrencyOperation) {
    const response = await currencyOperationsApiClient.currencyOperationsControllerGetListOperationsWithoutFiles(
      getClientId(),
      {
        pagination,
      }
    );
    if (response.status !== 200) {
      throw new ApiError(
        response.request.status,
        "currencyOperationsControllerGetOperationsWithoutFiles error. Can't get operations list."
      );
    }

    return response.data;
  } else {
    const response = await swiftApiClient.swiftControllerGetListOperationsWithoutFiles(getClientId(), {
      pagination,
    });
    if (response.status !== 200) {
      throw new ApiError(
        response.request.status,
        "currencyOperationsControllerGetOperationsWithoutFiles error. Can't get operations list."
      );
    }

    return response.data;
  }
};

export const useGetCurrencyDocumentsList = (): UseMutationResult<
  GetOperationsWithoutFilesResponseDto,
  ApiError,
  CurrencyDocumentsRequestProps
> => {
  return useMutation<GetOperationsWithoutFilesResponseDto, ApiError, CurrencyDocumentsRequestProps>(
    getCurrencyDocumentsListQueryFn
  );
};

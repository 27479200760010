import {
  getClientId,
  loanLinesApiApiClient,
  ApiError,
  GetLoanLinesListRequestDto,
  GetLoanLinesListResponseDto,
} from '@app/core/api';
import { TableColumnConfig } from '@app/core/types';
import { loansLinesTableFields } from '@app/pages/loansLines/tableFields';

export const getLoansLinesListFn = async (
  getLoanLinesListRequestDto: GetLoanLinesListRequestDto
): Promise<GetLoanLinesListResponseDto> => {
  const result = await loanLinesApiApiClient.loansControllerGetLoanLinesList(getClientId(), getLoanLinesListRequestDto);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error loansControllerGetLoanLinesList!');
  }
  return result.data;
};

export const loansControllerUpdateFn = async (
  loansLinesTableColumnsData: TableColumnConfig[]
): Promise<TableColumnConfig[]> => {
  return loansLinesTableFields;
};

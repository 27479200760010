import React, { ReactNode, useMemo } from 'react';

import { Tabs, Tab, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabProps } from '@mui/material/Tab/Tab';
import { useTranslation } from 'react-i18next';

import { SignMethodEnum } from '../types';

const SignMethodTabs = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  '& .MuiTabs-indicator': {
    height: '1px',
    backgroundColor: theme.palette.colors.primary500,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.colors.gray50,
  },
}));

const SignMethodTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '60px',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: 400,
  marginRight: theme.spacing(1),
  color: theme.palette.colors.gray400,
  '&.Mui-selected': {
    color: theme.palette.colors.primary500,
    fontWeight: 600,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.colors.primary500,
  },
}));

type MenuItem = {
  id: number;
  labelText: ReactNode;
  value: SignMethodEnum;
};

export interface SignMethodSelectTabsProps {
  selectedMethod: SignMethodEnum;
  onChange(activeTab: SignMethodEnum): void;
}

export const SignMethodSelectTabs: React.FC<SignMethodSelectTabsProps> = ({ selectedMethod, onChange }) => {
  const { t } = useTranslation();
  const onChangeHandler = (e: any, value: SignMethodEnum) => {
    onChange(value);
  };

  const signMethodMenuItems: MenuItem[] = useMemo(
    () => [
      {
        id: 0,
        labelText: t('signMethod_fileKey'),
        value: SignMethodEnum.file,
      },
      {
        id: 1,
        labelText: t('signMethod_hardToken'),
        value: SignMethodEnum.hardToken,
      },
    ],
    [t]
  );
  return (
    <Grid
      sx={{
        overflowX: 'auto',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <SignMethodTabs value={selectedMethod} onChange={onChangeHandler}>
          {signMethodMenuItems.map((item) => (
            <SignMethodTab key={`tab_${item.id}`} value={item.value} label={item.labelText} />
          ))}
        </SignMethodTabs>
      </Box>
    </Grid>
  );
};

import React from 'react';

import { ErrorSmallIcon, SuccessSmallIcon, WaitingSmallIcon } from '@app/common/icons';
import { DocumentStatusEnum } from '@app/core/api';

type DocumentIconByStatusProps = {
  status: DocumentStatusEnum;
};

export const DocumentIconByStatus: React.FC<DocumentIconByStatusProps> = ({ status }) => {
  switch (status) {
    case DocumentStatusEnum.Rejected:
      return <ErrorSmallIcon fontSize="small" sx={{ color: 'colors.error' }} />;
    case DocumentStatusEnum.Accepted:
      return <SuccessSmallIcon fontSize="small" />;
    default:
      return <WaitingSmallIcon fontSize="small" />;
  }
};

import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import { UserDto } from '@app/core/api';
import { ModalCommon, RadioButton, RadioOption } from '@app/core/components';
import { useGetAnotherSigners, useGetCurrencyAnotherSigners, useGetSwiftAnotherSigners } from '@app/core/hooks';

import { styles } from './styles';

export enum SignatureStrategyEnum {
  PaymentOrder = 'paymentOrder',
  CurrencyOperation = 'currencyOperation',
  Swift = 'swift',
  Message = 'message',
}

interface SignAnotherPersonPopupProps {
  type: SignatureStrategyEnum;
  onClose(): void;
  onSelect(user: UserDto): void;
}
export const SignAnotherPersonPopup: React.FC<SignAnotherPersonPopupProps> = ({ type, onClose, onSelect }) => {
  const { showLoader } = useUIState();
  const { t } = useTranslation();
  const {
    data: operationsAnotherSignersData,
    refetch: refetchOperationsAnotherSigners,
    isLoading: isLoading1,
  } = useGetAnotherSigners();
  const {
    data: currencyAnotherSignersData,
    refetch: refetchCurrencyAnotherSigners,
    isLoading: isLoading2,
  } = useGetCurrencyAnotherSigners();
  const {
    data: swiftAnotherSignersData,
    refetch: refetchSwiftAnotherSigners,
    isLoading: isLoading3,
  } = useGetSwiftAnotherSigners();

  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUserId(e.target.value as RadioOption['value']);
  };

  const userData = operationsAnotherSignersData || currencyAnotherSignersData || swiftAnotherSignersData;
  const onSelectHandler = () => {
    const selectedUser = userData?.find((user) => user.id.toString() === selectedUserId);
    if (selectedUser) {
      onSelect(selectedUser);
    }
  };

  useEffect(() => {
    showLoader(isLoading1 || isLoading2 || isLoading3);
  }, [isLoading1, isLoading2, isLoading3, showLoader]);

  useEffect(() => {
    switch (type) {
      case SignatureStrategyEnum.Swift:
        refetchSwiftAnotherSigners();
        break;
      case SignatureStrategyEnum.CurrencyOperation:
        refetchCurrencyAnotherSigners();
        break;
      case SignatureStrategyEnum.PaymentOrder:
      default:
        refetchOperationsAnotherSigners();
    }
  }, [refetchCurrencyAnotherSigners, refetchOperationsAnotherSigners, refetchSwiftAnotherSigners, type]);
  return (
    <>
      {userData ? (
        <ModalCommon open onClose={onClose}>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{
              backgroundColor: 'white',
              padding: (theme) => theme.spacing(7),
              outline: 0,
              borderRadius: (theme) => theme.spacing(2),
              width: '100%',
              maxWidth: '456px',
              maxHeight: '75vh',
            }}
          >
            <Box pb={3}>
              <Grid justifyContent="space-between" alignItems="center" container>
                <Typography component="span" variant="h6" sx={styles.caption}>
                  {t('signatureAnotherPerson')}
                </Typography>
                <IconButton size="small" onClick={onClose}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Box>
            <Grid container direction="column">
              <Box>
                <MuiRadioGroup color="primary" value={selectedUserId} onChange={handleChange}>
                  {userData.map((user) => (
                    <FormControlLabel
                      key={user.id}
                      value={user.id}
                      control={<RadioButton color="primary" size="small" />}
                      sx={{ paddingBottom: '8px' }}
                      label={
                        <>
                          <Typography variant="body1" sx={styles.name}>
                            {user.firstName} {user.lastName}
                          </Typography>
                          <Box>
                            <Typography variant="caption" sx={styles.signingAuthority}>
                              {t('signingAuthorityLabelTempl').replace('${authority}', String(user.signingAuthority))}
                            </Typography>
                          </Box>
                        </>
                      }
                    />
                  ))}
                </MuiRadioGroup>
              </Box>
              <Grid container justifyContent="flex-end" sx={{ paddingTop: '8px' }}>
                <Button type="submit" variant="contained" color="primary" onClick={onSelectHandler}>
                  {t('next')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalCommon>
      ) : null}
    </>
  );
};

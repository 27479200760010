import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, sessionApiClient, ApiError, GetUserSessionsDto, UserSessionsResponseDto } from '@app/core/api';

const getUserHistoryOfVisitFn = async (filter: GetUserSessionsDto): Promise<UserSessionsResponseDto> => {
  const response = await sessionApiClient.profileControllerGetUserSessions(getClientId(), {
    ...filter,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get user sessions!");
  }

  return response.data;
};

export const useGetUserHistoryOfVisit = (): UseMutationResult<
  UserSessionsResponseDto,
  ApiError,
  GetUserSessionsDto
> => {
  return useMutation<UserSessionsResponseDto, ApiError, GetUserSessionsDto>(getUserHistoryOfVisitFn);
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SuccessSmallSvgIcon, ErrorSmallIcon, WarningSmallIcon, InfoSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { FrontLanguageEnum } from '@app/core/api';
import { Tooltip } from '@app/core/components';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGetUserData } from '@app/core/hooks';
import { useVerifyUserEmail } from '@app/core/hooks/useVerifyUserEmail';
import { styles } from '@app/pages/profile/desktop/components/UserData/styles';
import { ReactComponent as InstructionIcon } from '@app/themes/default/assets/icons/instruction.svg';

const schema = yup.object().shape({
  email: yup.string().email(),
});

export const UserData: React.FC = (): JSX.Element => {
  const { data, mutate: mutateUserData, isLoading } = useGetUserData();
  const { mutate: mutateSendVerifyEmail } = useVerifyUserEmail();
  const [inputValue, setInputValue] = useState<string>('');
  const [editName, setEdithName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const { notify } = useNotify();

  useEffect(() => {
    mutateUserData({});
  }, [mutateUserData]);

  const showEditor = () => {
    setError('');
    setEditMode(!editMode);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value as string);
  };

  const handleName = () => {
    if (inputValue.length > 0) {
      schema.isValid({ email: inputValue }).then((valid) => {
        if (valid) {
          setEdithName(inputValue);
          setError('');
          setShowNewName(true);
          setEditMode(false);
        } else {
          setError('ÐÐµÐ¿Ñ€Ð°Ð²Ð¸Ð»ÑŒÐ½Ñ‹Ð¹ Ñ„Ð¾Ñ€Ð¼Ð°Ñ‚');
        }
      });
    } else {
      setError('ÐŸÑƒÑÑ‚Ð¾Ðµ Ð¿Ð¾Ð»Ðµ');
    }
  };
  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleName();
    }
  };

  const sendVerifyEmail = () => {
    mutateSendVerifyEmail(
      { language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          setShowSuccessAlert(true);
          setTimeout(() => setShowSuccessAlert(false), 2500);
        },
        onError: () => {
          setShowErrorAlert(true);
          setTimeout(() => setShowErrorAlert(false), 2500);
        },
      }
    );
  };

  type UserInfoRowProps = {
    trKey: string;
    value: string;
  };

  const UserInfoRow: React.FC<UserInfoRowProps> = ({ trKey, value }) => {
    return (
      <Grid item container alignItems="center" sx={styles.item}>
        <Grid item xs={4}>
          <Typography variant="caption" color="textSecondary">
            {t(trKey)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  const MailRow: React.FC<UserInfoRowProps> = ({ trKey, value }) => {
    return (
      <Grid item container alignItems="center" sx={styles.item}>
        <Grid item xs={4}>
          <Typography variant="caption" color="textSecondary">
            {t(trKey)}
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start" alignItems="center" xs={8}>
          <Typography variant="body2">{value}</Typography>
          {data?.emailVerified ? (
            <>
              <Tooltip arrow placement="top" color="primary" withBorder title={t('verifyInfoTooltip')}>
                <Box ml={2} pt={2}>
                  <InfoSmallIcon fontSize="small" />
                </Box>
              </Tooltip>
              <Tooltip arrow placement="top" color="primary" withBorder title={t('verifySuccessTooltip')}>
                <Box ml={2} pt={2}>
                  <SuccessSmallSvgIcon color="success" fontSize="small" />
                </Box>
              </Tooltip>
            </>
          ) : (
            <Tooltip arrow placement="top" color="primary" withBorder title={t('verifyWarningTooltip')}>
              <Box ml={2} pt={2}>
                <WarningSmallIcon fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  const onUserManualClick = () => {
    window.open(
      `https://storage.googleapis.com/dgb-web-assets/instructions/%D0%86%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%8F%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D1%87%D0%B0.pdf`,
      '_blank'
    );
  };

  const birthDate = data?.birthDate ? format(new Date(data?.birthDate), ddMMYYYYFormat.format) : '';

  return (
    <Grid container direction="column" justifyContent="space-between" sx={styles.root}>
      {showSuccessAlert ? (
        <Grid container alignItems="center" sx={styles.successAlert}>
          <SuccessSmallSvgIcon fontSize="small" />
          <Typography variant="caption" sx={styles.successAlertText}>
            {t('sendVerificationSuccess')}
          </Typography>
        </Grid>
      ) : null}
      {showErrorAlert ? (
        <Grid container alignItems="center" sx={styles.errorAlert}>
          <ErrorSmallIcon fontSize="small" />
          <Typography variant="caption" sx={styles.errorAlertText}>
            {t('SendVerificationError')}
          </Typography>
        </Grid>
      ) : null}
      {!data?.emailVerified && !isLoading ? (
        <Grid container alignItems="center" sx={styles.notVerifyWarning}>
          <Typography variant="caption" sx={styles.notVerifyWarningText}>
            {t('notVerifyWarning')}
          </Typography>
        </Grid>
      ) : null}
      {data ? (
        <>
          {data.lastName ? <UserInfoRow trKey="lastName" value={data.lastName} /> : null}
          {data.firstName ? <UserInfoRow trKey="firstName" value={data.firstName} /> : null}
          {data.patronymic ? <UserInfoRow trKey="userMiddleName" value={data.patronymic} /> : null}
          {data.birthDate ? <UserInfoRow trKey="birthDate" value={birthDate} /> : null}
          {data.phoneNumber ? <UserInfoRow trKey="phoneNumber" value={data.phoneNumber} /> : null}
          {data.inn ? <UserInfoRow trKey="userTIN" value={data.inn} /> : null}
          {data.email ? <MailRow trKey="email" value={data.email} /> : null}
          {!data.emailVerified ? (
            <Grid container justifyContent="flex-end">
              <Box mt={5}>
                <Button variant="outlined" size="medium" color="primary" onClick={sendVerifyEmail}>
                  {t('sendRequest')}
                </Button>
              </Box>
            </Grid>
          ) : null}
        </>
      ) : null}
      <Grid container alignItems="center" sx={styles.instruction} onClick={onUserManualClick}>
        <InstructionIcon />
        <Typography variant="caption" fontWeight={600}>
          {t('userManual')}
        </Typography>
      </Grid>
    </Grid>
  );
};

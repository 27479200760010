import { useMutation, UseMutationResult } from 'react-query';

import {
  getClientId,
  paymentOrdersApiClient,
  ApiError,
  CancelPaymentOrderResponseDto,
  DeleteDocumentRequestDto,
} from '@app/core/api';

export const useCancelDocument = (): UseMutationResult<
  CancelPaymentOrderResponseDto,
  ApiError,
  DeleteDocumentRequestDto
> =>
  useMutation(async (deleteDocumentRequestDto: DeleteDocumentRequestDto) => {
    const response = await paymentOrdersApiClient.paymentOrderControllerCancelDocument(
      getClientId(),
      deleteDocumentRequestDto
    );

    if (response.status !== 200) {
      throw new ApiError(response.request.status, "Can't cancel document");
    }
    return response.data;
  });

import React, { useEffect, useState } from 'react';

import { Box, CardContent, FormLabel, Grid } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ActionFiltersButtons } from '@app/pages/overdrafts/components/ActionFiltersButtons';
import { styles } from '@app/pages/overdrafts/components/OverdraftsFilter/style';
import { OverdraftsFilterField } from '@app/pages/overdrafts/types';

interface OverdraftsFilterProps {
  fullItems: boolean;
  namesFieldWithError?: string[];
  resetFormHandler?(): void;
}

export const OverdraftsFilter: React.FC<OverdraftsFilterProps> = ({
  fullItems,
  namesFieldWithError,
  resetFormHandler,
}) => {
  const { t } = useTranslation();

  const nowDate = new Date();
  const maxDocumentDate = addDays(nowDate, 30);
  const minDocumentDate = subDays(nowDate, 30);
  const [minValuationDate, setMinValuationDate] = useState<Date>(nowDate);
  const [valuationDateSelectDisabled, setValuationDateSelectDisabled] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(nowDate);

  const { control } = useFormContext();

  const watchDateFrom = useWatch({
    control,
    name: OverdraftsFilterField.agreementStartDateFrom,
  });

  useEffect(() => {
    if (watchDateFrom) {
      setMinValuationDate(watchDateFrom);
    }
  }, [watchDateFrom]);

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('startDate_from')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={OverdraftsFilterField.agreementStartDateFrom}
                  datePickerProps={{
                    disabled: {
                      before: minDocumentDate,
                    },
                    month: currentMonth,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('startDate')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={OverdraftsFilterField.agreementStartDateTo}
                      disabled={valuationDateSelectDisabled}
                      datePickerProps={{
                        disabled: {
                          before: minValuationDate,
                        },
                        month: minValuationDate,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('endDate_from')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={OverdraftsFilterField.agreementEndDateFrom}
                      disabled={valuationDateSelectDisabled}
                      datePickerProps={{
                        disabled: {
                          before: minValuationDate,
                        },
                        month: minValuationDate,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
              </>
            ) : null}
            <Grid item xs={3}>
              <FormLabel component="legend">{t('endDate_to')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={OverdraftsFilterField.agreementEndDateTo}
                  disabled={valuationDateSelectDisabled}
                  datePickerProps={{
                    disabled: {
                      before: minValuationDate,
                    },
                    month: minValuationDate,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('accountOverdraft')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={OverdraftsFilterField.accountOverdraft}
                  fullWidth
                  error={namesFieldWithError?.includes(OverdraftsFilterField.accountOverdraft)}
                  placeholder={t('accountOverdraft')}
                />
              </Box>
            </Grid>
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('agreementNumber')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={OverdraftsFilterField.agreementNumber}
                      placeholder={t('agreementNumber')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('currency')}</FormLabel>
                  <Box pt={2}>
                    <InputField name={OverdraftsFilterField.currency} fullWidth placeholder={t('currency')} />
                  </Box>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('amountFrom')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={OverdraftsFilterField.amountFrom}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('amountTo')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={OverdraftsFilterField.amountTo}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('interestRate_from')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={OverdraftsFilterField.interestRateFrom}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('interestRate_to')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={OverdraftsFilterField.interestRateTo}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={12}>
                  <Box pt={2} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

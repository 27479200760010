import React, { useState } from 'react';

import { IconButton, ListItemIcon, Menu, MenuItem, styled } from '@mui/material';

import { ChatOffIcon } from '@app/common/icons';
import telegramIcon from '@app/themes/default/assets/icons/Telegram.svg';
import { ReactComponent as ViberSvg } from '@app/themes/default/assets/icons/Viber.svg';

const ChatMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.colors.langSwitcherBackground,
    border: `1px solid ${theme.palette.colors.langSwitcherBackground}`,
    borderRadius: '20px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
  },
  '& .MuiMenu-list': {
    padding: '2px',
  },
}));

const ChatMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '0 0 6px 0',
  minHeight: 'unset',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },
}));

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  justifyContent: 'center',
  '&:hover': {
    transformOrigin: 'center',
    transform: 'scale(1.15)',
  },
}));

export const SupportChat: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickChat = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onIconClickHandler = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClickChat}
        sx={{
          minWidth: '24px',
          padding: 0,
          '&:hover': {
            transformOrigin: 'center',
            transform: 'scale(1.15)',
          },
        }}
        size="large"
      >
        <ChatOffIcon sx={{ color: anchorEl ? 'colors.secondary600' : 'colors.companyIconsColor' }} />
      </IconButton>

      <ChatMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: '32px',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ChatMenuItem>
          <a href="https://t.me/oxibank_bot" target="_blank" rel="noreferrer">
            <ListItemIconStyled onClick={onIconClickHandler}>
              <img src={telegramIcon} />
            </ListItemIconStyled>
          </a>
        </ChatMenuItem>
        <ChatMenuItem onClick={onIconClickHandler}>
          <a href="viber://pa?chatURI=oxibank" target="_blank" rel="noreferrer">
            <ListItemIconStyled onClick={onIconClickHandler}>
              <ViberSvg />
            </ListItemIconStyled>
          </a>
        </ChatMenuItem>
      </ChatMenu>
    </>
  );
};

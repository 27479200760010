import { RadioOption } from '@app/core/components';

export type DefaultNationalityAccountFormData = {
  configType: string;
};

export const filesFormatList: RadioOption[] = [
  {
    label: 'WITHOUT_RESET',
    value: 'WITHOUT_RESET',
  },
  {
    label: 'YEAR_RESET',
    value: 'YEAR',
  },
  {
    label: 'MONTH_RESET',
    value: 'MONTH',
  },
  {
    label: 'QUARTER_RESET',
    value: 'QUARTER',
  },
];

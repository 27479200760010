import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { AccountsResponseDto, ApiError, configurationApiClient, getClientId } from '@app/core/api';

export type UpdateAccountProps = {
  id: string;
  name?: string;
  favorite?: boolean;
};

const updateAccountQueryFn = async ({ id, name, favorite }: UpdateAccountProps): Promise<AccountsResponseDto> => {
  const result = await configurationApiClient.clientConfigControllerUpdateAccountConfig(getClientId(), {
    id,
    name,
    favorite,
  });
  return result.data;
};

export const useUpdateAccounts = (): UseMutationResult<AccountsResponseDto, ApiError, UpdateAccountProps> => {
  return useMutation<AccountsResponseDto, ApiError, UpdateAccountProps>(updateAccountQueryFn);
};

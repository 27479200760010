export const styles = {
  searchIconBtn: {
    color: 'colors.gray300',
  },
  filterIconBtn: {
    color: 'transparent',
    stroke: '#007ff2',
  },
  settingIconBtn: {
    color: 'colors.gray300',
    marginRight: '-16px',
  },
  chevron: {
    color: 'colors.gray200',
  },
  cell: {
    flex: '1 auto',
    width: '40px',
    lineHeight: '1.2rem',
    minHeight: '2.8rem',
    verticalAlign: 'middle',
  },
  cell_header: {
    color: 'colors.gray700',
  },
  cell_documentNumber: {
    // maxWidth: '116px',
  },
  cell_operationDate: {
    // width: '100px',
    // minWidth: '100px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_documentDate: {
    // width: '100px',
    // minWidth: '100px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_recipientAccountName: {},
  cell_LEI: {},
  cell_paymentReference: {
    '&.body': {
      color: 'colors.gray400',
      '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '@supports (-webkit-line-clamp: 2)': {
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          WebkitBoxAlign: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  },
  cell_accountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_currency: {
    justifyContent: 'center',
    // minWidth: '40px',
    // width: '70px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_amount: {
    textAlign: 'end',
    // width: '116px',
    // maxWidth: '116px',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_debit: {
    color: 'colors.gray600',
    fontWeight: 600,
    // minWidth: '116px',
    // maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_credit: {
    color: 'colors.gray600',
    fontWeight: 600,
    // minWidth: '116px',
    // maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_bankCode: {},
  cell_bankName: {},
  cell_recipientAccountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_documentStatus: {
    width: '96px',
  },
  cell_actions: {
    width: '96px',
    flexShrink: 0,
  },
  cell_selection: {
    width: '40px',
    // flexShrink: 0,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  status: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  statusRejected: {
    backgroundColor: 'colors.error',
  },
  statusExecuted: {
    backgroundColor: 'colors.success',
  },
  accountNumberPart1: {
    color: 'colors.gray700',
  },
  accountNumberPart2: {
    color: 'colors.gray700',
    fontWeight: 600,
  },
};

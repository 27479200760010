import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  getClientId,
  GetSwiftOperationListRequestDto,
  GetSwiftOperationListResponseDto,
  swiftApiClient,
} from '@app/core/api';

const getSwiftOperationsListFn = async (
  filterData: GetSwiftOperationListRequestDto
): Promise<GetSwiftOperationListResponseDto> => {
  const response = await swiftApiClient.swiftControllerGetSwiftOperationList(getClientId(), filterData);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error swiftControllerGetSwiftOperationList.');
  }

  return response.data;
};

export const useGetSwiftOperationsList = (): UseMutationResult<
  GetSwiftOperationListResponseDto,
  ApiError,
  GetSwiftOperationListRequestDto
> => {
  return useMutation<GetSwiftOperationListResponseDto, ApiError, GetSwiftOperationListRequestDto>(
    getSwiftOperationsListFn
  );
};

import { GetListRequestDto } from '@app/core/api';
import { DepositsFilterType } from '@app/pages/deposits/types';

export const makeFilterData = (data: DepositsFilterType): Omit<GetListRequestDto, 'pagination'> => {
  const {
    currency,
    amountTo,
    amountFrom,
    endDateFrom,
    endDateTo,
    agreementNumber,
    startDateTo,
    startDateFrom,
    interestRateFrom,
    interestRateTo,
    depositType,
  } = data;

  const startDateRange =
    startDateTo || startDateFrom
      ? { dateFrom: startDateFrom?.toISOString(), dateTo: startDateTo?.toISOString() }
      : undefined;

  const endDateRange =
    endDateFrom || endDateTo ? { dateFrom: endDateFrom?.toISOString(), dateTo: endDateTo?.toISOString() } : undefined;

  const interestRate =
    interestRateFrom?.length || interestRateTo?.length
      ? {
          from: interestRateFrom ? parseFloat(interestRateFrom) : undefined,
          to: interestRateTo ? parseFloat(interestRateTo) : undefined,
        }
      : undefined;

  return {
    startDate: startDateRange,
    endDate: endDateRange,
    interestRate,
    productType: depositType?.length ? depositType : undefined,
    agreementNumber: agreementNumber?.length ? agreementNumber : undefined,
    amount: amountTo || amountFrom ? { from: Number(amountFrom), to: Number(amountTo) } : undefined,
    currency: currency?.length ? currency : undefined,
  };
};

import React, { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { PageContent } from '@app/pages/paymentsTemplates/edit/PageContent';

export const EditTemplatePage: React.FC = () => {
  const { t } = useTranslation();
  const stateData: any = useLocation().state;
  const templateName = stateData ? stateData?.templateName : null;
  const showLeavePopupRef = useRef<boolean>(false);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  return (
    <PageWrapper>
      <PageHeader
        title={templateName}
        showLeavePopup={showLeavePopup}
        backLink={-1}
        confirmQuestionText={t('confirmLeaveTemplatePopupMessage')}
        showNavigateButton
        useDesktopStyle
      />
      <PageContainer>
        <PageContent onChange={onChangeHandler} />
      </PageContainer>
    </PageWrapper>
  );
};

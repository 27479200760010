import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  AvailableActionsDto,
  CreatePaymentOrderResultDto,
  PaymentOrderStatusEnum,
  PaymentOrderAdditionalInfoDto,
  ResultStatusEnum,
  CreateStatusEnum,
} from '@app/core/api';
import { LeaveEditPopup, SignPaymentOrder } from '@app/core/components';
import { useGetOrderByUuid } from '@app/core/hooks';
import { useEditOwnPaymentOrder } from '@app/core/hooks/useEditOwnPaymentOrder';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { isMobile } from '@app/core/utils';
import { BetweenMyAccountEditForm } from '@app/pages/payment/components/BetweenMyAccountEditForm';
import {
  BetweenMyAccountFormDefaultValues,
  BetweenMyAccountFormData,
} from '@app/pages/payment/components/BetweenMyAccountEditForm/types';
import { PaymentInfo } from '@app/pages/payment/components/PaymentInfo';
import { makeEditOwnPaymentSubmitData } from '@app/pages/payment/edit/BetweenMyAccount/helpers';
import { ProfileState } from '@app/slices/profileSlice';
import { PageWrapper } from '@app/src/common/layout/PageWrapper/PageWrapper';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface EditOwnPaymentProps {
  paymentData: BetweenMyAccountFormData;
  onEditSuccess(operation: CreatePaymentOrderResultDto): void;
  onEditError(additionalInfo?: PaymentOrderAdditionalInfoDto): void;
}

export const EditOwnPaymentPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const { uuid = 'no-uuid' } = useParams();
  const showLeavePopupRef = useRef<boolean>(false);
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [editPaymentResultOperation, setEditPaymentResultOperation] = useState<CreatePaymentOrderResultDto | null>();
  const [defaultValues, setDefaultValues] = useState<BetweenMyAccountFormDefaultValues>({});
  const [signPermissions, setSignPermissions] = useState<AvailableActionsDto>({
    ...permissions.sign.paymentOrder,
    allowSignFromAnotherPerson: permissions.sign.paymentOrder.signFromAnotherPerson,
  });
  const { data: paymentOrder, refetch: refetchPaymentOrder } = useGetOrderByUuid(uuid);
  const { data: editPaymentResultData, mutateAsync, reset: resetCreatePayment } = useEditOwnPaymentOrder();
  const { data: sendToSignPaymentResultData, mutate: mutateSendToSign } = useSendToSignPaymentOrder();

  const editPayment = ({ paymentData, onEditSuccess, onEditError }: EditOwnPaymentProps) => {
    mutateAsync(
      makeEditOwnPaymentSubmitData({
        uuid,
        paymentData,
      })
    )
      .then((result) => {
        if (result.operation) {
          onEditSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onEditError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onEditError();
      });
  };

  useEffect(() => {
    refetchPaymentOrder();
  }, [refetchPaymentOrder]);

  useEffect(() => {
    if (paymentOrder) {
      setDefaultValues({
        documentDate: paymentOrder.details.date,
        valueDate: paymentOrder?.valueDate || undefined,
        senderAccountId: paymentOrder.account.id,
        documentNumber: paymentOrder.documentNumber,
        recipientAccountId: paymentOrder.details.recipientAccount.uuid,
        amount: paymentOrder.amount,
        paymentReference: paymentOrder.paymentReference,
      });
      setSignPermissions(paymentOrder.actions);
    }
  }, [paymentOrder]);

  const onSaveClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess() {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(-1);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        showLoader(false);
        setEditPaymentResultOperation(operation);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        mutateSendToSign(operation.uuid);
        resetCreatePayment();
        showLoader(false);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(-1);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  const onSuccessPopupCancelHandler = () => {
    setShowSignPayment(false);
    navigate(RouteList.operations);
  };

  const onCloseModalHandler = () => {
    setEditPaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(RouteList.operations);
  };

  const onCancelHandler = () => {
    setLeavePopupOpen(true);
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(RouteList.operations);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };
  return (
    <>
      <PageWrapper
        sx={{
          ...(isMobile && {
            backgroundColor: 'initial',
            borderRadius: 0,
            height: '100vh',
            padding: 0,
          }),
        }}
      >
        <PageHeader
          title={t('paymentEditHeaderContent_title')}
          showNavigateButton
          showLeavePopup={showLeavePopup}
          confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
          backLink={-1}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container xs={isMobile ? false : 9}>
                <BetweenMyAccountEditForm
                  defaultValues={defaultValues}
                  signPermissions={{
                    ...permissions.sign.paymentOrder,
                    allowSignFromAnotherPerson: permissions.sign.paymentOrder.signFromAnotherPerson,
                  }}
                  additionalInfo={editPaymentResultData?.additionalInfo}
                  onCancelHandler={onCancelHandler}
                  onSaveClick={onSaveClickHandler}
                  onSaveAndSignClick={onSaveAndSignClickHandler}
                  onChange={onChangeHandler}
                  onSaveAndSendToSignClickHandler={onSaveAndSendToSignClickHandler}
                />
              </Grid>
              {!isMobile ? (
                <Box sx={{ width: '220px' }}>
                  <Box pl={2}>{paymentOrder ? <PaymentInfo paymentOrder={paymentOrder} edit /> : null}</Box>
                </Box>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>
      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
      {editPaymentResultOperation && showSignPayment ? (
        <SignPaymentOrder
          documentToSign={JSON.stringify(editPaymentResultOperation)}
          uuid={editPaymentResultOperation.uuid}
          onSuccessPopupCancel={onSuccessPopupCancelHandler}
          onClose={onCloseModalHandler}
        />
      ) : null}
    </>
  );
};

import React from 'react';

import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { SelectOptionType } from '@app/core/components/Select';

export type UsersSignatureSelectProps = {
  name: string;
  menuItems: SelectOptionType[];
  disabled?: boolean;
  defaultValue?: number;
};

export const UsersSignatureSelect: React.FC<UsersSignatureSelectProps> = ({
  name,
  menuItems,
  defaultValue,
  disabled,
}) => {
  return (
    <SelectField
      fullWidth
      disabled={disabled}
      name={name}
      options={menuItems}
      defaultValue={
        defaultValue ? menuItems[menuItems.findIndex((item) => item.value === defaultValue)].value : menuItems[0].value
      }
    />
  );
};

import React, { useEffect, useRef } from 'react';

import { Grid, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { styles } from './styles';
import { StatusText } from '../../../StatusText';
import { TextFieldMultiline } from '../../../TextFieldMultiline';

type InputFieldProps = {
  name: string;
  defaultValue?: string;
  maxLength: number;
  error?: boolean;
  textFieldProps?: TextFieldProps;
};

export const TextAreaField: React.FC<InputFieldProps> = ({ name, maxLength, defaultValue, textFieldProps }) => {
  const { setValue, control } = useFormContext();
  const valueLength = useRef<number>(0);
  const textAreaWatchField = useWatch({ control, name });

  useEffect(() => {
    if (textAreaWatchField) {
      valueLength.current = textAreaWatchField.length;
    }
  }, [textAreaWatchField]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    valueLength.current = e.target.value.length;
    if (valueLength.current <= maxLength) {
      setValue(name, e.target.value, { shouldValidate: true });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({
        field: { name: controlledName, value: controlledValue, ...props },
        fieldState: { error, invalid },
      }) => (
        <>
          <Grid container direction="column" sx={{ ...styles.root, ...(invalid && styles.errorLight) }}>
            <TextFieldMultiline
              name={name}
              value={controlledValue}
              variant="outlined"
              {...props}
              error={invalid}
              onChange={onChange}
              {...textFieldProps}
            />
            <Grid container justifyContent="flex-end">
              <Typography variant="caption" color="textSecondary">
                {textAreaWatchField ? textAreaWatchField.length : valueLength.current}/{maxLength}
              </Typography>
            </Grid>
          </Grid>
          {error ? <StatusText>{error.message}</StatusText> : null}
        </>
      )}
    />
  );
};

import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownloadIcon } from '@app/common/icons';

interface LoadOperationButtonProps {
  link: string;
}

export const LoadOperationButton: React.FC<LoadOperationButtonProps> = ({ link }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.open(link, '_blank');
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{
        'MuiButton-root': {
          backgroundColor: 'transparent',
          flexShrink: 0,
        },
      }}
      endIcon={<DownloadIcon />}
      onClick={handleClick}
    >
      {t('download')}
    </Button>
  );
};

import { string, object, SchemaOf } from 'yup';

type SchemaObject = {
  email: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaObject> => {
  return object().shape({
    email: string().email(t('emailValidationError')).required(t('paymentCreationLocalPayment_field_required')),
  });
};

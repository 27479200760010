export const styles = {
  root: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '12px',
    marginBottom: '4px',
  },
  buttonRoot: {
    color: '#ffffff',
  },
  signButtonRoot: {
    color: '#ffffff',
    border: '1px solid #fff',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: '0.875rem',
  },
  totalAmountContainer: {
    textAlign: 'right',
  },
  amountText: {
    paddingRight: '4px',
    color: '#ffffff',
  },
  amountValue: {
    paddingRight: '4px',
    color: '#ffffff',
    fontWeight: 600,
  },
  menuItem: {
    borderBottom: `1px solid`,
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  actionsContainer: {
    padding: '5px 16px 5px 0',
  },
  selectedActionsContainer: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '5px 16px',
  },
  divider: {
    marginRight: '20px',
    marginLeft: '20px',
    borderLeft: `1px solid`,
    borderColor: 'colors.gray100',
    width: '1px',
    height: '20px',
  },
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon, CrossIcon, EraserIcon, SearchSmallIcon } from '@app/common/icons';
import { CategoryInfoDto, ExtendedMessageDto, MessageStatus } from '@app/core/api';
import {
  DateSelectColumnFilter,
  SelectColumnFilter,
  SelectOptionType,
  TextFieldColumnFilter,
} from '@app/core/components';
import { Checkbox } from '@app/core/components/Checkbox';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { LettersSubFilterEnum, LettersTableColumns } from '@app/pages/letters/types';

import { styles } from './styles';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  t(text: string): string;
  categoryList: CategoryInfoDto[];
  showStatusSubFilter: boolean;
  messageStatusOptionsItems: SelectOptionType[];
  onSearchButtonClick(): void;
  onLetterLinkClick(uuid: string): void;
  onClearSubFilter(): void;
  onCloseSubFilter(): void;
}

type MessageStatusComponentProps = {
  status: MessageStatus;
};

const MessageStatusComponent: React.FC<MessageStatusComponentProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.messageStatus,
          ...((status === MessageStatus.New || status === MessageStatus.Delivered) && styles.messageStatusGreen),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(status)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  showSubFilters,
  t,
  categoryList,
  showStatusSubFilter,
  messageStatusOptionsItems,
  onSearchButtonClick,
  onLetterLinkClick,
  onClearSubFilter,
  onCloseSubFilter,
}: MakeTableColumnsProps): ColumnDef<ExtendedMessageDto, any>[] => {
  const categoryListOptions: SelectOptionType[] = [
    {
      id: -1,
      name: t('all'),
      value: '',
    },
    ...categoryList.map((item) => ({
      id: item.id,
      name: item.name,
      value: item.name,
    })),
  ];

  return [
    {
      id: 'selection',
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }: CellContext<ExtendedMessageDto, any>) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: LettersTableColumns.createdAt,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t(`letter_createDate`)}</Typography>
              </HeaderSortedWrapper>
            </div>
            {showSubFilters ? (
              <DateSelectColumnFilter<ExtendedMessageDto>
                name={LettersSubFilterEnum.createdAt}
                column={props.column}
                table={props.table}
                submit
              />
            ) : null}
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<ExtendedMessageDto, string>) => {
        const value = getValue();
        return <>{format(new Date(value.split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: LettersTableColumns.senderName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`letter_sender`)}</Typography>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<ExtendedMessageDto>
              name={LettersSubFilterEnum.senderName}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExtendedMessageDto, string>) => {
        return <Typography variant="caption">{getValue()}</Typography>;
      },
    },
    {
      accessorKey: LettersTableColumns.category,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`category_letter`)}</Typography>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <SelectColumnFilter<ExtendedMessageDto>
              submit
              displayEmpty
              name={LettersSubFilterEnum.category}
              column={props.column}
              table={props.table}
              options={categoryListOptions}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExtendedMessageDto, CategoryInfoDto>) => {
        return <Typography variant="caption">{getValue().name}</Typography>;
      },
    },
    {
      accessorKey: LettersTableColumns.subject,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`letter_topic`)}</Typography>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<ExtendedMessageDto>
              name={LettersSubFilterEnum.subject}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExtendedMessageDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: LettersTableColumns.message,
      header: (props) => (
        <Grid container direction="column" justifyContent="flex-start" sx={{ height: '100%' }}>
          <div>
            <Typography variant="caption">{t(`letter_column_message`)}</Typography>
          </div>
          {/*{showSubFilters ? (*/}
          {/*  <TextFieldColumnFilter<ExtendedMessageDto>*/}
          {/*    name={LettersSubFilterEnum.message}*/}
          {/*    column={props.column}*/}
          {/*    table={props.table}*/}
          {/*  />*/}
          {/*) : null}*/}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExtendedMessageDto, string>) => {
        return (
          <Typography component="span" variant="caption" color="textSecondary">
            <Ellipsis text={getValue()} />
          </Typography>
        );
      },
    },
    {
      accessorKey: LettersTableColumns.status,
      header: (props) => (
        <Grid container direction="column" justifyContent="flex-start" sx={{ height: '100%' }}>
          <div>
            <Typography variant="caption">{t(`letter_messageStatus`)}</Typography>
          </div>
          {showSubFilters && showStatusSubFilter ? (
            <SelectColumnFilter<ExtendedMessageDto>
              submit
              displayEmpty
              name={LettersSubFilterEnum.status}
              column={props.column}
              table={props.table}
              options={messageStatusOptionsItems}
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExtendedMessageDto, MessageStatus>) => {
        return <MessageStatusComponent status={getValue()} />;
      },
    },
    {
      id: 'actions',
      accessorKey: LettersTableColumns.uuid,
      header: ({ table }) => {
        const onSearchButtonClickHandler = () => {
          if (table.getRowModel().rows.length) {
            onSearchButtonClick();
          }
        };
        return (
          <Grid container justifyContent="flex-end">
            <div>
              {showSubFilters ? (
                <IconButton onClick={onClearSubFilter} size="large" sx={{ marginRight: '6px' }}>
                  <EraserIcon fontSize="small" />
                </IconButton>
              ) : null}

              {showSubFilters ? (
                <IconButton size="small" onClick={onCloseSubFilter}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={onSearchButtonClickHandler}>
                  <SearchSmallIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        );
      },
      cell: ({ row }: CellContext<ExtendedMessageDto, any>) => {
        const onLetterLinkClickHandler = () => {
          onLetterLinkClick(row.original.uuid);
        };
        return (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Box onClick={onLetterLinkClickHandler}>
              <ArrowRightIcon style={styles.linkArrow} />
            </Box>
          </Grid>
        );
      },
    },
  ];
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography, Box } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DepositDto, DepositDtoDepositTypeEnum } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { Loader } from '@app/core/components/Loader';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { CurrencyEnum, PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/deposits/components/DepositsTable/components/TableContent/columns';
import { styles } from '@app/pages/deposits/components/DepositsTable/components/TableContent/styles';
import { RouteList } from '@app/src/constants/routeList';

interface TableContentProps {
  data: DepositDto[];
  currency?: CurrencyEnum;
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  onSettingsButtonClick(): void;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
}

export const TableContent: React.FC<TableContentProps> = ({
  data,
  currency,
  pagination,
  loading,
  queryKey,
  onSettingsButtonClick,
  onSort,
  onPaginationChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        onSettingsButtonClick,
        navigateClickHandler: (depositType, uuid) => {
          if (depositType === DepositDtoDepositTypeEnum.Deposit) {
            navigate(RouteList.deposits_show_deposit_page.replace(':uuid', uuid));
          } else {
            navigate(RouteList.deposits_show_deposit_line_page.replace(':uuid', uuid));
          }
        },
      }),
    ];
  }, [navigate, onSettingsButtonClick, t]);

  const table = useReactTable<DepositDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: !data.length,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('no_operations_found')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<DepositDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<DepositDto> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
      footer={{
        content: (
          <TablePagination
            totalCount={pagination?.totalCount || 0}
            page={pagination?.page || 1}
            rowsPerPage={statementsTableRowsPerPageDefault}
            labelRowsPerPage={t('showOnPageShort')}
            rowsPerPageOptions={statementsTableRowsPerPageOptions}
            onChange={onPaginationChange}
            show={showPagination}
          />
        ),
      }}
    />
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  currencyDocumentsApiClient,
  getClientId,
  ApiError,
  DeleteFilesRequestDto,
  DeleteFilesResponseDto,
} from '@app/core/api';

export const deleteCurrencyDocumentQueryFn = async (uuids: DeleteFilesRequestDto): Promise<DeleteFilesResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerDeleteFiles(getClientId(), uuids);

  if (response.status !== 200) {
    throw new Error("Can't delete currency document.");
  }
  return response.data;
};

export const useDocumentsDeleteFiles = (): UseMutationResult<DeleteFilesResponseDto, ApiError, DeleteFilesRequestDto> =>
  useMutation<DeleteFilesResponseDto, ApiError, DeleteFilesRequestDto>(deleteCurrencyDocumentQueryFn);

import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ActionsButtonsProps {
  onCancel(): void;
  onConfirm(): void;
}

export const ActionsButtons: React.FC<ActionsButtonsProps> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center">
      <Box pr={6}>
        <Button size="small" variant="outlined" color="primary" onClick={onCancel}>
          {t('no')}
        </Button>
      </Box>
      <Button size="small" variant="contained" color="primary" onClick={onConfirm}>
        {t('yes')}
      </Button>
    </Grid>
  );
};

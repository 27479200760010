import React, { useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeleteIcon, PreviewIcon, SignatureIcon, EditIcon, SendIcon } from '@app/common/icons';
import { GetDocumentWithAvailableActionsDto } from '@app/core/api';
import { ConfirmEditOperationPopup } from '@app/core/components';
import { SendToSignExchangeDocuments } from '@app/core/components/SendToSignExchangeDocuments';
import { DeleteMultipleCurrencyDocumentsModal } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/DeleteMultipleCurrencyDocumentsModal';
import { activeButtons } from '@app/pages/documents/components/TableToolbar/helper';
import { styles } from '@app/pages/documents/components/TableToolbar/style';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface TableToolbarProps {
  selectedDocuments: GetDocumentWithAvailableActionsDto[];
  onEditClick(document: GetDocumentWithAvailableActionsDto): void;
  onSignClick(): void;
  onSendToSignSuccess(): void;
  onMultipleDeleteDocumentSuccess(): void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  selectedDocuments,
  onSignClick,
  onEditClick,
  onSendToSignSuccess,
  onMultipleDeleteDocumentSuccess,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const [showSendToSignPaymentOrderDialog, setShowSendToSignPaymentOrderDialog] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
  const documentsIds = selectedDocuments.map((document) => document.uuid);
  const navigate = useNavigate();
  const onDeleteDocumentHandler = () => {
    setShowDeleteDocumentDialog(true);
  };

  const onPreviewClickHandler = () => {
    if (documentsIds) {
      navigate(RouteList.documents_show.replace(':uuid', documentsIds[0]));
    }
  };

  const onEditClickHandler = () => {
    if (selectedDocuments[0].signed) {
      setShowConfirmEditPopup(true);
    } else {
      onEditClick(selectedDocuments[0]);
    }
  };

  const buttonConfig = activeButtons(selectedDocuments, permissions);

  const { t } = useTranslation();
  const onSendToSignClickHandler = () => {
    setShowSendToSignPaymentOrderDialog(true);
  };

  const onSendToSignSuccessHandler = () => {
    setShowSendToSignPaymentOrderDialog(false);
    onSendToSignSuccess();
  };

  const onCloseSendToSignHandler = () => {
    setShowSendToSignPaymentOrderDialog(false);
  };

  const onSubmitDeleteHandler = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onCancelDeleteDocument = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onDeleteDocumentSuccessHandler = () => {
    setShowDeleteDocumentDialog(false);
    onMultipleDeleteDocumentSuccess();
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    onEditClick(selectedDocuments[0]);
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  return (
    <>
      <Box pb={1}>
        {selectedDocuments && selectedDocuments.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
          >
            <Grid item container xs={12} alignItems="center" justifyContent="flex-start">
              <Box alignContent="center">
                <Grid container alignItems="center">
                  <Typography variant="body2" component="span" sx={styles.buttonText}>
                    {`${selectedDocuments.length} ${t('checked')}`}
                  </Typography>
                </Grid>
              </Box>
              {buttonConfig.sign ? (
                <>
                  <Box sx={styles.divider} />
                  <Button variant="text" sx={styles.buttonRoot} onClick={onSignClick} endIcon={<SignatureIcon />}>
                    <Typography variant="inherit">{t('sign')}</Typography>
                  </Button>
                </>
              ) : null}
              {buttonConfig.sendToSign ? (
                <>
                  <Box sx={styles.divider} />
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSendToSignClickHandler}
                    endIcon={<SendIcon />}
                  >
                    <Typography variant="inherit">{t('toSign')}</Typography>
                  </Button>
                </>
              ) : null}
              {buttonConfig.edit ? (
                <>
                  <Box sx={styles.divider} />
                  <Button variant="text" sx={styles.buttonRoot} onClick={onEditClickHandler} startIcon={<EditIcon />}>
                    <Typography variant="inherit">{t('edit')}</Typography>
                  </Button>
                </>
              ) : null}
              {buttonConfig.delete ? (
                <>
                  <Box sx={styles.divider} />
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onDeleteDocumentHandler}
                    startIcon={<DeleteIcon />}
                  >
                    <Typography variant="inherit">{t('delete')}</Typography>
                  </Button>
                </>
              ) : null}
              {buttonConfig.view ? (
                <>
                  <Box sx={styles.divider} />
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onPreviewClickHandler}
                    startIcon={<PreviewIcon />}
                  >
                    <Typography>{t('preview')}</Typography>
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Box>
      {showSendToSignPaymentOrderDialog ? (
        <SendToSignExchangeDocuments
          selectedDocuments={selectedDocuments}
          onClose={onCloseSendToSignHandler}
          onSubmitSuccess={onSendToSignSuccessHandler}
        />
      ) : null}
      {showDeleteDocumentDialog ? (
        <DeleteMultipleCurrencyDocumentsModal
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          selectedDocuments={selectedDocuments}
          onSubmit={onSubmitDeleteHandler}
        />
      ) : null}
      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

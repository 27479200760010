import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, Grid, IconButton, Typography } from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { OtpInput } from '@app/core/components/OtpInput/OtpInput';
import { StatusText } from '@app/core/components/StatusText';
import { useDisable2fa } from '@app/core/hooks';
import { validationSchema } from '@app/pages/profile/desktop/components/Security/components/validationSchema';
import { styles } from '@app/pages/profile/desktop/components/Security/style';

interface DeactivationModalProps {
  onClose(): void;
  onDeactivationSuccess(): void;
}

export const DeactivationModal: React.FC<DeactivationModalProps> = ({ onClose, onDeactivationSuccess }) => {
  const [focusOtp, setFocusOtp] = useState<boolean>(true);
  const { t } = useTranslation();

  const { notify } = useNotify();

  const { mutate } = useDisable2fa();

  const methods = useForm<{ code: string }>({ resolver: yupResolver(validationSchema(t)), mode: 'onSubmit' });

  const { control, getValues, trigger, setValue } = methods;

  const onConfirm = () => {
    const { code } = getValues();
    setFocusOtp(false);
    trigger().then((valid) => {
      if (valid) {
        mutate(
          { code },
          {
            onSuccess: onDeactivationSuccess,
            onError: () => {
              setValue('code', '');
              setFocusOtp(true);
              notify({
                notifyProps: {
                  title: t('error'),
                  message: t('wrongCodeError'),
                  severity: 'error',
                },
              });
            },
          }
        );
      }
    });
  };
  return (
    <ModalCommon onClose={onClose} open>
      <FormProvider {...methods}>
        <form>
          <Box sx={styles.paper}>
            <Box pb={3} width="100%">
              <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Typography sx={styles.modalTitle}>{t('gaDeactivationTitle')}</Typography>
                <IconButton size="small" onClick={onClose}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Box>
            <Box mt={3}>
              <Typography variant="body2">{t('gaDeactivationDescription')}</Typography>
            </Box>
            <Grid container>
              <Grid item container direction="column" alignItems="flex-start" justifyContent="space-between" xs={7}>
                <Box mb={5} mt={5}>
                  <Box pb={2}>
                    <Typography variant="body2">{t('setSms')}</Typography>
                  </Box>
                  <Controller
                    render={({ field, fieldState: { error, invalid } }) => (
                      <>
                        <OtpInput
                          {...field}
                          numInputs={6}
                          shouldAutoFocus
                          isInputNum
                          inputStyle={styles.otp}
                          hasErrored={invalid}
                          errorStyle={styles.otpError}
                          setFocus={focusOtp}
                        />
                        {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
                      </>
                    )}
                    name="code"
                    control={control}
                  />
                </Box>
              </Grid>
            </Grid>
            <CardActions sx={styles.cardAction}>
              <Grid>
                <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onClose}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                  {t('confirm')}
                </Button>
              </Grid>
            </CardActions>
          </Box>
        </form>
      </FormProvider>
    </ModalCommon>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { GetSwiftResponseDto } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetSwiftCounterparties } from '@app/core/hooks/useGetSwiftCounterparties';
import { PaginationChangeType, SortingRule } from '@app/core/types';
import { AddSwiftCounterparty } from '@app/pages/counterpartiesSwift/components/AddSwiftCounterparty';
import { DeleteSwiftCounterpartyModal } from '@app/pages/counterpartiesSwift/components/DeleteSwiftCounterparty';
import { EditSwiftCounterparty } from '@app/pages/counterpartiesSwift/components/EditSwiftCounterparty';
import { SwiftCounterpartiesFilter } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartiesFilter';
import { SwiftCounterpartiesTable } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartiesTable';
import { SwiftCounterpartiesFilterRows, resetFormData } from '@app/pages/counterpartiesSwift/types';

export const SwiftCounterpartiesPage: React.FC = () => {
  const { t } = useTranslation();
  const [showEditCounterpartyDialog, setShowEditCounterpartyDialog] = useState<boolean>(false);
  const [showAddCounterpartyDialog, setShowAddCounterpartyDialog] = useState<boolean>(false);
  const [showDeleteCounterpartyDialog, setShowDeleteCounterpartyDialog] = useState<boolean>(false);
  const [selectCounterparty, setSelectCounterparty] = useState<GetSwiftResponseDto | undefined>();
  const [selectCounterpartyId, setSelectCounterpartyId] = useState<string | undefined>();
  const deleteCounterpartyId = useRef<string | undefined>(undefined);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const sortRuleRef = useRef<SortingRule | undefined>();

  const methods = useForm<SwiftCounterpartiesFilterRows>({
    mode: 'onChange',
  });
  const { handleSubmit, reset: formReset, getValues } = methods;
  const { data, mutate, isLoading } = useGetSwiftCounterparties();

  useEffect(() => {
    mutate({ pagination: paginationRef.current });
  }, [mutate]);

  const updateTable = () => {
    mutate({
      ...getValues(),
      countryCode: getValues('country') ? getValues('country').numeric : undefined,
      pagination: paginationRef.current,
      sortBy: sortRuleRef.current,
    });
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateTable();
    }
  };

  const resetFormHandler = () => {
    formReset(resetFormData);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({ pagination: paginationRef.current });
  };

  const onSortHandler = (rule: SortingRule) => {
    sortRuleRef.current = rule;
    updateTable();
  };

  const addNewCounterparty = () => {
    setShowAddCounterpartyDialog(true);
  };

  const editCounterparty = (id: string) => {
    setSelectCounterpartyId(id);
  };

  useEffect(() => {
    if (selectCounterpartyId && data) {
      setSelectCounterparty(data?.rows.find((item) => item.uuid === selectCounterpartyId));
      setShowEditCounterpartyDialog(true);
    }
  }, [data, selectCounterpartyId]);

  const onCloseEditModal = () => {
    setShowEditCounterpartyDialog(false);
    setSelectCounterparty(undefined);
    setSelectCounterpartyId(undefined);
  };

  const onCloseAddModal = () => {
    setShowAddCounterpartyDialog(false);
  };

  const handleDeleteClick = (id: string) => {
    deleteCounterpartyId.current = id;
    setShowDeleteCounterpartyDialog(true);
  };

  const onSuccessDeleteCounterparty = () => {
    setShowDeleteCounterpartyDialog(false);
    setShowEditCounterpartyDialog(false);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    updateTable();
    setSelectCounterpartyId(undefined);
  };

  const onCloseDeleteCounterpartyDialog = () => {
    setShowDeleteCounterpartyDialog(false);
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateTable();
  };

  const onSuccessAddCounterpartyHandler = () => {
    updateTable();
    setShowAddCounterpartyDialog(false);
  };

  const onSuccessEditCounterpartyHandler = () => {
    updateTable();
    setShowEditCounterpartyDialog(false);
    setSelectCounterpartyId(undefined);
  };

  const counterparties = data?.rows || [];

  return (
    <>
      <PageWrapper>
        <PageHeader title={t('swiftCounterparties')} showNavigateButton backLink={-1} useDesktopStyle>
          <Grid item>
            <Button variant="contained" color="primary" onClick={addNewCounterparty}>
              {t('add')}
            </Button>
          </Grid>
        </PageHeader>
        <PageContainer>
          <Box
            mt={4}
            sx={{
              width: `100%`,
            }}
          >
            <Box mb={4}>
              <FilterIcon />
              <Typography
                variant="h6"
                component="span"
                sx={{
                  color: 'colors.primary600',
                  fontWeight: 'bold',
                  verticalAlign: 'bottom',
                  marginLeft: '4px',
                }}
              >
                {t('filters')}
              </Typography>
            </Box>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(updateTable)} onKeyPress={onKeyPressHandler} role="presentation">
                <SwiftCounterpartiesFilter resetFormHandler={resetFormHandler} />
              </form>
              <SwiftCounterpartiesTable
                counterparties={counterparties}
                handleShow={editCounterparty}
                deleteCounterpartyModal={handleDeleteClick}
                isLoading={isLoading}
                pagination={data?.pagination}
                onPaginationChange={onPaginationChangeHandler}
                onSort={onSortHandler}
              />
            </FormProvider>
          </Box>
        </PageContainer>
      </PageWrapper>
      {showAddCounterpartyDialog ? (
        <AddSwiftCounterparty onClose={onCloseAddModal} onSuccessAddCounterparty={onSuccessAddCounterpartyHandler} />
      ) : null}
      {showEditCounterpartyDialog ? (
        <EditSwiftCounterparty
          onClose={onCloseEditModal}
          onSuccessAddCounterparty={onSuccessEditCounterpartyHandler}
          counterparty={selectCounterparty}
        />
      ) : null}
      {showDeleteCounterpartyDialog && deleteCounterpartyId.current ? (
        <DeleteSwiftCounterpartyModal
          counterpartyId={deleteCounterpartyId.current}
          onClose={onCloseDeleteCounterpartyDialog}
          onErrorDelete={onCloseDeleteCounterpartyDialog}
          onSuccessDelete={onSuccessDeleteCounterparty}
        />
      ) : null}
    </>
  );
};

import { Theme } from '@mui/material';

import { isMobile } from '../../utils';

export const useStyles = ({ transitions, radius, breakpoints, palette: { colors, action, common } }: Theme) => ({
  checkBox: {
    paddingRight: '4px',
  },
  accountName: {
    color: colors.primary600,
    fontWeight: 400,
  },
  closed: {
    color: colors.gray300,
  },
  accountNumber: {
    color: colors.gray400,
    fontWeight: 400,
  },
  currencyActive: {
    color: colors.gray800,
  },
  currencyClosed: {
    color: colors.gray300,
  },
  chipRoot: {
    width: '100%',
    justifyContent: 'space-between',
  },
  groupHeader: {
    overflow: 'hidden',
    display: 'flex',
    marginTop: '12px',
    marginBottom: '12px',
  },
  groupLabel: {
    flexGrow: 1,
    flexShrink: 0,
    color: colors.gray400,
    fontWeight: 400,
    paddingRight: '2px',
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    borderRadius: '3px',
    width: '100%',
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  inputContainer: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  popperDisablePortal: {
    margin: 0,
    position: 'relative',
  },
  listbox: {
    maxHeight: '40vh',
    overflow: 'auto',
  },
  selectContentRoot: {
    margin: '16px 0px',
  },
  endAdornment: {
    position: 'absolute',
    right: '20px',
    top: 'calc(50% - 8px)',
  },
  searchIcon: {
    fill: 'currentColor',
    color: colors.gray300,
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
  selectPanel: {
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedCountLabel: {
    color: colors.gray600,
    fontWeight: 400,
  },
  selectedCount: {
    color: colors.primary600,
  },
  resetButton: {
    padding: 0,
    justifyContent: 'flex-end',
    fontWeight: 400,
  },
  selectPlaceholder: {
    color: colors.gray300,
    fontWeight: 400,
  },
  chipContainer: {
    width: '100%',
    position: 'relative',
    marginRight: '32px',
    backgroundColor: 'white',
  },
  scrollContainer: {
    width: '100%',
    position: 'absolute',
    overflowX: 'auto',
    top: 0,
    left: 0,
    transform: ' translate(0,-25%)',
  },
  chip: {
    height: '32px',
    backgroundColor: () => {
      return isMobile ? colors.mobileAccountChipBackgroundColor : colors.desktopAccountChipBackgroundColor;
    },
    borderRadius: radius.control,
    border: () => `1px solid ${isMobile ? colors.mobileAccountChipBorderColor : colors.desktopAccountChipBorderColor}`,
    marginLeft: '4px',
    color: () => (isMobile ? colors.mobileAccountChipTextColor : colors.desktopAccountChipTextColor),
  },
  option: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: '6px',
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',
    paddingBottom: '6px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
    '&[aria-disabled="true"]': {
      opacity: action.disabledOpacity,
      pointerEvents: 'none',
    },
  },
  selectAllFormControlLabel: {
    marginLeft: 0,
    paddingBottom: '6px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  accentSubAccountNumber: {
    fontWeight: 600,
  },
  chipText: {
    color: () => (isMobile ? colors.mobileAccountChipTextColor : colors.desktopAccountChipTextColor),
  },
  errorText: {
    color: () => (isMobile ? colors.mobileAccountErrorTextColor : ''),
  },
});

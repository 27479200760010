export enum MenuItemsEnum {
  all = 'all',
  correct = 'correct',
  error = 'error',
}

export enum OrderFieldEnum {
  paymentDocumentNumber = 'paymentDocumentNumber',
  paymentDocumentDate = 'paymentDocumentDate',

  senderName = 'senderName',
  senderAccount = 'senderAccount',
  senderLei = 'senderLei',
  senderBank = 'senderBank',
  senderBankCode = 'senderBankCode',

  recipientName = 'recipientName',
  recipientAccount = 'recipientAccount',
  recipientLei = 'recipientLei',
  recipientBank = 'recipientBank',
  recipientBankCode = 'recipientBankCode',

  amount = 'amount',
  currency = 'currency',
  paymentReference = 'paymentReference',
  status = 'status',
}

export type Order = {
  data: {
    paymentDocumentNumber: string;
    paymentDocumentDate: string;
    senderAccount: string;
    senderName: string;
    senderLei: string;
    recipientAccount: string;
    recipientName: string;
    recipientLei: string;
    senderBank: string;
    senderBankCode: string;
    recipientBank: string;
    recipientBankCode: string;
    amount: number;
    currency: string;
    status: boolean;
    paymentReference: string;
    uuid: string;
    recipientNonLeiFlag: boolean;
    recipientPassport: string;
  };
  errors?: string[];
};

export type OrderRow = {
  uuid: string;
  paymentDocumentNumber: string;
  paymentDocumentDate: string;
  senderAccount: string;
  senderName: string;
  senderLei: string;
  recipientAccount: string;
  recipientName: string;
  recipientLei: string;
  senderBank: string;
  senderBankCode: string;
  recipientBank: string;
  recipientBankCode: string;
  amount: number;
  currency: string;
  paymentReference: string;
  status: boolean;
  recipientNonLeiFlag: boolean;
  recipientPassport: string;
};

export const formatBIC = (v: string): string => {
  const str = (v.match(/[a-zA-Z0-9]*/g) || []).join('').toUpperCase();
  const parsedBIC = str.replace(/ /g, '');
  const bankCode = parsedBIC.slice(0, 4);
  const countryCode = parsedBIC.slice(4, 6);
  const locationCode = parsedBIC.slice(6, 8);
  const branchCode = parsedBIC.slice(8, 11);
  return `${bankCode} ${countryCode} ${locationCode} ${branchCode}`.trim();
};

export const formatFileSize = (bytes: number, decimalPoint: number): string => {
  if (bytes == 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

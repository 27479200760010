import { string, object, SchemaOf } from 'yup';

type SchemaObject = {
  templateName: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaObject> => {
  return object().shape({
    templateName: string().required(t('errorEmptyField')),
  });
};

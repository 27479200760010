import { SwiftOperationWithAvailableButtons } from '@app/core/api';

export const buildTableRowsData = (
  swiftOperations: SwiftOperationWithAvailableButtons[]
): SwiftOperationWithAvailableButtons[] => {
  return swiftOperations.map((operations) => {
    return {
      ...operations,
    };
  });
};

import Logger from 'js-logger';

import { cryptoConfig, euSettings } from '@app/src/config';
import { CrtViewer } from '@app/src/crypto/classes/CrtViewer';
import { Signer } from '@app/src/crypto/classes/Signer';
import { Verifier } from '@app/src/crypto/classes/Verifier';
import { arrayToHeap, convertBase64ToArrayBuffer, freeArray, getErrorDescription } from '@app/src/crypto/helpers';
import { CertificateType, ValidationResult } from '@app/src/crypto/types';

// Old version
interface InitCryptoProps {
  onPrint?(text: string): void;
  onRuntimeInitialized?(): void;
  printErr?(text: string): void;
}

export const initCryptoPrev = ({ onPrint, onRuntimeInitialized, printErr }: InitCryptoProps) => {
  try {
    window.WASMModule = Module().then(() => {});

    Logger.log('WASM module loaded');

    WASMModule['print'] = () => {
      return function (text: string) {
        // eslint-disable-next-line no-param-reassign,prefer-rest-params
        if (arguments.length > 1) text = Array.prototype.slice.call(arguments).join(' ');
        Logger.error(text);
        if (onPrint) {
          onPrint(text);
        }
      };
    };

    WASMModule['onRuntimeInitialized'] = async () => {
      Logger.log('onRuntimeInitialized section beg');
      const appConfigurationJsonStr = JSON.stringify(cryptoConfig.endpoinConfiguration);
      Logger.log(`configuration status: ${getErrorDescription(setUpConfiguration(appConfigurationJsonStr))}`);

      WASMModule['websocket']['url'] = `wss://${cryptoConfig.endpoinConfiguration.ProxySettings.Host}`;

      if (onRuntimeInitialized) {
        onRuntimeInitialized();
      }
    };

    WASMModule['printErr'] = (text: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      if (arguments.length > 1) text = Array.prototype.slice.call(arguments).join(' ');
      if (printErr) {
        printErr(text);
      }
      throw new Error(text);
    };
  } catch (e) {
    Logger.error(e);
  }
};

export const getCertInfo = (cer_base64: string) => {
  const certForInfoArrInfoPack = arrayToHeap(Uint8Array.from(atob(cer_base64), (c) => c.charCodeAt(0)));

  let viewer;
  try {
    viewer = new CrtViewer(certForInfoArrInfoPack);
    Logger.log(viewer.getDataJson());
  } catch (error) {
    Logger.error(`Error: ${error}`);
  } finally {
    if (viewer !== undefined) viewer.destroy();
    if (certForInfoArrInfoPack['ptr'] !== undefined) freeArray(certForInfoArrInfoPack['ptr']);
  }
};

export const getExtendedCertInfo = async (certificate: string): Promise<CertificateType[]> => {
  const certForInfoArrInfoPack = arrayToHeap(Uint8Array.from(atob(certificate), (c) => c.charCodeAt(0)));

  const memOffset = await getAssociatedCertificatesJsonFromKeyStore(
    certForInfoArrInfoPack.ptr,
    certForInfoArrInfoPack.length,
    ''
  );

  try {
    const certsData = WASMModule.UTF8ToString(memOffset);
    return JSON.parse(certsData);
  } catch (e) {
    throw new Error("Can't read a certificate. The key expired or a file incorrect.");
  }
};

export const signString = (
  strForSign: string,
  keyInBase64: string,
  password: string,
  paramsStr: string
): Promise<Uint8Array> => {
  const keyStoreForSignByteArray = convertBase64ToArrayBuffer(keyInBase64);
  const fileForSignByteArray = new TextEncoder().encode(strForSign);

  const dataForSignArrInfoPack = arrayToHeap(fileForSignByteArray);
  const keyStoreArrInfoPack = arrayToHeap(keyStoreForSignByteArray);

  const signer = new Signer();

  return signer.sign(keyStoreArrInfoPack, password, dataForSignArrInfoPack, null, paramsStr);
};

export const verifySignedData = (signedData: Uint8Array, paramsStr: string): Promise<ValidationResult> => {
  const DataPtr = arrayToHeap(new Uint8Array(signedData));

  const haveData = cmsSigned_isContentAttached(DataPtr['ptr'], DataPtr['length']);

  if (haveData) {
    const verifier = new Verifier(haveData);

    try {
      return verifier.verify(DataPtr, 'any', paramsStr);
    } catch (e) {
      Logger.error(e);
      throw e;
    }
  } else {
    Logger.error(`CMS doesn't have data inside`);
    throw new Error(`CMS doesn't have data inside`);
  }
};

// New version

export const initCrypto = () => {
  // Бібліотека для роботи з файловими ключами, що не потребує
  // встановлення додатково ПЗ
  window.euSignFile = new EndUser('/lib/iit/euscp.worker.ex.js', EndUserConstants.EndUserLibraryType.JS);

  // Бібліотека для роботи з аппаратними носіями, що потребує
  // встановлення додатково ПЗ бібліотек web-підпису, web-розширення для браузера
  window.euSignKeyMedia = new EndUser(undefined, EndUserConstants.EndUserLibraryType.SW);
};

// Ініціалізація бібліотеки для роботи з ключем із файлу
export const initializeEndUserSignFile = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    euSignFile
      .IsInitialized()
      .then(function (result: any) {
        if (result) {
          Logger.log('Crypto lib already initialized');
          resolve();
          return;
        }

        Logger.log('Crypto lib initializing...');
        return euSignFile.Initialize(euSettings);
      })
      .then(function () {
        Logger.log('Crypto lib initialized');
        resolve();
      })
      .catch(function (e: any) {
        reject(e);
      });
  });
};

// Ініціалізація бібліотеки для роботи з фізичним ключем
export const initializeEndUserSignKeyMedia = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
    euSignKeyMedia
      .GetLibraryInfo()
      .then((result: EndUserLibraryInfoSW) => {
        if (!result.supported) {
          // Logger.log(result);
          throw result;
          // throw 'Бібліотека web-підпису не підтримується ' + 'в вашому браузері або ОС';
        }

        if (!result.loaded) {
          // Logger.log(result);
          // Бібліотека встановлена, але потребує оновлення
          if (result.isNativeLibraryNeedUpdate) {
            throw result;
            // throw (
            //   'Бібліотека web-підпису потребує оновлення. ' +
            //   'Будь ласка, встановіть оновлення за посиланням ' +
            //   result.nativeLibraryInstallURL
            // );
          }

          // Якщо браузер підтримує web-розширення рекомендується
          // додатково до нативних модулів встановлювати web-розширення
          // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
          if (result.isWebExtensionSupported && !result.isWebExtensionInstalled) {
            throw result;
            // throw (
            //   'Бібліотека web-підпису потребує встановлення web-розширення. ' +
            //   'Будь ласка, встановіть web-розширення за посиланням ' +
            //   result.webExtensionInstallURL +
            //   ' та оновіть сторінку'
            // );
          }

          // Бібліотека (нативні модулі) не встановлені
          throw result;
          // throw (
          //   'Бібліотека web-підпису потребує встановлення. ' +
          //   'Будь ласка, встановіть бібліотеку за посиланням ' +
          //   result.nativeLibraryInstallURL +
          //   ' та оновіть сторінку'
          // );
        }

        return euSignKeyMedia.IsInitialized();
      })
      .then((isInitialized: boolean) => {
        if (isInitialized) {
          Logger.log('EndUser by KeyMedia: already initialized');
          resolve();
          return;
        }

        Logger.log('EndUser by KeyMedia: initializing...');
        return euSignKeyMedia.Initialize(euSettings);
      })
      .then(() => {
        Logger.log('EndUser by KeyMedia: initialized');
        resolve();
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

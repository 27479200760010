import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { getMenuItems } from '@app/pages/companyProfile/helper';
import { MobileCompanyData } from '@app/pages/companyProfile/mobile/components/MobileCompanyData';
import { MobileHistoryOfVisitsPage } from '@app/pages/companyProfile/mobile/components/MobileHistoryOfVisits';
import { MobileUsersPage } from '@app/pages/companyProfile/mobile/components/MobileUsers';
import { CompanyProfileMenuFilter } from '@app/pages/companyProfile/types';

export const MobileCompanyProfileView: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeMobileMenuItem, setActiveMobileMenuItem] = useState<CompanyProfileMenuFilter>(
    (searchParams.get('tab') as CompanyProfileMenuFilter) || CompanyProfileMenuFilter.Data
  );
  const { t } = useTranslation();

  const menuItems = getMenuItems(t);

  const onMobileProfiledMenuChange = (selected: CompanyProfileMenuFilter) => {
    setSearchParams(`tab=${selected}`);
    setActiveMobileMenuItem(selected);
  };

  return (
    <PageWrapper
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        height: '100vh',
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          height: '100vh',
        }}
      >
        <PageHeader
          title={t('companyProfile')}
          styles={{
            title: {
              color: '#FFFFFF',
            },
            closeButton: {
              color: '#FFFFFF',
            },
            burgerButton: {
              color: '#FFFFFF',
            },
          }}
        />
        <Box
          sx={{
            overflowX: 'auto',
            width: '100%',
          }}
        >
          <HorizontalMenu<CompanyProfileMenuFilter>
            onChange={onMobileProfiledMenuChange}
            menuItems={menuItems}
            styles={{
              itemDefault: {
                color: 'colors.gray200',
                fontWeight: 600,
                marginRight: '12px',
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
              },
              itemSelected: {
                color: 'colors.primary600',
                fontWeight: 600,
                fontSize: '1rem',
                marginRight: '12px',
                whiteSpace: 'nowrap',
              },
            }}
          />
          {activeMobileMenuItem === CompanyProfileMenuFilter.Data && <MobileCompanyData />}
          {activeMobileMenuItem === CompanyProfileMenuFilter.History && <MobileHistoryOfVisitsPage />}
          {activeMobileMenuItem === CompanyProfileMenuFilter.Users && <MobileUsersPage />}
        </Box>
      </Grid>
    </PageWrapper>
  );
};

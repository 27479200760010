import { useMutation, UseMutationResult } from 'react-query';

import { getClientId, statementsApiClient, ApiError, FileLinkResponseDto, GetOperationsFileDto } from '@app/core/api';

const getStatementsFileFn = async (filterData: GetOperationsFileDto): Promise<FileLinkResponseDto> => {
  const response = await statementsApiClient.operationsControllerGetFile(getClientId(), filterData);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get order file link.");
  }

  return response.data;
};

export const useGetStatementsFile = (): UseMutationResult<FileLinkResponseDto, ApiError, GetOperationsFileDto> =>
  useMutation<FileLinkResponseDto, ApiError, GetOperationsFileDto>(getStatementsFileFn);

import React from 'react';

import { Grid, Typography } from '@mui/material';

import { styles } from './styles';
import { formatAmount } from '../../../../utils';
import { IBanText } from '../../../IBanText';
import { AccountBalanceMovement } from '../../types';

type BalanceMovementItemProps = {
  accountBalanceMovement: AccountBalanceMovement;
};

export const BalanceMovementItem: React.FC<BalanceMovementItemProps> = ({ accountBalanceMovement }) => {
  const { account, openingBalance, deposit, withdrawal, closingBalance } = accountBalanceMovement;

  return (
    <Grid container justifyContent="space-between" sx={styles.movementItemContainer}>
      <Grid item xs={3}>
        <Typography component="span" variant="body2" sx={styles.accountNumber}>
          <IBanText text={account.number} />
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Typography variant="body2" sx={styles.openingBalance}>
          {`${formatAmount(openingBalance)} `}
          <Typography variant="caption" component="span">
            {account.currency}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Typography variant="body2" sx={styles.withdrawal}>
          {`${formatAmount(withdrawal)} `}
          <Typography variant="caption" component="span" sx={styles.currency}>
            {account.currency}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Typography variant="body2" sx={styles.deposit}>
          {`${formatAmount(deposit)} `}
          <Typography variant="caption" component="span" sx={styles.currency}>
            {account.currency}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Typography variant="body2" sx={styles.closingBalance}>
          {`${formatAmount(closingBalance)} `}
          <Typography variant="body2" component="span" sx={styles.currency}>
            {account.currency}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CollateralListResponseDto, OverdraftRepaymentTypeEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { IBanText } from '@app/core/components/IBanText';
import { SafetyTable } from '@app/core/components/SafetyTable';
import { StatusBadge } from '@app/core/components/StatusBadge';
import { WaitForAnotherLoanPaymentAlert } from '@app/core/components/WaitForAnotherLoanPaymentAlert';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { PaginationChangeType } from '@app/core/types';
import { formatDate, formatDateWithAppend, formatAmount } from '@app/core/utils';
import { getCollateral, useGetOverdraftByUuid } from '@app/pages/overdrafts/show/query';
import { styles } from '@app/pages/overdrafts/show/style';
import { OverdraftMenuItems } from '@app/pages/overdrafts/types';
import { RouteList } from '@app/src/constants/routeList';

export const OverdraftShow: React.FC = () => {
  const { uuid = 'no-uuid' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<OverdraftMenuItems>(OverdraftMenuItems.safety);
  const [interests, setInterests] = useState<boolean>(false);
  const [overdueInterests, setOverdueInterests] = useState<boolean>(false);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });

  const menuItems: MenuItem<OverdraftMenuItems>[] = [
    {
      id: 1,
      value: OverdraftMenuItems.operations,
      labelText: t('operations'),
    },
    {
      id: 2,
      value: OverdraftMenuItems.safety,
      labelText: t('safety'),
    },
  ];

  const { data: overdraft, refetch: refetchOverdraft } = useGetOverdraftByUuid(uuid);

  const { data: collateral, refetch: refetchCollateral } = useQuery<CollateralListResponseDto>(
    'overdraftCollateral',
    () => getCollateral(uuid, paginationRef.current),
    {
      enabled: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    refetchOverdraft();
    refetchCollateral();
  }, [refetchOverdraft, refetchCollateral]);

  useEffect(() => {
    if (overdraft) {
      setInterests(overdraft.repayments.repaymentTypes.includes(OverdraftRepaymentTypeEnum.Interests));
      setOverdueInterests(overdraft.repayments.repaymentTypes.includes(OverdraftRepaymentTypeEnum.OverdueInterests));
    }
  }, [overdraft]);

  const onTabChangeHandler = (tab: OverdraftMenuItems) => {
    setCurrentTab(tab);
  };

  const startDate = overdraft?.startDate.split('-').reverse().join('-') || '';
  const endDate = overdraft?.endDate.split('-').reverse().join('-') || '';

  const payClickHandler = (repaymentType: OverdraftRepaymentTypeEnum) => {
    if (overdraft) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.payment_create,
        query: {
          oid: overdraft.uuid,
          rt: repaymentType,
        },
      });
      navigate(urlTo);
    }
  };

  const interestsBtnDisabled = overdraft?.repayments.blockedRepaymentTypes.includes(
    OverdraftRepaymentTypeEnum.Interests
  );
  const overdueInterestsBtnDisabled = overdraft?.repayments.blockedRepaymentTypes.includes(
    OverdraftRepaymentTypeEnum.OverdueInterests
  );

  const showAlertMessage = interestsBtnDisabled || overdueInterestsBtnDisabled;

  const paginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    refetchCollateral();
  };

  return (
    <PageWrapper>
      <PageHeader title={`${t('overdraft')} № ${overdraft?.agreementNumber}`} showNavigateButton backLink={-1}>
        <Grid item container justifyContent="flex-end" alignItems="center">
          {interests ? (
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => payClickHandler(OverdraftRepaymentTypeEnum.Interests)}
                disabled={interestsBtnDisabled}
              >
                {t('pay_interests')}
              </Button>
            </Box>
          ) : null}

          {overdueInterests ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(OverdraftRepaymentTypeEnum.OverdueInterests)}
                disabled={overdueInterestsBtnDisabled}
              >
                {t('pay_overdueInterests')}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </PageHeader>
      <Grid container direction="column">
        {showAlertMessage ? <WaitForAnotherLoanPaymentAlert /> : null}
        <Grid container direction="column" sx={styles.paper}>
          <Grid item>
            <Box pl={5} pt={5}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <Typography sx={styles.companyName}>ТОВ “Тіллі-Віллі”</Typography>
                <Box pl={2}>
                  <StatusBadge status={overdraft?.dealState || ''} />
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={10} pl={5} sx={styles.infoBlock}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('startDate')}
                    </Typography>
                    <Typography sx={styles.bold}>{formatDate(startDate, '.')}</Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('endDate')}
                    </Typography>
                    <Typography sx={styles.bold}>{formatDateWithAppend(endDate, '.')}</Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('interestRate')}
                    </Typography>
                    <Typography sx={styles.bold}>
                      {formatAmount(overdraft?.interestRate || '')}
                      <Typography component="span">%</Typography>
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('commissionRate')}
                    </Typography>
                    <Typography sx={styles.bold}>
                      {formatAmount(overdraft?.commissionRate || '')}
                      <Typography component="span">%</Typography>
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('overdraftAccount')}
                    </Typography>
                    <IBanText text={overdraft?.accountOverdraft || ''} />
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={styles.limitBlock}>
            <Box pl={5} pr={5}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('overdraftLimit')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {formatAmount(overdraft?.totalLimit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {overdraft?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3} sx={styles.debt}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('limitDebt')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {formatAmount(overdraft?.usedLimit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {overdraft?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('availableLimit')}
                      </Typography>
                      <Typography sx={styles.bold}>
                        {formatAmount(overdraft?.availableLimit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {overdraft?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('currentDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {t('noData')}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('overdueDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {t('noData')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestAccrual')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(overdraft?.interestAccrual || '')}{' '}
                <Typography variant="body2" component="span">
                  {overdraft?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestOverdue')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(overdraft?.interestOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {overdraft?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(overdraft?.usedLimit || '')}{' '}
                <Typography variant="body2" component="span">
                  {overdraft?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(overdraft?.usedLimit || '')}{' '}
                <Typography variant="body2" component="span">
                  {overdraft?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={7}>
          <HorizontalMenu<OverdraftMenuItems>
            onChange={onTabChangeHandler}
            menuItems={menuItems}
            defaultActiveItem={OverdraftMenuItems.safety}
          />
        </Box>
        <Box mt={4}>
          {currentTab === OverdraftMenuItems.safety && (
            <SafetyTable
              data={collateral?.collateral || []}
              pagination={collateral?.pagination}
              onPaginationChange={paginationChangeHandler}
            />
          )}
        </Box>
      </Grid>
    </PageWrapper>
  );
};

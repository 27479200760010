import { array, object, SchemaOf, string } from 'yup';

import { StatementsFilterField } from '@app/core/components/Statements/types';

type LocalCurrencyOperationsFilterSchemaObject = {
  [StatementsFilterField.operationAccounts]: string[];
  [StatementsFilterField.dateRangeSelect]: string;
};

export const localCurrencyOperationsFilterSchema = (
  t: (key: string) => string
): SchemaOf<LocalCurrencyOperationsFilterSchemaObject> => {
  return object().shape({
    [StatementsFilterField.operationAccounts]: array().min(1, t('statementsFilter_errorAmountField')),
    [StatementsFilterField.dateRangeSelect]: string().required(t('statementsFilter_errorDateRange')),
  });
};

import React, { createContext, useContext, useState } from 'react';

import { CssBaseline } from '@mui/material';
import { Theme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';

import { ThemeEnum } from '@app/core/types';

import { baseTheme } from './default/baseTheme';
import { lightTheme } from './default/lightTheme';

export const themes: { [key in ThemeEnum]: Partial<Theme> | ((outerTheme: Theme) => Theme) } = {
  dark: baseTheme,
  light: lightTheme,
};

type ThemeProviderType = {
  children: React.ReactNode;
};

type ThemeState = {
  themeList: ThemeEnum[];
  currentTheme: ThemeEnum;
  changeTheme(theme: ThemeEnum): void;
};

const ThemeContext = createContext<ThemeState | undefined>(undefined);
const ThemeProvider: React.FC<ThemeProviderType> = ({ children }) => {
  const cookieTheme: ThemeEnum = Cookies.get('Theme') as ThemeEnum;
  const [currentTheme, setCurrentTheme] = useState<ThemeEnum>(cookieTheme || ThemeEnum.dark);

  const changeTheme = (theme: ThemeEnum) => {
    setCurrentTheme(theme);
    Cookies.set('Theme', theme);
  };

  const themeList = Object.values(ThemeEnum);

  return (
    <ThemeContext.Provider value={{ themeList, currentTheme, changeTheme }}>
      <MaterialThemeProvider theme={themes[currentTheme]}>
        <CssBaseline />
        {children}
      </MaterialThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = (): ThemeState => {
  const state = useContext(ThemeContext);
  if (state === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return state;
};

export { ThemeProvider, useTheme };

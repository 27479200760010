import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DownloadIcon, KeyIcon, PaperIcon, SuccessSmallIcon, WaitingSmallIcon, BankIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ChangeStatusRequestDtoStatusEnum,
  DocumentDto,
  FrontLanguageEnum,
  getClientId,
  MessageStatus,
  MessageType,
} from '@app/core/api';
import { Ellipsis, PeopleToSignMessageList, SignMessages } from '@app/core/components';
import {
  useChangeMessagesStatus,
  useDeleteMessages,
  useGetMessageFiles,
  useGetOneMessage,
  useSendMessagesToBank,
} from '@app/core/hooks';
import { formatFileSize, FULL_DATE_TIME_FORMAT, isMobile } from '@app/core/utils';
import { DeleteSingleMessagesDialog } from '@app/pages/letters/LettersListContent/LettersTable/components/DeleteSingleMessageDialog';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { MessageStatusHistory } from '../components/MessageStatusHistory';

export const MessageShowPage: React.FC = () => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { t, i18n } = useTranslation();
  const { showLoader } = useUIState();
  const { uuid = 'no-uuid' } = useParams();
  const { data, mutate, isLoading: isLoading1 } = useGetOneMessage();
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const [showDeletDialog, setShowDeleteDialog] = useState<boolean>(false);

  const { mutate: mutateChangeMessageStatus } = useChangeMessagesStatus();
  const { mutateAsync: mutateAsyncSendMessagesToBank } = useSendMessagesToBank();

  useEffect(() => {
    showLoader(isLoading1);
  }, [isLoading1, showLoader]);

  useEffect(() => {
    mutate({ uuid, language: i18n.language as FrontLanguageEnum });
  }, [i18n.language, mutate, uuid]);

  const showSignButtonByStatus: boolean =
    data?.type === MessageType.Outgoing &&
    (data?.status === MessageStatus.Saved || data?.status === MessageStatus.ToSign);
  const showSendToSignButtonByStatus: boolean =
    data?.type === MessageType.Outgoing && data?.status === MessageStatus.Saved;
  const showDeleteButtonByStatus: boolean =
    data?.type === MessageType.Outgoing &&
    (data?.status === MessageStatus.Saved || data?.status === MessageStatus.ToSign);
  const showEditButtonByStatus: boolean =
    data?.type === MessageType.Outgoing &&
    (data?.status === MessageStatus.Saved || data?.status === MessageStatus.ToSign);
  const showSendToBankButtonByStatus: boolean =
    data?.type === MessageType.Outgoing && data?.status === MessageStatus.Signed;

  const attachedDocuments = data?.documents;

  const onDeleteMessageClick = () => {
    setShowDeleteDialog(true);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteSuccess = () => {
    setShowDeleteDialog(false);
    navigate(-1);
  };

  const onEditHandler = () => {
    if (data) {
      navigate(RouteList.letters_edit.replace(':uuid', data?.uuid));
    }
  };

  const onSendToSignMessage = () => {
    if (data) {
      mutateChangeMessageStatus(
        {
          status: ChangeStatusRequestDtoStatusEnum.ToSign,
          uuids: [data?.uuid],
        },
        {
          onSuccess: () => {
            notify({
              notifyProps: {
                title: t('success'),
                message: t('sentToSign'),
                severity: 'success',
              },
            });
            navigate(-1);
          },
          onError: () => {
            notify({
              notifyProps: {
                title: t('server_error'),
                message: t('payment_sign_error_message'),
                severity: 'error',
              },
            });
          },
        }
      );
    }
  };

  const onSendToBankHandler = () => {
    if (data) {
      mutateAsyncSendMessagesToBank(
        {
          uuids: [data?.uuid],
        },
        {
          onSuccess: () => {
            notify({
              notifyProps: {
                title: t('success'),
                message: t('document_sent'),
                severity: 'success',
              },
            });
            navigate(-1);
          },
          onError: () => {
            notify({
              notifyProps: {
                title: t('server_error'),
                message: t('document_not_sent'),
                severity: 'error',
              },
            });
          },
        }
      );
    }
  };

  const onSignHandler = () => {
    setShowSignatureDialog(true);
  };

  const onSignSuccessHandler = () => {
    setShowSignatureDialog(false);
    navigate(-1);
  };

  const onSignModalClose = () => {
    setShowSignatureDialog(false);
  };

  const onFileDownloadClick = (file: DocumentDto) => {
    window.open(`${file.link}?clientId=${getClientId()}`, '_blank');
  };

  const onFileDownloadAllClick = () => {
    attachedDocuments?.forEach((file) => {
      window.open(`${file.link}?clientId=${getClientId()}`, '_blank');
    });
  };

  return (
    <>
      {data ? (
        <PageWrapper
          sx={{
            ...(isMobile && {
              backgroundColor: 'initial',
              borderRadius: 0,
              height: '100vh',
              padding: 0,
            }),
          }}
        >
          <PageHeader title={data.subject} showNavigateButton={!isMobile} backLink={-1} />
          <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
            <Box p={isMobile ? 0 : 3} width="100%">
              <Grid container>
                <Grid item xs={9}>
                  <Box sx={styles.container}>
                    <Grid container justifyContent="space-between">
                      <Box>
                        <Typography component="span" variant="body2" sx={styles.label}>
                          {t('letter_sender')}:{' '}
                        </Typography>
                        <Typography component="span" variant="body2">
                          {data.senderName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="span" variant="body2" sx={styles.label}>
                          {format(new Date(data.createdAt), FULL_DATE_TIME_FORMAT)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Box>
                      <Typography component="span" variant="body2" sx={styles.label}>
                        {t('subject')}:{' '}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {data.subject}
                      </Typography>
                    </Box>
                    <Box sx={styles.hr} />
                    <Box pt={4}>
                      <Typography component="span" variant="body2" sx={styles.label}>
                        {t('text')}:{' '}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {data.message}
                      </Typography>
                    </Box>
                    {attachedDocuments && attachedDocuments.length > 0 ? (
                      <Box pt={4}>
                        <Grid container item direction="column" wrap="nowrap">
                          <Box pt={4} pb={4}>
                            <Typography component="span" variant="body2" sx={styles.headerFile}>
                              {t('Завантажені файли ')}
                              <Box
                                component="span"
                                sx={styles.attachedFilesInfo}
                              >{` Кількість: ${attachedDocuments.length}`}</Box>
                              <IconButton onClick={onFileDownloadAllClick} sx={styles.iconDownloadAll}>
                                <DownloadIcon fontSize="small" />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid container item xs={6} direction="column" wrap="nowrap">
                          {attachedDocuments.length ? (
                            <>
                              {attachedDocuments.map((file, i) => {
                                return (
                                  <Box key={`${file.originalName}_${i}`} pl={isMobile ? 0 : 4} width="100%">
                                    <Grid item container wrap="nowrap" alignItems="center">
                                      <Grid item xs={8} container alignItems="center" wrap="nowrap">
                                        <PaperIcon fontSize="small" sx={styles.icon} />
                                        <Ellipsis
                                          text={file.originalName}
                                          styles={{
                                            color: 'colors.gray500',
                                            fontSize: '0.75rem',
                                            paddingLeft: '8px',
                                          }}
                                        />
                                      </Grid>
                                      <Grid item container xs={4} alignItems="center" justifyContent="flex-start">
                                        <DownloadIcon
                                          fontSize="small"
                                          sx={styles.iconDownload}
                                          onClick={() => onFileDownloadClick(file)}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                );
                              })}
                            </>
                          ) : (
                            <Grid container wrap="nowrap">
                              <PaperIcon fontSize="small" sx={styles.icon} />
                              <Typography variant="caption" sx={styles.noAttachedFilesLabel}>
                                {t('noAttachedFiles')}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                  <Box pt={4}>
                    <Grid container justifyContent={showDeleteButtonByStatus ? 'space-between' : 'flex-end'}>
                      {permissions.messages.delete && showDeleteButtonByStatus ? (
                        <Button color="error" onClick={onDeleteMessageClick}>
                          {t('delete')}
                        </Button>
                      ) : null}
                      <Box sx={{ justifyContent: 'space-between' }}>
                        <>
                          {permissions.messages.edit && showEditButtonByStatus ? (
                            <Button sx={styles.sendButton} variant="outlined" color="primary" onClick={onEditHandler}>
                              {t('edit')}
                            </Button>
                          ) : null}
                          {data.actions.allowToSign && showSignButtonByStatus ? (
                            <Button sx={styles.sendButton} variant="contained" color="primary" onClick={onSignHandler}>
                              {t('sign')}
                              <KeyIcon sx={styles.keyIcon} />
                            </Button>
                          ) : null}
                          {data.actions.allowToSendToSign && showSendToSignButtonByStatus ? (
                            <Button
                              sx={styles.sendButton}
                              variant="contained"
                              color="primary"
                              onClick={onSendToSignMessage}
                            >
                              {t('sendToSign')}
                              <KeyIcon sx={styles.keyIcon} />
                            </Button>
                          ) : null}
                          {data.actions.allowToSendToBank && showSendToBankButtonByStatus ? (
                            <Button variant="contained" color="primary" onClick={onSendToBankHandler}>
                              {t('send_to_bank')}
                              <BankIcon sx={styles.keyIcon} />
                            </Button>
                          ) : null}
                        </>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                {!isMobile && userProfile ? (
                  <Grid item xs={3}>
                    <Box pl={10}>
                      <Box>
                        <Typography variant="body1" sx={styles.title}>
                          {t('paymentInfo_currentStatus_title')}
                        </Typography>
                        <Box mt={2} mb={4}>
                          <Grid container alignItems="center">
                            {data.status === MessageStatus.Delivered ? (
                              <SuccessSmallIcon fontSize="small" />
                            ) : (
                              <WaitingSmallIcon fontSize="small" />
                            )}
                            <Box ml={2}>
                              <Typography variant="body2" component="span" sx={styles.value}>
                                {t(data.status)}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                      <Box sx={styles.hrInStatus} />
                      <Box mt={4} mb={4}>
                        <Typography variant="body1" sx={styles.title}>
                          {t('category_letter')}
                        </Typography>
                        <Typography variant="body2" component="span" sx={styles.value}>
                          {t(data.category.name)}
                        </Typography>
                      </Box>
                      {data.type === MessageType.Outgoing ? (
                        <>
                          <Box sx={styles.hrInStatus} />
                          <Box mt={4}>
                            <PeopleToSignMessageList
                              title={t('letterInfo_signPeopleList_title')}
                              signInfo={data.signInfo.personToSign}
                              userProfile={userProfile}
                            />
                          </Box>
                        </>
                      ) : null}
                      <Box mt={4}>
                        <MessageStatusHistory message={data} />
                      </Box>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            {showSignatureDialog ? (
              <SignMessages messages={[data]} onSuccessPopupCancel={onSignSuccessHandler} onClose={onSignModalClose} />
            ) : null}
            {showDeletDialog && data ? (
              <DeleteSingleMessagesDialog
                message={data}
                onCancelDelete={onCancelDelete}
                onDeleteSuccess={onDeleteSuccess}
              />
            ) : null}
          </PageContainer>
        </PageWrapper>
      ) : null}
    </>
  );
};

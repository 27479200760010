import React from 'react';

import { FormControlLabel, Grid, Typography, SxProps, Theme } from '@mui/material';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { MoveIcon } from '@app/common/icons';
import { Checkbox } from '@app/core/components/Checkbox';
import { styles as stylesLoanPref } from '@app/pages/loans/components/LoansTable/components/LoansTablePreference/LoansPreferenceItem/styles';

interface LoansPreferenceItemProps {
  name: string;
  enabled: boolean;
  edit: boolean;
  index: number;
  providedHandleProps?: DraggableProvidedDragHandleProps;
  styles?: SxProps<Theme>;
  onChangeEnabled(id: number, selected: boolean): void;
}

export const LoansPreferenceItem: React.FC<LoansPreferenceItemProps> = ({
  name,
  enabled,
  edit,
  index,
  providedHandleProps,
  styles,
  onChangeEnabled,
}) => {
  const onCheckHandler = (event: React.ChangeEvent<{}>, checked: boolean) => {
    onChangeEnabled(index, checked);
  };

  return (
    <Grid container sx={{ ...stylesLoanPref.root, ...styles }} justifyContent="space-between" alignItems="center">
      <FormControlLabel
        control={<Checkbox name={name} />}
        label={
          <Typography variant="caption" sx={stylesLoanPref.statementName}>
            {name}
          </Typography>
        }
        checked={enabled}
        disabled={!edit}
        onChange={onCheckHandler}
      />
      <span {...providedHandleProps}>
        <MoveIcon />
      </span>
    </Grid>
  );
};

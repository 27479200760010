import React from 'react';

import { ChipProps } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';

import { TagChip } from './TagChip';

export interface TagsProps<T> {
  chipProps: ChipProps;
  renderChipLabel(option: T): React.ReactNode;
}

export function Tags<T>({ chipProps, renderChipLabel }: TagsProps<T>) {
  return (value: T[], getTagProps: AutocompleteRenderGetTagProps): JSX.Element[] => {
    return [...value].map((val: T, index: number) => {
      return (
        <TagChip
          key={index.toString()}
          index={index}
          label={renderChipLabel(val)}
          getTagProps={getTagProps}
          chipProps={chipProps}
        />
      );
    });
  };
}

import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  ClickAwayListener,
  FormLabel,
  Grid,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DownChevronIcon, InfoIcon, KeyIcon } from '@app/common/icons';
import { AccountStatusEnum, GetAccountResponseDto, PaymentOrderErrorMessagesEnum } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { Switch } from '@app/core/components/Form/components/Switch';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { PassportTabs, PassportTabsEnum } from '@app/core/components/PassportTabs';
import { StatusText } from '@app/core/components/StatusText';
import { Tooltip } from '@app/core/components/Tooltip';
import { Severity } from '@app/core/types';
import { parseJSON } from '@app/core/utils';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';
import { Lei } from '@app/pages/payment/components/Lei';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import { FormFieldName, LocalPaymentEditFormProps } from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { PassportField } from '@app/pages/payment/components/PassportField';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { RecipientNameSelect } from '@app/pages/payment/components/RecipientNameSelect';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

export const DesktopLocalPaymentEditForm: React.FC<LocalPaymentEditFormProps> = ({
  defaultValues,
  defaultDocumentDate,
  maxDocumentDate,
  minDocumentDate,
  maxValuationDate,
  minValuationDate,
  senderAccountsData,
  passportActiveTab,
  vatEnabled,
  showBalance,
  countedBalance,
  recipientAccountDisabled,
  onSelectCounterpartyHandler,
  clearRecipientHandler,
  passportTabsChangeHandler,
  vatSwitchHandler,
  signPermissions,
  additionalInfo,
  onCancelClick,
  onSaveClick,
  onSaveAndSignClick,
  onSendToSign,
  documentType,
  createTemplateData,
  onSaveAndSignFromAnotherPerson,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { getValues, setValue, control, watch, setError, clearErrors } = useFormContext();
  const { t } = useTranslation();
  const [notEnoughBalance, setNotEnoughBalance] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openAnotherPerson, setOpenAnotherPerson] = useState(false);
  const documentNumberRef = useRef<HTMLElement>(null);
  const [senderAccountErrorSeverity, setSenderAccountErrorSeverity] = useState<Severity>();
  const [blockAction, setBlockAction] = useState<boolean>(false);

  const watchRecipientNonLeiFlag = useWatch({ control, name: FormFieldName.recipientNonLeiFlag });
  const watchNonResidentFlag = useWatch({ control, name: FormFieldName.nonResidentFlag });
  const watchRealSenderPresent = useWatch({ control, name: FormFieldName.realSenderPresent });
  const watchRealRecipientPresent = useWatch({ control, name: FormFieldName.realRecipientPresent });
  const watchRealSenderNonLeiFlag = useWatch({ control, name: FormFieldName.realSenderNonLeiFlag });
  const watchRealRecipientNonLeiFlag = useWatch({ control, name: FormFieldName.realRecipientNonLeiFlag });
  const watchRealRecipientNonResidentFlag = useWatch({ control, name: FormFieldName.realRecipientNonResidentFlag });
  const watchRealSenderNonResidentFlag = useWatch({ control, name: FormFieldName.realSenderNonResidentFlag });

  const recipientPassportClick = () => {
    setValue(FormFieldName.recipientNonLeiFlag, true);
  };

  const recipientLeiClick = () => {
    setValue(FormFieldName.recipientNonLeiFlag, false);
  };

  const watchAmount = watch(FormFieldName.amount);
  const watchAccount = watch(FormFieldName.senderAccount);
  useEffect(() => {
    if (watchAmount && watchAccount) {
      const selectedAccount: GetAccountResponseDto = parseJSON(watchAccount) as unknown as GetAccountResponseDto;
      const amount = parseFloat(watchAmount);
      setNotEnoughBalance(
        !selectedAccount.account.allowRedSaldo && amount > 0 && amount > Math.abs(selectedAccount.account.amount)
      );
    }
  }, [watchAmount, watchAccount]);

  useEffect(() => {
    if (watchAccount) {
      const selectedAccount: GetAccountResponseDto = parseJSON(watchAccount) as unknown as GetAccountResponseDto;
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Blocked
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningBlockedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Closed
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningClosedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (selectedAccount.account.status === AccountStatusEnum.Active) {
        clearErrors(FormFieldName.senderAccount);
        setBlockAction(false);
      }
    }
  }, [clearErrors, senderAccountsData, setError, t, watchAccount]);

  const handleToggle = () => {
    setOpenAnotherPerson(!openAnotherPerson);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenAnotherPerson(false);
  };

  const onSignFromAnotherPersonClickHandler = () => {
    setOpenAnotherPerson(false);
    onSaveAndSignFromAnotherPerson();
  };

  useEffect(() => {
    if (additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid sx={styles.tabBody}>
      <Grid container sx={styles.paymentNumber}>
        <Grid item xs={8} sx={styles.flexbox}>
          <Box ref={documentNumberRef}>
            <DocumentNumberField
              name={FormFieldName.paymentDocumentNumber}
              title={documentType ? t(documentType) : t('PAYMENT_ORDER_FLAG')}
              defaultValue={defaultValues.documentNumber || 'Auto123'}
              error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
              validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={styles.bookmarkBlock}>
          {permissions.uahTemplates.create ? <SavePaymentTemplate createTemplateData={createTemplateData} /> : null}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <CardContent>
            <Box mb={6}>
              <Grid container alignItems="flex-start">
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <DateSelectField
                      name={FormFieldName.documentDate}
                      selectedDate={
                        defaultValues.paymentDocumentDate ? new Date(defaultValues.paymentDocumentDate) : new Date()
                      }
                      datePickerProps={{
                        disabled: { after: maxDocumentDate, before: minDocumentDate },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <DateSelectField
                      name={FormFieldName.documentValuationDate}
                      datePickerProps={{
                        disabled: { after: maxValuationDate, before: minValuationDate },
                        toDate: maxValuationDate,
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Tooltip
                    color="primary"
                    title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                    arrow
                    placement="right"
                  >
                    <Box mt={9}>
                      <InfoIcon fontSize="small" sx={styles.info} />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
              <Box pt={2}>
                <AccountSelectField
                  name={FormFieldName.senderAccount}
                  options={senderAccountsData?.accounts}
                  placeholder={t('paymentCreationSelectAccountPlaceholder')}
                  errorSeverity={senderAccountErrorSeverity}
                  defaultAccount={senderAccountsData?.defaultAccount}
                />
              </Box>
            </Box>
            <Box sx={styles.hr} mb={5} />
            {appConfig.enableNextFeatures ? (
              <>
                <Box mb={6}>
                  <Box>
                    <CheckboxField
                      label={<Typography variant="caption">{t('addRealSender')}</Typography>}
                      defaultChecked={false}
                      name={FormFieldName.realSenderPresent}
                      sx={styles.checkboxField}
                    />
                  </Box>
                  {watchRealSenderPresent ? (
                    <Box mt={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormLabel component="legend">{t('realSenderName')}</FormLabel>
                          <Box pt={2} pr={8}>
                            <InputField
                              fullWidth
                              name={FormFieldName.realSenderName}
                              defaultValue={defaultValues.realSenderName}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          {!watchRealSenderNonLeiFlag ? (
                            <>
                              <Grid container item justifyContent="space-between" alignItems="center">
                                <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                                {appConfig.enableNextFeatures ? (
                                  <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                                    {t('paymentCreationLocalPayment_passport_select_title')}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Box pt={2}>
                                <Lei name={FormFieldName.realSenderTIN} defaultValue={defaultValues.realSenderTIN} />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Grid
                                container
                                item
                                justifyContent="space-between"
                                alignItems="center"
                                sx={styles.passport}
                              >
                                <PassportTabs
                                  onChange={passportTabsChangeHandler}
                                  defaultActiveTab={PassportTabsEnum.passport}
                                />
                                <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                                  {t('paymentCreationLocalPayment_lei_select_title')}
                                </Typography>
                              </Grid>
                              <Box pt={1}>
                                {passportActiveTab === PassportTabsEnum.passport ? (
                                  <PassportField name={FormFieldName.realSenderPassport} />
                                ) : null}
                                {passportActiveTab === PassportTabsEnum.idPassport ? (
                                  <FormatInputField
                                    type={FormatType.number}
                                    name={FormFieldName.realSenderPassport}
                                    placeholder="0000000000"
                                    maxLength={10}
                                  />
                                ) : null}
                              </Box>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box pt={4}>
                            <CheckboxField
                              label={<Typography variant="caption">{t('realSenderNonResidentLabel')}</Typography>}
                              defaultChecked={false}
                              name={FormFieldName.realSenderNonResidentFlag}
                              sx={styles.transactionPresentedLabel}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          {watchRealSenderNonResidentFlag ? (
                            <Box pt={5}>
                              <CountrySelect
                                name={FormFieldName.realSenderCountryCode}
                                defaultValue={defaultValues.realSenderCountryCode}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Box>
                <Box sx={styles.hr} mb={5} />
              </>
            ) : null}
            <Box mb={6}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientName')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <RecipientNameSelect
                      name={FormFieldName.recipientName}
                      defaultValue={defaultValues.recipientAccountName}
                      onSelectCounterparty={onSelectCounterpartyHandler}
                      onClear={clearRecipientHandler}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  {!watchRecipientNonLeiFlag ? (
                    <>
                      <Grid container item justifyContent="space-between" alignItems="center">
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                        {appConfig.enableNextFeatures ? (
                          <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                            {t('paymentCreationLocalPayment_passport_select_title')}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Box pt={2}>
                        <Lei name={FormFieldName.recipientLei} defaultValue={defaultValues.recipientLei} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Grid container item justifyContent="space-between" alignItems="center" sx={styles.passport}>
                        <PassportTabs
                          onChange={passportTabsChangeHandler}
                          defaultActiveTab={PassportTabsEnum.passport}
                        />
                        <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                          {t('paymentCreationLocalPayment_lei_select_title')}
                        </Typography>
                      </Grid>
                      <Box pt={1}>
                        {passportActiveTab === PassportTabsEnum.passport ? (
                          <PassportField name={FormFieldName.recipientPassport} />
                        ) : null}
                        {passportActiveTab === PassportTabsEnum.idPassport ? (
                          <FormatInputField
                            type={FormatType.number}
                            name={FormFieldName.idPassport}
                            placeholder="0000000000"
                            maxLength={10}
                          />
                        ) : null}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mb={6}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientAccount')}</FormLabel>
                  <Box pt={2} pr={8} mb={3}>
                    <RecipientAccountSelect
                      name={FormFieldName.recipientAccount}
                      defaultValue={defaultValues.recipientAccountNumber}
                      disabled={recipientAccountDisabled}
                    />
                  </Box>
                  <Box pt={watchNonResidentFlag ? 5 : 2}>
                    <CheckboxField
                      label={
                        <Typography variant="caption">
                          {t('paymentCreationLocalPayment_transactionPresentedLabel')}
                        </Typography>
                      }
                      defaultChecked={false}
                      name={FormFieldName.nonResidentFlag}
                      sx={styles.transactionPresentedLabel}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_bankName')}</FormLabel>
                  <Box pt={2}>
                    <BankName
                      name={FormFieldName._bankName}
                      iban={getValues(FormFieldName.recipientAccount)}
                      defaultValue={defaultValues.recipientBankName}
                    />
                  </Box>
                  <Box pt={5}>
                    {watchNonResidentFlag ? (
                      <CountrySelect name={FormFieldName.countryCode} defaultValue={defaultValues.countryCode} />
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {appConfig.enableNextFeatures ? (
              <>
                <Box sx={styles.hr} mb={5} />
                <Box mb={6}>
                  <Box>
                    <CheckboxField
                      label={<Typography variant="caption">{t('addRealRecipient')}</Typography>}
                      defaultChecked={false}
                      name={FormFieldName.realRecipientPresent}
                      sx={styles.checkboxField}
                    />
                  </Box>
                  {watchRealRecipientPresent ? (
                    <Box mt={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormLabel component="legend">{t('realRecipientName')}</FormLabel>
                          <Box pt={1} pr={8}>
                            <InputField
                              fullWidth
                              name={FormFieldName.realRecipientName}
                              defaultValue={defaultValues.realRecipientName}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          {!watchRealRecipientNonLeiFlag ? (
                            <>
                              <Grid container item justifyContent="space-between" alignItems="center">
                                <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                                {appConfig.enableNextFeatures ? (
                                  <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                                    {t('paymentCreationLocalPayment_passport_select_title')}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Box pt={1}>
                                <Lei
                                  name={FormFieldName.realRecipientTIN}
                                  defaultValue={defaultValues.realRecipientTIN}
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Grid
                                container
                                item
                                justifyContent="space-between"
                                alignItems="center"
                                sx={styles.passport}
                              >
                                <PassportTabs
                                  onChange={passportTabsChangeHandler}
                                  defaultActiveTab={PassportTabsEnum.passport}
                                />
                                <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                                  {t('paymentCreationLocalPayment_lei_select_title')}
                                </Typography>
                              </Grid>
                              <Box pt={1}>
                                {passportActiveTab === PassportTabsEnum.passport ? (
                                  <PassportField name={FormFieldName.recipientPassport} />
                                ) : null}
                                {passportActiveTab === PassportTabsEnum.idPassport ? (
                                  <FormatInputField
                                    type={FormatType.number}
                                    name={FormFieldName.idPassport}
                                    placeholder="0000000000"
                                    maxLength={10}
                                  />
                                ) : null}
                              </Box>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box pt={4}>
                            <CheckboxField
                              label={
                                <Typography variant="caption">
                                  {t('paymentCreationLocalPayment_transactionPresentedLabel')}
                                </Typography>
                              }
                              defaultChecked={false}
                              name={FormFieldName.realRecipientNonResidentFlag}
                              sx={styles.transactionPresentedLabel}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          {watchRealRecipientNonResidentFlag ? (
                            <Box pt={5}>
                              <CountrySelect
                                name={FormFieldName.realRecipientCountryCode}
                                defaultValue={defaultValues.realRecipientCountryCode}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Box>
              </>
            ) : null}
            <Box mb={6}>
              <Grid container>
                <Grid item xs={4}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                  <Box sx={styles.flexbox}>
                    <Box pt={2}>
                      <FormatInputField
                        defaultValue={defaultValues.amount ? `${defaultValues.amount}` : ''}
                        type={FormatType.amount}
                        name={FormFieldName.amount}
                        placeholder={'0.00'}
                      />
                      {notEnoughBalance ? (
                        <StatusText severity="error" absolute>
                          {t('notEnoughBalance')}
                        </StatusText>
                      ) : null}
                    </Box>
                    <Box pl={3} pt={5}>
                      <Tooltip color="primary" title={t('tooltip_bank-tariff')} arrow placement="top">
                        <div>
                          <InfoIcon fontSize="small" sx={styles.info} />
                        </div>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid sx={styles.switch} item xs={4}>
                  <Typography variant="body2" sx={{ ...(vatEnabled && styles.vatTextDisabled) }}>
                    {t('paymentCreationLocalPayment_withoutVat')}
                  </Typography>
                  <Box pl={1}>
                    <Switch checked={vatEnabled} onChange={vatSwitchHandler} />
                  </Box>
                  <Typography variant="body2" sx={{ ...(!vatEnabled && styles.vatTextDisabled) }}>
                    {t('paymentCreationLocalPayment_withVat')}
                  </Typography>
                  {vatEnabled ? (
                    <>
                      <InputField defaultValue={20} sx={styles.vat} name={FormFieldName.vat} />
                      <Box pl={1}>%</Box>
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <Box pt={3} sx={styles.balance}>
                    <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                    <Typography sx={styles.balanceText}>{showBalance ? `${countedBalance} UAH` : ''}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
              <Box pt={2}>
                <TextAreaField
                  defaultValue={defaultValues.paymentReference || ''}
                  name={FormFieldName.purpose}
                  textFieldProps={{
                    multiline: true,
                    fullWidth: true,
                    rows: 2,
                  }}
                  maxLength={420}
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={styles.cardAction}>
            <Button color="primary" onClick={onCancelClick}>
              {t('paymentCreationFormControl_cancel')}
            </Button>
            <Box sx={styles.cardAction}>
              {permissions.uahOperations.create || permissions.uahOperations.edit ? (
                <Button
                  sx={styles.submitBtn}
                  variant="outlined"
                  color="primary"
                  disabled={blockAction}
                  onClick={onSaveClick}
                >
                  {t('paymentCreationFormControl_save')}
                </Button>
              ) : null}
              {signPermissions.allowToSendToSign ? (
                <>
                  {permissions.sign.paymentOrder.signFromAnotherPerson && appConfig.enableNextFeatures ? (
                    <>
                      <ButtonGroup variant="contained" ref={anchorRef}>
                        <Button
                          sx={styles.signButton}
                          variant="contained"
                          color="primary"
                          onClick={onSendToSign}
                          disabled={notEnoughBalance || blockAction}
                        >
                          {t('paymentCreationFormControl_sendToSign')}
                        </Button>
                        <Button size="small" onClick={handleToggle}>
                          <DownChevronIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        sx={{
                          zIndex: 1,
                        }}
                        open={openAnotherPerson}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                      >
                        {({ TransitionProps }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: 'right top',
                            }}
                          >
                            <Paper sx={{ marginBottom: '8px' }}>
                              <ClickAwayListener onClickAway={handleClose}>
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={onSignFromAnotherPersonClickHandler}
                                  disabled={notEnoughBalance || blockAction}
                                >
                                  {t('signedByPerson')}
                                  <KeyIcon sx={styles.keyIcon} />
                                </Button>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : (
                    <Button
                      sx={styles.signButton}
                      variant="contained"
                      color="primary"
                      onClick={onSendToSign}
                      disabled={notEnoughBalance || blockAction}
                    >
                      {t('paymentCreationFormControl_sendToSign')}
                    </Button>
                  )}
                </>
              ) : null}
              {signPermissions.allowToSign ? (
                <Button
                  sx={styles.signButton}
                  variant="contained"
                  color="primary"
                  onClick={onSaveAndSignClick}
                  disabled={notEnoughBalance || blockAction}
                >
                  {t('paymentCreationFormControl_send')}
                  <KeyIcon sx={styles.keyIcon} />
                </Button>
              ) : null}
            </Box>
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

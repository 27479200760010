import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, dictionariesApiClient, GetCountryCodesDto, GetCountryCodesResponseDto } from '@app/core/api';

const getCountryCodesQueryFn = async (filter: GetCountryCodesDto): Promise<GetCountryCodesResponseDto> => {
  const response = await dictionariesApiClient.dictionariesControllerGetCountryCodes({ ...filter });

  return response.data;
};

export const useGetCountryCodes = (): UseMutationResult<GetCountryCodesResponseDto, ApiError, GetCountryCodesDto> => {
  return useMutation<GetCountryCodesResponseDto, ApiError, GetCountryCodesDto>(getCountryCodesQueryFn);
};

import React, { useEffect, useRef } from 'react';

import { Box, Button, CardActions, CardContent, FormLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '@app/common/icons';
import { PaymentOrderErrorMessagesEnum } from '@app/core/api';
import {
  AccountSelectField,
  DateSelectField,
  DocumentNumberField,
  FormatInputField,
  FormatType,
  TextAreaField,
  Tooltip,
} from '@app/core/components';
import { styles } from '@app/pages/payment/components/BetweenMyAccountEditForm/styles';
import {
  BetweenMyAccountFormFieldName,
  BetweenMyAccountFormProps,
} from '@app/pages/payment/components/BetweenMyAccountEditForm/types';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const DesktopBetweenMyAccountPaymentEditForm: React.FC<BetweenMyAccountFormProps> = ({
  defaultValues,
  signPermissions,
  senderAccountsData,
  recipientAccountsData,
  showBalance,
  countedBalance,
  createTemplateData,
  additionalInfo,
  onCancelHandler,
  onSaveClickHandler,
  onSaveAndSignClickHandler,
  onSendToSign,
  maxValuationDate,
  minValuationDate,
  errorSeverity,
  blockAction,
}) => {
  const { t } = useTranslation();
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const nowDate = new Date();
  const documentNumberRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid sx={styles.tabBody}>
      <Grid container sx={{ height: '50px' }}>
        <Grid item xs={8} sx={styles.flexbox}>
          <Box ref={documentNumberRef}>
            <DocumentNumberField
              name={BetweenMyAccountFormFieldName.paymentDocumentNumber}
              title={t('paymentCreationLocalPayment_paymentOrder')}
              defaultValue={defaultValues.documentNumber || 'Auto123'}
              error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
              validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={styles.bookmarkBlock}>
          <SavePaymentTemplate createTemplateData={createTemplateData} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <CardContent>
            <Box mb={6}>
              <Grid container alignItems="flex-start">
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <DateSelectField
                      name={BetweenMyAccountFormFieldName.paymentDocumentDate}
                      selectedDate={defaultValues.documentDate ? new Date(defaultValues.documentDate) : nowDate}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
                  <Box pt={2} pl={1} pr={8}>
                    <DateSelectField
                      name={BetweenMyAccountFormFieldName.paymentDocumentValueDate}
                      datePickerProps={{
                        disabled: { after: maxValuationDate, before: minValuationDate },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Tooltip
                    color="primary"
                    title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                    arrow
                    placement="right"
                  >
                    <Box mt={9}>
                      <InfoIcon fontSize="small" sx={styles.info} />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_write_off_account')}</FormLabel>
              <Box pt={2}>
                <AccountSelectField
                  name={BetweenMyAccountFormFieldName.senderAccount}
                  options={senderAccountsData?.accounts}
                  placeholder={t('paymentCreationSelectAccountPlaceholder')}
                  errorSeverity={errorSeverity}
                  defaultAccount={senderAccountsData?.defaultAccount}
                />
              </Box>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
              <Box pt={2}>
                <AccountSelectField
                  name={BetweenMyAccountFormFieldName.recipientAccount}
                  options={recipientAccountsData}
                  defaultSelectFirst
                  placeholder={t('paymentCreationSelectAccountPlaceholder')}
                />
              </Box>
            </Box>
            <Box mb={6}>
              <Grid container>
                <Grid item xs={4}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                  <Box sx={styles.flexbox}>
                    <Box pt={2}>
                      <FormatInputField
                        type={FormatType.amount}
                        name={BetweenMyAccountFormFieldName.amount}
                        placeholder={'0.00'}
                      />
                    </Box>
                    <Box pl={3} pt={5}>
                      <Tooltip color="primary" title={t('tooltip_bank-tariff')} arrow placement="top">
                        <div>
                          <InfoIcon fontSize="small" sx={styles.info} />
                        </div>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box pt={3} sx={styles.balance}>
                    <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                    <Typography sx={styles.balanceText}>{showBalance ? `${countedBalance} UAH` : ''}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
              <Box pt={2}>
                <TextAreaField
                  defaultValue={defaultValues.paymentReference || ''}
                  name={BetweenMyAccountFormFieldName.paymentReference}
                  maxLength={420}
                  textFieldProps={{
                    multiline: true,
                    fullWidth: true,
                    rows: 2,
                  }}
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={styles.cardAction}>
            <Button color="primary" onClick={onCancelHandler} disabled={blockAction}>
              {t('paymentCreationFormControl_cancel')}
            </Button>
            <Box sx={styles.cardAction}>
              <Button
                sx={styles.submitBtn}
                variant="outlined"
                color="primary"
                disabled={blockAction}
                onClick={onSaveClickHandler}
              >
                {t('paymentCreationFormControl_save')}
              </Button>
              {signPermissions.allowToSendToSign ? (
                <Button
                  sx={styles.signButton}
                  variant="contained"
                  color="primary"
                  disabled={blockAction}
                  onClick={onSendToSign}
                >
                  {t('paymentCreationFormControl_sendToSign')}
                </Button>
              ) : null}
              {permissions.sign.paymentOrder.allowToSign ? (
                <Button variant="contained" color="primary" disabled={blockAction} onClick={onSaveAndSignClickHandler}>
                  {t('paymentCreationFormControl_send')}
                </Button>
              ) : null}
            </Box>
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

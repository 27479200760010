import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  getClientId,
  ApiError,
  messagesApiClient,
  GetMessagesListRequestDto,
  GetMessagesListResponseDto,
} from '@app/core/api';

const getMessagesListFn = async (
  getMessagesListRequestDto: GetMessagesListRequestDto
): Promise<GetMessagesListResponseDto> => {
  const result = await messagesApiClient.messagesControllerGetList(getClientId(), getMessagesListRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get messages list!");
  }

  return result.data;
};

export const useGetMessagesList = (): UseMutationResult<
  GetMessagesListResponseDto,
  ApiError,
  GetMessagesListRequestDto
> => {
  return useMutation<GetMessagesListResponseDto, ApiError, GetMessagesListRequestDto>(getMessagesListFn);
};

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  FileLinkAdditionalInfoDtoCodeEnum,
  FileLinkResponseDto,
  StatementTypeEnum,
  OutputFormatEnum,
  GetOperationsFileDto,
} from '@app/core/api';
import { useGetStatementsFile } from '@app/core/hooks/useGetStatementsFile';
import { useGetStatementsMultipleFiles } from '@app/core/hooks/useGetStatementsMultipleFiles';

export const useGetOperationsFile = () => {
  const { notify } = useNotify();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { mutateAsync: mutateAsyncGetStatementsFile, isLoading: isLoading1 } = useGetStatementsFile();
  const { mutateAsync: mutateAsyncGetStatementsMultipleFiles, isLoading: isLoading2 } = useGetStatementsMultipleFiles();

  useEffect(() => {
    showLoader(isLoading1 || isLoading2);
  }, [isLoading1, isLoading2, showLoader]);

  const getOperationsFile = ({
    operationsUuids,
    outputType,
    format,
    getOperationsFileValues,
  }: {
    getOperationsFileValues: GetOperationsFileDto;
    operationsUuids: string[];
    outputType: string;
    format?: string;
  }) => {
    const navigateToFile = (responseData: FileLinkResponseDto) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (format) {
        window.open(`${responseData.link}?format=${format}&type=payment-order`);
      } else {
        window.open(`${responseData.link}?format=download&type=payment-order`);
      }
    };

    if (operationsUuids.length) {
      mutateAsyncGetStatementsMultipleFiles({
        outputType: outputType as unknown as OutputFormatEnum,
        ordersUids: operationsUuids,
        operationsType: StatementTypeEnum.Statement,
      }).then((responseData) => {
        navigateToFile(responseData);
      });
    } else {
      mutateAsyncGetStatementsFile(getOperationsFileValues).then((responseData) => {
        navigateToFile(responseData);
      });
    }
  };
  return { getOperationsFile };
};

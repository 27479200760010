import { isSameYear, setYear, setMonth, startOfMonth, startOfYear, format, Locale } from 'date-fns';

type GetDropdownOptionsType = {
  fromDate: Date;
  toDate: Date;
};

export const getDropdownMonths = ({ fromDate, toDate }: GetDropdownOptionsType): Date[] => {
  const dropdownMonths: Date[] = [];
  if (isSameYear(fromDate, toDate)) {
    const date = startOfMonth(fromDate);
    for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  } else {
    const date = startOfMonth(new Date());
    for (let month = 0; month <= 11; month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  }
  return dropdownMonths;
};

export const getDropdownYears = ({ fromDate, toDate }: GetDropdownOptionsType): Date[] => {
  const years: Date[] = [];
  const fromYear = fromDate.getFullYear();
  const toYear = toDate.getFullYear();
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year));
  }
  return years;
};

export const formatMonthCaption = (date: Date, locale: Locale) => {
  const month = locale.localize?.month(date.getMonth());
  return month.charAt(0).toUpperCase() + month.slice(1);
};

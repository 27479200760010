import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  CreatePaymentOrderRequestDto,
  CreatePaymentOrderResponseDto,
  getClientId,
  paymentOrdersApiClient,
} from '@app/core/api';

const createPaymentOrderFn = async (
  createPaymentOrderRequestDto: CreatePaymentOrderRequestDto
): Promise<CreatePaymentOrderResponseDto> => {
  const result = await paymentOrdersApiClient.paymentOrderControllerCreate(getClientId(), createPaymentOrderRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error create payment order. paymentOrderControllerCreate error');
  }

  return result.data;
};

export const useCreatePaymentOrder = (): UseMutationResult<
  CreatePaymentOrderResponseDto,
  ApiError,
  CreatePaymentOrderRequestDto
> => {
  return useMutation<CreatePaymentOrderResponseDto, ApiError, CreatePaymentOrderRequestDto>(createPaymentOrderFn);
};

import { GetOverdraftsListRequestDto } from '@app/core/api';
import { OverdraftsFilterFormData } from '@app/pages/overdrafts/types';

export const makeDataFromSubmit = (
  formData: OverdraftsFilterFormData
): Omit<GetOverdraftsListRequestDto, 'sort' | 'pagination'> => {
  const {
    agreementStartDateFrom,
    agreementStartDateTo,
    agreementEndDateFrom,
    agreementEndDateTo,
    accountOverdraft,
    amountFrom,
    amountTo,
    currency,
    interestRateFrom,
    interestRateTo,
  } = formData;

  const agreementStartDate =
    agreementStartDateFrom || agreementStartDateTo
      ? { startDate: agreementStartDateFrom?.toISOString(), endDate: agreementStartDateTo?.toISOString() }
      : undefined;

  const agreementEndDate =
    agreementEndDateFrom || agreementEndDateTo
      ? { startDate: agreementEndDateFrom?.toISOString(), endDate: agreementEndDateTo?.toISOString() }
      : undefined;

  const interestRate =
    interestRateFrom?.length || interestRateTo?.length
      ? {
          from: interestRateFrom ? parseFloat(interestRateFrom) : undefined,
          to: interestRateTo ? parseFloat(interestRateTo) : undefined,
        }
      : undefined;
  //TODO
  const amount =
    amountFrom?.length && amountTo?.length ? { from: parseFloat(amountFrom), to: parseFloat(amountTo) } : undefined;
  return {
    startDate: agreementStartDate,
    endDate: agreementEndDate,
    interestRate,
    currency: currency?.length ? currency : undefined,
    agreementNumber: accountOverdraft?.length ? accountOverdraft : undefined,
  };
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ErrorSmallIcon, InfoSmallIcon, SuccessSmallSvgIcon, WarningSmallIcon } from '@app/common/icons';
import { FrontLanguageEnum } from '@app/core/api';
import { Tooltip } from '@app/core/components';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGetUserData } from '@app/core/hooks';
import { useVerifyUserEmail } from '@app/core/hooks/useVerifyUserEmail';
import { styles } from '@app/pages/profile/desktop/components/UserData/styles';

export const MobileUserData: React.FC = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const { data, mutate: mutateUserData } = useGetUserData();

  useEffect(() => {
    mutateUserData({});
  }, [mutateUserData]);

  const birthDate = data?.birthDate ? format(new Date(data?.birthDate), ddMMYYYYFormat.format) : '';

  const { t, i18n } = useTranslation();

  const { mutate: mutateSendVerifyEmail } = useVerifyUserEmail();

  const sendVerifyEmail = () => {
    mutateSendVerifyEmail(
      { language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          setShowSuccessAlert(true);
          setTimeout(() => setShowSuccessAlert(false), 2500);
        },
        onError: () => {
          setShowErrorAlert(true);
          setTimeout(() => setShowErrorAlert(false), 2500);
        },
      }
    );
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{
        backgroundColor: '#fff',
        position: 'absolute',
        width: '100%',
        top: '15%',
        left: 0,
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        height: '-webkit-fill-available',
      }}
    >
      <Box ml={4} mr={4} mt={5}>
        {showSuccessAlert ? (
          <Grid container alignItems="center" sx={styles.successAlert}>
            <SuccessSmallSvgIcon fontSize="small" />
            <Typography variant="caption" sx={styles.successAlertText}>
              {t('sendVerificationSuccess')}
            </Typography>
          </Grid>
        ) : null}
        {showErrorAlert ? (
          <Grid container alignItems="center" sx={styles.errorAlert}>
            <ErrorSmallIcon fontSize="small" />
            <Typography variant="caption" sx={styles.errorAlertText}>
              {t('SendVerificationError')}
            </Typography>
          </Grid>
        ) : null}
        {!data?.emailVerified ? (
          <Grid container alignItems="center" sx={styles.notVerifyWarning}>
            <Typography variant="caption" sx={styles.notVerifyWarningText}>
              {t('notVerifyWarning')}
            </Typography>
          </Grid>
        ) : null}
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('lastName')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.lastName}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('firstName')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.firstName}</Typography>
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('userMiddleName')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.patronymic}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('birthDate')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{birthDate}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('phoneNumber')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.phoneNumber}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('userTIN')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.inn}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('email')}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" alignItems="center">
            <Typography variant="body2">{data?.email}</Typography>
            {data?.emailVerified ? (
              <>
                <Tooltip arrow placement="top" color="primary" withBorder title={t('verifyInfoTooltip')}>
                  <Box ml={2} pt={2}>
                    <InfoSmallIcon fontSize="small" />
                  </Box>
                </Tooltip>
                <Tooltip arrow placement="top" color="primary" withBorder title={t('verifySuccesTooltip')}>
                  <Box ml={2} pt={2}>
                    <SuccessSmallSvgIcon color="success" fontSize="small" />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <Tooltip arrow placement="top" color="primary" withBorder title={t('verifyWarningTooltip')}>
                <Box ml={2} pt={2}>
                  <WarningSmallIcon fontSize="small" />
                </Box>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={{ borderBottom: '1px solid', borderColor: 'colors.gray100' }} />
        {!data?.emailVerified ? (
          <Grid container justifyContent="flex-end">
            <Box mt={5}>
              <Button variant="outlined" size="medium" color="primary" onClick={sendVerifyEmail}>
                {t('sendRequest')}
              </Button>
            </Box>
          </Grid>
        ) : null}
      </Box>
    </Grid>
  );
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DeleteIcon, EditIcon } from '@app/common/icons';
import {
  DocumentStatusEnum,
  GetDocumentWithAvailableActionsDto,
  RelateCurrencyOperationDto,
  UserResourceDto,
} from '@app/core/api';
import { Checkbox } from '@app/core/components/Checkbox';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { styles } from '@app/pages/documents/components/DocumentsTable/styles';
import { DocumentsColumnsEnum } from '@app/pages/documents/DocumentsList/types';

interface MakeTableColumnsProps {
  t(text: string): string;
  permissions: UserResourceDto;
  onDeleteDocument(document: GetDocumentWithAvailableActionsDto): void;
  onEditDocument(document: GetDocumentWithAvailableActionsDto): void;
}

type StatusFieldProps = {
  status: DocumentStatusEnum;
};

const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.status,
          ...(status === DocumentStatusEnum.Rejected && styles.statusRejected),
          ...(status === DocumentStatusEnum.Accepted && styles.statusAccepted),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(status)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  t,
  permissions,
  onDeleteDocument,
  onEditDocument,
}: MakeTableColumnsProps): ColumnDef<GetDocumentWithAvailableActionsDto, any>[] => {
  return [
    {
      id: 'selection',
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }: CellContext<GetDocumentWithAvailableActionsDto, any>) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: DocumentsColumnsEnum.documentNumber,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t('currencyDocuments_documentNumber')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, number>) => {
        return (
          <Typography variant="body2" component="div">
            {getValue()}
          </Typography>
        );
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.data,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('currencyDocuments_documentData')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.link,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('currencyDocuments_documentType')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, string>) => {
        return <Ellipsis text={t('currencyTransactions')} />;
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.name,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('currencyDocuments_documentName')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.comment,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('currencyDocuments_documentComment')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.relateOperations,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('currencyDocuments_documentConnections')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, Array<RelateCurrencyOperationDto>>) => {
        return getValue().length ? t('currencyDocument_connected') : t('currencyDocument_notConnected');
      },
    },
    {
      accessorKey: DocumentsColumnsEnum.status,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('currencyDocuments_documentStatus')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetDocumentWithAvailableActionsDto, DocumentStatusEnum>) => {
        return <StatusField status={getValue()} />;
      },
    },
    {
      id: 'edit_action',
      cell: ({ row }: CellContext<GetDocumentWithAvailableActionsDto, any>) => {
        const status = row.original.status;
        const disabled =
          status !== DocumentStatusEnum.Rejected &&
          status !== DocumentStatusEnum.ToSign &&
          status !== DocumentStatusEnum.OnSigning &&
          status !== DocumentStatusEnum.Saved;
        return (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {permissions.documents.edit ? (
              <IconButton size="small" onClick={() => onEditDocument(row.original)} disabled={disabled}>
                <EditIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Grid>
        );
      },
    },
    {
      id: 'delete_action',
      cell: ({ row }: CellContext<GetDocumentWithAvailableActionsDto, any>) => {
        const status = row.original.status;
        const disabled =
          status !== DocumentStatusEnum.Saved &&
          status !== DocumentStatusEnum.ToSign &&
          status !== DocumentStatusEnum.OnSigning;
        return (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {permissions.documents.delete ? (
              <IconButton size="small" onClick={() => onDeleteDocument(row.original)} disabled={disabled}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Grid>
        );
      },
    },
  ];
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon, HideIcon, LoaderIcon, ShowIcon, SignatureIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { ApiError, SignBatchResponseDto } from '@app/core/api';
import { ProfileState } from '@app/slices/profileSlice';
import { CertificateType } from '@app/src/crypto/types';
import { useAppSelector } from '@app/src/store';

import { getCertificatesQueryFn, getCurrencyDocumentFileFn, signCurrencyDocumentFilesFn, signDataFn } from './query';
import { styles } from './style';
import { TINCode } from '../../constants';
import { isMobile } from '../../utils';
import { SelectField } from '../Form';
import { ModalPopupCommon } from '../Modal';
import { SelectOptionType } from '../Select';
import { DocumentToSign } from '../SignatureModal';

interface SignatureCurrencyDocumentModalProps {
  documentsToSign: DocumentToSign[];
  onHandleClose(): void;
  onSuccess(): void;
}

export const SignatureCurrencyDocumentModal: React.FC<SignatureCurrencyDocumentModalProps> = ({
  documentsToSign,
  onHandleClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();

  const [showPass, setShowPass] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm({ mode: 'onSubmit' });

  const [certificatesMap, setCertificatesMap] = useState<Map<string, { certs: CertificateType[] }>>(new Map());
  const [keysArray, setKeysArray] = useState<SelectOptionType[]>([]);
  const [certificate, setCertificate] = useState<CertificateType>();
  const [certificateVerifiedError, setCertificateVerifiedError] = useState<string | null>();

  const { userProfile, clientProfile } = useAppSelector((state): ProfileState => state.profile);

  const { mutateAsync: mutateAsyncUmcaCertificates } = useMutation(getCertificatesQueryFn);
  const { mutateAsync: mutateAsyncGetDocument } = useMutation<any, ApiError, string>(getCurrencyDocumentFileFn);
  const { mutateAsync: mutateAsyncSignData } = useMutation(signDataFn);
  const { mutateAsync: mutateAsyncSignFiles } = useMutation<
    SignBatchResponseDto,
    ApiError,
    {
      file: Array<any>;
      fileIds: Array<string>;
    }
  >(signCurrencyDocumentFilesFn);

  useEffect(() => {
    new Promise(() => {
      mutateAsyncUmcaCertificates().then((res) => {
        if (res !== null) {
          setCertificatesMap(res);

          const preparedKeys: SelectOptionType[] = [];

          res.forEach((cert) => {
            preparedKeys.push({
              id: nanoid(),
              name: cert.key,
              value: cert.key,
            });
          });

          setKeysArray(preparedKeys);
        }
      });
    });
  }, [mutateAsyncUmcaCertificates]);

  const { control } = methods;

  const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const key = useWatch({ control, name: 'key', defaultValue: undefined });

  useEffect(() => {
    if (certificatesMap.has(key)) {
      const cert = certificatesMap.get(key)?.certs[0] as unknown as CertificateType;

      const drfo = cert.DirectoryAttributes.drfo || cert['1.2.804.2.1.1.1.11.1.4.2.1'];
      const edrpou = cert.DirectoryAttributes.edrpou || cert['1.2.804.2.1.1.1.11.1.4.1.1'];
      const expired = new Date(cert.NotAfter) < new Date() && new Date() > new Date(cert.NotBefore);

      setCertificate(cert);

      if (clientProfile && userProfile && cert) {
        if (clientProfile.privateEnterpreuner === TINCode) {
          if (clientProfile.lei !== drfo) {
            setCertificateVerifiedError(t('sign_key_verification_error'));
          } else if (expired) {
            setCertificateVerifiedError(t('sign_key_verification_expire_error'));
          } else {
            setCertificateVerifiedError(null);
          }
        } else {
          const validDdrpou = clientProfile.lei === edrpou;
          const validInn = userProfile.inn === drfo;
          const valid = validDdrpou && validInn && !expired;

          let errorText = null;

          if (!valid) {
            if (!validDdrpou) {
              errorText = t('sign_key_verification_error');
            } else if (!validInn) {
              errorText = t('sign_key_verification_user_error');
            } else if (expired) {
              setCertificateVerifiedError(t('sign_key_verification_expire_error'));
            }

            setCertificateVerifiedError(errorText);
          }
        }
      }
    }
  }, [certificatesMap, clientProfile, key, t, userProfile]);

  const handleSignClickBtn = () => {
    setLoading(true);
    const fileIds: string[] = documentsToSign.map((doc) => doc.uuid);

    const getAllFiles = (uuids: string[]): Promise<Blob[]> =>
      new Promise(async (resolve, reject) => {
        try {
          const response = [];
          for await (const uuid of uuids) {
            const rawFile = await mutateAsyncGetDocument(uuid);
            response.push(rawFile);
          }
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });

    getAllFiles(fileIds)
      .then((array) => {
        if (certificate?.Base64) {
          mutateAsyncSignData({
            certificate: certificate.Base64,
            data: array,
            password,
          }).then((response) => {
            if (response.type === 'error' && response.data.CryptoLibError) {
              const errorConstant = response.data.Message.replace('KeyStoreException: ', '');
              notify({
                notifyProps: {
                  title: t('payment_sign_error_message'),
                  message: t(errorConstant),
                  severity: 'error',
                },
              });
            } else if (response.type === 'success') {
              const filesBinaryArray: any[] = response.data.map((encodedItem: string) => {
                const decodedData = atob(encodedItem);

                const byteArray = new Uint8Array(decodedData.length);
                byteArray.map((_, i) => (byteArray[i] = decodedData.charCodeAt(i)));

                return new Blob([byteArray], { type: 'application/pdf' });
              });

              mutateAsyncSignFiles({
                file: filesBinaryArray,
                fileIds,
              })
                .then((result) => {
                  setLoading(false);
                  if (filesBinaryArray.length === 1 && result.unsigned?.length === 1) {
                    notify({
                      notifyProps: {
                        title: t('payment_sign_error'),
                        severity: 'error',
                      },
                    });
                  } else {
                    notify({
                      notifyProps: {
                        title: t('success'),
                        message: t('success'),
                        severity: 'success',
                      },
                    });
                    onSuccess();
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  notify({
                    notifyProps: {
                      title: t('payment_sign_error'),
                      message: t(error),
                      severity: 'error',
                    },
                  });
                });
            }
          });
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('attachFile_ErrorTitle'),
            message: t(error),
            severity: 'error',
          },
        });
      });
  };

  const signButtonDisabled = !key || Boolean(certificateVerifiedError) || password.length === 0;

  return (
    <ModalPopupCommon onClose={onHandleClose} open questionPaper={true}>
      <FormProvider {...methods}>
        <form>
          <Grid sx={styles.paper}>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                <b>{t('signature')}</b>
              </Typography>
              <IconButton size="small" onClick={onHandleClose}>
                <CrossIcon />
              </IconButton>
            </Grid>
            {certificateVerifiedError ? (
              <Box mt={5} sx={styles.errorBlock}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ ...(certificateVerifiedError && styles.errorColorText) }}
                >
                  {certificateVerifiedError}
                  <div>{key}</div>
                </Typography>
              </Box>
            ) : null}
            <Box mt={5}>
              <Typography variant="body2">
                <b>{t('myProfile_keys')}</b>
              </Typography>
              <Box pt={2}>
                <SelectField name="key" options={keysArray} defaultValue="" fullWidth />
              </Box>
            </Box>
            <Box mt={4}>
              <Typography variant="body2">
                <b>{t('password')}</b>
              </Typography>
              <Box pt={2}>
                <OutlinedInput
                  fullWidth
                  placeholder={t('enter_password')}
                  type={showPass ? 'text' : 'password'}
                  sx={styles.passwordInput}
                  onChange={handleChangePass}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPass(!showPass)} size="large">
                        {showPass ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  autoComplete="new-password"
                />
              </Box>
            </Box>
            {certificate ? (
              <Box mt={4}>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_FSP')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoOwnerName}>
                      {certificate.Subject.CN}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_ITN')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.DirectoryAttributes.drfo}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_Certificate_issued_by')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.Issuer.CN}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_Serial_number')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.CertificateSerialNumber}
                    </Typography>
                  </Typography>
                </Box>
                {certificate?.DirectoryAttributes?.edrpou ? (
                  <Box mb={2}>
                    <Typography variant="caption" component="p" sx={styles.infoText}>
                      {t('paymentSign_CertificateEdrpou')}{' '}
                      <Typography variant="body2" component="span" sx={styles.infoValue}>
                        {certificate.DirectoryAttributes.edrpou}
                      </Typography>
                    </Typography>
                  </Box>
                ) : null}
                {certificate?.Subject?.O ? (
                  <Box mb={2}>
                    <Typography variant="caption" component="p" sx={styles.infoText}>
                      {t('paymentSign_CertificateCompanyName')}{' '}
                      <Typography variant="body2" component="span" sx={styles.infoValue}>
                        {certificate.Subject.O}
                      </Typography>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <Box mt={7} sx={styles.buttonWrap}>
              <Button
                fullWidth={isMobile}
                variant="contained"
                color="primary"
                endIcon={loading ? <LoaderIcon /> : <SignatureIcon />}
                onClick={handleSignClickBtn}
                disabled={signButtonDisabled || loading}
              >
                {loading ? t('loading') : t('sign')}
              </Button>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </ModalPopupCommon>
  );
};

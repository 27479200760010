import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ExchangeRateNbuItemDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { PaginationChangeType, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/exchangeRate/components/NbuRates/components/NbuCurrencyTable/columns';
import { styles } from '@app/pages/exchangeRate/components/NbuRates/components/NbuCurrencyTable/styles';
import { ExchangeSource } from '@app/pages/exchangeRate/types';

type NbuCurrencyTableProps = {
  data: ExchangeRateNbuItemDto[];
  pagination?: TablePaginationType;
  onPaginationChange(pagination: PaginationChangeType): void;
  exchangeSource: ExchangeSource;
};

export const NbuCurrencyTable: React.FC<NbuCurrencyTableProps> = ({
  data,
  pagination,
  onPaginationChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        onSettingsButtonClick(): void {},
        t,
        onSearchButtonClick() {},
      }),
    ];
  }, [t]);
  const table = useReactTable<ExchangeRateNbuItemDto>({
    columns,
    data,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const buildBodyContent = (): React.ReactNode => {
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<ExchangeRateNbuItemDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <Box mt={2}>
      <ComplexTable
        head={{
          content: <TableHeadContent<ExchangeRateNbuItemDto> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || DEFAULT_FIRST_PAGE}
              rowsPerPage={pagination?.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </Box>
  );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parseISO } from 'date-fns';
import { groupBy, map as lodashMap } from 'lodash-es';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { SheetProvider } from '@app/context/SheetContext';
import { useUIState } from '@app/context/UIContext';
import {
  FileLinkAdditionalInfoDtoCodeEnum,
  FileLinkResponseDto,
  StatementTypeEnum,
  OutputFormatEnum,
  PaymentTemplateTypeEnum,
} from '@app/core/api';
import { ConfirmEditOperationPopup } from '@app/core/components/ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { DeleteDocumentDialog } from '@app/core/components/DeleteDocumentDialog';
import { multipleDeleteDocumentFn } from '@app/core/components/DeleteDocumentDialog/query';
import { Loader } from '@app/core/components/Loader';
import { MobileSelectFileFormat } from '@app/core/components/MobileSelectFileFormat';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { SaveTemplateDialog } from '@app/core/components/SaveTemplateDialog';
import { makeTemplateDataFromOperation } from '@app/core/components/SaveTemplateDialog/helpers';
import { SignPayment } from '@app/core/components/SignPayment';
import { makeSubFilter } from '@app/core/components/Statements/helpers';
import {
  OperationsFromPeriodResult,
  StatementsSubFilterEnum,
  StatementsTableColumnsEnum,
} from '@app/core/components/Statements/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetStatementsMultipleFiles } from '@app/core/hooks';
import {
  ColumnFilterType,
  PaginationChangeType,
  SortingRule,
  DocumentStatus,
  Operation,
  OperationGroupType,
  OperationsTypeEnum,
} from '@app/core/types';
import { hasNextPage } from '@app/core/utils';
import { makeDataForSubmit } from '@app/pages/operations/helpers';
import { EmptyListText } from '@app/pages/operations/mobile/components/EmptyListText';
import { MobileOperationsFilters } from '@app/pages/operations/mobile/components/MobileOperationsFilters';
import { OperationsGroup } from '@app/pages/operations/mobile/components/OperationsGroup';
import { SheetHeader } from '@app/pages/operations/mobile/components/SheetHeader';
import { styles } from '@app/pages/operations/mobile/styles';
import { getUAHStatements } from '@app/pages/operations/query';
import { OperationsFilter, OperationsFilterFormData, OperationsViewProps } from '@app/pages/operations/types';
import { RouteList } from '@app/src/constants/routeList';

const SHEET_MAX_DIFF = 160;
const SHEET_MIN_DIFF = 250;

export const OperationsViewMobile: React.FC<OperationsViewProps> = ({ defaultValues }) => {
  const { t } = useTranslation();
  const columnFilter = useRef<ColumnFilterType<StatementsSubFilterEnum>>({});
  const { notify } = useNotify();
  const navigate = useNavigate();
  const operationsFilterFormDataRef = useRef<OperationsFilterFormData>();
  const operationsListRef = useRef<Array<Operation>>([]);
  const [selectedOperations, setSelectedOperations] = useState<Operation[]>([]);
  const bottomSheetRef = useRef<BottomSheetRef | null>(null);
  const [compact, setCompact] = useState(false);
  const sortRuleRef = useRef<SortingRule>({ field: StatementsTableColumnsEnum.operationDate, desc: true });
  const operationTypeRef = useRef<OperationsTypeEnum>(OperationsTypeEnum.withdrawal);
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
  const [showSignPayment, setShowSignPayment] = useState(false);
  const [fileTypeSelectDialogOpen, setFileTypeSelectDialogOpen] = useState(false);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });
  const [showBottomAnchor, setShowBottomAnchor] = useState<boolean>(false);
  const [operations, setOperations] = useState<Array<Operation>>([]);
  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<OperationsFromPeriodResult, unknown, OperationsFilter>(getUAHStatements);

  const { mutateAsync } = useGetStatementsMultipleFiles();

  const fileSelectHandler = (outputType: OutputFormatEnum, format?: string) => {
    const uids = selectedOperations.map((document) => document.uuid);
    mutateAsync({
      outputType,
      operationsType: StatementTypeEnum.UahOperation,
      ordersUids: uids,
    }).then((responseData: FileLinkResponseDto) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        if (format) {
          window.open(`${responseData.link}?format=${format}`, '_blank');
          return;
        }
        window.location.href = `${responseData.link}?format=download&type=payment-order`;
      }
    });
  };

  const onSelectFileTypeHandler = (outputType: OutputFormatEnum) => {
    setFileTypeSelectDialogOpen(false);
    const uids = selectedOperations.map((document) => document.uuid);
    mutateAsync({
      outputType,
      operationsType: StatementTypeEnum.UahOperation,
      ordersUids: uids,
    }).then((responseData: FileLinkResponseDto) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        window.location.href = `${responseData.link}?format=download&type=payment-order`;
      }
    });
  };

  const updateTable = useCallback(() => {
    if (operationsFilterFormDataRef.current) {
      setShowBottomAnchor(false);
      mutate({
        ...makeDataForSubmit(operationsFilterFormDataRef.current),
        subFilter: makeSubFilter(columnFilter.current),
        pagination: paginationRef.current,
        sortBy: sortRuleRef.current,
        operationType: operationTypeRef.current,
      });
    }
  }, [mutate]);

  useEffect(() => {
    if (data) {
      operationsListRef.current = operationsListRef.current.concat(data.operations);
      setOperations(operationsListRef.current);
      setShowBottomAnchor(true);
    }
  }, [data]);

  const pagination = data?.pagination;
  useEffect(() => {
    if (pagination && bottomAnchorInView && !isLoading && operationsFilterFormDataRef.current) {
      if (hasNextPage(pagination)) {
        paginationRef.current = {
          page: pagination.page + 1,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        };

        updateTable();
      }
    }
  }, [bottomAnchorInView, isLoading, mutate, pagination, updateTable]);

  const dataReset = useCallback(() => {
    operationsListRef.current = [];
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE };
    setOperations([]);
    setSelectedOperations([]);
  }, []);

  const onFilterChangeHandler = useCallback(
    (filterData: OperationsFilterFormData) => {
      dataReset();
      operationsFilterFormDataRef.current = filterData;
      updateTable();
    },
    [dataReset, updateTable]
  );

  const handleOperationTypeChange = (operationType: OperationsTypeEnum): void => {
    operationTypeRef.current = operationType;
    dataReset();
    updateTable();
  };

  const handleCompactFilterClick = () => {
    bottomSheetRef.current?.snapTo(({ snapPoints }) => {
      return Math.min(...snapPoints);
    });
  };

  const onDeleteDocumentSuccessHandler = () => {
    dataReset();
    updateTable();
  };

  const onSignHandler = () => {
    setShowSignPayment(true);
  };

  const onCloseSignPayment = () => {
    setShowSignPayment(false);
  };

  const onSignSuccessHandler = () => {
    dataReset();
    updateTable();
    setShowSignPayment(false);
  };

  const onPrintClickHandler = () => {
    if (selectedOperations.length === 1) {
      const { uuid, link } = selectedOperations[0];
      window.open(link, '_blank');
    } else {
      fileSelectHandler(OutputFormatEnum.Pdf, 'print');
    }
  };

  const onCopyClickHandler = () => {
    if (selectedOperations?.length === 1) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.payment_create,
        query: {
          copyFrom: selectedOperations[0].uuid,
        },
      });
      navigate(urlTo);
    }
  };

  const onDownloadClickHandler = () => {
    setFileTypeSelectDialogOpen(true);
  };

  const onCloseFileTypeSelectDialog = () => {
    setFileTypeSelectDialogOpen(false);
  };

  const onDeleteDocumentHandler = () => {
    setShowDeleteDocumentDialog(true);
  };
  const onSubmitDeleteDocumentHandler = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onCancelDeleteDocument = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onAddToTemplatesClickHandler = () => {
    setShowSaveTemplateDialog(true);
  };

  const onCancelSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  const onSuccessSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  const navigateToEditPayment = (uuid: string, templateType: PaymentTemplateTypeEnum) => {
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_edit.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_edit.replace(':uuid', uuid));
  };

  const onEditClickHandler = () => {
    const { uuid, signed, templateType } = selectedOperations[0];
    if (signed) {
      setShowConfirmEditPopup(true);
    } else {
      navigate(RouteList.payment_edit.replace(':uuid', uuid));
      navigateToEditPayment(uuid, templateType);
    }
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    const { uuid, templateType } = selectedOperations[0];
    navigateToEditPayment(uuid, templateType);
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const operationsGroups = useMemo(() => {
    const onCheckHandler = (operation: Operation, selected: boolean) => {
      if (selected) {
        const present = selectedOperations.filter((item) => {
          return item.id === operation.id;
        });
        if (present?.length === 0 && selectedOperations) {
          setSelectedOperations([...selectedOperations, operation]);
        }
      } else {
        setSelectedOperations(
          selectedOperations.filter((item) => {
            return item.id !== operation.id;
          })
        );
      }
    };
    const groups: OperationGroupType[] = operations.length
      ? lodashMap(
          groupBy<Operation>(operations, (item: Operation) => {
            const date = parseISO(item.details.date);
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0).toISOString();
          }),
          (items, key) => {
            return {
              date: key,
              operations: items,
            };
          }
        )
      : [];
    return groups.length ? (
      groups.map((operationsGroup, idx) => (
        <OperationsGroup
          key={`${idx}_${operationsGroup.date}`}
          operationsGroup={operationsGroup}
          onCheck={onCheckHandler}
        />
      ))
    ) : (
      <EmptyListText />
    );
  }, [operations, selectedOperations]);

  const docsForSignCount: number = selectedOperations.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  ).length;

  return (
    <>
      <PageWrapper
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          height: '100vh',
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            backgroundColor: 'transparent',
            borderRadius: 0,
            height: '100vh',
          }}
        >
          <PageHeader
            title={t('uahTransactions')}
            styles={{
              title: {
                color: '#FFFFFF',
              },
              closeButton: {
                color: '#FFFFFF',
              },
              burgerButton: {
                color: '#FFFFFF',
              },
            }}
          />
          <MobileOperationsFilters
            compact={compact}
            defaultValues={defaultValues}
            onFilterChange={onFilterChangeHandler}
            onOperationTypeChange={handleOperationTypeChange}
            onCompactFilterClick={handleCompactFilterClick}
          />
          <SheetProvider>
            <BottomSheet
              open={true}
              skipInitialTransition
              expandOnContentDrag
              blocking={false}
              ref={bottomSheetRef}
              scrollLocking
              style={{ ...styles.sheet }}
              defaultSnap={({ snapPoints }) => {
                return Math.min(...snapPoints);
              }}
              snapPoints={({ maxHeight, height }) => {
                const sheetMaxHeight = maxHeight - SHEET_MAX_DIFF;
                const sheetMinHeight = maxHeight - SHEET_MIN_DIFF;
                if (height === sheetMaxHeight) {
                  setCompact(true);
                }
                if (height === sheetMinHeight) {
                  setCompact(false);
                }
                return [sheetMaxHeight, sheetMinHeight];
              }}
              header={
                operations.length ? (
                  <SheetHeader
                    onSignClick={onSignHandler}
                    selectedOperations={selectedOperations}
                    onDeleteClick={onDeleteDocumentHandler}
                    onAddToTemplatesClick={onAddToTemplatesClickHandler}
                    onCopyClick={onCopyClickHandler}
                    onPrintClick={onPrintClickHandler}
                    onDownloadClick={onDownloadClickHandler}
                    onEditClick={onEditClickHandler}
                  />
                ) : null
              }
            >
              <Box sx={styles.container}>
                <>
                  {operationsGroups}
                  <Box
                    ref={bottomAnchorRef}
                    sx={styles.bottomAnchor}
                    style={{ display: showBottomAnchor ? 'block' : 'none' }}
                  />
                </>
              </Box>
            </BottomSheet>
          </SheetProvider>
          <ModalCommon open={isLoading}>
            <div>
              <Loader />
            </div>
          </ModalCommon>
        </Grid>
      </PageWrapper>
      {showSignPayment ? (
        <SignPayment
          operations={selectedOperations}
          onClose={onCloseSignPayment}
          onSignSuccess={onSignSuccessHandler}
        />
      ) : null}

      <MobileSelectFileFormat
        open={fileTypeSelectDialogOpen}
        onClose={onCloseFileTypeSelectDialog}
        onSelectFileType={onSelectFileTypeHandler}
      />

      {showDeleteDocumentDialog ? (
        <DeleteDocumentDialog
          onSubmit={onSubmitDeleteDocumentHandler}
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          mutationFn={multipleDeleteDocumentFn}
          selectedDocuments={selectedOperations}
          docsForSignCount={docsForSignCount}
          currency={selectedOperations[0].currency}
        />
      ) : null}

      {showSaveTemplateDialog && selectedOperations[0] ? (
        <SaveTemplateDialog
          open
          documentTemplateData={makeTemplateDataFromOperation(selectedOperations[0])}
          onCancel={onCancelSaveTemplateHandler}
          onSuccessSaveTemplate={onSuccessSaveTemplateHandler}
        />
      ) : null}

      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

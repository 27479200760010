export const getCurrencyMenuItems = (
  t: (translationKey: string) => string,
  currencies: string[]
): { labelText: string; id: string | number; value: string | null }[] => {
  return [
    {
      id: 1,
      value: null,
      labelText: t('allAccounts'),
    },
    ...currencies.map((item) => ({
      id: item,
      value: item,
      labelText: item,
    })),
  ];
};

export const styles = {
  root: {},
  cell: {
    flex: '1 auto',
    minWidth: '40x',
    padding: '10px',
  },
  cell_date: {},
  cell_duration: {},
  cell_browser: {},
  cell_operationSystem: {},
  cell_ip: {},
  cell_country: {},
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
  table: {
    flexWrap: 'nowrap',
  },
};

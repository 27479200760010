export const styles = {
  root: {
    marginBottom: '12px',
  },
  cardMainContainer: {
    backgroundColor: 'colors.containerBackgroundColor',
    borderRadius: '8px',
    padding: '12px',
  },
  documentNumber: {
    color: 'colors.gray400',
    fontWeight: 600,
    marginRight: '16px',
  },
  date: {
    color: 'colors.gray400',
    fontWeight: 400,
  },
  chevronContainer: {
    position: 'relative',
  },
  chevronIcon: {
    position: 'absolute',
    right: '10px',
    transform: 'translate(0, -50%)',
    color: 'colors.gray200',
  },
  accountContainer: {
    width: ' 100%',
    fontSize: '0.875rem',
    marginTop: '12px',
    paddingRight: '32px',
  },
  cardExpanded: {
    backgroundColor: 'colors.primary25',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  extendedInfoContainer: {
    backgroundColor: 'colors.primary25',
    boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    padding: '12px',
  },
  detailsInfoDescription: {
    color: 'colors.gray300',
    fontWeight: 600,
  },
  detailsInfoValue: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  divider: {
    height: '1px',
    width: '100%',
    marginTop: '12px',
    marginBottom: '12px',
    backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '12px 1px',
    backgroundRepeat: 'repeat-x',
  },
  buttonRoot: {
    color: 'colors.primary700',
  },
  menuItem: {
    borderBottom: `1px solid`,
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  actionButton: {
    color: 'colors.gray600',
  },
  actionIcon: {
    paddingTop: '3px',
    color: 'colors.gray600',
  },
};

import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  EmailDocumentTypeEnum,
  OperationDto,
  CurrencyEnum,
  PaymentOrderTypeEnum,
  OperationTypeEnum,
} from '@app/core/api';
import { DetailsInfoItem } from '@app/core/components/ComplexTable/primitives';
import { LoadOperationButton } from '@app/core/components/OperationDetails/Actions/LoadOperationButton';
import { PrintButton } from '@app/core/components/OperationDetails/Actions/PrintButton';
import { styles } from '@app/core/components/OperationDetails/styles';
import { ShareDocumentButton } from '@app/core/components/ShareDocumentButton';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export interface DepositOperationsDetailsProps {
  data: OperationDto;
}

export const DepositOperationDetails: React.FC<DepositOperationsDetailsProps> = ({ data: operation }) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const { type, date, documentNumber, paymentReference, details, link, currency, documentType, uuid } = operation;
  const { date: detailsDate, senderAccount, recipientAccount } = details;

  return (
    <>
      <Grid container alignItems="center" sx={{ ...styles.root }}>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem
              description={t(
                documentType === PaymentOrderTypeEnum.MemorialOrderFlag
                  ? 'documentCategory_memorialOrder'
                  : 'paymentCreationLocalPayment_paymentOrder'
              )}
              value={documentNumber}
            />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('paymentCreationLocalPayment_documentDate')}
              value={formatISODateString(detailsDate, DATE_FORMAT)}
            />
          </Grid>
          {date ? (
            <Grid item xs={3}>
              <DetailsInfoItem
                description={t('operationDetailShare_spend')}
                value={`${formatISODateString(date, DATE_FORMAT)}`}
              />
            </Grid>
          ) : null}
          <Grid item container xs={3} justifyContent="flex-end">
            {currency === CurrencyEnum.Uah ? (
              <>
                {permissions.uahOperations.print &&
                (type === OperationTypeEnum.Withdrawal || type === OperationTypeEnum.Deposit) ? (
                  <ShareDocumentButton
                    styles={styles.actionButton}
                    link={link}
                    type={EmailDocumentTypeEnum.PaymentOrder}
                    uuid={uuid}
                  />
                ) : null}
                {permissions.uahOperations.print &&
                (type === OperationTypeEnum.Withdrawal || type === OperationTypeEnum.Deposit) ? (
                  <PrintButton styles={styles.actionIcon} link={link} />
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderAccountName')} value={senderAccount.name} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('operationDetail_lei')}
              value={senderAccount.LEI || senderAccount.passport || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderBank')} value={senderAccount.bankName} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={senderAccount.accountNumber} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipient')} value={recipientAccount.name} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('operationDetail_recipientLei')}
              value={recipientAccount.LEI || recipientAccount.passport || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipientBank')} value={recipientAccount.bankName} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={recipientAccount.accountNumber} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
          <DetailsInfoItem description={t('paymentCreationLocalPayment_purpose')} value={paymentReference} />
          {permissions.uahOperations.download ? <LoadOperationButton link={link} /> : null}
        </Grid>
      </Grid>
    </>
  );
};

export const styles = {
  root: {
    borderRadius: '8px',
    overflow: 'hidden',
  },

  headNotificationsTable: {
    backgroundColor: 'colors.gray25',
    padding: `12px 16px}`,
  },
  bodyNotificationsTable: {
    borderTop: `1px solid`,
    borderColor: 'colors.gray50',
    paddingLeft: '12px',
    paddingRight: 'spacing(3)px',
  },
  item: {
    borderTop: `1px solid`,
    borderColor: 'colors.gray50',
    padding: `10px 4px`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  actionTableButtons: {
    '& svg': {
      fill: 'colors.primary500',
    },
    '& button': {
      marginRight: '8px',
    },
  },
  fontMiddleWeight: {
    fontWeight: 600,
  },
  statusKeyActive: {
    backgroundColor: 'colors.success',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
  statusKeyClose: {
    backgroundColor: 'colors.error',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
  sortName: {
    cursor: 'pointer',
  },
  tableRowHead: {
    border: 'none',
  },
  tableRow: {
    display: 'flex',
    width: '100%',
  },
  tableCell: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  tableCellDate: {
    flexShrink: 0,
    width: '142px',
  },
  tableCellIdKey: {
    flexShrink: 0,
    width: '340px',
  },
};

import React, { useState } from 'react';

import { Box, TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { ExpandedTableRow } from '@app/core/components';
import { styles as tableStyles } from '@app/core/components/OperationsTable/styles';

import { DefaultNationalityAccountSetting } from './components/DefaultNationalityAccountSetting';
import { styles } from './styles';

export const ProfileSettingsItemCompanySettings: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const handleExpand = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <Box mb={4}>
        <Typography sx={styles.title}>{t('documentNumeration')}</Typography>
      </Box>
      <ExpandedTableRow
        open={open}
        onExpand={handleExpand}
        sx={{ ...tableStyles.tableRow, ...(open && tableStyles.tableRowExpanded) }}
        extendedContend={<DefaultNationalityAccountSetting />}
      >
        <TableCell component="div" sx={{ ...tableStyles.tableCell }}>
          <Typography variant="body2" sx={styles.cellTitle}>
            {t('uahTransactions')}
          </Typography>
        </TableCell>
        <TableCell component="div" sx={{ ...tableStyles.tableCell, ...tableStyles.actionCell }}>
          {open ? (
            <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
          ) : (
            <DownChevronSmallIcon sx={styles.chevronIcon} fontSize="small" />
          )}
        </TableCell>
      </ExpandedTableRow>
    </Box>
  );
};

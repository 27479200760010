import { UserProfileMenuFilter } from '@app/pages/profile/types';

export const getMenuItems = (t: (key: string) => string) => {
  return [
    {
      id: 1,
      value: UserProfileMenuFilter.Data,
      labelText: t('myProfile_myData'),
    },
    // {
    // id: 2,
    //   value: UserProfileMenuFilter.user_menu_keys,
    //   labelText: t('myProfile_keys'),
    // },
    {
      id: 3,
      value: UserProfileMenuFilter.Security,
      labelText: t('myProfile_security'),
    },
    // {
    //   id: 4,
    //   value: UserProfileMenuFilter.Notification,
    //   labelText: t('myProfile_notification'),
    // },
    {
      id: 5,
      value: UserProfileMenuFilter.Monitoring,
      labelText: t('myProfile_monitoring'),
    },
    {
      id: 6,
      value: UserProfileMenuFilter.History,
      labelText: t('myProfile_history'),
    },
    {
      id: 7,
      value: UserProfileMenuFilter.Settings,
      labelText: t('settings'),
    },
  ];
};

import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/AddUserInfoModal/styles';
import { ReactComponent as AddUserIcoBgSVG } from '@app/themes/default/assets/icons/addUser.svg';

export interface AddUserInfoProps {
  onClose(): void;
}

export const AddUserInfoModal: React.FC<AddUserInfoProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalCommon open onClose={onClose}>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <AddUserIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
          <Typography component="span" variant="h6" sx={styles.caption}>
            {t('add_user_caption')}
          </Typography>
          <IconButton size="small" onClick={onClose} sx={styles.close}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={4}>
          <Typography variant="body1" sx={styles.text}>
            {t('addUserText')}
          </Typography>
        </Box>
        <Box mt={7}>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button variant="contained" size="small" color="primary" onClick={onClose}>
                {t('ok')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalCommon>
  );
};

import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  GetCountDocumentByStatusDto,
  InternalSwiftTotalAmountDto,
  SwiftOperationWithAvailableButtons,
  SWIFTStatusEnum,
} from '@app/core/api';
import { TableColumnConfig, PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { SwiftTablePreference } from '@app/pages/swift/components/SwiftTable/components/SwiftTablePreference';
import { TableContent } from '@app/pages/swift/components/SwiftTable/components/TableContent';
import { SwiftFilterField } from '@app/pages/swift/types';

export interface SwiftTableProps {
  swiftOperation: SwiftOperationWithAvailableButtons[];
  columnsConfig: TableColumnConfig[];
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  onPaginationChange(pagination: PaginationChangeType): void;
  columnsConfigMutationFn(swiftTableColumnsData: TableColumnConfig[]): Promise<TableColumnConfig[]>;
  onSuccessConfigurationSaved(swiftColumnsConfig: TableColumnConfig[]): void;
  onSort?(rule: SortingRule): void;
  onDeleteDocumentSuccess(): void;
  onSignDocumentsSuccess(): void;
  totalAmounts?: InternalSwiftTotalAmountDto[];
  onFilterChange(filter: SWIFTStatusEnum): void;
  countByStatus?: GetCountDocumentByStatusDto;
}

export const SwiftTable: React.FC<SwiftTableProps> = ({
  swiftOperation,
  pagination,
  loading,
  queryKey,
  columnsConfig,
  onSort,
  onPaginationChange,
  columnsConfigMutationFn,
  onSuccessConfigurationSaved,
  onDeleteDocumentSuccess,
  totalAmounts,
  onSignDocumentsSuccess,
  onFilterChange,
  countByStatus,
}) => {
  const { setValue, register } = useFormContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShow = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    register(SwiftFilterField.rowsPerPage, { required: false });
    register(SwiftFilterField.page, { required: false });
  }, [register]);

  const onPaginationChangeHandler = ({ rowsPerPage, page }: PaginationChangeType) => {
    setValue(SwiftFilterField.page, page);
    setValue(SwiftFilterField.rowsPerPage, rowsPerPage);
    onPaginationChange({ rowsPerPage, page });
  };

  return (
    <>
      <TableContent
        loading={loading}
        columnsConfig={columnsConfig}
        swiftData={swiftOperation}
        onSettingsButtonClick={handleShow}
        pagination={pagination}
        onSort={onSort}
        queryKey={queryKey}
        onPaginationChange={onPaginationChangeHandler}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        totalAmounts={totalAmounts}
        onSignDocumentsSuccess={onSignDocumentsSuccess}
        onFilterChange={onFilterChange}
        countByStatus={countByStatus}
      />

      {showModal ? (
        <SwiftTablePreference
          show={showModal}
          columnsConfig={columnsConfig}
          columnsConfigMutationFn={columnsConfigMutationFn}
          handleShow={handleShow}
          onSuccessConfigurationSaved={onSuccessConfigurationSaved}
        />
      ) : null}
    </>
  );
};

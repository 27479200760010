import { getClientId, paymentOrdersApiClient, ApiError, SendToSignResponseDto } from '@app/core/api';

export const sendToSignPayment = async (uuids: string[]): Promise<SendToSignResponseDto> => {
  const result = await paymentOrdersApiClient.paymentOrderControllerSendDocumentsToSign(getClientId(), {
    uuids,
  });

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error send to sign payment!');
  }
  return result.data;
};

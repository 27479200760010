export const styles = {
  root: {
    backgroundColor: 'colors.containerBackgroundColor',
    marginBottom: '4px',
    padding: `9px 20px`,
    borderRadius: '8px',
    cursor: 'pointer',
    height: '64px',
    textDecoration: 'none',
    color: 'inherit',
  },
  blocked: {
    backgroundColor: 'rgba(242, 153, 74, 0.1)',
    border: '1px solid #F2994A',
  },
  errorIcon: {
    color: 'colors.error',
    marginRight: '8px',
  },
  debitText: {
    color: 'colors.success',
    fontWeight: 600,
  },
  creditText: {
    color: 'colors.error',
    fontWeight: 600,
  },
  amountText: {
    color: 'colors.gray800',
    fontWeight: 600,
  },
  debitCreditContainer: {
    textAlign: 'right',
  },
  amountCurrencyContainer: {
    width: '200px',
    textAlign: 'right',
  },
  detailIconWrapper: {
    paddingLeft: '16px',
    lineHeight: 0,
  },
  arrowRight: {
    color: 'colors.primary600',
  },
};

import { OutputFormatEnum, PeriodType } from '@app/core/api';
import { AccountSelectType } from '@app/core/types';

export enum FormFieldName {
  deliveryChannel = 'deliveryChannel',
  operationAccounts = 'operationAccounts',
  outputType = 'outputType',
  periodType = 'periodType',
}

export type MonitoringFormData = {
  operationAccounts: AccountSelectType[];
  periodType: PeriodType;
  outputType: OutputFormatEnum;
};

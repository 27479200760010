export const styles = {
  root: {
    marginTop: '12px',
    height: '100%',
  },
  sortIcon: {
    fill: 'colors.primary500',
  },
  cell: {
    flex: '1 auto',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&.body': {
      overflow: 'hidden',
    },
  },
  cell_selection: {
    maxWidth: '40px',
  },
  cell_createdAt: {
    maxWidth: '140px',
    '&.body': {
      color: 'colors.gray400',
      fontSize: '0.75rem',
    },
  },
  cell_senderName: {
    maxWidth: '190px',
  },
  cell_subject: {
    maxWidth: '230px',
    minWidth: '100px',
    '&.body': {
      color: 'colors.gray400',
      fontSize: '0.75rem',
    },
  },
  cell_message: {
    minWidth: '160px',
    '&.body': {
      color: 'colors.gray400',
      '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '@supports (-webkit-line-clamp: 1)': {
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          WebkitBoxAlign: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  },
  cell_status: {
    flexGrow: 2,
    flexShrink: 0,
    width: '120px',
    maxWidth: '120px',
    '&.body': {
      color: 'colors.gray400',
      fontSize: '0.75rem',
    },
  },
  cell_actions: {
    maxWidth: '46px',
    color: 'inherit',
  },
  emptyRow: {
    position: 'relative',
    height: '150px',
  },
  linkArrow: {
    color: '#0066c2',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  messageStatus: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.gray200',
    maxWidth: '140px',
  },
  messageStatusGreen: {
    backgroundColor: 'colors.success',
  },
};

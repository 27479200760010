import { CountryCodeDto } from '@app/core/api';

export type SwiftCounterpartiesFilterRows = {
  clientId: number;
  name: string;
  lei: string;
  account: string;
  bank: string;
  country: CountryCodeDto;
};

export const resetFormData = {
  name: '',
  lei: '',
  account: '',
  bank: '',
};

export enum SwiftCounterpartiesColumnsEnum {
  name = 'name',
  country = 'country',
  bic = 'bic',
  address = 'address',
  account = 'account',
}

export enum SwiftCounterpartyFormFieldsEnum {
  name = 'name',
  correspondentSwiftBic = 'correspondentSwiftBic',
  correspondentBank = 'correspondentBank',
  correspondentBankCheck = 'correspondentBankCheck',
  account = 'account',
  countryCode = 'countryCode',
  bic = 'bic',
  address = 'address',
  bankName = 'bankName',
}

export type SwiftCounterpartyFormData = {
  [SwiftCounterpartyFormFieldsEnum.name]: string;
  [SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic]: string;
  [SwiftCounterpartyFormFieldsEnum.correspondentBank]: string;
  [SwiftCounterpartyFormFieldsEnum.correspondentBankCheck]: boolean;
  [SwiftCounterpartyFormFieldsEnum.account]: string;
  [SwiftCounterpartyFormFieldsEnum.bic]: string;
  [SwiftCounterpartyFormFieldsEnum.address]: string;
  [SwiftCounterpartyFormFieldsEnum.countryCode]: CountryCodeDto;
  [SwiftCounterpartyFormFieldsEnum.bankName]: string;
};

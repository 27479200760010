import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, accountsApiClient, ApiError, GetAccountResponseDto } from '@app/core/api';

const getSelectedAccountsQueryFn = async (): Promise<GetAccountResponseDto[]> => {
  const response = await accountsApiClient.accountsControllerGetSelectedAccounts(getClientId());
  return response.data;
};

export const useGetSelectedAccounts = (): UseMutationResult<GetAccountResponseDto[], ApiError, any> => {
  return useMutation<GetAccountResponseDto[], ApiError, any>(getSelectedAccountsQueryFn);
};

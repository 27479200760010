import React from 'react';

import { Grid, Typography } from '@mui/material';
import { ColumnDef, CellContext } from '@tanstack/react-table';

import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import {
  DateColumn,
  VisitColumn,
} from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable/ColumnContainer';
import { VisitsRow } from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable/helpers';
import { Visits } from '@app/pages/profile/desktop/components/HistoryOfVisits/types';

interface MakeTableColumnsProps {
  t(text: string): string;
}

enum VisitsField {
  id = 'id',
  date = 'date',
  duration = 'duration',
  browser = 'browser',
  operationSystem = 'operationSystem',
  ip = 'ip',
  country = 'country',
}

export const makeTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<VisitsRow, any>[] => {
  return [
    {
      accessorKey: VisitsField.date,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_date')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <DateColumn>{getValue()}</DateColumn>;
      },
    },
    {
      accessorKey: VisitsField.duration,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_duration')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.browser,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_browser')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.operationSystem,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_operationSystem')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.ip,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_ip')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.country,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_country')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
  ];
};

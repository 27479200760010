import React, { useEffect } from 'react';

import Cookies from 'js-cookie';
import Logger from 'js-logger';
import { parse } from 'query-string';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { MainLayout } from '@app/common/layout/MainLayout';
import { getAccessToken } from '@app/core/api';
import { isDevelopment, loadScript } from '@app/core/utils';
import { AccountsPage } from '@app/pages/accounts';
import { SelectedAccountPage } from '@app/pages/accounts/selectedAccount';
import { BanksSwiftPage } from '@app/pages/banksSwift';
import { BanksUAPage } from '@app/pages/banksUA';
import { CompanyProfilePage } from '@app/pages/companyProfile';
import { CounterpartiesPage } from '@app/pages/counterparties';
import { SwiftCounterpartiesPage } from '@app/pages/counterpartiesSwift';
import { CountryCodesPage } from '@app/pages/countryCodes';
import { DashboardPage } from '@app/pages/dashboard';
import { DepositsListPage } from '@app/pages/deposits';
import { DepositLinePage } from '@app/pages/deposits/show/DepositLinePage';
import { DepositPage } from '@app/pages/deposits/show/DepositPage';
import { DepositTranchePage } from '@app/pages/deposits/show/DepositTranchePage';
import { DirectoriesPage } from '@app/pages/directories';
import { DocumentsPage } from '@app/pages/documents/DocumentsList';
import { EditCurrencyDocumentPage } from '@app/pages/documents/edit';
import { ShowCurrencyDocumentPage } from '@app/pages/documents/show';
import { CreateExchangePage } from '@app/pages/exchange/create';
import { CurrencyExchangeTransactionsPage } from '@app/pages/exchange/currencyExchangeTransactions';
import { EditExchangePage } from '@app/pages/exchange/edit';
import { ShowExchangePage } from '@app/pages/exchange/show';
import { ExchangeRatePage } from '@app/pages/exchangeRate';
import { LettersPage } from '@app/pages/letters';
import { MessageCreationPage } from '@app/pages/letters/create';
import { MessageEditPage } from '@app/pages/letters/edit';
import { MessageShowPage } from '@app/pages/letters/show';
import { LoansPage } from '@app/pages/loans';
import { LoanShowPage } from '@app/pages/loans/show';
import { LoansLinesPage } from '@app/pages/loansLines';
import { LoanLinesShowPage } from '@app/pages/loansLines/show';
import { OperationsPage } from '@app/pages/operations';
import { OverdraftsPage } from '@app/pages/overdrafts';
import { OverdraftShow } from '@app/pages/overdrafts/show';
import { PaymentCreationPage } from '@app/pages/payment/create';
import { EditOwnPaymentPage } from '@app/pages/payment/edit/BetweenMyAccount';
import { EditLocalPaymentPage } from '@app/pages/payment/edit/LocalPayment';
import { PaymentImportPage } from '@app/pages/payment/import';
import { ShowBetweenMyAccountPaymentPage } from '@app/pages/payment/show/BetweenMyAccount';
import { ShowLocalPaymentPage } from '@app/pages/payment/show/LocalPayment';
import { CreateTemplatePage } from '@app/pages/paymentsTemplates/create';
import { EditTemplatePage } from '@app/pages/paymentsTemplates/edit';
import { PaymentsTemplatesPage } from '@app/pages/paymentsTemplates/PaymentsTemplates';
import { ProfilePage } from '@app/pages/profile';
import { StatementsPage } from '@app/pages/statements';
import { SwiftPage } from '@app/pages/swift';
import { SwiftPaymentPage } from '@app/pages/swift/SwiftPayment/create';
import { SwiftEditPage } from '@app/pages/swift/SwiftPayment/edit';
import { LoanSwiftCreatePage } from '@app/pages/swift/SwiftPayment/loanSwiftCreate';
import { LoanSwiftEditPage } from '@app/pages/swift/SwiftPayment/loanSwiftEdit';
import { SwiftShowPage } from '@app/pages/swift/SwiftPayment/show';
import { setCryptoLibraryLoaded } from '@app/slices/cryptoSlice';
import { getAllAccounts, getCurrentUahAccounts, updateProfile } from '@app/slices/profileSlice';
import { appConfig, serviceConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { initCrypto } from '@app/src/crypto';
import { useAppDispatch, useAppSelector } from '@app/src/store';

export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { cryptoLibraryLoaded } = useAppSelector((state) => state.crypto);
  const { development, enableNextFeatures } = parse(window.location.search);
  if (development) {
    Cookies.set('development', development as string);
  }

  Cookies.remove('lastPage');
  if (enableNextFeatures) {
    Cookies.set('enableNextFeatures', enableNextFeatures as string);
  }

  const token = getAccessToken();

  if (!token) {
    sessionStorage.removeItem('LastLogin');
  }

  if (!token && !isDevelopment()) {
    window.location.href = serviceConfig.loginUrl;
  }

  Logger.useDefaults();

  useEffect(() => {
    if (token) {
      dispatch(updateProfile());
      dispatch(getAllAccounts());
      dispatch(getCurrentUahAccounts());
    }
  }, [dispatch, token]);

  // TODO: remove it after fix env
  useEffect(() => {
    Logger.log('serviceConfig.cookiesDomain', serviceConfig.cookiesDomain);
  }, []);

  useEffect(() => {
    if (!cryptoLibraryLoaded) {
      loadScript({
        src: appConfig.cryptoModuleUrl,
        onloadCallback: () => {
          initCrypto();
          dispatch(setCryptoLibraryLoaded(true));
        },
      });
    }
  }, [cryptoLibraryLoaded, dispatch]);

  return token ? (
    <>
      <BrowserRouter>
        <MainLayout>
          <Routes>
            <Route path={RouteList.main} element={<Navigate to={RouteList.dashboard} />} />
            <Route path={RouteList.accounts} element={<AccountsPage />} />
            <Route path={RouteList.account_page} element={<SelectedAccountPage />} />
            <Route path={RouteList.dashboard} element={<DashboardPage />} />
            <Route path={RouteList.deposits} element={<DepositsListPage />} />
            <Route path={RouteList.deposits_show_deposit_page} element={<DepositPage />} />
            <Route path={RouteList.deposits_show_deposit_line_page} element={<DepositLinePage />} />
            <Route path={RouteList.deposits_show_deposit_tranche_page} element={<DepositTranchePage />} />
            <Route path={RouteList.statements} element={<StatementsPage />} />
            <Route path={RouteList.operations} element={<OperationsPage />} />
            <Route path={RouteList.profile} element={<ProfilePage />} />
            <Route path={RouteList.companyProfile} element={<CompanyProfilePage />} />
            <Route path={RouteList.template_creation} element={<CreateTemplatePage />} />
            <Route path={RouteList.template_edit} element={<EditTemplatePage />} />
            <Route path={RouteList.payment_create} element={<PaymentCreationPage />} />
            <Route path={RouteList.payment_edit} element={<EditLocalPaymentPage />} />
            <Route path={RouteList.payment_view} element={<ShowLocalPaymentPage />} />
            <Route path={RouteList.payment_import} element={<PaymentImportPage />} />
            <Route path={RouteList.exchange_rate} element={<ExchangeRatePage />} />
            <Route path={RouteList.directories} element={<DirectoriesPage />} />
            <Route path={RouteList.directories_banks_internal} element={<BanksUAPage />} />
            <Route path={RouteList.directories_banks_swift} element={<BanksSwiftPage />} />
            <Route path={RouteList.directories_codes_countries} element={<CountryCodesPage />} />
            <Route path={RouteList.directories_counterparties} element={<CounterpartiesPage />} />
            <Route path={RouteList.directories_counterparties_swift} element={<SwiftCounterpartiesPage />} />
            <Route path={RouteList.directories_payments_templates} element={<PaymentsTemplatesPage />} />
            <Route path={RouteList.letters} element={<LettersPage />} />
            <Route path={RouteList.letters_create} element={<MessageCreationPage />} />
            <Route path={RouteList.letters_edit} element={<MessageEditPage />} />
            <Route path={RouteList.letters_show} element={<MessageShowPage />} />
            <Route path={RouteList.loans} element={<LoansPage />} />
            <Route path={RouteList.loans_page} element={<LoanShowPage />} />
            <Route path={RouteList.loan_lines} element={<LoansLinesPage />} />
            <Route path={RouteList.loan_lines_page} element={<LoanLinesShowPage />} />
            <Route path={RouteList.loan_swift_create} element={<LoanSwiftCreatePage />} />
            <Route path={RouteList.loan_swift_edit} element={<LoanSwiftEditPage />} />
            <Route path={RouteList.swift} element={<SwiftPage />} />
            <Route path={RouteList.swift_create} element={<SwiftPaymentPage />} />
            <Route path={RouteList.swift_edit} element={<SwiftEditPage />} />
            <Route path={RouteList.swift_show} element={<SwiftShowPage />} />
            <Route path={RouteList.exchange} element={<CurrencyExchangeTransactionsPage />} />
            <Route path={RouteList.exchange_create} element={<CreateExchangePage />} />
            <Route path={RouteList.exchange_edit} element={<EditExchangePage />} />
            <Route path={RouteList.exchange_show} element={<ShowExchangePage />} />
            <Route path={RouteList.documents} element={<DocumentsPage />} />
            <Route path={RouteList.documents_show} element={<ShowCurrencyDocumentPage />} />
            <Route path={RouteList.documents_edit} element={<EditCurrencyDocumentPage />} />
            <Route path={RouteList.overdrafts} element={<OverdraftsPage />} />
            <Route path={RouteList.overdraft_page} element={<OverdraftShow />} />
            <Route path={RouteList.payment_own_edit} element={<EditOwnPaymentPage />} />
            <Route path={RouteList.payment_own_view} element={<ShowBetweenMyAccountPaymentPage />} />
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </>
  ) : null;
};

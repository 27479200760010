import * as React from 'react';

import { Grid, Typography, useTheme } from '@mui/material';

import { AccountStatusEnum } from '@app/core/api';

import { AccountSelectType } from '../../../types';
import { IBanText } from '../../IBanText';
import { useStyles } from '../styles';

type AccountInfoProps = {
  account: AccountSelectType;
};

export const AccountInfo: React.FC<AccountInfoProps> = ({ account }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <>
      <Grid container alignItems="flex-start" direction="column">
        <Typography
          variant="caption"
          sx={[
            styles.accountName,
            {
              ...(account.account.status === AccountStatusEnum.Closed && { ...styles.closed }),
            },
          ]}
        >
          {account.account.accountName}
        </Typography>
        <Typography variant="caption" component="div" sx={styles.accountNumber}>
          <IBanText text={account.account.accountNumber} />
        </Typography>
      </Grid>
    </>
  );
};

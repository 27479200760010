import React, { useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';

import { DownloadIcon, CsvIcon, PdfIcon, XmlIcon, XlsIcon } from '@app/common/icons';
import { GetFileSaldoRequestDtoOutputTypeEnum } from '@app/core/api';
import { appConfig } from '@app/src/config';

import { styles } from './style';
import { AccountSelectType } from '../../../../types';
import { getTurnoversFile } from '../../query';

interface DownloadOperationsProps {
  accounts?: AccountSelectType[];
  disableButtons: boolean;
}

export const DownloadTurnovers: React.FC<DownloadOperationsProps> = ({ accounts, disableButtons }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getValues } = useFormContext();

  const { mutateAsync, isLoading } = useMutation(getTurnoversFile);

  const handleDownloadFile = (fileType: GetFileSaldoRequestDtoOutputTypeEnum) => {
    const startDate = getValues().from;
    const endDate = getValues().to;
    if (isLoading) {
      return;
    }
    if (accounts) {
      const operationAccounts = accounts.map((account) => {
        return {
          id: account.account.id.toString(),
        };
      });
      mutateAsync({
        accountId: operationAccounts[0].id,
        dateRange: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        outputType: fileType,
      }).then((responseData) => {
        if (responseData?.link) {
          window.open(`${responseData.link}?format=download&type=payment-order`);
        }
      });
    }
  };

  const handlePopoverClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <IconButton size="small" color="default" sx={styles.icon} onClick={handleClick} disabled={disableButtons}>
        <DownloadIcon />
      </IconButton>
      <Popover
        onClick={handlePopoverClick}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          'MuiPopover-paper': styles.popoverOperationsFile,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box component="div" mt={1} sx={styles.popoverOperationsFileContent}>
          {appConfig.enableNextFeatures ? (
            <Button
              onClick={() => handleDownloadFile(GetFileSaldoRequestDtoOutputTypeEnum.Xlsx)}
              sx={styles.popoverOperationsFileItem}
            >
              <Typography variant="body2">
                <PdfIcon sx={styles.popoverOperationsFileItemIcon} />
                PDF
              </Typography>
            </Button>
          ) : null}
          <Button
            onClick={() => handleDownloadFile(GetFileSaldoRequestDtoOutputTypeEnum.Xlsx)}
            sx={styles.popoverOperationsFileItem}
          >
            <Typography variant="body2">
              <XlsIcon sx={styles.popoverOperationsFileItemIcon} />
              XLS
            </Typography>
          </Button>
          <Button
            onClick={() => handleDownloadFile(GetFileSaldoRequestDtoOutputTypeEnum.Csv)}
            sx={styles.popoverOperationsFileItem}
          >
            <Typography variant="body2">
              <CsvIcon sx={styles.popoverOperationsFileItemIcon} />
              СSV
            </Typography>
          </Button>
          {appConfig.enableNextFeatures ? (
            <Button
              onClick={() => handleDownloadFile(GetFileSaldoRequestDtoOutputTypeEnum.Xlsx)}
              sx={styles.popoverOperationsFileItem}
            >
              <Typography variant="body2">
                <XmlIcon sx={styles.popoverOperationsFileItemIcon} />
                XML
              </Typography>
            </Button>
          ) : null}
        </Box>
      </Popover>
    </Box>
  );
};

import { UserDtoSystemAccessEnum, UserStateStatusEnum } from '@app/core/api';
import { UserPermissions, UserSignature, SortingRule } from '@app/core/types';

export type CompanyUsersData = {
  registeredNumber: string;
  fullName: string;
  phone: string;
  email: string;
  accessPermissions: UserPermissions;
  signature: UserSignature;
  status: UserStateStatusEnum;
};

export type CompanyUsersEdit = {
  registeredNumber: string;
  fullName: string;
  middleName: string;
  surname: string;
  phone: string;
  email: string;
  accessPermissions: UserDtoSystemAccessEnum;
  signature: UserSignature;
  status: UserStateStatusEnum;
};

export enum CompanyUsersTableColumnsEnum {
  id = 'id',
  requestNumber = 'requestNumber',
  fullName = 'fullName',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  systemAccess = 'systemAccess',
  signingAuthority = 'signingAuthority',
  phoneNumber = 'phoneNumber',
  email = 'email',
  status = 'status',
  jobPosition = 'jobPosition',
}

export enum CompanyUsersFields {
  registeredNumber = 'registeredNumber',
  fullName = 'fullName',
  surname = 'surname',
  middleName = 'middleName',
  email = 'email',
  phone = 'phone',
  userPassiveOperations = 'userPassiveOperations',
  userDocEdit = 'userDocEdit',
  userDocView = 'userDocView',
  accessPermissions = 'accessPermissions',
  signature = 'signature',
  status = 'status',
}

export type CompanyUsersTableFilter = {
  registeredNumber: string;
  fullName: string;
  status?: UserStateStatusEnum;
  sortBy?: SortingRule;
  subFilter?: CompanyUsersTableSubFilter;
};

export type CompanyUsersTableSubFilter = {
  fullName?: {
    type: 'string';
    query: string;
    strict: boolean;
  };
};

export enum UsersFilterFields {
  status = 'status',
  fullName = 'fullName',
}

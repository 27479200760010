import { GetAccountResponseDto, SWIFTCommissionTypeEnum, SwiftInfoDto, SWIFTUrgencyEnum } from '@app/core/api';
import { SimpleSwiftDefaultValues, SwiftBetweenMyAccountFormData, SwiftFormData } from '@app/pages/swift/types';

export const makeSwiftData = (
  data: SwiftBetweenMyAccountFormData,
  documentNumber: string,
  swiftInfo: SwiftInfoDto,
  address: string,
  name: string,
  defaultValues?: SimpleSwiftDefaultValues
): SwiftFormData => {
  const {
    account: { accountNumber: recipientAccount },
  }: GetAccountResponseDto = JSON.parse(data.recipientAccount);

  return {
    documentNumber,
    documentDate: data.documentDate,
    documentValueDate: data.documentValueDate,
    senderAccount: data.senderAccount,
    senderName: defaultValues?.senderName || name,
    senderAddress: defaultValues?.senderAddress || address,
    recipientName: defaultValues?.recipientName || name,
    recipientAddress: defaultValues?.recipientAddress || address,
    recipientAccount: recipientAccount,
    recipientCountry: { numeric: '804', alpha2: '804', alpha3: '804', name: 'Ukraine' },
    recipientSwiftBic: defaultValues?.recipientSwiftBic || swiftInfo.swiftBic,
    recipientBank: defaultValues?.recipientBankName || swiftInfo.swiftBankName,
    amount: data.amount,
    commissionType: SWIFTCommissionTypeEnum.Our,
    urgency: SWIFTUrgencyEnum.Standard,
    paymentReference: data.paymentReference,
    correspondentBankCheck: false,
    operationCode: '',
  };
};

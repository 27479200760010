export const styles = {
  root: {
    backgroundColor: '#F3F9FF',
    boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
    padding: '0 20px',
  },
  expendRow: {
    height: '48px',
  },
  rowConfig: {
    paddingTop: '8px',
    paddingBottom: '8px',
    backgroundImage: `linear-gradient(to right, #d9dce0 100%, transparent 50%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '12px 1px',
    backgroundRepeat: 'repeat-x',
  },
  row: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  chevronIcon: {
    color: 'colors.gray200',
  },
  title: {
    pr: '14px',
    fontWeight: 600,
    color: 'colors.gray300',
  },
  titleData: {
    pr: '14px',
    fontWeight: 600,
    color: 'colors.gray600',
  },
  submitBtn: {
    marginLeft: '32px',
  },
  radioLabel: {
    fontWeight: 600,
    verticalAlign: 'top',
    paddingTop: '4px',
  },
  radioContainer: {
    justifyContent: 'space-between',
  },
};

import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DirectoriesMenu } from '@app/pages/directories/components/DirectoriesMenu';
import { DirectoriesMenuFilter } from '@app/pages/directories/components/DirectoriesMenu/types';

export const DirectoriesPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [currentFilter, setCurrentFilter] = useState<DirectoriesMenuFilter>(
    (searchParams.get('tab') as DirectoriesMenuFilter) || DirectoriesMenuFilter.Banking
  );

  const menuItems: MenuItem<DirectoriesMenuFilter>[] = [
    {
      id: 1,
      value: DirectoriesMenuFilter.Banking,
      labelText: t('banking'),
    },
    {
      id: 2,
      value: DirectoriesMenuFilter.Own,
      labelText: t('own'),
    },
  ];

  const onFilterChangeHandler = (filter: DirectoriesMenuFilter) => {
    setSearchParams(`tab=${filter}`);
    setCurrentFilter(filter);
  };

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('directories')} useDesktopStyle />
        <PageContainer>
          <Grid container direction="column" justifyContent="space-between">
            <Grid container direction="column">
              <Box mt={4}>
                <HorizontalMenu<DirectoriesMenuFilter>
                  onChange={onFilterChangeHandler}
                  defaultActiveItem={currentFilter}
                  menuItems={menuItems}
                />
              </Box>
              <DirectoriesMenu filter={currentFilter} />
            </Grid>
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: '40px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  cell_actions: {
    maxWidth: '40px',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
  activeSearchAlign: {
    display: 'flex',
    flexDirection: 'initial',
  },
};

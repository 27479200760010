import React from 'react';

import { Box, Button, FormControlLabel, OutlinedInput, SvgIcon, Typography, useTheme } from '@mui/material';
import { AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import useAutocomplete from '@mui/material/useAutocomplete';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIconSvg } from '@app/themes/default/assets/icons/search.svg';

import { AccountMenuOption } from './AccountOptionItem';
import { GroupHeader } from './GroupHeader';
import { AccountSelectType } from '../../../types';
import { Checkbox } from '../../Checkbox';
import { useStyles } from '../styles';

export type AccountsSelectContentProps = {
  name: string;
  options: AccountSelectType[];
  allActiveSelected: boolean;
  selectedValues: AccountSelectType[];
  onChange(newValue: AccountSelectType[]): void;
  onResetHandler(): void;
  onSelectActiveAll(event: React.ChangeEvent<{}>, checked: boolean): void;
};

export const AccountsSelectContent = React.forwardRef(
  (
    {
      name,
      options,
      selectedValues,
      allActiveSelected,
      onChange,
      onResetHandler,
      onSelectActiveAll,
    }: AccountsSelectContentProps,
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const groupByRule = (account: AccountSelectType): string => {
      return account.account?.status;
    };

    const renderGroup = (params: AutocompleteRenderGroupParams) => [
      <GroupHeader key={params.key} params={params} />,
      params.children,
    ];

    const filterOptions = createFilterOptions<AccountSelectType>({
      stringify: (account) => account.account.accountName + account.account.accountNumber,
    });

    const classes = useStyles(theme);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, popupOpen, inputValue, groupedOptions } =
      useAutocomplete<AccountSelectType, true, true, false>({
        componentName: name,
        options,
        groupBy: groupByRule,
        filterOptions: (accounts: AccountSelectType[], params): AccountSelectType[] => {
          if (accounts.length > 0) {
            const filtered = filterOptions(accounts, params);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [{ selectAll: 'selectAll' }, ...filtered];
          }
          if (accounts.length === 0) {
            const filtered = filterOptions(accounts, params);
            return [...filtered];
          }
          return [];
        },
        value: selectedValues,
        open: true,
        multiple: true,
        disableClearable: true,
        disableCloseOnSelect: true,
        onChange: (event, newValue) => {
          onChange([...newValue]);
        },
        isOptionEqualToValue: (account1, account2) => {
          return account1.account?.id === account2.account?.id;
        },
        getOptionLabel: (account) => {
          return JSON.stringify(account);
        },
      });

    const renderListOption = (option: AccountSelectType, index: number) => {
      const optionProps = getOptionProps({ option, index });

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Box {...optionProps} sx={classes.option}>
          {AccountMenuOption(option, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selected: optionProps['aria-selected'],
            inputValue,
          })}
        </Box>
      );
    };

    const SelectAllOption: React.FC = () => {
      return (
        <FormControlLabel
          sx={classes.selectAllFormControlLabel}
          control={<Checkbox name={name} />}
          label={<Typography variant="caption">{t('accountsSelect_select_all_active_accounts')}</Typography>}
          checked={allActiveSelected}
          onChange={onSelectActiveAll}
        />
      );
    };

    const resetButtonDisabled = !(selectedValues && selectedValues.length);

    return (
      <>
        {popupOpen ? (
          <Box sx={classes.selectContentRoot}>
            <Box {...getRootProps()} sx={classes.inputContainer}>
              <OutlinedInput
                autoFocus
                fullWidth
                inputProps={{ ...getInputProps() }}
                endAdornment={
                  <Box sx={classes.endAdornment}>
                    <SvgIcon viewBox="0 0 16 16" sx={classes.searchIcon}>
                      <SearchIconSvg />
                    </SvgIcon>
                  </Box>
                }
              />
              <Box sx={classes.selectPanel}>
                <Typography variant="caption" component="p" sx={classes.selectedCountLabel}>
                  {`${t('accountsSelect_selected')} `}
                  <Typography variant="caption" component="span" sx={classes.selectedCount}>
                    {selectedValues ? selectedValues.length : 0}
                  </Typography>
                </Typography>
                <Button
                  color="primary"
                  size="small"
                  sx={classes.resetButton}
                  disabled={resetButtonDisabled}
                  onClick={onResetHandler}
                >
                  {t('accountsSelect_clear_all')}
                </Button>
              </Box>
            </Box>
            {groupedOptions.length > 0 ? (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Box {...getListboxProps()} sx={classes.listbox}>
                {groupedOptions.map((option: any, index) =>
                  renderGroup({
                    key: option.key,
                    group: option.group,
                    children: option.options.map((option2: AccountSelectType, index2: number) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (option2.selectAll) {
                        return <SelectAllOption key={`select_all_${index2.toString()}`} />;
                      }
                      return renderListOption(option2, option.index + index2);
                    }),
                  })
                )}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </>
    );
  }
);

import React, { useCallback, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CsvIcon, PdfIcon, PrintIcon, XlsIcon, XmlIcon } from '@app/common/icons';
import { ApiError, FrontLanguageEnum, GetArchiveBankRequestDto, RatesByCurrency } from '@app/core/api';
import { SortingRule, SortOrder } from '@app/core/types';
import { BankCurrencyTable } from '@app/pages/exchangeRate/components/BankRates/components/BankCurrencyTable';
import { BankRatesFilter } from '@app/pages/exchangeRate/components/BankRates/components/BankRatesFilter';
import { bankRateQueryFn } from '@app/pages/exchangeRate/query';
import { ExchangeSource, BunkRateSubmit, BankCurrencyFilter } from '@app/pages/exchangeRate/types';
import { appConfig } from '@app/src/config';
import { styles } from '@app/src/pages/exchangeRate/PageContent/styles';
import { bankRatesSchema } from '@app/src/pages/exchangeRate/validationSchema';

interface BankRatesProps {
  bankBranch: string;
  setBankBranchError(error: boolean): void;
}

export const BankRates: React.FC<BankRatesProps> = ({ bankBranch, setBankBranchError }) => {
  const { t, i18n } = useTranslation();
  const [currentBank, setCurrentBank] = useState<ExchangeSource>(ExchangeSource.nbu);
  const methods = useForm<BunkRateSubmit>({
    resolver: yupResolver(bankRatesSchema(t)),
  });
  const { reset: formReset, getValues, trigger } = methods;
  const sortRuleRef = useRef<SortingRule | undefined>();

  const {
    data,
    mutate,
    reset: mutationReset,
  } = useMutation<Array<RatesByCurrency>, ApiError, GetArchiveBankRequestDto>(bankRateQueryFn);

  const submitFilter = useCallback(
    (formData: BankCurrencyFilter) => {
      mutate({
        bankBranchId: formData.bankBranch,
        currencyCodes: formData.currencies,
        language: i18n.language as FrontLanguageEnum,
      });
    },
    [i18n.language, mutate]
  );

  const handleFilterChange = useCallback(() => {
    const fieldsData = getValues();
    const currencies = fieldsData.exchangeCurrencyList.map((currency) => {
      return currency.alpha3;
    });
    const submitData = {
      currencies,
      bankName: currentBank,
      bankBranch: bankBranch,
    };
    submitFilter(submitData);
  }, [bankBranch, currentBank, getValues, submitFilter]);

  const onSubmit = () => {
    trigger().then((valid: boolean) => {
      if (valid) {
        handleFilterChange();
      }
    });
  };

  const resetFormHandler = () => {
    formReset({
      exchangeCurrencyList: [],
    });
    mutationReset();
  };

  const onSortHandler = (rule: SortingRule) => {
    sortRuleRef.current = rule;
    handleFilterChange();
  };

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid container direction="column">
        <FormProvider {...methods}>
          <form>
            <BankRatesFilter onSubmit={onSubmit} resetFormHandler={resetFormHandler} bankBranch={bankBranch} />
          </form>
        </FormProvider>
        {data ? (
          <>
            {appConfig.enableCurrencyOperationsDownloadButtons ? (
              <Grid container justifyContent="space-between">
                <Grid item sx={styles.actionTableButtons}>
                  <IconButton size="small">
                    <CsvIcon />
                  </IconButton>
                  <IconButton size="small">
                    <PdfIcon />
                  </IconButton>
                  <IconButton size="small">
                    <XlsIcon />
                  </IconButton>
                  <IconButton size="small">
                    <XmlIcon />
                  </IconButton>
                  <IconButton size="small">
                    <PrintIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null}
            <Box pt={4}>
              <BankCurrencyTable data={data} exchangeSource={currentBank} onSort={onSortHandler} />
            </Box>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

import { useMutation, UseMutationResult } from 'react-query';

import {
  ApiError,
  dictionariesApiClient,
  GetInternationalBankResponseDto,
  GetInternationalBanksDto,
} from '@app/core/api';

export const useGetInternationalBanksList = (): UseMutationResult<
  GetInternationalBankResponseDto,
  ApiError,
  GetInternationalBanksDto
> =>
  useMutation(async (filter: GetInternationalBanksDto) => {
    const response = await dictionariesApiClient.dictionariesControllerGetInternationalBanksList({ ...filter });

    if (response.status !== 200) {
      throw new ApiError(response.request.status, 'Error cant SWIFT banks!');
    }

    return response.data;
  });

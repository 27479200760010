import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { CounterpartyResponseDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { Loader } from '@app/core/components/Loader';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/counterparties/components/CounterpartiesTable/components/columns';
import { styles } from '@app/pages/counterparties/components/CounterpartiesTable/components/styles';
import { CounterpartiesColumns } from '@app/pages/counterparties/types';

export interface CounterpartiesTableProps {
  counterparties: CounterpartyResponseDto[];
  pagination?: TablePaginationType;
  isLoading: boolean;
  onPaginationChange(pagination: PaginationChangeType): void;
  handleShow(id: number): void;
  deleteCounterpartyModal(id: number): void;
  onSort?(rule: SortingRule): void;
}

export const CounterpartiesTable: React.FC<CounterpartiesTableProps> = ({
  counterparties,
  handleShow,
  deleteCounterpartyModal,
  pagination,
  isLoading,
  onPaginationChange,
  onSort,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();

  const data = useMemo(() => {
    return counterparties.map((column) => {
      return {
        ...column,
      };
    });
  }, [counterparties]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        handleShow,
        deleteCounterpartyModal,
      }),
    ];
  }, [deleteCounterpartyModal, handleShow, t]);

  const table = useReactTable<CounterpartiesColumns>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: counterparties.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (isLoading) {
      return <Box sx={styles.emptyRow}>{isLoading ? <Loader /> : null}</Box>;
    }

    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<CounterpartiesColumns> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);

  return (
    <Box mt={7}>
      <ComplexTable
        head={{
          content: <TableHeadContent<CounterpartiesColumns> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </Box>
  );
};

import React from 'react';

import { styled } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';

export const MenuItemIcon = styled(ListItemIcon)({
  minWidth: '24px',
  paddingLeft: '12px',
  paddingRight: '28px',
});

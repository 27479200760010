import React, { useRef, useState } from 'react';

import { Grid, Typography, Theme, SxProps } from '@mui/material';

import { MenuItem } from './types';
import { ConfirmPopup } from '../ConfirmPopup';

export * from './types';

export interface HorizontalMenuProps<T> {
  menuItems: MenuItem<T>[];
  defaultActiveItem?: T;
  styles?: {
    root?: SxProps<Theme>;
    item?: SxProps<Theme>;
    itemSelected?: SxProps<Theme>;
    itemDefault?: SxProps<Theme>;
  };
  showConfirmPopup?: boolean;
  confirmPopupMessage?: string;
  onChange?(activeTab: T): void;
}

export const HorizontalMenu = <T,>({
  menuItems,
  defaultActiveItem,
  showConfirmPopup = false,
  onChange = () => {},
  confirmPopupMessage,
  styles,
}: HorizontalMenuProps<T>): JSX.Element => {
  const [activeValue, setActiveValue] = useState<T>(defaultActiveItem || menuItems[0].value);
  const [confirmPopupShow, setConfirmPopupShow] = useState<boolean>(false);
  const valueRef = useRef<T | null>(null);

  const handleChange = (newValue: T): void => {
    if (showConfirmPopup) {
      valueRef.current = newValue;
      setConfirmPopupShow(true);
    } else {
      setActiveValue(newValue);
      onChange(newValue);
    }
  };

  const onConfirmLeaveHandler = () => {
    if (valueRef.current) {
      setConfirmPopupShow(false);
      setActiveValue(valueRef.current);
      onChange(valueRef.current);
      valueRef.current = null;
    }
  };

  const onCancelLeaveHandler = () => {
    setConfirmPopupShow(false);
    valueRef.current = null;
  };

  const activeStyle: SxProps<Theme> = styles?.itemSelected
    ? styles?.itemSelected
    : {
        color: 'colors.primary600',
        fontWeight: 600,
      };

  const defaultStyle = styles?.itemDefault
    ? styles?.itemDefault
    : {
        color: 'colors.gray400',
        fontWeight: 600,
      };

  return (
    <>
      <Grid
        sx={{
          overflowX: 'auto',
          display: 'flex',
          alignItems: 'center',
          ...styles?.root,
        }}
      >
        {menuItems.map((item, i) => {
          return (
            <Typography
              key={item.id}
              sx={{
                userSelect: 'none',
                cursor: 'pointer',
                marginRight: '40px',
                ...(activeValue === item.value ? activeStyle : defaultStyle),
              }}
              variant={activeValue === item.value ? 'h6' : 'body1'}
              component="span"
              onClick={() => handleChange(item.value)}
            >
              {item.labelText}
            </Typography>
          );
        })}
      </Grid>

      {confirmPopupShow ? (
        <ConfirmPopup
          open
          onConfirm={onConfirmLeaveHandler}
          onClose={onCancelLeaveHandler}
          questionText={confirmPopupMessage || ''}
        />
      ) : null}
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  CurrencySystemInfoDto,
  GetClientProfileResponseDto,
  SwiftInfoDto,
  UserProfileDto,
  UserResourceDto,
  accountsApiClient,
  getClientId,
  profileApiClient,
  ClientConfigurationDto,
  AccountStatementConfigDtoColumnTypeEnum,
} from '@app/core/api';
import { defaultPermissions } from '@app/core/constants';
import { TableColumnConfig, AccountSelectType, FetchStatus } from '@app/core/types';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { serviceConfig } from '@app/src/config';
import { LogTagName } from '@app/src/logger/types';

export interface ProfileState {
  appVersion: string;
  defaultLanguage: string;
  currencyRate?: CurrencySystemInfoDto;
  userProfile?: UserProfileDto;
  clientProfile?: GetClientProfileResponseDto;
  clientConfig?: ClientConfigurationDto;
  fetchStatus: FetchStatus;
  roleId?: number;
  permissions: UserResourceDto;
  statementsTableConfig: Array<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>>;
  operationsTableConfig: Array<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>>;
  systemDate: string;
  logo: string;
  allAccounts: AccountSelectType[];
  uahAccounts: AccountSelectType[];
  swiftInfo: SwiftInfoDto;
  unreadMessageCounter: number;
}

const initialState: ProfileState = {
  appVersion: '',
  defaultLanguage: serviceConfig.defaultLanguage,
  fetchStatus: FetchStatus.idle,
  statementsTableConfig: [],
  operationsTableConfig: [],
  systemDate: '',
  logo: '',
  allAccounts: [],
  uahAccounts: [],
  permissions: defaultPermissions,
  swiftInfo: { swiftBankName: '', swiftBic: '', swiftBICAddress: '' },
  unreadMessageCounter: 0,
};

export const updateProfile = createAsyncThunk('profile/fetchData', async () => {
  const response = await profileApiClient.profileControllerGetSystemInfo(getClientId());
  return response.data;
});

export const getAllAccounts = createAsyncThunk('allAccounts/fetchData', async (): Promise<AccountSelectType[]> => {
  const response = await accountsApiClient.accountsControllerGetDashboardAccounts(getClientId());

  return response.data;
});

export const getCurrentUahAccounts = createAsyncThunk(
  'currentUahAccounts/fetchData',
  async (): Promise<AccountSelectType[]> => {
    const response = await accountsApiClient.accountsControllerGetAccountsByCurrency(getClientId(), 'UAH');
    return response.data.accounts;
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateStatementsTableConfig: (
      state,
      action: PayloadAction<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]>
    ) => {
      state.statementsTableConfig = action.payload;
    },
    updateOperationsTableConfig: (
      state,
      action: PayloadAction<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]>
    ) => {
      state.operationsTableConfig = action.payload;
    },
    updateUserLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    updateUnreadMessagesCounter: (state, action: PayloadAction<number>) => {
      state.unreadMessageCounter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.fetchStatus = FetchStatus.loading;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (action.payload) {
          state.fetchStatus = FetchStatus.success;
          state.defaultLanguage = action.payload.defaultLanguage;
          state.appVersion = action.payload.appVersion;
          state.userProfile = action.payload.userProfile;
          state.clientConfig = action.payload.clientConfig;
          state.clientProfile = action.payload.clientProfile;
          state.roleId = action.payload.roleId;
          state.statementsTableConfig = action.payload.clientConfig.accountStatements.config;
          state.operationsTableConfig = action.payload.clientConfig.UAHOperations.config;
          state.systemDate = formatISODateString(action.payload.systemDate, DATE_FORMAT);
          state.permissions = action.payload.userRole.resources;
          state.swiftInfo = action.payload.swiftInfo;
          state.unreadMessageCounter = action.payload.unreadMessageCounter;
          const userId = action.payload.userProfile.userId;
          const clientId = getClientId();
          Sentry.configureScope((scope) => {
            scope.setTags({
              [LogTagName.userId]: userId,
              [LogTagName.clientId]: clientId,
            });
            scope.setContext('sessionInfo', {
              userId,
              clientId,
            });
          });
        }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.fetchStatus = FetchStatus.failed;
      })
      .addCase(getAllAccounts.pending, (state) => {
        state.fetchStatus = FetchStatus.loading;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.fetchStatus = FetchStatus.success;
        if (action.payload) {
          state.allAccounts = action.payload;
        }
      })
      .addCase(getAllAccounts.rejected, (state) => {
        state.fetchStatus = FetchStatus.failed;
      })
      .addCase(getCurrentUahAccounts.pending, (state) => {
        state.fetchStatus = FetchStatus.loading;
      })
      .addCase(getCurrentUahAccounts.fulfilled, (state, action) => {
        state.fetchStatus = FetchStatus.success;
        if (action.payload) {
          state.uahAccounts = action.payload;
        }
      })
      .addCase(getCurrentUahAccounts.rejected, (state) => {
        state.fetchStatus = FetchStatus.failed;
      });
  },
});

export const { updateStatementsTableConfig, updateOperationsTableConfig, updateUserLogo, updateUnreadMessagesCounter } =
  profileSlice.actions;
export default profileSlice.reducer;

import React from 'react';

import { Box, Button, Grid, Hidden, Typography, Tooltip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BankIcon, DeleteIcon, EditIcon, MailIcon, SendIcon, SignatureIcon } from '@app/common/icons';
import { MessageType, ExtendedMessageDto } from '@app/core/api';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { getActiveButtonsConfig } from '../../activeButtons';

interface TableToolbarProps {
  selectedMessages: ExtendedMessageDto[];
  messageType: MessageType;
  onSign(): void;
  onSendToSign(): void;
  onDelete(): void;
  onSendToBank(): void;
  onMarkAsRead(): void;
  onEdit(): void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  selectedMessages,
  messageType,
  onSign,
  onSendToSign,
  onDelete,
  onSendToBank,
  onMarkAsRead,
  onEdit,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();

  const activeButtonsConfig = getActiveButtonsConfig(selectedMessages, permissions, messageType);
  return (
    <>
      <Box pb={1} sx={styles.sticky}>
        {selectedMessages?.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
          >
            <Grid item container alignItems="center" justifyContent="flex-start" wrap="nowrap">
              <Typography variant="body2" sx={styles.selectedCount}>
                {selectedMessages.length} {t('selected')}
              </Typography>
              <Box sx={styles.divider} />
              {activeButtonsConfig.sign ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onSign} startIcon={<SignatureIcon />}>
                    <Hidden lgDown>
                      <Typography>{t('sign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sendToSign ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onSendToSign} startIcon={<SendIcon />}>
                    <Hidden lgDown>
                      <Typography>{t('toSign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sendToBank ? (
                <>
                  <Tooltip color="primary" title={t('send_to_bank')} arrow placement="top">
                    <IconButton sx={styles.buttonRoot} onClick={onSendToBank}>
                      <BankIcon />
                    </IconButton>
                  </Tooltip>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.markAsRead ? (
                <>
                  <Tooltip color="primary" title={t('markAsRead')} arrow placement="top">
                    <IconButton sx={styles.buttonRoot} onClick={onMarkAsRead}>
                      <MailIcon />
                    </IconButton>
                  </Tooltip>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.edit ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onEdit} startIcon={<EditIcon />}>
                    <Typography>{t('change')}</Typography>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.delete ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onDelete} startIcon={<DeleteIcon />}></Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </>
  );
};

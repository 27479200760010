import React, { useEffect, useState } from 'react';

import { ChipProps } from '@mui/material/Chip/Chip';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

import { ACCOUNT_STATUS_ORDER } from '../../../../api/constants';
import { AccountSelectType } from '../../../../types';
import { AccountsWithSearchSelect } from '../../../AccountsWithSearchSelect';
import { Select, SelectProps } from '../../../Select';

export type AccountsWithSearchSelectFieldProps = {
  name: string;
  required?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultSelectedAccountsId?: Array<string | null> | string | null;
  selectProps?: SelectProps;
  chipProps?: ChipProps;
  accounts: AccountSelectType[];
  onReady?(): void;
};

export const AccountsWithSearchSelectField: React.FC<AccountsWithSearchSelectFieldProps> = ({
  name,
  rules,
  defaultSelectedAccountsId,
  selectProps,
  chipProps,
  accounts,
  onReady,
}) => {
  const { control, setValue } = useFormContext();
  const [options, setOptions] = useState<AccountSelectType[]>([]);

  useEffect(() => {
    if (accounts) {
      if (defaultSelectedAccountsId && defaultSelectedAccountsId.length) {
        const selectedAccounts: AccountSelectType[] = accounts.filter((account) =>
          defaultSelectedAccountsId.includes(account.account.id)
        );
        setValue(name, selectedAccounts);
      }
      setOptions(
        [...accounts].sort((a, b) => {
          if (ACCOUNT_STATUS_ORDER[a.account.status] > ACCOUNT_STATUS_ORDER[b.account.status]) {
            return 1;
          }
          if (ACCOUNT_STATUS_ORDER[a.account.status] < ACCOUNT_STATUS_ORDER[b.account.status]) {
            return -1;
          }
          return 0;
        })
      );
      if (onReady) {
        onReady();
      }
    }
  }, [defaultSelectedAccountsId, setValue, name, onReady, accounts]);

  return (
    <>
      {options.length ? (
        <Controller
          name={name}
          control={control}
          defaultValue={[]}
          rules={rules}
          render={({ field: { value: currentValue, onChange }, fieldState: { error } }) => (
            <AccountsWithSearchSelect
              name={name}
              onChange={onChange}
              value={currentValue}
              options={options}
              errorText={error?.message}
              selectProps={selectProps}
            />
          )}
        />
      ) : (
        <Select displayEmpty disabled fullWidth {...selectProps} />
      )}
    </>
  );
};

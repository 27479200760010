import { AvailableActionsDto, PaymentOrderTypeEnum, PaymentTemplateTypeEnum } from '@app/core/api';

import { TransactionAccount } from './accounts';
import { DocumentStatus } from './documents';

export enum OperationsTypeEnum {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
}

export type OperationAccount = {
  id: string;
  number: string; // IBAN
  name: string; // Имя счета в системе банка. Например: "Основной счет"
  currency: string;
};

export type Operation = {
  id: number;
  link: string;
  status: DocumentStatus;
  documentNumber: string;
  date?: string; // operation date
  account: OperationAccount;
  paymentReference: string;
  type: OperationsTypeEnum;
  amount: number;
  currency: string;
  countryCode: string;
  nonResidentFlag: boolean;
  details: {
    date: string; // document date
    senderAccount: TransactionAccount; // счет отправителя
    recipientAccount: TransactionAccount;
  };
  signed: boolean;
  uuid: string;
  documentType?: PaymentOrderTypeEnum;
  actions?: AvailableActionsDto;
  templateType: PaymentTemplateTypeEnum;
  arcDate?: string | null;
};

export type OperationGroupType = {
  date: string;
  operations: Operation[];
};

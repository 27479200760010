import React from 'react';

import { CSSObject, Drawer, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { isMobile } from '@app/core/utils';

const drawerWidth = 272;
const drawerClosedWidth = isMobile ? 0 : 80;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerClosedWidth,
});

export const MenuDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open = true }) => ({
    anchor: 'left',
    width: open ? drawerWidth : drawerClosedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '& .MuiDrawer-paperAnchorDockedLeft': {
      backgroundColor: theme.palette.colors.sidebarBackground,
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      overflow: 'hidden',
      '@media (max-width: 959.95px)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  })
);

import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SignatureIcon } from '@app/common/icons';
import { DocumentDto } from '@app/core/api';
import { ReactComponent as SignatureBigSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

import { styles } from './style';
import { Ellipsis } from '../Ellipsis';
import { ModalCommon } from '../Modal';

interface ConfirmSignDocumentsProps {
  onAgreeSign(): void;
  onClose(): void;
  toSignDocuments: DocumentDto[];
  selectedDocumentsFilesCount: number;
}

export const ConfirmSignDocuments: React.FC<ConfirmSignDocumentsProps> = ({
  onAgreeSign,
  onClose,
  toSignDocuments,
  selectedDocumentsFilesCount,
}) => {
  const { t } = useTranslation();

  return (
    <ModalCommon onClose={onClose} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <SignatureBigSVG />
        </Box>
        <Box pb={3} width="100%">
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Typography variant="h6" component="h5" sx={styles.title}>
              {t('documentsToSign')}
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        <Box sx={styles.container}>
          <Box mb={3}>
            <Typography variant="body2" component="span" sx={styles.text}>
              {t('sign_selectedDocuments')}
              {' / '}
              {t('sign_signatureRight')}
              {': '}
            </Typography>
            <Typography variant="body2" component="span" sx={styles.selectedDocumentsCount}>
              {selectedDocumentsFilesCount}
              {' / '}
            </Typography>
            <Typography variant="body2" component="span" sx={styles.toSignDocumentsCount}>
              {toSignDocuments.length}
            </Typography>
          </Box>
          <Box mb={3} sx={styles.fileListContainer}>
            {toSignDocuments.map((file, i) => {
              const name = file.originalName.replace(/\.[^/.]+$/, '');
              const extension = file.originalName.split('.').length > 1 ? `.${file.originalName.split('.').pop()}` : '';
              return (
                <Box key={`${file.originalName}_${i}`} sx={styles.fileRow}>
                  <Typography variant="body2" component="div">
                    <Ellipsis
                      text={
                        <>
                          {i + 1}.{' '}
                          <Typography variant="body2" component="span" sx={styles.fileName}>
                            {name}{' '}
                          </Typography>
                          {extension}
                        </>
                      }
                    />
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box mt={4}>
          <Grid container justifyContent="center" wrap="nowrap">
            <Box pr={6}>
              <Button size="small" variant="outlined" color="primary" onClick={onClose}>
                {t('cancel')}
              </Button>
            </Box>
            <Button size="small" variant="contained" color="primary" endIcon={<SignatureIcon />} onClick={onAgreeSign}>
              {t('sign')}
            </Button>
          </Grid>
        </Box>
      </Box>
    </ModalCommon>
  );
};

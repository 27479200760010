import { object, SchemaOf, string } from 'yup';

import { FormFieldName } from '../../types';

type SchemaValidationObject = {
  [FormFieldName.periodType]: string;
};

type ValidationSchemaProps = {
  t: (key: string) => string;
};

export const validationSchema = ({ t }: ValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [FormFieldName.periodType]: string().required(t('paymentCreationLocalPayment_field_required')),
  });
};

import { useMutation, UseMutationResult } from 'react-query';

import {
  convertApiEnumToLocal,
  GetRecentDocumentsRequestDto,
  PaymentOrderTypeEnum,
  ApiError,
  dashboardApiClient,
  getClientId,
} from '@app/core/api';
import { TablePaginationType, DocumentStatus, Operation, OperationsTypeEnum } from '@app/core/types/';

export type GetRecentDocumentsResult = {
  operations: Array<Operation>;
  pagination: TablePaginationType;
};

export type GetUahOperationsResult = {
  operations: Array<Operation>;
  pagination: TablePaginationType;
};
export const getGetRecentDocumentsFn = async (
  getRecentDocumentsRequestDto: GetRecentDocumentsRequestDto
): Promise<GetUahOperationsResult> => {
  const response = await dashboardApiClient.dashboardControllerGetRecentDocuments(
    getClientId(),
    getRecentDocumentsRequestDto
  );

  if (response.status !== 201) {
    throw new ApiError(response.request.status, "Can't get recent documents.");
  }

  const operations: Array<Operation> = response.data.operations.map((operation) => {
    const { type, status } = operation;
    return {
      ...operation,
      type: convertApiEnumToLocal(OperationsTypeEnum, type),
      status: status as unknown as DocumentStatus,
      documentType: operation.documentType as unknown as PaymentOrderTypeEnum,
    };
  });

  return {
    operations,
    pagination: response.data.pagination,
  };
};

export const useGetGetRecentDocuments = (): UseMutationResult<
  GetRecentDocumentsResult,
  ApiError,
  GetRecentDocumentsRequestDto
> => useMutation<GetRecentDocumentsResult, ApiError, GetRecentDocumentsRequestDto>(getGetRecentDocumentsFn);

import { PaymentOrderTypeEnum, StatementTypeEnum, UserResourceDto } from '@app/core/api';
import { makeTableToolbarActions } from '@app/core/helpers';

import {
  DocumentStatus,
  Operation,
  TableToolbarActiveButtonsConfig,
  TableToolbarEnabledActionsType,
} from '../../../../types';

const MAX_DOCS_TO_SIG = 120;

export const getActiveButtonsConfig = (
  selectedOperations: Operation[],
  permissions: UserResourceDto,
  operationsFileDtoDocumentType: StatementTypeEnum
): TableToolbarActiveButtonsConfig => {
  const docsToSign: Operation[] = selectedOperations.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  );
  const single: boolean = selectedOperations.length === 1;
  const signEnabled: boolean =
    docsToSign.length >= 1 &&
    docsToSign.length <= MAX_DOCS_TO_SIG &&
    selectedOperations.filter((item) => item.actions?.allowToSign).length >= 1 &&
    permissions.sign.paymentOrder.sign;

  const signFromAnotherEnabled: boolean =
    selectedOperations.length >= 1 &&
    selectedOperations.length <= MAX_DOCS_TO_SIG &&
    selectedOperations.filter((item) => item.actions?.allowSignFromAnotherPerson).length >= 1 &&
    permissions.sign.paymentOrder.signFromAnotherPerson;

  const showDeleteButton: boolean =
    selectedOperations.filter((item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved)
      .length >= 1 && permissions.uahOperations.delete;

  const showSendToSignButton: boolean =
    selectedOperations.filter((item) => item.actions?.allowToSendToSign).length >= 1;

  const documentStatus = selectedOperations[0]?.status;

  const hasNonMemorialOrders: boolean =
    selectedOperations.filter((operation) => operation.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag).length >
    0;
  const enabledActions: TableToolbarEnabledActionsType = {
    sign: signEnabled,
    signFromAnother: signFromAnotherEnabled,
    sendToSign: showSendToSignButton,
    edit: false,
    download: hasNonMemorialOrders,
    view: single && permissions.uahOperations.view,
    delete: showDeleteButton,
    cancel:
      single &&
      permissions.uahOperations.cancel &&
      selectedOperations[0].currency === 'UAH' &&
      documentStatus === DocumentStatus.Processing,
    print: operationsFileDtoDocumentType === StatementTypeEnum.Statement && hasNonMemorialOrders,
  };

  if (
    permissions.uahOperations.edit &&
    single &&
    (documentStatus === DocumentStatus.ToSign ||
      documentStatus === DocumentStatus.OnSigning ||
      documentStatus === DocumentStatus.Saved)
  ) {
    enabledActions.edit = true;
  }

  return makeTableToolbarActions(enabledActions);
};

import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { LocalOperationsTable } from '@app/pages/operations/desktop/components/LocalOperationsTable';
import { styles } from '@app/pages/operations/styles';
import { OperationsViewProps } from '@app/pages/operations/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const OperationsViewDesktop: React.FC<OperationsViewProps> = ({ defaultValues }) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('uahTransactions')} useDesktopStyle>
          <Grid item container justifyContent="flex-end" alignItems="center">
            {permissions.uahOperations.import ? (
              <Box mr={2}>
                <Link to={RouteList.payment_import}>
                  <Button variant="outlined" color="primary">
                    {t('importPaymentsButton_text')}
                  </Button>
                </Link>
              </Box>
            ) : null}
            {permissions.uahOperations.create ? (
              <Link to={RouteList.payment_create}>
                <Button variant="contained" color="primary">
                  {t('dashboardCreatePaymentBtn')}
                </Button>
              </Link>
            ) : null}
          </Grid>
        </PageHeader>
        <PageContainer>
          <Grid container direction="column" wrap="nowrap" sx={styles.horizontalWrapper}>
            <LocalOperationsTable defaultValues={defaultValues} />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

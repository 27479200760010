import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  currencyDocumentsApiClient,
  getClientId,
  ApiError,
  DocumentOperationType,
  UploadFileResponseDto,
  DocumentType,
} from '@app/core/api';

const uploadFilesFn = async ({
  file,
  currencyOperationIds,
  documentType,
  operationsTypes,
  comments,
}: {
  file: Array<any>;
  currencyOperationIds?: string[];
  documentType?: DocumentType;
  operationsTypes?: Array<DocumentOperationType>;
  comments?: Array<string>;
}): Promise<Array<UploadFileResponseDto>> => {
  const response = await currencyDocumentsApiClient.documentsControllerUploadFile(
    getClientId(),
    file,
    documentType,
    currencyOperationIds,
    operationsTypes,
    comments
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "documentsControllerUploadFile error. Can't upload files.");
  }

  return response.data;
};

export const useCurrencyUploadFiles = (): UseMutationResult<
  Array<UploadFileResponseDto>,
  ApiError,
  {
    file: any;
    documentType?: DocumentType;
    currencyOperationIds?: string[];
    operationsTypes?: Array<DocumentOperationType>;
    comments?: Array<string>;
  }
> =>
  useMutation<
    Array<UploadFileResponseDto>,
    ApiError,
    {
      file: any;
      documentType?: DocumentType;
      currencyOperationIds?: string[];
      operationsTypes?: Array<DocumentOperationType>;
      comments?: Array<string>;
    }
  >(uploadFilesFn);

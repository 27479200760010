export const styles = {
  documentBlock: {
    display: 'flex',
    marginRight: '4px',
  },
  title: {
    fontSize: '1rem',
    color: 'colors.primary700',
    marginRight: '4px',
    fontWeight: 900,
  },
  editorButton: {
    marginLeft: '4px',
    marginTop: '8px',
    padding: '4px',
  },
  editorIconDone: {
    color: 'colors.success',
  },
  editorIconCancel: {
    color: 'colors.gray200',
  },
  documentNumberWrapper: {
    position: 'relative',
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  documentNumber: {
    display: 'inline-block',
    color: 'colors.primary500',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: `dashed 1px`,
    borderColor: 'colors.primary500',
    cursor: 'pointer',
  },
};

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUIState } from '@app/context/UIContext';
import { SignatureStrategyEnum } from '@app/core/components';
import { SignaturePopup } from '@app/core/components/SignaturePopup';

import { MutationStatus } from '../../types';
import { DocumentSigned } from '../SignatureModal';
import { useMultiPaymentOrderSign } from '../SignPaymentOrderMultiple/useMultiPaymentOrderSign';
import { StatusPopup } from '../StatusPopup';

export interface SignPaymentOrderProps {
  uuid: string;
  documentToSign: string;
  signFromAnotherPerson?: boolean;
  onSuccessPopupCancel(): void;
  onClose(status: MutationStatus): void;
}

export const SignPaymentOrder: React.FC<SignPaymentOrderProps> = ({
  uuid,
  documentToSign,
  signFromAnotherPerson = false,
  onSuccessPopupCancel,
  onClose,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const [documentSigned, setDocumentSigned] = useState<DocumentSigned>();
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);
  const { data: paymentOrderSignResultData, mutate, status, isLoading } = useMultiPaymentOrderSign();

  useEffect(() => {
    showLoader(isLoading);
  }, [showLoader, isLoading]);

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[], signatureBelongsToUser?: number) => {
    setDocumentSigned(signedDocuments[0]);
    mutate({ operations: [signedDocuments[0]], signatureBelongsToUser });
  };

  const onCloseHandler = () => {
    onClose(status);
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    if (onSuccessPopupCancel) {
      onSuccessPopupCancel();
      return;
    }
    navigate(-1);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSignModal(false);
    if (documentSigned) {
      mutate({ operations: [documentSigned] });
    }
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  useEffect(() => {
    if (paymentOrderSignResultData) {
      if (paymentOrderSignResultData.unsigned?.length) {
        setShowErrorSignModal(true);
      } else {
        setShowSuccessSignModal(true);
      }
    }
  }, [paymentOrderSignResultData, t]);

  return (
    <>
      <SignaturePopup
        documentsToSign={[{ uuid, documentToSign }]}
        signFromAnotherPerson={signFromAnotherPerson}
        type={SignatureStrategyEnum.PaymentOrder}
        onSignedStringResult={onSignedStringResultHandler}
        onClose={onCloseHandler}
      />

      <StatusPopup
        show={showSuccessSignModal}
        title={t('payment_sign_success_message')}
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={t('payment_sign_error_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

import { currencyDocumentsApiClient, getClientId, ApiError, GetDocumentsResponseDto } from '@app/core/api';
import { PaginationChangeType } from '@app/core/types';

interface GetCurrencyDocumentsQueryFnProps {
  pagination: PaginationChangeType;
}

export const getCurrencyDocumentsQueryFn = async ({
  pagination,
}: GetCurrencyDocumentsQueryFnProps): Promise<GetDocumentsResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerGetDocuments(getClientId(), {
    pagination,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "currencyDocumentsControllerGetFiles error. Can't get documents list.");
  }

  return response.data;
};

import { CurrencyEnum, GetAccountResponseDto, UpdateDocumentRequestDto } from '@app/core/api';
import { PaymentFormData } from '@app/pages/payment/components/LocalPaymentEditForm/types';

type EditPaymentData = {
  uuid: string;
  paymentData: PaymentFormData;
};

export const makeEditPaymentSubmitData = ({ uuid, paymentData }: EditPaymentData): UpdateDocumentRequestDto => {
  const {
    paymentDocumentNumber,
    documentDate,
    documentValuationDate,
    amount,
    recipientLei,
    recipientName,
    senderAccount: senderAccountInfo,
    recipientAccount,
    purpose: paymentReference,
    nonResidentFlag,
    countryCode,
    recipientNonLeiFlag,
    recipientPassport,
    realSenderName,
    realSenderTIN,
    realSenderNonResidentFlag,
    realSenderCountryCode,
    realSenderNonLeiFlag = false,
    realSenderPassport,
    realRecipientName,
    realRecipientTIN,
    realRecipientNonResidentFlag,
    realRecipientCountryCode,
    realRecipientNonLeiFlag = false,
    realRecipientPassport,
    realSenderPresent,
    realRecipientPresent,
  } = paymentData;

  const {
    account: { accountNumber: senderAccount },
  }: GetAccountResponseDto = JSON.parse(senderAccountInfo);

  return {
    uuid,
    order: {
      paymentDocumentNumber,
      paymentDocumentDate: documentDate.toISOString(),
      paymentDocumentValueDate: documentValuationDate ? documentValuationDate.toISOString() : undefined,
      amount: parseFloat(amount),
      senderAccount,
      recipientName,
      recipientLei: recipientNonLeiFlag ? undefined : recipientLei,
      recipientAccount,
      paymentReference,
      nonResidentFlag,
      currency: CurrencyEnum.Uah,
      countryCode: countryCode?.numeric,
      recipientNonLeiFlag,
      realSenderNonLeiFlag,
      realRecipientNonLeiFlag,
      recipientPassport: recipientNonLeiFlag ? recipientPassport : undefined,
      ...(realSenderPresent && {
        realSenderName,
        realSenderTIN: realSenderNonLeiFlag ? undefined : realSenderTIN,
        realSenderNonResidentFlag,
        realSenderCountryCode: realSenderCountryCode?.numeric,
        realSenderPassport: realSenderNonLeiFlag ? realSenderPassport : undefined,
      }),
      ...(realRecipientPresent && {
        realRecipientName,
        realRecipientTIN: realRecipientNonLeiFlag ? undefined : realRecipientTIN,
        realRecipientNonResidentFlag,
        realRecipientCountryCode: realRecipientCountryCode?.numeric,
        realRecipientPassport: realRecipientNonLeiFlag ? realRecipientPassport : undefined,
      }),
    },
  };
};

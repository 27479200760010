import React, { useEffect, useRef } from 'react';

import { Grid } from '@mui/material';
import { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow/TableRow';

import { TableRow } from './TableRow';

export interface ExpandedTableRowProps extends MuiTableRowProps<typeof Grid> {
  extendedContend: React.ReactNode;
  open: boolean;
  onExpand?(): void;
}

export const ExpandedTableRow: React.FC<ExpandedTableRowProps> = ({
  children,
  extendedContend,
  open,
  onExpand,
  ...otherProps
}) => {
  const handleRowClick = () => {
    if (onExpand) {
      onExpand();
    }
  };

  const listItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listItemRef.current && open) {
      listItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [open]);

  return (
    <>
      <TableRow onClick={handleRowClick} selected={open} ref={listItemRef} {...otherProps}>
        {children}
      </TableRow>
      {open ? <>{extendedContend}</> : null}
    </>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, CardContent, FormLabel, Grid, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { InfoIcon, StarIcon } from '@app/common/icons';
import { GetAccountResponseDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { SwitchField } from '@app/core/components/Form/controls/SwitchField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { PassportTabs, PassportTabsEnum } from '@app/core/components/PassportTabs';
import { Tooltip } from '@app/core/components/Tooltip';
import { Lei } from '@app/pages/payment/components/Lei';
import { PassportField } from '@app/pages/payment/components/PassportField';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { RecipientNameSelect } from '@app/pages/payment/components/RecipientNameSelect';
import { LeaveTemplatePopup } from '@app/pages/paymentsTemplates/components/LeaveTemplatePopup';
import { SenderAccountSelect } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/components/SenderAccountSelect';
import { senderAccountsQueryFn } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/query';
import { styles } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/styles';
import {
  FormFieldName,
  TemplateFormData,
  TemplateDefaultValues,
  FormFieldErrorsName,
} from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';
import {
  makeValidationSchemaConfig,
  validationSchema,
} from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/validationSchema';
import { appConfig } from '@app/src/config';

export interface LocalPaymentTemplateFormProps {
  defaultValues: TemplateDefaultValues;
  errorFields?: { fieldName: FormFieldErrorsName; errorMessage: string }[];
  onSaveClick(templateData: TemplateFormData, templateType: PaymentTemplateTypeEnum): void;
  onCancelHandler(): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const LocalPaymentTemplateForm: React.FC<LocalPaymentTemplateFormProps> = ({
  defaultValues,
  errorFields,
  onCancelHandler,
  onSaveClick,
  onChange,
}) => {
  const { t } = useTranslation();
  const [passportActiveTab, setPassportActiveTab] = useState<PassportTabsEnum>(PassportTabsEnum.passport);
  const [recipientNonLeiFlag, setRecipientNonLeiFlag] = useState<boolean>(defaultValues.recipientNonLeiFlag);
  const [vatEnabled, setVatEnabled] = useState<boolean>(false);
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [validationsFormFields, setValidationsFormFields] = useState<FormFieldName[]>([
    FormFieldName.templateName,
    FormFieldName.recipientAccount,
    FormFieldName.recipientLei,
    FormFieldName.recipientName,
    FormFieldName.amount,
    FormFieldName.senderAccount,
  ]);

  const previousAmountInfoRef = useRef<string>('');
  const previousVatInfoRef = useRef<string>('');
  const { data, refetch } = useQuery<GetAccountResponseDto[]>('senderAccounts', senderAccountsQueryFn, {
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const methods = useForm<TemplateFormData>({
    resolver: yupResolver(validationSchema({ t, validationsFormFields })),
    mode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });

  const { control, setValue, trigger, getValues, formState, setError } = methods;

  const { isDirty } = formState;

  const vat = useWatch<TemplateFormData>({
    control,
    name: FormFieldName.vat,
    defaultValue: 20,
  });
  const amount = useWatch<TemplateFormData>({
    control,
    name: FormFieldName.amount,
    defaultValue: 0,
  });
  const vatAmount = (Number(amount) / (100 + Number(vat))) * Number(vat);
  const fixedVatAmount = vatAmount.toFixed(2);

  useEffect(() => {
    const amountInfo = t('paymentCreationLocalPayment_purpose_value', { amount });
    const purposeVat = t('paymentCreationLocalPayment_purpose_vat', {
      vat,
      vatAmount: fixedVatAmount,
    });
    const vatInfo = vatEnabled ? purposeVat : '';
    const currentPurpose: string = getValues(FormFieldName.purpose);
    const purpose: string = currentPurpose
      .replace(previousAmountInfoRef.current, '')
      .replace(previousVatInfoRef.current, '');

    previousAmountInfoRef.current = amountInfo;
    previousVatInfoRef.current = vatInfo;
    setValue(`${FormFieldName.purpose}`, purpose + amountInfo + vatInfo);
  }, [amount, fixedVatAmount, getValues, setValue, t, vat, vatEnabled]);

  const watchRecipientAccount = useWatch({
    control,
    name: FormFieldName.recipientAccount,
  });

  useEffect(() => {
    if (watchRecipientAccount) {
      setValue(`${FormFieldName.recipientAccount}`, watchRecipientAccount);
    }
  }, [setValue, watchRecipientAccount]);

  useEffect(() => {
    if (errorFields?.length) {
      errorFields.forEach((error) => {
        setError(
          error.fieldName,
          {
            type: 'manual',
            message: error.errorMessage,
          },
          {
            shouldFocus: true,
          }
        );
      });
    }
  }, [errorFields, setError, t]);

  useEffect(() => {
    onChange({ formDirty: isDirty });
  }, [isDirty, onChange]);

  const passportTabsChangeHandler = (activeTab: PassportTabsEnum) => {
    setPassportActiveTab(activeTab);
  };

  const validateAndSaveTemplate = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveClick(getValues(), PaymentTemplateTypeEnum.General);
      }
    });
  };

  const vatSwitchHandler = () => {
    setVatEnabled(!vatEnabled);
  };

  const onSaveTemplate = () => {
    validateAndSaveTemplate();
  };
  useEffect(() => {
    if (data && defaultValues?.senderAccountId) {
      const activeAccount = data.find(({ account }) => account.id === defaultValues.senderAccountId);
      if (activeAccount) {
        setValue(`${FormFieldName.senderAccount}`, JSON.stringify(activeAccount.account));
      }
    }
  }, [data, defaultValues, setValue]);
  useEffect(() => {
    if (defaultValues?.recipientAccountName) {
      setValue(`${FormFieldName.recipientName}`, defaultValues.recipientAccountName);
    }
    if (defaultValues?.templateName) {
      setValue(`${FormFieldName.templateName}`, defaultValues.templateName);
    }
    if (defaultValues?.recipientAccountNumber) {
      setValue(`${FormFieldName.recipientAccount}`, defaultValues.recipientAccountNumber);
    }
    if (defaultValues?.recipientLei) {
      setValue(FormFieldName.recipientLei, defaultValues.recipientLei);
    }
    if (defaultValues?.recipientPassport) {
      setValue(FormFieldName.recipientPassport, defaultValues.recipientPassport);
    }
    if (defaultValues?.amount) {
      setValue(`${FormFieldName.amount}`, `${defaultValues.amount}`);
    }
    if (defaultValues?.paymentReference) {
      setValue(`${FormFieldName.purpose}`, defaultValues.paymentReference);
    }
    setValue(FormFieldName.recipientNonLeiFlag, defaultValues.recipientNonLeiFlag);
  }, [defaultValues, setValue]);

  useEffect(() => {
    setRecipientNonLeiFlag(defaultValues.recipientNonLeiFlag);
  }, [defaultValues.recipientNonLeiFlag]);

  const onClickHandler = () => {
    if (!leavePopupOpen && isDirty) {
      setLeavePopupOpen(true);
    } else {
      onCancelHandler();
    }
  };

  const onConfirmHandler = () => {
    setLeavePopupOpen(false);
    onCancelHandler();
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  useEffect(() => {
    setValue(FormFieldName.recipientNonLeiFlag, recipientNonLeiFlag);
    makeValidationSchemaConfig(recipientNonLeiFlag, passportActiveTab, setValidationsFormFields);
  }, [recipientNonLeiFlag, passportActiveTab, setValue]);

  const recipientNonLeiClickHandler = () => {
    setRecipientNonLeiFlag(!recipientNonLeiFlag);
  };

  return (
    <Box mt={4} sx={styles.tabWrapper}>
      <FormProvider {...methods}>
        <form
          style={{
            position: 'relative',
            backgroundColor: '#f6f6f7',
            borderRadius: '8px',
            padding: '4px 20px 20px',
          }}
        >
          <CardContent>
            <Box mb={6}>
              <Grid container>
                <Grid item xs>
                  <FormLabel component="legend">{t('templateNameLabel')}</FormLabel>
                  <Box pt={2} pr={7}>
                    <InputField
                      fullWidth
                      name={FormFieldName.templateName}
                      placeholder={t('saveTemplateTitle')}
                      defaultValue={defaultValues.templateName || ''}
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
                  <Box pt={2}>
                    <SenderAccountSelect name={FormFieldName.senderAccount} options={data} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.hr} mb={5} />
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientName')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <RecipientNameSelect
                      name={FormFieldName.recipientName}
                      defaultValue={defaultValues.recipientAccountName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  {!recipientNonLeiFlag ? (
                    <>
                      <Grid container item justifyContent="space-between" alignItems="center">
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                        {appConfig.enableNextFeatures ? (
                          <Typography variant="body2" onClick={recipientNonLeiClickHandler} sx={styles.lei}>
                            {t('paymentCreationLocalPayment_passport_select_title')}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Box pt={1}>
                        <Lei name={FormFieldName.recipientLei} defaultValue={defaultValues.recipientLei} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Grid container item justifyContent="space-between" alignItems="center" sx={styles.passport}>
                        <PassportTabs
                          onChange={passportTabsChangeHandler}
                          defaultActiveTab={PassportTabsEnum.passport}
                        />
                        <Typography variant="body2" onClick={recipientNonLeiClickHandler} sx={styles.lei}>
                          {t('paymentCreationLocalPayment_lei_select_title')}
                        </Typography>
                      </Grid>
                      <Box pt={1}>
                        {passportActiveTab === PassportTabsEnum.passport ? (
                          <PassportField name={FormFieldName.recipientPassport} />
                        ) : null}
                        {passportActiveTab === PassportTabsEnum.idPassport ? (
                          <FormatInputField
                            type={FormatType.number}
                            name={FormFieldName.idPassport}
                            placeholder="0000000000"
                            maxLength={10}
                          />
                        ) : null}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientAccount')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <RecipientAccountSelect
                      name={FormFieldName.recipientAccount}
                      defaultValue={defaultValues.recipientAccountNumber}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_bankName')}</FormLabel>
                  <Box pt={2}>
                    <BankName
                      name={FormFieldName.bankName}
                      iban={getValues(FormFieldName.recipientAccount)}
                      defaultValue={defaultValues.recipientBankName}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                  <Box sx={styles.flexbox}>
                    <Box pt={2}>
                      <FormatInputField
                        defaultValue={`${defaultValues.amount}` || ''}
                        type={FormatType.amount}
                        name={FormFieldName.amount}
                        placeholder={t('paymentCreationLocalPayment_amountPlaceholder')}
                      />
                    </Box>
                    <Box pl={3} pt={5}>
                      <Tooltip color="primary" title={t('tooltip_bank-tariff')} arrow placement="top">
                        <div>
                          <InfoIcon fontSize="small" sx={styles.info} />
                        </div>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid sx={styles.switch} item xs={5}>
                  <Typography variant="body2" sx={vatEnabled ? styles.vatTextDisabled : undefined}>
                    {t('paymentCreationLocalPayment_withoutVat')}
                  </Typography>
                  <Box pl={1}>
                    <SwitchField onChange={vatSwitchHandler} name={FormFieldName._vatSwitch} />
                  </Box>
                  <Typography variant="body2" sx={vatEnabled ? undefined : styles.vatTextDisabled}>
                    {t('paymentCreationLocalPayment_withVat')}
                  </Typography>
                  {vatEnabled ? (
                    <>
                      <InputField defaultValue={20} sx={styles.vat} name={FormFieldName.vat} />
                      <Box pl={1}>%</Box>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
            <Box mb={1}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
              <Box pt={2}>
                <TextAreaField
                  defaultValue={defaultValues.paymentReference || ''}
                  name={FormFieldName.purpose}
                  textFieldProps={{
                    multiline: true,
                    fullWidth: true,
                    rows: 2,
                  }}
                  maxLength={420}
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={styles.cardAction}>
            <Box sx={styles.cardAction}>
              <Button sx={styles.cancelBtn} variant="outlined" color="primary" onClick={onClickHandler}>
                {t('cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={onSaveTemplate}>
                {defaultValues.templateName ? (
                  t('save_changes')
                ) : (
                  <>
                    {t('payments_templ_create')}
                    <StarIcon sx={styles.starIcon} />
                  </>
                )}
              </Button>
            </Box>
          </CardActions>
        </form>
      </FormProvider>
      <LeaveTemplatePopup open={leavePopupOpen} onConfirm={onConfirmHandler} onCancel={onCancelLeaveHandler} />
    </Box>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { GetDocumentWithAvailableActionsDto, UserResourceDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/documents/components/DocumentsTable/columns';
import { styles } from '@app/pages/documents/components/DocumentsTable/styles';
import { TableToolbar } from '@app/pages/documents/components/TableToolbar';

export interface DocumentsTableProps {
  documents: GetDocumentWithAvailableActionsDto[];
  pagination?: TablePaginationType;
  onPaginationChange(pagination: PaginationChangeType): void;
  onDeleteDocument(document: GetDocumentWithAvailableActionsDto): void;
  onEditDocument(document: GetDocumentWithAvailableActionsDto): void;
  onSort?(rule: SortingRule): void;
  onSign(documents: GetDocumentWithAvailableActionsDto[]): void;
  onSendToSignSuccess(): void;
  onMultipleDeleteDocumentSuccess(): void;
  permissions: UserResourceDto;
}

export const DocumentsTable: React.FC<DocumentsTableProps> = ({
  documents,
  pagination,
  onDeleteDocument,
  onEditDocument,
  onPaginationChange,
  onSign,
  onSort,
  onSendToSignSuccess,
  onMultipleDeleteDocumentSuccess,
  permissions,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();

  const data = useMemo(() => {
    return documents.map((column) => {
      return {
        ...column,
      };
    });
  }, [documents]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        permissions,
        onEditDocument,
        onDeleteDocument,
      }),
    ];
  }, [onDeleteDocument, onEditDocument, permissions, t]);

  const table = useReactTable<GetDocumentWithAvailableActionsDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: documents.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<GetDocumentWithAvailableActionsDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const selectedDocuments: GetDocumentWithAvailableActionsDto[] = table
    .getSelectedRowModel()
    .flatRows.map((operationRow) => operationRow.original);

  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);

  const onSignClickHandler = () => {
    onSign(selectedDocuments);
  };

  const onMultipleDeleteDocumentSuccessHandler = () => {
    table.resetRowSelection();
    onMultipleDeleteDocumentSuccess();
  };

  return (
    <Box mt={7}>
      <TableToolbar
        selectedDocuments={selectedDocuments}
        onEditClick={onEditDocument}
        onSignClick={onSignClickHandler}
        onSendToSignSuccess={onSendToSignSuccess}
        onMultipleDeleteDocumentSuccess={onMultipleDeleteDocumentSuccessHandler}
      />
      <ComplexTable
        head={{
          content: <TableHeadContent<GetDocumentWithAvailableActionsDto> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </Box>
  );
};

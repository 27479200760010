import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitializeStatus = 'idle' | 'loading' | 'loaded' | 'initialized' | 'error';

export interface CryptoState {
  cryptoInitializeStatus: InitializeStatus;
  cryptoLibraryLoaded: boolean;
  signStatus: string;
}

const initialState: CryptoState = {
  cryptoInitializeStatus: 'idle',
  cryptoLibraryLoaded: false,
  signStatus: 'idle',
};

export const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    setCryptoInitializeStatus: (state, action: PayloadAction<InitializeStatus>) => {
      state.cryptoInitializeStatus = action.payload;
    },
    setCryptoLibraryLoaded: (state, action: PayloadAction<boolean>) => {
      state.cryptoLibraryLoaded = action.payload;
    },
  },
});

export const { setCryptoInitializeStatus, setCryptoLibraryLoaded } = cryptoSlice.actions;
export default cryptoSlice.reducer;

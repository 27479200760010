import React, { FC, forwardRef, PropsWithChildren, useState } from 'react';

import { Grid, ListItemText, Collapse, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { DownChevronIcon, UpChevronIcon } from '@app/common/icons';
import { MenuItem } from '@app/common/layout/Sidebar/components/MenuItem';
import { MenuItemIcon } from '@app/common/layout/Sidebar/components/MenuItemIcon';
import { styles } from '@app/common/layout/Sidebar/styles';
import { Tooltip } from '@app/core/components';
import { ThemeEnum } from '@app/core/types';
import { RouteList } from '@app/src/constants/routeList';

export type MultipleMenuItemProps = {
  name: string;
  to: RouteList;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  hideTooltip: boolean;
  subMenu?: { name: string; to: RouteList; SubMenuIcon: FC }[];
};

export const MultipleMenuItem: React.FC<MultipleMenuItemProps> = ({ name, to, Icon, hideTooltip, subMenu }) => {
  const { t } = useTranslation();
  const {
    themeName,
    palette: { colors },
  } = useTheme();
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const CustomLink = (link: string) => {
    return forwardRef(({ children, ...linkProps }: PropsWithChildren<{ className: string }>, ref) => (
      <NavLink
        to={link}
        {...linkProps}
        className={({ isActive }) => linkProps.className + (isActive ? ' selected' : '')}
      >
        {children}
      </NavLink>
    ));
  };

  const onCollapseClickHandler = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontSize: 14,
          fontWeight: collapseOpen ? 600 : 400,
          color: () =>
            collapseOpen
              ? themeName === ThemeEnum.light
                ? colors.primary700
                : colors.menuItemActiveText
              : themeName === ThemeEnum.light
              ? colors.gray400
              : colors.gray200,
          cursor: 'pointer',
        }}
        onClick={onCollapseClickHandler}
      >
        <Grid item container alignItems="center" xs={3}>
          <MenuItemIcon>
            <Tooltip
              disableFocusListener={hideTooltip}
              disableHoverListener={hideTooltip}
              disableTouchListener={hideTooltip}
              arrow
              placement="right"
              color="secondary"
              title={t(name)}
            >
              <Icon
                // className={collapseOpen ? styles.multipleMenuIconSelected : classes.multipleMenuIcon}
                style={{
                  fill: collapseOpen
                    ? themeName === ThemeEnum.light
                      ? colors.primary700
                      : colors.menuItemActiveText
                    : themeName === ThemeEnum.light
                    ? colors.gray400
                    : colors.gray200,
                }}
              />
            </Tooltip>
          </MenuItemIcon>
        </Grid>
        {hideTooltip ? (
          <>
            <Grid item xs={7}>
              <Box pl={1}>
                <ListItemText primary={<span>{t(name)}</span>} disableTypography />
              </Box>
            </Grid>
            <Grid item container alignItems="center" xs={2}>
              {collapseOpen ? <UpChevronIcon sx={styles.arrowIconOpen} /> : <DownChevronIcon sx={styles.arrowIcon} />}
            </Grid>
          </>
        ) : null}
      </Grid>
      <Collapse in={collapseOpen}>
        {subMenu?.map((item) => {
          const { SubMenuIcon } = item;
          return (
            <MenuItem key={item.name} component={CustomLink(item.to)}>
              <MenuItemIcon>
                <Tooltip
                  disableFocusListener={hideTooltip}
                  disableHoverListener={hideTooltip}
                  disableTouchListener={hideTooltip}
                  arrow
                  placement="right"
                  color="secondary"
                  title={t(item.name)}
                >
                  <Box
                    sx={{
                      paddingLeft: '10px',
                    }}
                  >
                    <SubMenuIcon />
                  </Box>
                </Tooltip>
              </MenuItemIcon>
              {hideTooltip ? (
                <Box pl={2}>
                  <ListItemText primary={<span>{t(item.name)}</span>} disableTypography />
                </Box>
              ) : null}
            </MenuItem>
          );
        })}
      </Collapse>
    </>
  );
};

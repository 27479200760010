export type UserDataProfile = {
  userSurname: string;
  userName: string;
  userMiddleName: string;
  userTIN: string; // ІПН
  userPassport: string; // Паспорт
  userPhone: string;
  userEmail: string;
};

export type CompanyDataProfile = {
  companyName: string;
  companyLei: string;
  companyAddress: string;
  companyNameEN: string;
  companyAddressEN: string;
  companyNameRU: string;
  companyAddressRU: string;
};

export enum NotificationEventEnum {
  temporary_locking = 'temporary_locking',
  login_from_forbidden_ip = 'login_from_forbidden_ip',
  account_locked = 'account_locked',
  account_unlocked = 'account_unlocked',
  key_activation_and_signature = 'key_activation_and_signature',
  login = 'login',
  bank_letters = 'bank_letters',
}

export type SubmitUserNotificationEvent = {
  type: NotificationEventEnum;
  email?: boolean;
  sms?: boolean;
};

export type UserNotificationEvent = {
  type: NotificationEventEnum;
  email: {
    active: boolean;
    edit: boolean;
  };
  sms?: {
    active: boolean;
    edit: boolean;
  };
};

export enum UserPermissions {
  viewer = 'viewer',
  creator = 'creator',
  individual = 'individual',
}
export enum UserSignature {
  signed = 'signed',
  unsigned = 'unsigned',
}
export enum NewUserSignature {
  one_signature_right = 'one_signature_right',
  two_signature_right = 'two_signature_right',
  unsigned = 'unsigned',
}
export enum UserStatus {
  active = 'active',
  blocked = 'blocked',
  processing = 'processing',
}

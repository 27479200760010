import React from 'react';

import { Box, Grid, IconButton, Skeleton } from '@mui/material';

import { styles } from '@app/pages/accounts/components/AccountsList/components/AccountsListRow/styles';
import { FavoriteIcon } from '@app/pages/accounts/components/AccountsList/primitives';

export const AccountInfoLoader: React.FC = () => {
  return (
    <Grid item container direction="row" sx={styles.mainAccountBlock}>
      <Grid item alignItems="center" container wrap="nowrap" sx={styles.accountLeft}>
        <Box>
          <IconButton disabled size="small">
            <FavoriteIcon disable />
          </IconButton>
        </Box>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          sx={styles.accountLeftInfo}
        >
          <Skeleton variant="text" animation="wave" height={28} width="75%" />
          <Skeleton variant="text" animation="wave" height={44} width="75%" />
        </Grid>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        sx={styles.accountCenter}
      >
        <Grid item container>
          <Grid item container>
            <Skeleton variant="text" animation="wave" height={28} width="100%" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Skeleton variant="text" animation="wave" height={44} width="45%" />
          <Skeleton variant="text" animation="wave" height={44} width="45%" />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={styles.accountRight}
      >
        <Skeleton variant="text" animation="wave" height={28} width="75%" />
        <Skeleton variant="text" animation="wave" height={44} width="75%" />
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, TextField, Typography, AutocompleteValue, AutocompleteInputChangeReason } from '@mui/material';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import { useTranslation } from 'react-i18next';

import { DocumentTemplateDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { Autocomplete } from '@app/core/components/Autocomplete/Autocomplete';
import { DEFAULT_FIRST_PAGE } from '@app/core/constants';
import { useGetPaymentTemplates } from '@app/core/hooks';

interface SelectPaymentTemplateProps {
  templateType?: PaymentTemplateTypeEnum;
  onTemplateChange(template: DocumentTemplateDto): void;
  placeholder?: string;
}

export const SelectPaymentTemplate: React.FC<SelectPaymentTemplateProps> = ({
  templateType,
  onTemplateChange,
  placeholder = '',
}): JSX.Element => {
  const { t } = useTranslation();
  const { mutateAsync } = useGetPaymentTemplates();
  const [value, setValue] = useState<DocumentTemplateDto | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly DocumentTemplateDto[]>([]);
  useEffect(() => {
    mutateAsync({ templateType, pagination: { page: DEFAULT_FIRST_PAGE, rowsPerPage: 1000 } }).then(({ templates }) => {
      setOptions(templates);
    });
  }, [mutateAsync, templateType]);

  const onChangeHandler = (
    event: React.ChangeEvent<{}>,
    newValue: AutocompleteValue<DocumentTemplateDto, false, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (newValue) {
      setValue(newValue);
      onTemplateChange(newValue);
    }
  };

  const onInputChangeHandler = (
    event: React.SyntheticEvent,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'clear') {
      setInputValue('');
    }
    if (reason === 'input') {
      setInputValue(newInputValue);
    }
  };

  useEffect(() => {
    let active = true;
    mutateAsync({
      templateType,
      templateName: inputValue,
      pagination: { page: DEFAULT_FIRST_PAGE, rowsPerPage: 1000 },
    }).then(({ templates }) => {
      if (active) {
        let newOptions: readonly DocumentTemplateDto[] = [];

        if (templates) {
          newOptions = [...newOptions, ...templates];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, mutateAsync, templateType]);

  return (
    <>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 'bold',
          color: 'colors.gray600',
        }}
      >
        {t('paymentCreationSelectPaymentTemplate')}
      </Typography>

      <Box pt={2}>
        <Autocomplete<DocumentTemplateDto, false, false, false>
          autoComplete
          filterSelectedOptions={false}
          value={value}
          name="SelectPaymentTemplate"
          noOptionsText={t('noTemplates')}
          blurOnSelect
          autoHighlight
          options={options}
          getOptionLabel={(option) => option.templateName}
          isOptionEqualToValue={(temp1, temp2) => {
            return temp1.uuid === temp2.uuid;
          }}
          onChange={onChangeHandler}
          renderInput={(params) => (
            <TextField autoComplete="off" {...params} variant="outlined" placeholder={placeholder} />
          )}
          onInputChange={onInputChangeHandler}
        />
      </Box>
    </>
  );
};

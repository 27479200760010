import React from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownloadIcon, InfoIcon } from '@app/common/icons';
import { EmailDocumentTypeEnum } from '@app/core/api';
import { ShareDocumentButton, TextFieldMultiline } from '@app/core/components';
import { Tooltip } from '@app/core/components/Tooltip';
import { DATE_FORMAT, formatBIC } from '@app/core/utils';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import { SwiftFormFields, SwiftViewProps } from '@app/pages/swift/types';

export const DesktopSwiftView: React.FC<SwiftViewProps> = ({ order, country, onClose, onDownloadClick }) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={styles.tabBody} direction="column">
      <Grid container item justifyContent="space-between">
        <Typography variant="h6" sx={styles.header}>
          {t('swiftPayment_documentNumber')} {order.documentNumber}
        </Typography>
        <ShareDocumentButton
          link={order.link}
          styles={{
            color: 'colors.primary500',
          }}
          type={EmailDocumentTypeEnum.SwiftOperation}
          uuid={order.uuid}
        />
      </Grid>
      <Grid item container direction="column">
        <CardContent>
          <Box mb={6}>
            <Grid container>
              <Grid item xs={3}>
                <FormLabel component="legend">{t('swiftPayment_documentDate')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={order.documentDate ? format(new Date(order.documentDate), DATE_FORMAT) : ''}
                    variant="outlined"
                    name={SwiftFormFields.documentDate}
                  />
                </Box>
              </Grid>
              {order.documentValueDate ? (
                <>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('swiftPayment_valuationDate')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField
                        disabled
                        variant="outlined"
                        defaultValue={
                          order.documentValueDate ? format(new Date(order.documentValueDate), DATE_FORMAT) : ''
                        }
                        name={SwiftFormFields.documentValueDate}
                      />
                    </Box>
                  </Grid>
                  <Grid item container alignItems="center" xs={1}>
                    <Tooltip color="primary" title={t('swiftPayment_valuationDateTooltip')} arrow placement="right">
                      <Box mt={8}>
                        <InfoIcon fontSize="small" sx={styles.info} />
                      </Box>
                    </Tooltip>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
          <Box mb={6}>
            <FormLabel component="legend">{t('swiftPayment_senderAccount')}</FormLabel>
            <Box pt={2}>
              <TextField disabled fullWidth defaultValue={order.sender.account} />
            </Box>
          </Box>
          <Box mb={6}>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_senderName')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={order.sender.name}
                    variant="outlined"
                    name={SwiftFormFields.senderName}
                    placeholder={t('swiftPayment_nameFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_senderAddress')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={order.sender.address}
                    variant="outlined"
                    name={SwiftFormFields.senderAddress}
                    placeholder={t('swiftPayment_addressFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={styles.hr} mb={5} />
          <Box mb={5}>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_recipientName')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={order.recipient.name}
                    variant="outlined"
                    name={SwiftFormFields.recipientName}
                    placeholder={t('swiftPayment_nameFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_recipientAddress')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={order.recipient.address}
                    variant="outlined"
                    name={SwiftFormFields.recipientAddress}
                    placeholder={t('swiftPayment_addressFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_recipientAccount')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={order.recipient.account}
                    variant="outlined"
                    name={SwiftFormFields.recipientAccount}
                    placeholder={t('swiftPayment_recipientAccount')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_recipientCountry')}</FormLabel>
                <Box pt={2}>
                  {country ? (
                    <TextField
                      disabled
                      defaultValue={country.name}
                      variant="outlined"
                      name={SwiftFormFields.recipientCountry}
                      fullWidth
                    />
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={formatBIC(order.recipient.swiftBic)}
                    variant="outlined"
                    name={SwiftFormFields.recipientSwiftBic}
                    placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={order.recipient.bankName}
                    variant="outlined"
                    name={SwiftFormFields.recipientBank}
                    placeholder={t('swiftPayment_bankFieldPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {order.recipient.correspondentSwiftBic && order.recipient.correspondentSwiftBic.length > 0 ? (
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_swiftBicCorrespondent')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <TextField
                      disabled
                      defaultValue={formatBIC(order.recipient.correspondentSwiftBic)}
                      variant="outlined"
                      name={SwiftFormFields.recipientCorrespondentSwiftBic}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                  <Box pt={2}>
                    <TextField
                      disabled
                      defaultValue={order.recipient.correspondentBank}
                      variant="outlined"
                      name={SwiftFormFields.recipientCorrespondentBank}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Box sx={styles.hr} mb={5} />
          <Box mb={5}>
            <Grid container>
              <Grid item xs={3}>
                <FormLabel component="legend">{t('swiftPayment_amount')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={parseFloat(`${order.amount}`).toFixed(2)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{order.currency}</InputAdornment>,
                    }}
                    variant="outlined"
                    name={SwiftFormFields.amount}
                    placeholder={t('swiftPayment_amountPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={t(`swiftCommission_${order.commissionType}`)}
                    variant="outlined"
                    name={SwiftFormFields.commissionType}
                    placeholder={t('swiftPayment_commissionTypePlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_operationCode')}</FormLabel>
                <Box pt={2} pr={8}>
                  <TextField
                    disabled
                    defaultValue={order.operationCode}
                    variant="outlined"
                    name={SwiftFormFields.operationCode}
                    placeholder={t('swiftPayment_operationCodePlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('swiftPayment_urgency')}</FormLabel>
                <Box pt={2}>
                  <TextField
                    disabled
                    defaultValue={t(`swiftUrgency_${order.urgency}`)}
                    variant="outlined"
                    name={SwiftFormFields.urgency}
                    placeholder={t('swiftPayment_urgencyPlaceholder')}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={6}>
            <FormLabel component="legend">{t('swiftPayment_purpose')}</FormLabel>
            <Box pt={2}>
              <TextFieldMultiline
                variant="outlined"
                multiline
                fullWidth
                defaultValue={order.paymentReference}
                name={SwiftFormFields.paymentReference}
                sx={{
                  '& textarea': {
                    padding: '10px 12px',
                    color: 'colors.gray300',
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions sx={styles.cardAction}>
          <Button variant="contained" color="primary" onClick={onClose}>
            {t('close')}
          </Button>
          <Box sx={styles.cardAction}>
            <Button
              variant="outlined"
              color="primary"
              sx={styles.submitBtn}
              endIcon={<DownloadIcon />}
              onClick={onDownloadClick}
            >
              {t('download')}
            </Button>
          </Box>
        </CardActions>
      </Grid>
    </Grid>
  );
};

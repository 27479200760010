import React from 'react';

import { UpChevronSmallIcon, DownChevronSmallIcon } from '@app/common/icons';

import { Operation, OperationsTypeEnum } from '../../../../../../types';
import {
  ExpandedTableRow,
  TableCell,
  AccountNameItem,
  AccountNumberItem,
  AmountItem,
  CounterPartyName,
  DateItem,
  PaymentReference,
  TimeItem,
} from '../../../../../ComplexTable';
import { IBanText } from '../../../../../IBanText';
import { OperationDetails } from '../../../../../OperationDetails';
import { styles } from '../../../../styles';

export interface OperationTableRowProps {
  operation: Operation;
  open: boolean;
  onOpenExtendedContent(id: string | undefined): void;
}

export const OperationTableRow: React.FC<OperationTableRowProps> = ({ operation, open, onOpenExtendedContent }) => {
  const { uuid, date, account, paymentReference, type, amount, currency, details } = operation;
  const { senderAccount, recipientAccount } = details;
  const { name: accountName, number: accountNumber } = account;

  const handleExpand = () => {
    onOpenExtendedContent(open ? undefined : uuid);
  };

  return (
    <ExpandedTableRow
      open={open}
      onExpand={handleExpand}
      extendedContend={<OperationDetails operation={operation} stylesRoot={styles.operationDetailsContent} />}
      sx={{ ...styles.tableRow, ...(open && styles.tableRowExpanded) }}
    >
      <TableCell sx={{ ...styles.tableCell, ...styles.dateCell }}>
        <DateItem text={date} />
        {date ? <TimeItem date={date} /> : null}
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.accountCell }}>
        <AccountNameItem text={accountName} />
        <AccountNumberItem text={<IBanText text={accountNumber} />} />
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.referenceCell }}>
        <CounterPartyName text={type === OperationsTypeEnum.deposit ? senderAccount.name : recipientAccount.name} />
        <PaymentReference text={paymentReference} />
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.amountCell }}>
        <AmountItem type={type} amount={amount} currency={currency} />
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.actionCell }}>
        {open ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
      </TableCell>
    </ExpandedTableRow>
  );
};

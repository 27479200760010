import { PaymentOrderTypeEnum } from '@app/core/api';

import {
  AccountSelectType,
  DateRangeFromTo,
  TablePaginationType,
  DocumentStatus,
  Operation,
  OperationAccount,
  OperationsTypeEnum,
  TotalAmounts,
} from '../../types';

export enum StatementsFilterField {
  operationAccounts = 'operationAccounts',
  dateRange = 'dateRange',
  groupCommissions = 'groupCommissions',
  LEI = 'LEI',
  counterPartyName = 'counterPartyName', // кореспондент
  counterPartyAccountNumber = 'counterPartyAccountNumber', // Рахунок коресподента
  counterPartyBankCode = 'counterPartyBankCode', // МФО коресподента
  documentNumber = 'documentNumber',
  documentStatus = 'documentStatus',
  amountMin = 'amountMin',
  amountMax = 'amountMax',
  paymentReference = 'paymentReference',
  hideCommission = 'hideCommission',
  operationType = 'operationType', // Тип операції
  documentCategory = 'documentCategory', // Вид операції
  rowsPerPage = 'rowsPerPage',
  page = 'page',
  sortBy = 'sortBy',
  dateRangeSelect = 'dateRangeSelect',
}

export enum StatementsTableColumnsEnum {
  documentNumber = 'documentNumber', // DocumentNumber
  operationDate = 'operationDate', // ExecutionDate
  documentDate = 'documentDate', // DocumentDate
  counterpartyName = 'counterpartyName', // CounterpartyName old recipientAccountName
  counterpartyAccount = 'counterpartyAccount', // CounterpartyAccount old recipientAccountNumber
  counterpartyLei = 'counterpartyLei', // CounterpartyLei old LEI
  counterpartyBankCode = 'counterpartyBankCode', // CounterpartyBankCode old bankCode
  counterpartyBank = 'counterpartyBank', // CounterpartyBank old bankName
  paymentReference = 'paymentReference', // PaymentReference
  accountNumber = 'accountNumber', // AccountNumber
  currency = 'currency', // Currency
  amount = 'amount', // Amount
  deposit = 'deposit', // Debit
  withdrawal = 'withdrawal', // Credit
  documentStatus = 'documentStatus', // Status
}

export type AccountBalanceMovement = {
  account: OperationAccount;
  openingBalance: number; // входящий остаток
  deposit: number; // Кредит банка
  withdrawal: number; // Дебет банка
  closingBalance: number; // Исходящий остаток
};

export type OperationsFromPeriodResult = {
  accountBalancesMovement?: AccountBalanceMovement[];
  operations: Operation[];
  operationsCount: number;
  totalAmounts?: TotalAmounts;
  pagination: TablePaginationType;
};

export type StatementsFilterFormData = {
  operationAccounts: AccountSelectType[];
  dateRange: DateRangeFromTo;
  dateRangeSelect?: string;
  operationType?: OperationsTypeEnum;
  documentCategory?: PaymentOrderTypeEnum;
  groupCommissions?: boolean;
  LEI?: string;
  counterPartyName?: string;
  counterPartyAccountNumber?: string;
  documentStatus?: DocumentStatus;
  amountMin?: string;
  amountMax?: string;
  paymentReference?: string;
  counterPartyBankCode?: string;
};

export enum StatementsSubFilterEnum {
  amount = 'amount',
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  executionDate = 'executionDate',
  recipientAccountName = 'recipientAccountName',
  LEI = 'LEI',
  paymentReference = 'paymentReference',
  accountNumber = 'accountNumber',
  currency = 'currency',
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  bankCode = 'bankCode',
  bankName = 'bankName',
  recipientAccountNumber = 'recipientAccountNumber',
  documentStatus = 'documentStatus',
  arcDate = 'arcDate',
}

export type StatementsSubFilterType = {
  [StatementsSubFilterEnum.amount]?: number;
  [StatementsSubFilterEnum.documentNumber]?: string;
  [StatementsSubFilterEnum.documentDate]?: Date;
  [StatementsSubFilterEnum.executionDate]?: Date;
  [StatementsSubFilterEnum.recipientAccountName]?: string;
  [StatementsSubFilterEnum.LEI]?: string;
  [StatementsSubFilterEnum.paymentReference]?: string;
  [StatementsSubFilterEnum.accountNumber]?: string;
  [StatementsSubFilterEnum.currency]?: string;
  [StatementsSubFilterEnum.deposit]?: string;
  [StatementsSubFilterEnum.withdrawal]?: string;
  [StatementsSubFilterEnum.bankCode]?: string;
  [StatementsSubFilterEnum.bankName]?: string;
  [StatementsSubFilterEnum.recipientAccountNumber]?: string;
  [StatementsSubFilterEnum.documentStatus]?: string;
  [StatementsSubFilterEnum.arcDate]?: Date;
};

import React, { useState } from 'react';

import { Box, styled, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { ReactComponent as MoonSvgIcon } from '@app/themes/default/assets/themeSwitcher/moon.svg';
import { ReactComponent as SkyDarkSvgIcon } from '@app/themes/default/assets/themeSwitcher/sky_dark.svg';
import { ReactComponent as SkyLightSvgIcon } from '@app/themes/default/assets/themeSwitcher/sky_light.svg';
import { ReactComponent as SunSvgIcon } from '@app/themes/default/assets/themeSwitcher/sun.svg';
import { useTheme } from '@app/themes/ThemeProvider';

import { ThemeEnum } from '../../types';

const svgBackgroundStyle: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  visibility: 'hidden',
  opacity: 0,
  transition: 'opacity 0.150s, visibility 0.150s',
  '&.checked': {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.150s, visibility 0.150s',
  },
};

const SkyLightBack = styled(SkyLightSvgIcon)(svgBackgroundStyle);
const SkyDarkBack = styled(SkyDarkSvgIcon)(svgBackgroundStyle);
const SunIcon = styled(SunSvgIcon)(svgBackgroundStyle);
const MoonIcon = styled(MoonSvgIcon)(svgBackgroundStyle);

const InputCheck = styled('input')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,
  zIndex: 1,
  margin: 0,
  cursor: 'pointer',
});

export const ThemeSwitcher: React.FC = () => {
  const { currentTheme, changeTheme } = useTheme();
  const [checked, setChecked] = useState<boolean>(currentTheme !== ThemeEnum.dark);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      changeTheme(ThemeEnum.light);
    } else {
      changeTheme(ThemeEnum.dark);
    }
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
        width: '62px',
        height: '32px',
      }}
    >
      <SkyLightBack className={checked ? 'checked' : undefined} />
      <SkyDarkBack className={!checked ? 'checked' : undefined} />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'block',
            width: '24px',
            height: '24px',
            top: '4px',
            left: '4px',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&.checked': {
              transform: 'translateX(30px)',
            },
          }}
          className={checked ? 'checked' : undefined}
        >
          <SunIcon className={checked ? 'checked' : undefined} />
          <MoonIcon className={!checked ? 'checked' : undefined} />
        </Box>
        <InputCheck type="checkbox" checked={checked} onChange={onChangeHandler} />
      </Box>
    </Box>
  );
};

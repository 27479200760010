import React from 'react';

import { DepositDto } from '@app/core/api';
import { PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { TableContent } from '@app/pages/deposits/components/DepositsTable/components/TableContent';

export interface DepositsTableProps {
  deposits: DepositDto[];
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  onPaginationChange(pagination: PaginationChangeType): void;
  onSort?(rule: SortingRule): void;
}

export const DepositsTable: React.FC<DepositsTableProps> = ({
  deposits,
  pagination,
  loading,
  queryKey,
  onSort,
  onPaginationChange,
}) => {
  const onSettingsButtonClickHandler = () => {};

  const onPaginationChangeHandler = ({ rowsPerPage, page }: PaginationChangeType) => {
    onPaginationChange({ rowsPerPage, page });
  };

  return (
    <TableContent
      loading={loading}
      data={deposits}
      onSettingsButtonClick={onSettingsButtonClickHandler}
      pagination={pagination}
      onSort={onSort}
      queryKey={queryKey}
      onPaginationChange={onPaginationChangeHandler}
    />
  );
};

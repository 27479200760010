import { PeriodType } from '@app/core/api';
import { RadioOption, SelectOptionType } from '@app/core/components';

export const filesFormatList: RadioOption[] = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'XLSX',
    value: 'xlsx',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
];

export const periodsListItems = (t: (translationKey: string) => string): SelectOptionType[] => {
  return [
    {
      id: PeriodType.Daily,
      name: t('currentDay'),
      value: PeriodType.Daily,
    },
    {
      id: PeriodType.Weekly,
      name: t('currentWeek'),
      value: PeriodType.Weekly,
    },
    {
      id: PeriodType.Monthly,
      name: t('currentMonth'),
      value: PeriodType.Monthly,
    },
    {
      id: PeriodType.Yearly,
      name: t('currentYear'),
      value: PeriodType.Yearly,
    },
  ];
};

import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { differenceInCalendarDays, format } from 'date-fns';

import { ArrowRightIcon, SettingIcon } from '@app/common/icons';
import { DepositDto, DepositDtoDepositTypeEnum } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { IBanText } from '@app/core/components/IBanText';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/deposits/components/DepositsTable/components/TableContent/styles';
import { DepositsTableColumns } from '@app/pages/deposits/types';

interface MakeTableColumnsProps {
  t(text: string): string;
  onSettingsButtonClick(): void;
  navigateClickHandler(depositType: DepositDtoDepositTypeEnum, uuid: string): void;
}

export const makeTableColumns = ({
  t,
  onSettingsButtonClick,
  navigateClickHandler,
}: MakeTableColumnsProps): ColumnDef<DepositDto, any>[] => {
  return [
    {
      accessorKey: DepositsTableColumns.agreementNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_agreementNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: DepositsTableColumns.productType,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_productType`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: DepositsTableColumns.depositType,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_depositType`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: DepositsTableColumns.accountInterestAccrued,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_accountInterestAccrued`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <IBanText text={getValue()} />;
      },
    },
    {
      accessorKey: DepositsTableColumns.agreementStartDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: DepositsTableColumns.agreementEndDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: DepositsTableColumns.term,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_period`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ row }: CellContext<DepositDto, string>) => {
        return (
          <>
            {`${differenceInCalendarDays(
              new Date(row.original.agreementEndDate),
              new Date(row.original.agreementStartDate)
            )} ${t('shorts_days')}`}
          </>
        );
      },
    },
    {
      accessorKey: DepositsTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: DepositsTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: DepositsTableColumns.interestRate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`deposits_interestRate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, number>) => {
        return `${getValue().toFixed(2)}%`;
      },
    },
    {
      id: 'actions',
      accessorKey: DepositsTableColumns.uuid,
      header: () => {
        return (
          <Grid container justifyContent="flex-end">
            {/*<IconButton sx={styles.settingIconBtn} onClick={onSettingsButtonClick} size="large">*/}
            {/*  <SettingIcon />*/}
            {/*</IconButton>*/}
          </Grid>
        );
      },
      cell: ({ row }: CellContext<DepositDto, any>) => {
        const navigateClick = () => {
          navigateClickHandler(row.original.depositType, row.original.uuid);
        };
        return (
          <Grid container justifyContent="flex-end" alignItems="center">
            <IconButton size="small" sx={styles.navigateButton} onClick={navigateClick}>
              <ArrowRightIcon />
            </IconButton>
          </Grid>
        );
      },
    },
  ];
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, depositsApiClient, getClientId, GetReplenishmentTemplateDto } from '@app/core/api';

const getDepositReplenishmentTemplateFn = async ({ uuid }: { uuid: string }): Promise<GetReplenishmentTemplateDto> => {
  const result = await depositsApiClient.depositsControllerGetReplenishmentTemplate(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get depositsControllerGetReplenishmentTemplate!');
  }
  return result.data;
};

export const useGetDepositReplenishmentTemplate = (): UseMutationResult<
  GetReplenishmentTemplateDto,
  ApiError,
  { uuid: string }
> => {
  return useMutation<GetReplenishmentTemplateDto, ApiError, { uuid: string }>(getDepositReplenishmentTemplateFn);
};

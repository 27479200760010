import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UmcaPopup } from '@app/common/components/UmcaPopup';
import { KeyIcon, LinkIcon, DeleteIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  DocumentOperationType,
  DocumentStatusEnum,
  GetDocumentWithShortOperationsInfoResponseDto,
  OperationInfoDto,
  OperationsWithoutFilesDto,
} from '@app/core/api';
import { ConfirmLeavePagePopup } from '@app/core/components/ConfirmLeavePagePopup';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { SignCurrencyDocumentsDialog } from '@app/core/components/SignCurrencyDocumentsDialog';
import { Tooltip } from '@app/core/components/Tooltip';
import { useUmca } from '@app/core/hooks';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { OperationsTabs } from '@app/pages/documents/components/AddCurrencyDocumentModal/components/OperationsTab';
import { RelateDocumentsSelect } from '@app/pages/documents/components/AddCurrencyDocumentModal/components/RelateDocumentsSelect';
import { DeleteSingleCurrencyDocumentModal } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/DeleteSingleCurrencyDocumentModal';
import { editCurrencyDocumentsQueryFn, sendToSignCurrencyDocumentsQueryFn } from '@app/pages/documents/edit/View/query';
import { styles } from '@app/pages/documents/show/style';
import { SwiftFormFields } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface EditCurrencyDocumentViewProps {
  document: GetDocumentWithShortOperationsInfoResponseDto;
  update(): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}
export const EditCurrencyDocumentView: React.FC<EditCurrencyDocumentViewProps> = ({ document, update, onChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notify } = useNotify();
  const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
  const [haveChanges, setHaveChanges] = useState<boolean>(false);
  const [showUmcaPopup, setShowUmcaPopup] = useState<boolean>(false);
  const [showSignCurrencyDocumentsDialog, setShowSignCurrencyDocumentsDialog] = useState<boolean>(false);
  const [relatedOperations, setRelatedOperations] = useState<OperationsWithoutFilesDto[]>(document.relateOperations);
  const [attachOperations, setAttachOperations] = useState<OperationInfoDto[]>([]);
  const [detachOperations, setDetachOperations] = useState<OperationInfoDto[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [activeOperationTab, setActiveOperationTab] = useState<DocumentOperationType>(
    DocumentOperationType.CurrencyOperation
  );
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { installed: umcaInstalled, isError: umcaError, errorMessage: umcaErrorMessage } = useUmca();

  const methods = useForm({ mode: 'onSubmit' });
  const { control, formState } = methods;
  const { isDirty } = formState;

  const comment = useWatch({ control, name: 'comment' });

  useEffect(() => {
    onChange({ formDirty: isDirty });
    if (isDirty) {
      setHaveChanges(isDirty);
    }
  }, [isDirty, onChange]);

  const { mutate } = useMutation(editCurrencyDocumentsQueryFn, {
    onSuccess: () => {
      navigate(-1);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('addCurrencyDocument_saveSuccessMessage'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('attachFile_ErrorMessage'),
          severity: 'error',
        },
      });
    },
  });

  const { mutate: sendToSignMutation } = useMutation(sendToSignCurrencyDocumentsQueryFn, {
    onSuccess: () => {
      navigate(-1);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      notify({
        notifyProps: {
          title: t('payment_save_error'),
          message: t('send_to_sign_payment_error'),
          severity: 'error',
        },
      });
    },
  });

  const saveClickHandler = () => {
    mutate({
      uuid: document.uuid,
      editFileRequestDto: {
        comment,
        attachOperations: attachOperations.length ? attachOperations : undefined,
        detachOperations: detachOperations.length ? detachOperations : undefined,
      },
    });
  };

  const sendToSignHandler = () => {
    sendToSignMutation(document.uuid);
  };

  const onCloseHandler = () => {
    if (haveChanges || isDirty) {
      setShowLeavePopup(true);
    } else {
      navigate(-1);
    }
  };

  const onDeleteButtonClickHandler = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteDocumentModal = () => {
    setShowDeleteModal(false);
  };

  const onDeleteDocumentSuccess = () => {
    setShowDeleteModal(false);
    navigate(-1);
  };

  const selectAddRelateDocumentHandler = (operation: OperationsWithoutFilesDto) => {
    setRelatedOperations([...relatedOperations, operation]);
    setAttachOperations([...attachOperations, operation]);
    setHaveChanges(true);
    onChange({ formDirty: true });
  };

  const deleteOperation = (operation: OperationsWithoutFilesDto) => {
    const operations = relatedOperations.filter((relateOperation) => relateOperation.uuid !== operation.uuid);
    setRelatedOperations(operations);
    setDetachOperations([...detachOperations, operation]);
    setHaveChanges(true);
    onChange({ formDirty: true });
  };

  const operationsTabsChangeHandler = (activeTab: DocumentOperationType) => {
    setActiveOperationTab(activeTab);
  };

  const onSendAndSignClickHandler = () => {
    if (umcaInstalled) {
      setShowSignCurrencyDocumentsDialog(true);
    } else {
      if (umcaError) {
        setShowUmcaPopup(true);
      }
    }
  };

  const onCloseSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
  };

  const onSuccessSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
    update();
  };

  const closeUmcaPopup = () => {
    setShowUmcaPopup(false);
  };

  const activeDelete =
    permissions.documents.delete &&
    (document.status === DocumentStatusEnum.Saved || document.status === DocumentStatusEnum.ToSign);

  const onConfirmLeaveHandler = () => {
    navigate(-1);
  };

  const onCancelLeaveHandler = () => {
    setShowLeavePopup(false);
  };

  const onRelateOperationClick = (uuid: string, operationType: DocumentOperationType) => {
    if (operationType === DocumentOperationType.CurrencyOperation) {
      navigate(RouteList.exchange_show.replace(':uuid', uuid));
    }
    if (operationType === DocumentOperationType.Swift) {
      navigate(RouteList.swift_show.replace(':uuid', uuid));
    }
  };

  return (
    <>
      <Grid sx={styles.tabBody}>
        <FormProvider {...methods}>
          <form style={styles.form}>
            <Grid item>
              <Grid container direction="column">
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="h6">
                        <Grid container>
                          <Typography sx={styles.header}>
                            {t('Документ №')} {document.documentNumber}
                          </Typography>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={4}>
                      {activeDelete ? (
                        <Box>
                          <IconButton size="small" onClick={onDeleteButtonClickHandler}>
                            <Box component="span" sx={styles.deleteText}>
                              {t('deleteDocument')}
                            </Box>
                            <DeleteIcon sx={styles.actionIcon} />
                          </IconButton>
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Box mb={6} mt={6}>
                    <Grid container>
                      <Grid item xs={3}>
                        <FormLabel component="legend">{t('downloadDate')}</FormLabel>
                        <Box pt={2} pr={8}>
                          <TextField
                            disabled
                            defaultValue={formatISODateString(document?.createdAt, DATE_FORMAT)}
                            variant="outlined"
                            name={SwiftFormFields.documentDate}
                          />
                        </Box>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={6}>
                          <Box pt={4}>
                            <FormLabel component="legend">{t('currencyDocumentsSection')}</FormLabel>
                            <Box pt={2} pr={8}>
                              <TextField
                                disabled
                                defaultValue={t('currencyTransactions')}
                                variant="outlined"
                                name={SwiftFormFields.documentDate}
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pt={4}>
                            <OperationsTabs
                              defaultActiveTab={activeOperationTab}
                              onChange={operationsTabsChangeHandler}
                            />
                            <Box mt={1}>
                              <RelateDocumentsSelect
                                name="relateDocumentsSelect"
                                placeholder={t('addCurrencyDocumentRelatePlaceholder')}
                                onSelectOperationChange={selectAddRelateDocumentHandler}
                                operationType={activeOperationTab}
                              />
                            </Box>
                            {relatedOperations?.length ? (
                              <>
                                {relatedOperations?.map((operation, index) => {
                                  const typeText =
                                    operation.operationType === DocumentOperationType.CurrencyOperation
                                      ? t('currencyExchange')
                                      : 'SWIFT';
                                  return (
                                    <Box key={index} mt={2}>
                                      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                                        <Grid container>
                                          <LinkIcon fontSize="small" />
                                          <Typography
                                            variant="body2"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              onRelateOperationClick(operation.uuid, operation.operationType);
                                            }}
                                          >
                                            {`${operation.documentDate} - ${typeText} #${operation.documentNumber} - ${operation.amount} ${operation.currency}`}
                                          </Typography>
                                        </Grid>
                                        <Tooltip arrow placement="top" color="primary" title={t('detachOperation')}>
                                          <IconButton
                                            size="small"
                                            color="default"
                                            onClick={() => {
                                              deleteOperation(operation);
                                            }}
                                          >
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Box>
                                  );
                                })}
                              </>
                            ) : (
                              <Box mt={2}>
                                <Typography variant="body2" sx={styles.relateOperationPlaceholder}>
                                  {t('noReleteOperationsPlaceholder')}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box mb={6} mt={6}>
                      <FormLabel component="legend">{t('Коментар')}</FormLabel>
                      <Box pt={2}>
                        <InputField
                          name="comment"
                          placeholder={t('addCurrencyDocumentCommentPlaceholder')}
                          defaultValue={document.comment}
                          variant="outlined"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions sx={styles.cardAction}>
                  <Button variant="contained" color="primary" onClick={onCloseHandler}>
                    {t('close')}
                  </Button>
                  <Box sx={styles.cardAction}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={styles.submitBtn}
                      onClick={saveClickHandler}
                      disabled={!haveChanges}
                    >
                      {t('save')}
                    </Button>
                    {document.actions.allowToSendToSign ? (
                      <Button variant="contained" color="primary" onClick={sendToSignHandler}>
                        {t('paymentCreationFormControl_sendToSign')}
                      </Button>
                    ) : null}
                    {document.actions.allowToSign ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSendAndSignClickHandler}
                        disabled={document.status === DocumentStatusEnum.Processing}
                      >
                        {t('paymentCreationFormControl_send')}
                        <KeyIcon sx={styles.keyIcon} />
                      </Button>
                    ) : null}
                  </Box>
                </CardActions>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
      {showDeleteModal ? (
        <DeleteSingleCurrencyDocumentModal
          document={document}
          relateOperations={document.relateOperations}
          onClose={closeDeleteDocumentModal}
          onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        />
      ) : null}
      {showSignCurrencyDocumentsDialog ? (
        <SignCurrencyDocumentsDialog
          toSignDocuments={[document]}
          onClose={onCloseSignCurrencyDocumentsDialog}
          onSuccessPopupCancel={onSuccessSignCurrencyDocumentsDialog}
        />
      ) : null}
      {showUmcaPopup ? <UmcaPopup errorMessage={t(umcaErrorMessage)} onClose={closeUmcaPopup} /> : null}
      {showLeavePopup ? (
        <ConfirmLeavePagePopup
          open
          onConfirm={onConfirmLeaveHandler}
          onClose={onCancelLeaveHandler}
          questionText={t('confirmLeaveExchangeDocumentEditPopupMessage')}
        />
      ) : null}
    </>
  );
};

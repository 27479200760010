import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  configurationApiClient,
  DocumentNumberConfigDto,
  getClientId,
  UpsertDocumentNumberConfigRequestDto,
} from '@app/core/api';

const updateNumberConfigQueryFn = async (
  config: UpsertDocumentNumberConfigRequestDto
): Promise<DocumentNumberConfigDto> => {
  const response = await configurationApiClient.clientConfigControllerUpsertDocumentNumberConfig(getClientId(), config);
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant update number config!');
  }
  return response.data;
};

export const useUpdateNumberConfig = (): UseMutationResult<
  DocumentNumberConfigDto,
  ApiError,
  UpsertDocumentNumberConfigRequestDto
> => {
  return useMutation<DocumentNumberConfigDto, ApiError, UpsertDocumentNumberConfigRequestDto>(
    updateNumberConfigQueryFn
  );
};

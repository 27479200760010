export const styles = {
  toolBar: {
    justifyItems: 'center',
    justifyContent: 'space-between',
  },
  horizontalScroll: {
    '@media (max-width: 1279.95px)': {
      marginRight: 4,
      overflow: 'scroll',
    },
  },
  settingIconBtn: {
    color: 'colors.gray300',
    marginRight: '-16px',
  },
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_selection: {
    maxWidth: '46px',
  },
  cell_documentDate: {
    maxWidth: '130px',
    minWidth: '130px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_currencyExchangeType: {
    maxWidth: '130px',
    minWidth: '130px',
    '&.body': {
      color: 'colors.gray600',
    },
  },
  cell_currencyAgreementNumber: {
    maxWidth: '228px',
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
      fontWeight: 600,
    },
  },
  cell_period: {
    maxWidth: '228px',
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
      fontWeight: 600,
    },
  },
  cell_withdrawalAccountCurrency: {
    justifyContent: 'center',
    minWidth: '40px',
    maxWidth: '70px',
    paddingRight: '20px',
    '&.body': {
      fontWeight: 600,
      color: 'colors.gray400',
    },
  },
  cell_amount: {
    textAlign: 'end',
    minWidth: '140px',
    maxWidth: '140px',
    paddingRight: '24px',
    '&.body': {
      whiteSpace: 'nowrap',
      textAlign: 'end',
      fontWeight: 600,
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_interestRate: {
    maxWidth: '80px',
    textAlign: 'end',
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_actions: {
    width: '50px',
    flexShrink: 0,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  linkArrow: {
    color: 'colors.primary600',
    verticalAlign: 'top',
  },
  status: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  statusRejected: {
    backgroundColor: 'colors.error',
  },
  statusExecuted: {
    backgroundColor: 'colors.success',
  },
};

import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorSmallIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CountryCodeDto, FrontLanguageEnum } from '@app/core/api';
import { useGetSwiftByUuid } from '@app/core/hooks';
import { useGetCountryCodes } from '@app/core/hooks/useGetCountryCode';
import { isMobile } from '@app/core/utils';
import { styles } from '@app/pages/swift/style';
import { SwiftOrderHistory } from '@app/pages/swift/SwiftPayment/components/SwiftOrderHistory';
import { DesktopSwiftView } from '@app/pages/swift/SwiftPayment/show/SwiftView/DesktopSwiftView';
import { MobileSwiftView } from '@app/pages/swift/SwiftPayment/show/SwiftView/MobileSwiftView';
import { RouteList } from '@app/src/constants/routeList';

export const SwiftShowPage: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { uuid = 'no-uuid' } = useParams();
  const { data: swiftData } = useGetSwiftByUuid(uuid, { enabled: true, refetchOnMount: true });
  const [country, setCountry] = useState<CountryCodeDto>();

  const { data: countryCodesData, mutate: mutateCountryCode } = useGetCountryCodes();

  useEffect(() => {
    mutateCountryCode({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n.language, mutateCountryCode]);

  useEffect(() => {
    if (swiftData && countryCodesData) {
      setCountry(countryCodesData.rows.find((code) => code.numeric === swiftData.recipient.countryCode));
    }
  }, [swiftData, countryCodesData]);

  const onDownloadClickHandler = () => {
    window.open(`${swiftData?.link}`, '_blank');
  };
  const onCloseHandler = () => {
    navigate(RouteList.swift);
  };

  const documentNumber = swiftData?.documentNumber ? swiftData.documentNumber : '';

  const SwiftView = isMobile ? MobileSwiftView : DesktopSwiftView;

  return (
    <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
      <PageHeader
        title={`${t('showPayment_pageTitle')} № ${documentNumber}`}
        showNavigateButton={!isMobile}
        backLink={-1}
      />
      <PageContainer>
        <Box p={isMobile ? 0 : 3} width="100%">
          <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item container xs={isMobile ? false : 9}>
              {swiftData ? (
                <>
                  {swiftData.rejectMessage ? (
                    <Grid container alignItems="center" sx={styles.rejectContainer}>
                      <ErrorSmallIcon sx={styles.rejectReasonIcon} />
                      <Typography variant="body2" sx={styles.rejectReasonText}>
                        {swiftData.rejectMessage}
                      </Typography>
                    </Grid>
                  ) : null}
                  <SwiftView
                    order={swiftData}
                    onClose={onCloseHandler}
                    country={country}
                    onDownloadClick={onDownloadClickHandler}
                  />
                </>
              ) : null}
            </Grid>
            {!isMobile ? (
              <Box sx={{ width: '220px' }}>
                <Box pl={2}>{swiftData ? <SwiftOrderHistory order={swiftData} edit={false} /> : null}</Box>
              </Box>
            ) : null}
          </Grid>
        </Box>
      </PageContainer>
    </PageWrapper>
  );
};

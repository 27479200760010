import { CurrencyEnum, GetAccountResponseDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { DocumentTemplateData } from '@app/core/components';
import { StatePaymentFormData } from '@app/pages/payment/components/StatePaymentForm/types';

export const makeCreateTemplateData = (paymentFormData: StatePaymentFormData): DocumentTemplateData => {
  const {
    amount,
    recipientLei,
    recipientName,
    senderAccount,
    recipientAccount,
    paymentReference,
    recipientNonLeiFlag,
  } = paymentFormData;

  const parsedSenderAccount: GetAccountResponseDto = JSON.parse(senderAccount);
  return {
    senderAccountId: parsedSenderAccount.account.id,
    senderAccount: parsedSenderAccount.account.accountNumber,
    amount: Number(amount),
    recipientName,
    recipientLei: recipientNonLeiFlag ? undefined : recipientLei,
    recipientAccount,
    currency: CurrencyEnum.Uah,
    paymentReference,
    recipientNonLeiFlag,
    templateType: PaymentTemplateTypeEnum.Budgetary,
    realSenderNonLeiFlag: false,
    realRecipientNonLeiFlag: false,
  };
};

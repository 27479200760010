import React, { useEffect, useState } from 'react';

import { DocumentStatus, Operation } from '../../types';
import { ConfirmSignPaymentMultipleOrderDialog } from '../ConfirmSignPaymentMultipleOrderDialog';
import { SignPaymentOrder } from '../SignPaymentOrder';
import { SignPaymentOrderMultiple } from '../SignPaymentOrderMultiple';

interface SignPaymentProps {
  operations: Operation[];
  onSignSuccess(): void;
  onClose(): void;
}
export const SignPayment: React.FC<SignPaymentProps> = ({ operations, onSignSuccess, onClose }) => {
  const [showSignPaymentOrderDialog, setShowSignPaymentOrderDialog] = useState<boolean>(false);
  const [showConfirmSignPaymentMultipleOrderDialog, setShowConfirmSignPaymentMultipleOrderDialog] =
    useState<boolean>(false);
  const [showSignPaymentOrderMultiple, setShowSignPaymentOrderMultiple] = useState<boolean>(false);

  const documentsToSign = operations.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  );

  const onAgreeConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(false);
    setShowSignPaymentOrderMultiple(true);
  };

  useEffect(() => {
    if (operations.length > 1) {
      setShowConfirmSignPaymentMultipleOrderDialog(true);
    } else {
      setShowSignPaymentOrderDialog(true);
    }
  }, [operations]);

  const onCloseHandler = () => {
    setShowSignPaymentOrderDialog(false);
    setShowConfirmSignPaymentMultipleOrderDialog(false);
    setShowSignPaymentOrderMultiple(true);
    onClose();
  };

  return (
    <>
      {showSignPaymentOrderDialog ? (
        <SignPaymentOrder
          uuid={documentsToSign[0].uuid}
          documentToSign={JSON.stringify(documentsToSign[0])}
          onSuccessPopupCancel={onSignSuccess}
          onClose={onCloseHandler}
        />
      ) : null}

      {showConfirmSignPaymentMultipleOrderDialog ? (
        <ConfirmSignPaymentMultipleOrderDialog
          selectedCount={operations.length}
          toSignDocuments={documentsToSign}
          onClose={onCloseHandler}
          onAgreeSign={onAgreeConfirmSignPaymentMultipleOrderDialog}
        />
      ) : null}

      {showSignPaymentOrderMultiple ? (
        <SignPaymentOrderMultiple
          operations={documentsToSign}
          onClose={onCloseHandler}
          onSuccessPopupCancel={onSignSuccess}
        />
      ) : null}
    </>
  );
};

import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BalanceMovementItem } from './BalanceMovementItem';
import { styles } from './styles';
import { AccountBalanceMovement } from '../../types';

type BalancesMovementInfoProps = {
  accountBalancesMovement: AccountBalanceMovement[];
};

export const BalancesMovementInfo: React.FC<BalancesMovementInfoProps> = ({ accountBalancesMovement }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between" sx={styles.root}>
      <Grid container justifyContent="space-between" sx={styles.header}>
        <Grid item xs={3}>
          <Typography variant="caption" sx={styles.itemName}>
            {t('account')}
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="caption" sx={styles.itemName}>
            {t('selectedAccount_inputBalance')}
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="caption" sx={styles.itemName}>
            {t('debit')}
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end">
          <Typography variant="caption" sx={styles.itemName}>
            {t('credit')}
          </Typography>
        </Grid>
        <Grid item container xs={2} justifyContent="flex-end">
          <Typography variant="caption" sx={styles.itemName}>
            {t('selectedAccount_outputBalance')}
          </Typography>
        </Grid>
      </Grid>
      {accountBalancesMovement.map((balancesMovement) => {
        return <BalanceMovementItem key={balancesMovement.account.id} accountBalanceMovement={balancesMovement} />;
      })}
    </Grid>
  );
};

export const styles = {
  title: {
    fontWeight: 700,
    marginRight: '4px',
    color: 'colors.gray600',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: 'colors.gray500',
  },
  statusIcon: {
    color: 'colors.primary400',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  collapseTitle: {
    userSelect: 'none',
    fontWeight: 600,
    color: 'colors.gray500',
  },
  headerContainer: {
    cursor: 'pointer',
    padding: '0 16px',
    height: '52px',
    backgroundColor: 'colors.gray25',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    '&.close': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  collapseBodyContainer: {
    backgroundColor: 'colors.primary25',
    boxShadow: 'inset 0px 3px 12px rgba(0, 0, 0, 0.05)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  collapseItemTitle: {
    fontWeight: 600,
    color: 'colors.primary700',
  },
  collapseItemName: {
    fontWeight: 400,
    color: 'colors.gray300',
  },
  collapseItemValue: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
  documentsBtn: {
    background: 'none',
  },
  tooltip: {
    height: '40x',
  },
  userInfo: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50rem',
    backgroundColor: 'colors.gray50',
    color: 'colors.gray200',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&.signed': {
      backgroundColor: 'colors.secondary200',
      color: 'colors.secondary800',
    },
  },
  historyItemName: {
    fontWeight: 400,
    color: 'colors.gray300',
  },
  historyItemValue: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
  detachFileButton: {
    color: 'colors.gray500',
  },
  errorIcon: {
    color: 'colors.error',
  },
  signerContainer: {
    marginTop: '4px',
    width: '100%',
  },
  attorneyContainer: {
    marginTop: '4px',
    marginLeft: '24px',
    width: '100%',
    boxShadow: '0px 4px 12px rgba(119, 135, 150, 0.1), 0px 1px 4px rgba(119, 135, 150, 0.07)',
    borderRadius: '8px',
    padding: '8px',
  },
  toggleButton: {
    color: 'colors.gray400',
    fontSize: '0.75rem',
    cursor: 'pointer',
    '& svg': {
      marginLeft: '4px',
    },
  },
  signingAuthority: {
    fontWeight: 400,
    color: 'colors.gray300',
  },
};

import React, { useEffect, useRef } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { FrontLanguageEnum } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetBankCodes } from '@app/core/hooks/useGetBankCodes';
import { ColumnFilterType, Langs, PaginationChangeType, SortingRule } from '@app/core/types';
import { BanksTable } from '@app/pages/banksUA/components/BanksTable';
import { BankColumnEnum } from '@app/pages/banksUA/types';

export const BanksUAPage: React.FC = () => {
  const { t } = useTranslation();
  const columnFilter = useRef<ColumnFilterType<BankColumnEnum>>({});
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { i18n } = useTranslation();
  const currentLang: Langs = i18n.language as Langs;

  const { data, mutate, isLoading } = useGetBankCodes();

  const submitFilter = () => {
    const { mfo, name } = columnFilter.current;
    mutate({
      name,
      mfo: mfo ? parseInt(mfo, 10) : undefined,
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  };

  useEffect(() => {
    const { mfo, name } = columnFilter.current;
    mutate({
      name,
      mfo: mfo ? parseInt(mfo, 10) : undefined,
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  }, [currentLang, mutate]);

  const onSortHandler = (rule: SortingRule) => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    submitFilter();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    submitFilter();
  };

  const onColumnFilterChangeHandler = (columnFilterUpdater: ColumnFilterType<BankColumnEnum>, submit?: boolean) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (submit) {
      if (isLoading) {
        return;
      }
      submitFilter();
    }
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };

    if (isLoading) {
      return;
    }
    submitFilter();
  };

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('directories_ua_bank')} showNavigateButton backLink={-1} useDesktopStyle />
        <PageContainer>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{
              mt: 4,
              height: '100%',
            }}
          >
            <BanksTable
              bankCodes={data?.rows || []}
              loading={isLoading}
              columnFilter={columnFilter.current}
              onSort={onSortHandler}
              pagination={data?.pagination}
              onColumnFilterChange={onColumnFilterChangeHandler}
              onPaginationChange={onPaginationChangeHandler}
              onClearSubFilter={onClearSubFilterHandler}
            />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

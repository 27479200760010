export const styles = {
  header: {
    fontSize: '1rem',
    color: 'colors.primary700',
    fontWeight: 900,
    marginRight: '4px',
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  tabBody: {
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
  },
  multiline: {
    padding: 0,
    fontSize: '0.875rem',
    lineHeight: '20px',
    backgroundColor: 'colors.primaryTextColor',
    borderRadius: '8px',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    background: 'none',
  },
  tooltip: {
    background: 'colors.primaryTextColor',
    color: 'colors.gray400',
    fontSize: '0.75rem',
    borderRadius: '8px',
  },
  arrow: {
    color: 'colors.primaryTextColor',
  },
  info: {
    color: 'colors.primary500',
  },
  actionIcon: {
    color: 'colors.primary500',
  },
  actionButton: {
    marginLeft: 20,
  },
  downloadIcon: {
    color: 'colors.primary500',
    marginRight: 8,
  },
  textarea: {
    backgroundColor: 'colors.primaryTextColor',
    border: '1px solid',
    borderColor: 'colors.gray100',
    borderRadius: '8px',
    padding: `8px 12px`,
  },
  checkboxField: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  rejectContainer: {
    height: '44px',
    backgroundColor: 'colors.error',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  rejectReasonIcon: {
    color: '#FFF',
    marginLeft: '16px',
    marginRight: '16px',
  },
  rejectReasonText: {
    color: '#FFF',
  },
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  currencyOperationsApiClient,
  getClientId,
  GetCurrencyOperationsRequestDto,
  GetCurrencyOperationsResponseDto,
} from '@app/core/api';

const getCurrencyOperationListFn = async (
  filterData: GetCurrencyOperationsRequestDto
): Promise<GetCurrencyOperationsResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerGetCurrencyOperations(getClientId(), {
    ...filterData,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error GetCurrencyOperations.');
  }

  return response.data;
};

export const useGetCurrencyOperationList = (): UseMutationResult<
  GetCurrencyOperationsResponseDto,
  ApiError,
  GetCurrencyOperationsRequestDto
> => {
  return useMutation<GetCurrencyOperationsResponseDto, ApiError, GetCurrencyOperationsRequestDto>(
    getCurrencyOperationListFn
  );
};

import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CompanyInfoRowProps {
  rowName: string;
  name: string;
}

export const CompanyInfoRow: React.FC<CompanyInfoRowProps> = ({ rowName, name }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="caption" color="textSecondary">
          {t(`company_${rowName}`)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{name}</Typography>
      </Grid>
    </>
  );
};

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { styles } from './styles';

export type DetailsInfoItemProps = {
  description: string;
  value: string | number;
};

export const DetailsInfoItem: React.FC<DetailsInfoItemProps> = ({ description, value }) => {
  return (
    <Box mb={2}>
      <Grid container justifyContent="space-between">
        <Typography variant="body2" component="span" sx={styles.detailsInfoDescription}>
          {description}
        </Typography>
        <Typography variant="body2" component="span" sx={styles.detailsInfoValue}>
          {value}
        </Typography>
      </Grid>
    </Box>
  );
};

import React from 'react';

import {
  TableContainer,
  TableProps as MuiTableProps,
  TableHeadProps as MuiTableHeadProps,
  TableBodyProps as MuiTableBodyProps,
  TableFooterProps as MuiTableFooterProps,
  Grid,
  Theme,
  Table as MuiTable,
  SxProps,
} from '@mui/material';
import { TableContainerProps } from '@mui/material/TableContainer/TableContainer';

import { TableBody, TableFooter, TableHead } from './components';

export interface TableProps {
  container?: {
    props?: TableContainerProps;
  };
  table?: {
    content?: React.ReactNode;
    props?: MuiTableProps<typeof Grid>;
    styles?: SxProps<Theme>;
  };
  top?: {
    content?: React.ReactNode;
  };
  head?: {
    content?: React.ReactNode;
    props?: MuiTableHeadProps<typeof Grid>;
    styles?: SxProps<Theme>;
  };
  body?: {
    content?: React.ReactNode;
    props?: MuiTableBodyProps<typeof Grid>;
    styles?: SxProps<Theme>;
  };
  footer?: {
    content?: React.ReactNode;
    props?: MuiTableFooterProps<typeof Grid>;
    styles?: SxProps<Theme>;
  };
}

export const ComplexTable: React.FC<TableProps> = ({ container, table, top, head, body, footer }) => {
  return (
    <TableContainer {...container?.props}>
      {top?.content}
      <MuiTable component={Grid} container sx={table?.styles} {...table?.props}>
        {table?.content ? (
          table.content
        ) : (
          <>
            {head?.content ? (
              <TableHead sx={head.styles} {...head?.props}>
                {head.content}
              </TableHead>
            ) : null}
            {body?.content ? (
              <TableBody sx={body?.styles} {...body?.props}>
                {body.content}
              </TableBody>
            ) : null}
            {footer?.content ? (
              <TableFooter sx={footer?.styles} {...footer?.props}>
                {footer.content}
              </TableFooter>
            ) : null}
          </>
        )}
      </MuiTable>
    </TableContainer>
  );
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WaitForAnotherLoanPaymentAlert: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: '28px',
        mb: '28px',
        border: `1px solid`,
        borderColor: 'error.main',
        backgroundColor: '#FFF7F9',
        borderRadius: '8px',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" sx={{ color: 'error.main' }}>
        {t('pleaseWaitForAnotherLoanPayment')}
      </Typography>
    </Box>
  );
};

import React from 'react';

import { Grid } from '@mui/material';
import MuiTableHead from '@mui/material/TableHead';
import { TableHeadProps } from '@mui/material/TableHead/TableHead';

export const TableHead: React.FC<TableHeadProps<typeof Grid>> = ({ children, ...otherProps }) => {
  return (
    <MuiTableHead component={Grid} container {...otherProps}>
      {children}
    </MuiTableHead>
  );
};

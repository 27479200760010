import { CurrencyEnum, GetAccountResponseDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { DocumentTemplateData } from '@app/core/components';
import { PaymentFormData } from '@app/pages/payment/components/LocalPaymentEditForm/types';

export const makeCreateTemplateData = (paymentFormData: PaymentFormData): DocumentTemplateData => {
  const {
    amount,
    recipientLei,
    recipientName,
    senderAccount,
    recipientAccount,
    purpose: paymentReference,
    nonResidentFlag,
    countryCode,
    vat,
    recipientNonLeiFlag,
    recipientPassport,
    realSenderName,
    realSenderTIN,
    realSenderNonResidentFlag,
    realSenderCountryCode,
    realSenderNonLeiFlag,
    realSenderPassport,
    realRecipientName,
    realRecipientTIN,
    realRecipientNonResidentFlag,
    realRecipientCountryCode,
    realRecipientNonLeiFlag,
    realRecipientPassport,
    realSenderPresent,
    realRecipientPresent,
  } = paymentFormData;

  const parsedSenderAccount: GetAccountResponseDto = JSON.parse(senderAccount);
  return {
    senderAccountId: parsedSenderAccount.account.id,
    senderAccount: parsedSenderAccount.account.accountNumber,
    amount: Number(amount),
    recipientName,
    recipientLei: recipientNonLeiFlag ? undefined : recipientLei,
    recipientAccount,
    nonResidentFlag,
    currency: CurrencyEnum.Uah,
    paymentReference,
    countryCode: countryCode?.numeric,
    vat,
    recipientNonLeiFlag,
    recipientPassport: recipientNonLeiFlag ? recipientPassport : undefined,
    templateType: PaymentTemplateTypeEnum.General,
    realSenderNonLeiFlag,
    realRecipientNonLeiFlag,
    ...(realSenderPresent && {
      realSenderName,
      realSenderTIN,
      realSenderNonResidentFlag,
      realSenderCountryCode: realSenderCountryCode?.numeric,
      realSenderPassport: realSenderNonResidentFlag ? realSenderPassport : undefined,
    }),
    ...(realRecipientPresent && {
      realRecipientName,
      realRecipientTIN,
      realRecipientNonResidentFlag,
      realRecipientCountryCode: realRecipientCountryCode?.numeric,
      realRecipientPassport: realRecipientNonResidentFlag ? realRecipientPassport : undefined,
    }),
  };
};

import React, { useEffect, useRef, useState } from 'react';

import { MenuItem, SelectProps, IconButton, Menu } from '@mui/material';
import { format, isToday, isYesterday, isBefore, add } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { DayPickerRangeProps } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '@app/common/icons';

import { dateListItems, DatePeriod } from './constants';
import { ddMMYYYYFormat } from '../../../../constants';
import { DateRangeFromTo } from '../../../../types';
import { Select } from '../../../Select';
import { StatusText } from '../../../StatusText';
import { DatePicker } from '../../components/DatePicker';

export type DateRangeSelectFieldProps = {
  formSelectName: string;
  formDateRangeName: string;
  defaultValue?: {
    select?: DatePeriod;
    dateRange?: DateRangeFromTo;
  };
  selectProps?: SelectProps;
  datePickerProps?: Omit<DayPickerRangeProps, 'mode'>;
};

type DateRange = {
  from: Date | undefined;
  to: Date | undefined;
  enteredTo?: Date | undefined; // enteredTo: храним куда смотрит курсор мышки
};

export const DateRangeSelectField: React.FC<DateRangeSelectFieldProps> = ({
  formSelectName,
  formDateRangeName,
  defaultValue,
  selectProps,
  datePickerProps,
}) => {
  const { t } = useTranslation();
  const nowDate = new Date();
  const { control, setValue, register, watch, formState } = useFormContext();
  const selectItemRef = useRef<any>(null);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    enteredTo: defaultValue?.dateRange?.to || undefined,
    from: defaultValue?.dateRange?.from || undefined,
    to: defaultValue?.dateRange?.to || undefined,
  });
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const resetEnabledRef = useRef<boolean>(false);

  useEffect(() => {
    register(formDateRangeName, { required: false, value: defaultValue?.dateRange });
  }, [register, formDateRangeName, defaultValue?.dateRange]);

  const formDateRangeNameWatch = watch(formDateRangeName);

  useEffect(() => {
    if (isEmpty(formDateRangeNameWatch)) {
      if (resetEnabledRef.current) {
        setDateRange({
          from: undefined,
          to: undefined,
          enteredTo: undefined,
        });
      }
    } else {
      setDateRange({
        from: formDateRangeNameWatch.from,
        to: formDateRangeNameWatch.to,
        enteredTo: formDateRangeNameWatch.to,
      });
    }
  }, [formDateRangeNameWatch, formState]);

  useEffect(() => {
    if (!isEmpty(formDateRangeNameWatch)) {
      resetEnabledRef.current = true;
    }
  }, [formDateRangeNameWatch]);

  const onChangeHandler =
    (onChange: (...event: any[]) => void) =>
    (event: (Event & { target: { value: unknown; name: string } }) | React.ChangeEvent<HTMLInputElement>) => {
      const dateVariant = event.target.value;

      onChange(dateVariant);

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      const startOfMonth = new Date();
      startOfMonth.setDate(1);
      const offset = new Date().getTimezoneOffset() * 60000;
      switch (dateVariant) {
        case DatePeriod.for_today:
          const startTodayDate = new Date();
          const endTodayDate = new Date();
          startTodayDate.setHours(0, 0, 0, 0);
          endTodayDate.setHours(23, 59, 59, 0);

          setValue(formDateRangeName, {
            from: new Date(new Date(startTodayDate).getTime() - offset),
            to: new Date(new Date(endTodayDate).getTime() - offset),
          });
          break;
        case DatePeriod.for_yesterday:
          setValue(formDateRangeName, {
            from: yesterday,
            to: yesterday,
          });
          break;
        case DatePeriod.for_mounts:
          setValue(formDateRangeName, {
            from: startOfMonth,
            to: nowDate,
          });
          break;
        default:
          break;
      }
      setSelectOpen(false);
    };

  const renderValue = (value: SelectProps['value']): React.ReactNode => {
    const fromDateFormatted = dateRange.from ? format(dateRange.from, ddMMYYYYFormat.format) : '';
    const toDateFormatted = dateRange.to ? format(dateRange.to, ddMMYYYYFormat.format) : '';
    if (value) {
      switch (value) {
        case DatePeriod.for_range:
          return `${fromDateFormatted} - ${toDateFormatted}`;

        default:
          return t(`${value}`);
      }
    }
    if (fromDateFormatted || toDateFormatted) {
      return `${fromDateFormatted} - ${toDateFormatted}`;
    }
    return '';
  };

  const onPopoverClose = () => {
    setCalendarOpen(false);
  };

  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date) => {
    const isRangeSelected = from && to;
    return !from || isRangeSelected;
  };

  const handleDayClick = (date: Date, modifiers: any) => {
    const { from, to } = dateRange;
    if (modifiers.disabled) {
      return;
    }
    const day = add(date, { hours: 12 });
    if (isSelectingFirstDay(from, to, day)) {
      setDateRange({
        from: day,
        to: undefined,
        enteredTo: day,
      });
    } else {
      const fromDate: Date = isBefore(day, from!) ? day : from!;
      const toDate: Date = isBefore(day, from!) ? from! : day;
      setDateRange({
        from: fromDate,
        to: toDate,
        enteredTo: toDate,
      });
      setValue(formDateRangeName, {
        from: fromDate,
        to: toDate,
      });
      setCalendarOpen(false);
    }
  };

  const { from, enteredTo } = dateRange!;
  const disabledDays = { before: new Date(2018, 0, 1) };
  const selectedDays = { from, to: enteredTo };

  const onForRangeMenuItemClickHandler = () => {
    setCalendarOpen(true);
  };

  const selectOptionByDatePeriod = ({ from: fromDate, to: toDate }: { from: Date; to: Date }): DatePeriod => {
    const offset = fromDate.getTimezoneOffset() * 60 * 1000;

    const fromDateWithoutOffset = fromDate.getTime() + offset;
    const toDateWithoutOffset = toDate.getTime() + offset;

    if (fromDate && toDate && isToday(fromDateWithoutOffset) && isToday(toDateWithoutOffset)) {
      return DatePeriod.for_today;
    }

    if (fromDate && toDate && isYesterday(fromDateWithoutOffset) && isYesterday(toDateWithoutOffset)) {
      return DatePeriod.for_yesterday;
    }

    return DatePeriod.for_range;
  };

  const selectOnOpenHandler = (event: React.SyntheticEvent) => {
    selectItemRef.current = event.target;
    setSelectOpen(true);
  };

  const selectOnCloseHandler = () => {
    setSelectOpen(false);
  };

  const calendarIconClickHandler = () => {
    setSelectOpen(!selectOpen);
  };

  const CalendarIconButton = (): JSX.Element => {
    return (
      <IconButton
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          right: 0,
          padding: '12px',
        }}
        onClick={calendarIconClickHandler}
        size="large"
      >
        <CalendarIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <Controller
      name={formSelectName}
      control={control}
      defaultValue={defaultValue?.dateRange ? selectOptionByDatePeriod(defaultValue.dateRange) : ''}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <>
            <Select
              name={formSelectName}
              value={value}
              open={selectOpen}
              onOpen={selectOnOpenHandler}
              onClose={selectOnCloseHandler}
              displayEmpty
              fullWidth
              ref={selectItemRef}
              options={dateListItems(t)}
              onChange={onChangeHandler(onChange)}
              renderValue={renderValue}
              IconComponent={CalendarIconButton}
              sx={{
                '&.MuiSelect-icon': {
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 0,
                  padding: '12px',
                },
              }}
              error={Boolean(error)}
              {...selectProps}
            >
              <MenuItem value={DatePeriod.for_range} onClick={onForRangeMenuItemClickHandler}>
                {t(DatePeriod.for_range)}
              </MenuItem>
            </Select>
            {error && error.message && <StatusText>{error.message}</StatusText>}
            <Menu
              open={calendarOpen}
              anchorEl={selectItemRef.current}
              onClose={onPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <DatePicker
                defaultMonth={selectedDays?.from || new Date()}
                mode="range"
                showOutsideDays
                selected={selectedDays}
                disabled={disabledDays}
                onDayClick={handleDayClick}
                {...datePickerProps}
              />
            </Menu>
          </>
        );
      }}
    />
  );
};

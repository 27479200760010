import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, accountsApiClient, getClientId, SetDefaultAccountDto } from '@app/core/api';

export type UpdateAccountProps = {
  id: string;
  name?: string;
  favorite?: boolean;
};

const setDefaultAccountQueryFn = async ({ accountId, type, currencyCode }: SetDefaultAccountDto): Promise<object> => {
  const result = await accountsApiClient.accountsControllerSetDefaultAccount(getClientId(), {
    type,
    currencyCode,
    accountId,
  });
  return result.data;
};

export const useSetDefaultAccount = (): UseMutationResult<object, ApiError, SetDefaultAccountDto> => {
  return useMutation<object, ApiError, SetDefaultAccountDto>(setDefaultAccountQueryFn);
};

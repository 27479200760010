import { OperationHistoryItemDtoActionTypeEnum } from '@app/core/api';
import { SwiftFilterFormData } from '@app/pages/swift/components/SwiftTable/types';

export const makeDataForSubmit = (data: SwiftFilterFormData) => {
  const {
    operationAccounts,
    dateRange,
    status,
    currency,
    documentNumber,
    counterPartyAccountNumber,
    paymentReference,
    commissionType,
    amountMax,
    amountMin,
    createdBy,
  } = data;
  const endDate = dateRange?.to.toISOString();
  const startDate = dateRange?.from.toISOString();
  const accountIds = operationAccounts.map((account) => account.account.id);
  return {
    accountIds,
    dateRange: { startDate, endDate },
    status,
    currency: Boolean(currency) ? currency : undefined,
    documentNumber: Boolean(documentNumber) ? documentNumber : undefined,
    counterPartyAccountNumber: Boolean(counterPartyAccountNumber) ? counterPartyAccountNumber : undefined,
    paymentReference: Boolean(paymentReference) ? paymentReference : undefined,
    commissionType: Boolean(commissionType) ? commissionType : undefined,
    amountMin: Boolean(amountMin) ? Number(amountMin) : undefined,
    amountMax: Boolean(amountMax) ? Number(amountMax) : undefined,
    createdBy: Boolean(createdBy) ? createdBy : undefined,
  };
};

export const getActionTranslationKeyByActionTypeEnum = (status: OperationHistoryItemDtoActionTypeEnum): string => {
  switch (status) {
    case OperationHistoryItemDtoActionTypeEnum.AddFile:
      return 'actionType_AddFile';
    case OperationHistoryItemDtoActionTypeEnum.DetachFile:
      return 'actionType_DetachFile';
    case OperationHistoryItemDtoActionTypeEnum.Created:
      return 'documentChangeAction_Created';
    case OperationHistoryItemDtoActionTypeEnum.Edited:
      return 'documentChangeAction_Edited';
    case OperationHistoryItemDtoActionTypeEnum.Deleted:
      return 'documentChangeAction_Deleted';
    case OperationHistoryItemDtoActionTypeEnum.ToSign:
      return 'documentChangeAction_ToSign';
    case OperationHistoryItemDtoActionTypeEnum.OnSigning:
      return 'documentChangeAction_OnSigning';
    case OperationHistoryItemDtoActionTypeEnum.Signed:
      return 'documentChangeAction_Signed';
    case OperationHistoryItemDtoActionTypeEnum.Executed:
      return 'documentStatus_executed';
    case OperationHistoryItemDtoActionTypeEnum.Rejected:
      return 'documentStatus_rejected';
    case OperationHistoryItemDtoActionTypeEnum.WaitingForDocument:
      return 'documentStatus_waitingForDocument';
    case OperationHistoryItemDtoActionTypeEnum.OperationAttached:
      return 'documentChangeAction_operationAttached';
    case OperationHistoryItemDtoActionTypeEnum.OperationDetach:
      return 'documentChangeAction_operationDetach';
    case OperationHistoryItemDtoActionTypeEnum.Accepted:
      return 'documentStatus_accepted';
    case OperationHistoryItemDtoActionTypeEnum.Processing:
      return 'documentChangeAction_Processing';
    case OperationHistoryItemDtoActionTypeEnum.RemovalOfSignature:
      return 'REMOVAL_OF_SIGNATURE';
    case OperationHistoryItemDtoActionTypeEnum.SignedFromAnotherUser:
      return 'SIGNED_FROM_ANOTHER_USER';
    default:
      return 'error key';
  }
};

export const styles = {
  item: {
    padding: '8px 4px',
  },
  actionTableButtons: {
    '& svg': {
      fill: 'colors.primary500',
    },
    '& button': {
      marginRight: '8px',
    },
  },
  fontMiddleWeight: {
    fontWeight: 600,
  },
  statusKeyActive: {
    backgroundColor: 'colors.success',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
  statusKeyClose: {
    backgroundColor: 'colors.error',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
  sortName: {
    cursor: 'pointer',
    '& svg': {
      opacity: 0,
      verticalAlign: 'text-top',
      fill: 'colors.primary500',
    },
    '&:hover': {
      '& svg': {
        opacity: 1,
      },
    },
  },
  tableRowHead: {
    border: 'none',
    padding: '12px 16px',
  },
  tableCell: {
    flex: 1,
    paddingTop: '12px',
    paddingBottom: '12px',
    minWidth: '40px',
  },
  searchIconBtn: {
    color: 'colors.gray300',
  },
  settingIconBtn: {
    color: 'colors.gray300',
    marginRight: '-16px',
  },
  chevron: {
    color: 'colors.gray200',
  },
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_header: {
    color: 'colors.gray700',
  },
  cell_body: {},
  cell_documentNumber: {
    maxWidth: '116px',
  },
  cell_operationDate: {
    maxWidth: '100px',
    minWidth: '100px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_documentDate: {
    maxWidth: '100px',
    minWidth: '100px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_recipientAccountName: {},
  cell_LEI: {},
  cell_paymentReference: {
    flexBasis: '150%',
    minWidth: '160px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_accountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_currency: {},
  cell_amount: {
    textAlign: 'end',
    minWidth: '116px',
    maxWidth: '116px',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_deposit: {
    color: 'colors.gray600',
    fontWeight: 600,
    minWidth: '116px',
    maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_withdrawal: {
    color: 'colors.gray600',
    fontWeight: 600,
    minWidth: '116px',
    maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_bankCode: {},
  cell_bankName: {},
  cell_recipientAccountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_documentStatus: {},
  cell_actions: {
    width: '86px',
    flexShrink: 0,
  },
  cell_actions_header: {},
  cell_selection: {
    width: '40px',
    flexShrink: 0,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  status: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  statusRejected: {
    backgroundColor: 'colors.error',
  },
  statusExecuted: {
    backgroundColor: 'colors.success',
  },
  accountNumberPart1: {
    color: 'colors.gray700',
  },
  accountNumberPart2: {
    color: 'colors.gray700',
    fontWeight: 600,
  },
};

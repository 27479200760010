import { isValidIBAN } from 'ibantools';
import { string, object, SchemaOf } from 'yup';

import { isValidBIC } from '@app/core/utils';
import { SwiftCounterpartyFormFieldsEnum } from '@app/pages/counterpartiesSwift/types';

type SchemaValidationObject = {
  [SwiftCounterpartyFormFieldsEnum.account]: string;
  [SwiftCounterpartyFormFieldsEnum.name]: string;
  [SwiftCounterpartyFormFieldsEnum.countryCode]: string;
  [SwiftCounterpartyFormFieldsEnum.bic]: string;
  [SwiftCounterpartyFormFieldsEnum.bankName]: string;
  [SwiftCounterpartyFormFieldsEnum.address]: string;
  [SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic]?: string;
  [SwiftCounterpartyFormFieldsEnum.correspondentBank]?: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [SwiftCounterpartyFormFieldsEnum.name]: string().required(t('paymentCreationLocalPayment_field_required')),
    [SwiftCounterpartyFormFieldsEnum.account]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(SwiftCounterpartyFormFieldsEnum.account, (value, { createError, path }) => {
        if (value && !isValidIBAN(value)) {
          return createError({
            path,
            message: t('paymentCreationLocalPayment_recipient_error_format'),
          });
        } else if (value && Boolean(value.slice(0, 2).match('UA'))) {
          return createError({
            path,
            message: t('swiftCounterparties_recipient_error_ua_iban'),
          });
        }
        return true;
      }),
    [SwiftCounterpartyFormFieldsEnum.countryCode]: object()
      .shape({
        numeric: string().required(t('swiftCreationPayment_field_required')),
        alpha2: string().required(t('swiftCreationPayment_field_required')),
        alpha3: string().required(t('swiftCreationPayment_field_required')),
        name: string().required(t('swiftCreationPayment_field_required')),
      })
      .nullable()
      .required(t('swiftCreationPayment_field_required')),
    [SwiftCounterpartyFormFieldsEnum.address]: string().required(t('paymentCreationLocalPayment_field_required')),
    [SwiftCounterpartyFormFieldsEnum.bic]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test('recipientSwiftBic', t('swiftCreationPayment_bic_error'), (value) => {
        return value ? isValidBIC(value) : false;
      }),
    [SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic]: string().when(
      [SwiftCounterpartyFormFieldsEnum.correspondentBankCheck],
      {
        is: true,
        then: string().test('recipientSwiftBic', t('swiftCreationPayment_bic_error'), (value) => {
          return value ? isValidBIC(value) : false;
        }),
      }
    ),
    [SwiftCounterpartyFormFieldsEnum.bankName]: string().required(t('paymentCreationLocalPayment_field_required')),
    [SwiftCounterpartyFormFieldsEnum.correspondentBank]: string().when(
      [SwiftCounterpartyFormFieldsEnum.correspondentBankCheck],
      {
        is: true,
        then: string().test('correspondentBank', (value, { createError, path }) => {
          if (Boolean(value?.match(/[%@&*#$«"№;\_]/))) {
            return createError({
              path,
              message: t('swiftCreationPayment_invalid_character_error'),
            });
          } else if (!Boolean(value?.match(/^[A-Za-z0-9\s/\-?:().,'+]*$/))) {
            return createError({
              path,
              message: t('swiftCreationPayment_field_containLatin'),
            });
          }
          return true;
        }),
      }
    ),
  });
};

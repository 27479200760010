import React, { useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useSession } from '@app/core/hooks';
import { ReactComponent as SessionTimerSvg } from '@app/themes/default/assets/icons/modalSessionTimer.svg';

import { ModalPopupCommon } from '../Modal';

export const Session: React.FC = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const { loading, seconds, minutes, continueSession, cancelSession } = useSession({
    onFinalCountdown: () => {
      setShowPopup(true);
    },
    onContinueSession: () => {
      setShowPopup(false);
    },
  });

  const onCancelSessionClickHandler = () => {
    setShowPopup(false);
    cancelSession();
  };

  const onContinueSessionClickHandler = () => {
    continueSession();
  };

  return (
    <ModalPopupCommon questionPaper open={showPopup}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '-12px',
            top: '12px',
            margin: 'auto 0',
          }}
        >
          <SessionTimerSvg />
        </Box>

        <Box pt={6} pb={1}>
          <IconButton
            onClick={onCancelSessionClickHandler}
            size="small"
            sx={{
              position: 'absolute',
              top: '28px',
              right: '20px',
            }}
          >
            <CrossIcon fontSize="small" />
          </IconButton>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Box mb={3}>
              <Typography variant="h6">{t('session_continue_session_question')}</Typography>
            </Box>
            <Box mb={7}>
              <Typography variant="body1">
                {t('session_expire_text')} 0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            </Box>
            <Grid container justifyContent="center">
              <Box pr={6}>
                <Button size="small" variant="outlined" color="primary" onClick={onCancelSessionClickHandler}>
                  {t('session_cancel_button')}
                </Button>
              </Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={onContinueSessionClickHandler}
              >
                {t('session_continue_button')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalPopupCommon>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DownChevronSmallIcon, FilterIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ApiError,
  DealStateEnum,
  GetLoansListRequestDto,
  GetLoansListResponseDto,
  InternalLoanSortingRuleDto,
} from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useProfileControllerSendRequest } from '@app/core/hooks';
import { TableColumnConfig, PaginationChangeType } from '@app/core/types';
import { makeDataFromSubmit } from '@app/pages/loans/components/helpers';
import { LoansFilter } from '@app/pages/loans/components/LoansFilter';
import { LoansTable } from '@app/pages/loans/components/LoansTable';
import { getLoansFn, loansControllerUpdateFn } from '@app/pages/loans/query';
import { styles } from '@app/pages/loans/styles';
import { fieldsCreditTable } from '@app/pages/loans/tableFields';
import { LoansFilterFormData, LoansTypeEnum } from '@app/pages/loans/types';
import { requestTopic } from '@app/src/config';
import { ReactComponent as CreditPlaceholder } from '@app/themes/default/assets/icons/creditPlaceholder.svg';

const defaultValues: LoansFilterFormData = {
  agreementStartDateFrom: undefined,
  agreementStartDateTo: undefined,
  agreementEndDateFrom: undefined,
  agreementEndDateTo: undefined,
  agreementNumber: '',
  amountFrom: '',
  amountTo: '',
  currency: '',
  interestRateFrom: '',
  interestRateTo: '',
  productType: '',
  temp_agreementStartDateFrom: '',
  temp_agreementStartDateTo: '',
  temp_agreementEndDateFrom: '',
  temp_agreementEndDateTo: '',
};

export const LoansContent: React.FC = () => {
  const [fullItems, setFullItems] = useState(false);
  const { t } = useTranslation();
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const dealStateRef = useRef<DealStateEnum[]>([DealStateEnum.New, DealStateEnum.Active]);
  const sortRuleRef = useRef<InternalLoanSortingRuleDto | undefined>();

  const { notify } = useNotify();

  const { refetch, isSuccess } = useProfileControllerSendRequest(requestTopic.loan);

  const loansTabsItems: MenuItem<LoansTypeEnum>[] = [
    {
      id: 1,
      labelText: t('open'),
      value: LoansTypeEnum.open,
    },
    {
      id: 2,
      labelText: t('closed'),
      value: LoansTypeEnum.closed,
    },
  ];

  const methods = useForm<LoansFilterFormData>({ mode: 'onChange', defaultValues });
  const { handleSubmit, reset: formReset, formState, getValues, control } = methods;

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<GetLoansListResponseDto, ApiError, GetLoansListRequestDto>(getLoansFn);
  const loanOperations = data?.loans || [];
  const pagination = data?.pagination;

  const [activeTab, setActiveTab] = useState<LoansTypeEnum>(LoansTypeEnum.open);

  const updateList = () => {
    mutate({
      ...makeDataFromSubmit(getValues()),
      pagination: paginationRef.current,
      sort: sortRuleRef.current,
      states: dealStateRef.current,
    });
  };

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
      sort: sortRuleRef.current,
      states: dealStateRef.current,
    });
  }, [mutate]);

  const handleChange = (currentTab: LoansTypeEnum): void => {
    setActiveTab(currentTab);
    if (currentTab === LoansTypeEnum.open) {
      dealStateRef.current = [DealStateEnum.New, DealStateEnum.Active];
    }
    if (currentTab === LoansTypeEnum.closed) {
      dealStateRef.current = [DealStateEnum.Closed, DealStateEnum.Deleted, DealStateEnum.WillBeClosed];
    }
    updateList();
  };
  const onSubmit = () => {
    updateList();
  };

  const resetFormHandler = () => {
    formReset();
    mutationReset();
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({ pagination: paginationRef.current, sort: sortRuleRef.current, states: dealStateRef.current });
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateList();
    }
  };

  const onSortHandler = (rule: InternalLoanSortingRuleDto) => {
    sortRuleRef.current = rule;
    updateList();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    if (data?.loans.length) {
      updateList();
    }
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const configurationSavedHandler = (tableColumnsData: TableColumnConfig[]) => {
    // dispatch(updateOperationsTableConfig(tableColumnsData));
  };

  const sendRequest = () => {
    refetch();
  };
  useEffect(() => {
    if (isSuccess) {
      notify({
        notifyProps: {
          title: t('success'),
          message: t('sendRequestSuccess'),
          severity: 'success',
        },
      });
    }
  }, [isSuccess, notify, t]);

  return (
    <>
      <HorizontalMenu<LoansTypeEnum> onChange={handleChange} menuItems={loansTabsItems} />
      {loanOperations.length || formState.isDirty ? (
        <Grid item container direction="column" wrap="nowrap">
          <Box pb={4} mt={7}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item>
                <FilterIcon />
                <Typography variant="h6" component="span" sx={styles.captionFilter}>
                  {t('filters')}
                </Typography>
              </Grid>
              <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
                {t('additionalParameters')}
                {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
              </Button>
            </Grid>
          </Box>
          <Box pb={4}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyPress={onKeyPressHandler}
                role="presentation"
                style={styles.verticalWrapper}
              >
                <LoansFilter
                  fullItems={fullItems}
                  namesFieldWithError={Object.keys(formState.errors)}
                  resetFormHandler={resetFormHandler}
                />
              </form>
            </FormProvider>
          </Box>
          <LoansTable
            loading={isLoading}
            loans={loanOperations}
            columnsConfig={fieldsCreditTable}
            columnsConfigMutationFn={loansControllerUpdateFn}
            pagination={pagination}
            onSort={onSortHandler}
            onPaginationChange={onPaginationChangeHandler}
            onSuccessConfigurationSaved={configurationSavedHandler}
          />
        </Grid>
      ) : (
        <>
          {!isLoading ? (
            <Grid container alignItems="center" justifyContent="center" sx={styles.placeholder} direction="column">
              <CreditPlaceholder />
              {activeTab === LoansTypeEnum.open ? (
                <>
                  <Typography variant="h5" sx={styles.placeholderTitle}>
                    {t('loansPlaceholderTitle')}
                  </Typography>
                  <Typography variant="body2" sx={styles.placeholderDescription}>
                    {t('loansPlaceholderDescription')}
                  </Typography>
                  <Box mt={5}>
                    <Button size="small" variant="contained" color="primary" onClick={sendRequest}>
                      {t('submitApplication')}
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography variant="h5" sx={styles.placeholderTitle}>
                  {t('loansClosedPlaceholderTitle')}
                </Typography>
              )}
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
};

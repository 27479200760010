export const styles = {
  root: {
    backgroundColor: 'colors.gray25',
    marginBottom: '4px',
    padding: '12px 20px 12px 31px',
    borderRadius: '4px',
  },
  statementName: {
    color: 'colors.gray600',
    marginLeft: '4px',
    lineHeight: '24px',
  },
};

import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Severity } from '@app/core/types';

import { Select, SelectProps } from '../../Select';
import { StatusText } from '../../StatusText';

export interface SelectFieldProps extends SelectProps {
  name: string;
  errorSeverity?: Severity;
}

export const SelectField: React.FC<SelectFieldProps> = ({ name, defaultValue, errorSeverity, ...otherProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <>
            <Select
              sx={{
                ...(errorSeverity === 'warning' && {
                  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': { borderColor: 'colors.warning' },
                }),
              }}
              name={name}
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              error={Boolean(error)}
              {...otherProps}
            />
            {error && error.message ? (
              <StatusText severity={errorSeverity} absolute>
                {error.message}
              </StatusText>
            ) : null}
          </>
        );
      }}
    />
  );
};

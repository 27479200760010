import axios from 'axios';
import Cookies from 'js-cookie';

import {
  AccountsApi,
  AuthApi,
  Configuration,
  ConfigurationApi,
  CounterpartiesApi,
  CurrencyOperationsApi,
  DashboardApi,
  DictionariesApi,
  ExchangeRatesApi,
  ImportPaymentOrdersApi,
  PaymentOrdersApi,
  ProfileApi,
  SessionsApi,
  StatementsApi,
  TemplatesApi,
  ArchiveExchangeRatesApi,
  LegalEntityApi,
  CurrencyDocumentsApi,
  SWIFTApi,
  LoansTranchesApi,
  DepositApi,
  OverdraftsApi,
  LoanLinesApi,
  LoansTranchesLoanLinesApi,
  FilesApi,
  MessagesApi,
  CategoriesApi,
} from './client';
import { SignFilesApi } from './custom/signFilesApi';
import { serviceConfig } from '../../config';
import { isDevelopment } from '../utils';

export const getAccessToken = (): string => (Cookies.get('UserToken') ? `${Cookies.get('UserToken')}` : '');

export const getClientId = (): string => Cookies.get('ClientId') || '';

const configuration = new Configuration({
  basePath: serviceConfig.apiUrl,
  accessToken: getAccessToken,
});

export const profileApiClient = (() => {
  return new ProfileApi(configuration);
})();

export const dashboardApiClient = (() => {
  return new DashboardApi(configuration);
})();

export const exchangeRatesApiClient = (() => {
  return new ExchangeRatesApi(configuration);
})();

export const statementsApiClient = (() => {
  return new StatementsApi(configuration);
})();

export const dictionariesApiClient = (() => {
  return new DictionariesApi(configuration);
})();

export const templatesApiClient = (() => {
  return new TemplatesApi(configuration);
})();

export const authApiClient = (() => {
  return new AuthApi(configuration);
})();

export const paymentOrdersApiClient = (() => {
  return new PaymentOrdersApi(configuration);
})();

export const accountsApiClient = (() => {
  return new AccountsApi(configuration);
})();

export const sessionApiClient = (() => {
  return new SessionsApi(configuration);
})();

export const configurationApiClient = (() => {
  return new ConfigurationApi(configuration);
})();

export const counterpartiesApiClient = (() => {
  return new CounterpartiesApi(configuration);
})();

export const importPaymentOrdersApiClient = (() => {
  return new ImportPaymentOrdersApi(configuration);
})();

export const currencyOperationsApiClient = (() => {
  return new CurrencyOperationsApi(configuration);
})();

export const archiveExchangeRatesApiClient = (() => {
  return new ArchiveExchangeRatesApi(configuration);
})();

export const legalEntityApiClient = (() => {
  return new LegalEntityApi(configuration);
})();

export const currencyDocumentsApiClient = (() => {
  return new CurrencyDocumentsApi(configuration);
})();

export const swiftApiClient = (() => {
  return new SWIFTApi(configuration);
})();

export const loansTranchesApiApiClient = (() => {
  return new LoansTranchesApi(configuration);
})();

export const depositsApiClient = (() => {
  return new DepositApi(configuration);
})();

export const overdraftsApiClient = (() => {
  return new OverdraftsApi(configuration);
})();

export const loanLinesApiApiClient = (() => {
  return new LoanLinesApi(configuration);
})();

export const loansTranchesLoanLinesApiClient = (() => {
  return new LoansTranchesLoanLinesApi(configuration);
})();

export const signFilesApiClient = (() => {
  return new SignFilesApi(configuration);
})();

export const filesApiClient = (() => {
  return new FilesApi(configuration);
})();

export const messagesApiClient = (() => {
  return new MessagesApi(configuration);
})();

export const categoriesApiClient = (() => {
  return new CategoriesApi(configuration);
})();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !isDevelopment()) {
      sessionStorage.removeItem('LastLogin');
      Cookies.set('lastPage', location.pathname, {
        sameSite: 'strict',
        secure: true,
        domain: serviceConfig.cookiesDomain,
      });
      setTimeout(() => {
        window.location.href = serviceConfig.loginUrl;
      }, 50);
    }
    return error;
  }
);

import React, { useEffect, useRef } from 'react';

import { PaginationRequestDto } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetDepositTranches } from '@app/core/hooks/useGetDepositTranches';
import { PaginationChangeType } from '@app/core/types';
import { TableContent } from '@app/pages/deposits/show/components/TranchesTable/components/TableContent';

interface TranchesTableProps {
  uuid: string;
}

export const TranchesTable: React.FC<TranchesTableProps> = ({ uuid }) => {
  const paginationRef = useRef<PaginationRequestDto>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { data, mutate } = useGetDepositTranches();

  useEffect(() => {
    mutate({ uuid, pagination: paginationRef.current });
  }, [mutate, uuid]);

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    mutate({ uuid, pagination: paginationRef.current });
  };
  return (
    <>
      {data ? (
        <TableContent
          data={data.deposits}
          onPaginationChange={onPaginationChangeHandler}
          pagination={data.pagination}
        />
      ) : null}
    </>
  );
};

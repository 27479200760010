import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SignatureIcon } from '@app/common/icons';
import { CheckAvailabilityFundsDtoStatusEnum } from '@app/core/api';
import { useCheckAvailabilityFunds } from '@app/core/hooks';
import { ReactComponent as SignatureBigSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

import { styles } from './style';
import { Operation } from '../../types';
import { formatAmount } from '../../utils';
import { ModalCommon } from '../Modal';

interface ConfirmSignPaymentMultipleOrderDialogProps {
  onAgreeSign(): void;
  onClose(): void;
  selectedCount: number;
  toSignDocuments: Operation[];
}

export const ConfirmSignPaymentMultipleOrderDialog: React.FC<ConfirmSignPaymentMultipleOrderDialogProps> = ({
  onAgreeSign,
  onClose,
  selectedCount,
  toSignDocuments,
}) => {
  const { t } = useTranslation();
  const { data: checkAvailabilityData, mutate } = useCheckAvailabilityFunds();
  const [insufficientFundsError, setInsufficientFundsError] = useState<boolean>(false);

  useEffect(() => {
    mutate(toSignDocuments.map((item) => item.uuid));
  }, [mutate, toSignDocuments]);

  useEffect(() => {
    if (checkAvailabilityData) {
      checkAvailabilityData.response.forEach((item) => {
        if (item.status === CheckAvailabilityFundsDtoStatusEnum.InsufficientFunds) {
          setInsufficientFundsError(true);
        }
      });
    }
  }, [checkAvailabilityData, toSignDocuments]);

  const amount = toSignDocuments.reduce((acc, document) => {
    return acc + document.amount;
  }, 0);

  return (
    <ModalCommon onClose={onClose} sx={styles.root} open>
      <Box sx={styles.paper}>
        {insufficientFundsError ? (
          <Box sx={styles.errorNotifyContainer}>
            <Typography variant="caption" sx={styles.errorNotifyText}>
              {t('insufficientFundsError')}
            </Typography>
          </Box>
        ) : null}
        <Box sx={styles.iconBg}>
          <SignatureBigSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <Box mt={4} mr={4}>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        <Box mt={3} mb={7}>
          <Typography variant="body1" sx={styles.title}>
            {t('documentsToSign')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_selectedDocuments')}
            {': '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {selectedCount}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_documentsToSign')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {toSignDocuments.length}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_totalAmount')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {formatAmount(amount)}
            </Typography>{' '}
            {toSignDocuments[0].currency}
          </Typography>
        </Box>
        <Box mt={4}>
          <Grid container justifyContent="center" wrap="nowrap">
            <Box pr={6}>
              <Button size="small" variant="outlined" color="primary" onClick={onClose}>
                {t('cancel')}
              </Button>
            </Box>
            <Button size="small" variant="contained" color="primary" endIcon={<SignatureIcon />} onClick={onAgreeSign}>
              {t('sign')}
            </Button>
          </Grid>
        </Box>
      </Box>
    </ModalCommon>
  );
};

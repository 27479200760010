import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, PaperIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import { DocumentDto } from '@app/core/api';
import { Ellipsis } from '@app/core/components';
import { useDocumentsDeleteFiles } from '@app/core/hooks';
import { FileType } from '@app/core/types';
import { formatFileSize, isMobile } from '@app/core/utils';

import { styles } from './style';

interface MessageAttachFilesProps {
  documents?: Array<DocumentDto>;
  files: FileType[];
  onFileSelectClick(): void;
  onFileDeleteClick(file: FileType): void;
}

export const MessageAttachFiles: React.FC<MessageAttachFilesProps> = ({
  files,
  documents,
  onFileSelectClick,
  onFileDeleteClick,
}) => {
  const [attachedDocuments, setAttachedDocuments] = useState<DocumentDto[]>([]);
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { mutateAsync: mutateAsyncDocumentsDeleteFiles, isLoading } = useDocumentsDeleteFiles();
  const totalFileSize = files.reduce((acc, file) => {
    return acc + file.file.size;
  }, 0);

  useEffect(() => {
    if (documents) {
      setAttachedDocuments(documents);
    }
  }, [documents]);
  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const onDetachDocumentClick = (document: DocumentDto) => {
    mutateAsyncDocumentsDeleteFiles({ uuids: [document.uuid] }).then(() => {
      setAttachedDocuments(attachedDocuments.filter((item) => item.uuid !== document.uuid));
    });
  };

  return (
    <Grid container item direction="column" wrap="nowrap">
      <Box pt={4} pb={4}>
        <Typography sx={styles.headerFile}>{t('downloadFiles')}</Typography>
      </Box>
      <Grid container direction={isMobile ? 'column' : 'row'}>
        <Grid
          container
          item
          xs={isMobile ? false : 8}
          alignItems="center"
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          sx={styles.dropZoneContainer}
        >
          <Box pb={1}>
            <Typography variant="body2" component="span" color="textPrimary">
              {t('importPaymentsDialog_dragFileInfoText')}
            </Typography>{' '}
            <Typography
              variant="body2"
              component={isMobile ? 'div' : 'span'}
              sx={styles.selectFileFromDiskText}
              onClick={onFileSelectClick}
            >
              {t('importPaymentsDialog_selectFileFromDiskText')}
            </Typography>
          </Box>

          <Typography variant="caption" component="div" sx={styles.maxFileSize}>
            {t('maxFileSizeShortText')} 10 MB
          </Typography>
        </Grid>

        <Grid container item xs={isMobile ? false : 4} direction="column" wrap="nowrap">
          {attachedDocuments.length > 0 ? (
            <>
              {attachedDocuments.map((attachedDocument, i) => {
                return (
                  <Box key={`${attachedDocument.originalName}_${i}`} pl={isMobile ? 0 : 4} width="100%">
                    <Grid item container wrap="nowrap" alignItems="center">
                      <Grid item xs={8} container alignItems="center" wrap="nowrap">
                        <PaperIcon fontSize="small" sx={styles.icon} />
                        <Ellipsis
                          text={attachedDocument.originalName}
                          styles={{
                            color: 'colors.gray500',
                            fontSize: '0.75rem',
                            paddingLeft: '8px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={4} alignItems="center" justifyContent="flex-start">
                        <CrossIcon
                          fontSize="small"
                          sx={styles.iconDelete}
                          onClick={() => onDetachDocumentClick(attachedDocument)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </>
          ) : null}

          {files.length ? (
            <>
              {files.map((file, i) => {
                return (
                  <Box key={`${file.file.name}_${i}`} pl={isMobile ? 0 : 4} width="100%">
                    <Grid item container wrap="nowrap" alignItems="center">
                      <Grid item xs={8} container alignItems="center" wrap="nowrap">
                        <PaperIcon fontSize="small" sx={styles.icon} />
                        <Ellipsis
                          text={file.file.name}
                          styles={{
                            color: 'colors.gray500',
                            fontSize: '0.75rem',
                            paddingLeft: '8px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={4} alignItems="center" justifyContent="flex-start">
                        <Typography variant="caption" sx={styles.fileSize}>
                          {file.size} MB
                        </Typography>
                        <CrossIcon fontSize="small" sx={styles.iconDelete} onClick={() => onFileDeleteClick(file)} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </>
          ) : null}
          {attachedDocuments.length === 0 && files.length === 0 ? (
            <Box pt={2} pl={isMobile ? 0 : 4} width="100%">
              <Grid container wrap="nowrap">
                <PaperIcon fontSize="small" sx={styles.icon} />
                <Typography variant="caption" sx={styles.noAttachedFilesLabel}>
                  {t('noAttachedFiles')}
                </Typography>
              </Grid>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Box pt={4}>
        {files.length ? (
          <Grid item container>
            <Typography component="span" variant="body2" sx={styles.headerFile}>
              {t('Завантажені файли ')}
            </Typography>
            <Typography component="span" variant="body2" sx={styles.attachedFilesInfo}>{`Об’єм: ${formatFileSize(
              totalFileSize,
              2
            )} Кількість: ${files.length}`}</Typography>
          </Grid>
        ) : null}
      </Box>
    </Grid>
  );
};

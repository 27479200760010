import React from 'react';

import { Box, CardContent, FormLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ActionFiltersButtons } from '@app/pages/loansLines/components/ActionFiltersButtons';
import { styles } from '@app/pages/loansLines/components/LoansLinesFilter/styles';
import { LoansLinesFilterField } from '@app/pages/loansLines/types';

interface LoansLinesFilterProps {
  fullItems: boolean;
  namesFieldWithError?: string[];
  resetFormHandler?(): void;
}

export const LoansLinesFilter: React.FC<LoansLinesFilterProps> = ({
  fullItems,
  namesFieldWithError,
  resetFormHandler,
}) => {
  const { t } = useTranslation();

  const nowDate = new Date();
  const agreementMinDate = new Date(2010, 0, 1);
  const agreementMaxDate = new Date(2045, 11, 31);
  const fromYear = 2010;
  const toYear = 2045;

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_startDate_from')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={LoansLinesFilterField.agreementStartDateFrom}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: nowDate,
                    },
                    fromYear: 2010,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_startDate')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={LoansLinesFilterField.agreementStartDateTo}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: nowDate,
                        },
                        fromYear: 2010,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_endDate_from')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={LoansLinesFilterField.agreementEndDateFrom}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: agreementMaxDate,
                        },
                        fromYear,
                        toYear,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
              </>
            ) : null}
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_endDate_to')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={LoansLinesFilterField.agreementEndDateTo}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: agreementMaxDate,
                    },
                    fromYear,
                    toYear,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={fullItems ? 6 : 3}>
              <FormLabel component="legend">{t('loans_loanAgreementNumber')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={LoansLinesFilterField.agreementNumber}
                  fullWidth
                  error={namesFieldWithError?.includes(LoansLinesFilterField.agreementNumber)}
                  defaultValue=""
                />
              </Box>
            </Grid>
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('statementsTable_currency')}</FormLabel>
                  <Box pt={2}>
                    {/*<CurrencyAutocomplete name={LoansFilterField.currency} options={data?.currencyCodes || []}  />*/}
                    <InputField
                      name={LoansLinesFilterField.currency}
                      fullWidth
                      placeholder={t('currency')}
                      defaultValue=""
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_limitFrom')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansLinesFilterField.limitFrom}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_limitTo')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansLinesFilterField.limitTo}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_usedLimitFrom')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansLinesFilterField.usedLimitFrom}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_usedLimitTo')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansLinesFilterField.usedLimitTo}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRateFrom')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={LoansLinesFilterField.interestRateFrom}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRateTo')}</FormLabel>
                  <Box pt={2} pl={2} pr={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={LoansLinesFilterField.interestRateTo}
                      fullWidth
                    />
                  </Box>
                </Grid>

                <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={12}>
                  <Box pt={2} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, SignBatchResponseDto, SignBatchRequestDto } from '@app/core/api';

const signMessagesFn = async (signBatchEntitiesRequestDto: SignBatchRequestDto): Promise<SignBatchResponseDto> => {
  const result = await messagesApiClient.messageSignControllerSignBatchCurrencyOperations(
    getClientId(),
    signBatchEntitiesRequestDto
  );

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't sign messages");
  }

  return result.data;
};

export const useSignMessages = (): UseMutationResult<SignBatchResponseDto, ApiError, SignBatchRequestDto> => {
  return useMutation<SignBatchResponseDto, ApiError, SignBatchRequestDto>(signMessagesFn);
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, counterpartiesApiClient, getClientId, GetSwiftResponseDto, UpdateSwiftDto } from '@app/core/api';

interface UpdateCounterpartyRequestProps {
  counterpartyData: UpdateSwiftDto;
  id: string;
}

const editSwiftCounterpartyFn = async ({
  counterpartyData,
  id,
}: UpdateCounterpartyRequestProps): Promise<GetSwiftResponseDto> => {
  const response = await counterpartiesApiClient.counterpartiesControllerUpdateSwiftCounterparty(
    id,
    getClientId(),
    counterpartyData
  );
  return response.data;
};

export const useEditSwiftCounterparty = (): UseMutationResult<
  GetSwiftResponseDto,
  ApiError,
  UpdateCounterpartyRequestProps
> => {
  return useMutation<GetSwiftResponseDto, ApiError, UpdateCounterpartyRequestProps>(editSwiftCounterpartyFn);
};

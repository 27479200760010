import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormLabel, Grid, InputAdornment, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { FileLinkAdditionalInfoDtoCodeEnum } from '@app/core/api';
import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { RadioGroupField } from '@app/core/components/Form/controls/RadioGroupField';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { Loader } from '@app/core/components/Loader';
import { useGetStatementsReport } from '@app/core/hooks/useGetStatementsReport';
import { OperationsFilterStatements } from '@app/core/types';
import { isMobile, isSafari } from '@app/core/utils';
import {
  filesFormatList,
  operationsListItems,
  defaultFormData,
} from '@app/pages/dashboard/view/PageContent/Statements/constants';
import { useStyles } from '@app/pages/dashboard/view/PageContent/Statements/styles';
import { FormFieldName, StatementsFormData } from '@app/pages/dashboard/view/PageContent/Statements/types';
import { validationSchema } from '@app/pages/dashboard/view/PageContent/Statements/validationShema';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const Statements: React.FC = () => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const methods = useForm<StatementsFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
    defaultValues: defaultFormData,
  });
  const { allAccounts } = useAppSelector((state): ProfileState => state.profile);
  const { handleSubmit, reset: formReset, formState } = methods;
  const { isDirty, isValid } = formState;
  const { reset: mutationReset, mutateAsync, isLoading } = useGetStatementsReport();

  const onSubmit = (formData: StatementsFormData) => {
    if (isLoading) {
      return;
    }

    let windowReference: Window | null = null;
    if (isSafari) {
      windowReference = window.open();
    }

    mutateAsync({
      ...formData,
      operationAccounts: formData.operationAccounts ? formData.operationAccounts : [],
      operationType: formData.operationType === OperationsFilterStatements.all ? undefined : formData.operationType,
    }).then((responseData) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        const url = `${responseData.link}?format=download&type=payment-order`;
        if (isSafari && windowReference) {
          windowReference.location.href = url;
        } else {
          window.open(url);
        }
      } else {
        notify({
          notifyProps: {
            message: t('no_operation_from_period_warning'),
            severity: 'warning',
          },
        });
      }
    });
  };

  const resetFormHandler = () => {
    formReset();
    mutationReset();
  };
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container direction="column" justifyContent="space-between" sx={classes.root} wrap="nowrap">
      {!isMobile ? (
        <Box pb={3}>
          <Typography variant="h6">{t('statements')}</Typography>
        </Box>
      ) : null}

      <Box sx={classes.formWrapper}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormLabel component="legend">{t('accounts')}</FormLabel>
                <Box pt={2}>
                  <AccountsWithSearchSelectField name={FormFieldName.operationAccounts} accounts={allAccounts} />
                </Box>
                <Box mt={2} mb={1}>
                  <CheckboxField
                    label={<Typography variant="caption">{t('dashboard_statements_transaction_presented')}</Typography>}
                    name={FormFieldName.transactionsPresented}
                    sx={classes.transactionPresentedLabel}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend">{t('dashboard_statements_search')}</FormLabel>
                <Box pt={2} mb={1}>
                  <InputField
                    name={FormFieldName.queryText}
                    fullWidth
                    defaultValue=""
                    placeholder={t('dashboard_statements_search_placeholder')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchSmallIcon fontSize="small" sx={classes.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('dashboard_statements_data_range')}</FormLabel>
                <Box pt={2}>
                  <DateRangeSelectField formSelectName="date_select" formDateRangeName={FormFieldName.dateRange} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel component="legend">{t('dashboard_statements_operation_label')}</FormLabel>
                <Box pt={2}>
                  <SelectField
                    name={FormFieldName.operationType}
                    defaultValue="all"
                    fullWidth
                    options={operationsListItems(t)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} mb={5}>
                  <RadioGroupField
                    title={t('dashboard_statements_file_type')}
                    name={FormFieldName.fileType}
                    value={filesFormatList[0].value}
                    options={filesFormatList}
                    fullWidth
                    styles={{ radioLabel: classes.radioLabel }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={classes.actionsContainer}>
              <Grid item xs={6}>
                <Button color="primary" disabled={!isDirty || !isValid} fullWidth onClick={resetFormHandler}>
                  {t('dashboard_statements_clear_btn')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {t('dashboard_statements_create_btn')}
                  {isLoading ? (
                    <Box sx={classes.buttonsLoaderWrapper}>
                      <Loader
                        styles={{
                          top: '-31px',
                          left: '-6px',
                          transform: 'scale(0.3)',
                          position: 'relative',
                          '& div > div': {
                            background: 'white',
                          },
                        }}
                      />
                    </Box>
                  ) : null}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Grid>
  );
};

import { isValidIBAN } from 'ibantools';
import { string, object, SchemaOf } from 'yup';

import { CounterpartyFormFieldsEnum } from '@app/pages/counterparties/types';

type SchemaValidationObject = {
  [CounterpartyFormFieldsEnum.account]: string;
  [CounterpartyFormFieldsEnum.name]: string;
  [CounterpartyFormFieldsEnum.lei]?: string;
  [CounterpartyFormFieldsEnum.passport]?: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [CounterpartyFormFieldsEnum.name]: string().required(t('paymentCreationLocalPayment_field_required')),
    [CounterpartyFormFieldsEnum.account]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(CounterpartyFormFieldsEnum.recipientAccount, (value, { createError, path }) => {
        if (value && !isValidIBAN(value)) {
          return createError({
            path,
            message: t('paymentCreationLocalPayment_recipient_error_format'),
          });
        } else if (value && !Boolean(value.slice(0, 2).match('UA'))) {
          return createError({
            path,
            message: t('paymentCreationLocalPayment_recipient_error_ua_iban'),
          });
        }
        return true;
      }),
    [CounterpartyFormFieldsEnum.lei]: string().when(CounterpartyFormFieldsEnum.nonLeiFlag, {
      is: false,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .test('recipientLei', t('counterpartyFilterLei_field_error'), (val) => val?.length === 8 || val?.length === 10),
    }),
    [CounterpartyFormFieldsEnum.passport]: string().when(CounterpartyFormFieldsEnum.nonLeiFlag, {
      is: true,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .min(9, t('paymentCreationLocalPayment_correct_passport')),
    }),
  });
};

import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { ArrowRightIcon, SettingIcon } from '@app/common/icons';
import { LoanLineDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/loansLines/components/LoansLinesTable/components/TableContent/styles';
import { LoansLinesTableColumns } from '@app/pages/loansLines/types';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';

interface MakeTableColumnsProps {
  t(text: string): string;
  onSettingsButtonClick(): void;
}

export const makeTableColumns = ({
  t,
  onSettingsButtonClick,
}: MakeTableColumnsProps): ColumnDef<LoanLineDto, any>[] => {
  return [
    {
      accessorKey: LoansLinesTableColumns.agreementStartDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: LoansLinesTableColumns.agreementEndDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: LoansLinesTableColumns.agreementNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_loanAgreementNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: LoansLinesTableColumns.limit,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_limit`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: LoansLinesTableColumns.outstanding,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_outstanding`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: LoansLinesTableColumns.availableLimit,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_availableLimit`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: LoansLinesTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`loans_currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: LoansLinesTableColumns.interestRate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`loans_interestRate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<LoanLineDto, string>) => {
        return `${getValue()}%`;
      },
    },
    {
      id: 'actions',
      accessorKey: LoansLinesTableColumns.uuid,
      header: () => {
        return (
          <Grid container justifyContent="flex-end">
            {appConfig.enableNextFeatures ? (
              <IconButton sx={styles.settingIconBtn} onClick={onSettingsButtonClick} size="large">
                <SettingIcon />
              </IconButton>
            ) : null}
          </Grid>
        );
      },
      cell: ({ row }: CellContext<LoanLineDto, any>) => {
        return (
          <Grid container justifyContent="flex-end" alignItems="center">
            <Link to={`${RouteList.loan_lines}/${row.original.uuid}`}>
              <ArrowRightIcon sx={styles.linkArrow} />
            </Link>
          </Grid>
        );
      },
    },
  ];
};

export const styles = {
  paper: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 7px rgba(134, 142, 150, 0.1), 0px 7px 20px rgba(222, 226, 230, 0.3)',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    height: '400px',
    overflow: 'hidden',
    position: 'relative',
  },
  companyName: {
    color: 'colors.gray800',
    fontWeight: 600,
  },
  limitBlock: {
    marginTop: '20px',
    marginBottom: '24px',
    height: '65px',
    backgroundColor: 'colors.primary25',
  },
  leftBlock: {
    marginTop: '20px',
    paddingLeft: '20px',
    paddingRight: '25px',
  },
  rightBlock: {
    marginTop: '20px',
    paddingLeft: '25px',
    paddingRight: '20px',
  },
  infoBlock: {
    paddingRight: '200px',
  },
  debt: {
    marginRight: '310px',
  },
  light: {
    color: 'colors.gray400',
  },
  bold: {
    fontWeight: 600,
    color: 'colors.gray600',
  },
  accountFontSize: {
    fontSize: '0.875rem',
  },
};

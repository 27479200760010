import { Theme, SxProps } from '@mui/material/styles';

export type Styles = { [key: string]: SxProps<Theme> };

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type SortingRule<T = string> = {
  field: T;
  desc: boolean;
};

export enum FetchStatus {
  success = 'success',
  failed = 'failed',
  loading = 'loading',
  idle = 'idle',
}

export type MutationStatus = 'idle' | 'loading' | 'error' | 'success';

export type PaginationChangeType = {
  rowsPerPage: number;
  page: number;
};

export type TablePaginationType = {
  rowsPerPage: number;
  page: number;
  totalCount: number;
};

export type Severity = 'success' | 'info' | 'warning' | 'error';

export type SvgFontSize = 'inherit' | 'medium' | 'small' | 'large';

export type DateRange = { startDate: Date; endDate: Date };

export type FileType = {
  file: File;
  name: string;
  result: string;
  size: string;
};

export type DateRangeFromTo = {
  from: Date;
  to: Date;
};

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  CreateStatusEnum,
  CreatePaymentOrderResultDto,
  DocumentTemplateDto,
  GetRepaymentTemplateRequestDto,
  GetUahRepaymentTemplateResponseDto,
  OverdraftRepaymentTypeEnum,
  PaymentOrderAdditionalInfoDto,
  PaymentTemplateTypeEnum,
  RepaymentTypeEnum,
  ResultStatusEnum,
  UahRepaymentTemplateDto,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useGeneratePaymentOrderNumber, useGetOrderByUuid, useGetUahPaymentSigners } from '@app/core/hooks';
import { useCreatePaymentOrder } from '@app/core/hooks/useCreatePaymentOrder';
import { useGetDepositReplenishmentTemplate } from '@app/core/hooks/useGetDepositReplenishmentTemplate';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { isMobile } from '@app/core/utils';
import { getLoanUahRepaymentTemplateFn } from '@app/pages/loans/show/query';
import { useOverdraftGetRepaymentTemplate } from '@app/pages/overdrafts/show/query';
import { LocalPaymentEditForm } from '@app/pages/payment/components/LocalPaymentEditForm';
import { DefaultValues, PaymentFormData } from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { makeCreatePaymentSubmitData } from '@app/pages/payment/create/LocalPayment/helpers';
import { LocalPaymentUrlQuery } from '@app/pages/payment/create/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface CreatePaymentProps {
  paymentData: PaymentFormData;
  onCreateSuccess(operation: CreatePaymentOrderResultDto): void;
  onCreateError(additionalInfo?: PaymentOrderAdditionalInfoDto): void;
}

export interface LocalPaymentProps {
  sendOnSign(operation: CreatePaymentOrderResultDto, signFromAnotherPerson?: boolean): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const LocalPayment: React.FC<LocalPaymentProps> = ({ sendOnSign, onChange }) => {
  const { permissions, userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [recipientAccountDisabled, setRecipientAccountDisabled] = useState<boolean>(false);
  const [uahRepaymentTemplate, setUahRepaymentTemplate] = useState<UahRepaymentTemplateDto>();
  const [defaultValues, setDefaultValues] = useState<DefaultValues>({
    recipientNonLeiFlag: false,
    realSenderNonLeiFlag: false,
    realRecipientNonLeiFlag: false,
  });
  const urlQueryValues = useLocation().search;
  const { copyFrom: copyFromUuid, lid, oid, did, rt, line } = parse(urlQueryValues) as LocalPaymentUrlQuery;

  const {
    data: copyFromPaymentOrder,
    refetch: refetchCopyFromPaymentOrder,
    isLoading: isLoading1,
  } = useGetOrderByUuid(copyFromUuid as string);

  const {
    data: loanRepaymentTemplateResponseData,
    mutate: mutateLoanRepaymentTemplate,
    isLoading: isLoading2,
  } = useMutation<GetUahRepaymentTemplateResponseDto, ApiError, GetRepaymentTemplateRequestDto>(
    getLoanUahRepaymentTemplateFn
  );

  const {
    data: overdraftRepaymentTemplateData,
    mutate: mutateOverdraftGetRepaymentTemplate,
    isLoading: isLoading4,
  } = useOverdraftGetRepaymentTemplate();

  const {
    data: depositReplenishmentTemplateData,
    mutate: mutateDepositReplenishmentTemplate,
    isLoading: isLoading5,
  } = useGetDepositReplenishmentTemplate();

  const {
    data: createPaymentOrderResultData,
    mutateAsync,
    reset: resetCreatePayment,
    isLoading: isLoading3,
  } = useCreatePaymentOrder();

  const {
    data: sendToSignPaymentResultData,
    mutate: mutateSendToSign,
    isLoading: isSendToSignLoading,
  } = useSendToSignPaymentOrder();

  const { data: generatedOrderNumber } = useGeneratePaymentOrderNumber();
  const { data: peopleToSigne } = useGetUahPaymentSigners();

  useEffect(() => {
    if (generatedOrderNumber) {
      setDefaultValues({
        recipientNonLeiFlag: false,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
        documentNumber: generatedOrderNumber,
      });
    }
  }, [generatedOrderNumber]);

  const createPayment = ({ paymentData, onCreateSuccess, onCreateError }: CreatePaymentProps) => {
    mutateAsync({ order: makeCreatePaymentSubmitData(paymentData) })
      .then((result) => {
        if (result.operation) {
          onCreateSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onCreateError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onCreateError();
      });
  };

  const onSaveClickHandler = (paymentData: PaymentFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(-1);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignHandler = (paymentData: PaymentFormData, signFromAnotherPerson?: boolean) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        resetCreatePayment();
        sendOnSign(operation, signFromAnotherPerson);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: PaymentFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        mutateSendToSign(operation.uuid);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData) {
      if (sendToSignPaymentResultData.status === ResultStatusEnum.Successfully) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('send_to_sign_payment_success'),
            severity: 'success',
          },
        });
        navigate(RouteList.operations);
      }
      if (sendToSignPaymentResultData.status === ResultStatusEnum.Error) {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t('send_to_sign_payment_error'),
            severity: 'error',
          },
        });
      }
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  useEffect(() => {
    showLoader(isSendToSignLoading || isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5);
  }, [isLoading1, isLoading2, isLoading3, isSendToSignLoading, showLoader, isLoading4, isLoading5]);

  const onCancelHandler = () => {
    setLeavePopupOpen(true);
  };

  const onTemplateChangeHandler = (templateData: DocumentTemplateDto) => {
    setDefaultValues({
      documentNumber: generatedOrderNumber,
      senderAccountId: templateData.accountId,
      recipientAccountName: templateData.recipientAccount.name,
      recipientAccountNumber: templateData.recipientAccount.accountNumber,
      recipientLei: templateData.recipientAccount.LEI,
      amount: templateData.amount,
      paymentReference: templateData.paymentReference,
      recipientBankName: templateData.recipientAccount.bankName,
      recipientNonLeiFlag: templateData.recipientAccount.nonLeiFlag,
      realSenderName: templateData.realSenderName,
      realSenderTIN: templateData.realSenderTIN,
      realSenderNonResidentFlag: templateData.realSenderNonResidentFlag,
      realSenderCountryCode: templateData.realSenderCountryCode,
      realSenderNonLeiFlag: templateData.realSenderNonLeiFlag,
      realSenderPassport: templateData.realSenderPassport,
      realRecipientName: templateData.realRecipientName,
      realRecipientTIN: templateData.realRecipientTIN,
      realRecipientNonResidentFlag: templateData.realRecipientNonResidentFlag,
      realRecipientCountryCode: templateData.realRecipientCountryCode,
      realRecipientNonLeiFlag: templateData.realRecipientNonLeiFlag,
      realRecipientPassport: templateData.realRecipientPassport,
    });
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(-1);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  useEffect(() => {
    if (copyFromUuid) {
      refetchCopyFromPaymentOrder();
    }
  }, [copyFromUuid, refetchCopyFromPaymentOrder]);

  useEffect(() => {
    if (lid && rt) {
      mutateLoanRepaymentTemplate({
        uuid: lid as string,
        isLoanLine: Boolean(line) || false,
        repaymentType: rt as RepaymentTypeEnum,
      });
      setRecipientAccountDisabled(true);
    }
  }, [line, mutateLoanRepaymentTemplate, rt, lid]);

  useEffect(() => {
    if (oid && rt) {
      mutateOverdraftGetRepaymentTemplate({
        uuid: oid as string,
        repaymentType: rt as OverdraftRepaymentTypeEnum,
      });
      setRecipientAccountDisabled(true);
    }
  }, [mutateOverdraftGetRepaymentTemplate, rt, oid]);

  useEffect(() => {
    if (did) {
      mutateDepositReplenishmentTemplate({
        uuid: did as string,
      });
      setRecipientAccountDisabled(true);
    }
  }, [mutateDepositReplenishmentTemplate, did]);

  useEffect(() => {
    if (copyFromPaymentOrder) {
      setDefaultValues({
        documentNumber: generatedOrderNumber,
        senderAccountId: copyFromPaymentOrder.account.id,
        recipientAccountName: copyFromPaymentOrder.details.recipientAccount.name,
        recipientAccountNumber: copyFromPaymentOrder.details.recipientAccount.accountNumber,
        recipientLei: copyFromPaymentOrder.details.recipientAccount.LEI,
        recipientBankName: copyFromPaymentOrder.details.recipientAccount.bankName,
        amount: copyFromPaymentOrder.amount,
        paymentReference: copyFromPaymentOrder.paymentReference,
        recipientNonLeiFlag: copyFromPaymentOrder.details.recipientAccount.nonLeiFlag,
        realSenderNonLeiFlag: copyFromPaymentOrder.realSenderNonLeiFlag,
        realRecipientNonLeiFlag: copyFromPaymentOrder.realRecipientNonLeiFlag,
      });
    }
  }, [copyFromPaymentOrder, generatedOrderNumber]);

  useEffect(() => {
    if (loanRepaymentTemplateResponseData) {
      setUahRepaymentTemplate(loanRepaymentTemplateResponseData.template);
    }
  }, [loanRepaymentTemplateResponseData]);

  useEffect(() => {
    if (overdraftRepaymentTemplateData) {
      setUahRepaymentTemplate(overdraftRepaymentTemplateData.template);
    }
  }, [overdraftRepaymentTemplateData]);

  useEffect(() => {
    if (depositReplenishmentTemplateData) {
      setDefaultValues({
        documentNumber: generatedOrderNumber,
        senderAccountId: depositReplenishmentTemplateData.template?.senderAccountId,
        recipientAccountName: depositReplenishmentTemplateData.template?.recipientName,
        recipientAccountNumber: depositReplenishmentTemplateData.template?.recipientAccount,
        recipientLei: depositReplenishmentTemplateData.template?.recipientLei,
        amount: depositReplenishmentTemplateData.template?.amount,
        paymentReference: depositReplenishmentTemplateData.template?.paymentReference,
        recipientPassport: depositReplenishmentTemplateData.template?.recipientPassport,
        recipientNonLeiFlag: depositReplenishmentTemplateData.template?.recipientNonLeiFlag || false,
        countryCode: depositReplenishmentTemplateData.template?.countryCode,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
      });
    }
  }, [depositReplenishmentTemplateData, generatedOrderNumber]);

  useEffect(() => {
    if (uahRepaymentTemplate) {
      setDefaultValues({
        documentNumber: generatedOrderNumber,
        senderAccountId: uahRepaymentTemplate.senderAccountId,
        recipientAccountName: uahRepaymentTemplate.recipientName,
        recipientAccountNumber: uahRepaymentTemplate.recipientAccount,
        recipientLei: uahRepaymentTemplate.recipientLei,
        amount: uahRepaymentTemplate.amount,
        paymentReference: uahRepaymentTemplate.paymentReference,
        recipientPassport: uahRepaymentTemplate.recipientPassport,
        recipientNonLeiFlag: uahRepaymentTemplate.recipientNonLeiFlag,
        countryCode: uahRepaymentTemplate.countryCode,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
      });
    }
  }, [generatedOrderNumber, uahRepaymentTemplate]);

  return (
    <>
      <Box p={isMobile ? 0 : 3} mb={2} width="100%">
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item xs={isMobile ? false : 9} container>
            <LocalPaymentEditForm
              defaultValues={defaultValues}
              signPermissions={{
                ...permissions.sign.paymentOrder,
                allowSignFromAnotherPerson: permissions.sign.paymentOrder.signFromAnotherPerson,
              }}
              recipientAccountDisabled={recipientAccountDisabled}
              additionalInfo={createPaymentOrderResultData?.additionalInfo}
              onCancel={onCancelHandler}
              onSave={onSaveClickHandler}
              onSaveAndSign={onSaveAndSignHandler}
              onTemplateChange={onTemplateChangeHandler}
              onChange={onChange}
              onSaveAndSendToSign={onSaveAndSendToSignClickHandler}
            />
          </Grid>
          {!isMobile && userProfile ? (
            <Box sx={{ width: '220px' }}>
              <Box pl={2}>
                <SelectPaymentTemplate
                  templateType={PaymentTemplateTypeEnum.General}
                  onTemplateChange={onTemplateChangeHandler}
                  placeholder={t('enterName_placeholder')}
                />
                <Box pt={10}>
                  <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Box>
      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

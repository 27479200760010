import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, DeleteRequestDto, ResultResponseDto } from '@app/core/api';

const deleteMessagesFn = async (deleteRequestDto: DeleteRequestDto): Promise<ResultResponseDto> => {
  const result = await messagesApiClient.messagesControllerDelete(getClientId(), deleteRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't delete messages");
  }

  return result.data;
};

export const useDeleteMessages = (): UseMutationResult<ResultResponseDto, ApiError, DeleteRequestDto> => {
  return useMutation<ResultResponseDto, ApiError, DeleteRequestDto>(deleteMessagesFn);
};

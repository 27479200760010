import React, { useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { CopyIcon, SuccessSmallSvgIcon } from '@app/common/icons';
import { AccountTypeEnum, GetAccountResponseDto } from '@app/core/api';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { isMobile } from '@app/core/utils';
import { styles } from '@app/pages/accounts/components/AccountsList/components/BankDetails/styles';
import { appConfig } from '@app/src/config';
import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/chevron-down.svg';
import { ReactComponent as SendIcon } from '@app/themes/default/assets/icons/send.svg';

export interface OperationDetailsProps {
  document: GetAccountResponseDto;
  handleShowModal(): void;
}

export const BankDetails: React.FC<OperationDetailsProps> = ({ document, handleShowModal }) => {
  const {
    accountNumber,
    accountType,
    currency,
    LEI,
    bankName,
    bankCode,
    legalEntityName,
    legalEntityAddress,
    currencyInfo,
  } = document.account;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const onCopy = () => {
    if (accountType === AccountTypeEnum.Current && currency !== 'UAH') {
      copy(
        `${t('accounts_type')} ${t(`accounts_${accountType}`)} \nIBAN ${accountNumber} \n${t(
          'accounts_currency'
        )} ${currency} \n${t('accounts_bankName')} ${bankName}\n${t('accounts_bankCode')} ${bankCode}\n${t(
          'accounts_leiName'
        )} ${legalEntityName}\n${t('accounts_lei')} ${LEI}\n${t('accounts_leiAddress')} ${legalEntityAddress}\n${t(
          'swiftBic'
        )} ${currencyInfo?.intermediarySwiftBic}\n${t('swiftBankAddress')} ${currencyInfo?.intermediaryBankName}\n${t(
          'bankCorrespondent'
        )} ${currencyInfo?.additionalIntermediarySWIFTBic}`,
        { format: 'text/plain' }
      );
    } else {
      copy(
        `${t('accounts_type')} ${t(`accounts_${accountType}`)} \nIBAN ${accountNumber} \n${t(
          'accounts_currency'
        )} ${currency} \n${t('accounts_bankName')} ${bankName}\n${t('accounts_bankCode')} ${bankCode}\n${t(
          'accounts_leiName'
        )} ${legalEntityName}\n${t('accounts_lei')} ${LEI}\n${t('accounts_leiAddress')} ${legalEntityAddress}`,
        { format: 'text/plain' }
      );
    }
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1500);
  };
  const handleShow = () => {
    handleShowModal();
    handleClose();
  };
  const handlePopoverClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography variant="body2" sx={styles.requisites} onClick={handleClick}>
        {!isMobile ? <Box component="span">{t('details')}</Box> : null}
        <ChevronDownIconSvg />
      </Typography>
      <Popover
        onClick={handlePopoverClick}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            background: 'none',
            boxShadow: 'none',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box component="div" m={2}>
          <Grid container alignItems="center" sx={{ ...styles.content, ...(isMobile && styles.contentMobile) }}>
            {copyAlert ? (
              <Grid container alignItems="center" sx={styles.alertCopy}>
                <SuccessSmallSvgIcon fontSize="small" />
                <Typography variant="caption" sx={styles.alertCopyText}>
                  {t('copy_details')}
                </Typography>
              </Grid>
            ) : null}
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_type')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {t(`accounts_${accountType}`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  IBAN
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {accountNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_currency')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {currency}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_bankName')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {bankName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_bankCode')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {bankCode}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_leiName')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {legalEntityName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('accounts_lei')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  {LEI}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item xs={4}>
                <Typography variant="body2" component="span">
                  {t('accounts_leiAddress')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" component="div" sx={styles.rowRight}>
                  <Ellipsis text={legalEntityAddress} width="100%" />
                </Typography>
              </Grid>
            </Grid>
            {accountType === AccountTypeEnum.Current && currency !== 'UAH' ? (
              <>
                <Box sx={styles.divider} />
                <Box mt={3} mb={3}>
                  <Typography variant="body2" component="div" sx={styles.swiftInfoHeader}>
                    {t('forSwift')}
                  </Typography>
                </Box>
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  wrap="nowrap"
                  justifyContent="space-between"
                  sx={styles.row}
                >
                  <Grid item>
                    <Typography variant="body2" component="span">
                      {t('swiftBic')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" component="div" sx={styles.rowRight}>
                      {currencyInfo?.intermediarySwiftBic}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  wrap="nowrap"
                  justifyContent="space-between"
                  sx={styles.row}
                >
                  <Grid item>
                    <Typography variant="body2" component="span">
                      {t('swiftBankAddress')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" component="div" sx={styles.rowRight}>
                      {currencyInfo?.intermediaryBankName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  wrap="nowrap"
                  justifyContent="space-between"
                  sx={styles.row}
                >
                  <Grid item>
                    <Typography variant="body2" component="span">
                      {t('bankCorrespondent')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" component="div" sx={styles.rowRight}>
                      {currencyInfo?.additionalIntermediarySWIFTBic}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : null}
            <Grid
              item
              container
              alignItems="flex-start"
              justifyContent="space-between"
              wrap="nowrap"
              sx={styles.rowLast}
            >
              <Button
                variant="outlined"
                size="small"
                color="primary"
                sx={{ ...styles.buttonPopover, ...(isMobile && styles.buttonPopoverMobile) }}
                endIcon={<CopyIcon style={{ ...styles.buttonPopoverSvgIcon, ...styles.buttonPopoverSvgIconFill }} />}
                onClick={onCopy}
              >
                {t('accounts_copy')}
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                sx={{ ...styles.buttonPopover, ...(isMobile && styles.buttonPopoverMobile) }}
                endIcon={<SendIcon style={styles.buttonPopoverSvgIcon} />}
                onClick={handleShow}
              >
                {t('accounts_send')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

import React, { useState } from 'react';

import { Grid, SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { StarIcon } from '@app/common/icons';
import { EmailDocumentTypeEnum, PaymentOrderTypeEnum } from '@app/core/api';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { CopyOperationButton } from './Actions/CopyOperationButton';
import { LoadOperationButton } from './Actions/LoadOperationButton';
import { PrintButton } from './Actions/PrintButton';
import { styles } from './styles';
import { CurrencyEnum, Operation, OperationsTypeEnum } from '../../types';
import { DATE_FORMAT, formatISODateString } from '../../utils';
import { DetailsInfoItem } from '../ComplexTable';
import { SaveTemplateDialog } from '../SaveTemplateDialog';
import { makeTemplateDataFromOperation } from '../SaveTemplateDialog/helpers';
import { ShareDocumentButton } from '../ShareDocumentButton';
import { Tooltip } from '../Tooltip';

export interface OperationDetailsProps {
  operation: Operation;
  stylesRoot?: SxProps<Theme>;
  showActions?: boolean;
}

export const OperationDetails: React.FC<OperationDetailsProps> = ({ operation, stylesRoot, showActions = true }) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const { type, date, documentNumber, paymentReference, details, link, currency, documentType, uuid } = operation;
  const { date: detailsDate, senderAccount, recipientAccount } = details;

  const addToTemplateClickHandler = () => {
    setShowSaveTemplateDialog(true);
  };

  const onCancelSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ ...styles.root, ...(stylesRoot && stylesRoot) }}>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem
              description={t(
                documentType === PaymentOrderTypeEnum.MemorialOrderFlag
                  ? 'documentCategory_memorialOrder'
                  : 'paymentCreationLocalPayment_paymentOrder'
              )}
              value={documentNumber}
            />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('paymentCreationLocalPayment_documentDate')}
              value={formatISODateString(detailsDate, DATE_FORMAT)}
            />
          </Grid>
          {date ? (
            <Grid item xs={3}>
              <DetailsInfoItem
                description={t('operationDetailShare_spend')}
                value={`${formatISODateString(date, DATE_FORMAT)}`}
              />
            </Grid>
          ) : null}
          <Grid item container xs={3} justifyContent="flex-end">
            {currency === CurrencyEnum.UAH ? (
              <>
                {permissions.uahOperations.copy &&
                type === OperationsTypeEnum.withdrawal &&
                documentType === PaymentOrderTypeEnum.PaymentOrderFlag ? (
                  <CopyOperationButton operation={operation} styles={styles.actionIcon} />
                ) : null}
                {permissions.uahOperations.print &&
                (type === OperationsTypeEnum.withdrawal || type === OperationsTypeEnum.deposit) &&
                documentType ? (
                  <ShareDocumentButton
                    styles={styles.actionButton}
                    link={link}
                    type={EmailDocumentTypeEnum.PaymentOrder}
                    uuid={uuid}
                  />
                ) : null}
                {permissions.uahOperations.print &&
                documentType !== PaymentOrderTypeEnum.MemorialOrderFlag &&
                (type === OperationsTypeEnum.withdrawal || type === OperationsTypeEnum.deposit) ? (
                  <PrintButton styles={styles.actionIcon} link={link} />
                ) : null}
                {permissions.uahTemplates.create &&
                type === OperationsTypeEnum.withdrawal &&
                documentType === PaymentOrderTypeEnum.PaymentOrderFlag ? (
                  <Tooltip arrow placement="top" color="primary" title={t('addToTemplate')}>
                    <IconButton
                      size="small"
                      color="default"
                      sx={styles.actionButton}
                      onClick={addToTemplateClickHandler}
                    >
                      <StarIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderAccountName')} value={senderAccount.name} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('operationDetail_lei')}
              value={senderAccount.LEI || senderAccount.passportId || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderBank')} value={senderAccount.bankName} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={senderAccount.accountNumber} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipient')} value={recipientAccount.name} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('operationDetail_recipientLei')}
              value={recipientAccount.LEI || recipientAccount.passportId || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipientBank')} value={recipientAccount.bankName} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={recipientAccount.accountNumber} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
          <Grid item xs={10}>
            <DetailsInfoItem description={t('paymentCreationLocalPayment_purpose')} value={paymentReference} />
          </Grid>
          {permissions.uahOperations.download && documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
            <Grid item container xs={2} justifyContent="flex-end" sx={{ minWidth: '150px' }}>
              <LoadOperationButton link={`${link}&format=download`} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {showSaveTemplateDialog ? (
        <SaveTemplateDialog
          open
          documentTemplateData={makeTemplateDataFromOperation(operation)}
          onCancel={onCancelSaveTemplateHandler}
          onSuccessSaveTemplate={onCancelSaveTemplateHandler}
        />
      ) : null}
    </>
  );
};

import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { LoansContent } from '@app/pages/loans/components/LoansContent';
import { styles } from '@app/pages/loans/styles';

export const LoansPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('loans_page')} useDesktopStyle />
        <PageContainer>
          <Grid container direction="column" wrap="nowrap" sx={styles.horizontalWrapper}>
            <LoansContent />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

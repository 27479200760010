export const styles = {
  paper: {
    backgroundColor: 'white',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '570px',
    minHeight: '230px',
    overflow: 'hidden',
    position: 'relative',
  },
  title: {
    color: 'colors.gray800',
    fontSize: '20px',
    fontWeight: 600,
  },
  dropContainer: {
    width: '288px',
    height: '288px',
    border: `1.5px dashed`,
    borderColor: 'colors.primary500',
    borderRadius: '50%',
    backgroundColor: 'colors.primary25',
  },
  mask: {
    position: 'absolute',
    width: '288px',
    height: '288px',
    border: `1.5px dashed`,
    borderColor: 'colors.primary500',
    borderRadius: '50%',
    boxShadow: `0 0 0 100vw rgba(27, 29, 32, 0.3)`,
  },
  image: {
    height: '300px',
  },
  dropContainerDefault: {
    backgroundColor: 'colors.primary25',
  },
  dropContainerActive: {
    backgroundColor: 'colors.primary100',
  },
  dropContainerError: {
    backgroundColor: '#FFF7F9',
    borderColor: 'colors.error',
  },
  mainText: {
    color: 'colors.gray500',
    textAlign: 'center',
  },
  fontWeight: {
    fontWeight: 600,
  },
  label: {
    textDecoration: 'underline',
  },
  secondText: {
    color: 'colors.gray300',
  },
  imageContainer: {
    width: '100%',
    borderTop: `1px solid`,
    borderBottom: `1px solid`,
    borderColor: 'colors.gray100',
    marginTop: '28px',
    marginBottom: '28px',
    paddingTop: '14px',
    paddingBottom: '14px',
    position: 'relative',
    overflow: 'hidden',
  },
  backLinkArrow: {
    fill: '#414e62',
  },
  errorColorText: {
    color: 'colors.error',
  },
};

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  AvailableActionsDto,
  CreateOperationAdditionalInfoDto,
  EmailDocumentTypeEnum,
  ResultStatusEnum,
  SwiftOperationDto,
} from '@app/core/api';
import { ShareDocumentButton, SignMultipleSwift } from '@app/core/components';
import { useGetSwiftByUuid } from '@app/core/hooks';
import { useEditSwiftPayment } from '@app/core/hooks/useEditSwiftPaymen';
import { useSendToSignSwift } from '@app/core/hooks/useSendToSignSwift';
import { isMobile } from '@app/core/utils';
import { styles } from '@app/pages/swift/style';
import { SwiftOrderHistory } from '@app/pages/swift/SwiftPayment/components/SwiftOrderHistory';
import { SwiftPaymentForm } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm';
import { makeEditSwiftPaymentSubmitData } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/helper';
import { DefaultValues, SwiftFormData } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface EditPaymentProps {
  paymentData: SwiftFormData;
  onEditSuccess(operation: SwiftOperationDto): void;
  onEditError(additionalInfo?: CreateOperationAdditionalInfoDto): void;
}

export const SwiftEditPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const [createPaymentResultOperation, setCreatePaymentResultOperation] = useState<SwiftOperationDto | null>();
  const [defaultValues, setDefaultValues] = useState<DefaultValues>();
  const [signPermissions, setSignPermissions] = useState<AvailableActionsDto>({
    ...permissions.sign.swift,
    allowSignFromAnotherPerson: permissions.sign.swift.signFromAnotherPerson,
  });
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean>();
  const { t } = useTranslation();
  const { notify } = useNotify();
  const { showLoader } = useUIState();
  const { uuid = 'no-uuid' } = useParams();
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);

  const onCloseModalHandler = () => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
  };

  const onSuccessSignHandler = () => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(-1);
  };

  const { data: editSwiftOperationResultData, mutateAsync, reset: resetCreatePayment } = useEditSwiftPayment();

  const {
    data: sendToSignPaymentResultData,
    mutate: sendToSignMutation,
    status: sendToSignPaymentStatus,
  } = useSendToSignSwift();

  const editPayment = ({ paymentData, onEditSuccess, onEditError }: EditPaymentProps) => {
    mutateAsync(makeEditSwiftPaymentSubmitData({ paymentData, uuid }))
      .then((result) => {
        if (result.operation) {
          onEditSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onEditError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onEditError();
      });
  };

  const onSaveHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess() {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(RouteList.swift);
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignHandler = (paymentData: SwiftFormData, signFromAnotherPers?: boolean) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        setSignFromAnotherPerson(signFromAnotherPers);
        showLoader(false);
        setCreatePaymentResultOperation(operation);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    editPayment({
      paymentData,
      onEditSuccess(operation) {
        sendToSignMutation([operation.uuid]);
        resetCreatePayment();
      },
      onEditError() {
        showLoader(false);
      },
    });
  };
  const { data: swiftOrder, refetch: refetchSwiftOrder, isLoading } = useGetSwiftByUuid(uuid);

  useEffect(() => {
    refetchSwiftOrder();
  }, [refetchSwiftOrder]);

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  useEffect(() => {
    showLoader(sendToSignPaymentStatus === 'loading');
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.swift);
    }
  }, [navigate, notify, sendToSignPaymentResultData, sendToSignPaymentStatus, showLoader, t]);

  useEffect(() => {
    if (swiftOrder) {
      setDefaultValues({
        senderAccountId: swiftOrder.sender.accountId,
        documentNumber: swiftOrder.documentNumber,
        recipientAccountName: swiftOrder.recipient.name,
        recipientAccountNumber: swiftOrder.recipient.account,
        recipientAddress: swiftOrder.recipient.address,
        amount: swiftOrder.amount,
        paymentReference: swiftOrder.paymentReference,
        recipientBankName: swiftOrder.recipient.bankName,
        recipientCorrespondentBankName: swiftOrder.recipient.correspondentBank,
        documentDate: swiftOrder.documentDate,
        documentValueDate: swiftOrder.documentValueDate,
        swiftBic: swiftOrder.recipient.swiftBic,
        swiftBicCorrespondent: swiftOrder.recipient.correspondentSwiftBic,
        commissionType: swiftOrder.commissionType,
        operationCode: swiftOrder.operationCode || undefined,
        urgency: swiftOrder.urgency,
        recipientCountry: swiftOrder.recipient.countryCode,
      });
      setSignPermissions(swiftOrder.actions);
    }
  }, [swiftOrder]);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    setShowLeavePopup(formDirty);
  };

  return (
    <>
      <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
        <PageHeader
          title={t('swiftEditHeaderContent_title')}
          showNavigateButton={!isMobile}
          backLink={-1}
          showLeavePopup={showLeavePopup}
          confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
        />
        <PageContainer>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container xs={isMobile ? false : 9}>
                <SwiftPaymentForm
                  defaultValues={defaultValues}
                  additionalInfo={editSwiftOperationResultData?.additionalInfo}
                  shareButton={
                    swiftOrder ? (
                      <ShareDocumentButton
                        link={swiftOrder.link}
                        styles={{
                          color: 'colors.primary500',
                        }}
                        type={EmailDocumentTypeEnum.SwiftOperation}
                        uuid={swiftOrder.uuid}
                      />
                    ) : undefined
                  }
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onSendToSign={onSaveAndSendToSignClickHandler}
                  signPermissions={signPermissions}
                  onChange={onChangeHandler}
                />
              </Grid>
              {!isMobile ? (
                <Box sx={{ width: '220px' }}>
                  <Box pl={2}>{swiftOrder ? <SwiftOrderHistory order={swiftOrder} edit /> : null}</Box>
                </Box>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>
      {createPaymentResultOperation && showSignPayment ? (
        <SignMultipleSwift
          operations={[createPaymentResultOperation]}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessSignHandler}
          onClose={onCloseModalHandler}
        />
      ) : null}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, ChangeStatusRequestDto, ResultResponseDto } from '@app/core/api';

const changeMessagesStatusFn = async (changeStatusRequestDto: ChangeStatusRequestDto): Promise<ResultResponseDto> => {
  const result = await messagesApiClient.messagesControllerChangeStatus(getClientId(), changeStatusRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't change messages status");
  }

  return result.data;
};

export const useChangeMessagesStatus = (): UseMutationResult<ResultResponseDto, ApiError, ChangeStatusRequestDto> => {
  return useMutation<ResultResponseDto, ApiError, ChangeStatusRequestDto>(changeMessagesStatusFn);
};

import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import { UseMutationOptions, UseMutationResult } from 'react-query/types/react/types';

import { getClientId, swiftApiClient, ApiError, SignBatchRequestDto, SignBatchResponseDto } from '@app/core/api';
import { SignApiError } from '@app/src/logger/SignApiError';
import { LogTagErrorName, LogTagErrorSource, LogTagErrorType } from '@app/src/logger/types';

const logSignApiError = (error: ApiError) => {
  Sentry.captureException(new SignApiError(error.message), {
    tags: {
      [LogTagErrorName.errorType]: LogTagErrorType.SignCurrencyExchangeApiError,
      [LogTagErrorName.errorSource]: LogTagErrorSource.api,
    },
    extra: {
      statusCode: error.code,
      message: error.message,
    },
  });
};

type SignSwiftProps = {
  signBatchRequestDto: SignBatchRequestDto;
  signatureBelongsToUser?: number;
};

const submitSignedSwiftFn = async ({
  signBatchRequestDto,
  signatureBelongsToUser,
}: SignSwiftProps): Promise<SignBatchResponseDto> => {
  const result = await swiftApiClient.swiftSignControllerSignBatchSwiftOperations(
    getClientId(),
    signBatchRequestDto,
    signatureBelongsToUser
  );

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Sign API response with error. StatusCode:' + result.request.status);
  }

  return result.data;
};

export const useMultiSwiftSign = (
  options?: UseMutationOptions<SignBatchResponseDto, ApiError, SignSwiftProps>
): UseMutationResult<SignBatchResponseDto, ApiError, SignSwiftProps> => {
  return useMutation<SignBatchResponseDto, ApiError, SignSwiftProps>(submitSignedSwiftFn, {
    onError: (error: ApiError) => {
      logSignApiError(error);
    },
    ...options,
  });
};

import React from 'react';

import { ErrorSmallIcon, InfoSmallIcon, SuccessSmallIcon, WarningSmallIcon } from '@app/common/icons/index';
import { Severity, SvgFontSize } from '@app/core/types';

export const getIconBySeverity = (severity: Severity, fontSizes?: SvgFontSize): React.ReactNode | false => {
  switch (severity) {
    case 'info':
      return <InfoSmallIcon fontSize={fontSizes || 'medium'} />;
    case 'error':
      return <ErrorSmallIcon fontSize={fontSizes || 'medium'} />;
    case 'success':
      return <SuccessSmallIcon fontSize={fontSizes || 'medium'} />;
    case 'warning':
      return <WarningSmallIcon fontSize={fontSizes || 'medium'} />;
    default:
      return false;
  }
};

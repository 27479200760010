export const styles = {
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: `28px`,
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '75vh',
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
  caption: {
    fontWeight: 700,
  },
  text: {
    textAlign: 'center',
  },
  close: {
    paddingLeft: '50px',
  },
};

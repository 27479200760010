import React, { useState } from 'react';

import { IconButton, ListItemIcon, Menu, MenuItem, styled } from '@mui/material';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { serviceConfig } from '@app/src/config';
import { useTheme } from '@app/themes/ThemeProvider';

import { ReactComponent as EngLightIcon } from './assets/eng-icon-light-theme.svg';
import { ReactComponent as EngIcon } from './assets/eng-icon-new.svg';
import { ReactComponent as RusLightIcon } from './assets/rus-icon-light-theme.svg';
import { ReactComponent as RusIcon } from './assets/rus-icon-new.svg';
import { ReactComponent as UkrIcon } from './assets/ukr-icon.svg';
import { ThemeEnum, Langs } from '../../types';

type LangIconsType = {
  [key: string]: React.FC;
};

const langDarkIcons: LangIconsType = {
  EN: EngIcon,
  RU: RusIcon,
  UA: UkrIcon,
};
const langLightIcons: LangIconsType = {
  EN: EngLightIcon,
  RU: RusLightIcon,
  UA: UkrIcon,
};

const LangMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.colors.langSwitcherBackground,
    border: `1px solid ${theme.palette.colors.langSwitcherBackground}`,
    borderRadius: '20px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
  },
  '& .MuiMenu-list': {
    padding: 0,
  },
}));

export const LangSwitcher: React.FC = () => {
  const { currentTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t, i18n } = useTranslation();

  const LangIconSVG = currentTheme === ThemeEnum.dark ? langDarkIcons[i18n.language] : langLightIcons[i18n.language];

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>,
    lang: string
  ) => {
    i18n.changeLanguage(lang);
    Cookies.set('Lang', lang, {
      sameSite: 'strict',
      secure: true,
      domain: serviceConfig.cookiesDomain,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const langsList = [i18n.language, ...Object.values(Langs).filter((lang) => lang !== i18n.language)];

  const menuItems: JSX.Element[] = langsList.map((type) => {
    const Icon = currentTheme === ThemeEnum.dark ? langDarkIcons[type] : langLightIcons[type];
    return (
      <MenuItem
        sx={{
          padding: '0 0 6px 0',
          minHeight: 'unset',
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiListItemIcon-root': {
            minWidth: '24px',
            padding: 0,
          },
        }}
        key={type}
        value={type}
        selected={i18n.language === type}
        onClick={(event) => handleMenuItemClick(event, type)}
      >
        <ListItemIcon
          sx={{
            '&:hover': {
              transformOrigin: 'center',
              transform: 'scale(1.15)',
            },
          }}
        >
          <Icon />
        </ListItemIcon>
      </MenuItem>
    );
  });

  return (
    <>
      <IconButton
        onClick={handleClickListItem}
        sx={{
          minWidth: '24px',
          padding: 0,
          '&:hover': {
            transformOrigin: 'center',
            transform: 'scale(1.15)',
          },
        }}
        size="large"
      >
        <LangIconSVG />
      </IconButton>
      <LangMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems}
      </LangMenu>
    </>
  );
};

export const styles = {
  form: {
    width: '100%',
  },
  selectInput: {
    color: '#FFFFFF',
    backgroundColor: 'colors.mainContentBackgroundColorMobile',
    '&:focus': {
      backgroundColor: 'colors.mainContentBackgroundColorMobile',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  chip: {
    height: '32px',
    backgroundColor: 'colors.gray500',
    borderRadius: '8px',
    border: `1px solid`,
    borderColor: 'colors.gray400',
    marginLeft: '4px',
    color: '#FFFFFF',
  },
  compactAccountLabel: {
    color: 'colors.gray300',
  },
  compactAccountValue: {
    color: '#FFFFFF',
    fontWeight: 600,
  },
  compactDateRangeLabel: {
    color: 'colors.gray300',
  },
  compactDateRangeValue: {
    color: '#FFFFFF',
    fontWeight: 600,
  },
  sheet: {
    width: '100vw',
    transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },

  sheetWithSidebarOpen: {
    '& [data-rsbs-overlay="true"]': {
      marginLeft: '272px',
    },
  },
  filterContainer: {
    position: 'relative',
  },
  filter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transformOrigin: 'top',
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 100ms',
  },
  filterHide: {
    transformOrigin: 'bottom',
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s linear 100ms, opacity 100ms',
  },
  filterCompact: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  bottomAnchor: {
    height: '10',
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
  titleText: {
    color: 'colors.gray400',
  },
  downloadButton: {
    color: 'colors.primary500',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  headerContent: {
    position: 'relative',
    height: '32px',
  },
  itemDefault: {
    userSelect: 'none',
    cursor: 'pointer',
    color: 'colors.gray200',
    fontWeight: 600,
    marginRight: '24px',
    fontSize: '0.85rem',
  },
  itemSelected: {
    userSelect: 'none',
    cursor: 'pointer',
    color: 'colors.primary400',
    fontWeight: 600,
    marginRight: '24px',
    fontSize: '0.85rem',
  },
  selectedActionsContainer: {
    backgroundColor: 'colors.primary500',
    height: '7rem',
    borderRadius: '8px',
    padding: '16px 16px 12px',
    marginBottom: '4px',
  },
  selectedCount: {
    color: 'white',
    marginRight: '16px',
    minWidth: '64px',
  },
  selectedAmountSum: {
    color: 'white',
  },
  selectedAmountCurrency: {
    color: 'white',
    marginRight: '12px',
  },
  buttonRoot: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  signButton: {
    color: '#ffffff',
    border: '1px solid #fff',
    backgroundColor: 'transparent',
  },
  menuItem: {
    borderBottom: `1px solid`,
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  amountText: {
    paddingRight: '4px',
    color: '#ffffff',
  },
  amountValue: {
    paddingRight: '4px',
    color: '#ffffff',
    fontWeight: 600,
  },
};

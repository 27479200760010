import React from 'react';

import { Button, CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ActionFiltersButtonsProps {
  resetFormHandler?(): void;
}

export const ActionFiltersButtons: React.FC<ActionFiltersButtonsProps> = ({ resetFormHandler }) => {
  const { t } = useTranslation();

  return (
    <CardActions disableSpacing>
      <Button color="primary" onClick={resetFormHandler}>
        {t('clear')}
      </Button>
      <Button type="submit" variant="contained" color="primary">
        {t('submit')}
      </Button>
    </CardActions>
  );
};

import { OverdraftsTableColumns } from '@app/pages/overdrafts/types';

export const fieldsCreditTable = [
  {
    columnType: OverdraftsTableColumns.startDate,
    enabled: true,
    order: 0,
    edit: false,
  },
  {
    columnType: OverdraftsTableColumns.endDate,
    enabled: true,
    order: 1,
    edit: false,
  },
  {
    columnType: OverdraftsTableColumns.accountOverdraft,
    enabled: true,
    order: 3,
    edit: true,
  },
  {
    columnType: OverdraftsTableColumns.totalLimit,
    enabled: true,
    order: 4,
    edit: true,
  },
  {
    columnType: OverdraftsTableColumns.usedLimit,
    enabled: true,
    order: 5,
    edit: true,
  },
  {
    columnType: OverdraftsTableColumns.availableLimit,
    enabled: true,
    order: 6,
    edit: true,
  },
  {
    columnType: OverdraftsTableColumns.currency,
    enabled: true,
    order: 7,
    edit: true,
  },
  {
    columnType: OverdraftsTableColumns.interestRate,
    enabled: true,
    order: 8,
    edit: true,
  },
];

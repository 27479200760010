export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: 'px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
  table: {
    flexWrap: 'nowrap',
  },
  searchActive: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: 4,
  },
  activeSearchAlign: {
    display: 'flex',
    flexDirection: 'row!important' as 'row',
  },
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  getClientId,
  GetCurrencyRepaymentTemplateResponseDto,
  GetRepaymentTemplateRequestDto,
  loansTranchesLoanLinesApiClient,
} from '@app/core/api';

const getCurrencyRepaymentTemplateFn = async (
  data: GetRepaymentTemplateRequestDto
): Promise<GetCurrencyRepaymentTemplateResponseDto> => {
  const response = await loansTranchesLoanLinesApiClient.loansControllerGetCurrencyRepaymentTemplate(
    getClientId(),
    data
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant get template.');
  }

  return response.data;
};

export const useGetCurrencyRepaymentTemplate = (): UseMutationResult<
  GetCurrencyRepaymentTemplateResponseDto,
  ApiError,
  GetRepaymentTemplateRequestDto
> => {
  return useMutation<GetCurrencyRepaymentTemplateResponseDto, ApiError, GetRepaymentTemplateRequestDto>(
    getCurrencyRepaymentTemplateFn
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon, InfoIcon } from '@app/common/icons';
import { PaymentOrderErrorMessagesEnum, PaymentTemplateTypeEnum } from '@app/core/api';
import {
  AccountSelectField,
  DateSelectField,
  DocumentNumberField,
  FormatInputField,
  FormatType,
  TextAreaField,
  Tooltip,
} from '@app/core/components';
import { styles } from '@app/pages/payment/components/BetweenMyAccountEditForm/styles';
import {
  BetweenMyAccountFormFieldName,
  BetweenMyAccountFormProps,
} from '@app/pages/payment/components/BetweenMyAccountEditForm/types';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const MobileBetweenMyAccountPaymentEditForm: React.FC<BetweenMyAccountFormProps> = ({
  defaultValues,
  signPermissions,
  senderAccountsData,
  recipientAccountsData,
  showBalance,
  countedBalance,
  createTemplateData,
  additionalInfo,
  onCancelHandler,
  onSaveClickHandler,
  onSaveAndSignClickHandler,
  onSendToSign,
  onTemplateChangeHandler,
  minValuationDate,
  maxValuationDate,
  errorSeverity,
  blockAction,
}) => {
  const { t } = useTranslation();
  const documentNumberRef = useRef<HTMLElement>(null);
  const nowDate = new Date();
  const { formState } = useFormContext();
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  useEffect(() => {
    setInvalidAmount(Boolean(formState.errors?.amount));
  }, [formState]);
  return (
    <Grid container wrap="nowrap" direction="column" sx={styles.contentMobile}>
      <Grid item container direction="column">
        <Box ref={documentNumberRef} mt={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              <Box pr={4} ref={documentNumberRef}>
                <DocumentNumberField
                  name={BetweenMyAccountFormFieldName.paymentDocumentNumber}
                  title={t('paymentCreationLocalPayment_paymentOrder')}
                  defaultValue={defaultValues.documentNumber || 'Auto123'}
                  error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
                  validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={onCancelHandler}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {onTemplateChangeHandler ? (
          <Box mt={6} mb={6}>
            <SelectPaymentTemplate
              templateType={PaymentTemplateTypeEnum.Own}
              onTemplateChange={onTemplateChangeHandler}
              placeholder={t('enterName_placeholder')}
            />
          </Box>
        ) : null}
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
          <Box pt={2}>
            <DateSelectField
              name={BetweenMyAccountFormFieldName.paymentDocumentDate}
              selectedDate={defaultValues.documentDate ? new Date(defaultValues.documentDate) : nowDate}
              inputProps={{ fullWidth: true }}
            />
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
          <Box mt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <DateSelectField
                  name={BetweenMyAccountFormFieldName.paymentDocumentValueDate}
                  datePickerProps={{
                    disabled: { after: maxValuationDate, before: minValuationDate },
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item container xs={1} alignContent="center" justifyContent="flex-end">
                <Tooltip
                  enterTouchDelay={10}
                  color="primary"
                  title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                  arrow
                  placement="right"
                >
                  <span>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_write_off_account')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={BetweenMyAccountFormFieldName.senderAccount}
              options={senderAccountsData?.accounts}
              placeholder={t('paymentCreationBetweenMyAccount_enrollment_accountPlaceholder')}
              errorSeverity={errorSeverity}
              defaultAccount={senderAccountsData?.defaultAccount}
            />
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={BetweenMyAccountFormFieldName.recipientAccount}
              options={recipientAccountsData}
              defaultSelectFirst
              placeholder={t('paymentCreationBetweenMyAccount_enrollment_accountPlaceholder')}
            />
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
          <Box pt={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={11}>
                <FormatInputField
                  defaultValue={defaultValues.amount ? `${defaultValues.amount}` : ''}
                  type={FormatType.amount}
                  name={BetweenMyAccountFormFieldName.amount}
                  placeholder={'0.00'}
                />
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end" alignItems="center">
                <Tooltip color="primary" title={t('tooltip_bank-tariff')} arrow enterTouchDelay={10} placement="right">
                  <span>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
          <Box pt={2}>
            <TextAreaField
              defaultValue={defaultValues.paymentReference || ''}
              name={BetweenMyAccountFormFieldName.paymentReference}
              maxLength={420}
              textFieldProps={{
                multiline: true,
                fullWidth: true,
                rows: 2,
              }}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <SavePaymentTemplate createTemplateData={createTemplateData} />
          </Grid>
        </Box>
        <Box mt={6} mb={3}>
          <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
            {permissions.uahOperations.create || permissions.uahOperations.edit ? (
              <Box pr={4} width="40%">
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={onSaveClickHandler}
                  disabled={invalidAmount || blockAction}
                >
                  {t('paymentCreationFormControl_save')}
                </Button>
              </Box>
            ) : null}
            {signPermissions.allowToSendToSign ? (
              <Box width="60%">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onSendToSign}
                  disabled={invalidAmount || blockAction}
                >
                  {t('paymentCreationFormControl_sendToSign')}
                </Button>
              </Box>
            ) : null}
            {signPermissions.allowToSign ? (
              <Box width="60%">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onSaveAndSignClickHandler}
                  disabled={invalidAmount || blockAction}
                >
                  {t('paymentCreationFormControl_send')}
                </Button>
              </Box>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, getClientId, profileApiClient, SendSecretEmailRequestDto } from '@app/core/api';

export const verifyUserEmailQueryFn = async ({ language }: SendSecretEmailRequestDto): Promise<object> => {
  const response = await profileApiClient.profileControllerSendSecretEmail(getClientId(), { language });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't verify email.");
  }

  return response.data;
};

export const useVerifyUserEmail = (): UseMutationResult<object, ApiError, SendSecretEmailRequestDto> => {
  return useMutation<object, ApiError, SendSecretEmailRequestDto>(verifyUserEmailQueryFn);
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, currencyOperationsApiClient, getClientId, NotifySignersQueryDto } from '@app/core/api';

const sendRemindCurrencyOperationSignFn = async (notifyData: NotifySignersQueryDto): Promise<object> => {
  const response = await currencyOperationsApiClient.currencyOperationSignControllerNotifyAnotherSigners(
    getClientId(),
    notifyData
  );
  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationSignControllerNotifyAnotherSigners error. Can't send notify."
    );
  }

  return response.data;
};

export const useSendRemindCurrencyOperationSign = (): UseMutationResult<object, ApiError, NotifySignersQueryDto> => {
  return useMutation<object, ApiError, NotifySignersQueryDto>(sendRemindCurrencyOperationSignFn);
};

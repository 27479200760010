import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FrontLanguageEnum, MessageField, MessageStatus, MessageType } from '@app/core/api';
import { SelectOptionType } from '@app/core/components';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetCategoriesList, useGetMessagesList } from '@app/core/hooks';
import { ColumnFilterType, PaginationChangeType, SortingRule } from '@app/core/types';
import { getEmptyTableMessage, makeLettersSubFilter } from '@app/pages/letters/LettersListContent/helpers';
import { LettersSubFilterEnum } from '@app/pages/letters/types';

import { LettersTable } from './LettersTable';
import { SubMenu } from './SubMenu';

export const LettersListContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const [messageType, setMessageType] = useState<MessageType>(
    (searchParams.get('type') as MessageType) || MessageType.Incoming
  );
  const [messageStatus, setMessageStatus] = useState<MessageStatus | undefined>(
    (searchParams.get('status') as MessageStatus) || undefined
  );
  const columnFilter = useRef<ColumnFilterType<LettersSubFilterEnum>>({});
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const sortRuleRef = useRef<SortingRule<MessageField>>({ field: MessageField.CreatedAt, desc: true });
  const { data: categoriesData, mutate: mutateGetCategoriesList, isLoading: isLoading1 } = useGetCategoriesList();
  const { data, mutate, isLoading } = useGetMessagesList();
  const messages = data?.messages || [];
  const pagination = data?.pagination;

  const updateList = () => {
    mutate({
      type: messageType,
      status: messageStatus,
      language: i18n.language as unknown as FrontLanguageEnum,
      subFilters: makeLettersSubFilter(columnFilter.current),
      pagination: paginationRef.current,
      sortBy: sortRuleRef.current,
    });
  };

  useEffect(() => {
    mutate({
      type: messageType,
      status: messageStatus,
      language: i18n.language as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  }, [i18n.language, messageStatus, messageType, mutate]);

  useEffect(() => {
    mutateGetCategoriesList({
      language: i18n.language as FrontLanguageEnum,
      pagination: { page: 1, rowsPerPage: 100 },
    });
  }, [i18n.language, mutateGetCategoriesList]);

  const handleFilterChange = () => {
    // submitFilter(makeDataForSubmit(getValues()));
  };

  const onSubmit = () => {
    handleFilterChange();
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleFilterChange();
    }
  };

  const onSortHandler = (rule: SortingRule<MessageField>) => {
    sortRuleRef.current = rule;
    updateList();
  };

  // const watchSubFilters = useWatch({
  //   control,
  //   name: [LettersTableColumns.createDate],
  // });

  const onSubMenuChangeHandler = (status: MessageStatus | undefined) => {
    paginationRef.current = {
      page: DEFAULT_FIRST_PAGE,
      rowsPerPage: paginationRef.current.rowsPerPage,
    };
    columnFilter.current = {};
    setMessageStatus(status);
    setSearchParams(``);
  };

  const onFilterChangeHandler = (filter: MessageType) => {
    paginationRef.current = {
      page: DEFAULT_FIRST_PAGE,
      rowsPerPage: paginationRef.current.rowsPerPage,
    };
    sortRuleRef.current = { field: MessageField.CreatedAt, desc: true };
    columnFilter.current = {};
    setMessageType(filter);
    setMessageStatus(undefined);
    setSearchParams(``);
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateList();
  };

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<LettersSubFilterEnum>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    if (submit) {
      if (isLoading) {
        return;
      }
      updateList();
    }
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    if (isLoading) {
      return;
    }
    updateList();
  };
  const menuItems: MenuItem<MessageType>[] = [
    {
      id: 1,
      value: MessageType.Incoming,
      labelText: t('incoming'),
    },
    {
      id: 2,
      value: MessageType.Outgoing,
      labelText: t('outgoing'),
    },
  ];

  const itemsIncomingSubMenuItems: MenuItem<MessageStatus | undefined>[] = [
    {
      id: 1,
      value: undefined,
      labelText: t('all'),
    },
    {
      id: 2,
      value: MessageStatus.New,
      labelText: t(`letter_menu_new_message`),
    },
    {
      id: 3,
      value: MessageStatus.Read,
      labelText: t(`letter_menu_read`),
    },
  ];

  const itemsOutgoingSubMenuItems: MenuItem<MessageStatus | undefined>[] = [
    {
      id: 1,
      value: undefined,
      labelText: t('all'),
    },
    {
      id: 2,
      value: MessageStatus.Saved,
      labelText: t(`letter_menu_saved`),
    },
    {
      id: 3,
      value: MessageStatus.ToSign,
      labelText: t(`TO_SIGN`),
    },
    {
      id: 4,
      value: MessageStatus.Signed,
      labelText: t(`letter_menu_signed`),
    },
    {
      id: 5,
      value: MessageStatus.Delivered,
      labelText: t(`letter_menu_delivered`),
    },
  ];

  const subMenuItems = messageType === MessageType.Incoming ? itemsIncomingSubMenuItems : itemsOutgoingSubMenuItems;

  const incomingMessageStatusOptionsItems: SelectOptionType[] = [
    {
      id: -1,
      name: t('all'),
      value: '',
    },
    {
      id: MessageStatus.New,
      name: t(MessageStatus.New),
      value: MessageStatus.New,
    },
    {
      id: MessageStatus.Read,
      name: t(MessageStatus.Read),
      value: MessageStatus.Read,
    },
  ];

  const outgoingMessageStatusOptionsItems: SelectOptionType[] = [
    {
      id: -1,
      name: t('all'),
      value: '',
    },
    {
      id: MessageStatus.Saved,
      name: t(MessageStatus.Saved),
      value: MessageStatus.Saved,
    },
    {
      id: MessageStatus.ToSign,
      name: t(MessageStatus.ToSign),
      value: MessageStatus.ToSign,
    },
    {
      id: MessageStatus.Signed,
      name: t(MessageStatus.Signed),
      value: MessageStatus.Signed,
    },
    {
      id: MessageStatus.Delivered,
      name: t(MessageStatus.Delivered),
      value: MessageStatus.Delivered,
    },
  ];

  const emptyTableMessage = getEmptyTableMessage(messageType, messageStatus);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{
        height: '100%',
      }}
    >
      <Box mt={4}>
        <HorizontalMenu<MessageType>
          defaultActiveItem={messageType}
          onChange={onFilterChangeHandler}
          menuItems={menuItems}
        />
      </Box>
      <Box mt={4}>
        <SubMenu items={subMenuItems} selectedItem={messageStatus} onChange={onSubMenuChangeHandler} />
      </Box>
      {messageType === MessageType.Incoming ? (
        <LettersTable
          loading={isLoading}
          messages={messages}
          messageType={messageType}
          pagination={pagination}
          categoryList={categoriesData?.data || []}
          columnFilter={columnFilter.current}
          showStatusSubFilter={!messageStatus}
          messageStatusOptionsItems={!messageStatus ? incomingMessageStatusOptionsItems : []}
          onSort={onSortHandler}
          onPaginationChange={onPaginationChangeHandler}
          onColumnFilterChange={onColumnFilterChangeHandler}
          updateList={updateList}
          emptyMessageTable={emptyTableMessage}
          onClearSubFilter={onClearSubFilterHandler}
        />
      ) : null}
      {messageType === MessageType.Outgoing ? (
        <LettersTable
          loading={isLoading}
          messages={messages}
          messageType={messageType}
          pagination={pagination}
          categoryList={categoriesData?.data || []}
          columnFilter={columnFilter.current}
          showStatusSubFilter={!messageStatus}
          messageStatusOptionsItems={outgoingMessageStatusOptionsItems}
          onSort={onSortHandler}
          onPaginationChange={onPaginationChangeHandler}
          onColumnFilterChange={onColumnFilterChangeHandler}
          updateList={updateList}
          emptyMessageTable={emptyTableMessage}
          onClearSubFilter={onClearSubFilterHandler}
        />
      ) : null}
    </Grid>
  );
};

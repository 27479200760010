import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format, subDays } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AccountStatusEnum,
  AvailableActionsDto,
  CurrencyEnum,
  DocumentTemplateDto,
  GetAccountResponseDto,
  PaymentOrderAdditionalInfoDto,
} from '@app/core/api';
import { PassportTabsEnum } from '@app/core/components/PassportTabs';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGetCurrentAccountsByCurrency } from '@app/core/hooks/useGetCurrentAccountsByCurrency';
import { Counterparty } from '@app/core/types';
import { isMobile, formatAmount } from '@app/core/utils';
import { DesktopLocalPaymentEditForm } from '@app/pages/payment/components/LocalPaymentEditForm/DesktopLocalPaymentEditForm';
import { makeCreateTemplateData } from '@app/pages/payment/components/LocalPaymentEditForm/helpers';
import { MobileLocalPaymentEditForm } from '@app/pages/payment/components/LocalPaymentEditForm/MobileLocalPaymentEditForm';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import {
  DefaultValues,
  FormFieldName,
  PaymentFormData,
} from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { validationSchema } from '@app/pages/payment/components/LocalPaymentEditForm/validationSchema';
import { calculateMaxValuationDate } from '@app/pages/payment/create/LocalPayment/helpers';

export interface LocalPaymentEditFormProps {
  defaultValues: DefaultValues;
  signPermissions: AvailableActionsDto;
  recipientAccountDisabled?: boolean;
  additionalInfo?: PaymentOrderAdditionalInfoDto | null;
  onSave(paymentData: PaymentFormData): void;
  onSaveAndSign(paymentData: PaymentFormData, signFromAnotherPerson?: boolean): void;
  onSaveAndSendToSign(paymentData: PaymentFormData): void;
  onCancel(): void;
  onTemplateChange?(templateData: DocumentTemplateDto): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const LocalPaymentEditForm: React.FC<LocalPaymentEditFormProps> = ({
  defaultValues,
  signPermissions,
  recipientAccountDisabled = false,
  additionalInfo,
  onSave,
  onSaveAndSign,
  onCancel,
  onTemplateChange,
  onChange,
  onSaveAndSendToSign,
}) => {
  const { t } = useTranslation();
  const [passportActiveTab, setPassportActiveTab] = useState<PassportTabsEnum>(PassportTabsEnum.passport);
  const defaultDocumentDate = defaultValues.paymentDocumentDate
    ? new Date(defaultValues.paymentDocumentDate)
    : new Date();

  const maxDocumentDate = addDays(defaultDocumentDate, 30);
  const minDocumentDate = subDays(defaultDocumentDate, 30);
  const [maxValuationDate, setMaxValuationDate] = useState<Date>(calculateMaxValuationDate(defaultDocumentDate));
  const [minValuationDate, setMinValuationDate] = useState<Date>(defaultDocumentDate);
  const [vatEnabled, setVatEnabled] = useState<boolean>(false);
  const previousAmountInfoRef = useRef<string>('');
  const previousVatInfoRef = useRef<string>('');
  const { data: senderAccountsData, mutate } = useGetCurrentAccountsByCurrency();

  useEffect(() => {
    mutate(CurrencyEnum.Uah);
  }, [mutate]);

  const methods = useForm<PaymentFormData>({
    resolver: yupResolver(validationSchema({ t })),
    mode: 'onSubmit',
    defaultValues: {
      [FormFieldName.recipientNonLeiFlag]: false,
      [FormFieldName.realSenderNonLeiFlag]: false,
      [FormFieldName.realRecipientNonLeiFlag]: false,
    },
  });

  const {
    reset: formReset,
    control,
    setValue,
    trigger,
    getValues,
    clearErrors,
    formState: { isDirty, dirtyFields },
    setError,
  } = methods;

  useEffect(() => {
    if (senderAccountsData && senderAccountsData.defaultAccount) {
      setValue(FormFieldName.senderAccount, JSON.stringify(senderAccountsData.defaultAccount));
      if (senderAccountsData.defaultAccount.account.status === AccountStatusEnum.Blocked) {
        setError(FormFieldName.senderAccount, { message: t('accountBlocked') });
      }
      if (senderAccountsData.defaultAccount.account.status === AccountStatusEnum.Closed) {
        setError(FormFieldName.senderAccount, { message: t('accountClosed') });
      }
    }
  }, [senderAccountsData, setError, setValue, t]);

  useEffect(() => {
    clearErrors();
  }, [clearErrors, defaultValues]);

  useEffect(() => {
    if (defaultValues?.recipientAccountName) {
      setValue(`${FormFieldName.recipientName}`, defaultValues.recipientAccountName);
    }
    if (defaultValues?.recipientAccountNumber) {
      setValue(`${FormFieldName.recipientAccount}`, defaultValues.recipientAccountNumber);
    }
    if (defaultValues?.recipientLei) {
      setValue(FormFieldName.recipientLei, defaultValues.recipientLei);
    }
    if (defaultValues?.recipientPassport) {
      setValue(FormFieldName.recipientPassport, defaultValues.recipientPassport);
    }
    if (defaultValues?.amount) {
      setValue(`${FormFieldName.amount}`, `${parseFloat(`${defaultValues.amount}`).toFixed(2)}`);
    }
    if (defaultValues?.paymentReference) {
      setValue(`${FormFieldName.purpose}`, defaultValues.paymentReference);
    }
    if (defaultValues?.nonResidentFlag) {
      setValue(`${FormFieldName.nonResidentFlag}`, defaultValues.nonResidentFlag);
    }
    if (defaultValues?.recipientBankName) {
      setValue(`${FormFieldName._bankName}`, defaultValues.recipientBankName);
    }
    if (defaultValues?.paymentDocumentDate) {
      setValue(`${FormFieldName.documentDate}`, new Date(defaultValues.paymentDocumentDate));
      setValue(
        FormFieldName.temp_documentDate,
        format(new Date(defaultValues.paymentDocumentDate), ddMMYYYYFormat.format)
      );
    }
    if (defaultValues?.paymentDocumentValueDate) {
      setValue(FormFieldName.documentValuationDate, new Date(defaultValues.paymentDocumentValueDate));
      setValue(
        FormFieldName.temp_documentValuationDate,
        format(new Date(defaultValues.paymentDocumentValueDate), ddMMYYYYFormat.format)
      );
    }
    if (defaultValues?.realSenderName) {
      setValue(FormFieldName.realSenderPresent, true);
      setValue(FormFieldName.realSenderName, defaultValues.realSenderName);
      setValue(FormFieldName.realSenderTIN, defaultValues.realSenderTIN);
      setValue(FormFieldName.realSenderNonResidentFlag, defaultValues.realSenderNonResidentFlag);
      setValue(FormFieldName.realSenderNonLeiFlag, defaultValues.realSenderNonLeiFlag);
      setValue(FormFieldName.realSenderPassport, defaultValues.realSenderPassport);
    }
    if (defaultValues?.realRecipientName) {
      setValue(FormFieldName.realRecipientPresent, true);
      setValue(FormFieldName.realRecipientName, defaultValues.realRecipientName);
      setValue(FormFieldName.realRecipientTIN, defaultValues.realRecipientTIN);
      setValue(FormFieldName.realRecipientNonResidentFlag, defaultValues.realRecipientNonResidentFlag);
      setValue(FormFieldName.realRecipientNonLeiFlag, defaultValues.realRecipientNonLeiFlag);
      setValue(FormFieldName.realRecipientPassport, defaultValues.realRecipientPassport);
    }
    setValue(FormFieldName.recipientNonLeiFlag, defaultValues.recipientNonLeiFlag, {
      shouldValidate: false,
      shouldDirty: false,
    });
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (senderAccountsData && defaultValues?.senderAccountId) {
      const activeAccount = senderAccountsData.accounts.find(
        ({ account }) => account.id === defaultValues.senderAccountId
      );
      if (activeAccount) {
        setValue(`${FormFieldName.senderAccount}`, JSON.stringify(activeAccount), {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    }
  }, [senderAccountsData, defaultValues, setValue]);

  const vat = useWatch<PaymentFormData>({ control, name: FormFieldName.vat, defaultValue: 20 });
  const amount = useWatch<PaymentFormData>({ control, name: FormFieldName.amount, defaultValue: 0 });
  const vatAmount = (Number(amount) / (100 + Number(vat))) * Number(vat);
  const fixedVatAmount = vatAmount.toFixed(2);

  useEffect(() => {
    if (!defaultValues?.paymentReference) {
      const amountInfo = t('paymentCreationLocalPayment_purpose_value', { amount });
      const purposeVat = t('paymentCreationLocalPayment_purpose_vat', {
        vat,
        vatAmount: fixedVatAmount,
      });
      const vatInfo = vatEnabled ? purposeVat : '';
      const currentPurpose: string = getValues(FormFieldName.purpose);
      const purpose: string = currentPurpose
        .replace(previousAmountInfoRef.current, '')
        .replace(previousVatInfoRef.current, '');

      previousAmountInfoRef.current = amountInfo;
      previousVatInfoRef.current = vatInfo;
      setValue(`${FormFieldName.purpose}`, purpose + amountInfo + vatInfo, {
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [amount, fixedVatAmount, getValues, defaultValues, setValue, t, vat, vatEnabled]);

  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const watchBalance = useWatch({ control, name: [`${FormFieldName.senderAccount}`, `${FormFieldName.amount}`] });

  useEffect(() => {
    const [watchBalanceSenderAccount, watchBalanceAmount] = watchBalance;
    if (watchBalanceSenderAccount && watchBalanceAmount) {
      const {
        account: { amount: selectedAccountAmount },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      const watchBalanceNotEmpty = selectedAccountAmount && watchBalanceAmount;
      if (watchBalanceNotEmpty) {
        setBalance(selectedAccountAmount);
        setShowBalance(true);
      }
    }
  }, [watchBalance]);

  const countedBalance = formatAmount(balance - Number(amount));

  const watchDateFrom = useWatch({
    control,
    name: FormFieldName.documentDate,
  });

  useEffect(() => {
    if (watchDateFrom) {
      setMaxValuationDate(calculateMaxValuationDate(watchDateFrom));
      setMinValuationDate(watchDateFrom);
      setValue(FormFieldName.documentValuationDate, undefined, { shouldValidate: false, shouldDirty: false });
      setValue(FormFieldName.temp_documentValuationDate, '', { shouldValidate: false, shouldDirty: false });
    }
  }, [formReset, setValue, watchDateFrom]);

  const watchRecipientAccount = useWatch({
    control,
    name: FormFieldName.recipientAccount,
  });

  const watchRecipientName = useWatch({ control, name: FormFieldName.recipientName });

  useEffect(() => {
    if (watchRecipientAccount) {
      setValue(`${FormFieldName.recipientAccount}`, watchRecipientAccount, {
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [setValue, watchRecipientAccount]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length || watchRecipientName || watchRecipientAccount) {
      onChange({ formDirty: true });
    }
  }, [dirtyFields, onChange, watchRecipientAccount, watchRecipientName]);

  const passportTabsChangeHandler = (activeTab: PassportTabsEnum) => {
    setPassportActiveTab(activeTab);
  };

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSave(getValues());
      }
    });
  };

  const onSendToSign = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSendToSign(getValues());
      }
    });
  };

  const onSaveAndSignClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign(getValues());
      }
    });
  };

  const onSignFromAnotherPersonHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign(getValues(), true);
      }
    });
  };

  const vatSwitchHandler = () => {
    setVatEnabled(!vatEnabled);
  };

  const onSelectCounterpartyHandler = (counterparty: Counterparty) => {
    setValue(`${FormFieldName.recipientAccount}`, counterparty.account, { shouldDirty: false });
    setValue(FormFieldName.recipientLei, counterparty.lei);
    setValue(`${FormFieldName._bankName}`, counterparty.bank);
  };

  const clearRecipientHandler = () => {
    setValue(`${FormFieldName.recipientAccount}`, '');
    setValue(FormFieldName.recipientLei, '');
    setValue(`${FormFieldName._bankName}`, '');
  };

  const createTemplateData = () => {
    return makeCreateTemplateData(getValues());
  };

  const Component = isMobile ? MobileLocalPaymentEditForm : DesktopLocalPaymentEditForm;
  return (
    <FormProvider {...methods}>
      <form style={styles.form}>
        <Component
          defaultValues={defaultValues}
          additionalInfo={additionalInfo}
          signPermissions={signPermissions}
          defaultDocumentDate={defaultDocumentDate}
          maxDocumentDate={maxDocumentDate}
          minDocumentDate={minDocumentDate}
          maxValuationDate={maxValuationDate}
          minValuationDate={minValuationDate}
          senderAccountsData={senderAccountsData}
          passportActiveTab={passportActiveTab}
          vatEnabled={vatEnabled}
          showBalance={showBalance}
          countedBalance={countedBalance}
          recipientAccountDisabled={recipientAccountDisabled}
          documentType={defaultValues.documentType}
          createTemplateData={createTemplateData}
          onSelectCounterpartyHandler={onSelectCounterpartyHandler}
          clearRecipientHandler={clearRecipientHandler}
          passportTabsChangeHandler={passportTabsChangeHandler}
          vatSwitchHandler={vatSwitchHandler}
          onCancelClick={onCancel}
          onSaveClick={onSaveClickHandler}
          onSaveAndSignClick={onSaveAndSignClickHandler}
          onTemplateChangeHandler={onTemplateChange}
          onSendToSign={onSendToSign}
          onSaveAndSignFromAnotherPerson={onSignFromAnotherPersonHandler}
        />
      </form>
    </FormProvider>
  );
};

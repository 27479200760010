import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { MultipleDateSelect } from '@app/core/components/DateSelect';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetCompanyHistoryOfVisit } from '@app/core/hooks';
import { DateRange, DateRangeFromTo, PaginationChangeType } from '@app/core/types';
import { VisitsTable } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable';
import { styles } from '@app/pages/companyProfile/desktop/style';

export const ProfileSettingsItemCompanyHistoryOfVisits: React.FC = () => {
  const [pagination, setPagination] = useState<PaginationChangeType>({
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    page: DEFAULT_FIRST_PAGE,
  });
  const methods = useForm<DateRangeFromTo>({ mode: 'onChange' });
  const dateFilterRef = useRef<DateRange | undefined>(undefined);

  const { data, mutate } = useGetCompanyHistoryOfVisit();

  useEffect(() => {
    mutate({
      pagination,
      dateRange: dateFilterRef.current
        ? {
            startDate: dateFilterRef.current.startDate.toISOString(),
            endDate: dateFilterRef.current.endDate.toISOString(),
          }
        : undefined,
    });
  }, [mutate, pagination]);

  const visits = data?.sessions || [];

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    setPagination(paginationChange);
  };

  const periodOnChange = (dateRange: DateRange | undefined) => {
    dateFilterRef.current = dateRange;
    mutate({
      pagination,
      dateRange: dateFilterRef.current
        ? {
            startDate: dateFilterRef.current.startDate.toISOString(),
            endDate: dateFilterRef.current.endDate.toISOString(),
          }
        : undefined,
    });
  };

  const onClearSubFilterHandler = () => {
    setPagination({ page: DEFAULT_FIRST_PAGE, rowsPerPage: pagination.rowsPerPage });
    mutate({
      pagination: { page: DEFAULT_FIRST_PAGE, rowsPerPage: pagination.rowsPerPage },
    });
  };
  return (
    <FormProvider {...methods}>
      <Grid container direction="column" wrap="nowrap" sx={styles.verticalWrapper}>
        <Grid container justifyContent="flex-end">
          <MultipleDateSelect onChange={periodOnChange} />
        </Grid>
        <Box pt={3} sx={styles.verticalWrapper}>
          <VisitsTable
            visitsData={visits}
            pagination={data?.pagination}
            onPaginationChange={onPaginationChangeHandler}
            onClearSubFilter={onClearSubFilterHandler}
          />
        </Box>
      </Grid>
    </FormProvider>
  );
};

import { isMobile } from '../../utils';

export const styles = {
  root: {
    width: '100%',
    '@media (max-width: 1279.95px)': {
      paddingLeft: '4px',
    },
  },
  toolBar: {
    justifyItems: 'center',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
  },
  horizontalScroll: {
    '@media (max-width: 1279.95px)': {
      marginRight: '4px',
      overflow: 'scroll',
    },
  },
  searchInput: {
    paddingRight: 0,
  },
  searchIcon: {
    fontSize: '16px',
    color: 'colors.gray300',
    fill: 'currentColor',
  },

  //  start tables styles
  tableRow: {
    backgroundColor: 'colors.containerBackgroundColor',
    padding: '10px 16px',
    borderRadius: '8px',
    marginBottom: '4px',
    zIndex: 3,
    cursor: 'pointer',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  tableCell: {
    borderBottom: 'none!important',
    flex: 1,
  },
  borderNone: {
    border: 'none',
  },
  tableRowExpanded: {
    marginBottom: 0,
  },
  dateCell: {
    flexDirection: 'column',
    alignItems: 'start',
    flexShrink: 0,
    maxWidth: '120px',
  },
  accountCell: {
    flexDirection: 'column',
    alignItems: 'start',
    maxWidth: '310px',
    flex: 2,
  },
  referenceCell: {
    flex: 2,
    maxWidth: '450px',
    flexDirection: 'column',
    alignItems: 'start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  amountCell: {
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },
  checkCell: {
    maxWidth: '40px',
  },
  actionCell: {
    maxWidth: '50px',
  },
  operationDetailsContent: {
    transform: 'translate(0, -4px)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  documentDetailsContent: {
    transform: 'translate(0, -8px)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  noOperationsContainer: {
    color: 'colors.gray200',
    paddingTop: '20px',
    paddingBottom: '40px',
    textAlign: 'center',
  },
};

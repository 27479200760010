import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SearchSmallIcon } from '@app/common/icons';
import { styles } from '@app/core/components/OperationsTable/styles';
import { useGetCompanyUsers } from '@app/core/hooks';
import { CompanyUsersCard } from '@app/pages/companyProfile/mobile/components/MobileUsers/components/CompanyUsersCard';

export const MobileUsersPage: React.FC = () => {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const { data, mutate, reset } = useGetCompanyUsers();

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const onSearchButtonClick = () => {
    setShowSearch(true);
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      reset();
      mutate({ fullName: searchInputValue });
    }
  };

  const onSearchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const onClearSearchClickHandler = () => {
    setSearchInputValue('');
    mutate({});
    setShowSearch(false);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: '#fff',
        position: 'absolute',
        width: '100%',
        top: '15%',
        left: 0,
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        padding: '18px 20px 0 20px',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      {showSearch ? (
        <OutlinedInput
          onChange={onSearchChangeHandler}
          onKeyPress={onKeyPressHandler}
          sx={styles.searchInput}
          placeholder={t('search')}
          fullWidth
          value={searchInputValue}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={onClearSearchClickHandler} size="large">
                <CrossIcon sx={styles.searchIcon} />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <Grid item container justifyContent="space-between" alignItems="center">
          <Box>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography variant="caption" color="textSecondary">
                {t('user_length')}
              </Typography>
              <Typography variant="body2" sx={{ paddingLeft: '4px', color: 'colors.primary700', fontWeight: 600 }}>
                {data?.activeCount}
              </Typography>
            </Grid>
          </Box>
          <Box pr={4} onClick={onSearchButtonClick}>
            <SearchSmallIcon fontSize="small" />
          </Box>
        </Grid>
      )}
      {data?.users.map((user) => (
        <CompanyUsersCard user={user} key={user.id} />
      ))}
    </Grid>
  );
};

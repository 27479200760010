import { isValidIBAN } from 'ibantools';
import { string, object, SchemaOf } from 'yup';

import { CounterpartyFormFieldsEnum } from '@app/pages/counterparties/types';

type SchemaObject = {
  lei?: string;
  account?: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaObject> => {
  return object().shape({
    account: string().test(
      CounterpartyFormFieldsEnum.recipientAccount,
      t('paymentCreationLocalPayment_recipient_error_format'),
      (iban: string | undefined): boolean => {
        if (iban && iban.length > 0) {
          return isValidIBAN(iban);
        }
        return true;
      }
    ),
    lei: string().test('lei', t('counterpartyFilterLei_field_error'), (val) => {
      if (val && val.length) {
        return val?.length === 8 || val?.length === 10;
      }
      return true;
    }),
  });
};

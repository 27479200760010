export const styles = {
  root: {
    backgroundColor: 'colors.containerBackgroundColor',
    borderRadius: '8px',
    padding: '12px',
    marginBottom: '12px',
  },
  documentNumber: {
    color: 'colors.gray400',
    fontWeight: 600,
    marginRight: '16px',
  },
  date: {
    color: 'colors.gray400',
    fontWeight: 400,
  },
  selectCheckboxContainer: {
    position: 'relative',
  },
  selectCheckbox: {
    position: 'absolute',
    right: 0,
    transform: 'translate(0, -50%)',
  },
  accountContainer: {
    width: ' 100%',
    fontSize: '0.875rem',
    marginTop: '12px',
    paddingRight: '32px',
  },
};

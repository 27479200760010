import { string, object, SchemaOf } from 'yup';

type SchemaValidationObject = {
  name: string;
  middleName: string;
  surname: string;
  email: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    name: string().required(t('paymentCreationLocalPayment_field_required')),
    middleName: string().required(t('paymentCreationLocalPayment_field_required')),
    surname: string().required(t('paymentCreationLocalPayment_field_required')),
    email: string().email().required(),
  });
};

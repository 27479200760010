import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatAmount } from '../../utils';

type Rate = {
  name: string;
  code: number;
  buyRate: number;
  sellRate: number;
};

type CurrencyBankInfoProps = {
  rates: Rate[];
};

export const CurrencyBankInfo: React.FC<CurrencyBankInfoProps> = ({ rates }) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="space-between">
      {rates.map((rate: Rate) => (
        <Grid
          key={rate.name}
          item
          xs={5}
          container
          direction="column"
          sx={{
            padding: '0 16px',
          }}
        >
          <Box
            sx={{
              color: 'colors.currencyTabCurrencyName',
              fontWeight: 600,
              fontSize: '12px',
            }}
          >
            {rate.name}
          </Box>
          <div>
            <Typography
              variant="caption"
              sx={{
                fontSize: '10px',
                color: 'colors.currencyTabCurrencyDescription',
              }}
            >
              {t('currency_tab_bank_description')}
            </Typography>
          </div>
          <Box
            sx={{
              color: 'colors.currencyTabCurrencyInfoText',
              fontSize: '14px',
            }}
          >
            {formatAmount(rate.buyRate)} / {formatAmount(rate.sellRate)}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export const styles = {
  form: {
    width: '100%',
  },
  header: {
    fontSize: '1rem',
    color: 'colors.primary700',
    fontWeight: 900,
    marginRight: '4px',
  },
  tabBody: {
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
  },
  paymentNumber: {
    height: '50px',
  },
  bookmarkBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  info: {
    color: 'colors.primary500',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  correspondentBankCheck: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  selectPlaceholder: {
    color: 'colors.gray300',
    fontWeight: 400,
  },
  actionIcon: {
    color: 'colors.gray300',
  },
  deleteText: {
    fontSize: '1rem',
    color: 'colors.gray300',
    marginRight: '14px',
  },
  relateOperationPlaceholder: {
    color: 'colors.gray500',
  },
  relatedOperation: {
    cursor: 'pointer',
  },
  rejectContainer: {
    height: '44px',
    backgroundColor: 'colors.error',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  rejectReasonIcon: {
    color: '#FFF',
    marginLeft: '16px',
    marginRight: '16px',
  },
  rejectReasonText: {
    color: '#FFF',
  },
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SignatureIcon } from '@app/common/icons';
import { CheckAvailabilityFundsDtoStatusEnum } from '@app/core/api';
import { useCheckAvailabilityFunds } from '@app/core/hooks';
import { ReactComponent as SignatureBigSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

import { styles } from './style';
import { Operation } from '../../types';
import { formatAmount } from '../../utils';
import { ModalCommon } from '../Modal';

interface ConfirmSignPaymentOrderDialogProps {
  onAgreeSign(): void;
  onClose(): void;
  toSignDocument: Operation;
}

export const ConfirmSignPaymentOrderDialog: React.FC<ConfirmSignPaymentOrderDialogProps> = ({
  onAgreeSign,
  onClose,
  toSignDocument,
}) => {
  const { t } = useTranslation();
  const { data: checkAvailabilityData, mutate } = useCheckAvailabilityFunds();
  const [showActions, setShowActions] = useState<boolean>(false);
  const [insufficientFundsError, setInsufficientFundsError] = useState<boolean>(false);

  useEffect(() => {
    mutate([toSignDocument.uuid]);
  }, [mutate, toSignDocument.uuid]);

  useEffect(() => {
    if (checkAvailabilityData) {
      if (
        checkAvailabilityData.response.length === 1 &&
        checkAvailabilityData.response[0].status === CheckAvailabilityFundsDtoStatusEnum.InsufficientFunds
      ) {
        setInsufficientFundsError(true);
        return;
      }
      setShowActions(true);
    }
  }, [checkAvailabilityData]);

  return (
    <ModalCommon onClose={onClose} sx={styles.root} open>
      <Box sx={styles.paper}>
        {insufficientFundsError ? (
          <Box sx={styles.errorNotifyContainer}>
            <Typography variant="caption" sx={styles.errorNotifyText}>
              {t('notEnoughFunds')}
            </Typography>
          </Box>
        ) : null}

        <Box sx={styles.iconBg}>
          <SignatureBigSVG />
        </Box>

        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <Box mt={6} mr={6}>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        <Box mt={3} mb={7}>
          <Typography variant="body1" sx={styles.title}>
            {t('documentToSign')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('amountToSign')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {formatAmount(toSignDocument.amount)}
            </Typography>{' '}
            {toSignDocument.currency}
          </Typography>
        </Box>
        <Box mt={4}>
          {showActions ? (
            <Grid container justifyContent="center" wrap="nowrap">
              <Box pr={6}>
                <Button size="small" variant="outlined" color="primary" onClick={onClose}>
                  {t('cancel')}
                </Button>
              </Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                endIcon={<SignatureIcon />}
                onClick={onAgreeSign}
                disabled={insufficientFundsError}
              >
                {t('sign')}
              </Button>
            </Grid>
          ) : null}
        </Box>
      </Box>
    </ModalCommon>
  );
};

import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Column, Table } from '@tanstack/react-table';

import { BicInput } from '@app/core/components/BicInput';

import { DateSelectInput, DateSelectInputProps } from '../../Form';
import { Select, SelectOptionType, SelectProps } from '../../Select';

interface TableColumnFilterProps<T> {
  column: Column<T, unknown>;
  table: Table<T>;
  name: string;
  submit?: boolean;
}
interface SelectFieldProps<T> extends TableColumnFilterProps<T>, Omit<SelectProps, 'options' | 'name'> {
  options: SelectOptionType[];
}

export const SelectColumnFilter = <T,>({
  name,
  options,
  column,
  table,
  submit = false,
  ...otherProps
}: SelectFieldProps<T>) => {
  const [value, setValue] = useState(table.options.meta?.columnFilterSate?.columnFilter[name] || '');

  return (
    <Select
      name={name}
      value={value}
      options={options}
      onChange={(event) => {
        setValue(event.target.value);
        table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: event.target.value }, submit);
      }}
      sx={{
        marginTop: '8px',
        height: '32px',
        fontSize: '0.75rem',
        '& .MuiOutlinedInput-input': {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      }}
      MenuProps={{
        sx: {
          '& .MuiMenu-paper': {
            marginTop: '8px',
          },
          '& .MuiMenu-list': {
            '& li': {
              paddingTop: '6px',
              paddingBottom: '6px',
              fontSize: '0.75rem',
            },
            '& li:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'colors.gray50',
            },
          },
        },
      }}
      {...otherProps}
    />
  );
};

export const TextFieldColumnFilter = <T,>({
  name,
  column,
  table,
  submit = false,
  ...textFieldProps
}: Omit<TextFieldProps, 'onChange' & 'value'> & TableColumnFilterProps<T>) => {
  const [value, setValue] = useState<string | number>(table.options.meta?.columnFilterSate?.columnFilter[name] || '');

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onBlurHandler = () => {
    table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: value }, submit);
  };

  const onEnterKeyHandler = () => {
    table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: value }, true);
  };

  return (
    <TextField
      variant="outlined"
      {...textFieldProps}
      value={value}
      onChange={onChangeHandler}
      inputProps={{
        onBlur: onBlurHandler,
        onKeyPress: (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onEnterKeyHandler();
          }
        },
        sx: {
          height: '32px',
          paddingBottom: 0,
          paddingTop: 0,
        },
      }}
      sx={{
        marginTop: '8px',
        height: '32px',
        fontSize: '0.75rem',
      }}
    />
  );
};

export const DateSelectColumnFilter = <T,>({
  name,
  column,
  table,
  submit = false,
  ...dateSelectProps
}: Omit<DateSelectInputProps, 'onChange'> & TableColumnFilterProps<T>) => {
  const onChangeHandler = (date: Date) => {
    table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: date }, submit);
  };
  return (
    <DateSelectInput
      onChange={onChangeHandler}
      selectedDate={table.options.meta?.columnFilterSate?.columnFilter[name]}
      inputProps={{
        sx: {
          marginTop: '8px',
          height: '32px',
          fontSize: '0.75rem',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '4px',
          paddingRight: '2px',
        },
        inputProps: {
          sx: {
            paddingLeft: '4px',
            paddingRight: '2px',
            paddingTop: '6px',
            paddingBottom: '6px',
          },
        },
      }}
      {...dateSelectProps}
    />
  );
};

export const BicColumnFilter = <T,>({
  name,
  column,
  table,
  submit = false,
  ...textFieldProps
}: Omit<TextFieldProps, 'onChange' & 'value'> & TableColumnFilterProps<T>) => {
  const [value, setValue] = useState<string>(table.options.meta?.columnFilterSate?.columnFilter[name] || '');

  const onChangeHandler = (bic: string) => {
    setValue(bic);
  };

  const onBlurHandler = () => {
    table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: value }, submit);
  };

  const onEnterKeyHandler = () => {
    table.options.meta?.columnFilterSate?.onColumnFilterChange({ [name]: value }, true);
  };

  return (
    <BicInput
      variant="outlined"
      {...textFieldProps}
      value={value}
      onChange={onChangeHandler}
      inputProps={{
        onBlur: onBlurHandler,
        onKeyPress: (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onEnterKeyHandler();
          }
        },
      }}
    />
  );
};

import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { UserDto, UserStateStatusEnum } from '@app/core/api';
import { Ellipsis } from '@app/core/components';
import { styles } from '@app/pages/companyProfile/mobile/components/MobileUsers/components/styles';

interface CompanyUserVisitCardProps {
  user: UserDto;
}

export const CompanyUsersCard: React.FC<CompanyUserVisitCardProps> = ({ user }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={styles.cardContainer}>
        <Box sx={styles.chevron} onClick={() => setOpen(!open)}>
          {open ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
        </Box>
        <Grid item container wrap="nowrap" sx={styles.badgeContainer}>
          <Typography
            sx={{ ...(user.status === UserStateStatusEnum.Active ? styles.greenBadgeStyle : styles.redBadgeStyle) }}
            variant="body2"
          >
            {t(user.status)}
          </Typography>
          <Ellipsis
            maxWidth="40%"
            styles={styles.infoBadgeStyle}
            text={user.signingAuthority === 0 ? t(`userSignature_noSign`) : t(`userSignature_${user.signingAuthority}`)}
          />
          <Ellipsis maxWidth="40%" styles={styles.infoBadgeStyle} text={t(`user_${user.systemAccess}`)} />
        </Grid>
        <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
          <Typography>{t('user_name')}</Typography>
        </Grid>
        <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
          <Ellipsis styles={{ fontWeight: 700 }} text={`${user.fullName}`} />
        </Grid>
      </Grid>
      {open ? (
        <Grid container sx={{ backgroundColor: 'colors.primary25', padding: '20px', zIndex: 1 }}>
          <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography variant="caption">{t('user_registeredNumber')}</Typography>
            <Typography sx={{ color: 'colors.primary700', fontWeight: 600 }}>{user.id}</Typography>
          </Grid>
          <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography variant="caption">{t('userPhone')}</Typography>
            <Typography sx={{ color: 'colors.primary700', fontWeight: 600 }}>{user.phoneNumber}</Typography>
          </Grid>
          <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography variant="caption">{t('userEmail')}</Typography>
            <Typography sx={{ color: 'colors.primary700', fontWeight: 600 }}>{user.email}</Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

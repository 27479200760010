import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { useStyles } from './styles';
import { OperationsTypeEnum } from '../../../types';
import { DATE_FORMAT, formatISODateString, TIME_FORMAT, formatAmount } from '../../../utils';
import { Ellipsis } from '../../Ellipsis';

export type DateItemProps = {
  text?: string;
};
export const DateItem: React.FC<DateItemProps> = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography variant="body1" sx={classes.date}>
      {text ? formatISODateString(text, DATE_FORMAT) : ''}
    </Typography>
  );
};

export type TimeItemProps = {
  date: string;
};

export const TimeItem: React.FC<TimeItemProps> = ({ date }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography variant="body2" sx={classes.date}>
      {formatISODateString(date, TIME_FORMAT)}
    </Typography>
  );
};

export type DocNumberItemProps = {
  text: string | number | React.ReactNode;
};
export const DocNumberItem: React.FC<DocNumberItemProps> = ({ text }) => {
  return (
    <Ellipsis
      text={`№ ${text}`}
      styles={{
        width: '100%',
        color: 'colors.gray400',
        fontWeight: 600,
      }}
    />
  );
};

export type AccountNameItemProps = {
  text: string | number | React.ReactNode;
};

export const AccountNameItem: React.FC<AccountNameItemProps> = ({ text }) => {
  return (
    <Ellipsis
      text={text}
      styles={{
        width: '100%',
        color: 'colors.primary600',
        fontWeight: 600,
      }}
    />
  );
};

export type AccountNumberItemProps = {
  text: string | number | React.ReactNode;
};

export const AccountNumberItem: React.FC<AccountNumberItemProps> = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography variant="body2" component="div" sx={classes.accountNumber}>
      {text}
    </Typography>
  );
};

export type CounterPartyNameProps = {
  text: string | number | React.ReactNode;
};

export const CounterPartyName: React.FC<CounterPartyNameProps> = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography variant="body1" sx={classes.counterPartyName}>
      {text}
    </Typography>
  );
};

export type PaymentReferenceProps = {
  text: string | number | React.ReactNode;
};

export const PaymentReference: React.FC<PaymentReferenceProps> = ({ text }) => {
  return (
    <Ellipsis
      text={text}
      width="100%"
      styles={{
        color: 'colors.gray400',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.43,
      }}
    />
  );
};

export type AmountItemProps = {
  type: OperationsTypeEnum;
  amount: number;
  currency: string;
};

export const AmountItem: React.FC<AmountItemProps> = ({ type, amount, currency }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography
      variant="body1"
      sx={{ ...(type === OperationsTypeEnum.deposit ? classes.amountDeposit : classes.amountWithdrawal) }}
    >
      {type === OperationsTypeEnum.deposit ? '+' : '-'} {` ${formatAmount(amount)} `}
      <Typography variant="body1" component="span">
        {currency}
      </Typography>
    </Typography>
  );
};

export type AmountDocumentItemProps = {
  amount: number;
  currency: string;
};

export const AmountDocumentItem: React.FC<AmountDocumentItemProps> = ({ amount, currency }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography variant="body1" sx={classes.amountDocument}>
      {formatAmount(amount)}{' '}
      <Typography variant="body1" component="span">
        {currency}
      </Typography>
    </Typography>
  );
};

export type DetailsInfoItemProps = {
  description: string;
  value?: string | number;
};
export const DetailsInfoItem: React.FC<DetailsInfoItemProps> = ({ description, value }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box pr={4}>
      <Typography variant="caption" component="p" sx={classes.detailsInfoDescription}>
        {description}
      </Typography>
      <Typography variant="body2" component="p" sx={classes.detailsInfoValue}>
        {value}
      </Typography>
    </Box>
  );
};

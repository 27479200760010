import React, { useState } from 'react';

import { Button, IconButton, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CurrencyOperationsExchangeTypeEnum, EmailDocumentTypeEnum } from '@app/core/api';
import { ReactComponent as ShareIconSvg } from '@app/themes/default/assets/icons/share.svg';

import { ShareDocumentPopup } from '../ShareDocumentPopup';
import { Tooltip } from '../Tooltip';

export type ShareButtonProps = {
  styles?: SxProps<Theme>;
  withLabel?: boolean;
  link: string;
  uuid: string;
  type: EmailDocumentTypeEnum;
  subType?: CurrencyOperationsExchangeTypeEnum;
};

export const ShareDocumentButton: React.FC<ShareButtonProps> = ({
  styles,
  link,
  uuid,
  type,
  subType,
  withLabel = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <Tooltip arrow placement="top" color="primary" title={t('operationDetailShare_shareDocument')}>
        {withLabel ? (
          <Button onClick={handleOpen} sx={styles} startIcon={<ShareIconSvg />}>
            <Typography variant="body1" component="span">
              {t('share')}
            </Typography>
          </Button>
        ) : (
          <IconButton onClick={handleOpen} size="small" color="default" sx={styles}>
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{
                fill: 'currentColor',
              }}
            >
              <ShareIconSvg />
            </SvgIcon>
          </IconButton>
        )}
      </Tooltip>
      {open ? <ShareDocumentPopup onClose={handleClose} link={link} type={type} uuid={uuid} subType={subType} /> : null}
    </>
  );
};

import React, { forwardRef, PropsWithChildren, useMemo } from 'react';

import { ListItemText, Badge, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { MenuItem } from '@app/common/layout/Sidebar/components/MenuItem';
import { MenuItemIcon } from '@app/common/layout/Sidebar/components/MenuItemIcon';
import { Tooltip } from '@app/core/components';
import { RouteList } from '@app/src/constants/routeList';

export type MenuListItemProps = {
  name: string;
  to: RouteList;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  hideTooltip: boolean;
  unreadMessageCounter: number;
};

export const MenuListItem: React.FC<MenuListItemProps> = ({ name, to, Icon, hideTooltip, unreadMessageCounter }) => {
  const { t } = useTranslation();
  const CustomLink = useMemo(() => {
    return forwardRef(({ children, className, ...linkProps }: PropsWithChildren<{ className: string }>, ref) => (
      <NavLink to={to} {...linkProps} className={({ isActive }) => className + (isActive ? ' selected' : '')}>
        {children}
      </NavLink>
    ));
  }, [to]);

  return (
    <MenuItem key={name} component={CustomLink}>
      <MenuItemIcon>
        <Tooltip
          disableFocusListener={hideTooltip}
          disableHoverListener={hideTooltip}
          disableTouchListener={hideTooltip}
          arrow
          placement="right"
          color="secondary"
          title={t(name)}
        >
          {name === 'letters' && unreadMessageCounter > 0 ? (
            <Badge
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'colors.warning',
                },
              }}
            >
              <Icon />
            </Badge>
          ) : (
            <Icon />
          )}
        </Tooltip>
      </MenuItemIcon>
      {name === 'letters' && unreadMessageCounter > 0 ? (
        <ListItemText
          primary={
            <span>
              {`${t(name)} `}
              <Typography
                sx={{ color: 'colors.warning', display: 'inline-block' }}
              >{`(${unreadMessageCounter})`}</Typography>
            </span>
          }
          disableTypography
        />
      ) : (
        <ListItemText primary={<span>{t(name)}</span>} disableTypography />
      )}
    </MenuItem>
  );
};

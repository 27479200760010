import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArrowRightSmallIcon } from '@app/common/icons';
import { GetAccountResponseDto, OperationTypeEnum } from '@app/core/api';
import { SettingsButton } from '@app/core/components/SettingsButton';
import { useGetSelectedAccounts } from '@app/core/hooks/useGetSelectedAccounts';
import { OperationsTypeEnum } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { AccountBalancesModal } from '@app/pages/dashboard/view/PageContent/AccountBalances/AccountBalancesModal';
import { BalanceItem } from '@app/pages/dashboard/view/PageContent/AccountBalances/BalanceItem';
import { BalanceLoadingItem } from '@app/pages/dashboard/view/PageContent/AccountBalances/BalanceItem/BalanceLoadingItem';
import { styles } from '@app/pages/dashboard/view/PageContent/AccountBalances/styles';
import { RouteList } from '@app/src/constants/routeList';

export const AccountBalances: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { data, isLoading, mutate } = useGetSelectedAccounts();

  const { t } = useTranslation();

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const handleShow = () => {
    setShowModal(true);
  };

  const onSaveHandler = () => {
    setShowModal(false);
    mutate({});
  };

  const onCloseModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Grid container direction="column" wrap="nowrap" justifyContent="space-between" sx={styles.root}>
      <Box pb={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          {!isMobile ? <Typography variant="h6">{t('selectedAccounts')}</Typography> : <div />}

          <div>
            <SettingsButton onClick={handleShow} sx={styles.settingsButton} />
          </div>
        </Grid>
      </Box>

      <Grid item>
        {isLoading ? (
          <>
            <BalanceLoadingItem />
            <BalanceLoadingItem />
            <BalanceLoadingItem />
            <BalanceLoadingItem />
            <BalanceLoadingItem />
            <BalanceLoadingItem />
          </>
        ) : null}
        {!data ? null : (
          <>
            {data.map((balance: GetAccountResponseDto) => {
              const lastOperation = balance.lastOperation
                ? {
                    ...balance.lastOperation,
                    type:
                      balance.lastOperation.type === OperationTypeEnum.Deposit
                        ? OperationsTypeEnum.deposit
                        : OperationsTypeEnum.withdrawal,
                  }
                : null;

              return <BalanceItem key={balance.account.id} {...balance.account} lastOperation={lastOperation} />;
            })}
          </>
        )}
      </Grid>
      {isLoading ? (
        <Grid container alignItems="center" justifyContent="center" sx={styles.allBalances} />
      ) : (
        <Grid
          component={Link}
          to={RouteList.accounts}
          container
          alignItems="center"
          justifyContent="center"
          sx={styles.allBalances}
        >
          <Typography variant="caption">{t('see_all_accounts')}</Typography>
          <ArrowRightSmallIcon sx={styles.arrowRight} />
        </Grid>
      )}

      {showModal ? (
        <AccountBalancesModal show={showModal} onSave={onSaveHandler} onClose={onCloseModalHandler} />
      ) : null}
    </Grid>
  );
};

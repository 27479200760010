import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from '@mui/utils';

import './theme.scss';
import { ThemeEnum } from '@app/core/types';

const spacing = 2;
const buttonRadius = 4;
const controlsRadius = 8;
const containersRadius = 8;

export const baseColors = {
  // Main colors
  primaryLight: '#3399f5',
  primaryMain: '#007ff2',
  primaryDark: '#0066c2',
  primaryTextColor: '#fff',
  success: '#38ac26',
  successBackground: '#F7FFFA',
  warning: '#f8c637',
  warningBackground: '#FFFEF7',
  error: '#b83f4d',
  errorBackground: '#FFF7F9',
  // TODO: refactoring naming of colors with designer
  // Primary colors
  primary25: '#f3f9ff',
  primary50: '#e6f2fe',
  primary100: '#cce5fc',
  primary200: '#99ccfa',
  primary300: '#66b2f7',
  primary400: '#3399f5',
  primary500: '#007ff2',
  primary600: '#0066c2',
  primary700: '#004c91',
  primary800: '#003361',
  primary900: '#001930',
  // Secondary colors
  secondary25: '#f3fbff',
  secondary50: '#e6f7ff',
  secondary100: '#ccf0ff',
  secondary200: '#b3e8ff',
  secondary300: '#80d9ff',
  secondary400: '#4dc9ff',
  secondary500: '#00b2ff',
  secondary600: '#008ecc',
  secondary700: '#006b99',
  secondary800: '#004766',
  secondary900: '#00354c',
  // Gray colors
  gray25: '#f6f6f7',
  gray50: '#ecedef',
  gray100: '#d9dce0',
  gray200: '#b3b8c0',
  gray300: '#8d95a1',
  gray400: '#677181',
  gray500: '#414e62',
  gray600: '#343e4e',
  gray700: '#272f3b',
  gray800: '#1a1f27',
  gray900: '#0d1014',
  // end
  // Main
  mainContentBackgroundColor: '#ffffff',
  mainContentBackgroundColorMobile: '#272F3B',
  // Base UI
  containerBackgroundColor: '#f6f6f7',
  // Buttons
  buttonContainedPrimary: '#007FF2',
  buttonContainedPrimaryHover: '#3399F5',
  buttonContainedPrimaryPressed: '#0066C2',
  buttonContainedPrimaryDisabled: '#D9DCE0',
  buttonContainedSecondary: '#007FF2',
  buttonOutlined: '#ffffff',
  buttonOutlinedHover: '#ebf5fe',
  buttonOutlinedPressed: '#0066C2',
  buttonOutlinedPressedBackground: 'rgba(0, 102, 194, 0.1)',
  buttonOutlinedDisabled: '#B3B8C0',
  // Company
  companyLogoBackgroundColor: '#343e4e',
  companyLogoTextColor: '#d9dce0',
  companyIconsColor: '#CCE5FC',
  settingsMenuBackground: '#343E4E',
  settingsMenuTextColor: '#E6F2FE',
  // Sidebar
  sidebarBackground: '#272F3B',
  menuItemText: '#B3B8C0',
  menuItemActiveText: '#00B2FF',
  menuDivider: '#677181',
  closeOpenArrowIconColor: '#B3B8C0',
  // Lang switcher
  langSwitcherBackground: '#1E2632',
  langSwitcherBorderColor: '#1E2632',
  userNameTextColor: '#cce5fc',
  companyNameTextColor: '#D9DCE0',
  companyEdrpouLabelTextColor: '#D9DCE0',
  companyEdrpouTextColor: '#cce5fc',
  // Currency panel
  currencyTabBackground: '#272F3B',
  currencyTabBorder: '#313945',
  currencyTabBorderHover: '#66b2f7',
  currencyTabText: '#B3B8C0',
  currencyTabTextHover: '#00b2ff',
  currencyTabSelectedBackground: '#313945',
  currencyTabSelectedText: '#00B2FF',
  currencyTabCurrencyName: '#D9DCE0',
  currencyTabCurrencyDescription: '#8D95A1',
  currencyTabCurrencyInfoText: '#F6F6F7',
  modalOverlay: 'rgba(13, 16, 20, 0.5)',
  // Tooltip
  tooltipDefaultBackground: '#fff',
  tooltipDefaultTextColor: '#677181',
  tooltipSecondaryBackground: '#414e62',
  tooltipSecondaryTextColor: '#fff',
  // AccountSelect
  desktopAccountChipBackgroundColor: '#f3f9ff',
  desktopAccountChipBorderColor: '#e6f2fe',
  desktopAccountChipTextColor: '#677181',
  mobileAccountChipBackgroundColor: '#414E62',
  mobileAccountChipBorderColor: '#677181',
  mobileAccountChipTextColor: '#FFFFFF',
  mobileAccountErrorTextColor: '#FFFFFF',

  // DateRangeSelect
  desktopDateRangeSelectTextColor: '',
};

type BuildThemeOptionsProps = {
  colors: typeof baseColors;
  replaceOptions?: ThemeOptions;
};

export const buildThemeOptions = ({ colors, replaceOptions }: BuildThemeOptionsProps): ThemeOptions => {
  const defaultThemeOptions: ThemeOptions = {
    themeName: ThemeEnum.dark,
    typography: {
      fontFamily: ['OpenSans', 'Roboto', 'Helvetica', 'Arial'].join(','),
      htmlFontSize: 16, // the same as MUI default. Just for info.
      fontSize: 14, // need for coef. coef = fontSize / 14;
      // remFontSize = pxFontSize / htmlFontSize * coef
      h1: {},
      h2: {},
      h3: {},
      h4: {},
      h5: {
        fontWeight: 'bold',
        // 24px
        '@media (max-width: 600px)': {
          // 20px
          fontSize: '1.25rem',
        },
      },
      h6: {
        // 20px
        fontWeight: 'bold',
        '@media (max-width: 600px)': {
          // 16px
          fontSize: '1rem',
        },
      },
      subtitle1: {},
      subtitle2: {},
      body1: {},
      body2: {},
      button: {},
      caption: {
        lineHeight: 1.4,
        display: 'inline-block',
      },
      overline: {},
    },
    spacing: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72],
    radius: {
      button: buttonRadius,
      control: controlsRadius,
      container: containersRadius,
    },
    palette: {
      colors,
      background: {
        default: '#FFFFFF',
      },
      primary: {
        light: colors.primaryLight,
        main: colors.primaryMain,
        dark: colors.primaryDark,
        contrastText: colors.primaryTextColor,
      },
      error: {
        main: '#B83F4D',
      },
      text: {
        secondary: colors.gray300,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: containersRadius,
            boxShadow: '0px 2px 7px rgba(134, 142, 150, 0.1), 0px 7px 20px rgba(222, 226, 230, 0.3)',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          secondary: {
            '&:hover': {
              backgroundColor: `${colors.gray600}!important`,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            // color: colors.gray200,
            borderRadius: buttonRadius,
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
            lineHeight: '20px',
            padding: `${spacing * 5}px ${spacing * 8}px`,
            minWidth: 86,
          },
          sizeSmall: {
            padding: `${spacing * 4}px ${spacing * 8}px`,
          },
          sizeLarge: {
            padding: `${spacing * 6}px ${spacing * 8}px`,
            fontFamily: 'OpenSans-Bold',
          },
          iconSizeMedium: {
            '& > *:first-of-type': {
              fontSize: 24,
            },
          },
          containedPrimary: {
            boxShadow: 'none',
            backgroundColor: colors.buttonContainedPrimary,

            '&:hover': {
              boxShadow: 'none',
              backgroundColor: colors.buttonContainedPrimaryHover,
            },
            '&.Mui-disabled': {
              boxShadow: 'none',
              backgroundColor: colors.buttonContainedPrimaryDisabled,
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: colors.buttonContainedPrimaryPressed,
            },
          },
          outlinedPrimary: {
            boxShadow: 'none',
            padding: `${spacing * 5}px ${spacing * 4}px`,
            backgroundColor: colors.buttonOutlined,

            '&:hover': {
              boxShadow: 'none',
              backgroundColor: colors.buttonOutlinedHover,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.buttonOutlined,
              borderColor: colors.buttonOutlinedDisabled,
              color: colors.buttonOutlinedDisabled,
            },
            '&:active': {
              backgroundColor: colors.buttonOutlinedPressedBackground,
              borderColor: colors.buttonOutlinedPressed,
              color: colors.buttonOutlinedPressed,
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'initial',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.primary500,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.gray100,
              borderWidth: 1,
            },
            '&.Mui-disabled': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: colors.gray100,
              },
            },
          },
          focused: {},
          notchedOutline: {
            top: 0,
            borderRadius: controlsRadius,
            borderColor: colors.gray100,

            '& legend': {
              display: 'none',
            },
          },
          input: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 12,
            paddingLeft: spacing * 6,
            borderRadius: controlsRadius,
            backgroundColor: 'white',
          },
          adornedEnd: {
            borderRadius: controlsRadius,
            background: 'white',
          },
          // inputMarginDense: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          //   height: 36,
          // },
          adornedStart: {
            backgroundColor: 'white',
          },
          multiline: {
            padding: 0,
            '& .fieldset': {
              border: 'none',
            },
          },
          inputMultiline: {
            padding: 2,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 40,
            '&.Mui-disabled': {
              pointerEvents: 'none',
            },
          },
          multiline: {
            height: 'auto',
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'initial',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            color: colors.gray600,
            backgroundColor: 'white',
            borderRadius: controlsRadius,
            '&.Mui-disabled': {
              color: 'inherit',
            },
          },
          // disabled: {
          //   color: 'inherit',
          // },
          select: {
            '&:focus': {
              backgroundColor: 'white',
            },
          },
          // selectMenu: {
          //   fontSize: 14,
          // },
          outlined: {
            borderRadius: controlsRadius,
          },
          icon: {
            top: 'calc(50% - 8px)',
            fill: 'currentColor',
          },
          iconOutlined: {
            right: '13px',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            color: '#FFFFFF',
            '&.MuiCheckbox-checked': {
              color: colors.primary500,
            },
          },
          colorPrimary: {
            '&.MuiCheckbox-checked': {
              color: '#FFFFFF',
            },
          },
          colorSecondary: {
            '&.MuiCheckbox-checked': {
              color: colors.primary500,
            },
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          colorPrimary: {
            color: colors.gray200,
            '&.MuiRadio-checked': {
              color: colors.primary500,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            paddingTop: 9,
            paddingBottom: 9,
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: colors.primary25,
            },
            '&.MuiMenuItem-selected': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: colors.primary25,
              },
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 9,
            paddingBottom: 9,
            fontSize: '0.875rem',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 30,
            color: colors.gray500,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            color: colors.gray600,
            fontWeight: 'bold',
            lineHeight: 'inherit',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius: controlsRadius,
            backgroundColor: 'white',
          },
          inputRoot: {
            '&[class*="MuiOutlinedInput-root"]': {
              padding: 0,
            },
          },
          input: {
            borderRadius: controlsRadius,
          },
          option: {
            '&[aria-selected="true"]': {
              backgroundColor: 'transparent',
            },
          },
          tag: {
            margin: 4,
          },
          endAdornment: {
            top: 'calc(50% - 11px)',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: controlsRadius,
            backgroundColor: colors.primary25,
          },
          sizeSmall: {
            height: 'inherit',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: {
            fontSize: '1rem',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '100%',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${colors.gray50}`,
            borderBottom: `1px solid ${colors.gray50}`,
            borderRight: `1px solid ${colors.gray50}`,
            borderBottomLeftRadius: containersRadius,
            borderBottomRightRadius: containersRadius,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            display: 'flex',
            border: `1px solid ${colors.gray50}`,
            borderTopLeftRadius: containersRadius,
            borderTopRightRadius: containersRadius,
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            display: 'flex',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: spacing * 6,
            paddingRight: spacing * 6,
            '&.MuiTableRow-hover:hover': {
              backgroundColor: colors.primary25,
            },
            '&.MuiTableRow-selected': {
              backgroundColor: colors.primary25,
            },
            '&:last-child .MuiTableCell-body': {
              borderBottom: 'none',
            },
          },
          hover: {
            '&.MuiTableRow-selected': {
              backgroundColor: colors.primary25,
              '&:hover': {
                backgroundColor: colors.primary25,
              },
            },
            '&:hover': {
              backgroundColor: colors.primary25,
            },
          },
          head: {
            '&:hover': {
              backgroundColor: colors.gray25,
            },
            backgroundColor: colors.gray25,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            display: 'flex',
            padding: `4px  6px`,
            borderBottom: 'none',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          },
          body: {
            borderBottom: `1px solid ${colors.gray50}`,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: 'inherit',
            },
            '&:hover svg.MuiTableSortLabel-icon': {
              opacity: 1,
            },
            '&.MuiTableSortLabel-active': {
              color: colors.primary500,
              // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
              '&& $icon': {
                opacity: 1,
                color: colors.primary500,
              },
            },
          },
          icon: {
            transition: 'initial',
            color: colors.primary500,
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            width: 300,
            height: 70,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: controlsRadius,
          },
          filledError: {
            backgroundColor: colors.errorBackground,
            border: `1px solid ${colors.error}`,
          },
          filledWarning: {
            backgroundColor: colors.warningBackground,
            border: `1px solid ${colors.warning}`,
          },
          filledInfo: {
            backgroundColor: colors.primary25,
            border: `1px solid ${colors.primary500}`,
          },
          filledSuccess: {
            backgroundColor: colors.successBackground,
            border: `1px solid ${colors.success}`,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipArrow: {
            fontSize: '0.75rem',
            borderRadius: containersRadius,
          },
          tooltip: {
            fontSize: '0.75rem',
            borderRadius: containersRadius,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: '#E9E9E9',
          },
          text: {
            borderRadius: 2,
          },
          wave: {
            '&::after': {
              animationDuration: '1.2s',
              animationDelay: '0.5s',
              background: 'linear-gradient(90deg, transparent, hsla(0,0%,100%,.3), transparent);',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorDockedLeft: {
            borderRight: 'none',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  };
  return deepmerge(defaultThemeOptions, replaceOptions || {});
};

export const baseThemeOptions = buildThemeOptions({ colors: baseColors });
export const baseTheme = createTheme(baseThemeOptions);

import i18n from 'i18next';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

import { Langs } from '@app/core/types';

import baseEn from './base/en.json';
import baseUa from './base/ua.json';
import signEn from './sign/en.json';
import signUa from './sign/ua.json';
import signErrorEn from './signErrors/en.json';
import signErrorUa from './signErrors/ua.json';

const cookieLang: Langs = Cookies.get('Lang') as Langs;
const currLang: Langs = cookieLang || Langs.EN;

export const resources = {
  en: {
    translation: {
      ...baseEn,
      ...signEn,
      ...signErrorEn,
    },
  },
  ua: {
    translation: {
      ...baseUa,
      ...signUa,
      ...signErrorUa,
    },
  },
};

export default i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: currLang,
  debug: false,
  fallbackLng: 'EN',
  lowerCaseLng: true,
  resources,
  returnNull: false,
});

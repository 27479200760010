import * as Sentry from '@sentry/react';

import { CryptoLibraryError } from '@app/src/logger/CryptoLibraryError';
import { SignError } from '@app/src/logger/SignError';
import { LogTagErrorName, LogTagErrorSource, LogTagErrorType } from '@app/src/logger/types';

type LogKeyErrorType = {
  message: string;
  extra?: Record<string, unknown>;
};

export const logCertificateValidationsError = ({ message, extra }: LogKeyErrorType) => {
  Sentry.captureException(new SignError(message), {
    tags: {
      [LogTagErrorName.errorType]: LogTagErrorType.SignError,
      [LogTagErrorName.errorSource]: LogTagErrorSource.internalValidations,
    },
    extra,
  });
};

export const logCertificateKeyDataError = ({ message, extra }: LogKeyErrorType) => {
  Sentry.captureException(new CryptoLibraryError(message), {
    tags: {
      [LogTagErrorName.errorType]: LogTagErrorType.CryptoLibraryError,
      [LogTagErrorName.errorSource]: LogTagErrorSource.cryptoLibrary,
    },
    extra,
  });
};

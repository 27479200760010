import { UserSessionDto } from '@app/core/api';

export enum UserProfileMenuFilter {
  Data = 'Data',
  Keys = 'Keys',
  Security = 'Security',
  Notification = 'Notification',
  Monitoring = 'Monitoring',
  History = 'History',
  Settings = 'Settings',
}

export type VisitsGroupType = {
  date: string;
  visits: UserSessionDto[];
};

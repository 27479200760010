import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { AccountSelectType } from '@app/core/types';

type AccountInfoProps = {
  account: AccountSelectType;
};

export const RecipientAccountInfo: React.FC<AccountInfoProps> = ({ account }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={5}>
        <Typography variant="caption">{account.account.accountName}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">{account.account.accountNumber}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" align="right">
          {account.account.currency}
        </Typography>
      </Grid>
    </Grid>
  );
};

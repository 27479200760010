import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { accountsApiClient, ApiError, getClientId, GetSaldoRequestDto, GetSaldoResponseDto } from '@app/core/api';

const getTurnoversQueryFn = async (requestParams: GetSaldoRequestDto): Promise<GetSaldoResponseDto> => {
  const response = await accountsApiClient.accountsControllerGetSaldo(getClientId(), requestParams);

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      'accountsControllerGetSaldo error. StatusCode:' + response.request.status
    );
  }

  return response.data;
};

export const useGetTurnovers = (): UseMutationResult<GetSaldoResponseDto, ApiError, GetSaldoRequestDto> => {
  return useMutation<GetSaldoResponseDto, ApiError, GetSaldoRequestDto>(getTurnoversQueryFn);
};

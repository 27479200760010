import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import { StatusText } from '../../StatusText';

export const TextInput =
  (textFieldProps: TextFieldProps, error?: string) => (params: AutocompleteRenderInputParams) => {
    const hasError = Boolean(error);
    return (
      <>
        <TextField {...params} {...textFieldProps} error={hasError} />
        {hasError ? <StatusText absolute>{error}</StatusText> : null}
      </>
    );
  };

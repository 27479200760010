import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, getClientId, legalEntityApiClient, UsersInfoDto } from '@app/core/api';

const getLegalUserFn = async (): Promise<UsersInfoDto[]> => {
  const result = await legalEntityApiClient.legalEntityControllerGetUsers(getClientId());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "legalEntityControllerGetUsers: Can't get users");
  }
  return result.data;
};

export const useGetLegalUser = (): UseMutationResult<UsersInfoDto[], ApiError, any> => {
  return useMutation<UsersInfoDto[], ApiError, any>(getLegalUserFn);
};

export const styles = {
  title: {
    fontSize: 20,
    color: 'colors.gray800',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 14,
    color: 'colors.gray600',
  },
  linkWrap: {
    color: 'colors.primary500',
    paddingRight: '8px',
  },
  copy: {
    marginTop: '20px',
  },
  buttonIcon: {
    marginLeft: '8px',
  },
  alertCopy: {
    backgroundColor: 'colors.successBackground',
    border: `1px solid`,
    borderColor: 'colors.success',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.success',
    maxHeight: '40px',
    width: '100%',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  alertCopyText: {
    fontWeight: 600,
    color: 'colors.success',
    marginLeft: '8px',
  },
  addButton: {
    fontSize: '0.75rem',
    marginTop: '6px',
  },
  buttonWidth: {
    width: '140px',
  },
};

import React from 'react';

import { Grid, Typography, Box } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { OperationDetailsDto, OperationDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { FULL_DATE_TIME_FORMAT, formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/deposits/show/components/DepositOperationsTable/style';
import { DepositOperationsTableColumns } from '@app/pages/deposits/types';

interface MakeTableColumnsProps {
  t(text: string): string;
}

export const makeTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<OperationDto, any>[] => {
  return [
    {
      accessorKey: DepositOperationsTableColumns.details,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`statementsTableColumn_documentDate_short`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationDto, OperationDetailsDto>) => {
        return <>{format(new Date(getValue().date.split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: DepositOperationsTableColumns.date,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`operationDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationDto, string>) => {
        return <>{format(new Date(getValue()), FULL_DATE_TIME_FORMAT)}</>;
      },
    },
    {
      accessorKey: DepositOperationsTableColumns.documentNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`statementsTable_documentNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationDto, string>) => {
        return <Typography variant="caption">{getValue()}</Typography>;
      },
    },
    {
      accessorKey: DepositOperationsTableColumns.paymentReference,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`statementsTable_paymentReference`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: DepositOperationsTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OperationDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: DepositOperationsTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      id: 'actions',
      accessorKey: DepositOperationsTableColumns.id,
      header: () => <></>,
      cell: ({ row }: CellContext<OperationDto, any>) => {
        return (
          <Grid container justifyContent="flex-end">
            <Box
              component="span"
              {...{
                onClick: () => row.toggleExpanded(),
                style: { cursor: 'pointer' },
              }}
              sx={styles.chevron}
            >
              {row.getIsExpanded() ? (
                <UpChevronSmallIcon fontSize="small" />
              ) : (
                <DownChevronSmallIcon fontSize="small" />
              )}
            </Box>
          </Grid>
        );
      },
    },
  ];
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useReactTable, SortingState, ColumnSort, getCoreRowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { OperationDetails } from '@app/core/components/OperationDetails';
import {
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { PaginationChangeType, SortingRule, TablePaginationType, Operation } from '@app/core/types';
import { makeTableColumns } from '@app/pages/loansLines/show/components/OperationsTable/columns';
import { styles } from '@app/pages/loansLines/show/components/OperationsTable/style';

const ExpandedOperationContent: React.FC<{ data: Operation }> = ({ data }) => <OperationDetails operation={data} />;

interface OperationsTableProps {
  data: Operation[];
  onPaginationChange(pagination: PaginationChangeType): void;
  pagination?: TablePaginationType;
  onSort?(rule: SortingRule): void;
}

export const OperationsTable: React.FC<OperationsTableProps> = ({ data, onPaginationChange, pagination, onSort }) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
      }),
    ];
  }, [t]);

  const table = useReactTable<Operation>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: data.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row, i) => {
      return (
        <TableRowContent<Operation>
          key={row.id}
          row={row}
          styles={styles}
          ExpandedComponent={ExpandedOperationContent}
        />
      );
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<Operation> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
      footer={{
        content: (
          <TablePagination
            totalCount={pagination?.totalCount || 0}
            page={pagination?.page || 1}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            labelRowsPerPage={t('showOnPageShort')}
            rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
            onChange={onPaginationChange}
            show={showPagination}
          />
        ),
      }}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { merge, keyBy, values, isEmpty } from 'lodash-es';
import { parse } from 'query-string';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { FilterIcon, DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import {
  CurrencyOperationsSortingRuleDto,
  CurrencyOperationsExchangeTypeEnum,
  TotalAmountByCurrencyDto,
  CurrencyOperationStatusEnum,
} from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { ddMMYYYYFormat, DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetCurrencyOperationList } from '@app/core/hooks/useGetCurrencyOperationsList';
import { TableColumnConfig, PaginationChangeType } from '@app/core/types';
import { CurrencyExchangeTable } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable';
import { CurrencyExchangeFilter } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrencyExchangeFilter';
import { makeDataFromSubmit } from '@app/pages/exchange/currencyExchangeTransactions/helper';
import { getCurrencyExchangeTableConfigFn } from '@app/pages/exchange/currencyExchangeTransactions/query';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/styles';
import { tableFieldsConfigMock } from '@app/pages/exchange/currencyExchangeTransactions/tableFieldsConfigMock';
import {
  CurrencyExchangeFilterField,
  CurrencyExchangeFilterFormData,
  CurrencyExchangeTypeEnum,
} from '@app/pages/exchange/currencyExchangeTransactions/types';
import { validationSchema } from '@app/pages/exchange/currencyExchangeTransactions/validationSchema';
import { appConfig } from '@app/src/config';

export const CurrencyExchangeContent: React.FC = () => {
  const { showLoader } = useUIState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoSubmitForm, setAutoSubmitForm] = useState<boolean>(false);
  const [fullItems, setFullItems] = useState(false);
  const [totalAmounts, setTotalAmounts] = useState<Array<TotalAmountByCurrencyDto> | undefined>();
  const { t } = useTranslation();
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const sortRuleRef = useRef<CurrencyOperationsSortingRuleDto | undefined>();
  const currencyExchangeItems: MenuItem<CurrencyExchangeTypeEnum>[] = [
    {
      id: 1,
      labelText: t('all'),
      value: CurrencyExchangeTypeEnum.all,
    },
    {
      id: 2,
      labelText: t('exchange_column_exchangeRateBuy'),
      value: CurrencyExchangeTypeEnum.purchase,
    },
    {
      id: 3,
      labelText: t('exchange_column_exchangeRateSell'),
      value: CurrencyExchangeTypeEnum.sale,
    },
    {
      id: 4,
      labelText: t('currency_exchange_convert'),
      value: CurrencyExchangeTypeEnum.conversion,
    },
  ];

  const { dateFrom, dateTo, ...other } = parse(location.search);
  const defaultValues = {
    ...other,
    dateFrom: dateFrom ? new Date(dateFrom as string) : undefined,
    temp_dateFrom: dateFrom ? format(new Date(dateFrom as string), ddMMYYYYFormat.format) : undefined,
    dateTo: dateTo ? new Date(dateTo as string) : undefined,
    temp_dateTo: dateTo ? format(new Date(dateTo as string), ddMMYYYYFormat.format) : undefined,
  };

  const methods = useForm<CurrencyExchangeFilterFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
  });
  const { handleSubmit, reset: formReset, formState, getValues, setValue, trigger, watch, control } = methods;

  const { data, mutate, reset: mutationReset, isLoading } = useGetCurrencyOperationList();
  const currencyOperations = data?.operations || [];
  const pagination = data?.pagination;

  const exchangeTypeRef = useRef<CurrencyOperationsExchangeTypeEnum | undefined>();

  const updateList = () => {
    mutate({
      ...makeDataFromSubmit(getValues(), exchangeTypeRef.current),
      exchangeType: exchangeTypeRef.current,
      pagination: paginationRef.current,
      sort: sortRuleRef.current,
    });
  };

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  useEffect(() => {
    if (data) {
      if (exchangeTypeRef.current) {
        if (exchangeTypeRef.current === CurrencyOperationsExchangeTypeEnum.Purchase) {
          setTotalAmounts(data.totalAmounts.purchase);
        }
        if (exchangeTypeRef.current === CurrencyOperationsExchangeTypeEnum.Sale) {
          setTotalAmounts(data.totalAmounts.sale);
        }
        if (exchangeTypeRef.current === CurrencyOperationsExchangeTypeEnum.Conversion) {
          setTotalAmounts(data.totalAmounts.conversion);
        }
      } else {
        setTotalAmounts(
          values(
            merge(
              [],
              keyBy<TotalAmountByCurrencyDto>(data.totalAmounts.purchase, 'currency'),
              keyBy<TotalAmountByCurrencyDto>(data.totalAmounts.sale, 'currency'),
              keyBy<TotalAmountByCurrencyDto>(data.totalAmounts.conversion, 'currency')
            )
          )
        );
      }
    }
  }, [data]);

  const watchedFields = watch();
  useEffect(() => {
    if (autoSubmitForm && !formState.isDirty) {
      mutate({
        ...makeDataFromSubmit(getValues(), exchangeTypeRef.current),
        exchangeType: exchangeTypeRef.current,
        pagination: paginationRef.current,
        sort: sortRuleRef.current,
      });
      setAutoSubmitForm(false);
    }
  }, [autoSubmitForm, formState.isDirty, getValues, mutate, watchedFields]);

  useEffect(() => {
    const urlQueryParams = parse(location.search);
    if (
      urlQueryParams &&
      Object.entries(urlQueryParams).filter(([key, value]) => {
        return !isEmpty(value);
      }).length > 0
    ) {
      setAutoSubmitForm(true);
    }
  }, []);

  const handleFilterChange = () => {
    updateList();
  };

  const watchDateFrom = useWatch<CurrencyExchangeFilterFormData>({
    control,
    name: CurrencyExchangeFilterField.dateFrom,
  });

  const watchDateTo = useWatch<CurrencyExchangeFilterFormData>({
    control,
    name: CurrencyExchangeFilterField.dateTo,
  });

  const handleChange = (currentTab: CurrencyExchangeTypeEnum): void => {
    switch (currentTab) {
      case CurrencyExchangeTypeEnum.purchase:
        exchangeTypeRef.current = CurrencyOperationsExchangeTypeEnum.Purchase;
        break;
      case CurrencyExchangeTypeEnum.conversion:
        exchangeTypeRef.current = CurrencyOperationsExchangeTypeEnum.Conversion;
        break;
      case CurrencyExchangeTypeEnum.sale:
        exchangeTypeRef.current = CurrencyOperationsExchangeTypeEnum.Sale;
        break;
      case CurrencyExchangeTypeEnum.all:
      default:
        exchangeTypeRef.current = undefined;
        break;
    }
    if (watchDateFrom && watchDateTo) {
      updateList();
    }
  };

  const onSubmit = () => {
    updateList();
  };

  const resetFormHandler = () => {
    formReset({
      [CurrencyExchangeFilterField.dateFrom]: undefined,
      [CurrencyExchangeFilterField.temp_dateFrom]: '',
      [CurrencyExchangeFilterField.dateTo]: undefined,
      [CurrencyExchangeFilterField.temp_dateTo]: '',
      [CurrencyExchangeFilterField.documentNumber]: '',
      [CurrencyExchangeFilterField.withdrawalAccount]: undefined,
      [CurrencyExchangeFilterField.depositAccount]: undefined,
      [CurrencyExchangeFilterField.amountMin]: '',
      [CurrencyExchangeFilterField.amountMax]: '',
    });
    mutationReset();
    setSearchParams({}, { replace: true });
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleFilterChange();
    }
  };

  const onSortHandler = (rule: CurrencyOperationsSortingRuleDto) => {
    sortRuleRef.current = rule;
    handleFilterChange();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    if (data?.operations.length) {
      handleFilterChange();
    }
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const configurationSavedHandler = (tableColumnsData: TableColumnConfig[]) => {
    // TODO: need endpoint
  };

  const onDeleteDocumentSuccessHandler = () => {
    updateList();
  };

  const onSignDocumentSuccessHandler = () => {
    updateList();
  };

  const onSendToSignDocumentsSuccessHandler = () => {
    updateList();
  };

  const onFilterChangeHandler = (filter?: CurrencyOperationStatusEnum) => {
    if (filter) {
      setValue(CurrencyExchangeFilterField.status, filter);
    } else {
      setValue(CurrencyExchangeFilterField.status, undefined);
    }
    trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        paginationRef.current = {
          page: DEFAULT_FIRST_PAGE,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        };
        updateList();
      }
    });
  };

  return (
    <>
      <HorizontalMenu<CurrencyExchangeTypeEnum> onChange={handleChange} menuItems={currencyExchangeItems} />
      <Grid item container direction="column" wrap="nowrap">
        <Box pb={4} mt={7}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FilterIcon />
              <Typography variant="h6" component="span" sx={styles.captionFilter}>
                {t('filters')}
              </Typography>
            </Grid>
            <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
              {t('additionalParameters')}
              {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
            </Button>
          </Grid>
        </Box>
        <Box pb={4}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyPress={onKeyPressHandler}
              role="presentation"
              style={styles.verticalWrapper}
            >
              <CurrencyExchangeFilter
                fullItems={fullItems}
                namesFieldWithError={Object.keys(formState.errors)}
                resetFormHandler={resetFormHandler}
              />
            </form>
          </FormProvider>
        </Box>
        <CurrencyExchangeTable
          currencyOperations={currencyOperations}
          columnsConfig={tableFieldsConfigMock}
          columnsConfigMutationFn={getCurrencyExchangeTableConfigFn}
          totalAmounts={totalAmounts}
          pagination={pagination}
          countByStatus={data?.countByStatus}
          onSort={onSortHandler}
          loading={isLoading}
          onPaginationChange={onPaginationChangeHandler}
          onSuccessConfigurationSaved={configurationSavedHandler}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          onSignDocumentSuccess={onSignDocumentSuccessHandler}
          onFilterChange={onFilterChangeHandler}
          onSendToSignDocumentsSuccess={onSendToSignDocumentsSuccessHandler}
        />
      </Grid>
    </>
  );
};

import { Theme } from '@mui/material';

export const useStyles = (
  { palette: { colors }, spacing }: Theme,
  editor: boolean,
  favorite: boolean,
  error: boolean
) => ({
  root: {
    backgroundColor: colors.gray25,
    marginBottom: '4px',
    padding: editor ? '6px 20px' : '12px 20px',
    borderRadius: spacing(2),
  },
  accountName: {
    fontWeight: 600,
    color: colors.primary600,
    marginRight: spacing(2),
    maxWidth: '50%',
  },
  accountNameWrapper: {
    minHeight: () => {
      if (error) {
        return '56px';
      }
      if (editor) {
        return '40px';
      }
      return '24px';
    },
  },
  editIcon: {
    width: '16px',
    height: '16px',
    color: colors.gray300,
  },
  nameText: {
    color: colors.primary600,
    fontWeight: 600,
  },
  iconContainer: {
    width: '62px',
    marginLeft: '24px',
  },
  favoriteIcon: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    color: favorite ? colors.warning : colors.gray300,
  },
  moveIconWrap: {
    marginLeft: '14px',
    color: colors.gray300,
  },
  amountText: {
    color: colors.gray800,
    fontWeight: 600,
  },
  amountCurrencyContainer: {
    width: '200px',
    textAlign: 'right',
  },
  editorInput: {
    width: '100%',
    maxWidth: '270px',
  },
  shakeAnimate: {
    animation: 'tremor 1000ms ease-in-out normal none running infinite',
  },
  editorButton: {
    marginLeft: spacing(2),
    marginTop: spacing(2),
    padding: '4px',
  },
  editorIconDone: {
    color: colors.success,
  },
  editorIconCancel: {
    color: colors.gray200,
  },
  popoverEditInput: {
    background: 'none',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '330px',
  },
});

import { addDays, isDate, parse } from 'date-fns';
import { isValidIBAN } from 'ibantools';
import { date, number, object, SchemaOf, string } from 'yup';

import { CountryCodeDto } from '@app/core/api';
import { parseJSON, DATE_FORMAT, isValidBIC } from '@app/core/utils';
import { SwiftFormFields } from '@app/pages/swift/types';

type SwiftValidationSchemaProps = {
  t: (key: string) => string;
};

type SchemaValidationObject = {
  [SwiftFormFields.recipientAccount]: string;
  [SwiftFormFields.recipientName]: string;
  [SwiftFormFields.recipientAddress]: string;
  [SwiftFormFields.senderName]: string;
  [SwiftFormFields.senderAddress]: string;
  [SwiftFormFields.amount]: string;
  [SwiftFormFields.senderAccount]: string;
  [SwiftFormFields.documentDate]: Date;
  [SwiftFormFields.paymentReference]: string;
  [SwiftFormFields.recipientSwiftBic]: string;
  [SwiftFormFields.operationCode]?: string;
  [SwiftFormFields.commissionType]: string;
  [SwiftFormFields.urgency]: string;
  [SwiftFormFields.recipientCountry]: CountryCodeDto;
  [SwiftFormFields.recipientBank]: string;
  [SwiftFormFields.recipientCorrespondentSwiftBic]?: string;
  [SwiftFormFields.recipientCorrespondentBank]?: string;
};

export const validationSchema = ({ t }: SwiftValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  const maxDocumentDate = addDays(new Date(), 30);
  const parseDateString = (value: string, originalValue: string) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, DATE_FORMAT, new Date());
  };
  return object().shape({
    [SwiftFormFields.recipientAccount]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(
        SwiftFormFields.recipientAccount,
        t('paymentCreationLocalPayment_recipient_error_format'),
        (iban: string | undefined): boolean => {
          if (iban?.slice(0, 1).match(/^[A-Z]*$/)) {
            return isValidIBAN(iban);
          } else {
            return Boolean(iban?.match(/^[0-9]*$/));
          }
        }
      )
      .test(
        SwiftFormFields.recipientAccount,
        t('paymentCreationLocalPayment_sender_recipient_identical_error'),
        (iban: string | undefined, context): boolean => {
          const { senderAccountNumber } = parseJSON(context.parent[SwiftFormFields.senderAccount]);
          return iban !== senderAccountNumber;
        }
      ),
    [SwiftFormFields.recipientName]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test('recipientName', (value, { createError, path }) => {
        if (Boolean(value?.match(/[%@&*#$«№;\_]/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_invalid_character_error'),
          });
        } else if (!Boolean(value?.match(/^[A-Za-zА-Яа-я0-9\s\-?:().`",'+]*$/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_field_containLatin'),
          });
        }
        return true;
      }),
    [SwiftFormFields.recipientAddress]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test('recipientAddress', (value, { createError, path }) => {
        if (Boolean(value?.match(/[%@&*#$«№;\_]/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_invalid_character_error'),
          });
        } else if (!Boolean(value?.match(/^[A-Za-zА-Яа-я0-9\s\-?:().`",'+]*$/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_field_containLatin'),
          });
        }
        return true;
      }),
    [SwiftFormFields.senderName]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test(
        'senderName',
        t('swiftCreationPayment_invalid_character_error'),
        (val) => !Boolean(val?.match(/[%@&*#$«№;\_]/))
      ),
    [SwiftFormFields.senderAddress]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test(
        'senderAddress',
        t('swiftCreationPayment_invalid_character_error'),
        (val) => !Boolean(val?.match(/[%@&*#$«№;\_]/))
      ),
    [SwiftFormFields.amount]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test(SwiftFormFields.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
        if (amount) {
          return parseFloat(amount) >= 0.01;
        }
        return false;
      }),
    [SwiftFormFields.senderAccount]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.recipientSwiftBic]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test('recipientSwiftBic', t('swiftCreationPayment_bic_error'), (value) => {
        return value ? isValidBIC(value) : false;
      }),
    [SwiftFormFields.operationCode]: string().test('operationCode', (value, { createError, path }) => {
      if (Boolean(value?.match(/[%@&*#$«"№;\_]/))) {
        return createError({
          path,
          message: t('swiftCreationPayment_invalid_character_error'),
        });
      } else if (!Boolean(value?.match(/^[A-Za-z0-9\s/\-?:().,'+]*$/))) {
        return createError({
          path,
          message: t('swiftCreationPayment_field_containLatin'),
        });
      }
      return true;
    }),
    [SwiftFormFields.commissionType]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.urgency]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.recipientCountry]: object()
      .shape({
        numeric: string().required(t('swiftCreationPayment_field_required')),
        alpha2: string().required(t('swiftCreationPayment_field_required')),
        alpha3: string().required(t('swiftCreationPayment_field_required')),
        name: string().required(t('swiftCreationPayment_field_required')),
      })
      .nullable()
      .required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.recipientBank]: string()
      .required(t('swiftCreationPayment_field_required'))
      .test('recipientCountry', (value, { createError, path }) => {
        if (Boolean(value?.match(/[%@&*#$«"№;\_]/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_invalid_character_error'),
          });
        } else if (!Boolean(value?.match(/^[A-Za-z0-9\s/\-?:().,'+]*$/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_field_containLatin'),
          });
        }
        return true;
      }),
    [SwiftFormFields.documentDate]: date()
      .required(t('swiftCreationPayment_field_required'))
      .typeError(t('paymentDocumentDate_correct_date'))
      .transform(parseDateString)
      .max(maxDocumentDate, t('paymentDocumentDate_correct_date')),
    [SwiftFormFields.paymentReference]: string()
      .required(t('swiftCreationPayment_field_required'))
      .trim()
      .min(4, t('swiftCreationPayment_field_minLetters'))
      .test('paymentReference', (value, { createError, path }) => {
        if (Boolean(value?.match(/[%@&*#$«"№;\_]/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_invalid_character_error'),
          });
        } else if (!Boolean(value?.match(/^[A-Za-z0-9\s/\-?:().,'+]*$/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_field_containLatin'),
          });
        }
        return true;
      }),
    [SwiftFormFields.recipientCorrespondentSwiftBic]: string().when([SwiftFormFields.correspondentBankCheck], {
      is: true,
      then: string().test('recipientCorrespondentSwiftBic', t('swiftCreationPayment_bic_error'), (value) => {
        return value ? isValidBIC(value) : false;
      }),
    }),
    [SwiftFormFields.recipientCorrespondentBank]: string().when([SwiftFormFields.correspondentBankCheck], {
      is: true,
      then: string().test('recipientCorrespondentBank', (value, { createError, path }) => {
        if (Boolean(value?.match(/[%@&*#$«"№;\_]/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_invalid_character_error'),
          });
        } else if (!Boolean(value?.match(/^[A-Za-z0-9\s/\-?:().,'+]*$/))) {
          return createError({
            path,
            message: t('swiftCreationPayment_field_containLatin'),
          });
        }
        return true;
      }),
    }),
  });
};

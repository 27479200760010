import { CompanyProfileMenuFilter } from '@app/pages/companyProfile/types';
import { appConfig } from '@app/src/config';

export const getMenuItems = (t: (translationKey: string) => string) => {
  return [
    {
      id: 1,
      value: CompanyProfileMenuFilter.Data,
      labelText: t('companyProfile_companyData'),
    },
    // {
    //   id: 2,
    //   value: CompanyProfileMenuFilter.Rules,
    //   labelText: t('companyProfile_companyRules'),
    // },
    {
      id: 3,
      value: CompanyProfileMenuFilter.History,
      labelText: t('companyProfile_companyHistory'),
    },
    // {
    //   id: 4,
    //   value: CompanyProfileMenuFilter.Tariff,
    //   labelText: t('companyProfile_companyTariff'),
    // },
    {
      id: 5,
      value: CompanyProfileMenuFilter.Users,
      labelText: t('companyProfile_companyUsers'),
    },
    {
      id: 6,
      value: CompanyProfileMenuFilter.Settings,
      labelText: t('settings'),
    },
  ];
};

import {
  currencyDocumentsApiClient,
  currencyOperationsApiClient,
  getClientId,
  swiftApiClient,
  ApiError,
  DeleteFilesRequestDto,
  DeleteFilesResponseDto,
  DocumentOperationType,
  GetCurrencyOperationsByUUIDsRequestDto,
  GetSwiftOperationsByUUIDsRequestDto,
  OperationsWithoutFilesDto,
} from '@app/core/api';

export const deleteCurrencyDocumentQueryFn = async (uuids: DeleteFilesRequestDto): Promise<DeleteFilesResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerDeleteFiles(getClientId(), uuids);

  if (response.status !== 200) {
    throw new Error("Can't delete currency document.");
  }
  return response.data;
};

export const getRelateCurrencyOperationsQueryFn = async (
  uuids: Array<string>
): Promise<OperationsWithoutFilesDto[]> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerGetByUUIDs(getClientId(), { uuids });
  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get currency operations.");
  }
  return response.data;
};

export const getRelateSwiftOperationsQueryFn = async (uuids: Array<string>): Promise<OperationsWithoutFilesDto[]> => {
  const response = await swiftApiClient.swiftControllerGetByUUIDs(getClientId(), { uuids });
  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get swift operations.");
  }
  return response.data;
};

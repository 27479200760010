import React from 'react';

import { useTranslation } from 'react-i18next';

import { CurrencyOperationsExchangeRateTypeEnum } from '@app/core/api';
import { SelectField, SelectFieldProps } from '@app/core/components/Form/controls/SelectField';
import { SelectOptionType } from '@app/core/components/Select';

export const ExchangeRateSelectField: React.FC<SelectFieldProps> = ({ name, ...props }) => {
  const { t } = useTranslation();

  const operationsListItems: SelectOptionType[] = [
    {
      id: 1,
      name: t('currencyExchange_ExchangeRateType_Bank'),
      value: CurrencyOperationsExchangeRateTypeEnum.Bank,
    },
    {
      id: 2,
      name: t('currencyExchange_ExchangeRateType_Client'),
      value: CurrencyOperationsExchangeRateTypeEnum.Client,
    },
  ];

  return <SelectField fullWidth name={name} options={operationsListItems} defaultValue="" {...props} />;
};

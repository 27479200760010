import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { CountryCodeDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { Loader } from '@app/core/components/Loader';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { ColumnFilterType, PaginationChangeType, SortingRule, TablePaginationType } from '@app/core/types';
import { CountryCodesColumnEnum } from '@app/pages/countryCodes/types';
import { makeTableColumns } from '@app/src/pages/countryCodes/components/CountryCodesTable/columns';
import { styles } from '@app/src/pages/countryCodes/components/CountryCodesTable/style';

interface TableContentProps {
  dataCountryCodes: CountryCodeDto[];
  loading?: boolean;
  pagination?: TablePaginationType;
  columnFilter: ColumnFilterType<CountryCodesColumnEnum>;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onColumnFilterChange(updater: ColumnFilterType<CountryCodesColumnEnum>, submit?: boolean): void;
  onClearSubFilter(): void;
}

export const CountryCodesTable: React.FC<TableContentProps> = ({
  dataCountryCodes,
  loading,
  pagination,
  columnFilter,
  onSort,
  onPaginationChange,
  onColumnFilterChange,
  onClearSubFilter,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);
  const data = useMemo(() => {
    return [...dataCountryCodes];
  }, [dataCountryCodes]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        showSubFilters,
        t,
        onSearchButtonClick: () => setShowSubFilters(true),
        onCloseSubFilter() {
          setShowSubFilters(false);
        },
        onClearSubFilter,
      }),
    ];
  }, [showSubFilters, t, onClearSubFilter]);

  const table = useReactTable<CountryCodeDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    meta: {
      columnFilterSate: {
        columnFilter,
        onColumnFilterChange,
      },
    },
  });
  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (loading || !dataCountryCodes.length) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<CountryCodeDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <ComplexTable
      head={{
        content: <TableHeadContent<CountryCodeDto> {...{ table, styles }} />,
      }}
      body={{ content: buildBodyContent() }}
      footer={{
        content: (
          <TablePagination
            totalCount={pagination?.totalCount || 0}
            page={pagination?.page || DEFAULT_FIRST_PAGE}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            labelRowsPerPage={t('showOnPageShort')}
            rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
            onChange={onPaginationChange}
            show={showPagination}
          />
        ),
      }}
    />
  );
};

import React, { useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import {
  CurrencyOperationsExchangeTypeEnum,
  CurrencyOperationStatusEnum,
  GetCurrencyOperationDto,
} from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { SignCurrencyExchangeMultiple } from '@app/core/components/SignCurrencyExchangeMultiple';
import { useMultiCurrencyExchangeSign } from '@app/core/hooks';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/SignExchangeDocumentDialog/style';
import { ReactComponent as SignIcoBgSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

interface SignExchangeDocumentDialogProps {
  selectedDocuments: GetCurrencyOperationDto[];
  onSubmitSuccess(): void;
  onClose(): void;
}

export const SignExchangeDocumentDialog: React.FC<SignExchangeDocumentDialogProps> = ({
  selectedDocuments,
  onSubmitSuccess,
  onClose,
}) => {
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const docsForDelete = selectedDocuments.filter(
    (item) => item.status === CurrencyOperationStatusEnum.Saved || item.status === CurrencyOperationStatusEnum.ToSign
  );

  const docsForSignCount: number = docsForDelete.length;

  const onConfirmSignDocument = () => {
    setShowSignatureDialog(true);
  };

  const currencyOperationGroupByExchangeType: {
    [key in CurrencyOperationsExchangeTypeEnum]?: GetCurrencyOperationDto[];
  } = groupBy<GetCurrencyOperationDto>(docsForDelete, 'currencyExchangeType');

  const currencyOperationConversionGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.CONVERSION,
      (operation) => operation.depositAccount.currency
    );

  const currencyOperationSaleGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.SALE,
      (operation) => operation.withdrawalAccount.currency
    );

  const currencyOperationPurchaseGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.PURCHASE,
      (operation) => operation.depositAccount.currency
    );

  const onSignSuccessHandler = () => {
    setShowSignatureDialog(false);
    onSubmitSuccess();
  };

  const onSignModalClose = () => {
    setShowSignatureDialog(false);
  };

  return (
    <ModalCommon onClose={onClose} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <SignIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onClose}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('documentsToSign')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('deleteDocumentCount')}
                {' / '} {t('sign_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedDocuments.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForSignCount}>
                {docsForSignCount}
              </Typography>
            </Grid>
          </Grid>
          {currencyOperationGroupByExchangeType.CONVERSION
            ? Object.entries(currencyOperationConversionGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_conversion_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_conversion')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {currencyOperationGroupByExchangeType.PURCHASE
            ? Object.entries(currencyOperationPurchaseGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_purchase_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_purchase')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {currencyOperationGroupByExchangeType.SALE
            ? Object.entries(currencyOperationSaleGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_sale_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_sale')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onClose}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmSignDocument}>
            {t('yes')}
          </Button>
        </Grid>
        {showSignatureDialog ? (
          <SignCurrencyExchangeMultiple
            operations={selectedDocuments}
            onClose={onSignModalClose}
            onSuccessPopupCancel={onSignSuccessHandler}
          />
        ) : null}
      </Box>
    </ModalCommon>
  );
};

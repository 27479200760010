import React, { useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SignatureIcon } from '@app/common/icons';
import { CreatePaymentOrderResultDto } from '@app/core/api';
import { ModalCommon } from '@app/core/components';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/payment/import/components/styles';
import { ReactComponent as SignatureBigSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

interface ConfirmSignImportOrdersProps {
  onAgreeSign(): void;
  onClose(): void;
  selectedCount: number;
  toSignDocuments: CreatePaymentOrderResultDto[];
}

export const ConfirmSignImportOrders: React.FC<ConfirmSignImportOrdersProps> = ({
  onAgreeSign,
  onClose,
  selectedCount,
  toSignDocuments,
}) => {
  const { t } = useTranslation();
  const [showActions, setShowActions] = useState<boolean>(true);
  const [insufficientFundsError, setInsufficientFundsError] = useState<boolean>(false);

  const amount = toSignDocuments.reduce((acc, document) => {
    return acc + document.amount;
  }, 0);

  return (
    <ModalCommon onClose={onClose} open>
      <Box sx={styles.paper}>
        {insufficientFundsError ? (
          <Box sx={styles.errorNotifyContainer}>
            <Typography variant="caption" sx={styles.errorNotifyText}>
              {t('notEnoughFunds')}
            </Typography>
          </Box>
        ) : null}
        <Box sx={styles.iconBg}>
          <SignatureBigSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <Box mt={4} mr={4}>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        <Box mt={3} mb={7}>
          <Typography variant="body1" sx={styles.title}>
            {t('documentsToSign')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_selectedDocuments')}
            {': '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {selectedCount}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_documentsToSign')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {toSignDocuments.length}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('sign_totalAmount')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {formatAmount(amount)}
            </Typography>{' '}
            {toSignDocuments[0].currency}
          </Typography>
        </Box>
        <Box mt={4}>
          {showActions ? (
            <Grid container justifyContent="center" wrap="nowrap">
              <Box pr={6}>
                <Button size="small" variant="outlined" color="primary" onClick={onClose}>
                  {t('cancel')}
                </Button>
              </Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                endIcon={<SignatureIcon />}
                onClick={onAgreeSign}
                disabled={insufficientFundsError}
              >
                {t('sign')}
              </Button>
            </Grid>
          ) : null}
        </Box>
      </Box>
    </ModalCommon>
  );
};

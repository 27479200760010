import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DocumentTemplateDto, TemplateSortingRuleDto, TemplateSortingRuleDtoFieldEnum } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { Loader } from '@app/core/components/Loader';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { PaginationChangeType, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesTable/components/columns';
import { styles } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesTable/components/styles';
import { TableToolbar } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesTable/components/TableToolbar';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export interface PaymentsTemplatesTableProps {
  templates: DocumentTemplateDto[];
  pagination?: TablePaginationType;
  loading?: boolean;
  onPaginationChange(pagination: PaginationChangeType): void;
  onSort?(rule: TemplateSortingRuleDto): void;
  onDeleteButtonClick(uuid: string | undefined): void;
}

export const PaymentsTemplatesTable: React.FC<PaymentsTemplatesTableProps> = ({
  templates,
  pagination,
  loading,
  onSort,
  onPaginationChange,
  onDeleteButtonClick,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data: DocumentTemplateDto[] = useMemo(() => templates.map((template) => ({ ...template })), [templates]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        enableEdit: permissions.uahTemplates.edit,
        enableDelete: permissions.uahTemplates.delete,
        onEditButtonClick: (uuid: string | undefined, templateName: string | undefined) => {
          if (uuid) {
            navigate(RouteList.template_edit.replace(':uuid', uuid), { state: { templateName } });
          }
        },
        onDeleteButtonClick,
        t,
      }),
    ];
  }, [navigate, onDeleteButtonClick, permissions.uahTemplates.delete, permissions.uahTemplates.edit, t]);

  const table = useReactTable<DocumentTemplateDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: templates.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as TemplateSortingRuleDtoFieldEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (templates.length === 0) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyDataMessage}>
          <Typography variant="body2">{t('emptyTemplateListMessage')}</Typography>
        </Grid>
      );
    }
    if (loading || !templates.length) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<DocumentTemplateDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <>
      <Box mt={7}>
        <TableToolbar />
      </Box>
      <ComplexTable
        head={{
          content: <TableHeadContent<DocumentTemplateDto> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || DEFAULT_FIRST_PAGE}
              rowsPerPage={DEFAULT_ROWS_PER_PAGE}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

import React, { useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CsvIcon, DownloadIcon, PdfIcon, XlsIcon, XmlIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  FileLinkAdditionalInfoDtoCodeEnum,
  OutputFormatEnum,
  StatementTemplateTypeEnum,
  StatementTypeEnum,
} from '@app/core/api';
import { appConfig } from '@app/src/config';

import { styles } from './style';
import { AccountSelectType } from '../../../../types';
import { getOperationsFile } from '../../query';

interface DownloadOperationsProps {
  accounts?: AccountSelectType[];
  disableButtons: boolean;
}

export const DownloadOperations: React.FC<DownloadOperationsProps> = ({ accounts, disableButtons }) => {
  const { notify } = useNotify();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { getValues } = useFormContext();

  const { mutateAsync, isLoading } = useMutation(getOperationsFile);

  const handleDownloadFile = (fileType: OutputFormatEnum) => {
    const startDate = getValues().from;
    const endDate = getValues().to;
    if (isLoading) {
      return;
    }
    if (accounts) {
      const operationAccounts = accounts.map((account) => {
        return {
          id: account.account.id.toString(),
          currencyCode: account.account.currency,
        };
      });
      mutateAsync({
        operationAccounts,
        documentType: StatementTypeEnum.Statement,
        dateRange: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        outputType: fileType,
        templateType: StatementTemplateTypeEnum.Default,
      }).then((responseData) => {
        if (
          responseData?.additionalInfo &&
          responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
        ) {
          notify({
            notifyProps: {
              message: t('load_too_many_operations_alert'),
              severity: 'warning',
            },
          });
          return;
        }
        if (responseData?.link) {
          window.open(`${responseData.link}?format=download&type=payment-order`);
        }
      });
    }
  };

  const handlePopoverClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton size="small" color="default" sx={styles.icon} onClick={handleClick} disabled={disableButtons}>
        <DownloadIcon />
      </IconButton>
      <Popover
        onClick={handlePopoverClick}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          'MuiPopover-paper': styles.popoverOperationsFile,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box component="div" mt={1} sx={styles.popoverOperationsFileContent}>
          <Button onClick={() => handleDownloadFile(OutputFormatEnum.Xlsx)} sx={styles.popoverOperationsFileItem}>
            <Typography variant="body2">
              <XlsIcon sx={styles.popoverOperationsFileItemIcon} />
              XLS
            </Typography>
          </Button>
          <Button onClick={() => handleDownloadFile(OutputFormatEnum.Csv)} sx={styles.popoverOperationsFileItem}>
            <Typography variant="body2">
              <CsvIcon sx={styles.popoverOperationsFileItemIcon} />
              СSV
            </Typography>
          </Button>
          <Button onClick={() => handleDownloadFile(OutputFormatEnum.Pdf)} sx={styles.popoverOperationsFileItem}>
            <Typography variant="body2">
              <PdfIcon sx={styles.popoverOperationsFileItemIcon} />
              PDF
            </Typography>
          </Button>
          {appConfig.enableFileFormat ? (
            <Button onClick={() => handleDownloadFile(OutputFormatEnum.Xml)} sx={styles.popoverOperationsFileItem}>
              <Typography variant="body2">
                <XmlIcon sx={styles.popoverOperationsFileItemIcon} />
                XML
              </Typography>
            </Button>
          ) : null}
        </Box>
      </Popover>
    </>
  );
};

import React from 'react';

import { Typography } from '@mui/material';

import { TaxDto } from '@app/core/api';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { SelectOptionType } from '@app/core/components/Select';
import { styles } from '@app/pages/payment/create/StatePayment/style';

export interface TaxesListSelectProps {
  options: TaxDto[];
  name: string;
  placeholder: string;
}

export const TaxesListSelect: React.FC<TaxesListSelectProps> = ({ name, options, placeholder }) => {
  const operationsListItems: SelectOptionType[] = options.map((tax) => {
    return {
      id: tax.code,
      name: `${tax.code} ${tax.type}`,
      value: `${tax.templateInfo.template.replace('taxCode', tax.code)},${tax.code} ${tax.type}`,
    };
  });

  return (
    <SelectField
      placeholder={placeholder}
      renderValue={(selected: any) => {
        if (!selected && placeholder) {
          return (
            <Typography variant="body2" sx={styles.selectPlaceholder}>
              {placeholder}
            </Typography>
          );
        }
        const value = selected.split(',');
        return value[1];
      }}
      name={name}
      defaultValue=""
      options={operationsListItems}
      fullWidth
      displayEmpty={Boolean(placeholder)}
    />
  );
};

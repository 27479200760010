import React from 'react';

import { CounterpartyResponseDto } from '@app/core/api';
import { useEditCounterparty } from '@app/core/hooks/useEditCounterparty';
import { CounterpartiesModal } from '@app/pages/counterparties/components/CounterpartiesModal';
import { CounterpartyFormData, FormMode } from '@app/pages/counterparties/types';

interface EditCounterpartyProps {
  counterparty: CounterpartyResponseDto;
  onClose(): void;
  onDeleteCounterparty(id: number): void;
  onSuccessEditCounterparty(): void;
}

export const EditCounterparty: React.FC<EditCounterpartyProps> = ({
  counterparty,
  onClose,
  onDeleteCounterparty,
  onSuccessEditCounterparty,
}) => {
  const { mutate } = useEditCounterparty();

  const onEditCounterpartyHandler = (value: CounterpartyFormData) => {
    if (counterparty) {
      mutate({ counterpartyData: value, id: counterparty.id }, { onSuccess: onSuccessEditCounterparty });
    }
  };

  return (
    <CounterpartiesModal
      show
      mode={FormMode.edit}
      onClose={onClose}
      counterparty={counterparty}
      onSaveCounterparty={onEditCounterpartyHandler}
      onDeleteCounterparty={onDeleteCounterparty}
    />
  );
};

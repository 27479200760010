import React, { useRef, useState } from 'react';

import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CrossIcon, EditIcon, DoneIcon } from '@app/common/icons';
import { GetAccountResponseDto } from '@app/core/api';
import { IBanText } from '@app/core/components/IBanText';
import { useUpdateAccounts } from '@app/core/hooks/useUpdateAccount';
import { cropName, formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/accounts/components/AccountsList/components/AccountsListRow/styles';
import { BankDetails } from '@app/pages/accounts/components/AccountsList/components/BankDetails';
import { SendDetailsModal } from '@app/pages/accounts/components/AccountsList/components/BankDetails/SendDetails';
import { BalanceInfo, DetailsInfoItem, FavoriteIcon } from '@app/pages/accounts/components/AccountsList/primitives';
import { RouteList } from '@app/src/constants/routeList';

export interface MobileAccountInfoProps {
  account: GetAccountResponseDto;
}
//TODO: need refactor styles
export const MobileAccountInfo: React.FC<MobileAccountInfoProps> = ({ account }) => {
  const {
    id,
    accountType,
    accountNumber,
    accountName,
    openingDate,
    amount,
    currency,
    favorite: originFavorite,
  } = account.account;
  const { lastOperation } = account;

  const { mutate } = useUpdateAccounts();
  const [favorite, setFavorite] = useState<boolean>(originFavorite);
  const [inputValue, setInputValue] = useState<string>('');
  const [editName, setEdithName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const refAnhor = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const showEditor = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setError('');
    setEditMode(true);
    setAnchorEl(refAnhor.current);
  };
  const closeEditor = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setError('');
    setEditMode(false);
    setAnchorEl(null);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value as string);
  };
  // TODO: define type for submitData
  const handleSubmit = () => {
    if (inputValue.length > 0) {
      mutate({ id, name: inputValue });
      setEdithName(inputValue);
      setError('');
      setShowNewName(true);
      setEditMode(false);
      setAnchorEl(null);
    } else {
      setError(t('errorEmptyField'));
    }
  };
  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const onCheckHandler = () => {
    mutate({ id, favorite: !favorite });
    setFavorite(!favorite);
  };

  const handleShow = () => {
    setShowModal(!showModal);
  };

  const [shake, setShake] = useState<boolean>(false);

  const shakeAnimationTrigger = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  };

  const handleOverlayClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    shakeAnimationTrigger();
  };

  const onCloseSendDetailsModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Grid
        item
        container
        direction="row"
        sx={styles.mainAccountBlock}
        component={Link}
        to={`${RouteList.accounts}/${id}`}
        state={{ accountName }}
      >
        <Grid item alignItems="center" container wrap="nowrap" sx={styles.accountLeft}>
          <Box>
            <IconButton size="small" onClick={onCheckHandler}>
              <FavoriteIcon status={favorite} />
            </IconButton>
          </Box>
          <Box sx={styles.accountLeftInfo}>
            <Popover
              onClose={handleOverlayClick}
              open={editMode}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPopover-paper': {
                  background: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  maxWidth: '330px',
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transitionDuration={{
                enter: 200,
                exit: 100,
              }}
            >
              <Grid container alignItems="flex-start" wrap="nowrap">
                <TextField
                  error={error.length > 0}
                  helperText={t(error)}
                  variant="outlined"
                  autoFocus
                  placeholder={t('selectedAccountAccountInfo_changeAccountNamePlaceholder')}
                  sx={{ ...styles.editorInput, ...(shake && styles.shakeAnimate) }}
                  value={inputValue}
                  onChange={handleChange}
                  onKeyPress={onEnterPress}
                />
                <IconButton sx={styles.editorButton} onClick={handleSubmit} size="large">
                  <DoneIcon sx={styles.editorIconDone} fontSize="small" />
                </IconButton>
                <IconButton sx={styles.editorButton} onClick={closeEditor} size="large">
                  <CrossIcon sx={styles.editorIconCancel} fontSize="small" />
                </IconButton>
              </Grid>
            </Popover>
            <Typography
              variant="h6"
              sx={{
                color: 'colors.primary600',
                fontWeight: 600,
                minHeight: error ? '50px' : '40px',
              }}
              ref={refAnhor}
            >
              {editMode ? null : (
                <>
                  <Box component="span" mr={1}>
                    <b>{cropName(showNewName ? editName : accountName, 17)}</b>
                  </Box>
                  <IconButton aria-label="edit" size="small" onClick={showEditor}>
                    <EditIcon sx={styles.editIcon} fontSize="medium" />
                  </IconButton>
                </>
              )}
            </Typography>
            <IBanText
              text={accountNumber}
              styles={{
                root: {
                  fontSize: '0.75rem',
                },
              }}
            />
          </Box>
          {account.account.showRequisites ? <BankDetails document={account} handleShowModal={handleShow} /> : null}
        </Grid>
        {lastOperation ? (
          <>
            <Grid container direction="column">
              <Box sx={styles.hr} mb={3} mt={3} />
              <DetailsInfoItem description={t('selectedAccountAccountInfo_openingDate')} value={openingDate} />
              <DetailsInfoItem
                description={t('selectedAccountAccountInfo_lastMovementDate')}
                value={lastOperation?.date || ''}
              />
              <DetailsInfoItem
                description={t('selectedAccountAccountInfo_lastAmount')}
                value={formatAmount(lastOperation.amount)}
                type={lastOperation.type}
                currency={lastOperation.currency}
              />
              <Box sx={styles.hr} mb={3} mt={3} />
              <BalanceInfo
                description={t('selectedAccountAccountInfo_remains')}
                balance={formatAmount(amount)}
                currency={currency}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      {showModal ? <SendDetailsModal show onClose={onCloseSendDetailsModal} id={id} /> : null}
    </>
  );
};

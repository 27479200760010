export const styles = {
  root: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    top: '15%',
    left: 0,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    height: '-webkit-fill-available',
  },
  hr: {
    borderBottom: '1px solid',
    borderColor: 'colors.gray100',
  },
};

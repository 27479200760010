export enum OperationsFilter {
  status_toSign = 'status_toSign',
  status_onSigning = 'status_onSigning',
  status_processing = 'status_processing',
  status_rejected = 'status_rejected',
  status_saved = 'status_saved',
  type_all = 'type_all',
  type_incoming = 'type_incoming',
  type_outgoing = 'type_outgoing',
}

export enum OperationsFilterStatements {
  all = 'all',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

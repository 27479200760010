import React, { useRef, useState } from 'react';

import { Box, Grid, IconButton, TextField, Typography, SxProps, Theme, useTheme } from '@mui/material';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { CrossIcon, DoneIcon, EditIcon, MoveIcon, StarFilledIcon, StarIcon } from '@app/common/icons';
import { GetAccountResponseDto } from '@app/core/api';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { IBanText } from '@app/core/components/IBanText';
import { useScreenOrientation } from '@app/core/hooks';
import { formatAmount, isMobile } from '@app/core/utils';
import { useStyles } from '@app/pages/dashboard/view/PageContent/AccountBalances/AccountBalancesModal/AccountBalanceItem/styles';

interface AccountBalanceItemProps {
  data: GetAccountResponseDto;
  selected?: boolean;
  editedValue?: string;
  providedHandleProps?: DraggableProvidedDragHandleProps;
  styles?: SxProps<Theme>;
  isDragDisabled?: boolean;
  onEditName?(id: string, newName: string): void;
  onAddToFavorites?(id: string, selected: boolean): void;
}

export const AccountBalanceItem: React.FC<AccountBalanceItemProps> = ({
  data,
  selected = false,
  editedValue,
  providedHandleProps,
  styles: stylesBalance,
  isDragDisabled,
  onEditName,
  onAddToFavorites,
}) => {
  const { id, accountNumber, accountName, amount, currency } = data.account;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(accountName);
  const [editName, setEdithName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const refAnhor = useRef<HTMLDivElement>(null);
  const showEditor = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(refAnhor.current);
    setError(false);
    setEditMode(true);
  };

  const closeEditor = () => {
    setError(false);
    setEditMode(false);
    setAnchorEl(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value as string);
  };
  const handleName = () => {
    if (inputValue.length > 0) {
      setEdithName(inputValue);
      if (onEditName) {
        onEditName(id, inputValue);
      }
      setError(false);
      setShowNewName(true);
      setEditMode(false);
      setAnchorEl(null);
    } else {
      setError(true);
    }
  };
  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleName();
    }
  };
  const onCheckHandler = () => {
    if (onAddToFavorites) {
      onAddToFavorites(id, !selected);
    }
  };

  const [shake, setShake] = useState<boolean>(false);

  const shakeAnimationTrigger = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  };

  const handleOverlayClick = () => {
    shakeAnimationTrigger();
  };

  const { isPortrait } = useScreenOrientation();

  const isMobilePortrait = isMobile && isPortrait;
  const theme = useTheme();
  const styles = useStyles(theme, editMode, selected, error);

  return (
    <Grid
      container
      sx={{ ...styles.root, ...(stylesBalance && stylesBalance) }}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item xs={7}>
        <Grid container justifyContent="center" direction="column">
          <Typography variant="body1" component="div" sx={styles.accountNameWrapper} ref={refAnhor}>
            {editMode ? (
              <Grid container alignItems="flex-start" wrap="nowrap">
                <TextField
                  error={error}
                  helperText={error ? t('selectedAccountAccountInfo_errorEmptyField') : null}
                  variant="outlined"
                  autoFocus
                  placeholder={t('selectedAccountAccountInfo_changeAccountNamePlaceholder')}
                  sx={{ ...styles.editorInput, ...(shake && styles.shakeAnimate) }}
                  value={inputValue}
                  onChange={handleChange}
                  onKeyPress={onEnterPress}
                />
                <IconButton sx={styles.editorButton} onClick={handleName} size="large">
                  <DoneIcon sx={styles.editorIconDone} fontSize="small" />
                </IconButton>
                <IconButton sx={styles.editorButton} onClick={closeEditor} size="large">
                  <CrossIcon sx={styles.editorIconCancel} fontSize="small" />
                </IconButton>
              </Grid>
            ) : (
              <Grid container wrap="nowrap">
                <Typography sx={styles.accountName} component="div">
                  <Ellipsis text={showNewName ? editName : editedValue || accountName} />
                </Typography>
                <IconButton aria-label="edit" size="small" onClick={showEditor}>
                  <EditIcon sx={styles.editIcon} />
                </IconButton>
              </Grid>
            )}
          </Typography>
          <IBanText
            text={accountNumber}
            styles={{
              root: {
                color: 'colors.gray400',
                accountNumberPart2: { color: 'colors.gray500', fontWeight: 600 },
                '@media (max-width: 1279.95px)': {
                  fontSize: '0.75rem',
                  maxWidth: '100%',
                },
              },
            }}
          />
          {isMobilePortrait ? (
            <Typography variant="body1" component="div" sx={styles.amountText}>
              {formatAmount(amount)}{' '}
              <Typography variant="body1" component="span">
                {currency}
              </Typography>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container alignContent="center" justifyContent="flex-end">
          {!isMobilePortrait ? (
            <Typography variant="body1" component="div" sx={styles.amountText}>
              {formatAmount(amount)}{' '}
              <Typography variant="body1" component="span">
                {currency}
              </Typography>
            </Typography>
          ) : null}
          <Box sx={styles.iconContainer}>
            <Box component="span" onClick={onCheckHandler}>
              {selected ? <StarFilledIcon sx={styles.favoriteIcon} /> : <StarIcon sx={styles.favoriteIcon} />}
            </Box>
            <Box component="span" sx={styles.moveIconWrap} {...providedHandleProps}>
              {isDragDisabled ? null : <MoveIcon />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

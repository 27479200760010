import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ChangeStatusRequestDtoStatusEnum,
  DocumentOperationType,
  DocumentType,
  MessageDto,
  MessageStatus,
  MessageType,
  UploadFileResponseDto,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { PeopleToSignMessageList } from '@app/core/components/PeopleToSignMessageList';
import { SignMessages } from '@app/core/components/SignMessages';
import {
  useChangeMessagesStatus,
  useCreateMessage,
  useCurrencyUploadFiles,
  useDocumentAttachOperation,
  useGetMessagesSigners,
} from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { MessageEditForm } from '@app/pages/letters/components/MessageEditForm';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { MessageEditData } from '../components/MessageEditForm/types';
import { MessageStatusTitle } from '../components/MessageStatusTitle';
import { styles } from '../styles';

interface CreateMessageProps {
  messageEditData: MessageEditData;
  onCreateSuccess(message: MessageDto): void;
  onCreateError(): void;
}
export const MessageCreationPage: React.FC = () => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [showSignPopup, setShowSignPopup] = useState<boolean>(false);
  const [createMessageResultData, setCreateMessageResultData] = useState<MessageDto>();
  const { mutateAsync: mutateAsyncCreateMessage, isLoading: isLoading1 } = useCreateMessage();
  const { data: peopleToSigne, mutate: mutatePeopleToSign, isLoading: isLoading2 } = useGetMessagesSigners();
  const { mutate: mutateChangeMessageStatus } = useChangeMessagesStatus();
  const { mutateAsync: mutateAsyncUploadFiles } = useCurrencyUploadFiles();
  const { mutateAsync: mutateAsyncAttachOperation } = useDocumentAttachOperation();

  useEffect(() => {
    mutatePeopleToSign(undefined);
  }, [mutatePeopleToSign]);

  useEffect(() => {
    showLoader(isLoading1 || isLoading2);
  }, [isLoading1, showLoader, isLoading2]);

  const createMessage = ({ messageEditData, onCreateSuccess, onCreateError }: CreateMessageProps) => {
    const { messageData, files } = messageEditData;
    showLoader(true);
    mutateAsyncCreateMessage({ ...messageData })
      .then((message: MessageDto) => {
        if (files.getAll('file').length > 0) {
          mutateAsyncUploadFiles({
            file: files,
            documentType: DocumentType.Attachment,
            operationsTypes: [DocumentOperationType.Message],
          })
            .then((resultSaveFiles: UploadFileResponseDto[]) => {
              mutateAsyncAttachOperation({
                uuids: [...resultSaveFiles.map((file) => file.uuid)],
                operations: [{ uuid: message.uuid, operationType: DocumentOperationType.Message }],
              })
                .then(() => {
                  showLoader(false);
                  onCreateSuccess(message);
                })
                .catch(() => {
                  notify({
                    notifyProps: {
                      title: t('attachFile_ErrorTitle'),
                      message: t('attachFile_ErrorMessage'),
                      severity: 'error',
                    },
                  });
                  showLoader(false);
                });
            })
            .catch(() => {
              notify({
                notifyProps: {
                  title: t('attachFile_ErrorTitle'),
                  message: t('attachFile_ErrorMessage'),
                  severity: 'error',
                },
              });
            });
        } else {
          showLoader(false);
          onCreateSuccess(message);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('error'),
            message: t('letter_save_error_message'),
            severity: 'error',
          },
        });
        showLoader(false);
        onCreateError();
      })
      .finally(() => {
        showLoader(false);
      });
  };

  const onSaveHandler = (messageEditData: MessageEditData) => {
    createMessage({
      messageEditData,
      onCreateSuccess(message: MessageDto) {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('letter_save_success_message'),
            severity: 'success',
          },
        });
        navigate(`${RouteList.letters}?type=${MessageType.Outgoing}&status=${MessageStatus.Saved}`);
      },
      onCreateError() {},
    });
  };

  const onSaveAndSignHandler = (messageEditData: MessageEditData) => {
    createMessage({
      messageEditData,
      onCreateSuccess(message: MessageDto) {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('letter_save_success_message'),
            severity: 'success',
          },
        });
        setCreateMessageResultData(message);
        setShowSignPopup(true);
      },
      onCreateError() {},
    });
  };

  const onSaveSendToSignHandler = (messageEditData: MessageEditData) => {
    createMessage({
      messageEditData,
      onCreateSuccess(message: MessageDto) {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('sentToSign'),
            severity: 'success',
          },
        });
        mutateChangeMessageStatus({
          status: ChangeStatusRequestDtoStatusEnum.ToSign,
          uuids: [message.uuid],
        });
        navigate(-1);
      },
      onCreateError() {},
    });
  };

  const onSuccessSignHandler = () => {
    setCreateMessageResultData(undefined);
    setShowSignPopup(false);
    navigate(`${RouteList.letters}?type=${MessageType.Outgoing}&status=${MessageStatus.Signed}`);
  };

  const onCloseSignMessagesPopupHandler = () => {
    setShowSignPopup(false);
    setCreateMessageResultData(undefined);
    setShowSignPopup(false);
  };

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    setShowConfirmPopup(formDirty);
  };

  const onCancelHandler = () => {
    if (showConfirmPopup) {
      setLeavePopupOpen(true);
    } else {
      navigate(RouteList.letters);
    }
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(RouteList.letters);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  return (
    <>
      <PageWrapper
        sx={{
          ...(isMobile && {
            backgroundColor: 'initial',
            borderRadius: 0,
            height: '100vh',
            padding: 0,
          }),
        }}
      >
        <PageHeader
          title={t('new_letter')}
          showNavigateButton={!isMobile}
          showLeavePopup={showConfirmPopup}
          confirmQuestionText={t('corrections_massage')}
          backLink={-1}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap">
              <Grid item container xs={isMobile ? false : 9}>
                <MessageEditForm
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onChange={onChangeHandler}
                  onCancel={onCancelHandler}
                  onSaveAndSendToSign={onSaveSendToSignHandler}
                />
              </Grid>
              {!isMobile && userProfile ? (
                <Grid item container xs={3}>
                  <Box pl={10}>
                    <MessageStatusTitle />
                    <Box sx={styles.hr} />
                    <Box mt={4} />
                    <PeopleToSignMessageList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>

      {showSignPopup && createMessageResultData ? (
        <SignMessages
          messages={[createMessageResultData]}
          onSuccessPopupCancel={onSuccessSignHandler}
          onClose={onCloseSignMessagesPopupHandler}
        />
      ) : null}

      <LeaveEditPopup
        questionText={t('corrections_massage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { GetInternationalBankResponseDto, GetSwiftResponseDto } from '@app/core/api';
import { BICField } from '@app/core/components/Form/controls/BICField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { useGetInternationalBanksList } from '@app/core/hooks/useGetInternationalBanksList';
import { styles } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartyModal/styles';
import { validationSchema } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartyModal/validationSchema';
import { SwiftCounterpartyFormData, SwiftCounterpartyFormFieldsEnum } from '@app/pages/counterpartiesSwift/types';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';

export interface SwiftCounterpartiesModalProps {
  show: boolean;
  counterparty?: GetSwiftResponseDto;
  onClose?(): void;
  onSaveCounterparty(value: SwiftCounterpartyFormData): void;
}

export const SwiftCounterpartyModal: React.FC<SwiftCounterpartiesModalProps> = ({
  counterparty,
  show,
  onClose,
  onSaveCounterparty,
}) => {
  const [showCorrespondent, setShowCorrespondent] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mutateAsync: mutateAsyncGetInternationalBanksList } = useGetInternationalBanksList();
  const methods = useForm<SwiftCounterpartyFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      [SwiftCounterpartyFormFieldsEnum.correspondentBankCheck]: false,
    },
  });
  const { handleSubmit, getValues, setValue, control } = methods;

  useEffect(() => {
    if (counterparty) {
      setValue(SwiftCounterpartyFormFieldsEnum.name, counterparty.name);
      setValue(SwiftCounterpartyFormFieldsEnum.account, counterparty.account);
      setValue(SwiftCounterpartyFormFieldsEnum.address, counterparty.address);
      if (counterparty.correspondentBank) {
        setValue(SwiftCounterpartyFormFieldsEnum.correspondentBank, counterparty.correspondentBank);
      }
      if (counterparty.correspondentSwiftBic) {
        setValue(SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic, counterparty.correspondentSwiftBic);
      }
      if (counterparty.correspondentSwiftBic || counterparty.correspondentBank) {
        setValue(SwiftCounterpartyFormFieldsEnum.correspondentBankCheck, true);
        setShowCorrespondent(true);
      }
    }
  }, [counterparty, setValue]);

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = () => {
    onSaveCounterparty(getValues());
  };

  const onCorrespondentCheckChange = () => {
    setShowCorrespondent(!showCorrespondent);
  };

  const watchAccount = useWatch({ control, name: SwiftCounterpartyFormFieldsEnum.account });

  useEffect(() => {
    if (watchAccount) {
      setValue(SwiftCounterpartyFormFieldsEnum.account, watchAccount.replace(/\s/g, ''));
    }
  }, [setValue, watchAccount]);

  const bicCodeWatch = useWatch({ control, name: SwiftCounterpartyFormFieldsEnum.bic });
  useEffect(() => {
    if (bicCodeWatch && bicCodeWatch.length >= 8) {
      mutateAsyncGetInternationalBanksList({ swift: bicCodeWatch }).then((result: GetInternationalBankResponseDto) => {
        if (result.rows.length) {
          setValue(SwiftCounterpartyFormFieldsEnum.bankName, result.rows[0].name);
        } else {
          setValue(SwiftCounterpartyFormFieldsEnum.bankName, '');
        }
      });
    }
  }, [mutateAsyncGetInternationalBanksList, bicCodeWatch, setValue]);

  const correspondentBicCodeWatch = useWatch({ control, name: SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic });
  useEffect(() => {
    if (correspondentBicCodeWatch && correspondentBicCodeWatch.length >= 4) {
      mutateAsyncGetInternationalBanksList({ swift: correspondentBicCodeWatch }).then(
        (result: GetInternationalBankResponseDto) => {
          if (result.rows.length) {
            setValue(SwiftCounterpartyFormFieldsEnum.correspondentBank, result.rows[0].name);
          } else {
            setValue(SwiftCounterpartyFormFieldsEnum.correspondentBank, '');
          }
        }
      );
    }
  }, [mutateAsyncGetInternationalBanksList, correspondentBicCodeWatch, setValue]);

  return (
    <ModalCommon open={show}>
      <Grid container direction="column" wrap="nowrap" sx={styles.paper}>
        <Box pb={3}>
          <Grid justifyContent="space-between" alignItems="center" container>
            <Typography component="span" variant="h6" sx={styles.caption}>
              {counterparty ? t('editSwiftCounterparty') : t('swiftCounterpartiesNew')}
            </Typography>
            <IconButton size="small" onClick={onCloseHandler}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container justifyContent="space-between" spacing={7}>
              <Grid item xs={6}>
                <FormLabel>{t('counterparties_name')}</FormLabel>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={SwiftCounterpartyFormFieldsEnum.name}
                    placeholder={t('counterparties_name')}
                    defaultValue={counterparty?.name}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel>{t('account')}</FormLabel>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={SwiftCounterpartyFormFieldsEnum.account}
                    placeholder={t('account')}
                    defaultValue={counterparty?.account}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{t('myProfile_country')}</FormLabel>
                <Box pt={1}>
                  <CountrySelect
                    placeholder={t('countrySelect_placeholder')}
                    name={SwiftCounterpartyFormFieldsEnum.countryCode}
                    defaultValue={counterparty?.country.numeric}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{t('swiftPayment_swiftBicFieldPlaceholder')}</FormLabel>
                <Box pt={1}>
                  <BICField
                    name={SwiftCounterpartyFormFieldsEnum.bic}
                    placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
                    defaultValue={counterparty?.bic}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{t('paymentCreationLocalPayment_bankName')}</FormLabel>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={SwiftCounterpartyFormFieldsEnum.bankName}
                    placeholder={t('banks_name')}
                    defaultValue={counterparty?.bankName}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t('company_address')}</FormLabel>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={SwiftCounterpartyFormFieldsEnum.address}
                    placeholder={t('company_address')}
                    defaultValue={counterparty?.address}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  label={<Typography variant="caption">{t('swiftPayment_correspondentBankCheckboxLabel')}</Typography>}
                  defaultChecked={false}
                  value={showCorrespondent}
                  name={SwiftCounterpartyFormFieldsEnum.correspondentBankCheck}
                  sx={{
                    color: 'colors.gray600',
                    marginLeft: 0,
                  }}
                  onChange={onCorrespondentCheckChange}
                />
              </Grid>
              {showCorrespondent ? (
                <>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('swiftPayment_swiftBicCorrespondent')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <BICField
                        name={SwiftCounterpartyFormFieldsEnum.correspondentSwiftBic}
                        placeholder={t('swiftPayment_swiftBicCorrespondent')}
                        defaultValue={counterparty?.correspondentSwiftBic}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={SwiftCounterpartyFormFieldsEnum.correspondentBank}
                        placeholder={t('swiftPayment_bankFieldPlaceholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </>
              ) : null}
              <Grid item container justifyContent="flex-end" spacing={5}>
                <Box mt={4}>
                  <Button variant="contained" size="small" color="primary" type="submit">
                    {t('save')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ModalCommon>
  );
};

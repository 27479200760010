import Cookies from 'js-cookie';

export type ServiceConfig = {
  defaultLanguage: string;
  apiUrl: string;
  loginUrl: string;
  cookiesDomain: string;
};

export type AppConfig = {
  enableStatementsGroupCommission: boolean;
  enableOperationGroupCommission: boolean;
  enableRecipientsInstant: boolean;
  cryptoModuleUrlPrev: string;
  cryptoModuleUrl: string;
  enableDashboardNotify: boolean;
  enableCompanyUserTable: boolean;
  enableUserNotification: boolean;
  enableFileFormat: boolean;
  enableNewYearIcons: boolean;
  enableShareButton: boolean;
  enableShareMailInput: boolean;
  enableNextFeatures: boolean;
  enableMobileAlert: boolean;
  enableCurrencyOperationsDownloadButtons: boolean;
  shoUmcaPopup: boolean;
  hideCompanyUsersButtons: boolean;
  version: string;
  coreVersion: string;
};

export const serviceConfig: ServiceConfig = {
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANG || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  loginUrl: process.env.REACT_APP_LOGIN_URL || '',
  cookiesDomain: process.env.REACT_APP_COOKIES_DOMAIN || '',
};

export const appConfig: AppConfig = {
  enableStatementsGroupCommission: false,
  enableOperationGroupCommission: false,
  enableRecipientsInstant: false,
  cryptoModuleUrlPrev: '/lib/wasmModule/wasmModule.js',
  cryptoModuleUrl: '/lib/iit/euscp.js',
  enableDashboardNotify: true,
  enableCompanyUserTable: false,
  enableUserNotification: false,
  enableFileFormat: true,
  enableNewYearIcons: false,
  enableShareButton: false,
  enableShareMailInput: false,
  enableNextFeatures: Boolean(Cookies.get('enableNextFeatures')) || false,
  enableMobileAlert: false,
  enableCurrencyOperationsDownloadButtons: false,
  shoUmcaPopup: true,
  hideCompanyUsersButtons: true,
  version: process.env.PACKAGE_VERSION || '',
  coreVersion: process.env.CORE_PACKAGE_VERSION || '',
};
//
// const envConfigs = {
//   dev: {
//     cryptoURL: 'crypto.dgb-corp.ftr-dev.net',
//     cryptoPort: 443,
//   },
//   production: {},
// };

export const cryptoConfig = {
  endpoinConfiguration: {
    ProxySettings: {
      Host: 'api.business.oxibank.ua/crypto',
      Port: 443,
      User: '',
      Password: '',
    },
    CmpServices: [
      'acsk.privatbank.ua',
      'acskidd.gov.ua',
      'uakey.com.ua',
      'masterkey.ua',
      'ca.informjust.ua',
      'csk.uz.gov.ua',
      'csk.uss.gov.ua',
      'csk.ukrsibbank.com',
      'ca.alfabank.kiev.ua',
      'ca.credit-agricole.ua',
      'pki.pumb.ua',
      'ca.depositsign.com',
      'ca.vchasno.ua',
      'ca.mil.gov.ua',
      'acsk.dpsu.gov.ua',
      'acsk.oree.com.ua',
      'ca.mvs.gov.ua',
      'canbu.bank.gov.ua',
      'ca.gp.gov.ua/cmp',
    ],
    TspServices: [
      'acsk.privatbank.ua',
      'acskidd.gov.ua',
      'uakey.com.ua',
      'masterkey.ua/services/tsp/',
      'ca.informjust.ua',
      'csk.uz.gov.ua',
      'csk.uss.gov.ua',
      'csk.ukrsibbank.com',
      'ca.alfabank.kiev.ua',
      'ca.credit-agricole.ua',
      'pki.pumb.ua',
      'ca.depositsign.com',
      'ca.vchasno.ua/services/tsp/dstu/',
      'ca.mil.gov.ua',
      'acsk.dpsu.gov.ua',
      'acsk.oree.com.ua',
      'ca.mvs.gov.ua',
      'canbu.bank.gov.ua',
      'ocsp.treasury.gov.ua/TspHTTPServer/tsp',
      'ca.oschadbank.ua/public/tsa',
      'ca.gp.gov.ua/tsp',
      'cesaris.itsway.kiev.ua/services/tsp/',
    ],
    IntermediateCertificatesCmsURL: 'storage.googleapis.com/dgb-web-assets/certificates.p7b',
    TrustedCertificatesCmsURL: 'storage.googleapis.com/dgb-web-assets/root.p7b',
  },
  paramsStr: 'cms;attached;stdua',
  verifyStr: 'stdua',
};

// Налаштування бібліотеки
export const euSettings = {
  language: 'uk',
  encoding: 'utf-8',
  httpProxyServiceURL:
    process.env.REACT_APP_CRYPTO_PROXY_URL || 'https://crypto-proxy.corporate.fenomena.dev/api/v1/proxy',
  directAccess: true,
  CAs: '/crypto/CAs.json',
  CACertificates: '/crypto/CACertificates.p7b',
  allowedKeyMediaTypes: [
    'е.ключ ІІТ Алмаз-1К',
    'е.ключ ІІТ Кристал-1',
    'ID-карта громадянина (БЕН)',
    'е.ключ ІІТ Алмаз-1К (PKCS#11)',
    'е.ключ ІІТ Кристал-1 (PKCS#11)',
  ],
};

export const umcaConfig = {
  url: 'https://localhost:25989',
  services: {
    about: '/about',
    certificates: '/keystores',
    sign: '/sign/multiple',
  },
};

export const requestTopic = {
  overdraft: 'Овердрафт',
  loan: 'Кредит',
  loanLine: 'Кредитні лінії',
  deposit: 'Депозит',
};

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { IBanText } from '@app/core/components/IBanText';
import { Operation, OperationAccount } from '@app/core/types';
import { formatAmount } from '@app/core/utils';
import { OperationsTableColumns } from '@app/pages/loans/types';
import { styles } from '@app/pages/loansLines/show/components/OperationsTable/style';

interface MakeTableColumnsProps {
  t(text: string): string;
}

export const makeTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<Operation, any>[] => {
  return [
    {
      accessorKey: OperationsTableColumns.date,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`Date`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<Operation, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: OperationsTableColumns.account,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`accountNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<Operation, OperationAccount>) => {
        return <IBanText text={getValue().number} />;
      },
    },
    {
      accessorKey: OperationsTableColumns.paymentReference,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`paymentReference`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OperationsTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<Operation, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: OperationsTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      id: 'actions',
      cell: ({ row }: CellContext<Operation, any>) => {
        return (
          <Grid container justifyContent="flex-end">
            <Box
              component="span"
              {...{
                onClick: () => row.toggleExpanded(),
              }}
              sx={styles.chevron}
            >
              {row.getIsExpanded() ? (
                <UpChevronSmallIcon fontSize="small" />
              ) : (
                <DownChevronSmallIcon fontSize="small" />
              )}
            </Box>
          </Grid>
        );
      },
    },
  ];
};

import React, { forwardRef } from 'react';

import { Grid, TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@mui/material';

export const TableRow: React.FC<MuiTableRowProps<typeof Grid>> = forwardRef(
  ({ className, children, ...otherProps }, ref) => (
    <MuiTableRow component={Grid} hover role="checkbox" tabIndex={-1} {...otherProps} ref={ref}>
      {children}
    </MuiTableRow>
  )
);

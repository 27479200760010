import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RadioButton } from '../RadioButton';

export type RadioOption = {
  label: string;
  value: any;
};

export interface RadioGroupProps {
  title?: React.ReactNode;
  name: string;
  value: RadioOption['value'];
  options: RadioOption[];
  row?: boolean;
  fullWidth?: boolean;
  styles?: {
    title?: SxProps<Theme>;
    radioLabel?: SxProps<Theme>;
    container?: SxProps<Theme>;
  };
  onChange?(value: keyof RadioOption): void;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  title,
  name,
  value,
  options,
  row = true,
  fullWidth = false,
  styles,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value as RadioOption['value']);
    }
  };

  const { t } = useTranslation();

  return (
    <FormControl component="fieldset" fullWidth={fullWidth} focused={false}>
      {title ? (
        <FormLabel component="legend" sx={styles?.title}>
          {title}
        </FormLabel>
      ) : null}

      <MuiRadioGroup row={row} name={name} sx={styles?.container} color="primary" value={value} onChange={handleChange}>
        {options.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<RadioButton color="primary" size="small" />}
            label={
              <Typography variant="caption" sx={styles?.radioLabel}>
                {t(item.label)}
              </Typography>
            }
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CreatePaymentOrderResultDto,
  GetAccountResponseDto,
  PaymentOrderAdditionalInfoDto,
  ResultStatusEnum,
  CurrencyEnum,
  PaymentTemplateTypeEnum,
  DocumentTemplateDto,
  AccountStatusEnum,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useGeneratePaymentOrderNumber, useGetUahPaymentSigners } from '@app/core/hooks';
import { useCreateStatePayment } from '@app/core/hooks/useCreateStatePayment';
import { useGetCurrentAccountsByCurrency } from '@app/core/hooks/useGetCurrentAccountsByCurrency';
import { useGetTaxesList } from '@app/core/hooks/useGetTaxesList';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { Severity } from '@app/core/types';
import { isMobile, formatAmount, parseJSON } from '@app/core/utils';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { DesktopStatePaymentForm } from '@app/pages/payment/components/StatePaymentForm/DesktopStatePaymentForm';
import { makeCreateTemplateData } from '@app/pages/payment/components/StatePaymentForm/helpers';
import { MobileStatePaymentForm } from '@app/pages/payment/components/StatePaymentForm/MobileStatePaymentForm';
import {
  FormFieldName,
  StatePaymentFormData,
  StatePaymentFormDefaultValues,
} from '@app/pages/payment/components/StatePaymentForm/types';
import { makeStatePaymentData } from '@app/pages/payment/create/StatePayment/helpers';
import { styles } from '@app/pages/payment/create/StatePayment/style';
import { validationSchema } from '@app/pages/payment/create/StatePayment/validationschema';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface CreatePaymentProps {
  paymentData: StatePaymentFormData;
  onCreateSuccess(operation: CreatePaymentOrderResultDto): void;
  onCreateError(additionalInfo?: PaymentOrderAdditionalInfoDto): void;
}

export interface StatePaymentProps {
  sendOnSign(createPaymentResultData: CreatePaymentOrderResultDto): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const StatePayment: React.FC<StatePaymentProps> = ({ sendOnSign, onChange }) => {
  const { permissions, userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [elsePersonCheck, setElsePersonCheck] = useState<boolean>(false);
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [notEnoughBalance, setNotEnoughBalance] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<StatePaymentFormDefaultValues>({});
  const elsePersonCheckHandler = (e: React.ChangeEvent<{}>, checked: boolean) => {
    setElsePersonCheck(checked);
  };
  const [senderAccountErrorSeverity, setSenderAccountErrorSeverity] = useState<Severity>();
  const [blockAction, setBlockAction] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: senderAccountsData, mutate: mutateAccounts } = useGetCurrentAccountsByCurrency();
  const { data: peopleToSigne } = useGetUahPaymentSigners();
  const { data: taxesList, mutate: mutateTaxes } = useGetTaxesList();
  const { data: generatedOrderNumber } = useGeneratePaymentOrderNumber();

  useEffect(() => {
    mutateAccounts(CurrencyEnum.Uah);
    mutateTaxes({});
  }, [mutateAccounts, mutateTaxes]);

  const { clientProfile } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const methods = useForm<StatePaymentFormData>({
    resolver: yupResolver(validationSchema({ t })),
    mode: 'onChange',
    defaultValues: {
      [FormFieldName.recipientNonLeiFlag]: false,
    },
  });
  const { formState, setValue, trigger, getValues, control, watch, setError, clearErrors } = methods;
  const { dirtyFields } = formState;
  const amount = useWatch({ control, name: FormFieldName.amount, defaultValue: '0' });
  const paymentInfo = useWatch({ control, name: FormFieldName.paymentInfo, defaultValue: '' });
  const paymentCode = useWatch({ control, name: FormFieldName.paymentCode });

  const watchRecipientAccount = useWatch({
    control,
    name: FormFieldName.recipientAccount,
  });
  const watchSenderAccount = useWatch({
    control,
    name: FormFieldName.senderAccount,
  });

  const watchRecipientName = useWatch({ control, name: FormFieldName.recipientName });

  useEffect(() => {
    if (Object.keys(dirtyFields).length || watchRecipientName || watchRecipientAccount) {
      onChange({ formDirty: true });
    }
  }, [dirtyFields, onChange, watchRecipientAccount, watchRecipientName]);

  const watchBalance = useWatch({ control, name: [`${FormFieldName.senderAccount}`, `${FormFieldName.amount}`] });

  useEffect(() => {
    const [watchBalanceSenderAccount, watchBalanceAmount] = watchBalance;
    if (watchBalanceSenderAccount && watchBalanceAmount) {
      const {
        account: { amount: selectedAccountAmount },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      const watchBalanceNotEmpty = selectedAccountAmount && watchBalanceAmount;
      if (watchBalanceNotEmpty) {
        setBalance(selectedAccountAmount);
        setShowBalance(true);
      }
    }
  }, [watchBalance]);

  const countedBalance = formatAmount(balance - Number(amount));

  useEffect(() => {
    const purpose = paymentCode?.split(',')[0].replace('paymentReference', paymentInfo);
    setValue(FormFieldName.paymentReference, purpose);
  }, [paymentCode, paymentInfo, setValue]);

  const { data: createPaymentResultData, mutateAsync, reset: resetCreatePayment } = useCreateStatePayment();

  const {
    data: sendToSignPaymentResultData,
    mutate: mutateSendToSign,
    isLoading: isSendToSignLoading,
  } = useSendToSignPaymentOrder();

  const createPayment = ({ paymentData, onCreateSuccess, onCreateError }: CreatePaymentProps) => {
    mutateAsync({ order: makeStatePaymentData(paymentData) })
      .then((result) => {
        if (result.operation) {
          onCreateSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onCreateError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t('save_payment_error'),
            severity: 'error',
          },
        });
        onCreateError();
      });
  };

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        showLoader(true);
        createPayment({
          paymentData: getValues(),
          onCreateSuccess(operation: CreatePaymentOrderResultDto) {
            notify({
              notifyProps: {
                title: t('payment_save_success'),
                message: t('save_payment_success'),
                severity: 'success',
              },
            });
            showLoader(false);
            navigate(-1);
          },
          onCreateError() {
            showLoader(false);
          },
        });
      }
    });
  };

  const onSaveAndSignClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        showLoader(true);
        createPayment({
          paymentData: getValues(),
          onCreateSuccess(operation: CreatePaymentOrderResultDto) {
            notify({
              notifyProps: {
                title: t('payment_save_success'),
                message: t('save_payment_success'),
                severity: 'success',
              },
            });
            showLoader(false);
            resetCreatePayment();
            sendOnSign(operation);
          },
          onCreateError() {
            showLoader(false);
          },
        });
      }
    });
  };

  const onSaveAndSendToSignClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        showLoader(true);
        createPayment({
          paymentData: getValues(),
          onCreateSuccess(operation: CreatePaymentOrderResultDto) {
            mutateSendToSign(operation.uuid);
            resetCreatePayment();
          },
          onCreateError() {
            showLoader(false);
          },
        });
      }
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.operations);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  useEffect(() => {
    showLoader(isSendToSignLoading);
  }, [isSendToSignLoading, showLoader]);

  const onCancelHandler = () => {
    setLeavePopupOpen(true);
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(-1);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  const onTemplateChangeHandler = (templateData: DocumentTemplateDto) => {
    setDefaultValues({
      documentNumber: generatedOrderNumber,
      amount: templateData.amount,
      paymentReference: templateData.paymentReference,
      recipientNonLeiFlag: templateData.recipientAccount.nonLeiFlag,
      recipientAccount: templateData.recipientAccount.accountNumber,
      recipientBankName: templateData.recipientAccount.bankName,
      recipientName: templateData.recipientAccount.name,
      senderAccount: templateData.senderAccount.accountNumber,
      recipientLei: templateData.recipientAccount.LEI,
    });
  };

  useEffect(() => {
    if (defaultValues?.paymentReference) {
      setValue(FormFieldName.paymentReference, defaultValues.paymentReference);
    }
    if (defaultValues?.recipientBankName) {
      setValue(FormFieldName.recipientBankName, defaultValues.recipientBankName);
    }
    if (defaultValues?.documentNumber) {
      setValue(FormFieldName.documentNumber, defaultValues.documentNumber);
    }
    if (defaultValues?.amount) {
      setValue(FormFieldName.amount, `${defaultValues.amount}`);
    }
    if (defaultValues?.recipientName) {
      setValue(FormFieldName.recipientName, defaultValues.recipientName);
    }
    if (defaultValues?.recipientAccount) {
      setValue(FormFieldName.recipientAccount, defaultValues.recipientAccount);
    }
    if (defaultValues?.recipientLei) {
      setValue(FormFieldName.recipientLei, defaultValues.recipientLei);
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (senderAccountsData && defaultValues?.senderAccount) {
      const activeAccount = senderAccountsData.accounts.find(
        ({ account }) => account.accountNumber === defaultValues.senderAccount
      );
      if (activeAccount) {
        setValue(FormFieldName.senderAccount, JSON.stringify(activeAccount), {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    }
  }, [senderAccountsData, defaultValues, setValue]);

  const watchAmount = watch(FormFieldName.amount);
  const watchAccount = watch(FormFieldName.senderAccount);
  useEffect(() => {
    if (watchAmount && watchAccount) {
      const selectedAccount: GetAccountResponseDto = parseJSON(watchAccount) as unknown as GetAccountResponseDto;
      const currentAmount = parseFloat(watchAmount);
      setNotEnoughBalance(
        !selectedAccount.account.allowRedSaldo &&
          currentAmount > 0 &&
          currentAmount > Math.abs(selectedAccount.account.amount)
      );
    }
  }, [watchAmount, watchAccount]);

  const createTemplateData = () => {
    return makeCreateTemplateData(getValues());
  };

  useEffect(() => {
    if (senderAccountsData && senderAccountsData.defaultAccount) {
      setValue(FormFieldName.senderAccount, JSON.stringify(senderAccountsData.defaultAccount), {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [senderAccountsData, setValue]);

  useEffect(() => {
    if (watchSenderAccount) {
      const selectedAccount: GetAccountResponseDto = parseJSON(watchSenderAccount) as unknown as GetAccountResponseDto;
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Blocked
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningBlockedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Closed
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningClosedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (selectedAccount.account.status === AccountStatusEnum.Active) {
        clearErrors(FormFieldName.senderAccount);
        setBlockAction(false);
      }
    }
  }, [clearErrors, senderAccountsData, setError, t, watchSenderAccount]);

  const Component = isMobile ? MobileStatePaymentForm : DesktopStatePaymentForm;

  return (
    <>
      <Box p={isMobile ? 0 : 3} width="100%">
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item container xs={isMobile ? false : 9}>
            <FormProvider {...methods}>
              <form style={styles.form}>
                <Component
                  defaultValues={defaultValues}
                  senderAccountsData={senderAccountsData}
                  showBalance={showBalance}
                  countedBalance={countedBalance}
                  permissions={permissions}
                  notEnoughBalance={notEnoughBalance}
                  elsePersonCheck={elsePersonCheck}
                  onCancelHandler={onCancelHandler}
                  onSaveClickHandler={onSaveClickHandler}
                  onSaveAndSignClickHandler={onSaveAndSignClickHandler}
                  onSendToSign={onSaveAndSendToSignClickHandler}
                  elsePersonCheckHandler={elsePersonCheckHandler}
                  generatedOrderNumber={generatedOrderNumber}
                  taxesList={taxesList}
                  additionalInfo={createPaymentResultData?.additionalInfo}
                  createTemplateData={createTemplateData}
                  onTemplateChangeHandler={onTemplateChangeHandler}
                  errorSeverity={senderAccountErrorSeverity}
                  blockAction={blockAction}
                />
              </form>
            </FormProvider>
          </Grid>
          {!isMobile && userProfile ? (
            <Box sx={{ width: '220px' }}>
              <Box pl={2}>
                <SelectPaymentTemplate
                  templateType={PaymentTemplateTypeEnum.Budgetary}
                  onTemplateChange={onTemplateChangeHandler}
                  placeholder={t('enterName_placeholder')}
                />
                <Box pt={10}>
                  <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Box>
      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

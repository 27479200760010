import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { ArrowRightSmallIcon, ErrorSmallIcon } from '@app/common/icons';
import { AccountStatusEnum } from '@app/core/api';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { IBanText } from '@app/core/components/IBanText';
import { useScreenOrientation } from '@app/core/hooks';
import { OperationsTypeEnum } from '@app/core/types';
import { formatAmount, isMobile } from '@app/core/utils';
import { styles } from '@app/pages/dashboard/view/PageContent/AccountBalances/BalanceItem/styles';
import { RouteList } from '@app/src/constants/routeList';

type LastOperation = {
  date?: string;
  amount: number;
  currency: string;
  type: OperationsTypeEnum;
};

type BalanceItemProps = {
  id: string;
  amount: number;
  currency: string;
  accountNumber: string;
  accountName: string;
  lastOperation: LastOperation | null;
  status: AccountStatusEnum;
};

export const BalanceItem: React.FC<BalanceItemProps> = ({
  accountNumber,
  accountName,
  amount,
  currency,
  id,
  lastOperation,
  status,
}) => {
  const { isPortrait } = useScreenOrientation();
  const date =
    lastOperation && lastOperation.date ? format(new Date(lastOperation.date.split('T')[0]), 'dd.MM.yyyy') : null;

  const isMobilePortrait = isMobile && isPortrait;

  const LastOperation: React.FC = () => {
    return (
      <>
        {lastOperation ? (
          <>
            {lastOperation.type === OperationsTypeEnum.withdrawal ? (
              <Typography variant="body2" sx={styles.creditText}>
                <b>-</b> {formatAmount(lastOperation.amount)}
              </Typography>
            ) : (
              <Typography variant="body2" sx={styles.debitText}>
                <b>+</b> {formatAmount(lastOperation.amount)}
              </Typography>
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Grid
        component={Link}
        to={`${RouteList.accounts}/${id}`}
        state={{ accountName }}
        container
        sx={{ ...styles.root, ...(status === AccountStatusEnum.Blocked && { ...styles.blocked }) }}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={isMobilePortrait ? 7 : 5}>
          <Grid container alignItems="center" wrap="nowrap">
            {status === AccountStatusEnum.Blocked ? <ErrorSmallIcon fontSize="small" sx={styles.errorIcon} /> : null}
            <Ellipsis
              text={accountName}
              styles={{
                color: 'colors.primary600',
                fontWeight: 600,
              }}
            />
          </Grid>
          <Typography variant={isMobilePortrait ? 'body2' : 'caption'} component="div" display="block">
            <IBanText text={accountNumber} />
          </Typography>
        </Grid>
        {!isMobilePortrait ? (
          <Grid item xs={3} sx={styles.debitCreditContainer}>
            <>
              <LastOperation />
              <Typography variant="caption" color="textSecondary">
                {date}
              </Typography>
            </>
          </Grid>
        ) : null}
        <Grid
          item
          container
          xs={isMobilePortrait ? 5 : 4}
          alignItems={isMobilePortrait ? 'flex-end' : 'center'}
          direction={isMobilePortrait ? 'column' : 'row'}
          justifyContent="flex-end"
          sx={styles.amountCurrencyContainer}
        >
          <Typography variant="body1" sx={styles.amountText}>
            {formatAmount(amount)}{' '}
            <Typography variant="body2" component="span">
              {currency}
            </Typography>
          </Typography>
          {isMobilePortrait ? (
            <LastOperation />
          ) : (
            <Box component="span" sx={styles.detailIconWrapper}>
              <ArrowRightSmallIcon sx={styles.arrowRight} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const styles = {
  flexbox: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'colors.primary500',
  },
  label: {
    color: 'colors.primary500',
    marginRight: '4px',
  },
  from: {
    marginRight: '16px',
    width: '125px',
  },
  to: {
    marginRight: '26px',
    width: '125px',
  },
  button: {
    padding: 0,
  },
  popoverOperationsFile: {
    background: 'none',
    boxShadow: 'none',
  },
  popoverOperationsFileContent: {
    border: `1px solid`,
    borderColor: 'colors.gray100',
    borderRadius: '8px',
    width: 92,
    backgroundColor: 'white',
  },
  popoverOperationsFileItem: {
    borderBottom: `1px solid`,
    borderColor: 'colors.gray100',
    padding: `8px 12px`,
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-start',
    color: 'colors.gray600',
    '&:last-child': {
      border: 'none',
    },
  },
  popoverOperationsFileItemIcon: {
    verticalAlign: 'bottom',
    color: 'colors.gray500',
    marginRight: '8px',
  },
};

import React from 'react';

import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNotify } from '@app/context/NotifyContext';
import { ModalPopupCommon } from '@app/core/components/Modal/ModalPopupCommon';
import { useDeleteCounterparty } from '@app/core/hooks/useDeleteCounterparty';
import { useStyles } from '@app/src/pages/dashboard/view/PageContent/AccountBalances/AccountBalancesModal/styles';
import { ReactComponent as CloseIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';

export interface DeleteCounterpartyModalProps {
  counterpartyId: number;
  onClose(): void;
  onSuccessDelete(): void;
  onErrorDelete(): void;
}

export const DeleteCounterpartyModal: React.FC<DeleteCounterpartyModalProps> = ({
  counterpartyId,
  onClose,
  onSuccessDelete,
  onErrorDelete,
}) => {
  const { mutate } = useDeleteCounterparty();
  const { notify } = useNotify();
  const { t } = useTranslation();

  const onSubmit = () => {
    mutate(counterpartyId, {
      onSuccess: () => {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('delete_counterparty_success_message'),
            severity: 'success',
          },
        });
        onSuccessDelete();
      },
      onError: () => {
        notify({
          notifyProps: {
            title: t('error'),
            message: t('delete_counterparty_error_message'),
            severity: 'error',
          },
        });
        onErrorDelete();
      },
    });
  };

  const classes = useStyles();

  return (
    <ModalPopupCommon open questionPaper>
      <>
        <Grid justifyContent="flex-end" container>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography component="div" variant="body1" sx={classes.captionSaveModal}>
          {t('deleteCounterpartyModalTitle')}
        </Typography>
        <Grid justifyContent="center" container>
          <Button variant="outlined" size="medium" color="primary" sx={classes.buttonSaveModal} onClick={onClose}>
            {t('no')}
          </Button>
          <Button variant="contained" size="medium" color="primary" sx={classes.buttonSaveModal} onClick={onSubmit}>
            {t('yes')}
          </Button>
        </Grid>
      </>
    </ModalPopupCommon>
  );
};

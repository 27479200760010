import { PeriodType } from '@app/core/api';

export const getTranslKeyForPeriodType = (period: PeriodType): string => {
  switch (period) {
    case PeriodType.Daily:
      return 'currentDay';
    case PeriodType.Monthly:
      return 'currentMonth';
    case PeriodType.Weekly:
      return 'currentWeek';
    case PeriodType.Yearly:
    default:
      return 'currentYear';
  }
};

import React, { Suspense, useState, lazy } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GetAccountResponseDto } from '@app/core/api';
import { MultipleDateSelect } from '@app/core/components/DateSelect';
import { SelectedAccountsTabEnum } from '@app/core/components/DateSelect/types';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { MainLoader } from '@app/core/components/MainLoader';
import { OperationsTable } from '@app/core/components/OperationsTable';
import { DateRange } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { MobileAccountInfo } from '@app/pages/accounts/components/MobileAccountInfo';
import { AccountInfo } from '@app/pages/accounts/selectedAccount/PageContent/AccountInfo';
import { AccountInfoLoader } from '@app/pages/accounts/selectedAccount/PageContent/AccountInfo/AccountInfoLoader';
import { styles } from '@app/pages/accounts/selectedAccount/PageContent/style';
import { Turnovers } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers';

const AnalyticsComponent = lazy(() => import('@app/pages/accounts/selectedAccount/PageContent/Analytics'));

interface PageContentProps {
  account?: GetAccountResponseDto;
}

export const PageContent: React.FC<PageContentProps> = ({ account }) => {
  const { t } = useTranslation();
  const [disableButtons, setDisableButtons] = useState<boolean>(true);
  const accountCurrency = account?.account.currency || '';

  const [activeTab, setActiveTab] = useState<SelectedAccountsTabEnum>(SelectedAccountsTabEnum.operations);

  const handleChange = (currentTab: SelectedAccountsTabEnum): void => {
    setActiveTab(currentTab);
  };

  const newPaymentTabsItems: MenuItem<SelectedAccountsTabEnum>[] = [
    {
      id: 1,
      labelText: t('selectedAccount_operations'),
      value: SelectedAccountsTabEnum.operations,
    },
    {
      id: 2,
      labelText: t('selectedAccount_turnovers'),
      value: SelectedAccountsTabEnum.turnovers,
    },
    {
      id: 3,
      labelText: t('selectedAccount_analytics'),
      value: SelectedAccountsTabEnum.analytics,
    },
  ];

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);

  const periodOnChange = (newDateRange: DateRange | undefined) => {
    setDateRange(newDateRange);
  };

  const onDateRangeSelectedHandler = () => {
    setDisableButtons(false);
  };

  const onResetHandler = () => {
    setDisableButtons(true);
  };

  const Component = isMobile ? MobileAccountInfo : AccountInfo;

  return (
    <>
      <Box sx={styles.accountInfo} mt={8}>
        {account ? <Component account={account} /> : <AccountInfoLoader />}
      </Box>
      <Box mt={10} sx={styles.accountInfo}>
        <Grid container justifyContent="space-between">
          <Box pl={3}>
            <HorizontalMenu<SelectedAccountsTabEnum> onChange={handleChange} menuItems={newPaymentTabsItems} />
          </Box>
          <MultipleDateSelect
            onChange={periodOnChange}
            accounts={account ? [account] : undefined}
            activeTab={activeTab}
          />
        </Grid>
        <Box p={3}>
          <Grid container direction="column" wrap="nowrap">
            {activeTab === SelectedAccountsTabEnum.operations && account && (
              <OperationsTable accounts={account ? [account] : undefined} dateRange={dateRange} />
            )}
            {activeTab === SelectedAccountsTabEnum.turnovers && account && dateRange ? (
              <Turnovers account={account} dateRange={dateRange} />
            ) : (
              <>
                {activeTab === SelectedAccountsTabEnum.turnovers ? (
                  <Grid container alignItems="center" justifyContent="center" sx={styles.emptyDataMessage}>
                    <Typography variant="body2">{t('empty_date_filter')}</Typography>
                  </Grid>
                ) : null}
              </>
            )}
            {activeTab === SelectedAccountsTabEnum.analytics && account && dateRange ? (
              <Suspense fallback={<MainLoader />}>
                <AnalyticsComponent
                  accountId={account.account.id}
                  dateRange={dateRange}
                  accountCurrency={accountCurrency}
                />
              </Suspense>
            ) : (
              <>
                {activeTab === SelectedAccountsTabEnum.analytics ? (
                  <Grid container alignItems="center" justifyContent="center" sx={styles.emptyDataMessage}>
                    <Typography variant="body2">{t('empty_date_filter')}</Typography>
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

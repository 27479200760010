import { SxProps, Theme } from '@mui/material';
import { Header, Cell, ColumnOrderState } from '@tanstack/react-table';

import { TableColumnConfig } from '@app/core/types';

type HeaderHelperOptionsType = {
  header: Header<any, unknown>;
  styles: { [name: string]: SxProps<Theme> };
};

type HeaderHelperPropsResult = {
  sx: SxProps<Theme>;
  className: string;
};

export const headerCellProps = ({ header, styles }: HeaderHelperOptionsType): HeaderHelperPropsResult => ({
  sx: {
    ...(styles['cell'] && styles['cell']),
    ...(styles[`cell_${header.id}`] && styles[`cell_${header.id}`]),
  } as SxProps<Theme>,
  className: 'header',
});

type BodyCellHelperOptionsType = {
  cell: Cell<any, unknown>;
  styles: { [name: string]: SxProps<Theme> };
};

type BodyCellPropsResult = {
  sx: SxProps<Theme>;
  className: string;
};
export const bodyCellProps = ({ cell, styles }: BodyCellHelperOptionsType): BodyCellPropsResult => ({
  sx: {
    ...(styles['cell'] && styles['cell']),
    ...(styles[`cell_${cell.column.id}`] && styles[`cell_${cell.column.id}`]),
    ...(cell.getContext().table.options.meta?.columnFilterSate?.columnFilter[cell.column.id] && {
      backgroundColor: 'colors.primary25',
    }),
  } as SxProps<Theme>,
  className: 'body',
});

export const getColumnVisibility = (columnsConfig: TableColumnConfig[]): Record<string, boolean> =>
  columnsConfig.reduce((acc: Record<string, boolean>, confItem) => {
    acc[confItem.columnType] = confItem.enabled;
    return acc;
  }, {});

export const getEnabledColumns = (columnsConfig: TableColumnConfig[]): string[] =>
  columnsConfig.reduce((acc: string[], confItem) => {
    if (confItem.enabled) {
      acc.push(confItem.columnType);
    }
    return acc;
  }, [] as string[]);

export const configTableComparator = (a: TableColumnConfig, b: TableColumnConfig) => {
  return a.order - b.order;
};

export const getColumOrder = (config: TableColumnConfig[]): ColumnOrderState => {
  return ['selection', ...[...config].sort(configTableComparator).map((conf) => conf.columnType), 'actions'];
};

import {
  getClientId,
  loansTranchesApiApiClient,
  ApiError,
  GetLoansListRequestDto,
  GetLoansListResponseDto,
} from '@app/core/api';
import { TableColumnConfig } from '@app/core/types';
import { fieldsCreditTable } from '@app/pages/loans/tableFields';

export const getLoansFn = async (getLoansListRequestDto: GetLoansListRequestDto): Promise<GetLoansListResponseDto> => {
  const result = await loansTranchesApiApiClient.loansControllerGetList(getClientId(), getLoansListRequestDto);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error loansControllerGetList!');
  }
  return result.data;
};

export const loansControllerUpdateFn = async (
  loansTableColumnsData: TableColumnConfig[]
): Promise<TableColumnConfig[]> => {
  return fieldsCreditTable;
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UmcaPopup } from '@app/common/components/UmcaPopup';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  DocumentOperationType,
  DocumentStatusEnum,
  GetDocumentWithAvailableActionsDto,
  GetDocumentsResponseDto,
  OperationsWithoutFilesDto,
} from '@app/core/api';
import { ConfirmEditOperationPopup } from '@app/core/components';
import { ConfirmSignDocuments } from '@app/core/components/ConfirmSignDocuments';
import { SignCurrencyDocumentsDialog } from '@app/core/components/SignCurrencyDocumentsDialog';
import { DEFAULT_FIRST_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useUmca } from '@app/core/hooks';
import { FetchStatus, PaginationChangeType } from '@app/core/types';
import { AddCurrencyDocumentModal } from '@app/pages/documents/components/AddCurrencyDocumentModal';
import { DeleteSingleCurrencyDocumentModal } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/DeleteSingleCurrencyDocumentModal';
import {
  getRelateCurrencyOperationsQueryFn,
  getRelateSwiftOperationsQueryFn,
} from '@app/pages/documents/components/DeleteCurrencyDocumentModal/query';
import { DocumentsTable } from '@app/pages/documents/components/DocumentsTable';
import { getCurrencyDocumentsQueryFn } from '@app/pages/documents/DocumentsList/query';
import { styles } from '@app/pages/documents/DocumentsList/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const DocumentsPage: React.FC = () => {
  const { permissions, fetchStatus } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const [selectedDocumentFiles, setSelectedDocumentFiles] = useState<GetDocumentWithAvailableActionsDto[]>();
  const [editDocumentFiles, setEditDocumentFiles] = useState<GetDocumentWithAvailableActionsDto>();
  const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<GetDocumentWithAvailableActionsDto>();
  const [documentToDeleteRelateOperations, setDocumentToDeleteRelateOperations] = useState<OperationsWithoutFilesDto[]>(
    []
  );
  const [showConfirmSignDocumentsDialog, setShowConfirmSignDocumentsDialog] = useState<boolean>(false);
  const [showSignCurrencyDocumentsDialog, setShowSignCurrencyDocumentsDialog] = useState<boolean>(false);
  const [showUmcaPopup, setShowUmcaPopup] = useState<boolean>(false);
  const [relateOperationsLoaded, setRelateOperationsLoaded] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const { data, refetch, remove } = useQuery<GetDocumentsResponseDto>(
    'getCurrencyDocuments',
    () => getCurrencyDocumentsQueryFn({ pagination: paginationRef.current }),
    {
      enabled: true,
      refetchOnMount: true,
    }
  );

  const { mutateAsync: mutateAsyncRelateCurrencyOperations } = useMutation<
    OperationsWithoutFilesDto[],
    ApiError,
    Array<string>
  >(getRelateCurrencyOperationsQueryFn);

  const { mutateAsync: mutateAsyncRelateSwiftOperations } = useMutation<
    OperationsWithoutFilesDto[],
    ApiError,
    Array<string>
  >(getRelateSwiftOperationsQueryFn);

  const { installed: umcaInstalled, isError: umcaError, errorMessage: umcaErrorMessage } = useUmca();

  useEffect(() => {
    if (documentToDelete) {
      const currencyOperationsUuids = documentToDelete.relateOperations
        .filter((operation) => operation.operationType === DocumentOperationType.CurrencyOperation)
        .map((operation) => operation.currencyOperationId);

      const swiftOperationsUuids = documentToDelete.relateOperations
        .filter((operation) => operation.operationType === DocumentOperationType.Swift)
        .map((operation) => operation.currencyOperationId);
      showLoader(true);
      setRelateOperationsLoaded(false);

      const relateCurrencyOperationsPromise = new Promise<OperationsWithoutFilesDto[]>((resolve, reject) => {
        if (currencyOperationsUuids.length) {
          mutateAsyncRelateCurrencyOperations(currencyOperationsUuids)
            .then((relateCurrencyOperations) => {
              resolve(relateCurrencyOperations);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve([]);
        }
      });

      const relateSwiftOperationsPromise = new Promise<OperationsWithoutFilesDto[]>((resolve, reject) => {
        if (swiftOperationsUuids.length) {
          mutateAsyncRelateSwiftOperations(swiftOperationsUuids)
            .then((relateSwiftOperations) => {
              resolve(relateSwiftOperations);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve([]);
        }
      });

      Promise.all([relateCurrencyOperationsPromise, relateSwiftOperationsPromise])
        .then(([relateCurrencyOperations, relateSwiftOperations]) => {
          const relateOperations: OperationsWithoutFilesDto[] = [
            ...(relateCurrencyOperations || []),
            ...(relateSwiftOperations || []),
          ];
          setDocumentToDeleteRelateOperations(relateOperations);
          setRelateOperationsLoaded(true);
        })
        .finally(() => {
          showLoader(false);
        });
    }
  }, [documentToDelete, mutateAsyncRelateCurrencyOperations, mutateAsyncRelateSwiftOperations, showLoader]);

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    refetch();
  };

  const onDeleteDocumentHandler = (document: GetDocumentWithAvailableActionsDto) => {
    setDocumentToDelete(document);
  };
  const closeDeleteDocumentModal = () => {
    setDocumentToDelete(undefined);
  };
  const addDocumentHandler = () => {
    setShowAddDocumentModal(true);
  };

  const closeAddDocumentModal = () => {
    setShowAddDocumentModal(false);
  };

  const onDeleteDocumentSuccess = () => {
    setDocumentToDelete(undefined);
    refetch();
  };

  const onSuccessImportDocument = () => {
    setShowAddDocumentModal(false);
    refetch();
  };

  const onSignHandler = (selectedFiles: GetDocumentWithAvailableActionsDto[]) => {
    if (umcaInstalled) {
      setSelectedDocumentFiles(selectedFiles);
      setShowConfirmSignDocumentsDialog(true);
    } else {
      if (umcaError) {
        setShowUmcaPopup(true);
      }
    }
  };

  const onAgreeSignHandler = () => {
    setShowConfirmSignDocumentsDialog(false);
    setShowSignCurrencyDocumentsDialog(true);
  };

  const onCloseSignDocumentsHandler = () => {
    setSelectedDocumentFiles(undefined);
    setShowConfirmSignDocumentsDialog(false);
  };

  const onCloseSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
  };

  const onSuccessSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
    setSelectedDocumentFiles(undefined);
    remove();
    refetch();
  };

  const onSendToSignSuccessHandler = () => {
    refetch();
  };

  const toSignDocuments = selectedDocumentFiles?.filter(
    (item) => item.status === DocumentStatusEnum.Saved || item.status === DocumentStatusEnum.ToSign
  );

  const onEditDocumentHandler = (document: GetDocumentWithAvailableActionsDto) => {
    if (document.signed) {
      setShowConfirmEditPopup(true);
      setEditDocumentFiles(document);
    } else {
      navigate(RouteList.documents_edit.replace(':uuid', document.uuid));
    }
  };

  const closeUmcaPopup = () => {
    setShowUmcaPopup(false);
  };

  const onMultipleDeleteDocumentSuccess = () => {
    refetch();
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    if (editDocumentFiles) {
      navigate(RouteList.documents_edit.replace(':uuid', editDocumentFiles.uuid));
    }
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  return (
    <>
      <PageWrapper>
        <PageHeader title={t('documents')} tooltip={t('currencyDocuments_headerTooltip')} useDesktopStyle>
          {permissions.documents.upload ? (
            <Button variant="contained" color="primary" onClick={addDocumentHandler}>
              {t('downloadDocument')}
            </Button>
          ) : null}
        </PageHeader>
        <PageContainer>
          <Box mt={4} sx={styles.fullWidth}>
            {data ? (
              <>
                {data?.documents.length && fetchStatus === FetchStatus.success ? (
                  <DocumentsTable
                    onDeleteDocument={onDeleteDocumentHandler}
                    documents={data.documents}
                    onPaginationChange={onPaginationChangeHandler}
                    pagination={data.pagination}
                    onSign={onSignHandler}
                    onSendToSignSuccess={onSendToSignSuccessHandler}
                    onEditDocument={onEditDocumentHandler}
                    onMultipleDeleteDocumentSuccess={onMultipleDeleteDocumentSuccess}
                    permissions={permissions}
                  />
                ) : (
                  <Grid container alignItems="flex-start" justifyContent="center" sx={styles.emptyRow}>
                    <Typography variant="body2">{t('empty_currency_documents')}</Typography>
                  </Grid>
                )}
              </>
            ) : null}
          </Box>
        </PageContainer>
      </PageWrapper>
      {documentToDelete && relateOperationsLoaded ? (
        <DeleteSingleCurrencyDocumentModal
          document={documentToDelete}
          relateOperations={documentToDeleteRelateOperations}
          onClose={closeDeleteDocumentModal}
          onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        />
      ) : null}
      {showAddDocumentModal ? (
        <AddCurrencyDocumentModal onClose={closeAddDocumentModal} onSuccessImportDocument={onSuccessImportDocument} />
      ) : null}
      {showConfirmSignDocumentsDialog && selectedDocumentFiles && toSignDocuments ? (
        <ConfirmSignDocuments
          onAgreeSign={onAgreeSignHandler}
          onClose={onCloseSignDocumentsHandler}
          selectedDocumentsFilesCount={selectedDocumentFiles.length}
          toSignDocuments={toSignDocuments}
        />
      ) : null}

      {showSignCurrencyDocumentsDialog && toSignDocuments ? (
        <SignCurrencyDocumentsDialog
          toSignDocuments={toSignDocuments}
          onClose={onCloseSignCurrencyDocumentsDialog}
          onSuccessPopupCancel={onSuccessSignCurrencyDocumentsDialog}
        />
      ) : null}
      {showUmcaPopup ? <UmcaPopup errorMessage={t(umcaErrorMessage)} onClose={closeUmcaPopup} /> : null}
      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { DeleteIcon, EditIcon } from '@app/common/icons';
import { GetSwiftResponseDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { IBanText } from '@app/core/components/IBanText';
import { styles } from '@app/pages/counterparties/components/CounterpartiesTable/components/styles';
import { SwiftCounterpartiesColumnsEnum } from '@app/pages/counterpartiesSwift/types';

interface MakeTableColumnsProps {
  t(text: string): string;
  handleShow(id: string): void;
  deleteCounterpartyModal(id: string): void;
}

export const makeTableColumns = ({
  t,
  handleShow,
  deleteCounterpartyModal,
}: MakeTableColumnsProps): ColumnDef<GetSwiftResponseDto, any>[] => {
  return [
    {
      accessorKey: SwiftCounterpartiesColumnsEnum.name,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}>
              <Typography variant="caption">{t('swiftCounterpartiesName')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetSwiftResponseDto, string>) => {
        return <Typography variant="body2">{getValue()}</Typography>;
      },
    },
    {
      accessorKey: SwiftCounterpartiesColumnsEnum.country,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}>
              <Typography variant="caption">{t('country')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue, row }: CellContext<GetSwiftResponseDto, string>) => {
        return <Typography variant="body2">{row.original.country?.name}</Typography>;
      },
    },
    {
      accessorKey: SwiftCounterpartiesColumnsEnum.bic,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('swiftBic')}</Typography>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: SwiftCounterpartiesColumnsEnum.account,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper
                {...props}
                tableSortLabelProps={{ sx: { 'MuiTableSortLabel-icon': styles.sortIcon } }}
              >
                <Typography variant="caption">{t('account')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<GetSwiftResponseDto, string>) => {
        const accountNumber = getValue();
        return accountNumber.length === 29 ? (
          <Typography variant="body2" component="div">
            <IBanText text={accountNumber} />
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ color: 'colors.gray400' }}>
            {accountNumber}
          </Typography>
        );
      },
    },
    {
      accessorKey: SwiftCounterpartiesColumnsEnum.address,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('address')}</Typography>
          </div>
        </Grid>
      ),
    },
    {
      id: 'edit_action',
      cell: ({ row }: CellContext<GetSwiftResponseDto, string>) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <IconButton size="small" onClick={() => handleShow(row.original.uuid)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Grid>
      ),
    },
    {
      id: 'delete_action',
      cell: ({ row }: CellContext<GetSwiftResponseDto, string>) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <IconButton size="small" onClick={() => deleteCounterpartyModal(row.original.uuid)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      ),
    },
  ];
};

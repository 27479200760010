import React, { useEffect, useState } from 'react';

import { Toolbar } from '@mui/material';

import { RowsPerPage } from './RowsPerPage';
import { TablePageInput } from './TablePageInput';
import { PaginationChangeType } from '../../../../types';

export interface TablePaginationProps {
  totalCount: number;
  page: number;
  labelRowsPerPage: React.ReactNode;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  show?: boolean;
  onChange(pagination: PaginationChangeType): void;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
  totalCount,
  page,
  labelRowsPerPage,
  rowsPerPage,
  rowsPerPageOptions,
  show = true,
  onChange,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(rowsPerPage);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    onChange({ rowsPerPage: selectedRowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = (rows: number) => {
    setSelectedRowsPerPage(rows);
    setCurrentPage(1);
    onChange({ rowsPerPage: rows, page: 1 });
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setSelectedRowsPerPage(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <>
      {show ? (
        <Toolbar
          disableGutters
          sx={{
            width: '100%',
            justifyItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <RowsPerPage
            selectedRowsPerPage={selectedRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onOptionSelect={handleChangeRowsPerPage}
          />
          <TablePageInput
            totalCount={totalCount}
            page={currentPage}
            rowsPerPage={selectedRowsPerPage}
            onChangePage={handleChangePage}
          />
        </Toolbar>
      ) : null}
    </>
  );
};

export const styles = {
  title: {
    textAlign: 'center',
    fontWeight: 600,
  },
  text: {
    color: 'colors.gray600',
    textAlign: 'center',
  },
  item: {
    padding: '20px 0 0',
  },
  link: {
    color: 'colors.primary600',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  icon: {
    color: 'colors.primary600',
  },
  alertContainer: {
    width: '100%',
    height: 'auto',
  },
};

import { CreateCurrencyOperationDto, CurrencyOperationsExchangeTypeEnum, GetAccountResponseDto } from '@app/core/api';
import { CurrencyFormData } from '@app/pages/exchange/components/form/types';

export const makeCurrencyOperationFromFormData = (formData: CurrencyFormData): CreateCurrencyOperationDto => {
  const {
    documentNumber,
    documentDate,
    documentValueDate,
    withdrawalAccount: withdrawalAccountStr,
    depositAccount: depositAccountStr,
    amount,
    exchangeRateType,
    exchangeRate,
    currencyOperationReason,
  } = formData;

  const withdrawalAccount: GetAccountResponseDto = JSON.parse(withdrawalAccountStr);
  const depositAccount: GetAccountResponseDto = JSON.parse(depositAccountStr);

  return {
    documentNumber,
    documentDate: documentDate.toISOString(),
    documentValueDate: documentValueDate?.toISOString(),
    withdrawalAccount: {
      id: withdrawalAccount.account.id,
      accountNumber: withdrawalAccount.account.accountNumber,
      currency: withdrawalAccount.account.currency,
    },
    depositAccount: {
      id: depositAccount.account.id,
      accountNumber: depositAccount.account.accountNumber,
      currency: depositAccount.account.currency,
    },
    amount: parseFloat(amount),
    exchangeRateType,
    exchangeRate: exchangeRate ? Number(exchangeRate) : undefined,
    currentAccountCommission: true,
    currencyOperationReason,
  };
};

export const getDocumentNumberTranslationKeyTitle = (exchangeType: CurrencyOperationsExchangeTypeEnum): string => {
  switch (exchangeType) {
    case CurrencyOperationsExchangeTypeEnum.Conversion:
      return 'currencyExchange_Conversion_orderNumberTitle';
    case CurrencyOperationsExchangeTypeEnum.Purchase:
      return 'currencyExchange_Purchase_orderNumberTitle';
    case CurrencyOperationsExchangeTypeEnum.Sale:
      return 'currencyExchange_orderNumberTitle';
  }
};

export const getTransferMethodTranslationKeyLabel = (exchangeType: CurrencyOperationsExchangeTypeEnum) => {
  switch (exchangeType) {
    case CurrencyOperationsExchangeTypeEnum.Conversion:
      return 'currencyExchange_Conversion_transferMethodLabel';
    case CurrencyOperationsExchangeTypeEnum.Purchase:
      return 'currencyExchange_Purchase_transferMethodLabel';
    case CurrencyOperationsExchangeTypeEnum.Sale:
      return 'transferMethodLabel';
  }
};

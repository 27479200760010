export const styles = {
  accountType: {
    color: 'colors.primary500',
  },
  infoContainer: {
    flexDirection: 'column',
  },
  amount: {
    fontWeight: 600,
  },
  accountName: {
    color: 'colors.gray400',
  },
  iban: {
    fontWeight: 600,
  },
};

import { ImportOrderWithValidationDto } from '@app/core/api';
import { OrderRow } from '@app/pages/payment/import/types';

export const buildImportTableRowsData = (orders: ImportOrderWithValidationDto[]): OrderRow[] => {
  return orders.map((order) => {
    return {
      ...order.data,
      currency: 'UAH',
      status: !order.errors || order.errors.length === 0,
    };
  });
};

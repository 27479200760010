import React from 'react';

import { ClientConfigurationDto } from '@app/core/api';
import { appConfig } from '@app/src/config';
import { ReactComponent as CreditIcon } from '@app/themes/default/assets/menu/credit.svg';
import { ReactComponent as DepositIcon } from '@app/themes/default/assets/menu/deposit.svg';
import { ReactComponent as FolderIcon } from '@app/themes/default/assets/menu/folder.svg';
import { ReactComponent as ListIcon } from '@app/themes/default/assets/menu/list.svg';
import { ReactComponent as MessageIcon } from '@app/themes/default/assets/menu/message.svg';
import { ReactComponent as SubMenuIcon } from '@app/themes/default/assets/menu/subMenuIcon.svg';
import { ReactComponent as UAHIcon } from '@app/themes/default/assets/menu/UAH.svg';
import { ReactComponent as USDIcon } from '@app/themes/default/assets/menu/USD.svg';
import { ReactComponent as WalletIcon } from '@app/themes/default/assets/menu/wallet.svg';

import { RouteList } from './routeList';

export type MenuListItemType = {
  id: number;
  name: string;
  to: RouteList;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  subMenu?: {
    name: string;
    to: RouteList;
    SubMenuIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  }[];
};

export const geMenuList = (clientConfig?: ClientConfigurationDto) => {
  const currencySubMenu = [
    {
      name: 'menuCurrencyExchange_exchangeTransactions',
      to: RouteList.exchange,
      SubMenuIcon,
    },
  ];
  const loansSubMenu = [
    {
      name: 'overdraft',
      to: RouteList.overdrafts,
      SubMenuIcon,
    },
    {
      name: 'loans_page',
      to: RouteList.loans,
      SubMenuIcon,
    },
    {
      name: 'loansLines',
      to: RouteList.loan_lines,
      SubMenuIcon,
    },
  ];

  if (clientConfig && clientConfig.features.swift) {
    currencySubMenu.push({
      name: 'menuSwift_swiftTransaction',
      to: RouteList.swift,
      SubMenuIcon,
    });
  }

  if (clientConfig && clientConfig.features.documents) {
    currencySubMenu.push({
      name: 'documents',
      to: RouteList.documents,
      SubMenuIcon,
    });
  }

  const menuList: MenuListItemType[] = [
    {
      id: 1,
      name: 'accountsAndTransactions',
      to: RouteList.dashboard,
      Icon: WalletIcon,
    },
    {
      id: 2,
      name: 'statements',
      to: RouteList.statements,
      Icon: ListIcon,
    },
    {
      id: 3,
      name: 'uahTransactions',
      to: RouteList.operations,
      Icon: UAHIcon,
    },
    {
      id: 7,
      name: 'directories',
      to: RouteList.directories,
      Icon: FolderIcon,
    },
  ];

  if (clientConfig && clientConfig?.features.currencyOperations) {
    menuList.push({
      id: 4,
      name: 'currencyTransactions',
      to: RouteList.exchange,
      Icon: USDIcon,
      subMenu: currencySubMenu,
    });
  }

  if (clientConfig && clientConfig?.features.deposit) {
    menuList.push({
      id: 5,
      name: 'deposits',
      to: RouteList.deposits,
      Icon: DepositIcon,
    });
  }

  if (clientConfig && clientConfig?.features.loan) {
    menuList.push({
      id: 6,
      name: 'loans',
      to: RouteList.loans,
      Icon: CreditIcon,
      subMenu: loansSubMenu,
    });
  }

  if (appConfig.enableNextFeatures) {
    menuList.push({
      id: 7,
      name: 'letters',
      to: RouteList.letters,
      Icon: MessageIcon,
    });
  }

  return menuList;
};

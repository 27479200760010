import React, { useState } from 'react';

import { Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EditIcon, DeleteIcon, UnLockUserIcon, LockUserIcon } from '@app/common/icons';
import { UserDto, UserStateStatusEnum } from '@app/core/api';
import { DetailsInfoItem } from '@app/core/components/ComplexTable/primitives';
import { DeleteUserModal } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/DeleteUserModal';
import { UsersModal } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersModal';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/styles';
import { appConfig } from '@app/src/config';

export interface UsersTableDetailsProps {
  data: UserDto;
}

export const UsersTableDetails: React.FC<UsersTableDetailsProps> = ({ data }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleEditClick = () => {
    setShowModal(!showModal);
  };

  const handleDeleteClick = () => {
    setDeleteModal(!deleteModal);
  };

  const closeWithUserModal = () => {
    setDeleteModal(!deleteModal);
    setShowModal(false);
  };

  const { t } = useTranslation();

  const { id, firstName, lastName, middleName, phoneNumber, email, status } = data;

  return (
    <Grid container alignItems="center" sx={styles.detailsTable}>
      <Grid item container alignItems="center" sx={styles.row}>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('userLastName')} value={lastName} />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('userName')} value={firstName} />
        </Grid>
        <Grid item xs={3}>
          {middleName ? <DetailsInfoItem description={t('userMiddleName')} value={middleName} /> : null}
        </Grid>
        <Grid item container xs={3} justifyContent="flex-end">
          {appConfig.hideCompanyUsersButtons ? null : (
            <>
              <IconButton size="small" onClick={handleDeleteClick} sx={styles.actionIcon}>
                <DeleteIcon />
              </IconButton>
              <IconButton size="small" onClick={handleEditClick} sx={styles.actionIcon}>
                <EditIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container alignItems="center" sx={styles.row}>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('userPhone')} value={phoneNumber} />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('userEmail')} value={email} />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          {appConfig.hideCompanyUsersButtons ? null : (
            <>
              {status === UserStateStatusEnum.Inactive ? null : (
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={status === UserStateStatusEnum.Blocked ? <UnLockUserIcon /> : <LockUserIcon />}
                  sx={styles.buttonAction}
                >
                  {status === UserStateStatusEnum.Blocked ? t('user_unblock') : t('user_block')}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <UsersModal user={data} show={showModal} handleShow={handleEditClick} showDeleteDialog={handleDeleteClick} />
      <DeleteUserModal show={deleteModal} onClose={handleDeleteClick} userId={id} closeUserModal={closeWithUserModal} />
    </Grid>
  );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parseISO } from 'date-fns';
import { groupBy, map as lodashMap } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useMutation } from 'react-query';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { SheetProvider } from '@app/context/SheetContext';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  FileLinkAdditionalInfoDtoCodeEnum,
  FileLinkResponseDto,
  GetOperationsFileDto,
  OutputFormatEnum,
  StatementTemplateTypeEnum,
  StatementTypeEnum,
} from '@app/core/api';
import { Loader } from '@app/core/components/Loader';
import { MobileSelectFileFormat } from '@app/core/components/MobileSelectFileFormat';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { makeSubFilter } from '@app/core/components/Statements/helpers';
import {
  OperationsFromPeriodResult,
  StatementsFilterField,
  StatementsFilterFormData,
  StatementsTableColumnsEnum,
} from '@app/core/components/Statements/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetStatementsMultipleFiles } from '@app/core/hooks';
import { ColumnFilterType, Operation, OperationGroupType, PaginationChangeType, SortingRule } from '@app/core/types';
import { hasNextPage } from '@app/core/utils';
import { makeDataForSubmit } from '@app/pages/statements/helpers';
import { EmptyListText } from '@app/pages/statements/mobile/components/EmptyListText';
import { MobileStatementsFilters } from '@app/pages/statements/mobile/components/MobileStatementsFilters';
import { OperationsGroup } from '@app/pages/statements/mobile/components/OperationsGroup';
import { SheetHeader } from '@app/pages/statements/mobile/components/SheetHeader';
import { useStyles } from '@app/pages/statements/mobile/styles';
import { getStatements, getStatementsFileFn } from '@app/pages/statements/query';
import { StatementsFilter, StatementsViewProps } from '@app/pages/statements/types';

const SHEET_MAX_DIFF = 160;
const SHEET_MIN_DIFF = 220;

export const StatementsViewMobile: React.FC<StatementsViewProps> = ({ defaultValues }) => {
  const { notify } = useNotify();
  const columnFilter = useRef<ColumnFilterType<StatementsFilterField>>({});
  const [fileTypeSelectDialogOpen, setFileTypeSelectDialogOpen] = useState(false);
  const { sidebarOpen } = useUIState();
  const { t } = useTranslation();
  const [compact, setCompact] = useState(false);
  const bottomSheetRef = useRef<BottomSheetRef | null>(null);
  const selectedOperationsRef = useRef<Operation[]>([]);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const sortRuleRef = useRef<SortingRule>({ field: StatementsTableColumnsEnum.operationDate, desc: true });
  const statementsFilterFormDataRef = useRef<StatementsFilterFormData>();
  const [showBottomAnchor, setShowBottomAnchor] = useState<boolean>(false);
  const [operations, setOperations] = useState<Array<Operation>>([]);
  const operationsListRef = useRef<Array<Operation>>([]);

  const { mutateAsync: mutateAsyncStatementsFileLink } = useMutation<
    FileLinkResponseDto,
    ApiError,
    GetOperationsFileDto
  >(getStatementsFileFn);

  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<OperationsFromPeriodResult, unknown, StatementsFilter>(getStatements);

  const { mutateAsync: mutateAsyncMultipleFiles } = useGetStatementsMultipleFiles();

  const handleCompactFilterClick = () => {
    bottomSheetRef.current?.snapTo(({ snapPoints }) => {
      return Math.min(...snapPoints);
    });
  };

  const onFilterChangeHandler = useCallback(
    (filterData: StatementsFilterFormData) => {
      mutationReset();
      statementsFilterFormDataRef.current = filterData;
      operationsListRef.current = [];
      paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE };

      if (statementsFilterFormDataRef.current) {
        mutate({
          ...makeDataForSubmit(statementsFilterFormDataRef.current),
          subFilter: makeSubFilter(columnFilter.current),
          pagination: paginationRef.current,
          sortBy: sortRuleRef.current,
        });
      }
    },
    [mutate, mutationReset]
  );

  useEffect(() => {
    const pagination = data?.pagination;
    if (pagination && bottomAnchorInView && !isLoading && statementsFilterFormDataRef.current) {
      setShowBottomAnchor(false);
      if (hasNextPage(pagination)) {
        paginationRef.current = {
          page: pagination.page + 1,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        };

        mutate({
          ...makeDataForSubmit(statementsFilterFormDataRef.current),
          subFilter: makeSubFilter(columnFilter.current),
          pagination: paginationRef.current,
          sortBy: sortRuleRef.current,
        });
      }
    }
  }, [bottomAnchorInView, data, isLoading, mutate]);

  useEffect(() => {
    if (data) {
      operationsListRef.current = operationsListRef.current.concat(data.operations);
      setOperations(operationsListRef.current);
      setShowBottomAnchor(true);
    }
  }, [data]);

  const onCheckHandler = (operation: Operation, selected: boolean) => {
    if (selected) {
      const present = selectedOperationsRef.current.filter((item) => {
        return item.id === operation.id;
      });
      if (present?.length === 0 && selectedOperationsRef.current) {
        selectedOperationsRef.current = [...selectedOperationsRef.current, operation];
      }
    } else {
      selectedOperationsRef.current = selectedOperationsRef.current.filter((item) => {
        return item.id !== operation.id;
      });
    }
  };

  const styles = useStyles(sidebarOpen);

  const operationsGroups = useMemo(() => {
    const groups: OperationGroupType[] = operations.length
      ? lodashMap(
          groupBy<Operation>(operations, (item: Operation) => {
            const date = parseISO(item.date!);
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0).toISOString();
          }),
          (items, key) => {
            return {
              date: key,
              operations: items,
            };
          }
        )
      : [];
    return groups.length ? (
      groups.map((operationsGroup, idx) => (
        <OperationsGroup
          key={`${idx}_${operationsGroup.date}`}
          operationsGroup={operationsGroup}
          onCheck={onCheckHandler}
        />
      ))
    ) : (
      <EmptyListText />
    );
  }, [operations]);

  const onDownloadClickHandler = () => {
    setFileTypeSelectDialogOpen(true);
  };

  const onCloseFileTypeSelectDialog = () => {
    setFileTypeSelectDialogOpen(false);
  };

  const onSelectFileTypeHandler = (outputType: OutputFormatEnum) => {
    setFileTypeSelectDialogOpen(false);
    if (selectedOperationsRef.current.length) {
      const uuids = selectedOperationsRef.current.map((operation) => operation.uuid);
      mutateAsyncMultipleFiles({
        outputType,
        ordersUids: uuids || [],
        operationsType: StatementTypeEnum.Statement,
      }).then((responseData) => {
        if (
          responseData?.additionalInfo &&
          responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
        ) {
          notify({
            notifyProps: {
              message: t('load_too_many_operations_alert'),
              severity: 'warning',
            },
          });
          return;
        }

        if (responseData?.link) {
          const url = `${responseData.link}?format=download&type=payment-order`;
          window.open(url);
        }
      });
    } else {
      if (statementsFilterFormDataRef.current) {
        const { operationAccounts, dateRange } = statementsFilterFormDataRef.current;
        mutateAsyncStatementsFileLink({
          operationAccounts: operationAccounts.map((account) => ({
            id: account.account.id,
            currencyCode: account.account.currency,
          })),
          dateRange: { startDate: dateRange.from.toISOString(), endDate: dateRange.to.toISOString() },
          documentType: StatementTypeEnum.Statement,
          outputType: outputType as unknown as OutputFormatEnum,
          templateType: StatementTemplateTypeEnum.Default,
        }).then((responseData) => {
          if (
            responseData?.additionalInfo &&
            responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
          ) {
            notify({
              notifyProps: {
                message: t('load_too_many_operations_alert'),
                severity: 'warning',
              },
            });
            return;
          }

          window.open(`${responseData.link}?format=download&type=payment-order`);
        });
      }
    }
  };

  return (
    <PageWrapper
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        height: '100vh',
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          height: '100vh',
        }}
      >
        <PageHeader
          title={t('statementsPageHeaderTitle')}
          styles={{
            title: {
              color: '#FFFFFF',
            },
            closeButton: {
              color: '#FFFFFF',
            },
            burgerButton: {
              color: '#FFFFFF',
            },
          }}
        />

        <MobileStatementsFilters
          compact={compact}
          defaultValues={defaultValues}
          onFilterChange={onFilterChangeHandler}
          onCompactFilterClick={handleCompactFilterClick}
        />
        <SheetProvider>
          <BottomSheet
            open={true}
            skipInitialTransition
            expandOnContentDrag
            blocking={false}
            ref={bottomSheetRef}
            scrollLocking
            style={{ ...styles.sheet }}
            defaultSnap={({ snapPoints }) => {
              return Math.min(...snapPoints);
            }}
            snapPoints={({ maxHeight, height }) => {
              const sheetMaxHeight = maxHeight - SHEET_MAX_DIFF;
              const sheetMinHeight = maxHeight - SHEET_MIN_DIFF;
              if (height === sheetMaxHeight) {
                setCompact(true);
              }
              if (height === sheetMinHeight) {
                setCompact(false);
              }
              return [sheetMaxHeight, sheetMinHeight];
            }}
            header={operations.length ? <SheetHeader onDownloadClick={onDownloadClickHandler} /> : null}
          >
            <Box sx={styles.container}>
              <>
                {operationsGroups}
                <Box
                  ref={bottomAnchorRef}
                  sx={styles.bottomAnchor}
                  style={{ display: showBottomAnchor ? 'block' : 'none' }}
                />
              </>
            </Box>
          </BottomSheet>
        </SheetProvider>
        <ModalCommon open={isLoading}>
          <div>
            <Loader />
          </div>
        </ModalCommon>
      </Grid>
      <MobileSelectFileFormat
        open={fileTypeSelectDialogOpen}
        onClose={onCloseFileTypeSelectDialog}
        onSelectFileType={onSelectFileTypeHandler}
      />
    </PageWrapper>
  );
};

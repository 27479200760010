export const styles = {
  chevron: {
    color: 'colors.gray200',
  },
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_header: {
    color: 'colors.gray700px',
  },
  cell_documentNumber: {
    maxWidth: '116px',
  },
  cell_paymentDocumentDate: {
    maxWidth: '100px',
    minWidth: '60px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_recipientAccountName: {
    flexBasis: '100%',
    minWidth: '60px',
  },
  cell_paymentReference: {
    flexBasis: '100%',
    minWidth: '60px',
    '&.body': {
      color: 'colors.gray400px',
    },
  },
  cell_accountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_currency: {
    justifyContent: 'center',
    minWidth: '40px',
    maxWidth: '70px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_amount: {
    textAlign: 'end',
    minWidth: '116px',
    maxWidth: '116px',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_deposit: {
    color: 'colors.gray600',
    fontWeight: 600,
    minWidth: '116px',
    maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_withdrawal: {
    color: 'colors.gray600',
    fontWeight: 600,
    minWidth: '116px',
    maxWidth: '116px',
    textAlign: 'end',
    '&.body': {
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },
  cell_recipientAccountNumber: {
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_documentStatus: {},
  cell_actions: {
    width: '40px',
    flexShrink: 0,
  },
  cell_actions_header: {},
  cell_selection: {
    width: '40px',
    flexShrink: 0,
  },
  cell_status: {
    maxWidth: '94px',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  status: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  statusCorrect: {
    backgroundColor: 'colors.success',
  },
  statusIncorrect: {
    backgroundColor: 'colors.error',
  },
  deleteIcon: {
    marginLeft: '8px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingBottom: '28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '75vh',
  },
  title: {
    color: 'colors.gray500',
    fontWeight: 600,
  },
  text: {
    color: 'colors.gray600',
  },
  value: {
    fontWeight: 600,
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
  errorNotifyContainer: {
    width: '100%',
    backgroundColor: 'rgba(249, 12, 40, 0.09);',
    padding: 8,
    textAlign: 'center',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  errorNotifyText: {
    color: 'colors.error',
  },
};

import {
  CurrencyOperationsExchangeTypeEnum,
  CurrencyOperationStatusEnum,
  GetCurrencyOperationsRequestDto,
} from '@app/core/api';
import { CurrencyExchangeFilterFormData } from '@app/pages/exchange/currencyExchangeTransactions/types';

export const makeDataFromSubmit = (
  formData: CurrencyExchangeFilterFormData,
  exchangeType?: CurrencyOperationsExchangeTypeEnum
): Omit<GetCurrencyOperationsRequestDto, 'sort' | 'pagination' | 'exchangeType'> => {
  const {
    dateFrom,
    dateTo,
    documentNumber,
    amountMin,
    amountMax,
    withdrawalAccount,
    depositAccount,
    status,
    createdBy,
    withdrawalAccountCurrency,
  } = formData;
  return {
    status: Boolean(status) ? status : undefined,
    withdrawalAccountId: withdrawalAccount ? withdrawalAccount.account.id : undefined,
    withdrawalAccountCurrency:
      withdrawalAccountCurrency && exchangeType !== CurrencyOperationsExchangeTypeEnum.Purchase
        ? withdrawalAccountCurrency
        : undefined,
    depositAccountId: depositAccount ? depositAccount.account.id : undefined,
    depositAccountCurrency:
      withdrawalAccountCurrency && exchangeType !== CurrencyOperationsExchangeTypeEnum.Sale
        ? withdrawalAccountCurrency
        : undefined,
    dateFrom: dateFrom.toISOString(),
    dateTo: dateTo.toISOString(),
    documentNumber: Boolean(documentNumber) ? documentNumber : undefined,
    amountMin: Boolean(amountMin) ? Number(amountMin) : undefined,
    amountMax: Boolean(amountMax) ? Number(amountMax) : undefined,
    createdBy: Boolean(createdBy) ? createdBy : undefined,
  };
};

export const getExchangeDocumentStatusTranslationKey = (status: CurrencyOperationStatusEnum): string => {
  switch (status) {
    case CurrencyOperationStatusEnum.Executed:
      return 'documentStatus_executed';
    case CurrencyOperationStatusEnum.Processing:
      return 'documentStatus_processing';
    case CurrencyOperationStatusEnum.Rejected:
      return 'documentStatus_rejected';
    case CurrencyOperationStatusEnum.Saved:
      return 'documentStatus_saved';
    case CurrencyOperationStatusEnum.ToSign:
      return 'documentStatus_toSign';
    case CurrencyOperationStatusEnum.WaitingForDocument:
      return 'documentStatus_waitingForDocument';
    case CurrencyOperationStatusEnum.OnSigning:
      return 'ON_SIGNING';
    default:
      return '';
  }
};

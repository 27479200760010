import React, { useState } from 'react';

import { Popover, Grid, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Logger from 'js-logger';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SettingCompanyIcon } from '@app/common/icons';
import { authApiClient } from '@app/core/api';
import { serviceConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';

import { useStyles } from './styles';
import { Tooltip } from '../../../Tooltip';

interface SettingsSwitcherProps {
  menuOpen: boolean;
}

export const SettingsSwitcher: React.FC<SettingsSwitcherProps> = ({ menuOpen }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExit = async () => {
    const result = await authApiClient.authControllerLogout({});
    if (result.status === 200) {
      sessionStorage.removeItem('LastLogin');
      window.location.href = serviceConfig.loginUrl;
    } else {
      Logger.error("Can't logout");
    }
  };

  const classes = useStyles(theme, menuOpen);
  return (
    <>
      <Tooltip arrow placement={menuOpen ? 'bottom' : 'right'} color="secondary" title={`${t('settings')}`}>
        <IconButton sx={classes.settingsButton} onClick={handleClick} size="small">
          <SettingCompanyIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: menuOpen ? 'bottom' : 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: menuOpen ? 'top' : 'center',
          horizontal: menuOpen ? 'right' : 'left',
        }}
        sx={{
          '& .MuiPopover-paper': classes.popover,
        }}
      >
        <Grid container direction="column" sx={classes.switcherMenu}>
          <Grid item sx={classes.item} onClick={handleClose}>
            <Link to={RouteList.profile} style={classes.switcherLink}>
              <Typography variant="body2">{t('myProfile')}</Typography>
            </Link>
          </Grid>
          <Grid item sx={classes.item} onClick={handleClose}>
            <Link to={RouteList.companyProfile} style={classes.switcherLink}>
              <Typography variant="body2">{t('companyProfile')}</Typography>
            </Link>
          </Grid>
          <Grid item sx={{ ...classes.item, ...classes.itemLast }} onClick={handleExit}>
            <Typography variant="body2" sx={classes.switcherLink}>
              {t('exit')}
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

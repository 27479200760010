import { UserResourceDto } from '@app/core/api';
import { makeTableToolbarActions } from '@app/core/helpers';

import {
  DocumentStatus,
  Operation,
  TableToolbarActionsEnum,
  TableToolbarActiveButtonsConfig,
  TableToolbarEnabledActionsType,
} from '../../../../types';

export const getActiveButtonsConfig = (
  documentStatus: DocumentStatus,
  selectedDocuments: Operation[],
  permissions: UserResourceDto
): TableToolbarActiveButtonsConfig => {
  const single: boolean = selectedDocuments.length === 1;
  const signEnabled: boolean = selectedDocuments.filter((item) => item.actions?.allowToSign).length >= 1;
  const signFromAnotherPersonEnabled: boolean =
    selectedDocuments.filter((item) => item.actions?.allowSignFromAnotherPerson).length >= 1;
  const multiSignEnabled: boolean = selectedDocuments.length > 1 && selectedDocuments.length <= 10;
  const multiSignFromAnotherEnabled: boolean = selectedDocuments.length > 1 && selectedDocuments.length <= 10;

  const sendToSign = selectedDocuments.filter((item) => item.actions?.allowToSendToSign).length >= 1;

  const enabledActions: TableToolbarEnabledActionsType = {
    [TableToolbarActionsEnum.sign]: single && signEnabled,
    [TableToolbarActionsEnum.signFromAnother]: single && signFromAnotherPersonEnabled,
    [TableToolbarActionsEnum.multiSign]: multiSignEnabled && signEnabled,
    [TableToolbarActionsEnum.multiSignFromAnother]: multiSignFromAnotherEnabled && signFromAnotherPersonEnabled,
    [TableToolbarActionsEnum.sendToSign]: sendToSign,
    [TableToolbarActionsEnum.edit]: single && permissions.uahOperations.edit,
    [TableToolbarActionsEnum.view]: single && permissions.uahOperations.view,
    [TableToolbarActionsEnum.cancel]: false,
    [TableToolbarActionsEnum.download]: permissions.uahOperations.download,
    [TableToolbarActionsEnum.copy]: single && permissions.uahOperations.copy,
    [TableToolbarActionsEnum.template]: single && permissions.uahTemplates.create,
    [TableToolbarActionsEnum.print]: single && permissions.uahOperations.print,
    [TableToolbarActionsEnum.printMultiple]: !single && permissions.uahOperations.print,
    [TableToolbarActionsEnum.delete]: permissions.uahOperations.delete,
  };

  if (documentStatus === DocumentStatus.OnSigning) {
    enabledActions.sign = false;
    enabledActions.multiSign = false;
    enabledActions.delete = false;
    enabledActions.cancel = false;
    enabledActions.copy = false;
  }
  if (documentStatus === DocumentStatus.Processing) {
    enabledActions.sign = false;
    enabledActions.signFromAnother = false;
    enabledActions.multiSign = false;
    enabledActions.multiSignFromAnother = false;
    enabledActions.sendToSign = false;
    enabledActions.edit = false;
    enabledActions.delete = false;
    enabledActions.cancel = permissions.uahOperations.cancel && single;
    enabledActions.copy = false;
  }
  if (documentStatus === DocumentStatus.Rejected) {
    enabledActions.sign = false;
    enabledActions.signFromAnother = false;
    enabledActions.multiSign = false;
    enabledActions.multiSignFromAnother = false;
    enabledActions.sendToSign = false;
    enabledActions.edit = false;
    enabledActions.template = false;
    enabledActions.delete = false;
    enabledActions.cancel = false;
  }

  return makeTableToolbarActions(enabledActions);
};

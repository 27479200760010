import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { RatesByCurrency } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { SortingRule } from '@app/core/types';
import { makeTableColumns } from '@app/pages/exchangeRate/components/BankRates/components/BankCurrencyTable/columns';
import { styles } from '@app/pages/exchangeRate/components/BankRates/components/BankCurrencyTable/styles';
import { ExchangesRateColumnEnum, ExchangeSource } from '@app/pages/exchangeRate/types';

type BankCurrencyTableProps = {
  data: RatesByCurrency[];
  exchangeSource: ExchangeSource;
  onSort(name: SortingRule): void;
};

export const BankCurrencyTable: React.FC<BankCurrencyTableProps> = ({ data, onSort }): JSX.Element => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();

  const columns = useMemo(() => {
    return [...makeTableColumns({ t })];
  }, [t]);
  const table = useReactTable<RatesByCurrency>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: data.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as ExchangesRateColumnEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<RatesByCurrency> key={row.id} row={row} styles={styles} />;
    });
  };

  return (
    <Box mt={2}>
      <ComplexTable
        head={{
          content: <TableHeadContent<RatesByCurrency> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
      />
    </Box>
  );
};

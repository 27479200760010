import { Theme } from '@mui/material';

export const useStyles = ({ palette: { colors }, spacing, breakpoints }: Theme) => ({
  root: {
    [breakpoints.up('sm')]: {
      minWidth: '320px',
    },
  },
  formWrapper: {
    position: 'relative',
    width: 'auto',
    padding: 0,
    borderRadius: spacing(2),
    paddingTop: spacing(4),
    paddingBottom: spacing(6),
    [breakpoints.up('md')]: {
      backgroundColor: colors.containerBackgroundColor,
      paddingLeft: spacing(5),
      paddingRight: spacing(5),
    },
  },
  cardContent: {
    width: '100%',
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
  },
  actionsContainer: {
    '& button': {
      height: '100%',
    },
  },
  buttonsLoaderWrapper: {
    width: 24,
    height: 20,
    marginRight: -24,
  },
  radioLabel: {
    fontWeight: 600,
    verticalAlign: 'top',
    paddingTop: spacing(1),
  },
  fileText: {
    color: colors.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  transactionPresentedLabel: {
    color: colors.gray600,
    marginLeft: 0,
  },
  searchIcon: {
    color: colors.gray300,
  },
});

import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetCompanyData } from '@app/core/hooks';
import { styles } from '@app/src/pages/companyProfile/mobile/components/styles';

export const MobileCompanyData: React.FC = () => {
  const { data, mutate } = useGetCompanyData();

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const address = data?.address ? data?.address : undefined;

  const name = data?.fullName?.length ? data.fullName : data?.shortName;

  const { t } = useTranslation();
  return (
    <Grid container direction="column" justifyContent="space-between" sx={styles.root}>
      <Box ml={4} mr={4} mt={5}>
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('company_fullName')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{name}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={styles.hr} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('company_lei')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{data?.lei}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={styles.hr} />
        <Grid item container alignItems="flex-start" direction="column">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('company_address')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{address}</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={3} sx={styles.hr} />
      </Box>
    </Grid>
  );
};

export const styles = {
  root: {
    backgroundColor: 'colors.primary25',
    borderRadius: '8px',
    '& > :not(:last-child)': {
      borderBottom: `1px solid`,
      borderColor: 'colors.gray50',
    },
  },
  header: {
    padding: '6px 20px',
  },
  itemName: {
    color: 'colors.gray400',
  },
  movementItemContainer: {
    padding: '6px 20px',
  },
  accountNumber: {
    color: 'colors.gray400',
    fontWeight: 600,
  },
  openingBalance: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  withdrawal: {
    color: 'colors.error',
    fontWeight: 600,
  },
  deposit: {
    color: 'colors.success',
    fontWeight: 600,
  },
  closingBalance: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  currency: {
    fontWeight: 400,
  },
};

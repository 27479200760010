import React, { forwardRef } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { OutputFormatEnum } from '@app/core/api';

type MobileSelectFileFormatProps = {
  open?: boolean;
  onClose(): void;
  onSelectFileType(typeFile: OutputFormatEnum): void;
};

const Transition = forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement;
  }
>((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MobileSelectFileFormat: React.FC<MobileSelectFileFormatProps> = ({
  open = false,
  onSelectFileType,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        'MuiDialog-scrollPaper': {
          alignItems: 'flex-end',
        },
        'MuiDialog-paper': {
          margin: 0,
          width: '100%',
          borderRadius: 0,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        },
        'MuiDialog-paperScrollBody': {
          verticalAlign: 'bottom',
        },
      }}
    >
      <DialogTitle>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{t('mobileSelectFileFormatTitle')}</Typography>
          <IconButton size="small" onClick={onClose}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Box
        sx={{
          backgroundColor: 'colors.gray100',
          width: '100%',
          height: 1,
        }}
      />
      <DialogContent>
        <MenuItem onClick={() => onSelectFileType(OutputFormatEnum.Csv)}>
          <Typography variant="inherit">CSV</Typography>
        </MenuItem>
        <>
          <MenuItem onClick={() => onSelectFileType(OutputFormatEnum.Pdf)}>
            <Typography variant="inherit">PDF</Typography>
          </MenuItem>
        </>
        <MenuItem onClick={() => onSelectFileType(OutputFormatEnum.Xlsx)}>
          <Typography variant="inherit">XLS</Typography>
        </MenuItem>
        <MenuItem onClick={() => onSelectFileType(OutputFormatEnum.Xml)}>
          <Typography variant="inherit">XML</Typography>
        </MenuItem>
      </DialogContent>
    </Dialog>
  );
};

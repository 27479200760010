import React from 'react';

import { Grid, Typography } from '@mui/material';
import { GridProps } from '@mui/material/Grid/Grid';

import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/dashboard/view/PageContent/AvailableFunds/styles';

type FundItemProps = {
  amount: number;
  currency: string;
  description: string;
  containerProps?: GridProps;
};

export const FundItem: React.FC<FundItemProps> = ({ amount, currency, description, containerProps }) => {
  return (
    <Grid item sx={styles.fundRoot} {...containerProps}>
      <Grid container direction="column">
        <Typography variant="body1" sx={styles.fundAmount}>
          {formatAmount(amount)}{' '}
          <Typography variant="body1" component="span" sx={styles.fundCurrency}>
            {currency}
          </Typography>
        </Typography>
        <Typography variant="caption" sx={styles.fundDescription}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DetailsInfoItem } from '@app/core/components/ComplexTable/primitives';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { OrderRow } from '@app/pages/payment/import/types';

export interface ImportOrderDetailsProps {
  order: OrderRow;
}

export const ImportOrderDetails: React.FC<ImportOrderDetailsProps> = ({ order }) => {
  const { t } = useTranslation();
  const {
    paymentDocumentNumber,
    paymentDocumentDate,
    senderAccount,
    senderName,
    senderLei,
    senderBank,
    recipientAccount,
    recipientName,
    recipientLei,
    recipientBank,
    paymentReference,
  } = order;

  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{
          backgroundColor: '#F3F9FF',
          boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
          padding: '0 20px',
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
            '&:not(:last-child)': {
              backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
              backgroundPosition: 'bottom',
              backgroundSize: '12px 1px',
              backgroundRepeat: 'repeat-x',
            },
          }}
        >
          <Grid item xs={3}>
            <DetailsInfoItem
              description={t('paymentImport_details_paymentDocumentNumber')}
              value={paymentDocumentNumber}
            />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem
              description={t('paymentImport_details_paymentDocumentDate')}
              value={formatISODateString(paymentDocumentDate, DATE_FORMAT)}
            />
          </Grid>
          <Grid item container xs={3} justifyContent="flex-end" />
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
            '&:not(:last-child)': {
              backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
              backgroundPosition: 'bottom',
              backgroundSize: '12px 1px',
              backgroundRepeat: 'repeat-x',
            },
          }}
        >
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderAccountName')} value={senderName} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem description={t('operationDetail_lei')} value={senderLei} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_senderBank')} value={senderBank} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={senderAccount} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
            '&:not(:last-child)': {
              backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
              backgroundPosition: 'bottom',
              backgroundSize: '12px 1px',
              backgroundRepeat: 'repeat-x',
            },
          }}
        >
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipient')} value={recipientName} />
          </Grid>
          <Grid item xs={2}>
            <DetailsInfoItem description={t('operationDetail_recipientLei')} value={recipientLei} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_recipientBank')} value={recipientBank} />
          </Grid>
          <Grid item xs={3}>
            <DetailsInfoItem description={t('operationDetail_account')} value={recipientAccount} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          wrap="nowrap"
          justifyContent="space-between"
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
            '&:not(:last-child)': {
              backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
              backgroundPosition: 'bottom',
              backgroundSize: '12px 1px',
              backgroundRepeat: 'repeat-x',
            },
          }}
        >
          <DetailsInfoItem description={t('paymentCreationLocalPayment_purpose')} value={paymentReference} />
        </Grid>
      </Grid>
    </>
  );
};

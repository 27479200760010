import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUIState } from '@app/context/UIContext';
import { ProgressPopup, SignaturePopup, SignatureStrategyEnum } from '@app/core/components';

import { useMultiPaymentOrderSign } from './useMultiPaymentOrderSign';
import { Operation } from '../../types';
import { DocumentSigned, DocumentToSign } from '../SignatureModal';
import { StatusPopup } from '../StatusPopup';

export interface SignPaymentOrderMultipleProps {
  operations: Operation[];
  onSuccessPopupCancel(): void;
  onClose(): void;
  signFromAnotherPerson?: boolean;
}

export const SignPaymentOrderMultiple: React.FC<SignPaymentOrderMultipleProps> = ({
  operations,
  onSuccessPopupCancel,
  onClose,
  signFromAnotherPerson,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const [documentsSigned, setDocumentsSigned] = useState<DocumentSigned[]>([]);
  const [showSignaturePopup, setShowSignaturePopup] = useState<boolean>(true);
  const [showSendingProgressPopup, setShowSendingProgressPopup] = useState<boolean>(false);
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);
  const [currentSendingProgress, setCurrentSendingProgress] = useState<number>(0);
  const { mutate, status, data, isLoading } = useMultiPaymentOrderSign();

  const multipleSign = operations.length > 2;

  useEffect(() => {
    if (multipleSign && isLoading) {
      setCurrentSendingProgress(50);
      return;
    }
    showLoader(isLoading);
  }, [isLoading, multipleSign, showLoader]);

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[]) => {
    setDocumentsSigned(signedDocuments);
    if (multipleSign) {
      setShowSignaturePopup(false);
      setShowSendingProgressPopup(true);
    }
    mutate({ operations: signedDocuments });
  };

  const documentsToSign: DocumentToSign[] = operations.map((document) => {
    return { uuid: document.uuid, documentToSign: JSON.stringify(document) };
  });

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    if (onSuccessPopupCancel) {
      onSuccessPopupCancel();
      return;
    }
    navigate(-1);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSignModal(false);
    mutate({ operations: documentsSigned });
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  useEffect(() => {
    if (status === 'success') {
      setShowSuccessSignModal(true);
    }
    if (status === 'error') {
      setShowErrorSignModal(true);
    }
  }, [status]);

  useEffect(() => {
    if (status === 'success') {
      if (multipleSign) {
        setCurrentSendingProgress(100);
        setTimeout(() => {
          setShowSendingProgressPopup(false);
          setShowSuccessSignModal(true);
        }, 2000);
        return;
      }
      setShowSuccessSignModal(true);
    }
    if (status === 'error') {
      setShowErrorSignModal(true);
    }
  }, [data, multipleSign, status]);

  return (
    <>
      {showSignaturePopup ? (
        <SignaturePopup
          type={SignatureStrategyEnum.PaymentOrder}
          documentsToSign={documentsToSign}
          onSignedStringResult={onSignedStringResultHandler}
          onClose={onClose}
          signFromAnotherPerson={signFromAnotherPerson}
        />
      ) : null}

      {showSendingProgressPopup ? (
        <ProgressPopup
          current={currentSendingProgress}
          total={100}
          beforeProgressText={t('progressStepIsSendingToBank')}
        />
      ) : null}

      <StatusPopup
        show={showSuccessSignModal}
        title={t('payments_sign_success_message')}
        text={
          data ? (
            <div>
              <Typography variant="body2" component="span">
                {t('payments_sign_success_label')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {data.signedCount}/{documentsSigned.length}
              </Typography>
            </div>
          ) : undefined
        }
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={t('payments_sign_error_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

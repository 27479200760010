import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  ClickAwayListener,
  FormLabel,
  Grid,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronIcon, InfoIcon, KeyIcon } from '@app/common/icons';
import { CreateOperationAdditionalInfoDtoCodeEnum } from '@app/core/api';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { BICField } from '@app/core/components/Form/controls/BICField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { Tooltip } from '@app/core/components/Tooltip';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';
import { CommissionTypeSelect } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/components/CommissionTypeSelect';
import { UrgencySelectField } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/components/UrgencySelectField';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import { SwiftFormFields, SwiftPaymentFormProps } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

export const DesktopSwiftPaymentForm: React.FC<SwiftPaymentFormProps> = ({
  generatedSwiftOrderNumber,
  maxDocumentDate,
  minDocumentDate,
  maxValuationDate,
  minValuationDate,
  currencyAccounts,
  recipientCountry,
  currency,
  showBalance,
  countedBalance,
  shareButton,
  additionalInfo,
  isShowCorrespondentBank = false,
  onCancelClick,
  onSaveClick,
  onSaveAndSignClick,
  onSendToSignClick,
  signPermissions,
  onSaveAndSignFromAnotherPerson,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const [showCorrespondentBank, setShowCorrespondentBank] = useState<boolean>(isShowCorrespondentBank);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openAnotherPerson, setOpenAnotherPerson] = useState(false);
  const documentNumberRef = useRef<HTMLElement>(null);

  const correspondentBankCheckHandler = () => {
    setShowCorrespondentBank(!showCorrespondentBank);
  };

  const nowDate = new Date();
  nowDate.setHours(12, 0, 0);

  useEffect(() => {
    setShowCorrespondentBank(isShowCorrespondentBank);
  }, [isShowCorrespondentBank]);

  const handleToggle = () => {
    setOpenAnotherPerson(!openAnotherPerson);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenAnotherPerson(false);
  };

  const onSignFromAnotherPersonClickHandler = () => {
    setOpenAnotherPerson(false);
    onSaveAndSignFromAnotherPerson();
  };

  useEffect(() => {
    if (additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid sx={styles.tabBody}>
      <Grid container sx={styles.paymentNumber} justifyContent="space-between">
        <Grid item container xs={8}>
          <Box ref={documentNumberRef}>
            <DocumentNumberField
              name={SwiftFormFields.documentNumber}
              title={t('swiftPayment_paymentOrder')}
              defaultValue={generatedSwiftOrderNumber}
              error={additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed}
              validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
            />
          </Box>
        </Grid>
        {shareButton}
        {appConfig.enableNextFeatures ? (
          <Grid item xs={4} sx={styles.bookmarkBlock}>
            {/*<SavePaymentTemplate />*/}
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <CardContent>
            <Box mb={6}>
              <Grid container alignItems="flex-start">
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('swiftPayment_documentDate')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <DateSelectField
                      name={SwiftFormFields.documentDate}
                      selectedDate={nowDate}
                      datePickerProps={{
                        disabled: { after: maxDocumentDate, before: minDocumentDate },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: '186px' }}>
                  <FormLabel component="legend">{t('swiftPayment_valuationDate')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <DateSelectField
                      name={SwiftFormFields.documentValueDate}
                      datePickerProps={{
                        disabled: { after: maxValuationDate, before: minValuationDate },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Tooltip color="primary" title={t('swiftPayment_valuationDateTooltip')} arrow placement="right">
                    <Box mt={9}>
                      <InfoIcon fontSize="small" sx={styles.info} />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>
            <Box mb={5}>
              <FormLabel component="legend">{t('swiftPayment_senderAccount')}</FormLabel>
              <Box pt={2}>
                <AccountSelectField
                  name={SwiftFormFields.senderAccount}
                  options={currencyAccounts}
                  placeholder={t('swiftPayment_senderAccountPlaceholder')}
                />
              </Box>
            </Box>
            <Box mb={6}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_senderName')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <InputField
                      name={SwiftFormFields.senderName}
                      placeholder={t('swiftPayment_nameFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_senderAddress')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFormFields.senderAddress}
                      placeholder={t('swiftPayment_addressFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.hr} mb={5} />
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientName')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <InputField
                      name={SwiftFormFields.recipientName}
                      placeholder={t('swiftPayment_nameFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientAddress')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFormFields.recipientAddress}
                      placeholder={t('swiftPayment_addressFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientAccount')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <InputField
                      name={SwiftFormFields.recipientAccount}
                      placeholder={t('swiftPayment_recipientAccount')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientCountry')}</FormLabel>
                  <Box pt={2}>
                    <CountrySelect
                      placeholder={t('countrySelect_placeholder')}
                      name={SwiftFormFields.recipientCountry}
                      defaultValue={recipientCountry}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <BICField
                      name={SwiftFormFields.recipientSwiftBic}
                      placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFormFields.recipientBank}
                      placeholder={t('swiftPayment_bankFieldPlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <CheckboxField
                  label={<Typography variant="caption">{t('swiftPayment_correspondentBankCheckboxLabel')}</Typography>}
                  checked={showCorrespondentBank}
                  name={SwiftFormFields.correspondentBankCheck}
                  sx={styles.correspondentBankCheck}
                  onChange={correspondentBankCheckHandler}
                />
              </Grid>
            </Box>
            {showCorrespondentBank ? (
              <Box mb={5}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('swiftPayment_swiftBicCorrespondent')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <BICField
                        name={SwiftFormFields.recipientCorrespondentSwiftBic}
                        placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={SwiftFormFields.recipientCorrespondentBank}
                        placeholder={t('swiftPayment_bankFieldPlaceholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            <Box sx={styles.hr} mb={5} />
            <Box mb={5}>
              <Grid container>
                <Grid item xs={4}>
                  <FormLabel component="legend">{t('swiftPayment_amount')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.currencyAmount}
                      name={SwiftFormFields.amount}
                      placeholder={'0.00'}
                      currency={currency}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box pt={6} pr={10} sx={styles.balance}>
                    <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                    <Typography sx={styles.balanceText}>
                      {showBalance ? `${countedBalance} ${currency}` : '0.00'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
                  <Box pt={2}>
                    <CommissionTypeSelect
                      name={SwiftFormFields.commissionType}
                      placeholder={t('swiftPayment_commissionTypePlaceholder')}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_operationCode')}</FormLabel>
                  <Box pt={2} pr={8}>
                    <InputField
                      name={SwiftFormFields.operationCode}
                      placeholder={t('swiftPayment_operationCodePlaceholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_urgency')}</FormLabel>
                  <Box pt={2}>
                    <UrgencySelectField
                      name={SwiftFormFields.urgency}
                      placeholder={t('swiftPayment_urgencyPlaceholder')}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={6}>
              <FormLabel component="legend">{t('swiftPayment_purpose')}</FormLabel>
              <Box pt={2}>
                <TextAreaField
                  name={SwiftFormFields.paymentReference}
                  textFieldProps={{
                    multiline: true,
                    fullWidth: true,
                    rows: 2,
                  }}
                  maxLength={160}
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={styles.cardAction}>
            <Button color="primary" onClick={onCancelClick}>
              {t('paymentCreationFormControl_cancel')}
            </Button>
            <Box sx={styles.cardAction}>
              <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onSaveClick}>
                {t('paymentCreationFormControl_save')}
              </Button>
              {signPermissions.allowToSendToSign ? (
                <>
                  {permissions.sign.swift.signFromAnotherPerson && appConfig.enableNextFeatures ? (
                    <>
                      <ButtonGroup variant="contained" ref={anchorRef}>
                        <Button variant="contained" color="primary" onClick={onSendToSignClick}>
                          {t('paymentCreationFormControl_sendToSign')}
                        </Button>
                        <Button size="small" onClick={handleToggle}>
                          <DownChevronIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        sx={{
                          zIndex: 1,
                        }}
                        open={openAnotherPerson}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                      >
                        {({ TransitionProps }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: 'right top',
                            }}
                          >
                            <Paper sx={{ marginBottom: '8px' }}>
                              <ClickAwayListener onClickAway={handleClose}>
                                <Button variant="text" color="primary" onClick={onSignFromAnotherPersonClickHandler}>
                                  {t('signedByPerson')}
                                  <KeyIcon sx={styles.keyIcon} />
                                </Button>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : (
                    <Button variant="contained" color="primary" onClick={onSendToSignClick}>
                      {t('paymentCreationFormControl_sendToSign')}
                    </Button>
                  )}
                </>
              ) : null}
              {signPermissions.allowToSign ? (
                <Button variant="contained" color="primary" onClick={onSaveAndSignClick}>
                  {t('paymentCreationFormControl_send')}
                  <KeyIcon sx={styles.keyIcon} />
                </Button>
              ) : null}
            </Box>
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUIState } from '@app/context/UIContext';
import { CreatePaymentOrderResultDto } from '@app/core/api';
import {
  DocumentSigned,
  DocumentToSign,
  SignaturePopup,
  SignatureStrategyEnum,
  StatusPopup,
} from '@app/core/components';
import { useMultiPaymentOrderSign } from '@app/core/components/SignPaymentOrderMultiple/useMultiPaymentOrderSign';

export interface SignImportPaymentProps {
  operations: CreatePaymentOrderResultDto[];
  onSuccessPopupCancel(): void;
  onClose(): void;
}

export const SignImportPayment: React.FC<SignImportPaymentProps> = ({ operations, onSuccessPopupCancel, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const [documentsSigned, setDocumentsSigned] = useState<DocumentSigned[]>([]);
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);

  const { mutate, status, data, isLoading } = useMultiPaymentOrderSign();

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[]) => {
    setDocumentsSigned(signedDocuments);
    mutate({ operations: signedDocuments });
  };

  const documentsToSign: DocumentToSign[] = operations.map((document) => {
    return { uuid: document.uuid, documentToSign: JSON.stringify(document) };
  });

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    if (onSuccessPopupCancel) {
      onSuccessPopupCancel();
      return;
    }
    navigate(-1);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSignModal(false);
    mutate({ operations: documentsSigned });
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  useEffect(() => {
    if (status === 'success') {
      setShowSuccessSignModal(true);
    }
    if (status === 'error') {
      setShowErrorSignModal(true);
    }
  }, [status]);

  return (
    <>
      <SignaturePopup
        documentsToSign={documentsToSign}
        type={SignatureStrategyEnum.PaymentOrder}
        onSignedStringResult={onSignedStringResultHandler}
        onClose={onClose}
      />

      <StatusPopup
        show={showSuccessSignModal}
        title={t('payments_sign_success_message')}
        text={
          data ? (
            <div>
              <Typography variant="body2" component="span">
                {t('payments_sign_success_label')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {data.signedCount}/{documentsSigned.length}
              </Typography>
            </div>
          ) : undefined
        }
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={t('payments_sign_error_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

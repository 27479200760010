import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useScreenOrientation } from '@app/core/hooks';
import { formatAmount, isMobile } from '@app/core/utils';
import { styles } from '@app/pages/dashboard/view/PageContent/AvailableFunds/styles';

type TotalFundItemProps = {
  currency: string;
  amount: number;
  exchangeRates?: {
    currency: string;
    amount: number;
  }[];
};

export const TotalFundItem: React.FC<TotalFundItemProps> = ({ currency, amount, exchangeRates }) => {
  const { t } = useTranslation();
  const { isPortrait } = useScreenOrientation();
  return (
    <Grid container={isMobile && isPortrait} item sx={styles.fundRoot}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Typography variant="caption" sx={styles.totalText}>
          {t('funds_total_label')}
        </Typography>
        <Typography variant="body1" component="span" sx={styles.fundAmount}>
          {formatAmount(amount)}
          <Typography variant="body1" component="span" sx={styles.fundCurrency}>
            {currency}
          </Typography>
        </Typography>
      </Grid>
      <Box mt={1} />
      <Grid item container justifyContent="space-between" alignItems="center">
        {exchangeRates?.map((exchangeRate, index) => (
          <Typography
            variant="caption"
            key={`${index.toString()}${exchangeRate.currency}`}
            sx={styles.exchangeRate}
          >{`${exchangeRate.currency} ${formatAmount(exchangeRate.amount)}`}</Typography>
        ))}
      </Grid>
    </Grid>
  );
};

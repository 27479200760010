import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, MessageDto, UpdateMessageRequestDto } from '@app/core/api';

type EditMessageDto = {
  uuid: string;
  updateMessageRequestDto: UpdateMessageRequestDto;
};
const editMessageFn = async ({ uuid, updateMessageRequestDto }: EditMessageDto): Promise<MessageDto> => {
  const result = await messagesApiClient.messagesControllerEdit(uuid, getClientId(), updateMessageRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't edit messages");
  }

  return result.data;
};

export const useEditMessage = (): UseMutationResult<MessageDto, ApiError, EditMessageDto> => {
  return useMutation<MessageDto, ApiError, EditMessageDto>(editMessageFn);
};

export const styles = {
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '28px',
    outline: 0,
    borderRadius: '8px',
    width: '456px',
  },
  placeholder: {
    color: 'colors.gray300',
  },
  errorColorText: {
    color: 'colors.error',
  },
  buttonWrap: {
    textAlign: 'right',
  },
  passwordInput: {
    paddingRight: 0,
  },
  infoText: {
    color: 'colors.gray400',
  },
  infoOwnerName: {
    wordBreak: 'break-all',
    color: 'colors.gray600',
    fontWeight: 600,
  },
  infoValue: {
    wordBreak: 'break-all',
    color: 'colors.gray600',
    fontWeight: 400,
  },
  errorBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    border: `1.5px dashed`,
    borderColor: 'colors.error',
    backgroundColor: '#FFF7F9',
  },
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getAccessToken, getClientId, statementsApiClient, ApiError, GetOneOperationResponseDto } from '@app/core/api';

const getPaymentOrderByUuidFn = async (uuid: string): Promise<GetOneOperationResponseDto> => {
  const result = await statementsApiClient.operationsControllerGetOneOrder(getClientId(), uuid, getAccessToken());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the payment order");
  }
  return result.data;
};

export const useGetOrderByUuid = (uuid: string): UseQueryResult<GetOneOperationResponseDto, ApiError> => {
  return useQuery<GetOneOperationResponseDto, ApiError>('payment_getOneOrder', () => getPaymentOrderByUuidFn(uuid), {
    enabled: false,
  });
};

import React from 'react';

import { parse } from 'query-string';
import { DefaultValues } from 'react-hook-form/dist/types/form';

import { PaymentOrderTypeEnum } from '@app/core/api';
import { StatementsFilterFormData } from '@app/core/components/Statements/types';
import { OperationsTypeEnum } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { StatementsViewDesktop } from '@app/pages/statements/desktop';
import { StatementsViewMobile } from '@app/pages/statements/mobile';

export const StatementsPage: React.FC = () => {
  const {
    dateFrom,
    dateTo,
    dateRangeSelect,
    operationType,
    documentCategory,
    groupCommissions,
    LEI,
    counterPartyName,
    counterPartyAccountNumber,
    amountMin,
    amountMax,
    paymentReference,
  } = parse(location.search);

  const defaultValues: DefaultValues<StatementsFilterFormData> = {
    dateRange: dateFrom
      ? {
          from: new Date(dateFrom as string),
          to: new Date((dateTo as string) || (dateFrom as string)),
        }
      : undefined,
    dateRangeSelect: dateRangeSelect as string,
    LEI: LEI as string,
    counterPartyName: counterPartyName as string,
    counterPartyAccountNumber: counterPartyAccountNumber as string,
    amountMin: amountMin as string,
    amountMax: amountMax as string,
    paymentReference: paymentReference as string,
    operationType: operationType as OperationsTypeEnum,
    documentCategory: documentCategory as PaymentOrderTypeEnum,
    groupCommissions: groupCommissions ? Boolean(groupCommissions) : undefined,
  };

  const StatementsView = isMobile ? StatementsViewMobile : StatementsViewDesktop;

  return <StatementsView defaultValues={defaultValues} />;
};

import React from 'react';

import { Button, SxProps, Theme, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CopyIcon } from '@app/common/icons';
import { RouteList } from '@app/src/constants/routeList';

import { Operation } from '../../../../types';
import { Tooltip } from '../../../Tooltip';

export type CopyOperationProps = {
  operation: Operation;
  withLabel?: boolean;
  styles?: SxProps<Theme>;
};

export const CopyOperationButton: React.FC<CopyOperationProps> = ({ styles, operation, withLabel = false }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const urlTo = queryString.stringifyUrl({
      url: RouteList.payment_create,
      query: {
        copyFrom: operation.uuid,
        templateType: operation.templateType,
      },
    });
    navigate(urlTo);
  };

  const { t } = useTranslation();
  return (
    <Tooltip arrow placement="top" color="primary" title={t('copy')}>
      {withLabel ? (
        <Button sx={styles} onClick={handleClick} startIcon={<CopyIcon />}>
          <Typography variant="body1" component="span">
            {t('copy')}
          </Typography>
        </Button>
      ) : (
        <IconButton size="small" color="default" sx={styles} onClick={handleClick}>
          <CopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

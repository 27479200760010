import React, { forwardRef } from 'react';

import { MenuItem, Select as MuiSelect } from '@mui/material';
import { SelectProps as MuiSelectProps } from '@mui/material/Select/Select';

import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/down.svg';

export type SelectOptionType = {
  id: string | number;
  name: React.ReactNode;
  value: any;
};

export interface SelectProps extends MuiSelectProps {
  options?: SelectOptionType[];
}

export const Select: React.FC<SelectProps> = forwardRef(({ options, children, MenuProps, ...props }, ref) => {
  return (
    <MuiSelect
      defaultValue=""
      variant="outlined"
      ref={ref}
      MenuProps={{
        sx: {
          '& .MuiMenu-paper': {
            marginTop: 4,
          },
          '& .MuiMenu-list': {
            '& li:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'colors.gray50',
            },
          },
        },
        // paper: st.menuPaper, list: classes.menuList },
        MenuListProps: { disablePadding: true },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        // getContentAnchorEl: null,
        ...MenuProps,
      }}
      IconComponent={ChevronDownIconSvg}
      {...props}
    >
      {options &&
        options.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      {children}
    </MuiSelect>
  );
});

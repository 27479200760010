import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { DownloadIcon } from '@app/common/icons';
import { useSheetState } from '@app/context/SheetContext';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { useStyles } from '@app/pages/statements/mobile/styles';

interface SheetHeaderProps {
  onDownloadClick(): void;
}

export const SheetHeader: React.FC<SheetHeaderProps> = ({ onDownloadClick }) => {
  const { visibleGroupDates } = useSheetState();

  const dateInHeader: string | undefined = visibleGroupDates.length
    ? visibleGroupDates.reduce((a, b) => (new Date(a) > new Date(b) ? a : b))
    : undefined;

  const styles = useStyles();

  return (
    <Box sx={styles.headerContent}>
      <IconButton sx={styles.downloadButton} onClick={onDownloadClick} size="large">
        <DownloadIcon />
      </IconButton>
      <Typography variant="body2" component="span" color="textSecondary">
        {dateInHeader ? formatISODateString(dateInHeader, DATE_FORMAT) : null}
      </Typography>
    </Box>
  );
};

import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, paymentOrdersApiClient, ApiError, SignBatchResponseDto, SignOperationDto } from '@app/core/api';
import { SignApiError } from '@app/src/logger/SignApiError';
import { LogTagErrorName, LogTagErrorSource, LogTagErrorType } from '@app/src/logger/types';

const logSignApiError = (error: ApiError) => {
  Sentry.captureException(new SignApiError(error.message), {
    tags: {
      [LogTagErrorName.errorType]: LogTagErrorType.SignCurrencyExchangeApiError,
      [LogTagErrorName.errorSource]: LogTagErrorSource.api,
    },
    extra: {
      statusCode: error.code,
      message: error.message,
    },
  });
};

type SignBatchProps = {
  operations: Array<SignOperationDto>;
  signatureBelongsToUser?: number;
};

export const useMultiPaymentOrderSign = (): UseMutationResult<SignBatchResponseDto, ApiError, SignBatchProps> =>
  useMutation(
    async ({ operations, signatureBelongsToUser }: SignBatchProps) => {
      const result = await paymentOrdersApiClient.paymentOrderSignControllerSignBatchPaymentService(
        getClientId(),
        { operations },
        signatureBelongsToUser
      );

      if (result.status !== 200) {
        throw new ApiError(result.request.status, 'Sign API response with error. StatusCode:' + result.request.status);
      }

      return result.data;
    },
    {
      onError: (error: ApiError) => {
        logSignApiError(error);
      },
    }
  );

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, paymentOrdersApiClient, getClientId } from '@app/core/api';

const resetNumberConfigQueryFn = async (): Promise<{}> => {
  const response = await paymentOrdersApiClient.paymentOrderControllerResetDocumentNumber(getClientId());
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant reset number config!');
  }
  return response.data;
};

export const useResetNumberConfig = (): UseMutationResult<{}, ApiError, any> => {
  return useMutation<{}, ApiError, any>(resetNumberConfigQueryFn);
};

export const styles = {
  root: {
    backgroundColor: 'colors.primary500',
    height: '44px',
    borderRadius: '8px',
    padding: '0 20px',
    marginBottom: '4px',
    position: 'sticky',
    top: '5px',
    zIndex: 999,
  },
  divider: {
    marginRight: '20px',
    marginLeft: '20px',
    borderLeft: `1px solid`,
    borderColor: 'colors.gray100',
    width: '1px',
    height: '20px',
  },
  buttonRoot: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  selectedText: {
    color: '#ffffff',
    whiteSpace: 'nowrap',
  },
  totalAmountContainer: {
    textAlign: 'right',
  },
  amountText: {
    paddingRight: '4px',
    color: '#ffffff',
  },
  amountValue: {
    paddingRight: '4px',
    color: '#ffffff',
    fontWeight: 600,
  },
  menuItem: {
    borderBottom: '1px solid',
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, counterpartiesApiClient, getClientId, GetSwiftResponseDto, SwiftDto } from '@app/core/api';

const addSwiftCounterpartyFn = async (counterpartyData: SwiftDto): Promise<GetSwiftResponseDto> => {
  const response = await counterpartiesApiClient.counterpartiesControllerCreateSwiftCounterparty(
    getClientId(),
    counterpartyData
  );
  return response.data;
};

export const useAddSwiftCounterparty = (): UseMutationResult<GetSwiftResponseDto, ApiError, SwiftDto> => {
  return useMutation<GetSwiftResponseDto, ApiError, SwiftDto>(addSwiftCounterpartyFn);
};

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ChangeStatusRequestDtoStatusEnum,
  DocumentOperationType,
  DocumentType,
  FrontLanguageEnum,
  MessageDto,
  MessageStatus,
  MessageType,
  UploadFileResponseDto,
} from '@app/core/api';
import { LeaveEditPopup } from '@app/core/components';
import { PeopleToSignMessageList } from '@app/core/components/PeopleToSignMessageList';
import { SignMessages } from '@app/core/components/SignMessages';
import {
  useChangeMessagesStatus,
  useCurrencyUploadFiles,
  useDocumentAttachOperation,
  useEditMessage,
  useGetMessagesSigners,
  useGetOneMessage,
} from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { MessageEditForm } from '@app/pages/letters/components/MessageEditForm';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { MessageDefaultValues, MessageEditData } from '../components/MessageEditForm/types';
import { MessageStatusTitle } from '../components/MessageStatusTitle';
import { styles } from '../styles';

interface EditMessageProps {
  messageEditData: MessageEditData;
  onEditSuccess(message: MessageDto): void;
  onEditError(): void;
}

export const MessageEditPage: React.FC = () => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const { uuid = 'no-uuid' } = useParams();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [showSignPopup, setShowSignPopup] = useState<boolean>(false);
  const [editMessageResultData, setEditMessageResultData] = useState<MessageDto>();
  const [defaultValues, setDefaultValues] = useState<MessageDefaultValues>();
  const { mutateAsync: mutateAsyncEditMessage, isLoading: isLoading1 } = useEditMessage();
  const { data: messageByUuidData, mutate: mutateGetOneMessage, isLoading: isLoading2 } = useGetOneMessage();
  const { data: peopleToSigne, mutate: mutatePeopleToSign, isLoading: isLoading3 } = useGetMessagesSigners();
  const { mutate: mutateChangeMessageStatus } = useChangeMessagesStatus();
  const { mutateAsync: mutateAsyncUploadFiles } = useCurrencyUploadFiles();
  const { mutateAsync: mutateAsyncAttachOperation } = useDocumentAttachOperation();

  useEffect(() => {
    mutatePeopleToSign(undefined);
  }, [mutatePeopleToSign]);

  useEffect(() => {
    mutateGetOneMessage({ uuid, language: i18n.language as FrontLanguageEnum });
  }, [i18n.language, mutateGetOneMessage, uuid]);

  useEffect(() => {
    showLoader(isLoading1 || isLoading2 || isLoading3);
  }, [isLoading1, isLoading2, isLoading3, showLoader]);

  useEffect(() => {
    if (messageByUuidData) {
      setDefaultValues({
        conversationId: messageByUuidData.conversationId,
        categoryId: messageByUuidData.category.id,
        subject: messageByUuidData.subject,
        message: messageByUuidData.message,
        documents: messageByUuidData.documents,
        status: messageByUuidData.status,
      });
    }
  }, [messageByUuidData]);

  const editMessage = ({ messageEditData, onEditSuccess, onEditError }: EditMessageProps) => {
    const { messageData, files } = messageEditData;
    showLoader(true);
    mutateAsyncEditMessage({ uuid: uuid, updateMessageRequestDto: { ...messageData } })
      .then((message: MessageDto) => {
        if (files.getAll('file').length > 0) {
          mutateAsyncUploadFiles({
            file: files,
            documentType: DocumentType.Attachment,
            operationsTypes: [DocumentOperationType.Message],
          })
            .then((resultSaveFiles: UploadFileResponseDto[]) => {
              mutateAsyncAttachOperation({
                uuids: [...resultSaveFiles.map((file) => file.uuid)],
                operations: [{ uuid: message.uuid, operationType: DocumentOperationType.Message }],
              })
                .then(() => {
                  showLoader(false);
                  onEditSuccess(message);
                })
                .catch(() => {
                  notify({
                    notifyProps: {
                      title: t('attachFile_ErrorTitle'),
                      message: t('attachFile_ErrorMessage'),
                      severity: 'error',
                    },
                  });
                  showLoader(false);
                });
            })
            .catch(() => {
              notify({
                notifyProps: {
                  title: t('attachFile_ErrorTitle'),
                  message: t('attachFile_ErrorMessage'),
                  severity: 'error',
                },
              });
            });
        } else {
          showLoader(false);
          onEditSuccess(message);
        }
        showLoader(false);
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('error'),
            message: t(error),
            severity: 'error',
          },
        });
        showLoader(false);
        onEditError();
      })
      .finally(() => {
        showLoader(false);
      });
  };

  const onSaveHandler = (messageEditData: MessageEditData) => {
    editMessage({
      messageEditData,
      onEditSuccess() {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('editLetterSuccessMessage'),
            severity: 'success',
          },
        });
        navigate(`${RouteList.letters}?type=${MessageType.Outgoing}&status=${MessageStatus.Saved}`);
      },
      onEditError() {
        notify({
          notifyProps: {
            title: t('error'),
            message: t('editLetterErrorMessage'),
            severity: 'error',
          },
        });
      },
    });
  };

  const onSaveAndSignHandler = (messageEditData: MessageEditData) => {
    editMessage({
      messageEditData,
      onEditSuccess(message: MessageDto) {
        setEditMessageResultData(message);
        setShowSignPopup(true);
      },
      onEditError() {
        notify({
          notifyProps: {
            title: t('error'),
            message: t('editLetterErrorMessage'),
            severity: 'error',
          },
        });
      },
    });
  };

  const onSaveAndSendToSignHandler = (messageEditData: MessageEditData) => {
    editMessage({
      messageEditData,
      onEditSuccess(message: MessageDto) {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('sentToSign'),
            severity: 'success',
          },
        });
        mutateChangeMessageStatus({
          status: ChangeStatusRequestDtoStatusEnum.ToSign,
          uuids: [message.uuid],
        });
        navigate(-1);
      },
      onEditError() {
        notify({
          notifyProps: {
            title: t('error'),
            message: t('editLetterErrorMessage'),
            severity: 'error',
          },
        });
      },
    });
  };

  const onSuccessSignHandler = () => {
    setEditMessageResultData(undefined);
    setShowSignPopup(false);
    navigate(`${RouteList.letters}?type=${MessageType.Outgoing}&status=${MessageStatus.Signed}`);
  };

  const onCloseSignMessagesPopupHandler = () => {
    setShowSignPopup(false);
    setEditMessageResultData(undefined);
    setShowSignPopup(false);
  };

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    setShowConfirmPopup(formDirty);
  };

  const onCancelHandler = () => {
    if (showConfirmPopup) {
      setLeavePopupOpen(true);
    } else {
      navigate(RouteList.letters);
    }
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(RouteList.letters);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  return (
    <>
      <PageWrapper
        sx={{
          ...(isMobile && {
            backgroundColor: 'initial',
            borderRadius: 0,
            height: '100vh',
            padding: 0,
          }),
        }}
      >
        <PageHeader
          title={t('messageEditHeaderContent_title')}
          showNavigateButton={!isMobile}
          showLeavePopup={showConfirmPopup}
          confirmQuestionText={t('confirmLeaveEditLetterPopupMessage')}
          backLink={-1}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap">
              <Grid container wrap="nowrap">
                <MessageEditForm
                  defaultValues={defaultValues}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onChange={onChangeHandler}
                  onCancel={onCancelHandler}
                  onSaveAndSendToSign={onSaveAndSendToSignHandler}
                />
              </Grid>
              {!isMobile && userProfile ? (
                <Grid item container xs={3}>
                  <Box pl={10}>
                    <MessageStatusTitle status={messageByUuidData?.status} />
                    {messageByUuidData?.type === MessageType.Outgoing ? (
                      <>
                        <Box sx={styles.hr} />
                        <Box mt={4}>
                          <PeopleToSignMessageList
                            signInfo={peopleToSigne?.personToSign || []}
                            userProfile={userProfile}
                          />
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>

      {showSignPopup && editMessageResultData ? (
        <SignMessages
          messages={[editMessageResultData]}
          onSuccessPopupCancel={onSuccessSignHandler}
          onClose={onCloseSignMessagesPopupHandler}
        />
      ) : null}

      <LeaveEditPopup
        questionText={t('corrections_massage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

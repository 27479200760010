import React from 'react';

import { useTranslation } from 'react-i18next';

import { PaymentOrderStatusEnum } from '@app/core/api';

import { SelectOptionType } from '../../../Select';
import { SelectField, SelectFieldProps } from '../SelectField';

export const DocumentStatusSelectField: React.FC<SelectFieldProps> = ({ name, ...otherProps }) => {
  const { t } = useTranslation();

  const notSelectedValue = {
    id: 'not_selected',
    name: t('not_selected'),
    value: '',
  };

  const statusesListItems: SelectOptionType[] = Object.values(PaymentOrderStatusEnum).map((status) => {
    return {
      id: status,
      name: t(status),
      value: status,
    };
  });

  return <SelectField name={name} options={[notSelectedValue, ...statusesListItems]} {...otherProps} />;
};

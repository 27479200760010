import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import useInView from 'react-cool-inview';

import { useSheetState } from '@app/context/SheetContext';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { UserVisitCard } from '@app/pages/profile/mobile/components/HistoryOfVisits/components/UserVisitCard';
import { VisitsGroupType } from '@app/pages/profile/types';

interface VisitsGroupProps {
  visitGroup: VisitsGroupType;
}

export const VisitsGroup: React.FC<VisitsGroupProps> = ({ visitGroup }) => {
  const { visibleGroupDates, setVisibleGroupDates } = useSheetState();

  const { observe } = useInView({
    onEnter: () => {
      setVisibleGroupDates([...visibleGroupDates, visitGroup.date]);
    },
    onLeave: () => setVisibleGroupDates([...visibleGroupDates.filter((item) => item !== visitGroup.date)]),
  });
  const cards = useMemo(() => {
    return visitGroup.visits.map((visit, key) => <UserVisitCard key={`${visit.userId}_${key}`} visit={visit} />);
  }, [visitGroup.visits]);
  return (
    <div ref={observe}>
      <Box mt={2} mb={2}>
        <Typography
          align="center"
          variant="body2"
          sx={{
            color: 'colors.gray400',
          }}
        >
          {formatISODateString(visitGroup.date, DATE_FORMAT)}
        </Typography>
      </Box>
      {cards}
    </div>
  );
};

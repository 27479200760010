import {
  AvailableActionsDto,
  CreateTemplateRequestDto,
  DocumentTemplateDto,
  GetAccountResponseDto,
  GetDefaultAccountsResponseDto,
  PaymentOrderAdditionalInfoDto,
} from '@app/core/api';
import { Severity } from '@app/core/types';

export enum BetweenMyAccountFormFieldName {
  paymentDocumentNumber = 'paymentDocumentNumber',
  paymentDocumentDate = 'paymentDocumentDate',
  paymentDocumentValueDate = 'paymentDocumentValueDate',
  senderAccount = 'senderAccount',
  recipientAccount = 'recipientAccount',
  amount = 'amount',
  paymentReference = 'paymentReference',
  temp_paymentDocumentDate = 'temp_paymentDocumentDate',
  temp_paymentDocumentValueDate = 'temp_paymentDocumentValueDate',
}

export type BetweenMyAccountFormDefaultValues = {
  senderAccountId?: string;
  recipientAccountId?: string;
  documentNumber?: string;
  documentDate?: string;
  valueDate?: string;
  amount?: number;
  paymentReference?: string;
};

export type BetweenMyAccountFormData = {
  [BetweenMyAccountFormFieldName.paymentDocumentNumber]: string;
  [BetweenMyAccountFormFieldName.paymentDocumentDate]: Date;
  [BetweenMyAccountFormFieldName.paymentDocumentValueDate]?: Date;
  [BetweenMyAccountFormFieldName.senderAccount]: string;
  [BetweenMyAccountFormFieldName.recipientAccount]: string;
  [BetweenMyAccountFormFieldName.amount]: string;
  [BetweenMyAccountFormFieldName.paymentReference]: string;
  [BetweenMyAccountFormFieldName.temp_paymentDocumentDate]: string;
  [BetweenMyAccountFormFieldName.temp_paymentDocumentValueDate]: string;
};

export interface BetweenMyAccountFormProps {
  defaultValues: BetweenMyAccountFormDefaultValues;
  signPermissions: AvailableActionsDto;
  senderAccountsData?: GetDefaultAccountsResponseDto;
  recipientAccountsData?: GetAccountResponseDto[];
  showBalance: boolean;
  countedBalance: string;
  additionalInfo?: PaymentOrderAdditionalInfoDto | null;
  createTemplateData(): Omit<CreateTemplateRequestDto, 'templateName'>;
  onCancelHandler(): void;
  onSaveClickHandler(): void;
  onSaveAndSignClickHandler(): void;
  onSendToSign(): void;
  onTemplateChangeHandler?(templateData: DocumentTemplateDto): void;
  maxValuationDate: Date;
  minValuationDate: Date;
  errorSeverity?: Severity;
  blockAction: boolean;
}

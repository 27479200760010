import React from 'react';

import { styled, TextField } from '@mui/material';

export const TextFieldMultiline = styled(TextField)({
  '& textarea': {
    borderColor: 'transparent!important',
  },
  '& fieldset': {
    borderColor: 'transparent!important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover textarea': {
    borderColor: 'transparent',
  },
  '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
});

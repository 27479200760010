import { Theme } from '@mui/material/styles';

import { ThemeEnum } from '../../types';

export const useStyles = ({ palette: { colors }, themeName }: Theme, disableFocus?: boolean) => ({
  root: {
    width: '86px',
    height: '24px',
    minWidth: '88px',
    minHeight: '24px',
    background: colors.currencyTabBackground,
    border: `1px solid ${colors.currencyTabBorder}`,
    borderBottom: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    color: colors.currencyTabText,
    textTransform: 'none',
    '&:hover': {
      borderColor: disableFocus ? 'inherit' : colors.currencyTabBorderHover,
      color: disableFocus ? 'inherit' : colors.currencyTabTextHover,
    },
  },
  container: {
    background: colors.currencyTabSelectedBackground,
    boxShadow: themeName === ThemeEnum.light ? '0px -6px 6px 1px rgba(0 ,51, 97, 0.05) inset' : 'initial',
  },
  selected: {
    background: colors.currencyTabSelectedBackground,
    color: colors.currencyTabSelectedText,
    '&:hover': {
      borderColor: colors.currencyTabBorder,
    },
    boxShadow: () => {
      if (themeName === ThemeEnum.light) {
        return '0px 6px 6px 1px rgba(0 ,51, 97, 0.05) inset';
      }
      return 'initial';
    },
  },
});

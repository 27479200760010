import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { ColumnSort, getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DepositDto, LoanSortingFieldsEnum } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import {
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
} from '@app/core/constants';
import { PaginationChangeType, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/deposits/show/components/TranchesTable/components/TableContent/columns';
import { styles } from '@app/pages/deposits/show/components/TranchesTable/components/TableContent/styles';
import { RouteList } from '@app/src/constants/routeList';

interface TableContentProps {
  data: DepositDto[];
  pagination?: TablePaginationType;
  onPaginationChange(pagination: PaginationChangeType): void;
  onSort?(rule: any): void;
}

export const TableContent: React.FC<TableContentProps> = ({ data, pagination, onPaginationChange, onSort }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        navigateClickHandler(uuid: string) {
          navigate(RouteList.deposits_show_deposit_tranche_page.replace(':uuid', uuid));
        },
      }),
    ];
  }, [navigate, t]);

  const table = useReactTable<DepositDto>({
    columns,
    data,
    manualPagination: true,
    enableSorting: data.length > 0,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as LoanSortingFieldsEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<DepositDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <>
      <ComplexTable
        head={{ content: <TableHeadContent<DepositDto> {...{ table, styles }} /> }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={DEFAULT_ROWS_PER_PAGE}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  currencyDocumentsApiClient,
  getClientId,
  ApiError,
  AttachOperationRequestDto,
  AttachOperationResponseDto,
} from '@app/core/api';

const attachFilesFn = async (
  attachOperationRequestDto: AttachOperationRequestDto
): Promise<AttachOperationResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerAttachOperation(
    getClientId(),
    attachOperationRequestDto
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "documentsControllerAttachOperation error. Can't attach files.");
  }

  return response.data;
};

export const useDocumentAttachOperation = (): UseMutationResult<
  AttachOperationResponseDto,
  ApiError,
  AttachOperationRequestDto
> => useMutation<AttachOperationResponseDto, ApiError, AttachOperationRequestDto>(attachFilesFn);

import React from 'react';

import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { StatusText } from '@app/core/components/StatusText';

interface PassportFieldProps {
  name: string;
  defaultValue?: string;
  disabled?: boolean;
}

export const PassportField: React.FC<PassportFieldProps> = ({ name, defaultValue, disabled }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <InputMask
            mask={[/^([аА-яЯ])/, /([аА-яЯ])/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            maskPlaceholder={null}
            value={value}
            onChange={onChange}
            disabled={disabled}
          >
            <TextField error={Boolean(error)} name={name} fullWidth variant="outlined" placeholder="KK-000000" />
          </InputMask>
          {error && error?.message ? <StatusText absolute>{error.message}</StatusText> : null}
        </>
      )}
    />
  );
};

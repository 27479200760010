import React from 'react';

import { Box, Grid, Typography, Skeleton } from '@mui/material';

import { styles } from '@app/pages/dashboard/view/PageContent/AccountBalances/BalanceItem/styles';

export const BalanceLoadingItem: React.FC = () => {
  return (
    <>
      <Grid container sx={styles.root} justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs={5}>
          <Box pl={3} pr={3}>
            <Typography variant="h6">
              <Skeleton animation="wave" />
            </Typography>
            <Typography variant="body1">
              <Skeleton animation="wave" />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box pl={5} pr={4}>
            <Typography variant="h6">
              <Skeleton animation="wave" />
            </Typography>

            <Typography variant="body1">
              <Skeleton animation="wave" />
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={styles.amountCurrencyContainer}>
          <Typography variant="h6">
            <Skeleton animation="wave" />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

import React from 'react';

import { SwitchProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Switch } from '../../components/Switch';

export const SwitchField: React.FC<SwitchProps> = ({ name, defaultChecked, ...otherProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name || ''}
      control={control}
      defaultValue={defaultChecked}
      render={({ field: { value, onChange } }) => (
        <Switch onChange={(e) => onChange(e.target.checked)} checked={value} {...otherProps} />
      )}
    />
  );
};

import React, { PropsWithChildren, Suspense } from 'react';

import { styled } from '@mui/material';

import { MainLoaderBackdrop } from '@app/common/layout/MainLayout/MainLoaderBackdrop';
import { Sidebar } from '@app/common/layout/Sidebar';
import { MainLoader, MobileAlertInfo, Session } from '@app/core/components';
import { appConfig } from '@app/src/config';

import { MainContent } from './MainContent';

const Root = styled('div')(({ theme: { breakpoints, palette } }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  // [breakpoints.down('xs')]: {
  '@media (max-width: 599.95px)': {
    backgroundColor: palette.colors.mainContentBackgroundColorMobile,
    display: 'inline-block',
  },
  [breakpoints.down('sm')]: {
    // overflow: 'hidden',
  },
}));

export const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Root>
        <Sidebar />
        <MainContent>
          <Suspense fallback={<MainLoader />}>{children}</Suspense>
        </MainContent>
        <MainLoaderBackdrop />
      </Root>
      {appConfig.enableMobileAlert ? <MobileAlertInfo /> : null}
      <Session />
    </>
  );
};

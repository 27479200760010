import { LoansLinesTableColumns } from '@app/pages/loansLines/types';

export const loansLinesTableFields = [
  {
    columnType: LoansLinesTableColumns.agreementStartDate,
    enabled: true,
    order: 0,
    edit: false,
  },
  {
    columnType: LoansLinesTableColumns.agreementEndDate,
    enabled: true,
    order: 1,
    edit: false,
  },
  {
    columnType: LoansLinesTableColumns.agreementNumber,
    enabled: true,
    order: 2,
    edit: true,
  },
  {
    columnType: LoansLinesTableColumns.limit, //Лимит
    enabled: true,
    order: 3,
    edit: true,
  },
  {
    columnType: LoansLinesTableColumns.outstanding, //оставшаяся сумма задолженности
    enabled: true,
    order: 4,
    edit: true,
  },
  {
    columnType: LoansLinesTableColumns.availableLimit, //  доступний ліміт (залишок ліміту)
    enabled: true,
    order: 5,
    edit: true,
  },
  {
    columnType: LoansLinesTableColumns.currency, //валюта
    enabled: true,
    order: 6,
    edit: true,
  },
  {
    columnType: LoansLinesTableColumns.interestRate,
    enabled: true,
    order: 7,
    edit: true,
  },
];

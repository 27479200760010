import {
  dictionariesApiClient,
  archiveExchangeRatesApiClient,
  ApiError,
  GetArchiveNbuResponseDto,
  GetArchiveCurrencyListResponseDto,
  FrontLanguageEnum,
  GetArchiveBankRequestDto,
  RatesByCurrency,
  BankCurrencyInfoDto,
} from '@app/core/api';
import { ExchangesCurrencyFilter } from '@app/pages/exchangeRate/types';

export const submitCurrencyFilter = async (filterData: ExchangesCurrencyFilter): Promise<GetArchiveNbuResponseDto> => {
  const { currencies, dateRange, pagination } = filterData;
  const response = await archiveExchangeRatesApiClient.exchangeRatesControllerGetArchiveNbu({
    currencyCodes: currencies,
    dateRange: {
      startDate: dateRange.from,
      endDate: dateRange.to,
    },
    pagination,
    language: FrontLanguageEnum.Ua,
  });
  return response.data;
};

export const bankRateQueryFn = async (
  getArchiveBankRequestDto: GetArchiveBankRequestDto
): Promise<Array<RatesByCurrency>> => {
  const response = await archiveExchangeRatesApiClient.exchangeRatesControllerGetArchiveBank(getArchiveBankRequestDto);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error exchangeRatesControllerGetArchiveBank.');
  }
  return response.data.data;
};

export const nbuCurrencyListQueryFn = async (filter: {
  language: FrontLanguageEnum;
}): Promise<GetArchiveCurrencyListResponseDto> => {
  const response = await dictionariesApiClient.dictionariesControllerGetNbuCurrencyCodesList({
    language: filter.language,
  });

  return response.data;
};

export const oxiCurrencyListQueryFn = async (filter: {
  bankBranchId: number;
  language: FrontLanguageEnum;
}): Promise<BankCurrencyInfoDto[]> => {
  const response = await dictionariesApiClient.dictionariesControllerGetBankCurrenciesList({
    bankBranchId: filter.bankBranchId,
    language: filter.language,
  });
  return response.data;
};

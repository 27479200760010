import React from 'react';

import { TableSortLabel } from '@mui/material';
import { TableSortLabelProps } from '@mui/material/TableSortLabel/TableSortLabel';
import { HeaderContext, SortDirection } from '@tanstack/react-table';

import { ArrowDownSmallIcon } from '@app/common/icons';

export const HeaderSortedWrapper = <T,>({
  children,
  tableSortLabelProps,
  ...props
}: React.PropsWithChildren<HeaderContext<T, any> & { tableSortLabelProps?: TableSortLabelProps }>): JSX.Element => {
  const sorted: false | SortDirection = props.header.column.getIsSorted();

  return (
    <TableSortLabel
      active={Boolean(sorted)}
      direction={sorted ? (sorted as 'asc' | 'desc') : undefined}
      IconComponent={ArrowDownSmallIcon}
      onClick={props.header.column.getToggleSortingHandler()}
      {...tableSortLabelProps}
    >
      {children}
    </TableSortLabel>
  );
};

import React from 'react';

import { FormControlLabel, Grid, Typography, Theme, SxProps } from '@mui/material';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { MoveIcon } from '@app/common/icons';

import { Checkbox } from '../../../../../../Checkbox';

interface UserStatementsItemProps {
  name: string;
  enabled: boolean;
  edit: boolean;
  index: number;
  providedHandleProps?: DraggableProvidedDragHandleProps;
  containerStyle?: SxProps<Theme>;
  onChangeEnabled(id: number, selected: boolean): void;
}

export const UserStatementsItem: React.FC<UserStatementsItemProps> = ({
  name,
  enabled,
  edit,
  index,
  providedHandleProps,
  containerStyle,
  onChangeEnabled,
}) => {
  const onCheckHandler = (event: React.ChangeEvent<{}>, checked: boolean) => {
    onChangeEnabled(index, checked);
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'colors.gray25',
        marginBottom: '4px',
        padding: '12px 20px 12px 31px',
        borderRadius: '4px',
        ...(containerStyle && containerStyle),
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <FormControlLabel
        control={<Checkbox name={name} />}
        label={
          <Typography
            variant="caption"
            sx={{
              color: 'colors.gray600',
              marginLeft: '4px',
              lineHeight: '24px',
            }}
          >
            {name}
          </Typography>
        }
        checked={enabled}
        disabled={!edit}
        onChange={onCheckHandler}
      />
      <span {...providedHandleProps}>
        <MoveIcon />
      </span>
    </Grid>
  );
};

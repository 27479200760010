import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { format } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AccountStatusEnum,
  AvailableActionsDto,
  CurrencyEnum,
  DocumentTemplateDto,
  GetAccountResponseDto,
  PaymentOrderAdditionalInfoDto,
} from '@app/core/api';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGetAccountsForEnrollment } from '@app/core/hooks/useGetAccountsForEnrollment';
import { useGetCurrentAccountsByCurrency } from '@app/core/hooks/useGetCurrentAccountsByCurrency';
import { Severity } from '@app/core/types';
import { formatAmount, isMobile, parseJSON } from '@app/core/utils';
import { DesktopBetweenMyAccountPaymentEditForm } from '@app/pages/payment/components/BetweenMyAccountEditForm/DesktopBetweenMyAccountPaymentEditForm';
import { makeCreateTemplateData } from '@app/pages/payment/components/BetweenMyAccountEditForm/helpers';
import { MobileBetweenMyAccountPaymentEditForm } from '@app/pages/payment/components/BetweenMyAccountEditForm/MobileBetweenMyAccountEditForm';
import {
  BetweenMyAccountFormData,
  BetweenMyAccountFormDefaultValues,
  BetweenMyAccountFormFieldName,
} from '@app/pages/payment/components/BetweenMyAccountEditForm/types';
import { validationSchema } from '@app/pages/payment/components/BetweenMyAccountEditForm/validationSchema';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import { FormFieldName } from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { calculateMaxValuationDate } from '@app/pages/payment/create/LocalPayment/helpers';

export interface BetweenMyAccountEditFormProps {
  defaultValues: BetweenMyAccountFormDefaultValues;
  signPermissions: AvailableActionsDto;
  additionalInfo?: PaymentOrderAdditionalInfoDto | null;
  onSaveClick(paymentData: BetweenMyAccountFormData): void;
  onSaveAndSignClick(paymentData: BetweenMyAccountFormData): void;
  onSaveAndSendToSignClickHandler(paymentData: BetweenMyAccountFormData): void;
  onCancelHandler(): void;
  onTemplateChangeHandler?(templateData: DocumentTemplateDto): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}
export const BetweenMyAccountEditForm: React.FC<BetweenMyAccountEditFormProps> = ({
  defaultValues,
  signPermissions,
  additionalInfo,
  onSaveClick,
  onSaveAndSignClick,
  onCancelHandler,
  onTemplateChangeHandler,
  onChange,
  onSaveAndSendToSignClickHandler,
}) => {
  const { t } = useTranslation();
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const defaultDocumentDate = defaultValues.documentDate ? new Date(defaultValues.documentDate) : new Date();
  const [maxValuationDate, setMaxValuationDate] = useState<Date>(calculateMaxValuationDate(defaultDocumentDate));
  const [minValuationDate, setMinValuationDate] = useState<Date>(defaultDocumentDate);
  const [senderAccountErrorSeverity, setSenderAccountErrorSeverity] = useState<Severity>();
  const [blockAction, setBlockAction] = useState<boolean>(false);
  const {
    data: senderAccountsData,
    mutate: mutateGetSenderAccounts,
    isLoading: isLoadingSenderAccounts,
  } = useGetCurrentAccountsByCurrency();
  const {
    data: recipientAccountsData,
    mutate: mutateGetRecipientAccount,
    isLoading: isLoadingRecipientAccounts,
  } = useGetAccountsForEnrollment();

  useEffect(() => {
    mutateGetSenderAccounts(CurrencyEnum.Uah);
  }, [mutateGetSenderAccounts]);

  const methods = useForm<BetweenMyAccountFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema({ t })),
    defaultValues: {
      // [BetweenMyAccountFormFieldName.recipientAccount]: undefined,
    },
  });

  const {
    reset: formReset,
    control,
    setValue,
    trigger,
    getValues,
    clearErrors,
    setError,
    formState: { isDirty, dirtyFields },
  } = methods;

  useEffect(() => {
    if (defaultValues?.amount) {
      setValue(BetweenMyAccountFormFieldName.amount, `${parseFloat(`${defaultValues.amount}`).toFixed(2)}`);
    }
    if (defaultValues?.paymentReference) {
      setValue(BetweenMyAccountFormFieldName.paymentReference, defaultValues.paymentReference);
    }
    if (defaultValues?.documentDate) {
      setValue(BetweenMyAccountFormFieldName.paymentDocumentDate, new Date(defaultValues.documentDate));
      setValue(
        BetweenMyAccountFormFieldName.temp_paymentDocumentDate,
        format(new Date(defaultValues.documentDate), ddMMYYYYFormat.format)
      );
    }
    if (defaultValues?.valueDate) {
      setValue(BetweenMyAccountFormFieldName.paymentDocumentValueDate, new Date(defaultValues.valueDate));
      setValue(
        BetweenMyAccountFormFieldName.temp_paymentDocumentValueDate,
        format(new Date(defaultValues.valueDate), ddMMYYYYFormat.format)
      );
    }
    if (senderAccountsData && defaultValues?.senderAccountId) {
      const activeAccount = senderAccountsData.accounts.find(
        ({ account }) => account.id === defaultValues.senderAccountId
      );
      if (activeAccount) {
        setValue(BetweenMyAccountFormFieldName.senderAccount, JSON.stringify(activeAccount), {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    }
    if (recipientAccountsData && defaultValues?.recipientAccountId) {
      const activeAccount = recipientAccountsData.find(
        ({ account }) => account.id === defaultValues.recipientAccountId
      );
      if (activeAccount) {
        setValue(BetweenMyAccountFormFieldName.recipientAccount, JSON.stringify(activeAccount), {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    }
  }, [defaultValues, recipientAccountsData, senderAccountsData, setValue]);

  const senderAccountWatch = useWatch({
    control,
    name: BetweenMyAccountFormFieldName.senderAccount,
  });

  useEffect(() => {
    if (senderAccountsData && senderAccountsData.defaultAccount) {
      setValue(BetweenMyAccountFormFieldName.senderAccount, JSON.stringify(senderAccountsData.defaultAccount), {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [senderAccountsData, setValue]);

  useEffect(() => {
    if (senderAccountWatch) {
      const selectedAccount: GetAccountResponseDto = parseJSON(senderAccountWatch) as unknown as GetAccountResponseDto;
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Blocked
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningBlockedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (
        selectedAccount.account.id === senderAccountsData?.defaultAccount?.account.id &&
        senderAccountsData?.defaultAccount?.account.status === AccountStatusEnum.Closed
      ) {
        setError(FormFieldName.senderAccount, { message: t('warningClosedAccount') });
        setSenderAccountErrorSeverity('warning');
        setBlockAction(true);
      }
      if (selectedAccount.account.status === AccountStatusEnum.Active) {
        clearErrors(FormFieldName.senderAccount);
        setBlockAction(false);
      }
    }
  }, [clearErrors, senderAccountsData, setError, t, senderAccountWatch]);

  const amountWatch = useWatch<BetweenMyAccountFormData>({
    control,
    name: BetweenMyAccountFormFieldName.amount,
  });

  useEffect(() => {
    if (senderAccountWatch) {
      const {
        account: { id },
      }: GetAccountResponseDto = JSON.parse(senderAccountWatch);
      mutateGetRecipientAccount({ currency: CurrencyEnum.Uah, excludeAccount: id });
    }
  }, [mutateGetRecipientAccount, senderAccountWatch]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length || senderAccountWatch) {
      onChange({ formDirty: true });
    }
  }, [dirtyFields, onChange, senderAccountWatch]);

  useEffect(() => {
    if (senderAccountWatch && amountWatch) {
      const {
        account: { amount: selectedAccountAmount },
      }: GetAccountResponseDto = JSON.parse(senderAccountWatch);
      setBalance(selectedAccountAmount);
      setShowBalance(true);
    }
  }, [amountWatch, senderAccountWatch]);

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveClick(getValues());
      }
    });
  };

  const onSendToSign = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSendToSignClickHandler(getValues());
      }
    });
  };

  const onSaveAndSignClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSignClick(getValues());
      }
    });
  };

  const createTemplateData = () => {
    return makeCreateTemplateData(getValues());
  };

  const countedBalance = formatAmount(balance - Number(amountWatch));

  const watchDateFrom = useWatch({
    control,
    name: BetweenMyAccountFormFieldName.paymentDocumentDate,
  });

  useEffect(() => {
    if (watchDateFrom) {
      setMaxValuationDate(calculateMaxValuationDate(watchDateFrom));
      setMinValuationDate(watchDateFrom);
      setValue(BetweenMyAccountFormFieldName.paymentDocumentValueDate, undefined, {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue(BetweenMyAccountFormFieldName.temp_paymentDocumentValueDate, '', {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [formReset, setValue, watchDateFrom]);

  const Component = isMobile ? MobileBetweenMyAccountPaymentEditForm : DesktopBetweenMyAccountPaymentEditForm;

  return (
    <FormProvider {...methods}>
      <form style={styles.form}>
        <Component
          defaultValues={defaultValues}
          signPermissions={signPermissions}
          senderAccountsData={senderAccountsData}
          recipientAccountsData={recipientAccountsData}
          showBalance={showBalance}
          countedBalance={countedBalance}
          createTemplateData={createTemplateData}
          additionalInfo={additionalInfo}
          onCancelHandler={onCancelHandler}
          onSaveClickHandler={onSaveClickHandler}
          onSaveAndSignClickHandler={onSaveAndSignClickHandler}
          onTemplateChangeHandler={onTemplateChangeHandler}
          onSendToSign={onSendToSign}
          maxValuationDate={maxValuationDate}
          minValuationDate={minValuationDate}
          errorSeverity={senderAccountErrorSeverity}
          blockAction={blockAction}
        />
      </form>
    </FormProvider>
  );
};

export const statusDescription = new Map([
  /* document verification status */
  [1, 'VFS_INPROGRESS'],
  [2, 'VFS_CERT_NOT_FOUND'],
  [3, 'VFS_CERT_USAGE'],
  [4, 'VFS_ALG_NOT_FOUND'],
  [5, 'VFS_INVALID'],
  [6, 'VFS_BAD_FORMAT'],

  /* basic exceptions */
  [0x70000001, 'ERR_INVALID_FUNCTION'],
  [0x70000002, 'ERR_INVALID_OPERATION'],
  [0x70000003, 'ERR_INVALID_PARAM'],
  [0x70000004, 'ERR_DATA_NOT_FOUND'],
  [0x70000005, 'ERR_DATA_CORRUPTED'],
  [0x70000006, 'ERR_ALG_NOT_FOUND'],
  [0x70000100, 'ERR_TRANSPORT_STATUS'],
  [0x70000800, 'ERR_TSP_STATUS'],
  [-3, 'ERR_IN_OUT'],
  [-9, 'ERR_CORRUPTED'],

  [0x70100001, 'KSE_NOT_IMPLEMENTED'],
  [0x70100002, 'KSE_DATA_NOT_FOUND'],
  [0x70100003, 'KSE_STORE_GENERAL'],
  [0x70100004, 'KSE_INVALID_PASSWORD'],
  [0x70100005, 'KSE_LOGIN_REQUIRED'],
  [0x70100006, 'KSE_STORE_LOCKED'],
  [0x70100007, 'KSE_STORE_NOMEMORY'],

  [0, 'OK_CORE_FUNC'],
  [0x60000100, 'OK_PACK_CREATE'],
  [0x60000101, 'ERR_PACK_CREATE'],

  [0x60000301, 'ERR_CORE_FUNC_UNDEFINED'],
  [0x60000302, 'ERR_REQUIRED_PARAM'],
  [0x60000303, 'ERR_BAD_PARAM'],
  [0x60000304, 'ERR_SETUP_CONFIGURATION'],
  [0x60000305, 'ERR_BAD_CERT'],
  [0x60000306, 'ERR_SAVE_TO_FILE'],
  [0x60000307, 'ERR_EMPTY_FILE'],
  [0x60000308, 'ERR_NO_RECIPIENT'],
  [0x60000309, 'ERR_NO_SIGNERS'],
  [0x60000310, 'ERR_NOT_SELF_SIGNED'],

  [-100, 'ERR_UNDEFINED'],
]);

export const vfsErroStatusDescription = new Map([
  [0, 'CVS_VALID'],
  [-1, 'CVS_REVOKED'],
  [-2, 'CVS_NOT_EFFECTIVE'],
  [-3, 'CVS_CRL_NOT_EFFECTIVE'],
  [-4, 'CVS_BROKEN'],
  [-5, 'CVS_CRL_BROKEN'],
  [-6, 'CVS_VIOLATE_POLICY'],
  [-7, 'CVS_CRL_VIOLATE_POLICY'],
  [-8, 'CVS_UNKNOWN_CA'],
  [-9, 'CVS_UNKNOWN_CRL'],
  [-10, 'CVS_UNREFERENCED_CA'],
  [-12, 'CVS_VIOLATE_USAGE'],
  [-13, 'CVS_STORAGE_ERROR'],
  [-14, 'CVS_OCSP_TRANSPORT_ERROR'],
  [-15, 'CVS_OCSP_CERT_ERROR'],
  [-16, 'CVS_OCSP_BROKEN'],
  [-17, 'CVS_OCSP_DIFFERENT_TIME'],
  [-18, 'CVS_ALG_NOT_SUPPORTED'],
  [-19, 'CVS_OCSP_CERT_CA_ERROR'],
]);

export const OP_STATUS_KIND = {
  NO_VFS_ERROR: 1,
  VFS_ERROR: 2,
};

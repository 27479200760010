import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

export type EllipsisProps = {
  styles?: SxProps<Theme>;
  text: string | number | React.ReactNode;
  width?: string | number;
  maxWidth?: string | number;
  component?: React.FC;
  componentProps?: any;
  direction?: 'left' | 'right';
};

export const Ellipsis: React.FC<EllipsisProps> = ({
  styles,
  width,
  maxWidth,
  text,
  component: Component,
  componentProps,
  direction,
}) => {
  const contentStyles = Component ? { maxWidth: '100%' } : { width, maxWidth };
  const directionClassName = `${direction === 'right' && 'right'} ${direction === 'left' && 'left'}`;

  const content: JSX.Element = (
    <Box
      style={contentStyles}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&.left': {
          direction: 'rtl',
          textAlign: 'left',
        },
        '&.right': {
          direction: 'ltr',
          textAlign: 'right',
        },
        ...styles,
      }}
      className={directionClassName}
    >
      {text}
    </Box>
  );

  if (Component) {
    return (
      <Component style={{ width, maxWidth }} {...componentProps}>
        {content}
      </Component>
    );
  }

  return content;
};

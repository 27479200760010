export const styles = {
  root: {},
  cell: {
    flex: '1 auto',
    minWidth: '40px',
    padding: '10px',
  },
  cell_date: { maxWidth: '180px' },
  cell_user: {},
  cell_duration: { maxWidth: '180px' },
  cell_browser: { maxWidth: '200px' },
  cell_operationSystem: { maxWidth: '180px' },
  cell_ip: { maxWidth: '120px' },
  cell_country: { maxWidth: '120px' },
  cell_actions: { maxWidth: '5px' },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
  table: {
    flexWrap: 'nowrap',
  },
  searchActive: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: '4px',
  },
  activeSearchAlign: {
    display: 'flex',
    flexDirection: 'initial',
  },
};

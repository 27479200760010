import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { CrossIcon, EraserIcon, SearchSmallIcon } from '@app/common/icons';
import { InternationalBankDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { BicColumnFilter, TextFieldColumnFilter } from '@app/core/components/ComplexTable/components/TableColumnFilter';
import { formatBIC } from '@app/core/utils';
import { styles } from '@app/pages/banksSwift/components/SwiftBankTable/styles';
import { SwiftBankColumnEnum } from '@app/pages/banksSwift/types';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  t(text: string): string;
  onSearchButtonClick(): void;
  onClearSubFilter(): void;
  onCloseSubFilter(): void;
}

export const makeTableColumns = ({
  showSubFilters,
  t,
  onSearchButtonClick,
  onClearSubFilter,
  onCloseSubFilter,
}: MakeTableColumnsProps): ColumnDef<InternationalBankDto, any>[] => {
  return [
    {
      accessorKey: SwiftBankColumnEnum.swift,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t(`swiftBic`)}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <BicColumnFilter name={SwiftBankColumnEnum.swift} column={props.column} table={props.table} submit />
            </Box>
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<InternationalBankDto, string>) => {
        return formatBIC(getValue());
      },
    },
    {
      accessorKey: SwiftBankColumnEnum.name,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t(`banks_${SwiftBankColumnEnum.name}`)}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <TextFieldColumnFilter name={SwiftBankColumnEnum.name} column={props.column} table={props.table} submit />
            </Box>
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: SwiftBankColumnEnum.location,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t(`banks_${SwiftBankColumnEnum.location}`)}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <TextFieldColumnFilter
                name={SwiftBankColumnEnum.location}
                column={props.column}
                table={props.table}
                submit
              />
            </Box>
          ) : null}
        </Grid>
      ),
    },
    {
      id: 'actions',
      header: ({ table }) => {
        const onSearchButtonClickHandler = () => {
          if (table.getRowModel().rows.length) {
            onSearchButtonClick();
          }
        };
        return (
          <Grid container justifyContent="flex-end">
            <div>
              {showSubFilters ? (
                <IconButton onClick={onClearSubFilter} size="large" sx={{ marginRight: '6px' }}>
                  <EraserIcon fontSize="small" />
                </IconButton>
              ) : null}
              {showSubFilters ? (
                <IconButton size="small" onClick={onCloseSubFilter}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={onSearchButtonClickHandler}>
                  <SearchSmallIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        );
      },
    },
  ];
};

export const styles = {
  actionsContainer: {
    padding: '5px 16px 5px 0',
  },
  selectedActionsContainer: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '5px 16px',
    minHeight: '54px',
  },
  selectedCount: {
    color: 'white',
    marginRight: '16px',
    minWidth: '64px',
  },
  buttonRoot: {
    color: '#ffffff',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-endIcon': {
      padding: 0,
      color: 'white',
    },
  },
  divider: {
    height: '20px',
    backgroundColor: 'colors.gray100',
    marginRight: '8px',
    marginLeft: '8px',
    width: '1px',
    flexShrink: 0,
  },
  selectedText: {
    color: '#ffffff',
  },
  sticky: {
    position: 'sticky',
    top: '5px',
    zIndex: 1,
    background: '#fff',
  },
  menuItem: {
    borderBottom: '1px solid',
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

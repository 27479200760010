import React, { useEffect, useRef } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FrontLanguageEnum } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetCountryCodes } from '@app/core/hooks/useGetCountryCode';
import { ColumnFilterType, Langs, PaginationChangeType, SortingRule } from '@app/core/types';
import { CountryCodesTable } from '@app/pages/countryCodes/components/CountryCodesTable';
import { CountryCodesColumnEnum } from '@app/pages/countryCodes/types';

export const PageContent: React.FC = () => {
  const columnFilter = useRef<ColumnFilterType<CountryCodesColumnEnum>>({});
  const { i18n } = useTranslation();
  const currentLang: Langs = i18n.language as Langs;
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });

  const { data, mutate, isLoading } = useGetCountryCodes();

  const submitFilter = () => {
    const { name, alpha3, numeric } = columnFilter.current;
    mutate({
      name,
      alpha3,
      numeric: numeric && numeric?.length > 0 ? numeric : undefined,
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  };

  const onSortHandler = (rule: SortingRule) => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    submitFilter();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    submitFilter();
  };

  useEffect(() => {
    mutate({
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  }, [currentLang, mutate]);

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<CountryCodesColumnEnum>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (submit) {
      if (isLoading) {
        return;
      }
      submitFilter();
    }
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (isLoading) {
      return;
    }
    submitFilter();
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          mt: 4,
          height: '100%',
        }}
      >
        <CountryCodesTable
          dataCountryCodes={data?.rows || []}
          pagination={data?.pagination}
          loading={isLoading}
          columnFilter={columnFilter.current}
          onSort={onSortHandler}
          onPaginationChange={onPaginationChangeHandler}
          onColumnFilterChange={onColumnFilterChangeHandler}
          onClearSubFilter={onClearSubFilterHandler}
        />
      </Grid>
    </Grid>
  );
};

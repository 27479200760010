import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  CreateCurrencyOperationRequestDto,
  CreateCurrencyOperationResponseDto,
  CurrencyOperationsExchangeTypeEnum,
  DocumentOperationType,
  GetCurrencyOperationDto,
  GetOneCurrencyOperationResponseDto,
  ResultStatusEnum,
  SendToSignResponseDto,
} from '@app/core/api';
import { SignCurrencyExchangeMultiple } from '@app/core/components';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useCurrencyUploadFiles, useGetCurrencyExchangePaymentSigners } from '@app/core/hooks';
import { FileType, MutationStatus } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { CurrencyForm, EditData } from '@app/pages/exchange/components/form';
import { makeCurrencyOperationFromFormData } from '@app/pages/exchange/components/form/helpers';
import { CurrencyFormData, CurrencyFormDefaultValues } from '@app/pages/exchange/components/form/types';
import { CreateExchangeMenuTab } from '@app/pages/exchange/create/types';
import {
  createCurrencyOperationFn,
  getCurrencyOperationFn,
  sendToSignCurrencyOperationFn,
} from '@app/pages/exchange/query';
import { styles } from '@app/pages/exchange/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const CreateExchangePage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const showLeavePopupRef = useRef<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<CreateExchangeMenuTab>(CreateExchangeMenuTab.sale);
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const [createdCurrencyOperation, setCreatedCurrencyOperation] = useState<GetCurrencyOperationDto>();
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean | undefined>(false);
  const [showConfirmChangeTab, setShowConfirmChangeTab] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<CurrencyFormDefaultValues>();
  const urlQueryValues = useLocation().search;
  const { copyFrom: copyFromUuid } = parse(urlQueryValues);

  const { data: signersData, isLoading: signersLoading } = useGetCurrencyExchangePaymentSigners();

  const {
    data,
    mutate: mutateCopyFromPaymentExchange,
    isLoading: mutateLoading,
  } = useMutation<GetOneCurrencyOperationResponseDto, ApiError, string>(getCurrencyOperationFn);

  useEffect(() => {
    if (copyFromUuid) {
      mutateCopyFromPaymentExchange(copyFromUuid as string);
    }
  }, [copyFromUuid, mutateCopyFromPaymentExchange]);

  useEffect(() => {
    if (data) {
      setDefaultValues({
        amount: `${data.amount}`,
        depositAccount: JSON.stringify(data.depositAccount),
        withdrawalAccount: JSON.stringify(data.withdrawalAccount),
        temp_documentDate: format(new Date(data.documentDate), ddMMYYYYFormat.format),
        documentValueDate: data.documentValueDate ? new Date(data.documentValueDate) : undefined,
        temp_documentValueDate: data.documentValueDate
          ? format(new Date(data.documentValueDate), ddMMYYYYFormat.format)
          : undefined,
        exchangeRate: data.exchangeRate ? `${data.exchangeRate}` : undefined,
        exchangeRateType: data.exchangeRateType,
        currencyOperationReason: data.currencyOperationReason,
        _userConfirmCheck: false,
      });
    }
  }, [data]);

  const menuItems: MenuItem<CreateExchangeMenuTab>[] = [
    {
      id: 1,
      value: CreateExchangeMenuTab.conversion,
      labelText: t('createExchangeMenu_conversion'),
    },
    {
      id: 2,
      value: CreateExchangeMenuTab.sale,
      labelText: t('createExchangeMenu_sale'),
    },
    {
      id: 3,
      value: CreateExchangeMenuTab.purchase,
      labelText: t('createExchangeMenu_purchase'),
    },
  ];

  const { data: createCurrencyOperationResultData, mutateAsync: mutateAsyncCurrencyOperation } = useMutation<
    CreateCurrencyOperationResponseDto,
    ApiError,
    CreateCurrencyOperationRequestDto
  >(createCurrencyOperationFn);

  const { data: sendToSignPaymentResultData, mutate: sendToSignMutation } = useMutation<
    SendToSignResponseDto,
    ApiError,
    string
  >(sendToSignCurrencyOperationFn);

  const { mutateAsync: mutateAsyncUploadFiles } = useCurrencyUploadFiles();

  const onFilterChangeHandler = (filter: CreateExchangeMenuTab) => {
    setCurrentFilter(filter);
    showLeavePopupRef.current = false;
  };

  const showLeavePopup = (): boolean => {
    return showLeavePopupRef.current;
  };

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
    setShowConfirmChangeTab(formDirty);
  };

  const onCloseSignCurrencyModalHandler = (status: MutationStatus) => {
    setShowSignatureDialog(false);
    if (createdCurrencyOperation && status !== 'success') {
      navigate(RouteList.exchange_edit.replace(':uuid', createdCurrencyOperation.uuid));
    } else {
      navigate(-1);
    }
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSignatureDialog(false);
    navigate(-1);
  };

  const onSuccess = () => {
    notify({
      notifyProps: {
        title: t('currencyExchange_saveSuccessTitle'),
        message: t('currencyExchange_saveSuccessMessage'),
        severity: 'success',
      },
    });
    showLoader(false);
    navigate(-1);
  };

  const saveOperation = ({
    formData,
    files,
    currencyExchangeType,
    onSuccessSave,
  }: {
    formData: CurrencyFormData;
    files: FileType[];
    currencyExchangeType: CurrencyOperationsExchangeTypeEnum;
    onSuccessSave(result: CreateCurrencyOperationResponseDto): void;
  }) => {
    mutateAsyncCurrencyOperation({
      currencyExchangeType,
      operation: makeCurrencyOperationFromFormData(formData),
    })
      .then((saveOperationResponse) => {
        if (saveOperationResponse.operation) {
          if (files.length) {
            mutateAsyncUploadFiles({
              currencyOperationIds: [saveOperationResponse.operation.uuid],
              file: files.map((file) => file.file),
              operationsTypes: [DocumentOperationType.CurrencyOperation],
            })
              .then(() => {
                onSuccessSave(saveOperationResponse);
              })
              .catch(() => {
                notify({
                  notifyProps: {
                    title: t('attachFile_ErrorTitle'),
                    message: t('attachFile_ErrorMessage'),
                    severity: 'error',
                  },
                });
                showLoader(false);
              });
          } else {
            onSuccessSave(saveOperationResponse);
          }
        }
        if (saveOperationResponse.additionalInfo) {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('currencyExchange_saveErrorTitle'),
              message: t('currencyExchange_saveErrorMessage'),
              severity: 'error',
            },
          });
        }
      })
      .catch((e: ApiError) => {
        showLoader(false);
        notify({
          notifyProps: {
            title: t('currencyExchange_saveErrorTitle'),
            message: t('currencyExchange_saveErrorMessage'),
            severity: 'error',
          },
        });
      });
  };
  const onSaveHandler = (editData: EditData) => {
    showLoader(true);
    saveOperation({
      ...editData,
      onSuccessSave: onSuccess,
    });
  };

  const onSendToSignHandler = (editData: EditData) => {
    showLoader(true);
    saveOperation({
      ...editData,
      onSuccessSave(saveOperationResponse: CreateCurrencyOperationResponseDto) {
        showLoader(false);
        if (saveOperationResponse.operation) {
          sendToSignMutation(saveOperationResponse.operation.uuid);
        }
      },
    });
  };

  const onSaveAndSignHandler = async (editData: EditData, signFromAnotherPers?: boolean) => {
    showLoader(true);
    saveOperation({
      ...editData,
      onSuccessSave(saveOperationResponse: CreateCurrencyOperationResponseDto) {
        showLoader(false);
        if (saveOperationResponse.operation) {
          setCreatedCurrencyOperation(saveOperationResponse.operation);
          setSignFromAnotherPerson(signFromAnotherPers);
          setShowSignatureDialog(true);
        }
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.exchange);
    }
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Error) {
      notify({
        notifyProps: {
          title: t('payment_save_error'),
          message: t('send_to_sign_payment_error'),
          severity: 'error',
        },
      });
      if (sendToSignPaymentResultData?.errors?.uuid) {
        navigate(RouteList.exchange_edit.replace(':uuid', sendToSignPaymentResultData.errors.uuid));
      } else {
        navigate(RouteList.exchange);
      }
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  useEffect(() => {
    showLoader(signersLoading || mutateLoading);
  }, [signersLoading, mutateLoading, showLoader]);

  return (
    <>
      <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
        <PageHeader
          title={t('currencyExchange_createTitle')}
          showLeavePopup={showLeavePopup}
          backLink={-1}
          confirmQuestionText={t('confirmLeaveCreateExchangePopupMessage')}
          tooltip={t('tooltip_currency-operations-create')}
          showNavigateButton={!isMobile}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Grid container direction="column" wrap="nowrap">
            <Box p={isMobile ? 2 : 0} width="100%" sx={styles.menuWrapper}>
              <HorizontalMenu<CreateExchangeMenuTab>
                defaultActiveItem={CreateExchangeMenuTab.sale}
                onChange={onFilterChangeHandler}
                menuItems={menuItems}
                showConfirmPopup={showConfirmChangeTab}
                confirmPopupMessage={t('exchange_confirmChangeTab')}
                styles={{
                  root: {
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                  },
                  item: {
                    marginRight: '16px',
                  },
                  itemDefault: {
                    color: 'colors.gray400',
                    fontWeight: 600,
                  },
                }}
              />
            </Box>
            <Box mt={7}>
              {currentFilter === CreateExchangeMenuTab.conversion && (
                <CurrencyForm
                  defaultValues={defaultValues}
                  onChange={onChangeHandler}
                  documents={[]}
                  personToSign={signersData?.personToSign || []}
                  currencyExchangeType={CurrencyOperationsExchangeTypeEnum.Conversion}
                  signDocumentsButtonEnabled={false}
                  signPermissions={{
                    ...permissions.sign.currencyOperation,
                    allowSignFromAnotherPerson: permissions.sign.currencyOperation.signFromAnotherPerson,
                  }}
                  additionalInfo={createCurrencyOperationResultData?.additionalInfo}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onSendToSign={onSendToSignHandler}
                />
              )}
              {currentFilter === CreateExchangeMenuTab.sale && (
                <CurrencyForm
                  defaultValues={defaultValues}
                  onChange={onChangeHandler}
                  documents={[]}
                  personToSign={signersData?.personToSign || []}
                  signDocumentsButtonEnabled={false}
                  signPermissions={{
                    ...permissions.sign.currencyOperation,
                    allowSignFromAnotherPerson: permissions.sign.currencyOperation.signFromAnotherPerson,
                  }}
                  additionalInfo={createCurrencyOperationResultData?.additionalInfo}
                  currencyExchangeType={CurrencyOperationsExchangeTypeEnum.Sale}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onSendToSign={onSendToSignHandler}
                />
              )}
              {currentFilter === CreateExchangeMenuTab.purchase && (
                <CurrencyForm
                  defaultValues={defaultValues}
                  currencyExchangeType={CurrencyOperationsExchangeTypeEnum.Purchase}
                  documents={[]}
                  personToSign={signersData?.personToSign || []}
                  signDocumentsButtonEnabled={false}
                  signPermissions={{
                    ...permissions.sign.currencyOperation,
                    allowSignFromAnotherPerson: permissions.sign.currencyOperation.signFromAnotherPerson,
                  }}
                  additionalInfo={createCurrencyOperationResultData?.additionalInfo}
                  onChange={onChangeHandler}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onSendToSign={onSendToSignHandler}
                />
              )}
            </Box>
          </Grid>
        </PageContainer>
      </PageWrapper>

      {showSignatureDialog && createdCurrencyOperation ? (
        <SignCurrencyExchangeMultiple
          operations={[createdCurrencyOperation]}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessPopupCancelHandler}
          onClose={onCloseSignCurrencyModalHandler}
        />
      ) : null}
    </>
  );
};

export const styles = {
  cardContainer: {
    backgroundColor: 'colors.primary25',
    padding: '20px',
    marginTop: '20px',
    boxShadow: '0px 2px 7px rgba(134, 142, 150, 0.2), 0px 7px 20px rgba(222, 226, 230, 0.4)',
    zIndex: 999,
  },
  greenBadgeStyle: {
    border: `1px solid`,
    borderColor: 'colors.success',
    borderRadius: '50px',
    padding: '3px 8px 3px 8px',
    background: 'colors.successBackground',
    color: 'colors.success',
  },
  redBadgeStyle: {
    border: `1px solid`,
    borderColor: 'colors.error',
    borderRadius: '50px',
    padding: '3px 8px 3px 8px',
    background: 'colors.errorBackground',
    color: 'colors.error',
  },
  infoBadgeStyle: {
    marginLeft: '4px',
    border: `1px solid`,
    borderColor: 'colors.primary500',
    borderRadius: '50px',
    padding: '3px 8px 3px 8px',
    background: 'colors.primary25',
    color: 'colors.primary500',
  },
  chevron: {
    position: 'absolute',
    right: '40px',
    marginTop: '90px',
  },
  badgeContainer: {
    marginBottom: '12px',
    marginTop: '12px',
  },
};

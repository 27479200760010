import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  getClientId,
  templatesApiClient,
  ApiError,
  GetTemplatesListQueryDto,
  GetTemplatesListResponseDto,
} from '@app/core/api';

const getPaymentTemplatesQueryFn = async (
  getTemplatesListQueryDto: GetTemplatesListQueryDto
): Promise<GetTemplatesListResponseDto> => {
  const response = await templatesApiClient.paymentTemplateControllerGetAllTemplates(
    getClientId(),
    getTemplatesListQueryDto
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get counterparties!");
  }

  return response.data;
};

export const useGetPaymentTemplates = (): UseMutationResult<
  GetTemplatesListResponseDto,
  ApiError,
  GetTemplatesListQueryDto
> => useMutation<GetTemplatesListResponseDto, ApiError, GetTemplatesListQueryDto>(getPaymentTemplatesQueryFn);

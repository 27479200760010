import React, { useEffect } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { NotificationEventEnum, SubmitUserNotificationEvent, UserNotificationEvent } from '@app/core/types';
import { styles } from '@app/pages/profile/desktop/components/UserNotifications/styles';
import { appConfig } from '@app/src/config';

type SubmitDataProps = { [key: string]: boolean };

export const UserNotifications: React.FC = (): JSX.Element => {
  const { data, refetch } = useQuery<UserNotificationEvent[]>('userSettingsNotificationData', () => [], {
    enabled: false,
  });
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = methods;
  const { isDirty, dirtyFields } = formState;
  const { mutate } = useMutation(async (props: SubmitUserNotificationEvent[]) => {});

  useEffect(() => {
    refetch();
  }, [refetch]);

  // TODO: define type for submitData
  const onSubmit = (dataForm: SubmitDataProps) => {
    const submitData: SubmitUserNotificationEvent[] = [];
    Object.keys(dirtyFields).forEach((field) => {
      const [NotificationEventType, NotificationEventName] = field.split('-');
      const notifyConfigIndex = submitData.findIndex((notifyConfig) => notifyConfig.type === NotificationEventType);
      if (notifyConfigIndex >= 0) {
        submitData[notifyConfigIndex] = {
          ...submitData[notifyConfigIndex],
          [NotificationEventName]: dataForm[field],
        };
      } else {
        submitData.push({
          type: NotificationEventType as NotificationEventEnum,
          [NotificationEventName]: dataForm[field],
        });
      }
    });

    mutate(submitData);
  };

  const { t } = useTranslation();

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {data && appConfig.enableUserNotification ? (
            <Box sx={styles.root}>
              <Grid container sx={styles.headNotificationsTable}>
                <Grid item xs={6}>
                  <Typography variant="caption">{t('event')}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">E-mail</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{t('sms')}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" sx={styles.bodyNotificationsTable}>
                {data.map((notification) => {
                  return (
                    <Grid item container sx={styles.item} key={notification.type}>
                      <Grid item xs={6}>
                        <Typography variant="caption">{t(`notification_${notification.type}`)}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Box pl={3}>
                          <CheckboxField
                            label={null}
                            name={`${notification.type}-email`}
                            defaultChecked={notification.email.active}
                            disabledValue={!notification.email.edit}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        {notification.sms ? (
                          <Box pl={3}>
                            <CheckboxField
                              label={null}
                              name={`${notification.type}-sms`}
                              defaultChecked={notification.sms.active}
                              disabledValue={!notification.sms.edit}
                            />
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : null}
          {isDirty ? (
            <Box mt={4}>
              <Grid justifyContent="flex-end" container>
                <Button variant="contained" size="small" color="primary" type="submit">
                  {t('save')}
                </Button>
              </Grid>
            </Box>
          ) : null}
        </form>
      </FormProvider>
    </>
  );
};

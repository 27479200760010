import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, DepositDto, depositsApiClient, getClientId } from '@app/core/api';

const getOneDepositQueryFn = async (uuid: string): Promise<DepositDto> => {
  const response = await depositsApiClient.depositsControllerGetOne(getClientId(), uuid);
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant get deposit!');
  }
  return response.data;
};

export const useOneGetDeposit = (): UseMutationResult<DepositDto, ApiError, string> => {
  return useMutation<DepositDto, ApiError, string>(getOneDepositQueryFn);
};

export enum OverdraftsTypeEnum {
  open = 'open',
  closed = 'closed',
}

export enum OverdraftsTableColumns {
  startDate = 'startDate',
  endDate = 'endDate',
  accountOverdraft = 'accountOverdraft',
  totalLimit = 'totalLimit',
  usedLimit = 'usedLimit',
  availableLimit = 'availableLimit',
  currency = 'currency',
  interestRate = 'interestRate',
  uuid = 'uuid',
}

export enum OverdraftsFilterField {
  agreementStartDateFrom = 'agreementStartDateFrom',
  agreementStartDateTo = 'agreementStartDateTo',
  agreementEndDateFrom = 'agreementEndDateFrom',
  agreementEndDateTo = 'agreementEndDateTo',
  accountOverdraft = 'accountOverdraft',
  currency = 'currency',
  amountFrom = 'amountFrom',
  amountTo = 'amountTo',
  interestRateFrom = 'interestRateFrom',
  interestRateTo = 'interestRateTo',
  agreementNumber = 'agreementNumber',
  temp_agreementStartDateFrom = 'temp_agreementStartDateFrom',
  temp_agreementStartDateTo = 'temp_agreementStartDateTo',
  temp_agreementEndDateFrom = 'temp_agreementEndDateFrom',
  temp_agreementEndDateTo = 'temp_agreementEndDateTo',
}

export type OverdraftsFilterFormData = {
  [OverdraftsFilterField.agreementStartDateFrom]?: Date;
  [OverdraftsFilterField.agreementStartDateTo]?: Date;
  [OverdraftsFilterField.agreementEndDateFrom]?: Date;
  [OverdraftsFilterField.agreementEndDateTo]?: Date;
  [OverdraftsFilterField.accountOverdraft]?: string;
  [OverdraftsFilterField.amountFrom]?: string;
  [OverdraftsFilterField.amountTo]?: string;
  [OverdraftsFilterField.currency]?: string;
  [OverdraftsFilterField.interestRateFrom]?: string;
  [OverdraftsFilterField.interestRateTo]?: string;
  [OverdraftsFilterField.agreementNumber]?: string;
  [OverdraftsFilterField.temp_agreementStartDateFrom]?: string;
  [OverdraftsFilterField.temp_agreementStartDateTo]?: string;
  [OverdraftsFilterField.temp_agreementEndDateFrom]?: string;
  [OverdraftsFilterField.temp_agreementEndDateTo]?: string;
};

export enum OverdraftMenuItems {
  operations = 'operations',
  graph = 'graph',
  safety = 'safety',
}

export enum SafetyTableColumns {
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  agreementNumber = 'agreementNumber',
  securityDescription = 'securityDescription',
  lastEvaluationDate = 'lastEvaluationDate',
  amount = 'amount',
  currencyCode = 'currencyCode',
  state = 'state',
}

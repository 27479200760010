import { object, string } from 'yup';

import { PassportTabsEnum } from '@app/core/components/PassportTabs';
import { isValidUAIBAN, parseJSON } from '@app/core/utils';
import { FormFieldName } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';

type NewPaymentValidationSchemaProps = {
  t: (key: string) => string;
  validationsFormFields: FormFieldName[];
};

export const makeValidationSchemaConfig = (
  recipientNonLeiFlag: boolean,
  passportActiveTab: string,
  setValidationsFormFields: (fieldConfig: FormFieldName[]) => void
): void => {
  const validationsFormFields: FormFieldName[] = [
    FormFieldName.templateName,
    FormFieldName.recipientAccount,
    FormFieldName.recipientLei,
    FormFieldName.recipientName,
    FormFieldName.amount,
    FormFieldName.senderAccount,
    FormFieldName.purpose,
  ];
  if (!recipientNonLeiFlag) {
    setValidationsFormFields([...validationsFormFields, FormFieldName.recipientLei]);
  }
  if (recipientNonLeiFlag && passportActiveTab === PassportTabsEnum.passport) {
    setValidationsFormFields([...validationsFormFields, FormFieldName.recipientPassport]);
  }
  if (recipientNonLeiFlag && passportActiveTab === PassportTabsEnum.idPassport) {
    setValidationsFormFields([...validationsFormFields, FormFieldName.idPassport]);
  }
};

export const validationSchema = ({ t, validationsFormFields }: NewPaymentValidationSchemaProps) => {
  const schema = validationsFormFields.reduce((acc, field) => {
    switch (field) {
      case FormFieldName.templateName:
        return {
          ...acc,
          [FormFieldName.templateName]: string().required(t('saveTemplateTitle')),
        };
      case FormFieldName.recipientAccount:
        return {
          ...acc,
          [FormFieldName.recipientAccount]: string()
            .required(t('paymentCreationLocalPayment_field_required'))
            .test(
              FormFieldName.recipientAccount,
              t('paymentCreationLocalPayment_recipient_error_format'),
              (iban: string | undefined): boolean => {
                if (iban) {
                  return isValidUAIBAN(iban);
                }
                return false;
              }
            )
            .test(
              FormFieldName.recipientAccount,
              t('paymentCreationLocalPayment_sender_recipient_identical_error'),
              (iban: string | undefined, context): boolean => {
                const { accountNumber } = parseJSON(context.parent[FormFieldName.senderAccount]);
                return iban !== accountNumber;
              }
            ),
        };
      case FormFieldName.recipientLei:
        return {
          ...acc,
          [FormFieldName.recipientLei]: string()
            .required(t('paymentCreationLocalPayment_field_required'))
            .test('lei', t('paymentCreationLocalPayment_lei_error'), (val) => val?.length === 8 || val?.length === 10),
        };
      case FormFieldName.recipientName:
        return {
          ...acc,
          [FormFieldName.recipientName]: string().required(t('paymentCreationLocalPayment_field_required')),
        };
      case FormFieldName.amount:
        return {
          ...acc,
          [FormFieldName.amount]: string()
            .min(1, t('paymentCreationLocalPayment_field_required'))
            .required(t('paymentCreationLocalPayment_field_required'))
            .test(FormFieldName.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
              if (amount) {
                return parseFloat(amount) >= 0.01;
              }
              return false;
            }),
        };
      case FormFieldName.senderAccount:
        return {
          ...acc,
          [FormFieldName.senderAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
        };
      case FormFieldName.idPassport:
        return {
          ...acc,
          [FormFieldName.idPassport]: string()
            .required(t('paymentCreationLocalPayment_field_required'))
            .min(10, t('paymentCreationLocalPayment_correct_passport')),
        };
      case FormFieldName.recipientPassport:
        return {
          ...acc,
          [FormFieldName.recipientPassport]: string()
            .required(t('paymentCreationLocalPayment_field_required'))
            .min(9, t('paymentCreationLocalPayment_correct_passport')),
        };
      case FormFieldName.purpose:
        return {
          ...acc,
          [FormFieldName.purpose]: string()
            .required(t('paymentCreationLocalPayment_field_required'))
            .trim()
            .min(3, t('paymentCreationLocalPayment_field_minLetters'))
            .test('purpose', t('paymentCreationLocalPayment_field_minLetters'), (val) => {
              if (val && val.length === 3) {
                return !/\s/g.test(val.slice(0, 2));
              }
              return true;
            }),
        };
      default:
        return acc;
    }
  }, {});
  return object().shape(schema);
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { groupBy, map as lodashMap } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { SheetProvider } from '@app/context/SheetContext';
import { useUIState } from '@app/context/UIContext';
import { DateRangeDto, UserSessionDto } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetCompanyHistoryOfVisit } from '@app/core/hooks';
import { PaginationChangeType } from '@app/core/types';
import { hasNextPage } from '@app/core/utils';
import { CompanyVisitsGroup } from '@app/pages/companyProfile/mobile/components/MobileHistoryOfVisits/components/CompanyVisitsGroup';
import { SheetHeader } from '@app/pages/companyProfile/mobile/components/MobileHistoryOfVisits/components/SheetHeader';
import { CompanyVisitsGroupType } from '@app/pages/companyProfile/types';

const SHEET_MAX_DIFF = 120;
const SHEET_MIN_DIFF = 120;

export const MobileHistoryOfVisitsPage: React.FC = () => {
  const { sidebarOpen } = useUIState();
  const { t } = useTranslation();
  const bottomSheetRef = useRef<BottomSheetRef | null>(null);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const dateFilterRef = useRef<DateRangeDto>();
  const [showBottomAnchor, setShowBottomAnchor] = useState<boolean>(false);
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });
  const visitsListRef = useRef<Array<UserSessionDto>>([]);
  const [visitsList, setVisitsList] = useState<UserSessionDto[]>([]);

  const { data, mutate, reset, isLoading } = useGetCompanyHistoryOfVisit();

  const setDateRange = useCallback(
    (range: DateRangeDto) => {
      reset();
      dateFilterRef.current = range;
      visitsListRef.current = [];
      paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE };

      if (dateFilterRef.current) {
        mutate({
          pagination: paginationRef.current,
          dateRange: dateFilterRef.current,
        });
      }
    },
    [mutate, reset]
  );

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
      dateRange: dateFilterRef.current,
    });
  }, [mutate]);

  useEffect(() => {
    const pagination = data?.pagination;
    if (pagination && bottomAnchorInView && !isLoading) {
      setShowBottomAnchor(false);
      if (hasNextPage(pagination)) {
        paginationRef.current = {
          page: pagination.page + 1,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        };

        mutate({
          pagination: paginationRef.current,
          dateRange: dateFilterRef.current,
        });
      }
    }
  }, [bottomAnchorInView, data, dateFilterRef, isLoading, mutate]);

  useEffect(() => {
    if (data) {
      visitsListRef.current = visitsListRef.current.concat(data.sessions);
      setVisitsList(visitsListRef.current);
      setShowBottomAnchor(true);
    }
  }, [data]);

  const visitsGroup = useMemo(() => {
    const groups: CompanyVisitsGroupType[] = visitsList.length
      ? lodashMap(
          groupBy<UserSessionDto>(visitsList, (item: UserSessionDto) => {
            const date = parseISO(new Date(item.startedAt).toISOString());
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0).toISOString();
          }),
          (items, key) => {
            return {
              date: key,
              visits: items,
            };
          }
        )
      : [];
    return groups.length ? (
      groups.map((visitGroup, idx) => <CompanyVisitsGroup key={`${idx}_${visitGroup.date}`} visitGroup={visitGroup} />)
    ) : (
      <Grid container justifyContent="center">
        <Box mt={4} mr={10} ml={10}>
          <Typography variant="body2" align="center" component="div" sx={{ color: 'colors.gray400' }}>
            {t('emptySessionList')}
          </Typography>
        </Box>
      </Grid>
    );
  }, [t, visitsList]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
      }}
    >
      <SheetProvider>
        <BottomSheet
          open={true}
          skipInitialTransition
          expandOnContentDrag
          blocking={false}
          ref={bottomSheetRef}
          scrollLocking
          style={{
            width: '100vw',
            transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            marginLeft: sidebarOpen ? '272px' : 'auto',
          }}
          defaultSnap={({ snapPoints }) => {
            return Math.min(...snapPoints);
          }}
          snapPoints={({ maxHeight, height }) => {
            const sheetMaxHeight = maxHeight - SHEET_MAX_DIFF;
            const sheetMinHeight = maxHeight - SHEET_MIN_DIFF;
            return [sheetMaxHeight, sheetMinHeight];
          }}
          header={<SheetHeader setDateFilter={setDateRange} />}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              overflowX: 'hidden',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <>
              {visitsGroup}
              <Box
                ref={bottomAnchorRef}
                sx={{
                  height: '10px',
                }}
                style={{ display: showBottomAnchor ? 'block' : 'none' }}
              />
            </>
          </Box>
        </BottomSheet>
      </SheetProvider>
    </Grid>
  );
};

import { InternalMessageSubFiltersDto, MessageStatus, MessageType } from '@app/core/api';
import { LettersSubFilterType } from '@app/pages/letters/types';

export const makeLettersSubFilter = (subFilterProps: LettersSubFilterType): InternalMessageSubFiltersDto => {
  const { createdAt, senderName, category, subject, status } = subFilterProps;

  const subFilter: InternalMessageSubFiltersDto = {};
  if (createdAt) {
    subFilter.createdAt = {
      query: createdAt.toISOString(),
      strict: false,
    };
  }
  if (senderName) {
    subFilter.senderName = {
      query: senderName,
      strict: false,
    };
  }

  if (category && category.length > 0) {
    subFilter.category = {
      query: category,
      strict: false,
    };
  }

  if (subject) {
    subFilter.subject = {
      query: subject,
      strict: false,
    };
  }

  if (status && status.length > 0) {
    subFilter.status = {
      query: status,
      strict: true,
    };
  }
  return subFilter;
};

export const getEmptyTableMessage = (type: MessageType, status?: MessageStatus): string => {
  if (type === MessageType.Incoming) {
    switch (status) {
      case MessageStatus.New:
        return 'lettersIncomingNew';
      case MessageStatus.Read:
        return 'lettersIncomingRead';
      default:
        return 'lettersIncomingAll';
    }
  } else {
    switch (status) {
      case MessageStatus.Saved:
        return 'lettersOutgoingSaved';
      case MessageStatus.ToSign:
        return 'lettersOutgoingToSign';
      case MessageStatus.Signed:
        return 'lettersOutgoingSigned';
      case MessageStatus.Delivered:
        return 'lettersOutgoingDelivered';
      default:
        return 'lettersOutgoingAll';
    }
  }
};

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SendIcon } from '@app/common/icons';
import { EmailFieldControl } from '@app/core/components/Form/controls/EmailFieldControl';
import { Loader } from '@app/core/components/Loader';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { StatusLargePopup } from '@app/core/components/StatusLargePopup';
import { useGetUserData } from '@app/core/hooks';
import { useAccountControllerSendRequisites } from '@app/core/hooks/useSendRequisites';
import { styles } from '@app/pages/accounts/components/AccountsList/components/BankDetails/SendDetails/styles';
import { validationSchema } from '@app/pages/accounts/components/AccountsList/components/BankDetails/SendDetails/validationShema';

export interface SendDetailsProps {
  show: boolean;
  id: string;
  onClose(): void;
}

interface EmailFieldProps {
  email: string;
}

export const SendDetailsModal: React.FC<SendDetailsProps> = ({ show, id, onClose }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data: user, mutate: mutateUserData } = useGetUserData();

  useEffect(() => {
    mutateUserData({});
  }, [mutateUserData]);

  const methods = useForm<EmailFieldProps>({
    resolver: yupResolver(validationSchema(t)),
  });
  const { handleSubmit, setValue } = methods;

  const { mutate, isLoading } = useAccountControllerSendRequisites();

  const onSubmit = (data: EmailFieldProps) => {
    mutate(
      { id, email: data.email },
      {
        onSuccess: () => {
          setSuccess(true);
          setShowStatusModal(true);
        },
        onError: () => {
          setSuccess(false);
          setShowStatusModal(true);
        },
      }
    );
  };

  const onClosePopupHandler = () => {
    setShowStatusModal(false);
    setSuccess(false);
    onClose();
  };

  const onOkErrorPopupHandler = () => {
    setShowStatusModal(false);
    setSuccess(false);
  };

  const addCurrentEmail = () => {
    if (user) {
      setValue('email', user?.email);
    }
  };

  return (
    <ModalCommon open={show} onClose={onClose}>
      <Box sx={styles.paper}>
        <Grid container direction="column">
          <Grid justifyContent="space-between" container alignItems="center">
            <Grid item xs={10} container flexDirection="column">
              <Typography variant="h6">
                <b>{t('accounts_send')}</b>
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton aria-label="close" size="small" onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container sx={styles.mainContent}>
                <Grid container>
                  <Grid item container xs={8}>
                    <EmailFieldControl name="email" value="" placeholder={t('email')} disabled={isLoading} />
                  </Grid>
                  <Grid item xs={4} justifyContent="flex-end" container>
                    {isLoading ? (
                      <Button variant="outlined" size="small" color="primary" type="submit" sx={styles.tempSendButton}>
                        {t('sending')}
                        <Loader styles={styles.buttonsLoader} />
                      </Button>
                    ) : (
                      <Button size="small" type="submit" variant="outlined" color="primary" sx={styles.sendButton}>
                        {t('send')}
                        <SendIcon sx={styles.buttonIcon} />
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ marginLeft: 'auto' }}>
                    <Button color="primary" onClick={addCurrentEmail} sx={styles.addButton}>
                      {t('addCurrentEmail')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>

        {showStatusModal && success ? (
          <StatusLargePopup
            show
            status="success"
            text={t('requisites_sent')}
            onClose={onClosePopupHandler}
            onOk={onClosePopupHandler}
          />
        ) : null}
        {showStatusModal && !success ? (
          <StatusLargePopup
            show
            status="error"
            text={t('requisites_not_sent')}
            buttonText={t('try_button')}
            onClose={onClosePopupHandler}
            onOk={onOkErrorPopupHandler}
          />
        ) : null}
      </Box>
    </ModalCommon>
  );
};

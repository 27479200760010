import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, dashboardApiClient, getAccessToken, GetAvailableFundsResponseDto, getClientId } from '@app/core/api';

const getAvailableFundsQueryFn = async (): Promise<GetAvailableFundsResponseDto> => {
  const response = await dashboardApiClient.dashboardControllerGetAvailableFunds(getClientId(), getAccessToken());
  return response.data;
};

export const useGetAvailableFunds = (): UseMutationResult<GetAvailableFundsResponseDto, ApiError, any> => {
  return useMutation<GetAvailableFundsResponseDto, ApiError, any>(getAvailableFundsQueryFn);
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, paymentOrdersApiClient, getClientId, NotifySignersQueryDto } from '@app/core/api';

const sendRemindPaymentOrderSignFn = async (notifyData: NotifySignersQueryDto): Promise<object> => {
  const response = await paymentOrdersApiClient.paymentOrderSignControllerNotifyAnotherSigners(
    getClientId(),
    notifyData
  );
  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "paymentOrderSignControllerNotifyAnotherSigners error. Can't send notify."
    );
  }

  return response.data;
};

export const useSendRemindPaymentOrderSign = (): UseMutationResult<object, ApiError, NotifySignersQueryDto> => {
  return useMutation<object, ApiError, NotifySignersQueryDto>(sendRemindPaymentOrderSignFn);
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { CrossIcon, EraserIcon, SearchSmallIcon } from '@app/common/icons';
import { CountryCodeDto } from '@app/core/api';
import { TextFieldColumnFilter } from '@app/core/components';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { CountryCodesColumnEnum } from '@app/pages/countryCodes/types';
import { styles } from '@app/src/pages/countryCodes/components/CountryCodesTable/style';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  t(text: string): string;
  onSearchButtonClick(): void;
  onClearSubFilter(): void;
  onCloseSubFilter(): void;
}

export const makeTableColumns = ({
  showSubFilters,
  t,
  onSearchButtonClick,
  onClearSubFilter,
  onCloseSubFilter,
}: MakeTableColumnsProps): ColumnDef<CountryCodeDto, any>[] => {
  return [
    {
      accessorKey: CountryCodesColumnEnum.numeric,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t('directories_country_number_code')}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <TextFieldColumnFilter
                name={CountryCodesColumnEnum.numeric}
                column={props.column}
                table={props.table}
                submit
              />
            </Box>
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: CountryCodesColumnEnum.alpha3,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t('directories_country_letter_code')}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <TextFieldColumnFilter
                name={CountryCodesColumnEnum.alpha3}
                column={props.column}
                table={props.table}
                submit
              />
            </Box>
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: CountryCodesColumnEnum.name,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t('directories_country_name')}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <TextFieldColumnFilter
                name={CountryCodesColumnEnum.name}
                column={props.column}
                table={props.table}
                submit
              />
            </Box>
          ) : null}
        </Grid>
      ),
    },
    {
      id: 'actions',
      header: ({ table }) => {
        const onSearchButtonClickHandler = () => {
          if (table.getRowModel().rows.length) {
            onSearchButtonClick();
          }
        };
        return (
          <Grid container justifyContent="flex-end">
            <div>
              {showSubFilters ? (
                <IconButton onClick={onClearSubFilter} size="large" sx={{ marginRight: '6px' }}>
                  <EraserIcon fontSize="small" />
                </IconButton>
              ) : null}
              {showSubFilters ? (
                <IconButton size="small" onClick={onCloseSubFilter}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={onSearchButtonClickHandler}>
                  <SearchSmallIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        );
      },
      cell: () => <Grid container direction="column" justifyContent="center" alignItems="center" />,
    },
  ];
};

import React from 'react';

import { SignatureCurrencyDocumentModal } from '../SignatureCurrencyDocumentModal';
import { DocumentToSign } from '../SignatureModal';

export type DocumentsToSign = {
  uuid: string;
};

export interface SignCurrencyDocumentsDialogProps {
  toSignDocuments: DocumentsToSign[];
  onSuccessPopupCancel(): void;
  onClose(): void;
}

export const SignCurrencyDocumentsDialog: React.FC<SignCurrencyDocumentsDialogProps> = ({
  toSignDocuments,
  onSuccessPopupCancel,
  onClose,
}) => {
  // FIXME: мэппинг делал для соблюдения интерфейсов. хз как должно быть, ено если поменять, то валится в 10 других мест
  return (
    <SignatureCurrencyDocumentModal
      documentsToSign={toSignDocuments.map(
        (doc) =>
          ({
            documentToSign: null,
            uuid: doc.uuid,
          } as unknown as DocumentToSign)
      )}
      onSuccess={onSuccessPopupCancel}
      onHandleClose={onClose}
    />
  );
};

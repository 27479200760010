import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CounterpartyResponseDto } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetCounterparties } from '@app/core/hooks';
import { PaginationChangeType, SortingRule } from '@app/core/types';
import { AddCounterparty } from '@app/pages/counterparties/components/AddCounterparty';
import { CounterpartiesFilter } from '@app/pages/counterparties/components/CounterpartiesFilter';
import { CounterpartiesTable } from '@app/pages/counterparties/components/CounterpartiesTable';
import { DeleteCounterpartyModal } from '@app/pages/counterparties/components/DeleteCounterpartyModal';
import { EditCounterparty } from '@app/pages/counterparties/components/EditCounterparty';
import { CounterpartiesFilterRows, resetFormData } from '@app/pages/counterparties/types';
import { validationSchema } from '@app/src/pages/counterparties/validationSchema';

export const CounterpartiesPage: React.FC = () => {
  const { t } = useTranslation();
  const [showEditCounterpartyDialog, setShowEditCounterpartyDialog] = useState<boolean>(false);
  const [showAddCounterpartyDialog, setShowAddCounterpartyDialog] = useState<boolean>(false);
  const [showDeleteCounterpartyDialog, setShowDeleteCounterpartyDialog] = useState<boolean>(false);
  const [selectCounterparty, setSelectCounterparty] = useState<CounterpartyResponseDto | undefined>();
  const [selectCounterpartyId, setSelectCounterpartyId] = useState<number | undefined>();
  const deleteCounterpartyId = useRef<number | undefined>(undefined);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const sortRuleRef = useRef<SortingRule | undefined>();

  const methods = useForm<CounterpartiesFilterRows>({
    resolver: yupResolver(validationSchema(t)),
    mode: 'onChange',
  });
  const { handleSubmit, reset: formReset, getValues } = methods;
  const { data, mutate, isLoading } = useGetCounterparties();

  useEffect(() => {
    mutate({ pagination: paginationRef.current });
  }, [mutate]);

  const updateTable = () => {
    mutate({
      ...getValues(),
      pagination: paginationRef.current,
      sortBy: sortRuleRef.current,
    });
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateTable();
    }
  };

  const resetFormHandler = () => {
    formReset(resetFormData);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({ pagination: paginationRef.current });
  };

  const onSortHandler = (rule: SortingRule) => {
    sortRuleRef.current = rule;
    updateTable();
  };

  const addNewCounterparty = () => {
    setShowAddCounterpartyDialog(true);
  };

  const editCounterparty = (id: number) => {
    setSelectCounterpartyId(id);
  };

  useEffect(() => {
    if (selectCounterpartyId && data) {
      setSelectCounterparty(data?.counterparties.find((item) => item.id === selectCounterpartyId));
      setShowEditCounterpartyDialog(true);
    }
  }, [data, selectCounterpartyId]);

  const onCloseEditModal = () => {
    setShowEditCounterpartyDialog(false);
    setSelectCounterparty(undefined);
    setSelectCounterpartyId(undefined);
  };

  const onCloseAddModal = () => {
    setShowAddCounterpartyDialog(false);
  };

  const handleDeleteClick = (id: number) => {
    deleteCounterpartyId.current = id;
    setShowDeleteCounterpartyDialog(true);
  };

  const onSuccessDeleteCounterparty = () => {
    setShowDeleteCounterpartyDialog(false);
    setShowEditCounterpartyDialog(false);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    updateTable();
    setSelectCounterpartyId(undefined);
  };

  const onCloseDeleteCounterpartyDialog = () => {
    setShowDeleteCounterpartyDialog(false);
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateTable();
  };

  const onSuccessAddCounterpartyHandler = () => {
    updateTable();
    setShowAddCounterpartyDialog(false);
  };

  const onSuccessEditCounterpartyHandler = () => {
    updateTable();
    setShowEditCounterpartyDialog(false);
    setSelectCounterpartyId(undefined);
  };

  const counterparties = data?.counterparties || [];

  return (
    <>
      <PageWrapper>
        <PageHeader title={t('counterparties')} showNavigateButton backLink={-1} useDesktopStyle>
          <Grid item>
            <Button variant="contained" color="primary" onClick={addNewCounterparty}>
              {t('add')}
            </Button>
          </Grid>
        </PageHeader>
        <PageContainer>
          <Box
            mt={4}
            sx={{
              width: `100%`,
            }}
          >
            <Box mb={4}>
              <FilterIcon />
              <Typography
                variant="h6"
                component="span"
                sx={{
                  color: 'colors.primary600',
                  fontWeight: 'bold',
                  verticalAlign: 'bottom',
                  marginLeft: '4px',
                }}
              >
                {t('filters')}
              </Typography>
            </Box>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(updateTable)} onKeyPress={onKeyPressHandler} role="presentation">
                <CounterpartiesFilter resetFormHandler={resetFormHandler} />
              </form>
              <CounterpartiesTable
                counterparties={counterparties}
                handleShow={editCounterparty}
                deleteCounterpartyModal={handleDeleteClick}
                isLoading={isLoading}
                pagination={data?.pagination}
                onPaginationChange={onPaginationChangeHandler}
                onSort={onSortHandler}
              />
            </FormProvider>
          </Box>
        </PageContainer>
      </PageWrapper>

      {showAddCounterpartyDialog ? (
        <AddCounterparty onClose={onCloseAddModal} onSuccessAddCounterparty={onSuccessAddCounterpartyHandler} />
      ) : null}

      {showEditCounterpartyDialog && selectCounterparty ? (
        <EditCounterparty
          onClose={onCloseEditModal}
          counterparty={selectCounterparty}
          onDeleteCounterparty={handleDeleteClick}
          onSuccessEditCounterparty={onSuccessEditCounterpartyHandler}
        />
      ) : null}

      {showDeleteCounterpartyDialog && deleteCounterpartyId.current ? (
        <DeleteCounterpartyModal
          counterpartyId={deleteCounterpartyId.current}
          onClose={onCloseDeleteCounterpartyDialog}
          onErrorDelete={onCloseDeleteCounterpartyDialog}
          onSuccessDelete={onSuccessDeleteCounterparty}
        />
      ) : null}
    </>
  );
};

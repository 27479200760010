import {
  getClientId,
  importPaymentOrdersApiClient,
  ApiError,
  AddImportOrdersRequestDto,
  CreatePaymentOrderResultDto,
  ImportOrdersResponseDto,
  LinkResponseDto,
} from '@app/core/api';

export const paymentOrderParseFn = async (file: string): Promise<ImportOrdersResponseDto> => {
  const response = await importPaymentOrdersApiClient.paymentOrderControllerParseFile(getClientId(), new Blob([file]));

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Server can't parse file");
  }

  return response.data;
};

export const paymentOrderImportOrdersFn = async (
  addImportOrdersRequestDto: AddImportOrdersRequestDto
): Promise<Array<CreatePaymentOrderResultDto>> => {
  const response = await importPaymentOrdersApiClient.paymentOrderControllerImportOrders(
    getClientId(),
    addImportOrdersRequestDto
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Server can't import orders");
  }

  return response.data;
};

export const getPaymentOrderExampleFileFn = async (): Promise<LinkResponseDto> => {
  const response = await importPaymentOrdersApiClient.paymentOrderControllerGetExampleFile();

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Server can't return example file link");
  }

  return response.data;
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CreateOperationAdditionalInfoDto,
  RepaymentTypeEnum,
  ResultStatusEnum,
  SwiftOperationDto,
} from '@app/core/api';
import { SignMultipleSwift } from '@app/core/components';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useGetSwiftSigners } from '@app/core/hooks';
import { useCreateSwiftPayment } from '@app/core/hooks/useCreateSwiftPayment';
import { useGetCurrencyRepaymentTemplate } from '@app/core/hooks/useGetCurrencyRepaymentTemplate';
import { useSendToSignSwift } from '@app/core/hooks/useSendToSignSwift';
import { isMobile } from '@app/core/utils';
import { LocalPaymentUrlQuery } from '@app/pages/payment/create/types';
import { SwiftPaymentBetweenMyAccountForm } from '@app/pages/swift/SwiftPayment/components/BetweenMyAccounts';
import { makeSwiftPaymentSubmitData } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/helper';
import { SimpleSwiftDefaultValues, SwiftFormData } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface CreatePaymentProps {
  paymentData: SwiftFormData;
  onCreateSuccess(operation: SwiftOperationDto): void;
  onCreateError(additionalInfo?: CreateOperationAdditionalInfoDto): void;
}

export const LoanSwiftCreatePage: React.FC = () => {
  const urlQueryValues = useLocation().search;
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const [createPaymentResultOperation, setCreatePaymentResultOperation] = useState<SwiftOperationDto | null>();
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<SimpleSwiftDefaultValues>();
  const { showLoader } = useUIState();

  const navigate = useNavigate();

  const { notify } = useNotify();

  const { lid = 'no-Uid', rt } = parse(urlQueryValues) as LocalPaymentUrlQuery;

  const { data, mutate } = useGetCurrencyRepaymentTemplate();

  useEffect(() => {
    mutate({
      isLoanLine: false,
      repaymentType: rt as unknown as RepaymentTypeEnum,
      uuid: lid as string,
    });
  }, [mutate, rt, lid]);

  const { permissions } = useAppSelector((state): ProfileState => state.profile);

  const { data: peopleToSigne } = useGetSwiftSigners();

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.template) {
      setDefaultValues({
        senderAccountId: data.template?.senderAccountId,
        senderAccount: data.template?.senderAccount,
        senderAddress: data.template?.senderAddress,
        senderName: data.template?.senderName,
        recipientSwiftBic: data.template?.recipientSwiftBic,
        recipientAccount: data.template?.recipientAccount,
        recipientAccountId: data.template?.recipientAccountId,
        recipientAddress: data.template?.recipientAddress,
        recipientBankName: data.template?.recipientBankName,
        recipientCountryCode: data.template?.recipientCountryCode,
        recipientName: data.template?.recipientName,
        paymentReference: data.template?.paymentReference,
        amount: data.template?.amount,
      });
    }
  }, [data]);

  const onSuccessSignHandler = () => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(-1);
  };

  const onCloseModalHandler = (status: 'idle' | 'loading' | 'error' | 'success') => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    if (createPaymentResultOperation && status !== 'success') {
      navigate(RouteList.swift_edit.replace(':uuid', createPaymentResultOperation.uuid));
    } else {
      navigate(-1);
    }
  };

  const { data: createSwiftPaymentResultData, mutateAsync, reset: resetCreatePayment } = useCreateSwiftPayment();

  const { data: sendToSignPaymentResultData, mutate: sendToSignMutation } = useSendToSignSwift();

  const createPayment = ({ paymentData, onCreateSuccess, onCreateError }: CreatePaymentProps) => {
    mutateAsync({ operation: makeSwiftPaymentSubmitData(paymentData) })
      .then((result) => {
        if (result.operation) {
          onCreateSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onCreateError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onCreateError();
      });
  };

  const onSaveAndSignClickHandler = (paymentData: SwiftFormData) => {
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        setCreatePaymentResultOperation(operation);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: SwiftFormData) => {
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        sendToSignMutation([operation.uuid]);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(-1);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.swift);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  return (
    <PageWrapper>
      <PageHeader
        title={t('swiftCreationHeaderContent_title')}
        showNavigateButton={!isMobile}
        backLink={-1}
        showLeavePopup={showLeavePopup}
        confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
      />
      <PageContainer>
        <Box p={isMobile ? 0 : 3} width="100%">
          <Grid container wrap="nowrap">
            <Grid item container xs={isMobile ? false : 9}>
              <SwiftPaymentBetweenMyAccountForm
                signPermissions={permissions.sign}
                additionalInfo={createSwiftPaymentResultData?.additionalInfo}
                onSaveAndSignClick={onSaveAndSignClickHandler}
                onSaveClick={onSaveClickHandler}
                onSendToSign={onSaveAndSendToSignClickHandler}
                defaultValues={defaultValues}
              />
            </Grid>
            {!isMobile && userProfile ? (
              <Grid item xs={3}>
                <Box pl={10}>
                  <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        {createPaymentResultOperation && showSignPayment ? (
          <SignMultipleSwift
            operations={[createPaymentResultOperation]}
            onSuccessPopupCancel={onSuccessSignHandler}
            onClose={onCloseModalHandler}
          />
        ) : null}
      </PageContainer>
    </PageWrapper>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon, SuccessSmallSvgIcon } from '@app/common/icons';

import { DownloadOperations } from './components/DownloadOperations';
import { DownloadTurnovers } from './components/DownloadTurnovers';
import { DataPeriodType, SelectedAccountsTabEnum } from './types';
import { AccountSelectType, DateRange } from '../../types';
import { DateSelectField } from '../Form';

interface MultipleDateSelectProps {
  accounts?: AccountSelectType[];
  onChange(filter: DateRange | undefined): void;
  activeTab?: SelectedAccountsTabEnum;
}
// TODO: rename this component
export const MultipleDateSelect: React.FC<MultipleDateSelectProps> = ({ accounts, onChange, activeTab }) => {
  const methods = useForm<DataPeriodType>({
    mode: 'onSubmit',
    defaultValues: {
      temp_from: '',
      temp_to: '',
    },
  });
  const { handleSubmit, reset: formReset, control } = methods;
  const [switchActionButton, setSwitchActionButton] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(true);
  const onSubmit = (data: any) => {
    const startDate = data.from;
    const endDate = data.to;
    onChange({ startDate, endDate });
    setSwitchActionButton(true);
  };
  const resetHandler = () => {
    setSwitchActionButton(false);
    onChange(undefined);
    formReset();
    setDisableButtons(true);
  };
  const watchDateFrom = useWatch({ control, name: 'from' });
  const watchDateTo = useWatch({ control, name: 'to' });
  const nowDate = new Date();
  useEffect(() => {
    setSwitchActionButton(false);
    if (watchDateFrom && watchDateTo) {
      setDisableButtons(false);
    }
  }, [watchDateFrom, watchDateTo]);
  const { t } = useTranslation();
  return (
    <FormProvider {...methods}>
      <form>
        <Grid container alignItems="center">
          <Typography
            component="div"
            sx={{
              display: 'inline-block',
              width: '40px',
              color: 'colors.primary500',
              marginRight: '4px',
              textAlign: 'right',
            }}
          >
            {t('from')}
          </Typography>
          <Box
            component="span"
            sx={{
              marginRight: '16px',
              width: '145px',
            }}
          >
            <DateSelectField
              name="from"
              datePickerProps={{
                disabled: { after: watchDateTo || nowDate },
              }}
            />
          </Box>
          <Grid>
            <Typography
              component="div"
              sx={{
                display: 'inline-block',
                width: '40px',
                color: 'colors.primary500',
                marginRight: '4px',
                textAlign: 'right',
              }}
            >
              {t('to')}
            </Typography>

            <DateSelectField
              name="to"
              datePickerProps={{
                disabled: { before: watchDateFrom, after: nowDate },
              }}
              inputProps={{
                sx: {
                  marginRight: '26px',
                  width: '145px',
                },
              }}
            />

            {switchActionButton ? (
              <IconButton
                onClick={resetHandler}
                sx={{
                  mr: 6,
                  padding: 0,
                  color: 'colors.primary500',
                }}
                size="large"
              >
                <CrossIcon
                  fontSize="small"
                  sx={{
                    color: 'colors.primary500',
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                color="default"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  mr: 6,
                  padding: 0,
                  color: 'colors.primary500',
                }}
                disabled={disableButtons}
              >
                <SuccessSmallSvgIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
          {accounts && activeTab === SelectedAccountsTabEnum.operations ? (
            <DownloadOperations accounts={accounts} disableButtons={disableButtons} />
          ) : null}
          {accounts && activeTab === SelectedAccountsTabEnum.turnovers ? (
            <DownloadTurnovers accounts={accounts} disableButtons={disableButtons} />
          ) : null}
        </Grid>
      </form>
    </FormProvider>
  );
};

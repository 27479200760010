import * as React from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

import { AccountStatusEnum } from '@app/core/api';

import { AccountInfo } from './AccountInfo';
import { AccountSelectType } from '../../../types';
import { Checkbox } from '../../Checkbox';
import { useStyles } from '../styles';

type OptionItemProps = {
  account: AccountSelectType;
  selected: boolean;
};
const AccountOptionItem: React.FC<OptionItemProps> = ({ account, selected }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <>
      <Checkbox checked={selected} sx={styles.checkBox} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <AccountInfo account={account} />
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body2"
            align="right"
            sx={{
              ...(account.account.status === AccountStatusEnum.Active && {
                ...styles.currencyActive,
              }),
              ...(account.account.status === AccountStatusEnum.Closed && {
                ...styles.closed,
              }),
            }}
          >
            {account.account.currency}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const AccountMenuOption = (
  option: AccountSelectType,
  { selected }: AutocompleteRenderOptionState
): React.ReactNode => {
  return <AccountOptionItem account={option} selected={selected} />;
};

export const bankBranchList = [
  {
    details: 'м. Львів, вул. Газова, 17',
    id: '1300',
  },
  {
    details: 'м.Львів, пл. Галицька, 15',
    id: '1302',
  },
  {
    details: 'м.Львів, вул.Данила Апостола, 10',
    id: '1301',
  },
  {
    details: 'м.Львів, вул.Липинського, 54',
    id: '1303',
  },
  {
    details: 'м.Київ, вул. Хрещатик, 8',
    id: '2601',
  },
  {
    details: 'м.Харків, вул. Мистецтв, 6',
    id: '2001',
  },
  {
    details: 'м. Хмельницький, вул. Свободи, 46/1',
    id: '2201',
  },
  {
    details: 'м. Ужгород, вул. Гагаріна, 13а',
    id: '601',
  },
];

export const styles = {
  balance: {
    color: 'colors.primary700',
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'right',
    marginRight: '22px',
  },
  currency: {
    color: 'colors.gray400',
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'right',
    marginRight: '22px',
  },
  date: {
    color: 'colors.gray400',
    fontSize: '0.875rem',
  },
  operations: {
    color: 'colors.gray600',
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'right',
    marginRight: '22px',
  },
  navigateButton: {
    color: 'colors.primary600',
  },
};

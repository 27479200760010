import Cookies from 'js-cookie';

import { authApiClient, ApiError, AuthResponseDto } from '@app/core/api';

export const getNewAccessTokenFn = async (): Promise<AuthResponseDto> => {
  const response = await authApiClient.authControllerRefreshToken({
    params: {
      refresh_token: Cookies.get('RefreshToken') || 'no_token',
    },
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't refresh token.");
  }
  return response.data;
};

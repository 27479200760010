import React, { useState } from 'react';

import { Box, Button, Grid, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PreviewIconGray,
  PrintIcon,
  SignatureIcon,
  StarIcon,
  BackCancelIcon,
} from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ApiError,
  FileLinkAdditionalInfoDtoCodeEnum,
  FileLinkResponseDto,
  StatementTypeEnum,
  OutputFormatEnum,
  CurrencyEnum,
  PaymentTemplateTypeEnum,
} from '@app/core/api';
import { Checkbox } from '@app/core/components';
import { DocumentStatus, Operation } from '@app/core/types';
import { calculateTotal, formatAmount } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { ConfirmEditOperationPopup } from '../../../../../ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { MobileSelectFileFormat } from '../../../../../MobileSelectFileFormat';
import { GetMultipleStatementsFilesProps } from '../../../../types';
import { activeButtons } from '../../helpers';

export interface ActionsPanelProps {
  selectedDocuments: Operation[];
  rowsCount: number;
  documentStatus: DocumentStatus;
  onSelectAllClick(selectAll: boolean): void;
  onSignClick(): void;
  onMultiSignClick(): void;
  onCopyClick(): void;
  onDeleteClick(): void;
  onAddToTemplatesClick(): void;
  onCancelDocument(): void;
  getStatementsMultipleFiles(): UseMutationResult<FileLinkResponseDto, ApiError, GetMultipleStatementsFilesProps>;
  onSignFromAnotherClick(): void;
  onMultiSignFromAnotherClick(): void;
}

export const ActionsPanel: React.FC<ActionsPanelProps> = ({
  selectedDocuments,
  rowsCount,
  documentStatus,
  onSignClick,
  onMultiSignClick,
  onSelectAllClick,
  onCopyClick,
  onDeleteClick,
  onAddToTemplatesClick,
  onCancelDocument,
  getStatementsMultipleFiles,
  onSignFromAnotherClick,
  onMultiSignFromAnotherClick,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const [fileTypeSelectDialogOpen, setFileTypeSelectDialogOpen] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { uuid, link, signed, currency, templateType } = selectedDocuments[0];

  const onPrintClickHandler = () => {
    window.open(link, '_blank');
  };

  const onCopyClickHandler = () => {
    setMoreMenuAnchorEl(null);
    onCopyClick();
  };

  const onDeleteClickHandler = () => {
    setMoreMenuAnchorEl(null);
    onDeleteClick();
  };

  const onCancelDocumentClickHandler = () => {
    setMoreMenuAnchorEl(null);
    onCancelDocument();
  };

  const onTemplateClickHandler = () => {
    setMoreMenuAnchorEl(null);
    onAddToTemplatesClick();
  };

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  const handleCloseDownloadMenu = () => {
    setDownloadMenuAnchorEl(null);
  };

  const { totalUah, totalEur, totalUsd } = calculateTotal(selectedDocuments || []);

  const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectAllClick(e.target.checked);
  };

  const { t } = useTranslation();

  const onPreviewClickHandler = () => {
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_view.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_view.replace(':uuid', uuid));
  };

  const navigateToEditPayment = () => {
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_edit.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_edit.replace(':uuid', uuid));
  };

  const onEditClickHandler = () => {
    if (signed && currency === CurrencyEnum.Uah) {
      setShowConfirmEditPopup(true);
    } else {
      navigateToEditPayment();
    }
  };

  const { mutateAsync } = getStatementsMultipleFiles();

  const fileSelectHandler = (outputType: OutputFormatEnum, format?: string) => {
    const uids = selectedDocuments.map((document) => document.uuid);
    mutateAsync({
      outputType,
      operationsType: StatementTypeEnum.UahOperation,
      ordersUids: uids,
    }).then((responseData) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        if (format) {
          window.open(`${responseData.link}?format=${format}`, '_blank');
          return;
        }
        window.location.href = `${responseData.link}?format=download&type=payment-order`;
      }
    });
  };

  const onSelectFileTypeHandler = (outputType: OutputFormatEnum) => {
    setFileTypeSelectDialogOpen(false);
    const uids = selectedDocuments.map((document) => document.uuid);
    mutateAsync({
      outputType,
      operationsType: StatementTypeEnum.UahOperation,
      ordersUids: uids,
    }).then((responseData) => {
      if (
        responseData?.additionalInfo &&
        responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
      ) {
        notify({
          notifyProps: {
            message: t('load_too_many_operations_alert'),
            severity: 'warning',
          },
        });
        return;
      }
      if (responseData?.link) {
        window.location.href = `${responseData.link}?format=download&type=payment-order`;
      }
    });
  };

  const onPrintMultipleClickHandler = () => {
    fileSelectHandler(OutputFormatEnum.Pdf, 'print');
  };

  const onDownloadClickHandler = () => {
    handleCloseMoreMenu();
    setFileTypeSelectDialogOpen(true);
  };

  const onCloseFileTypeSelectDialog = () => {
    setFileTypeSelectDialogOpen(false);
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    navigateToEditPayment();
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const activeButtonsConfig = activeButtons(documentStatus, selectedDocuments, permissions);

  return (
    <>
      <Box minHeight={42}>
        <Grid container justifyContent="space-between" sx={styles.root} alignItems="center" direction="column">
          <Grid item container alignItems="center" justifyContent="space-between">
            <Box alignContent="center">
              <Grid container alignItems="center">
                <Checkbox
                  indeterminate={selectedDocuments.length > 0 && selectedDocuments.length < rowsCount}
                  icon={<CheckboxUncheckedIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  indeterminateIcon={<CheckboxIndeterminateIcon />}
                  checked={Boolean(selectedDocuments.length)}
                  onChange={onCheckHandler}
                />
                <Typography variant="body2" component="span" sx={styles.buttonText}>
                  {`${selectedDocuments.length} ${t('checked')}`}
                </Typography>
              </Grid>
            </Box>
            <Box>
              {activeButtonsConfig.moreMenu ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onMenuBtnClickHandler} endIcon={<MoreIcon />}>
                    <Typography>{t('more')}</Typography>
                  </Button>

                  <Popover
                    anchorEl={moreMenuAnchorEl}
                    keepMounted
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={handleCloseMoreMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {activeButtonsConfig.moreMenuButtons.print ? (
                      <MenuItem sx={styles.menuItem} onClick={onPrintClickHandler}>
                        <ListItemIcon>
                          <PrintIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('print')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.printMultiple ? (
                      <MenuItem sx={styles.menuItem} onClick={onPrintMultipleClickHandler}>
                        <ListItemIcon>
                          <PrintIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('print')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.copy ? (
                      <MenuItem sx={styles.menuItem} onClick={onCopyClickHandler}>
                        <ListItemIcon>
                          <CopyIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('copy')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.download ? (
                      <MenuItem sx={styles.menuItem} onClick={onDownloadClickHandler}>
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('download')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.template ? (
                      <MenuItem sx={styles.menuItem} onClick={onTemplateClickHandler}>
                        <ListItemIcon>
                          <StarIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('addToTemplate')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.view ? (
                      <MenuItem sx={styles.menuItem} onClick={onPreviewClickHandler}>
                        <ListItemIcon>
                          <PreviewIconGray />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('preview')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.edit ? (
                      <MenuItem sx={styles.menuItem} onClick={onEditClickHandler}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('change')}</Typography>
                      </MenuItem>
                    ) : null}

                    {activeButtonsConfig.moreMenuButtons.delete ? (
                      <MenuItem sx={styles.menuItem} onClick={onDeleteClickHandler}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('delete')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenuButtons.cancel ? (
                      <MenuItem sx={styles.menuItem} onClick={onCancelDocumentClickHandler}>
                        <ListItemIcon>
                          <BackCancelIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('reject')}</Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                </>
              ) : null}
            </Box>
          </Grid>
          <Box mt={4} width="100%">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item container xs={6} justifyContent="flex-start" sx={styles.totalAmountContainer}>
                <Typography variant="body2" component="span" sx={styles.amountText}>
                  {t('amount')}:
                </Typography>
                {totalUah > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.amountValue}>
                      {formatAmount(totalUah)}
                    </Typography>
                    <Typography variant="body2" component="span" sx={styles.amountText}>
                      UAH
                    </Typography>
                  </>
                ) : null}
                {totalUsd > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.amountValue}>
                      {formatAmount(totalUsd)}
                    </Typography>
                    <Typography variant="body2" component="span" sx={styles.amountText}>
                      USD
                    </Typography>
                  </>
                ) : null}
                {totalEur > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.amountValue}>
                      {formatAmount(totalEur)}
                    </Typography>
                    <Typography variant="body2" component="span" sx={styles.amountText}>
                      EUR
                    </Typography>
                  </>
                ) : null}
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end">
                <Box>
                  {activeButtonsConfig.sign || activeButtonsConfig.multiSign ? (
                    <>
                      <Button
                        variant="outlined"
                        sx={styles.signButtonRoot}
                        onClick={activeButtonsConfig.multiSign ? onMultiSignClick : onSignClick}
                        endIcon={<SignatureIcon />}
                      >
                        <Typography sx={styles.buttonText}>{t('sign')}</Typography>
                      </Button>
                    </>
                  ) : null}
                  {activeButtonsConfig.signFromAnother || activeButtonsConfig.multiSignFromAnother ? (
                    <>
                      <Button
                        variant="outlined"
                        sx={styles.signButtonRoot}
                        onClick={activeButtonsConfig.multiSign ? onMultiSignFromAnotherClick : onSignFromAnotherClick}
                        endIcon={<SignatureIcon />}
                      >
                        <Typography sx={styles.buttonText}>{t('signedByPerson')}</Typography>
                      </Button>
                    </>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <MobileSelectFileFormat
        open={fileTypeSelectDialogOpen}
        onClose={onCloseFileTypeSelectDialog}
        onSelectFileType={onSelectFileTypeHandler}
      />

      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
    </>
  );
};

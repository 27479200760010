import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid, ListItemIcon, Typography } from '@mui/material';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PaperIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { FloatMenu, FloatMenuOption } from '@app/core/components/FloatMenu';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { isMobile, FULL_DATE_TIME_FORMAT } from '@app/core/utils';
import { AccountBalances } from '@app/pages/dashboard/view/PageContent/AccountBalances';
import { AvailableFunds } from '@app/pages/dashboard/view/PageContent/AvailableFunds';
import { Operations } from '@app/pages/dashboard/view/PageContent/Operations';
import { Statements } from '@app/pages/dashboard/view/PageContent/Statements';
import { MobileDashboardMenuEnum } from '@app/pages/dashboard/view/PageContent/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const PageContent: React.FC = () => {
  const { permissions, clientConfig } = useAppSelector((state): ProfileState => state.profile);
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeMobileMenuItem, setActiveMobileMenuItem] = useState<MobileDashboardMenuEnum>(
    MobileDashboardMenuEnum.AvailableFunds
  );

  const lastLogin = Cookies.get('LastLogin');

  const floatMenuOptions: FloatMenuOption[] = useMemo(() => {
    const options: FloatMenuOption[] = [];
    if (permissions.uahOperations.create) {
      options.push({
        id: 1,
        action: () => navigate(RouteList.payment_create),
        content: (
          <>
            <ListItemIcon>
              <PaperIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('paymentCreationHeaderContent_title')}</Typography>
          </>
        ),
      });
    }

    if (clientConfig?.features.swift && permissions.swift.create) {
      options.push({
        id: 2,
        action: () => navigate(RouteList.swift_create),
        content: (
          <>
            <ListItemIcon>
              <PaperIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('createSwiftPayment')}</Typography>
          </>
        ),
      });
    }

    if (clientConfig?.features.currencyOperations && permissions.swift.create) {
      options.push({
        id: 3,
        action: () => navigate(RouteList.exchange_create),
        content: (
          <>
            <ListItemIcon>
              <PaperIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('currencyExchange')}</Typography>
          </>
        ),
      });
    }

    return options;
  }, [clientConfig, navigate, permissions, t]);

  useEffect(() => {
    if (!sessionStorage.getItem('LastLogin')) {
      if (lastLogin) {
        sessionStorage.setItem('LastLogin', lastLogin);
        notify({
          notifyProps: {
            title: t('lastLoginTitle'),
            message: `${format(new Date(lastLogin), FULL_DATE_TIME_FORMAT)}`,
            severity: 'info',
            content: (
              <Typography
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  color: 'colors.primary500',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(`${RouteList.profile}?tab=History`)}
              >
                {t('lastLoginLink')}
              </Typography>
            ),
          },
        });
      }
    }
  }, [lastLogin, navigate, notify, t]);

  const mobileDashboardMenuItems: MenuItem<MobileDashboardMenuEnum>[] = [
    {
      id: 1,
      labelText: t('available_funds_header'),
      value: MobileDashboardMenuEnum.AvailableFunds,
    },
    {
      id: 2,
      labelText: t('selectedAccounts'),
      value: MobileDashboardMenuEnum.AccountBalances,
    },
    {
      id: 3,
      labelText: t('statements'),
      value: MobileDashboardMenuEnum.Statements,
    },
  ];

  const onMobileDashboardMenuChange = (selected: MobileDashboardMenuEnum) => {
    setActiveMobileMenuItem(selected);
  };

  return (
    <>
      <Grid container direction="column">
        {isMobile ? (
          <>
            <Box
              sx={{
                overflowX: 'auto',
                width: '100%',
              }}
            >
              <HorizontalMenu<MobileDashboardMenuEnum>
                onChange={onMobileDashboardMenuChange}
                menuItems={mobileDashboardMenuItems}
                styles={{
                  root: {
                    '&.div': {
                      display: 'inline-block',
                      whiteSpace: 'nowrap',
                      overflow: 'initial',
                    },
                  },
                  itemDefault: {
                    color: 'colors.gray600',
                    fontWeight: 600,
                    marginRight: '12px',
                    fontSize: '0.875rem',
                  },
                  itemSelected: {
                    color: 'colors.primary600',
                    fontWeight: 600,
                    fontSize: '1rem',
                    marginRight: '12px',
                  },
                }}
              />
            </Box>
            {activeMobileMenuItem === MobileDashboardMenuEnum.AvailableFunds ? <AvailableFunds /> : null}
            {activeMobileMenuItem === MobileDashboardMenuEnum.AccountBalances ? <AccountBalances /> : null}
            {activeMobileMenuItem === MobileDashboardMenuEnum.Statements ? <Statements /> : null}
          </>
        ) : (
          <>
            <AvailableFunds />
            <Box pt={9} mb={9} width="100%">
              <Grid
                item
                container
                justifyContent="space-between"
                direction={isMobile ? 'column' : 'row'}
                wrap={isMobile ? 'nowrap' : 'wrap'}
              >
                <Grid item xs={isMobile ? false : 7}>
                  <Box pr={isMobile ? 0 : 3}>
                    <AccountBalances />
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? false : 5}>
                  <Box pl={isMobile ? 0 : 3} mt={isMobile ? 9 : 0}>
                    <Statements />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        <Box width="100%">
          <Operations />
        </Box>
      </Grid>
      {isMobile ? <FloatMenu options={floatMenuOptions} /> : null}
    </>
  );
};

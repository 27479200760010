import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { differenceInDays } from 'date-fns';
import Logger from 'js-logger';
import { useTranslation } from 'react-i18next';

import { CrossIcon, ErrorSmallIcon, HideIcon, LoaderIcon, ShowIcon, SignatureIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { UserDto } from '@app/core/api';
import { ModalCommon, Select, ProgressPopup } from '@app/core/components';
import { keyECPfileTypes } from '@app/core/constants/files';
import { TINCode } from '@app/core/constants/profile';
import { isMobile } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { initializeEndUserSignFile, initializeEndUserSignKeyMedia } from '@app/src/crypto';
import { EndUserCertificate } from '@app/src/crypto/types/EndUserCertificate';
import { EndUserCertificateInfoEx } from '@app/src/crypto/types/EndUserCertificateInfoEx';
import { useAppSelector } from '@app/src/store';

import { SignMethodSelectTabs } from './components/SignMethodSelectTabs';
import { logCertificateKeyDataError, logCertificateValidationsError } from './helpers';
import { styles } from './styles';
import { SignMethodEnum } from './types';

export type DocumentToSign = {
  uuid: string;
  documentToSign: string;
};

export type DocumentSigned = {
  uuid: string;
  signedDocument: string;
};

export interface SignatureModalProps {
  title: string;
  documentsToSign: DocumentToSign[];
  validateUser?: UserDto;
  onSignedStringResult(signedDocuments: DocumentSigned[]): void;
  onClose(): void;
}

export const SignatureModal: React.FC<SignatureModalProps> = ({
  title,
  documentsToSign,
  validateUser,
  onSignedStringResult,
  onClose,
}) => {
  const { showLoader } = useUIState();
  const { userProfile, clientProfile } = useAppSelector((state): ProfileState => state.profile);
  const { notify } = useNotify();
  const { t } = useTranslation();
  const { cryptoLibraryLoaded } = useAppSelector((state) => state.crypto);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [signKeyError, setSignKeyError] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<EndUserCertificateInfoEx>();
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [certificateVerified, setCertificateVerified] = useState<boolean>(false);
  const [certificateVerifiedError, setCertificateVerifiedError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [signMethod, setSignMethod] = useState<SignMethodEnum>(SignMethodEnum.file);
  const [endUserSignFileInitialized, setEndUserSignFileInitialized] = useState<boolean>(false);
  const [endUserSignKeyMediaInitialized, setEndUserSignKeyMediaInitialized] = useState<boolean>(false);
  const [endUserSignKeyMediaLibraryInfo, setEndUserSignKeyMediaLibraryInfo] = useState<EndUserLibraryInfoSW>();
  const [endUserSignKeyMediaKeyMedias, setEndUserSignKeyMediaKeyMedias] = useState<EndUserKeyMedia[]>();
  const [endUserKeyMedia, setEndUserKeyMedia] = useState<EndUserKeyMedia>();
  const [certificateExpDays, setCertificateExpDays] = useState<number | undefined>();
  const [showProgressPopup, setShowProgressPopup] = useState<boolean>(false);
  const [currentSignedDocuments, setCurrentSignedDocuments] = useState<number>(0);

  const caCN = undefined;

  useEffect(() => {
    if (cryptoLibraryLoaded) {
      if (!endUserSignFileInitialized) {
        initializeEndUserSignFile()
          .then(() => {
            setEndUserSignFileInitialized(true);
          })
          .catch((e) => {
            const msg = e.message || e;
            Logger.log('Initialize error: ' + msg);
          })
          .finally(() => {
            setLoading(false);

            // ініціалізація токенів після ініціалізації основного модуля
            if (!endUserSignKeyMediaInitialized) {
              initializeEndUserSignKeyMedia()
                .then(() => {
                  setEndUserSignKeyMediaInitialized(true);
                  setEndUserSignKeyMediaLibraryInfo(undefined);
                  euSignKeyMedia.GetKeyMedias().then((keyMedias: EndUserKeyMedia[]) => {
                    setEndUserSignKeyMediaKeyMedias(keyMedias);
                    setEndUserKeyMedia(keyMedias[0]);
                  });
                })
                .catch((libraryInfo: EndUserLibraryInfoSW) => {
                  setEndUserSignKeyMediaInitialized(false);
                  setEndUserSignKeyMediaLibraryInfo(libraryInfo);
                  Logger.log(libraryInfo);
                });
              return;
            }
          });
      }
    }
  }, [cryptoLibraryLoaded, endUserSignFileInitialized, endUserSignKeyMediaInitialized, showLoader, signMethod]);

  const resetForm = () => {
    setShowPass(false);
    setPassword('');
    setCertificate(undefined);
    setFileName(undefined);
    setCertificateVerifiedError(null);
    setLoading(false);
    setSignKeyError(false);
    setCertificateVerified(false);
    setCertificateExpDays(undefined);
  };

  const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const euReadPrivateKey = (file: File, result: ArrayBuffer): Promise<EndUserOwnerInfo> => {
    const keyData = new Uint8Array(result as ArrayBufferLike);
    let pkCertificates = undefined;

    // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
    // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися
    if (file.name.endsWith('.jks')) {
      return window.euSignFile.GetJKSPrivateKeys(keyData).then((jksKeys: EndUserJKSPrivateKeyInfo[]) => {
        // Для спрощення прикладу обирається перший ключ
        const pkIndex = 0;
        pkCertificates = [];
        for (let i = 0; i < jksKeys[pkIndex].certificates.length; i++) {
          pkCertificates.push(jksKeys[pkIndex].certificates[i].data);
        }

        return euSignFile.ReadPrivateKeyBinary(jksKeys[pkIndex].privateKey, password, pkCertificates, caCN);
      });
    }

    return euSignFile.ReadPrivateKeyBinary(keyData, password, pkCertificates, caCN);
  };

  const handleFile = (file: File) => {
    resetForm();
    if (keyECPfileTypes.indexOf(file.name.toLocaleLowerCase().substr(-3)) === -1) {
      return;
    }
    setFileName(file.name);
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const fileInputClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0) {
          handleFile(fileInputRef.current.files[0]);
        }
      }
    }
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    if (fileInputRef.current && e.dataTransfer.files.length > 0) {
      fileInputRef.current.files = e.dataTransfer.files;
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const signDocument = (documentToSign: DocumentToSign, callback: (documentSigned: DocumentSigned) => void) => {
    const euSign = signMethod === SignMethodEnum.file ? euSignFile : euSignKeyMedia;
    euSign
      .SignDataInternal(true, documentToSign.documentToSign, true)
      .then((signResult: string) => {
        callback({ uuid: documentToSign.uuid, signedDocument: signResult });
      })
      .catch((e: { message: string } | string) => {
        const msg: string = typeof e === 'string' ? e : e.message;
        Logger.log('Sign data error: ' + msg);
        logCertificateKeyDataError({
          message: `Ошибка генерируемая криптографией: ${msg} `,
          extra: {
            cryptoErrorCode: msg,
          },
        });
        notify({
          notifyProps: {
            title: t('payment_sign_error'),
            message: msg,
            severity: 'error',
          },
        });
        setLoading(false);
      });
  };

  const handleSignClickBtn = () => {
    const signedDocuments: DocumentSigned[] = [];
    const documentsToSignArr: DocumentToSign[] = [...documentsToSign];
    const callbackFn = (documentSigned: DocumentSigned) => {
      signedDocuments.push(documentSigned);
      setCurrentSignedDocuments(signedDocuments.length);
      const item = documentsToSignArr.shift();
      if (item) {
        signDocument(item, callbackFn);
      } else {
        if (documentsToSign.length > 2) {
          setTimeout(() => {
            setShowProgressPopup(false);
            onSignedStringResult(signedDocuments);
          }, 2000);
          return;
        }
        onSignedStringResult(signedDocuments);
        setLoading(false);
      }
    };

    if (documentsToSign.length > 2) {
      setShowProgressPopup(true);
    } else {
      setLoading(true);
    }

    const firstDocument = documentsToSignArr.shift();
    if (firstDocument) {
      signDocument(firstDocument, callbackFn);
    }
  };

  const errorNotify = (err: { code: number }) => {
    if (err.code === 24) {
      notify({
        notifyProps: {
          title: t('payment_sign_error'),
          message: t('KSE_LOGIN_REQUIRED'),
          severity: 'error',
        },
      });
    } else if (err.code === 50) {
      notify({
        notifyProps: {
          title: t('payment_sign_error'),
          message: `${t('ERR_BAD_PARAM')}`,
          severity: 'error',
        },
      });
    }
  };

  const validateCertificate = useCallback(
    (certInfoEx: EndUserCertificateInfoEx) => {
      const nowDate = new Date();
      if (clientProfile && userProfile && certInfoEx) {
        const drfo = certInfoEx.subjDRFOCode;
        const edrpou = certInfoEx.subjEDRPOUCode;
        const expired = certInfoEx.certEndTime < nowDate && nowDate > certInfoEx.certBeginTime;
        const dateForExp = new Date();
        dateForExp.setMonth(dateForExp.getMonth() + 1);
        if (certInfoEx.certEndTime < dateForExp) {
          setCertificateExpDays(differenceInDays(certInfoEx.certEndTime, nowDate));
        }
        if (clientProfile.privateEnterpreuner === TINCode) {
          let valid = false;
          if (clientProfile.lei !== drfo) {
            setCertificateVerifiedError(t('sign_key_verification_error'));
          } else if (expired) {
            setCertificateVerifiedError(t('sign_key_verification_expire_error'));
          } else {
            valid = true;
          }

          setCertificateVerified(valid);
        } else {
          const user = validateUser || userProfile;
          const validDdrpou = clientProfile.lei === edrpou;
          const validInn = user.inn === drfo;
          const valid = validDdrpou && validInn && !expired;

          if (!valid) {
            let errorText = null;
            if (!validDdrpou) {
              errorText = t('sign_key_verification_error');
            } else if (!validInn) {
              errorText = t('sign_key_verification_user_error');
            } else if (expired) {
              setCertificateVerifiedError(t('sign_key_verification_expire_error'));
            }

            setCertificateVerifiedError(errorText);
            // TODO: remove "clientProfileLei: clientProfile.lei" when we implement cerf attach.
            logCertificateValidationsError({
              message: 'Внутренняя ошибка верификации ключа на принадлежность к компании.',
              extra: {
                clientProfileLei: clientProfile.lei,
                clientProfilePrivateEnterpreuner: clientProfile.privateEnterpreuner,
                cerInfo: {
                  issuerCN: certInfoEx.issuerCN,
                  subjCN: certInfoEx.subjCN,
                  DirectoryAttributes: {
                    drfo,
                    edrpou,
                  },
                  certEndTime: certInfoEx.certEndTime,
                },
              },
            });
          } else {
            setCertificateVerifiedError(null);
          }
          setCertificateVerified(valid);
        }
      }
    },
    [clientProfile, t, userProfile, validateUser]
  );

  const getOwnCerAndValidate = (euSign: EndUser) => {
    // 2. якщо дані прочитані, то читаємо сертифікати
    euSign
      .GetOwnCertificates()
      .then((certs: EndUserCertificate[]) => {
        // 3. беремо перший сертифікат і валідуємо кредами профайлу
        const cert = certs[0];
        setCertificate(cert.infoEx);
        validateCertificate(cert.infoEx);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setCertificateVerifiedError(null);
        setCertificateVerified(false);
        setSignKeyError(true);
        logCertificateKeyDataError({
          message: "Can't read a certificate. The key expired or a file incorrect.",
        });
      });
  };
  // Головна функція для зчитування ключа з файлу на ПК
  const readFromFileDataKeyHandler = () => {
    if (fileInputRef.current?.files && fileInputRef.current.files[0]) {
      setLoading(true);
      const file = fileInputRef.current.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          // 1. вичитуємо дані ключа з файлу
          euReadPrivateKey(file, reader.result as ArrayBuffer)
            .then((result: EndUserOwnerInfo) => {
              getOwnCerAndValidate(euSignFile);
            })
            .catch((err) => {
              setLoading(false);
              errorNotify(err);
              logCertificateKeyDataError({
                message: err.message,
              });
            });
        }
      };
      reader.onerror = () => {
        notify({
          notifyProps: {
            title: t('payment_sign_error'),
            message: t('sign_key_error'),
            severity: 'error',
          },
        });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const readFromMediaDeviceKeyHandler = () => {
    const pkCertificates = undefined;
    const keyMedia = new EndUserKeyMedia(endUserKeyMedia);
    keyMedia.password = password;

    euSignKeyMedia
      .ReadPrivateKey(keyMedia, pkCertificates, caCN)
      .then((result: EndUserOwnerInfo) => {
        // 2. якщо дані прочитані, то читаємо сертифікати
        getOwnCerAndValidate(euSignKeyMedia);
      })
      .catch((err: { message: string }) => {
        logCertificateKeyDataError({
          message: err.message,
        });
      });
  };

  const onHandleClose = () => {
    resetForm();
    onClose();
  };

  const onChangeSignMethodHandler = (method: SignMethodEnum) => {
    setSignMethod(method);
    resetForm();
  };

  const onEUSelectKeyMediaHandler = (
    event: (Event & { target: { value: unknown; name: string } }) | React.ChangeEvent<HTMLInputElement>
  ) => {
    setEndUserKeyMedia(typeof event.target.value === 'string' ? undefined : (event.target.value as EndUserKeyMedia));
  };

  const signButtonDisabled = loading || !certificateVerified || password.length === 0;

  const readDataKeyButtonDisabled =
    loading || password.length === 0 || fileName?.length === 0 || !endUserSignFileInitialized;

  const readDataKeyFromMediaButtonDisabled =
    loading || password.length === 0 || !endUserSignKeyMediaInitialized || !endUserKeyMedia;

  const error = certificateVerifiedError || signKeyError;

  const showFormControls =
    signMethod === SignMethodEnum.file ||
    (signMethod === SignMethodEnum.hardToken &&
      (!Boolean(endUserSignKeyMediaLibraryInfo) || endUserSignKeyMediaInitialized));

  const progressSignCountText = t('progressSignCountLabel')
    .replace('${value1}', `${currentSignedDocuments}`)
    .replace('${value2}', `${documentsToSign.length}`);

  return (
    <>
      {showProgressPopup ? (
        <ProgressPopup
          current={currentSignedDocuments}
          total={documentsToSign.length}
          beforeProgressText={t('progressStepIsSign')}
          afterProgressText={progressSignCountText}
        />
      ) : (
        <ModalCommon open>
          <Grid container direction="column" wrap="nowrap" sx={styles.paper}>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <Grid container alignItems="center">
                <Typography variant="h6">
                  <b>{title}</b>
                </Typography>
              </Grid>

              {!loading ? (
                <IconButton size="small" onClick={onHandleClose}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : null}
            </Grid>
            <Box mt={5}>
              <SignMethodSelectTabs selectedMethod={signMethod} onChange={onChangeSignMethodHandler} />
            </Box>
            {signMethod === SignMethodEnum.file ? (
              <Box mt={5}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  wrap="nowrap"
                  sx={{
                    ...styles.dropContainer,
                    ...((oveDragZone || fileName?.length) && styles.dropContainerActive),
                    ...(error && styles.dropContainerError),
                    ...(certificateVerified && styles.dropContainerCertificateVerified),
                  }}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <Typography variant="body2" component="div" color="textPrimary" sx={styles.mainText}>
                    <input
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      id="upload"
                      name="upload"
                      type="file"
                      disabled={loading || !endUserSignFileInitialized}
                      onChange={fileSelected}
                    />
                    {fileName ? (
                      <Typography component="span" variant="body2" sx={{ ...(error && { ...styles.errorColorText }) }}>
                        {!error ? t('key_file') : null}
                        {certificateVerifiedError}
                        {signKeyError ? t('invalid_key') : null}
                        <Box sx={styles.fontWeight}>{fileName}</Box>
                      </Typography>
                    ) : (
                      <>
                        {isMobile ? null : (
                          <>
                            {t('drag_key')} <br />
                          </>
                        )}
                        <Button
                          variant="text"
                          sx={styles.selectKeyFileLabel}
                          onClick={fileInputClicked}
                          disabled={loading}
                        >
                          {isMobile ? t('signaturePopup_select_key_file') : t('select_key')}
                        </Button>
                      </>
                    )}
                  </Typography>
                  <Typography variant="caption" sx={styles.secondText}>
                    {fileName ? (
                      <Button variant="text" sx={styles.label} onClick={fileInputClicked} disabled={loading}>
                        {t('upload_other_file')}
                      </Button>
                    ) : (
                      `${t('valid_format')}: ${keyECPfileTypes.join('. ')}`
                    )}
                  </Typography>
                </Grid>
              </Box>
            ) : null}

            {signMethod === SignMethodEnum.hardToken ? (
              <Box mt={5}>
                {endUserSignKeyMediaInitialized ? (
                  <>
                    <FormLabel component="legend">{t('token')}</FormLabel>
                    <Box pt={2}>
                      <Select
                        displayEmpty
                        fullWidth
                        value={endUserKeyMedia?.visibleName || ''}
                        disabled={endUserSignKeyMediaKeyMedias?.length === 0}
                        variant="outlined"
                        onChange={onEUSelectKeyMediaHandler}
                      >
                        {endUserSignKeyMediaKeyMedias ? (
                          endUserSignKeyMediaKeyMedias.map((keyMedia) => (
                            <MenuItem value={keyMedia.visibleName} key={keyMedia.visibleName}>
                              {keyMedia.visibleName}
                            </MenuItem>
                          ))
                        ) : (
                          <div>Loading</div>
                        )}
                      </Select>
                    </Box>
                  </>
                ) : null}
                {!endUserSignKeyMediaInitialized && endUserSignKeyMediaLibraryInfo ? (
                  <Box sx={styles.euNativeLibraryErrorContainer}>
                    <Typography variant="body1">{t('crypto_euNativeLibraryErrorInitialize')}</Typography>
                    <Box mt={2}>
                      {!endUserSignKeyMediaLibraryInfo.isWebExtensionInstalled ? (
                        <a href={endUserSignKeyMediaLibraryInfo.nativeLibraryInstallURL} target="_blank">
                          {t('crypto_nativeLibraryInstallURL')}
                        </a>
                      ) : null}
                      {endUserSignKeyMediaLibraryInfo.isWebExtensionSupported &&
                      !endUserSignKeyMediaLibraryInfo.isWebExtensionInstalled ? (
                        <div>
                          <a href={endUserSignKeyMediaLibraryInfo.webExtensionInstallURL} target="_blank">
                            {t('crypto_nativeLibraryInstallURLPlugin')}
                          </a>
                        </div>
                      ) : null}
                      {endUserSignKeyMediaLibraryInfo.isNativeLibraryNeedUpdate ? (
                        <div>
                          <a href={endUserSignKeyMediaLibraryInfo.nativeLibraryUpdateURL} target="_blank">
                            {t('crypto_nativeLibraryUpdateURL')}
                          </a>
                        </div>
                      ) : null}
                    </Box>
                    <div>
                      <a href={endUserSignKeyMediaLibraryInfo.helpURL} target="_blank">
                        {t('crypto_helpURL')}
                      </a>
                    </div>
                  </Box>
                ) : null}
              </Box>
            ) : null}
            {showFormControls ? (
              <Box mt={4}>
                <Typography variant="body2">
                  <b>{t('password')}</b>
                </Typography>
                <Box pt={2}>
                  <OutlinedInput
                    fullWidth
                    placeholder={t('enter_password')}
                    type={showPass ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePass}
                    sx={styles.passwordInput}
                    disabled={
                      loading || Boolean(certificateVerifiedError) || (signMethod === SignMethodEnum.file && !fileName)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass(!showPass)} size="large">
                          {showPass ? <ShowIcon /> : <HideIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    autoComplete="new-password"
                  />
                </Box>
              </Box>
            ) : null}

            {certificate ? (
              <Box mt={4}>
                {certificateVerifiedError && signMethod === SignMethodEnum.hardToken ? (
                  <Box>
                    <Grid container alignItems="center" sx={styles.errorAlert}>
                      <ErrorSmallIcon fontSize="small" />
                      <Typography variant="caption" sx={styles.errorAlertText}>
                        {certificateVerifiedError}
                      </Typography>
                    </Grid>
                  </Box>
                ) : null}
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_FSP')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoOwnerName}>
                      {certificate.subjCN}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_ITN')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.subjDRFOCode}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_Certificate_issued_by')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.issuerCN}
                    </Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="caption" component="p" sx={styles.infoText}>
                    {t('payment_sign_Serial_number')}{' '}
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      {certificate.serial}
                    </Typography>
                  </Typography>
                </Box>
                {certificate?.subjEDRPOUCode ? (
                  <Box mb={2}>
                    <Typography variant="caption" component="p" sx={styles.infoText}>
                      {t('paymentSign_CertificateEdrpou')}{' '}
                      <Typography variant="body2" component="span" sx={styles.infoValue}>
                        {certificate.subjEDRPOUCode}
                      </Typography>
                    </Typography>
                  </Box>
                ) : null}
                {certificate?.subjOCode?.length > 0 ? (
                  <Box mb={2}>
                    <Typography variant="caption" component="p" sx={styles.infoText}>
                      {t('paymentSign_CertificateCompanyName')}{' '}
                      <Typography variant="body2" component="span" sx={styles.infoValue}>
                        {certificate.subjOCode}
                      </Typography>
                    </Typography>
                  </Box>
                ) : null}
                {certificateExpDays ? (
                  <Box mb={2} sx={styles.expDaysContent}>
                    <Typography variant="body2" component="span" sx={styles.infoValue}>
                      До закінчення дії сертифікату залишилося {certificateExpDays} днів
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            ) : null}
            {showFormControls ? (
              <Box mt={7} sx={styles.buttonWrap}>
                {!certificateVerified ? (
                  <>
                    {signMethod === SignMethodEnum.file ? (
                      <Button
                        fullWidth={isMobile}
                        variant="contained"
                        color="primary"
                        endIcon={loading ? <LoaderIcon /> : <SignatureIcon />}
                        disabled={readDataKeyButtonDisabled}
                        onClick={readFromFileDataKeyHandler}
                      >
                        {loading ? t('loading') : t('read')}
                      </Button>
                    ) : null}
                    {signMethod === SignMethodEnum.hardToken ? (
                      <Button
                        fullWidth={isMobile}
                        variant="contained"
                        color="primary"
                        endIcon={loading ? <LoaderIcon /> : <SignatureIcon />}
                        disabled={readDataKeyFromMediaButtonDisabled}
                        onClick={readFromMediaDeviceKeyHandler}
                      >
                        {loading ? t('loading') : t('read')}
                      </Button>
                    ) : null}
                  </>
                ) : null}
                {certificateVerified ? (
                  <Button
                    fullWidth={isMobile}
                    variant="contained"
                    color="primary"
                    endIcon={loading ? <LoaderIcon /> : <SignatureIcon />}
                    disabled={signButtonDisabled}
                    onClick={handleSignClickBtn}
                  >
                    {loading ? t('loading') : t('sign')}
                  </Button>
                ) : null}
              </Box>
            ) : null}
            {signMethod === SignMethodEnum.hardToken && !endUserSignKeyMediaInitialized ? (
              <Box mt={7} sx={styles.buttonWrap}>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.reload()}
                >
                  {t('updatePage')}
                </Button>
              </Box>
            ) : null}
          </Grid>
        </ModalCommon>
      )}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  accountsApiClient,
  ApiError,
  CurrencyOperationsExchangeTypeEnum,
  GetAccountResponseDto,
  getClientId,
} from '@app/core/api';

const getCurrencyOperationsAccountFn = async (): Promise<GetAccountResponseDto[]> => {
  const response = await accountsApiClient.accountsControllerGetCurrencyOperationsAccount(getClientId(), {
    exchangeType: CurrencyOperationsExchangeTypeEnum.Sale,
    withdrawalAccounts: true,
  });

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "accountsControllerGetCurrencyOperationsAccount error. Can't get accounts."
    );
  }

  return response.data;
};

export const useGetCurrencyOperationsAccount = (): UseMutationResult<GetAccountResponseDto[], ApiError, any> => {
  return useMutation<GetAccountResponseDto[], ApiError, any>(getCurrencyOperationsAccountFn);
};

import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Cookies from 'js-cookie';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import '@app/src/i18n/i18n';

import { NotifyProvider } from '@app/context/NotifyContext';
import { QueryClientProvider } from '@app/context/QueryClientContext';
// import { ServiceWorkerProvider } from '@app/context/ServiceWorkerContext';
import { UIProvider } from '@app/context/UIContext';
import { App } from '@app/src/App';
import { appConfig } from '@app/src/config';
import { store } from '@app/src/store';
import { ThemeProvider } from '@app/themes/ThemeProvider';

import '@app/src/index.scss';
import '@app/src/sheet-style.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL || '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: appConfig.version,
  initialScope: {
    tags: {
      'core-version': appConfig.coreVersion,
      'screen-size': `${window.innerWidth}x${window.innerHeight}`,
    },
  },
  environment: 'dev',
  debug: false,
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

// TODO: remove after 3-4 month of usage
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

// TODO: remove after 3-4 month of usage
if (!Cookies.get('webWorkerStopped')) {
  Cookies.set('webWorkerStopped', 'true');
  console.log(`Force page reloading`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.location.reload(true);
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <QueryClientProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <NotifyProvider>
                {/*<ServiceWorkerProvider>*/}
                <UIProvider>
                  <App />
                </UIProvider>
                {/*</ServiceWorkerProvider>*/}
              </NotifyProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

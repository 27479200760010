import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { DocumentOperationType, OperationsWithoutFilesDto } from '@app/core/api';
import { ModalPopupCommon } from '@app/core/components/Modal/ModalPopupCommon';
import { cropName, DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { ActionsButtons } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/components/ActionsButtons';
import { deleteCurrencyDocumentQueryFn } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/query';
import { styles } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/style';
import { RouteList } from '@app/src/constants/routeList';
import { ReactComponent as CloseIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';

type DeleteCurrencyDocument = {
  uuid: string;
  originalName: string;
};

export interface DeleteSingleCurrencyDocumentModalProps {
  document: DeleteCurrencyDocument;
  relateOperations: Array<OperationsWithoutFilesDto>;
  onClose(): void;
  onDeleteDocumentSuccess(): void;
}

export const DeleteSingleCurrencyDocumentModal: React.FC<DeleteSingleCurrencyDocumentModalProps> = ({
  document,
  relateOperations,
  onClose,
  onDeleteDocumentSuccess,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const { mutate } = useMutation(deleteCurrencyDocumentQueryFn, {
    onSuccess: () => {
      onDeleteDocumentSuccess();
      showLoader(false);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('deleteDocumentSuccessMessage'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      showLoader(false);
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('deleteDocumentErrorMessage'),
          severity: 'error',
        },
      });
    },
  });

  const shortDocumentName = cropName(document.originalName || '', 30);
  const onSubmit = () => {
    mutate({ uuids: [document.uuid] });
  };

  const onRelateOperationClick = (uuid: string) => {
    navigate(`${RouteList.exchange_edit}/${uuid}`);
  };

  return (
    <ModalPopupCommon open questionPaper>
      <>
        <Grid justifyContent="flex-end" container>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography component="div" variant="body1" sx={styles.captionSaveModal}>
          {t('deleteCurrencyDocumentModalTitle')}
        </Typography>

        <Grid container alignItems="center" justifyContent="center" sx={styles.captionSaveModal}>
          <Typography component="div" variant="body1" sx={styles.warning}>
            {`${t('attention')} `}{' '}
          </Typography>
          <Typography>{t('deleteCurrencyDocumentSubTitle')}</Typography>
        </Grid>
        {relateOperations.length ? (
          <>
            <Grid container alignItems="center" sx={styles.captionSaveModal}>
              <Typography component="div" variant="body2" sx={styles.bold}>
                {`${t('Document')} ${shortDocumentName} ${t('hasRelations')}`}
              </Typography>
            </Grid>
            {relateOperations.map((operation) => {
              const typeText =
                operation.operationType === DocumentOperationType.CurrencyOperation ? t('currencyExchange') : 'SWIFT';
              return (
                <Grid
                  key={operation.uuid}
                  container
                  justifyContent="space-between"
                  sx={styles.relateOperationsContainer}
                  onClick={() => {
                    onRelateOperationClick(operation.uuid);
                  }}
                >
                  <Typography>{`${formatISODateString(operation.documentDate, DATE_FORMAT)} - ${typeText}#${
                    operation.documentNumber
                  }`}</Typography>
                  <Typography sx={styles.bold}>{`${operation.amount} ${operation.currency}`}</Typography>
                </Grid>
              );
            })}
          </>
        ) : null}

        <ActionsButtons onCancel={onClose} onConfirm={onSubmit} />
      </>
    </ModalPopupCommon>
  );
};

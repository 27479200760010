export const styles = {
  infoText: {
    fontWeight: 600,
    textAlign: 'center',
  },
  paper: {
    backgroundColor: 'white',
    padding: `12px 12px 28px`,
    outline: 0,
    borderRadius: '8px',
    maxWidth: '327px',
    maxHeight: '75vh',
    overflow: 'hidden',
    position: 'relative',
  },
  iconBg: {
    position: 'absolute',
    left: 0,
    top: '14px',
  },
  sendButton: {
    marginTop: '28px',
    minWidth: '110px',
    '& > span': {
      fontWeight: 600,
    },
  },
};

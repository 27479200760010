import {
  accountsApiClient,
  currencyDocumentsApiClient,
  currencyOperationsApiClient,
  getClientId,
  ApiError,
  CreateCurrencyOperationRequestDto,
  CreateCurrencyOperationResponseDto,
  DetachOperationRequestDto,
  DetachOperationResponseDto,
  EditCurrencyOperationRequestDto,
  GeneratedDocumentNumberResponseDto,
  GetAccountResponseDto,
  GetCurrencyOperationAccountsDto,
  GetDocumentResponseDto,
  GetOneCurrencyOperationResponseDto,
  SendToSignResponseDto,
} from '@app/core/api';

export const getCurrencyAccountsQueryFn = async (
  getCurrencyOperationAccountsDto: GetCurrencyOperationAccountsDto
): Promise<GetAccountResponseDto[]> => {
  const response = await accountsApiClient.accountsControllerGetCurrencyOperationsAccount(
    getClientId(),
    getCurrencyOperationAccountsDto
  );

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "accountsControllerGetCurrencyOperationsAccount error. Can't get accounts."
    );
  }

  return response.data;
};

export const generateOrderNumberFn = async (): Promise<GeneratedDocumentNumberResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerGenerateOrderNumber(getClientId());
  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerGenerateOrderNumber error. Can't get generated document number."
    );
  }

  return response.data;
};

export const validateOrderNumberQueryFn = async (documentNumber: string): Promise<boolean> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerCheckOrderNumber(getClientId(), {
    documentNumber,
  });

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerCheckOrderNumber error. Can't validate document number."
    );
  }

  return response.data.available;
};

export const createCurrencyOperationFn = async (
  createCurrencyOperationRequestDto: CreateCurrencyOperationRequestDto
): Promise<CreateCurrencyOperationResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerCreateCurrencyOperation(
    getClientId(),
    createCurrencyOperationRequestDto
  );
  if (response.status !== 200) {
    throw new ApiError(response.request.status, "createCurrencyOperation error. Can't create currency operation.");
  }
  return response.data;
};

export const editCurrencyOperationFn = async (
  editCurrencyOperationRequestDto: EditCurrencyOperationRequestDto
): Promise<CreateCurrencyOperationResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerEditCurrencyOperation(
    getClientId(),
    editCurrencyOperationRequestDto
  );
  if (response.status !== 200) {
    throw new ApiError(response.request.status, "editCurrencyOperationFn error. Can't edit currency operation.");
  }
  return response.data;
};

export const getCurrencyOperationFn = async (uuid: string): Promise<GetOneCurrencyOperationResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerGetCurrencyOperation(
    getClientId(),
    uuid
  );

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerGetCurrencyOperation error. Can't get currency operation."
    );
  }

  return response.data;
};

export const getCurrencyOperationFilesFn = async (uuid: string): Promise<Array<GetDocumentResponseDto>> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerGetFiles(getClientId(), uuid);

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerGetFiles error. Can't get files for operation."
    );
  }

  return response.data;
};
export const detachOperationFromFileFn = async (
  detachOperationRequestDto: DetachOperationRequestDto
): Promise<DetachOperationResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerDetachOperation(
    getClientId(),
    detachOperationRequestDto
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "documentsControllerDetachOperation error. Can't get detach.");
  }

  return response.data;
};

export const sendToSignCurrencyOperationFn = async (uuid: string): Promise<SendToSignResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerBatchSendToSign(getClientId(), {
    uuids: [uuid],
  });

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerBatchSendToSign error. Can't send to sign."
    );
  }

  return response.data;
};

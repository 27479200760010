export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileMenuContainer: {
    overflow: 'scroll',
  },
  itemWrapper: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    lineHeight: 1.5,
    paddingRight: '32px',
  },
  itemText: {
    userSelect: 'none',
    cursor: 'pointer',
    color: 'colors.gray400',
    fontWeight: 600,
  },
  selected: {
    color: 'colors.primary600',
  },
  disabled: {
    cursor: 'auto',
    color: 'colors.gray200',
  },
  divider: {
    marginRight: '20px',
    borderLeft: '1px solid',
    borderColor: 'colors.gray100',
    width: '1px',
    height: '20px',
  },
  outlined: {
    paddingRight: '40px!important',
    fontWeight: 700,
    color: 'colors.primary600',
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
};

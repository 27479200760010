export const styles = {
  mainContent: {
    marginTop: '12px',
  },
  paper: {
    backgroundColor: 'white',
    padding: '28px',
    outline: 0,
    borderRadius: '4px',
    width: '100%',
    maxWidth: '570px',
  },
  row: {
    marginBottom: '20px',
  },
  nameRow: {
    paddingTop: '4px',
  },
  iban: {
    color: 'colors.primary600',
    '& > span': {
      fontWeight: 600,
    },
  },
  addButton: {
    fontSize: '0.75rem',
    marginTop: '6px',
  },
  sendButton: {
    minWidth: '120px',
    marginLeft: '4px',
    '& > span': {
      fontWeight: 600,
    },
  },
  tempSendButton: {
    width: '120px',
    paddingRight: '40px',
  },
  buttonsLoader: {
    right: 0,
    top: '75px',
    left: 'auto',
    zoom: 0.3,
    '& div > div': {
      background: 'white',
    },
  },
  buttonIcon: {
    marginLeft: '8px',
  },
};

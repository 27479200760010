import { useMutation, UseMutationResult } from 'react-query';

import {
  getClientId,
  statementsApiClient,
  ApiError,
  FileLinkResponseDto,
  StatementTypeEnum,
  OutputFormatEnum,
} from '@app/core/api';

interface GetMultipleStatementsFilesProps {
  operationsType: StatementTypeEnum;
  outputType: OutputFormatEnum;
  ordersUids: Array<string>;
}

const getMultipleStatementsFiles = async ({
  operationsType,
  outputType,
  ordersUids,
}: GetMultipleStatementsFilesProps): Promise<FileLinkResponseDto> => {
  const response = await statementsApiClient.operationsControllerGetMultiOrders(getClientId(), {
    outputType,
    operationsType,
    ordersUids,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get multi orders file link.");
  }

  return response.data;
};

export const useGetStatementsMultipleFiles = (): UseMutationResult<
  FileLinkResponseDto,
  ApiError,
  GetMultipleStatementsFilesProps
> => useMutation<FileLinkResponseDto, ApiError, GetMultipleStatementsFilesProps>(getMultipleStatementsFiles);

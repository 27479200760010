export const styles = {
  fundRoot: {
    backgroundColor: 'colors.containerBackgroundColor',
    borderRadius: '8px',
    padding: '8px 16px',
    minWidth: 180,
    '@media (max-width: 959.95px)': {
      //breakpoints.down('sm')
      minWidth: '136px',
    },
    textAlign: 'right',
    minHeight: '64px',
    marginBottom: '8px',
  },
  fundAmount: {
    color: 'colors.primary600',
    fontWeight: 600,
  },
  fundCurrency: {
    marginLeft: 1,
    color: 'colors.gray500',
    fontWeight: 600,
  },
  fundDescription: {
    color: 'colors.gray400',
    '@media (max-width: 959.95px)': {
      fontSize: '0.875rem',
    },
  },
  totalText: {
    marginRight: '30px',
    '@media (max-width: 959.95px)': {
      fontSize: '0.875rem',
    },
  },
  exchangeRate: {
    '@media (max-width: 959.95px)': {
      fontSize: '0.875rem',
    },
  },
};

import { ThemeEnum, Styles } from '@app/core/types';

export const styles: Styles = {
  menuButton: {
    marginRight: '36px',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexDirection: 'column',
  },
  menuLogoWrapper: {
    height: '80px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuCompanyWrapper: {
    height: '188px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  collapseButton: {
    padding: '0 32px',
  },
  collapseText: {
    color: 'colors.gray300',
    fontSize: 12,
    paddingLeft: '16px',
  },
  versionText: {
    color: 'colors.gray300',
    fontSize: 12,
    paddingRight: '16px',
    cursor: 'pointer',
  },
  collapseContainer: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  scrollable: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  arrowIcon: {
    color: 'colors.closeOpenArrowIconColor',
  },
  arrowIconOpen: {
    fill: ({ themeName, palette: { colors } }) =>
      themeName === ThemeEnum.light ? colors.primary700 : colors.primary700,
  },
};

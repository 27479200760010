import React, { useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { ProfileSettingsItemUsers } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers';
import { AddUserInfoModal } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/AddUserInfoModal';
import { ProfileSettingsItemCompanyData } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyData';
import { ProfileSettingsItemCompanySettings } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanySettings';
import { ProfileSettingsItemCompanyHistoryOfVisits } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory';
import { styles } from '@app/pages/companyProfile/desktop/style';
import { getMenuItems } from '@app/pages/companyProfile/helper';
import { CompanyProfileMenuFilter } from '@app/pages/companyProfile/types';

export const DesktopCompanyProfilePage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentFilter, setCurrentFilter] = useState<CompanyProfileMenuFilter>(
    (searchParams.get('tab') as CompanyProfileMenuFilter) || CompanyProfileMenuFilter.Data
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const menuItems = getMenuItems(t);

  const onFilterChangeHandler = (filter: CompanyProfileMenuFilter) => {
    setSearchParams(`tab=${filter}`);
    setCurrentFilter(filter);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('companyProfile')} useDesktopStyle>
          {currentFilter === CompanyProfileMenuFilter.Users ? (
            <Grid item>
              <Button type="submit" variant="contained" color="primary" fullWidth onClick={handleShowModal}>
                {t('add_user')}
              </Button>
            </Grid>
          ) : null}
        </PageHeader>
        <PageContainer>
          <Grid container direction="column" wrap="nowrap">
            <HorizontalMenu<CompanyProfileMenuFilter>
              defaultActiveItem={currentFilter}
              onChange={onFilterChangeHandler}
              menuItems={menuItems}
            />
            <Box pt={7} sx={styles.verticalWrapper}>
              {currentFilter === CompanyProfileMenuFilter.Data && <ProfileSettingsItemCompanyData />}
              {currentFilter === CompanyProfileMenuFilter.History && <ProfileSettingsItemCompanyHistoryOfVisits />}
              {currentFilter === CompanyProfileMenuFilter.Users && <ProfileSettingsItemUsers />}
              {currentFilter === CompanyProfileMenuFilter.Settings && <ProfileSettingsItemCompanySettings />}
            </Box>
          </Grid>
        </PageContainer>
        {showModal ? <AddUserInfoModal onClose={handleCloseModal} /> : null}
      </Grid>
    </PageWrapper>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DownChevronSmallIcon, FilterIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ApiError,
  DealStateEnum,
  GetOverdraftsListRequestDto,
  GetOverdraftsListResponseDto,
  InternalOverdraftSortingRuleDto,
} from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useProfileControllerSendRequest } from '@app/core/hooks';
import { TableColumnConfig, PaginationChangeType } from '@app/core/types';
import { makeDataFromSubmit } from '@app/pages/overdrafts/components/helpers';
import { OverdraftsFilter } from '@app/pages/overdrafts/components/OverdraftsFilter';
import { OverdraftsTable } from '@app/pages/overdrafts/components/OverdraftsTable';
import { getOverdraftsFn, overdraftsControllerUpdateFn } from '@app/pages/overdrafts/components/query';
import { styles } from '@app/pages/overdrafts/style';
import { fieldsCreditTable } from '@app/pages/overdrafts/tableFields';
import { OverdraftsFilterFormData, OverdraftsTypeEnum } from '@app/pages/overdrafts/types';
import { requestTopic } from '@app/src/config';
import { ReactComponent as OverdraftPlaceholder } from '@app/themes/default/assets/icons/overdraftPlaceholder.svg';

const defaultValues: OverdraftsFilterFormData = {
  agreementStartDateFrom: undefined,
  agreementStartDateTo: undefined,
  agreementEndDateFrom: undefined,
  agreementEndDateTo: undefined,
  agreementNumber: '',
  accountOverdraft: '',
  amountFrom: '',
  amountTo: '',
  currency: '',
  interestRateFrom: '',
  interestRateTo: '',
  temp_agreementStartDateFrom: '',
  temp_agreementStartDateTo: '',
  temp_agreementEndDateFrom: '',
  temp_agreementEndDateTo: '',
};

export const OverdraftContent: React.FC = () => {
  const dealStateRef = useRef<DealStateEnum[]>([DealStateEnum.New, DealStateEnum.Active]);
  const [fullItems, setFullItems] = useState(false);
  const { t } = useTranslation();
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });
  const sortRuleRef = useRef<InternalOverdraftSortingRuleDto | undefined>();

  const overdraftsTabsItems: MenuItem<OverdraftsTypeEnum>[] = [
    {
      id: 1,
      labelText: t('open'),
      value: OverdraftsTypeEnum.open,
    },
    {
      id: 2,
      labelText: t('closed'),
      value: OverdraftsTypeEnum.closed,
    },
  ];

  const { notify } = useNotify();

  const methods = useForm<OverdraftsFilterFormData>({ mode: 'onChange', defaultValues });
  const { handleSubmit, reset: formReset, formState, getValues, control } = methods;

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<GetOverdraftsListResponseDto, ApiError, GetOverdraftsListRequestDto>(getOverdraftsFn);
  const overdraftsOperations = data?.overdrafts;
  const pagination = data?.pagination;

  const [activeTab, setActiveTab] = useState<OverdraftsTypeEnum>(OverdraftsTypeEnum.open);

  const updateList = () => {
    mutate({
      ...makeDataFromSubmit(getValues()),
      pagination: paginationRef.current,
      states: dealStateRef.current,
    });
  };

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
      states: dealStateRef.current,
    });
  }, [mutate]);

  const { refetch, isSuccess } = useProfileControllerSendRequest(requestTopic.overdraft);

  const handleChange = (currentTab: OverdraftsTypeEnum): void => {
    setActiveTab(currentTab);
    if (currentTab === OverdraftsTypeEnum.open) {
      dealStateRef.current = [DealStateEnum.New, DealStateEnum.Active];
    }
    if (currentTab === OverdraftsTypeEnum.closed) {
      dealStateRef.current = [DealStateEnum.Closed, DealStateEnum.Deleted, DealStateEnum.WillBeClosed];
    }
    updateList();
  };

  const onSubmit = () => {
    updateList();
  };

  const resetFormHandler = () => {
    formReset();
    updateList();
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateList();
    }
  };

  const onSortHandler = (rule: InternalOverdraftSortingRuleDto) => {
    sortRuleRef.current = rule;
    updateList();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    if (data?.overdrafts.length) {
      updateList();
    }
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const configurationSavedHandler = (tableColumnsData: TableColumnConfig[]) => {
    // dispatch(updateOperationsTableConfig(tableColumnsData));
  };

  const sendRequest = () => {
    refetch();
  };
  useEffect(() => {
    if (isSuccess) {
      notify({
        notifyProps: {
          title: t('success'),
          message: t('sendRequestSuccess'),
          severity: 'success',
        },
      });
    }
  }, [isSuccess, notify, t]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={onKeyPressHandler}
        role="presentation"
        style={{
          height: '100%',
        }}
      >
        <HorizontalMenu<OverdraftsTypeEnum> onChange={handleChange} menuItems={overdraftsTabsItems} />
        {overdraftsOperations?.length || formState.isDirty ? (
          <Grid item container direction="column" wrap="nowrap" sx={styles.verticalWrapper}>
            <Box pb={4} mt={7}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <FilterIcon />
                  <Typography variant="h6" component="span" sx={styles.captionFilter}>
                    {t('filters')}
                  </Typography>
                </Grid>
                <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
                  {t('additionalParameters')}
                  {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
                </Button>
              </Grid>
            </Box>
            <Box pb={4}>
              <OverdraftsFilter
                fullItems={fullItems}
                namesFieldWithError={Object.keys(formState.errors)}
                resetFormHandler={resetFormHandler}
              />
            </Box>
            <OverdraftsTable
              loading={isLoading}
              overdrafts={overdraftsOperations || []}
              columnsConfig={fieldsCreditTable}
              columnsConfigMutationFn={overdraftsControllerUpdateFn}
              pagination={pagination}
              onSort={onSortHandler}
              onPaginationChange={onPaginationChangeHandler}
              onSuccessConfigurationSaved={configurationSavedHandler}
            />
          </Grid>
        ) : (
          <>
            {!isLoading ? (
              <Grid container alignItems="center" justifyContent="center" sx={styles.placeholder} direction="column">
                <OverdraftPlaceholder />
                {activeTab === OverdraftsTypeEnum.open ? (
                  <>
                    <Typography variant="h5" sx={styles.placeholderTitle}>
                      {t('overdraftPlaceholderTitle')}
                    </Typography>
                    <Typography variant="body2" sx={styles.placeholderDescription}>
                      {t('overdraftPlaceholderDescription')}
                    </Typography>
                    <Box mt={5}>
                      <Button size="small" variant="contained" color="primary" onClick={sendRequest}>
                        {t('submitApplication')}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Typography variant="h5" sx={styles.placeholderTitle}>
                    {t('overdraftClosedPlaceholderTitle')}
                  </Typography>
                )}
              </Grid>
            ) : null}
          </>
        )}
      </form>
    </FormProvider>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, accountsApiClient, ApiError, SendAccountRequisitesDto } from '@app/core/api';

const sendRequisitesQueryFn = async (data: SendAccountRequisitesDto): Promise<object> => {
  const result = await accountsApiClient.accountsControllerSendAccountRequisites(getClientId(), data);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error send requisites!');
  }
  return result.data;
};

export const useAccountControllerSendRequisites = (): UseMutationResult<object, ApiError, SendAccountRequisitesDto> => {
  return useMutation<object, ApiError, SendAccountRequisitesDto>(sendRequisitesQueryFn);
};

export const styles = {
  sortIcon: {
    fill: 'colors.primary500',
  },
  cell: {
    flex: '1',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  cell_body: {},
  cell_selection: {
    maxWidth: '46px',
  },
  cell_relateOperations: {
    maxWidth: '200px',
  },
  cell_originalName: {
    maxWidth: '170px',
    minWidth: '100px',
  },
  cell_createDate: {
    maxWidth: '130px',
  },
  cell_sender: {
    maxWidth: '190px',
  },
  cell_comment: {
    maxWidth: '200px',
    minWidth: '100px',
    flex: 4,
  },
  cell_incomingStatus: {
    maxWidth: '130px',
  },
  cell_edit_action: {
    maxWidth: '40px',
    color: 'colors.gray400',
  },
  cell_delete_action: {
    maxWidth: '40px',
    color: 'colors.gray400',
  },
  cell_actions: {
    maxWidth: '32px',
    color: 'inherit',
  },
  messageStatus: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.gray200',
  },
  messageStatusGreen: {
    backgroundColor: 'colors.success',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  status: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  statusRejected: {
    backgroundColor: 'colors.error',
  },
  statusExecuted: {
    backgroundColor: 'colors.success',
  },
  statusAccepted: {
    backgroundColor: 'colors.success',
  },
};

export const styles = {
  paper: {
    backgroundColor: 'white',
    padding: '28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '824px',
    maxHeight: '75vh',
  },
  wrapHeight: {
    maxHeight: '80%',
    overflow: 'auto',
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
    marginRight: 0,
  },
  caption: {
    fontWeight: 700,
  },
  labelInput: {
    color: 'colors.gray300',
    fontWeight: 600,
  },
  captionSaveModal: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
  },
  buttonSaveModal: {
    marginLeft: 12,
    marginRight: 12,
  },
  closedCaption: {
    position: 'relative',
    fontSize: '0.75rem',
    color: 'colors.gray200',
    marginBottom: 4,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 0,
      width: '100%',
      height: '1px',
      borderBottom: `1px dashed`,
      borderColor: 'colors.gray50',
    },
    '& span': {
      position: 'relative',
      zIndex: 1,
      backgroundColor: 'white',
    },
  },
  sendButton: {
    marginTop: '20px',
    '& > span': {
      fontWeight: 600,
    },
  },
  alertSignature: {
    backgroundColor: 'colors.errorBackground',
    border: `1px solid`,
    borderColor: 'colors.error',
    borderRadius: '4px',
    padding: '12px',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  alertSignatureText: {
    fontWeight: 600,
    color: 'colors.error',
    marginLeft: '8px',
  },
  errorIcon: {
    color: 'colors.error',
  },
};

import { format, intervalToDuration } from 'date-fns';

import { UserSessionDto } from '@app/core/api';
import { tokenLifetime } from '@app/core/constants';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';

export interface VisitsRow {
  date: string;
  duration: string | number;
  country: string;
  browser: string;
  ip: string;
  operationSystem: string;
  fullName: string;
}

export const buildTableRowsData = (visits: Array<UserSessionDto>, t: (path: string) => string): VisitsRow[] => {
  return visits.map((visit) => {
    const startDuration = new Date(visit.startedAt);
    const endDate = new Date(visit.lastUpdatedAt).getTime() + tokenLifetime;
    const getDuration = (start: Date, end: number | Date) => {
      const sessionTime = intervalToDuration({
        start,
        end,
      });
      return `${sessionTime.hours} ${t('session_hours')} ${sessionTime.minutes} ${t('session_minutes')}`;
    };
    const duration = () => {
      if (new Date(visit.lastUpdatedAt).getTime() < new Date().getTime() - tokenLifetime) {
        if (visit.finishedAt) {
          return getDuration(startDuration, new Date(visit.finishedAt));
        }
        return getDuration(startDuration, endDate);
      }
      return t('current_session');
    };
    return {
      date: `${format(new Date(visit.startedAt), FULL_DATE_TIME_FORMAT)}`,
      duration: duration(),
      browser: visit.browser,
      operationSystem: visit.os,
      ip: visit.ip,
      country: visit.location,
      fullName: visit.fullName || '',
    };
  });
};

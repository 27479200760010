import { GetLoanLinesListRequestDto } from '@app/core/api';
import { LoansLinesFilterFormData } from '@app/pages/loansLines/types';

export const makeDataFromSubmit = (
  formData: LoansLinesFilterFormData
): Omit<GetLoanLinesListRequestDto, 'sort' | 'pagination'> => {
  const {
    agreementStartDateFrom,
    agreementStartDateTo,
    agreementEndDateFrom,
    agreementEndDateTo,
    agreementNumber,
    limitFrom,
    limitTo,
    usedLimitFrom,
    usedLimitTo,
    currency,
    interestRateFrom,
    interestRateTo,
  } = formData;

  const agreementStartDate =
    agreementStartDateFrom || agreementStartDateTo
      ? { startDate: agreementStartDateFrom?.toISOString(), endDate: agreementStartDateTo?.toISOString() }
      : undefined;

  const agreementEndDate =
    agreementEndDateFrom || agreementEndDateTo
      ? { startDate: agreementEndDateFrom?.toISOString(), endDate: agreementEndDateTo?.toISOString() }
      : undefined;

  const interestRate =
    interestRateFrom?.length && interestRateTo?.length
      ? { from: parseFloat(interestRateFrom), to: parseFloat(interestRateTo) }
      : undefined;
  const limit =
    limitFrom?.length && limitTo?.length ? { from: parseFloat(limitFrom), to: parseFloat(limitTo) } : undefined;
  const usedLimit =
    usedLimitFrom?.length && usedLimitTo?.length
      ? { from: parseFloat(usedLimitFrom), to: parseFloat(usedLimitTo) }
      : undefined;

  return {
    agreementStartDate,
    agreementEndDate,
    interestRate,
    currency: currency?.length ? currency : undefined,
    limit,
    usedLimit,
    agreementNumber: agreementNumber?.length ? agreementNumber : undefined,
  };
};

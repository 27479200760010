import React, { useEffect, useState } from 'react';

import { Box, Chip, FormControl, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccountStatusEnum } from '@app/core/api';

import { AccountsSelectContent } from './components/AccountsSelectContent';
import { useStyles } from './styles';
import { AccountSelectType } from '../../types';
import { IBanText } from '../IBanText';
import { Select, SelectProps } from '../Select';
import { StatusText } from '../StatusText';

export interface AccountsSelectProps {
  name: string;
  value: AccountSelectType[];
  options: AccountSelectType[];
  errorText?: string;
  selectProps?: SelectProps;
  onChange(newValue: AccountSelectType[]): void;
}

export const AccountsWithSearchSelect: React.FC<AccountsSelectProps> = ({
  name,
  value,
  options,
  errorText,
  selectProps,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [allActiveSelected, setAllActiveSelected] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { getValues } = useFormContext();
  const selectedAccountLength = getValues(name).length;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const accountsSelectHandler = (selectedAccounts: AccountSelectType[]) => {
    onChange(selectedAccounts);
  };

  const resetHandler = () => {
    onChange([]);
    setAllActiveSelected(false);
  };

  const selectActiveAllHandler = (event: React.ChangeEvent<{}>, checked: boolean) => {
    if (checked) {
      const allActiveAccounts: AccountSelectType[] = options.filter(
        (account) => account.account.status === AccountStatusEnum.Active
      );

      onChange(allActiveAccounts);
      setAllActiveSelected(true);
    } else {
      onChange([]);
      setAllActiveSelected(false);
    }
  };

  useEffect(() => {
    if (selectedAccountLength === 0) {
      setAllActiveSelected(false);
    }
  }, [name, selectedAccountLength]);

  const hasError = Boolean(errorText);

  const styles = useStyles(theme);

  return (
    <>
      <FormControl fullWidth error={hasError}>
        <Select
          displayEmpty
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          fullWidth
          value={value}
          disabled={!options.length}
          MenuProps={{ keepMounted: true, disablePortal: true }}
          renderValue={(selectedAccounts: any) => {
            if (!selectedAccounts || selectedAccounts.length === 0) {
              return (
                <Typography variant="body2" sx={styles.selectPlaceholder}>
                  {t('accountsSelect_select_placeholder')}
                </Typography>
              );
            }
            return (
              <Box sx={styles.chipContainer}>
                <Box sx={styles.scrollContainer}>
                  {selectedAccounts?.length === 1 ? (
                    <Chip
                      key={selectedAccounts[0].account.id}
                      label={
                        <IBanText
                          styles={{ accountNumberPart1: styles.chipText, accountNumberPart2: styles.chipText }}
                          text={selectedAccounts[0].account.accountNumber}
                        />
                      }
                      size="small"
                      sx={styles.chip}
                    />
                  ) : (
                    selectedAccounts?.map((account: AccountSelectType) => {
                      return (
                        <Chip
                          key={account.account.id}
                          label={`...${account.account.accountNumber.slice(15)}`}
                          size="small"
                          sx={{
                            ...styles.chip,
                            ...styles.accentSubAccountNumber,
                          }}
                        />
                      );
                    })
                  )}
                </Box>
              </Box>
            );
          }}
          {...selectProps}
        >
          {options.length ? (
            <AccountsSelectContent
              name={name}
              options={options}
              selectedValues={value}
              onChange={accountsSelectHandler}
              onResetHandler={resetHandler}
              onSelectActiveAll={selectActiveAllHandler}
              allActiveSelected={allActiveSelected}
            />
          ) : null}
        </Select>
        {hasError ? <StatusText sx={styles.errorText}>{errorText}</StatusText> : null}
      </FormControl>
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon, ErrorSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { TableColumnConfig } from '@app/core/types';

import { styles } from './styles';
import { UserStatementsItem } from './UserStatementsItem';
import { onDragEndHandler } from '../../../../../../helpers/dndHelpers';
import { configTableComparator } from '../../../../../ComplexTable';
import { ModalPopupCommon } from '../../../../../Modal';
import { StrictModeDroppable } from '../../../../../StrictModeDroppable';
import { ConfirmTableConfigPopup } from '../ConfirmTableConfigPopup';

export interface StatementsTablePreferenceProps {
  columnsConfig: TableColumnConfig[];
  columnsConfigMutationFn(userStatementsTableColumnsData: TableColumnConfig[]): Promise<TableColumnConfig[]>;
  onCloseClick(): void;
  onSuccessConfigurationSaved(tableColumnsConfig: TableColumnConfig[]): void;
  onErrorConfigurationSaved(): void;
}

export const StatementsTablePreference: React.FC<StatementsTablePreferenceProps> = ({
  columnsConfig,
  columnsConfigMutationFn,
  onCloseClick,
  onSuccessConfigurationSaved,
}) => {
  const [edited, setEdited] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const { notify } = useNotify();
  const { mutate } = useMutation<TableColumnConfig[], unknown, TableColumnConfig[]>(columnsConfigMutationFn);
  const [userTableConfig, setUserTableConfig] = useState<TableColumnConfig[]>([]);
  const [enabledItemsError, setEnabledItemsCount] = useState<boolean>(false);

  useEffect(() => {
    setUserTableConfig([...columnsConfig].sort(configTableComparator));
  }, [columnsConfig]);

  const { t } = useTranslation();

  const updateSelectedRows = (order: number, enabled: boolean) => {
    setEdited(true);
    const statementsTableColumnArray = [...userTableConfig];
    statementsTableColumnArray.splice(order, 1);
    setUserTableConfig(
      [
        ...statementsTableColumnArray,
        {
          ...userTableConfig[order],
          enabled,
        },
      ].sort(configTableComparator)
    );
  };

  useEffect(() => {
    const enabledItems = userTableConfig.filter((item) => item.enabled);
    if (userTableConfig.length) {
      if (enabledItems.length < 5) {
        setEnabledItemsCount(true);
      } else {
        setEnabledItemsCount(false);
      }
    }
  }, [userTableConfig.length, userTableConfig]);

  const onDragEnd = (result: DropResult) => {
    setEdited(true);
    onDragEndHandler<TableColumnConfig>(result, userTableConfig, (newState) => {
      const newConfig = newState.map((item, index) => ({
        ...item,
        order: index,
      }));
      setUserTableConfig(newConfig);
    });
  };

  const onSubmit = () => {
    mutate(userTableConfig, {
      onSuccess: (newConfig) => {
        onSuccessConfigurationSaved(newConfig);
      },
      onError: () => {
        notify({
          notifyProps: {
            title: t('server_error'),
            message: t('statements_config_save_error'),
            severity: 'error',
          },
        });
      },
    });
  };

  const onSuccessConfirmPopUp = () => {
    onCloseClick();
    setShowConfirmPopup(false);
    setEdited(false);
  };

  const closeConfirmPopup = () => {
    setShowConfirmPopup(false);
  };

  const onClose = () => {
    if (edited) {
      setShowConfirmPopup(true);
    } else onCloseClick();
  };

  return (
    <ModalPopupCommon open>
      <>
        <Box pb={3}>
          <Grid justifyContent="space-between" alignItems="center" container>
            <Typography component="span" variant="h6" sx={styles.caption}>
              {t('settings')}
            </Typography>
            <IconButton size="small" onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </Grid>
          {enabledItemsError ? (
            <Grid container alignItems="center" sx={styles.errorNotifyContainer}>
              <ErrorSmallIcon fontSize="small" />
              <Typography variant="caption" sx={styles.errorNotifyText}>
                {t('minSelectedEnabledColumnsError')}
              </Typography>
            </Grid>
          ) : null}
        </Box>
        {userTableConfig ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef} sx={styles.wrapHeight}>
                  {userTableConfig.map((tableColumn, index) => {
                    return (
                      <Draggable draggableId={tableColumn.columnType} key={tableColumn.columnType} index={index}>
                        {(providedDraggable, snapshotDraggable) => (
                          <div {...providedDraggable.draggableProps} ref={providedDraggable.innerRef}>
                            <UserStatementsItem
                              name={t(`statementsTable_${tableColumn.columnType}`)}
                              key={tableColumn.columnType}
                              index={index}
                              onChangeEnabled={updateSelectedRows}
                              providedHandleProps={providedDraggable.dragHandleProps!}
                              containerStyle={{ ...(snapshotDraggable.isDragging && styles.isDragging) }}
                              {...tableColumn}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        ) : null}
        <Grid justifyContent="flex-end" container>
          <Button
            variant="contained"
            size="small"
            color="primary"
            sx={styles.sendButton}
            onClick={onSubmit}
            disabled={enabledItemsError}
          >
            {t('save')}
          </Button>
        </Grid>
        {showConfirmPopup ? (
          <ConfirmTableConfigPopup onClose={closeConfirmPopup} onSubmit={onSuccessConfirmPopUp} />
        ) : null}
      </>
    </ModalPopupCommon>
  );
};

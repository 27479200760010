import React, { useEffect, useState } from 'react';

import { Box, Button, Collapse, FormControl, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  DownChevronSmallIcon,
  ErrorSmallIcon,
  NotifySmallIcon,
  PaperIcon,
  SecondSignature,
  UpChevronSmallIcon,
  WaitingSmallIcon,
} from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  CreateDocumentInfo,
  CurrencyOperationStatusEnum,
  FrontLanguageEnum,
  GetDocumentResponseDto,
  OperationHistoryItemDto,
  SignatureStatusDto,
  UserProfileDto,
} from '@app/core/api';
import { useSendRemindCurrencyOperationSign } from '@app/core/hooks/useSendRemindCurrencyOperationSign';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';
import { DocumentInfo } from '@app/pages/exchange/components/form/components/InfoBlock/DocumentInfo';
import {
  getActionTranslationKeyByActionTypeEnum,
  getCurrentPersonInfoStatusIcon,
} from '@app/pages/exchange/components/form/components/InfoBlock/helpers';
import { styles } from '@app/pages/exchange/components/form/components/InfoBlock/style';

type InfoBlockProps = {
  status?: CurrencyOperationStatusEnum;
  documents?: Array<GetDocumentResponseDto>;
  createDocumentInfo?: CreateDocumentInfo;
  userHistory?: Array<OperationHistoryItemDto>;
  personToSign: Array<SignatureStatusDto>;
  showLinkedBlock?: boolean;
  detachFileEnabled?: boolean;
  signDocumentsButtonEnabled: boolean;
  onSignDocumentsClick(): void;
  onDeleteFile?(document: GetDocumentResponseDto): void;
  userProfile: UserProfileDto;
  uuid: string;
  edit: boolean;
};

export const InfoBlock: React.FC<InfoBlockProps> = ({
  status,
  documents,
  createDocumentInfo,
  userHistory,
  showLinkedBlock = false,
  detachFileEnabled = true,
  signDocumentsButtonEnabled,
  personToSign,
  onSignDocumentsClick,
  onDeleteFile,
  userProfile,
  uuid,
  edit,
}): JSX.Element => {
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [showAttorney, setShowAttorney] = useState<number>();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [linkedDocumentsOpen, setLinkedDocumentsOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { mutate } = useSendRemindCurrencyOperationSign();
  const { notify } = useNotify();

  const onClickHistoryHandler = () => {
    setHistoryOpen(!historyOpen);
  };

  const onClickLinkedDocuments = () => {
    setLinkedDocumentsOpen(!linkedDocumentsOpen);
  };

  useEffect(() => {
    setDeleteMode(false);
  }, [documents]);

  const detachClickHandler = () => {
    setDeleteMode(true);
  };

  const cancelDetachHandler = () => {
    setDeleteMode(false);
  };

  const openAttorney = (id: number) => {
    setShowAttorney(id);
  };

  const closeAttorney = () => {
    setShowAttorney(undefined);
  };

  const getSignAuthorityText = (authority: number) => {
    if (authority === 1) {
      return t('firstSign');
    }
    if (authority === 2) {
      return t('secondSign');
    }
  };

  const onNotifyButtonClick = () => {
    mutate(
      { uuid, language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          notify({
            notifyProps: {
              title: t('success'),
              message: t('remindSuccessMessage'),
              severity: 'success',
            },
          });
        },
        onError: () => {
          notify({
            notifyProps: {
              title: t('error'),
              message: t('remindErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };

  return (
    <Box width="100%">
      <Box>
        <Typography variant="body1" sx={styles.title}>
          {t('currencySaleInfo_currentStatus_title')}
        </Typography>
        <Box mt={2} mb={2}>
          <Grid container alignItems="center">
            {status ? (
              <>
                {status === CurrencyOperationStatusEnum.Rejected ? (
                  <ErrorSmallIcon fontSize="small" sx={styles.errorIcon} />
                ) : (
                  <WaitingSmallIcon fontSize="small" />
                )}
              </>
            ) : (
              <PaperIcon fontSize="small" sx={styles.statusIcon} />
            )}
            <Box ml={2}>
              <Typography variant="body2" component="span" sx={styles.value}>
                {status ? t(status) : t('documentStatus_new')}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box sx={styles.hr} mb={4} mt={4} />

      <Box mt={6} mb={4}>
        <Typography variant="body1" sx={styles.title}>
          {t('currencySaleInfo_toSignList_title')}
        </Typography>
        <Box mt={2}>
          <Grid container alignItems="center">
            {personToSign.map(({ main, attorney, status: personInfoStatus, signingAuthority }, idx) => {
              return (
                <Grid key={idx} sx={styles.signerContainer}>
                  <Grid container alignItems="center" justifyContent="space-between" sx={styles.signerContainer}>
                    <Grid item xs={11} container alignItems="center">
                      {getCurrentPersonInfoStatusIcon(personInfoStatus)}
                      <Box component="span" ml={2}>
                        {attorney?.lastName === userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                        <Typography variant="body2" component="span" sx={styles.value}>
                          {attorney?.lastName === userProfile.lastName
                            ? `${attorney.lastName} ${attorney.firstName}`
                            : `${main?.lastName} ${main?.firstName}`}
                        </Typography>
                      </Box>
                    </Grid>
                    {main && attorney ? (
                      <Grid item xs={1} sx={styles.toggleButton}>
                        {showAttorney === idx ? (
                          <UpChevronSmallIcon fontSize="small" onClick={closeAttorney} />
                        ) : (
                          <DownChevronSmallIcon
                            fontSize="small"
                            onClick={() => {
                              openAttorney(idx);
                            }}
                          />
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Box ml={6}>
                    <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                      {getSignAuthorityText(signingAuthority)}
                    </Typography>
                  </Box>
                  {showAttorney === idx ? (
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection="column"
                      sx={styles.attorneyContainer}
                    >
                      <Grid item container alignItems="center" justifyContent="flex-start">
                        <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                          {t('attorneyTitle')}
                        </Typography>
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="flex-start">
                        {attorney?.lastName !== userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                        <Box>
                          <Typography variant="body2" component="span" sx={styles.value}>
                            {attorney?.lastName === userProfile.lastName
                              ? `${main?.lastName} ${main?.firstName}`
                              : `${attorney?.lastName} ${attorney?.firstName}`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {status === CurrencyOperationStatusEnum.OnSigning && !edit ? (
        <>
          <Box mt={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={onNotifyButtonClick}
                endIcon={<NotifySmallIcon fontSize="small" />}
              >
                {t('paymentInfo_alarmNotifyButton')}
              </Button>
            </FormControl>
          </Box>
        </>
      ) : null}
      {createDocumentInfo ? (
        <Box pt={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={!historyOpen ? 'close' : ''}
            sx={styles.headerContainer}
            onClick={onClickHistoryHandler}
          >
            <Typography variant="body2" component="span" sx={styles.collapseTitle}>
              {t('currencySaleInfo_historyTitle')}
            </Typography>
            {historyOpen ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
          </Grid>
          <Collapse in={historyOpen} unmountOnExit={false}>
            <Box sx={styles.collapseBodyContainer} p={4}>
              <Box>
                <Typography variant="caption" sx={styles.collapseItemTitle}>
                  {t('paymentInfo_documentCreated_title')}
                </Typography>
                <Box mt={2}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" sx={styles.collapseItemName}>
                      {t('paymentInfo_documentCreated_date')}
                    </Typography>
                    <Typography variant="caption" sx={styles.collapseItemValue}>
                      {format(new Date(createDocumentInfo.createDocumentDate), FULL_DATE_TIME_FORMAT)}
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" sx={styles.collapseItemName}>
                      {t('paymentInfo_documentCreated_creator')}
                    </Typography>
                    <Typography variant="caption" sx={styles.collapseItemValue}>
                      {`${createDocumentInfo.lastName} ${createDocumentInfo.firstName}`}
                    </Typography>
                  </Grid>
                </Box>
              </Box>

              {userHistory && userHistory.length ? (
                <Box mt={4}>
                  <Typography variant="caption" sx={styles.collapseItemTitle}>
                    {t('paymentInfo_documentChanges_title')}
                  </Typography>
                  {userHistory.map((historyItem) => {
                    return (
                      <Box key={historyItem.id} mb={4}>
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('paymentInfo_documentCreated_date')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {format(new Date(historyItem.createdAt), FULL_DATE_TIME_FORMAT)}
                            </Typography>
                          </Grid>
                        </Box>
                        {historyItem.lastName || historyItem.firstName ? (
                          <Box mt={2}>
                            <Grid container justifyContent="space-between">
                              <Typography variant="caption" sx={styles.historyItemName}>
                                {t('paymentInfo_documentCreated_creator')}
                              </Typography>
                              <Typography variant="caption" sx={styles.historyItemValue}>
                                {historyItem.lastName || ''} {historyItem.firstName || ''}
                              </Typography>
                            </Grid>
                          </Box>
                        ) : null}
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('paymentInfo_documentCreated_changes')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {t(getActionTranslationKeyByActionTypeEnum(historyItem.actionType))}
                            </Typography>
                          </Grid>
                        </Box>
                        {historyItem?.signatureBelongsToUser ? (
                          <Box mt={2}>
                            <Grid container justifyContent="space-between">
                              <Typography variant="caption" sx={styles.historyItemName}>
                                {t('signature')}
                              </Typography>
                              <Typography variant="caption" sx={styles.historyItemValue}>
                                {historyItem?.signatureBelongsToUser.lastName || ''}{' '}
                                {historyItem?.signatureBelongsToUser.firstName || ''}
                              </Typography>
                            </Grid>
                          </Box>
                        ) : null}
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Collapse>
        </Box>
      ) : null}
      {showLinkedBlock ? (
        <Box pt={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={!linkedDocumentsOpen ? 'close' : ''}
            sx={styles.headerContainer}
            onClick={onClickLinkedDocuments}
          >
            <Typography variant="body2" component="span" sx={styles.collapseTitle}>
              {t('currencySaleInfo_LinkedDocumentsTitle')}
            </Typography>
            {linkedDocumentsOpen ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
          </Grid>
          <Collapse in={linkedDocumentsOpen} unmountOnExit={false}>
            <Box sx={styles.collapseBodyContainer} p={4} pt={8}>
              <Grid container direction="column" alignItems="center">
                {documents &&
                  documents.map((document, i) => (
                    <DocumentInfo
                      document={document}
                      key={`${document.originalName}_${i}`}
                      showDeleteButton={deleteMode}
                      onDeleteFile={onDeleteFile}
                    />
                  ))}

                <>
                  {deleteMode ? (
                    <Button
                      fullWidth
                      sx={styles.documentsBtn}
                      variant="outlined"
                      color="primary"
                      onClick={cancelDetachHandler}
                    >
                      {t('cancelDetachFileButtonLabel')}
                    </Button>
                  ) : (
                    <>
                      {signDocumentsButtonEnabled ? (
                        <Button
                          fullWidth
                          sx={styles.documentsBtn}
                          variant="outlined"
                          color="primary"
                          onClick={onSignDocumentsClick}
                          disabled={!documents || !documents.length}
                        >
                          {t('signAllAttachedDocuments')}
                        </Button>
                      ) : null}
                      {detachFileEnabled ? (
                        <Box mt={2}>
                          <Button variant="text" onClick={detachClickHandler} sx={styles.detachFileButton}>
                            {t('detachFileButtonLabel')}
                          </Button>
                        </Box>
                      ) : null}
                    </>
                  )}
                </>
              </Grid>
            </Box>
          </Collapse>
        </Box>
      ) : null}
    </Box>
  );
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, UserDto, swiftApiClient } from '@app/core/api';

export const useGetSwiftAnotherSigners = (uuid?: string): UseQueryResult<Array<UserDto>, ApiError> => {
  return useQuery<Array<UserDto>, ApiError>(
    'payment_useGetSwiftAnotherSigners',
    async () => {
      const result = await swiftApiClient.swiftSignControllerGetAnotherSigners(getClientId(), uuid);
      if (result.status !== 200) {
        throw new ApiError(result.request.status, "Can't get swift an another signers");
      }
      return result.data;
    },
    { enabled: false }
  );
};

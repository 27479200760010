import React from 'react';

import { useFormContext, Controller } from 'react-hook-form';

import { RadioGroup, RadioGroupProps } from '../components/RadioGroup';

export const RadioGroupField: React.FC<RadioGroupProps> = ({ name, value, ...otherProps }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field: { value: newValue, onChange } }) => (
          <RadioGroup
            name={name}
            onChange={(valueCurrent) => onChange(valueCurrent)}
            value={newValue}
            {...otherProps}
          />
        )}
      />
    </>
  );
};

import { useEffect, useState } from 'react';

import { ColumnSizingState } from '@tanstack/react-table';
import Cookies from 'js-cookie';

import { parseJSON } from '@app/core/utils';
import { serviceConfig } from '@app/src/config';

const TABLE_COOKIE_CONFIG_PREFIX = '_tbconf_';

type UseTableColumnSizingProps = {
  name?: string;
};

export const useTableColumnSizing = ({ name }: UseTableColumnSizingProps) => {
  const columnSizeConfigCookie = Cookies.get(`${TABLE_COOKIE_CONFIG_PREFIX}${name}`);
  const columnSizeConfig: ColumnSizingState = columnSizeConfigCookie ? parseJSON(columnSizeConfigCookie) : {};
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(columnSizeConfig);

  useEffect(() => {
    if (name && columnSizing) {
      Cookies.set(`${TABLE_COOKIE_CONFIG_PREFIX}${name}`, JSON.stringify(columnSizing), {
        sameSite: 'strict',
        secure: true,
        domain: serviceConfig.cookiesDomain,
      });
    }
  }, [columnSizing, name]);

  return {
    columnSizing,
    setColumnSizing,
  };
};

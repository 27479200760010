import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { MutationFunction } from 'react-query/types/core/types';

import { CrossIcon, DeleteIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { ApiError } from '@app/core/api';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

import { styles } from './style';
import { formatAmount } from '../../utils';
import { ModalCommon } from '../Modal';

export type DeleteDocumentType = {
  uuid: string;
  amount: number;
};

interface DeleteDocumentProps {
  selectedDocuments: DeleteDocumentType[];
  docsForSignCount: number;
  currency: string;
  mutationFn: MutationFunction<boolean, string[]>;
  onSubmit(): void;
  onCancelDeleteDocument(): void;
  onDeleteDocumentSuccess(): void;
}

export const DeleteDocumentDialog: React.FC<DeleteDocumentProps> = ({
  selectedDocuments,
  docsForSignCount,
  currency,
  mutationFn,
  onSubmit,
  onCancelDeleteDocument,
  onDeleteDocumentSuccess,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const uuids = selectedDocuments.map((document) => {
    return document.uuid;
  });

  const { showLoader } = useUIState();
  const { mutate } = useMutation<boolean, ApiError, string[]>({
    mutationFn,
    onSuccess: () => {
      onDeleteDocumentSuccess();
      showLoader(false);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('deleteDocumentSuccessMessage'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      showLoader(false);
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('deleteDocumentErrorMessage'),
          severity: 'error',
        },
      });
    },
  });

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate(uuids);
    onSubmit();
  };
  const amount = selectedDocuments.reduce((acc, document) => {
    return acc + document.amount;
  }, 0);

  return (
    <ModalCommon onClose={onCancelDeleteDocument} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDeleteDocument}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={7}>
          <Typography variant="body1" sx={styles.title}>
            {t('deleteDocumentTitle')}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('deleteDocumentCount')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {selectedDocuments.length}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('deleteDocument_documentsToSign')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {docsForSignCount}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" sx={styles.text}>
            {t('deleteDocumentAmount')}{' '}
            <Typography variant="body2" component="span" sx={styles.value}>
              {formatAmount(amount)}
            </Typography>{' '}
            {currency}
          </Typography>
        </Box>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDeleteDocument}>
              {t('cancel')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmDeleteDocument}>
            {t('delete')}
            <DeleteIcon sx={styles.deleteIcon} />
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { authApiClient, getClientId, ApiError, Enable2faResponseDto, FrontLanguageEnum, TYPE2FA } from '@app/core/api';

interface UserEnable2faFnProps {
  language: FrontLanguageEnum;
}

const enable2faFn = async ({ language }: UserEnable2faFnProps): Promise<Enable2faResponseDto> => {
  const response = await authApiClient.authControllerEnable2faSendSecret(getClientId(), {
    params: { type2FA: TYPE2FA.Totp, language },
  });

  if (response.status !== 201) {
    throw new ApiError(response.request.status, 'Cant enable GA');
  }

  return response.data;
};

export const useEnable2fa = (): UseMutationResult<Enable2faResponseDto, ApiError, UserEnable2faFnProps> => {
  return useMutation<Enable2faResponseDto, ApiError, UserEnable2faFnProps>(enable2faFn);
};

export const styles = {
  captionSaveModal: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '12px',
  },
  buttonSaveModal: {
    marginLeft: '12px',
    marginRight: '12px',
    transition: 'none',
  },
};

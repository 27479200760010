import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '@app/common/icons';
import { isMobile } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { DirectoriesMenuFilter } from './types';

export interface DirectoriesMenuProps {
  filter: DirectoriesMenuFilter;
}

type DirectoriesLinksProps = {
  text: string;
  alias: string | RouteList;
};
const getBankingLinks = (enableSWIFT: boolean) => {
  return enableSWIFT
    ? [
        {
          text: 'directories_ua_bank',
          alias: RouteList.directories_banks_internal,
        },
        {
          text: 'directories_swift_bank',
          alias: RouteList.directories_banks_swift,
        },
        {
          text: 'directories_country_codes',
          alias: RouteList.directories_codes_countries,
        },
        {
          text: 'directories_exchange_rates',
          alias: RouteList.exchange_rate,
        },
      ]
    : [
        {
          text: 'directories_ua_bank',
          alias: RouteList.directories_banks_internal,
        },
        {
          text: 'directories_country_codes',
          alias: RouteList.directories_codes_countries,
        },
        {
          text: 'directories_exchange_rates',
          alias: RouteList.exchange_rate,
        },
      ];
};

const getOwnLinks = (enableSWIFT: boolean): DirectoriesLinksProps[] => {
  return enableSWIFT
    ? [
        {
          text: 'directories_counterparties',
          alias: RouteList.directories_counterparties,
        },
        {
          text: 'directories_counterparties_swift',
          alias: RouteList.directories_counterparties_swift,
        },
        {
          text: 'directories_templates_payments_uah',
          alias: RouteList.directories_payments_templates,
        },
      ]
    : [
        {
          text: 'directories_counterparties',
          alias: RouteList.directories_counterparties,
        },
        {
          text: 'directories_templates_payments_uah',
          alias: RouteList.directories_payments_templates,
        },
      ];
};

export const DirectoriesMenu: React.FC<DirectoriesMenuProps> = ({ filter }): JSX.Element => {
  const { clientConfig } = useAppSelector((state): ProfileState => state.profile);
  const enableSWIFT = Boolean(clientConfig?.features.swift);
  const [data, setData] = useState<DirectoriesLinksProps[]>(getBankingLinks(Boolean(enableSWIFT)));
  const [hover, setHover] = useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    switch (filter) {
      case DirectoriesMenuFilter.Own:
        setData(getOwnLinks(enableSWIFT));
        break;
      default:
        setData(getBankingLinks(enableSWIFT));
        break;
    }
  }, [enableSWIFT, filter]);

  const handleMouseEnter = (index: number) => {
    setHover(true);
    setHoverIndex(index);
  };
  const handleMouseLeave = () => {
    setHover(false);
    setHoverIndex(undefined);
  };

  const { t } = useTranslation();

  return (
    <Box mt={7}>
      <Grid container spacing={5}>
        {data
          ? data.map((link, index) => (
              <Grid item xs={isMobile ? 12 : 4} key={link.alias}>
                <Link
                  to={link.alias}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    boxShadow: '0px 2px 7px rgba(134, 142, 150, 0.1), 0px 7px 20px rgba(222, 226, 230, 0.3)',
                    borderRadius: '8px',
                    padding: `12px 20px`,
                    color: '#0066c2',
                    fontWeight: 600,
                    backgroundColor: hover && hoverIndex === index ? '#f3f9ff' : 'transparent',
                  }}
                >
                  <Grid container item alignItems="center" justifyContent="space-between">
                    {t(link.text)}
                    <ArrowRightIcon />
                  </Grid>
                </Link>
              </Grid>
            ))
          : null}
      </Grid>
    </Box>
  );
};

import { DocumentStatusEnum, GetDocumentWithAvailableActionsDto, UserResourceDto } from '@app/core/api';

export type ActiveButtons = {
  sign: boolean;
  sendToSign: boolean;
  view: boolean;
  delete: boolean;
  edit: boolean;
};

export const activeButtons = (
  selectedOperations: GetDocumentWithAvailableActionsDto[],
  permissions: UserResourceDto
): ActiveButtons => {
  const docsForSign = selectedOperations.filter((item) => item.actions.allowToSign);
  const single: boolean = selectedOperations.length === 1;
  const signEnabled: boolean = docsForSign.length >= 1 && docsForSign.length <= 10;
  const showSignButton: boolean =
    signEnabled &&
    selectedOperations.filter(
      (item) => item.status === DocumentStatusEnum.ToSign || item.status === DocumentStatusEnum.Saved
    ).length >= 1;

  const activeDelete: boolean =
    permissions.documents.delete &&
    selectedOperations.filter(
      (item) =>
        item.status === DocumentStatusEnum.ToSign ||
        item.status === DocumentStatusEnum.Saved ||
        item.status === DocumentStatusEnum.OnSigning
    ).length >= 1;

  const edit =
    permissions.documents.edit &&
    single &&
    (selectedOperations[0].status === DocumentStatusEnum.Saved ||
      selectedOperations[0].status === DocumentStatusEnum.ToSign ||
      selectedOperations[0].status === DocumentStatusEnum.OnSigning ||
      selectedOperations[0].status === DocumentStatusEnum.Rejected);

  const sendToSign: boolean = selectedOperations.filter((item) => item.actions?.allowToSendToSign).length >= 1;

  return {
    sign: showSignButton,
    sendToSign,
    view: permissions.documents.view && single,
    delete: activeDelete,
    edit,
  };
};

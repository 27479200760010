import React, { useMemo, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  getCoreRowModel,
  getExpandedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
  getPaginationRowModel,
  PaginationState,
  getSortedRowModel,
  RowSelectionState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ImportOrderWithValidationDto } from '@app/core/api';
import { ConfirmPopup } from '@app/core/components';
import { ComplexTable, LocalTablePagination } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from '@app/core/constants';
import { CurrencyEnum } from '@app/core/types';
import { makeImportTableColumns } from '@app/pages/payment/import/components/columns';
import { buildImportTableRowsData } from '@app/pages/payment/import/components/helpers';
import { ImportTableToolbar } from '@app/pages/payment/import/components/ImportTableToolbar';
import { ImportOrderDetails } from '@app/pages/payment/import/components/OperationDetails';
import { styles } from '@app/pages/payment/import/components/styles';
import { Order, OrderFieldEnum, OrderRow } from '@app/pages/payment/import/types';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

const ExtendRowContent: React.FC<{ data: OrderRow }> = ({ data }) => <ImportOrderDetails order={data} />;

type ImportResultTableProps = {
  count: number;
  amount: number;
  currency: CurrencyEnum;
  orders: ImportOrderWithValidationDto[];
  onDeleteDocuments(orders: ImportOrderWithValidationDto[]): void;
};

export const ImportResultTable: React.FC<ImportResultTableProps> = ({
  count,
  amount,
  currency,
  orders,
  onDeleteDocuments,
}) => {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    [OrderFieldEnum.paymentDocumentNumber]: false,
    [OrderFieldEnum.senderLei]: false,
    [OrderFieldEnum.senderBank]: false,
    [OrderFieldEnum.senderBankCode]: false,
    [OrderFieldEnum.recipientLei]: false,
    [OrderFieldEnum.recipientBank]: false,
    [OrderFieldEnum.recipientBankCode]: false,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const data = useMemo(() => {
    return buildImportTableRowsData(orders);
  }, [orders]);

  const [showConfirmDeleteOrdersPopup, setShowConfirmDeleteOrdersPopup] = useState<boolean>(false);
  const columns = useMemo(() => {
    return [
      ...makeImportTableColumns({
        t,
        onSettingsButtonClick() {},
      }),
    ];
  }, [t]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ROWS_PER_PAGE,
  });
  const table = useReactTable<OrderRow>({
    columns,
    data,
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      pagination,
    },
    onRowSelectionChange: setRowSelection,
    enableSorting: data.length > 0,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: setPagination,
  });

  const selectedOrders: Order[] = table.getSelectedRowModel().flatRows.map((orderRow) => ({
    data: { ...orderRow.original },
  }));

  const onDeleteDocumentsHandler = () => {
    setShowConfirmDeleteOrdersPopup(true);
  };

  const buildBodyContent = (): React.ReactNode => {
    if (!orders.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<OrderRow> key={row.id} row={row} styles={styles} ExpandedComponent={ExtendRowContent} />;
    });
  };

  const onConfirmDeleteDocument = () => {
    setShowConfirmDeleteOrdersPopup(false);
    onDeleteDocuments([...selectedOrders]);
    setRowSelection({});
  };
  const onCancelDeleteHandler = () => {
    setShowConfirmDeleteOrdersPopup(false);
  };

  return (
    <>
      <ImportTableToolbar
        selectedOrders={selectedOrders}
        count={count}
        amount={amount}
        currency={currency}
        onDeleteDocuments={onDeleteDocumentsHandler}
      />
      <ComplexTable
        head={{ content: <TableHeadContent<OrderRow> {...{ table, styles }} /> }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <LocalTablePagination<OrderRow>
              table={table}
              defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
            />
          ),
        }}
      />

      <ConfirmPopup
        open={showConfirmDeleteOrdersPopup}
        onConfirm={onConfirmDeleteDocument}
        onClose={onCancelDeleteHandler}
        questionText={t('confirmDeletePaymentImportPopupMessage')}
        icon={<DeleteIcoBgSVG />}
        classes={{
          questionTextContainer: {
            fontWeight: 600,
            color: 'colors.gray500',
          },
        }}
      />
    </>
  );
};

import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { CopyIcon, SuccessSmallSvgIcon } from '@app/common/icons';
import { DepositDto } from '@app/core/api';
import { IBanText } from '@app/core/components/IBanText';
import { styles } from '@app/pages/deposits/show/components/DepositInfo/style';
import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/chevron-down.svg';

interface DepositInfoProps {
  deposit: DepositDto;
}

export const DepositInfo: React.FC<DepositInfoProps> = ({ deposit }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const onCopy = (value: string) => {
    copy(value);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1500);
  };

  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2" sx={styles.info} onClick={handleClick}>
        <Box component="span">{t('linkedAccounts')}</Box>
        <ChevronDownIconSvg />
      </Typography>
      <Popover
        onClick={handlePopoverClick}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPopover-paper': {
            background: 'none',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box component="div" m={2}>
          <Grid container alignItems="center" sx={styles.tooltipContent}>
            {copyAlert ? (
              <Grid container alignItems="center" sx={styles.alertCopy}>
                <SuccessSmallSvgIcon fontSize="small" />
                <Typography variant="caption" sx={styles.alertCopyText}>
                  {t('copy_account')}
                </Typography>
              </Grid>
            ) : null}
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('deposit')}
                </Typography>
              </Grid>
              <Grid item sx={styles.rowRight}>
                <Grid container>
                  <IBanText text={deposit.accountDepositOutstanding} />
                  <CopyIcon
                    sx={{ ...styles.buttonPopoverSvgIcon, ...styles.buttonPopoverSvgIconFill }}
                    onClick={() => {
                      onCopy(deposit.accountDepositOutstanding);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('interestAccrued')}
                </Typography>
              </Grid>
              <Grid item sx={styles.rowRight}>
                <Grid container>
                  <IBanText text={deposit.accountInterestAccrued} />
                  <CopyIcon
                    sx={{ ...styles.buttonPopoverSvgIcon, ...styles.buttonPopoverSvgIconFill }}
                    onClick={() => {
                      onCopy(deposit.accountInterestAccrued);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="flex-start" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
              <Grid item>
                <Typography variant="body2" component="span">
                  {t('correspondentAccount')}
                </Typography>
              </Grid>
              <Grid item sx={styles.rowRight}>
                <Grid container>
                  <IBanText text={deposit.returnAccountNumber} />
                  <CopyIcon
                    sx={{ ...styles.buttonPopoverSvgIcon, ...styles.buttonPopoverSvgIconFill }}
                    onClick={() => {
                      onCopy(deposit.returnAccountNumber);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

import { LoansTableColumns } from '@app/pages/loans/types';

export const fieldsCreditTable = [
  {
    columnType: LoansTableColumns.agreementStartDate,
    enabled: true,
    order: 0,
    edit: false,
  },
  {
    columnType: LoansTableColumns.agreementEndDate,
    enabled: true,
    order: 1,
    edit: false,
  },
  {
    columnType: LoansTableColumns.agreementNumber,
    enabled: true,
    order: 3,
    edit: true,
  },
  // {
  //   columnType: LoansTableColumns.loanName,
  //   enabled: true,
  //   order: 4,
  //   edit: false,
  // },
  {
    columnType: LoansTableColumns.amount,
    enabled: true,
    order: 5,
    edit: true,
  },
  {
    columnType: LoansTableColumns.currency,
    enabled: true,
    order: 6,
    edit: true,
  },
  {
    columnType: LoansTableColumns.interestRate,
    enabled: true,
    order: 7,
    edit: true,
  },
  {
    columnType: LoansTableColumns.productType,
    enabled: false,
    order: 10,
    edit: false,
  },
];

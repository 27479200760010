import React from 'react';

import { Typography, Box, IconButton, Grid, SxProps, Theme } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EditIcon, StarFilledIcon, StarIcon } from '@app/common/icons';
import { AccountDirectionTypeEnum } from '@app/core/api';
import { isMobile, formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/accounts/components/AccountsList/primitives/styles';
import { RouteList } from '@app/src/constants/routeList';
import { ReactComponent as FollowSvg } from '@app/themes/default/assets/icons/move.svg';

export type FavoriteIconProps = {
  status?: boolean;
  disable?: boolean;
};

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({ status, disable }) => {
  const favoriteIconPath: SxProps<Theme> = {
    width: '20px',
    height: '20px',
    marginRight: '16px',
    marginTop: 'auto',
    marginBottom: 'auto',
    cursor: 'pointer',
    color: () => {
      if (disable) {
        return 'colors.gray100';
      }
      if (status) {
        return 'colors.warning';
      }
      return 'colors.gray300';
    },
  };
  if (status) {
    return <StarFilledIcon sx={favoriteIconPath} />;
  }
  return <StarIcon sx={favoriteIconPath} />;
};

export type CounterPartyNameProps = {
  text: string | number | React.ReactNode;
};

export const CounterPartyName: React.FC<CounterPartyNameProps> = ({ text }) => {
  return (
    <Typography variant="h6" sx={styles.counterPartyName}>
      {text}
    </Typography>
  );
};

export type AccountNameItemProps = {
  text: string | number | React.ReactNode;
  editIcon?: boolean;
  onClick?(event: React.SyntheticEvent): void;
};

export const AccountNameItem: React.FC<AccountNameItemProps> = ({ text, editIcon, onClick }) => {
  return (
    <Typography variant="h6" sx={styles.accountName}>
      <Box component="span" mr={1}>
        <b>{text}</b>
      </Box>
      {editIcon ? (
        <IconButton aria-label="edit" size="small" onClick={onClick}>
          <EditIcon sx={styles.editIcon} />
        </IconButton>
      ) : null}
    </Typography>
  );
};

export type AmountItemProps = {
  type: string;
  amount: string;
  currency: string;
};

export const AmountItem: React.FC<AmountItemProps> = ({ type, amount, currency }) => {
  return (
    <Typography
      variant="body1"
      sx={type === AccountDirectionTypeEnum.Deposit ? styles.amountDeposit : styles.amountWithdrawal}
    >
      {type === AccountDirectionTypeEnum.Deposit ? '+' : '-'}
      {amount}{' '}
      <Typography variant="body2" component="span">
        {currency}
      </Typography>
    </Typography>
  );
};

export type DetailsInfoItemProps = {
  description: string;
  value?: string;
  currency?: string;
  type?: string;
  balance?: boolean;
};
export const DetailsInfoItem: React.FC<DetailsInfoItemProps> = ({ description, value, currency, type }) => {
  const date = currency && type ? null : value && format(new Date(value.split('T')[0]), 'dd.MM.yyyy');

  return (
    <>
      {isMobile ? (
        <Box mt={1}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" component="p" sx={styles.detailsInfoDescription}>
              {description}
            </Typography>
            {currency && type && value ? (
              <AmountItem type={type} currency={currency} amount={value.toString()} />
            ) : (
              <Typography variant="body1" component="div" sx={styles.detailsInfoValue}>
                {date}
              </Typography>
            )}
          </Grid>
        </Box>
      ) : (
        <Box mt={1}>
          <Typography variant="body2" component="p" sx={styles.detailsInfoDescription}>
            {description}
          </Typography>
          {currency && type && value ? (
            <AmountItem type={type} currency={currency} amount={value.toString()} />
          ) : (
            <Typography variant="body1" component="div" sx={styles.detailsInfoValue}>
              {date}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
export type BalanceInfoProps = {
  description: string;
  currency?: string;
  balance: string | number;
};
export const BalanceInfo: React.FC<BalanceInfoProps> = ({ description, currency, balance }) => {
  return (
    <>
      {isMobile ? (
        <Box mt={1}>
          <Grid container justifyContent="space-between">
            <Typography variant="body2" component="p" sx={styles.detailsInfoDescription}>
              {description}
            </Typography>
            <Typography variant="h6" sx={styles.amountText}>
              {balance}{' '}
              <Typography variant="body1" component="span">
                {currency}
              </Typography>
            </Typography>
          </Grid>
        </Box>
      ) : (
        <Box mt={4}>
          <Typography variant="body2" component="p" sx={styles.detailsInfoDescription}>
            {description}
          </Typography>
          <Typography variant="h6" sx={styles.amountText}>
            {balance}{' '}
            <Typography variant="body1" component="span">
              {currency}
            </Typography>
          </Typography>
        </Box>
      )}
    </>
  );
};

export type OverdraftInfoProps = {
  currency: string;
  amount: number;
  uuid: string;
};

export const OverdraftInfo: React.FC<OverdraftInfoProps> = ({ amount, currency, uuid }) => {
  const navigate = useNavigate();
  const goToOverdraft = () => {
    navigate(RouteList.overdraft_page.replace(':uuid', uuid));
  };
  const { t } = useTranslation();
  return (
    <Box mt={1}>
      <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="body2" component="span" sx={styles.overdraftInfoDescription}>
          {t('availableLimit')} <FollowSvg style={styles.icon} onClick={goToOverdraft} />
        </Typography>
        <Typography variant="body1" sx={styles.availableLimit}>
          {formatAmount(amount)}{' '}
          <Typography variant="body1" component="span">
            {currency}
          </Typography>
        </Typography>
      </Grid>
    </Box>
  );
};

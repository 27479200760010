import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '@app/common/icons';
import {
  CurrencyOperationAccountDto,
  CurrencyOperationsExchangeTypeEnum,
  CurrencyOperationStatusEnum,
  GetCurrencyOperationWithAvailableActionsDto,
  UserResourceDto,
} from '@app/core/api';
import { Checkbox } from '@app/core/components/Checkbox';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { DATE_FORMAT, formatISODateString, formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableContent/styles';
import { getExchangeDocumentStatusTranslationKey } from '@app/pages/exchange/currencyExchangeTransactions/helper';
import { CurrencyExchangeTableColumns } from '@app/pages/exchange/currencyExchangeTransactions/types';

interface MakeTableColumnsProps {
  t(text: string): string;
}

type StatusFieldProps = {
  status: CurrencyOperationStatusEnum;
};

const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.status,
          ...(status === CurrencyOperationStatusEnum.Executed && styles.statusExecuted),
          ...(status === CurrencyOperationStatusEnum.Rejected && styles.statusRejected),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(getExchangeDocumentStatusTranslationKey(status))}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  t,
}: MakeTableColumnsProps): ColumnDef<GetCurrencyOperationWithAvailableActionsDto, any>[] => {
  return [
    {
      id: 'selection',
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: CurrencyExchangeTableColumns.documentNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.documentNumber}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: CurrencyExchangeTableColumns.documentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('statementsTableColumn_documentDate')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetCurrencyOperationWithAvailableActionsDto, string>) => {
        return <>{getValue() ? formatISODateString(getValue(), DATE_FORMAT) : ''}</>;
      },
    },
    {
      id: CurrencyExchangeTableColumns.withdrawalAccountNumber,
      accessorKey: CurrencyExchangeTableColumns.withdrawalAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.withdrawalAccountNumber}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetCurrencyOperationWithAvailableActionsDto, CurrencyOperationAccountDto>) => {
        return (
          <Ellipsis
            text={
              <>
                <span>{getValue().accountNumber.substring(0, 13)}</span>
                <span>{getValue().accountNumber.slice(-13)}</span>
              </>
            }
          />
        );
      },
    },
    {
      id: CurrencyExchangeTableColumns.depositAccountNumber,
      accessorKey: CurrencyExchangeTableColumns.depositAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.depositAccountNumber}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetCurrencyOperationWithAvailableActionsDto, CurrencyOperationAccountDto>) => {
        return (
          <Ellipsis
            text={
              <>
                <span>{getValue().accountNumber.substring(0, 13)}</span>
                <span>{getValue().accountNumber.slice(-13)}</span>
              </>
            }
          />
        );
      },
    },
    {
      accessorKey: CurrencyExchangeTableColumns.currencyExchangeType,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.currencyExchangeType}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({
        getValue,
      }: CellContext<GetCurrencyOperationWithAvailableActionsDto, CurrencyOperationsExchangeTypeEnum>) => {
        return t(`createExchangeMenu_${getValue().toString().toLowerCase()}`);
      },
    },
    {
      id: CurrencyExchangeTableColumns.withdrawalAccountCurrency,
      accessorKey: CurrencyExchangeTableColumns.withdrawalAccount,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">
              {t(`currencyTableColumn_${CurrencyExchangeTableColumns.withdrawalAccountCurrency}`)}
            </Typography>
          </div>
        </Grid>
      ),
      cell: ({
        getValue,
        row,
      }: CellContext<GetCurrencyOperationWithAvailableActionsDto, CurrencyOperationAccountDto>) => {
        return row.original.currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Sale
          ? getValue().currency
          : row.original.depositAccount.currency;
      },
    },
    {
      accessorKey: CurrencyExchangeTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.amount}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetCurrencyOperationWithAvailableActionsDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: CurrencyExchangeTableColumns.status,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">
                {t(`currencyTableColumn_${CurrencyExchangeTableColumns.status}`)}
              </Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<GetCurrencyOperationWithAvailableActionsDto, CurrencyOperationStatusEnum>) => {
        return <StatusField status={getValue()} />;
      },
    },
    // {
    //   id: 'actions',
    //   accessorKey: CurrencyExchangeTableColumns.documentNumber,
    //   header: () => {
    //     return <Grid container justifyContent="flex-end" />;
    //   },
    //   cell: ({ row }) => {
    //     return (
    //       <Grid container justifyContent="flex-end" alignItems="center">
    //         {permissions.currencyExchange.edit ? (
    //           <Link to={`${RouteList.exchange_edit}/${row.id}`}>
    //             <ArrowRightIcon sx={styles.linkArrow} />
    //           </Link>
    //         ) : null}
    //       </Grid>
    //     );
    //   },
    // },
  ];
};

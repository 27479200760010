import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import {
  getClientId,
  templatesApiClient,
  ApiError,
  DocumentTemplateDto,
  UpdateTemplateRequestDto,
} from '@app/core/api';

export const updatePaymentTemplateFn = async (updateTemplateData: UpdateTemplateRequestDto): Promise<string> => {
  const result = await templatesApiClient.paymentTemplateControllerUpdateTemplate(getClientId(), updateTemplateData);

  if (result.status !== 200) {
    throw new Error("Can't save the template.");
  }

  return result.data;
};

const getPaymentTemplateByUuidFn = async (uuid: string): Promise<DocumentTemplateDto> => {
  const result = await templatesApiClient.paymentTemplateControllerGetTemplateByUid(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the payment order");
  }
  return result.data;
};

export const useGetTemplateByUuid = (uuid: string): UseQueryResult<DocumentTemplateDto, ApiError> => {
  return useQuery<DocumentTemplateDto, ApiError>('documentTemplate', () => getPaymentTemplateByUuidFn(uuid), {
    enabled: false,
  });
};

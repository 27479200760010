import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { ArrowRightIcon, SettingIcon } from '@app/common/icons';
import { OverdraftDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { IBanText } from '@app/core/components/IBanText';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/overdrafts/components/OverdraftsTable/TableContent/styles';
import { OverdraftsTableColumns } from '@app/pages/overdrafts/types';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';

interface MakeTableColumnsProps {
  t(text: string): string;
  onSettingsButtonClick(): void;
}

export const makeTableColumns = ({
  t,
  onSettingsButtonClick,
}: MakeTableColumnsProps): ColumnDef<OverdraftDto, any>[] => {
  return [
    {
      accessorKey: OverdraftsTableColumns.startDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: OverdraftsTableColumns.endDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: OverdraftsTableColumns.accountOverdraft,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`accountOverdraft`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, string>) => {
        return <IBanText text={getValue()} />;
      },
    },
    {
      accessorKey: OverdraftsTableColumns.totalLimit,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`totalLimit`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: OverdraftsTableColumns.usedLimit,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`usedLimit`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: OverdraftsTableColumns.availableLimit,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`availableLimit`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: OverdraftsTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OverdraftsTableColumns.interestRate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`interestRate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OverdraftDto, string>) => {
        return `${formatAmount(getValue())}%`;
      },
    },
    {
      id: 'actions',
      accessorKey: OverdraftsTableColumns.uuid,
      header: () => {
        return (
          <Grid container justifyContent="flex-end">
            {appConfig.enableNextFeatures ? (
              <IconButton sx={styles.settingIconBtn} onClick={onSettingsButtonClick} size="large">
                <SettingIcon />
              </IconButton>
            ) : null}
          </Grid>
        );
      },
      cell: ({ row }: CellContext<OverdraftDto, any>) => {
        return (
          <Grid container justifyContent="flex-end" alignItems="center">
            <Link to={`${RouteList.overdrafts}${row.original.uuid}`}>
              <ArrowRightIcon sx={styles.linkArrow} />
            </Link>
          </Grid>
        );
      },
    },
  ];
};

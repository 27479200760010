import React from 'react';

import { Chip as MuiChip, ChipProps } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';

import { ReactComponent as CrossIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';

export interface TagChipProps {
  index: number;
  label: React.ReactNode;
  chipProps?: ChipProps;
  getTagProps: AutocompleteRenderGetTagProps;
}

export const TagChip: React.FC<TagChipProps> = ({ index, label, chipProps, getTagProps }) => {
  return (
    <MuiChip
      {...chipProps}
      label={label}
      {...getTagProps({ index })}
      deleteIcon={<CrossIcon className="MuiSvgIcon-root" />}
    />
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  configurationApiClient,
  getClientId,
  ApiError,
  GetStatementConfigResponseDto,
  UpdateStatementConfigDto,
} from '@app/core/api';

type UpdateProps = {
  uuid: string;
  updateStatementConfigDto: UpdateStatementConfigDto;
};

export const useUpdateStatementsConfig = (): UseMutationResult<
  GetStatementConfigResponseDto,
  ApiError,
  UpdateProps
> => {
  return useMutation(async ({ uuid, updateStatementConfigDto }: UpdateProps) => {
    const result = await configurationApiClient.clientConfigControllerUpdateStatementsConfig(
      uuid,
      getClientId(),
      updateStatementConfigDto
    );

    if (result.status !== 200) {
      throw new ApiError(result.request.status, "Can't update the statements config.");
    }

    return result.data;
  });
};

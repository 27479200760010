import { DropResult } from 'react-beautiful-dnd';

export const onDragEndHandler = <T>(result: DropResult, prevState: T[], setNewState: (newState: T[]) => void): void => {
  const { destination, source } = result;
  if (!destination) {
    return;
  }
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return;
  }
  const destinationArray = [...prevState];
  destinationArray.splice(source.index, 1);
  destinationArray.splice(destination.index, 0, prevState[source.index]);
  setNewState(destinationArray);
};

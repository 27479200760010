import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, counterpartiesApiClient, getClientId } from '@app/core/api';

const deleteSwiftCounterpartyFn = async (id: string): Promise<void> => {
  const response = await counterpartiesApiClient.counterpartiesControllerDetachSwiftCounterparty(id, getClientId());
  return response.data;
};

export const useDeleteSwiftCounterparty = (): UseMutationResult<void, ApiError, string> => {
  return useMutation<void, ApiError, string>(deleteSwiftCounterpartyFn);
};

import React, { useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useGetSingleAccount } from '@app/core/hooks/useGetSingleAccount';
import { PageContent } from '@app/pages/accounts/selectedAccount/PageContent';

export const SelectedAccountPage: React.FC = (props): JSX.Element => {
  const { id = 'no-id' } = useParams();
  const stateData: any = useLocation().state;
  const accountName = stateData?.accountName ? stateData.accountName : '';

  const { mutate, data } = useGetSingleAccount();

  useEffect(() => {
    mutate(id);
  }, [id, mutate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title: string = data?.account?.accountName ? data.account.accountName : accountName;
  return (
    <PageWrapper>
      <PageHeader title={title} showNavigateButton backLink={-1} useDesktopStyle />
      <PageContainer direction="column">{data ? <PageContent account={data} /> : null}</PageContainer>
    </PageWrapper>
  );
};

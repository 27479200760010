import {
  getClientId,
  templatesApiClient,
  ApiError,
  CreateTemplateRequestDto,
  DocumentTemplateDto,
} from '@app/core/api';

export const savePaymentTemplateFn = async (templateData: CreateTemplateRequestDto): Promise<DocumentTemplateDto> => {
  const result = await templatesApiClient.paymentTemplateControllerCreateTemplate(getClientId(), templateData);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't save the template.");
  }

  return result.data;
};

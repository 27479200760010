import React from 'react';

import { Box, Button, FormControlLabel, OutlinedInput, SvgIcon, Typography, useAutocomplete } from '@mui/material';
import { AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import { useTranslation } from 'react-i18next';

import { CurrencyCodeDto } from '@app/core/api';
import { ReactComponent as SearchIconSvg } from '@app/themes/default/assets/icons/search.svg';

import { CurrencyMenuOption } from './CurrencyOptionItem';
import { Checkbox } from '../../Checkbox';
import { styles } from '../styles';

export type CurrencySelectContentProps = {
  name: string;
  options: CurrencyCodeDto[];
  allSelected: boolean;
  selectedValues: CurrencyCodeDto[];
  onChange(newValue: CurrencyCodeDto[]): void;
  onResetHandler(): void;
  onSelectActiveAll(event: React.ChangeEvent<{}>, checked: boolean): void;
};

export const CurrencySelectContent = React.forwardRef(
  (
    {
      name,
      options,
      selectedValues,
      allSelected,
      onChange,
      onResetHandler,
      onSelectActiveAll,
    }: CurrencySelectContentProps,
    ref
  ) => {
    const { t } = useTranslation();

    const filterOptions = createFilterOptions<CurrencyCodeDto>({
      stringify: (currency) => currency.alpha3 + currency.name,
    });

    const groupByRule = (currency: CurrencyCodeDto): string => {
      return currency.alpha3;
    };

    const renderGroup = (params: AutocompleteRenderGroupParams) => [<></>, params.children];

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, popupOpen, inputValue, groupedOptions } =
      useAutocomplete<CurrencyCodeDto, true, true, false>({
        componentName: name,
        options,
        groupBy: groupByRule,
        filterOptions: (currency: CurrencyCodeDto[], params): CurrencyCodeDto[] => {
          if (currency.length > 0) {
            const filtered = filterOptions(currency, params);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return [{ selectAll: 'selectAll' }, ...filtered];
          }
          if (currency.length === 0) {
            const filtered = filterOptions(currency, params);
            return [...filtered];
          }
          return [];
        },
        value: selectedValues,
        open: true,
        multiple: true,
        disableClearable: true,
        disableCloseOnSelect: true,
        onChange: (event, newValue) => {
          onChange([...newValue]);
        },
        isOptionEqualToValue: (currency1, currency2) => {
          return currency1.name === currency2.name;
        },
        getOptionLabel: (currency) => {
          return JSON.stringify(currency);
        },
      });

    const renderListOption = (option: CurrencyCodeDto, index: number) => {
      const optionProps = getOptionProps({ option, index });

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Box {...optionProps} sx={styles.option}>
          {CurrencyMenuOption(option, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selected: optionProps['aria-selected'],
            inputValue,
          })}
        </Box>
      );
    };

    const SelectAllOption: React.FC = () => {
      return (
        <FormControlLabel
          sx={styles.selectAllFormControlLabel}
          control={<Checkbox name={name} />}
          label={<Typography variant="caption">{t('selectAllCurrency')}</Typography>}
          checked={allSelected}
          onChange={onSelectActiveAll}
        />
      );
    };

    const resetButtonDisabled = !(selectedValues && selectedValues.length);

    return (
      <>
        {popupOpen ? (
          <Box sx={styles.selectContentRoot}>
            <Box {...getRootProps()} sx={styles.inputContainer}>
              <OutlinedInput
                autoFocus
                fullWidth
                inputProps={{ ...getInputProps() }}
                endAdornment={
                  <Box sx={styles.endAdornment}>
                    <SvgIcon viewBox="0 0 16 16" sx={styles.searchIcon}>
                      <SearchIconSvg />
                    </SvgIcon>
                  </Box>
                }
              />
              <Box sx={styles.selectPanel}>
                <Typography variant="caption" component="p" sx={styles.selectedCountLabel}>
                  {`${t('currencySelectSelected')} `}
                  <Typography variant="caption" component="span" sx={styles.selectedCount}>
                    {selectedValues ? selectedValues.length : 0}
                  </Typography>
                </Typography>
                <Button
                  color="primary"
                  size="small"
                  sx={styles.resetButton}
                  disabled={resetButtonDisabled}
                  onClick={onResetHandler}
                >
                  {t('accountsSelect_clear_all')}
                </Button>
              </Box>
            </Box>
            {groupedOptions.length > 0 ? (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Box {...getListboxProps()} sx={styles.listbox}>
                {groupedOptions.map((option: any, index) =>
                  renderGroup({
                    key: option.key,
                    group: option.group,
                    children: option.options.map((option2: CurrencyCodeDto, index2: number) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (option2.selectAll) {
                        return <SelectAllOption key={`select_all_${index2.toString()}`} />;
                      }
                      return renderListOption(option2, option.index + index2);
                    }),
                  })
                )}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </>
    );
  }
);

export const styles = {
  root: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    top: '15%',
    left: 0,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    height: '-webkit-fill-available',
  },
  title: {
    fontWeight: 600,
  },
  statusEnabled: {
    color: 'colors.success',
    fontWeight: 600,
  },
  statusDisabled: {
    color: 'colors.error',
    fontWeight: 600,
  },
  actionButtonContainer: {
    position: 'absolute',
    bottom: '28px',
  },
  button: {
    width: '100%',
  },
  point: {
    width: '6px',
    height: '6px',
    backgroundColor: 'colors.primary500',
    borderRadius: '50%',
  },
};

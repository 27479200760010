import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CurrencyEnum,
  ResultStatusEnum,
  SwiftOperationWithAvailableButtons,
  SwiftOperationWithAvailableButtonsStatusEnum,
} from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { useDeleteSwiftOperation } from '@app/core/hooks/useDeleteSwiftOperation';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/swift/components/SwiftTable/components/DeleteSwiftOperations/style';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

interface DeleteSwiftOperationsDialogProps {
  onSubmit(): void;
  onCancelDeleteDocument(): void;
  onDeleteDocumentSuccess(): void;
  selectedDocuments: SwiftOperationWithAvailableButtons[];
}

export const DeleteSwiftOperationsDialog: React.FC<DeleteSwiftOperationsDialogProps> = ({
  onDeleteDocumentSuccess,
  onCancelDeleteDocument,
  selectedDocuments,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();

  const { showLoader } = useUIState();
  const { mutate } = useDeleteSwiftOperation();

  const docsForDelete = selectedDocuments.filter(
    (item) =>
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.Saved ||
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.ToSign
  );

  const docsForDeleteCount: number = docsForDelete.length;

  const swiftOperationGroupByCurrency: {
    [key in CurrencyEnum]?: SwiftOperationWithAvailableButtons[];
  } = groupBy<SwiftOperationWithAvailableButtons>(docsForDelete, 'currency');

  const uuids = docsForDelete.map((document) => {
    return document.uuid;
  });

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate(
      { uuids },
      {
        onSuccess: (result) => {
          showLoader(false);
          if (result.status === ResultStatusEnum.Successfully) {
            onDeleteDocumentSuccess();

            notify({
              notifyProps: {
                title: t('success'),
                message: t('deleteDocumentSuccessMessage'),
                severity: 'success',
              },
            });
          } else {
            notify({
              notifyProps: {
                title: t('server_error'),
                message: t('deleteDocumentErrorMessage'),
                severity: 'error',
              },
            });
          }
          onSubmit();
        },
        onError: () => {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('server_error'),
              message: t('deleteDocumentErrorMessage'),
              severity: 'error',
            },
          });
          onSubmit();
        },
      }
    );
  };
  return (
    <ModalCommon onClose={onCancelDeleteDocument} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDeleteDocument}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('areYouSureYouWantToDelete')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('deleteDocumentCount')}
                {' / '} {t('deleteDocument_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedDocuments.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForDeleteCount}>
                {docsForDeleteCount}
              </Typography>
            </Grid>
          </Grid>
          {swiftOperationGroupByCurrency
            ? Object.entries(swiftOperationGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`swiftOperation_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDeleteDocument}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmDeleteDocument}>
            {t('yes')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { ThemeSwitcher } from '@app/core/components/ThemeSwitcher';
import { HistoryOfVisits } from '@app/pages/profile/desktop/components/HistoryOfVisits';
import { Keys } from '@app/pages/profile/desktop/components/Keys';
import { Monitoring } from '@app/pages/profile/desktop/components/Monitoring';
import { Security } from '@app/pages/profile/desktop/components/Security';
import { Settings } from '@app/pages/profile/desktop/components/Settings';
import { UserData } from '@app/pages/profile/desktop/components/UserData';
import { UserNotifications } from '@app/pages/profile/desktop/components/UserNotifications';
import { getMenuItems } from '@app/pages/profile/helper';
import { UserProfileMenuFilter } from '@app/pages/profile/types';

export const ProfileViewDesktop: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentFilter, setCurrentFilter] = useState<UserProfileMenuFilter>(
    (searchParams.get('tab') as UserProfileMenuFilter) || UserProfileMenuFilter.Data
  );
  const { t } = useTranslation();
  const menuItems: MenuItem<UserProfileMenuFilter>[] = getMenuItems(t);

  const onFilterChangeHandler = (filter: UserProfileMenuFilter) => {
    setSearchParams(`tab=${filter}`);
    setCurrentFilter(filter);
  };

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('myProfile')} useDesktopStyle>
          <Grid item>
            <ThemeSwitcher />
          </Grid>
        </PageHeader>
        <PageContainer>
          <Grid container direction="column" wrap="nowrap">
            <HorizontalMenu<UserProfileMenuFilter>
              onChange={onFilterChangeHandler}
              menuItems={menuItems}
              defaultActiveItem={currentFilter}
            />
            <Box
              pt={7}
              sx={{
                height: '100%',
              }}
            >
              {currentFilter === UserProfileMenuFilter.Data && <UserData />}
              {currentFilter === UserProfileMenuFilter.Notification && <UserNotifications />}
              {currentFilter === UserProfileMenuFilter.Security && <Security />}
              {currentFilter === UserProfileMenuFilter.Keys && <Keys />}
              {currentFilter === UserProfileMenuFilter.History && <HistoryOfVisits />}
              {currentFilter === UserProfileMenuFilter.Monitoring && <Monitoring />}
              {currentFilter === UserProfileMenuFilter.Settings && <Settings />}
            </Box>
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

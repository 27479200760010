import React, { useEffect } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  ChangeStatusRequestDtoStatusEnum,
  MessageDto,
  MessageStatus,
  ResultResponseDto,
  ResultStatusEnum,
} from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { useChangeMessagesStatus } from '@app/core/hooks';
import { styles } from '@app/pages/swift/components/SwiftTable/components/SignSwiftOperations/style';
import { ReactComponent as SignIcoBgSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

interface SendToSignMessagesDialogProps {
  selectedMessages: MessageDto[];
  onSuccessPopupCancel(): void;
  onClose(): void;
}

export const SendToSignMessagesDialog: React.FC<SendToSignMessagesDialogProps> = ({
  selectedMessages,
  onSuccessPopupCancel,
  onClose,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();

  const { mutateAsync: mutateAsyncChangeMessageStatus, data } = useChangeMessagesStatus();

  const docsForSign = selectedMessages.filter(
    (item) => item.status === MessageStatus.Saved || item.status === MessageStatus.ToSign
  );

  const docsForSignCount: number = docsForSign.length;

  const onConfirmSignDocument = () => {
    const messagesToSendToSign = selectedMessages.filter((item) => item.status === MessageStatus.Saved);
    mutateAsyncChangeMessageStatus({
      status: ChangeStatusRequestDtoStatusEnum.ToSign,
      uuids: messagesToSendToSign.map((mess) => mess.uuid),
    })
      .then((result: ResultResponseDto) => {
        if (result.errors && result.errors.length) {
          notify({
            notifyProps: {
              title: t('error'),
              severity: 'error',
            },
          });
          onSuccessPopupCancel();
          return;
        }
      })
      .catch((e) => {
        notify({
          notifyProps: {
            title: t('error'),
            severity: 'error',
          },
        });
      });
  };

  return (
    <ModalCommon onClose={onClose} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <SignIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onClose}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('documentsToSign')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('sign_selectedDocuments')}
                {':'}
                {' / '} {t('sign_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedMessages.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForSignCount}>
                {docsForSignCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onClose}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmSignDocument}>
            {t('send')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

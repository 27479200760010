import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { ExtendedMessageDto, MessageStatus } from '@app/core/api';
import { ModalCommon } from '@app/core/components';
import { useDeleteMessages } from '@app/core/hooks';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

import { styles } from './style';

interface DeleteMessagesProps {
  selectedMessages: ExtendedMessageDto[];
  onCancelDelete(): void;
  onDeleteSuccess(): void;
}

export const DeleteMessagesDialog: React.FC<DeleteMessagesProps> = ({
  selectedMessages,
  onCancelDelete,
  onDeleteSuccess,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const { showLoader } = useUIState();
  const messagesForDelete = selectedMessages.filter((item) => item.status !== MessageStatus.Delivered);
  const messagesForDeleteCount: number = messagesForDelete.length;

  const uuids = messagesForDelete.map((item) => {
    return item.uuid;
  });

  const { mutate } = useDeleteMessages();

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate(
      { uuids },
      {
        onSuccess: () => {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('success'),
              message: t('deleteLetterSuccessMessage'),
              severity: 'success',
            },
          });
          onDeleteSuccess();
        },
        onError: () => {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('server_error'),
              message: t('deleteLetterErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };

  return (
    <ModalCommon onClose={onCancelDelete} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDelete}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('areYouSureYouWantToDelete')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('selectedMessages')}
                {' / '} {t('deleteDocument_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedMessages.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForDeleteCount}>
                {messagesForDeleteCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDelete}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmDeleteDocument}>
            {t('yes')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PersonInfoDto, SignatureStatusDtoStatusEnum } from '@app/core/api';
import { Tooltip } from '@app/core/components/Tooltip';
import { styles } from '@app/pages/exchange/components/form/components/InfoBlock/style';

type UserInfoProps = {
  person: PersonInfoDto;
  status: SignatureStatusDtoStatusEnum;
};

export const UserInfo: React.FC<UserInfoProps> = ({ person, status }) => {
  const { t } = useTranslation();
  const statusText =
    status === SignatureStatusDtoStatusEnum.Signed
      ? t('personSignInfoStatus_Signed')
      : t('personSignInfoStatus_ToSign');
  return (
    <Tooltip
      arrow
      placement="top"
      color="primary"
      sx={styles.tooltip}
      title={`${person.lastName} ${person.firstName} - ${statusText}  `}
    >
      <Box sx={styles.userInfo}>
        {person.lastName.charAt(0)}
        {person.firstName.charAt(0)}
      </Box>
    </Tooltip>
  );
};

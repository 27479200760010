import React from 'react';

import { Box, ButtonBase, Typography } from '@mui/material';

import { styles } from './styles';

interface RowsPerPageProps {
  labelRowsPerPage: React.ReactNode;
  selectedRowsPerPage: number;
  rowsPerPageOptions: number[];
  onOptionSelect(rows: number): void;
}

export const RowsPerPage: React.FC<RowsPerPageProps> = ({
  labelRowsPerPage,
  selectedRowsPerPage,
  rowsPerPageOptions = [5, 10, 15, 25, 50],
  onOptionSelect,
}) => {
  const onclickHandler = (e: React.MouseEvent<HTMLElement>) => {
    const rows = Number(e.currentTarget.getAttribute('data-value'));
    onOptionSelect(rows);
  };

  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={styles.label}>
        {labelRowsPerPage}
      </Typography>
      <Box sx={styles.optionsContainer}>
        {rowsPerPageOptions?.map((option) => {
          return (
            <ButtonBase
              centerRipple
              key={option}
              sx={{ ...styles.optionButton, ...(selectedRowsPerPage === option && styles.selected) }}
              data-value={option}
              onClick={onclickHandler}
            >
              <Typography variant="body1" sx={styles.optionText}>
                {option}
              </Typography>
            </ButtonBase>
          );
        })}
      </Box>
    </Box>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  CreateSwiftOperationResponseDto,
  EditSwiftOperationRequestDto,
  getClientId,
  swiftApiClient,
} from '@app/core/api';

const editSwiftPaymentFn = async (
  paymentData: EditSwiftOperationRequestDto
): Promise<CreateSwiftOperationResponseDto> => {
  const result = await swiftApiClient.swiftControllerUpdateSwiftOperation(getClientId(), paymentData);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "swiftControllerUpdateSwiftOperation error. Can't update operation");
  }

  return result.data;
};

export const useEditSwiftPayment = (): UseMutationResult<
  CreateSwiftOperationResponseDto,
  ApiError,
  EditSwiftOperationRequestDto
> => {
  return useMutation<CreateSwiftOperationResponseDto, ApiError, EditSwiftOperationRequestDto>(editSwiftPaymentFn);
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CollateralListResponseDto, RepaymentTypeEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { LoanLinkedAccount } from '@app/core/components/LoanLinkedAccount';
import { SafetyTable } from '@app/core/components/SafetyTable';
import { StatusBadge } from '@app/core/components/StatusBadge';
import { WaitForAnotherLoanPaymentAlert } from '@app/core/components/WaitForAnotherLoanPaymentAlert';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { PaginationChangeType } from '@app/core/types';
import { formatDate, formatAmount } from '@app/core/utils';
import { OperationsTable } from '@app/pages/loansLines/show/components/OperationsTable';
import { TranchesTable } from '@app/pages/loansLines/show/components/TranchesTable';
import { useGetLoanLineByUuid, getLoanLineOperationFn, getCollateral } from '@app/pages/loansLines/show/query';
import { styles } from '@app/pages/loansLines/show/style';
import { LoansLinesMenuItems } from '@app/pages/loansLines/types';
import { RouteList } from '@app/src/constants/routeList';

export const LoanLinesShowPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<LoansLinesMenuItems>(LoansLinesMenuItems.operations);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { uuid = 'no-uuid' } = useParams();
  const { t } = useTranslation();
  const [outstanding, setOutstanding] = useState<boolean>(false);
  const [interests, setInterests] = useState<boolean>(false);
  const [overdueOutstanding, setOverdueOutstanding] = useState<boolean>(false);
  const [overdueInterests, setOverdueInterests] = useState<boolean>(false);
  const collateralPaginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });

  const { data: loanLine, refetch: refetchLoanLine } = useGetLoanLineByUuid(uuid);
  const { data: operationsData, refetch: refetchOperations } = useQuery('loansOperations', () =>
    getLoanLineOperationFn(uuid, paginationRef.current)
  );

  const { data: collateral, refetch: refetchCollateral } = useQuery<CollateralListResponseDto>(
    'loanLinesCollateral',
    () => getCollateral(uuid, collateralPaginationRef.current),
    {
      enabled: false,
      refetchOnMount: false,
    }
  );

  const menuItems: MenuItem<LoansLinesMenuItems>[] = loanLine?.security
    ? [
        {
          id: 1,
          value: LoansLinesMenuItems.operations,
          labelText: t('operations'),
        },
        {
          id: 2,
          value: LoansLinesMenuItems.tranches,
          labelText: t('tranches'),
        },
        {
          id: 3,
          value: LoansLinesMenuItems.safety,
          labelText: t('safety'),
        },
      ]
    : [
        {
          id: 1,
          value: LoansLinesMenuItems.operations,
          labelText: t('operations'),
        },
        {
          id: 2,
          value: LoansLinesMenuItems.tranches,
          labelText: t('tranches'),
        },
      ];

  useEffect(() => {
    refetchLoanLine();
    refetchOperations();
    refetchCollateral();
  }, [refetchLoanLine, refetchOperations, refetchCollateral]);

  const paginationChangeHandler = (pagination: PaginationChangeType) => {
    paginationRef.current = pagination;
    refetchOperations();
  };

  const onTabChangeHandler = (tab: LoansLinesMenuItems) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (loanLine) {
      setOutstanding(loanLine.repayments.repaymentTypes.includes(RepaymentTypeEnum.Outstanding));
      setInterests(loanLine.repayments.repaymentTypes.includes(RepaymentTypeEnum.Interests));
      setOverdueInterests(loanLine.repayments.repaymentTypes.includes(RepaymentTypeEnum.OverdueInterests));
      setOverdueOutstanding(loanLine.repayments.repaymentTypes.includes(RepaymentTypeEnum.OverdueOutstanding));
    }
  }, [loanLine]);

  const payClickHandler = (repaymentType: RepaymentTypeEnum) => {
    if (loanLine) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.payment_create,
        query: {
          lid: loanLine.uuid,
          rt: repaymentType,
          line: true,
        },
      });
      navigate(urlTo);
    }
  };

  const startDate = loanLine?.agreementStartDate.split('-').reverse().join('-') || '';
  const endDate = loanLine?.agreementEndDate.split('-').reverse().join('-') || '';

  const days: number | undefined = loanLine
    ? differenceInCalendarDays(new Date(loanLine.agreementEndDate), new Date())
    : undefined;

  const outstandingBtnDisabled = loanLine?.repayments.blockedRepaymentTypes.includes(RepaymentTypeEnum.Outstanding);
  const interestsBtnDisabled = loanLine?.repayments.blockedRepaymentTypes.includes(RepaymentTypeEnum.Interests);
  const overdueInterestsBtnDisabled = loanLine?.repayments.blockedRepaymentTypes.includes(
    RepaymentTypeEnum.OverdueInterests
  );
  const overdueOutstandingBtnDisabled = loanLine?.repayments.blockedRepaymentTypes.includes(
    RepaymentTypeEnum.OverdueOutstanding
  );

  const showAlertMessage =
    outstandingBtnDisabled || interestsBtnDisabled || overdueInterestsBtnDisabled || overdueOutstandingBtnDisabled;

  const collateralPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    collateralPaginationRef.current = paginationChange;
    refetchCollateral();
  };

  return (
    <PageWrapper>
      <PageHeader title={`${t('loanLine')} № ${loanLine?.agreementNumber || ''}`} showNavigateButton backLink={-1}>
        <Grid item container justifyContent="flex-end" alignItems="center">
          {interests ? (
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.Interests)}
                disabled={interestsBtnDisabled}
              >
                {t('pay_interests')}
              </Button>
            </Box>
          ) : null}

          {overdueOutstanding ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.OverdueOutstanding)}
                disabled={overdueOutstandingBtnDisabled}
              >
                {t('pay_overdueOutstanding')}
              </Button>
            </Box>
          ) : null}
          {overdueInterests ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.OverdueInterests)}
                disabled={overdueInterestsBtnDisabled}
              >
                {t('pay_overdueInterests')}
              </Button>
            </Box>
          ) : null}
          {outstanding ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.Outstanding)}
              >
                {t('pay_outstanding')}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </PageHeader>
      <Grid container direction="column">
        {showAlertMessage ? <WaitForAnotherLoanPaymentAlert /> : null}
        <Grid container direction="column" sx={styles.paper}>
          <Grid item container>
            <Grid item xs={8}>
              <Box pl={5} pt={5}>
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Typography sx={styles.companyName}>{`${t('contract')} ${loanLine?.agreementNumber}`}</Typography>
                  <Box pl={2}>
                    <StatusBadge status={loanLine?.state || ''} />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box pr={5} pt={5}>
                {loanLine ? <LoanLinkedAccount loan={loanLine} /> : null}
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Box mt={10} pl={5} sx={styles.infoBlock}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('startDate')}
                    </Typography>
                    <Typography sx={styles.bold}>{formatDate(startDate, '.')}</Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('endDate')}
                    </Typography>
                    <Typography sx={styles.bold}> {formatDate(endDate, '.')}</Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('timeLeft')}
                    </Typography>
                    <Typography sx={styles.bold}>
                      {days} {t('shorts_days')}
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('interestRate')}
                    </Typography>
                    <Typography sx={styles.bold}>
                      {loanLine?.interestRate}
                      <Typography component="span">%</Typography>
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('currency')}
                    </Typography>
                    <Typography sx={styles.bold}>{loanLine?.currency}</Typography>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={styles.limitBlock}>
            <Box pl={5} pr={5}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('loanLineLimit')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {formatAmount(loanLine?.limit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {loanLine?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3} sx={styles.debt}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('limitDebt')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {formatAmount(loanLine?.usedLimit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {loanLine?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('balanceOverLimit')}
                      </Typography>
                      <Typography sx={styles.bold}>
                        {formatAmount(loanLine?.availableLimit || '')}{' '}
                        <Typography variant="body2" component="span">
                          {loanLine?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('currentDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {formatAmount(loanLine?.outstanding || '')}{' '}
                <Typography variant="body2" component="span" sx={styles.bold}>
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('overdueDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {loanLine && loanLine?.outstandingOverdue
                  ? formatAmount(loanLine?.interestOverdue + loanLine?.outstandingOverdue || '')
                  : formatAmount(loanLine?.interestOverdue || '')}{' '}
                <Typography variant="body2" component="span" sx={styles.bold}>
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestAccrual')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loanLine?.interestAccrued || '')}{' '}
                <Typography variant="body2" component="span">
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestOverdue')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loanLine?.interestOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loanLine?.outstandingOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loanLine?.outstandingOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {loanLine?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={7}>
          <HorizontalMenu<LoansLinesMenuItems> onChange={onTabChangeHandler} menuItems={menuItems} />
        </Box>
        <Box mt={4}>
          {currentTab === LoansLinesMenuItems.operations && (
            <OperationsTable
              data={operationsData?.operations || []}
              onPaginationChange={paginationChangeHandler}
              pagination={operationsData?.pagination}
            />
          )}
          {currentTab === LoansLinesMenuItems.safety && (
            <SafetyTable
              data={collateral?.collateral || []}
              pagination={collateral?.pagination}
              onPaginationChange={collateralPaginationChangeHandler}
            />
          )}
          {currentTab === LoansLinesMenuItems.tranches && <TranchesTable data={loanLine?.tranches || []} />}
        </Box>
      </Grid>
    </PageWrapper>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { configTableComparator } from '@app/core/components/ComplexTable/helpers';
import { ModalPopupCommon } from '@app/core/components/Modal/ModalPopupCommon';
import { StrictModeDroppable } from '@app/core/components/StrictModeDroppable';
import { onDragEndHandler } from '@app/core/helpers';
import { TableColumnConfig } from '@app/core/types';
import { CurrencyExchangePreferenceItem } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/CurrencyExchangeTablePreference/CurrencyExchangePreferenceItem';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/CurrencyExchangeTablePreference/styles';

export interface CurrencyExchangeTablePreferenceProps {
  show: boolean;
  columnsConfig: TableColumnConfig[];
  columnsConfigMutationFn(currencyTableColumnsData: TableColumnConfig[]): Promise<TableColumnConfig[]>;
  handleShow(show: boolean): void;
  onSuccessConfigurationSaved(tableColumnsConfig: TableColumnConfig[]): void;
}

export const CurrencyExchangeTablePreference: React.FC<CurrencyExchangeTablePreferenceProps> = ({
  show,
  columnsConfig,
  columnsConfigMutationFn,
  handleShow,
  onSuccessConfigurationSaved,
}) => {
  const { notify } = useNotify();
  const { mutate } = useMutation<TableColumnConfig[], unknown, TableColumnConfig[]>(columnsConfigMutationFn);
  const [userTableConfig, setUserTableConfig] = useState<TableColumnConfig[]>([]);

  useEffect(() => {
    setUserTableConfig([...columnsConfig].sort(configTableComparator));
  }, [columnsConfig]);

  const { t } = useTranslation();

  const updateSelectedRows = (order: number, enabled: boolean) => {
    const currencyTableColumnArray = [...userTableConfig];
    currencyTableColumnArray.splice(order, 1);
    setUserTableConfig(
      [
        ...currencyTableColumnArray,
        {
          ...userTableConfig[order],
          enabled,
        },
      ].sort(configTableComparator)
    );
  };

  const onDragEnd = (result: DropResult) => {
    onDragEndHandler<TableColumnConfig>(result, userTableConfig, (newState) => {
      const newConfig = newState.map((item, index) => ({
        ...item,
        order: index,
      }));
      setUserTableConfig(newConfig);
    });
  };

  const closeConfigModal = () => {
    handleShow(false);
  };

  const onHandleShow = () => {
    closeConfigModal();
  };

  const onSubmit = () => {
    mutate(userTableConfig, {
      onSuccess: (newConfig) => {
        onSuccessConfigurationSaved(newConfig);
      },
      onError: () => {
        notify({
          notifyProps: {
            title: t('server_error'),
            message: t('statements_config_save_error'),
            severity: 'error',
          },
        });
      },
      onSettled: () => {
        closeConfigModal();
      },
    });
  };

  return (
    <ModalPopupCommon open={show}>
      <>
        <Box pb={3}>
          <Grid justifyContent="space-between" alignItems="center" container>
            <Typography component="span" variant="h6" sx={styles.caption}>
              {t('settings')}
            </Typography>
            <IconButton size="small" onClick={onHandleShow}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        {userTableConfig ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef} sx={styles.wrapHeight}>
                  {userTableConfig.map((tableColumn, index) => {
                    return (
                      <Draggable draggableId={tableColumn.columnType} key={tableColumn.columnType} index={index}>
                        {(providedDraggable, snapshotDraggable) => (
                          <div {...providedDraggable.draggableProps} ref={providedDraggable.innerRef}>
                            <CurrencyExchangePreferenceItem
                              name={t(`currency_${tableColumn.columnType}`)}
                              key={tableColumn.columnType}
                              index={index}
                              onChangeEnabled={updateSelectedRows}
                              providedHandleProps={providedDraggable.dragHandleProps || undefined}
                              styles={snapshotDraggable.isDragging ? styles.isDragging : undefined}
                              {...tableColumn}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        ) : null}
        <Grid justifyContent="flex-end" container>
          <Button variant="contained" size="small" color="primary" sx={styles.sendButton} onClick={onSubmit}>
            {t('save')}
          </Button>
        </Grid>
      </>
    </ModalPopupCommon>
  );
};

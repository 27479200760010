import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUIState } from '@app/context/UIContext';
import { MessageDto } from '@app/core/api';
import {
  DocumentSigned,
  DocumentToSign,
  SignaturePopup,
  SignatureStrategyEnum,
  StatusPopup,
} from '@app/core/components';
import { useSignMessages } from '@app/core/hooks';

export interface SignMessagesProps {
  messages: MessageDto[];
  onSuccessPopupCancel(): void;
  onClose(): void;
}

export const SignMessages: React.FC<SignMessagesProps> = ({ messages, onSuccessPopupCancel, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const [documentsSigned, setDocumentsSigned] = useState<DocumentSigned[]>([]);
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);

  const { mutate, status, data, isLoading } = useSignMessages();

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[]) => {
    setDocumentsSigned(signedDocuments);
    mutate({ operations: signedDocuments.map((item) => ({ uuid: item.uuid, signedDocument: item.signedDocument })) });
  };

  const messagesToSign: DocumentToSign[] = messages.map((item) => {
    return { uuid: item.uuid, documentToSign: JSON.stringify(item) };
  });

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    if (onSuccessPopupCancel) {
      onSuccessPopupCancel();
      return;
    }
    navigate(-1);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSignModal(false);
    mutate({ operations: documentsSigned.map((item) => ({ uuid: item.uuid, signedDocument: item.signedDocument })) });
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  useEffect(() => {
    if (status === 'success') {
      setShowSuccessSignModal(true);
    }
    if (status === 'error') {
      setShowErrorSignModal(true);
    }
  }, [status]);

  return (
    <>
      <SignaturePopup
        documentsToSign={messagesToSign}
        type={SignatureStrategyEnum.Message}
        onSignedStringResult={onSignedStringResultHandler}
        onClose={onClose}
      />

      <StatusPopup
        show={showSuccessSignModal}
        title={data && data.signedCount > 1 ? t('letters_sign_title') : t('messageIsSigned')}
        text={
          data && data.signedCount > 1 ? (
            <div>
              <Typography variant="body2" component="span">
                {t('payments_sign_success_label')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {data.signedCount}/{documentsSigned.length}
              </Typography>
            </div>
          ) : undefined
        }
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={t('letters_sign_error_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

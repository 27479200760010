import React from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CrossIcon, DownloadIcon, InfoIcon } from '@app/common/icons';
import { Tooltip } from '@app/core/components/Tooltip';
import { DATE_FORMAT, formatBIC } from '@app/core/utils';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import { SwiftFormFields, SwiftViewProps } from '@app/pages/swift/types';

export const MobileSwiftView: React.FC<SwiftViewProps> = ({ order, country, onClose, onDownloadClick }) => {
  const { t } = useTranslation();

  return (
    <Grid sx={styles.tabBodyMobile} container wrap="nowrap" direction="column">
      <Grid item container direction="column">
        <Box mt={6}>
          <Grid container justifyContent="space-between">
            <Grid item container xs={11}>
              <Typography sx={styles.header}>
                {t('swiftPayment_documentNumber')} {order.documentNumber}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={onClose}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_documentDate')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.documentDate ? format(new Date(order.documentDate), DATE_FORMAT) : ''}
              variant="outlined"
              name={SwiftFormFields.documentDate}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_valuationDate')}</FormLabel>
          <Box pt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <TextField
                  disabled
                  variant="outlined"
                  defaultValue={order.documentValueDate ? format(new Date(order.documentValueDate), DATE_FORMAT) : ''}
                  name={SwiftFormFields.documentValueDate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} container justifyContent={'flex-end'}>
                <Tooltip color="primary" title={t('swiftPayment_valuationDateTooltip')} arrow placement="bottom">
                  <Box mr={2}>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mb={6}>
          <FormLabel component="legend">{t('swiftPayment_senderAccount')}</FormLabel>
          <Box pt={2}>
            <TextField disabled fullWidth defaultValue={order.sender.account} />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_senderName')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.sender.name}
              variant="outlined"
              name={SwiftFormFields.senderName}
              placeholder={t('swiftPayment_nameFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_senderAddress')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.sender.address}
              variant="outlined"
              name={SwiftFormFields.senderAddress}
              placeholder={t('swiftPayment_addressFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientName')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.recipient.name}
              variant="outlined"
              name={SwiftFormFields.recipientName}
              placeholder={t('swiftPayment_nameFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientAddress')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.recipient.address}
              variant="outlined"
              name={SwiftFormFields.recipientAddress}
              placeholder={t('swiftPayment_addressFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientAccount')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.recipient.account}
              variant="outlined"
              name={SwiftFormFields.recipientAccount}
              placeholder={t('swiftPayment_recipientAccount')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientCountry')}</FormLabel>
          <Box pt={2}>
            {country ? (
              <TextField
                disabled
                defaultValue={country.name}
                variant="outlined"
                name={SwiftFormFields.recipientCountry}
                fullWidth
              />
            ) : null}
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={formatBIC(order.recipient.swiftBic)}
              variant="outlined"
              name={SwiftFormFields.recipientSwiftBic}
              placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.recipient.bankName}
              variant="outlined"
              name={SwiftFormFields.recipientBank}
              placeholder={t('swiftPayment_bankFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_amount')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={parseFloat(`${order.amount}`).toFixed(2)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{order.currency}</InputAdornment>,
              }}
              variant="outlined"
              name={SwiftFormFields.amount}
              placeholder={t('swiftPayment_amountPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={t(`swiftCommission_${order.commissionType}`)}
              variant="outlined"
              name={SwiftFormFields.commissionType}
              placeholder={t('swiftPayment_commissionTypePlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_operationCode')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.operationCode}
              variant="outlined"
              name={SwiftFormFields.operationCode}
              placeholder={t('swiftPayment_operationCodePlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_urgency')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={t(`swiftUrgency_${order.urgency}`)}
              variant="outlined"
              name={SwiftFormFields.urgency}
              placeholder={t('swiftPayment_urgencyPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_purpose')}</FormLabel>
          <Box pt={2}>
            <TextField
              disabled
              defaultValue={order.paymentReference}
              variant="outlined"
              name={SwiftFormFields.paymentReference}
              fullWidth
            />
          </Box>
        </Box>
      </Grid>

      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Box pr={1}>
              <Button variant="contained" color="primary" onClick={onClose} fullWidth>
                {t('close')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box pl={2}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                endIcon={<DownloadIcon fontSize="small" />}
                onClick={onDownloadClick}
              >
                {t('download')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

import React, { useRef, useState } from 'react';

import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import { CrossIcon, DoneIcon, EditIcon } from '@app/common/icons';
import { GetAccountResponseDto } from '@app/core/api';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { UpdateAccountProps } from '@app/core/hooks/useUpdateAccount';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/accounts/components/AccountsList/components/AccountsListRow/styles';
import { BankDetails } from '@app/pages/accounts/components/AccountsList/components/BankDetails';
import { SendDetailsModal } from '@app/pages/accounts/components/AccountsList/components/BankDetails/SendDetails';
import {
  CounterPartyName,
  BalanceInfo,
  DetailsInfoItem,
  FavoriteIcon,
  OverdraftInfo,
} from '@app/pages/accounts/components/AccountsList/primitives';
import { RouteList } from '@app/src/constants/routeList';

export interface AccountsListRowProps {
  account: GetAccountResponseDto;
  onUpdateAccount(props: UpdateAccountProps): void;
}

export const AccountsListRow: React.FC<AccountsListRowProps> = ({ account, onUpdateAccount }) => {
  const {
    id,
    accountType,
    accountNumber,
    accountName,
    openingDate,
    amount,
    currency,
    favorite: originFavorite,
    overdraft,
  } = account.account;
  const lastOperation = account.lastOperation;

  const [favorite, setFavorite] = useState<boolean>(originFavorite);
  const [inputValue, setInputValue] = useState<string>(accountName);
  const [editName, setEdithName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showNewName, setShowNewName] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const refAnhor = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const showEditor = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setError('');
    setEditMode(true);
    setAnchorEl(refAnhor.current);
  };

  const closeEditor = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setError('');
    setEditMode(false);
    setAnchorEl(null);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value as string);
  };

  const handleSaveName = () => {
    if (inputValue.length > 0) {
      onUpdateAccount({ id, name: inputValue });
      setEdithName(inputValue);
      setError('');
      setShowNewName(true);
      setEditMode(false);
      setAnchorEl(null);
    } else {
      setError(t('errorEmptyField'));
    }
  };

  const onClickSaveNameHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    handleSaveName();
  };
  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSaveName();
    }
  };

  const onCheckHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateAccount({ id, favorite: !favorite });
    setFavorite(!favorite);
  };

  const handleShow = () => {
    setShowModal(!showModal);
  };

  const [shake, setShake] = useState<boolean>(false);

  const shakeAnimationTrigger = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  };

  const handleOverlayClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    shakeAnimationTrigger();
  };

  const onCloseSendDetailsModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Grid
        item
        container
        direction="row"
        sx={styles.mainAccountBlock}
        component={Link}
        to={`${RouteList.accounts}/${id}`}
        state={{ accountName }}
      >
        <Grid item alignItems="center" container wrap="nowrap" sx={styles.accountLeft}>
          <Box>
            <IconButton size="small" onClick={onCheckHandler}>
              <FavoriteIcon status={favorite} />
            </IconButton>
          </Box>
          <Box sx={styles.accountLeftInfo}>
            <Popover
              onClose={handleOverlayClick}
              open={editMode}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPopover-paper': {
                  background: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  maxWidth: 330,
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transitionDuration={{
                enter: 200,
                exit: 100,
              }}
            >
              <Grid container alignItems="flex-start" wrap="nowrap">
                <TextField
                  error={error.length > 0}
                  helperText={t(error)}
                  variant="outlined"
                  autoFocus
                  placeholder={t('selectedAccountAccountInfo_changeAccountNamePlaceholder')}
                  sx={{ ...styles.editorInput, ...(shake && styles.shakeAnimate) }}
                  value={inputValue}
                  onChange={handleChange}
                  onKeyPress={onEnterPress}
                />
                <IconButton sx={styles.editorButton} onClick={onClickSaveNameHandler} size="large">
                  <DoneIcon sx={styles.editorIconDone} fontSize="small" />
                </IconButton>
                <IconButton sx={styles.editorButton} onClick={closeEditor} size="large">
                  <CrossIcon sx={styles.editorIconCancel} fontSize="small" />
                </IconButton>
              </Grid>
            </Popover>
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: 'colors.primary600',
                fontWeight: 600,
                minHeight: error ? '50px' : '40px',
              }}
              ref={refAnhor}
            >
              {editMode ? null : (
                <Grid container wrap="nowrap">
                  <Box component="span" mr={1} sx={styles.accountNameText}>
                    <Ellipsis text={<b>{showNewName ? editName : accountName}</b>} />
                  </Box>
                  <IconButton aria-label="edit" size="small" onClick={showEditor}>
                    <EditIcon sx={styles.editIcon} fontSize="medium" />
                  </IconButton>
                </Grid>
              )}
            </Typography>
            <Grid container justifyContent="space-between">
              <DetailsInfoItem description={t('selectedAccountAccountInfo_openingDate')} value={openingDate} />
              {overdraft ? (
                <DetailsInfoItem
                  description={t('selectedAccountAccountInfo_lastMovementDate')}
                  value={lastOperation?.date || ''}
                />
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={1} />
        <Grid item sx={styles.accountCenter}>
          <CounterPartyName text={accountNumber} />
          {lastOperation && !overdraft ? (
            <Grid container justifyContent="space-between">
              <Grid item>
                <DetailsInfoItem
                  description={t('selectedAccountAccountInfo_lastMovementDate')}
                  value={lastOperation?.date || ''}
                />
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <DetailsInfoItem
                  description={t('selectedAccountAccountInfo_lastAmount')}
                  value={formatAmount(lastOperation.amount)}
                  type={lastOperation.type}
                  currency={lastOperation.currency}
                />
              </Grid>
            </Grid>
          ) : null}
          {lastOperation && overdraft ? (
            <Grid container justifyContent="space-between">
              <Grid item>
                <DetailsInfoItem
                  description={t('selectedAccountAccountInfo_lastAmount')}
                  value={formatAmount(lastOperation.amount)}
                  type={lastOperation.type}
                  currency={lastOperation.currency}
                />
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <OverdraftInfo amount={overdraft.availableLimit} currency={currency} uuid={overdraft.uuid} />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid item sx={styles.accountRight} container direction="column" justifyContent="space-between">
          {account.account.showRequisites ? <BankDetails document={account} handleShowModal={handleShow} /> : <Box />}
          <BalanceInfo
            description={t('selectedAccountAccountInfo_remains')}
            balance={formatAmount(amount)}
            currency={currency}
          />
        </Grid>
      </Grid>
      {showModal ? <SendDetailsModal show onClose={onCloseSendDetailsModal} id={id} /> : null}
    </>
  );
};

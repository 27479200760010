import React from 'react';

import { Grid, IconButton, Typography, Box } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { DownChevronSmallIcon, SearchSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { ExchangeRateNbuItemDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { SettingsButton } from '@app/core/components/SettingsButton';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { styles } from '@app/pages/exchangeRate/components/NbuRates/components/NbuCurrencyTable/styles';
import { ExchangesRateColumnEnum } from '@app/pages/exchangeRate/types';

interface TableColumnProps {
  t(text: string): string;
  onSearchButtonClick(): void;
  onSettingsButtonClick(): void;
}

export const makeTableColumns = ({
  t,
  onSearchButtonClick,
  onSettingsButtonClick,
}: TableColumnProps): ColumnDef<ExchangeRateNbuItemDto, any>[] => {
  return [
    {
      accessorKey: ExchangesRateColumnEnum.date,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t('nbuRateTableColumn_data')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<ExchangeRateNbuItemDto, string>) => {
        return formatISODateString(getValue(), DATE_FORMAT);
      },
    },
    {
      accessorKey: ExchangesRateColumnEnum.alpha3,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('nbuRateTableColumn_alpha3')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: ExchangesRateColumnEnum.numeric,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('nbuRateTableColumn_numeric')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: ExchangesRateColumnEnum.currencyName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('nbuRateTableColumn_currencyName')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: ExchangesRateColumnEnum.rate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('nbuRateTableColumn_rate')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ExchangeRateNbuItemDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    // {
    //   id: 'actions',
    //   header: ({ table }) => {
    //     const onSearchButtonClickHandler = () => {
    //       if (table.getRowModel().rows.length) {
    //         onSearchButtonClick();
    //       }
    //     };
    //
    //     return (
    //       <Grid container justifyContent="flex-end">
    //         <IconButton sx={styles.searchIconBtn} onClick={onSearchButtonClickHandler} size="large">
    //           <SearchSmallIcon fontSize="small" />
    //         </IconButton>
    //
    //         <SettingsButton onClick={onSettingsButtonClick} />
    //       </Grid>
    //     );
    //   },
    //   cell: ({ row }) => {
    //     return (
    //       <Grid container justifyContent="flex-end">
    //         <Box
    //           component="span"
    //           {...{
    //             onClick: () => row.toggleExpanded(),
    //           }}
    //           sx={styles.chevron}
    //         >
    //           {row.getIsExpanded() ? (
    //             <UpChevronSmallIcon fontSize="small" />
    //           ) : (
    //             <DownChevronSmallIcon fontSize="small" />
    //           )}
    //         </Box>
    //       </Grid>
    //     );
    //   },
    // },
  ];
};

import {
  statementsApiClient,
  getClientId,
  OperationTypeEnum,
  PaymentOrderTypeEnum,
  AccountStatementConfigDtoColumnTypeEnum,
  AccountStatementUpdateConfigDtoColumnTypeEnum,
} from '@app/core/api';
import { OperationsFromPeriodResult } from '@app/core/components/Statements/types';
import { TableColumnConfig, DocumentStatus, Operation, OperationsTypeEnum } from '@app/core/types';
import { OperationsFilter } from '@app/pages/operations/types';

export const getUAHStatements = async (filterData: OperationsFilter): Promise<OperationsFromPeriodResult> => {
  const operationType = filterData.operationType
    ? (filterData.operationType as unknown as OperationTypeEnum)
    : undefined;

  const documentCategory = filterData.documentCategory?.length ? filterData.documentCategory : undefined;

  const response = await statementsApiClient.operationsControllerGetUahOperations(getClientId(), {
    ...filterData,
    operationType,
    documentCategory,
  });

  const { operationsCount, pagination, totalAmounts, operations: operationsDto } = response.data;

  const operations: Operation[] = operationsDto.map((operationDto) => {
    return {
      ...operationDto,
      status: operationDto.status as unknown as DocumentStatus,
      type: operationDto.type as unknown as OperationsTypeEnum,
      documentType: operationDto.documentType as unknown as PaymentOrderTypeEnum,
    };
  });

  return {
    operations,
    operationsCount,
    pagination,
    totalAmounts,
  };
};

export const operationsControllerUpdateUahOperationsFn = async (
  config: TableColumnConfig<AccountStatementUpdateConfigDtoColumnTypeEnum>[]
): Promise<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]> => {
  const response = await statementsApiClient.operationsControllerUpdateUahOperationsConfig(getClientId(), { config });
  return response.data.config;
};

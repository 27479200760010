import React, { useEffect, useState } from 'react';

import { Box, CardContent, FormControl, FormLabel, Grid, InputAdornment } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';

import { FrontLanguageEnum } from '@app/core/api';
import { CurrencyAutocomplete, FormatInputField, FormatType, InputField, SelectField } from '@app/core/components';
import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { useGetLegalUser } from '@app/core/hooks';
import { useGetCurrencyOperationsAccount } from '@app/core/hooks/useGetCurrencyAccounts';
import { useGetClientCurrencyList } from '@app/core/hooks/useGetFilterCurrencyList';
import { AccountSelectType } from '@app/core/types';
import { ActionFiltersButtons } from '@app/pages/swift/components/ActionFiltersButtons';
import { styles } from '@app/pages/swift/components/SwiftFilter/style';
import { SwiftFilterFieldName } from '@app/pages/swift/components/SwiftTable/types';
import { CommissionTypeSelect } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/components/CommissionTypeSelect';
import { SwiftFormFields } from '@app/pages/swift/types';

interface SwiftFilterProps {
  fullItems: boolean;
  resetFormHandler?(): void;
}

export const SwiftFilter: React.FC<SwiftFilterProps> = ({ fullItems, resetFormHandler }) => {
  const { t, i18n } = useTranslation();
  const [operationAccounts, setOperationAccounts] = useState<AccountSelectType[]>([]);
  const [defaultSelectedAccountsId, setDefaultSelectedAccountsId] = useState<string[]>();
  const handleSelectAccountsLength = (value: []) => value.length > 0 || t('statementsFilter_errorAmountField');
  const { data, mutate } = useGetCurrencyOperationsAccount();

  const { data: legalUsersData, mutate: mutateLegaUser } = useGetLegalUser();
  const { data: clientCurrencyList, mutate: mutateClientCurrency } = useGetClientCurrencyList();

  useEffect(() => {
    mutate({});
    mutateLegaUser({});
    mutateClientCurrency({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n, mutate, mutateClientCurrency, mutateLegaUser]);

  useEffect(() => {
    if (data) {
      const { operationAccounts: operationAccountsId } = parse(location.search);
      setOperationAccounts(data);
      if (operationAccountsId) {
        setDefaultSelectedAccountsId((operationAccountsId as string).split(','));
      }
    }
  }, [data]);

  const legalUsers = legalUsersData?.map((user) => {
    return { id: user.id, name: `${user.lastName} ${user.firstName}`, value: user.id };
  });

  const getCurrencyList = () => {
    const currencyList = clientCurrencyList?.map((currency, index) => {
      return {
        id: index,
        name: currency.alpha3,
        value: currency.alpha3,
      };
    });

    currencyList?.unshift({ id: 99, name: t('all'), value: '' });

    return currencyList;
  };

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={styles.inputWrapper}>
                <FormLabel component="legend">{t('accounts')}</FormLabel>
                <Box pt={2}>
                  <AccountsWithSearchSelectField
                    name={SwiftFilterFieldName.operationAccounts}
                    accounts={operationAccounts}
                    defaultSelectedAccountsId={defaultSelectedAccountsId}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={fullItems ? 6 : 3}>
              <FormLabel component="legend">{t('statementsFilter_dateRange')}</FormLabel>
              <FormControl fullWidth sx={styles.inputWrapper}>
                <Box pt={2} sx={styles.inputWrapper}>
                  <DateRangeSelectField
                    formSelectName={SwiftFilterFieldName.dateRangeSelect}
                    formDateRangeName={SwiftFilterFieldName.dateRange}
                  />
                </Box>
              </FormControl>
            </Grid>
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.currencyAmount}
                      currency=""
                      defaultValue=""
                      name={SwiftFilterFieldName.amountMin}
                      fullWidth
                      placeholder="0.00"
                      sx={styles.fullWidth}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.currencyAmount}
                      currency=""
                      defaultValue=""
                      name={SwiftFilterFieldName.amountMax}
                      fullWidth
                      placeholder="0.00"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">UAH</InputAdornment>,
                      }}
                      sx={styles.fullWidth}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('statementsFilter_paymentReference')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFilterFieldName.paymentReference}
                      placeholder={t('statementsFilter_paymentReference_placeholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('currency')}</FormLabel>
                  <Box pt={2}>
                    <SelectField
                      name={SwiftFilterFieldName.currency}
                      options={getCurrencyList()}
                      fullWidth
                      defaultValue={''}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('Документ створив')}</FormLabel>
                  <Box pt={2}>
                    <SelectField
                      name={SwiftFilterFieldName.createdBy}
                      options={legalUsers}
                      fullWidth
                      defaultValue={''}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('currencyExchange_statementsTableFilter_documentNumber')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFilterFieldName.documentNumber}
                      fullWidth
                      placeholder={t('statementsFilter_documentNumber_placeholder')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_counterPartyAccountNumber')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      name={SwiftFilterFieldName.counterPartyAccountNumber}
                      placeholder={t('statementsFilter_counterPartyAccountNumber_placeholder')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
                  <Box pt={2}>
                    <CommissionTypeSelect
                      name={SwiftFormFields.commissionType}
                      placeholder={t('swiftPayment_commissionTypePlaceholder')}
                    />
                  </Box>
                </Grid>
                <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={6}>
                  <Box pt={2} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

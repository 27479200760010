import React, { useEffect, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CrossIcon, PrintIcon } from '@app/common/icons';
import {
  EmailDocumentTypeEnum,
  FrontLanguageEnum,
  GetOneOperationResponseDto,
  PaymentOrderStatusEnum,
  PaymentOrderTypeEnum,
} from '@app/core/api';
import { ShareDocumentButton, SignPaymentOrder, TextFieldMultiline } from '@app/core/components';
import { useGetCountryCodes } from '@app/core/hooks';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { DATE_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/payment/show/BetweenMyAccount/styles';
import { RouteList } from '@app/src/constants/routeList';

type MobilePaymentViewProps = {
  paymentOrder: GetOneOperationResponseDto;
};

export const MobilePaymentView: React.FC<MobilePaymentViewProps> = ({ paymentOrder }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const { details, amount, documentNumber, paymentReference, link, currency, uuid, documentType, status, valueDate } =
    paymentOrder;
  const senderName = details.senderAccount.name;
  const senderAccountNumber = details.senderAccount.accountNumber;
  const recipientBank = details.recipientAccount.bankName;
  const paymentDocumentDate = details.date;
  const recipientName = paymentOrder.details.recipientAccount.name;
  const recipientLei = paymentOrder.details.recipientAccount.LEI;
  const recipientPassport = paymentOrder.details.recipientAccount.passport;
  const recipientAccountNumber = paymentOrder.details.recipientAccount.accountNumber;
  const recipientNonLeiFlag = paymentOrder.details.recipientAccount.nonLeiFlag;

  const showSignButtonByStatus: boolean =
    status === PaymentOrderStatusEnum.Processing || status === PaymentOrderStatusEnum.Rejected;

  const { mutate: mutateSendToSign, isLoading: isSendToSignLoading } = useSendToSignPaymentOrder();

  const { data: countryData, mutate: mutateCountry } = useGetCountryCodes();

  useEffect(() => {
    mutateCountry({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n.language, mutateCountry]);

  const downloadHandler = () => {
    window.open(`${link}&format=download`, '_blank');
  };

  const onSignClickHandler = () => {
    setShowSignModal(true);
  };

  const onSendToSignClickHandler = () => {
    mutateSendToSign(uuid);
    navigate(-1);
  };

  const onCloseSignModal = () => {
    setShowSignModal(false);
  };

  const onSuccessSignPayment = () => {
    setShowSignModal(false);
  };

  const closeHandler = () => {
    navigate(RouteList.operations);
  };

  const realSenderCountryCode = countryData?.rows.find(
    (country) => country.numeric === paymentOrder.realSenderCountryCode
  );
  const realRecipientCountryCode = countryData?.rows.find(
    (country) => country.numeric === paymentOrder.realRecipientCountryCode
  );

  return (
    <Grid container wrap="nowrap" direction="column" sx={styles.contentMobile}>
      <Typography variant="h6">
        <Grid container>
          <Grid item xs={11}>
            <Typography sx={styles.header}>
              {t(documentType)} № {documentNumber}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" onClick={closeHandler}>
              <CrossIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Typography>
      <Box mb={6} mt={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
        <Box pt={2}>
          <TextField
            variant="outlined"
            defaultValue={paymentDocumentDate ? format(new Date(paymentDocumentDate), DATE_FORMAT) : ''}
            disabled
            fullWidth
          />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccountName')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={senderName} disabled fullWidth />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={senderAccountNumber} disabled fullWidth />
        </Box>
      </Box>
      {paymentOrder.realSenderName ? (
        <>
          <Box mb={6}>
            <FormLabel component="legend">{t('realSenderName')}</FormLabel>
            <Box pt={2}>
              <TextField disabled fullWidth defaultValue={paymentOrder.realSenderName} />
            </Box>
          </Box>
          <Box mb={6}>
            <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
            <Box pt={2}>
              <TextField fullWidth disabled defaultValue={paymentOrder.realSenderTIN} />
            </Box>
          </Box>
          {realSenderCountryCode ? (
            <Box mb={6}>
              <FormLabel component="legend">{t('realSenderNonResidentLabel')}</FormLabel>
              <Box pt={2}>
                <TextField fullWidth disabled defaultValue={realSenderCountryCode.name} />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientName')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={recipientName} disabled fullWidth />
        </Box>
      </Box>
      {recipientNonLeiFlag ? (
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_passport')}</FormLabel>
          <Box pt={2}>
            <TextField variant="outlined" defaultValue={recipientPassport} disabled fullWidth />
          </Box>
        </Box>
      ) : (
        <Box mb={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
          <Box pt={2}>
            <TextField variant="outlined" defaultValue={recipientLei} disabled fullWidth />
          </Box>
        </Box>
      )}
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientAccount')}</FormLabel>
        <Box pt={2} pr={8}>
          <TextField variant="outlined" defaultValue={recipientAccountNumber} disabled fullWidth />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_bankName')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={recipientBank} disabled fullWidth />
        </Box>
      </Box>
      {paymentOrder.realRecipientName ? (
        <>
          <Box mb={6}>
            <FormLabel component="legend">{t('realRecipientName')}</FormLabel>
            <Box pt={2} pr={8}>
              <TextField disabled fullWidth defaultValue={paymentOrder.realRecipientName} />
            </Box>
          </Box>
          <Box mb={6}>
            <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
            <Box pt={2}>
              <TextField fullWidth disabled defaultValue={paymentOrder.realRecipientTIN} />
            </Box>
          </Box>
          {realRecipientCountryCode ? (
            <Box mb={6}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_transactionPresentedLabel')}</FormLabel>
              <Box pt={2}>
                <TextField fullWidth disabled defaultValue={realRecipientCountryCode.name} />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
        <Box pt={2}>
          <TextField
            variant="outlined"
            defaultValue={parseFloat(`${amount}`).toFixed(2)}
            disabled
            fullWidth
            InputProps={{ endAdornment: currency }}
          />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
        <Box pt={2}>
          <TextFieldMultiline
            sx={{
              ...styles.textarea,
              '& textarea': {
                color: 'colors.gray300',
              },
            }}
            variant="outlined"
            multiline
            fullWidth
            rows={2}
            defaultValue={paymentReference}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      <Box mt={6}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
            <Box>
              <a href={link} target="_blank" rel="noreferrer">
                <IconButton size="small">
                  <PrintIcon sx={styles.actionIcon} />
                </IconButton>
              </a>
            </Box>
          ) : null}
          <Box ml={5}>
            <ShareDocumentButton
              link={link}
              styles={{
                color: 'colors.primary500',
              }}
              type={EmailDocumentTypeEnum.PaymentOrder}
              uuid={uuid}
            />
          </Box>
        </Grid>
      </Box>
      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
            <Box pr={4} width="40%">
              <Button fullWidth variant="outlined" color="primary" onClick={downloadHandler}>
                {t('download')}
              </Button>
            </Box>
          ) : null}
          {paymentOrder.actions.allowToSendToSign && !showSignButtonByStatus ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSendToSignClickHandler}>
                {t('paymentCreationFormControl_sendToSign')}
              </Button>
            </Box>
          ) : null}
          {paymentOrder.actions.allowToSign && !showSignButtonByStatus ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSignClickHandler}>
                {t('paymentCreationFormControl_send')}
              </Button>
            </Box>
          ) : null}
          {showSignModal ? (
            <SignPaymentOrder
              uuid={uuid}
              documentToSign={JSON.stringify(paymentOrder)}
              onSuccessPopupCancel={onSuccessSignPayment}
              onClose={onCloseSignModal}
            />
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

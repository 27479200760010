import React, { useEffect, useState } from 'react';

import { Box, IconButton, OutlinedInput, Toolbar, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { useRifm } from 'rifm';

import { LeftChevronIcon, RightChevronIcon } from '@app/common/icons';
import { styles } from '@app/core/components/ComplexTable/components/TablePagination/TablePageInput/styles';

import { RowsPerPage } from './RowsPerPage';

export interface LocalTablePaginationProps<T> {
  table: Table<T>;
  show?: boolean;
  rowsPerPageOptions: number[];
  defaultRowsPerPage: number;
  labelRowsPerPage: React.ReactNode;
}

export const LocalTablePagination = <T,>({
  table,
  rowsPerPageOptions,
  show = true,
  defaultRowsPerPage,
  labelRowsPerPage,
}: LocalTablePaginationProps<T>) => {
  const { pagination } = table.getState();
  const [page, setPage] = useState<string>(`${pagination.pageIndex + 1}`);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setSelectedRowsPerPage(rows);
    table.setPageSize(rows);
  };

  const numberFormat = (str: string): string => {
    const parsedStr = str.slice(0, 10).replace(/[^\d]+/gi, '');
    if (parsedStr.length) {
      const number = parseInt(parsedStr, 10);
      if (number > table.getPageCount()) {
        return `${table.getPageCount()}`;
      }
      if (number < 1) {
        return '';
      }
      return `${number}`;
    }
    return '';
  };

  const onInputChangeHandler = (newPageNumber: string) => {
    setPage(newPageNumber);
  };

  const rifm = useRifm({
    value: page,
    onChange: onInputChangeHandler,
    format: numberFormat,
  });

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      e.stopPropagation();
      if (page.length) {
        const newPage = page ? parseInt(page, 10) - 1 : 0;
        table.setPageIndex(newPage);
      }
    }
  };

  const onBlurInputHandler = () => {
    if (page.length) {
      const newPage = page ? parseInt(page, 10) - 1 : 0;
      table.setPageIndex(newPage);
    }
  };

  useEffect(() => {
    setPage(`${pagination.pageIndex + 1}`);
  }, [pagination.pageIndex]);

  return (
    <>
      {show ? (
        <Toolbar
          disableGutters
          sx={{
            width: '100%',
            justifyItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <RowsPerPage
            selectedRowsPerPage={selectedRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onOptionSelect={handleChangeRowsPerPage}
          />
          <Box sx={styles.root}>
            <IconButton
              size="small"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              aria-label="previous page"
              sx={styles.navigateButton}
            >
              <LeftChevronIcon />
            </IconButton>
            <IconButton size="small" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
              <Typography
                variant="body1"
                component="span"
                sx={!table.getCanPreviousPage() ? styles.pageLabelInactive : styles.pageLabel}
              >
                1
              </Typography>
            </IconButton>

            <OutlinedInput
              onKeyPress={onKeyPressHandler}
              value={rifm.value}
              onChange={rifm.onChange}
              onBlur={onBlurInputHandler}
              margin="dense"
              sx={styles.pageInput}
            />
            <IconButton
              size="small"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <Typography
                variant="body1"
                component="span"
                sx={!table.getCanNextPage() ? styles.pageLabelInactive : styles.pageLabel}
              >
                {table.getPageCount() > 0 ? table.getPageCount() : ''}
              </Typography>
            </IconButton>

            <IconButton
              size="small"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              aria-label="last page"
              sx={styles.navigateButton}
            >
              <RightChevronIcon />
            </IconButton>
          </Box>
        </Toolbar>
      ) : null}
    </>
  );
};

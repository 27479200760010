export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  pageLabel: {
    fontWeight: 400,
    color: 'colors.gray200',
  },
  pageLabelInactive: {
    fontWeight: 400,
    color: 'colors.gray100',
  },
  pageNumberLabel: {
    userSelect: 'none',
    color: 'colors.primary600',
    fontWeight: 600,
  },
  pageInput: {
    width: '36px',
    height: '32px',
    padding: 0,
    marginLeft: '16px',
    marginRight: '16px',
    '& input': {
      paddingRight: 0,
      paddingLeft: 0,
      fontWeight: 600,
      color: 'colors.primary600',
      textAlign: 'center',
    },
  },
  navigateButton: {
    color: 'colors.gray200',
    '&[disabled]': {
      color: 'colors.gray100',
    },
  },
};

import React from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { OperationsFilter } from '../../../../types';

type MenuItemProps = {
  value: OperationsFilter;
  text: React.ReactNode;
  disabled: boolean;
  isSelected: boolean;
  divide: boolean;
  onClick(id: OperationsFilter): void;
};

export const OperationsMenuItem: React.FC<MenuItemProps> = ({ value, text, disabled, isSelected, divide, onClick }) => {
  const onClickHandler = () => {
    if (!disabled) {
      onClick(value);
    }
  };

  return (
    <>
      <Box sx={styles.itemWrapper}>
        <Typography
          sx={{ ...styles.itemText, ...(isSelected && styles.selected), ...(disabled && styles.disabled) }}
          variant={isSelected ? 'body2' : 'caption'}
          component="span"
          onClick={onClickHandler}
        >
          {text}
        </Typography>
      </Box>
      {divide ? <Box sx={styles.divider} /> : null}
    </>
  );
};

import { useQuery } from 'react-query';
import { QueryObserverOptions } from 'react-query/types/core/types';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, swiftApiClient, ApiError, GetOneSwiftOperationResponseDto } from '@app/core/api';

const getSwiftByUuidFn = async (uuid: string): Promise<GetOneSwiftOperationResponseDto> => {
  const result = await swiftApiClient.swiftControllerGetOneOrder(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the swift payment order");
  }
  return result.data;
};

export const useGetSwiftByUuid = (
  uuid: string,
  options?: QueryObserverOptions<GetOneSwiftOperationResponseDto, ApiError>
): UseQueryResult<GetOneSwiftOperationResponseDto, ApiError> => {
  return useQuery<GetOneSwiftOperationResponseDto, ApiError>('swiftPayment_getOneOrder', () => getSwiftByUuidFn(uuid), {
    enabled: false,
    ...options,
  });
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, depositsApiClient, getClientId, GetListResponseDto, PaginationRequestDto } from '@app/core/api';

interface TranchesRequestProps {
  uuid: string;
  pagination: PaginationRequestDto;
}

const getDepositTranchesQueryFn = async ({ uuid, pagination }: TranchesRequestProps): Promise<GetListResponseDto> => {
  const response = await depositsApiClient.depositsControllerGetTranchesList(getClientId(), {
    depositLineId: uuid,
    pagination,
  });
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error cant get tranches!');
  }
  return response.data;
};

export const useGetDepositTranches = (): UseMutationResult<GetListResponseDto, ApiError, TranchesRequestProps> => {
  return useMutation<GetListResponseDto, ApiError, TranchesRequestProps>(getDepositTranchesQueryFn);
};

import React, { isValidElement, PropsWithChildren } from 'react';

import { Box, Grid, Theme, SxProps } from '@mui/material';

import { getIconBySeverity } from '@app/common/icons/utils';

import { Severity, SvgFontSize } from '../../types';

type StatusTextProps = {
  absolute?: boolean;
  bottomIndent?: number;
  severity?: Severity;
  fontSizes?: SvgFontSize;
  sx?: SxProps<Theme>;
};

export const StatusText: React.FC<PropsWithChildren<StatusTextProps>> = ({
  bottomIndent,
  children,
  absolute = false,
  severity,
  fontSizes,
  sx,
}): JSX.Element | null => {
  return isValidElement(children) || typeof children === 'string' ? (
    <Grid
      container
      flexWrap="nowrap"
      sx={[
        {
          width: 'auto',
          paddingTop: '4px',
          paddingBottom: bottomIndent ? `${bottomIndent}px` : 0,
          color: 'colors.gray600',
          fontSize: '12px',
          position: absolute ? 'absolute' : 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box mr={1}>{getIconBySeverity(severity || 'error', fontSizes || 'small')}</Box>
      {children}
    </Grid>
  ) : null;
};

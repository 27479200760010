import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Disable2faParamsDtoLanguageEnum } from '@app/core/api';
import { useGetDisable2faCode, useGetUserData } from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { ActivationsModal } from '@app/pages/profile/desktop/components/Security/components/ActivationsModal';
import { DeactivationModal } from '@app/pages/profile/desktop/components/Security/components/DeactivationModat';
import { styles } from '@app/pages/profile/desktop/components/Security/style';

export const Security: React.FC = () => {
  const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const { data, mutate: mutateUserData } = useGetUserData();

  const { mutate: mutateGetDisableCode } = useGetDisable2faCode();

  useEffect(() => {
    mutateUserData({});
  }, [mutateUserData]);

  const enabled2Fa = data ? data.twoFactorReady.length > 1 : false;

  const onActivateClickHandler = () => {
    setShowActivationModal(true);
  };

  const onDeactivateClickHandler = () => {
    mutateGetDisableCode(
      { language: i18n.language as unknown as Disable2faParamsDtoLanguageEnum },
      {
        onSuccess: () => {
          mutateUserData({});
        },
      }
    );
    setShowDeactivationModal(true);
  };

  const onCloseActivationsModal = () => {
    setShowActivationModal(false);
  };

  const onActivationSuccess = () => {
    setShowActivationModal(false);
    mutateUserData({});
  };

  const onCloseDeactivationModal = () => {
    setShowDeactivationModal(false);
  };

  const onDeactivationSuccess = () => {
    setShowDeactivationModal(false);
    mutateUserData({});
  };

  return (
    <>
      {data ? (
        <>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Typography sx={styles.gaTitle}>Google Authenticator – </Typography>
            <Box pl={2}>
              {enabled2Fa ? (
                <Typography sx={styles.statusEnabled}>{t('gaStatusEnabled')}</Typography>
              ) : (
                <Typography sx={styles.statusDisabled}>{t('gaStatusDisabled')}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Box mt={5}>
              {enabled2Fa ? (
                <Grid container direction="column">
                  <Typography variant="body2">{t('gaDisableDescriptionTitle')}</Typography>
                  <Typography variant="body2">{t('enterToSystem')}</Typography>
                  <Typography variant="body2">{t('gaDisableDescription')}</Typography>
                </Grid>
              ) : (
                <Typography variant="body2">{t('gaDescription')}</Typography>
              )}
            </Box>
          </Grid>
          <Grid>
            <Box mt={5}>
              {enabled2Fa ? (
                <Button variant="outlined" color="primary" onClick={onDeactivateClickHandler}>
                  {t('deactivate')}
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={onActivateClickHandler}>
                  {t('activate')}
                </Button>
              )}
            </Box>
          </Grid>
        </>
      ) : null}
      {showActivationModal ? (
        <ActivationsModal onClose={onCloseActivationsModal} onActivationSuccess={onActivationSuccess} />
      ) : null}
      {showDeactivationModal ? (
        <DeactivationModal onClose={onCloseDeactivationModal} onDeactivationSuccess={onDeactivationSuccess} />
      ) : null}
    </>
  );
};

import React, { PropsWithChildren } from 'react';

import { Box, Grid, IconButton, Typography, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { BurgerIcon, CrossIcon, InfoIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import { Action, NavigateToButton, SystemDate, Tooltip } from '@app/core/components';
import { isMobile, cropName } from '@app/core/utils';

interface HeaderProps {
  title: string;
  showNavigateButton?: boolean;
  confirmQuestionText?: string;
  backLink?: number;
  styles?: {
    title?: SxProps<Theme>;
    burgerButton?: SxProps<Theme>;
    closeButton?: SxProps<Theme>;
  };
  showLeavePopup?: Action | boolean;
  onClick?(): void;
  tooltip?: string;
  useDesktopStyle?: boolean; // temporary param for pages without mobile layout
}

export const PageHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
  children,
  title,
  showLeavePopup,
  showNavigateButton,
  confirmQuestionText,
  styles,
  backLink,
  onClick,
  tooltip,
  useDesktopStyle = false,
}) => {
  const { sidebarOpen, setSidebarOpen } = useUIState();
  const handleMenuClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const { t } = useTranslation();

  const isMobileStyle = useDesktopStyle ? false : isMobile;
  const shortTitle = `${cropName(title, 50)}`;
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        width: '100%',
        height: '80px',
        padding: isMobileStyle ? '0 16px' : undefined,
      }}
    >
      <Grid
        container
        alignContent="center"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        {isMobile && sidebarOpen ? (
          <Grid item>
            <IconButton
              size="small"
              onClick={handleMenuClick}
              sx={{
                color: isMobileStyle ? '#FFFFFF' : undefined,
                ...styles?.closeButton,
              }}
            >
              <CrossIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item>
          <Grid container>
            {showNavigateButton ? (
              <Box mr={3}>
                <NavigateToButton
                  delta={backLink}
                  showLeavePopup={showLeavePopup}
                  confirmQuestionText={confirmQuestionText}
                  onClick={onClick}
                />
              </Box>
            ) : null}
            <Grid item>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography
                    title={title}
                    variant="h5"
                    sx={{
                      color: isMobileStyle ? '#FFFFFF' : 'colors.gray800',
                      ...styles?.title,
                    }}
                  >
                    {shortTitle}
                  </Typography>
                </Grid>
                {tooltip ? (
                  <Grid item>
                    <Box pl={3}>
                      <Tooltip color="primary" title={tooltip} arrow placement="top">
                        <div>
                          <InfoIcon
                            fontSize="small"
                            sx={{
                              color: 'colors.primary500',
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
              <Typography variant="body2" component="span" color="textSecondary">
                {t('Date')}: <SystemDate />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isMobile ? (
            <IconButton
              size="small"
              onClick={handleMenuClick}
              sx={{
                color: isMobileStyle ? '#FFFFFF' : undefined,
                ...styles?.burgerButton,
              }}
            >
              <BurgerIcon />
            </IconButton>
          ) : (
            children
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { getAccessToken } from '@app/core/api';

export const getTokenExpTime = (): Date => {
  const token = getAccessToken();
  if (token.length) {
    const tokenPayload = token.split('.')[1];
    const parsedTokenPayload = JSON.parse(atob(tokenPayload));
    const expiryTimestamp = parsedTokenPayload.exp;
    return new Date(expiryTimestamp * 1000);
  }

  return new Date();
};

export const getLastChanceTime = (): Date => {
  const time = getTokenExpTime();
  time.setMinutes(time.getMinutes() - 1);
  return time;
};

import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { PageContent } from '@app/src/pages/exchangeRate/PageContent';

export const ExchangeRatePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('exchangeRate')} showNavigateButton backLink={-1} useDesktopStyle />
        <PageContainer>
          <PageContent />
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

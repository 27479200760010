import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetAccountResponseDto } from '@app/core/api';
import { OperationsTable } from '@app/core/components/OperationsTable';
import { useScreenOrientation } from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { AccountsSelect } from '@app/pages/dashboard/view/PageContent/AccountsSelect';
import { OperationsAccountsSelectField } from '@app/pages/dashboard/view/PageContent/types';

export const Operations: React.FC = (): JSX.Element => {
  const { isPortrait } = useScreenOrientation();
  const methods = useForm();
  const { control } = methods;
  const { t } = useTranslation();

  const accounts: GetAccountResponseDto[] = useWatch({ control, name: OperationsAccountsSelectField.accountSelect });

  const isMobilePortrait = isMobile && isPortrait;
  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box pb={2}>
        <Grid
          container
          alignItems={isMobilePortrait ? 'flex-start' : 'center'}
          direction={isMobilePortrait ? 'column' : 'row'}
        >
          <Box mr={2} pb={1}>
            <Typography variant="h6">{t('dashboard_lastOperations')}</Typography>
          </Box>
          <Grid item xs={isMobile ? false : 3} container={isMobilePortrait}>
            <FormProvider {...methods}>
              <AccountsSelect />
            </FormProvider>
          </Grid>
        </Grid>
      </Box>

      <Grid container direction="column">
        <OperationsTable accounts={accounts} />
      </Grid>
    </Grid>
  );
};

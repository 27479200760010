export enum SelectedAccountsTabEnum {
  operations = 'operations',
  turnovers = 'turnovers',
  analytics = 'analytics',
}
export type DataPeriodType = {
  from: Date;
  to: Date;
  temp_from: string;
  temp_to: string;
};

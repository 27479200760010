import React from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { LettersListContent } from '@app/pages/letters/LettersListContent';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const LettersPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('letters')} useDesktopStyle>
          {permissions.messages.create ? (
            <Box mr={2}>
              <Link to={RouteList.letters_create}>
                <Button variant="contained" color="primary">
                  {t('letter_create_new')}
                </Button>
              </Link>
            </Box>
          ) : null}
        </PageHeader>
        <PageContainer>
          <LettersListContent />
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

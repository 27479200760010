import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { EmailDocumentTypeEnum, SwiftOperationWithAvailableButtons } from '@app/core/api';
import { DetailsInfoItem } from '@app/core/components/ComplexTable/primitives';
import { LoadOperationButton } from '@app/core/components/OperationDetails/Actions/LoadOperationButton';
import { PrintButton } from '@app/core/components/OperationDetails/Actions/PrintButton';
import { ShareDocumentButton } from '@app/core/components/ShareDocumentButton';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { styles } from '@app/pages/swift/components/SwiftTable/components/OperationDetails/styles';

export interface OperationDetailsProps {
  operation: SwiftOperationWithAvailableButtons;
}

export const OperationDetails: React.FC<OperationDetailsProps> = ({ operation }) => {
  const { t } = useTranslation();

  const { documentDate, documentNumber, amount, currency, sender, recipient, uuid, link } = operation;

  return (
    <Grid container alignItems="center" sx={styles.root}>
      <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('paymentCreationLocalPayment_paymentOrder')} value={documentNumber} />
        </Grid>
        <Grid item xs={2}>
          <DetailsInfoItem
            description={t('paymentCreationLocalPayment_documentDate')}
            value={formatISODateString(documentDate, DATE_FORMAT)}
          />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem
            description={t('operationDetailShare_spend')}
            value={`${formatISODateString(documentDate, DATE_FORMAT)}`}
          />
        </Grid>
        <Grid item container xs={3} justifyContent="flex-end">
          <>
            <ShareDocumentButton
              styles={{
                marginLeft: '16px',
                color: 'colors.primary700',
              }}
              link={link}
              type={EmailDocumentTypeEnum.PaymentOrder}
              uuid={uuid}
            />
            <PrintButton
              styles={{
                paddingTop: '3px',
                marginLeft: '16px',
                color: 'colors.primary700',
              }}
              link=""
            />
          </>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_senderAccountName')} value={sender.account} />
        </Grid>
        <Grid item xs={2}>
          <DetailsInfoItem description={t('operationDetail_lei')} value="sdkfmsadasd" />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_senderBank')} value="adkasdkasd" />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_account')} value="asdnaskjnd" />
        </Grid>
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between" sx={styles.row}>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_recipient')} value="askdmasd" />
        </Grid>
        <Grid item xs={2}>
          <DetailsInfoItem description={t('operationDetail_recipientLei')} value="alsdmasldm" />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_recipientBank')} value={recipient.bankName} />
        </Grid>
        <Grid item xs={3}>
          <DetailsInfoItem description={t('operationDetail_account')} value={recipient.account} />
        </Grid>
      </Grid>
      <Grid item container alignItems="center" wrap="nowrap" justifyContent="space-between" sx={styles.row}>
        <DetailsInfoItem description={t('paymentCreationLocalPayment_purpose')} value="asdasdasdasdasdasdasdasd" />
        <LoadOperationButton link="" />
      </Grid>
    </Grid>
  );
};

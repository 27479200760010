export const styles = {
  cell: {
    height: '40px',
    flex: '1 auto',
    minWidth: '40px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  cell_header: {
    color: 'colors.gray700',
  },
  cell_body: {},
  cell_paymentDate: {
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_principalAmount: {
    '&.body': {
      fontWeight: 600,
      color: 'colors.primary700',
    },
  },
  cell_principalOverdue: {
    '&.body': {
      fontWeight: 600,
      color: 'colors.error',
    },
  },
  cell_interestRepaymentDate: {
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_interestAmount: {
    '&.body': {
      color: 'colors.primary600',
    },
  },
  cell_interestOverdue: {
    '&.body': {
      color: 'colors.error',
    },
  },
  tableRow: {
    backgroundColor: 'colors.containerBackgroundColor',
    padding: '10px 16px',
    borderRadius: '8px',
    marginBottom: '4px',
    zIndex: 3,
    cursor: 'pointer',
  },
  tableCell: {
    borderBottom: 'none!important',
    flex: '1',
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '21px',
    borderColor: 'colors.gray50',
    margin: '0 12px',
  },
  navigationBtn: {
    color: 'colors.primary600',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navigationBtnIcon: {
    fontSize: '10px',
    color: 'colors.primary600',
    marginLeft: '6px',
  },
};

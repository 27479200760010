import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CurrencyOperationsExchangeTypeEnum,
  CurrencyOperationStatusEnum,
  DeleteOperationsResponseDto,
  GetCurrencyOperationDto,
  ResultStatusEnum,
} from '@app/core/api';
import { styles } from '@app/core/components/DeleteDocumentDialog/style';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { useDeleteCurrencyOperation } from '@app/core/hooks/useDeleteCurrencyOperation';
import { formatAmount } from '@app/core/utils';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

interface DeleteExchangeDocumentDialogProps {
  selectedDocuments: GetCurrencyOperationDto[];
  onSubmit(): void;
  onCancelDeleteDocument(): void;
  onDeleteDocumentSuccess(): void;
}

export const DeleteExchangeDocumentDialog: React.FC<DeleteExchangeDocumentDialogProps> = ({
  selectedDocuments,
  onSubmit,
  onCancelDeleteDocument,
  onDeleteDocumentSuccess,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();

  const docsForDelete = selectedDocuments.filter(
    (item) => item.status === CurrencyOperationStatusEnum.Saved || item.status === CurrencyOperationStatusEnum.ToSign
  );

  const docsForSignCount: number = docsForDelete.length;

  const uuids = docsForDelete.map((document) => {
    return document.uuid;
  });

  const { showLoader } = useUIState();
  const { mutate } = useDeleteCurrencyOperation();

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate(uuids, {
      onSuccess: (result: DeleteOperationsResponseDto) => {
        showLoader(false);
        if (result.status === ResultStatusEnum.Successfully) {
          onDeleteDocumentSuccess();
          notify({
            notifyProps: {
              title: t('success'),
              message: t('deleteDocumentSuccessMessage'),
              severity: 'success',
            },
          });
        } else {
          notify({
            notifyProps: {
              title: t('server_error'),
              message: t('deleteDocumentErrorMessage'),
              severity: 'error',
            },
          });
        }
        onSubmit();
      },
      onError: () => {
        showLoader(false);
        notify({
          notifyProps: {
            title: t('server_error'),
            message: t('deleteDocumentErrorMessage'),
            severity: 'error',
          },
        });
        onSubmit();
      },
    });
  };

  const currencyOperationGroupByExchangeType: {
    [key in CurrencyOperationsExchangeTypeEnum]?: GetCurrencyOperationDto[];
  } = groupBy<GetCurrencyOperationDto>(docsForDelete, 'currencyExchangeType');

  const currencyOperationConversionGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.CONVERSION,
      (operation) => operation.depositAccount.currency
    );

  const currencyOperationSaleGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.SALE,
      (operation) => operation.withdrawalAccount.currency
    );

  const currencyOperationPurchaseGroupByCurrency: { [key: string]: GetCurrencyOperationDto[] } =
    groupBy<GetCurrencyOperationDto>(
      currencyOperationGroupByExchangeType.PURCHASE,
      (operation) => operation.depositAccount.currency
    );

  return (
    <ModalCommon onClose={onCancelDeleteDocument} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDeleteDocument}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('areYouSureYouWantToDelete')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('deleteDocumentCount')}
                {' / '} {t('deleteDocument_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedDocuments.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForDeleteCount}>
                {docsForSignCount}
              </Typography>
            </Grid>
          </Grid>
          {currencyOperationGroupByExchangeType.CONVERSION
            ? Object.entries(currencyOperationConversionGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_conversion_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_conversion')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {currencyOperationGroupByExchangeType.PURCHASE
            ? Object.entries(currencyOperationPurchaseGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_purchase_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_purchase')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {currencyOperationGroupByExchangeType.SALE
            ? Object.entries(currencyOperationSaleGroupByCurrency).map(([currency, operations]) => {
                const amount = operations.reduce((acc, document) => {
                  return acc + document.amount;
                }, 0);
                return (
                  <Grid
                    key={`createExchangeMenu_sale_${currency}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.itemRow}
                  >
                    <Grid item>
                      <Typography variant="body2" sx={styles.text}>
                        {t('createExchangeMenu_sale')}
                        {` ${currency} `}
                        {`(${operations.length}${t('document_short')})`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {formatAmount(amount)} {currency}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDeleteDocument}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmDeleteDocument}>
            {t('yes')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

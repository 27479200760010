import React from 'react';

import { Radio, styled } from '@mui/material';
import { RadioProps } from '@mui/material/Radio/Radio';

import { ReactComponent as RadioActiveIcon } from '@app/themes/default/assets/controls/24x24/radio/radio-active.svg';
import { ReactComponent as RadioInactiveIcon } from '@app/themes/default/assets/controls/24x24/radio/radio-inactive.svg';

const RadioInactive = styled(RadioInactiveIcon)({
  fill: 'currentColor',
});

const RadioActive = styled(RadioActiveIcon)({
  fill: 'currentColor',
});

export const RadioButton: React.FC<RadioProps> = (props) => {
  return <Radio icon={<RadioInactive />} checkedIcon={<RadioActive />} {...props} />;
};

import {
  getClientId,
  templatesApiClient,
  ApiError,
  CreateTemplateRequestDto,
  DocumentTemplateDto,
} from '@app/core/api';

export const savePaymentTemplateFn = async (
  createTemplateRequestDto: CreateTemplateRequestDto
): Promise<DocumentTemplateDto> => {
  const response = await templatesApiClient.paymentTemplateControllerCreateTemplate(getClientId(), {
    ...createTemplateRequestDto,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Save template error!');
  }

  return response.data;
};

export const styles = {
  placeholder: {
    color: 'colors.gray300',
  },
  dropContainer: {
    width: '100%',
    height: '100px',
    border: `1.5px dashed`,
    borderColor: 'colors.primary200',
    borderRadius: '8px',
    padding: '20px',
  },
  dropContainerActive: {
    backgroundColor: 'colors.primary25',
  },
  dropContainerError: {
    backgroundColor: '#FFF7F9',
    borderColor: 'colors.error',
  },
  errorColorText: {
    color: 'colors.error',
  },
  fontWeight: {
    fontWeight: 600,
  },
  label: {
    textDecoration: 'underline',
  },
  mainText: {
    color: 'colors.gray500',
    textAlign: 'center',
  },
  secondText: {
    color: 'colors.gray300',
  },
  infoText: {
    color: 'colors.gray500',
    '& > p': {
      marginBottom: '4px',
    },
  },
  downloadTemplate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'colors.primary500',
    '& span': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  info: {
    marginRight: '16px',
    color: 'colors.primary500',
  },
  infoLink: {
    color: 'colors.primary500',
  },
};

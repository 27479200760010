import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  configurationApiClient,
  getClientId,
  StatementConfigDto,
  ApiError,
  GetStatementConfigResponseDto,
} from '@app/core/api';

export const useCreateStatementsConfig = (): UseMutationResult<
  GetStatementConfigResponseDto,
  ApiError,
  StatementConfigDto
> => {
  return useMutation(async (statementConfigDto: StatementConfigDto) => {
    const result = await configurationApiClient.clientConfigControllerCreateStatementsConfig(
      getClientId(),
      statementConfigDto
    );

    if (result.status !== 200) {
      throw new ApiError(result.request.status, "Can't create statements config.");
    }

    return result.data;
  });
};

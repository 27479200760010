import React from 'react';

import { Checkbox as MuiCheckbox } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';

import { ReactComponent as CheckboxDisabledIcon } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-checked-disabled.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-checked.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-indeterminate.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-unchecked.svg';

export const Checkbox: React.FC<CheckboxProps> = (props): JSX.Element => {
  const { disabled } = props;
  return (
    <MuiCheckbox
      icon={<CheckboxUncheckedIcon />}
      indeterminateIcon={<CheckboxIndeterminateIcon />}
      checkedIcon={disabled ? <CheckboxDisabledIcon /> : <CheckboxCheckedIcon />}
      color="primary"
      {...props}
    />
  );
};

import React, { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetCountDocumentResultDto } from '@app/core/api';
import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/arrowDownFilled.svg';

import { OperationsMenuItem } from './OperationsMenuItem';
import { styles } from './styles';
import { OperationsFilter } from '../../../../types';
import { isMobile } from '../../../../utils';
import { SelectField } from '../../../Form';
import { SelectOptionType } from '../../../Select';

export interface FilterPanelProps {
  onFilterChange(filter: OperationsFilter): void;
  countData?: GetCountDocumentResultDto;
}

type NavigationItem = {
  id: number;
  value: OperationsFilter;
  text: React.ReactNode;
  divide: boolean;
};

export const FilterPanel: React.FC<FilterPanelProps> = ({ onFilterChange, countData }) => {
  const [selected, setSelected] = useState<OperationsFilter>(OperationsFilter.type_all);
  const { t } = useTranslation();
  const toSign = countData?.toSign;
  const onSigning = countData?.onSigning;
  const rejected = countData?.rejected;
  const processing = countData?.processing;
  const saved = countData?.saved;
  const methods = useForm({ mode: 'onChange' });
  const { setValue } = methods;

  const operationTypeMenuItemsMobile: SelectOptionType[] = [
    {
      id: 1,
      value: OperationsFilter.type_all,
      name: t('all'),
    },
    {
      id: 2,
      value: OperationsFilter.type_incoming,
      name: t('incoming'),
    },
    {
      id: 3,
      value: OperationsFilter.type_outgoing,
      name: t('outgoing'),
    },
  ];

  const menuItemsDesktop: NavigationItem[] = [
    {
      id: 1,
      value: OperationsFilter.type_all,
      text: t('all'),
      divide: false,
    },
    {
      id: 2,
      value: OperationsFilter.type_incoming,
      text: t('incoming'),
      divide: false,
    },
    {
      id: 3,
      value: OperationsFilter.type_outgoing,
      text: t('outgoing'),
      divide: true,
    },
    {
      id: 4,
      value: OperationsFilter.status_toSign,
      text: `${t('toSign')} (${toSign || 0})`,
      divide: false,
    },
    {
      id: 6,
      value: OperationsFilter.status_saved,
      text: `${t('documentStatus_saved')} (${saved || 0})`,
      divide: false,
    },
    {
      id: 7,
      value: OperationsFilter.status_processing,
      text: `${t('documentStatus_processing')} (${processing || 0})`,
      divide: false,
    },
    {
      id: 8,
      value: OperationsFilter.status_rejected,
      text: `${t('rejected')} (${rejected || 0})`,
      divide: false,
    },
  ];

  const documentsMenuItemsMobile: NavigationItem[] = [
    {
      id: 1,
      value: OperationsFilter.status_toSign,
      text: `${t('toSign')} (${toSign || 0})`,
      divide: false,
    },
    {
      id: 3,
      value: OperationsFilter.status_saved,
      text: `${t('documentStatus_saved')} (${saved || 0})`,
      divide: false,
    },
    {
      id: 4,
      value: OperationsFilter.status_processing,
      text: `${t('documentStatus_processing')} (${processing || 0})`,
      divide: false,
    },
    {
      id: 5,
      value: OperationsFilter.status_rejected,
      text: `${t('rejected')} (${rejected || 0})`,
      divide: false,
    },
  ];

  if (onSigning && onSigning > 0) {
    const item: NavigationItem = {
      id: isMobile ? 2 : 5,
      value: OperationsFilter.status_onSigning,
      text: `${t('documentChangeAction_OnSigning')} (${onSigning || 0})`,
      divide: false,
    };

    menuItemsDesktop.push(item);
    documentsMenuItemsMobile.push(item);
  }

  const menuItems: NavigationItem[] = (isMobile ? documentsMenuItemsMobile : menuItemsDesktop).sort(
    (a: NavigationItem, b: NavigationItem) => a.id - b.id
  );
  const handleMenuItemClick = (currentFilter: OperationsFilter) => {
    setSelected(currentFilter);
    onFilterChange(currentFilter);
    if (isMobile) {
      setValue('operationsType', null);
    }
  };

  const onChangeHandler = (event: any) => {
    setSelected(event.target.value);
    onFilterChange(event.target.value);
    setValue('operationsType', event.target.value);
  };

  const zeroPadding =
    selected !== OperationsFilter.type_all || OperationsFilter.type_incoming || OperationsFilter.type_outgoing;

  return (
    <>
      {isMobile ? (
        <Grid container>
          <Grid item>
            <Box sx={styles.root}>
              <FormProvider {...methods}>
                <form role="presentation">
                  <SelectField
                    sx={{ ...styles.select, ...styles.outlined, ...(zeroPadding && { paddingRight: '0!important' }) }}
                    name="operationsType"
                    options={operationTypeMenuItemsMobile}
                    onChange={onChangeHandler}
                    defaultValue={operationTypeMenuItemsMobile[0].value}
                    IconComponent={ChevronDownIconSvg}
                  />
                </form>
              </FormProvider>
              <Box mr={2} />
              <Box sx={styles.divider} />
              {menuItems.map((item) => (
                <OperationsMenuItem
                  key={item.value}
                  value={item.value}
                  text={item.text}
                  disabled={false}
                  isSelected={selected === item.value}
                  divide={item.divide}
                  onClick={handleMenuItemClick}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={styles.root}>
          {menuItems.map((item) => (
            <OperationsMenuItem
              key={item.value}
              value={item.value}
              text={item.text}
              disabled={false}
              isSelected={selected === item.value}
              divide={item.divide}
              onClick={handleMenuItemClick}
            />
          ))}
        </Box>
      )}
    </>
  );
};

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Checkbox } from '@app/core/components/Checkbox';
import { AccountNumberItem, PaymentReference } from '@app/core/components/ComplexTable/primitives';
import { IBanText } from '@app/core/components/IBanText';
import { Operation, OperationsTypeEnum } from '@app/core/types';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/statements/mobile/components/OperationCard/styles';

export interface OperationCardProps {
  operation: Operation;
  onCheck(document: Operation, selected: boolean): void;
}

export const OperationCard: React.FC<OperationCardProps> = ({ operation, onCheck }) => {
  const { documentNumber, paymentReference, amount, currency, details, type } = operation;

  const { name: senderName, accountNumber: senderAccountNumber } = details.senderAccount;
  const { name: recipientName } = details.recipientAccount;

  const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.blur();
    onCheck(operation, e.target.checked);
  };

  return (
    <>
      <Grid container direction="column" sx={styles.root}>
        <Box pt={2} pb={2}>
          <Grid item container justifyContent="space-between">
            <Typography variant="caption" sx={styles.documentNumber}>
              № {documentNumber}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{ ...(type === OperationsTypeEnum.withdrawal ? styles.withdrawal : styles.deposit) }}
            >
              {type === OperationsTypeEnum.withdrawal ? '-' : '+'}
              {formatAmount(amount)}{' '}
              <Typography variant="body1" component="span">
                {currency}
              </Typography>
            </Typography>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body2" component="div" sx={styles.textFirst}>
            {senderName}
          </Typography>
          <AccountNumberItem text={<IBanText text={senderAccountNumber} />} />
        </Box>
        <Box sx={styles.buttonContainer}>
          <Checkbox onChange={onCheckHandler} sx={styles.buttonIcon} disableTouchRipple={true} focusRipple={false} />
        </Box>
        <Box sx={styles.accountContainer}>
          <Typography variant="body2" component="div" sx={styles.textFirst}>
            {recipientName}
          </Typography>
          <PaymentReference text={paymentReference} />
        </Box>
      </Grid>
    </>
  );
};

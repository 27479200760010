import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { GetAccountResponseDto } from '@app/core/api';

import { parseIBAN, isMobile, formatAmount } from '../../../../utils';

type SelectOptionProps = {
  optionValue: string;
};

export const AccountSelectOption: React.FC<SelectOptionProps> = ({ optionValue }) => {
  const {
    account: { accountNumber, accountName, amount, currency },
  }: GetAccountResponseDto = JSON.parse(optionValue);
  const amountFormat = formatAmount(amount);
  const { accountPart1, accountPart2 } = parseIBAN(accountNumber);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid container item xs={isMobile ? 9 : 5}>
        <Typography
          sx={{
            color: 'colors.gray400',
          }}
          variant="body2"
        >
          {accountPart1}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
          }}
          variant="body2"
        >
          {accountPart2}
        </Typography>
      </Grid>
      {!isMobile ? (
        <Grid item xs={5}>
          <Typography
            sx={{
              color: 'colors.primary500',
            }}
            variant="body2"
          >
            {accountName}
          </Typography>
        </Grid>
      ) : null}
      <Grid container justifyContent="flex-end" item xs={2}>
        <Typography
          sx={{
            fontWeight: 600,
          }}
          variant="body2"
          align="right"
        >
          {amountFormat}
        </Typography>
        <Typography variant="body2" align="right">
          {currency}
        </Typography>
      </Grid>
    </Grid>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, swiftApiClient, getClientId, NotifySignersQueryDto } from '@app/core/api';

const sendRemindSwiftSignFn = async (notifyData: NotifySignersQueryDto): Promise<object> => {
  const response = await swiftApiClient.swiftSignControllerNotifyAnotherSigners(getClientId(), notifyData);
  if (response.status !== 200) {
    throw new ApiError(response.request.status, "swiftSignControllerNotifyAnotherSigners error. Can't send notify.");
  }

  return response.data;
};

export const useSendRemindSwiftSign = (): UseMutationResult<object, ApiError, NotifySignersQueryDto> => {
  return useMutation<object, ApiError, NotifySignersQueryDto>(sendRemindSwiftSignFn);
};

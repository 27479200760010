import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CancelDocIconSvg } from '@app/themes/default/assets/icons/back-cancel.svg';

import { ConfirmPopup } from '../ConfirmPopup';

interface CancelDocumentDialogProps {
  onClose(): void;
  onConfirmCancelDocument(): void;
}

export const CancelDocumentDialog: React.FC<CancelDocumentDialogProps> = ({ onConfirmCancelDocument, onClose }) => {
  const { t } = useTranslation();
  return (
    <ConfirmPopup
      icon={<CancelDocIconSvg style={{ fill: '#414E62' }} />}
      questionText={t('cancelDocumentTitle')}
      onConfirm={onConfirmCancelDocument}
      onClose={onClose}
      classes={{
        questionText: {
          fontSize: '1.25rem',
          color: 'colors.gray800',
        },
      }}
      buttonsProps={{
        confirmBtnText: t('reject'),
        cancelBtnText: t('cancel'),
      }}
      open
    >
      <Box mt={3}>
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '0.875rem',
            color: 'colors.gray500',
          }}
        >
          {t('confirmCancelDocumentQuestion')}
        </Typography>
      </Box>
    </ConfirmPopup>
  );
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, UserDto, currencyOperationsApiClient } from '@app/core/api';

export const useGetCurrencyAnotherSigners = (uuid?: string): UseQueryResult<Array<UserDto>, ApiError> => {
  return useQuery<Array<UserDto>, ApiError>(
    'payment_useGetCurrencyAnotherSigners',
    async () => {
      const result = await currencyOperationsApiClient.currencyOperationSignControllerGetAnotherSigners(
        getClientId(),
        uuid
      );
      if (result.status !== 200) {
        throw new ApiError(result.request.status, "Can't get currency an another signers");
      }
      return result.data;
    },
    { enabled: false }
  );
};

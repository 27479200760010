import { isMobile } from '@app/core/utils';

export const styles = {
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: `28px`,
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: isMobile ? '350px' : '640px',
    maxHeight: '75vh',
  },
  otp: {
    background: 'transparent',
    border: '1px solid #b3b8c0',
    borderRadius: '8px',
    fontSize: '16px',
    height: '48px',
    width: '32px',
    marginRight: '8px',
    outline: 0,
  },
  otpError: {
    border: '1px solid #b83f4d',
  },
  modalTitle: {
    fontWeight: 700,
    color: 'colors.primary600',
  },
  cardAction: {
    justifyContent: isMobile ? 'center' : 'flex-end',
    width: '100%',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  statusDisabled: {
    color: 'colors.error',
    fontWeight: 600,
  },
  statusEnabled: {
    color: 'colors.success',
    fontWeight: 600,
  },
  gaTitle: {
    fontWeight: 600,
  },
  code: {
    color: 'colors.primary600',
  },
};

import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Down2ChevronSmallIcon } from '@app/common/icons';
import { GetOneLoanResponseDtoLoanTypeEnum, ScheduleDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { nearestDate } from '@app/core/utils';
import {
  makeLoanScheduleTableColumns,
  makeTrancheScheduleTableColumns,
} from '@app/pages/loans/show/components/ScheduleTable/columns';
import { styles } from '@app/pages/loans/show/components/ScheduleTable/style';

interface ScheduleTableProps {
  loanType: GetOneLoanResponseDtoLoanTypeEnum;
  scheduleOfPayments: Array<ScheduleDto>;
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({ loanType, scheduleOfPayments }) => {
  const { t } = useTranslation();
  const [showCollapsePreviousBtn, setShowCollapsePreviousBtn] = useState<boolean>(false);
  const [showCollapseNextBtn, setShowCollapseNextBtn] = useState<boolean>(false);
  const [nearestRowElementIndex, setNearestRowElementIndex] = useState<number>(0);
  const [currentRowElementIndex, setCurrentRowElementIndex] = useState<number>(-1);

  useEffect(() => {
    const dates = scheduleOfPayments.map((item) => new Date(item.paymentDate));
    const index = nearestDate(dates);
    const element = document.getElementById(`schedule_row_${index}`);
    if (element) {
      element.scrollIntoView();
      setNearestRowElementIndex(index);
      setCurrentRowElementIndex(index);
    }
  }, [scheduleOfPayments]);

  const seePreviousBtnClickHandler = () => {
    const toElIndex = currentRowElementIndex - 3;
    if (toElIndex >= 0) {
      setShowCollapsePreviousBtn(true);
      setCurrentRowElementIndex(toElIndex);
      document.getElementById(`schedule_row_${toElIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      setCurrentRowElementIndex(0);
      document.getElementById(`schedule_row_${0}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const collapsePreviousBtnClickHandler = () => {
    setShowCollapsePreviousBtn(false);
    setCurrentRowElementIndex(nearestRowElementIndex);
    document.getElementById(`schedule_row_${nearestRowElementIndex}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  const seeNextBtnClickHandler = () => {
    const toElIndex = currentRowElementIndex + 3;
    if (toElIndex <= scheduleOfPayments.length) {
      setShowCollapseNextBtn(true);
      setCurrentRowElementIndex(toElIndex);
      document.getElementById(`schedule_row_${toElIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      setCurrentRowElementIndex(scheduleOfPayments.length);
      document.getElementById(`schedule_row_${scheduleOfPayments.length}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const collapseNextBtnClickHandler = () => {
    setShowCollapseNextBtn(false);
    setCurrentRowElementIndex(nearestRowElementIndex);
    document.getElementById(`schedule_row_${nearestRowElementIndex}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  const columns = useMemo(() => {
    return [
      ...(loanType === GetOneLoanResponseDtoLoanTypeEnum.Loan
        ? makeLoanScheduleTableColumns({
            t,
          })
        : makeTrancheScheduleTableColumns({
            t,
          })),
    ];
  }, [loanType, t]);

  const table = useReactTable<ScheduleDto>({
    columns,
    data: scheduleOfPayments,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const buildBodyContent = (): React.ReactNode => {
    return (
      <>
        <Box
          sx={{
            borderBottom: '1px solid',
            ...styles.navigationContainer,
          }}
        >
          <Box sx={styles.navigationBtn} onClick={seePreviousBtnClickHandler}>
            {t('seePrevious')}
            <Down2ChevronSmallIcon sx={{ transform: 'rotate(180deg)', ...styles.navigationBtnIcon }} />
          </Box>
          {showCollapsePreviousBtn ? (
            <Box sx={{ marginLeft: '30px', ...styles.navigationBtn }} onClick={collapsePreviousBtnClickHandler}>
              {t('collapsePrevious')}
              <Down2ChevronSmallIcon sx={styles.navigationBtnIcon} />
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            display: 'block',
            height: '119px',
          }}
        >
          {table.getRowModel().rows.map((row, index) => {
            return <TableRowContent<ScheduleDto> key={row.id} row={row} styles={styles} id={`schedule_row_${index}`} />;
          })}
        </Box>
        <Box
          sx={{
            borderTop: '1px solid',
            ...styles.navigationContainer,
          }}
        >
          <Box sx={styles.navigationBtn} onClick={seeNextBtnClickHandler}>
            {t('seeNext')}
            <Down2ChevronSmallIcon sx={styles.navigationBtnIcon} />
          </Box>
          {showCollapseNextBtn ? (
            <Box sx={{ marginLeft: '30px', ...styles.navigationBtn }} onClick={collapseNextBtnClickHandler}>
              {t('collapseNext')}
              <Down2ChevronSmallIcon sx={{ transform: 'rotate(180deg)', ...styles.navigationBtnIcon }} />
            </Box>
          ) : null}
        </Box>
      </>
    );
  };

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<ScheduleDto> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
    />
  );
};

import { RadioOption } from '@app/core/components/Form/components/RadioGroup';
import { DatePeriod } from '@app/core/components/Form/controls/DateField/constants';
import { SelectOptionType } from '@app/core/components/Select';
import { OperationsFilterStatements } from '@app/core/types';
import { FormFieldName } from '@app/pages/dashboard/view/PageContent/Statements/types';

export const filesFormatList: RadioOption[] = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'XLSX',
    value: 'xlsx',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'XML',
    value: 'xml',
  },
];

export const operationsListItems = (t: (translationKey: string) => string): SelectOptionType[] => {
  return [
    {
      id: OperationsFilterStatements.all,
      name: t('all'),
      value: OperationsFilterStatements.all,
    },
    {
      id: OperationsFilterStatements.Deposit,
      name: t('incoming'),
      value: OperationsFilterStatements.Deposit,
    },
    {
      id: OperationsFilterStatements.Withdrawal,
      name: t('outgoing'),
      value: OperationsFilterStatements.Withdrawal,
    },
  ];
};

export const defaultFormData = {
  [FormFieldName.operationAccounts]: [],
  [FormFieldName.transactionsPresented]: false,
  [FormFieldName.fileType]: filesFormatList[0].value,
  [FormFieldName.dateRange]: {
    from: new Date(),
    to: new Date(),
  },
  date_select: DatePeriod.for_today,
};

export const styles = {
  title: {
    fontWeight: 700,
    marginRight: '4px',
    color: 'colors.gray600',
  },
  value: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
  signingAuthority: {
    fontWeight: 400,
    color: 'colors.gray300',
  },
  statusIcon: {
    color: 'colors.primary400',
  },
  signerContainer: {
    marginTop: '2px',
    width: '100%',
  },
  attorneyContainer: {
    marginTop: '4px',
    marginLeft: '24px',
    width: '100%',
    boxShadow: '0px 4px 12px rgba(119, 135, 150, 0.1), 0px 1px 4px rgba(119, 135, 150, 0.07)',
    borderRadius: '8px',
    padding: '8px',
  },
  toggleButton: {
    color: 'colors.gray400',
    fontSize: '0.75rem',
    cursor: 'pointer',
    '& svg': {
      marginLeft: '4px',
    },
  },
};

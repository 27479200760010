import { ResourceAction, SignPermissionsDto, UserResourceDto } from '@app/core/api';

export const TINCode = 1; // FOP
export enum ClientSigningAuthority {
  firstSignature = 1,
  secondSignature = 2,
}

export const defaultResourceActions: ResourceAction = {
  view: false,
  create: false,
  edit: false,
  delete: false,
  print: false,
  download: false,
  copy: false,
  upload: false,
  attach: false,
  detach: false,
  import: false,
  cancel: false,
  send: false,
};

const defaultSignPermissions: SignPermissionsDto = {
  paymentOrder: {
    allowToSign: false,
    allowToSendToSign: false,
    save: false,
    sign: false,
    send: false,
    signFromAnotherPerson: false,
  },
  swift: {
    allowToSign: false,
    allowToSendToSign: false,
    save: false,
    sign: false,
    send: false,
    signFromAnotherPerson: false,
  },
  message: {
    allowToSign: false,
    allowToSendToSign: false,
    save: false,
    sign: false,
    send: false,
    signFromAnotherPerson: false,
  },
  currencyOperation: {
    allowToSign: false,
    allowToSendToSign: false,
    save: false,
    sign: false,
    send: false,
    signFromAnotherPerson: false,
  },
  confirmatory: {
    allowToSign: false,
    allowToSendToSign: false,
    save: false,
    sign: false,
    send: false,
    signFromAnotherPerson: false,
  },
};

export const defaultPermissions: UserResourceDto = {
  uahOperations: defaultResourceActions,
  uahTemplates: defaultResourceActions,
  currencyExchange: defaultResourceActions,
  swift: defaultResourceActions,
  documents: defaultResourceActions,
  messages: defaultResourceActions,
  sign: defaultSignPermissions,
  accounts: { setDefaultAccount: false },
  configuration: { create: false, manual: false, view: false },
};

import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { hasNextPage } from '@app/core/utils';

import { ActionsPanel } from './components/ActionsPanel';
import { DocumentCard } from './components/DocumentCard';
import { Loader } from '../../../Loader';
import { ModalCommon } from '../../../Modal';
import { DocumentsListProps } from '../../types';

export const MobileDocumentsList: React.FC<DocumentsListProps> = ({
  documentStatus,
  isLoading,
  selectedDocuments,
  operations,
  pagination,
  onSelectAllClick,
  updateSelectedDocuments,
  onSignClick,
  onMultiSignClick,
  onDeleteClick,
  onCopyClick,
  onAddToTemplatesClick,
  onPaginationChange,
  onCancelDocument,
  getStatementsMultipleFiles,
  onSignFromAnother,
  onMultiFromAnotherClick,
}) => {
  const [nextPageExists, setNextPageExists] = useState<boolean>(true);
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (pagination && bottomAnchorInView && !isLoading) {
      if (nextPageExists) {
        onPaginationChange({
          page: pagination.page + 1,
          rowsPerPage: pagination.rowsPerPage,
        });
      }
    }
  }, [bottomAnchorInView, isLoading, nextPageExists, onPaginationChange, pagination]);

  useEffect(() => {
    if (operations && pagination) {
      setNextPageExists(hasNextPage(pagination));
    }
  }, [operations, pagination]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <ModalCommon open={isLoading}>
        <div>
          <Loader />
        </div>
      </ModalCommon>
      {selectedDocuments?.length ? (
        <ActionsPanel
          selectedDocuments={selectedDocuments}
          rowsCount={operations?.length || 0}
          documentStatus={documentStatus}
          onSelectAllClick={onSelectAllClick}
          onSignClick={onSignClick}
          onDeleteClick={onDeleteClick}
          onCopyClick={onCopyClick}
          onAddToTemplatesClick={onAddToTemplatesClick}
          onMultiSignClick={onMultiSignClick}
          onCancelDocument={onCancelDocument}
          getStatementsMultipleFiles={getStatementsMultipleFiles}
          onSignFromAnotherClick={onSignFromAnother}
          onMultiSignFromAnotherClick={onMultiFromAnotherClick}
        />
      ) : null}

      {operations?.length ? (
        <>
          {operations.map((document, i) => {
            const presentInSelected = Boolean(
              selectedDocuments.filter((selectedDocument) => {
                return selectedDocument.id === document.id;
              }).length
            );

            return (
              <DocumentCard
                key={document.uuid + i.toString()}
                document={document}
                selected={presentInSelected}
                onCheck={(doc, selected) => {
                  updateSelectedDocuments(doc, selected);
                }}
              />
            );
          })}
          {isLoading ? null : <div ref={bottomAnchorRef} />}
        </>
      ) : null}
    </Grid>
  );
};

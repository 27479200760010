import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { CrossIcon, DownloadIcon, ErrorSmallIcon, InfoIcon, KeyIcon, PrintIcon } from '@app/common/icons';
import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  CurrencyOperationsExchangeRateTypeEnum,
  CurrencyOperationsExchangeTypeEnum,
  CurrencyOperationStatusEnum,
  DocumentStatusEnum,
  EmailDocumentTypeEnum,
  GetOneCurrencyOperationResponseDto,
} from '@app/core/api';
import { SignCurrencyExchangeMultiple } from '@app/core/components';
import { Checkbox } from '@app/core/components/Checkbox';
import { ConfirmSignDocuments } from '@app/core/components/ConfirmSignDocuments';
import { ShareDocumentButton } from '@app/core/components/ShareDocumentButton';
import { SignCurrencyDocumentsDialog } from '@app/core/components/SignCurrencyDocumentsDialog';
import { TextFieldMultiline } from '@app/core/components/TextFieldMultiline';
import { Tooltip } from '@app/core/components/Tooltip';
import { isMobile, DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { InfoBlock } from '@app/pages/exchange/components/form/components/InfoBlock';
import {
  getDocumentNumberTranslationKeyTitle,
  getTransferMethodTranslationKeyLabel,
} from '@app/pages/exchange/components/form/helpers';
import { styles } from '@app/pages/exchange/components/form/style';
import { getCurrencyOperationFn } from '@app/pages/exchange/query';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

export const ShowExchangePage: React.FC = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { showLoader } = useUIState();
  const { t } = useTranslation();
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const [showConfirmSignDocumentsDialog, setShowConfirmSignDocumentsDialog] = useState<boolean>(false);
  const [showSignCurrencyDocumentsDialog, setShowSignCurrencyDocumentsDialog] = useState<boolean>(false);

  const { userProfile } = useAppSelector((state): ProfileState => state.profile);

  const { data, mutate, isLoading } = useMutation<GetOneCurrencyOperationResponseDto, ApiError, string>(
    getCurrencyOperationFn
  );

  useEffect(() => {
    if (uuid) {
      mutate(uuid);
    }
  }, [mutate, uuid]);

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const currencyExchangeType = data?.currencyExchangeType;

  const amountLabel =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Purchase ? t('purchaseAmount') : t('salleAmount');

  const amountCurrency =
    currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Sale
      ? data?.withdrawalAccount.currency
      : data?.depositAccount.currency;

  const exchangeRateType =
    data?.exchangeRateType === CurrencyOperationsExchangeRateTypeEnum.Bank
      ? t('currencyExchange_ExchangeRateType_Bank')
      : t('currencyExchange_ExchangeRateType_Client');

  const closeHandler = () => {
    navigate(-1);
  };

  const downloadHandler = () => {
    window.open(`${data?.link}&format=download`, '_blank');
  };

  const onSaveAndSignClickHandler = () => {
    setShowSignatureDialog(true);
  };

  const onSuccessPopupCancelHandler = () => {
    if (typeof uuid === 'string') {
      mutate(uuid);
    }
    setShowSignatureDialog(false);
  };

  const onCloseSignCurrencyModalHandler = () => {
    setShowSignatureDialog(false);
  };
  const toSignDocuments = data?.documents.filter(
    (item) => item.status === DocumentStatusEnum.Saved || item.status === DocumentStatusEnum.ToSign
  );

  const onSignDocumentsClickHandler = () => {
    if (toSignDocuments && toSignDocuments.length) {
      setShowConfirmSignDocumentsDialog(true);
    }
  };

  const onCloseSignDocumentsHandler = () => {
    setShowConfirmSignDocumentsDialog(false);
  };

  const onAgreeSignHandler = () => {
    setShowConfirmSignDocumentsDialog(false);
    setShowSignCurrencyDocumentsDialog(true);
  };

  const onCloseSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
  };

  const onSuccessSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
    if (uuid) {
      mutate(uuid);
    }
  };

  return (
    <>
      <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
        <PageHeader
          title={currencyExchangeType ? t(getDocumentNumberTranslationKeyTitle(currencyExchangeType)) : ''}
          showLeavePopup={false}
          backLink={-1}
          showNavigateButton={!isMobile}
        />
        {data ? (
          <PageContainer>
            <Grid container direction="column" wrap="nowrap">
              <Box mt={2}>
                <>
                  <Box p={isMobile ? 0 : 3} width="100%">
                    <Grid container wrap="nowrap" justifyContent="space-between">
                      <Grid item container xs={isMobile ? false : 9} direction="column">
                        {data.rejectReason ? (
                          <Grid container alignItems="center" sx={styles.rejectContainer}>
                            <ErrorSmallIcon sx={styles.rejectReasonIcon} />
                            <Typography variant="body2" sx={styles.rejectReasonText}>
                              {data.rejectReason}
                            </Typography>
                          </Grid>
                        ) : null}

                        <Grid
                          container
                          direction="column"
                          sx={{ ...styles.formContent, ...(isMobile && styles.formContentMobile) }}
                        >
                          <Grid container>
                            <Grid item container xs={8}>
                              <Typography variant="h6">
                                <Grid container>
                                  <Typography sx={styles.header}>
                                    {currencyExchangeType ? (
                                      <>
                                        {t(getDocumentNumberTranslationKeyTitle(currencyExchangeType))}
                                        {' № '}
                                        {data.documentNumber}
                                      </>
                                    ) : null}
                                  </Typography>
                                </Grid>
                              </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-end" item xs={4} alignItems="center">
                              <Box>
                                <a href={`${data?.link}&format=print`} target="_blank" rel="noreferrer">
                                  <IconButton size="small">
                                    <PrintIcon sx={styles.actionIcon} />
                                  </IconButton>
                                </a>
                              </Box>
                              {appConfig.enableNextFeatures ? (
                                <Box ml={isMobile ? 2 : 5}>
                                  <ShareDocumentButton
                                    link={`${data?.link}&format=download`}
                                    styles={{ color: 'colors.primary500' }}
                                    type={EmailDocumentTypeEnum.CurrencyOperation}
                                    uuid={uuid || ''}
                                    subType={data.currencyExchangeType}
                                  />
                                </Box>
                              ) : null}
                              {isMobile ? (
                                <Box ml={2}>
                                  <IconButton size="small" onClick={closeHandler}>
                                    <CrossIcon />
                                  </IconButton>
                                </Box>
                              ) : null}
                            </Grid>
                          </Grid>
                          <Grid container direction="column">
                            <Grid container direction="column">
                              {/* start */}

                              <Grid container direction={isMobile ? 'column' : 'row'}>
                                <Grid item>
                                  <Box mt={6}>
                                    <FormLabel component="legend">
                                      {t('paymentCreationLocalPayment_documentDate')}
                                    </FormLabel>
                                    <Box pt={2} pr={isMobile ? 0 : 8}>
                                      <TextField
                                        disabled
                                        variant="outlined"
                                        defaultValue={formatISODateString(data.documentDate, DATE_FORMAT)}
                                        fullWidth
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                {data.documentValueDate ? (
                                  <Grid>
                                    <Grid item>
                                      <Box mt={6} width="100%">
                                        <FormLabel component="legend">
                                          {t('paymentCreationLocalPayment_valuationDate')}
                                        </FormLabel>
                                        <Box pt={2}>
                                          <Grid container alignItems="center" wrap="nowrap">
                                            <Grid item xs={11}>
                                              <TextField
                                                disabled
                                                variant="outlined"
                                                fullWidth
                                                defaultValue={formatISODateString(data.documentValueDate, DATE_FORMAT)}
                                              />
                                            </Grid>
                                            <Box ml={2}>
                                              <Tooltip
                                                color="primary"
                                                title={t('currencyExchange_valuationDateTooltip')}
                                                arrow
                                                placement={isMobile ? 'top' : 'right'}
                                              >
                                                <span>
                                                  <InfoIcon fontSize="small" sx={styles.info} />
                                                </span>
                                              </Tooltip>
                                            </Box>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>

                              <Box mt={6}>
                                <FormLabel component="legend">{t('write-offAccount')}</FormLabel>
                                <Box pt={2}>
                                  <TextField
                                    variant="outlined"
                                    defaultValue={data.withdrawalAccount.accountNumber}
                                    disabled
                                    fullWidth
                                  />
                                </Box>
                              </Box>
                              <Box mt={6}>
                                <FormLabel component="legend">{t('enrollmentAccount')}</FormLabel>
                                <Box pt={2}>
                                  <TextField
                                    variant="outlined"
                                    defaultValue={data.depositAccount.accountNumber}
                                    disabled
                                    fullWidth
                                  />
                                </Box>
                              </Box>
                              <Box sx={styles.hr} mt={5} />

                              <Grid container direction={isMobile ? 'column' : 'row'}>
                                <Grid
                                  item
                                  xs={isMobile ? false : 4}
                                  container={isMobile}
                                  direction={isMobile ? 'column' : undefined}
                                >
                                  <Box mt={6}>
                                    <FormLabel component="legend">{amountLabel}</FormLabel>
                                    <Box pt={2} pr={isMobile ? 0 : 5}>
                                      <TextField
                                        variant="outlined"
                                        disabled
                                        defaultValue={parseFloat(`${data.amount}`).toFixed(2)}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">{amountCurrency}</InputAdornment>
                                          ),
                                        }}
                                        fullWidth={isMobile}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={isMobile ? false : 4}>
                                  <Box mt={6}>
                                    <FormLabel component="legend">{t('conversionRate')}</FormLabel>
                                    <Box pt={2} pr={isMobile ? 0 : 5}>
                                      <TextField
                                        disabled
                                        variant="outlined"
                                        defaultValue={exchangeRateType}
                                        fullWidth={isMobile}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                {data.exchangeRate ? (
                                  <Grid item xs={isMobile ? false : 4}>
                                    <FormControl component="fieldset" fullWidth disabled>
                                      <Box mt={6}>
                                        <FormLabel component="legend">{t('course')}</FormLabel>
                                        <Box pt={2}>
                                          <TextField
                                            disabled
                                            variant="outlined"
                                            defaultValue={data.exchangeRate}
                                            fullWidth={isMobile}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  {data.withdrawalAccount.currency}
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </FormControl>
                                  </Grid>
                                ) : null}
                              </Grid>
                              <Box sx={styles.hr} mt={5} />
                              <Box mt={6} width="100%">
                                <FormLabel component="legend">{t('maintenanceCommissionAccount')}</FormLabel>
                                <Grid container alignItems="center" wrap="nowrap">
                                  <Grid item xs={isMobile ? 11 : 8}>
                                    <Box pt={2}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="unused"
                                        disabled
                                        placeholder={t('maintenanceCommissionAccountPlaceholder')}
                                      />
                                    </Box>
                                  </Grid>
                                  <Box ml={2} pt={2}>
                                    <Tooltip
                                      color="primary"
                                      title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                                      arrow
                                      placement={isMobile ? 'top' : 'right'}
                                    >
                                      <i>
                                        <InfoIcon fontSize="small" sx={styles.info} />
                                      </i>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                {data.currencyOperationReason ? (
                                  <Box mt={2}>
                                    <Box pt={4} pb={2}>
                                      <FormLabel component="legend">{t('groundsForBuyingCurrency')}</FormLabel>
                                    </Box>
                                    <Box pt={2} pb={2}>
                                      <TextFieldMultiline
                                        variant="outlined"
                                        value={data.currencyOperationReason}
                                        multiline
                                        fullWidth
                                        sx={{
                                          '& textarea': {
                                            padding: '10px 12px',
                                            color: 'colors.gray300',
                                          },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                ) : null}
                              </Box>
                              <Box mt={6}>
                                <FormLabel component="legend">{t('transferMethod')}</FormLabel>
                                <Box pt={3}>
                                  <FormControlLabel
                                    control={<Checkbox checked />}
                                    label={
                                      <Typography variant="caption">
                                        {t(getTransferMethodTranslationKeyLabel(data.currencyExchangeType))}
                                      </Typography>
                                    }
                                    disabled
                                    sx={styles.transactionPresentedLabel}
                                  />
                                </Box>
                              </Box>
                              {/*  end*/}
                            </Grid>
                            <Box pt={6} width="100%">
                              <Grid container justifyContent="space-between">
                                {isMobile ? null : (
                                  <Button variant="contained" color="primary" onClick={closeHandler}>
                                    {t('close')}
                                  </Button>
                                )}
                                <Grid
                                  container={isMobile}
                                  item
                                  xs={isMobile ? 12 : false}
                                  justifyContent={isMobile ? 'space-between' : undefined}
                                  wrap={isMobile ? 'nowrap' : undefined}
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={styles.submitBtn}
                                    endIcon={isMobile ? null : <DownloadIcon />}
                                    onClick={downloadHandler}
                                  >
                                    {t('download')}
                                  </Button>
                                  {data &&
                                  (data.status === CurrencyOperationStatusEnum.ToSign ||
                                    data.status === CurrencyOperationStatusEnum.Saved) ? (
                                    <Button variant="contained" color="primary" onClick={onSaveAndSignClickHandler}>
                                      {t('paymentCreationFormControl_send')}
                                      {isMobile ? null : <KeyIcon sx={styles.keyIcon} />}
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {isMobile ? null : (
                        <Box sx={{ width: '220px' }}>
                          {userProfile && (
                            <Box pl={2}>
                              <InfoBlock
                                status={data.status}
                                documents={data.documents}
                                createDocumentInfo={data.createDocumentInfo}
                                userHistory={data.userHistory}
                                personToSign={data.signInfo.personToSign}
                                onSignDocumentsClick={onSignDocumentsClickHandler}
                                showLinkedBlock={
                                  data.documents.length > 0 &&
                                  data.currencyExchangeType !== CurrencyOperationsExchangeTypeEnum.Sale
                                }
                                detachFileEnabled={false}
                                signDocumentsButtonEnabled={Boolean(toSignDocuments?.length)}
                                userProfile={userProfile}
                                edit={false}
                                uuid={data.uuid}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </>
              </Box>
            </Grid>
          </PageContainer>
        ) : null}
      </PageWrapper>

      {showSignatureDialog && data ? (
        <SignCurrencyExchangeMultiple
          operations={[data]}
          onSuccessPopupCancel={onSuccessPopupCancelHandler}
          onClose={onCloseSignCurrencyModalHandler}
        />
      ) : null}

      {showConfirmSignDocumentsDialog && toSignDocuments && data ? (
        <ConfirmSignDocuments
          onAgreeSign={onAgreeSignHandler}
          onClose={onCloseSignDocumentsHandler}
          selectedDocumentsFilesCount={data.documents.length}
          toSignDocuments={toSignDocuments}
        />
      ) : null}

      {showSignCurrencyDocumentsDialog && toSignDocuments ? (
        <SignCurrencyDocumentsDialog
          toSignDocuments={toSignDocuments}
          onClose={onCloseSignCurrencyDocumentsDialog}
          onSuccessPopupCancel={onSuccessSignCurrencyDocumentsDialog}
        />
      ) : null}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  counterpartiesApiClient,
  CounterpartyRequestDto,
  CounterpartyResponseDto,
  getClientId,
} from '@app/core/api';

const addCounterpartyFn = async (counterpartyData: CounterpartyRequestDto): Promise<CounterpartyResponseDto> => {
  const response = await counterpartiesApiClient.counterpartiesControllerCreateCounterparty(
    getClientId(),
    counterpartyData
  );
  return response.data;
};

export const useAddCounterparty = (): UseMutationResult<CounterpartyResponseDto, ApiError, CounterpartyRequestDto> => {
  return useMutation<CounterpartyResponseDto, ApiError, CounterpartyRequestDto>(addCounterpartyFn);
};

export enum ScheduleTableColumns {
  paymentDate = 'paymentDate',
  principalAmount = 'principalAmount',
  principalOverdue = 'principalOverdue',
  principalUnpaid = 'principalUnpaid',
  interestAmount = 'interestAmount',
  interestOverdue = 'interestOverdue',
  interestUnpaid = 'interestUnpaid',
  interestRepaymentDate = 'interestRepaymentDate',
  principalOverdueUnpaid = 'principalOverdueUnpaid',
  interestOverdueUnpaid = 'interestOverdueUnpaid',
  addInterestAmount = 'addInterestAmount',
}

import React, { useEffect, useState } from 'react';

import { Box, CardContent, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';

import { ActionFiltersButtons } from '@app/core/components';
import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { DocumentStatusSelectField } from '@app/core/components/Form/controls/DocumentStatusSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { StatementsFilterField } from '@app/core/components/Statements/types';
import { styles } from '@app/pages/operations/desktop/components/LocalCurrencyOperationsFilter/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

interface LocalCurrencyOperationsFilterProps {
  fullItems: boolean;
  resetFormHandler(): void;
}

export const LocalCurrencyOperationsFilter: React.FC<LocalCurrencyOperationsFilterProps> = ({
  fullItems,
  resetFormHandler,
}) => {
  const { t } = useTranslation();
  const handleSelectAccountsLength = (value: []) => value.length > 0 || t('statementsFilter_errorAmountField');
  const { uahAccounts } = useAppSelector((state): ProfileState => state.profile);
  const [defaultSelectedAccountsId, setDefaultSelectedAccountsId] = useState<string[]>();

  useEffect(() => {
    if (uahAccounts) {
      const { operationAccounts: operationAccountsId } = parse(location.search);
      if (operationAccountsId) {
        setDefaultSelectedAccountsId((operationAccountsId as string).split(','));
      }
    }
  }, [uahAccounts]);

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Box pb={5}>
            <Grid container justifyContent="space-between" spacing={5}>
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth sx={styles.inputWrapper}>
                  <FormLabel component="legend">{t('accounts')}</FormLabel>
                  <Box pt={2}>
                    <AccountsWithSearchSelectField
                      name={StatementsFilterField.operationAccounts}
                      accounts={uahAccounts}
                      defaultSelectedAccountsId={defaultSelectedAccountsId}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">{t('statementsFilter_dateRange')}</FormLabel>
                <FormControl fullWidth sx={styles.inputWrapper}>
                  <Box pt={2}>
                    <DateRangeSelectField
                      formSelectName={StatementsFilterField.dateRangeSelect}
                      formDateRangeName={StatementsFilterField.dateRange}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                {!fullItems ? (
                  <Box pt={6} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>

          {fullItems ? (
            <>
              <Box pb={5}>
                <Grid container justifyContent="space-between" spacing={5}>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_LEI')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        name={StatementsFilterField.LEI}
                        placeholder={t('statementsFilter_dateRange_LEI_placeholder')}
                        fullWidth
                        type={FormatType.number}
                        maxLength={10}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_counterPartyName')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.counterPartyName}
                        placeholder={t('statementsFilter_counterPartyName_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_counterPartyAccountNumber')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.counterPartyAccountNumber}
                        placeholder={t('statementsFilter_counterPartyAccountNumber_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_counterPartyBankCode')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.counterPartyBankCode}
                        placeholder={t('statementsFilter_counterPartyBankCode_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box pb={5}>
                <Grid container justifyContent="space-between" spacing={5}>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_documentNumber')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.documentNumber}
                        placeholder={t('statementsFilter_documentNumber_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_documentStatus')}</FormLabel>
                    <Box pt={2}>
                      <DocumentStatusSelectField name={StatementsFilterField.documentStatus} fullWidth />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        type={FormatType.amount}
                        defaultValue=""
                        name={StatementsFilterField.amountMin}
                        fullWidth
                        placeholder="0.00"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        type={FormatType.amount}
                        defaultValue=""
                        name={StatementsFilterField.amountMax}
                        fullWidth
                        placeholder="0.00"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box pb={5}>
                <Grid container justifyContent="space-between" spacing={5} alignItems="flex-end">
                  <Grid item xs={9}>
                    <FormLabel component="legend">{t('statementsFilter_paymentReference')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.paymentReference}
                        placeholder={t('statementsFilter_paymentReference_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  {appConfig.enableOperationGroupCommission ? (
                    <Grid item xs={3} container alignItems="center">
                      <Box pb={2}>
                        <CheckboxField
                          label={<Typography variant="caption">{t('statementsFilter_hideCommission')}</Typography>}
                          name={StatementsFilterField.hideCommission}
                          defaultValue="true"
                          sx={styles.transactionPresentedLabel}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={3}>
                    <Box pt={6} sx={styles.actionButtonsContainer}>
                      <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : null}
        </Grid>
      </CardContent>
    </Grid>
  );
};

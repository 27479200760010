export const styles = {
  divider: {
    backgroundColor: 'colors.gray100',
    marginLeft: '16px',
    width: '1px',
  },
  editIcon: {
    width: '24px',
    height: '24px',
  },
  date: {
    color: 'colors.gray400',
    fontWeight: 400,
  },
  accountName: {
    color: 'colors.primary600',
    fontWeight: 600,
    minHeight: '40px',
  },
  accountNumber: {
    color: 'colors.primary400',
  },
  counterPartyName: {
    color: 'colors.primary600',
    fontWeight: 600,
    minHeight: '40px',
  },
  paymentReference: {
    color: 'colors.gray400',
    fontWeight: 400,
    fontSize: '14px',
  },
  amountDeposit: {
    marginTop: '4px',
    color: 'colors.success',
    fontWeight: 600,
  },
  amountWithdrawal: {
    marginTop: '4px',
    color: 'colors.error',
    fontWeight: 600,
  },
  detailsInfoDescription: {
    color: 'colors.gray300',
    fontWeight: 600,
  },
  detailsInfoValue: {
    marginTop: '4px',
    color: 'colors.primary700',
    fontWeight: 600,
  },
  amountText: {
    color: 'colors.gray800',
    fontWeight: 'bold',
  },
  availableLimit: {
    marginTop: '4px',
    fontWeight: 600,
    color: 'colors.gray800',
  },
  overdraftInfoDescription: {
    display: 'flex',
    alignItems: 'center',
    color: 'colors.gray300',
    fontWeight: 600,
  },
  icon: {
    marginLeft: '4px',
    cursor: 'pointer',
  },
};

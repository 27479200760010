import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  counterpartiesApiClient,
  getClientId,
  ApiError,
  GetCounterpartiesBodyDto,
  GetCounterpartiesResponseDto,
} from '@app/core/api';

const getCounterpartiesFn = async (
  getCounterpartiesBodyDto: GetCounterpartiesBodyDto
): Promise<GetCounterpartiesResponseDto> => {
  const result = await counterpartiesApiClient.counterpartiesControllerGetCounterparties(
    getClientId(),
    getCounterpartiesBodyDto
  );

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get counterparties!");
  }

  return result.data;
};

export const useGetCounterparties = (): UseMutationResult<
  GetCounterpartiesResponseDto,
  ApiError,
  GetCounterpartiesBodyDto
> => {
  return useMutation<GetCounterpartiesResponseDto, ApiError, GetCounterpartiesBodyDto>(getCounterpartiesFn);
};

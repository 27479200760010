export const styles = {
  root: {
    maxWidth: '530px',
  },
  item: {
    padding: '12px',
    borderBottom: `1px solid`,
    borderColor: 'colors.gray100',
  },
  editEmail: {
    marginLeft: 'auto',
    '& svg': {
      width: '16px',
      height: '16px',
      fill: 'colors.gray300',
    },
  },
  editorInput: {
    width: '100%',
    maxWidth: '270px',
  },
  editorButton: {
    marginLeft: '4px',
    marginTop: '4px',
    padding: '4px',
  },
  editorIconDone: {
    color: '4px',
  },
  successAlert: {
    backgroundColor: 'colors.successBackground',
    border: `1px solid`,
    borderColor: 'colors.success',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.success',
    zIndex: 10,
    width: 'auto',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  successAlertText: {
    fontWeight: 600,
    color: 'colors.success',
    marginLeft: '8px',
  },
  errorAlert: {
    backgroundColor: 'colors.errorBackground',
    border: `1px solid`,
    borderColor: 'colors.error',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.error',
    zIndex: 10,
    width: 'auto',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  errorAlertText: {
    fontWeight: 600,
    color: 'colors.error',
    marginLeft: '8px',
  },
  notVerifyWarning: {
    backgroundColor: 'colors.warningBackground',
    border: `1px solid`,
    borderColor: 'colors.warning',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.warning',
    zIndex: 10,
    width: 'auto',
  },
  notVerifyWarningText: {
    fontWeight: 600,
    color: 'colors.gray700',
    marginLeft: '8px',
  },
  instruction: {
    width: 'auto',
    position: 'fixed',
    bottom: 50,
    color: 'colors.primary600',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

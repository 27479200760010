import React from 'react';

import { Grid, Typography } from '@mui/material';
import { format, intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { UserSessionDto } from '@app/core/api';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { tokenLifetime } from '@app/core/constants';
import { TIME_FORMAT } from '@app/core/utils';

interface CompanyUserVisitCardProps {
  visit: UserSessionDto;
}

export const CompanyUserVisitCard: React.FC<CompanyUserVisitCardProps> = ({ visit }) => {
  const { t } = useTranslation();
  const startDuration = new Date(visit.startedAt);
  const endDate = new Date(visit.lastUpdatedAt).getTime() + tokenLifetime;
  const getDuration = (start: Date, end: number | Date) => {
    const sessionTime = intervalToDuration({
      start,
      end,
    });
    return `${sessionTime.hours} ${t('session_hours')} ${sessionTime.minutes} ${t('session_minutes')}`;
  };
  const duration = () => {
    if (new Date(visit.lastUpdatedAt).getTime() < new Date().getTime() - tokenLifetime) {
      if (visit.finishedAt) {
        return getDuration(startDuration, new Date(visit.finishedAt));
      }
      return getDuration(startDuration, endDate);
    }
    return t('current_session');
  };
  return (
    <Grid container sx={{ backgroundColor: 'colors.gray25', padding: '20px', marginTop: '20px' }}>
      <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
        <Typography variant="body2">{t('myProfile_time/duration')}</Typography>
        <Typography>{`${format(new Date(visit.startedAt), TIME_FORMAT)} / ${duration()}`}</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        sx={{ marginBottom: '12px', marginTop: '12px', flexWrap: 'nowrap' }}
      >
        <Typography variant="body2">{t('myProfile_column_fullName')}</Typography>
        <Ellipsis maxWidth="40%" text={visit.fullName} />
      </Grid>
      <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
        <Typography>{t('myProfile_browser')}</Typography>
        <Typography>{visit.browser}</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
        <Typography>{t('myProfile_operationSystem')}</Typography>
        <Typography>{visit.os}</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
        <Typography>{t('myProfile_ip')}</Typography>
        <Typography>{visit.ip}</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" sx={{ marginBottom: '12px', marginTop: '12px' }}>
        <Typography>{t('myProfile_country')}</Typography>
        <Typography>{visit.location}</Typography>
      </Grid>
    </Grid>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, paymentOrdersApiClient, ApiError, CheckAvailabilityFundsResponseDto } from '@app/core/api';

const checkAvailabilityFundsFn = async (uuids: string[]): Promise<CheckAvailabilityFundsResponseDto> => {
  const result = await paymentOrdersApiClient.paymentOrderControllerCheckAvailabilityFunds(getClientId(), {
    uuids,
  });

  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error check availability funds.');
  }
  return result.data;
};

export const useCheckAvailabilityFunds = (): UseMutationResult<CheckAvailabilityFundsResponseDto, ApiError, string[]> =>
  useMutation<CheckAvailabilityFundsResponseDto, ApiError, string[]>(checkAvailabilityFundsFn);

import {
  accountsApiClient,
  ApiError,
  FileLinkResponseDto,
  getClientId,
  GetFileSaldoRequestDto,
  GetFileSaldoResponseDto,
  GetOperationsFileDto,
  statementsApiClient,
  StatementTemplateTypeEnum,
} from '@app/core/api';

export const getTurnoversFile = async ({
  accountId,
  dateRange,
  outputType,
}: GetFileSaldoRequestDto): Promise<GetFileSaldoResponseDto> => {
  const response = await accountsApiClient.accountsControllerGetFileSaldo(getClientId(), {
    accountId,
    dateRange,
    outputType,
  });

  return response.data;
};

export const getOperationsFile = async ({
  operationAccounts,
  dateRange,
  documentType,
  outputType,
}: GetOperationsFileDto): Promise<FileLinkResponseDto> => {
  const response = await statementsApiClient.operationsControllerGetFile(getClientId(), {
    operationAccounts,
    documentType,
    dateRange,
    outputType,
    templateType: StatementTemplateTypeEnum.Default,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get operations file link.");
  }

  return response.data;
};

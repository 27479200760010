import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, SearchSmallIcon, SettingIcon, UpChevronSmallIcon } from '@app/common/icons';
import {
  SwiftOperationWithAvailableButtons,
  SwiftOperationWithAvailableButtonsStatusEnum,
  SwiftRecipientInfo,
  SwiftSenderInfo,
} from '@app/core/api';
import { Checkbox } from '@app/core/components/Checkbox';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/swift/components/SwiftTable/components/TableContent/styles';
import { SwiftTableColumn } from '@app/pages/swift/components/SwiftTable/types';
import { appConfig } from '@app/src/config';

interface MakeTableColumnsProps {
  t(text: string): string;
  onSearchButtonClick(): void;
  onSettingsButtonClick(): void;
}

type StatusFieldProps = {
  status: SwiftOperationWithAvailableButtonsStatusEnum;
};

const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.status,
          ...(status === SwiftOperationWithAvailableButtonsStatusEnum.Executed && styles.statusExecuted),
          ...(status === SwiftOperationWithAvailableButtonsStatusEnum.Rejected && styles.statusRejected),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(status)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  onSearchButtonClick,
  onSettingsButtonClick,
  t,
}: MakeTableColumnsProps): ColumnDef<SwiftOperationWithAvailableButtons, any>[] => {
  return [
    {
      id: 'selection',
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: SwiftTableColumn.documentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`swift_${SwiftTableColumn.documentDate}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy')}</>;
      },
    },
    {
      accessorKey: SwiftTableColumn.documentNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`swift_${SwiftTableColumn.documentNumber}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, string>) => {
        return getValue();
      },
    },
    {
      accessorKey: SwiftTableColumn.sender,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`swift_senderAccount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, SwiftSenderInfo>) => {
        return <Ellipsis text={getValue().account} />;
      },
    },
    {
      accessorKey: SwiftTableColumn.recipient,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`swift_recipientName`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, SwiftRecipientInfo>) => {
        return <Ellipsis text={getValue().name} />;
      },
    },
    {
      accessorKey: SwiftTableColumn.paymentReference,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`swift_purpose`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: SwiftTableColumn.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`swift_${SwiftTableColumn.currency}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: SwiftTableColumn.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`swift_${SwiftTableColumn.amount}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<SwiftOperationWithAvailableButtons, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: SwiftTableColumn.status,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`swift_${SwiftTableColumn.status}`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({
        getValue,
      }: CellContext<SwiftOperationWithAvailableButtons, SwiftOperationWithAvailableButtonsStatusEnum>) => {
        return <StatusField status={getValue()} />;
      },
    },
    // {
    //   id: 'actions',
    //   header: ({ table }) => {
    //     const onSearchButtonClickHandler = () => {
    //       if (table.getRowModel().rows.length) {
    //         onSearchButtonClick();
    //       }
    //     };
    //     return (
    //       <Grid container justifyContent="flex-end">
    //         {appConfig.enableNextFeatures ? (
    //           <>
    //             <IconButton sx={styles.searchIconBtn} onClick={onSearchButtonClickHandler} size="large">
    //               <SearchSmallIcon fontSize="small" />
    //             </IconButton>
    //
    //             <IconButton sx={styles.settingIconBtn} onClick={onSettingsButtonClick} size="large">
    //               <SettingIcon />
    //             </IconButton>
    //           </>
    //         ) : null}
    //       </Grid>
    //     );
    //   },
    //   cell: ({ row }) => {
    //     return (
    //       <Grid container justifyContent="flex-end">
    //         {appConfig.enableNextFeatures ? (
    //           <Box
    //             component="span"
    //             {...{
    //               onClick: () => row.toggleExpanded(),
    //             }}
    //             sx={styles.chevron}
    //           >
    //             {row.getIsExpanded() ? (
    //               <UpChevronSmallIcon fontSize="small" />
    //             ) : (
    //               <DownChevronSmallIcon fontSize="small" />
    //             )}
    //           </Box>
    //         ) : null}
    //       </Grid>
    //     );
    //   },
    // },
  ];
};

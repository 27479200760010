import React, { useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CrossIcon, DownloadIcon, PrintIcon } from '@app/common/icons';
import {
  EmailDocumentTypeEnum,
  GetOneOperationResponseDto,
  PaymentOrderStatusEnum,
  PaymentOrderTypeEnum,
} from '@app/core/api';
import { ShareDocumentButton, SignPaymentOrder, TextFieldMultiline } from '@app/core/components';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { DATE_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/payment/show/BetweenMyAccount/styles';
import { RouteList } from '@app/src/constants/routeList';

type ShowBetweenMyAccountPaymentViewProps = {
  paymentOrder: GetOneOperationResponseDto;
};

export const ShowMobileBetweenMyAccountPaymentView: React.FC<ShowBetweenMyAccountPaymentViewProps> = ({
  paymentOrder,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const { details, amount, documentNumber, paymentReference, link, currency, uuid, documentType, status, valueDate } =
    paymentOrder;
  const paymentDocumentDate = details.date;
  const senderAccountNumber = details.senderAccount.accountNumber;
  const recipientAccountNumber = paymentOrder.details.recipientAccount.accountNumber;

  const showSignButtonByStatus: boolean =
    status === PaymentOrderStatusEnum.Processing || status === PaymentOrderStatusEnum.Rejected;

  const { mutate: mutateSendToSign, isLoading: isSendToSignLoading } = useSendToSignPaymentOrder();

  const downloadHandler = () => {
    window.open(`${link}&format=download`, '_blank');
  };

  const onSignClickHandler = () => {
    setShowSignModal(true);
  };

  const onSendToSignClickHandler = () => {
    mutateSendToSign(uuid);
    navigate(-1);
  };

  const onCloseSignModal = () => {
    setShowSignModal(false);
  };

  const onSuccessSignPayment = () => {
    setShowSignModal(false);
  };

  const closeHandler = () => {
    navigate(RouteList.operations);
  };

  return (
    <Grid container wrap="nowrap" direction="column" sx={styles.contentMobile}>
      <Typography variant="h6">
        <Grid container>
          <Grid item xs={11}>
            <Typography sx={styles.header}>
              {t(documentType)} № {documentNumber}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" onClick={closeHandler}>
              <CrossIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Typography>
      <Box mb={6} mt={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
        <Box pt={2}>
          <TextField
            variant="outlined"
            defaultValue={paymentDocumentDate ? format(new Date(paymentDocumentDate), DATE_FORMAT) : ''}
            disabled
            fullWidth
          />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_write_off_account')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={senderAccountNumber} disabled fullWidth />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
        <Box pt={2}>
          <TextField variant="outlined" defaultValue={recipientAccountNumber} disabled fullWidth />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
        <Box pt={2}>
          <TextField
            variant="outlined"
            defaultValue={parseFloat(`${amount}`).toFixed(2)}
            disabled
            fullWidth
            InputProps={{ endAdornment: currency }}
          />
        </Box>
      </Box>
      <Box mb={6}>
        <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
        <Box pt={2}>
          <TextFieldMultiline
            sx={{
              ...styles.textarea,
              '& textarea': {
                color: 'colors.gray300',
              },
            }}
            variant="outlined"
            multiline
            fullWidth
            rows={2}
            defaultValue={paymentReference}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      <Box mt={6}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
            <Box>
              <a href={link} target="_blank" rel="noreferrer">
                <IconButton size="small">
                  <PrintIcon sx={styles.actionIcon} />
                </IconButton>
              </a>
            </Box>
          ) : null}
          <Box ml={5}>
            <ShareDocumentButton
              link={link}
              styles={{
                color: 'colors.primary500',
              }}
              type={EmailDocumentTypeEnum.PaymentOrder}
              uuid={uuid}
            />
          </Box>
        </Grid>
      </Box>
      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
            <Box pr={4} width="40%">
              <Button fullWidth variant="outlined" color="primary" onClick={downloadHandler}>
                {t('download')}
              </Button>
            </Box>
          ) : null}
          {paymentOrder.actions.allowToSendToSign && !showSignButtonByStatus ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSendToSignClickHandler}>
                {t('paymentCreationFormControl_sendToSign')}
              </Button>
            </Box>
          ) : null}
          {paymentOrder.actions.allowToSign && !showSignButtonByStatus ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSignClickHandler}>
                {t('paymentCreationFormControl_send')}
              </Button>
            </Box>
          ) : null}
          {showSignModal ? (
            <SignPaymentOrder
              uuid={uuid}
              documentToSign={JSON.stringify(paymentOrder)}
              onSuccessPopupCancel={onSuccessSignPayment}
              onClose={onCloseSignModal}
            />
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, currencyOperationsApiClient, GetSignersResponseDto } from '@app/core/api';

export const getCurrencyExchangePaymentSignersFn = async (): Promise<GetSignersResponseDto> => {
  const result = await currencyOperationsApiClient.currencyOperationSignControllerGetSigners(getClientId());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the signers");
  }
  return result.data;
};

export const useGetCurrencyExchangePaymentSigners = (): UseQueryResult<GetSignersResponseDto> => {
  return useQuery<GetSignersResponseDto, ApiError>('peopleToSigne', getCurrencyExchangePaymentSignersFn, {
    enabled: true,
    refetchOnMount: true,
  });
};

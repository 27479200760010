import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { OperationTableRow } from './components/OperationTableRow';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageOptions,
} from '../../../../constants';
import { PaginationChangeType } from '../../../../types';
import { ComplexTable, TablePagination } from '../../../ComplexTable';
import { BodyLoader } from '../../components/BodyLoader';
import { styles } from '../../styles';
import { OperationsProps } from '../types';

export const DesktopOperationsList: React.FC<OperationsProps> = ({
  operationType,
  accounts,
  searchText,
  dateRange,
  getRecentOperations,
}) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState<string | undefined>(undefined);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { data, mutate, isLoading } = getRecentOperations();

  const operations = data?.operations;
  const pagination = data?.pagination;

  useEffect(() => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({
      accountIds: accounts?.map((account) => account.account.id) || undefined,
      operationType,
      queryText: searchText,
      pagination: paginationRef.current,
      dateRange,
    });
  }, [mutate, accounts, operationType, searchText, dateRange]);

  const showPagination = Boolean(
    data && data?.pagination.totalCount > statementsTableMinRowsForShowPagination && !isLoading
  );

  const onOpenExtendedContent = (id: string) => {
    setExpandedId(id);
  };

  const onPaginationChangeHandler = (newPagination: PaginationChangeType) => {
    paginationRef.current = newPagination;
    mutate({
      accountIds: accounts?.map((account) => account.account.id) || undefined,
      operationType,
      queryText: searchText,
      pagination: paginationRef.current,
      dateRange,
    });
  };

  const BodyContent: JSX.Element = useMemo(
    () => (
      <>
        {operations ? (
          <>
            {operations?.length ? (
              operations.map((operation) => {
                return (
                  <OperationTableRow
                    key={operation.id}
                    operation={operation}
                    open={operation.uuid === expandedId}
                    onOpenExtendedContent={onOpenExtendedContent}
                  />
                );
              })
            ) : (
              <Box sx={styles.noOperationsContainer}>
                <Typography variant="body2">{t('noOperationsForThisAccount')}</Typography>
              </Box>
            )}
          </>
        ) : null}
      </>
    ),
    [operations, t, expandedId]
  );

  const footerContent: JSX.Element = (
    <>
      {pagination ? (
        <TablePagination
          totalCount={pagination.totalCount}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          labelRowsPerPage={t('showOnPageShort')}
          rowsPerPageOptions={statementsTableRowsPerPageOptions}
          onChange={onPaginationChangeHandler}
          show={showPagination}
        />
      ) : null}
    </>
  );

  return (
    <ComplexTable
      body={{
        content: isLoading ? <BodyLoader /> : BodyContent,
        styles: {
          border: 'none',
        },
      }}
      footer={{ content: footerContent }}
    />
  );
};

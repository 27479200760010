import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { RepaymentCurrencyEnum, RepaymentTypeEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { IBanText } from '@app/core/components/IBanText';
import { LoanLinkedAccount } from '@app/core/components/LoanLinkedAccount';
import { SafetyTable } from '@app/core/components/SafetyTable';
import { StatusBadge } from '@app/core/components/StatusBadge';
import { WaitForAnotherLoanPaymentAlert } from '@app/core/components/WaitForAnotherLoanPaymentAlert';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { PaginationChangeType } from '@app/core/types';
import { formatAmount } from '@app/core/utils';
import { OperationsTable } from '@app/pages/loans/show/components/OperationsTable';
import { ScheduleTable } from '@app/pages/loans/show/components/ScheduleTable';
import {
  getLoanOperationFn,
  useGetLoanByUuid,
  getScheduleOfPayments,
  getCollateral,
} from '@app/pages/loans/show/query';
import { styles } from '@app/pages/loans/show/style';
import { LoansMenuItems } from '@app/pages/loans/types';
import { RouteList } from '@app/src/constants/routeList';

export const LoanShowPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<LoansMenuItems>(LoansMenuItems.operations);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { uuid = 'no-uuid' } = useParams();
  const { t } = useTranslation();
  const [outstanding, setOutstanding] = useState<boolean>(false);
  const [interests, setInterests] = useState<boolean>(false);
  const [overdueOutstanding, setOverdueOutstanding] = useState<boolean>(false);
  const [overdueInterests, setOverdueInterests] = useState<boolean>(false);
  const collateralPaginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  });

  const { data: loan, refetch: refetchLoan } = useGetLoanByUuid(uuid);
  const { data: operationsData, refetch: refetchOperations } = useQuery('loansOperations', () =>
    getLoanOperationFn(uuid, paginationRef.current)
  );
  const { data: collateralData, refetch: refetchCollateral } = useQuery('loansCollateral', () =>
    getCollateral(uuid, paginationRef.current)
  );
  const { data: scheduleData, refetch: refetchSchedule } = useQuery('loansSchedule', () => getScheduleOfPayments(uuid));

  const menuItems: MenuItem<LoansMenuItems>[] = loan?.security
    ? [
        {
          id: 1,
          value: LoansMenuItems.operations,
          labelText: t('operations'),
        },
        {
          id: 2,
          value: LoansMenuItems.schedule,
          labelText: t('schedule'),
        },
        {
          id: 3,
          value: LoansMenuItems.safety,
          labelText: t('safety'),
        },
      ]
    : [
        {
          id: 1,
          value: LoansMenuItems.operations,
          labelText: t('operations'),
        },
        {
          id: 2,
          value: LoansMenuItems.schedule,
          labelText: t('schedule'),
        },
      ];

  useEffect(() => {
    refetchLoan();
    refetchOperations();
  }, [refetchLoan, refetchOperations]);

  const paginationChangeHandler = (pagination: PaginationChangeType) => {
    paginationRef.current = pagination;
    refetchOperations();
  };

  const onTabChangeHandler = (tab: LoansMenuItems) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (loan) {
      setOutstanding(loan.repayments.repaymentTypes.includes(RepaymentTypeEnum.Outstanding));
      setInterests(loan.repayments.repaymentTypes.includes(RepaymentTypeEnum.Interests));
      setOverdueInterests(loan.repayments.repaymentTypes.includes(RepaymentTypeEnum.OverdueInterests));
      setOverdueOutstanding(loan.repayments.repaymentTypes.includes(RepaymentTypeEnum.OverdueOutstanding));
    }
  }, [loan]);

  const payClickHandler = (repaymentType: RepaymentTypeEnum) => {
    if (loan) {
      const baseUrl =
        loan.repayments.currencyType === RepaymentCurrencyEnum.National
          ? RouteList.payment_create
          : RouteList.loan_swift_create;
      const urlTo = queryString.stringifyUrl({
        url: baseUrl,
        query: {
          lid: loan.uuid,
          rt: repaymentType,
        },
      });
      navigate(urlTo);
    }
  };

  const startDate = loan?.agreementStartDate.split('-').reverse().join('.') || '';
  const endDate = loan?.agreementEndDate.split('-').reverse().join('.') || '';
  const dateInterestPayment = loan?.dateInterestPayment.split('-').reverse().join('.') || '';
  const dateOverdue = loan?.dateOverdue ? loan?.dateOverdue.split('-').reverse().join('.') : '';

  const outstandingBtnDisabled = loan?.repayments.blockedRepaymentTypes.includes(RepaymentTypeEnum.Outstanding);
  const interestsBtnDisabled = loan?.repayments.blockedRepaymentTypes.includes(RepaymentTypeEnum.Interests);
  const overdueInterestsBtnDisabled = loan?.repayments.blockedRepaymentTypes.includes(
    RepaymentTypeEnum.OverdueInterests
  );
  const overdueOutstandingBtnDisabled = loan?.repayments.blockedRepaymentTypes.includes(
    RepaymentTypeEnum.OverdueOutstanding
  );

  const showAlertMessage =
    outstandingBtnDisabled || interestsBtnDisabled || overdueInterestsBtnDisabled || overdueOutstandingBtnDisabled;

  const collateralPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    collateralPaginationRef.current = paginationChange;
    refetchCollateral();
  };

  return (
    <PageWrapper>
      <PageHeader title={`${t('loan')} № ${loan?.agreementNumber || ''}`} showNavigateButton backLink={-1}>
        <Grid item container justifyContent="flex-end" alignItems="center">
          {interests ? (
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.Interests)}
                disabled={interestsBtnDisabled}
              >
                {t('pay_interests')}
              </Button>
            </Box>
          ) : null}
          {overdueOutstanding ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.OverdueOutstanding)}
                disabled={overdueOutstandingBtnDisabled}
              >
                {t('pay_overdueOutstanding')}
              </Button>
            </Box>
          ) : null}
          {overdueInterests ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.OverdueInterests)}
                disabled={overdueInterestsBtnDisabled}
              >
                {t('pay_overdueInterests')}
              </Button>
            </Box>
          ) : null}
          {outstanding ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => payClickHandler(RepaymentTypeEnum.Outstanding)}
                disabled={outstandingBtnDisabled}
              >
                {t('pay_outstanding')}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </PageHeader>
      <Grid container direction="column">
        {showAlertMessage ? <WaitForAnotherLoanPaymentAlert /> : null}
        <Grid container direction="column" sx={styles.paper}>
          <Grid item container>
            <Grid item xs={8}>
              <Box pl={5} pt={5}>
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Typography sx={styles.companyName}>{`${t('contract')} ${loan?.agreementNumber}`}</Typography>
                  <Box pl={2}>
                    <StatusBadge status={loan?.state || ''} />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box pr={5} pt={5}>
                {loan ? <LoanLinkedAccount loan={loan} /> : null}
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Box mt={10} pl={5}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('startDate')}
                    </Typography>
                    <Typography variant="body2" sx={styles.bold}>
                      {startDate}
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('endDate')}
                    </Typography>
                    <Typography variant="body2" sx={styles.bold}>
                      {endDate}
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('interestRate')}
                    </Typography>
                    <Typography variant="body2" sx={styles.bold}>
                      {loan?.interestRate}
                      <Typography variant="body2" component="span">
                        %
                      </Typography>
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('accountOutstanding')}
                    </Typography>
                    <IBanText
                      styles={{
                        accountNumberPart1: { fontSize: '0.875rem' },
                        accountNumberPart2: { fontSize: '0.875rem' },
                      }}
                      text={loan?.accountOutstanding || ''}
                    />
                  </Grid>
                </Box>
                <Box mt={1} pr={6}>
                  <Grid container direction="column" justifyContent="space-between" alignItems="flex-end">
                    <Typography variant="caption" component="span" sx={styles.light}>
                      {t('amount')}
                    </Typography>
                    <Typography variant="body2" sx={styles.bold}>
                      {formatAmount(loan?.amount || '')}{' '}
                      <Typography variant="body2" component="span">
                        {loan?.currency}
                      </Typography>
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={styles.limitBlock}>
            <Box pl={5} pr={5}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('dateInterestPayment')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {dateInterestPayment}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3} sx={styles.debt}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('dateOverdue')}
                      </Typography>
                      <Typography variant="body2" sx={styles.bold}>
                        {dateOverdue}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mt={3}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Typography variant="caption" component="span" sx={styles.light}>
                        {t('outstanding')}
                      </Typography>
                      <Typography sx={styles.bold}>
                        {formatAmount(loan?.outstanding || '')}{' '}
                        <Typography variant="body2" component="span">
                          {loan?.currency}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('currentDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {loan ? formatAmount(loan?.outstanding + loan?.interestAccrued) : ''}{' '}
                <Typography variant="body2" component="span" sx={styles.bold}>
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography component="span" sx={styles.bold}>
                {t('overdueDebt')}
              </Typography>
              <Typography component="span" sx={styles.bold}>
                {loan && loan?.outstandingOverdue
                  ? formatAmount(loan?.interestOverdue + loan?.outstandingOverdue || '')
                  : formatAmount(loan?.interestOverdue || '')}{' '}
                <Typography variant="body2" component="span" sx={styles.bold}>
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestAccrual')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loan?.interestAccrued || '')}{' '}
                <Typography variant="body2" component="span">
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('interestOverdue')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loan?.interestOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.leftBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loan?.outstanding || '')}{' '}
                <Typography variant="body2" component="span">
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" xs={6} sx={styles.rightBlock}>
              <Typography variant="body2" component="span" sx={styles.light}>
                {t('amountOwned')}
              </Typography>
              <Typography variant="body2" component="span">
                {formatAmount(loan?.outstandingOverdue || '')}{' '}
                <Typography variant="body2" component="span">
                  {loan?.currency}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={7}>
          <HorizontalMenu<LoansMenuItems> onChange={onTabChangeHandler} menuItems={menuItems} />
        </Box>
        <Box mt={4} mb={4}>
          {currentTab === LoansMenuItems.operations && (
            <OperationsTable
              data={operationsData?.operations || []}
              onPaginationChange={paginationChangeHandler}
              pagination={operationsData?.pagination}
            />
          )}
          {currentTab === LoansMenuItems.safety && (
            <SafetyTable
              data={collateralData?.collateral || []}
              pagination={collateralData?.pagination}
              onPaginationChange={collateralPaginationChangeHandler}
            />
          )}
          {currentTab === LoansMenuItems.schedule && loan && (
            <ScheduleTable loanType={loan.loanType} scheduleOfPayments={scheduleData?.scheduleOfPayments || []} />
          )}
        </Box>
      </Grid>
    </PageWrapper>
  );
};

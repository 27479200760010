import React from 'react';

import { Grid, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

import { ScheduleDto } from '@app/core/api';
import { formatAmount } from '@app/core/utils';
import { ScheduleTableColumns } from '@app/pages/loans/show/components/ScheduleTable/types';

interface MakeTableColumnsProps {
  t(text: string): string;
}

export const makeLoanScheduleTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<ScheduleDto, any>[] => {
  return [
    {
      accessorKey: ScheduleTableColumns.paymentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`paymentDate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return <>{getValue() ? format(new Date(getValue()), 'dd.MM.yyyy') : '-'}</>;
      },
    },
    {
      accessorKey: ScheduleTableColumns.principalAmount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`principalAmount`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, number>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
    {
      accessorKey: ScheduleTableColumns.principalOverdue,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`overdueDebt`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, number>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
    {
      accessorKey: ScheduleTableColumns.interestRepaymentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`interestRepaymentDate_short`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return <>{getValue() ? format(new Date(getValue()), 'dd.MM.yyyy') : '-'}</>;
      },
    },
    {
      accessorKey: ScheduleTableColumns.interestAmount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`interestAmount`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
    {
      accessorKey: ScheduleTableColumns.interestOverdue,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`loanInterestOverdue_short`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
  ];
};

export const makeTrancheScheduleTableColumns = ({ t }: MakeTableColumnsProps): ColumnDef<ScheduleDto, any>[] => {
  return [
    {
      accessorKey: ScheduleTableColumns.paymentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`paymentDate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return <>{getValue() ? format(new Date(getValue()), 'dd.MM.yyyy') : '-'}</>;
      },
    },
    {
      accessorKey: ScheduleTableColumns.principalAmount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`principalAmount`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
    {
      accessorKey: ScheduleTableColumns.principalUnpaid,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`principalUnpaid`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<ScheduleDto, string>) => {
        return `${formatAmount(getValue())} UAH`;
      },
    },
  ];
};

import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUIState } from '@app/context/UIContext';
import { SignaturePopup, SignatureStrategyEnum } from '@app/core/components';
import { MutationStatus } from '@app/core/types';

import { useMultiSwiftSign } from './useMultipleSwiftSign';
import { DocumentSigned, DocumentToSign } from '../SignatureModal';
import { StatusPopup } from '../StatusPopup';

export interface SignMultipleSwiftProps {
  operations: { uuid: string }[];
  signFromAnotherPerson?: boolean;
  onSuccessPopupCancel(): void;
  onClose(status: MutationStatus): void;
}

export const SignMultipleSwift: React.FC<SignMultipleSwiftProps> = ({
  operations,
  signFromAnotherPerson,
  onSuccessPopupCancel,
  onClose,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const [documentsSigned, setDocumentsSigned] = useState<DocumentSigned[]>([]);
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);
  const [status, setStatus] = useState<MutationStatus>('idle');
  const signatureBelongsToUserRef = useRef<number | undefined>();
  const { mutate, data, isLoading } = useMultiSwiftSign();

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[], signatureBelongsToUser?: number) => {
    setDocumentsSigned(signedDocuments);
    signatureBelongsToUserRef.current = signatureBelongsToUser;
    mutate({ signBatchRequestDto: { operations: signedDocuments }, signatureBelongsToUser });
  };

  const documentsToSign: DocumentToSign[] = operations.map((document) => {
    return { uuid: document.uuid, documentToSign: JSON.stringify(document) };
  });

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    if (onSuccessPopupCancel) {
      onSuccessPopupCancel();
      return;
    }
    navigate(-1);
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSignModal(false);
    mutate({
      signBatchRequestDto: { operations: documentsSigned },
      signatureBelongsToUser: signatureBelongsToUserRef.current,
    });
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  useEffect(() => {
    if (data) {
      if (data?.unsigned?.length) {
        setStatus('error');
        setShowErrorSignModal(true);
      } else {
        setStatus('success');
        setShowSuccessSignModal(true);
      }
    }
  }, [data]);

  return (
    <>
      <SignaturePopup
        documentsToSign={documentsToSign}
        type={SignatureStrategyEnum.Swift}
        signFromAnotherPerson={signFromAnotherPerson}
        onSignedStringResult={onSignedStringResultHandler}
        onClose={() => onClose(status)}
      />
      <StatusPopup
        show={showSuccessSignModal}
        title={t('payments_sign_success_message')}
        text={
          data && operations.length > 1 ? (
            <div>
              <Typography variant="body2" component="span">
                {t('payments_sign_success_label')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {data.signedCount}/{documentsSigned.length}
              </Typography>
            </div>
          ) : undefined
        }
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={t('payments_sign_error_message')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

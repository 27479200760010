import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';

import { ThemeEnum } from '@app/core/types';
import { baseColors, baseThemeOptions, buildThemeOptions } from '@app/themes/default/baseTheme';

export const colors = {
  ...baseColors,
  // Main
  mainContentBackgroundColor: '#FAFAFA',
  // Base UI
  containerBackgroundColor: '#FFFFFF',
  mainContentBackgroundColorMobile: '#FFFFFF',
  // Company
  companyLogoBackgroundColor: baseColors.primary25,
  companyLogoTextColor: baseColors.primary600,
  userNameTextColor: baseColors.primary700,
  companyNameTextColor: baseColors.gray300,
  companyEdrpouLabelTextColor: baseColors.gray300,
  companyEdrpouTextColor: baseColors.primary700,
  settingsMenuBackground: baseColors.gray25,
  settingsMenuTextColor: baseColors.gray700,
  // Sidebar
  sidebarBackground: '#FFFFFF',
  menuItemText: baseColors.gray400,
  menuItemActiveText: baseColors.primary700,
  menuDivider: baseColors.gray100,
  companyIconsColor: baseColors.primary700,
  closeOpenArrowIconColor: baseColors.gray200,
  // Lang switcher
  langSwitcherBackground: baseColors.gray25,
  langSwitcherBorderColor: baseColors.gray50,
  // Currency panel
  currencyTabBackground: '#FFFFFF',
  currencyTabBorder: '#FFFFFF',
  currencyTabBorderHover: baseColors.gray200,
  currencyTabText: baseColors.gray300,
  currencyTabTextHover: baseColors.primary800,
  currencyTabSelectedBackground: '#FBFBFB',
  currencyTabSelectedText: baseColors.primary800,
  currencyInfoText: baseColors.gray500,
  currencyTabCurrencyName: baseColors.gray300,
  currencyTabCurrencyDescription: baseColors.gray300,
  currencyTabCurrencyInfoText: baseColors.gray500,
  // Tooltip
  tooltipDefaultBackground: baseColors.gray25,
  tooltipDefaultTextColor: baseColors.gray700,
  tooltipSecondaryBackground: baseColors.gray25,
  tooltipSecondaryTextColor: baseColors.gray700,

  // AccountSelect
  desktopAccountChipBackgroundColor: '#f3f9ff',
  desktopAccountChipBorderColor: '#e6f2fe',
  desktopAccountChipTextColor: '#677181',
  mobileAccountChipBackgroundColor: '#414E62',
  mobileAccountChipBorderColor: '#677181',
  mobileAccountChipTextColor: '#FFFFFF',
  mobileAccountErrorTextColor: '#FFFFFF',
};

export const lightThemeOptions: ThemeOptions = {
  themeName: ThemeEnum.light,
  radius: {
    ...baseThemeOptions.radius,
  },
  palette: {
    colors,
  },
};

export const lightTheme = createTheme(
  buildThemeOptions({
    colors,
    replaceOptions: lightThemeOptions,
  })
);

import React, { useRef } from 'react';

import { FormControl, AutocompleteValue } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteQueryField } from '@app/core/components/Form/controls/AutocompleteQueryField';
import { AccountSelectType } from '@app/core/types';
import { RecipientAccountInfo } from '@app/pages/payment/components/RecipientAccountSelect/components/RecipientAccountInfo';
import { recipientAccountQueryFn } from '@app/pages/payment/components/RecipientAccountSelect/query';

type SenderAccountSelectProps = {
  name: string;
  placeholder?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: string;
  disabled?: boolean;
};

export const RecipientAccountSelect: React.FC<SenderAccountSelectProps> = ({
  name,
  placeholder,
  rules,
  defaultValue,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const valueInInput = useRef<string>('');
  const accountSelectedFromList = useRef<boolean>(false);
  const { setValue } = useFormContext();

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    account: AccountSelectType,
    state: AutocompleteRenderOptionState
  ): React.ReactNode => {
    return <RecipientAccountInfo account={account} />;
  };

  const getOptionLabel = (option: AccountSelectType | string): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.account.accountNumber) {
      return option.account.accountNumber;
    }

    return '';
  };

  const onInputChangeHandler = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    valueInInput.current = value;
    accountSelectedFromList.current = false;
  };

  const onChangeHandler = (
    e: React.ChangeEvent<{}>,
    newValue: string | AutocompleteValue<AccountSelectType, false, true, true>,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof newValue === 'string') {
      setValue(name, newValue, { shouldValidate: true });
    } else if (newValue && newValue.account.accountNumber) {
      accountSelectedFromList.current = true;
      setValue(name, newValue.account.accountNumber, { shouldValidate: false });
    }
  };

  const onBlurHandler = () => {
    if (!accountSelectedFromList.current) {
      setValue(name, valueInInput.current, { shouldValidate: true });
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <AutocompleteQueryField<AccountSelectType, false, true, true>
        freeSolo
        autoHighlight
        disableClearable
        selectOnFocus={false}
        includeInputInList
        clearErrorOnFocus
        textFieldProps={{ placeholder: placeholder || t('paymentCreationLocalPayment_recipientAccount') }}
        name={name}
        defaultValue={defaultValue || ''}
        fetchFunction={recipientAccountQueryFn}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        isOptionEqualToValue={(account1, account2) => {
          return account1.account.accountNumber === account2.account.accountNumber;
        }}
        onInputChange={onInputChangeHandler}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
      />
    </FormControl>
  );
};

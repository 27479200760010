import React from 'react';

import { Box, useTheme } from '@mui/material';

import { useStyles } from './CurrencyTab.styles';

export type TabPanelProps = {
  value: number;
  index: number;
  children?: React.ReactNode;
};

export const CurrencyTabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box p={1} sx={styles.container}>
          {children}
        </Box>
      )}
    </Box>
  );
};

import React, { useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { ApiError, StatementTypeEnum, OutputFormatEnum } from '@app/core/api';
import { BalancesMovementInfo } from '@app/core/components/Statements/components/SelectedAccountsInfo';
import { StatementsTable } from '@app/core/components/Statements/components/StatementsTable';
import { makeSubFilter } from '@app/core/components/Statements/helpers';
import {
  OperationsFromPeriodResult,
  StatementsFilterField,
  StatementsFilterFormData,
  StatementsSubFilterEnum,
  StatementsTableColumnsEnum,
} from '@app/core/components/Statements/types';
import { TableSkeleton } from '@app/core/components/TableSkeleton';
import { DEFAULT_FIRST_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { useGetOperationsFile } from '@app/core/hooks';
import { ColumnFilterType, CurrencyEnum, PaginationChangeType, SortingRule } from '@app/core/types';
import { parseJSON } from '@app/core/utils';
import { StatementsFilters } from '@app/pages/statements/components/StatementsFilters';
import { statementsFilterSchema } from '@app/pages/statements/components/StatementsFilters/validationSchema';
import { resetFormData } from '@app/pages/statements/constants';
import { StatementsFilterWatch } from '@app/pages/statements/desktop/StatementsFilterWatch';
import { makeDataForGetStatementsFile, makeDataForSubmit } from '@app/pages/statements/helpers';
import { getStatements, operationsControllerUpdateStatementsFn } from '@app/pages/statements/query';
import { StatementsFilter, StatementsViewProps } from '@app/pages/statements/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const StatementsViewDesktop: React.FC<StatementsViewProps> = ({ defaultValues }) => {
  const { statementsTableConfig } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const columnFilter = useRef<ColumnFilterType<StatementsSubFilterEnum>>({});
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const sortRuleRef = useRef<SortingRule>({ field: StatementsTableColumnsEnum.operationDate, desc: true });
  const methods = useForm<StatementsFilterFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(statementsFilterSchema(t)),
  });

  const {
    data,
    mutate,
    reset: mutationReset,
    isLoading,
  } = useMutation<OperationsFromPeriodResult, ApiError, StatementsFilter>(getStatements);
  const { getOperationsFile } = useGetOperationsFile();

  const updateList = () => {
    mutate({
      ...makeDataForSubmit(methods.getValues()),
      subFilter: makeSubFilter(columnFilter.current),
      pagination: paginationRef.current,
      sortBy: sortRuleRef.current,
    });
  };

  const onSubmitHandler = () => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (isLoading) {
      return;
    }
    updateList();
  };

  const resetFormHandler = () => {
    methods.reset(resetFormData);
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutationReset();
    history.replaceState(null, '', '?');
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateList();
    }
  };

  const onSortHandler = (rule: SortingRule) => {
    sortRuleRef.current = rule;
    updateList();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateList();
  };

  const accountBalancesMovement = data?.accountBalancesMovement;

  const onSignDocumentsSuccess = () => {
    updateList();
  };
  const onDeleteDocumentSuccessHandler = () => {};

  const onDownloadOperationsFileHandler = ({
    operationsUuids,
    outputType,
    format,
  }: {
    operationsUuids: string[];
    outputType: string;
    format?: string;
  }) => {
    methods.trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        getOperationsFile({
          operationsUuids,
          format,
          outputType,
          getOperationsFileValues: makeDataForGetStatementsFile({
            outputType: outputType as OutputFormatEnum,
            documentType: StatementTypeEnum.Statement,
            formData: methods.getValues(),
            subFilter: makeSubFilter(columnFilter.current),
          }),
        });
      }
    });
  };

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<StatementsFilterField>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (submit) {
      methods.trigger().then((valid) => {
        if (isLoading) {
          return;
        }
        if (valid) {
          updateList();
        }
      });
    }
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    methods.trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        updateList();
      }
    });
  };

  return (
    <>
      <StatementsFilterWatch formMethods={methods} onFilterReady={updateList} />
      <PageWrapper>
        <Grid container direction="column">
          <PageHeader title={t('statementsPageHeaderTitle')} />
          <PageContainer>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              sx={{
                height: '100%',
              }}
            >
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmitHandler)}
                  onKeyPress={onKeyPressHandler}
                  role="presentation"
                  style={{
                    width: '100%',
                  }}
                >
                  <StatementsFilters defaultValues={defaultValues} resetFormHandler={resetFormHandler} />
                </form>
              </FormProvider>
              <Grid
                item
                container
                direction="column"
                wrap="nowrap"
                sx={{
                  height: '100%',
                }}
              >
                <Box pb={2}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'colors.primary600',
                    }}
                  >
                    {t('statementsMenuOperations')}
                  </Typography>
                </Box>
                <Box pb={2}>
                  {accountBalancesMovement ? (
                    <BalancesMovementInfo accountBalancesMovement={accountBalancesMovement} />
                  ) : null}
                </Box>
                {statementsTableConfig.length > 0 ? (
                  <StatementsTable
                    operationsFromPeriod={data}
                    loading={isLoading}
                    columnsConfigMutationFn={operationsControllerUpdateStatementsFn}
                    onSort={onSortHandler}
                    onPaginationChange={onPaginationChangeHandler}
                    queryKey={StatementTypeEnum.Statement}
                    columnFilter={columnFilter.current}
                    onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
                    currencyOptions={Object.values(CurrencyEnum)}
                    columnsConfig={statementsTableConfig}
                    onSendToSignDocumentsSuccess={onSignDocumentsSuccess}
                    onSignDocumentsSuccess={onSignDocumentsSuccess}
                    onDownloadOperationsFile={onDownloadOperationsFileHandler}
                    onColumnFilterChange={onColumnFilterChangeHandler}
                    onCancelDocumentSuccess={() => {}}
                    onClearSubFilter={onClearSubFilterHandler}
                  />
                ) : (
                  <TableSkeleton showActionsColumn showSelectionColumn />
                )}
              </Grid>
            </Grid>
          </PageContainer>
        </Grid>
      </PageWrapper>
    </>
  );
};

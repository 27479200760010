export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  itemWrapper: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    lineHeight: 1.5,
    paddingRight: '32px',
  },
  itemText: {
    userSelect: 'none',
    cursor: 'pointer',
    color: 'colors.gray400',
    fontWeight: 600,
  },
  selected: {
    color: 'colors.primary600',
  },
  disabled: {
    cursor: 'auto',
    color: 'colors.gray200',
  },
  bullet: {
    display: 'inline-block',
    width: '8px',
    height: '8px',
    marginRight: '4px',
    border: `2px solid`,
    borderColor: 'colors.success',
    borderRadius: '50%',
  },
  bulletBlock: {
    borderColor: 'colors.warning',
  },
  bulletClose: {
    borderColor: 'colors.error',
  },
};

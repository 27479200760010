import React, { useRef, useState } from 'react';

import { Fab, MenuItem, Popover } from '@mui/material';

import { CrossIcon, PlusIcon } from '@app/common/icons';

export type FloatMenuOption = {
  id: number | string;
  action(): void;
  content: React.ReactNode;
};

type FloatMenuProps = {
  options: FloatMenuOption[];
};

export const FloatMenu: React.FC<FloatMenuProps> = ({ options }) => {
  const [open, setOpen] = useState<boolean>(false);
  const inputItemRef = useRef(null);

  const onActionButtonClickHandler = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (action: () => void) => {
    return () => {
      action();
      setOpen(false);
    };
  };

  return (
    <>
      <Fab
        color="secondary"
        ref={inputItemRef}
        aria-label="edit"
        sx={{
          position: 'fixed',
          backgroundColor: 'colors.gray600',
          bottom: '24px',
          right: '16px',
          zIndex: 999,
        }}
        onClick={onActionButtonClickHandler}
      >
        {open ? <CrossIcon /> : <PlusIcon />}
      </Fab>

      <Popover
        anchorEl={inputItemRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {options.map((item) => {
          return (
            <MenuItem
              key={item.id}
              onClick={handleMenuItemClick(item.action)}
              sx={{
                borderBottom: `1px solid`,
                borderColor: 'colors.gray50',
                '&:last-child': {
                  borderBottom: 'none',
                },
              }}
            >
              {item.content}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

import React from 'react';

import { Tab, TabProps, useTheme } from '@mui/material';

import { useStyles } from './CurrencyTab.styles';

export const CurrencyTab: React.FC<TabProps & { disableFocus?: boolean }> = ({ disableFocus, ...props }) => {
  const theme = useTheme();
  const styles = useStyles(theme, disableFocus);
  return <Tab sx={{ '&.MuiTab-root': styles.root, '&.Mui-selected': styles.selected }} {...props} />;
};

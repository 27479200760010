import { useMutation, UseMutationResult } from 'react-query';

import { ApiError, getClientId, paymentOrdersApiClient, SendToSignResponseDto } from '@app/core/api';

export const useSendToSignPaymentOrder = (): UseMutationResult<SendToSignResponseDto, ApiError, string> =>
  useMutation(async (uuid: string) => {
    const result = await paymentOrdersApiClient.paymentOrderControllerSendDocumentsToSign(getClientId(), {
      uuids: [uuid],
    });

    if (result.status !== 200) {
      throw new ApiError(result.request.status, 'Error send to sign payment!');
    }
    return result.data;
  });

import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, DeleteIcon } from '@app/common/icons';
import { DeleteDocumentType, ModalCommon } from '@app/core/components';
import { styles } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanySettings/components/DropNumberModal/style';
import { ReactComponent as NumberIconSvg } from '@app/themes/default/assets/icons/numbers.svg';

interface DropNumberModalProps {
  onSubmit(): void;
  onCancelDeleteDocument(): void;
}

export const DropNumberModal: React.FC<DropNumberModalProps> = ({ onCancelDeleteDocument, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalCommon onClose={onCancelDeleteDocument} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <NumberIconSvg />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDeleteDocument}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={7}>
          <Typography variant="body1" sx={styles.title}>
            {t('dropNumberTitle')}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body1" sx={styles.text}>
            {t('changeNumberConfigQuestion')}
          </Typography>
        </Box>
        <Box mb={7}>
          <Typography variant="body1" sx={styles.text}>
            {t('changeNumberConfigDescription')}
          </Typography>
        </Box>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDeleteDocument}>
              {t('cancel')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onSubmit}>
            {t('resetCounter')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

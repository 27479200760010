import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, FrontLanguageEnum, GetOneMessageResponseDto } from '@app/core/api';

type GetOneMessageDto = {
  uuid: string;
  language?: FrontLanguageEnum;
};

const getOneMessageFn = async ({ uuid, language }: GetOneMessageDto): Promise<GetOneMessageResponseDto> => {
  const result = await messagesApiClient.messagesControllerGetOne(uuid, getClientId(), language);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get message");
  }

  return result.data;
};

export const useGetOneMessage = (): UseMutationResult<GetOneMessageResponseDto, ApiError, GetOneMessageDto> => {
  return useMutation<GetOneMessageResponseDto, ApiError, GetOneMessageDto>(getOneMessageFn);
};

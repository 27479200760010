import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, currencyOperationsApiClient, getClientId, SendToSignResponseDto } from '@app/core/api';

const sendToSigCurrencyOperationFn = async (uuids: string[]): Promise<SendToSignResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerBatchSendToSign(getClientId(), {
    uuids,
  });
  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerBatchSendToSign error. Can't send to sign."
    );
  }

  return response.data;
};

export const useSendToSignCurrencyOperation = (): UseMutationResult<SendToSignResponseDto, ApiError, string[]> => {
  return useMutation<SendToSignResponseDto, ApiError, string[]>(sendToSigCurrencyOperationFn);
};

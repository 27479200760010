export const styles = {
  form: {
    width: '100%',
  },
  header: {
    fontSize: '1rem',
    color: 'colors.primary700',
    fontWeight: 'bold',
    marginRight: '4',
  },
  flexbox: {
    display: 'flex',
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  bookmarkBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bookmarkText: {
    fontSize: '1rem',
    color: 'colors.gray300',
    marginRight: '14px',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
    '& > span': {
      paddingRight: '4px',
    },
  },
  formBody: {
    width: '100%',
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
    minWidth: '600px',
  },
  documentNumber: {
    height: '50px',
  },
  cardContent: {
    width: '100%',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  cancelBtn: {
    background: 'none',
  },
  info: {
    color: 'colors.primary500',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  dropZoneContainer: {
    backgroundColor: '#FFFFFF',
    border: `1.5px dashed`,
    borderColor: 'colors.primary200',
    borderRadius: '8px',
    padding: '20px',
    minHeight: '80px',
  },
  selectFileFromDiskText: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: 'colors.primary500',
    textAlign: 'center',
    cursor: 'pointer',
  },
  maxFileSize: {
    color: 'colors.gray300',
  },
  dropContainerActive: {
    backgroundColor: 'colors.primary25',
  },
  noAttachedFilesLabel: {
    paddingLeft: '8',
    color: 'colors.gray400',
  },
  icon: {
    color: 'colors.gray500',
  },
  iconDelete: {
    color: 'colors.gray500',
    cursor: 'pointer',
  },
  fileName: {
    color: 'colors.gray500',
    fontSize: '0.75rem',
    paddingLeft: 8,
  },
  fileSize: {
    color: 'colors.gray400',
    fontSize: '0.75rem',
    paddingRight: '8px',
  },
  selectPlaceholder: {
    color: 'colors.gray300',
    fontWeight: 400,
  },
};

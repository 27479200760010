import React from 'react';

import { useTranslation } from 'react-i18next';

import logoLargeEnDark from '@app/themes/default/assets/logo/dark/logo_large_en.svg';
import logoLargeRuDark from '@app/themes/default/assets/logo/dark/logo_large_ru.svg';
import logoLargeUaDark from '@app/themes/default/assets/logo/dark/logo_large_ua.svg';
import logoSmallEnDark from '@app/themes/default/assets/logo/dark/logo_small_en.svg';
import logoSmallRuDark from '@app/themes/default/assets/logo/dark/logo_small_ru.svg';
import logoSmallUaDark from '@app/themes/default/assets/logo/dark/logo_small_ua.svg';
import logoLargeEnLight from '@app/themes/default/assets/logo/light/logo_large_en.svg';
import logoLargeRuLight from '@app/themes/default/assets/logo/light/logo_large_ru.svg';
import logoLargeUaLight from '@app/themes/default/assets/logo/light/logo_large_ua.svg';
import logoSmallEnLight from '@app/themes/default/assets/logo/light/logo_small_en.svg';
import logoSmallRuLight from '@app/themes/default/assets/logo/light/logo_small_ru.svg';
import logoSmallUaLight from '@app/themes/default/assets/logo/light/logo_small_ua.svg';
import { useTheme } from '@app/themes/ThemeProvider';

import { ThemeEnum, Langs } from '../../types';

type LogoProps = {
  size: 'small' | 'large';
};

enum LogoSize {
  small = 'small',
  large = 'large',
}

type LogoListType = {
  [key: string]: {
    [lang in Langs]: { [size in LogoSize]: string };
  };
};

const logoList: LogoListType = {
  [ThemeEnum.dark]: {
    EN: {
      small: logoSmallEnDark,
      large: logoLargeEnDark,
    },
    UA: {
      small: logoSmallUaDark,
      large: logoLargeUaDark,
    },
  },
  [ThemeEnum.light]: {
    EN: {
      small: logoSmallEnLight,
      large: logoLargeEnLight,
    },
    UA: {
      small: logoSmallUaLight,
      large: logoLargeUaLight,
    },
  },
};

export const Logo: React.FC<LogoProps> = ({ size }) => {
  const { t, i18n } = useTranslation();
  const currentLang: Langs = i18n.language as Langs;
  const { currentTheme } = useTheme();
  return <img src={logoList[currentTheme][currentLang][size]} alt={t('bankName')} />;
};

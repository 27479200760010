import * as React from 'react';

import { Typography } from '@mui/material';

import { Counterparty } from '@app/core/types';

type AccountInfoProps = {
  counterparty: Counterparty;
};

export const RecipientNameInfo: React.FC<AccountInfoProps> = ({ counterparty }) => {
  return (
    <Typography variant="caption">
      {counterparty.name}, {counterparty.bank}
    </Typography>
  );
};

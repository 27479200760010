import React from 'react';

import { useTranslation } from 'react-i18next';

import { GetListRequestDtoProductTypeEnum } from '@app/core/api';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { SelectOptionType } from '@app/core/components/Select';
import { DepositsTypeSelect } from '@app/pages/deposits/types';

export type DepositTypeSelectFieldProps = {
  name: string;
};

export const DepositTypeSelectField: React.FC<DepositTypeSelectFieldProps> = ({ name }) => {
  const { t } = useTranslation();
  const depositListItems: SelectOptionType[] = [
    {
      id: DepositsTypeSelect.deposit,
      name: t(`deposits_${DepositsTypeSelect.deposit}`),
      value: GetListRequestDtoProductTypeEnum.Deposit,
    },
    {
      id: DepositsTypeSelect.depositLine,
      name: t(`deposits_${DepositsTypeSelect.depositLine}`),
      value: GetListRequestDtoProductTypeEnum.DepositFacility,
    },
  ];

  return <SelectField name={name} options={depositListItems} fullWidth />;
};

import { addDays, isDate, parse } from 'date-fns';
import { date, object, SchemaOf, string } from 'yup';

import { DATE_FORMAT } from '@app/core/utils';
import { SwiftFormFields } from '@app/pages/swift/types';

type SwiftValidationSchemaProps = {
  t: (key: string) => string;
};

type SchemaValidationObject = {
  [SwiftFormFields.recipientAccount]: string;
  [SwiftFormFields.amount]: string;
  [SwiftFormFields.senderAccount]: string;
  [SwiftFormFields.documentDate]: Date;
  [SwiftFormFields.paymentReference]: string;
};

export const validationSchema = ({ t }: SwiftValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  const maxDocumentDate = addDays(new Date(), 30);
  const parseDateString = (value: string, originalValue: string) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, DATE_FORMAT, new Date());
  };
  return object().shape({
    [SwiftFormFields.recipientAccount]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.amount]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.senderAccount]: string().required(t('swiftCreationPayment_field_required')),
    [SwiftFormFields.documentDate]: date()
      .required(t('swiftCreationPayment_field_required'))
      .typeError(t('paymentDocumentDate_correct_date'))
      .transform(parseDateString)
      .max(maxDocumentDate, t('paymentDocumentDate_correct_date')),
    [SwiftFormFields.paymentReference]: string()
      .required(t('swiftCreationPayment_field_required'))
      .trim()
      .min(4, t('swiftCreationPayment_field_minLetters')),
  });
};

import React, { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

export const DateColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: 'colors.gray400',
      }}
    >
      {children}
    </Typography>
  );
};

export const VisitColumn: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: 'colors.gray600',
      }}
    >
      {children}
    </Typography>
  );
};

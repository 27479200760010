import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DownloadIcon, InfoIcon, KeyIcon, PrintIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import {
  EmailDocumentTypeEnum,
  GetOneOperationResponseDto,
  PaymentOrderStatusEnum,
  PaymentOrderTypeEnum,
} from '@app/core/api';
import { ShareDocumentButton, SignPaymentOrder, TextFieldMultiline, Tooltip } from '@app/core/components';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { DATE_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/payment/show/BetweenMyAccount/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

type ShowBetweenMyAccountPaymentViewProps = {
  paymentOrder: GetOneOperationResponseDto;
};

export const ShowBetweenMyAccountPaymentView: React.FC<ShowBetweenMyAccountPaymentViewProps> = ({ paymentOrder }) => {
  const { showLoader } = useUIState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const { details, amount, documentNumber, paymentReference, link, currency, uuid, documentType, status, valueDate } =
    paymentOrder;
  const senderAccountNumber = details.senderAccount.accountNumber;
  const paymentDocumentDate = details.date;
  const paymentDocumentValueDate = valueDate;
  const recipientAccountNumber = paymentOrder.details.recipientAccount.accountNumber;

  const { mutate: mutateSendToSign, isLoading: isSendToSignLoading } = useSendToSignPaymentOrder();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeHandler = () => {
    navigate(RouteList.operations);
  };

  const downloadHandler = () => {
    window.open(`${link}&format=download`, '_blank');
  };

  const onSignClickHandler = () => {
    setShowSignModal(true);
  };

  const onCloseSignModal = () => {
    setShowSignModal(false);
  };

  const onSuccessSignPayment = () => {
    setShowSignModal(false);
  };

  const onSendToSignClickHandler = () => {
    mutateSendToSign(uuid);
    navigate(-1);
  };

  useEffect(() => {
    showLoader(isSendToSignLoading);
  }, [isSendToSignLoading, showLoader]);

  const showSignButtonByStatus: boolean =
    status === PaymentOrderStatusEnum.Processing || status === PaymentOrderStatusEnum.Rejected;

  return (
    <>
      <Grid sx={styles.tabBody}>
        <Grid container>
          <Grid container item xs={8}>
            <Typography variant="h6">
              <Grid container>
                <Typography sx={styles.header}>
                  {t(documentType)} № {documentNumber}
                </Typography>
              </Grid>
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={4}>
            {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
              <Box>
                <a href={link} target="_blank" rel="noreferrer">
                  <IconButton size="small">
                    <PrintIcon sx={styles.actionIcon} />
                  </IconButton>
                </a>
              </Box>
            ) : null}
            <Box ml={5}>
              <ShareDocumentButton
                link={link}
                styles={{
                  color: 'colors.primary500',
                }}
                type={EmailDocumentTypeEnum.PaymentOrder}
                uuid={uuid}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <CardContent>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField
                        variant="outlined"
                        defaultValue={paymentDocumentDate ? format(new Date(paymentDocumentDate), DATE_FORMAT) : ''}
                        disabled
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  {paymentDocumentValueDate ? (
                    <>
                      <Grid item xs={3}>
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
                        <Box pt={2} pl={1} pr={8}>
                          <TextField
                            variant="outlined"
                            defaultValue={format(new Date(paymentDocumentValueDate), DATE_FORMAT)}
                            disabled
                            fullWidth
                          />
                        </Box>
                      </Grid>
                      <Grid item container alignItems="center" xs={1}>
                        <Tooltip
                          color="primary"
                          title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                          arrow
                          placement="right"
                        >
                          <Box mt={8}>
                            <InfoIcon fontSize="small" sx={styles.info} />
                          </Box>
                        </Tooltip>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Box>
              <Box mb={6}>
                <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_write_off_account')}</FormLabel>
                <Box pt={2}>
                  <TextField variant="outlined" defaultValue={senderAccountNumber} disabled fullWidth />
                </Box>
              </Box>
              <Box mb={6}>
                <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
                <Box pt={2}>
                  <TextField variant="outlined" defaultValue={recipientAccountNumber} disabled fullWidth />
                </Box>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                    <Grid container>
                      <Box pt={2}>
                        <TextField
                          variant="outlined"
                          defaultValue={parseFloat(`${amount}`).toFixed(2)}
                          disabled
                          fullWidth
                          InputProps={{ endAdornment: currency }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={6}>
                <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
                <Box pt={2}>
                  <TextFieldMultiline
                    sx={{
                      ...styles.textarea,
                      '& textarea': {
                        color: 'colors.gray300',
                      },
                    }}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={2}
                    defaultValue={paymentReference}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={styles.cardAction}>
              <Button variant="contained" color="primary" onClick={closeHandler}>
                {t('close')}
              </Button>
              <Box sx={styles.cardAction}>
                {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ...styles.submitBtn, marginRight: '28px' }}
                    endIcon={<DownloadIcon />}
                    onClick={downloadHandler}
                  >
                    {t('download')}
                  </Button>
                ) : null}
                {paymentOrder.actions.allowToSendToSign && !showSignButtonByStatus ? (
                  <Button variant="contained" color="primary" onClick={onSendToSignClickHandler}>
                    {t('paymentCreationFormControl_sendToSign')}
                  </Button>
                ) : null}
                {paymentOrder.actions.allowToSign && !showSignButtonByStatus ? (
                  <Button variant="contained" color="primary" onClick={onSignClickHandler}>
                    {t('paymentCreationFormControl_send')}
                    <KeyIcon sx={{ marginLeft: '8px' }} />
                  </Button>
                ) : null}
              </Box>
            </CardActions>
          </Grid>
        </Grid>
      </Grid>
      {showSignModal ? (
        <SignPaymentOrder
          uuid={uuid}
          documentToSign={JSON.stringify(paymentOrder)}
          onSuccessPopupCancel={onSuccessSignPayment}
          onClose={onCloseSignModal}
        />
      ) : null}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  DeleteSwiftOperationsRequestDto,
  DeleteSwiftOperationsResponseDto,
  getClientId,
  swiftApiClient,
} from '@app/core/api';

const deleteSwiftOperationFn = async (
  uuids: DeleteSwiftOperationsRequestDto
): Promise<DeleteSwiftOperationsResponseDto> => {
  const response = await swiftApiClient.swiftControllerDeleteSwiftOperations(getClientId(), uuids);

  if (response.status !== 200) {
    throw new Error("Can't delete a document.");
  }
  return response.data;
};

export const useDeleteSwiftOperation = (): UseMutationResult<
  DeleteSwiftOperationsResponseDto,
  ApiError,
  DeleteSwiftOperationsRequestDto
> => {
  return useMutation<DeleteSwiftOperationsResponseDto, ApiError, DeleteSwiftOperationsRequestDto>(
    deleteSwiftOperationFn
  );
};

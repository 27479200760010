import React, { createContext, useContext, ReactNode, useState } from 'react';

import { isMobile } from '@app/core/utils';

interface UIState {
  mainLoaderShowed: boolean;
  sidebarOpen: boolean;
  showLoader(show: boolean): void;
  setSidebarOpen(open: boolean): void;
}
const UIContext = createContext<UIState | undefined>(undefined);

const UIProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [mainLoaderShowed, showLoader] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(!isMobile);

  return (
    <UIContext.Provider value={{ mainLoaderShowed, showLoader, sidebarOpen, setSidebarOpen }}>
      {children}
    </UIContext.Provider>
  );
};

const useUIState = (): UIState => {
  const state = useContext(UIContext);
  if (state === undefined) {
    throw new Error('useUIState must be used within a UIProvider');
  }
  return state;
};

export { UIProvider, useUIState };

import React, { useState } from 'react';

import { Box, TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, InfoIcon, UpChevronSmallIcon } from '@app/common/icons';
import { ExpandedTableRow, Tooltip } from '@app/core/components';
import { styles as tableStyles } from '@app/core/components/OperationsTable/styles';

import { DefaultNationalityAccountSetting } from './components/DefaultNationalityAccountSetting';
import { styles } from './styles';

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const handleExpand = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ maxWidth: '1000px' }}>
      <ExpandedTableRow
        open={open}
        onExpand={handleExpand}
        sx={{ ...tableStyles.tableRow, ...(open && tableStyles.tableRowExpanded) }}
        extendedContend={<DefaultNationalityAccountSetting />}
      >
        <TableCell component="div" sx={{ ...tableStyles.tableCell }}>
          <Typography variant="body2" sx={styles.cellTitle}>
            {t('uahTransactions')}
          </Typography>
          <Tooltip color="primary" title={t('')} arrow placement="right">
            <Box pt={1}>
              <InfoIcon fontSize="small" sx={styles.info} />
            </Box>
          </Tooltip>
        </TableCell>
        <TableCell component="div" sx={{ ...tableStyles.tableCell, ...tableStyles.actionCell }}>
          {open ? (
            <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
          ) : (
            <DownChevronSmallIcon sx={styles.chevronIcon} fontSize="small" />
          )}
        </TableCell>
      </ExpandedTableRow>
    </Box>
  );
};

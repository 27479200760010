export const styles = {
  actionsContainer: {
    padding: '5px 16px 5px 0',
  },
  selectedActionsContainer: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '5px 16px',
  },
  selectedCount: {
    color: 'white',
    marginRight: '16px',
  },
  importCountTitle: {
    color: 'colors.gray400',
  },
  importCount: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  totalAmountTitle: {
    color: 'colors.gray400',
  },
  totalAmount: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  totalAmountCurrency: {
    color: 'colors.primary700',
    fontWeight: 400,
  },
  buttonRoot: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  divider: {
    height: '20px',
    backgroundColor: 'colors.gray100',
    marginRight: 'px',
    width: '1px',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
  },
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, CardContent, FormLabel, Grid } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, isEqual } from 'lodash-es';
import { parse } from 'query-string';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AccountDirectionTypeEnum, FrontLanguageEnum } from '@app/core/api';
import { CurrencyAutocomplete } from '@app/core/components';
import { ActionFiltersButtons } from '@app/core/components/ActionFiltersButtons';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useGetAccountsForCurrencyExchange } from '@app/core/hooks/useGetAccountsForCurrencyExchange';
import { useGetClientCurrencyList } from '@app/core/hooks/useGetFilterCurrencyList';
import { useGetLegalUser } from '@app/core/hooks/usegetLegalUser';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrencyExchangeFilter/style';
import {
  CurrencyExchangeFilterField,
  CurrencyExchangeFilterFormData,
} from '@app/pages/exchange/currencyExchangeTransactions/types';

interface DepositFilterProps {
  fullItems: boolean;
  namesFieldWithError?: string[];
  resetFormHandler(): void;
}

export const CurrencyExchangeFilter: React.FC<DepositFilterProps> = ({
  fullItems,
  namesFieldWithError,
  resetFormHandler,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { setValue, getValues, watch, formState } = useFormContext<CurrencyExchangeFilterFormData>();
  const urlQueryRef = useRef<{ [key: string]: string }>({});
  const nowDate = new Date();

  const { data: legalUsersData, mutate: mutateLegaUser } = useGetLegalUser();

  const { data: depositAccountsData, mutate: mutateDepsitAccountsData } = useGetAccountsForCurrencyExchange();

  const { data: withdrawalAccountsData, mutate: mutateWithdravalAccountsData } = useGetAccountsForCurrencyExchange();

  const { data: clientCurrencyList, mutate: mutateClientCurrency } = useGetClientCurrencyList();

  useEffect(() => {
    mutateLegaUser({});
    mutateDepsitAccountsData({ accountType: AccountDirectionTypeEnum.Withdrawal });
    mutateWithdravalAccountsData({ accountType: AccountDirectionTypeEnum.Withdrawal });
    mutateClientCurrency({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n, mutateClientCurrency, mutateDepsitAccountsData, mutateLegaUser, mutateWithdravalAccountsData]);

  const [minValuationDate, setMinValuationDate] = useState<Date>(nowDate);

  const watchDateFrom = watch(CurrencyExchangeFilterField.dateFrom);

  const watchFields = watch();

  useEffect(() => {
    const { dateFrom: urlDateFrom } = parse(location.search);
    if (
      urlDateFrom &&
      watchDateFrom &&
      format(new Date(urlDateFrom as string), ddMMYYYYFormat.format) === format(watchDateFrom, ddMMYYYYFormat.format)
    ) {
      return;
    }
    if (watchDateFrom) {
      setValue(CurrencyExchangeFilterField.temp_dateTo, '');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(CurrencyExchangeFilterField.dateTo, undefined);
      setMinValuationDate(watchDateFrom);
    }
  }, [setValue, watchDateFrom]);

  useEffect(() => {
    if (watchFields && formState.isDirty) {
      const { dateFrom, dateTo, temp_dateFrom: tempDateFrom, temp_dateTo: tempDateTo, ...otherValues } = getValues();
      const fieldsArr = Object.entries(otherValues).filter(([key, value]) => {
        return !isEmpty(value);
      });
      const fieldsForUrl = Object.fromEntries(fieldsArr);
      const urlQueryObj: { [key: string]: any } = { ...fieldsForUrl };

      if (dateFrom) {
        urlQueryObj['dateFrom'] = dateFrom.toISOString();
      }
      if (dateTo) {
        urlQueryObj['dateTo'] = dateTo.toISOString();
      }
      if (!isEqual(urlQueryObj, urlQueryRef.current)) {
        urlQueryRef.current = urlQueryObj;
        setSearchParams(urlQueryObj, { replace: true });
      }
    }
  }, [formState.isDirty, getValues, searchParams, setSearchParams, watchFields]);

  const legalUsers = legalUsersData?.map((user) => {
    return { id: user.id, name: `${user.lastName} ${user.firstName}`, value: user.id };
  });

  const getCurrencyList = () => {
    const currencyList = clientCurrencyList?.map((currency, index) => {
      return {
        id: index,
        name: currency.alpha3,
        value: currency.alpha3,
      };
    });

    currencyList?.unshift({ id: 99, name: t('all'), value: '' });

    return currencyList;
  };

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('currencyExchange_startDateFrom')}</FormLabel>
              <Box pt={2}>
                <DateSelectField name={CurrencyExchangeFilterField.dateFrom} inputProps={{ fullWidth: true }} />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('currencyExchange_endDateTo')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={CurrencyExchangeFilterField.dateTo}
                  datePickerProps={{
                    disabled: {
                      // after: nowDate,
                      before: minValuationDate,
                    },
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('currencyExchange_statementsTableFilter_documentNumber')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={CurrencyExchangeFilterField.documentNumber}
                  fullWidth
                  placeholder={t('statementsFilter_documentNumber_placeholder')}
                  error={namesFieldWithError?.includes(CurrencyExchangeFilterField.documentNumber)}
                />
              </Box>
            </Grid>
            {fullItems ? <Grid item xs={3} /> : null}
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                {/*<Grid item xs={6}>*/}
                {/*  <FormLabel component="legend">{t('status')}</FormLabel>*/}
                {/*  <Box pt={2}>*/}
                {/*    <ExchangeDocumentStatusField name={CurrencyExchangeFilterField.status} fullWidth />*/}
                {/*  </Box>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6}>*/}
                {/*  <FormLabel component="legend">{t('documentCreatedBy')}</FormLabel>*/}
                {/*  <Box pt={2}>*/}
                {/*    <SelectField name="documentCreatedBy" options={[]} fullWidth />*/}
                {/*  </Box>*/}
                {/*</Grid>*/}
                <Grid container>
                  <Grid item xs={6}>
                    <Box pt={6} pl={2} pr={2}>
                      <FormLabel component="legend">{t('write-offAccount')}</FormLabel>
                      <Box pt={2}>
                        <AccountSelectField
                          name={CurrencyExchangeFilterField.depositAccount}
                          options={depositAccountsData}
                          placeholder={t('choose')}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box pt={6} pl={2} pr={2}>
                      <FormLabel component="legend">{t('enrollmentAccount')}</FormLabel>
                      <Box pt={2}>
                        <AccountSelectField
                          name={CurrencyExchangeFilterField.withdrawalAccount}
                          options={withdrawalAccountsData}
                          placeholder={t('choose')}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/*<Grid item xs={3}>*/}
                {/*  <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>*/}
                {/*  <Box pt={2}>*/}
                {/*    <FormatInputField*/}
                {/*      type={FormatType.amount}*/}
                {/*      defaultValue=""*/}
                {/*      name={CurrencyExchangeFilterField.amountMin}*/}
                {/*      fullWidth*/}
                {/*      placeholder="0.00"*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={3}>*/}
                {/*  <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>*/}
                {/*  <Box pt={2}>*/}
                {/*    <FormatInputField*/}
                {/*      type={FormatType.amount}*/}
                {/*      defaultValue=""*/}
                {/*      name={CurrencyExchangeFilterField.amountMax}*/}
                {/*      fullWidth*/}
                {/*      placeholder="0.00"*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*</Grid>*/}
                <Grid container>
                  <Grid item container xs={6} />

                  <Grid item container xs={6}>
                    <Grid item xs={6}>
                      <Box pt={6} pl={2} pr={2}>
                        <FormLabel component="legend">{t('currency')}</FormLabel>
                        <Box pt={2}>
                          <SelectField
                            name={CurrencyExchangeFilterField.withdrawalAccountCurrency}
                            options={getCurrencyList()}
                            fullWidth
                            defaultValue={''}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pt={6} pl={2} pr={2}>
                        <FormLabel component="legend">{t('Документ створив')}</FormLabel>
                        <Box pt={2}>
                          <SelectField
                            name={CurrencyExchangeFilterField.createdBy}
                            options={legalUsers}
                            fullWidth
                            defaultValue={''}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container alignItems="flex-end" justifyContent="flex-end">
                  <Box pt={6} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

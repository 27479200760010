export const styles = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '28px 24px 28px',
    outline: 0,
    borderRadius: '8px',
    maxHeight: '75vh',
    width: '498px',
    height: '184px',
  },
  titleContainer: {
    width: '100%',
  },
  title: {
    color: 'colors.gray800',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  progressContainer: {
    width: '100%',
  },
  progressLabel: {
    width: '100%',
    color: 'colors.gray700',
  },
  progressCountLabel: {
    color: 'colors.gray200',
    fontSize: '0.75rem',
  },
};

import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { umcaConfig } from '@app/src/config';

type UmcaResponse = {
  Edition: string;
};

const getUmcaInfoQueryFn = async (): Promise<UmcaResponse> => {
  try {
    const response = await fetch(`${umcaConfig.url}${umcaConfig.services.about}`);

    return await response.json();
  } catch (e) {
    throw new Error('Error: Umca not available!');
  }
};

type UmcaResult = {
  installed: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
};
export const useUmca = (): UmcaResult => {
  const [installed, setInstalled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data, isLoading, isError } = useQuery('umcaService', getUmcaInfoQueryFn, {
    enabled: true,
    refetchOnMount: true,
    retry: false,
  });

  useEffect(() => {
    if (data) {
      if (data.Edition !== 'full') {
        setErrorMessage('umca_serviceBasicVersion_error');
      } else {
        setInstalled(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setErrorMessage('umca_serviceNotExist_error');
    }
  }, [isError]);

  return {
    installed,
    isError,
    isLoading,
    errorMessage,
  };
};

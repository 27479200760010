import { FilesEnum, AccountSelectType, DateRangeFromTo, OperationsFilterStatements } from '@app/core/types';

export enum FormFieldName {
  operationAccounts = 'operationAccounts',
  dateRange = 'dateRange',
  transactionsPresented = 'transactionsPresented',
  queryText = 'queryText',
  operationType = 'operationType',
  fileType = 'fileType',
}

export type StatementsFormData = {
  operationAccounts: AccountSelectType[];
  dateRange: DateRangeFromTo;
  transactionsPresented: boolean;
  queryText: string;
  operationType?: OperationsFilterStatements;
  fileType: FilesEnum;
};

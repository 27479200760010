import React, { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { PageContent } from '@app/pages/paymentsTemplates/create/PageContent';

export const CreateTemplatePage: React.FC = () => {
  const { t } = useTranslation();
  const showLeavePopupRef = useRef<boolean>(false);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  return (
    <PageWrapper>
      <PageHeader
        title={t('templateCreationTitle')}
        showLeavePopup={showLeavePopup}
        backLink={-1}
        confirmQuestionText={t('confirmLeaveTemplatePopupMessage')}
        showNavigateButton
        useDesktopStyle
      />
      <PageContent onChange={onChangeHandler} />
    </PageWrapper>
  );
};

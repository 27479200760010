import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Toolbar, Typography } from '@mui/material';
import {
  ColumnOrderState,
  ColumnSort,
  getCoreRowModel,
  getExpandedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import {
  CurrencyOperationsSortingRuleDto,
  GetCurrencyOperationWithAvailableActionsDto,
  TotalAmountByCurrencyDto,
  CurrencyOperationStatusEnum,
  GetCountDocumentByStatusDto,
  CurrencyOperationsSortingFieldsEnum,
} from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { getColumnVisibility, getColumOrder } from '@app/core/components/ComplexTable/helpers';
import { Loader } from '@app/core/components/Loader';
import {
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { TableColumnConfig, PaginationChangeType, TablePaginationType } from '@app/core/types';
import { FilterPanel } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/FilterPanel';
import { makeTableColumns } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableContent/columns';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableContent/styles';
import { TableToolbar } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableToolbar';

interface TableContentProps {
  currencyOperations: GetCurrencyOperationWithAvailableActionsDto[];
  columnsConfig: TableColumnConfig[];
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  totalAmounts?: Array<TotalAmountByCurrencyDto>;
  countByStatus?: GetCountDocumentByStatusDto;
  onSettingsButtonClick(): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onSort(rule: CurrencyOperationsSortingRuleDto): void;
  onDeleteDocumentSuccess(): void;
  onSignDocumentSuccess(): void;
  onSendToSignDocumentsSuccess(): void;
  onFilterChange(filter?: CurrencyOperationStatusEnum): void;
}

export const TableContent: React.FC<TableContentProps> = ({
  currencyOperations,
  columnsConfig,
  pagination,
  loading,
  queryKey,
  totalAmounts,
  countByStatus,
  onSort,
  onSettingsButtonClick,
  onPaginationChange,
  onDeleteDocumentSuccess,
  onSignDocumentSuccess,
  onSendToSignDocumentsSuccess,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getColumnVisibility(columnsConfig));
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(getColumOrder(columnsConfig));
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return currencyOperations;
  }, [currencyOperations]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
      }),
    ];
  }, [t]);

  const table = useReactTable<GetCurrencyOperationWithAvailableActionsDto>({
    columns,
    data,
    initialState: {
      sorting,
      columnOrder,
      columnVisibility,
    },
    manualPagination: true,
    enableSorting: currencyOperations.length > 0,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (currencyOperations.length && table.getIsSomeRowsSelected()) {
      table.resetRowSelection();
    }
  }, [currencyOperations, table]);

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as CurrencyOperationsSortingFieldsEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  useEffect(() => {
    setColumnVisibility(getColumnVisibility(columnsConfig));
    setColumnOrder(getColumOrder(columnsConfig));
  }, [columnsConfig]);

  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);
  const selectedOperations: GetCurrencyOperationWithAvailableActionsDto[] = table
    .getSelectedRowModel()
    .flatRows.map((operationRow) => operationRow.original);

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow} />;
    }
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<GetCurrencyOperationWithAvailableActionsDto> key={row.id} row={row} styles={styles} />;
    });
  };

  return (
    <>
      <Toolbar disableGutters sx={styles.toolBar}>
        <Grid item sx={styles.horizontalScroll}>
          <FilterPanel
            currencyOperations={currencyOperations}
            onFilterChange={onFilterChange}
            countByStatus={countByStatus}
          />
        </Grid>
      </Toolbar>
      {currencyOperations.length ? (
        <TableToolbar
          selectedOperations={selectedOperations}
          queryKey={queryKey}
          totalAmounts={totalAmounts}
          totalCount={pagination?.totalCount}
          onDeleteDocumentSuccess={onDeleteDocumentSuccess}
          onSignDocumentSuccess={onSignDocumentSuccess}
          onSendToSignDocumentsSuccess={onSendToSignDocumentsSuccess}
        />
      ) : null}

      <ComplexTable
        head={{
          content: <TableHeadContent<GetCurrencyOperationWithAvailableActionsDto> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

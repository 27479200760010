import React, { useEffect, useRef, useState } from 'react';

import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { UseMutationResult, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  DocumentTemplateDto,
  FileLinkResponseDto,
  GetRecentDocumentsRequestDto,
  PaymentOrderStatusEnum,
  PaymentOrderErrorMessagesEnum,
} from '@app/core/api';
import { GetRecentDocumentsResult, useCancelDocument } from '@app/core/hooks';
import { RouteList } from '@app/src/constants/routeList';

import { DesktopDocumentsList } from './DesktopDocumentsList';
import { MobileDocumentsList } from './MobileDocumentsList';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../../constants';
import { AccountSelectType, PaginationChangeType, DocumentStatus, Operation } from '../../../types';
import { isMobile } from '../../../utils';
import { CancelDocumentDialog } from '../../CancelDocumentDialog';
import { CancelDocumentErrorPopup } from '../../CancelDocumentErrorPopup';
import { ConfirmSignPaymentMultipleOrderDialog } from '../../ConfirmSignPaymentMultipleOrderDialog';
import { ConfirmSignPaymentOrderDialog } from '../../ConfirmSignPaymentOrderDialog';
import { DeleteDocumentDialog } from '../../DeleteDocumentDialog';
import { multipleDeleteDocumentFn } from '../../DeleteDocumentDialog/query';
import { SaveTemplateDialog } from '../../SaveTemplateDialog';
import { makeTemplateDataFromOperation } from '../../SaveTemplateDialog/helpers';
import { SendToSignPaymentOrder } from '../../SendToSignPaymentOrder';
import { SignPaymentOrder } from '../../SignPaymentOrder';
import { SignPaymentOrderMultiple } from '../../SignPaymentOrderMultiple';
import { GetMultipleStatementsFilesProps } from '../types';

type DocumentsProps = {
  accounts?: AccountSelectType[];
  documentStatus: DocumentStatus;
  updateDocumentCount(): void;
  getGetRecentDocuments(): UseMutationResult<GetRecentDocumentsResult, ApiError, GetRecentDocumentsRequestDto>;
  getStatementsMultipleFiles(): UseMutationResult<FileLinkResponseDto, ApiError, GetMultipleStatementsFilesProps>;
};

export const Documents: React.FC<DocumentsProps> = ({
  documentStatus,
  accounts,
  updateDocumentCount,
  getGetRecentDocuments,
  getStatementsMultipleFiles,
}) => {
  const navigate = useNavigate();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const { t } = useTranslation();
  const [operations, setOperations] = useState<Array<Operation>>([]);
  const operationsListRef = useRef<Array<Operation>>([]);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState<boolean>(false);
  const [showModalSignature, setShowModalSignature] = useState<boolean>(false);
  const [showConfirmSignPaymentMultipleOrderDialog, setShowConfirmSignPaymentMultipleOrderDialog] =
    useState<boolean>(false);
  const [showConfirmSignPaymentOrderDialog, setShowConfirmSignPaymentOrderDialog] = useState<boolean>(false);
  const [showSignPaymentOrderMultiple, setShowSignPaymentOrderMultiple] = useState<boolean>(false);
  const [showSendToSignPaymentOrderDialog, setShowSendToSignPaymentOrderDialog] = useState<boolean>(false);
  const [showCancelDocumentDialog, setShowCancelDocumentDialog] = useState<boolean>(false);
  const [showCancelDocumentErrorPopup, setShowCancelDocumentErrorPopup] = useState<boolean>(false);
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean>();
  const [selectedDocuments, setSelectedDocuments] = useState<Operation[]>([]);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const {
    data: cancelResultData,
    mutate: mutateCancelDocument,
    status: cancelDocumentMutateStatus,
    reset: cancelDocumentMutateReset,
    isLoading: isLoading1,
  } = useCancelDocument();
  const { data, mutate, isLoading: isLoading2 } = getGetRecentDocuments();

  const updateList = () => {
    mutate({
      operationAccounts:
        accounts?.map((account) => {
          return { id: account.account.id, currencyCode: account.account.currency };
        }) || [],
      documentStatus: documentStatus ? (documentStatus as unknown as PaymentOrderStatusEnum) : undefined,
      pagination: paginationRef.current,
    });
  };

  useEffect(() => {
    showLoader(isLoading1);
  }, [isLoading1, showLoader]);

  useEffect(() => {
    setSelectedDocuments([]);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
  }, [documentStatus]);

  useEffect(() => {
    if (data) {
      if (isMobile) {
        operationsListRef.current = operationsListRef.current.concat(data.operations);
      } else {
        operationsListRef.current = data.operations;
      }
      setOperations(operationsListRef.current);
    }
  }, [data]);

  useEffect(() => {
    operationsListRef.current = [];
    setSelectedDocuments([]);
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({
      operationAccounts:
        accounts?.map((account) => {
          return { id: account.account.id, currencyCode: account.account.currency };
        }) || [],
      documentStatus: documentStatus ? (documentStatus as unknown as PaymentOrderStatusEnum) : undefined,
      pagination: paginationRef.current,
    });
  }, [mutate, accounts, documentStatus]);

  useEffect(() => {
    if (cancelResultData) {
      if (cancelResultData.additionalInfo?.code === PaymentOrderErrorMessagesEnum.WrongDocumentStatus) {
        setShowCancelDocumentErrorPopup(true);
      } else {
        notify({
          notifyProps: {
            title: t('success'),
            message: t('cancelDocumentSuccessMessage'),
            severity: 'success',
          },
        });
        setSelectedDocuments([]);
        paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
        cancelDocumentMutateReset();
        mutate({
          operationAccounts:
            accounts?.map((account) => {
              return { id: account.account.id, currencyCode: account.account.currency };
            }) || [],
          documentStatus: documentStatus ? (documentStatus as unknown as PaymentOrderStatusEnum) : undefined,
          pagination: paginationRef.current,
        });
        updateDocumentCount();
      }
    }
  }, [accounts, cancelDocumentMutateReset, cancelResultData, documentStatus, mutate, notify, t, updateDocumentCount]);

  useEffect(() => {
    if (cancelDocumentMutateStatus === 'error') {
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('cancelDocumentErrorMessage'),
          severity: 'error',
        },
      });
    }
  }, [cancelDocumentMutateStatus, notify, t]);

  const paginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    updateList();
  };

  const updateSelectedDocuments = (document: Operation, selected: boolean) => {
    if (selected) {
      const present = selectedDocuments?.filter((item) => {
        return item.id === document.id;
      });
      if (present?.length === 0 && selectedDocuments) {
        setSelectedDocuments([...selectedDocuments, document]);
      }
    } else {
      const newSelectedDocuments = selectedDocuments?.filter((item) => {
        return item.id !== document.id;
      });

      setSelectedDocuments(newSelectedDocuments);
    }
  };

  const onSelectAllClickHandler = (selectAll: boolean) => {
    if (selectAll) {
      if (data?.operations) {
        setSelectedDocuments(data.operations);
      }
    } else {
      setSelectedDocuments([]);
    }
  };

  const onSignClickHandler = () => {
    setShowConfirmSignPaymentOrderDialog(true);
  };

  const onSignFromAnotherClickHandler = () => {
    setShowConfirmSignPaymentOrderDialog(true);
    setSignFromAnotherPerson(true);
  };

  const onSignSuccessHandler = () => {
    setShowModalSignature(false);
    setShowSignPaymentOrderMultiple(false);
    setSelectedDocuments([]);
    updateList();
    updateDocumentCount();
    setSignFromAnotherPerson(false);
  };

  const onCloseSignatureDialogHandler = () => {
    setShowModalSignature(false);
  };

  const onDeleteDocumentHandler = () => {
    setShowConfirmDeleteDialog(true);
  };

  const onCancelDeleteDocument = () => {
    setShowConfirmDeleteDialog(false);
  };

  const onDeleteDocumentSuccessHandler = () => {
    setShowConfirmDeleteDialog(false);
    setSelectedDocuments([]);
    updateList();
    updateDocumentCount();
  };

  const onSubmitHandler = () => {
    setShowConfirmDeleteDialog(false);
  };

  const onCancelSaveTemplateHandler = () => {
    setShowSaveTemplateDialog(false);
  };

  const onSuccessSaveTemplateHandler = (saveResultTemplateData: DocumentTemplateDto) => {
    setShowSaveTemplateDialog(false);
  };

  const onCopyClickHandler = () => {
    if (selectedDocuments?.length === 1) {
      const urlTo = queryString.stringifyUrl({
        url: RouteList.payment_create,
        query: {
          copyFrom: selectedDocuments[0].uuid,
          templateType: selectedDocuments[0].templateType,
        },
      });
      navigate(urlTo);
    }
  };

  const onAddToTemplatesClickHandler = () => {
    setShowSaveTemplateDialog(true);
  };

  const onMultiSignClickHandler = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(true);
  };

  const onMultiSignFromAnotherClickHandler = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(true);
    setSignFromAnotherPerson(true);
  };

  const onCloseConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(false);
  };

  const onAgreeConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(false);
    setShowSignPaymentOrderMultiple(true);
  };

  const onCloseSignPaymentOrderMultipleHandler = () => {
    setShowSignPaymentOrderMultiple(false);
  };

  const docsForSignCount: number = selectedDocuments.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  ).length;

  const onSendToSignClickHandler = () => {
    setShowSendToSignPaymentOrderDialog(true);
  };

  const onSendToSignSuccessHandler = () => {
    setSelectedDocuments([]);
    setShowSendToSignPaymentOrderDialog(false);
    updateList();
    updateDocumentCount();
  };

  const onCloseSendToSignPaymentOrderMultipleHandler = () => {
    setShowSendToSignPaymentOrderDialog(false);
  };

  const onCloseConfirmSignPaymentOrderDialog = () => {
    setShowConfirmSignPaymentOrderDialog(false);
  };

  const onAgreeConfirmSignPaymentOrderDialog = () => {
    setShowConfirmSignPaymentOrderDialog(false);
    setShowModalSignature(true);
  };

  const onCloseCancelDocumentDialogHandler = () => {
    setShowCancelDocumentDialog(false);
  };

  const onCancelDocumentSubmitSuccessHandler = () => {
    if (selectedDocuments[0].uuid) {
      mutateCancelDocument({ uuid: selectedDocuments[0].uuid });
    }
    setShowCancelDocumentDialog(false);
  };

  const onCancelDocumentHandler = () => {
    setShowCancelDocumentDialog(true);
  };

  const onCloseCancelDocumentErrorPopup = () => {
    setShowCancelDocumentErrorPopup(false);
  };

  const DocumentsList = isMobile ? MobileDocumentsList : DesktopDocumentsList;

  return (
    <>
      <DocumentsList
        accounts={accounts}
        documentStatus={documentStatus}
        isLoading={isLoading2}
        operations={operations}
        pagination={data?.pagination}
        selectedDocuments={selectedDocuments}
        onSelectAllClick={onSelectAllClickHandler}
        onSignClick={onSignClickHandler}
        onDeleteClick={onDeleteDocumentHandler}
        onCopyClick={onCopyClickHandler}
        onAddToTemplatesClick={onAddToTemplatesClickHandler}
        onPaginationChange={paginationChangeHandler}
        updateSelectedDocuments={updateSelectedDocuments}
        onMultiSignClick={onMultiSignClickHandler}
        onSendToSignClick={onSendToSignClickHandler}
        onCancelDocument={onCancelDocumentHandler}
        getStatementsMultipleFiles={getStatementsMultipleFiles}
        onSignFromAnother={onSignFromAnotherClickHandler}
        onMultiFromAnotherClick={onMultiSignFromAnotherClickHandler}
      />

      {showConfirmSignPaymentOrderDialog ? (
        <ConfirmSignPaymentOrderDialog
          toSignDocument={selectedDocuments[0]}
          onClose={onCloseConfirmSignPaymentOrderDialog}
          onAgreeSign={onAgreeConfirmSignPaymentOrderDialog}
        />
      ) : null}

      {selectedDocuments[0] && showModalSignature ? (
        <SignPaymentOrder
          uuid={selectedDocuments[0].uuid}
          documentToSign={JSON.stringify(selectedDocuments[0])}
          onSuccessPopupCancel={onSignSuccessHandler}
          onClose={onCloseSignatureDialogHandler}
          signFromAnotherPerson={signFromAnotherPerson}
        />
      ) : null}

      {showConfirmSignPaymentMultipleOrderDialog ? (
        <ConfirmSignPaymentMultipleOrderDialog
          selectedCount={selectedDocuments.length}
          toSignDocuments={selectedDocuments}
          onClose={onCloseConfirmSignPaymentMultipleOrderDialog}
          onAgreeSign={onAgreeConfirmSignPaymentMultipleOrderDialog}
        />
      ) : null}
      {showSignPaymentOrderMultiple ? (
        <SignPaymentOrderMultiple
          operations={selectedDocuments}
          onClose={onCloseSignPaymentOrderMultipleHandler}
          onSuccessPopupCancel={onSignSuccessHandler}
        />
      ) : null}

      {showConfirmDeleteDialog ? (
        <DeleteDocumentDialog
          mutationFn={multipleDeleteDocumentFn}
          onSubmit={onSubmitHandler}
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          selectedDocuments={selectedDocuments}
          docsForSignCount={docsForSignCount}
          currency={selectedDocuments[0].currency}
        />
      ) : null}
      {showSaveTemplateDialog && selectedDocuments[0] ? (
        <SaveTemplateDialog
          open
          documentTemplateData={makeTemplateDataFromOperation(selectedDocuments[0])}
          onCancel={onCancelSaveTemplateHandler}
          onSuccessSaveTemplate={onSuccessSaveTemplateHandler}
        />
      ) : null}
      {showSendToSignPaymentOrderDialog ? (
        <SendToSignPaymentOrder
          selectedDocuments={selectedDocuments}
          onClose={onCloseSendToSignPaymentOrderMultipleHandler}
          onSubmitSuccess={onSendToSignSuccessHandler}
        />
      ) : null}

      {showCancelDocumentDialog ? (
        <CancelDocumentDialog
          onClose={onCloseCancelDocumentDialogHandler}
          onConfirmCancelDocument={onCancelDocumentSubmitSuccessHandler}
        />
      ) : null}
      {showCancelDocumentErrorPopup ? <CancelDocumentErrorPopup onClose={onCloseCancelDocumentErrorPopup} /> : null}
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, currencyOperationsApiClient, DeleteOperationsResponseDto, getClientId } from '@app/core/api';

const deleteCurrencyOperationFn = async (uuids: Array<string>): Promise<DeleteOperationsResponseDto> => {
  const response = await currencyOperationsApiClient.currencyOperationsControllerDeleteOperations(getClientId(), {
    uuids,
  });

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyOperationsControllerDeleteOperations error. Can't delete operations."
    );
  }

  return response.data;
};

export const useDeleteCurrencyOperation = (): UseMutationResult<DeleteOperationsResponseDto, ApiError, string[]> => {
  return useMutation<DeleteOperationsResponseDto, ApiError, string[]>(deleteCurrencyOperationFn);
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { CrossIcon, EraserIcon, SearchSmallIcon } from '@app/common/icons';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { InputField } from '@app/core/components/Form/controls/InputField';
import {
  DateColumn,
  VisitColumn,
} from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/ColumnContainer';
import { VisitsRow } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/helpers';
import { styles } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyVisitsHistory/components/VisitsTable/style';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  t(text: string): string;
  onSearchButtonClick(): void;
  onClearSubFilter(): void;
  onCloseSubFilter(): void;
}

enum VisitsField {
  id = 'id',
  date = 'date',
  duration = 'duration',
  browser = 'browser',
  operationSystem = 'operationSystem',
  ip = 'ip',
  country = 'country',
  fullName = 'fullName',
}

export const makeTableColumns = ({
  t,
  showSubFilters,
  onSearchButtonClick,
  onClearSubFilter,
  onCloseSubFilter,
}: MakeTableColumnsProps): ColumnDef<VisitsRow, any>[] => {
  return [
    {
      accessorKey: VisitsField.date,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props} tableSortLabelProps={{ sx: styles.activeSearchAlign }}>
            <Typography variant="caption">{t('myProfile_date')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, string>) => {
        return <DateColumn>{getValue()}</DateColumn>;
      },
    },
    {
      accessorKey: VisitsField.fullName,
      header: (props) => (
        <Grid container direction={showSubFilters ? 'column' : 'row'} justifyContent="flex-start">
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_column_fullName')}</Typography>
          </HeaderSortedWrapper>
          {showSubFilters ? (
            <Box>
              <InputField name={VisitsField.fullName} />
            </Box>
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.duration,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_duration')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.browser,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_browser')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.operationSystem,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_operationSystem')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.ip,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_ip')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      accessorKey: VisitsField.country,
      header: (props) => (
        <Grid container>
          <HeaderSortedWrapper {...props}>
            <Typography variant="caption">{t('myProfile_country')}</Typography>
          </HeaderSortedWrapper>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<VisitsRow, any>) => {
        return <VisitColumn>{getValue()}</VisitColumn>;
      },
    },
    {
      id: 'actions',
      header: ({ table }) => {
        const onSearchButtonClickHandler = () => {
          if (table.getRowModel().rows.length) {
            onSearchButtonClick();
          }
        };

        return (
          <Grid container justifyContent="flex-end">
            <div>
              {showSubFilters ? (
                <IconButton onClick={onClearSubFilter} size="large" sx={{ marginRight: '6px' }}>
                  <EraserIcon fontSize="small" />
                </IconButton>
              ) : null}
              {showSubFilters ? (
                <IconButton size="small" onClick={onCloseSubFilter}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={onSearchButtonClickHandler}>
                  <SearchSmallIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        );
      },
      cell: () => <VisitColumn />,
    },
  ];
};

export const styles = {
  horizontalWrapper: {
    width: '100%',
  },
  verticalWrapper: {
    height: '100%',
  },
  dateText: {
    color: 'colors.gray300',
  },
  captionFilter: {
    color: 'colors.primary600',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    marginLeft: '4px',
  },
  toggleButton: {
    fontSize: '0.75rem',
    '& svg': {
      marginLeft: '4px',
      fill: 'colors.primary500',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  emptyDataMessage: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  placeholder: {
    height: '500px',
  },
  placeholderTitle: {
    fontWeight: 600,
  },
  placeholderDescription: {
    color: 'colors.gray400',
  },
};

import { SxProps, Theme } from '@mui/material';

export const styles: { [name: string]: SxProps<Theme> } = {
  cell: {
    width: 'initial',
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_header: {
    color: 'colors.gray700',
  },
  cell_selection: {
    minWidth: '40px',
    maxWidth: '40px',
    flexShrink: 0,
  },
  cell_actions: {
    maxWidth: '100px',
    minWidth: '100px',
  },
  cell_0: {
    maxWidth: '80px',
    minWidth: '80px',
  },
  cell_1: {
    maxWidth: '120px',
    minWidth: '120px',
  },
  cell_2: {
    maxWidth: '120px',
    minWidth: '120px',
  },
  cell_4: {
    maxWidth: '60px',
    minWidth: '60px',
  },
  cell_5: {
    maxWidth: '180px',
    minWidth: '180px',
  },
  searchIconBtn: {
    color: 'colors.gray300',
  },
};

import { ExtendedMessageDto, MessageStatus, MessageType, UserResourceDto } from '@app/core/api';
import { LettersTableToolbarActiveButtonsConfig } from '@app/pages/letters/types';

export const getActiveButtonsConfig = (
  selectedMessages: ExtendedMessageDto[],
  permissions: UserResourceDto,
  messageType: MessageType
): LettersTableToolbarActiveButtonsConfig => {
  const docsToSign: ExtendedMessageDto[] = selectedMessages.filter((item) => item.status === MessageStatus.Saved);
  const single: boolean = selectedMessages.length === 1;
  const signEnabled: boolean =
    docsToSign.length >= 1 &&
    docsToSign.length <= 10 &&
    selectedMessages.filter(
      (item) =>
        item.actions?.allowToSign && (item.status === MessageStatus.Saved || item.status === MessageStatus.ToSign)
    ).length >= 1 &&
    permissions.sign.message.allowToSign;

  const showDeleteButton: boolean =
    selectedMessages.filter((item) => item.status !== MessageStatus.Delivered).length >= 1 &&
    permissions.messages.delete;

  const showSendToSignButton: boolean =
    selectedMessages.filter((item) => item.actions?.allowToSendToSign && item.status === MessageStatus.Saved).length >=
    1;

  const sendToBank: boolean =
    selectedMessages.filter((item) => item.status === MessageStatus.Signed).length >= 1 && permissions.messages.send;

  const markAsRead: boolean =
    selectedMessages.filter((item) => item.status === MessageStatus.New).length >= 1 && permissions.messages.send;

  const edit: boolean =
    messageType === MessageType.Outgoing &&
    single &&
    selectedMessages.filter((item) => item.status === MessageStatus.Saved || item.status === MessageStatus.ToSign)
      .length >= 1 &&
    permissions.messages.edit;

  return {
    sign: messageType === MessageType.Outgoing && signEnabled,
    sendToSign: messageType === MessageType.Outgoing && showSendToSignButton,
    edit,
    delete: messageType === MessageType.Outgoing && showDeleteButton,
    sendToBank: messageType === MessageType.Outgoing && sendToBank,
    markAsRead: messageType === MessageType.Incoming && markAsRead,
    view: single,
  };
};

import React, { useEffect } from 'react';

import { Box, FormLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FilterIcon } from '@app/common/icons';
import { FrontLanguageEnum } from '@app/core/api';
import { CurrencyAutocomplete } from '@app/core/components/CurrencyAutocomplete';
import { DatePeriod } from '@app/core/components/Form/controls/DateField/constants';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { ActionFiltersButtons } from '@app/pages/exchangeRate/components/BankRates/components/BankRatesFilter/components/ActionFiltersButtons';
import { styles } from '@app/pages/exchangeRate/components/BankRates/components/BankRatesFilter/styles';
import { oxiCurrencyListQueryFn } from '@app/pages/exchangeRate/query';
import { CurrencyRatesFilterField } from '@app/pages/exchangeRate/types';

interface BankRatesFilterProps {
  onSubmit(): void;
  resetFormHandler(): void;
  bankBranch: string;
}

export const BankRatesFilter: React.FC<BankRatesFilterProps> = ({ onSubmit, resetFormHandler, bankBranch }) => {
  const nowDate = new Date();
  const { t, i18n } = useTranslation();
  const { data, mutate } = useMutation(oxiCurrencyListQueryFn);

  useEffect(() => {
    if (bankBranch) {
      mutate({
        language: i18n.language as unknown as FrontLanguageEnum,
        bankBranchId: Number(bankBranch),
      });
    }
  }, [mutate, i18n.language, bankBranch]);

  const currencyList = data?.map((currency, index) => {
    return {
      id: index,
      numeric: currency.numeric,
      numericCountryCode: currency.numeric,
      alpha3: currency.alpha3,
      name: currency.name,
      nomin: index,
    };
  });
  return (
    <>
      <Box mt={4} mb={4}>
        <FilterIcon />
        <Typography variant="h6" component="span" sx={styles.captionFilter}>
          {t('filters')}
        </Typography>
      </Box>
      <Grid container direction="column" sx={styles.wrapper}>
        <Grid item container justifyContent="space-between" spacing={5}>
          <Grid item xs={6}>
            <FormLabel component="legend">{t('exchange_column_currency')}</FormLabel>
            <Box pt={2}>
              <CurrencyAutocomplete name={CurrencyRatesFilterField.exchangeCurrencyList} options={currencyList || []} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormLabel component="legend">{t('exchangeRatesFilter_dateRange')}</FormLabel>
            <Box pt={2}>
              <DateRangeSelectField
                defaultValue={{
                  dateRange: { from: nowDate, to: nowDate },
                  select: DatePeriod.for_today,
                }}
                formSelectName="dateRangeSelect"
                formDateRangeName={CurrencyRatesFilterField.dateRange}
                selectProps={{ disabled: true }}
              />
            </Box>
          </Grid>
          <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
            <Box pt={6} sx={styles.actionButtonsContainer}>
              <ActionFiltersButtons onSubmit={onSubmit} resetFormHandler={resetFormHandler} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

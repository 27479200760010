import { CurrencyExchangeTableColumns } from '@app/pages/exchange/currencyExchangeTransactions/types';

export const tableFieldsConfigMock = [
  {
    columnType: CurrencyExchangeTableColumns.documentNumber,
    enabled: true,
    order: 0,
    edit: false,
  },
  {
    columnType: CurrencyExchangeTableColumns.documentDate,
    enabled: true,
    order: 1,
    edit: false,
  },
  {
    columnType: CurrencyExchangeTableColumns.withdrawalAccountNumber,
    enabled: true,
    order: 3,
    edit: true,
  },
  {
    columnType: CurrencyExchangeTableColumns.depositAccountNumber,
    enabled: true,
    order: 4,
    edit: false,
  },
  {
    columnType: CurrencyExchangeTableColumns.currencyExchangeType,
    enabled: true,
    order: 5,
    edit: true,
  },
  {
    columnType: CurrencyExchangeTableColumns.withdrawalAccountCurrency,
    enabled: true,
    order: 6,
    edit: true,
  },
  {
    columnType: CurrencyExchangeTableColumns.amount,
    enabled: true,
    order: 7,
    edit: true,
  },
  {
    columnType: CurrencyExchangeTableColumns.status,
    enabled: true,
    order: 8,
    edit: true,
  },
];

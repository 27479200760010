import React, {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

const isStyleObject = (obj: any): boolean => typeof obj === 'object';
const getClasses = (...classes: any[]) => classes.filter((c) => !isStyleObject(c) && c !== false).join(' ');

interface SingleOtpInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  index: number;
  value?: string;
  focus: boolean;
  shouldAutoFocus?: boolean;
  isInputSecure?: boolean;
  isInputNum?: boolean;
  isDisabled?: boolean;
  hasErrored?: boolean;
  isLastChild: boolean;
  placeholder?: string;
  separator: React.ReactNode;
  className?: string;
  disabledStyle?: Record<string, unknown> | string;
  inputStyle?: Record<string, unknown> | string;
  errorStyle?: Record<string, unknown> | string;
  focusStyle?: Record<string, unknown> | string;
}

const SingleOtpInput: React.FC<SingleOtpInputProps> = ({
  index,
  value,
  placeholder,
  focus,
  shouldAutoFocus,
  isInputSecure,
  isInputNum,
  isDisabled,
  isLastChild,
  hasErrored,
  className,
  disabledStyle,
  errorStyle,
  focusStyle,
  inputStyle,
  separator,
  ...rest
}) => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputEl.current && focus && shouldAutoFocus) {
      inputEl.current.focus();
    }
  }, [focus, inputEl, shouldAutoFocus]);

  useEffect(() => {
    if (inputEl.current && focus) {
      inputEl.current.focus();
      inputEl.current.select();
    }
  }, [focus, inputEl]);

  const getType = (): string => {
    if (isInputSecure) {
      return 'password';
    }

    if (isInputNum) {
      return 'tel';
    }

    return 'text';
  };

  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center' }}>
      <input
        aria-label={`${index === 0 ? 'Please enter verification code. ' : ''}${isInputNum ? 'Digit' : 'Character'} ${
          index + 1
        }`}
        autoComplete="off"
        style={Object.assign(
          { width: '1em', textAlign: 'center' },
          isStyleObject(inputStyle) && inputStyle,
          focus && isStyleObject(focusStyle) && focusStyle,
          isDisabled && isStyleObject(disabledStyle) && disabledStyle,
          hasErrored && isStyleObject(errorStyle) && errorStyle
        )}
        placeholder={placeholder}
        className={getClasses(inputStyle, focus && focusStyle, isDisabled && disabledStyle, hasErrored && errorStyle)}
        type={getType()}
        maxLength={1}
        ref={inputEl}
        disabled={isDisabled}
        value={value ? value : ''}
        {...rest}
        onInput={(e) => {}}
      />
      {!isLastChild && separator}
    </div>
  );
};

interface OtpInputProps {
  className?: string;
  containerStyle?: Record<string, unknown> | string;
  disabledStyle?: Record<string, unknown> | string;
  errorStyle?: Record<string, unknown> | string;
  focusStyle?: Record<string, unknown> | string;
  hasErrored?: boolean;
  inputStyle?: Record<string, unknown> | string;
  isDisabled?: boolean;
  isInputNum?: boolean;
  isInputSecure?: boolean;
  numInputs: number;
  placeholder?: string;
  separator?: React.ReactNode;
  shouldAutoFocus?: boolean;
  value?: string;
  onChange?(otp: string): void;
  setFocus?: boolean;
}

export const OtpInput: React.FC<OtpInputProps> = ({
  value,
  containerStyle,
  numInputs = 4,
  inputStyle,
  focusStyle,
  separator,
  isDisabled,
  disabledStyle,
  hasErrored,
  errorStyle,
  shouldAutoFocus,
  isInputNum,
  isInputSecure,
  className,
  placeholder,
  onChange = () => {},
  setFocus,
}) => {
  const [activeInput, setActiveInput] = useState<number>(0);

  const getOtpValue = (): string[] => (value ? value.toString().split('') : []);

  const getPlaceholderValue = (): string | undefined => {
    if (typeof placeholder === 'string') {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        console.error('Length of the placeholder should be equal to the number of inputs.');
      }
    }
  };

  // Helper to return OTP from input
  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('');

    onChange(otpValue);
  };

  const isInputValueValid = (inputValue: string) => {
    const isTypeValid = isInputNum ? !isNaN(parseInt(inputValue, 10)) : typeof inputValue === 'string';

    return isTypeValid && inputValue.trim().length === 1;
  };

  // Focus on input by index
  const focusInput = (inputNum: number) => {
    const newActiveInput = Math.max(Math.min(numInputs - 1, inputNum), 0);

    setActiveInput(newActiveInput);
  };

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = (inputValue: string) => {
    const otp = getOtpValue();
    otp[activeInput] = inputValue[0];

    handleOtpChange(otp);
  };

  // Handle pasted OTP
  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    const otp = getOtpValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData: string[] = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        const item = pastedData.shift();
        if (item) {
          otp[pos] = item;
          nextActiveInput++;
        }
      }
    }

    setActiveInput(nextActiveInput);
    // test it
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInputValueValid(e.target.value)) {
      changeCodeAtFocus(e.target.value);
      setActiveInput(activeInput + 1);
    }
  };

  // Handle cases of backspace, delete, left arrow, right arrow, space
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (e.keyCode === SPACEBAR || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault();
    }
  };

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput();
    } else {
      // This is a workaround for dealing with keyCode "229 Unidentified" on Android.

      if (!isInputNum) {
        const { nativeEvent } = e;

        if (
          (nativeEvent as InputEvent).data === null &&
          (nativeEvent as InputEvent).inputType === 'deleteContentBackward'
        ) {
          e.preventDefault();
          changeCodeAtFocus('');
          focusPrevInput();
        }
      }
    }
  };

  useEffect(() => {
    if (setFocus) {
      setActiveInput(0);
    }
  }, [setFocus]);

  const renderInputs = (): ReactElement[] => {
    const inputs = [];
    const otp = getOtpValue();
    const placeholderForInput = getPlaceholderValue();

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          placeholder={placeholderForInput && placeholderForInput[i]}
          key={i}
          index={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            setActiveInput(i);
            e.target.select();
          }}
          separator={separator}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus}
          isInputNum={isInputNum}
          isInputSecure={isInputSecure}
          className={className}
        />
      );
    }

    return inputs;
  };

  return (
    <div
      style={Object.assign({ display: 'flex' }, isStyleObject(containerStyle) && containerStyle)}
      className={typeof containerStyle !== 'object' ? containerStyle : ''}
    >
      {renderInputs()}
    </div>
  );
};

import React from 'react';

import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

import { CurrencyCodeDto } from '@app/core/api';

import { CurrencySelectQuery } from '../CurrencySelect';

type CurrencyAutocompleteProps = {
  name: string;
  options: CurrencyCodeDto[];
  required?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
};

export const CurrencyAutocomplete: React.FC<CurrencyAutocompleteProps> = ({ name, rules, options }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        rules={rules}
        render={({ field: { value: currentValue, onChange }, fieldState: { error } }) => {
          return (
            <CurrencySelectQuery
              name={name}
              onChange={onChange}
              value={currentValue}
              errorText={error?.message}
              options={options}
            />
          );
        }}
      />
    </>
  );
};

import React from 'react';

import { FormControl } from '@mui/material';

import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { OperationsAccountsSelectField } from '@app/pages/dashboard/view/PageContent/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const AccountsSelect: React.FC = () => {
  const { allAccounts } = useAppSelector((state): ProfileState => state.profile);
  return (
    <FormControl component="fieldset" fullWidth>
      <AccountsWithSearchSelectField name={OperationsAccountsSelectField.accountSelect} accounts={allAccounts} />
    </FormControl>
  );
};

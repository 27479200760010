import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  authApiClient,
  getClientId,
  ApiError,
  Disable2faParamsDtoLanguageEnum,
  Disable2faParamsDtoType2FAEnum,
  TYPE2FA,
} from '@app/core/api';

interface UserDisable2faGetCodeFnProps {
  language: Disable2faParamsDtoLanguageEnum;
}

const getDisable2faCodeFn = async ({ language }: UserDisable2faGetCodeFnProps): Promise<any> => {
  const response = await authApiClient.authControllerDisable2faSendSecret(getClientId(), {
    params: {
      type2FA: TYPE2FA.Totp as unknown as Disable2faParamsDtoType2FAEnum,
      language,
    },
  });

  if (response.status !== 201) {
    throw new ApiError(response.request.status, 'Cant get disable GA code');
  }

  return response.data;
};

export const useGetDisable2faCode = (): UseMutationResult<any, ApiError, UserDisable2faGetCodeFnProps> => {
  return useMutation<any, ApiError, UserDisable2faGetCodeFnProps>(getDisable2faCodeFn);
};

import React from 'react';

import { Grid, Typography, Skeleton } from '@mui/material';
import { GridProps } from '@mui/material/Grid/Grid';

import { styles } from '@app/pages/dashboard/view/PageContent/AvailableFunds/styles';

export const FundLoadingItem: React.FC<GridProps> = (containerProps) => {
  return (
    <Grid item sx={styles.fundRoot} {...containerProps}>
      <Typography variant="body1" sx={styles.fundAmount}>
        <Skeleton animation="wave" height={27} />
      </Typography>
      <Typography variant="body2" component="p" sx={styles.fundDescription}>
        <Skeleton animation="wave" />
      </Typography>
    </Grid>
  );
};

import { Theme } from '@mui/material';

export const useStyles = ({ spacing, palette: { colors } }: Theme, menuOpen: boolean) => ({
  settingsButton: {
    cursor: 'pointer',
    color: colors.companyIconsColor,
    position: 'relative',
    '&:hover .MuiSvgIcon-root': {
      transformOrigin: 'center',
      animation: 'rotation 2s infinite linear',
    },
  },
  popover: {
    paddingTop: menuOpen ? '8px' : 0,
    paddingLeft: menuOpen ? 0 : '12px',
    background: 'none',
    boxShadow: 'none',
    overflow: 'initial',
  },
  switcherMenu: () => {
    return {
      borderRadius: spacing(3),
      padding: `0 ${spacing(4)}`,
      width: '256px',
      backgroundColor: colors.settingsMenuBackground,
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '12px',
        height: '12px',
        top: menuOpen ? '8px' : '50%',
        right: menuOpen ? '36px' : 'unset',
        left: menuOpen ? 'unset' : '12px',
        backgroundColor: colors.settingsMenuBackground,
        borderLeft: `1px solid ${colors.settingsMenuBackground}`,
        borderTop: `1px solid ${colors.settingsMenuBackground}`,
        transform: 'translate(-50%, -50%) rotate(45deg)',
        borderRadius: '1px 0 0 0',
      },
    };
  },
  item: {
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    borderBottom: `1px solid ${colors.menuDivider}`,
  },
  itemLast: {
    borderBottom: 'none',
  },
  switcherLink: {
    color: colors.settingsMenuTextColor,
    cursor: 'pointer',
    '&:hover': {
      color: colors.secondary500,
    },
  },
});

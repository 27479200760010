import React from 'react';

import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

import { Autocomplete, AutocompleteProps } from '../../Autocomplete';

export const AutocompleteField = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  name,
  defaultValue,
  rules,
  ...otherProps
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={rules}
      render={({ field, fieldState }) => {
        const { value, onChange: onChangeControl } = field;
        return (
          <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
            name={name}
            value={value}
            onChange={(event, newValue) => onChangeControl(newValue)}
            error={fieldState.error?.message}
            {...otherProps}
          />
        );
      }}
    />
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { isEmpty, isEqual } from 'lodash-es';
import { parse } from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FilterIcon, DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import {
  GetSwiftOperationListRequestDto,
  GetSwiftOperationListRequestDtoTypeEnum,
  SWIFTStatusEnum,
} from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetSwiftOperationsList } from '@app/core/hooks/useGetSwiftOperationsList';
import { TableColumnConfig, PaginationChangeType, SortingRule } from '@app/core/types';
import { SwiftFilter } from '@app/pages/swift/components/SwiftFilter';
import { swiftFilterSchema } from '@app/pages/swift/components/SwiftFilter/validationSchema';
import { SwiftTable } from '@app/pages/swift/components/SwiftTable';
import { SwiftFilterFieldName, SwiftFilterFormData } from '@app/pages/swift/components/SwiftTable/types';
import { fieldsCreditTable } from '@app/pages/swift/mocks/tableFields';
import { swiftControllerUpdateFn } from '@app/pages/swift/query';
import { styles } from '@app/pages/swift/style';
import { makeDataForSubmit } from '@app/pages/swift/SwiftPayment/helper';
import { resetFormData } from '@app/pages/swift/types';
import { appConfig } from '@app/src/config';

export const SwiftContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQueryRef = useRef<{ [key: string]: string }>({});
  const [autoSubmitForm, setAutoSubmitForm] = useState<boolean>(false);
  const [fullItems, setFullItems] = useState(false);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { t } = useTranslation();
  const swiftTabsItems: MenuItem<GetSwiftOperationListRequestDtoTypeEnum>[] = [
    {
      id: 2,
      labelText: t('outgoing'),
      value: GetSwiftOperationListRequestDtoTypeEnum.Outgoing,
    },
  ];

  const { dateFrom, dateTo, dateRangeSelect } = parse(location.search);
  const defaultValues: DefaultValues<SwiftFilterFormData> = {
    [SwiftFilterFieldName.dateRange]: dateFrom
      ? {
          from: new Date(dateFrom as string),
          to: new Date((dateTo as string) || (dateFrom as string)),
        }
      : undefined,
    [SwiftFilterFieldName.dateRangeSelect]: dateRangeSelect as string,
  };
  const methods = useForm<SwiftFilterFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(swiftFilterSchema(t)),
  });
  const { handleSubmit, reset: formReset, formState, getValues, watch, trigger, setValue } = methods;

  const watchFields = watch();
  useEffect(() => {
    if (watchFields && formState.isDirty) {
      const { dateRange, operationAccounts, ...otherValues } = getValues();
      const fieldsArr = Object.entries(otherValues).filter(([key, value]) => {
        return !isEmpty(value);
      });
      const fieldsForUrl = Object.fromEntries(fieldsArr);
      const urlQueryObj: { [key: string]: any } = { ...fieldsForUrl };

      if (dateRange) {
        urlQueryObj['dateFrom'] = dateRange.from.toISOString();
        urlQueryObj['dateTo'] = dateRange.to.toISOString();
      }
      if (operationAccounts) {
        urlQueryObj['operationAccounts'] = operationAccounts.map((account) => account.account.id).toString();
      }
      if (!isEqual(urlQueryObj, urlQueryRef.current)) {
        urlQueryRef.current = urlQueryObj;
        setSearchParams(urlQueryObj, { replace: true });
      }
    }
  }, [formState.isDirty, getValues, setSearchParams, watchFields]);

  const { data, mutate, reset: mutationReset, isLoading } = useGetSwiftOperationsList();

  const swiftOperations = data?.operations || [];
  const pagination = data?.pagination;
  const totalAmounts = data?.totalAmount;
  const countsByStatus = data?.countByStatus;

  const [activeTab, setActiveTab] = useState<GetSwiftOperationListRequestDtoTypeEnum>(
    GetSwiftOperationListRequestDtoTypeEnum.Outgoing
  );

  const submitFilter = useCallback(
    (submitData: GetSwiftOperationListRequestDto) => {
      console.log(submitData);
      mutate({
        ...submitData,
      });
    },
    [mutate]
  );

  const handleFilterChange = useCallback(() => {
    const submitData = makeDataForSubmit(getValues());
    submitFilter({ type: activeTab, ...submitData, pagination: paginationRef.current });
  }, [activeTab, getValues, submitFilter]);

  const handleChange = (currentTab: GetSwiftOperationListRequestDtoTypeEnum): void => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE };
    setActiveTab(currentTab);
  };

  const onSubmit = () => {
    handleFilterChange();
  };

  const updateTable = () => {
    mutate({
      type: activeTab,
      ...makeDataForSubmit(getValues()),
      pagination: paginationRef.current,
    });
  };

  const resetFormHandler = () => {
    formReset(resetFormData);
    mutationReset();
    setSearchParams({}, { replace: true });
  };

  const watchedFields = watch();

  useEffect(() => {
    if (autoSubmitForm && !formState.isDirty && watchedFields?.operationAccounts?.length) {
      mutate({
        type: activeTab,
        ...makeDataForSubmit(getValues()),
        pagination: paginationRef.current,
      });
      setAutoSubmitForm(false);
    }
  }, [activeTab, autoSubmitForm, formState.isDirty, getValues, mutate, watchedFields]);

  useEffect(() => {
    const urlQueryParams = parse(location.search);
    if (
      urlQueryParams &&
      Object.entries(urlQueryParams).filter(([key, value]) => {
        return !isEmpty(value);
      }).length > 0
    ) {
      setAutoSubmitForm(true);
    }
  }, []);

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleFilterChange();
    }
  };

  const onSortHandler = useCallback(
    (rule: SortingRule) => {
      handleFilterChange();
    },
    [handleFilterChange]
  );

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    handleFilterChange();
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const configurationSavedHandler = (tableColumnsData: TableColumnConfig[]) => {
    // dispatch(updateOperationsTableConfig(tableColumnsData));
  };

  const onDeleteDocumentSuccess = () => {
    updateTable();
  };

  const onSignDocumentsSuccess = () => {
    updateTable();
  };

  const onFilterChangeHandler = (filter?: SWIFTStatusEnum) => {
    if (filter) {
      setValue(SwiftFilterFieldName.status, filter);
    } else {
      setValue(SwiftFilterFieldName.status, undefined);
    }
    trigger().then((valid) => {
      if (isLoading) {
        return;
      }
      if (valid) {
        paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE };
        updateTable();
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={onKeyPressHandler}
        role="presentation"
        style={styles.verticalWrapper}
      >
        <HorizontalMenu<GetSwiftOperationListRequestDtoTypeEnum> onChange={handleChange} menuItems={swiftTabsItems} />
        <Grid item container direction="column" wrap="nowrap" sx={styles.verticalWrapper}>
          <Box pb={4} mt={7}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item>
                <FilterIcon />
                <Typography variant="h6" component="span" sx={styles.captionFilter}>
                  {t('filters')}
                </Typography>
              </Grid>
              {appConfig.enableNextFeatures ? (
                <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
                  {t('additionalParameters')}
                  {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
                </Button>
              ) : null}
            </Grid>
          </Box>
          <Box pb={4}>
            <SwiftFilter fullItems={fullItems} resetFormHandler={resetFormHandler} />
          </Box>
          <SwiftTable
            loading={isLoading}
            swiftOperation={swiftOperations}
            columnsConfig={fieldsCreditTable}
            columnsConfigMutationFn={swiftControllerUpdateFn}
            pagination={pagination}
            onSort={onSortHandler}
            onPaginationChange={onPaginationChangeHandler}
            onSuccessConfigurationSaved={configurationSavedHandler}
            onDeleteDocumentSuccess={onDeleteDocumentSuccess}
            onSignDocumentsSuccess={onSignDocumentsSuccess}
            totalAmounts={totalAmounts}
            onFilterChange={onFilterChangeHandler}
            countByStatus={countsByStatus}
          />
        </Grid>
      </form>
    </FormProvider>
  );
};

export const styles = {
  settingIconBtn: {
    color: 'colors.gray300',
    marginRight: '-16px',
  },
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_header: {},
  cell_body: {},
  cell_id: {
    width: '48px',
    flexShrink: 0,
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_date: {
    maxWidth: '150px',
    minWidth: '150px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_details: {
    maxWidth: '100px',
    minWidth: '100px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_documentNumber: {
    maxWidth: '180px',
    minWidth: '100px',
    '&.header': {},
    '&.body': {
      color: 'colors.gray700',
    },
  },
  cell_currency: {
    justifyContent: 'center',
    minWidth: '70px',
    maxWidth: '70px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_amount: {
    textAlign: 'end',
    minWidth: '116px',
    maxWidth: '116px',
    '&.body': {
      whiteSpace: 'nowrap',
      textAlign: 'end',
      fontWeight: 600,
      justifyContent: 'flex-end',
      color: 'colors.primary700',
      paddingRight: '24px',
    },
  },
  cell_paymentReference: {
    flexBasis: '150%',
    minWidth: '160px',
    '&.body': {
      color: 'colors.gray700',
    },
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  linkArrow: {
    color: 'colors.primary600',
    verticalAlign: 'top',
  },
  tableRowExpanded: {
    marginBottom: 0,
  },
  tableRow: {
    backgroundColor: 'colors.containerBackgroundColor',
    padding: '10px 16px',
    borderRadius: '8px',
    marginBottom: '4px',
    zIndex: 3,
    cursor: 'pointer',
  },
  operationDetailsContent: {
    transform: 'translate(0, -4px)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  tableCell: {
    borderBottom: 'none!important',
    flex: '1',
  },
  actionCell: {
    maxWidth: '50px',
  },
  noOperationsContainer: {
    color: 'colors.gray200',
    paddingTop: '20px',
    textAlign: 'center',
  },
  chevron: {
    color: 'colors.gray200',
  },
};

import React, { useEffect, useState } from 'react';

import { AccountStatusEnum, GetAccountResponseDto } from '@app/core/api';
import { useGetAllAccounts } from '@app/core/hooks/useGetAllAccounts';
import { CurrencyEnum } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { AccountsDesktopPage } from '@app/pages/accounts/desktop';
import { AccountsMobilePage } from '@app/pages/accounts/mobile';
import { AccountsByStatusCountsType } from '@app/pages/accounts/types';

export const AccountsPage: React.FC = () => {
  const [accountsByStatusCounts, setAccountsByStatusCounts] = useState<AccountsByStatusCountsType>({
    active: 0,
    blocked: 0,
    closed: 0,
  });
  const [activeCurrency, setActiveCurrency] = useState<CurrencyEnum | null>(null);
  const [queryIBAN, setQueryIBAN] = useState<string | undefined>();
  const [activeAccountStatus, setActiveAccountStatus] = useState<AccountStatusEnum>(AccountStatusEnum.Active);
  const [accounts, setAccounts] = useState<GetAccountResponseDto[]>([]);
  const [accountsCurrency, setAccountsCurrency] = useState<GetAccountResponseDto[]>([]);
  const { data, mutate: getAllAccountsMutate } = useGetAllAccounts();

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    getAllAccountsMutate(queryIBAN);
  }, [queryIBAN, getAllAccountsMutate]);

  useEffect(() => {
    if (data) {
      setAccountsCurrency(
        data.accounts.filter(({ account }) => {
          if (activeCurrency) {
            return account.currency === activeCurrency;
          }
          return true;
        })
      );
    }
  }, [activeCurrency, setAccountsCurrency, data]);

  useEffect(() => {
    if (data) {
      setAccountsByStatusCounts({
        active: accountsCurrency.filter((item) => item.account.status === AccountStatusEnum.Active).length,
        blocked: accountsCurrency.filter((item) => item.account.status === AccountStatusEnum.Blocked).length,
        closed: accountsCurrency.filter((item) => item.account.status === AccountStatusEnum.Closed).length,
      });
      setAccounts(accountsCurrency.filter((item) => item.account.status === activeAccountStatus));
    }
  }, [accountsCurrency, activeAccountStatus, data]);

  const onStatusFilterChangeHandler = (status: AccountStatusEnum) => {
    setActiveAccountStatus(status);
  };

  const onCurrencyMenuChangeHandler = (filter: CurrencyEnum) => {
    setActiveCurrency(filter);
    setActiveAccountStatus(AccountStatusEnum.Active);
  };

  const onSearchChangeHandler = (query: string) => {
    setQueryIBAN(query);
  };

  const AccountsView = isMobile ? AccountsMobilePage : AccountsDesktopPage;

  return (
    <AccountsView
      accounts={accounts}
      accountsByStatusCounts={accountsByStatusCounts}
      activeAccountStatus={activeAccountStatus}
      onCurrencyMenuChangeHandler={onCurrencyMenuChangeHandler}
      onStatusFilterChangeHandler={onStatusFilterChangeHandler}
      onSearchChange={onSearchChangeHandler}
      currencies={data?.currencies || []}
    />
  );
};

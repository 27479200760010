import { useMutation, UseMutationResult } from 'react-query';

import {
  getClientId,
  paymentOrdersApiClient,
  ApiError,
  CreatePaymentOrderResponseDto,
  UpdateDocumentRequestDto,
} from '@app/core/api';

export const useEditPaymentOrder = (): UseMutationResult<
  CreatePaymentOrderResponseDto,
  ApiError,
  UpdateDocumentRequestDto
> => {
  return useMutation(async (updateDocumentRequestDto: UpdateDocumentRequestDto) => {
    const result = await paymentOrdersApiClient.paymentOrderControllerUpdateStatus(
      getClientId(),
      updateDocumentRequestDto
    );

    if (result.status !== 200) {
      throw new ApiError(result.request.status, 'Error update payment!');
    }

    return result.data;
  });
};

import React, { useRef, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { updateUserLogo } from '@app/slices/profileSlice';
import { useAppDispatch } from '@app/src/store';
import { ReactComponent as ArrowLeftIconSvg } from '@app/themes/default/assets/icons/24x24/arrow/left.svg';

import { uploadUserPhotoQueryFn } from './query';
import { styles } from './style';
import { ModalCommon } from '../../../Modal';

interface EditLogoPopupProps {
  open: boolean;
  onClose(): void;
}

enum ImageType {
  png = 'image/png',
  jpeg = 'image/jpeg',
}

export const EditLogoPopup: React.FC<EditLogoPopupProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [formatPhoto, setFormatPhoto] = useState<string>();
  const [photo, setPhoto] = useState<string>();
  const [resetPhoto, setResetPhoto] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notify } = useNotify();
  const { mutate } = useMutation(uploadUserPhotoQueryFn);

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.items[0];
    if (file.type === ImageType.png || file.type === ImageType.jpeg) {
      setOveDragZone(false);
    } else {
      setError(true);
    }
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    setError(false);
  };

  const handleFile = (file: File) => {
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const image = reader.result;
        setPhoto(image);
        setResetPhoto(true);
        if (file.type === ImageType.png) {
          setFormatPhoto(image.slice(22));
        } else {
          setFormatPhoto(image.slice(23));
        }
      }
    };

    reader.onerror = () => {
      notify({
        notifyProps: {
          title: t('avatar_logo_error_title'),
          message: '',
          severity: 'error',
        },
      });
    };
    reader.readAsDataURL(file);
  };

  const resetClick = () => {
    setResetPhoto(false);
    setPhoto(undefined);
    setError(false);
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.type === ImageType.png || file.type === ImageType.jpeg) {
        handleFile(file);
      } else {
        setError(true);
      }
    }
  };
  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0 && fileInputRef.current.files[0].size < 2000000) {
          handleFile(fileInputRef.current.files[0]);
        } else {
          setError(true);
        }
      }
    }
  };

  const fileInputClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onCancelClickHandler = () => {
    onClose();
    setPhoto(undefined);
    setError(false);
  };

  const saveHandler = () => {
    if (formatPhoto && fileName) {
      onClose();
      mutate(
        {
          file: formatPhoto,
          fileName,
        },
        {
          onSuccess: (response) => {
            dispatch(updateUserLogo(`${response.link}?id=${Math.random()}`));
            notify({
              notifyProps: {
                title: t('success'),
                message: t('load_avatar_success_message'),
                severity: 'success',
              },
            });
          },
          onError: () => {
            notify({
              notifyProps: {
                title: t('avatar_logo_error_title'),
                message: '',
                severity: 'error',
              },
            });
          },
        }
      );
    }
    setPhoto(undefined);
    setError(false);
  };

  return (
    <>
      <ModalCommon open={open} onClose={onClose}>
        <Box sx={styles.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid justifyContent="space-around" container>
              {resetPhoto ? (
                <Box pt={8} pl={6}>
                  <IconButton size="small" onClick={resetClick}>
                    <ArrowLeftIconSvg style={{ ...styles.backLinkArrow }} className="MuiSvgIcon" />
                  </IconButton>
                </Box>
              ) : null}
              <Box pt={8} pr={6} pl={resetPhoto ? 0 : 6}>
                <Typography sx={styles.title}> Зміна зображення користувача </Typography>
              </Box>
              <Box pt={8} pl={6} pr={6}>
                <IconButton size="small" onClick={onClose}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center" sx={styles.imageContainer}>
              {photo ? (
                <>
                  <img src={photo} alt="" style={styles.image} />
                  <Box sx={styles.mask} />
                </>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  wrap="nowrap"
                  sx={{
                    ...styles.dropContainer,
                    ...(!fileName?.length && styles.dropContainerDefault),
                    ...(oveDragZone && styles.dropContainerActive),
                    ...(error && styles.dropContainerError),
                  }}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <Typography variant="body2" component="div" color="textPrimary" sx={styles.mainText}>
                    <input
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      id="upload"
                      name="upload"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={fileSelected}
                    />
                    {error ? (
                      <Button variant="text" sx={styles.label} onClick={fileInputClicked}>
                        {t('upload_other_file')}
                      </Button>
                    ) : (
                      <>
                        {t('select_image')} <br />
                        <Button
                          variant="text"
                          sx={{ ...styles.label, ...styles.fontWeight }}
                          onClick={fileInputClicked}
                        >
                          {t('select_image_file')}
                        </Button>
                      </>
                    )}
                    <Grid container direction="column">
                      <Typography variant="caption" sx={styles.secondText}>
                        {t('allow_file_format')}
                      </Typography>
                      <Typography variant="caption" sx={styles.secondText}>
                        {t('avatar_file_size')}
                      </Typography>
                    </Grid>
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={6} justifyContent="flex-end">
              <Grid item>
                <Box pb={8} pr={6}>
                  <Button size="small" variant="outlined" color="primary" onClick={onCancelClickHandler}>
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box pb={8} pr={6}>
                  <Button size="small" variant="contained" color="primary" onClick={saveHandler}>
                    {t('save')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ModalCommon>
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, counterpartiesApiClient, getClientId } from '@app/core/api';

const deleteCounterpartyFn = async (id: number): Promise<void> => {
  const response = await counterpartiesApiClient.counterpartiesControllerDetachCounterparty(id, getClientId());
  return response.data;
};

export const useDeleteCounterparty = (): UseMutationResult<void, ApiError, number> => {
  return useMutation<void, ApiError, number>(deleteCounterpartyFn);
};

import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DealStateEnum } from '@app/core/api';

interface StatusBadgeProps {
  status: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        backgroundColor: 'colors.warningBackground',
        color: 'colors.warning',
        borderColor: 'colors.warning',
        border: '1px solid',
        padding: '0 10px 0px 10px',
        borderRadius: '14px',
        ...((status === DealStateEnum.New || status === DealStateEnum.Active) && {
          backgroundColor: 'colors.successBackground',
          color: 'colors.success',
          borderColor: 'colors.success',
        }),
        ...((status === DealStateEnum.Deleted || status === DealStateEnum.Closed) && {
          backgroundColor: 'colors.errorBackground',
          color: 'colors.error',
          borderColor: 'colors.error',
        }),
      }}
      variant="body2"
    >
      {t(status)}
    </Typography>
  );
};

import React, { useEffect, useRef } from 'react';

import { AutocompleteValue, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ReactComponent as CloseIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';
import { ReactComponent as SearchIcon } from '@app/themes/default/assets/icons/16x16/search.svg';

import { Autocomplete, AutocompleteProps } from './Autocomplete';

export interface AutocompleteQueryProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'onChange' | 'options'> {
  options?: T[];
  onChange?(
    event: React.ChangeEvent<{}>,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason
  ): void;
  fetchFunction(queryStr: string): Promise<T[]>;
}

export const AutocompleteQuery = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  name,
  value,
  fetchFunction,
  onInputChange,
  onChange,
  classes: muiClasses,
  ...otherProps
}: Omit<AutocompleteQueryProps<T, Multiple, DisableClearable, FreeSolo>, 'options'>): JSX.Element => {
  const { t } = useTranslation();
  const inputValueRef = useRef<string>('');
  const [options, setOptions] = React.useState<T[]>([]);
  const { data, isFetching, refetch } = useQuery<T[], Error>(
    name,
    () => {
      return fetchFunction(inputValueRef.current);
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (isFetching) {
      setOptions([]);
    } else if (data) {
      setOptions(data);
    }
  }, [isFetching, data]);

  const onInputChangeHandler = (
    event: React.SyntheticEvent,
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      inputValueRef.current = inputValue;
      refetch();
    }
    if (onInputChange) {
      onInputChange(event, inputValue, reason);
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<{}>,
    newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason
  ) => {
    if (onChange) {
      onChange(event, newValue, reason);
    }
  };

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      name={name}
      classes={{
        ...muiClasses,
      }}
      sx={{ '& .MuiAutocomplete-popupIndicatorOpen': { transform: 'initial!important' } }}
      value={value}
      options={options}
      loadingText={`${t('loading')}...`}
      loading={isFetching}
      filterOptions={(opts) => opts}
      clearIcon={<CloseIcon />}
      popupIcon={<SearchIcon />}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      {...otherProps}
    />
  );
};

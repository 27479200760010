import React from 'react';

import { Box, Button, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '@app/common/icons';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { Lei } from '@app/pages/payment/components/Lei';
import { styles } from '@app/pages/paymentsTemplates/PaymentsTemplates/PageContent/components/PaymentsTemplatesFilter/styles';
import { TemplatesFormFieldNamesEnum } from '@app/pages/paymentsTemplates/PaymentsTemplates/types';

interface CounterpartiesFilterProps {
  resetFormHandler?(): void;
}

export const PaymentsTemplatesFilter: React.FC<CounterpartiesFilterProps> = ({ resetFormHandler }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box mt={4} mb={4}>
        <FilterIcon />
        <Typography variant="h6" component="span" sx={styles.captionFilter}>
          {t('filters')}
        </Typography>
      </Box>
      <Grid container direction="column" sx={styles.wrapper}>
        <Grid item container justifyContent="space-between" spacing={4}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <FormLabel component="legend">{t('payments_templ_name')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={TemplatesFormFieldNamesEnum.templateName}
                  defaultValue=""
                  placeholder={t('payments_templ_name')}
                  fullWidth
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormLabel component="legend">{t('statementsFilter_LEI')}</FormLabel>
            <Box pt={2}>
              <Lei name={TemplatesFormFieldNamesEnum.lei} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
              <Box pt={2}>
                <FormatInputField
                  type={FormatType.amount}
                  name={TemplatesFormFieldNamesEnum.amountMin}
                  placeholder={t('paymentCreationLocalPayment_amountPlaceholder')}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
              <Box pt={2}>
                <FormatInputField
                  type={FormatType.amount}
                  name={TemplatesFormFieldNamesEnum.amountMax}
                  placeholder={t('paymentCreationLocalPayment_amountPlaceholder')}
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Grid item container justifyContent="flex-end" alignItems="flex-end">
            <Grid container justifyContent="space-between" sx={styles.actionButtonsWrap}>
              <Button color="primary" onClick={resetFormHandler}>
                {t('clear')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

import React from 'react';

import { Box, Grid, IconButton } from '@mui/material';

import { CsvIcon, PdfIcon, PrintIcon, XlsIcon, XmlIcon } from '@app/common/icons';
import { StatementTypeEnum } from '@app/core/api';
import { styles } from '@app/pages/loans/components/LoansTable/components/TableToolbar/styles';

interface TableToolbarProps {
  queryKey?: string;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({ queryKey }) => {
  const handleClickDownload = () => {
    return null;
  };

  return (
    <Box pb={1}>
      <Grid container alignItems="center" justifyContent="space-between" sx={styles.actionsContainer}>
        <Grid item container xs={6} alignItems="center" justifyContent="flex-start">
          <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
            <CsvIcon />
          </IconButton>
          {queryKey === StatementTypeEnum.Statement ? (
            <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
              <PdfIcon />
            </IconButton>
          ) : null}
          <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
            <XlsIcon />
          </IconButton>
          <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
            <XmlIcon />
          </IconButton>
          {queryKey === StatementTypeEnum.Statement ? (
            <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
              <PrintIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

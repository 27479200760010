export enum LoansTypeEnum {
  open = 'open',
  closed = 'closed',
}

export enum LoansFilterField {
  agreementStartDateFrom = 'agreementStartDateFrom',
  agreementStartDateTo = 'agreementStartDateTo',
  agreementEndDateFrom = 'agreementEndDateFrom',
  agreementEndDateTo = 'agreementEndDateTo',
  agreementNumber = 'agreementNumber',
  currency = 'currency',
  amountFrom = 'amountFrom',
  amountTo = 'amountTo',
  interestRateFrom = 'interestRateFrom',
  interestRateTo = 'interestRateTo',
  productType = 'productType',
  temp_agreementStartDateFrom = 'temp_agreementStartDateFrom',
  temp_agreementStartDateTo = 'temp_agreementStartDateTo',
  temp_agreementEndDateFrom = 'temp_agreementEndDateFrom',
  temp_agreementEndDateTo = 'temp_agreementEndDateTo',
}

export type LoansFilterFormData = {
  [LoansFilterField.agreementStartDateFrom]?: Date;
  [LoansFilterField.agreementStartDateTo]?: Date;
  [LoansFilterField.agreementEndDateFrom]?: Date;
  [LoansFilterField.agreementEndDateTo]?: Date;
  [LoansFilterField.agreementNumber]?: string;
  [LoansFilterField.amountFrom]?: string;
  [LoansFilterField.amountTo]?: string;
  [LoansFilterField.currency]?: string;
  [LoansFilterField.interestRateFrom]?: string;
  [LoansFilterField.interestRateTo]?: string;
  [LoansFilterField.productType]?: string;
  [LoansFilterField.temp_agreementStartDateFrom]?: string;
  [LoansFilterField.temp_agreementStartDateTo]?: string;
  [LoansFilterField.temp_agreementEndDateFrom]?: string;
  [LoansFilterField.temp_agreementEndDateTo]?: string;
};

export enum LoansTableColumns {
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  agreementNumber = 'agreementNumber',
  amount = 'amount',
  currency = 'currency',
  interestRate = 'interestRate',
  uuid = 'uuid',
  productType = 'productType',
}

export enum LoansMenuItems {
  operations = 'operations',
  schedule = 'schedule',
  safety = 'safety',
}

export enum OperationsTableColumns {
  date = 'date',
  account = 'account',
  paymentReference = 'paymentReference',
  amount = 'amount',
  currency = 'currency',
  id = 'id',
}

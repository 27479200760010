import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, dictionariesApiClient, TaxDto } from '@app/core/api';

const getTaxesList = async (): Promise<TaxDto[]> => {
  const response = await dictionariesApiClient.dictionariesControllerGetTaxesList({});

  return response.data;
};

export const useGetTaxesList = (): UseMutationResult<TaxDto[], ApiError, any> => {
  return useMutation<TaxDto[], ApiError, any>(getTaxesList);
};

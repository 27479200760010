import {
  AvailableActionsDto,
  CurrencyOperationsExchangeRateTypeEnum,
  CurrencyOperationsExchangeTypeEnum,
  GetAccountResponseDto,
} from '@app/core/api';
import { FileType } from '@app/core/types';

export enum FormFieldName {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  documentValueDate = 'documentValueDate',
  withdrawalAccount = 'withdrawalAccount',
  depositAccount = 'depositAccount',
  amount = 'amount',
  exchangeRateType = 'exchangeRateType',
  exchangeRate = 'exchangeRate',
  currencyOperationReason = 'currencyOperationReason',
  temp_documentDate = 'temp_documentDate',
  temp_documentValueDate = 'temp_documentValueDate',
  _userConfirmCheck = '_userConfirmCheck',
}

export type CurrencyFormDefaultValues = {
  [FormFieldName.documentNumber]?: string;
  [FormFieldName.documentDate]?: Date;
  [FormFieldName.documentValueDate]?: Date;
  [FormFieldName.withdrawalAccount]: string;
  [FormFieldName.depositAccount]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.currencyOperationReason]?: string;
  [FormFieldName.exchangeRateType]: CurrencyOperationsExchangeRateTypeEnum;
  [FormFieldName.exchangeRate]?: string;
  [FormFieldName.temp_documentDate]: string;
  [FormFieldName.temp_documentValueDate]?: string;
  [FormFieldName._userConfirmCheck]: boolean;
};

export type CurrencyFormData = {
  [FormFieldName.documentNumber]: string;
  [FormFieldName.documentDate]: Date;
  [FormFieldName.documentValueDate]?: Date;
  [FormFieldName.withdrawalAccount]: string;
  [FormFieldName.depositAccount]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.currencyOperationReason]?: string;
  [FormFieldName.exchangeRateType]: CurrencyOperationsExchangeRateTypeEnum;
  [FormFieldName.exchangeRate]?: string;
  [FormFieldName.temp_documentDate]: string;
  [FormFieldName.temp_documentValueDate]?: string;
  [FormFieldName._userConfirmCheck]: boolean;
};

export interface ExchangeFormContentProps {
  uuid?: string;
  currencyExchangeType: CurrencyOperationsExchangeTypeEnum;
  watchDocumentDate?: Date;
  withdrawalAccounts: GetAccountResponseDto[];
  depositAccounts: GetAccountResponseDto[];
  withdrawalAccountPlaceholder: string;
  depositAccountPlaceholder: string;
  amountLabel: string;
  currency: string;
  conversionRateLabel: string;
  exchangeRateDisable: boolean;
  selectedWithdrawalAccount?: GetAccountResponseDto;
  files: FileType[];
  buttonDisabled: boolean;
  signPermissions: AvailableActionsDto;
  onFileSelectClick(): void;
  onFileDeleteClick(fileForDelete: FileType): void;
  onUploadFilesClick(): void;
  onCancelButtonClick(): void;
  onSave(): void;
  onSaveAndSign(): void;
  onSaveAndSignFromAnotherPerson(): void;
  onSendToSign(): void;
}

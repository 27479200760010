import { useMutation, UseMutationResult } from 'react-query';

import {
  getClientId,
  dictionariesApiClient,
  ApiError,
  BankCurrencyInfoDto,
  GetClientCurrenciesListDto,
} from '@app/core/api';

export type GetDocumentsCountProps = { accounts?: { id: string; currencyCode: string }[] };

const getClientCurrencyListFn = async ({ language }: GetClientCurrenciesListDto): Promise<BankCurrencyInfoDto[]> => {
  const response = await dictionariesApiClient.dictionariesControllerGetClientCurrenciesList(getClientId(), {
    language,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get currency list");
  }

  return response.data;
};

export const useGetClientCurrencyList = (): UseMutationResult<
  BankCurrencyInfoDto[],
  ApiError,
  GetClientCurrenciesListDto
> => useMutation<BankCurrencyInfoDto[], ApiError, GetClientCurrenciesListDto>(getClientCurrencyListFn);

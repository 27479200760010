export const styles = {
  accountInfo: {
    width: '100%',
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  emptyDataMessage: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
};

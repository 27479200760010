import React, { useState } from 'react';

import { TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { ExpandedTableRow } from '@app/core/components';
import { styles as tableStyles } from '@app/core/components/OperationsTable/styles';
import { StatementsConfigList } from '@app/pages/profile/desktop/components/Monitoring/components/StatementsConfigs/StatementsConfigList';

import { styles } from './styles';

export const Monitoring: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const handleExpand = () => {
    setOpen(!open);
  };

  // TODO: refactor it after add one more item to the ComplexTable
  return (
    <ExpandedTableRow
      open={open}
      onExpand={handleExpand}
      sx={{ ...tableStyles.tableRow, ...(open && tableStyles.tableRowExpanded) }}
      extendedContend={<StatementsConfigList onCancel={() => {}} />}
    >
      <TableCell component="div" sx={{ ...tableStyles.tableCell }}>
        <Typography variant="body2" sx={styles.cellTitle}>
          {t('account_statement')}
        </Typography>
      </TableCell>
      <TableCell component="div" sx={{ ...tableStyles.tableCell, ...tableStyles.actionCell }}>
        {open ? (
          <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
        ) : (
          <DownChevronSmallIcon sx={styles.chevronIcon} fontSize="small" />
        )}
      </TableCell>
    </ExpandedTableRow>
  );
};

import {
  statementsApiClient,
  getClientId,
  ApiError,
  FileLinkResponseDto,
  GetOperationsFileDto,
  PaymentOrderStatusEnum,
  OperationTypeEnum,
  AccountStatementConfigDtoColumnTypeEnum,
  AccountStatementUpdateConfigDtoColumnTypeEnum,
} from '@app/core/api';
import { OperationsFromPeriodResult } from '@app/core/components/Statements/types';
import { TableColumnConfig, DocumentStatus, Operation, OperationsTypeEnum } from '@app/core/types';
import { StatementsFilter } from '@app/pages/statements/types';

export const getStatements = async (filterData: StatementsFilter): Promise<OperationsFromPeriodResult> => {
  const documentStatus = filterData.documentStatus
    ? (filterData.documentStatus as unknown as PaymentOrderStatusEnum)
    : undefined;

  const operationType = filterData.operationType
    ? (filterData.operationType as unknown as OperationTypeEnum)
    : undefined;

  const documentCategory = filterData.documentCategory?.length ? filterData.documentCategory : undefined;

  const response = await statementsApiClient.operationsControllerGetStatements(getClientId(), {
    ...filterData,
    documentStatus,
    operationType,
    documentCategory,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't load statements!");
  }
  const {
    accountBalancesMovement,
    operationsCount,
    pagination,
    totalAmounts,
    operations: operationsDto,
  } = response.data;

  const operations: Operation[] = operationsDto.map((operationDto) => {
    return {
      ...operationDto,
      status: operationDto.status as unknown as DocumentStatus,
      type: operationDto.type as unknown as OperationsTypeEnum,
    };
  });

  return {
    accountBalancesMovement,
    operations,
    operationsCount,
    pagination,
    totalAmounts,
  };
};

export const operationsControllerUpdateStatementsFn = async (
  config: TableColumnConfig<AccountStatementUpdateConfigDtoColumnTypeEnum>[]
): Promise<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]> => {
  const response = await statementsApiClient.operationsControllerUpdateStatementsConfig(getClientId(), { config });

  return response.data.config;
};

export const getStatementsFileFn = async (getOperationsFileDto: GetOperationsFileDto): Promise<FileLinkResponseDto> => {
  const response = await statementsApiClient.operationsControllerGetFile(getClientId(), getOperationsFileDto);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get order file link.");
  }

  return response.data;
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import {
  CurrencyEnum,
  ResultStatusEnum,
  SwiftOperationWithAvailableButtons,
  SwiftOperationWithAvailableButtonsStatusEnum,
} from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { StatusPopup } from '@app/core/components/StatusPopup';
import { useSendToSignSwift } from '@app/core/hooks/useSendToSignSwift';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/swift/components/SwiftTable/components/SignSwiftOperations/style';
import { ReactComponent as SignIcoBgSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

interface SendToSignSwiftDocumentDialogProps {
  selectedDocuments: SwiftOperationWithAvailableButtons[];
  onSubmitSuccess(): void;
  onCancel(): void;
}

export const SendToSignSwiftDocumentDialog: React.FC<SendToSignSwiftDocumentDialogProps> = ({
  selectedDocuments,
  onSubmitSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);

  const docsForSend = selectedDocuments.filter(
    (item) =>
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.Saved ||
      item.status === SwiftOperationWithAvailableButtonsStatusEnum.ToSign
  );

  const { data: sendToSignResponseData, mutate } = useSendToSignSwift();

  const docsForSignCount: number = docsForSend.length;

  const swiftOperationGroupByCurrency: {
    [key in CurrencyEnum]?: SwiftOperationWithAvailableButtons[];
  } = groupBy<SwiftOperationWithAvailableButtons>(docsForSend, 'currency');

  const uuids = docsForSend.map((item) => item.uuid);

  useEffect(() => {
    if (sendToSignResponseData) {
      if (sendToSignResponseData.status === ResultStatusEnum.Successfully) {
        setShowSuccessSignModal(true);
      }
      if (sendToSignResponseData.status === ResultStatusEnum.Error) {
        setShowErrorSignModal(true);
      }
    }
  }, [sendToSignResponseData]);

  const onSendToSignSuccessHandler = () => {
    mutate(uuids);
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    onSubmitSuccess();
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  const errorMessage = docsForSignCount > 1 ? t('payments_sign_error_message') : t('payment_sign_error_message');

  return (
    <>
      <ModalCommon onClose={onCancel} open>
        <Box sx={styles.paper}>
          <Box sx={styles.iconBg}>
            <SignIcoBgSVG />
          </Box>
          <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
            <IconButton size="small" onClick={onCancel}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Box mt={3} mb={4}>
            <Typography variant="body1" sx={styles.title}>
              {t('documentsToSign')}
            </Typography>
          </Box>
          <Grid container direction="column" wrap="nowrap" sx={styles.container}>
            <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
              <Grid item>
                <Typography variant="body2" sx={styles.text}>
                  {t('sign_selectedDocuments')}
                  {':'}
                  {' / '} {t('sign_documentsToSign')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="span" sx={styles.value}>
                  {selectedDocuments.length}
                </Typography>
                <Typography variant="body2" component="span" sx={styles.value}>
                  {' / '}
                </Typography>
                <Typography variant="body2" component="span" sx={styles.docsForSignCount}>
                  {docsForSignCount}
                </Typography>
              </Grid>
            </Grid>
            {swiftOperationGroupByCurrency
              ? Object.entries(swiftOperationGroupByCurrency).map(([currency, operations]) => {
                  const amount = operations.reduce((acc, document) => {
                    return acc + document.amount;
                  }, 0);
                  return (
                    <Grid
                      key={`swiftOperation_${currency}`}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={styles.itemRow}
                    >
                      <Grid item>
                        <Typography variant="body2" sx={styles.text}>
                          {` ${currency} `}
                          {`(${operations.length}${t('document_short')})`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" component="span" sx={styles.value}>
                          {formatAmount(amount)} {currency}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </Grid>
          <Grid container justifyContent="center">
            <Box pr={6}>
              <Button size="small" variant="outlined" color="primary" onClick={onCancel}>
                {t('no')}
              </Button>
            </Box>
            <Button size="small" variant="contained" color="primary" onClick={onSendToSignSuccessHandler}>
              {t('yes')}
            </Button>
          </Grid>
        </Box>
      </ModalCommon>
      <StatusPopup
        show={showSuccessSignModal}
        title={t('sentToSign')}
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={errorMessage}
        onOk={onErrorPopupCancelHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

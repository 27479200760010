export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_operationDate: {},
  cell_openingBalance: {
    justifyContent: 'flex-end',
  },
  cell_deposit: {
    justifyContent: 'flex-end',
  },
  cell_currency: {
    minWidth: '20px',
    maxWidth: '40px',
    justifyContent: 'center',
  },
  cell_withdrawal: {
    justifyContent: 'flex-end',
  },
  cell_closingBalance: {
    justifyContent: 'flex-end',
  },
  cell_action: {
    minWidth: '15px',
    maxWidth: '30px',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
  },
};

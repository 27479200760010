import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { AccountDto } from '@app/core/api';
import { styles } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/components/SenderAccountSelect/style';

type SelectOptionProps = {
  selectOption: string;
};

export const SelectOption: React.FC<SelectOptionProps> = ({ selectOption }) => {
  const { accountNumber }: AccountDto = JSON.parse(selectOption);
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid container item>
        <Typography sx={styles.accountName} variant="body2">
          {accountNumber.substring(0, 15)}
        </Typography>
        <Typography sx={styles.iban} variant="body2">
          {accountNumber.slice(-14)}
        </Typography>
      </Grid>
    </Grid>
  );
};

import React, { useEffect } from 'react';

import { Backdrop } from '@mui/material';

import { useUIState } from '@app/context/UIContext';
import { MainLoader } from '@app/core/components';
import { FetchStatus } from '@app/core/types';
import { useAppSelector } from '@app/src/store';

export const MainLoaderBackdrop: React.FC = () => {
  const { mainLoaderShowed, showLoader } = useUIState();
  const { fetchStatus } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (fetchStatus === FetchStatus.loading) {
      showLoader(true);
    } else {
      showLoader(false);
    }
  }, [fetchStatus, showLoader]);
  return (
    <Backdrop sx={{ zIndex: 1900 }} open={mainLoaderShowed}>
      <MainLoader />
    </Backdrop>
  );
};

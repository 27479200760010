export const styles = {
  sortIcon: {
    fill: 'colors.primary500',
  },
  cell: {
    flex: '1',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  headCell: {
    alignItems: 'flex-start',
  },
  cell_registeredNumber: {
    maxWidth: '160px',
  },
  cell_name: {
    maxWidth: '290px',
    flex: 3,
  },
  cell_accessPermissions: {
    maxWidth: '240px',
    flex: 2,
  },
  cell_actions: {
    maxWidth: '32px',
  },
  emptyRow: {
    position: 'relative',
    height: '150px',
  },
  userStatus: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '8px',
    backgroundColor: 'colors.warning',
  },
  userStatusBlocked: {
    backgroundColor: 'colors.error',
  },
  userStatusActive: {
    backgroundColor: 'colors.success',
  },
  detailsTable: {
    backgroundColor: '#F3F9FF',
    boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
    padding: '0 20px',
  },
  row: {
    paddingTop: '20px',
    paddingBottom: '20px',
    '&:not(:last-child)': {
      backgroundImage: `linear-gradient(to right, #d9dce0 50%, transparent 50%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '12px 1px',
      backgroundRepeat: 'repeat-x',
    },
  },
  actionIcon: {
    marginLeft: '20px',
    color: 'colors.primary700',
  },
  buttonAction: {
    minWidth: '154px',
    backgroundColor: 'transparent',
  },
  length: {
    color: 'colors.primary700',
    fontWeight: 600,
    marginLeft: '4px',
  },
  switchTextActive: {
    color: 'colors.primary500',
    fontWeight: 600,
  },
  switchTextInactive: {
    color: 'colors.gray300',
  },
};

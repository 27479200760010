export const styles = {
  wrapper: {
    padding: '16px 20px',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
  },
  captionFilter: {
    color: 'colors.primary600',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    marginLeft: '4px',
  },
  input: {
    width: '100%',
  },
  customTooltip: {
    backgroundColor: 'white',
    color: 'colors.gray400',
    border: `1px solid`,
    borderColor: 'colors.gray100',
    padding: '8px',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '9px',
      height: '9px',
      top: '14px',
      right: 'calc(50% - 25px)',
      backgroundColor: 'white',
      borderLeft: `1px solid`,
      borderTop: `1px solid`,
      borderColor: 'colors.gray100',
      transform: 'translate(-50%, -47%) rotate(45deg)',
      borderRadius: '1px 0 0 0',
    },
  },
  questionWrap: {
    paddingTop: '16px',
  },
  formContainer: {
    backgroundColor: 'colors.gray25',
    padding: '10px 16px',
  },
  actionsContainer: {
    justifyContent: 'space-around',
  },
  fileText: {
    color: 'colors.gray600',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  questionIcon: {
    verticalAlign: 'middle',
  },
  actionButtonsWrap: {
    maxWidth: '240px',
  },
  actionButtonsContainer: {
    width: '100%',
    maxWidth: '240px',
    justifyContent: 'space-around',
  },
};

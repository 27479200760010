import { object, SchemaOf, string } from 'yup';

import { FormFieldName } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';

type NewPaymentValidationSchemaProps = {
  t: (key: string) => string;
};

type SchemaValidationObject = {
  [FormFieldName.templateName]: string;
  [FormFieldName.recipientAccount]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.senderAccount]: string;
  [FormFieldName.purpose]: string;
};

export const validationSchema = ({ t }: NewPaymentValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [FormFieldName.senderAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.templateName]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.recipientAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.amount]: string()
      .min(1, t('paymentCreationLocalPayment_field_required'))
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(FormFieldName.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
        if (amount) {
          return parseFloat(amount) >= 0.01;
        }
        return false;
      }),
    [FormFieldName.purpose]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .trim()
      .min(3, t('paymentCreationLocalPayment_field_minLetters'))
      .test('purpose', t('paymentCreationLocalPayment_field_minLetters'), (val) => {
        if (val && val.length === 3) {
          return !/\s/g.test(val.slice(0, 2));
        }
        return true;
      }),
  });
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, CreateMessageRequestDto, MessageDto } from '@app/core/api';

const createMessageFn = async (createMessageRequestDto: CreateMessageRequestDto): Promise<MessageDto> => {
  const result = await messagesApiClient.messagesControllerCreate(getClientId(), createMessageRequestDto);

  if (result.status !== 200 && result.status !== 201) {
    throw new ApiError(result.request.status, "Can't create messages");
  }

  return result.data;
};

export const useCreateMessage = (): UseMutationResult<MessageDto, ApiError, CreateMessageRequestDto> => {
  return useMutation<MessageDto, ApiError, CreateMessageRequestDto>(createMessageFn);
};

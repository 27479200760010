import React, { useEffect } from 'react';

import { isValidIBAN } from 'ibantools';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';

import { bankNameQueryFn, BankNameQueryFnProps } from './query';
import { InputField } from '../Form/controls/InputField';

type BankNameProps = {
  name: string;
  iban: string | undefined;
  defaultValue?: string;
};

export const BankName: React.FC<BankNameProps> = ({ name, iban, defaultValue }) => {
  const { data, refetch } = useQuery<BankNameQueryFnProps>('name', () => bankNameQueryFn({ iban: iban || '' }), {
    enabled: false,
  });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (iban) {
      if (isValidIBAN(iban)) {
        refetch();
      }
    }
  }, [refetch, iban]);

  useEffect(() => {
    if (data) {
      setValue(name, data.bank);
    }
  }, [data, name, setValue]);

  return (
    <InputField
      InputProps={{
        readOnly: true,
      }}
      fullWidth
      defaultValue={defaultValue}
      name={name}
    />
  );
};

import React, { forwardRef } from 'react';

import { Alert, AlertProps, IconButton, Typography } from '@mui/material';

import { CrossIcon } from '@app/common/icons';

export interface NotifyPopUpProps extends Omit<AlertProps, 'content'> {
  title?: string;
  message?: string;
  content?: React.ReactNode;
  linkClickHandler?(): void;
  handleDismiss(id: string | undefined): void;
}

export const NotifyPopUp = forwardRef<HTMLDivElement, NotifyPopUpProps>(
  ({ id, title, message, severity, icon, content, handleDismiss, linkClickHandler, ...otherProps }, ref) => {
    const onCloseHandler = () => {
      handleDismiss(id);
    };

    return (
      <Alert
        ref={ref}
        severity={severity}
        icon={icon}
        variant="filled"
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onCloseHandler}>
            <CrossIcon
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                color: 'colors.gray300',
              }}
              fontSize="small"
            />
          </IconButton>
        }
        {...otherProps}
      >
        {title ? (
          <Typography
            sx={{
              color: 'colors.gray600',
            }}
            variant="body2"
          >
            {title}
          </Typography>
        ) : null}
        {message ? (
          <Typography
            sx={{
              color: 'colors.gray400',
            }}
            variant="caption"
          >
            {message}
          </Typography>
        ) : null}

        {content}
      </Alert>
    );
  }
);

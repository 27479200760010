import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, getClientId, SendToSignResponseDto, swiftApiClient } from '@app/core/api';

const sendToSignSwiftFn = async (uuids: string[]): Promise<SendToSignResponseDto> => {
  const result = await swiftApiClient.swiftControllerBatchSendToSign(getClientId(), { uuids });
  if (result.status !== 200) {
    throw new ApiError(
      result.request.status,
      "swiftControllerUpdateSwiftOperation error. Can't send to sign operation"
    );
  }

  return result.data;
};

export const useSendToSignSwift = (): UseMutationResult<SendToSignResponseDto, ApiError, string[]> => {
  return useMutation<SendToSignResponseDto, ApiError, string[]>(sendToSignSwiftFn);
};

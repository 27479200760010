import { CurrencyEnum } from '@app/core/api';

import { DocumentTemplateData } from './index';
import { Operation } from '../../types';

export const makeTemplateDataFromOperation = (operation: Operation): DocumentTemplateData => {
  const { amount, paymentReference, details, account, currency } = operation;
  const { recipientAccount, senderAccount } = details;
  return {
    senderAccountId: account.id,
    senderAccount: senderAccount.accountNumber,
    amount: Number(amount),
    recipientName: recipientAccount.name,
    recipientLei: recipientAccount.LEI!, // TODO: check it !
    recipientAccount: recipientAccount.accountNumber,
    nonResidentFlag: false,
    currency: currency as CurrencyEnum,
    paymentReference,
    recipientNonLeiFlag: false,
    realSenderNonLeiFlag: false,
    realRecipientNonLeiFlag: false,
  };
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  counterpartiesApiClient,
  getClientId,
  GetSwiftListResponseDto,
  GetSwiftsRequestDto,
} from '@app/core/api';

const getSwiftCounterpartiesFn = async (filterData: GetSwiftsRequestDto): Promise<GetSwiftListResponseDto> => {
  const response = await counterpartiesApiClient.counterpartiesControllerGetSwiftCounterparties(getClientId(), {
    ...filterData,
  });
  return response.data;
};

export const useGetSwiftCounterparties = (): UseMutationResult<
  GetSwiftListResponseDto,
  ApiError,
  GetSwiftsRequestDto
> => {
  return useMutation<GetSwiftListResponseDto, ApiError, GetSwiftsRequestDto>(getSwiftCounterpartiesFn);
};

import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { CounterpartyResponseDto } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { CounterpartyFormData, CounterpartyFormFieldsEnum, FormMode } from '@app/pages/counterparties/types';
import { Lei } from '@app/pages/payment/components/Lei';
import { PassportField } from '@app/pages/payment/components/PassportField';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { styles } from '@app/src/pages/counterparties/components/CounterpartiesModal/styles';
import { validationSchema } from '@app/src/pages/counterparties/components/CounterpartiesModal/validationShema';

export interface CounterpartiesModalProps {
  show: boolean;
  counterparty?: CounterpartyResponseDto;
  mode: FormMode;
  onDeleteCounterparty?(id: number): void;
  onClose?(): void;
  onSaveCounterparty(value: CounterpartyFormData): void;
}

export const CounterpartiesModal: React.FC<CounterpartiesModalProps> = ({
  counterparty,
  show,
  mode,
  onClose,
  onSaveCounterparty,
  onDeleteCounterparty,
}) => {
  const { t } = useTranslation();
  const methods = useForm<CounterpartyFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      [CounterpartyFormFieldsEnum.nonLeiFlag]: false,
    },
  });
  const { handleSubmit, getValues, setValue, control } = methods;

  useEffect(() => {
    if (counterparty) {
      setValue(CounterpartyFormFieldsEnum.name, counterparty.name);
      setValue(CounterpartyFormFieldsEnum.nonLeiFlag, counterparty.nonLeiFlag);
      setValue(CounterpartyFormFieldsEnum.account, counterparty.account);
      setValue(CounterpartyFormFieldsEnum.countryCode, counterparty.countryCode);
      if (counterparty.lei) {
        setValue(CounterpartyFormFieldsEnum.lei, counterparty.lei);
      }
      if (counterparty.passport) {
        setValue(CounterpartyFormFieldsEnum.passport, counterparty.passport);
      }
      if (counterparty.currency) {
        setValue(CounterpartyFormFieldsEnum.currency, counterparty.currency);
      }
      if (counterparty.nonResidentFlag) {
        setValue(CounterpartyFormFieldsEnum.nonResidentFlag, counterparty.nonResidentFlag);
      }
    }
  }, [counterparty, setValue]);

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = () => {
    onSaveCounterparty(getValues());
  };

  const deleteCounterpartyHandler = () => {
    if (onDeleteCounterparty && counterparty) {
      onDeleteCounterparty(counterparty.id);
    }
  };

  const watchNonLeiFlag = useWatch({ control, name: CounterpartyFormFieldsEnum.nonLeiFlag });
  const recipientPassportClick = () => {
    setValue(CounterpartyFormFieldsEnum.nonLeiFlag, true);
  };

  const recipientLeiClick = () => {
    setValue(CounterpartyFormFieldsEnum.nonLeiFlag, false);
  };

  const watchAccount = useWatch({ control, name: CounterpartyFormFieldsEnum.account });

  useEffect(() => {
    if (watchAccount) {
      setValue(CounterpartyFormFieldsEnum.account, watchAccount.replace(/\s/g, ''));
    }
  }, [setValue, watchAccount]);

  return (
    <ModalCommon open={show}>
      <Grid container direction="column" wrap="nowrap" sx={styles.paper}>
        <Box pb={3}>
          <Grid justifyContent="space-between" alignItems="center" container>
            <Typography component="span" variant="h6" sx={styles.caption}>
              {counterparty ? t('edit_counterparty') : t('counterparties_new')}
            </Typography>
            <IconButton size="small" onClick={onCloseHandler}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container justifyContent="space-between" spacing={7}>
              <Grid item xs={6}>
                <FormLabel>{t('counterparties_name')}</FormLabel>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name="name"
                    placeholder={t('counterparties_name')}
                    defaultValue={counterparty ? counterparty.name : ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                {watchNonLeiFlag ? (
                  <>
                    <Grid container item justifyContent="space-between" alignItems="center">
                      <FormLabel component="legend">{t('paymentCreationLocalPayment_passport')}</FormLabel>
                      {mode !== FormMode.edit ? (
                        <Typography variant="body2" onClick={recipientLeiClick} sx={styles.action}>
                          {t('paymentCreationLocalPayment_lei_select_title')}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Box pt={1}>
                      <PassportField
                        disabled={mode === FormMode.edit}
                        name={CounterpartyFormFieldsEnum.passport}
                        defaultValue={counterparty?.passport || ''}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container item justifyContent="space-between" alignItems="center">
                      <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                      {mode !== FormMode.edit ? (
                        <Typography variant="body2" onClick={recipientPassportClick} sx={styles.action}>
                          {t('paymentCreationLocalPayment_passport_select_title')}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Box pt={1}>
                      <Lei
                        disabled={mode === FormMode.edit}
                        name={CounterpartyFormFieldsEnum.lei}
                        defaultValue={counterparty?.lei || ''}
                      />
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormLabel>{t('account')}</FormLabel>
                <Box pt={1}>
                  {counterparty ? (
                    <InputField
                      fullWidth
                      name="account"
                      placeholder={t('account')}
                      defaultValue={counterparty.account}
                    />
                  ) : (
                    <RecipientAccountSelect name="account" placeholder={t('account')} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormLabel>{t('paymentCreationLocalPayment_bankName')}</FormLabel>
                <Box pt={1}>
                  {counterparty ? (
                    <BankName name="bank" iban={getValues('account')} defaultValue={counterparty.bank} />
                  ) : (
                    <BankName name="bank" iban={getValues('account')} />
                  )}
                </Box>
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={5}>
                {counterparty ? (
                  <Box mr={5}>
                    <Button variant="outlined" size="small" color="primary" onClick={deleteCounterpartyHandler}>
                      {t('delete')}
                    </Button>
                  </Box>
                ) : null}
                <Button variant="contained" size="small" color="primary" type="submit">
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ModalCommon>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FilterIcon, DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { DealStateEnum, PaginationRequestDto } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useProfileControllerSendRequest } from '@app/core/hooks';
import { useGetDepositsList } from '@app/core/hooks/useGetDepositsList';
import { PaginationChangeType, SortingRule } from '@app/core/types';
import { DepositFilter } from '@app/pages/deposits/components/DepositFilter';
import { DepositsTable } from '@app/pages/deposits/components/DepositsTable';
import { makeFilterData } from '@app/pages/deposits/helpers';
import { styles } from '@app/pages/deposits/styles';
import { DepositsFilterType, DepositsTypeEnum } from '@app/pages/deposits/types';
import { requestTopic } from '@app/src/config';
import depositPlaceholderIcon from '@app/themes/default/assets/icons/DepositPlaceholder.svg';

const defaultValues: DepositsFilterType = {
  startDateFrom: undefined,
  startDateTo: undefined,
  endDateFrom: undefined,
  endDateTo: undefined,
  agreementNumber: '',
  currency: '',
  depositType: undefined,
  amountFrom: '',
  amountTo: '',
  interestRateFrom: '',
  interestRateTo: '',
  temp_startDateFrom: '',
  temp_startDateTo: '',
  temp_endDateFrom: '',
  temp_endDateTo: '',
};

export const DepositsContent: React.FC = () => {
  const { showLoader } = useUIState();
  const [states, setStates] = useState<DealStateEnum[]>([DealStateEnum.New, DealStateEnum.Active]);
  const [activeTab, setActiveTab] = useState<DepositsTypeEnum>(DepositsTypeEnum.open);
  const [fullItems, setFullItems] = useState(false);
  const paginationRef = useRef<PaginationRequestDto>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { t } = useTranslation();
  const { notify } = useNotify();

  const { data: allDepositData, mutate: mutateAllDeposits, isLoading: isLoadingAllDepositData } = useGetDepositsList();

  const { data, mutate, isLoading } = useGetDepositsList();

  const { refetch, isSuccess } = useProfileControllerSendRequest(requestTopic.deposit);

  const depositsTabsItems: MenuItem<DepositsTypeEnum>[] = [
    {
      id: 1,
      labelText: t('open'),
      value: DepositsTypeEnum.open,
    },
    {
      id: 2,
      labelText: t('closed'),
      value: DepositsTypeEnum.closed,
    },
  ];

  const methods = useForm<DepositsFilterType>({ mode: 'onChange', defaultValues });
  const { handleSubmit, reset: formReset, formState, getValues } = methods;

  useEffect(() => {
    mutateAllDeposits({ pagination: paginationRef.current });
  }, [mutateAllDeposits]);

  useEffect(() => {
    showLoader(isLoadingAllDepositData);
  }, [isLoadingAllDepositData, showLoader]);

  useEffect(() => {
    mutate({ ...makeFilterData(getValues()), states, pagination: paginationRef.current });
  }, [getValues, mutate, states]);

  const submitFilter = () => {
    mutate({ ...makeFilterData(getValues()), states, pagination: paginationRef.current });
  };

  const handleChange = (currentTab: DepositsTypeEnum): void => {
    setActiveTab(currentTab);
    if (currentTab === DepositsTypeEnum.closed) {
      setStates([DealStateEnum.Closed, DealStateEnum.WillBeClosed, DealStateEnum.Deleted]);
    } else {
      setStates([DealStateEnum.New, DealStateEnum.Active]);
    }
  };

  const onSubmit = () => {
    submitFilter();
  };

  const resetFormHandler = () => {
    formReset();
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitFilter();
    }
  };

  const onSortHandler = (rule: SortingRule) => {
    submitFilter();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    submitFilter();
  };

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  const depositsOperations = data?.deposits || [];
  const pagination = data?.pagination;

  const sendRequest = () => {
    refetch();
  };
  useEffect(() => {
    if (isSuccess) {
      notify({
        notifyProps: {
          title: t('success'),
          message: t('sendRequestSuccess'),
          severity: 'success',
        },
      });
    }
  }, [isSuccess, notify, t]);

  return (
    <>
      <HorizontalMenu<DepositsTypeEnum> onChange={handleChange} menuItems={depositsTabsItems} />

      {allDepositData ? (
        <>
          {allDepositData.deposits.length || formState.isDirty ? (
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyPress={onKeyPressHandler}
                role="presentation"
                style={styles.verticalWrapper}
              >
                <Grid item container direction="column" wrap="nowrap" sx={styles.verticalWrapper}>
                  <Box pb={4} mt={7}>
                    <Grid item container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <FilterIcon />
                        <Typography variant="h6" component="span" sx={styles.captionFilter}>
                          {t('filters')}
                        </Typography>
                      </Grid>
                      <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
                        {t('additionalParameters')}
                        {fullItems ? (
                          <UpChevronSmallIcon fontSize="small" />
                        ) : (
                          <DownChevronSmallIcon fontSize="small" />
                        )}
                      </Button>
                    </Grid>
                  </Box>
                  <Box pb={4}>
                    <DepositFilter
                      fullItems={fullItems}
                      namesFieldWithError={Object.keys(formState.errors)}
                      resetFormHandler={resetFormHandler}
                    />
                  </Box>
                  <DepositsTable
                    loading={isLoading}
                    deposits={depositsOperations}
                    pagination={pagination}
                    onSort={onSortHandler}
                    onPaginationChange={onPaginationChangeHandler}
                  />
                </Grid>
              </form>
            </FormProvider>
          ) : (
            <>
              {!isLoading ? (
                <Grid container alignItems="center" justifyContent="center" sx={styles.placeholder} direction="column">
                  <img src={depositPlaceholderIcon} alt="" />
                  {activeTab === DepositsTypeEnum.open ? (
                    <>
                      <Typography variant="h5" sx={styles.placeholderTitle}>
                        {t('depositPlaceholderTitle')}
                      </Typography>
                      <Typography variant="body2" sx={styles.placeholderDescription}>
                        {t('depositPlaceholderDescription')}
                      </Typography>
                      <Box mt={5}>
                        <Button size="small" variant="contained" color="primary" onClick={sendRequest}>
                          {t('submitApplication')}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Typography variant="h5" sx={styles.placeholderTitle}>
                      {t('depositClosedPlaceholderTitle')}
                    </Typography>
                  )}
                </Grid>
              ) : null}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

import React, { useRef, useState } from 'react';

import { Box, Button, CardActions, CardContent, FormLabel, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { KeyIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { MessageStatus } from '@app/core/api';
import { InputField, SelectField, TextAreaField } from '@app/core/components';
import { FileType } from '@app/core/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { MessageAttachFiles } from './MessageAttachFiles';
import { styles } from './style';
import { MessageEditFormProps, MessageFormData, MessageFormFieldName } from './types';

export const DesktopMessageEditForm: React.FC<MessageEditFormProps> = ({
  defaultValues,
  categoryList,
  onCancelClick,
  onSave,
  onSaveAndSign,
  onSaveAndSendToSign,
}) => {
  const { getValues } = useFormContext<MessageFormData>();
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [files, setFiles] = useState<FileType[]>([]);
  const { notify } = useNotify();

  const handleLoadFile = (file: File) => {
    const extension = file.name.split('.').pop();

    if (file.size > 10000000) {
      notify({
        notifyProps: {
          message: `${t('maxFileSizeLimitMsg')} - 10MB`,
          severity: 'error',
        },
      });
      return;
    }
    if (extension) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setFiles([
            ...files,
            { file: file, name: file.name, result: reader.result, size: (file.size / (1024 * 1024)).toFixed(2) },
          ]);
        }
      };
      reader.readAsText(file);
    }
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleLoadFile(file);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const onFileSelectClickHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0) {
          handleLoadFile(fileInputRef.current.files[0]);
        }
      }
    }
  };

  const onFileDeleteClickHandler = (fileForDelete: FileType) => {
    setFiles(files.filter((file) => file.file.name !== fileForDelete.file.name));
  };

  const onSaveClickHandler = () => {
    const formData = new FormData();
    files.map((file) => formData.append('file', file.file, encodeURIComponent(file.file.name)));
    onSave({ messageData: getValues(), files: formData });
  };

  const onSaveAndSignClickHandler = () => {
    const formData = new FormData();
    files.map((file) => formData.append('file', file.file, encodeURIComponent(file.file.name)));
    onSaveAndSign({ messageData: getValues(), files: formData });
  };

  const onSaveAndSendToSignClickHandler = () => {
    const formData = new FormData();
    files.map((file) => formData.append('file', file.file, encodeURIComponent(file.file.name)));
    onSaveAndSendToSign({ messageData: getValues(), files: formData });
  };

  const enableAttachFiles = defaultValues
    ? defaultValues.status !== MessageStatus.Signed &&
      defaultValues.status !== MessageStatus.Delivered &&
      defaultValues.status !== MessageStatus.Read
    : true;
  return (
    <Grid
      container
      direction="column"
      sx={{
        ...styles.body,
        ...(oveDragZone && styles.dropContainerActive),
      }}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      <Grid item>
        <Grid container direction="column">
          <CardContent>
            <Box mb={6}>
              <Grid container>
                <Grid item xs={6} pr={4}>
                  <FormLabel component="legend">{t('category_letter')}</FormLabel>
                  <Box pt={2} pr={1}>
                    <SelectField
                      fullWidth
                      name={MessageFormFieldName.categoryId}
                      options={categoryList}
                      defaultValue=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('subject')}</FormLabel>
                  <Box pt={2}>
                    <InputField fullWidth name={MessageFormFieldName.subject} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mb={6}>
              <Grid item xs={12}>
                <FormLabel component="legend">{t('text')}</FormLabel>
                <Box pt={2}>
                  <TextAreaField
                    name={MessageFormFieldName.message}
                    maxLength={500}
                    textFieldProps={{
                      multiline: true,
                      fullWidth: true,
                      rows: 3,
                      placeholder: t('letter_text'),
                    }}
                  />
                </Box>
              </Grid>
            </Box>
            {enableAttachFiles ? (
              <Box mb={6}>
                <MessageAttachFiles
                  files={files}
                  documents={defaultValues?.documents}
                  onFileSelectClick={onFileSelectClickHandler}
                  onFileDeleteClick={onFileDeleteClickHandler}
                />
              </Box>
            ) : null}
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button color="primary" onClick={onCancelClick}>
              {t('paymentCreationFormControl_cancel')}
            </Button>
            <Box sx={{ justifyContent: 'space-between' }}>
              <>
                <Button sx={styles.sendButton} variant="outlined" color="primary" onClick={onSaveClickHandler}>
                  {t('save')}
                </Button>
                {permissions.sign.message.allowToSign ? (
                  <Button
                    sx={styles.sendButton}
                    variant="contained"
                    color="primary"
                    onClick={onSaveAndSignClickHandler}
                  >
                    {t('sign')}
                    <KeyIcon sx={styles.keyIcon} />
                  </Button>
                ) : null}
                {permissions.sign.message.allowToSendToSign ? (
                  <Button
                    sx={styles.sendButton}
                    variant="contained"
                    color="primary"
                    onClick={onSaveAndSendToSignClickHandler}
                  >
                    {t('sendToSign')}
                    <KeyIcon sx={styles.keyIcon} />
                  </Button>
                ) : null}
              </>
            </Box>
          </CardActions>
        </Grid>
      </Grid>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="upload"
        name="upload"
        type="file"
        onChange={fileSelected}
      />
    </Grid>
  );
};

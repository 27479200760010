import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUIState } from '@app/context/UIContext';
import { FrontLanguageEnum } from '@app/core/api';
import { SelectOptionType } from '@app/core/components';
import { useGetCategoriesList } from '@app/core/hooks';

import { DesktopMessageEditForm } from './DesktopMessageEditForm';
import { styles } from './style';
import { MessageDefaultValues, MessageEditData, MessageFormData, MessageFormFieldName } from './types';
import { validationSchema } from './validationSchema';

export interface LocalPaymentEditFormProps {
  defaultValues?: MessageDefaultValues;
  onSave(messageEditData: MessageEditData): void;
  onSaveAndSign(messageEditData: MessageEditData, signFromAnotherPerson?: boolean): void;
  onSaveAndSendToSign(messageEditData: MessageEditData): void;
  onCancel(): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const MessageEditForm: React.FC<LocalPaymentEditFormProps> = ({
  defaultValues,
  onSave,
  onSaveAndSign,
  onSaveAndSendToSign,
  onChange,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const { showLoader } = useUIState();
  const { data: categoriesData, mutate: mutateGetCategoriesList, isLoading: isLoading1 } = useGetCategoriesList();
  const methods = useForm<MessageFormData>({
    resolver: yupResolver(validationSchema({ t })),
    mode: 'onSubmit',
    defaultValues: {
      [MessageFormFieldName.conversationId]: undefined,
    },
  });

  const {
    control,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { dirtyFields },
  } = methods;

  useEffect(() => {
    showLoader(isLoading1);
  }, [isLoading1, showLoader]);

  useEffect(() => {
    clearErrors();
  }, [clearErrors, defaultValues]);

  useEffect(() => {
    if (defaultValues?.subject) {
      setValue(MessageFormFieldName.subject, defaultValues.subject);
    }
    if (defaultValues?.message) {
      setValue(MessageFormFieldName.message, defaultValues.message);
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (defaultValues?.categoryId && categoriesData) {
      setValue(MessageFormFieldName.categoryId, defaultValues.categoryId, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
  }, [defaultValues, setValue, categoriesData]);

  useEffect(() => {
    mutateGetCategoriesList({
      language: i18n.language as FrontLanguageEnum,
      pagination: { page: 1, rowsPerPage: 100 },
    });
  }, [i18n.language, mutateGetCategoriesList]);

  const watchCategory = useWatch({ control, name: MessageFormFieldName.categoryId });

  useEffect(() => {
    if (Object.keys(dirtyFields).length || (watchCategory && watchCategory.toString().length > 0)) {
      onChange({ formDirty: true });
    }
  }, [dirtyFields, onChange, watchCategory]);

  const onSaveClickHandler = (messageEditData: MessageEditData) => {
    trigger().then((valid) => {
      if (valid) {
        onSave(messageEditData);
      }
    });
  };

  const onSaveAndSignHandler = (messageEditData: MessageEditData) => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSign(messageEditData);
      }
    });
  };

  const onSendToSignHandler = (messageEditData: MessageEditData) => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSendToSign(messageEditData);
      }
    });
  };

  const categoryList: SelectOptionType[] = useMemo(() => {
    if (categoriesData && categoriesData.data.length > 0) {
      return categoriesData.data.map((item) => ({ id: item.id, name: item.name, value: item.id }));
    }
    return [];
  }, [categoriesData]);

  return (
    <FormProvider {...methods}>
      <form style={styles.form}>
        <DesktopMessageEditForm
          defaultValues={defaultValues}
          categoryList={categoryList}
          onCancelClick={onCancel}
          onSave={onSaveClickHandler}
          onSaveAndSign={onSaveAndSignHandler}
          onSaveAndSendToSign={onSendToSignHandler}
        />
      </form>
    </FormProvider>
  );
};

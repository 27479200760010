export const styles = {
  actionsContainer: {
    padding: '5px 16px 5px 0',
    minHeight: '46px',
  },
  selectedActionsContainer: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '5px 16px',
  },
  selectedCount: {
    color: 'white',
    marginRight: '16px',
  },
  selectedAmountSum: {
    color: 'white',
  },
  selectedAmountCurrency: {
    color: 'white',
    marginRight: '12px',
  },
  actionIcon: {
    marginRight: '16px',
    color: 'colors.primary500',
  },
  selected: {
    color: 'white',
  },
  divider: {
    height: '20px',
    backgroundColor: 'colors.gray100',
    marginRight: '16px',
    width: '1px',
  },
  buttonRoot: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  selectedText: {
    color: '#ffffff',
  },
  operationsCountTitle: {
    color: 'colors.gray400',
  },
  operationsCount: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  totalAmountTitle: {
    color: 'colors.gray400',
  },
  totalAmount: {
    color: 'colors.primary700',
    fontWeight: 600,
  },
  totalAmountCurrency: {
    color: 'colors.primary700',
    fontWeight: 400,
  },
  menuItem: {
    borderBottom: `1px solid`,
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

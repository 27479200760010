import { useState, useEffect } from 'react';

const getOrientation = () => window.screen.orientation?.type;
const isLandscapeMatch = () => window.matchMedia('(orientation: landscape)').matches;
const isPortraitMatch = () => window.matchMedia('(orientation: portrait)').matches;

type ScreenOrientation = {
  orientation: string;
  isLandscape: boolean;
  isPortrait: boolean;
};
export const useScreenOrientation = (): ScreenOrientation => {
  const [orientation, setOrientation] = useState({
    orientation: getOrientation(),
    isLandscape: isLandscapeMatch(),
    isPortrait: isPortraitMatch(),
  });
  const updateOrientation = () => {
    setTimeout(
      () =>
        setOrientation({
          orientation: getOrientation(),
          isLandscape: isLandscapeMatch(),
          isPortrait: isPortraitMatch(),
        }),
      90
    );
  };

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);

  return {
    ...orientation,
  };
};

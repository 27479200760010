import React from 'react';

import { Box, Button, FormLabel, Grid, InputAdornment, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '@app/common/icons';
import { CurrencyOperationsExchangeRateTypeEnum, CurrencyOperationsExchangeTypeEnum } from '@app/core/api';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { Tooltip } from '@app/core/components/Tooltip';
import { ExchangeRateSelectField } from '@app/pages/exchange/components/form/components/ExchangeRateSelectField';
import { PurchaseAdditionalBlock } from '@app/pages/exchange/components/form/components/PurchaseAdditionalBlock';
import { getTransferMethodTranslationKeyLabel } from '@app/pages/exchange/components/form/helpers';
import { styles } from '@app/pages/exchange/components/form/style';
import { ExchangeFormContentProps, FormFieldName } from '@app/pages/exchange/components/form/types';

export const MobileExchangeFormContent: React.FC<ExchangeFormContentProps> = ({
  uuid,
  currencyExchangeType,
  watchDocumentDate,
  withdrawalAccounts,
  depositAccounts,
  withdrawalAccountPlaceholder,
  depositAccountPlaceholder,
  amountLabel,
  currency,
  conversionRateLabel,
  exchangeRateDisable,
  selectedWithdrawalAccount,
  files,
  buttonDisabled,
  signPermissions,
  onFileSelectClick,
  onFileDeleteClick,
  onSave,
  onCancelButtonClick,
  onSaveAndSign,
  onUploadFilesClick,
  onSendToSign,
}) => {
  const { t } = useTranslation();
  const nowDate = new Date();

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item container direction="column">
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
          <Box pt={2}>
            <DateSelectField
              name={FormFieldName.documentDate}
              selectedDate={nowDate}
              datePickerProps={{
                disabled: { before: nowDate },
              }}
              inputProps={{ fullWidth: true }}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
          <Box pt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <DateSelectField
                  name={FormFieldName.documentValueDate}
                  datePickerProps={{
                    disabled: {
                      before: watchDocumentDate || nowDate,
                      after: addDays(watchDocumentDate || nowDate, 29),
                    },
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <Tooltip color="primary" title={t('currencyExchange_valuationDateTooltip')} arrow placement="bottom">
                  <Box mr={1} textAlign="center" pt={1}>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('write-offAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={FormFieldName.withdrawalAccount}
              options={withdrawalAccounts}
              placeholder={withdrawalAccountPlaceholder}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('enrollmentAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={FormFieldName.depositAccount}
              options={depositAccounts}
              placeholder={depositAccountPlaceholder}
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{amountLabel}</FormLabel>
          <Box pt={2}>
            <FormatInputField
              type={FormatType.amount}
              name={FormFieldName.amount}
              InputProps={{
                endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
              }}
              placeholder={'0.00'}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{conversionRateLabel}</FormLabel>
          <Box pt={2}>
            <ExchangeRateSelectField
              name={FormFieldName.exchangeRateType}
              defaultValue={CurrencyOperationsExchangeRateTypeEnum.Bank}
              disabled={currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('course')}</FormLabel>
          <Box pt={2}>
            <FormatInputField
              type={FormatType.amount}
              name={FormFieldName.exchangeRate}
              disabled={exchangeRateDisable}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {exchangeRateDisable ? '' : selectedWithdrawalAccount?.account.currency || ''}
                  </InputAdornment>
                ),
              }}
              placeholder={exchangeRateDisable ? '' : t('specifyCourse')}
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{t('maintenanceCommissionAccount')}</FormLabel>
          <Box pt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <InputField
                  fullWidth
                  name="unused"
                  disabled
                  placeholder={t('maintenanceCommissionAccountPlaceholder')}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <Tooltip
                  color="primary"
                  title={t('maintenanceCommissionAccount-tooltip') || ''}
                  arrow
                  placement="bottom"
                >
                  <Box pr={1} pt={1}>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('transferMethod')}</FormLabel>
          <Box pt={2}>
            <CheckboxField
              label={
                <Typography variant="caption">
                  {t(getTransferMethodTranslationKeyLabel(currencyExchangeType))}
                </Typography>
              }
              defaultChecked={true}
              name={FormFieldName._userConfirmCheck}
              sx={styles.transactionPresentedLabel}
            />
          </Box>
        </Box>

        {currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Purchase ||
        currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion ? (
          <PurchaseAdditionalBlock
            files={files}
            onFileSelectClick={onFileSelectClick}
            onFileDeleteClick={onFileDeleteClick}
          />
        ) : null}
      </Grid>

      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between">
          {uuid && files.length ? (
            <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onUploadFilesClick}>
              {t('downloadFiles')}
            </Button>
          ) : (
            <>
              <Grid item xs={4}>
                <Box pr={1}>
                  <Button fullWidth variant="outlined" color="primary" onClick={onSave} disabled={buttonDisabled}>
                    {t('paymentCreationFormControl_save')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box pl={2}>
                  {signPermissions.allowToSign ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={onSaveAndSign}
                      disabled={buttonDisabled}
                    >
                      {t('paymentCreationFormControl_send')}
                    </Button>
                  ) : null}
                  {signPermissions.allowToSendToSign ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={onSendToSign}
                      disabled={buttonDisabled}
                    >
                      {t('paymentCreationFormControl_sendToSign')}
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

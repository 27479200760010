import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon, InfoIcon } from '@app/common/icons';
import { PaymentOrderErrorMessagesEnum, PaymentTemplateTypeEnum } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { Switch } from '@app/core/components/Form/components/Switch';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { PassportTabs, PassportTabsEnum } from '@app/core/components/PassportTabs';
import { Tooltip } from '@app/core/components/Tooltip';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';
import { Lei } from '@app/pages/payment/components/Lei';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import { FormFieldName, LocalPaymentEditFormProps } from '@app/pages/payment/components/LocalPaymentEditForm/types';
import { PassportField } from '@app/pages/payment/components/PassportField';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { RecipientNameSelect } from '@app/pages/payment/components/RecipientNameSelect';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

export const MobileLocalPaymentEditForm: React.FC<LocalPaymentEditFormProps> = ({
  defaultValues,
  defaultDocumentDate,
  maxDocumentDate,
  minDocumentDate,
  maxValuationDate,
  minValuationDate,
  senderAccountsData,
  passportActiveTab,
  vatEnabled,
  showBalance,
  countedBalance,
  onSelectCounterpartyHandler,
  clearRecipientHandler,
  passportTabsChangeHandler,
  vatSwitchHandler,
  signPermissions,
  additionalInfo,
  onCancelClick,
  onSaveClick,
  onSaveAndSignClick,
  onTemplateChangeHandler,
  onSendToSign,
  documentType,
  createTemplateData,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { getValues, setValue, control, formState } = useFormContext();
  const { t } = useTranslation();
  const [countryCheck, setCountryCheck] = useState<boolean>(false);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const documentNumberRef = useRef<HTMLElement>(null);

  const countryCheckHandler = (e: React.ChangeEvent<{}>, checked: boolean) => {
    setCountryCheck(checked);
  };

  const watchRecipientNonLeiFlag = useWatch({ control, name: FormFieldName.recipientNonLeiFlag });
  const watchRealSenderPresent = useWatch({ control, name: FormFieldName.realSenderPresent });
  const watchRealRecipientPresent = useWatch({ control, name: FormFieldName.realRecipientPresent });
  const watchRealSenderNonLeiFlag = useWatch({ control, name: FormFieldName.realSenderNonLeiFlag });
  const watchRealRecipientNonLeiFlag = useWatch({ control, name: FormFieldName.realRecipientNonLeiFlag });
  const watchRealRecipientNonResidentFlag = useWatch({ control, name: FormFieldName.realRecipientNonResidentFlag });
  const watchRealSenderNonResidentFlag = useWatch({ control, name: FormFieldName.realSenderNonResidentFlag });

  const recipientPassportClick = () => {
    setValue(FormFieldName.recipientNonLeiFlag, true);
  };

  const recipientLeiClick = () => {
    setValue(FormFieldName.recipientNonLeiFlag, false);
  };

  useEffect(() => {
    setInvalidAmount(Boolean(formState.errors?.amount));
  }, [formState]);

  useEffect(() => {
    if (additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid container wrap="nowrap" direction="column" sx={styles.contentMobile}>
      <Grid item container direction="column">
        <Box mt={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              <Box pr={4} ref={documentNumberRef}>
                <DocumentNumberField
                  name={FormFieldName.paymentDocumentNumber}
                  title={documentType ? t(documentType) : t('PAYMENT_ORDER_FLAG')}
                  defaultValue={defaultValues.documentNumber || 'Auto123'}
                  error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
                  validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={onCancelClick}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {onTemplateChangeHandler ? (
          <Box mt={6}>
            <SelectPaymentTemplate
              templateType={PaymentTemplateTypeEnum.General}
              onTemplateChange={onTemplateChangeHandler}
              placeholder={t('enterName_placeholder')}
            />
          </Box>
        ) : null}
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
          <Box mt={2}>
            <DateSelectField
              name={FormFieldName.documentDate}
              selectedDate={
                defaultValues.paymentDocumentDate ? new Date(defaultValues.paymentDocumentDate) : new Date()
              }
              datePickerProps={{
                disabled: { after: maxDocumentDate, before: minDocumentDate },
                month: defaultDocumentDate,
              }}
              inputProps={{ fullWidth: true }}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Grid container direction="column">
            <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
            <Box mt={2}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={11}>
                  <DateSelectField
                    name={FormFieldName.documentValuationDate}
                    datePickerProps={{
                      month: minValuationDate,
                      disabled: { after: maxValuationDate, before: minValuationDate },
                      toDate: maxValuationDate,
                    }}
                    inputProps={{ fullWidth: true }}
                  />
                </Grid>
                <Grid item container xs={1} alignContent="center" justifyContent="flex-end">
                  <Tooltip
                    enterTouchDelay={10}
                    color="primary"
                    title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                    arrow
                    placement="right"
                  >
                    <span>
                      <InfoIcon fontSize="small" sx={styles.info} />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField name={FormFieldName.senderAccount} options={senderAccountsData?.accounts} />
          </Box>
        </Box>
        {appConfig.enableNextFeatures ? (
          <>
            <Box mt={6}>
              <Box>
                <Box>
                  <CheckboxField
                    label={<Typography variant="caption">{t('addRealSender')}</Typography>}
                    defaultChecked={false}
                    name={FormFieldName.realSenderPresent}
                    sx={styles.checkboxField}
                  />
                </Box>
              </Box>
              {watchRealSenderPresent ? (
                <Box mt={6}>
                  <FormLabel component="legend">{t('realSenderName')}</FormLabel>
                  <Box pt={2}>
                    <InputField
                      fullWidth
                      name={FormFieldName.realSenderName}
                      defaultValue={defaultValues.realSenderName}
                    />
                  </Box>
                  <Box mt={6}>
                    {!watchRealSenderNonLeiFlag ? (
                      <>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                          <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                            {t('paymentCreationLocalPayment_passport_select_title')}
                          </Typography>
                        </Grid>
                        <Box pt={2}>
                          <Lei name={FormFieldName.realSenderTIN} defaultValue={defaultValues.realSenderTIN} />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Grid container justifyContent="space-between" alignItems="center" sx={styles.passport}>
                          <PassportTabs
                            onChange={passportTabsChangeHandler}
                            defaultActiveTab={PassportTabsEnum.passport}
                          />
                          <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                            {t('paymentCreationLocalPayment_lei_select_title')}
                          </Typography>
                        </Grid>
                        <Box pt={2}>
                          {passportActiveTab === PassportTabsEnum.passport ? (
                            <PassportField name={FormFieldName.realSenderPassport} />
                          ) : null}
                          {passportActiveTab === PassportTabsEnum.idPassport ? (
                            <FormatInputField
                              type={FormatType.number}
                              name={FormFieldName.realSenderPassport}
                              placeholder="0000000000"
                              maxLength={10}
                            />
                          ) : null}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box pt={4}>
                    <CheckboxField
                      label={<Typography variant="caption">{t('realSenderNonResidentLabel')}</Typography>}
                      defaultChecked={false}
                      name={FormFieldName.realSenderNonResidentFlag}
                      sx={styles.transactionPresentedLabel}
                    />
                  </Box>
                  {watchRealSenderNonResidentFlag ? (
                    <Box pt={5}>
                      <CountrySelect
                        name={FormFieldName.realSenderCountryCode}
                        defaultValue={defaultValues.realSenderCountryCode}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </>
        ) : null}
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientName')}</FormLabel>
          <Box pt={2}>
            <RecipientNameSelect
              name={FormFieldName.recipientName}
              defaultValue={defaultValues.recipientAccountName}
              onSelectCounterparty={onSelectCounterpartyHandler}
              onClear={clearRecipientHandler}
            />
          </Box>
        </Box>
        <Box mt={6}>
          {!watchRecipientNonLeiFlag ? (
            <>
              <Grid container justifyContent="space-between" alignItems="center">
                <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                  {t('paymentCreationLocalPayment_passport_select_title')}
                </Typography>
              </Grid>
              <Box pt={2}>
                <Lei name={FormFieldName.recipientLei} defaultValue={defaultValues.recipientLei} />
              </Box>
            </>
          ) : (
            <>
              <Grid container justifyContent="space-between" alignItems="center" sx={styles.passport}>
                <PassportTabs onChange={passportTabsChangeHandler} defaultActiveTab={PassportTabsEnum.passport} />
                <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                  {t('paymentCreationLocalPayment_lei_select_title')}
                </Typography>
              </Grid>
              <Box pt={2}>
                {passportActiveTab === PassportTabsEnum.passport ? (
                  <PassportField name={FormFieldName.recipientPassport} />
                ) : null}
                {passportActiveTab === PassportTabsEnum.idPassport ? (
                  <FormatInputField
                    type={FormatType.number}
                    name={FormFieldName.idPassport}
                    placeholder="0000000000"
                    maxLength={10}
                  />
                ) : null}
              </Box>
            </>
          )}
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientAccount')}</FormLabel>
          <Box pt={2}>
            <RecipientAccountSelect
              name={FormFieldName.recipientAccount}
              defaultValue={defaultValues.recipientAccountNumber}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <CheckboxField
            label={t('paymentCreationLocalPayment_transactionPresentedLabel')}
            // checked={countryCheck}
            defaultChecked={false}
            name={FormFieldName.nonResidentFlag}
            sx={styles.transactionPresentedLabel}
            onChange={countryCheckHandler}
          />
        </Box>
        {countryCheck ? (
          <Box mt={5}>
            <CountrySelect name={FormFieldName.countryCode} />
          </Box>
        ) : null}
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_bankName')}</FormLabel>
          <Box pt={2}>
            <BankName
              name={FormFieldName._bankName}
              iban={getValues(FormFieldName.recipientAccount)}
              defaultValue={defaultValues.recipientBankName}
            />
          </Box>
        </Box>
        {appConfig.enableNextFeatures ? (
          <>
            <Box mt={6}>
              <Box>
                <CheckboxField
                  label={<Typography variant="caption">{t('addRealRecipient')}</Typography>}
                  defaultChecked={false}
                  name={FormFieldName.realRecipientPresent}
                  sx={styles.checkboxField}
                />
              </Box>
              {watchRealRecipientPresent ? (
                <Box mt={6}>
                  <FormLabel component="legend">{t('realRecipientName')}</FormLabel>
                  <Box pt={1}>
                    <InputField
                      fullWidth
                      name={FormFieldName.realRecipientName}
                      defaultValue={defaultValues.realRecipientName}
                    />
                  </Box>
                  <Box mt={6}>
                    {!watchRealRecipientNonLeiFlag ? (
                      <>
                        <Grid container item justifyContent="space-between" alignItems="center">
                          <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                          {appConfig.enableNextFeatures ? (
                            <Typography variant="body2" onClick={recipientPassportClick} sx={styles.lei}>
                              {t('paymentCreationLocalPayment_passport_select_title')}
                            </Typography>
                          ) : null}
                        </Grid>
                        <Box pt={1}>
                          <Lei name={FormFieldName.realRecipientTIN} defaultValue={defaultValues.realRecipientTIN} />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Grid container item justifyContent="space-between" alignItems="center" sx={styles.passport}>
                          <PassportTabs
                            onChange={passportTabsChangeHandler}
                            defaultActiveTab={PassportTabsEnum.passport}
                          />
                          <Typography variant="body2" onClick={recipientLeiClick} sx={styles.lei}>
                            {t('paymentCreationLocalPayment_lei_select_title')}
                          </Typography>
                        </Grid>
                        <Box pt={1}>
                          {passportActiveTab === PassportTabsEnum.passport ? (
                            <PassportField name={FormFieldName.recipientPassport} />
                          ) : null}
                          {passportActiveTab === PassportTabsEnum.idPassport ? (
                            <FormatInputField
                              type={FormatType.number}
                              name={FormFieldName.idPassport}
                              placeholder="0000000000"
                              maxLength={10}
                            />
                          ) : null}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box pt={4}>
                    <CheckboxField
                      label={
                        <Typography variant="caption">
                          {t('paymentCreationLocalPayment_transactionPresentedLabel')}
                        </Typography>
                      }
                      defaultChecked={false}
                      name={FormFieldName.realRecipientNonResidentFlag}
                      sx={styles.transactionPresentedLabel}
                    />
                  </Box>
                  {watchRealRecipientNonResidentFlag ? (
                    <Box pt={5}>
                      <CountrySelect
                        name={FormFieldName.realRecipientCountryCode}
                        defaultValue={defaultValues.realRecipientCountryCode}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </>
        ) : null}
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
          <Box pt={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={11}>
                <FormatInputField
                  defaultValue={defaultValues.amount ? `${defaultValues.amount}` : ''}
                  type={FormatType.amount}
                  name={FormFieldName.amount}
                  placeholder={'0.00'}
                />
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end" alignItems="center">
                <Tooltip color="primary" title={t('tooltip_bank-tariff')} arrow enterTouchDelay={10} placement="right">
                  <span>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={6}>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid container wrap="nowrap" item xs={6} justifyContent="space-between" alignItems="center">
              <Typography variant="body2" sx={{ ...(vatEnabled && styles.vatTextDisabled) }}>
                {t('paymentCreationLocalPayment_withoutVat')}
              </Typography>
              <Box pl={1}>
                <Switch checked={vatEnabled} onChange={vatSwitchHandler} />
              </Box>
              <Typography variant="body2" sx={{ ...(!vatEnabled && styles.vatTextDisabled) }}>
                {t('paymentCreationLocalPayment_withVat')}
              </Typography>
            </Grid>

            <Grid container wrap="nowrap" item xs={6} justifyContent="flex-end" alignItems="center">
              <Box minHeight={40} width="100%">
                {vatEnabled ? (
                  <Grid container wrap="nowrap" item alignItems="center" justifyContent="space-between">
                    <InputField
                      defaultValue={20}
                      sx={styles.mobileVatInput}
                      name={FormFieldName.vat}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                    %
                  </Grid>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={6} sx={styles.balance}>
          <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
          <Typography sx={styles.balanceText}>{showBalance ? `${countedBalance} UAH` : ''}</Typography>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
          <Box pt={2}>
            <TextAreaField
              defaultValue={defaultValues.paymentReference || ''}
              name={FormFieldName.purpose}
              textFieldProps={{
                multiline: true,
                fullWidth: true,
                rows: 2,
              }}
              maxLength={420}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <SavePaymentTemplate createTemplateData={createTemplateData} />
          </Grid>
        </Box>
      </Grid>

      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          {permissions.uahOperations.create || permissions.uahOperations.edit ? (
            <Box pr={4} width="40%">
              <Button fullWidth variant="outlined" color="primary" onClick={onSaveClick} disabled={invalidAmount}>
                {t('paymentCreationFormControl_save')}
              </Button>
            </Box>
          ) : null}
          {signPermissions.allowToSendToSign ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSendToSign} disabled={invalidAmount}>
                {t('paymentCreationFormControl_sendToSign')}
              </Button>
            </Box>
          ) : null}
          {signPermissions.allowToSign ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSaveAndSignClick}>
                {t('paymentCreationFormControl_send')}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

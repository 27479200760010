import { SecureKeyQueryConfigType, SecureKeyType } from '@app/pages/profile/desktop/components/Keys/types';

import keysJSON from './mock/userKeyData.json';

export const userKeysDataFn = async (config: SecureKeyQueryConfigType | undefined): Promise<SecureKeyType[]> => {
  return new Promise<SecureKeyType[]>((resolve) => {
    setTimeout(() => {
      resolve(keysJSON.data as SecureKeyType[]);
    }, 500);
  });
};

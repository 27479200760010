import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon, SendIcon } from '@app/common/icons';
import { CheckAvailabilityFundsDtoStatusEnum, ResultStatusEnum } from '@app/core/api';
import { useCheckAvailabilityFunds } from '@app/core/hooks';
import { ReactComponent as SignIcoBgSVG } from '@app/themes/default/assets/icons/modalSignatureBig.svg';

import { sendToSignPayment } from './query';
import { styles } from './style';
import { Operation } from '../../types';
import { formatAmount } from '../../utils';
import { ModalCommon } from '../Modal';
import { StatusPopup } from '../StatusPopup';

interface SendToSignPaymentOrderProps {
  selectedDocuments: Operation[];
  onSubmitSuccess(): void;
  onClose(): void;
}

export const SendToSignPaymentOrder: React.FC<SendToSignPaymentOrderProps> = ({
  selectedDocuments,
  onSubmitSuccess,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showSuccessSignModal, setShowSuccessSignModal] = useState<boolean>(false);
  const [showErrorSignModal, setShowErrorSignModal] = useState<boolean>(false);

  const { data: sendToSignResponseData, mutate } = useMutation(sendToSignPayment);
  const { data: checkAvailabilityData, mutate: mutateAvailabilityFunds } = useCheckAvailabilityFunds();
  const [showActions, setShowActions] = useState<boolean>(true);
  const [insufficientFundsError, setInsufficientFundsError] = useState<boolean>(false);

  useEffect(() => {
    mutateAvailabilityFunds(selectedDocuments.map((item) => item.uuid));
  }, [mutateAvailabilityFunds, selectedDocuments]);

  // useEffect(() => {
  //   if (checkAvailabilityData) {
  //     if (checkAvailabilityData.result === CheckAvailabilityFundsResponseDtoResultEnum.InsufficientFunds) {
  //       setInsufficientFundsError(true);
  //     }
  //     setShowActions(true);
  //   }
  // }, [checkAvailabilityData]);

  const docsForSign = selectedDocuments.filter((item) => item.actions?.allowToSendToSign);
  const uuids = docsForSign.map((item) => item.uuid);

  const onSignedStringResultHandler = () => {
    mutate(uuids);
  };
  useEffect(() => {
    if (sendToSignResponseData) {
      if (sendToSignResponseData.status === ResultStatusEnum.Successfully) {
        setShowSuccessSignModal(true);
      }
      if (sendToSignResponseData.status === ResultStatusEnum.Error) {
        setShowErrorSignModal(true);
      }
    }
  }, [sendToSignResponseData]);

  const amount = selectedDocuments.reduce((acc, document) => {
    return acc + document.amount;
  }, 0);

  const docsForSignCount: number = docsForSign.length;

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSignModal(false);
    onSubmitSuccess();
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSignModal(false);
  };

  const errorMessage = docsForSignCount > 1 ? t('payments_sign_error_message') : t('payment_sign_error_message');

  return (
    <>
      <ModalCommon onClose={onClose} open>
        <Box sx={styles.paper}>
          {insufficientFundsError ? (
            <Box sx={styles.errorNotifyContainer}>
              <Typography variant="caption" sx={styles.errorNotifyText}>
                {t('notEnoughFunds')}
              </Typography>
            </Box>
          ) : null}
          <Box sx={styles.iconBg}>
            <SignIcoBgSVG />
          </Box>
          <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
            <Box mt={4} mr={4}>
              <IconButton size="small" onClick={onClose}>
                <CrossIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Box mt={3} mb={7}>
            <Typography variant="body1" sx={styles.title}>
              {t('documentsToSign')}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body2" sx={styles.text}>
              {t('sign_selectedDocuments')}{' '}
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedDocuments.length}
              </Typography>
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body2" sx={styles.text}>
              {t('sign_documentsToSign')}{' '}
              <Typography variant="body2" component="span" sx={styles.value}>
                {docsForSignCount}
              </Typography>
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body2" sx={styles.text}>
              {t('deleteDocumentAmount')}{' '}
              <Typography variant="body2" component="span" sx={styles.value}>
                {formatAmount(amount)}
              </Typography>{' '}
              {selectedDocuments[0].currency}
            </Typography>
          </Box>
          {showActions ? (
            <Grid container justifyContent="center">
              <Box pr={6}>
                <Button size="small" variant="outlined" color="primary" onClick={onClose}>
                  {t('no')}
                </Button>
              </Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onSignedStringResultHandler}
                endIcon={<SendIcon />}
              >
                {t('send')}
              </Button>
            </Grid>
          ) : null}
        </Box>
      </ModalCommon>
      <StatusPopup
        show={showSuccessSignModal}
        title={t('sentToSign')}
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSignModal}
        title={errorMessage}
        onOk={onErrorPopupCancelHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

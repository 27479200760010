import React, { useState } from 'react';

import { Box, Button, Grid, Hidden, IconButton, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import * as queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  CopyIcon,
  CsvIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PdfIcon,
  PreviewIcon,
  PrintIcon,
  SendIcon,
  SignatureIcon,
  XlsIcon,
  XmlIcon,
} from '@app/common/icons';
import {
  GetCurrencyOperationWithAvailableActionsDto,
  StatementTypeEnum,
  TotalAmountByCurrencyDto,
} from '@app/core/api';
import { ConfirmEditOperationPopup } from '@app/core/components/ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { formatAmount } from '@app/core/utils';
import { getActiveButtonsConfig } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/activeButtons';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/components/CurrenctExchangeTable/components/TableToolbar/styles';
import { DeleteExchangeDocumentDialog } from '@app/pages/exchange/currencyExchangeTransactions/components/DeleteExchangeDocumentDialog';
import { SendToSignExchangeDocumentDialog } from '@app/pages/exchange/currencyExchangeTransactions/components/SendToSignDocumentDialog';
import { SignExchangeDocumentDialog } from '@app/pages/exchange/currencyExchangeTransactions/components/SignExchangeDocumentDialog';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface TableToolbarProps {
  selectedOperations: GetCurrencyOperationWithAvailableActionsDto[];
  queryKey?: string;
  totalAmounts?: Array<TotalAmountByCurrencyDto>;
  totalCount?: number;
  onDeleteDocumentSuccess(): void;
  onSignDocumentSuccess(): void;
  onSendToSignDocumentsSuccess(): void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  selectedOperations,
  queryKey,
  totalAmounts,
  totalCount,
  onDeleteDocumentSuccess,
  onSignDocumentSuccess,
  onSendToSignDocumentsSuccess,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
  const [showSignDocumentDialog, setShowSignDocumentDialog] = useState<boolean>(false);
  const [showSendToSignDialog, setShowSendToSignDialog] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);

  const handleClickDownload = () => {
    return null;
  };

  const onEditClickHandler = () => {
    const { uuid, signed } = selectedOperations[0];
    if (signed) {
      setShowConfirmEditPopup(true);
    } else {
      navigate(RouteList.exchange_edit.replace(':uuid', uuid));
    }
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    const { uuid } = selectedOperations[0];
    navigate(RouteList.exchange_edit.replace(':uuid', uuid));
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const onViewClickHandler = () => {
    if (selectedOperations) {
      const { uuid } = selectedOperations[0];
      navigate(RouteList.exchange_show.replace(':uuid', uuid));
    }
  };

  const onSubmitHandler = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onSubmitSignSuccessHandler = () => {
    setShowSignDocumentDialog(false);
    onSignDocumentSuccess();
  };

  const onSubmitSendToSignSuccessHandler = () => {
    setShowSendToSignDialog(false);
    onSendToSignDocumentsSuccess();
  };

  const onDeleteDocumentHandler = () => {
    setShowDeleteDocumentDialog(true);
  };

  const onCancelDeleteDocument = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onDeleteDocumentSuccessHandler = () => {
    setShowDeleteDocumentDialog(false);
    onDeleteDocumentSuccess();
  };

  const onSignDocumentHandler = () => {
    setShowSignDocumentDialog(true);
  };
  const onSendToSignDocumentHandler = () => {
    setShowSendToSignDialog(true);
  };

  const onCancelSignDocument = () => {
    setShowSignDocumentDialog(false);
  };

  const onCancelSendToSignDocument = () => {
    setShowSendToSignDialog(false);
  };

  const onCopyClickHandler = () => {
    setMoreMenuAnchorEl(null);
    const urlTo = queryString.stringifyUrl({
      url: RouteList.exchange_create,
      query: {
        copyFrom: selectedOperations[0].uuid,
      },
    });
    navigate(urlTo);
  };

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };
  const onPrintClickHandler = () => {
    window.open(`${selectedOperations[0].link}`, '_blank');
  };
  const onDownloadHandlerClickHandler = () => {
    window.open(`${selectedOperations[0]?.link}&format=download`, '_blank');
  };

  const activeButtonsConfig = getActiveButtonsConfig(selectedOperations, permissions);

  return (
    <>
      <Box pb={1}>
        {selectedOperations?.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
          >
            <Grid item container alignItems="center" justifyContent="flex-start">
              <Typography variant="body2" sx={styles.selectedCount}>
                {selectedOperations.length} {t('selected')}
              </Typography>
              <Box sx={styles.divider} />
              {activeButtonsConfig.sign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSignDocumentHandler}
                    startIcon={<SignatureIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('sign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sendToSign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSendToSignDocumentHandler}
                    startIcon={<SendIcon />}
                  >
                    <Hidden lgDown>
                      <Typography sx={styles.selectedText}>{t('toSign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.edit ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onEditClickHandler} startIcon={<EditIcon />}>
                    {activeButtonsConfig.edit.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('change')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}

              {activeButtonsConfig.view ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onViewClickHandler}
                    startIcon={<PreviewIcon />}
                  >
                    {activeButtonsConfig.view.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('preview')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.delete ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onDeleteDocumentHandler}
                    startIcon={<DeleteIcon />}
                  >
                    {activeButtonsConfig.delete.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('delete')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.copy ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onCopyClickHandler} startIcon={<CopyIcon />}>
                    {activeButtonsConfig.copy.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('copy')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.download ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onDownloadHandlerClickHandler}
                    startIcon={<DownloadIcon />}
                  >
                    {activeButtonsConfig.download.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('download')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.print ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onPrintClickHandler} startIcon={<PrintIcon />}>
                    {activeButtonsConfig.print.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('print')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.moreMenu ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onMenuBtnClickHandler}
                    startIcon={<MoreIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('more')}</Typography>
                    </Hidden>
                  </Button>

                  <Popover
                    anchorEl={moreMenuAnchorEl}
                    keepMounted
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={handleCloseMoreMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {activeButtonsConfig.moreMenu.copy ? (
                      <MenuItem sx={styles.menuItem} onClick={onCopyClickHandler}>
                        <ListItemIcon>
                          <CopyIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('copy')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.download ? (
                      <MenuItem sx={styles.menuItem} onClick={onDownloadHandlerClickHandler}>
                        <ListItemIcon>
                          <DownloadIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('download')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.print ? (
                      <MenuItem sx={styles.menuItem} onClick={onPrintClickHandler}>
                        <ListItemIcon>
                          <PrintIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('print')}</Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="space-between" sx={styles.actionsContainer}>
            <Grid item container xs={4} alignItems="center" justifyContent="flex-start">
              {appConfig.enableCurrencyOperationsDownloadButtons ? (
                <>
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
                    <CsvIcon />
                  </IconButton>
                  {queryKey === StatementTypeEnum.Statement ? (
                    <IconButton
                      size="small"
                      color="default"
                      sx={styles.actionIcon}
                      onClick={() => handleClickDownload()}
                    >
                      <PdfIcon />
                    </IconButton>
                  ) : null}
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
                    <XlsIcon />
                  </IconButton>
                  <IconButton size="small" color="default" sx={styles.actionIcon} onClick={() => handleClickDownload()}>
                    <XmlIcon />
                  </IconButton>
                  {queryKey === StatementTypeEnum.Statement ? (
                    <IconButton
                      size="small"
                      color="default"
                      sx={styles.actionIcon}
                      onClick={() => handleClickDownload()}
                    >
                      <PrintIcon />
                    </IconButton>
                  ) : null}
                </>
              ) : null}
            </Grid>
            <Grid item container xs={8} alignItems="center" justifyContent="flex-end">
              {totalCount ? (
                <Grid item>
                  <Box pr={4}>
                    <Typography variant="body2" component="span" sx={styles.operationsCountTitle}>
                      {t('statementsTablePanelDocuments')}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={styles.operationsCount}
                    >{` ${totalCount}`}</Typography>
                  </Box>
                </Grid>
              ) : null}
              {totalAmounts ? (
                <>
                  <Typography variant="body2" component="span" sx={styles.totalAmountTitle}>
                    {t('statementsTablePanelTotalAmount')}
                  </Typography>
                  {totalAmounts.map((amount, i) => {
                    return (
                      <Box pl={4} key={`totalAmounts_${i.toString()}`}>
                        <Typography variant="body2" component="span" sx={styles.totalAmount}>{` ${formatAmount(
                          amount.amount
                        )}`}</Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={styles.totalAmountCurrency}
                        >{` ${amount.currency}`}</Typography>
                      </Box>
                    );
                  })}
                </>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Box>
      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
      {showDeleteDocumentDialog ? (
        <DeleteExchangeDocumentDialog
          onSubmit={onSubmitHandler}
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          selectedDocuments={selectedOperations}
        />
      ) : null}
      {showSignDocumentDialog ? (
        <SignExchangeDocumentDialog
          onSubmitSuccess={onSubmitSignSuccessHandler}
          onClose={onCancelSignDocument}
          selectedDocuments={selectedOperations}
        />
      ) : null}
      {showSendToSignDialog ? (
        <SendToSignExchangeDocumentDialog
          onSubmitSuccess={onSubmitSendToSignSuccessHandler}
          onCancel={onCancelSendToSignDocument}
          selectedDocuments={selectedOperations}
        />
      ) : null}
    </>
  );
};

import React from 'react';

import {
  CreateTemplateRequestDto,
  DocumentTemplateDto,
  GetDefaultAccountsResponseDto,
  PaymentOrderAdditionalInfoDto,
  TaxDto,
  UserResourceDto,
} from '@app/core/api';
import { Severity } from '@app/core/types';

export enum FormFieldName {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  documentValuationDate = 'documentValuationDate',
  senderAccount = 'senderAccount',
  recipientName = 'recipientName',
  recipientLei = 'recipientLei',
  recipientBankName = 'recipientBankName',
  recipientAccount = 'recipientAccount',
  recipientNonLeiFlag = 'recipientNonLeiFlag',
  paymentCode = 'paymentCode',
  paymentInfo = 'paymentInfo',
  elsePersonFlag = 'elsePersonFlag',
  elsePersonLei = 'elsePersonLei',
  amount = 'amount',
  paymentReference = 'paymentReference',
}

export type StatePaymentFormDefaultValues = {
  documentNumber?: string;
  documentDate?: string;
  documentValuationDate?: string;
  senderAccount?: string;
  recipientName?: string;
  recipientLei?: string;
  recipientBankName?: string;
  recipientAccount?: string;
  paymentCode?: string;
  paymentInfo?: string;
  elsePersonLei?: string;
  amount?: number;
  paymentReference?: string;
  recipientNonLeiFlag?: boolean;
  elsePersonFlag?: boolean;
};

export type StatePaymentFormData = {
  [FormFieldName.documentNumber]: string;
  [FormFieldName.documentDate]: Date;
  [FormFieldName.documentValuationDate]?: Date;
  [FormFieldName.senderAccount]: string;
  [FormFieldName.recipientAccount]: string;
  [FormFieldName.recipientLei]: string;
  [FormFieldName.recipientName]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.recipientBankName]: string;
  [FormFieldName.paymentCode]: string;
  [FormFieldName.elsePersonFlag]: boolean;
  [FormFieldName.elsePersonLei]: string;
  [FormFieldName.paymentReference]: string;
  [FormFieldName.paymentInfo]: string;
  [FormFieldName.recipientNonLeiFlag]: boolean;
};

export interface StatePaymentFormProps {
  defaultValues?: StatePaymentFormDefaultValues;
  senderAccountsData: GetDefaultAccountsResponseDto | undefined;
  showBalance: boolean;
  countedBalance: string;
  permissions: UserResourceDto;
  notEnoughBalance: boolean;
  elsePersonCheck: boolean;
  generatedOrderNumber?: string;
  taxesList?: TaxDto[];
  additionalInfo?: PaymentOrderAdditionalInfoDto | null;
  onCancelHandler(): void;
  onSaveClickHandler(): void;
  onSaveAndSignClickHandler(): void;
  onSendToSign(): void;
  elsePersonCheckHandler(e: React.ChangeEvent<{}>, checked: boolean): void;
  createTemplateData(): Omit<CreateTemplateRequestDto, 'templateName'>;
  onTemplateChangeHandler?(templateData: DocumentTemplateDto): void;
  errorSeverity?: Severity;
  blockAction: boolean;
}

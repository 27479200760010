import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  configurationApiClient,
  getClientId,
  ApiError,
  GetStatementsConfigRequestDto,
  GetStatementsConfigResponseDto,
} from '@app/core/api';

export const useGetStatementsConfigList = (): UseMutationResult<
  GetStatementsConfigResponseDto,
  ApiError,
  GetStatementsConfigRequestDto
> => {
  return useMutation(async (getStatementsConfigRequestDto: GetStatementsConfigRequestDto) => {
    const result = await configurationApiClient.clientConfigControllerGetStatementsConfigList(
      getClientId(),
      getStatementsConfigRequestDto
    );

    if (result.status !== 200) {
      throw new ApiError(result.request.status, "Can't get the statements config list.");
    }

    return result.data;
  });
};

import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material';

import { useUIState } from '@app/context/UIContext';
import { isMobile } from '@app/core/utils';

const Main = styled('main')<{ sidebarOpen: boolean; isMobile: boolean }>(
  ({ sidebarOpen, isMobile: mobile, theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(100% - ${sidebarOpen ? '272px' : '80px'})`,
      flexBasis: `calc(100% - ${sidebarOpen ? '272px' : '80px'})`,
    },
    [theme.breakpoints.down('xs')]: {
      transform: mobile && sidebarOpen ? 'translate(272px, 0)' : undefined,
    },
  })
);

export const MainContent: React.FC<PropsWithChildren> = ({ children }) => {
  const { sidebarOpen } = useUIState();
  return (
    <Main sidebarOpen={sidebarOpen} isMobile={isMobile}>
      {children}
    </Main>
  );
};

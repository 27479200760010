import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, Grid, IconButton, Typography } from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { FrontLanguageEnum } from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { OtpInput } from '@app/core/components/OtpInput/OtpInput';
import { StatusText } from '@app/core/components/StatusText';
import { useEnable2fa, useValidate2fa } from '@app/core/hooks';
import { isMobile } from '@app/core/utils';
import { validationSchema } from '@app/pages/profile/desktop/components/Security/components/validationSchema';
import { styles } from '@app/pages/profile/desktop/components/Security/style';

interface ActivationsModalProps {
  onClose(): void;
  onActivationSuccess(): void;
}

export const ActivationsModal: React.FC<ActivationsModalProps> = ({ onClose, onActivationSuccess }) => {
  const [focusOtp, setFocusOtp] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const { notify } = useNotify();

  const { data, mutate: mutateEnable2fa } = useEnable2fa();

  const { mutate: mutateValidate2Fa } = useValidate2fa();

  const methods = useForm<{ code: string }>({
    resolver: yupResolver(validationSchema(t)),
    mode: 'onSubmit',
  });

  const { control, getValues, trigger, setValue } = methods;

  const onConfirm = () => {
    const { code } = getValues();
    trigger().then((valid) => {
      if (valid) {
        mutateValidate2Fa(
          { language: i18n.language as FrontLanguageEnum, code },
          {
            onSuccess: onActivationSuccess,
            onError: () => {
              setValue('code', '');
              setFocusOtp(true);
              notify({
                notifyProps: {
                  title: t('error'),
                  message: t('wrongCodeError'),
                  severity: 'error',
                },
              });
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    mutateEnable2fa({ language: i18n.language as FrontLanguageEnum });
  }, [i18n.language, mutateEnable2fa]);

  return (
    <FormProvider {...methods}>
      <ModalCommon onClose={onClose} open>
        <form>
          <Box sx={styles.paper}>
            <Box pb={3} width="100%">
              <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Typography sx={styles.modalTitle}>{t('gaActivationTitle')}</Typography>
                <IconButton size="small" onClick={onClose}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Box>
            <Box mt={3}>
              <Typography variant="body2">{t('gaModalDescription')}</Typography>
            </Box>
            <Grid container direction={isMobile ? 'column' : 'row'}>
              <Grid item container xs={isMobile ? 12 : 4} justifyContent={isMobile ? 'center' : 'flex-start'}>
                <img role="presentation" src={data?.result.secret} alt="qr" />
              </Grid>
              <Grid item container alignItems="flex-start" justifyContent="space-between" xs={8}>
                <Box mt={3} mb={isMobile ? 3 : 0}>
                  <Typography variant="body2">{t('gaActivationKey')}</Typography>
                  {data ? (
                    <Typography variant="body2" sx={styles.code}>
                      {isMobile ? (
                        <>
                          {data.result.secretCode?.slice(0, 35)}
                          <br />
                          {data.result.secretCode?.slice(35)}
                        </>
                      ) : (
                        <>
                          {data.result.secretCode?.slice(0, 45)}
                          <br />
                          {data.result.secretCode?.slice(45)}
                        </>
                      )}
                    </Typography>
                  ) : null}
                </Box>
                <Box mb={5}>
                  <Typography variant="body2">{t('gaGneratedPassword')}</Typography>
                  <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <OtpInput
                          value={value}
                          numInputs={6}
                          shouldAutoFocus
                          isInputNum
                          inputStyle={styles.otp}
                          hasErrored={Boolean(error)}
                          errorStyle={styles.otpError}
                          onChange={onChange}
                          setFocus={focusOtp}
                        />
                        {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
                      </>
                    )}
                    name="code"
                    control={control}
                  />
                </Box>
              </Grid>
            </Grid>
            <CardActions sx={styles.cardAction}>
              <Grid>
                <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onClose}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                  {t('confirm')}
                </Button>
              </Grid>
            </CardActions>
          </Box>
        </form>
      </ModalCommon>
    </FormProvider>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon, DoneIcon } from '@app/common/icons';
import { StatusText } from '@app/core/components';

import { styles } from './style';
import { cropName } from '../../../../utils';

type DocumentNumberFieldProps = {
  name: string;
  title: string;
  error?: boolean;
  defaultValue: string;
  validateErrorMessage?: string;
};

export const DocumentNumberField: React.FC<DocumentNumberFieldProps> = ({
  name,
  error = false,
  validateErrorMessage = '',
  title,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue, register } = useFormContext();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  useEffect(() => {
    register(name, { required: true, value: defaultValue });
  }, [defaultValue, name, register]);

  useEffect(() => {
    if (error) {
      setErrorMessage(validateErrorMessage);
    }
  }, [error, validateErrorMessage]);

  const showEditor = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage('');
    setEditMode(!editMode);
  };

  useEffect(() => {
    setInputValue(defaultValue);
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^[a-zA-Zа-яА-Я0-9]*$/g.test(e.target.value as string)) {
      setInputValue(e.target.value as string);
    }
  };

  const onDoneEditHandler = async () => {
    if (inputValue.length > 0) {
      setValue(name, inputValue);
      setErrorMessage('');
      setEditMode(false);
    } else {
      setErrorMessage(t('errorEmptyField'));
    }
  };
  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onDoneEditHandler();
    }
  };

  return (
    <>
      {editMode ? (
        <Grid container wrap="nowrap">
          <Box pt={2}>
            <Typography sx={styles.title}>{title}</Typography>
          </Box>
          <TextField
            error={errorMessage.length > 0}
            helperText={t(errorMessage)}
            variant="outlined"
            autoFocus
            placeholder={t('paymentCreationLocalPayment_paymentNumber_input_placeholder')}
            value={inputValue}
            onChange={handleChange}
            onKeyPress={onEnterPress}
          />
          <Box pt={1}>
            <IconButton sx={styles.editorButton} onClick={onDoneEditHandler} size="large">
              <DoneIcon fontSize="small" sx={styles.editorIconDone} />
            </IconButton>
            <IconButton sx={styles.editorButton} onClick={showEditor} size="large">
              <CrossIcon fontSize="small" sx={styles.editorIconCancel} />
            </IconButton>
          </Box>
        </Grid>
      ) : (
        <Grid container direction="column" onClick={showEditor} sx={styles.documentBlock}>
          <Box>
            <Box component="span" sx={styles.title}>
              {`${title} № `}
            </Box>
            {getValues(name) ? (
              <Box sx={styles.documentNumberWrapper}>
                <Box component="div" sx={styles.documentNumber}>
                  {cropName(getValues(name), 17)}
                </Box>
                {errorMessage.length > 0 ? <StatusText absolute>{t(errorMessage)}</StatusText> : null}
              </Box>
            ) : null}
          </Box>
        </Grid>
      )}
    </>
  );
};

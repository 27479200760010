import React, { useEffect } from 'react';

import { Grid, MenuItem, Typography } from '@mui/material';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccountStatusEnum, GetAccountResponseDto } from '@app/core/api';
import { StatusText } from '@app/core/components';
import { Severity } from '@app/core/types';

import { AccountSelectOption } from './AccountSelectOption';
import { SelectField } from '../SelectField';

type AccountSelectFieldProps = {
  name: string;
  placeholder?: string;
  options: GetAccountResponseDto[] | undefined;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: string;
  defaultSelectFirst?: boolean;
  withEmpty?: boolean;
  errorSeverity?: Severity;
  defaultAccount?: GetAccountResponseDto | null;
};

export const AccountSelectField: React.FC<AccountSelectFieldProps> = ({
  name,
  placeholder,
  options,
  defaultValue,
  defaultSelectFirst = false,
  withEmpty = false,
  errorSeverity,
  defaultAccount,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (options && (defaultSelectFirst || options.length === 1) && !defaultAccount && !withEmpty) {
      setValue(name, JSON.stringify(options[0]));
    }
  });

  return (
    <>
      <SelectField
        fullWidth
        name={name}
        defaultValue={defaultValue || ''}
        placeholder={placeholder || ''}
        displayEmpty={Boolean(placeholder)}
        disabled={!(options && options.length)}
        errorSeverity={errorSeverity}
        renderValue={(selected: any) => {
          if (!selected && placeholder) {
            return (
              <Typography
                variant="body2"
                sx={{
                  color: 'colors.gray300',
                  fontWeight: 400,
                }}
              >
                {placeholder}
              </Typography>
            );
          }
          return <AccountSelectOption optionValue={selected} />;
        }}
      >
        {withEmpty ? <MenuItem value="">{t('emptyMenuOption')}</MenuItem> : null}
        {options?.map((account) => {
          const optionValue = JSON.stringify(account);
          if (
            defaultAccount?.account.id === account.account.id &&
            defaultAccount.account.status !== AccountStatusEnum.Active
          ) {
            return (
              <MenuItem
                key={account.account.id}
                value={optionValue}
                sx={{
                  backgroundColor: 'rgba(248, 198, 55, 0.08)',
                  '&& .Mui-selected': { backgroundColor: 'rgba(248, 198, 55, 0.08)' },
                }}
              >
                <Grid container direction="column">
                  <AccountSelectOption optionValue={optionValue} />
                  <StatusText severity={'warning'}>{t('warningBlockedAccount')}</StatusText>
                </Grid>
              </MenuItem>
            );
          }
          return (
            <MenuItem key={account.account.id} value={optionValue}>
              <AccountSelectOption optionValue={optionValue} />
            </MenuItem>
          );
        })}
      </SelectField>
    </>
  );
};

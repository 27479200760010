import React from 'react';

import { useTranslation } from 'react-i18next';

import { HorizontalMenu } from '../HorizontalMenu';
import { MenuItem } from '../HorizontalMenu/types';

export enum PassportTabsEnum {
  passport = 'passport',
  idPassport = 'idPassport',
}

interface PassportTabsProps {
  defaultActiveTab: PassportTabsEnum;
  onChange(tab: PassportTabsEnum): void;
}

export const PassportTabs: React.FC<PassportTabsProps> = ({ defaultActiveTab, onChange }) => {
  const { t } = useTranslation();

  const newPassportTabsItems: MenuItem<PassportTabsEnum>[] = [
    {
      id: 1,
      labelText: t('paymentCreationLocalPayment_passport'),
      value: PassportTabsEnum.passport,
    },
    {
      id: 2,
      labelText: t('paymentCreationLocalPayment_id_passport'),
      value: PassportTabsEnum.idPassport,
    },
  ];

  const passportTypeChange = (currentTab: PassportTabsEnum): void => {
    onChange(currentTab);
  };
  return (
    <HorizontalMenu<PassportTabsEnum>
      onChange={passportTypeChange}
      menuItems={newPassportTabsItems}
      defaultActiveItem={defaultActiveTab}
      styles={{
        itemDefault: {
          userSelect: 'none',
          cursor: 'pointer',
          fontWeight: 600,
          marginRight: '8px',
          fontSize: '14px',
        },
      }}
    />
  );
};

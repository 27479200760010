import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { parse } from 'query-string';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, FilterIcon, UpChevronSmallIcon } from '@app/common/icons';
import { ActionFiltersButtons } from '@app/core/components';
import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { DocumentCategorySelectField } from '@app/core/components/Form/controls/DocumentCategorySelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { OperationsTypeSelectField } from '@app/core/components/Form/controls/OperationsTypeSelectField';
import { StatementsFilterField, StatementsFilterFormData } from '@app/core/components/Statements/types';
import { styles } from '@app/pages/statements/components/StatementsFilters/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';
import { ReactComponent as QuestionIconSvg } from '@app/themes/default/assets/icons/16x16/question.svg';

interface StatementsFiltersProps {
  defaultValues: DefaultValues<StatementsFilterFormData>;
  resetFormHandler(): void;
}

export const StatementsFilters: React.FC<StatementsFiltersProps> = ({ resetFormHandler, defaultValues }) => {
  const { t } = useTranslation();
  const { allAccounts } = useAppSelector((state): ProfileState => state.profile);
  const [defaultSelectedAccountsId, setDefaultSelectedAccountsId] = useState<string[]>();
  const { operationAccounts, dateRange: defaultDateRange, dateRangeSelect, ...otherDefaultValues } = defaultValues;

  const [fullItems, setFullItems] = useState<boolean>(
    Object.entries(otherDefaultValues).filter(([key, value]) => {
      return !isEmpty(value);
    }).length > 0
  );

  useEffect(() => {
    if (allAccounts) {
      const { operationAccounts: operationAccountsId } = parse(location.search);
      if (operationAccountsId) {
        setDefaultSelectedAccountsId((operationAccountsId as string).split(','));
      }
    }
  }, [allAccounts]);

  const handleSelectAccountsLength = (value: []) => value.length > 0 || t('statementsFilter_errorAmountField');

  const handleShowMoreItems = () => {
    setFullItems(!fullItems);
  };

  return (
    <Grid item container direction="column">
      <Box mb={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FilterIcon className="MuiSvgIcon" />
            <Typography variant="h6" component="span" sx={styles.captionFilter}>
              {t('filters')}
            </Typography>
          </Grid>
          <Button color="primary" sx={styles.toggleButton} onClick={handleShowMoreItems}>
            {t('additionalParameters')}
            {fullItems ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
          </Button>
        </Grid>
      </Box>
      <Box pb={4}>
        <Grid container direction="column" sx={styles.wrapper}>
          <CardContent>
            <Grid item container justifyContent="space-between" spacing={5}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={styles.inputWrapper}>
                  <FormLabel component="legend">{t('accounts')}</FormLabel>
                  <Box pt={2}>
                    <AccountsWithSearchSelectField
                      name={StatementsFilterField.operationAccounts}
                      defaultSelectedAccountsId={defaultSelectedAccountsId}
                      accounts={allAccounts}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component="legend">{t('statementsFilter_dateRange')}</FormLabel>
                <FormControl fullWidth sx={styles.inputWrapper}>
                  <Box pt={2}>
                    <DateRangeSelectField
                      formSelectName="dateRangeSelect"
                      formDateRangeName={StatementsFilterField.dateRange}
                    />
                  </Box>
                </FormControl>
              </Grid>
              {fullItems ? (
                <Grid item container xs={3} alignItems="flex-start" sx={styles.questionWrap}>
                  <Box pt={4}>
                    {appConfig.enableStatementsGroupCommission ? (
                      <>
                        <CheckboxField
                          label={<Typography variant="caption">{t('statementsFilter_groupCommissions')}</Typography>}
                          name={StatementsFilterField.groupCommissions}
                          defaultValue="true"
                          sx={styles.transactionPresentedLabel}
                        />
                        <Tooltip
                          title={t('statementsFilter_groupCommissions_tooltip')}
                          placement="bottom"
                          classes={{ tooltip: `${styles.customTooltip}` }}
                          TransitionProps={{ timeout: 0 }}
                        >
                          <QuestionIconSvg style={styles.questionIcon} />
                        </Tooltip>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              ) : (
                <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                  <Box pt={6} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              )}
              {fullItems ? (
                <>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_LEI')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        name={StatementsFilterField.LEI}
                        placeholder={t('statementsFilter_dateRange_LEI_placeholder')}
                        type={FormatType.number}
                        fullWidth
                        maxLength={10}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_counterPartyName')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.counterPartyName}
                        placeholder={t('statementsFilter_counterPartyName_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('statementsFilter_counterPartyAccountNumber')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.counterPartyAccountNumber}
                        placeholder={t('statementsFilter_counterPartyAccountNumber_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        type={FormatType.amount}
                        defaultValue=""
                        name={StatementsFilterField.amountMin}
                        fullWidth
                        placeholder="0.00"
                        sx={styles.fullWidth}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
                    <Box pt={2}>
                      <FormatInputField
                        type={FormatType.amount}
                        defaultValue=""
                        name={StatementsFilterField.amountMax}
                        fullWidth
                        placeholder="0.00"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">UAH</InputAdornment>,
                        }}
                        sx={styles.fullWidth}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_operationType')}</FormLabel>
                    <Box pt={2}>
                      <OperationsTypeSelectField name={StatementsFilterField.operationType} styles={styles.fullWidth} />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('statementsFilter_documentCategory')}</FormLabel>
                    <Box pt={2}>
                      <DocumentCategorySelectField
                        name={StatementsFilterField.documentCategory}
                        styles={styles.fullWidth}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <FormLabel component="legend">{t('statementsFilter_paymentReference')}</FormLabel>
                    <Box pt={2}>
                      <InputField
                        name={StatementsFilterField.paymentReference}
                        placeholder={t('statementsFilter_paymentReference_placeholder')}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={3}>
                    <Box sx={styles.actionButtonsContainer}>
                      <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                    </Box>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Grid>
      </Box>
    </Grid>
  );
};

import {
  currencyDocumentsApiClient,
  getClientId,
  ApiError,
  EditFileRequestDto,
  EditFileResponseDto,
  SendToSignResponseDto,
} from '@app/core/api';

interface EditCurrencyDocumentsQueryFnProps {
  uuid: string;
  editFileRequestDto: EditFileRequestDto;
}

export const editCurrencyDocumentsQueryFn = async ({
  uuid,
  editFileRequestDto,
}: EditCurrencyDocumentsQueryFnProps): Promise<EditFileResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerEditFile(
    getClientId(),
    uuid,
    editFileRequestDto
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "currencyDocumentsControllerGetFiles error. Can't edit document");
  }

  return response.data;
};

export const sendToSignCurrencyDocumentsQueryFn = async (uuid: string): Promise<SendToSignResponseDto> => {
  const response = await currencyDocumentsApiClient.documentsControllerBatchSendToSign(getClientId(), {
    uuids: [uuid],
  });

  if (response.status !== 200) {
    throw new ApiError(
      response.request.status,
      "currencyDocumentsControllerGetFiles error. Can't send to sign document"
    );
  }

  return response.data;
};

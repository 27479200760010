import { string, array, object, SchemaOf } from 'yup';

type SchemaObject = {
  exchangeCurrencyList: string[];
  bankBranch: string | undefined;
  dateRangeSelect: string;
};

type BankSchemaObject = {
  exchangeCurrencyList: string[];
  dateRangeSelect: string;
};

type ValidationSchemaProps = {
  validateBankBranch: boolean;
  t: (key: string) => string;
};

export const validationSchema = ({ validateBankBranch, t }: ValidationSchemaProps): SchemaOf<SchemaObject> => {
  return object().shape({
    exchangeCurrencyList: array().min(1, t('exchange_currencySelect_error')),
    bankBranch: string().test('bankBranch', t('exchangeRatesFilter_errorBankBranch'), (value) => {
      if (!validateBankBranch) return true;
      if (!value) return false;
      return value.length > 0;
    }),
    dateRangeSelect: string().required(t('exchangeRatesFilter_errorDateRange')),
  });
};

export const bankRatesSchema = (t: (key: string) => string): SchemaOf<BankSchemaObject> => {
  return object().shape({
    exchangeCurrencyList: array().min(1, t('exchange_currencySelect_error')),
    dateRangeSelect: string().required(t('exchangeRatesFilter_errorDateRange')),
  });
};

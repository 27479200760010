export const styles = {
  paper: {
    backgroundColor: 'white',
    padding: '28px',
    outline: 0,
    borderRadius: '8px',
    width: '456px',
  },
  placeholder: {
    color: 'colors.gray300',
  },
  dropContainer: {
    width: '100%',
    minHeight: '100px',
    border: `1.5px dashed`,
    borderColor: 'colors.primary200',
    borderRadius: '8px',
    padding: '20px',
  },
  dropContainerDefault: {
    backgroundColor: 'colors.primary25',
  },
  dropContainerActive: {
    backgroundColor: 'colors.primary25',
  },
  dropContainerError: {
    backgroundColor: '#FFF7F9',
    borderColor: 'colors.error',
  },
  dropContainerCertificateVerified: {
    borderColor: 'colors.success',
    backgroundColor: 'colors.successBackground',
  },
  errorColorText: {
    color: 'colors.error',
  },
  fontWeight: {
    fontWeight: 600,
  },
  label: {
    textDecoration: 'underline',
  },
  mainText: {
    color: 'colors.gray500',
    textAlign: 'center',
  },
  secondText: {
    color: 'colors.gray300',
  },
  buttonWrap: {
    textAlign: 'right',
  },
  passwordInput: {
    paddingRight: 0,
  },
  infoText: {
    color: 'colors.gray400',
  },
  infoOwnerName: {
    wordBreak: 'break-all',
    color: 'colors.gray600',
    fontWeight: 600,
  },
  infoValue: {
    wordBreak: 'break-all',
    color: 'colors.gray600',
    fontWeight: 400,
  },
  selectKeyFileLabel: {
    textDecoration: 'underline',
    fontWeight: 600,
    '@media (max-width: 959.95px)': {
      color: 'colors.primary500',
      fontSize: '0.875rem',
      paddingBottom: '8px',
    },
  },
  tooltipContainer: {
    left: '-30px!important',
  },
  tooltipArrow: {
    transform: 'translate(30px, 0px)!important',
  },
  euNativeLibraryErrorContainer: {
    fontWeight: 600,
    'div > a': {
      color: 'colors.primary500',
      textDecoration: 'none',
    },
  },
  errorAlert: {
    backgroundColor: 'colors.errorBackground',
    border: `1px solid`,
    borderColor: 'colors.error',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.error',
    zIndex: 10,
    width: 'auto',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  errorAlertText: {
    fontWeight: 600,
    color: 'colors.error',
    marginLeft: '8px',
  },
  expDaysContent: {
    backgroundColor: '#f5f3bb',
    padding: '0 12px',
  },
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { accountsApiClient, ApiError, GetAccountResponseDto, getClientId } from '@app/core/api';

const getSingleAccountQueryFn = async (id: string): Promise<GetAccountResponseDto> => {
  const response = await accountsApiClient.accountsControllerGetAccountById(id, getClientId());

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error accountsControllerGetAccountById!');
  }
  return response.data;
};

export const useGetSingleAccount = (): UseMutationResult<GetAccountResponseDto, ApiError, string> => {
  return useMutation<GetAccountResponseDto, ApiError, string>(getSingleAccountQueryFn);
};

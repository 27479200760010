import React, { useEffect, useRef } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { FrontLanguageEnum } from '@app/core/api';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetInternationalBanksList } from '@app/core/hooks/useGetInternationalBanksList';
import { ColumnFilterType, Langs, PaginationChangeType, SortingRule } from '@app/core/types';
import { SwiftBanksTable } from '@app/pages/banksSwift/components/SwiftBankTable';
import { SwiftBankColumnEnum } from '@app/pages/banksSwift/types';

export const BanksSwiftPage: React.FC = () => {
  const { data, mutate, isLoading } = useGetInternationalBanksList();
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { t, i18n } = useTranslation();
  const columnFilter = useRef<ColumnFilterType<SwiftBankColumnEnum>>({});
  const currentLang: Langs = i18n.language as Langs;
  const submitFilter = () => {
    const { swift, name, location } = columnFilter.current;
    mutate({
      name,
      swift,
      location,
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  };

  useEffect(() => {
    const { swift, name, location } = columnFilter.current;
    mutate({
      name,
      swift,
      location,
      language: currentLang as unknown as FrontLanguageEnum,
      pagination: paginationRef.current,
    });
  }, [currentLang, mutate]);

  const onSortHandler = (rule: SortingRule) => {
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    submitFilter();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    submitFilter();
  };

  const onColumnFilterChangeHandler = (
    columnFilterUpdater: ColumnFilterType<SwiftBankColumnEnum>,
    submit?: boolean
  ) => {
    columnFilter.current = {
      ...columnFilter.current,
      ...columnFilterUpdater,
    };
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    if (submit) {
      if (isLoading) {
        return;
      }
      submitFilter();
    }
  };

  const onClearSubFilterHandler = () => {
    columnFilter.current = {};
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };

    if (isLoading) {
      return;
    }
    submitFilter();
  };
  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('directories_swift_bank')} showNavigateButton backLink={-1} useDesktopStyle />
        <PageContainer>
          <Grid container direction="column">
            <Box
              mt={4}
              sx={{
                height: '100%',
              }}
            >
              <SwiftBanksTable
                data={data?.rows || []}
                loading={isLoading}
                columnFilter={columnFilter.current}
                onSort={onSortHandler}
                pagination={data?.pagination}
                onColumnFilterChange={onColumnFilterChangeHandler}
                onPaginationChange={onPaginationChangeHandler}
                onClearSubFilter={onClearSubFilterHandler}
              />
            </Box>
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

import {
  GetOperationsFileDto,
  OperationTypeEnum,
  OutputFormatEnum,
  PaymentOrderStatusEnum,
  StatementTemplateTypeEnum,
  StatementTypeEnum,
  SubFilterDto,
} from '@app/core/api';
import { OperationsFilter, OperationsFilterFormData } from '@app/pages/operations/types';

export const makeDataForSubmit = (
  formData: OperationsFilterFormData
): Omit<OperationsFilter, 'pagination' | 'subFilter'> => {
  const {
    operationAccounts,
    dateRange,
    LEI,
    counterPartyName,
    counterPartyAccountNumber,
    counterPartyBankCode,
    documentNumber,
    documentStatus,
    amountMin,
    amountMax,
    hideCommission,
    paymentReference,
  } = formData;

  const operationAccountsList = operationAccounts?.map((account) => ({
    id: account.account.id,
    currencyCode: account.account.currency,
  }));

  const endDate = dateRange?.to.toISOString();
  const startDate = dateRange?.from.toISOString();

  const submitData: Omit<OperationsFilter, 'pagination' | 'subFilter'> = {
    operationAccounts: operationAccountsList,
    dateRange: { endDate, startDate },
    LEI: LEI?.length ? LEI : undefined,
    counterPartyName: counterPartyName?.length ? counterPartyName : undefined,
    counterPartyAccountNumber: counterPartyAccountNumber?.length ? counterPartyAccountNumber : undefined,
    counterPartyBankCode: counterPartyBankCode?.length ? counterPartyBankCode : undefined,
    documentNumber: documentNumber?.length ? documentNumber : undefined,
    documentStatus: documentStatus?.length ? documentStatus : undefined,
    hideCommission,
    paymentReference: paymentReference?.length ? paymentReference : undefined,
  };

  if (amountMin) {
    submitData.amountMin = parseFloat(amountMin);
  }

  if (amountMax) {
    submitData.amountMax = parseFloat(amountMax);
  }

  return submitData;
};

export const makeDataForGetStatementsFile = ({
  outputType,
  documentType,
  formData,
  subFilter,
  operationType,
}: {
  outputType: OutputFormatEnum;
  documentType: StatementTypeEnum;
  formData: OperationsFilterFormData;
  subFilter: SubFilterDto;
  operationType?: OperationTypeEnum;
}): GetOperationsFileDto => {
  const {
    operationAccounts,
    dateRange,
    LEI,
    counterPartyName,
    counterPartyAccountNumber,
    amountMin,
    amountMax,
    paymentReference,
    documentStatus,
    counterPartyBankCode,
    hideCommission,
  } = formData;

  const operationAccountsList = operationAccounts?.map((account) => ({
    id: account.account.id,
    currencyCode: account.account.currency,
  }));

  const startDate = dateRange?.from.toISOString();
  const endDate = dateRange?.to.toISOString();

  const submitData: Omit<GetOperationsFileDto, 'pagination'> = {
    operationAccounts: operationAccountsList,
    dateRange: { endDate, startDate },
    LEI: LEI?.length ? LEI : undefined,
    counterPartyName: counterPartyName?.length ? counterPartyName : undefined,
    counterPartyAccountNumber: counterPartyAccountNumber?.length ? counterPartyAccountNumber : undefined,
    paymentReference: paymentReference?.length ? paymentReference : undefined,
    subFilter,
    documentStatus: documentStatus?.length ? (documentStatus as unknown as PaymentOrderStatusEnum) : undefined,
    outputType,
    documentType,
    counterPartyBankCode: counterPartyBankCode?.length ? counterPartyBankCode : undefined,
    hideCommission,
    operationType,
    templateType: StatementTemplateTypeEnum.Default,
  };

  if (amountMin) {
    submitData.amountMin = parseFloat(amountMin);
  }

  if (amountMax) {
    submitData.amountMax = parseFloat(amountMax);
  }

  return submitData;
};

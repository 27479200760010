import { useMutation, UseMutationResult } from 'react-query';

import { getClientId, statementsApiClient, ApiError, GetCountDocumentResultDto } from '@app/core/api';

export type GetDocumentsCountProps = { accounts?: { id: string; currencyCode: string }[] };

const getDocumentsCountFn = async ({ accounts }: GetDocumentsCountProps): Promise<GetCountDocumentResultDto> => {
  const response = await statementsApiClient.operationsControllerGetCountDocumentByStatus(getClientId(), {
    accounts,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get count");
  }

  return response.data;
};

export const useGetDocumentsCount = (): UseMutationResult<
  GetCountDocumentResultDto,
  ApiError,
  GetDocumentsCountProps
> => useMutation<GetCountDocumentResultDto, ApiError, GetDocumentsCountProps>(getDocumentsCountFn);

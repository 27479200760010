export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  label: {
    paddingRight: '32px',
    fontWeight: 400,
    color: 'colors.gray300',
  },
  optionsContainer: {
    minHeight: '32px',
  },
  optionButton: {
    width: '40px',
    height: '32px',
    color: 'colors.gray200',
    fontWeight: 600,
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: `1px solid`,
      borderColor: 'colors.gray50',
    },
    '&:hover': {
      color: 'colors.primary200',
    },
  },
  selected: {
    color: 'colors.primary600',
    fontWeight: 600,
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: `2px solid`,
      borderColor: 'colors.primary600',
    },
    '&:hover': {
      color: 'colors.primary600',
    },
  },
  optionText: {
    fontWeight: 600,
  },
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { isEmpty, isEqual } from 'lodash-es';
import { parse } from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { StatementsFilterField, StatementsFilterFormData } from '@app/core/components/Statements/types';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { useStyles } from '@app/pages/statements/mobile/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

interface StatementsFiltersProps {
  compact: boolean;
  defaultValues: DefaultValues<StatementsFilterFormData>;
  onFilterChange(filterData: StatementsFilterFormData): void;
  onCompactFilterClick(): void;
}

export const MobileStatementsFilters: React.FC<StatementsFiltersProps> = ({
  compact,
  defaultValues,
  onFilterChange,
  onCompactFilterClick,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allAccounts } = useAppSelector((state): ProfileState => state.profile);
  const [defaultSelectedAccountsId, setDefaultSelectedAccountsId] = useState<string[]>();
  const urlQueryRef = useRef<{ [key: string]: string }>({});
  const currentFilterValuesRef = useRef<StatementsFilterFormData>();

  const methods = useForm<StatementsFilterFormData>({ mode: 'onChange', defaultValues });
  const { getValues, formState, watch } = methods;

  useEffect(() => {
    if (allAccounts) {
      const { operationAccounts: operationAccountsId } = parse(location.search);
      if (operationAccountsId) {
        setDefaultSelectedAccountsId((operationAccountsId as string).split(','));
      }
    }
  }, [allAccounts]);

  const watchFields = watch();
  useEffect(() => {
    if (watchFields && formState.isDirty) {
      const { dateRange, operationAccounts, ...otherValues } = getValues();
      const fieldsArr = Object.entries(otherValues).filter(([key, value]) => {
        return !isEmpty(value);
      });
      const fieldsForUrl = Object.fromEntries(fieldsArr);
      const urlQueryObj: { [key: string]: any } = { ...fieldsForUrl };

      if (dateRange) {
        urlQueryObj['dateFrom'] = dateRange.from.toISOString();
        urlQueryObj['dateTo'] = dateRange.to.toISOString();
      }
      if (operationAccounts) {
        urlQueryObj['operationAccounts'] = operationAccounts.map((account) => account.account.id).toString();
      }
      if (!isEqual(urlQueryObj, urlQueryRef.current)) {
        urlQueryRef.current = urlQueryObj;
        setSearchParams(urlQueryObj, { replace: true });
      }
    }
  }, [formState.isDirty, getValues, setSearchParams, watchFields]);

  const { operationAccounts, dateRange } = getValues();

  const watchFilters = watch([StatementsFilterField.operationAccounts, StatementsFilterField.dateRange]);

  useEffect(() => {
    if (
      operationAccounts &&
      operationAccounts.length &&
      dateRange &&
      dateRange?.from &&
      dateRange?.to &&
      !isEqual(currentFilterValuesRef.current, getValues())
    ) {
      currentFilterValuesRef.current = getValues();
      onFilterChange(getValues());
    }
  }, [dateRange, getValues, onFilterChange, operationAccounts, watchFilters]);

  const handleSelectAccountsLength = (value: []) => value.length > 0 || t('statementsFilter_errorAmountField');

  const namesFieldWithError = Object.keys(formState.errors);

  const shortAccounts = operationAccounts?.length
    ? operationAccounts.length > 1
      ? `${t('mobileStatementsFilter_selectedAccountsNumber')} ${operationAccounts.length}`
      : operationAccounts[0].account.accountNumber
    : t('accountsSelect_select_placeholder');

  const styles = useStyles();

  return (
    <Box sx={styles.filterContainer}>
      <FormProvider {...methods}>
        <form role="presentation" style={styles.form}>
          <Box sx={{ ...styles.filter, ...(compact && styles.filterHide) }}>
            <Box mt={4} mb={4}>
              <AccountsWithSearchSelectField
                name={StatementsFilterField.operationAccounts}
                defaultSelectedAccountsId={defaultSelectedAccountsId}
                accounts={allAccounts}
                selectProps={{ sx: { '& .MuiSelect-select': styles.selectInput } }}
              />
            </Box>
            <Box>
              <DateRangeSelectField
                formSelectName="dateRangeSelect"
                formDateRangeName={StatementsFilterField.dateRange}
                selectProps={{ sx: { '& .MuiSelect-select': styles.selectInput } }}
              />
            </Box>
          </Box>
        </form>
      </FormProvider>

      {compact ? (
        <>
          <Grid container wrap="nowrap" direction="column" onClick={onCompactFilterClick} role="presentation">
            <Grid item>
              <Typography variant="caption" component="span" sx={styles.compactAccountLabel}>
                {t('account')}:{' '}
              </Typography>{' '}
              <Typography variant="body2" component="span" sx={styles.compactAccountValue}>
                {shortAccounts}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" component="span" sx={styles.compactDateRangeLabel}>
                {t('statementsFilter_dateRange')}:{' '}
              </Typography>{' '}
              <Typography variant="body2" component="span" sx={styles.compactDateRangeValue}>
                {dateRange?.from && dateRange?.to
                  ? `${formatISODateString(dateRange.from.toISOString(), DATE_FORMAT)} - ${formatISODateString(
                      dateRange.to.toISOString(),
                      DATE_FORMAT
                    )}`
                  : t('empty_date_filter')}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
};

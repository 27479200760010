import { CreateTemplateRequestDto, GetAccountResponseDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { BetweenMyAccountFormData } from '@app/pages/payment/components/BetweenMyAccountEditForm/types';

export const makeCreateTemplateData = (
  paymentFormData: BetweenMyAccountFormData
): Omit<CreateTemplateRequestDto, 'templateName'> => {
  const { amount, senderAccount, recipientAccount, paymentReference } = paymentFormData;

  const parsedSenderAccount: GetAccountResponseDto = JSON.parse(senderAccount);
  const parsedRecipientAccount: GetAccountResponseDto = JSON.parse(recipientAccount);
  return {
    senderAccountId: parsedSenderAccount.account.id,
    recipientAccountId: parsedRecipientAccount.account.id,
    amount: Number(amount),
    paymentReference,
    templateType: PaymentTemplateTypeEnum.Own,
    realSenderNonLeiFlag: false,
    realRecipientNonLeiFlag: false,
  };
};

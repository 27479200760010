export enum FormFieldErrorsName {
  templateName = 'templateName',
}

export enum FormFieldName {
  templateName = 'templateName',
  senderAccount = 'senderAccount',
  recipientName = 'recipientName',
  recipientLei = 'recipientLei',
  recipientPassport = 'recipientPassport',
  recipientNonLeiFlag = 'recipientNonLeiFlag',
  idPassport = 'idPassport',
  recipientAccount = 'recipientAccount',
  amount = 'amount',
  vat = 'vat',
  purpose = 'purpose',
  bankName = 'bankName',
  _vatSwitch = '_vatSwitch',
}

export type TemplateFormData = {
  paymentDocumentNumber: string;
  templateName: string;
  documentDate: Date;
  documentValuationDate?: Date;
  senderAccount: string;
  recipientName: string;
  [FormFieldName.recipientLei]?: string;
  recipientAccount: string;
  nonResidentFlag: boolean;
  amount: string;
  vat: number;
  purpose: string;
  countryCode: string;
  [FormFieldName.recipientPassport]?: string;
  [FormFieldName.recipientNonLeiFlag]: boolean;
  idPassport: string;
  _documentValuationDate: string;
  _vatSwitch: boolean;
  _tempDocumentValuationDate: string;
};

export type TemplateDefaultValues = {
  senderAccountId?: string;
  templateName?: string;
  recipientAccountName?: string;
  recipientAccountNumber?: string;
  [FormFieldName.recipientLei]?: string;
  [FormFieldName.recipientPassport]?: string;
  amount?: number;
  paymentReference?: string;
  recipientBankName?: string;
  [FormFieldName.recipientNonLeiFlag]: boolean;
};

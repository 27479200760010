import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  AccountDto,
  ApiError,
  ApiErrorCodes,
  CreateTemplateRequestDto,
  CurrencyEnum,
  DocumentTemplateDto,
  GetAccountResponseDto,
  PaymentTemplateTypeEnum,
} from '@app/core/api';
import { HorizontalMenu, MenuItem } from '@app/core/components';
import { BetweenMyAccountTemplateForm } from '@app/pages/paymentsTemplates/components/BetweenMyAccountTemplateForm';
import { LocalPaymentTemplateForm } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm';
import {
  FormFieldErrorsName,
  FormFieldName,
  TemplateFormData,
} from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';
import { savePaymentTemplateFn } from '@app/pages/paymentsTemplates/create/query';
import { CreatePaymentTemplateTab } from '@app/pages/paymentsTemplates/create/types';

interface PageContentProps {
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const PageContent: React.FC<PageContentProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [errorFields, setErrorFields] = useState<
    Array<{ fieldName: FormFieldErrorsName; errorMessage: string }> | undefined
  >(undefined);
  const [activeTab, setActiveTab] = useState<CreatePaymentTemplateTab>(CreatePaymentTemplateTab.localPayment);
  const newPaymentTabsItems: MenuItem<CreatePaymentTemplateTab>[] = [
    {
      id: 1,
      labelText: t('paymentCreationPaymentTab_localPaymentLabel'),
      value: CreatePaymentTemplateTab.localPayment,
    },
    {
      id: 2,
      labelText: t('paymentCreationPaymentTab_betweenMyAccountLabel'),
      value: CreatePaymentTemplateTab.betweenOwnAccounts,
    },
  ];
  const {
    mutate: saveTemplateMutate,
    status: saveTemplateStatus,
    error,
  } = useMutation<DocumentTemplateDto, ApiError, CreateTemplateRequestDto>(savePaymentTemplateFn);
  const saveTemplate = (templateData: TemplateFormData, templateType: PaymentTemplateTypeEnum) => {
    const {
      templateName,
      senderAccount,
      amount,
      recipientName,
      recipientAccount,
      recipientLei,
      nonResidentFlag,
      purpose: paymentReference,
      countryCode,
      vat,
      recipientNonLeiFlag,
    } = templateData;

    if (templateType === PaymentTemplateTypeEnum.General) {
      const parsedSenderAccount: AccountDto = JSON.parse(senderAccount);
      saveTemplateMutate({
        senderAccountId: parsedSenderAccount.id,
        senderAccount: parsedSenderAccount.accountNumber,
        templateName,
        amount: Number(amount),
        recipientName,
        recipientLei,
        recipientAccount,
        nonResidentFlag: nonResidentFlag ? nonResidentFlag : false,
        currency: CurrencyEnum.Uah,
        paymentReference,
        countryCode,
        vat,
        recipientNonLeiFlag,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
        templateType,
      });
    }
    if (templateType === PaymentTemplateTypeEnum.Own) {
      const parsedSenderAccount: GetAccountResponseDto = JSON.parse(senderAccount);
      const parsedRecipientAccount: GetAccountResponseDto = JSON.parse(recipientAccount);
      saveTemplateMutate({
        senderAccountId: parsedSenderAccount.account.id,
        senderAccount: parsedSenderAccount.account.accountNumber,
        templateName,
        amount: Number(amount),
        recipientAccount: parsedRecipientAccount.account.accountNumber,
        recipientAccountId: parsedRecipientAccount.account.id,
        currency: CurrencyEnum.Uah,
        paymentReference,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
        templateType,
      });
    }
  };
  useEffect(() => {
    showLoader(saveTemplateStatus === 'loading');
    if (saveTemplateStatus === 'success') {
      navigate(-1);
      notify({
        notifyProps: {
          title: t('template_save_success_title'),
          message: t('template_save_success_message'),
          severity: saveTemplateStatus,
        },
      });
    }
    if (saveTemplateStatus === 'error') {
      if (error?.code === ApiErrorCodes.DUPLICATE_TEMPLATE_NAME) {
        setErrorFields([
          { fieldName: FormFieldErrorsName.templateName, errorMessage: t('saveTemplateDuplicateNameErrorMessage') },
        ]);
      }
      notify({
        notifyProps: {
          title: t('template_save_error_title'),
          message: t('template_save_error_message'),
          severity: saveTemplateStatus,
        },
      });
    }
  }, [saveTemplateStatus, t, notify, navigate, showLoader, error?.code]);

  const onCancelHandler = () => {
    navigate(-1);
  };

  const handleChange = (currentTab: CreatePaymentTemplateTab): void => {
    setActiveTab(currentTab);
  };

  return (
    <Grid container>
      <Box pl={3}>
        <HorizontalMenu<CreatePaymentTemplateTab>
          onChange={handleChange}
          menuItems={newPaymentTabsItems}
          styles={{
            root: {
              '&.div': {
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'initial',
              },
            },
          }}
        />
      </Box>
      <Grid item xs={9}>
        {activeTab === CreatePaymentTemplateTab.localPayment ? (
          <LocalPaymentTemplateForm
            defaultValues={{
              [FormFieldName.recipientNonLeiFlag]: false,
            }}
            onCancelHandler={onCancelHandler}
            onSaveClick={saveTemplate}
            onChange={onChange}
            errorFields={errorFields}
          />
        ) : null}
        {activeTab === CreatePaymentTemplateTab.betweenOwnAccounts ? (
          <BetweenMyAccountTemplateForm
            onCancelHandler={onCancelHandler}
            onSaveClick={saveTemplate}
            onChange={onChange}
            errorFields={errorFields}
            defaultValues={{
              [FormFieldName.recipientNonLeiFlag]: false,
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

import { useMutation, UseMutationResult } from 'react-query';

import { accountsApiClient, ApiError, CurrencyEnum, GetAccountResponseDto, getClientId } from '@app/core/api';

export const useGetAccountsForEnrollment = (): UseMutationResult<
  GetAccountResponseDto[],
  ApiError,
  { currency: CurrencyEnum; excludeAccount: string }
> =>
  useMutation(async ({ currency, excludeAccount }: { currency: CurrencyEnum; excludeAccount: string }) => {
    const response = await accountsApiClient.accountsControllerGetAccountsForEnrollment(
      getClientId(),
      currency,
      excludeAccount
    );
    if (response.status !== 200) {
      throw new ApiError(response.request.status, "Can't get AccountsForEnrollment");
    }
    return response.data;
  });

import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { ColumnDef, CellContext } from '@tanstack/react-table';

import { EditIcon, DeleteIcon } from '@app/common/icons';
import { DocumentTemplateDto, TransactionBaseAccountDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { formatAmount } from '@app/core/utils';
import { TemplatesTableColumns } from '@app/pages/paymentsTemplates/PaymentsTemplates/types';

interface MakeTableColumnsProps {
  enableEdit: boolean;
  enableDelete: boolean;
  onEditButtonClick(uuid: string | undefined, templateName: string | undefined): void;
  onDeleteButtonClick(uuid: string | undefined): void;
  t(text: string): string;
}

export const makeTableColumns = ({
  enableEdit,
  enableDelete,
  onEditButtonClick,
  onDeleteButtonClick,
  t,
}: MakeTableColumnsProps): ColumnDef<DocumentTemplateDto, any>[] => {
  return [
    {
      accessorKey: TemplatesTableColumns.templateName,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t(`payments_templ_${TemplatesTableColumns.templateName}`)}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<DocumentTemplateDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: TemplatesTableColumns.senderAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`payments_templ_${TemplatesTableColumns.senderAccount}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DocumentTemplateDto, TransactionBaseAccountDto>) => {
        return <Ellipsis direction="left" text={getValue().accountNumber} />;
      },
    },
    {
      id: TemplatesTableColumns.recipientName,
      accessorKey: TemplatesTableColumns.recipientAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`payments_templ_${TemplatesTableColumns.recipientAccount}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DocumentTemplateDto, TransactionBaseAccountDto>) => {
        return <Ellipsis text={getValue().name} />;
      },
    },
    {
      accessorKey: TemplatesTableColumns.paymentReference,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`payments_templ_${TemplatesTableColumns.paymentReference}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DocumentTemplateDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: TemplatesTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`payments_templ_${TemplatesTableColumns.amount}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ row }: CellContext<DocumentTemplateDto, number>) => {
        const { amount, currency } = row.original;
        return (
          <Typography variant="body2" component="p">
            {formatAmount(amount)}
            <span>{` ${currency}`}</span>
          </Typography>
        );
      },
    },
    {
      id: 'actions',
      header: () => <></>,
      cell: ({ table, row }) => {
        const { uuid, templateName } = row.original;
        return (
          <>
            {enableEdit ? (
              <IconButton
                size="small"
                onClick={() => {
                  onEditButtonClick(uuid, templateName);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            ) : null}
            {enableDelete ? (
              <IconButton
                size="small"
                onClick={() => {
                  onDeleteButtonClick(uuid);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
  ];
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Toolbar, Typography, Box } from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  VisibilityState,
  ColumnOrderState,
  SortingState,
  ColumnSort,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import {
  GetCountDocumentByStatusDto,
  InternalSwiftTotalAmountDto,
  LoanSortingFieldsEnum,
  SwiftOperationWithAvailableButtons,
  SWIFTStatusEnum,
} from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { getColumnVisibility, getColumOrder } from '@app/core/components/ComplexTable/helpers';
import { Loader } from '@app/core/components/Loader';
import {
  DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_ROWS_PER_PAGE,
} from '@app/core/constants';
import {
  TableColumnConfig,
  CurrencyEnum,
  PaginationChangeType,
  SortingRule,
  TablePaginationType,
} from '@app/core/types';
import { FilterPanel } from '@app/pages/swift/components/SwiftTable/components/FilterPanel';
import { OperationDetails } from '@app/pages/swift/components/SwiftTable/components/OperationDetails';
import { makeTableColumns } from '@app/pages/swift/components/SwiftTable/components/TableContent/columns';
import { buildTableRowsData } from '@app/pages/swift/components/SwiftTable/components/TableContent/helpers';
import { styles } from '@app/pages/swift/components/SwiftTable/components/TableContent/styles';
import { TableToolbar } from '@app/pages/swift/components/SwiftTable/components/TableToolbar';

const ExpandedRowContent: React.FC<{ data: SwiftOperationWithAvailableButtons }> = ({ data }) => (
  <OperationDetails operation={data} />
);

interface TableContentProps {
  swiftData: SwiftOperationWithAvailableButtons[];
  columnsConfig: TableColumnConfig[];
  currency?: CurrencyEnum;
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  onSettingsButtonClick(): void;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onDeleteDocumentSuccess(): void;
  onSignDocumentsSuccess(): void;
  totalAmounts?: InternalSwiftTotalAmountDto[];
  onFilterChange(filter: SWIFTStatusEnum): void;
  countByStatus?: GetCountDocumentByStatusDto;
}

export const TableContent: React.FC<TableContentProps> = ({
  swiftData,
  columnsConfig,
  currency,
  pagination,
  loading,
  queryKey,
  onSettingsButtonClick,
  onSort,
  onPaginationChange,
  onDeleteDocumentSuccess,
  totalAmounts,
  onSignDocumentsSuccess,
  onFilterChange,
  countByStatus,
}) => {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getColumnVisibility(columnsConfig));
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(getColumOrder(columnsConfig));
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return buildTableRowsData(swiftData);
  }, [swiftData]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        onSearchButtonClick(): void {},
        onSettingsButtonClick,
        t,
      }),
    ];
  }, [onSettingsButtonClick, t]);

  const table = useReactTable<SwiftOperationWithAvailableButtons>({
    columns,
    data,
    state: {
      sorting,
      columnOrder,
      columnVisibility,
    },
    enableSorting: swiftData.length > 0,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (swiftData.length && table.getIsSomeRowsSelected()) {
      table.resetRowSelection();
    }
  }, [swiftData, table]);

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as LoanSortingFieldsEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  useEffect(() => {
    setColumnVisibility(getColumnVisibility(columnsConfig));
    setColumnOrder(getColumOrder(columnsConfig));
  }, [columnsConfig]);

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row) => {
      return (
        <TableRowContent<SwiftOperationWithAvailableButtons>
          key={row.id}
          row={row}
          styles={styles}
          ExpandedComponent={ExpandedRowContent}
        />
      );
    });
  };

  const selectedOperation: SwiftOperationWithAvailableButtons[] = table
    .getSelectedRowModel()
    .flatRows.map((operationRow) => operationRow.original);
  const operationsCount = pagination?.totalCount;
  const showPagination = Boolean(pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION);

  return (
    <>
      <Toolbar disableGutters sx={styles.toolBar}>
        <Grid item sx={styles.horizontalScroll}>
          <FilterPanel onFilterChange={onFilterChange} countByStatus={countByStatus} />
        </Grid>
      </Toolbar>
      <TableToolbar
        selectedOperation={selectedOperation}
        operationsCount={operationsCount}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        totalAmounts={totalAmounts}
        onSignDocumentSuccess={onSignDocumentsSuccess}
      />
      <ComplexTable
        head={{
          content: <TableHeadContent<SwiftOperationWithAvailableButtons> {...{ table, styles }} />,
        }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={DEFAULT_ROWS_PER_PAGE}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

import { useMutation, useQuery } from 'react-query';
import { UseMutationResult, UseQueryResult } from 'react-query/types/react/types';

import {
  getClientId,
  overdraftsApiClient,
  ApiError,
  CollateralListResponseDto,
  GetOneOverdraftResponseDto,
  GetOverdraftRepaymentTemplateResponseDto,
  OverdraftRepaymentTypeEnum,
  PaginationRequestDto,
} from '@app/core/api';

const getOneOverdrafts = async (uuid: string): Promise<GetOneOverdraftResponseDto> => {
  const result = await overdraftsApiClient.overdraftControllerGetOne(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get overdraft!');
  }
  return result.data;
};

export const useGetOverdraftByUuid = (uuid: string): UseQueryResult<GetOneOverdraftResponseDto, ApiError> => {
  return useQuery<GetOneOverdraftResponseDto, ApiError>('overdraft_getOneDocument', () => getOneOverdrafts(uuid), {
    enabled: false,
  });
};

const getRepaymentTemplateFn = async ({
  uuid,
  repaymentType,
}: {
  uuid: string;
  repaymentType: OverdraftRepaymentTypeEnum;
}): Promise<GetOverdraftRepaymentTemplateResponseDto> => {
  const result = await overdraftsApiClient.overdraftControllerGetRepaymentTemplate(uuid, getClientId(), repaymentType);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get GetRepaymentTemplate!');
  }
  return result.data;
};

export const useOverdraftGetRepaymentTemplate = (): UseMutationResult<
  GetOverdraftRepaymentTemplateResponseDto,
  ApiError,
  { uuid: string; repaymentType: OverdraftRepaymentTypeEnum }
> => {
  return useMutation<
    GetOverdraftRepaymentTemplateResponseDto,
    ApiError,
    { uuid: string; repaymentType: OverdraftRepaymentTypeEnum }
  >(getRepaymentTemplateFn);
};

export const getCollateral = async (
  uuid: string,
  pagination: PaginationRequestDto
): Promise<CollateralListResponseDto> => {
  const result = await overdraftsApiClient.overdraftControllerGetLoanLineCollateral(getClientId(), {
    pagination,
    uuid,
  });

  return result.data;
};

import React, { useEffect, useRef } from 'react';

import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { MultipleDateSelect } from '@app/core/components/DateSelect';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetUserHistoryOfVisit } from '@app/core/hooks';
import { DateRange, DateRangeFromTo, PaginationChangeType } from '@app/core/types';
import { VisitsTable } from '@app/pages/profile/desktop/components/HistoryOfVisits/components/VisitsTable';

export const HistoryOfVisits: React.FC = () => {
  const methods = useForm<DateRangeFromTo>({ mode: 'onChange' });
  const dateFilterRef = useRef<DateRange | undefined>(undefined);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });

  const { data, mutate, isLoading } = useGetUserHistoryOfVisit();

  useEffect(() => {
    mutate({
      pagination: paginationRef.current,
      dateRange: dateFilterRef.current
        ? {
            startDate: dateFilterRef.current.startDate.toISOString(),
            endDate: dateFilterRef.current.endDate.toISOString(),
          }
        : undefined,
    });
  }, [mutate]);

  const onPaginationChangeHandler = (pagination: PaginationChangeType) => {
    paginationRef.current = pagination;
    mutate({
      pagination: paginationRef.current,
      dateRange: dateFilterRef.current
        ? {
            startDate: dateFilterRef.current.startDate.toISOString(),
            endDate: dateFilterRef.current.endDate.toISOString(),
          }
        : undefined,
    });
  };

  const periodOnChange = (dateRange: DateRange | undefined) => {
    dateFilterRef.current = dateRange;
    mutate({
      pagination: paginationRef.current,
      dateRange: dateFilterRef.current
        ? {
            startDate: dateFilterRef.current.startDate.toISOString(),
            endDate: dateFilterRef.current.endDate.toISOString(),
          }
        : undefined,
    });
  };

  const visits = data?.sessions || [];
  const pagination = data?.pagination;

  return (
    <FormProvider {...methods}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          height: '100%',
        }}
      >
        <Grid container justifyContent="flex-end">
          <MultipleDateSelect onChange={periodOnChange} />
        </Grid>
        <Box
          pt={3}
          sx={{
            height: '100%',
          }}
        >
          <VisitsTable
            visitsData={visits}
            pagination={pagination}
            onPaginationChange={onPaginationChangeHandler}
            loading={Boolean(isLoading)}
          />
        </Box>
      </Grid>
    </FormProvider>
  );
};

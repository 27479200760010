import { GetListRequestDtoProductTypeEnum } from '@app/core/api';

export type DepositsFilterType = {
  startDateFrom?: Date;
  startDateTo?: Date;
  endDateFrom?: Date;
  endDateTo?: Date;
  agreementNumber?: string;
  currency?: string;
  amountFrom?: string;
  amountTo?: string;
  depositType?: GetListRequestDtoProductTypeEnum;
  interestRateFrom?: string;
  interestRateTo?: string;
  temp_startDateFrom?: string;
  temp_startDateTo?: string;
  temp_endDateFrom?: string;
  temp_endDateTo?: string;
};

export const resetFormData = {};

export enum DepositsTypeEnum {
  open = 'open',
  closed = 'closed',
}

export enum DepositsTypeSelect {
  deposit = 'deposit',
  depositLine = 'depositLine',
}

export enum DepositsFilterField {
  startDateFrom = 'startDateFrom',
  startDateTo = 'startDateTo',
  endDateFrom = 'endDateFrom',
  endDateTo = 'endDateTo',
  agreementNumber = 'agreementNumber',
  currency = 'currency',
  amountFrom = 'amountFrom',
  amountTo = 'amountTo',
  depositType = 'depositType',
  interestRateFrom = 'interestRateFrom',
  interestRateTo = 'interestRateTo',
  rowsPerPage = 'rowsPerPage',
  page = 'page',
  sortBy = 'sortBy',
}

export enum DepositsTableColumns {
  accountDepositOutstanding = 'accountDepositOutstanding',
  agreementNumber = 'agreementNumber',
  depositType = 'depositType',
  accountInterestAccrued = 'accountInterestAccrued',
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  amount = 'amount',
  currency = 'currency',
  interestRate = 'interestRate',
  uuid = 'uuid',
  productType = 'productType',
  term = 'term',
}

export enum TranchesTableColumns {
  agreementNumber = 'agreementNumber',
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  interestRate = 'interestRate',
  currency = 'currency',
  amount = 'amount',
  state = 'state',
  uuid = 'uuid',
}

export enum DepositOperationsTableColumns {
  date = 'date',
  type = 'type',
  amount = 'amount',
  account = 'account',
  currency = 'currency',
  paymentReference = 'paymentReference',
  id = 'id',
  status = 'status',
  documentType = 'documentType',
  documentNumber = 'documentNumber',
  documentName = 'documentName',
  valueDate = 'valueDate',
  countryCode = 'countryCode',
  signed = 'signed',
  details = 'details',
}

export enum DepositMenuItems {
  operations = 'operations',
  tranches = 'tranches',
}

import React from 'react';

import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CurrencyExchangeContent } from '@app/pages/exchange/currencyExchangeTransactions/CurrencyExchangeContent';
import { styles } from '@app/pages/exchange/currencyExchangeTransactions/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const CurrencyExchangeTransactionsPage: React.FC = () => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Grid container direction="column">
        <PageHeader title={t('currencyExchange_pageTitle')} tooltip={t('tooltip_currency-operations')} useDesktopStyle>
          <Grid item>
            {permissions.currencyExchange.create ? (
              <Link to={RouteList.exchange_create}>
                <Button variant="contained" color="primary">
                  {t('currencyExchange_createButtonText')}
                </Button>
              </Link>
            ) : null}
          </Grid>
        </PageHeader>
        <PageContainer>
          <Grid container direction="column" wrap="nowrap" sx={styles.horizontalWrapper}>
            <CurrencyExchangeContent />
          </Grid>
        </PageContainer>
      </Grid>
    </PageWrapper>
  );
};

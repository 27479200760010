import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getAccessToken, getClientId, profileApiClient, ApiError, GetClientProfileResponseDto } from '@app/core/api';

const getCompanyDataFn = async (): Promise<GetClientProfileResponseDto> => {
  const response = await profileApiClient.profileControllerGetClientProfile(getClientId(), getAccessToken());
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Cant get company data');
  }
  return response.data;
};

export const useGetCompanyData = (): UseMutationResult<GetClientProfileResponseDto, ApiError, any> => {
  return useMutation<GetClientProfileResponseDto, ApiError, any>(getCompanyDataFn);
};

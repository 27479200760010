export enum RouteList {
  main = '/',
  accounts = '/accounts',
  dashboard = '/dashboard', // Рахунки та Операцїї, accounts and deposits ???
  account_page = '/accounts/:id',
  statements = '/statements', // выписки
  operations = '/operations', // гривневые операции
  fxOperations = '/fx_operations', // валютные операции
  loans = '/loans', // кредит
  loans_page = '/loans/:uuid',
  loan_lines = '/loan-lines', // кредитные линии
  loan_lines_page = '/loan-lines/:uuid', // страница кредитные линии
  loan_swift_create = '/loan/swift-create', // оплата процентов по шаблону
  loan_swift_edit = '/loan/swift-edit', // редактирование оплаты процентов по шаблону
  overdrafts = '/overdrafts/', // овердрафты
  overdraft_page = '/overdrafts/:uuid', // просмотр овердрафта
  deposits = '/deposits', // Депозиты
  deposits_show_deposit_page = '/deposits/show/deposit/:uuid', // Стрнаица депозита
  deposits_show_deposit_line_page = '/deposits/show/deposit-line/:uuid', // Страница депозитной линии
  deposits_show_deposit_tranche_page = '/deposits/show/deposit-tranche/:uuid', // Страница транша линии
  letters = '/letters', // файлы и письма
  letters_create = '/letters/create',
  letters_edit = '/letters/edit/:uuid',
  letters_show = '/letters/show/:uuid',
  profile = '/profile', // мои данные
  companyProfile = '/company-profile', // Данные о компании
  template_creation = '/template_creation', // создание шаблона
  template_edit = '/template_edit/:uuid', // создание шаблона
  payment = '/payment', // root для локальных платежей
  payment_create = '/payment/create', // создание платежа
  payment_edit = '/payment/edit/:uuid', // редактирование платежа
  payment_own_edit = '/payment/edit/own/:uuid', // редактирование власного платежа
  payment_view = '/payment/view/:uuid', // просмотр платежа
  payment_own_view = '/payment/view/own/:uuid', // просмотр власного платежа
  payment_import = '/payment/import', // импорт платежей
  directories = '/directories', // Справочники
  directories_payments_templates = '/directories/payments_templates', // Шаблони переказів
  directories_counterparties = '/directories/counterparties', // Контрагенти страница
  directories_counterparties_swift = '/directories/counterparties-swift', // SWIFT контрагенти страница
  directories_banks_internal = '/directories/banks-internal', // Банки Украины
  directories_banks_swift = '/directories/banks-swift', // Банки кореспонденты SWIFT
  directories_codes_countries = '/directories/codes-countries', // Коди країн
  exchange_rate = '/exchange_rate', // курс валют
  swift = '/swift', // swift платежи
  swift_create = '/swift/create', // создание swift платежа
  swift_edit = '/swift/edit/:uuid', // редактирование swift платежа
  swift_show = '/swift/show/:uuid', // просмотр swift платежа
  exchange = '/exchange', // список транзакций обмена валют
  exchange_create = '/exchange/create', // страница создания заявок
  exchange_edit = '/exchange/edit/:uuid', // страница редактирования заявок
  exchange_show = '/exchange/show/:uuid', // страница просмотра заявок
  documents = '/documents', // страница документов
  documents_show = '/documents/show/:uuid', // страница просмотра документа
  documents_edit = '/documents/edit/:uuid', // страница редактирования документа
}

import React from 'react';

import { Grid } from '@mui/material';
import MuiTableFooter, { TableFooterProps } from '@mui/material/TableFooter';

export const TableFooter: React.FC<TableFooterProps<typeof Grid>> = ({ children, ...otherProps }) => {
  return (
    <MuiTableFooter component={Grid} container {...otherProps}>
      {children}
    </MuiTableFooter>
  );
};

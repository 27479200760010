export const styles = {
  verticalWrapper: {
    height: '100%',
  },
  dateText: {
    color: 'colors.gray300',
  },
  horizontalWrapper: {
    width: '100%',
  },
  mobilePageWrapper: {
    backgroundColor: 'initial',
    borderRadius: 0,
    height: '100vh',
    padding: 0,
  },
  rejectContainer: {
    height: '44px',
    backgroundColor: 'colors.error',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  rejectReasonIcon: {
    color: '#FFF',
    marginLeft: '16px',
    marginRight: '16px',
  },
  rejectReasonText: {
    color: '#FFF',
  },
  toggleButton: {
    fontSize: '0.75rem',
    '& svg': {
      marginLeft: '4px',
      fill: 'colors.primary500',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  captionFilter: {
    color: 'colors.primary600',
    fontWeight: 'bold',
    verticalAlign: 'bottom',
    marginLeft: '8px',
  },
};

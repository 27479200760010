import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { DocumentHistory } from '@app/pages/documents/components/DocumentHistory';
import { EditCurrencyDocumentView } from '@app/pages/documents/edit/View';
import { useGetCurrencyDocumentByUuid } from '@app/pages/documents/show/query';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

export const EditCurrencyDocumentPage: React.FC = () => {
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const showLeavePopupRef = useRef<boolean>(false);
  const { uuid = 'no-uuid' } = useParams();
  const { data: currencyDocument, refetch: refetchCurrencyDocument } = useGetCurrencyDocumentByUuid(uuid);

  useEffect(() => {
    refetchCurrencyDocument();
  }, [refetchCurrencyDocument]);

  const updateHandler = () => {
    refetchCurrencyDocument();
  };

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  return (
    <PageWrapper>
      <PageHeader
        title={currencyDocument?.originalName || ''}
        showNavigateButton
        backLink={-1}
        showLeavePopup={showLeavePopup}
        confirmQuestionText={t('confirmLeaveExchangeDocumentEditPopupMessage')}
      />
      <PageContainer>
        <Grid container>
          {currencyDocument && userProfile ? (
            <>
              <Grid item xs={9}>
                <EditCurrencyDocumentView
                  document={currencyDocument}
                  update={updateHandler}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid item xs={3}>
                <Box pl={10}>
                  {currencyDocument ? (
                    <DocumentHistory document={currencyDocument} userProfile={userProfile} edit />
                  ) : null}
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </PageContainer>
    </PageWrapper>
  );
};

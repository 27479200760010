import React from 'react';

import { InputAdornment, TextField, Typography } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import { Rifm } from 'rifm';

import { StatusText } from '../../../StatusText';

export enum FormatType {
  amount = 'amount',
  number = 'number',
  percent = 'percent',
  currencyAmount = 'currencyAmount',
}

export interface FormatInputProps {
  name: string;
  placeholder?: string;
  type?: FormatType;
  error?: string;
  maxLength?: number;
  defaultValue?: string;
  format?(str: string): string;
  currency?: string;
}

export type FormatInputFieldProps = FormatInputProps & TextFieldProps;

export const FormatInputField: React.FC<FormatInputFieldProps> = ({
  name,
  placeholder,
  maxLength,
  format,
  type,
  defaultValue,
  currency,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const formatFloatingPointNumber = (value: string, maxDigits: number) => {
    const [head, tail] = value.split('.');

    const scaledTail = tail != null ? tail.slice(0, maxDigits) : '';

    const parsedValue = Number.parseFloat(`${head}.${scaledTail}`);

    if (Number.isNaN(parsedValue)) {
      return '';
    }

    const formatted = parsedValue.toString();
    if (value.includes('.')) {
      const [formattedHead] = formatted.split('.');
      const formattedTail =
        scaledTail !== '' && scaledTail[maxDigits - 1] === '0' ? scaledTail.slice(0, -1) : scaledTail;

      return `${formattedHead}.${formattedTail}`;
    }
    return formatted;
  };
  const formatFn = (v: string) => {
    let formatted = v;
    switch (type) {
      case FormatType.amount:
        formatted = formatFloatingPointNumber((v.match(/\d*[.,]?\d{0,2}/) || []).join(''), 2);
        break;
      case FormatType.currencyAmount:
        formatted = formatFloatingPointNumber((v.match(/\d*[.,]?\d{0,2}/) || []).join(''), 2);
        break;
      case FormatType.number:
        formatted = (maxLength ? v.slice(0, maxLength).match(/\d+?\d*/) || [] : v.match(/\d+?\d*/) || []).join('');
        break;
      default:
        return format ? format(v) : formatted;
    }
    return formatted;
  };

  const getEndAdornment = () => {
    switch (type) {
      case FormatType.amount:
        return <InputAdornment position="start">UAH</InputAdornment>;
      case FormatType.currencyAmount:
        return <InputAdornment position="start">{currency}</InputAdornment>;
      case FormatType.percent:
        return <InputAdornment position="start">%</InputAdornment>;
      default:
        return undefined;
    }
  };

  const getLabel = () => {
    switch (type) {
      case FormatType.amount:
        return <Typography variant="body2">{placeholder}</Typography>;
      default:
        return null;
    }
  };

  const getPlaceholder = () => {
    switch (type) {
      case FormatType.amount:
        return '0.00';
      case FormatType.percent:
        return '00.00';
      default:
        return placeholder;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange: onChangeController }, fieldState: { error } }) => (
        <>
          <Rifm
            value={value}
            onChange={onChangeController}
            mask
            format={(v) => formatFn(v)}
            replace={(v) => v.replace(',', '')}
          >
            {({ value: newRifmValue, onChange }) => (
              <TextField
                error={Boolean(error)}
                label={getLabel()}
                placeholder={getPlaceholder()}
                variant="outlined"
                InputProps={{
                  endAdornment: getEndAdornment(),
                }}
                InputLabelProps={{
                  sx: {
                    '&.MuiInputLabel-outlined': {
                      paddingTop: '10px',
                      paddingLeft: '12px',
                      paddingBottom: '10px',
                      transform: 'translate(0px, 0px) scale(1)',
                      transition: 'none',
                      color: 'colors.gray300',
                    },
                    '&.MuiInputLabel-shrink': {
                      display: 'none',
                    },
                  },
                }}
                fullWidth
                value={newRifmValue}
                onChange={onChange}
                {...otherProps}
              />
            )}
          </Rifm>
          {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
        </>
      )}
    />
  );
};

import React from 'react';

import { IconButton } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

import { SettingIcon } from '@app/common/icons';

export const SettingsButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      aria-label="setting"
      size="small"
      sx={{
        color: 'colors.gray300',
        '&:hover': {
          color: 'colors.primary500',
        },
      }}
      {...props}
    >
      <SettingIcon />
    </IconButton>
  );
};

export const styles = {
  form: {
    width: '100%',
  },
  tabBody: {
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
    minWidth: '600px',
  },
  tabBodyMobile: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    padding: '20px',
  },
  header: {
    fontSize: '1rem',
    color: 'colors.primary700',
    fontWeight: 900,
    marginRight: '4px',
  },
  paymentNumber: {
    height: '50px',
  },
  bookmarkBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  info: {
    color: 'colors.primary500',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  correspondentBankCheck: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  selectPlaceholder: {
    color: 'colors.gray300',
    fontWeight: 400,
  },
};

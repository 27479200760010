import { object, SchemaOf, string } from 'yup';

import { MessageFormFieldName } from './types';

type SchemaValidationObject = {
  [MessageFormFieldName.categoryId]: string;
  [MessageFormFieldName.subject]: string;
  [MessageFormFieldName.message]: string;
};

type MessageValidationSchemaProps = {
  t: (key: string) => string;
};

export const validationSchema = ({ t }: MessageValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [MessageFormFieldName.categoryId]: string().required(t('paymentCreationLocalPayment_field_required')),
    [MessageFormFieldName.subject]: string().required(t('paymentCreationLocalPayment_field_required')),
    [MessageFormFieldName.message]: string().required(t('paymentCreationLocalPayment_field_required')),
  });
};

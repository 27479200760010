import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PaperIcon, SuccessSmallIcon, WaitingSmallIcon } from '@app/common/icons';
import { MessageStatus } from '@app/core/api';

import { styles } from './style';

type MessageStatusTitleProps = {
  status?: MessageStatus;
};

export const MessageStatusTitle: React.FC<MessageStatusTitleProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="body1" sx={styles.title}>
        {t('paymentInfo_currentStatus_title')}
      </Typography>
      <Box mt={2} mb={2}>
        <Grid container alignItems="center">
          {status ? (
            status === MessageStatus.Delivered ? (
              <SuccessSmallIcon fontSize="small" />
            ) : (
              <WaitingSmallIcon fontSize="small" />
            )
          ) : (
            <PaperIcon fontSize="small" sx={styles.statusIcon} />
          )}
          <Box ml={2}>
            <Typography variant="body2" component="span" sx={styles.value}>
              {status ? t(status) : t('new_letter')}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  ApiError,
  dashboardApiClient,
  FileLinkResponseDto,
  getClientId,
  OperationTypeEnum,
  OutputFormatEnum,
  StatementTemplateTypeEnum,
} from '@app/core/api';
import { StatementsFormData } from '@app/pages/dashboard/view/PageContent/Statements/types';

const getStatementsReportQueryFn = async (formData: StatementsFormData): Promise<FileLinkResponseDto> => {
  const { operationAccounts, dateRange, fileType, transactionsPresented, queryText } = formData;
  const operationType = formData.operationType?.toString() === '' ? undefined : formData.operationType;

  const response = await dashboardApiClient.dashboardControllerStatementsFileReport(getClientId(), {
    allowEmptyFile: !transactionsPresented,
    operationAccounts: operationAccounts.map((account) => ({
      id: account.account.id,
      currencyCode: account.account.currency,
    })),
    queryText,
    dateRange: {
      startDate: dateRange.from.toISOString(),
      endDate: dateRange.to.toISOString(),
    },
    operationType: operationType as unknown as OperationTypeEnum,
    templateType: StatementTemplateTypeEnum.Default,
    outputType: fileType as unknown as OutputFormatEnum,
  });

  return response.data;
};

export const useGetStatementsReport = (): UseMutationResult<FileLinkResponseDto, ApiError, StatementsFormData> => {
  return useMutation<FileLinkResponseDto, ApiError, StatementsFormData>(getStatementsReportQueryFn);
};

export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_startDate: {
    maxWidth: '130px',
    minWidth: '130px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_endDate: {
    maxWidth: '130px',
    minWidth: '130px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_depositAgreementNumber: {
    maxWidth: '228px',
    '&.body': {
      textAlign: 'left',
      fontWeight: 600,
    },
  },
  cell_interestRate: {
    maxWidth: '228px',
    '&.body': {
      textAlign: 'left',
      fontWeight: 600,
    },
  },
  cell_currency: {
    justifyContent: 'center',
    minWidth: '40px',
    maxWidth: '70px',
    paddingRight: '20px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_amount: {
    textAlign: 'end',
    minWidth: '140px',
    maxWidth: '140px',
    '&.body': {
      whiteSpace: 'nowrap',
      textAlign: 'end',
      fontWeight: 600,
      justifyContent: 'flex-end',
      color: 'colors.primary700',
      paddingRight: '24px',
    },
  },
  cell_state: {
    maxWidth: '80px',
    textAlign: 'end',
    '&.body': {
      direction: 'rtl',
      textAlign: 'left',
    },
  },
  cell_actions: {
    width: '50px',
    flexShrink: 0,
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  linkArrow: {
    color: 'colors.primary600',
    verticalAlign: 'top',
  },
  navigateButton: {
    color: 'colors.primary600',
  },
};

import { useMutation, UseMutationResult } from 'react-query';

import {
  ApiError,
  CreateOwnPaymentRequestDto,
  CreatePaymentOrderResponseDto,
  getClientId,
  paymentOrdersApiClient,
} from '@app/core/api';

export const useCreateOwnPayment = (): UseMutationResult<
  CreatePaymentOrderResponseDto,
  ApiError,
  CreateOwnPaymentRequestDto
> =>
  useMutation(async (createOwnPaymentRequestDto: CreateOwnPaymentRequestDto) => {
    const response = await paymentOrdersApiClient.paymentOrderControllerCreateOwnPayment(
      getClientId(),
      createOwnPaymentRequestDto
    );
    if (response.status !== 200) {
      throw new ApiError(response.request.status, "Can't create own payment");
    }
    return response.data;
  });

import React, { useState } from 'react';

import {
  AccountStatementConfigDtoColumnTypeEnum,
  AccountStatementUpdateConfigDtoColumnTypeEnum,
  StatementTypeEnum,
} from '@app/core/api';
import {
  ColumnFilterType,
  TableColumnConfig,
  CurrencyEnum,
  PaginationChangeType,
  SortingRule,
  DocumentStatus,
  Operation,
} from '@app/core/types';
import { updateStatementsTableConfig, updateOperationsTableConfig, ProfileState } from '@app/slices/profileSlice';
import { useAppDispatch, useAppSelector } from '@app/src/store';

import { StatementsTablePreference } from './components/StatementsTablePreference';
import { TableContent } from './components/TableContent';
import { ConfirmSignPaymentMultipleOrderDialog } from '../../../ConfirmSignPaymentMultipleOrderDialog';
import { ConfirmSignPaymentOrderDialog } from '../../../ConfirmSignPaymentOrderDialog';
import { SendToSignPaymentOrder } from '../../../SendToSignPaymentOrder';
import { SignPaymentOrder } from '../../../SignPaymentOrder';
import { SignPaymentOrderMultiple } from '../../../SignPaymentOrderMultiple';
import { OperationsFromPeriodResult, StatementsSubFilterEnum } from '../../types';

export interface StatementsTableProps {
  operationsFromPeriod?: OperationsFromPeriodResult;
  loading?: boolean;
  queryKey: string;
  currencyOptions: CurrencyEnum[];
  columnFilter: ColumnFilterType<StatementsSubFilterEnum>;
  columnsConfig: Array<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>>;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  columnsConfigMutationFn(
    config: TableColumnConfig<AccountStatementUpdateConfigDtoColumnTypeEnum>[]
  ): Promise<TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]>;
  onDeleteDocumentSuccess(): void;
  onCancelDocumentSuccess(): void;
  onSignDocumentsSuccess(): void;
  onSendToSignDocumentsSuccess(): void;
  onDownloadOperationsFile(props: { operationsUuids: string[]; outputType: string; format?: string }): void;
  onColumnFilterChange(updater: ColumnFilterType<StatementsSubFilterEnum>, submit?: boolean): void;
  onClearSubFilter(): void;
}

export const StatementsTable: React.FC<StatementsTableProps> = ({
  operationsFromPeriod,
  loading,
  queryKey,
  columnFilter,
  columnsConfig,
  onSort,
  onPaginationChange,
  columnsConfigMutationFn,
  onDeleteDocumentSuccess,
  onSignDocumentsSuccess,
  onSendToSignDocumentsSuccess,
  currencyOptions,
  onDownloadOperationsFile,
  onColumnFilterChange,
  onCancelDocumentSuccess,
  onClearSubFilter,
}) => {
  const dispatch = useAppDispatch();
  const [showStatementsPreferencePopup, setShowStatementsPreferencePopup] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<Operation[]>([]);
  const [showSignPaymentOrderDialog, setShowSignPaymentOrderDialog] = useState<boolean>(false);
  const [showSendToSignPaymentOrderDialog, setShowSendToSignPaymentOrderDialog] = useState<boolean>(false);
  const [documentsToSign, setDocumentsToSign] = useState<Operation[]>([]);
  const [showConfirmSignPaymentMultipleOrderDialog, setShowConfirmSignPaymentMultipleOrderDialog] =
    useState<boolean>(false);
  const [showConfirmSignPaymentOrderDialog, setShowConfirmSignPaymentOrderDialog] = useState<boolean>(false);
  const [showSignPaymentOrderMultiple, setShowSignPaymentOrderMultiple] = useState<boolean>(false);
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean | undefined>(false);

  const onPaginationChangeHandler = ({ rowsPerPage, page }: PaginationChangeType) => {
    onPaginationChange({ rowsPerPage, page });
  };

  const onClosePreferencePopup = () => {
    setShowStatementsPreferencePopup(false);
  };

  const onSettingsButtonClickHandler = () => {
    setShowStatementsPreferencePopup(true);
  };

  const onErrorConfigurationSavedHandler = () => {
    setShowStatementsPreferencePopup(false);
  };

  const onSuccessConfigurationSavedHandler = (
    tableColumnsConfig: TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[]
  ) => {
    setShowStatementsPreferencePopup(false);
    if (queryKey === StatementTypeEnum.UahOperation) {
      dispatch(updateOperationsTableConfig(tableColumnsConfig));
    } else {
      dispatch(updateStatementsTableConfig(tableColumnsConfig));
    }
  };

  const onCloseSignPaymentOrderHandler = () => {
    setShowSignPaymentOrderDialog(false);
  };

  const onSignSuccessHandler = () => {
    setShowSignPaymentOrderDialog(false);
    setShowSignPaymentOrderMultiple(false);
    setDocumentsToSign([]);
    setSelectedDocuments([]);
    onSignDocumentsSuccess();
  };

  const onSendToSignSuccessHandler = () => {
    setShowSendToSignPaymentOrderDialog(false);
    setDocumentsToSign([]);
    setSelectedDocuments([]);
    onSendToSignDocumentsSuccess();
    setSignFromAnotherPerson(false);
  };

  const onSignHandler = (selectedOperations: Operation[]) => {
    const docsForSign = selectedOperations.filter(
      (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
    );

    setDocumentsToSign(docsForSign);
    setSelectedDocuments(selectedOperations);
    if (selectedOperations.length > 1) {
      setShowConfirmSignPaymentMultipleOrderDialog(true);
    } else {
      setShowConfirmSignPaymentOrderDialog(true);
    }
  };

  const onSignFromAnotherHandler = (selectedOperations: Operation[]) => {
    const docsForSign = selectedOperations.filter(
      (item) =>
        item.status === DocumentStatus.ToSign ||
        item.status === DocumentStatus.Saved ||
        item.status === DocumentStatus.OnSigning
    );

    setDocumentsToSign(docsForSign);
    setSelectedDocuments(selectedOperations);
    setSignFromAnotherPerson(true);
    if (selectedOperations.length > 1) {
      setShowConfirmSignPaymentMultipleOrderDialog(true);
    } else {
      setShowConfirmSignPaymentOrderDialog(true);
    }
  };

  const onSendToSignHandler = (selectedOperations: Operation[]) => {
    setSelectedDocuments(selectedOperations);
    setShowSendToSignPaymentOrderDialog(true);
  };

  const onCloseConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(false);
  };

  const onAgreeConfirmSignPaymentMultipleOrderDialog = () => {
    setShowConfirmSignPaymentMultipleOrderDialog(false);
    setShowSignPaymentOrderMultiple(true);
  };

  const onCloseSignPaymentOrderMultipleHandler = () => {
    setShowSignPaymentOrderMultiple(false);
  };

  const onCloseSendToSignHandler = () => {
    setShowSendToSignPaymentOrderDialog(false);
  };

  const onCloseConfirmSignPaymentOrderDialog = () => {
    setShowConfirmSignPaymentOrderDialog(false);
  };

  const onAgreeConfirmSignPaymentOrderDialog = () => {
    setShowConfirmSignPaymentOrderDialog(false);
    setShowSignPaymentOrderDialog(true);
  };

  const operations = operationsFromPeriod?.operations || [];
  const operationsCount = operationsFromPeriod?.pagination.totalCount;
  const totalAmounts = operationsFromPeriod?.totalAmounts;
  const pagination = operationsFromPeriod?.pagination;

  return (
    <>
      <TableContent
        loading={loading}
        operationsCount={operationsCount}
        totalAmounts={totalAmounts}
        columnsConfig={columnsConfig}
        operationsData={operations}
        onSettingsButtonClick={onSettingsButtonClickHandler}
        pagination={pagination}
        columnFilter={columnFilter}
        onSort={onSort}
        queryKey={queryKey}
        onPaginationChange={onPaginationChangeHandler}
        onSign={onSignHandler}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        currencyOptions={currencyOptions}
        onSendToSignSign={onSendToSignHandler}
        onDownloadOperationsFile={onDownloadOperationsFile}
        onColumnFilterChange={onColumnFilterChange}
        onCancelDocumentSuccess={onCancelDocumentSuccess}
        onSignFromAnother={onSignFromAnotherHandler}
        onClearSubFilter={onClearSubFilter}
      />

      {showStatementsPreferencePopup ? (
        <StatementsTablePreference
          columnsConfig={columnsConfig}
          columnsConfigMutationFn={columnsConfigMutationFn}
          onCloseClick={onClosePreferencePopup}
          onSuccessConfigurationSaved={onSuccessConfigurationSavedHandler}
          onErrorConfigurationSaved={onErrorConfigurationSavedHandler}
        />
      ) : null}

      {showConfirmSignPaymentOrderDialog ? (
        <ConfirmSignPaymentOrderDialog
          toSignDocument={selectedDocuments[0]}
          onClose={onCloseConfirmSignPaymentOrderDialog}
          onAgreeSign={onAgreeConfirmSignPaymentOrderDialog}
        />
      ) : null}

      {showSignPaymentOrderDialog ? (
        <SignPaymentOrder
          uuid={documentsToSign[0].uuid}
          documentToSign={JSON.stringify(documentsToSign[0])}
          onSuccessPopupCancel={onSignSuccessHandler}
          onClose={onCloseSignPaymentOrderHandler}
          signFromAnotherPerson={signFromAnotherPerson}
        />
      ) : null}

      {showConfirmSignPaymentMultipleOrderDialog ? (
        <ConfirmSignPaymentMultipleOrderDialog
          selectedCount={selectedDocuments.length}
          toSignDocuments={documentsToSign}
          onClose={onCloseConfirmSignPaymentMultipleOrderDialog}
          onAgreeSign={onAgreeConfirmSignPaymentMultipleOrderDialog}
        />
      ) : null}

      {showSignPaymentOrderMultiple ? (
        <SignPaymentOrderMultiple
          operations={documentsToSign}
          onClose={onCloseSignPaymentOrderMultipleHandler}
          onSuccessPopupCancel={onSignSuccessHandler}
        />
      ) : null}
      {showSendToSignPaymentOrderDialog ? (
        <SendToSignPaymentOrder
          selectedDocuments={selectedDocuments}
          onClose={onCloseSendToSignHandler}
          onSubmitSuccess={onSendToSignSuccessHandler}
        />
      ) : null}
    </>
  );
};

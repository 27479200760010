import React from 'react';

import { Modal, styled } from '@mui/material';
import { ModalProps } from '@mui/material/Modal/Modal';

export const ModalCommon: React.FC<ModalProps> = styled(Modal)<ModalProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('xs')]: {
    paddingRight: '24px',
    paddingLeft: '24px',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: ' blur(1.5px)',
    backgroundColor: 'rgba(86, 86, 86, 0.2)',
  },
}));

import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { CrossIcon, CsvIcon, InfoIcon } from '@app/common/icons';
import { ApiError, ImportAdditionalInfoDtoCodeEnum, ImportOrdersResponseDto, LinkResponseDto } from '@app/core/api';

import { getPaymentOrderExampleFileFn, paymentOrderParseFn } from './query';
import { styles } from './styles';
import { FilesEnum } from '../../types';
import { ModalPopupCommon } from '../Modal';
import { StatusLargePopup } from '../StatusLargePopup';

export interface ImportPaymentsDialogProps {
  show: boolean;
  onCancel(): void;
  onImportSuccessResult(data: ImportOrdersResponseDto): void;
  linkForInstruction: string;
}

export const ImportPaymentsDialog: React.FC<ImportPaymentsDialogProps> = ({
  show,
  onCancel,
  onImportSuccessResult,
  linkForInstruction,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [showErrorImportModal, setShowErrorImportModal] = useState<boolean>(false);
  const [fileToParse, setFileToParse] = useState<string | undefined>();
  const [linkForExampleFile, setLinkForExampleFile] = useState<string | undefined>();
  const [importErrorMessage, setImportErrorMessage] = useState<string>(
    'importPaymentsDialog_error_import_file_message'
  );
  const { t } = useTranslation();
  const { data, mutate, isLoading, status } = useMutation<ImportOrdersResponseDto, ApiError, string>(
    paymentOrderParseFn
  );

  const { data: linkResponse } = useQuery<LinkResponseDto, ApiError>(
    'getLinkForPaymentOrderExampleFile',
    getPaymentOrderExampleFileFn,
    {
      enabled: true,
    }
  );

  useEffect(() => {
    if (linkResponse) {
      setLinkForExampleFile(linkResponse.link);
    }
  }, [linkResponse]);
  const resetForm = () => {
    setFileToParse(undefined);
  };

  useEffect(() => {
    if (status === 'success' && data) {
      if (data.additionalInfo) {
        if (data.additionalInfo.code === ImportAdditionalInfoDtoCodeEnum.TooManyDocuments) {
          setImportErrorMessage('importErrorMessage_maxLimit');
        }
        setShowErrorImportModal(true);
      } else {
        onImportSuccessResult(data);
      }
    }

    if (status === 'error') {
      setImportErrorMessage('importPaymentsDialog_error_import_file_message');
      setShowErrorImportModal(true);
    }
  }, [data, onImportSuccessResult, status]);

  const handleFile = (file: File) => {
    resetForm();
    if (file.name.toLocaleLowerCase().substr(-3) !== FilesEnum.csv) {
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setFileToParse(reader.result);
      }
    };
    reader.readAsText(file);
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const fileInputClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0) {
          handleFile(fileInputRef.current.files[0]);
        }
      }
    }
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const submitPaymentsFile = () => {
    if (fileToParse) {
      mutate(fileToParse);
    }
  };

  const handleImportClickBtn = () => {
    submitPaymentsFile();
  };

  const onHandleClose = () => {
    resetForm();
    onCancel();
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorImportModal(false);
    submitPaymentsFile();
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorImportModal(false);
  };

  const importButtonDisabled = isLoading;

  const onLinkClickHandler = () => {
    if (linkForExampleFile) {
      window.open(linkForExampleFile);
    }
  };

  return (
    <>
      <ModalPopupCommon open={show} questionPaper>
        <Box pb={5}>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <b>{t('importPaymentsDialog_title')}</b>
            </Typography>
            <IconButton size="small" onClick={onHandleClose}>
              <CrossIcon />
            </IconButton>
          </Grid>
          <Box mt={5}>
            <Grid
              container
              direction="column"
              alignItems="center"
              wrap="nowrap"
              sx={{ ...styles.dropContainer, ...((oveDragZone || fileName?.length) && styles.dropContainerActive) }}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
            >
              <Typography variant="body2" component="div" color="textPrimary" sx={styles.mainText}>
                <input
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  id="upload"
                  name="upload"
                  type="file"
                  disabled={isLoading}
                  onChange={fileSelected}
                />
                {fileName ? (
                  <Typography component="span" variant="body2">
                    <Box sx={styles.fontWeight}>{fileName}</Box>
                  </Typography>
                ) : (
                  <>
                    {t('importPaymentsDialog_dragFileInfoText')} <br />
                    <Button
                      variant="text"
                      sx={{ ...styles.label, ...styles.fontWeight }}
                      onClick={fileInputClicked}
                      disabled={isLoading}
                    >
                      {t('importPaymentsDialog_selectFileFromDiskText')}
                    </Button>
                  </>
                )}
              </Typography>
              <Typography variant="caption" sx={styles.secondText}>
                {fileName ? (
                  <Button variant="text" sx={styles.label} onClick={fileInputClicked} disabled={isLoading}>
                    {t('upload_other_file')}
                  </Button>
                ) : (
                  t('importPaymentsDialog_supportedFileText')
                )}
              </Typography>
            </Grid>
          </Box>
          <Grid container direction="column">
            <Box mt={4}>
              <Grid container justifyContent="space-between">
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={styles.downloadTemplate}
                  onClick={onLinkClickHandler}
                >
                  <Box mr={4}>
                    <CsvIcon />
                  </Box>
                  <Typography variant="caption">{t('importPaymentsDialog_loadTemplateText')}</Typography>
                </Grid>
                <Grid container item xs={6} alignItems="center" justifyContent="flex-end">
                  <a
                    target="_blank"
                    href={linkForInstruction}
                    style={{
                      ...styles.downloadTemplate,
                    }}
                  >
                    <InfoIcon fontSize="small" sx={styles.info} />
                    <Typography sx={styles.infoLink} variant="caption">
                      {t('importPaymentsDialog_instructionLinkText')}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Box>
            <Box mt={1}>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Box pr={6}>
                  <Button size="small" variant="outlined" color="primary" onClick={onHandleClose}>
                    {t('cancel')}
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={importButtonDisabled}
                  onClick={handleImportClickBtn}
                >
                  {isLoading ? t('loading') : t('importPaymentsDialog_importButtonText')}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </ModalPopupCommon>

      <StatusLargePopup
        show={showErrorImportModal}
        text={<Box sx={{ textAlign: 'center' }}>{t(importErrorMessage)}</Box>}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

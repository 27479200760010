import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  ColumnOrderState,
  VisibilityState,
  SortingState,
  ColumnSort,
  ColumnResizeMode,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { AccountStatementConfigDtoColumnTypeEnum } from '@app/core/api';
import { useTableColumnSizing } from '@app/core/hooks';
import {
  ColumnFilterType,
  TableColumnConfig,
  CurrencyEnum,
  PaginationChangeType,
  SortingRule,
  TablePaginationType,
  Operation,
  TotalAmounts,
} from '@app/core/types';

import { makeTableColumns } from './columns';
import { buildTableRowsData, getStatementsColumnVisibility } from './helpers';
import { StatementsBodyContent } from './StatementsBodyContent';
import { styles } from './styles';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
  statementsTableShortAfterNumber,
} from '../../../../../../constants';
import {
  ComplexTable,
  TableHeadContent,
  TablePagination,
  getColumOrder,
  getEnabledColumns,
} from '../../../../../ComplexTable';
import { StatementsSubFilterEnum } from '../../../../types';
import { OperationRow } from '../../types';
import { TableToolbar } from '../TableToolbar';

interface TableContentProps {
  operationsData: Operation[];
  columnsConfig: TableColumnConfig<AccountStatementConfigDtoColumnTypeEnum>[];
  operationsCount?: number;
  totalAmounts?: TotalAmounts;
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey: string;
  currencyOptions: CurrencyEnum[];
  columnFilter: ColumnFilterType<StatementsSubFilterEnum>;
  onSettingsButtonClick(): void;
  onSort?(rule: SortingRule): void;
  onPaginationChange(pagination: PaginationChangeType): void;
  onSign(selectedOperations: Operation[]): void;
  onSignFromAnother(selectedOperations: Operation[]): void;
  onSendToSignSign(selectedOperations: Operation[]): void;
  onDeleteDocumentSuccess(): void;
  onCancelDocumentSuccess(): void;
  onDownloadOperationsFile(props: { operationsUuids: string[]; outputType: string; format?: string }): void;
  onColumnFilterChange(updater: ColumnFilterType<StatementsSubFilterEnum>, submit?: boolean): void;
  onClearSubFilter(): void;
}

export const TableContent: React.FC<TableContentProps> = ({
  operationsData,
  columnsConfig,
  operationsCount,
  totalAmounts,
  pagination,
  loading,
  queryKey,
  columnFilter,
  onSettingsButtonClick,
  onSort,
  onPaginationChange,
  onSign,
  onDeleteDocumentSuccess,
  currencyOptions,
  onSendToSignSign,
  onDownloadOperationsFile,
  onColumnFilterChange,
  onCancelDocumentSuccess,
  onSignFromAnother,
  onClearSubFilter,
}) => {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    getStatementsColumnVisibility(columnsConfig)
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(getColumOrder(columnsConfig));
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');
  const { columnSizing, setColumnSizing } = useTableColumnSizing({ name: queryKey });

  const sortRuleRef = useRef<ColumnSort | undefined>();
  const [showSubFilters, setShowSubFilters] = useState<boolean>(false);
  const data = useMemo(() => {
    return buildTableRowsData(operationsData);
  }, [operationsData]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        showSubFilters,
        showShort: getEnabledColumns(columnsConfig).length > statementsTableShortAfterNumber,
        t,
        currencyOptions,
        onSearchButtonClick: () => setShowSubFilters(true),
        onSettingsButtonClick,
        onClearSubFilter,
        onCloseSubFilter() {
          setShowSubFilters(false);
        },
      }),
    ];
  }, [columnsConfig, currencyOptions, onClearSubFilter, onSettingsButtonClick, showSubFilters, t]);

  const table = useReactTable<OperationRow>({
    data,
    columns,
    state: {
      sorting,
      columnOrder,
      columnVisibility,
      columnSizing,
    },
    columnResizeMode,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnSizingChange: setColumnSizing,
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      columnFilterSate: {
        columnFilter,
        onColumnFilterChange,
      },
    },
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  useEffect(() => {
    setColumnVisibility(getStatementsColumnVisibility(columnsConfig));
    setColumnOrder(getColumOrder(columnsConfig));
  }, [columnsConfig]);

  useEffect(() => {
    if (operationsData.length && table.getIsSomeRowsSelected()) {
      table.resetRowSelection();
    }
  }, [operationsData, table]);

  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);

  return (
    <>
      <TableToolbar
        operationsCount={operationsCount}
        totalAmounts={totalAmounts}
        queryKey={queryKey}
        onSign={onSign}
        onSignFromAnother={onSignFromAnother}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        onSendToSign={onSendToSignSign}
        getSelectedRowModel={table.getSelectedRowModel}
        onDownloadOperationsFile={onDownloadOperationsFile}
        onCancelDocumentSuccess={onCancelDocumentSuccess}
      />
      <ComplexTable
        container={{
          props: {
            className: 'overflow-x-auto',
          },
        }}
        table={{
          props: {
            style: {
              width: operationsData.length > 0 ? table.getTotalSize() : '100%',
              minWidth: '100%',
            },
          },
        }}
        head={{
          content: <TableHeadContent<OperationRow> {...{ table, styles }} resizable={operationsData.length > 0} />,
        }}
        body={{
          content: (
            <StatementsBodyContent {...{ loading, operationsCount, totalAmounts, getRowModel: table.getRowModel }} />
          ),
        }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, GetSignersResponseDto } from '@app/core/api';

const getMessagesSignersFn = async (): Promise<GetSignersResponseDto> => {
  const result = await messagesApiClient.messageSignControllerGetSigners(getClientId());

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't edit messages signers");
  }

  return result.data;
};

export const useGetMessagesSigners = (): UseMutationResult<GetSignersResponseDto, ApiError, undefined> => {
  return useMutation<GetSignersResponseDto, ApiError, undefined>(getMessagesSignersFn);
};

import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DocumentStatus, Operation } from '@app/core/types';
import { styles } from '@app/pages/operations/mobile/components/DocumentStatusTag/styles';

export const getStatusTranslationKeyByStatusEnum = (status: DocumentStatus): string => {
  switch (status) {
    case DocumentStatus.ToSign:
      return 'documentStatus_toSign';
    case DocumentStatus.Rejected:
      return 'documentStatus_rejected';
    case DocumentStatus.Executed:
      return 'documentStatus_executed';
    case DocumentStatus.Saved:
      return 'documentStatus_saved';
    case DocumentStatus.OnSigning:
      return 'documentStatus_OnSigning';
    case DocumentStatus.Processing:
      return 'documentStatus_processing';
    case DocumentStatus.Canceled:
      return 'CANCELED';
    default:
      return '';
  }
};
type DocumentStatusTagProps = {
  operation: Operation;
};

export const DocumentStatusTag: React.FC<DocumentStatusTagProps> = ({ operation }) => {
  const { t } = useTranslation();
  const status = operation.status;
  return (
    <Box
      component="span"
      sx={{
        ...styles.root,
        ...(status === DocumentStatus.ToSign && styles.toSign),
        ...(status === DocumentStatus.Rejected && styles.rejected),
        ...(status === DocumentStatus.Canceled && styles.rejected),
        ...(status === DocumentStatus.Executed && styles.executed),
        ...(status === DocumentStatus.Saved && styles.saved),
        ...(status === DocumentStatus.OnSigning && styles.onSigning),
        ...(status === DocumentStatus.Processing && styles.processing),
      }}
    >
      {t(getStatusTranslationKeyByStatusEnum(status))}
    </Box>
  );
};

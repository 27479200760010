export const styles = {
  form: {
    width: '100%',
  },
  contentMobile: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    padding: '20px 16px 50px 16px',
  },
  header: {
    fontSize: '1rem',
    color: 'colors.primary700',
    fontWeight: 'bold',
    marginRight: '4px',
  },
  flexbox: {
    display: 'flex',
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  bookmarkBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bookmarkText: {
    fontSize: '1rem',
    color: 'colors.gray300',
    marginRight: '14',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  transactionPresentedLabel: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  checkboxField: {
    color: 'colors.gray600',
    marginLeft: 0,
  },
  tabBody: {
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
    minWidth: '600px',
  },
  paymentNumber: {
    height: '50px',
  },
  passport: {
    height: '24px',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingTop: '30px',
  },
  multiline: {
    padding: 0,
    fontSize: '0.875rem',
    lineHeight: '20',
    backgroundColor: 'colors.primaryTextColor',
    borderRadius: '8px',
  },
  vat: {
    width: '40px',
    marginLeft: '4px',
  },
  mobileVatInput: {
    width: '85%',
    marginLeft: '4px',
    textAlign: 'right',
  },
  vatTextDisabled: {
    color: 'colors.gray300',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  info: {
    color: 'colors.primary500',
  },
  lei: {
    display: 'flex',
    color: 'colors.primary500',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  leiBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  starSelected: {
    color: 'colors.warning',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  signButton: {
    marginLeft: '28px',
  },
};

import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, profileApiClient, ApiError, GetClientUsersResponseDto, UserStateStatusEnum } from '@app/core/api';

export interface CompanyUsersTableQueryProps {
  fullName?: string;
  status?: UserStateStatusEnum;
}

const getCompanyUsersFn = async (subFilter: CompanyUsersTableQueryProps): Promise<GetClientUsersResponseDto> => {
  const response = await profileApiClient.profileControllerGetClientUsers(
    getClientId(),
    subFilter.fullName,
    subFilter.status
  );

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't get users!");
  }

  return response.data;
};

export const useGetCompanyUsers = (): UseMutationResult<
  GetClientUsersResponseDto,
  ApiError,
  CompanyUsersTableQueryProps
> => {
  return useMutation<GetClientUsersResponseDto, ApiError, CompanyUsersTableQueryProps>(getCompanyUsersFn);
};

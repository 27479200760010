import { CurrencyOperationStatusEnum, GetAccountResponseDto } from '@app/core/api';
import { DatePeriod } from '@app/core/components/Form/controls/DateField/constants';

export enum CurrencyExchangeTypeEnum {
  all = 'all',
  purchase = 'purchase',
  sale = 'sale',
  conversion = 'conversion',
}

export enum CurrencyExchangeFilterField {
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  documentNumber = 'documentNumber',
  status = 'status',
  amountMin = 'amountMin',
  amountMax = 'amountMax',
  depositType = 'depositType',
  depositAccount = 'depositAccount',
  withdrawalAccount = 'withdrawalAccount',
  interestRateFrom = 'interestRateFrom',
  interestRateTo = 'interestRateTo',
  temp_dateFrom = 'temp_dateFrom',
  temp_dateTo = 'temp_dateTo',
  dateRangeSelect = 'dateRangeSelect',
  withdrawalAccountCurrency = 'withdrawalAccountCurrency',
  createdBy = 'createdBy',
}

export enum CurrencyExchangeTableColumns {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  withdrawalAccount = 'withdrawalAccount',
  depositAccount = 'depositAccount',
  withdrawalAccountNumber = 'withdrawalAccountNumber',
  depositAccountNumber = 'depositAccountNumber',
  currencyAgreementNumber = 'currencyAgreementNumber',
  currencyExchangeType = 'currencyExchangeType',
  amount = 'amount',
  withdrawalAccountCurrency = 'withdrawalAccountCurrency',
  status = 'status',
}

export type CurrencyExchangeFilterFormData = {
  [CurrencyExchangeFilterField.dateFrom]: Date;
  [CurrencyExchangeFilterField.dateTo]: Date;
  [CurrencyExchangeFilterField.temp_dateFrom]: string;
  [CurrencyExchangeFilterField.temp_dateTo]: string;
  [CurrencyExchangeFilterField.documentNumber]?: string;
  [CurrencyExchangeFilterField.withdrawalAccount]?: GetAccountResponseDto;
  [CurrencyExchangeFilterField.depositAccount]?: GetAccountResponseDto;
  [CurrencyExchangeFilterField.status]?: CurrencyOperationStatusEnum;
  [CurrencyExchangeFilterField.amountMin]?: string;
  [CurrencyExchangeFilterField.amountMax]?: string;
  [CurrencyExchangeFilterField.withdrawalAccountCurrency]?: string;
  [CurrencyExchangeFilterField.dateRangeSelect]?: DatePeriod;
  [CurrencyExchangeFilterField.createdBy]?: number;
};

import { date, object, SchemaOf, string } from 'yup';

import { CurrencyExchangeFilterField } from '@app/pages/exchange/currencyExchangeTransactions/types';

type SchemaValidationObject = {
  [CurrencyExchangeFilterField.dateFrom]: Date;
  [CurrencyExchangeFilterField.dateTo]: Date;
  [CurrencyExchangeFilterField.temp_dateFrom]: string;
  [CurrencyExchangeFilterField.temp_dateTo]: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [CurrencyExchangeFilterField.dateFrom]: date().required(t('paymentCreationLocalPayment_field_required')),
    [CurrencyExchangeFilterField.dateTo]: date().required(t('paymentCreationLocalPayment_field_required')),
    [CurrencyExchangeFilterField.temp_dateFrom]: string().required(t('paymentCreationLocalPayment_field_required')),
    [CurrencyExchangeFilterField.temp_dateTo]: string().required(t('paymentCreationLocalPayment_field_required')),
  });
};

import { paymentOrdersApiClient } from '@app/core/api';

export interface BankNameQueryFnProps {
  bank: string;
  bankCode: string;
}

export type BankNameBankNameQueryFilter = {
  iban: string;
};

export const bankNameQueryFn = async (filter: BankNameBankNameQueryFilter): Promise<BankNameQueryFnProps> => {
  const response = await paymentOrdersApiClient.paymentOrderControllerGetBankNameByIban(filter);
  return response.data;
};

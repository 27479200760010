import React from 'react';

import { Button, CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ActionFiltersButtonsProps {
  submitButtonText?: React.ReactNode;
  resetFormHandler(): void;
}

export const ActionFiltersButtons: React.FC<ActionFiltersButtonsProps> = ({ submitButtonText, resetFormHandler }) => {
  const { t } = useTranslation();
  return (
    <CardActions
      disableSpacing
      sx={{
        justifyContent: 'space-between',
        padding: 0,
        width: '100%',
      }}
    >
      <Button color="primary" onClick={resetFormHandler}>
        {t('clear')}
      </Button>
      <Button type="submit" variant="contained" color="primary">
        {submitButtonText || t('submit')}
      </Button>
    </CardActions>
  );
};

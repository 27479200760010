export const styles = {
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_agreementStartDate: {
    maxWidth: '150px',
    minWidth: '60px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_securityDescription: {
    maxWidth: '400px',
    minWidth: '60px',
  },
  cell_lastEvaluationDate: {
    maxWidth: '110px',
    minWidth: '60px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_agreementNumber: {
    mixWidth: '60px',
    maxWidth: '140px',
  },
  cell_agreementEndDate: {
    mixWidth: '60px',
    maxWidth: '150px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_currencyCode: {
    justifyContent: 'start',
    minWidth: '70px',
    maxWidth: '90px',
    '&.body': {
      color: 'colors.gray400',
      paddingRight: '20px',
    },
  },
  cell_amount: {
    textAlign: 'start',
    minWidth: '140px',
    maxWidth: '140px',
    '&.header': {
      textAlign: 'end',
      justifyContent: 'flex-end',
    },
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-end',
      paddingRight: '20px',
      color: 'colors.primary700',
      fontWeight: 600,
    },
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  cell_state: {
    textAlign: 'start',
    minWidth: '70px',
    maxWidth: '140px',
    '&.body': {
      textAlign: 'end',
      justifyContent: 'flex-start',
      paddingRight: '12px',
    },
  },
};

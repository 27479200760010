import { CurrencyEnum } from '@app/core/api';

export enum CounterpartyFormFieldsEnum {
  name = 'name',
  nonLeiFlag = 'nonLeiFlag',
  lei = 'lei',
  passport = 'passport',
  account = 'account',
  currency = 'currency',
  idPassport = 'idPassport',
  recipientAccount = 'recipientAccount',
  nonResidentFlag = 'nonResidentFlag',
  countryCode = 'countryCode',
}

export type CounterpartyFormData = {
  [CounterpartyFormFieldsEnum.name]: string;
  [CounterpartyFormFieldsEnum.lei]?: string;
  [CounterpartyFormFieldsEnum.nonLeiFlag]: boolean;
  [CounterpartyFormFieldsEnum.passport]?: string;
  [CounterpartyFormFieldsEnum.account]: string;
  [CounterpartyFormFieldsEnum.currency]: CurrencyEnum;
  [CounterpartyFormFieldsEnum.nonResidentFlag]?: boolean;
  [CounterpartyFormFieldsEnum.countryCode]?: string;
};

export type CounterpartiesColumns = {
  name: string;
  lei?: string;
  passport?: string;
  account: string;
  bank: string;
  id: number;
};

export enum CounterpartiesColumnsEnum {
  name = 'name',
  lei = 'lei',
  account = 'account',
  bank = 'bank',
}

export type CounterpartiesFilterRows = {
  clientId: number;
  name: string;
  lei: string;
  account: string;
  bank: string;
};

export const resetFormData = {
  name: '',
  lei: '',
  account: '',
  bank: '',
};

export enum FormMode {
  create = 'create',
  edit = 'edit',
}

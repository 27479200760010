export enum LoansLinesTypeEnum {
  open = 'open',
  closed = 'closed',
}

export enum LoansLinesFilterField {
  agreementStartDateFrom = 'agreementStartDateFrom',
  agreementStartDateTo = 'agreementStartDateTo',
  agreementEndDateFrom = 'agreementEndDateFrom',
  agreementEndDateTo = 'agreementEndDateTo',
  agreementNumber = 'agreementNumber',
  currency = 'currency',
  limitFrom = 'limitFrom',
  limitTo = 'limitTo',
  usedLimitFrom = 'usedLimitFrom',
  usedLimitTo = 'usedLimitTo',
  interestRateFrom = 'interestRateFrom',
  interestRateTo = 'interestRateTo',
  temp_agreementStartDateFrom = 'temp_agreementStartDateFrom',
  temp_agreementStartDateTo = 'temp_agreementStartDateTo',
  temp_agreementEndDateFrom = 'temp_agreementEndDateFrom',
  temp_agreementEndDateTo = 'temp_agreementEndDateTo',
}

export type LoansLinesFilterFormData = {
  [LoansLinesFilterField.agreementStartDateFrom]?: Date;
  [LoansLinesFilterField.agreementStartDateTo]?: Date;
  [LoansLinesFilterField.agreementEndDateFrom]?: Date;
  [LoansLinesFilterField.agreementEndDateTo]?: Date;
  [LoansLinesFilterField.agreementNumber]?: string;
  [LoansLinesFilterField.limitFrom]?: string;
  [LoansLinesFilterField.limitTo]?: string;
  [LoansLinesFilterField.usedLimitFrom]?: string;
  [LoansLinesFilterField.usedLimitTo]?: string;
  [LoansLinesFilterField.currency]?: string;
  [LoansLinesFilterField.interestRateFrom]?: string;
  [LoansLinesFilterField.interestRateTo]?: string;
  [LoansLinesFilterField.temp_agreementStartDateFrom]?: string;
  [LoansLinesFilterField.temp_agreementStartDateTo]?: string;
  [LoansLinesFilterField.temp_agreementEndDateFrom]?: string;
  [LoansLinesFilterField.temp_agreementEndDateTo]?: string;
};

export enum LoansLinesTableColumns {
  uuid = 'uuid',
  agreementStartDate = 'agreementStartDate',
  agreementEndDate = 'agreementEndDate',
  agreementNumber = 'agreementNumber',
  limit = 'limit',
  usedLimit = 'usedLimit', //использованный лимит
  availableLimit = 'availableLimit', // доступний ліміт (залишок ліміту)
  outstanding = 'outstanding', //оставшаяся сумма задолженности
  currency = 'currency',
  interestRate = 'interestRate', //Процентная ставка
}

export enum LoansLinesMenuItems {
  operations = 'operations',
  tranches = 'tranches',
  safety = 'safety',
}

import React from 'react';

import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeleteIcon } from '@app/common/icons';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/payment/import/components/ImportTableToolbar/styles';
import { Order } from '@app/pages/payment/import/types';

interface ImportTableToolbarProps {
  selectedOrders: Order[];
  count: number;
  amount: number;
  currency: string;
  onDeleteDocuments(): void;
}
export const ImportTableToolbar: React.FC<ImportTableToolbarProps> = ({
  selectedOrders,
  count,
  amount,
  currency,
  onDeleteDocuments,
}) => {
  const { t } = useTranslation();

  return (
    <Box pb={1} sx={styles.sticky}>
      {selectedOrders?.length ? (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
        >
          <Grid item container xs={6} alignItems="center" justifyContent="flex-start">
            <Typography variant="body2" sx={styles.selectedCount}>
              {selectedOrders.length} {t('selected')}
            </Typography>
            <Box sx={styles.divider} />
            <Button variant="text" sx={styles.buttonRoot} onClick={onDeleteDocuments} startIcon={<DeleteIcon />}>
              <Hidden lgDown>
                <Typography>{t('delete')}</Typography>
              </Hidden>
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item container alignItems="center" justifyContent="flex-end">
          <Box>
            <Typography variant="body2" component="span" sx={styles.importCountTitle}>
              {t('paymentImport_tableToolbar_documentsCount')}
            </Typography>
            <Typography variant="body2" component="span" sx={styles.importCount}>{` ${count}`}</Typography>
          </Box>
          <Box ml={1}>
            <Typography variant="body2" component="span" sx={styles.totalAmountTitle}>
              {t('paymentImport_tableToolbar_documentsAmount')}
            </Typography>
            <Typography variant="body2" component="span" sx={styles.totalAmount}>{` ${formatAmount(
              amount
            )}`}</Typography>
            <Typography variant="caption" component="span" sx={styles.totalAmountCurrency}>{` ${currency}`}</Typography>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

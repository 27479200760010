import { SortingRule } from '@app/core/types';

export type PaymentsTemplatesFilterFormData = {
  templateName: string;
  lei: string;
  amountMin: string;
  amountMax: string;
  sortBy?: SortingRule;
};

export enum TemplatesTableColumns {
  templateName = 'templateName',
  senderAccount = 'senderAccount',
  recipientAccount = 'recipientAccount',
  recipientName = 'recipientName',
  paymentReference = 'paymentReference',
  amount = 'amount',
}

export enum TemplatesFormFieldNamesEnum {
  templateName = 'templateName',
  lei = 'lei',
  amountMin = 'amountMin',
  amountMax = 'amountMax',
}

import { profileApiClient, ApiError, UploadUserPhotoDto, UploadUserPhotoResponseDto } from '@app/core/api';

export const uploadUserPhotoQueryFn = async (userPhoto: UploadUserPhotoDto): Promise<UploadUserPhotoResponseDto> => {
  const response = await profileApiClient.profileControllerUploadUserPhoto({
    ...userPhoto,
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "Can't save user photo");
  }

  return response.data;
};

import {
  TableToolbarActionsEnum,
  TableToolbarActiveButtonsConfig,
  TableToolbarEnabledActionsType,
} from '@app/core/types';

export const makeTableToolbarActions = (
  enabledActions: TableToolbarEnabledActionsType
): TableToolbarActiveButtonsConfig => {
  let count = 1;
  const moreMenuPresent: boolean =
    Object.keys(enabledActions).reduce((acc: number, item) => {
      if (enabledActions[item as TableToolbarActionsEnum]) {
        return acc + 1;
      }
      return acc;
    }, 0) > 4;
  return Object.keys(enabledActions).reduce((acc: TableToolbarActiveButtonsConfig, item) => {
    if (enabledActions[item as TableToolbarActionsEnum]) {
      if (count <= 2) {
        acc[item as TableToolbarActionsEnum] = {
          short: false,
        };
      } else if (count > 2 && count <= 4) {
        acc[item as TableToolbarActionsEnum] = {
          short: moreMenuPresent,
        };
      } else if (count > 4) {
        acc.moreMenu = {
          ...acc.moreMenu,
          [item as TableToolbarActionsEnum]: true,
        };
      }
      count++;
    }
    return acc;
  }, {} as TableToolbarActiveButtonsConfig);
};

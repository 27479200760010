import { object, SchemaOf, string } from 'yup';

type SchemaValidationObject = {
  code: string;
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    code: string().required(t('paymentCreationLocalPayment_field_required')),
  });
};

import React, { useState, useRef, useEffect } from 'react';

import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import InputMask, { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask';

const shortBicMask: (string | RegExp)[] = [
  /^([a-zA-Z])/,
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  ' ',
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  ' ',
  /([a-zA-Z0-9])/,
  /([a-zA-Z0-9])/,
];

const longBicMask: (string | RegExp)[] = [
  /^([a-zA-Z])/,
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  ' ',
  /([a-zA-Z])/,
  /([a-zA-Z])/,
  ' ',
  /([a-zA-Z0-9])/,
  /([a-zA-Z0-9])/,
  ' ',
  /([a-zA-Z0-9])/,
  /([a-zA-Z0-9])/,
  /([a-zA-Z0-9])/,
];

export interface BicInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value?: string;
  onChange(bic: string): void;
}

export const BicInput: React.FC<BicInputProps> = ({ value = '', onChange, ...otherProps }) => {
  const ref = useRef<HTMLInputElement>();
  const [strValue, setStrValue] = useState<string>(value);
  const [mask, setMask] = useState<(string | RegExp)[]>(longBicMask);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrValue(event.target.value);
    onChange(event.target.value.replace(/ /g, ''));
  };

  useEffect(() => {
    setStrValue(value);
  }, [value]);

  const updateCursor = () => {
    window.requestAnimationFrame(() => {
      if (ref.current) {
        ref.current.setSelectionRange(100, 100);
      }
    });
  };

  const beforeMaskedStateChangeHandler = ({ nextState, currentState }: BeforeMaskedStateChangeStates): InputState => {
    if (currentState?.value) {
      if (currentState.value.replace(/ /g, '').length > 8 && mask !== longBicMask) {
        setMask(longBicMask);
        updateCursor();
        return {
          ...nextState,
          value: currentState.value.toUpperCase(),
        };
      }
      if (currentState.value.replace(/ /g, '').length <= 8 && mask !== shortBicMask) {
        setMask(shortBicMask);
        updateCursor();
      }
    }
    return {
      ...nextState,
      value: nextState.value.toUpperCase(),
    };
  };

  return (
    <InputMask
      mask={mask}
      beforeMaskedStateChange={beforeMaskedStateChangeHandler}
      maskPlaceholder={null}
      value={strValue}
      onChange={onChangeHandler}
      children={<TextField inputRef={ref} fullWidth variant="outlined" {...otherProps} />}
    />
  );
};

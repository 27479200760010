import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { configurationApiClient, getClientId, ApiError } from '@app/core/api';

export const useDeleteStatementsConfig = (): UseMutationResult<unknown, ApiError, string> => {
  return useMutation(async (uuid: string) => {
    const result = await configurationApiClient.clientConfigControllerDeleteStatementsConfig(getClientId(), uuid);

    if (result.status !== 200) {
      throw new ApiError(result.request.status, "Can't delete the statements config.");
    }

    return result.data;
  });
};

export const styles = {
  menuWrapper: {
    overflowX: 'auto',
  },
  mobilePageWrapper: {
    backgroundColor: 'initial',
    borderRadius: 0,
    height: '100vh',
    padding: 0,
  },
  createMenuRoot: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    marginRight: '16px',
  },
  menuItemTextStyle: {
    color: 'colors.gray400',
    fontWeight: 600,
  },
};

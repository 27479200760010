export const styles = {
  requisites: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    fontWeight: 600,
    '& span': {
      color: 'colors.gray400',
    },
  },
  content: {
    border: `1px solid`,
    borderColor: 'colors.gray100',
    borderRadius: '6px',
    padding: '14px 20px 20px 20px',
    width: '412px',
    backgroundColor: 'white',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '9px',
      height: '9px',
      top: -1,
      right: '74px',
      backgroundColor: 'white',
      borderLeft: `1px solid`,
      borderTop: `1px solid`,
      borderColor: 'colors.gray100',
      transform: 'translate(-50%, -47%) rotate(45deg)',
      borderRadius: '1px 0 0 0',
    },
  },
  contentMobile: {
    width: '100%',
  },
  row: {
    marginBottom: '10px',
    lineHeight: '1.4',
    color: 'colors.gray400',
  },
  rowRight: {
    textAlign: 'right',
    color: 'colors.primary600',
    paddingLeft: '10px',
  },
  rowLast: {
    marginTop: '10px',
    marginBottom: 0,
  },
  buttonPopover: {
    borderColor: 'colors.primary500',
  },
  buttonPopoverMobile: {
    fontSize: '12px',
  },
  buttonPopoverSvgIcon: {
    width: '17px',
    height: '17px',
    stroke: '#007ff2',
  },
  buttonPopoverSvgIconFill: {
    strokeWidth: 0,
    fill: 'colors.primary500',
  },
  alertCopy: {
    backgroundColor: 'colors.successBackground',
    border: `1px solid`,
    borderColor: 'colors.success',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.success',
    position: 'absolute',
    left: '20px',
    right: '20px',
    top: '20px',
    zIndex: 10,
    width: 'auto',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  alertCopyText: {
    fontWeight: 600,
    color: 'colors.success',
    marginLeft: '8px',
  },
  divider: {
    width: '100%',
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
    marginRight: '16px',
    flexShrink: 0,
  },
  swiftInfoHeader: {
    fontWeight: 600,
    color: 'colors.gray600',
  },
};

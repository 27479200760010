import { AccountBalanceMovement } from '@app/core/components/Statements/types';
import { CurrencyEnum } from '@app/core/types';

export type Turnover = {
  deposit: number;
  withdrawal: number;
  date: string;
  openingBalance: number;
  closingBalance: number;
  currency: string;
};

export enum TurnoversTableColumns {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  date = 'date',
  openingBalance = 'openingBalance',
  closingBalance = 'closingBalance',
  currency = 'currency',
}

export type OperationsFromPeriodResult = {
  accountBalancesMovement: AccountBalanceMovement[];
  operations: Turnover[];
  operationsCount: number;
  totalAmount: number;
  currency: CurrencyEnum;
  pagination: {
    rowsPerPage: number;
    page: number;
    totalCount: number;
  };
};

export enum TurnoversFieldEnum {
  date = 'date',
  currency = 'currency',
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  openingBalance = 'openingBalance',
  closingBalance = 'closingBalance',
  action = 'action',
}

export type TurnoversRow = {
  deposit: string;
  withdrawal: string;
  date: string;
  openingBalance: string;
  closingBalance: string;
  currency: string;
  action: string;
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Langs } from '@app/core/types';
import { ReactComponent as CancelDocIconSvg } from '@app/themes/default/assets/icons/back-cancel.svg';

import { ConfirmPopup } from '../ConfirmPopup';

const endFirstPartOfPhrase: { [key in Langs]: number } = {
  UA: 22,
  EN: 28,
};
const startSecondPartOfPhrase: { [key in Langs]: number } = {
  UA: 22,
  EN: 28,
};

interface CancelDocumentErrorPopupProps {
  onClose(): void;
}

export const CancelDocumentErrorPopup: React.FC<CancelDocumentErrorPopupProps> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const lang: Langs = i18n.language as Langs;

  return (
    <ConfirmPopup
      icon={<CancelDocIconSvg style={{ fill: 'rgba(184, 63, 77, 1)' }} />}
      questionText={t('cancelDocumentTitle')}
      onConfirm={onClose}
      onClose={onClose}
      classes={{
        questionText: {
          fontSize: '1.25rem',
          color: 'colors.gray800',
        },
      }}
      buttonsProps={{
        confirmBtnText: t('ok'),
      }}
      open
      showCancelBtn={false}
    >
      <Box mt={3}>
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '0.875rem',
            color: 'colors.gray500',
          }}
        >
          {t('cancelDocumentErrorMsg').slice(0, endFirstPartOfPhrase[lang])}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          sx={{
            color: 'colors.error',
            fontSize: '0.875rem',
            fontWeight: 400,
          }}
        >
          {t('cancelDocumentErrorMsg').slice(startSecondPartOfPhrase[lang], 100)}
        </Typography>
      </Box>
    </ConfirmPopup>
  );
};

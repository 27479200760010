import React, { useEffect, useState } from 'react';

import { Box, CardContent, FormLabel, Grid } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CurrencyAutocomplete } from '@app/core/components/CurrencyAutocomplete';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ActionFiltersButtons } from '@app/pages/loans/components/ActionFiltersButtons';
import { styles } from '@app/pages/loans/components/LoansFilter/styles';
import { LoansFilterField } from '@app/pages/loans/types';

interface LoansFilterProps {
  fullItems: boolean;
  namesFieldWithError?: string[];
  resetFormHandler?(): void;
}

export const LoansFilter: React.FC<LoansFilterProps> = ({ fullItems, namesFieldWithError, resetFormHandler }) => {
  const { t } = useTranslation();

  const nowDate = new Date();
  const agreementMinDate = new Date(2010, 0, 1);
  const agreementMaxDate = new Date(2045, 11, 31);
  const fromYear = 2010;
  const toYear = 2045;
  const maxDocumentDate = addDays(nowDate, 30);
  const minDocumentDate = subDays(nowDate, 30);
  const [minValuationDate, setMinValuationDate] = useState<Date>(new Date(2019, 0, 1));
  const [valuationDateSelectDisabled, setValuationDateSelectDisabled] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<Date>(nowDate);

  const { control } = useFormContext();

  const watchDateFrom = useWatch({
    control,
    name: LoansFilterField.agreementStartDateFrom,
  });

  useEffect(() => {
    if (watchDateFrom) {
      setMinValuationDate(watchDateFrom);
    }
  }, [watchDateFrom]);

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_startDate_from')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={LoansFilterField.agreementStartDateFrom}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: nowDate,
                    },
                    fromYear: 2010,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_startDate')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={LoansFilterField.agreementStartDateTo}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: nowDate,
                        },
                        fromYear: 2010,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_endDate_from')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={LoansFilterField.agreementEndDateFrom}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: agreementMaxDate,
                        },
                        fromYear,
                        toYear,
                      }}
                      inputProps={{ fullWidth: true }}
                    />
                  </Box>
                </Grid>
              </>
            ) : null}
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_endDate_to')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={LoansFilterField.agreementEndDateTo}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: agreementMaxDate,
                    },
                    fromYear,
                    toYear,
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_loanAgreementNumber')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={LoansFilterField.agreementNumber}
                  fullWidth
                  error={namesFieldWithError?.includes(LoansFilterField.agreementNumber)}
                />
              </Box>
            </Grid>
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_loanName')}</FormLabel>
                  <Box pt={2}>
                    <InputField name={LoansFilterField.productType} placeholder={t('loans_loanName')} fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsTable_currency')}</FormLabel>
                  <Box pt={2}>
                    {/*<CurrencyAutocomplete name={LoansFilterField.currency} options={data?.currencyCodes || []}  />*/}
                    <InputField name={LoansFilterField.currency} fullWidth placeholder={t('currency')} />
                  </Box>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
                  <Box pt={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansFilterField.amountFrom}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
                  <Box pt={2} pr={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={LoansFilterField.amountTo}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRate_from')}</FormLabel>
                  <Box pt={2} pr={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={LoansFilterField.interestRateFrom}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRate_to')}</FormLabel>
                  <Box pt={2} pr={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={LoansFilterField.interestRateTo}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={12}>
                  <Box pt={2} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

import * as React from 'react';

import { Grid } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

import { CurrencyCodeDto } from '@app/core/api';

import { CurrencyInfo } from './CurrencyInfo';
import { Checkbox } from '../../Checkbox';
import { styles } from '../styles';

type OptionItemProps = {
  currency: CurrencyCodeDto;
  selected: boolean;
};
const CurrencyOptionItem: React.FC<OptionItemProps> = ({ currency, selected }) => {
  return (
    <>
      <Checkbox checked={selected} sx={styles.checkBox} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <CurrencyInfo currency={currency} />
        </Grid>
      </Grid>
    </>
  );
};

export const CurrencyMenuOption = (
  option: CurrencyCodeDto,
  { selected }: AutocompleteRenderOptionState
): React.ReactNode => {
  return <CurrencyOptionItem currency={option} selected={selected} />;
};

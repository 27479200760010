import { SortOrder } from '@app/core/types';

export enum KeyStatus {
  active = 'active',
  closed = 'closed',
}

export enum SecureColumnEnum {
  secureColumnOpeningDate = 'secureColumnOpeningDate',
  secureColumnActivateDate = 'secureColumnActivateDate',
  secureColumnClosedDate = 'secureColumnClosedDate',
}

export type SecureKeyType = {
  id: string;
  type: string;
  openingDate: SecureColumnEnum;
  activateDate: SecureColumnEnum;
  closedDate: SecureColumnEnum;
  status: KeyStatus;
};

export type SecureKeyQueryConfigType = {
  sort: {
    order: SortOrder;
    column: SecureColumnEnum;
  };
};

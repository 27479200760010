import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, ApiError, messagesApiClient, SendToBankRequestDto, ResultResponseDto } from '@app/core/api';

const sendMessagesFn = async (sendToBankRequestDto: SendToBankRequestDto): Promise<ResultResponseDto> => {
  const result = await messagesApiClient.messagesControllerSendToBank(getClientId(), sendToBankRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't send messages to bank");
  }

  return result.data;
};

export const useSendMessagesToBank = (): UseMutationResult<ResultResponseDto, ApiError, SendToBankRequestDto> => {
  return useMutation<ResultResponseDto, ApiError, SendToBankRequestDto>(sendMessagesFn);
};

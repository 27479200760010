import React from 'react';

import { ReactComponent as CloudIconSvg } from '@app/themes/default/assets/icons/cloud.svg';

import { ConfirmPopup, ConfirmPopupProps } from '../ConfirmPopup';

export const ConfirmLeavePagePopup: React.FC<ConfirmPopupProps> = (props) => {
  return (
    <ConfirmPopup
      icon={<CloudIconSvg />}
      classes={{
        questionTextContainer: {
          width: '85%',
          textAlign: 'center',
        },
      }}
      {...props}
    />
  );
};

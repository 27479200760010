import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { isValidIBAN } from 'ibantools';

import { parseIBAN } from '../../utils';
import { Ellipsis, EllipsisProps } from '../Ellipsis';

export interface IBanTextProps extends Omit<EllipsisProps, 'styles'> {
  text: string;
  styles?: {
    root?: SxProps<Theme>;
    accountNumberPart1?: SxProps<Theme>;
    accountNumberPart2?: SxProps<Theme>;
  };
}

export const IBanText: React.FC<IBanTextProps> = ({ text, styles, ...props }) => {
  let part1 = text,
    part2 = '';
  if (isValidIBAN(text)) {
    const { accountPart1, accountPart2 } = parseIBAN(text);
    part1 = accountPart1;
    part2 = accountPart2;
  }

  return (
    <Ellipsis
      direction="left"
      text={
        <>
          <Box
            component="span"
            sx={{
              color: 'colors.gray400',
              ...(styles && styles.accountNumberPart1),
            }}
          >
            {part1}
          </Box>
          <Box
            component="span"
            sx={{
              color: 'colors.gray400',
              fontWeight: 'bold',
              ...(styles && styles.accountNumberPart2),
            }}
          >
            {part2}
          </Box>
        </>
      }
      styles={styles?.root}
      {...props}
    />
  );
};

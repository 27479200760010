import React, { useEffect, useState } from 'react';

import { Grid, Avatar, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EditIcon, NotificationOffIcon } from '@app/common/icons';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';
import { ReactComponent as CompanyLogoSvg } from '@app/themes/default/assets/icons/company-logo.svg';

import { EditLogoPopup } from './components/EditLogoPopup';
import { SettingsSwitcher } from './components/SettingsSwitcher';
import { styles } from './styles';
import { TINCode } from '../../constants';
import { LangSwitcher } from '../LangSwitcher';
import { Tooltip } from '../Tooltip';

type CompanyProps = {
  open: boolean;
  logoLink?: string;
  support?: React.ReactNode;
};

export const Company: React.FC<CompanyProps> = ({ open, logoLink, support }) => {
  const { userProfile, clientProfile, logo } = useAppSelector((state): ProfileState => state.profile);
  const [editIcon, setEditIcon] = useState<boolean>(false);
  const [openEditPopup, setOpenEditPopup] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (logo.length > 1) {
      setLogoImage(logo);
    } else {
      setLogoImage(logoLink);
    }
  }, [logo, logoLink]);

  const showEditIcon = () => {
    setEditIcon(!editIcon);
  };

  const showEditPopup = () => {
    setOpenEditPopup(true);
  };

  const onPopupCancelHandler = () => {
    setOpenEditPopup(false);
  };

  const { t } = useTranslation();

  return (
    <Grid container wrap="nowrap" sx={{ ...styles.root, ...(!open && { ...styles.close }) }}>
      <Grid item>
        <Box sx={{ ...styles.logo, ...(!open && { ...styles.logoSizeSmall }) }}>
          <EditIcon fontSize="small" sx={{ ...(editIcon ? styles.editIcon : styles.hideEditIcon) }} />
          <EditLogoPopup open={openEditPopup} onClose={onPopupCancelHandler} />
          {logoImage ? (
            <Avatar
              role="presentation"
              onClick={showEditPopup}
              onMouseEnter={showEditIcon}
              onMouseLeave={showEditIcon}
              src={logoImage}
              alt="logo"
              sx={styles.logoImage}
            />
          ) : (
            <Box sx={styles.logoIcon}>
              <CompanyLogoSvg
                style={{ width: '32px' }}
                onClick={showEditPopup}
                onMouseEnter={showEditIcon}
                onMouseLeave={showEditIcon}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ ...styles.wrapperAnimation, ...(open && { ...styles.fadeAnimate }) }}
      >
        {open ? (
          <Grid item sx={styles.infoContainer}>
            <Box sx={styles.name}>
              {userProfile?.lastName} {userProfile?.firstName}
            </Box>
            <Box sx={styles.companyName}>{clientProfile?.shortName}</Box>
            <Box sx={styles.edrpou}>
              {clientProfile?.privateEnterpreuner === TINCode ? t('TIN') : t('LEI')}: <span>{clientProfile?.lei}</span>
            </Box>
          </Grid>
        ) : null}
        <Grid
          item
          container
          sx={{ ...styles.controlPanel, ...(!open && { ...styles.fadeAnimate }) }}
          alignItems="center"
          justifyContent={open ? 'space-between' : 'space-around'}
          direction={open ? 'row' : 'column'}
        >
          {open ? <LangSwitcher /> : null}
          {support}
          <Tooltip arrow placement={open ? 'bottom' : 'right'} color="secondary" title={`${t('letters')}`}>
            <>
              {appConfig.enableNextFeatures ? (
                <Link to={RouteList.letters} style={styles.linkZeroSize}>
                  <Box sx={styles.statusIcon}>
                    <NotificationOffIcon />
                  </Box>
                </Link>
              ) : null}
            </>
          </Tooltip>
          <SettingsSwitcher menuOpen={open} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const styles = {
  root: {
    paddingRight: '4px',
  },
  fundRoot: {
    backgroundColor: 'colors.containerBackgroundColor',
    borderRadius: '8px',
    padding: '8px 16px',
    minWidth: '180px',
    textAlign: 'right',
  },
  fundAmount: {
    color: 'colors.primary600',
    fontWeight: 600,
  },
  fundCurrency: {
    color: 'colors.gray500',
    fontWeight: 600,
  },
  fundDescription: {
    color: 'colors.gray400',
  },
  totalText: {
    marginRight: '30px',
  },
  allBalances: {
    minHeight: '40px',
    backgroundColor: 'colors.containerBackgroundColor',
    borderRadius: '8px',
    padding: `8px 12px`,
    color: 'colors.primary600',
    '& span': {
      paddingRight: '4px',
      textDecoration: 'underline',
    },
  },
  arrowRight: {
    fill: 'colors.primary600',
  },
  settingsButton: {
    color: 'colors.gray500',
  },
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormatInputField,
  FormatInputFieldProps,
  FormatType,
} from '@app/core/components/Form/controls/FormatInputField';

export const Lei: React.FC<FormatInputFieldProps> = ({ name, defaultValue, ...props }) => {
  const { t } = useTranslation();
  return (
    <FormatInputField
      name={name}
      defaultValue={defaultValue || ''}
      type={FormatType.number}
      placeholder={t('paymentCreationLocalPayment_edrpouPlaceholder')}
      maxLength={10}
      {...props}
    />
  );
};

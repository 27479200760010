import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, paymentOrdersApiClient, UserDto } from '@app/core/api';

export const useGetAnotherSigners = (uuid?: string): UseQueryResult<Array<UserDto>, ApiError> => {
  return useQuery<Array<UserDto>, ApiError>(
    'payment_useGetAnotherSigners',
    async () => {
      const result = await paymentOrdersApiClient.paymentOrderSignControllerGetAnotherSigners(getClientId(), uuid);
      if (result.status !== 200) {
        throw new ApiError(result.request.status, "Can't get an another signers");
      }
      return result.data;
    },
    { enabled: false }
  );
};

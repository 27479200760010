import { addDays, isAfter, isBefore, isSameDay, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { date, object, SchemaOf, string } from 'yup';

import { CurrencyOperationsExchangeRateTypeEnum, CurrencyOperationsExchangeTypeEnum } from '@app/core/api';
import { ddMMYYYYFormat } from '@app/core/constants';
import { FormFieldName } from '@app/pages/exchange/components/form/types';

type SchemaValidationObject = {
  [FormFieldName.documentNumber]: string;
  [FormFieldName.documentDate]: Date;
  [FormFieldName.documentValueDate]?: Date;
  [FormFieldName.withdrawalAccount]: string;
  [FormFieldName.depositAccount]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.exchangeRateType]: string;
  [FormFieldName.currencyOperationReason]?: string;
  [FormFieldName.exchangeRate]?: string;
  [FormFieldName.temp_documentDate]: string;
  [FormFieldName.temp_documentValueDate]?: string;
};

export const validationSchema = (
  t: (key: string) => string,
  currencyExchangeType: CurrencyOperationsExchangeTypeEnum
): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [FormFieldName.documentNumber]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.withdrawalAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.depositAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.documentDate]: date().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.documentValueDate]: date(),
    [FormFieldName.amount]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(FormFieldName.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
        if (amount) {
          return parseFloat(amount) >= 0.01;
        }
        return false;
      }),
    [FormFieldName.exchangeRateType]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.currencyOperationReason]: string().test(
      FormFieldName.currencyOperationReason,
      t('paymentCreationLocalPayment_field_required'),
      (reason: string | undefined, context): boolean => {
        if (currencyExchangeType !== CurrencyOperationsExchangeTypeEnum.Sale) {
          return !isEmpty(reason);
        }
        return true;
      }
    ),
    [FormFieldName.exchangeRate]: string().when([FormFieldName.exchangeRateType], {
      is: CurrencyOperationsExchangeRateTypeEnum.Client,
      then: string()
        .required(t('paymentCreationLocalPayment_field_required'))
        .min(1, t('paymentCreationLocalPayment_field_required')),
    }),
    [FormFieldName.temp_documentDate]: string().required(t('paymentDocumentDate_correct_date')),
    [FormFieldName.temp_documentValueDate]: string().test(
      FormFieldName.temp_documentValueDate,
      t('paymentDocumentDate_correct_date'),
      (documentValueDateStr: string | undefined, context) => {
        if (documentValueDateStr) {
          const documentValueDate = parse(documentValueDateStr, ddMMYYYYFormat.format, new Date());
          if (isValid(documentValueDate)) {
            const documentDate = context.parent[FormFieldName.documentDate];
            return (
              isSameDay(documentDate, documentValueDate) ||
              (isAfter(documentValueDate, documentDate) && isBefore(documentValueDate, addDays(documentDate, 29)))
            );
          }
          return false;
        }

        return true;
      }
    ),
  });
};

import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DownloadIcon, InfoIcon, KeyIcon, PrintIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import {
  EmailDocumentTypeEnum,
  FrontLanguageEnum,
  GetOneOperationResponseDto,
  PaymentOrderStatusEnum,
  PaymentOrderTypeEnum,
} from '@app/core/api';
import { Checkbox } from '@app/core/components';
import { ShareDocumentButton } from '@app/core/components/ShareDocumentButton';
import { SignPaymentOrder } from '@app/core/components/SignPaymentOrder';
import { TextFieldMultiline } from '@app/core/components/TextFieldMultiline';
import { Tooltip } from '@app/core/components/Tooltip';
import { useGetCountryCodes } from '@app/core/hooks';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { DATE_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/payment/show/LocalPayment/PaymentView/styles';
import { appConfig } from '@app/src/config';

type PaymentViewProps = {
  paymentOrder: GetOneOperationResponseDto;
};

export const PaymentView: React.FC<PaymentViewProps> = ({ paymentOrder }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const { showLoader } = useUIState();
  const { details, amount, documentNumber, paymentReference, link, currency, uuid, documentType, status, valueDate } =
    paymentOrder;
  const senderName = details.senderAccount.name;
  const senderAccountNumber = details.senderAccount.accountNumber;
  const recipientBank = details.recipientAccount.bankName;
  const paymentDocumentDate = details.date;
  const paymentDocumentValueDate = valueDate;
  const recipientName = paymentOrder.details.recipientAccount.name;
  const recipientLei = paymentOrder.details.recipientAccount.LEI;
  const recipientPassport = paymentOrder.details.recipientAccount.passport;
  const recipientAccountNumber = paymentOrder.details.recipientAccount.accountNumber;
  const recipientNonLeiFlag = paymentOrder.details.recipientAccount.nonLeiFlag;

  const {
    data: sendToSignResponseData,
    mutate: mutateSendToSign,
    isLoading: isSendToSignLoading,
  } = useSendToSignPaymentOrder();

  const { data: countryData, mutate: mutateCountry } = useGetCountryCodes();

  useEffect(() => {
    mutateCountry({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n.language, mutateCountry]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeHandler = () => {
    navigate(-1);
  };
  const downloadHandler = () => {
    window.open(`${link}&format=download`, '_blank');
  };

  const onSignClickHandler = () => {
    setShowSignModal(true);
  };

  const onCloseSignModal = () => {
    setShowSignModal(false);
  };

  const onSuccessSignPayment = () => {
    setShowSignModal(false);
    navigate(-1);
  };

  const onSendToSignClickHandler = () => {
    mutateSendToSign(uuid);
  };

  useEffect(() => {
    showLoader(isSendToSignLoading);
  }, [isSendToSignLoading, showLoader]);

  useEffect(() => {
    if (sendToSignResponseData) {
      showLoader(false);
      navigate(-1);
    }
  });

  const showSignButtonByStatus: boolean =
    status === PaymentOrderStatusEnum.Processing || status === PaymentOrderStatusEnum.Rejected;

  const realSenderCountryCode = countryData?.rows.find(
    (country) => country.numeric === paymentOrder.realSenderCountryCode
  );
  const realRecipientCountryCode = countryData?.rows.find(
    (country) => country.numeric === paymentOrder.realRecipientCountryCode
  );

  return (
    <>
      <Grid sx={styles.tabBody}>
        <Grid container>
          <Grid container item xs={8}>
            <Typography variant="h6">
              <Grid container>
                <Typography sx={styles.header}>
                  {t(documentType)} № {documentNumber}
                </Typography>
              </Grid>
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={4}>
            {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
              <Box>
                <a href={link} target="_blank" rel="noreferrer">
                  <IconButton size="small">
                    <PrintIcon sx={styles.actionIcon} />
                  </IconButton>
                </a>
              </Box>
            ) : null}
            <Box ml={5}>
              <ShareDocumentButton
                link={link}
                styles={{
                  color: 'colors.primary500',
                }}
                type={EmailDocumentTypeEnum.PaymentOrder}
                uuid={uuid}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <CardContent>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField
                        variant="outlined"
                        defaultValue={paymentDocumentDate ? format(new Date(paymentDocumentDate), DATE_FORMAT) : ''}
                        disabled
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  {paymentDocumentValueDate ? (
                    <>
                      <Grid item xs={3}>
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
                        <Box pt={2} pl={1} pr={8}>
                          <TextField
                            variant="outlined"
                            defaultValue={
                              paymentDocumentValueDate ? format(new Date(paymentDocumentValueDate), DATE_FORMAT) : ''
                            }
                            disabled
                            fullWidth
                          />
                        </Box>
                      </Grid>
                      <Grid item container alignItems="center" xs={1}>
                        <Tooltip
                          color="primary"
                          title={t('paymentCreationLocalPayment_valuationDateTooltip')}
                          arrow
                          placement="right"
                        >
                          <Box mt={8}>
                            <InfoIcon fontSize="small" sx={styles.info} />
                          </Box>
                        </Tooltip>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccountName')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField variant="outlined" defaultValue={senderName} disabled fullWidth />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField variant="outlined" defaultValue={senderAccountNumber} disabled fullWidth />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {appConfig.enableNextFeatures ? (
                <>
                  <Box mb={6}>
                    <Box>
                      <FormControlLabel
                        disabled
                        label={<Typography variant="caption">{t('addRealSender')}</Typography>}
                        control={
                          <Checkbox defaultChecked={Boolean(paymentOrder.realSenderName)} sx={styles.checkboxField} />
                        }
                      />
                    </Box>
                    {paymentOrder.realSenderName ? (
                      <Box mt={6}>
                        <Grid container>
                          <Grid item xs={6}>
                            <FormLabel component="legend">{t('realSenderName')}</FormLabel>
                            <Box pt={2} pr={8}>
                              <TextField disabled fullWidth defaultValue={paymentOrder.realSenderName} />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container item justifyContent="space-between" alignItems="center">
                              <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                            </Grid>
                            <Box pt={2}>
                              <TextField fullWidth disabled defaultValue={paymentOrder.realSenderTIN} />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={6}>
                            <Box pt={4}>
                              <FormControlLabel
                                disabled
                                label={<Typography variant="caption">{t('realSenderNonResidentLabel')}</Typography>}
                                control={
                                  <Checkbox
                                    defaultChecked={paymentOrder.realSenderNonResidentFlag}
                                    sx={styles.checkboxField}
                                  />
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            {realSenderCountryCode ? (
                              <Box pt={5}>
                                <TextField fullWidth disabled defaultValue={realSenderCountryCode.name} />
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                </>
              ) : null}
              <Box mb={6}>
                <Box sx={styles.hr} mb={5} mt={5} />
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientName')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField variant="outlined" defaultValue={recipientName} disabled fullWidth />
                    </Box>
                  </Grid>
                  {recipientNonLeiFlag ? (
                    <Grid item xs={6}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_passport')}</FormLabel>
                      </Grid>
                      <Box pt={2}>
                        <TextField variant="outlined" defaultValue={recipientPassport} disabled fullWidth />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                      </Grid>
                      <Box pt={2}>
                        <TextField variant="outlined" defaultValue={recipientLei} disabled fullWidth />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_recipientAccount')}</FormLabel>
                    <Box pt={2} pr={8}>
                      <TextField variant="outlined" defaultValue={recipientAccountNumber} disabled fullWidth />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_bankName')}</FormLabel>
                    <Box pt={2}>
                      <TextField variant="outlined" defaultValue={recipientBank} disabled fullWidth />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {appConfig.enableNextFeatures ? (
                <>
                  <Box mb={6}>
                    <Box>
                      <FormControlLabel
                        disabled
                        label={<Typography variant="caption">{t('addRealRecipient')}</Typography>}
                        control={
                          <Checkbox
                            defaultChecked={Boolean(paymentOrder.realRecipientName)}
                            sx={styles.checkboxField}
                          />
                        }
                      />
                    </Box>
                    {paymentOrder.realSenderName ? (
                      <Box mt={6}>
                        <Grid container>
                          <Grid item xs={6}>
                            <FormLabel component="legend">{t('realRecipientName')}</FormLabel>
                            <Box pt={2} pr={8}>
                              <TextField disabled fullWidth defaultValue={paymentOrder.realRecipientName} />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container item justifyContent="space-between" alignItems="center">
                              <FormLabel component="legend">{t('paymentCreationLocalPayment_edrpou')}</FormLabel>
                            </Grid>
                            <Box pt={2}>
                              <TextField fullWidth disabled defaultValue={paymentOrder.realRecipientTIN} />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={6}>
                            <Box pt={4}>
                              <FormControlLabel
                                disabled
                                label={
                                  <Typography variant="caption">
                                    {t('paymentCreationLocalPayment_transactionPresentedLabel')}
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    defaultChecked={paymentOrder.realRecipientNonResidentFlag}
                                    sx={styles.checkboxField}
                                  />
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            {realRecipientCountryCode ? (
                              <Box pt={5}>
                                <TextField fullWidth disabled defaultValue={realRecipientCountryCode.name} />
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                </>
              ) : null}
              <Box mb={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                    <Grid container>
                      <Box pt={2}>
                        <TextField
                          variant="outlined"
                          defaultValue={parseFloat(`${amount}`).toFixed(2)}
                          disabled
                          fullWidth
                          InputProps={{ endAdornment: currency }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={6}>
                <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
                <Box pt={2}>
                  <TextFieldMultiline
                    sx={{
                      ...styles.textarea,
                      '& textarea': {
                        color: 'colors.gray300',
                      },
                    }}
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={2}
                    defaultValue={paymentReference}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={styles.cardAction}>
              <Button variant="contained" color="primary" onClick={closeHandler}>
                {t('close')}
              </Button>
              <Box sx={styles.cardAction}>
                {paymentOrder.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ...styles.submitBtn, marginRight: '28px' }}
                    endIcon={<DownloadIcon />}
                    onClick={downloadHandler}
                  >
                    {t('download')}
                  </Button>
                ) : null}
                {paymentOrder.actions.allowToSendToSign && !showSignButtonByStatus ? (
                  <Button variant="contained" color="primary" onClick={onSendToSignClickHandler}>
                    {t('paymentCreationFormControl_sendToSign')}
                  </Button>
                ) : null}
                {paymentOrder.actions.allowToSign && !showSignButtonByStatus ? (
                  <Button variant="contained" color="primary" onClick={onSignClickHandler}>
                    {t('paymentCreationFormControl_send')}
                    <KeyIcon sx={{ marginLeft: '8px' }} />
                  </Button>
                ) : null}
              </Box>
            </CardActions>
          </Grid>
        </Grid>
      </Grid>
      {showSignModal ? (
        <SignPaymentOrder
          uuid={uuid}
          documentToSign={JSON.stringify(paymentOrder)}
          onSuccessPopupCancel={onSuccessSignPayment}
          onClose={onCloseSignModal}
        />
      ) : null}
    </>
  );
};

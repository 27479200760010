import { addDays, isDate, parse } from 'date-fns';
import { date, object, SchemaOf, string } from 'yup';

import { isValidUAIBAN, parseJSON, DATE_FORMAT } from '@app/core/utils';
import { FormFieldName } from '@app/pages/payment/components/StatePaymentForm/types';

type SchemaValidationObject = {
  [FormFieldName.recipientAccount]: string;
  [FormFieldName.amount]: string;
  [FormFieldName.senderAccount]: string;
  [FormFieldName.documentDate]: Date;
  [FormFieldName.recipientName]: string;
  [FormFieldName.recipientLei]: string;
  [FormFieldName.paymentInfo]: string;
  [FormFieldName.elsePersonLei]?: string;
  [FormFieldName.paymentCode]: string;
};

type NewPaymentValidationSchemaProps = {
  t: (key: string) => string;
};

export const validationSchema = ({ t }: NewPaymentValidationSchemaProps): SchemaOf<SchemaValidationObject> => {
  const maxDocumentDate = addDays(new Date(), 30);
  const parseDateString = (value: string, originalValue: string) => {
    return isDate(originalValue) ? originalValue : parse(originalValue, DATE_FORMAT, new Date());
  };

  return object().shape({
    [FormFieldName.recipientAccount]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(
        FormFieldName.recipientAccount,
        t('paymentCreationLocalPayment_recipient_error_format'),
        (iban: string | undefined): boolean => {
          if (iban) {
            return isValidUAIBAN(iban);
          }
          return false;
        }
      )
      .test(
        FormFieldName.recipientAccount,
        t('paymentCreationLocalPayment_sender_recipient_identical_error'),
        (iban: string | undefined, context): boolean => {
          const { senderAccountNumber } = parseJSON(context.parent[FormFieldName.senderAccount]);
          return iban !== senderAccountNumber;
        }
      ),
    [FormFieldName.amount]: string()
      .min(1, t('paymentCreationLocalPayment_field_required'))
      .required(t('paymentCreationLocalPayment_field_required'))
      .test(FormFieldName.amount, t('amountMustBeMoreThen_001'), (amount: string | undefined): boolean => {
        if (amount) {
          return parseFloat(amount) >= 0.01;
        }
        return false;
      }),
    [FormFieldName.senderAccount]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.recipientName]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.recipientLei]: string()
      .required(t('paymentCreationLocalPayment_field_required'))
      .test('recipientLei', t('paymentCreationSatePayment_recipient_lei_error'), (val) => val?.length === 8),
    [FormFieldName.paymentInfo]: string().required(t('paymentCreationLocalPayment_field_required')),
    [FormFieldName.documentDate]: date()
      .required(t('paymentCreationLocalPayment_field_required'))
      .typeError(t('paymentDocumentDate_correct_date'))
      .transform(parseDateString)
      .max(maxDocumentDate, t('paymentDocumentDate_correct_date')),
    [FormFieldName.elsePersonLei]: string().when([FormFieldName.elsePersonFlag], {
      is: true,
      then: string().test('recipientLei', t('paymentCreationSatePayment_lei_error'), (val) => val?.length === 10),
    }),
    [FormFieldName.paymentCode]: string().required(t('paymentCreationLocalPayment_field_required')),
  });
};

export const styles = {
  root: {
    marginBottom: '20px',
  },
  amountText: {
    color: 'colors.success',
    fontWeight: 700,
  },
  withdrawal: {
    color: 'colors.error',
    fontWeight: 700,
  },
  deposit: {
    color: 'colors.success',
    fontWeight: 700,
  },
  documentNumber: {
    color: 'colors.gray500',
  },
  textFirst: {
    color: 'colors.gray700',
    fontWeight: 600,
  },
  accountContainer: {
    width: ' 100%',
    fontSize: '0.875rem',
    marginTop: '12px',
    paddingRight: '32px',
  },
  buttonContainer: {
    position: 'relative',
  },
  buttonIcon: {
    position: 'absolute',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
};

import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  AccountDto,
  ApiError,
  ApiErrorCodes,
  CurrencyEnum,
  GetAccountResponseDto,
  PaymentTemplateTypeEnum,
  UpdateTemplateRequestDto,
} from '@app/core/api';
import { BetweenMyAccountTemplateForm } from '@app/pages/paymentsTemplates/components/BetweenMyAccountTemplateForm';
import { LocalPaymentTemplateForm } from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm';
import {
  FormFieldErrorsName,
  TemplateDefaultValues,
  TemplateFormData,
} from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';
import { updatePaymentTemplateFn, useGetTemplateByUuid } from '@app/pages/paymentsTemplates/edit/query';

interface PageContentProps {
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const PageContent: React.FC<PageContentProps> = ({ onChange }) => {
  const [defaultValues, setDefaultValues] = useState<TemplateDefaultValues>({
    recipientNonLeiFlag: false,
  });
  const [errorFields, setErrorFields] = useState<
    Array<{ fieldName: FormFieldErrorsName; errorMessage: string }> | undefined
  >(undefined);
  const { t } = useTranslation();
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { uuid = 'no-uuid' } = useParams();
  const { data, refetch, isRefetching, isLoading, isFetching } = useGetTemplateByUuid(uuid);
  const templateLoading = isRefetching || isLoading || isFetching;
  useEffect(() => {
    if (uuid) {
      refetch();
    }
  }, [uuid, refetch]);

  useEffect(() => {
    if (data) {
      setDefaultValues({
        templateName: data.templateName,
        senderAccountId: data.accountId,
        recipientAccountName: data.recipientAccount.name,
        recipientAccountNumber: data.recipientAccount.accountNumber,
        recipientLei: data.recipientAccount.LEI,
        amount: data.amount,
        paymentReference: data.paymentReference,
        recipientBankName: data.recipientAccount.bankName,
        recipientNonLeiFlag: data.recipientAccount.nonLeiFlag,
      });
    }
  }, [data]);
  const {
    mutate: updateTemplateMutate,
    status: saveTemplateStatus,
    error,
  } = useMutation<string, ApiError, UpdateTemplateRequestDto>(updatePaymentTemplateFn);
  const updateTemplate = (templateData: TemplateFormData, templateType: PaymentTemplateTypeEnum) => {
    const {
      templateName,
      senderAccount,
      amount,
      recipientName,
      recipientAccount,
      recipientLei,
      nonResidentFlag,
      purpose: paymentReference,
      countryCode,
      recipientNonLeiFlag,
      vat,
      recipientPassport,
      idPassport,
    } = templateData;

    if (templateType === PaymentTemplateTypeEnum.General) {
      const { id: parsedSenderAccountId, accountNumber }: AccountDto = JSON.parse(senderAccount);
      updateTemplateMutate({
        uuid: uuid,
        senderAccountId: parsedSenderAccountId,
        senderAccount: accountNumber,
        templateName,
        amount: Number(amount),
        recipientName,
        recipientLei,
        recipientAccount,
        nonResidentFlag,
        currency: CurrencyEnum.Uah,
        paymentReference,
        countryCode,
        vat,
        recipientNonLeiFlag,
        recipientPassport: recipientPassport || idPassport,
        templateType,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
      });
    }

    if (templateType === PaymentTemplateTypeEnum.Own) {
      const parsedSenderAccount: GetAccountResponseDto = JSON.parse(senderAccount);
      const parsedRecipientAccount: GetAccountResponseDto = JSON.parse(recipientAccount);
      updateTemplateMutate({
        senderAccountId: parsedSenderAccount.account.id,
        uuid: uuid,
        senderAccount: parsedSenderAccount.account.accountNumber,
        templateName,
        amount: Number(amount),
        recipientAccount: parsedRecipientAccount.account.accountNumber,
        recipientAccountId: parsedRecipientAccount.account.id,
        currency: CurrencyEnum.Uah,
        paymentReference,
        templateType,
        realSenderNonLeiFlag: false,
        realRecipientNonLeiFlag: false,
      });
    }
  };

  useEffect(() => {
    showLoader(saveTemplateStatus === 'loading');
    if (saveTemplateStatus === 'success') {
      navigate(-1);
      notify({
        notifyProps: {
          title: t('template_save_success_title'),
          message: t('template_save_success_message'),
          severity: saveTemplateStatus,
        },
      });
    }
    if (saveTemplateStatus === 'error') {
      if (error?.code === ApiErrorCodes.DUPLICATE_TEMPLATE_NAME) {
        setErrorFields([
          { fieldName: FormFieldErrorsName.templateName, errorMessage: t('saveTemplateDuplicateNameErrorMessage') },
        ]);
      }
      notify({
        notifyProps: {
          title: t('template_save_error_title'),
          message: t('template_save_error_message'),
          severity: saveTemplateStatus,
        },
      });
    }
  }, [saveTemplateStatus, t, notify, navigate, showLoader, error?.code]);

  useEffect(() => {
    showLoader(templateLoading);
  }, [showLoader, templateLoading]);

  const onCancelHandler = () => {
    navigate(-1);
  };

  return (
    <Grid container>
      <Grid item xs={9}>
        {data?.templateType === PaymentTemplateTypeEnum.General ? (
          <LocalPaymentTemplateForm
            onChange={onChange}
            defaultValues={defaultValues}
            onCancelHandler={onCancelHandler}
            onSaveClick={updateTemplate}
            errorFields={errorFields}
          />
        ) : null}
        {data?.templateType === PaymentTemplateTypeEnum.Own ? (
          <BetweenMyAccountTemplateForm
            defaultValues={defaultValues}
            onSaveClick={updateTemplate}
            onCancelHandler={onCancelHandler}
            onChange={onChange}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import style from './style.module.scss';

interface LoaderProps {
  show?: boolean;
  styles?: SxProps<Theme>;
}

export const Loader: React.FC<LoaderProps> = ({ show = true, styles }) => {
  if (show) {
    return (
      <Box className={style.container} sx={styles}>
        <div className={style.loadingDefault}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </Box>
    );
  }
  return null;
};

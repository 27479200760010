export const useStyles = (sidebarOpen?: boolean) => ({
  // root: {
  //   backgroundColor: 'transparent',
  //   borderRadius: 0,
  //   height: '100vh',
  // },
  form: {
    width: '100%',
  },
  // title: {
  //   color: '#FFFFFF',
  // },
  // closeButton: {
  //   color: '#FFFFFF',
  // },
  selectInput: {
    color: '#FFFFFF',
    backgroundColor: 'colors.mainContentBackgroundColorMobile',
    '&:focus': {
      backgroundColor: 'colors.mainContentBackgroundColorMobile',
    },
  },
  // burgerButton: {
  //   color: '#FFFFFF',
  // },
  container: {
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  chip: {
    height: '32px',
    backgroundColor: 'colors.gray500',
    borderRadius: 'radius.control',
    border: `1px solid`,
    borderColor: 'colors.gray400',
    marginLeft: '4px',
    color: '#FFFFFF',
  },
  compactAccountLabel: {
    color: 'colors.gray300',
  },
  compactAccountValue: {
    color: '#FFFFFF',
    fontWeight: 600,
  },
  compactDateRangeLabel: {
    color: 'colors.gray300',
  },
  compactDateRangeValue: {
    color: '#FFFFFF',
    fontWeight: 600,
  },
  sheet: {
    width: '100vw',
    transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    marginLeft: sidebarOpen ? '272px' : 'auto',
  },

  filterContainer: {
    position: 'relative',
  },
  filter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transformOrigin: 'top',
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 100ms',
  },
  filterHide: {
    transformOrigin: 'bottom',
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s linear 100ms, opacity 100ms',
  },
  filterCompact: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  bottomAnchor: {
    height: '10px',
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
  titleText: {
    color: 'colors.gray400',
  },
  downloadButton: {
    color: 'colors.primary500',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  headerContent: {
    position: 'relative',
    height: '32px',
  },
});

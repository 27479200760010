import React, { useState } from 'react';

import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActionsPanel } from './components/ActionsPanel';
import { DocumentTableRow } from './components/DocumentTableRow';
import { styles } from './styles';
import { DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION, statementsTableRowsPerPageOptions } from '../../../../constants';
import { ComplexTable, TablePagination } from '../../../ComplexTable';
import { BodyLoader } from '../../components/BodyLoader';
import { DocumentsListProps } from '../../types';

export const DesktopDocumentsList: React.FC<DocumentsListProps> = ({
  documentStatus,
  isLoading,
  selectedDocuments,
  operations,
  pagination,
  onSelectAllClick,
  updateSelectedDocuments,
  onSignClick,
  onSendToSignClick,
  onMultiSignClick,
  onDeleteClick,
  onCopyClick,
  onAddToTemplatesClick,
  onPaginationChange,
  onCancelDocument,
  getStatementsMultipleFiles,
  onSignFromAnother,
  onMultiFromAnotherClick,
}) => {
  const { t } = useTranslation();
  const showPagination = Boolean(
    pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION && !isLoading
  );
  const [expandedId, setExpandedId] = useState<string | undefined>(undefined);

  const topContent = selectedDocuments?.length ? (
    <ActionsPanel
      selectedDocuments={selectedDocuments}
      rowsCount={operations?.length || 0}
      documentStatus={documentStatus}
      onSelectAllClick={onSelectAllClick}
      onSignClick={onSignClick}
      onDeleteClick={onDeleteClick}
      onCopyClick={onCopyClick}
      onAddToTemplatesClick={onAddToTemplatesClick}
      onMultiSignClick={onMultiSignClick}
      onSendToSignClick={onSendToSignClick}
      onCancelDocument={onCancelDocument}
      getStatementsMultipleFiles={getStatementsMultipleFiles}
      onSignFromAnotherClick={onSignFromAnother}
      onMultiSignFromAnotherClick={onMultiFromAnotherClick}
    />
  ) : null;

  const onOpenExtendedContent = (id: string) => {
    setExpandedId(id);
  };

  const BodyContent = (
    <>
      {operations ? (
        <>
          {operations.length ? (
            operations.map((document) => {
              const presentInSelected = Boolean(
                selectedDocuments.filter((selectedDocument) => {
                  return selectedDocument.id === document.id;
                }).length
              );

              return (
                <DocumentTableRow
                  key={document.id}
                  document={document}
                  selected={presentInSelected}
                  onCheck={(doc, selected) => {
                    updateSelectedDocuments(doc, selected);
                  }}
                  open={document.uuid === expandedId}
                  onOpenExtendedContent={onOpenExtendedContent}
                />
              );
            })
          ) : (
            <Box sx={styles.noOperationsContainer}>
              <Typography variant="body2">{t('noOperationsForThisAccount')}</Typography>
            </Box>
          )}
        </>
      ) : null}
    </>
  );

  const footerContent = (
    <>
      {pagination ? (
        <TablePagination
          totalCount={pagination.totalCount}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          labelRowsPerPage={t('showOnPageShort')}
          rowsPerPageOptions={statementsTableRowsPerPageOptions}
          onChange={onPaginationChange}
          show={showPagination}
        />
      ) : null}
    </>
  );

  return (
    <ComplexTable
      top={{
        content: topContent,
      }}
      body={{
        content: isLoading ? <BodyLoader /> : BodyContent,
        styles: {
          border: 'none',
        },
      }}
      footer={{
        content: footerContent,
      }}
    />
  );
};

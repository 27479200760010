import globalAxios from 'axios';

import { BASE_PATH, BaseAPI, COLLECTION_FORMATS } from '../client/base';
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  setBearerAuthToObject,
  setSearchParams,
  toPathString,
} from '../client/common';

export class SignFilesApi extends BaseAPI {
  public async request(clientId: string, file: Array<any>, fileIds: Array<string>, options: any = {}) {
    assertParamExists('signControllerSignFiles', 'clientId', clientId);
    assertParamExists('signControllerSignFiles', 'file', file);
    assertParamExists('signControllerSignFiles', 'fileIds', fileIds);
    const localVarPath = `/api/v1/documents/sign-batch`;
    const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
    let baseOptions;
    if (this.configuration) {
      baseOptions = this.configuration.baseOptions;
    }

    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;
    const localVarFormParams = new FormData();

    await setBearerAuthToObject(localVarHeaderParameter, this.configuration);

    if (clientId !== undefined) {
      localVarQueryParameter['clientId'] = clientId;
    }

    if (file) {
      file.forEach((element) => {
        localVarFormParams.append('file', element as any, 'file.pdf');
      });
    }

    if (fileIds) {
      localVarFormParams.append('fileIds', fileIds.join(COLLECTION_FORMATS.csv));
    }

    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

    setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
    const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
    localVarRequestOptions.data = localVarFormParams;

    const localVarAxiosArgs = {
      url: toPathString(localVarUrlObj),
      options: localVarRequestOptions,
    };

    const preparedRequest = createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, this.configuration);

    return preparedRequest(this.axios, this.basePath);
  }
}

export const styles = {
  root: {
    backgroundColor: 'colors.primary500',
    borderRadius: '8px',
    padding: '12px',
    marginBottom: '4px',
  },
  buttonRoot: {
    color: '#ffffff',
  },
  signButtonRoot: {
    color: '#ffffff',
    border: '1px solid #fff',
    background: 'none',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: '0.875rem',
  },
  totalAmountContainer: {
    textAlign: 'right',
  },
  amountText: {
    paddingRight: '4px',
    color: '#ffffff',
  },
  amountValue: {
    paddingRight: '4px',
    color: '#ffffff',
    fontWeight: 600,
  },
  menuItem: {
    borderBottom: '1px solid',
    borderColor: 'colors.gray50',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

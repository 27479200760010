import { SWIFTCommissionTypeEnum, SWIFTStatusEnum } from '@app/core/api';
import { AccountSelectType, DateRangeFromTo } from '@app/core/types';

export enum SwiftTableColumn {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  sender = 'sender',
  recipient = 'recipient',
  paymentReference = 'paymentReference',
  amount = 'amount',
  currency = 'currency',
  status = 'status',
  uuid = 'uuid',
}

export enum SwiftFilterFieldName {
  operationAccounts = 'operationAccounts',
  dateRange = 'dateRange',
  dateRangeSelect = 'dateRangeSelect',
  status = 'status',
  amountMin = 'amountMin',
  amountMax = 'amountMax',
  paymentReference = 'paymentReference',
  currency = 'currency',
  createdBy = 'createdBy',
  documentNumber = 'documentNumber',
  counterPartyAccountNumber = 'counterPartyAccountNumber',
  commissionType = 'commissionType',
}

export type SwiftFilterFormData = {
  [SwiftFilterFieldName.operationAccounts]: AccountSelectType[];
  [SwiftFilterFieldName.dateRange]: DateRangeFromTo;
  [SwiftFilterFieldName.dateRangeSelect]?: string;
  [SwiftFilterFieldName.status]?: SWIFTStatusEnum;
  [SwiftFilterFieldName.amountMin]?: string;
  [SwiftFilterFieldName.amountMax]?: string;
  [SwiftFilterFieldName.paymentReference]?: string;
  [SwiftFilterFieldName.currency]?: string;
  [SwiftFilterFieldName.documentNumber]?: string;
  [SwiftFilterFieldName.counterPartyAccountNumber]?: string;
  [SwiftFilterFieldName.commissionType]?: SWIFTCommissionTypeEnum;
  [SwiftFilterFieldName.createdBy]?: number;
};

export enum SwiftTypeEnum {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

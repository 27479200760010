import React, { useEffect, useState } from 'react';

import { Button, Grid, IconButton, Typography } from '@mui/material';

import { CrossIcon } from '@app/common/icons';
import { ReactComponent as MobileBgIconSvg } from '@app/themes/default/assets/icons/mobileModalBg.svg';

import { styles } from './styles';
import { ModalCommon } from '../Modal';

export const MobileAlertInfo: React.FC = () => {
  const [mobileModal, setMobileModal] = useState<boolean>(false);

  const handleShowMobileModal = () => {
    setMobileModal(false);
    sessionStorage.setItem('mobileAlert', 'true');
  };

  const checkMobile = () => {
    if (
      /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
      )
    ) {
      setMobileModal(true);
      sessionStorage.setItem('mobileAlert', 'true');
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem('mobileAlert')) {
      checkMobile();
    }
  }, []);

  return (
    <ModalCommon open={mobileModal}>
      <Grid container direction="column" wrap="nowrap" sx={styles.paper}>
        <MobileBgIconSvg
          style={{
            position: 'absolute',
            left: 0,
            top: '14px',
          }}
        />
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={handleShowMobileModal}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Typography variant="body2" sx={styles.infoText}>
          Web-кабінет створений для роботи на комп’ютері. Рекомендуємо виконати задумане на комп’ютері.
        </Typography>
        <Grid justifyContent="center" container>
          <Button
            variant="contained"
            size="small"
            color="primary"
            sx={styles.sendButton}
            onClick={handleShowMobileModal}
          >
            Зрозуміло
          </Button>
        </Grid>
      </Grid>
    </ModalCommon>
  );
};

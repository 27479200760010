import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useReactTable, getCoreRowModel, SortingState, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { LoanDto } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { SortingRule } from '@app/core/types';
import { makeTableColumns } from '@app/pages/loansLines/show/components/TranchesTable/columns';
import { styles } from '@app/pages/loansLines/show/components/TranchesTable/styles';

interface TranchesTableProps {
  data: LoanDto[];
  onSort?(rule: SortingRule): void;
}

export const TranchesTable: React.FC<TranchesTableProps> = ({ data, onSort }) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
      }),
    ];
  }, [t]);

  const table = useReactTable<LoanDto>({
    columns,
    data,
    state: {
      sorting,
    },
    manualPagination: true,
    enableSorting: data.length > 0,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  const buildBodyContent = (): React.ReactNode => {
    if (!data.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row, i) => {
      return <TableRowContent<LoanDto> key={row.id} row={row} styles={styles} />;
    });
  };

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<LoanDto> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
    />
  );
};

import { object, SchemaOf, array } from 'yup';

import { FormFieldName } from '@app/pages/dashboard/view/PageContent/Statements/types';

type SchemaValidationObject = {
  [FormFieldName.operationAccounts]: string[];
};

export const validationSchema = (t: (key: string) => string): SchemaOf<SchemaValidationObject> => {
  return object().shape({
    [FormFieldName.operationAccounts]: array().min(1, t('dashboard_statements_select_account_error')),
  });
};

import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CountryCodeDto, FrontLanguageEnum } from '@app/core/api';
import { AutocompleteField } from '@app/core/components/Form/controls/AutocompleteField';
import { Select } from '@app/core/components/Select';
import { useGetCountryCodes } from '@app/core/hooks/useGetCountryCode';
type CountrySelectProps = {
  name: string;
  defaultValue?: string;
  placeholder?: string;
};

export const CountrySelect: React.FC<CountrySelectProps> = ({ name, defaultValue, placeholder }) => {
  const { i18n } = useTranslation();
  const { data, mutate } = useGetCountryCodes();

  useEffect(() => {
    mutate({ language: i18n.language as unknown as FrontLanguageEnum });
  }, [i18n.language, mutate]);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (data?.rows && defaultValue) {
      const selectedCountry = data.rows.find((country) => country.numeric === defaultValue);
      if (selectedCountry) {
        setValue(name, selectedCountry);
      }
    }
  }, [data, defaultValue, name, setValue]);

  const getOptionLabel = (option: CountryCodeDto): string => {
    return option ? option.name : '';
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    country: CountryCodeDto,
    state: AutocompleteRenderOptionState
  ): React.ReactNode => {
    return (
      <li {...props} key={country.numeric}>
        <Typography variant="caption">{` ${country.name} (${country.alpha3}) ${country.numeric}`}</Typography>
      </li>
    );
  };

  const filterOptions = createFilterOptions<CountryCodeDto>({
    stringify: (option) => option.name + option.alpha3 + option.numeric,
  });

  return (
    <>
      {data ? (
        <AutocompleteField<CountryCodeDto, false, false, false>
          name={name}
          selectOnFocus={false}
          options={data.rows}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          isOptionEqualToValue={(countryCodeDto1, countryCodeDto2) => {
            return countryCodeDto1.numeric === countryCodeDto2.numeric;
          }}
          filterOptions={filterOptions}
          textFieldProps={{ placeholder: placeholder }}
        />
      ) : (
        <Select disabled variant="outlined" fullWidth>
          <div>Loading</div>
        </Select>
      )}
    </>
  );
};

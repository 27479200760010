import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import {
  getClientId,
  ApiError,
  categoriesApiClient,
  GetCategoriesListRequestDto,
  GetCategoriesListResponseDto,
} from '@app/core/api';

const getCategoriesControllerListFn = async (
  getCategoriesListRequestDto: GetCategoriesListRequestDto
): Promise<GetCategoriesListResponseDto> => {
  const result = await categoriesApiClient.categoriesControllerGetList(getClientId(), getCategoriesListRequestDto);

  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get categories list");
  }

  return result.data;
};

export const useGetCategoriesList = (): UseMutationResult<
  GetCategoriesListResponseDto,
  ApiError,
  GetCategoriesListRequestDto
> => {
  return useMutation<GetCategoriesListResponseDto, ApiError, GetCategoriesListRequestDto>(
    getCategoriesControllerListFn
  );
};

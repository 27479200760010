import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { MessageStatus } from '@app/core/api';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';

export interface SubMenuProps {
  items: MenuItem<MessageStatus | undefined>[];
  selectedItem: MessageStatus | undefined;
  onChange(activeTab: MessageStatus | undefined): void;
}

export const SubMenu: React.FC<SubMenuProps> = ({ items, selectedItem, onChange }) => {
  return (
    <Grid container alignItems="center">
      {items.map((item) => {
        const selected = selectedItem === item.value;
        return (
          <Box mr={8} key={item.id}>
            <Typography
              sx={{
                userSelect: 'none',
                cursor: 'pointer',
                color: 'colors.gray400',
                fontWeight: 600,
                ...(selected && {
                  color: 'colors.primary600',
                }),
              }}
              variant={selected ? 'body2' : 'caption'}
              component="span"
              onClick={() => onChange(item.value)}
            >
              {item.labelText}
            </Typography>
          </Box>
        );
      })}
    </Grid>
  );
};

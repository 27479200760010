import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon } from '@app/common/icons';
import { UserDto, UserDtoSystemAccessEnum } from '@app/core/api';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { SelectOptionType } from '@app/core/components/Select';
import { UserSignature } from '@app/core/types';
import {
  CompanyUsersEdit,
  CompanyUsersTableColumnsEnum,
  CompanyUsersFields,
} from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/types';
import { UsersAccessPermissionsSelect } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UserFormComponents/UsersAccessPermissionsSelect';
import { UsersSignatureSelect } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UserFormComponents/UsersSignatureSelect';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersModal/styles';
import { validationSchema } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersModal/validationShema';
import { getName } from '@app/pages/companyProfile/desktop/utils';

export interface UsersModalProps {
  user: UserDto;
  show: boolean;
  showDeleteDialog?(): void;
  handleShow?(): void;
}

export const UsersModal: React.FC<UsersModalProps> = ({ user, show, showDeleteDialog, handleShow }) => {
  const { t } = useTranslation();
  const methods = useForm<CompanyUsersEdit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
  });
  const { handleSubmit, formState, getValues, control } = methods;
  const { fullName, phoneNumber, email, signingAuthority, systemAccess } = user;
  const [permissions, setPermissions] = useState<UserDtoSystemAccessEnum>(systemAccess);
  const { mutate } = useMutation(async () => {});
  const { errors } = formState;

  const signatureListItems: SelectOptionType[] = [
    {
      id: t(`user_${UserSignature.signed}`),
      name: t(`user_${UserSignature.signed}`),
      value: UserSignature.signed,
    },
    {
      id: t(`user_${UserSignature.unsigned}`),
      name: t(`user_${UserSignature.unsigned}`),
      value: UserSignature.unsigned,
    },
  ];

  const watchPermissionsFrom = useWatch({
    control,
    name: CompanyUsersFields.accessPermissions,
  });

  useEffect(() => {
    if (watchPermissionsFrom) {
      setPermissions(watchPermissionsFrom);
    }
  }, [watchPermissionsFrom]);

  const closeConfigModal = () => {
    if (handleShow) {
      handleShow();
    }
  };

  const onSubmit = () => {
    if (Object.keys(errors).length === 0) {
      const values = getValues();
      mutate();
      closeConfigModal();
    }
  };

  return (
    <ModalCommon open={show}>
      <Grid container direction="column" wrap="nowrap" sx={styles.paper}>
        <Box pb={3}>
          <Grid justifyContent="space-between" alignItems="center" container>
            <Typography component="span" variant="h6" sx={styles.caption}>
              {t('user_permissions_edit')}
            </Typography>
            <IconButton size="small" onClick={closeConfigModal}>
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={styles.wrapHeight}>
            <Grid item container justifyContent="space-between" spacing={5}>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('lastName')}
                </Typography>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={CompanyUsersFields.surname}
                    disabled={signingAuthority === 1}
                    placeholder={t('lastName')}
                    defaultValue={getName(fullName, 0)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('firstName')}
                </Typography>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={CompanyUsersFields.fullName}
                    disabled={signingAuthority === 1}
                    placeholder={t('firstName')}
                    defaultValue={getName(fullName, 1)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('userMiddleName')}
                </Typography>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={CompanyUsersFields.middleName}
                    disabled={signingAuthority === 1}
                    placeholder={t('userMiddleName')}
                    defaultValue={getName(fullName, 2)}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('userPhone')}
                </Typography>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={CompanyUsersFields.phone}
                    placeholder={t('userPhone')}
                    disabled={signingAuthority === 1}
                    defaultValue={phoneNumber}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('userEmail')}
                </Typography>
                <Box pt={1}>
                  <InputField
                    fullWidth
                    name={CompanyUsersFields.email}
                    placeholder={t('userEmail')}
                    defaultValue={email}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('user_signature')}
                </Typography>
                <Box pt={1}>
                  <UsersSignatureSelect
                    name={CompanyUsersFields.signature}
                    menuItems={signatureListItems}
                    disabled
                    defaultValue={signingAuthority}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={styles.labelInput}>
                  {t('user_accessPermissions')}
                </Typography>
                <Box pt={1}>
                  <UsersAccessPermissionsSelect
                    name={CompanyUsersTableColumnsEnum.systemAccess}
                    defaultValue={systemAccess}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={12}>
                <Box mt={2}>
                  <CheckboxField
                    label={<Typography variant="caption">{t('user_passive_operations')}</Typography>}
                    name={CompanyUsersFields.userPassiveOperations}
                    defaultChecked
                    disabledValue
                    sx={styles.transactionPresentedLabel}
                  />
                </Box>
              </Grid>
              {permissions === UserDtoSystemAccessEnum.MonitoringRole ? null : (
                <Grid item xs={12}>
                  <CheckboxField
                    label={<Typography variant="caption">{t('user_doc_edit')}</Typography>}
                    name={CompanyUsersFields.userDocEdit}
                    defaultValue="true"
                    defaultChecked
                    disabledValue
                    sx={styles.transactionPresentedLabel}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CheckboxField
                  label={<Typography variant="caption">{t('user_doc_view')}</Typography>}
                  name={CompanyUsersFields.userDocView}
                  defaultValue="true"
                  defaultChecked
                  disabledValue
                  sx={styles.transactionPresentedLabel}
                />
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={5}>
                {signingAuthority === 0 ? (
                  <Grid item>
                    <Button variant="outlined" size="small" color="primary" onClick={showDeleteDialog}>
                      {t('user_delete')}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item>
                  <Button variant="contained" size="small" color="primary" onClick={onSubmit}>
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </ModalCommon>
  );
};

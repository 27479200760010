export const styles = {
  form: {
    width: '100%',
  },
  flexbox: {
    display: 'flex',
  },
  balance: {
    textAlign: 'right',
    color: 'colors.gray200',
  },
  balanceText: {
    fontSize: '0.75rem',
  },
  bookmarkBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bookmarkText: {
    fontSize: '1rem',
    color: 'colors.gray300',
    marginRight: '14px',
  },
  tabBody: {
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
    marginTop: '20px',
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  submitBtn: {
    marginRight: '28px',
    background: 'none',
  },
  info: {
    color: 'colors.primary500',
  },
  signButton: {
    marginLeft: '28px',
  },
  starIcon: {
    marginLeft: '4px',
  },
  cancelBtn: {
    marginRight: '28px',
    background: 'none',
  },
};

import React from 'react';

import { Box, Grid, IconButton, Link, Typography, Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PaperIcon } from '@app/common/icons';
import { ModalPopupCommon } from '@app/core/components';
import { ReactComponent as CrossIcon } from '@app/themes/default/assets/icons/16x16/cross.svg';
import appStoreIcon from '@app/themes/default/assets/icons/appStore.svg';
import linuxSvg from '@app/themes/default/assets/icons/linux.svg';
import windowsIcon from '@app/themes/default/assets/icons/windows.svg';

import { styles } from './style';

interface UmcaPopupProps {
  errorMessage: string;
  onClose(): void;
}

export const UmcaPopup: React.FC<UmcaPopupProps> = ({ onClose, errorMessage }) => {
  const { t } = useTranslation();

  return (
    <ModalPopupCommon open questionPaper>
      <>
        <Grid justifyContent="space-between" alignItems="flex-start" container wrap="nowrap">
          <Typography component="div" variant="body1" sx={styles.title}>
            {t('umcaPopupTitle')}
          </Typography>
          <Grid item xs={1}>
            <IconButton aria-label="close" size="small" onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container direction="column" sx={styles.item}>
          <Alert severity="error" sx={styles.alertContainer}>
            <Grid container direction="column" alignItems="center">
              <AlertTitle>{t('error')}</AlertTitle>
              <Typography variant="body2" sx={styles.text} align="left">
                {errorMessage}
              </Typography>
            </Grid>
          </Alert>
        </Grid>

        <Grid container direction="column" sx={styles.item}>
          <Grid item container justifyContent="space-between">
            <Grid item xs={6}>
              <Box mt={4}>
                <Grid container alignItems="center">
                  <img src={windowsIcon} alt="" />
                  <Typography variant="body2" sx={styles.link}>
                    <Link
                      href="https://storage.googleapis.com/dgb-web-assets/umca/UmCAService-3.9.5-full.exe"
                      target="_blank"
                      variant="body2"
                    >
                      UmCA Windows
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={4} ml={10}>
                <Grid container alignItems="center">
                  <img src={appStoreIcon} alt="" />
                  <Typography variant="body2" sx={styles.link}>
                    <Link
                      href="https://storage.googleapis.com/dgb-web-assets/umca/UmCAService-3.9.5-full.pkg"
                      target="_blank"
                      variant="body2"
                    >
                      UmCA MacOS
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item xs={6}>
              <Box mt={4}>
                <Grid container alignItems="center">
                  <img src={linuxSvg} alt="" />
                  <Typography variant="body2" sx={styles.link}>
                    <Link
                      href="https://storage.googleapis.com/dgb-web-assets/umca/umcad_3.9.5-full_amd64.deb"
                      target="_blank"
                      variant="body2"
                    >
                      UmCA Linux
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={4} ml={10}>
                <Grid container alignItems="center">
                  <PaperIcon fontSize="small" sx={styles.icon} />
                  <Typography variant="body2" sx={styles.link}>
                    <Link
                      href="https://storage.googleapis.com/dgb-web-assets/umca/instruction.pdf"
                      target="_blank"
                      variant="body2"
                    >
                      {t('instruction')}
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    </ModalPopupCommon>
  );
};

import React from 'react';

import { Theme, SxProps } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OperationsTypeEnum } from '../../../../types';
import { SelectOptionType } from '../../../Select';
import { SelectField } from '../SelectField';

export type OperationsTypeSelectFieldProps = {
  name: string;
  styles?: SxProps<Theme>;
};

export const OperationsTypeSelectField: React.FC<OperationsTypeSelectFieldProps> = ({ name, styles }) => {
  const { t } = useTranslation();
  const operationsListItems: SelectOptionType[] = [
    {
      id: 'not_selected',
      name: t('not_selected'),
      value: '',
    },
    {
      id: OperationsTypeEnum.deposit,
      name: t(`statementsFilter_${OperationsTypeEnum.deposit}`),
      value: OperationsTypeEnum.deposit,
    },
    {
      id: OperationsTypeEnum.withdrawal,
      name: t(`statementsFilter_${OperationsTypeEnum.withdrawal}`),
      value: OperationsTypeEnum.withdrawal,
    },
  ];

  return <SelectField name={name} options={operationsListItems} sx={styles} defaultValue="" />;
};

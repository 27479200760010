import { TableColumnConfig, Operation, OperationsTypeEnum } from '@app/core/types';

import { formatAmount } from '../../../../../../utils';
import { getColumnVisibility } from '../../../../../ComplexTable';
import { OperationFieldEnum, OperationRow } from '../../types';

export const buildTableRowsData = (operations: Operation[]): OperationRow[] => {
  return operations.map((operation) => {
    const { senderAccount, recipientAccount } = operation.details;
    const operationType = operation.type;

    const counterpartyName = operationType === OperationsTypeEnum.deposit ? senderAccount.name : recipientAccount.name;

    const counterpartyAccount =
      operationType === OperationsTypeEnum.deposit ? senderAccount.accountNumber : recipientAccount.accountNumber;

    const counterpartyLei =
      operationType === OperationsTypeEnum.deposit
        ? senderAccount.LEI || senderAccount.passportId || ''
        : recipientAccount.LEI || recipientAccount.passportId || '';

    const counterpartyBankCode = String(
      operationType === OperationsTypeEnum.deposit ? senderAccount.bankCode : recipientAccount.bankCode
    );

    const counterpartyBank =
      operationType === OperationsTypeEnum.deposit ? senderAccount.bankName : recipientAccount.bankName;

    return {
      documentNumber: operation.documentNumber,
      executionDate: operation.date,
      documentDate: operation.details.date,
      counterpartyName,
      counterpartyAccount,
      counterpartyLei,
      counterpartyBankCode,
      counterpartyBank,
      paymentReference: operation.paymentReference,
      accountNumber: operation.account.number,
      currency: operation.account.currency,
      amount: `${formatAmount(operation.amount)}`,
      credit: operationType === OperationsTypeEnum.deposit ? `${formatAmount(operation.amount)}` : '',
      debit: operationType === OperationsTypeEnum.withdrawal ? `${formatAmount(operation.amount)}` : '',
      status: operation.status,
      arcDate: operation.arcDate,
      operation,
    };
  });
};

// special "getColumnVisibility" for statements. In another case just use common getColumnVisibility.
export const getStatementsColumnVisibility = (columnsConfig: TableColumnConfig[]) => ({
  ...Object.values(OperationFieldEnum).reduce((acc: Record<string, boolean>, item: string) => {
    acc[item] = false;
    return acc;
  }, {}),
  ...getColumnVisibility(columnsConfig),
});

import React from 'react';

import { SxProps, Theme } from '@mui/material';
import { flexRender, Row } from '@tanstack/react-table';

import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { bodyCellProps } from '../helpers';

interface TableRowContentProps<T> {
  id?: string;
  row: Row<T>;
  styles: { [name: string]: SxProps<Theme> };
  ExpandedComponent?: React.FC<{ data: T }>;
  resizable?: boolean;
}

export const TableRowContent = <T,>({
  id,
  row,
  styles,
  resizable = false,
  ExpandedComponent,
}: TableRowContentProps<T>): JSX.Element => {
  const { getIsExpanded } = row;
  const isExpanded = getIsExpanded();
  return (
    <>
      <TableRow hover role="checkbox" id={id} selected={isExpanded}>
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            {...bodyCellProps({ cell, styles })}
            style={{
              width: resizable ? cell.column.getSize() : undefined,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
      {isExpanded && ExpandedComponent ? <ExpandedComponent data={row.original} /> : null}
    </>
  );
};

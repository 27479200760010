import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { isEmpty, isEqual } from 'lodash-es';
import { parse } from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AccountsWithSearchSelectField } from '@app/core/components/Form/controls/AccountsWithSearchSelectField';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { StatementsFilterField } from '@app/core/components/Statements/types';
import { OperationsTypeEnum } from '@app/core/types';
import { DATE_FORMAT, formatISODateString } from '@app/core/utils';
import { styles } from '@app/pages/operations/mobile/styles';
import { OperationsFilterFormData } from '@app/pages/operations/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

interface MobileOperationsFiltersProps {
  compact: boolean;
  defaultValues: DefaultValues<OperationsFilterFormData>;
  onFilterChange(filterData: OperationsFilterFormData): void;
  onOperationTypeChange(operationType: OperationsTypeEnum): void;
  onCompactFilterClick(): void;
}

export const MobileOperationsFilters: React.FC<MobileOperationsFiltersProps> = ({
  compact,
  defaultValues,
  onFilterChange,
  onCompactFilterClick,
  onOperationTypeChange,
}) => {
  const { uahAccounts } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentFilterValuesRef = useRef<OperationsFilterFormData>();
  const [defaultSelectedAccountsId, setDefaultSelectedAccountsId] = useState<string[]>([]);
  const urlQueryRef = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    if (uahAccounts) {
      const { operationAccounts: operationAccountsId } = parse(location.search);
      if (operationAccountsId) {
        setDefaultSelectedAccountsId((operationAccountsId as string).split(','));
      }
    }
  }, [uahAccounts]);

  const newLocalOperationsTabsItems: MenuItem<OperationsTypeEnum>[] = [
    {
      id: 1,
      labelText: t('outgoing'),
      value: OperationsTypeEnum.withdrawal,
    },
    {
      id: 2,
      labelText: t('incoming'),
      value: OperationsTypeEnum.deposit,
    },
  ];

  const methods = useForm<OperationsFilterFormData>({ mode: 'onChange', defaultValues });

  const { getValues, formState, watch } = methods;

  const watchFields = watch();
  useEffect(() => {
    if (watchFields && formState.isDirty) {
      const { dateRange, operationAccounts, ...otherValues } = getValues();
      const fieldsArr = Object.entries(otherValues).filter(([key, value]) => {
        return !isEmpty(value);
      });
      const fieldsForUrl = Object.fromEntries(fieldsArr);
      const urlQueryObj: { [key: string]: any } = { ...fieldsForUrl };

      if (dateRange) {
        urlQueryObj['dateFrom'] = dateRange.from.toISOString();
        urlQueryObj['dateTo'] = dateRange.to.toISOString();
      }
      if (operationAccounts) {
        urlQueryObj['operationAccounts'] = operationAccounts.map((account) => account.account.id).toString();
      }
      if (!isEqual(urlQueryObj, urlQueryRef.current)) {
        urlQueryRef.current = urlQueryObj;
        setSearchParams(urlQueryObj, { replace: true });
      }
    }
  }, [formState.isDirty, getValues, setSearchParams, watchFields]);

  const watchFilters = watch([StatementsFilterField.operationAccounts, StatementsFilterField.dateRange]);

  useEffect(() => {
    const { operationAccounts, dateRange } = getValues();
    if (
      operationAccounts &&
      operationAccounts.length &&
      dateRange &&
      dateRange.from &&
      dateRange.to &&
      !isEqual(currentFilterValuesRef.current, getValues())
    ) {
      currentFilterValuesRef.current = getValues();
      onFilterChange(getValues());
    }
  }, [getValues, onFilterChange, watchFilters]);

  const handleSelectAccountsLength = (value: []) => value.length > 0 || t('statementsFilter_errorAmountField');

  const { operationAccounts, dateRange } = getValues();
  const shortAccounts = operationAccounts?.length
    ? operationAccounts.length > 1
      ? `${t('mobileStatementsFilter_selectedAccountsNumber')} ${operationAccounts.length}`
      : operationAccounts[0].account.accountNumber
    : t('accountsSelect_select_placeholder');

  return (
    <Box sx={styles.filterContainer}>
      <FormProvider {...methods}>
        <form role="presentation" style={styles.form}>
          <Box sx={{ ...styles.filter, ...(compact && styles.filterHide) }}>
            <Box mt={4} mb={4}>
              <AccountsWithSearchSelectField
                name={StatementsFilterField.operationAccounts}
                defaultSelectedAccountsId={defaultSelectedAccountsId}
                accounts={uahAccounts}
                selectProps={{ sx: { '& .MuiSelect-select': styles.selectInput } }}
              />
            </Box>
            <Box>
              <DateRangeSelectField
                formSelectName={StatementsFilterField.dateRangeSelect}
                formDateRangeName={StatementsFilterField.dateRange}
                selectProps={{ sx: { select: styles.selectInput } }}
              />
            </Box>

            <Box mt={4}>
              <HorizontalMenu
                onChange={onOperationTypeChange}
                menuItems={newLocalOperationsTabsItems}
                styles={{
                  itemDefault: {
                    userSelect: 'none',
                    cursor: 'pointer',
                    color: 'colors.gray200',
                    fontWeight: 600,
                    marginRight: '24px',
                    fontSize: '0.85rem',
                  },
                  itemSelected: {
                    userSelect: 'none',
                    cursor: 'pointer',
                    color: 'colors.primary400',
                    fontWeight: 600,
                    marginRight: '24px',
                    fontSize: '0.85rem',
                  },
                }}
              />
            </Box>
          </Box>
        </form>
      </FormProvider>

      {compact ? (
        <>
          <Grid container wrap="nowrap" direction="column" onClick={onCompactFilterClick} role="presentation">
            <Grid item>
              <Typography variant="caption" component="span" sx={styles.compactAccountLabel}>
                {t('account')}:{' '}
              </Typography>{' '}
              <Typography variant="body2" component="span" sx={styles.compactAccountValue}>
                {shortAccounts}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" component="span" sx={styles.compactDateRangeLabel}>
                {t('statementsFilter_dateRange')}:{' '}
              </Typography>{' '}
              <Typography variant="body2" component="span" sx={styles.compactDateRangeValue}>
                {dateRange && dateRange.from && dateRange.to
                  ? `${formatISODateString(dateRange.from.toISOString(), DATE_FORMAT)} - ${formatISODateString(
                      dateRange.to.toISOString(),
                      DATE_FORMAT
                    )}`
                  : t('empty_date_filter')}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
};

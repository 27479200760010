import React, { useEffect, useRef, useState } from 'react';

import { isEmpty, isEqual } from 'lodash-es';
import { parse } from 'query-string';
import { useWatch, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { StatementsFilterFormData } from '@app/core/components/Statements/types';

interface FiltersWatchProps {
  formMethods: UseFormReturn<StatementsFilterFormData>;
  onFilterReady(): void;
}

export const StatementsFilterWatch: React.FC<FiltersWatchProps> = ({ formMethods, onFilterReady }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoSubmitForm, setAutoSubmitForm] = useState<boolean>(false);
  const urlQueryRef = useRef<{ [key: string]: string }>({});
  const watchedFields = useWatch({ control: formMethods.control });

  useEffect(() => {
    const urlQueryParams = parse(location.search);
    if (
      urlQueryParams &&
      Object.entries(urlQueryParams).filter(([key, value]) => {
        return !isEmpty(value);
      }).length > 0
    ) {
      setAutoSubmitForm(true);
    }
  }, []);

  useEffect(() => {
    if (watchedFields && formMethods.formState.isDirty) {
      const { dateRange, operationAccounts, ...otherValues } = formMethods.getValues();
      const fieldsArr = Object.entries(otherValues).filter(([key, value]) => {
        return !isEmpty(value);
      });
      const fieldsForUrl = Object.fromEntries(fieldsArr);
      const urlQueryObj: { [key: string]: any } = { ...fieldsForUrl };

      if (dateRange) {
        urlQueryObj['dateFrom'] = dateRange.from.toISOString();
        urlQueryObj['dateTo'] = dateRange.to.toISOString();
      }
      if (operationAccounts) {
        urlQueryObj['operationAccounts'] = operationAccounts.map((account) => account.account.id).toString();
      }
      if (!isEqual(urlQueryObj, urlQueryRef.current)) {
        urlQueryRef.current = urlQueryObj;
        setSearchParams(urlQueryObj, { replace: true });
      }
    }
  }, [formMethods, formMethods.formState.isDirty, formMethods.getValues, setSearchParams, watchedFields]);

  useEffect(() => {
    if (autoSubmitForm && !formMethods.formState.isDirty && watchedFields?.operationAccounts?.length) {
      onFilterReady();
      setAutoSubmitForm(false);
    }
  }, [autoSubmitForm, formMethods.formState.isDirty, onFilterReady, watchedFields]);

  return <></>;
};

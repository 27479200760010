export const styles = {
  title: {
    fontWeight: 700,
    marginRight: '4px',
    color: 'colors.gray600',
  },
  value: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
  hr: {
    borderBottom: `dashed 1px`,
    borderColor: 'colors.gray100',
  },
  historyTitle: {
    userSelect: 'none',
    fontWeight: 600,
    color: 'colors.gray500',
  },
  historyHeaderContainer: {
    cursor: 'pointer',
    padding: '0 16px',
    height: '52px',
    backgroundColor: 'colors.gray25',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    '&.close': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  historyBodyContainer: {
    backgroundColor: 'colors.primary25',
    boxShadow: 'inset 0px 3px 12px rgba(0, 0, 0, 0.05)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  historyItemTitle: {
    fontWeight: 600,
    color: 'colors.primary700',
  },
  historyItemName: {
    fontWeight: 400,
    color: 'colors.gray300',
  },
  historyItemValue: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
};

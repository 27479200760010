import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon } from '@app/common/icons';
import { DepositDto } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { DATE_FORMAT, formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/deposits/show/components/TranchesTable/components/TableContent/styles';
import { TranchesTableColumns } from '@app/pages/deposits/types';
import { RouteList } from '@app/src/constants/routeList';

interface MakeTableColumnsProps {
  t(text: string): string;
  navigateClickHandler(uuid: string): void;
}

export const makeTableColumns = ({ t, navigateClickHandler }: MakeTableColumnsProps): ColumnDef<DepositDto, any>[] => {
  return [
    {
      accessorKey: TranchesTableColumns.agreementNumber,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_agreementNumber`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: TranchesTableColumns.agreementStartDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_startDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), DATE_FORMAT)}</>;
      },
    },
    {
      accessorKey: TranchesTableColumns.agreementEndDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_endDate`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return <>{format(new Date(getValue().split('T')[0]), DATE_FORMAT)}</>;
      },
    },
    {
      accessorKey: TranchesTableColumns.interestRate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t(`deposits_interestRate`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, number>) => {
        return `${getValue().toFixed(2)}%`;
      },
    },
    {
      accessorKey: TranchesTableColumns.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_currency`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: TranchesTableColumns.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`deposits_amount`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: TranchesTableColumns.state,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t(`status`)}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<DepositDto, string>) => {
        return t(getValue());
      },
    },
    {
      id: 'actions',
      header: () => <></>,
      accessorKey: TranchesTableColumns.uuid,
      cell: ({ row }: CellContext<DepositDto, any>) => {
        const onClickHandler = () => {
          navigateClickHandler(row.original.uuid);
        };
        return (
          <Grid container justifyContent="flex-end" alignItems="center">
            <IconButton size="small" sx={styles.navigateButton} onClick={onClickHandler}>
              <ArrowRightIcon />
            </IconButton>
          </Grid>
        );
      },
    },
  ];
};

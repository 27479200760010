import { MessageStatus, MessageType } from '@app/core/api';
import { SortingRule } from '@app/core/types';

export enum LettersTableColumns {
  uuid = 'uuid',
  createdAt = 'createdAt',
  senderName = 'senderName',
  type = 'type',
  signed = 'signed',
  category = 'category',
  subject = 'subject',
  message = 'message',
  status = 'status',
}

export type LettersFilterFormData = {
  status: MessageType;
  messageStatus?: MessageType;
  createDate?: string;
  sender?: string;
  topic?: string;
  sortBy?: SortingRule;
  rowsPerPage?: number;
  page?: number;
};

export enum LettersSubFilterEnum {
  createdAt = 'createdAt',
  senderName = 'senderName',
  category = 'category',
  subject = 'subject',
  message = 'message',
  status = 'status',
}

export type LettersSubFilterType = {
  createdAt?: Date;
  senderName?: string;
  category?: string;
  subject?: string;
  status?: MessageStatus;
};

enum LettersTableToolbarActionsEnum {
  sign = 'sign',
  sendToSign = 'sendToSign',
  edit = 'edit',
  sendToBank = 'sendToBank',
  markAsRead = 'markAsRead',
  delete = 'delete',
  view = 'view',
}

export type LettersTableToolbarActiveButtonsConfig = {
  [key in LettersTableToolbarActionsEnum]: boolean;
};

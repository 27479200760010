import React, { useCallback, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CsvIcon, PdfIcon, PrintIcon, XlsIcon, XmlIcon } from '@app/common/icons';
import { DEFAULT_FIRST_PAGE, statementsTableRowsPerPageDefault } from '@app/core/constants';
import { PaginationChangeType } from '@app/core/types';
import { NbuCurrencyTable } from '@app/pages/exchangeRate/components/NbuRates/components/NbuCurrencyTable';
import { NbuRatesFilter } from '@app/pages/exchangeRate/components/NbuRates/components/NbuRateFilter';
import { submitCurrencyFilter } from '@app/pages/exchangeRate/query';
import { ExchangeSource, ExchangesCurrencyFilter, ExchangesCurrencySubmit } from '@app/pages/exchangeRate/types';
import { appConfig } from '@app/src/config';
import { styles } from '@app/src/pages/exchangeRate/PageContent/styles';
import { validationSchema } from '@app/src/pages/exchangeRate/validationSchema';

export const NbuRates: React.FC = () => {
  const { t } = useTranslation();
  const [currentBank, setCurrentBank] = useState<ExchangeSource>(ExchangeSource.nbu);
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: statementsTableRowsPerPageDefault,
  });
  const methods = useForm<ExchangesCurrencySubmit>({
    resolver: yupResolver(validationSchema({ validateBankBranch: currentBank === ExchangeSource.bank, t })),
  });
  const { handleSubmit, reset: formReset, getValues } = methods;

  const { data, mutate, reset: mutationReset } = useMutation(submitCurrencyFilter);

  const submitFilter = useCallback(
    (submitData: ExchangesCurrencyFilter) => {
      mutate(submitData);
    },
    [mutate]
  );

  const handleFilterChange = useCallback(() => {
    const fieldsData = getValues();
    const bankBranch = currentBank === ExchangeSource.nbu ? undefined : fieldsData.bankBranch;
    const currencies = fieldsData.exchangeCurrencyList.map((currency) => {
      return currency.alpha3;
    });
    const submitData = {
      currencies,
      dateRange: fieldsData.dateRange,
      bankName: currentBank,
      pagination: paginationRef.current,
      bankBranch,
    };
    submitFilter(submitData);
  }, [currentBank, getValues, submitFilter]);

  const onSubmit = () => {
    handleFilterChange();
  };

  const resetFormHandler = () => {
    formReset({
      exchangeCurrencyList: [],
      dateRangeSelect: '',
    });
    mutationReset();
  };

  const onPaginationChangeHandler = (paginationChange: PaginationChangeType) => {
    paginationRef.current = paginationChange;
    handleFilterChange();
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NbuRatesFilter resetFormHandler={resetFormHandler} />
        </form>
      </FormProvider>
      {data ? (
        <>
          {appConfig.enableCurrencyOperationsDownloadButtons ? (
            <Grid container justifyContent="space-between">
              <Grid item sx={styles.actionTableButtons}>
                <IconButton size="small">
                  <CsvIcon />
                </IconButton>
                <IconButton size="small">
                  <PdfIcon />
                </IconButton>
                <IconButton size="small">
                  <XlsIcon />
                </IconButton>
                <IconButton size="small">
                  <XmlIcon />
                </IconButton>
                <IconButton size="small">
                  <PrintIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
          <Box pt={4}>
            <NbuCurrencyTable
              data={data.data}
              pagination={data.pagination}
              onPaginationChange={onPaginationChangeHandler}
              exchangeSource={currentBank}
            />
          </Box>
        </>
      ) : null}
    </Grid>
  );
};

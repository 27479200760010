import { useMutation, UseMutationResult } from 'react-query';

import {
  accountsApiClient,
  ApiError,
  GetAccountResponseDto,
  getClientId,
  GetDefaultAccountsResponseDto,
} from '@app/core/api';

export const useGetCurrentAccountsByCurrency = (): UseMutationResult<GetDefaultAccountsResponseDto, ApiError, any> =>
  useMutation(async (currency: any) => {
    const response = await accountsApiClient.accountsControllerGetAccountsByCurrency(getClientId(), currency);
    if (response.status !== 200) {
      throw new ApiError(response.request.status, "Can't get current accounts by currency");
    }
    return response.data;
  });

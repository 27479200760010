import React from 'react';

import { GetAccountResponseDto } from '@app/core/api';
import { useUpdateAccounts, UpdateAccountProps } from '@app/core/hooks/useUpdateAccount';
import { isMobile } from '@app/core/utils';
import { AccountsListRow } from '@app/pages/accounts/components/AccountsList/components/AccountsListRow';
import { MobileAccountInfo } from '@app/pages/accounts/components/MobileAccountInfo';

export interface AccountsListProps {
  accounts: GetAccountResponseDto[];
}

export const AccountsListComponent: React.FC<AccountsListProps> = ({ accounts }) => {
  const { mutate } = useUpdateAccounts();

  const onUpdateAccountHandler = (updateAccountData: UpdateAccountProps) => {
    mutate(updateAccountData);
  };

  return (
    <>
      {accounts?.length
        ? accounts.map((account) => {
            if (isMobile) {
              return <MobileAccountInfo key={account.account.id} account={account} />;
            }
            return (
              <AccountsListRow key={account.account.id} account={account} onUpdateAccount={onUpdateAccountHandler} />
            );
          })
        : null}
    </>
  );
};

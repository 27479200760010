import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  CardContent,
  ClickAwayListener,
  FormControl,
  FormLabel,
  Grid,
  Grow,
  InputAdornment,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { addDays } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DownChevronIcon, InfoIcon, KeyIcon } from '@app/common/icons';
import { CurrencyOperationsExchangeRateTypeEnum, CurrencyOperationsExchangeTypeEnum } from '@app/core/api';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { Tooltip } from '@app/core/components/Tooltip';
import { ExchangeRateSelectField } from '@app/pages/exchange/components/form/components/ExchangeRateSelectField';
import { PurchaseAdditionalBlock } from '@app/pages/exchange/components/form/components/PurchaseAdditionalBlock';
import { getTransferMethodTranslationKeyLabel } from '@app/pages/exchange/components/form/helpers';
import { styles } from '@app/pages/exchange/components/form/style';
import { ExchangeFormContentProps, FormFieldName } from '@app/pages/exchange/components/form/types';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { useAppSelector } from '@app/src/store';

export const DesktopExchangeFormContent: React.FC<ExchangeFormContentProps> = ({
  uuid,
  currencyExchangeType,
  watchDocumentDate,
  withdrawalAccounts,
  depositAccounts,
  withdrawalAccountPlaceholder,
  depositAccountPlaceholder,
  amountLabel,
  currency,
  conversionRateLabel,
  exchangeRateDisable,
  selectedWithdrawalAccount,
  files,
  buttonDisabled,
  onFileSelectClick,
  onFileDeleteClick,
  onSave,
  onCancelButtonClick,
  onSaveAndSign,
  onUploadFilesClick,
  signPermissions,
  onSendToSign,
  onSaveAndSignFromAnotherPerson,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openAnotherPerson, setOpenAnotherPerson] = useState(false);
  const nowDate = new Date();
  const { control } = useFormContext();
  const watchedExchangeRateTypeFields = useWatch({ control, name: FormFieldName.exchangeRateType });
  const [conversionCourseTooltip, setConversionCourseTooltip] = useState<string>('bankRateTooltip');

  const handleToggle = () => {
    setOpenAnotherPerson(!openAnotherPerson);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenAnotherPerson(false);
  };

  const onSignFromAnotherPersonClickHandler = () => {
    setOpenAnotherPerson(false);
    onSaveAndSignFromAnotherPerson();
  };

  useEffect(() => {
    if (watchedExchangeRateTypeFields === CurrencyOperationsExchangeRateTypeEnum.Bank) {
      setConversionCourseTooltip('bankRateTooltip');
    } else if (watchedExchangeRateTypeFields === CurrencyOperationsExchangeRateTypeEnum.Client) {
      setConversionCourseTooltip('fixedCourseTooltip');
    }
  }, [watchedExchangeRateTypeFields]);

  return (
    <Grid container direction="column">
      <CardContent sx={styles.cardContent}>
        <Box mb={6}>
          <Grid container alignItems="flex-start">
            <Box sx={{ width: '186px' }}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
              <Box pt={2} pr={8}>
                <DateSelectField
                  name={FormFieldName.documentDate}
                  selectedDate={nowDate}
                  datePickerProps={{
                    disabled: { before: nowDate },
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ width: '186px' }}>
              <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
              <Box pt={2} pr={4}>
                <DateSelectField
                  name={FormFieldName.documentValueDate}
                  datePickerProps={{
                    disabled: {
                      before: watchDocumentDate || nowDate,
                      after: addDays(watchDocumentDate || nowDate, 29),
                    },
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Tooltip color="primary" title={t('currencyExchange_valuationDateTooltip')} arrow placement="right">
                <Box mt={9}>
                  <InfoIcon fontSize="small" sx={styles.info} />
                </Box>
              </Tooltip>
            </Box>
          </Grid>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('write-offAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={FormFieldName.withdrawalAccount}
              options={withdrawalAccounts}
              placeholder={withdrawalAccountPlaceholder}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('enrollmentAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={FormFieldName.depositAccount}
              options={depositAccounts}
              placeholder={depositAccountPlaceholder}
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mb={5} />
        <Box mb={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <FormLabel component="legend">{amountLabel}</FormLabel>
              <Box pt={2} pr={5}>
                <FormatInputField
                  type={FormatType.amount}
                  name={FormFieldName.amount}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                  }}
                  placeholder={'0.00'}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Grid container item alignItems="flex-start">
                <FormLabel component="legend">{conversionRateLabel}</FormLabel>
                {currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion ? null : (
                  <Tooltip color="primary" title={t(`${conversionCourseTooltip}`)} arrow placement="right">
                    <Box pl={4} sx={{ height: '20px' }}>
                      <InfoIcon fontSize="small" sx={styles.info} />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
              <Box pt={2} pr={5}>
                <ExchangeRateSelectField
                  name={FormFieldName.exchangeRateType}
                  defaultValue={CurrencyOperationsExchangeRateTypeEnum.Bank}
                  disabled={currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" fullWidth disabled={exchangeRateDisable}>
                <FormLabel component="legend">{t('course')}</FormLabel>
                <Box pt={2}>
                  <FormatInputField
                    type={FormatType.amount}
                    name={FormFieldName.exchangeRate}
                    disabled={exchangeRateDisable}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {exchangeRateDisable ? '' : selectedWithdrawalAccount?.account.currency || ''}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={exchangeRateDisable ? '' : t('specifyCourse')}
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.hr} mb={5} />
        <Box mb={6}>
          <Grid container>
            <Grid item xs={8}>
              <FormLabel component="legend">{t('maintenanceCommissionAccount')}</FormLabel>
              <Box pt={2}>
                <InputField
                  fullWidth
                  name="unused"
                  disabled
                  placeholder={t('maintenanceCommissionAccountPlaceholder')}
                />
              </Box>
            </Grid>
            <Grid item container alignItems="center" xs={1}>
              <Tooltip color="primary" title={t('maintenanceCommissionAccount-tooltip') || ''} arrow placement="right">
                <Box mt={8} pl={4}>
                  <InfoIcon fontSize="small" sx={styles.info} />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('transferMethod')}</FormLabel>
          <Box pt={3}>
            <CheckboxField
              label={
                <Typography variant="caption">
                  {t(getTransferMethodTranslationKeyLabel(currencyExchangeType))}
                </Typography>
              }
              defaultChecked={true}
              name={FormFieldName._userConfirmCheck}
              sx={styles.transactionPresentedLabel}
            />
          </Box>
        </Box>
        {currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Purchase ||
        currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Conversion ? (
          <PurchaseAdditionalBlock
            files={files}
            onFileSelectClick={onFileSelectClick}
            onFileDeleteClick={onFileDeleteClick}
          />
        ) : null}
      </CardContent>
      <Box mt={5} />
      <Grid container justifyContent="space-between">
        <Button color="primary" sx={styles.cancelBtn} onClick={onCancelButtonClick}>
          {t('cancel')}
        </Button>
        {uuid && files.length ? (
          <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onUploadFilesClick}>
            {t('downloadFiles')}
          </Button>
        ) : (
          <Box>
            <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onSave} disabled={buttonDisabled}>
              {t('save')}
            </Button>
            {signPermissions.allowToSendToSign ? (
              <>
                {permissions.sign.currencyOperation.signFromAnotherPerson && appConfig.enableNextFeatures ? (
                  <>
                    <ButtonGroup variant="contained" ref={anchorRef}>
                      <Button variant="contained" color="primary" onClick={onSendToSign}>
                        {t('paymentCreationFormControl_sendToSign')}
                      </Button>
                      <Button size="small" onClick={handleToggle}>
                        <DownChevronIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      sx={{
                        zIndex: 1,
                      }}
                      open={openAnotherPerson}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      placement="bottom-end"
                    >
                      {({ TransitionProps }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: 'right top',
                          }}
                        >
                          <Paper sx={{ marginBottom: '8px' }}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <Button variant="text" color="primary" onClick={onSignFromAnotherPersonClickHandler}>
                                {t('signedByPerson')}
                                <KeyIcon sx={styles.keyIcon} />
                              </Button>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <Button variant="contained" color="primary" onClick={onSendToSign}>
                    {t('paymentCreationFormControl_sendToSign')}
                  </Button>
                )}
              </>
            ) : null}
            {signPermissions.allowToSign ? (
              <Button variant="contained" color="primary" onClick={onSaveAndSign} disabled={buttonDisabled}>
                {t('paymentCreationFormControl_send')}
                <KeyIcon sx={styles.keyIcon} />
              </Button>
            ) : null}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export const styles = {
  root: {
    backgroundColor: '#F3F9FF',
    boxShadow: 'inset 0px 27px 16px -30px rgba(0, 0, 0, 0.2)',
    padding: '0 20px',
  },
  expendRow: {
    height: '48px',
  },
  rowChannels: {
    paddingTop: '8px',
    paddingBottom: '8px',
    backgroundImage: `linear-gradient(to right, #d9dce0 100%, transparent 50%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '12px 1px',
    backgroundRepeat: 'repeat-x',
  },
  row: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  cellTitle: {
    color: 'colors.primary600',
    fontWeight: 600,
  },
  chevronIcon: {
    color: 'colors.gray200',
  },
  deliveryChannel: {
    pr: '14px',
    fontWeight: 600,
    color: 'colors.gray300',
  },
  radioLabel: {
    fontWeight: 600,
    verticalAlign: 'top',
    paddingTop: '4px',
  },
  submitBtn: {
    marginLeft: '32px',
  },
  tableRow: {
    padding: 0,
    zIndex: 3,
    cursor: 'pointer',
  },
  emptyRow: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
  tableCell: {
    flex: '1 auto',
    minWidth: '40px',
    padding: '8px 12px',
  },
  tableCellAccounts: {
    flexBasis: '150%',
    minWidth: '160px',
    flexGrow: 1,
    // flexShrink: 0,
  },
  tableCellDelivery: {
    maxWidth: '150px',
    minWidth: '100px',
  },
  actionCell: {
    maxWidth: '50px',
  },
  tableRowExpanded: {
    marginBottom: 0,
  },
};

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { ConfirmPopup } from '@app/core/components/ConfirmPopup';
import { paymentsTemplatesDelete } from '@app/pages/paymentsTemplates/PaymentsTemplates/query';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

interface LeavePopupProps {
  open: boolean;
  onConfirm(): void;
  onCancel(): void;
  uuid: string | undefined;
}

export const DeleteTemplatePopup: React.FC<LeavePopupProps> = ({ open, onConfirm, onCancel, uuid }) => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const { showLoader } = useUIState();

  const { mutate } = useMutation(paymentsTemplatesDelete, {
    onSuccess: () => {
      onConfirm();
      showLoader(false);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('deleteTemplateSuccessMessage'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      showLoader(false);
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('deleteTemplateErrorMessage'),
          severity: 'error',
        },
      });
    },
  });

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    if (uuid) {
      mutate(uuid);
    }
  };
  return (
    <ConfirmPopup
      open={open}
      onConfirm={onConfirmDeleteDocument}
      onClose={onCancel}
      questionText={t('confirmDeleteTemplatePopupMessage')}
      icon={<DeleteIcoBgSVG />}
      classes={{
        questionTextContainer: {
          fontWeight: 600,
          color: 'colors.gray500',
        },
      }}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { CreateOperationAdditionalInfoDto, ResultStatusEnum, SwiftOperationDto } from '@app/core/api';
import { SignMultipleSwift } from '@app/core/components';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useGetSwiftSigners, useGetSwiftByUuid } from '@app/core/hooks';
import { useCreateSwiftPayment } from '@app/core/hooks/useCreateSwiftPayment';
import { useSendToSignSwift } from '@app/core/hooks/useSendToSignSwift';
import { isMobile } from '@app/core/utils';
import { styles } from '@app/pages/swift/style';
import { SwiftPaymentBetweenMyAccountForm } from '@app/pages/swift/SwiftPayment/components/BetweenMyAccounts';
import { SwiftPaymentForm } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm';
import { makeSwiftPaymentSubmitData } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/helper';
import { DefaultValues, SwiftFormData, SwiftTypeEnum } from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface CreatePaymentProps {
  paymentData: SwiftFormData;
  onCreateSuccess(operation: SwiftOperationDto): void;
  onCreateError(additionalInfo?: CreateOperationAdditionalInfoDto): void;
}

export const SwiftPaymentPage: React.FC = () => {
  const { permissions, userProfile } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const [createPaymentResultOperation, setCreatePaymentResultOperation] = useState<SwiftOperationDto | null>();
  const { t } = useTranslation();
  const { notify } = useNotify();
  const { showLoader } = useUIState();
  const urlQueryValues = useLocation().search;
  const [defaultValues, setDefaultValues] = useState<DefaultValues>();
  const [showSignPayment, setShowSignPayment] = useState<boolean>(false);
  const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<SwiftTypeEnum>(SwiftTypeEnum.international);
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean | undefined>(false);

  const { copyFrom: copyFromUuid } = parse(urlQueryValues);

  const { data: swiftOrder, refetch: refetchSwiftOrder } = useGetSwiftByUuid(copyFromUuid as string);

  useEffect(() => {
    if (copyFromUuid) {
      refetchSwiftOrder();
    }
  }, [copyFromUuid, refetchSwiftOrder]);

  useEffect(() => {
    if (swiftOrder) {
      setDefaultValues({
        senderAccountId: swiftOrder.sender.accountId,
        documentNumber: swiftOrder.documentNumber,
        recipientAccountName: swiftOrder.recipient.name,
        recipientAccountNumber: swiftOrder.recipient.account,
        recipientAddress: swiftOrder.recipient.address,
        amount: swiftOrder.amount,
        paymentReference: swiftOrder.paymentReference,
        recipientBankName: swiftOrder.recipient.bankName,
        recipientCorrespondentBankName: swiftOrder.recipient.correspondentBank,
        documentDate: swiftOrder.documentDate,
        documentValueDate: swiftOrder.documentValueDate,
        swiftBic: swiftOrder.recipient.swiftBic,
        swiftBicCorrespondent: swiftOrder.recipient.correspondentSwiftBic,
        commissionType: swiftOrder.commissionType,
        operationCode: swiftOrder.operationCode || undefined,
        urgency: swiftOrder.urgency,
        recipientCountry: swiftOrder.recipient.countryCode,
      });
    }
  }, [swiftOrder]);

  const onCloseModalHandler = (status: 'idle' | 'loading' | 'error' | 'success') => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    if (createPaymentResultOperation && status !== 'success') {
      navigate(RouteList.swift_edit.replace(':uuid', createPaymentResultOperation.uuid));
    } else {
      navigate(-1);
    }
  };

  const onSuccessSignHandler = () => {
    setCreatePaymentResultOperation(null);
    setShowSignPayment(false);
    navigate(RouteList.swift);
  };

  const { data: peopleToSigne } = useGetSwiftSigners();

  const { data: createSwiftPaymentResultData, mutateAsync, reset: resetCreatePayment } = useCreateSwiftPayment();

  const { data: sendToSignPaymentResultData, mutate: sendToSignMutation } = useSendToSignSwift();

  const createPayment = ({ paymentData, onCreateSuccess, onCreateError }: CreatePaymentProps) => {
    mutateAsync({ operation: makeSwiftPaymentSubmitData(paymentData) })
      .then((result) => {
        if (result.operation) {
          onCreateSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onCreateError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onCreateError();
      });
  };

  const onSaveClickHandler = (paymentData: SwiftFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(-1);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignClickHandler = (paymentData: SwiftFormData, signFromAnotherPers?: boolean) => {
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        setCreatePaymentResultOperation(operation);
        setSignFromAnotherPerson(signFromAnotherPers);
        setShowSignPayment(true);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: SwiftFormData) => {
    createPayment({
      paymentData,
      onCreateSuccess(operation: SwiftOperationDto) {
        sendToSignMutation([operation.uuid]);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData?.status === ResultStatusEnum.Successfully) {
      notify({
        notifyProps: {
          title: t('payment_save_success'),
          message: t('send_to_sign_payment_success'),
          severity: 'success',
        },
      });
      navigate(RouteList.swift);
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    setShowLeavePopup(formDirty);
  };

  const onFilterChangeHandler = (tab: SwiftTypeEnum) => {
    setCurrentTab(tab);
  };

  const menuItems: MenuItem<SwiftTypeEnum>[] = [
    {
      id: 1,
      value: SwiftTypeEnum.international,
      labelText: t(SwiftTypeEnum.international),
    },
    {
      id: 2,
      value: SwiftTypeEnum.betweenMyAccounts,
      labelText: t(SwiftTypeEnum.betweenMyAccounts),
    },
  ];

  return (
    <>
      <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
        <PageHeader
          title={t('swiftCreationHeaderContent_title')}
          showNavigateButton={!isMobile}
          backLink={-1}
          showLeavePopup={showLeavePopup}
          confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Box pl={3} pt={4} mb={isMobile ? 4 : 0}>
            <HorizontalMenu<SwiftTypeEnum> onChange={onFilterChangeHandler} menuItems={menuItems} />
          </Box>
          <Box p={isMobile ? 0 : 3} width="100%">
            <Grid container wrap="nowrap" justifyContent="space-between">
              <Grid item container xs={isMobile ? false : 9}>
                {currentTab === SwiftTypeEnum.international && (
                  <SwiftPaymentForm
                    signPermissions={{
                      ...permissions.sign.swift,
                      allowSignFromAnotherPerson: permissions.sign.swift.signFromAnotherPerson,
                    }}
                    additionalInfo={createSwiftPaymentResultData?.additionalInfo}
                    onSaveAndSign={onSaveAndSignClickHandler}
                    onSave={onSaveClickHandler}
                    defaultValues={defaultValues}
                    onSendToSign={onSaveAndSendToSignClickHandler}
                    onChange={onChangeHandler}
                  />
                )}
                {currentTab === SwiftTypeEnum.betweenMyAccounts && (
                  <SwiftPaymentBetweenMyAccountForm
                    signPermissions={permissions.sign}
                    additionalInfo={createSwiftPaymentResultData?.additionalInfo}
                    onSaveAndSignClick={onSaveAndSignClickHandler}
                    onSaveClick={onSaveClickHandler}
                    onSendToSign={onSaveAndSendToSignClickHandler}
                  />
                )}
              </Grid>
              {!isMobile && userProfile ? (
                <Box sx={{ width: '220px' }}>
                  <Box pl={2}>
                    <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                  </Box>
                </Box>
              ) : null}
            </Grid>
          </Box>
        </PageContainer>
      </PageWrapper>
      {createPaymentResultOperation && showSignPayment ? (
        <SignMultipleSwift
          operations={[createPaymentResultOperation]}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessSignHandler}
          onClose={onCloseModalHandler}
        />
      ) : null}
    </>
  );
};

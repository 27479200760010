import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormLabel, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetStatementConfigResponseDto, PeriodType } from '@app/core/api';
import { AccountsWithSearchSelectField, CheckboxField, RadioGroupField, SelectField } from '@app/core/components';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { filesFormatList, periodsListItems } from './constants';
import { styles } from './styles';
import { validationSchema } from './validationSchema';
import { FormFieldName, MonitoringFormData } from '../../types';

interface MonitoringStatementsFormProps {
  config?: GetStatementConfigResponseDto;
  onSave(formData: MonitoringFormData): void;
  onCancel(): void;
  onDelete?(uuid: string): void;
}

export const MonitoringStatementsForm: React.FC<MonitoringStatementsFormProps> = ({
  config,
  onSave,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  const methods = useForm<MonitoringFormData>({ mode: 'onSubmit', resolver: yupResolver(validationSchema({ t })) });
  const { getValues, trigger, setValue } = methods;
  const { allAccounts } = useAppSelector((state): ProfileState => state.profile);

  useEffect(() => {
    if (config) {
      if (config.periodType) {
        setValue(FormFieldName.periodType, config.periodType);
      }
    }
  }, [config, setValue]);

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSave(getValues());
      }
    });
  };

  const onDeleteHandler = () => {
    if (config && onDelete) {
      onDelete(config.uuid);
    }
  };

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Grid container alignItems="center" sx={styles.root}>
          <Grid item container alignItems="center" justifyContent="space-between" sx={styles.rowChannels}>
            <Grid>
              <Typography variant="caption" sx={styles.deliveryChannel}>
                {t('deliveryChannel')}:
              </Typography>
              <CheckboxField
                disabledValue
                defaultChecked={true}
                label={
                  <Typography variant="caption" sx={{ lineHeight: '24px' }}>
                    Email
                  </Typography>
                }
                name={FormFieldName.deliveryChannel}
              />
            </Grid>
            {config ? (
              <Button variant="outlined" color="warning" onClick={onDeleteHandler}>
                {t('delete')}
              </Button>
            ) : null}
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between" sx={styles.row}>
            <Box sx={{ width: '100%' }}>
              <FormLabel component="legend">{t('accounts')}</FormLabel>
              <Box pt={2}>
                <AccountsWithSearchSelectField
                  name={FormFieldName.operationAccounts}
                  accounts={allAccounts}
                  defaultSelectedAccountsId={config?.requestData.accountNumber.map((account) => account.id)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid container alignItems="center" justifyContent="flex-start" sx={styles.row}>
            <Grid item xs={3}>
              <RadioGroupField
                title={t('dashboard_statements_file_type')}
                name={FormFieldName.outputType}
                value={config?.requestData.outputType || filesFormatList[0].value}
                options={filesFormatList}
                fullWidth
                styles={{ radioLabel: styles.radioLabel }}
              />
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: '100%' }}>
                <FormLabel component="legend">{t('statementsPeriod')}</FormLabel>
                <Box pt={2}>
                  <SelectField
                    name={FormFieldName.periodType}
                    defaultValue={config?.periodType || PeriodType.Weekly}
                    fullWidth
                    options={periodsListItems(t)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button color="primary" onClick={onCancel}>
                {t('cancel')}
              </Button>
              <Button sx={styles.submitBtn} color="primary" variant="contained" onClick={onSaveClickHandler}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

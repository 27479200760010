import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';

import { OperationCard } from './OperationCard';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../../../constants';
import { PaginationChangeType, Operation } from '../../../../types';
import { hasNextPage } from '../../../../utils';
import { Loader } from '../../../Loader';
import { ModalCommon } from '../../../Modal';
import { OperationsProps } from '../types';

export const MobileOperationsList: React.FC<OperationsProps> = ({
  operationType,
  accounts,
  searchText,
  dateRange,
  getRecentOperations,
}) => {
  const [nextPageExists, setNextPageExists] = useState<boolean>(true);
  const [showBottomAnchor, setShowBottomAnchor] = useState<boolean>(true);
  const [operations, setOperations] = useState<Array<Operation>>([]);
  const operationsListRef = useRef<Array<Operation>>([]);
  const paginationRef = useRef<PaginationChangeType>({ page: DEFAULT_FIRST_PAGE, rowsPerPage: DEFAULT_ROWS_PER_PAGE });
  const { data, mutate, isLoading } = getRecentOperations();

  useEffect(() => {
    if (data) {
      operationsListRef.current = operationsListRef.current.concat(data.operations);
      setOperations(operationsListRef.current);
      setShowBottomAnchor(true);
      setNextPageExists(hasNextPage(data.pagination));
    }
  }, [data]);

  const pagination = data?.pagination;

  useEffect(() => {
    operationsListRef.current = [];
    paginationRef.current = { page: DEFAULT_FIRST_PAGE, rowsPerPage: paginationRef.current.rowsPerPage };
    mutate({
      accountIds: accounts?.map((account) => account.account.id) || undefined,
      operationType,
      queryText: searchText,
      pagination: paginationRef.current,
      dateRange,
    });
  }, [accounts, dateRange, mutate, operationType, searchText]);

  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (pagination && bottomAnchorInView && !isLoading) {
      setShowBottomAnchor(false);
      if (nextPageExists) {
        paginationRef.current = {
          page: pagination.page + 1,
          rowsPerPage: pagination.rowsPerPage,
        };
        mutate({
          accountIds: accounts?.map((account) => account.account.id) || undefined,
          operationType,
          queryText: searchText,
          pagination: paginationRef.current,
          dateRange,
        });
      }
    }
  }, [
    accounts,
    bottomAnchorInView,
    dateRange,
    isLoading,
    mutate,
    nextPageExists,
    operationType,
    pagination,
    searchText,
  ]);

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        {operations.map((operation) => {
          return <OperationCard key={operation.uuid} operation={operation} />;
        })}
        <div ref={bottomAnchorRef} style={{ display: showBottomAnchor ? 'block' : 'none' }} />
      </Grid>
      <ModalCommon open={isLoading}>
        <div>
          <Loader />
        </div>
      </ModalCommon>
    </>
  );
};

import React from 'react';

import { ChipProps, TextFieldProps } from '@mui/material';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

import { ReactComponent as ChevronDownIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/down.svg';

import { Tags } from './components/Tags';
import { TextInput } from './components/TextInput';

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  name: string;
  error?: string;
  textFieldProps?: TextFieldProps;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  chipProps?: ChipProps;
  renderChipLabel?(option: T & React.ReactPortal): React.ReactNode;
}

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  name,
  options = [],
  textFieldProps = {},
  error,
  chipProps = {},
  renderChipLabel = (option) => option,
  ...otherProps
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  return (
    <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      id={name}
      options={options}
      renderInput={TextInput(textFieldProps, error)}
      renderTags={Tags<T>({
        chipProps,
        renderChipLabel,
      })}
      popupIcon={<ChevronDownIconSvg style={{ fill: 'currentColor' }} />}
      {...otherProps}
    />
  );
};

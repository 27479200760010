import React, { PropsWithChildren } from 'react';

import { Grid, GridProps, TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@mui/material';
import { TableCellBaseProps } from '@mui/material/TableCell/TableCell';

interface TableCellProps extends MuiTableCellProps {
  componentProps?: GridProps;
}

export const TableCell: React.FC<TableCellProps> = ({ children, componentProps, ...otherProps }) => {
  const CellComponent: React.FC<PropsWithChildren<TableCellBaseProps>> = ({ children: cellChildren, ...cellProps }) => (
    <Grid item {...cellProps} {...componentProps}>
      {cellChildren}
    </Grid>
  );

  return (
    <MuiTableCell component={CellComponent} {...otherProps}>
      {children}
    </MuiTableCell>
  );
};

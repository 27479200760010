import React, { useEffect, useRef, useState } from 'react';

import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps, Popover } from '@mui/material';
import { format, parse, isValid, add } from 'date-fns';
import { ActiveModifiers, DayPickerSingleProps } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';
import { Rifm } from 'rifm';

import { CalendarIcon } from '@app/common/icons';

import { ddMMYYYYFormat } from '../../../../constants';
import { formatDateWithAppend, REGEX_INT, appendHyphen } from '../../../../utils';
import { StatusText } from '../../../StatusText';
import { DatePicker } from '../../components/DatePicker';

export type DateSelectFieldType = {
  name: string;
  disabled?: boolean;
  selectedDate?: Date;
  datePickerProps?: Omit<DayPickerSingleProps, 'mode'>;
  inputProps?: OutlinedInputProps;
};

export const DateSelectField: React.FC<DateSelectFieldType> = ({
  name,
  selectedDate,
  datePickerProps,
  disabled,
  inputProps,
}) => {
  const { control, setValue, register, watch } = useFormContext();
  const inputItemRef = useRef<HTMLDivElement>(null);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const currentSelectedDateRef = useRef<Date | undefined>(selectedDate);

  useEffect(() => {
    register(name, { required: false, value: currentSelectedDateRef.current });
  }, [register, name]);

  const watchDate = watch(name);

  useEffect(() => {
    currentSelectedDateRef.current = watchDate;
  }, [watchDate]);

  const onPopoverClose = () => {
    setCalendarOpen(false);
  };

  const onCalendarClickHandler = () => {
    setCalendarOpen(!calendarOpen);
  };

  const onDayClickHandler =
    (onControllerChange: (...event: any[]) => void) => (date: Date, activeModifiers: ActiveModifiers) => {
      if (activeModifiers.disabled) {
        return;
      }
      const resultDate = add(date, { hours: 12 });
      setValue(name, resultDate);
      onControllerChange(format(resultDate, ddMMYYYYFormat.format));
      setCalendarOpen(false);
    };

  const onBlurInputHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const dateStr = event.target.value;
    const newDate = parse(dateStr, ddMMYYYYFormat.format, new Date());
    if (isValid(newDate)) {
      setValue(name, newDate);
    }
  };

  const defaultValue: string = selectedDate ? format(selectedDate, ddMMYYYYFormat.format) : '';

  return (
    <Controller
      name={`temp_${name}`}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange: onChangeController }, fieldState: { error } }) => {
        return (
          <>
            <Rifm
              value={value}
              onChange={onChangeController}
              accept={REGEX_INT}
              mask
              format={(v) => formatDateWithAppend(v, ddMMYYYYFormat.hyphen)}
              append={(v) => appendHyphen(v, ddMMYYYYFormat.hyphen)}
              replace={ddMMYYYYFormat.mask}
            >
              {({ value: newRifmValue, onChange }) => (
                <OutlinedInput
                  disabled={disabled}
                  error={Boolean(error)}
                  value={newRifmValue}
                  ref={inputItemRef}
                  onChange={onChange}
                  onBlur={onBlurInputHandler}
                  sx={{
                    paddingRight: '12px',
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={onCalendarClickHandler}>
                        <CalendarIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  {...inputProps}
                />
              )}
            </Rifm>
            <Popover
              open={calendarOpen}
              anchorEl={inputItemRef.current}
              onClose={onPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <DatePicker
                defaultMonth={currentSelectedDateRef.current || new Date()}
                selected={currentSelectedDateRef.current}
                showOutsideDays
                onDayClick={onDayClickHandler(onChangeController)}
                mode="single"
                {...datePickerProps}
              />
            </Popover>
            {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
          </>
        );
      }}
    />
  );
};

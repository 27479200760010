import React, { useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { CollateralDto, PaginationResponseDto } from '@app/core/api';

import { makeTableColumns } from './columns';
import { styles } from './styles';
import { DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION, statementsTableRowsPerPageOptions } from '../../constants';
import { PaginationChangeType } from '../../types';
import { ComplexTable, TableHeadContent, TablePagination, TableRowContent } from '../ComplexTable';
import { Loader } from '../Loader';

export interface SafetyTableProps {
  data: CollateralDto[];
  pagination?: PaginationResponseDto;
  onPaginationChange(pagination: PaginationChangeType): void;
  loading?: boolean;
}

export const SafetyTable: React.FC<SafetyTableProps> = ({ data, loading, pagination, onPaginationChange }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
      }),
    ];
  }, [t]);

  const table = useReactTable<CollateralDto>({
    columns,
    data,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    const rows = table.getRowModel().rows;
    if (!rows.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return rows.map((row) => {
      return <TableRowContent<CollateralDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(
    pagination && pagination.totalCount > DEFAULT_MIN_ROWS_FOR_SHOW_PAGINATION && !loading
  );

  const footerContent = (
    <>
      {pagination ? (
        <TablePagination
          totalCount={pagination.totalCount}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          labelRowsPerPage={t('showOnPageShort')}
          rowsPerPageOptions={statementsTableRowsPerPageOptions}
          onChange={onPaginationChange}
          show={showPagination}
        />
      ) : null}
    </>
  );

  return (
    <ComplexTable
      head={{ content: <TableHeadContent<CollateralDto> {...{ table, styles }} /> }}
      body={{ content: buildBodyContent() }}
      footer={{
        content: footerContent,
      }}
    />
  );
};

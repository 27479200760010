import React from 'react';

import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { ExtendedMessageDto } from '@app/core/api';
import { ModalCommon } from '@app/core/components';
import { useDeleteMessages } from '@app/core/hooks';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

import { styles } from './style';

interface DeleteSingleMessagesProps {
  message: ExtendedMessageDto;
  onCancelDelete(): void;
  onDeleteSuccess(): void;
}

export const DeleteSingleMessagesDialog: React.FC<DeleteSingleMessagesProps> = ({
  message,
  onCancelDelete,
  onDeleteSuccess,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();
  const { showLoader } = useUIState();

  const { mutate } = useDeleteMessages();

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate(
      { uuids: [message.uuid] },
      {
        onSuccess: () => {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('success'),
              message: t('deleteLetterSuccessMessage'),
              severity: 'success',
            },
          });
          onDeleteSuccess();
        },
        onError: () => {
          showLoader(false);
          notify({
            notifyProps: {
              title: t('server_error'),
              message: t('deleteLetterErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };

  return (
    <ModalCommon onClose={onCancelDelete} sx={styles.root} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDelete}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('areYouSureYouWantToDelete')}
          </Typography>
        </Box>
        <Grid container justifyContent="center">
          <Box pr={6}>
            <Button size="small" variant="outlined" color="primary" onClick={onCancelDelete}>
              {t('no')}
            </Button>
          </Box>
          <Button size="small" variant="contained" color="primary" onClick={onConfirmDeleteDocument}>
            {t('yes')}
          </Button>
        </Grid>
      </Box>
    </ModalCommon>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';

import { SelectOptionType } from '../../../Select';
import { SelectField } from '../SelectField';

export type CurrencySelectFilterFieldProps = {
  name: string;
};

enum CurrencySelectFilterFieldEnum {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR',
}

export const CurrencySelectFilterField: React.FC<CurrencySelectFilterFieldProps> = ({ name }) => {
  const { t } = useTranslation();
  const currencyListItems: SelectOptionType[] = [
    {
      id: 'not_selected',
      name: t('not_selected'),
      value: '',
    },
    {
      id: CurrencySelectFilterFieldEnum.UAH,
      name: CurrencySelectFilterFieldEnum.UAH,
      value: CurrencySelectFilterFieldEnum.UAH,
    },
    {
      id: CurrencySelectFilterFieldEnum.USD,
      name: CurrencySelectFilterFieldEnum.USD,
      value: CurrencySelectFilterFieldEnum.USD,
    },
    {
      id: CurrencySelectFilterFieldEnum.EUR,
      name: CurrencySelectFilterFieldEnum.EUR,
      value: CurrencySelectFilterFieldEnum.EUR,
    },
  ];

  return <SelectField name={name} options={currencyListItems} fullWidth />;
};

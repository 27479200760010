import { ReactNode } from 'react';

import {
  AvailableActionsDto,
  CountryCodeDto,
  CreateOperationAdditionalInfoDto,
  GetAccountResponseDto,
  GetOneSwiftOperationResponseDto,
  SWIFTCommissionTypeEnum,
  SWIFTUrgencyEnum,
} from '@app/core/api';

export enum SwiftFilterField {
  accounts = 'accounts',
  dateRange = 'dateRange',
  rowsPerPage = 'rowsPerPage',
  page = 'page',
}

export enum SwiftFormFields {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  documentValueDate = 'documentValueDate',
  senderAccount = 'senderAccount',
  senderName = 'senderName',
  senderAddress = 'senderAddress',
  recipientName = 'recipientName',
  recipientAddress = 'recipientAddress',
  recipientAccount = 'recipientAccount',
  recipientCountry = 'recipientCountry',
  recipientSwiftBic = 'recipientSwiftBic',
  recipientBank = 'recipientBank',
  recipientCorrespondentSwiftBic = 'recipientCorrespondentSwiftBic',
  recipientCorrespondentBank = 'recipientCorrespondentBank',
  amount = 'amount',
  commissionType = 'commissionType',
  operationCode = 'operationCode',
  urgency = 'urgency',
  paymentReference = 'paymentReference',
  correspondentBankCheck = 'correspondentBankCheck',
  temp_documentValueDate = 'temp_documentValueDate',
}

export enum SwiftBetweenMyAccountFormFields {
  documentNumber = 'documentNumber',
  documentDate = 'documentDate',
  documentValueDate = 'documentValueDate',
  senderAccount = 'senderAccount',
  recipientAccount = 'recipientAccount',
  amount = 'amount',
  paymentReference = 'paymentReference',
}

export type SwiftFormData = {
  [SwiftFormFields.documentNumber]: string;
  [SwiftFormFields.documentDate]: Date;
  [SwiftFormFields.documentValueDate]?: Date;
  [SwiftFormFields.senderAccount]: string;
  [SwiftFormFields.senderName]: string;
  [SwiftFormFields.senderAddress]: string;
  [SwiftFormFields.recipientName]: string;
  [SwiftFormFields.recipientAddress]: string;
  [SwiftFormFields.recipientAccount]: string;
  [SwiftFormFields.recipientCountry]: CountryCodeDto;
  [SwiftFormFields.recipientSwiftBic]: string;
  [SwiftFormFields.recipientBank]?: string;
  [SwiftFormFields.recipientCorrespondentSwiftBic]?: string;
  [SwiftFormFields.recipientCorrespondentBank]?: string;
  [SwiftFormFields.amount]: string;
  [SwiftFormFields.commissionType]: SWIFTCommissionTypeEnum;
  [SwiftFormFields.operationCode]: string;
  [SwiftFormFields.urgency]: SWIFTUrgencyEnum;
  [SwiftFormFields.paymentReference]: string;
  [SwiftFormFields.correspondentBankCheck]: boolean;
  [SwiftFormFields.temp_documentValueDate]?: string;
};

export type SwiftBetweenMyAccountFormData = {
  [SwiftBetweenMyAccountFormFields.documentNumber]: string;
  [SwiftBetweenMyAccountFormFields.documentDate]: Date;
  [SwiftBetweenMyAccountFormFields.documentValueDate]?: Date;
  [SwiftBetweenMyAccountFormFields.senderAccount]: string;
  [SwiftBetweenMyAccountFormFields.recipientAccount]: string;
  [SwiftBetweenMyAccountFormFields.amount]: string;
  [SwiftBetweenMyAccountFormFields.paymentReference]: string;
};

export type DefaultValues = {
  senderAccountId: string;
  documentNumber: string;
  recipientAccountName: string;
  recipientAccountNumber: string;
  recipientAddress: string;
  amount: number;
  paymentReference: string;
  recipientBankName: string;
  swiftBic: string;
  commissionType: string;
  urgency: string;
  recipientCountry: string;
  documentDate?: string;
  documentValueDate?: string;
  operationCode?: number;
  recipientCorrespondentBankName?: string;
  swiftBicCorrespondent?: string;
};

export enum CommissionType {
  our = 'OUR',
  ben = 'BEN',
  sha = 'SHA',
}

export enum OperationCodes {
  import = 'import',
  contribution = 'contribution',
}

export const resetFormData = {
  operationAccounts: [],
  dateRangeSelect: '',
};

export interface SwiftPaymentFormProps {
  generatedSwiftOrderNumber: string;
  maxDocumentDate: Date;
  minDocumentDate: Date;
  maxValuationDate: Date;
  minValuationDate: Date;
  currencyAccounts: GetAccountResponseDto[];
  recipientCountry?: string;
  currency: string;
  showBalance: boolean;
  countedBalance: string;
  signPermissions: AvailableActionsDto;
  additionalInfo?: CreateOperationAdditionalInfoDto | null;
  shareButton?: ReactNode;
  isShowCorrespondentBank?: boolean;
  onSaveClick(): void;
  onSaveAndSignClick(): void;
  onCancelClick(): void;
  onSendToSignClick(): void;
  onSaveAndSignFromAnotherPerson(): void;
}

export interface SwiftViewProps {
  order: GetOneSwiftOperationResponseDto;
  country?: CountryCodeDto;
  onDownloadClick(): void;
  onClose(): void;
}

export enum SwiftTypeEnum {
  international = 'international',
  betweenMyAccounts = 'betweenOwnAccounts',
}

export type SimpleSwiftDefaultValues = {
  senderAccount: string;
  senderName: string;
  senderAddress: string;
  senderAccountId: string;
  recipientAccount: string;
  recipientAccountId: string;
  recipientName: string;
  recipientAddress: string;
  recipientSwiftBic: string;
  recipientBankName: string;
  recipientCountryCode: string;
  amount: number;
  paymentReference: string;
};

import { CreateSwiftOperationDto, EditSwiftOperationRequestDto, GetAccountResponseDto } from '@app/core/api';
import { SwiftFormData } from '@app/pages/swift/types';

type EditSwiftPaymentData = {
  paymentData: SwiftFormData;
  uuid: string;
};

export const makeSwiftPaymentSubmitData = (paymentData: SwiftFormData): CreateSwiftOperationDto => {
  const {
    documentNumber,
    documentDate,
    documentValueDate,
    amount,
    recipientName,
    senderAccount: senderAccountInfo,
    recipientAccount,
    paymentReference,
    recipientAddress,
    recipientSwiftBic,
    recipientBank,
    recipientCorrespondentSwiftBic,
    recipientCorrespondentBank,
    recipientCountry,
    commissionType,
    operationCode,
    urgency,
    senderName,
    senderAddress,
  } = paymentData;

  const {
    account: { accountNumber: senderAccount, id: senderAccountId },
  }: GetAccountResponseDto = JSON.parse(senderAccountInfo);

  return {
    documentNumber,
    documentDate: documentDate.toISOString(),
    documentValueDate: documentValueDate ? documentValueDate.toISOString() : undefined,
    senderAccount: senderAccount,
    senderAccountId,
    senderAddress,
    senderName,
    recipientAccount: recipientAccount,
    recipientName: recipientName.trim(),
    recipientAddress,
    recipientSwiftBic,
    recipientBankName: recipientBank || '',
    recipientCorrespondentSwiftBic,
    recipientCorrespondentBank,
    recipientCountryCode: recipientCountry.numeric,
    amount: parseFloat(amount),
    commissionType,
    operationCode: parseFloat(operationCode),
    urgency,
    paymentReference,
  };
};

export const makeEditSwiftPaymentSubmitData = ({
  paymentData,
  uuid,
}: EditSwiftPaymentData): EditSwiftOperationRequestDto => {
  const {
    documentNumber,
    documentDate,
    documentValueDate,
    amount,
    recipientName,
    senderAccount: senderAccountInfo,
    recipientAccount,
    paymentReference,
    recipientAddress,
    recipientSwiftBic,
    recipientBank,
    recipientCorrespondentSwiftBic,
    recipientCorrespondentBank,
    recipientCountry,
    commissionType,
    operationCode,
    urgency,
    senderName,
    senderAddress,
  } = paymentData;

  const {
    account: { accountNumber: senderAccount, id: senderAccountId },
  }: GetAccountResponseDto = JSON.parse(senderAccountInfo);

  return {
    operation: {
      documentNumber,
      documentDate: documentDate ? documentDate.toISOString() : undefined,
      documentValueDate: documentValueDate ? documentValueDate.toISOString() : undefined,
      senderAccount: senderAccount,
      senderAccountId,
      recipientAccount: recipientAccount,
      recipientName,
      recipientAddress,
      recipientSwiftBic,
      recipientBankName: recipientBank || '',
      recipientCorrespondentSwiftBic,
      recipientCorrespondentBank,
      recipientCountryCode: recipientCountry.numeric,
      amount: parseFloat(amount),
      commissionType,
      operationCode: parseFloat(operationCode),
      urgency,
      paymentReference,
      uuid,
      senderAddress,
      senderName,
    },
  };
};

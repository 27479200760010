import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { SearchSmallIcon, UpChevronSmallIcon, DownChevronSmallIcon, EraserIcon, CrossIcon } from '@app/common/icons';
import { UserDto, UserStateStatusEnum } from '@app/core/api';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import {
  SelectColumnFilter,
  TextFieldColumnFilter,
} from '@app/core/components/ComplexTable/components/TableColumnFilter';
import { SelectOptionType } from '@app/core/components/Select';
import { CompanyUsersTableColumnsEnum } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/types';
import { styles } from '@app/pages/companyProfile/desktop/components/CompanyProfileSettingsItemUsers/UsersTable/components/styles';

interface MakeTableColumnsProps {
  showSubFilters: boolean;
  t(text: string): string;
  onSearchButtonClick(): void;
  onClearSubFilter(): void;
  onCloseSubFilter(): void;
}

type UserStatusProps = {
  status: UserStateStatusEnum;
};

const UserStatusBlock: React.FC<UserStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{
          ...styles.userStatus,
          ...(status === UserStateStatusEnum.Active && styles.userStatusActive),
          ...(status === UserStateStatusEnum.Blocked && styles.userStatusBlocked),
        }}
      />
      <Typography variant="caption" color="textSecondary">
        {t(status)}
      </Typography>
    </Grid>
  );
};

export const makeTableColumns = ({
  showSubFilters,
  t,
  onSearchButtonClick,
  onClearSubFilter,
  onCloseSubFilter,
}: MakeTableColumnsProps): ColumnDef<UserDto, any>[] => {
  const statusOptionsItems: SelectOptionType[] = Object.values(UserStateStatusEnum).map((items) => {
    return { id: items, name: t(items), value: items };
  });
  return [
    {
      accessorKey: CompanyUsersTableColumnsEnum.id,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="flex-start">
            <div>
              <Typography variant="caption">{t(`user_registeredNumber`)}</Typography>
            </div>
          </Grid>
        );
      },
      cell: ({ getValue }: CellContext<UserDto, string>) => {
        return (
          <Typography variant="body2" color="textSecondary">
            {getValue()}
          </Typography>
        );
      },
    },
    {
      accessorKey: CompanyUsersTableColumnsEnum.fullName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper
              {...props}
              tableSortLabelProps={{ sx: { '& .MuiTableSortLabel-icon': styles.sortIcon } }}
            >
              <Typography variant="caption">{t(`user_name`)}</Typography>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <TextFieldColumnFilter<UserDto>
              name={CompanyUsersTableColumnsEnum.fullName}
              column={props.column}
              table={props.table}
            />
          ) : null}
        </Grid>
      ),
    },
    {
      accessorKey: CompanyUsersTableColumnsEnum.systemAccess,
      header: (props) => (
        <Grid container direction="column" justifyContent="flex-start">
          <div>
            <Typography variant="caption">{t(`user_accessPermissions`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<UserDto, string>) => {
        return t(`user_${getValue()}`);
      },
    },
    {
      accessorKey: CompanyUsersTableColumnsEnum.signingAuthority,
      header: () => (
        <Grid container direction="column" justifyContent="flex-start">
          <div>
            <Typography variant="caption">{t(`user_signature`)}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<UserDto, number>) => {
        return getValue() === 0 ? t(`userSignature_noSign`) : t(`userSignature_${getValue()}`);
      },
    },
    {
      accessorKey: CompanyUsersTableColumnsEnum.status,
      header: (props) => (
        <Grid container direction="column" justifyContent="flex-start">
          <div>
            <HeaderSortedWrapper
              {...props}
              tableSortLabelProps={{
                sx: { '& .MuiTableSortLabel-icon': styles.sortIcon },
              }}
            >
              <Typography variant="caption">{t(`user_${CompanyUsersTableColumnsEnum.status}`)}</Typography>
            </HeaderSortedWrapper>
          </div>
          {showSubFilters ? (
            <SelectColumnFilter<UserDto>
              name={CompanyUsersTableColumnsEnum.status}
              column={props.column}
              table={props.table}
              options={statusOptionsItems}
              submit
            />
          ) : null}
        </Grid>
      ),
      cell: ({ getValue }: CellContext<UserDto, UserStateStatusEnum>) => {
        return <UserStatusBlock status={getValue()} />;
      },
    },
    {
      id: 'actions',
      header: () => {
        return (
          <Grid container justifyContent="flex-end">
            <div>
              {showSubFilters ? (
                <IconButton onClick={onClearSubFilter} size="large" sx={{ marginRight: '6px' }}>
                  <EraserIcon fontSize="small" />
                </IconButton>
              ) : null}
              {showSubFilters ? (
                <IconButton size="small" onClick={onCloseSubFilter}>
                  <CrossIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={onSearchButtonClick}>
                  <SearchSmallIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </Grid>
        );
      },
      cell: ({ row }: CellContext<UserDto, any>) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Box
            component="span"
            {...{
              onClick: () => row.toggleExpanded(),
              style: { cursor: 'pointer' },
            }}
          >
            {row.getIsExpanded() ? (
              <UpChevronSmallIcon color="primary" fontSize="small" />
            ) : (
              <DownChevronSmallIcon color="primary" fontSize="small" />
            )}
          </Box>
        </Grid>
      ),
    },
  ];
};

import React, { useState } from 'react';

import { Box, Button, Grid, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PrintIcon,
  SignatureIcon,
  StarIcon,
} from '@app/common/icons';
import { useSheetState } from '@app/context/SheetContext';
import { Checkbox } from '@app/core/components/Checkbox';
import { Operation } from '@app/core/types';
import { DATE_FORMAT, formatISODateString, formatAmount } from '@app/core/utils';
import { activeButtons } from '@app/pages/operations/activeButtons';
import { styles } from '@app/pages/operations/mobile/styles';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

interface SheetHeaderProps {
  selectedOperations: Operation[];
  onSignClick(): void;
  onCopyClick(): void;
  onDownloadClick(): void;
  onPrintClick(): void;
  onAddToTemplatesClick(): void;
  onDeleteClick(): void;
  onEditClick(): void;
}

export const SheetHeader: React.FC<SheetHeaderProps> = ({
  selectedOperations,
  onSignClick,
  onCopyClick,
  onDownloadClick,
  onPrintClick,
  onAddToTemplatesClick,
  onDeleteClick,
  onEditClick,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const { t } = useTranslation();
  const { visibleGroupDates } = useSheetState();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const dateInHeader: string | undefined = visibleGroupDates.length
    ? visibleGroupDates.reduce((a, b) => (new Date(a) > new Date(b) ? a : b))
    : undefined;

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  const totalUah = selectedOperations.reduce((acc, operation) => {
    return acc + operation.amount;
  }, 0);

  const buttonsConfig = activeButtons(selectedOperations, permissions);

  return (
    <>
      <Box sx={styles.headerContent}>
        <Typography variant="body2" component="span" color="textSecondary">
          {dateInHeader ? formatISODateString(dateInHeader, DATE_FORMAT) : null}
        </Typography>
      </Box>
      {selectedOperations.length ? (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          wrap="nowrap"
          sx={styles.selectedActionsContainer}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container alignItems="center" xs={9}>
              <Checkbox
                indeterminate
                icon={<CheckboxUncheckedIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                indeterminateIcon={<CheckboxIndeterminateIcon />}
                checked={Boolean(selectedOperations.length)}
                onChange={() => {}}
              />
              <Typography variant="body2" sx={styles.selectedCount}>
                {selectedOperations.length} {t('selected')}
              </Typography>
            </Grid>
            <Grid item container xs={3}>
              <>
                <Button variant="text" sx={styles.buttonRoot} onClick={onMenuBtnClickHandler} endIcon={<MoreIcon />}>
                  <Typography>{t('more')}</Typography>
                </Button>

                <Popover
                  anchorEl={moreMenuAnchorEl}
                  keepMounted
                  open={Boolean(moreMenuAnchorEl)}
                  onClose={handleCloseMoreMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {buttonsConfig.copy ? (
                    <MenuItem sx={styles.menuItem} onClick={onCopyClick}>
                      <ListItemIcon>
                        <CopyIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('copy')}</Typography>
                    </MenuItem>
                  ) : null}

                  {buttonsConfig.edit ? (
                    <MenuItem sx={styles.menuItem} onClick={onEditClick}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('change')}</Typography>
                    </MenuItem>
                  ) : null}

                  {buttonsConfig.download ? (
                    <MenuItem sx={styles.menuItem} onClick={onDownloadClick}>
                      <ListItemIcon>
                        <DownloadIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('download')}</Typography>
                    </MenuItem>
                  ) : null}

                  {buttonsConfig.print ? (
                    <MenuItem sx={styles.menuItem} onClick={onPrintClick}>
                      <ListItemIcon>
                        <PrintIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('print')}</Typography>
                    </MenuItem>
                  ) : null}

                  {buttonsConfig.delete ? (
                    <MenuItem sx={styles.menuItem} onClick={onDeleteClick}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('delete')}</Typography>
                    </MenuItem>
                  ) : null}

                  {buttonsConfig.template ? (
                    <MenuItem sx={styles.menuItem} onClick={onAddToTemplatesClick}>
                      <ListItemIcon>
                        <StarIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('addToTemplate')}</Typography>
                    </MenuItem>
                  ) : null}
                </Popover>
              </>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2" component="span" sx={styles.amountText}>
                {t('amount')}:
              </Typography>
              <Typography variant="body2" component="span" sx={styles.amountValue}>
                {formatAmount(totalUah)}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.amountText}>
                UAH
              </Typography>
            </Box>
            <Box>
              {buttonsConfig.sign ? (
                <Button variant="text" sx={styles.signButton} onClick={onSignClick} endIcon={<SignatureIcon />}>
                  <Typography>{t('sign')}</Typography>
                </Button>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

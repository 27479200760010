import { getClientId, paymentOrdersApiClient } from '@app/core/api';

export const multipleDeleteDocumentFn = async (uuids: Array<string>): Promise<boolean> => {
  const response = await paymentOrdersApiClient.paymentOrderControllerDeleteDocuments(
    getClientId(),
    uuids.map((uuid) => ({
      uuid,
    }))
  );

  if (response.status !== 200) {
    throw new Error("Can't delete a document.");
  }
  return true;
};

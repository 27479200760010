import React, { useState } from 'react';

import { IconButton } from '@mui/material';
import { State, To } from 'history';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftIconSvg } from '@app/themes/default/assets/icons/24x24/arrow/left.svg';

import { isMobile } from '../../utils';
import { ConfirmLeavePagePopup } from '../ConfirmLeavePagePopup';

export type Action = () => boolean;

export interface NavigateToProps {
  delta?: number;
  to?: To;
  options?: {
    replace?: boolean;
    state?: State;
  };
  onClick?(): void;
  showLeavePopup?: Action | boolean;
  confirmQuestionText?: string;
}

export const NavigateToButton: React.FC<NavigateToProps> = ({
  delta,
  to,
  options,
  onClick,
  showLeavePopup,
  confirmQuestionText,
}) => {
  const navigate = useNavigate();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (showLeavePopup) {
      if (typeof showLeavePopup === 'function') {
        if (showLeavePopup()) {
          setLeavePopupOpen(true);
          return;
        }
      } else if (showLeavePopup) {
        setLeavePopupOpen(showLeavePopup);
        return;
      }
    }
    if (delta) {
      navigate(delta);
    }
    if (to) {
      navigate(to, options);
    }
  };

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    if (delta) {
      navigate(delta);
    }
    if (to) {
      navigate(to, options);
    }
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <ArrowLeftIconSvg
          className="MuiSvgIcon"
          style={{
            ...(isMobile
              ? {
                  fill: '#fff',
                }
              : {
                  fill: '#414e62',
                }),
          }}
        />
      </IconButton>
      <ConfirmLeavePagePopup
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onClose={onCancelLeaveHandler}
        questionText={confirmQuestionText || ''}
      />
    </>
  );
};

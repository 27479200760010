import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '@app/common/icons';
import { PaymentOrderErrorMessagesEnum, PaymentTemplateTypeEnum } from '@app/core/api';
import { BankName } from '@app/core/components/BankName';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { styles } from '@app/pages/payment/components/LocalPaymentEditForm/style';
import { RecipientAccountSelect } from '@app/pages/payment/components/RecipientAccountSelect';
import { SavePaymentTemplate } from '@app/pages/payment/components/SavePaymentTemplate';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { FormFieldName, StatePaymentFormProps } from '@app/pages/payment/components/StatePaymentForm/types';
import { TaxesListSelect } from '@app/pages/payment/components/TaxesListSelect';
import { calculateMaxValuationDate } from '@app/pages/payment/create/LocalPayment/helpers';

export const MobileStatePaymentForm: React.FC<StatePaymentFormProps> = ({
  generatedOrderNumber,
  onCancelHandler,
  senderAccountsData,
  taxesList,
  defaultValues,
  permissions,
  additionalInfo,
  onSaveClickHandler,
  onSaveAndSignClickHandler,
  onSendToSign,
  createTemplateData,
  onTemplateChangeHandler,
  errorSeverity,
  blockAction,
}) => {
  const { t } = useTranslation();
  const { getValues, control, setValue } = useFormContext();
  const nowDate = new Date();
  const [maxValuationDate, setMaxValuationDate] = useState<Date>(calculateMaxValuationDate(nowDate));
  const [minValuationDate, setMinValuationDate] = useState<Date>(nowDate);
  const watchDocumentDate = useWatch({ control, name: FormFieldName.documentDate });
  const documentNumberRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (watchDocumentDate) {
      setMaxValuationDate(calculateMaxValuationDate(watchDocumentDate));
      setMinValuationDate(watchDocumentDate);
      setValue(FormFieldName.documentValuationDate, undefined, { shouldValidate: false, shouldDirty: false });
    }
  }, [setValue, watchDocumentDate]);

  useEffect(() => {
    if (additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid container wrap="nowrap" direction="column" sx={styles.contentMobile}>
      <Grid item container direction="column">
        <Box mt={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              <Box pr={4} ref={documentNumberRef}>
                <DocumentNumberField
                  name={FormFieldName.documentNumber}
                  title={t('paymentCreationLocalPayment_paymentOrder')}
                  defaultValue={generatedOrderNumber || 'Auto123'}
                  validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
                  error={additionalInfo?.code === PaymentOrderErrorMessagesEnum.DocumentNumberAlreadyUsed}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={onCancelHandler}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {onTemplateChangeHandler ? (
          <Box mb={6} mt={6}>
            <SelectPaymentTemplate
              templateType={PaymentTemplateTypeEnum.Budgetary}
              onTemplateChange={onTemplateChangeHandler}
              placeholder={t('enterName_placeholder')}
            />
          </Box>
        ) : null}
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_documentDate')}</FormLabel>
          <Box pt={2}>
            <DateSelectField
              name={FormFieldName.documentDate}
              selectedDate={nowDate}
              datePickerProps={{
                disabled: { before: nowDate },
              }}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_valuationDate')}</FormLabel>
          <Box pt={2}>
            <DateSelectField
              name={FormFieldName.documentValuationDate}
              datePickerProps={{
                disabled: {
                  before: minValuationDate,
                  after: maxValuationDate,
                },
              }}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_senderAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              placeholder={t('selectUahAccountPlaceholder')}
              name={FormFieldName.senderAccount}
              options={senderAccountsData?.accounts}
              errorSeverity={errorSeverity}
              defaultAccount={senderAccountsData?.defaultAccount}
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mb={5} />
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationSatePayment_recipientName')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={FormFieldName.recipientName}
              placeholder={t('paymentCreationSatePayment_recipientNamePlaceholder')}
              variant="outlined"
              fullWidth
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationSatePayment_recipientAccount')}</FormLabel>
          <Box pt={2}>
            <RecipientAccountSelect
              name={FormFieldName.recipientAccount}
              defaultValue={defaultValues?.recipientAccount}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationSatePayment_recipientLei')}</FormLabel>
          <Box pt={2}>
            <FormatInputField
              name={FormFieldName.recipientLei}
              placeholder={t('paymentCreationLocalPayment_edrpouPlaceholder')}
              type={FormatType.number}
              maxLength={8}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationSatePayment_bankName')}</FormLabel>
          <Box pt={2}>
            <BankName
              name={FormFieldName.recipientBankName}
              iban={getValues(FormFieldName.recipientAccount)}
              defaultValue={defaultValues?.recipientBankName}
            />
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <FormLabel component="legend">{t('paymentCreationSatePayment_taxesList')}</FormLabel>
            <Box mb={5}>
              <TaxesListSelect
                name={FormFieldName.paymentCode}
                options={taxesList || []}
                placeholder={t('paymentCreationSatePayment_taxesListPlaceholder')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationSatePayment_paymentInfo')}</FormLabel>
          <Box pt={2}>
            <TextAreaField
              name={FormFieldName.paymentInfo}
              textFieldProps={{
                multiline: true,
                fullWidth: true,
                rows: 1,
                placeholder: t('paymentCreationStatePayment_paymentInfoPlaceholder'),
              }}
              maxLength={416}
            />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
          <Box pt={2}>
            <FormatInputField type={FormatType.amount} name={FormFieldName.amount} placeholder={'0.00'} />
          </Box>
        </Box>
        <Box mb={5}>
          <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
          <Box pt={2}>
            <TextAreaField
              name={FormFieldName.paymentReference}
              textFieldProps={{
                multiline: true,
                fullWidth: true,
                rows: 2,
                disabled: true,
              }}
              maxLength={420}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <SavePaymentTemplate createTemplateData={createTemplateData} />
          </Grid>
        </Box>
        <Box mt={6} mb={3}>
          <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
            {permissions.uahOperations.create || permissions.uahOperations.edit ? (
              <Box pr={4} width="40%">
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={blockAction}
                  onClick={onSaveClickHandler}
                >
                  {t('paymentCreationFormControl_save')}
                </Button>
              </Box>
            ) : null}
            {permissions.sign.paymentOrder.allowToSendToSign ? (
              <Box width="60%">
                <Button fullWidth variant="contained" color="primary" disabled={blockAction} onClick={onSendToSign}>
                  {t('paymentCreationFormControl_sendToSign')}
                </Button>
              </Box>
            ) : null}
            {permissions.sign.paymentOrder.allowToSign ? (
              <Box width="60%">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={blockAction}
                  onClick={onSaveAndSignClickHandler}
                >
                  {t('paymentCreationFormControl_send')}
                </Button>
              </Box>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { GetSwiftResponseDto } from '@app/core/api/client';
import { useEditSwiftCounterparty } from '@app/core/hooks/useEditSwiftCounterparty';
import { SwiftCounterpartyModal } from '@app/pages/counterpartiesSwift/components/SwiftCounterpartyModal';
import { SwiftCounterpartyFormData } from '@app/pages/counterpartiesSwift/types';

interface EditCounterpartyProps {
  onClose(): void;
  onSuccessAddCounterparty(): void;
  counterparty?: GetSwiftResponseDto;
}

export const EditSwiftCounterparty: React.FC<EditCounterpartyProps> = ({
  onClose,
  onSuccessAddCounterparty,
  counterparty,
}) => {
  const { mutate } = useEditSwiftCounterparty();

  const onSaveCounterpartyHandler = (value: SwiftCounterpartyFormData) => {
    if (counterparty) {
      mutate(
        { counterpartyData: { ...value, countryCode: value.countryCode.numeric }, id: counterparty?.uuid },
        { onSuccess: onSuccessAddCounterparty }
      );
    }
  };

  return (
    <SwiftCounterpartyModal
      onSaveCounterparty={onSaveCounterpartyHandler}
      show
      onClose={onClose}
      counterparty={counterparty}
    />
  );
};

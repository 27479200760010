import React from 'react';

import { styled } from '@mui/material';

export const PageWrapper = styled('div')(({ theme: { breakpoints, palette } }) => ({
  position: 'relative',
  backgroundColor: palette.colors.mainContentBackgroundColor,
  width: '100%',
  flexGrow: 1,
  paddingLeft: '20px',
  paddingRight: '20px',
  [breakpoints.down('sm')]: {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  [breakpoints.up('md')]: {
    maxWidth: '1440px',
    minWidth: '920px',
  },
}));

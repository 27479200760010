import { UserResourceDto } from '@app/core/api';
import { DocumentStatus, Operation } from '@app/core/types';

export type ActiveButtons = {
  sign: boolean;
  sendToSign: boolean;
  edit: boolean;
  download: boolean;
  copy: boolean;
  delete: boolean;
  print: boolean;
  template: boolean;
};

export const activeButtons = (selectedOperations: Operation[], permissions: UserResourceDto): ActiveButtons => {
  const docsToSign = selectedOperations.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  );
  const showSendToSignButton = selectedOperations.filter((item) => item.actions?.allowToSendToSign).length >= 1;
  const single: boolean = selectedOperations.length === 1;
  const signEnabled: boolean =
    docsToSign.length >= 1 &&
    docsToSign.length <= 10 &&
    selectedOperations.filter((item) => item.actions?.allowToSign).length >= 1 &&
    permissions.sign.paymentOrder.allowToSign;

  const showDeleteButton: boolean =
    selectedOperations.filter((item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved)
      .length >= 1 && permissions.uahOperations.delete;

  const documentStatus = selectedOperations[0]?.status;
  const config: ActiveButtons = {
    sign: signEnabled,
    sendToSign: showSendToSignButton,
    edit: false,
    download: permissions.uahOperations.download,
    copy: false,
    template: single,
    print: permissions.uahOperations.print,
    delete: showDeleteButton,
  };

  if (
    single &&
    permissions.uahOperations.edit &&
    (documentStatus === DocumentStatus.ToSign ||
      documentStatus === DocumentStatus.OnSigning ||
      documentStatus === DocumentStatus.Saved ||
      documentStatus === DocumentStatus.Rejected)
  ) {
    config.edit = true;
  }
  if (
    single &&
    permissions.uahOperations.copy &&
    documentStatus !== DocumentStatus.Processing &&
    documentStatus !== DocumentStatus.Rejected
  ) {
    config.copy = true;
  }

  return config;
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import {
  convertApiEnumToLocal,
  getClientId,
  loanLinesApiApiClient,
  loansTranchesLoanLinesApiClient,
  ApiError,
  CollateralListResponseDto,
  GetOneLoanLineResponseDto,
  PaginationRequestDto,
} from '@app/core/api';
import { DocumentStatus, TablePaginationType, Operation, OperationsTypeEnum } from '@app/core/types';

const getOneLoanLines = async (uuid: string): Promise<GetOneLoanLineResponseDto> => {
  const result = await loanLinesApiApiClient.loansControllerGetLoanLine(getClientId(), uuid);
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get loanLines!');
  }
  return result.data;
};

export const useGetLoanLineByUuid = (uuid: string): UseQueryResult<GetOneLoanLineResponseDto, ApiError> => {
  return useQuery<GetOneLoanLineResponseDto, ApiError>('loanLines_getOneDocument', () => getOneLoanLines(uuid), {
    enabled: false,
  });
};

export type GetRecentOperationsResult = {
  operations: Array<Operation>;
  pagination: TablePaginationType;
};

export const getLoanLineOperationFn = async (
  uuid: string,
  pagination: PaginationRequestDto
): Promise<GetRecentOperationsResult> => {
  const result = await loansTranchesLoanLinesApiClient.loansControllerGetRecentOperations(getClientId(), {
    isLoanLine: true,
    uuid,
    pagination,
  });
  if (result.status !== 200) {
    throw new ApiError(result.request.status, 'Error cant get loanLines operations!');
  }
  const operations: Array<Operation> = result.data.operations.map((operation) => {
    const { type, status } = operation;
    return {
      ...operation,
      type: convertApiEnumToLocal(OperationsTypeEnum, type),
      status: convertApiEnumToLocal(DocumentStatus, status),
    };
  });

  return {
    operations,
    pagination: result.data.pagination,
  };
};

export const getCollateral = async (
  uuid: string,
  pagination: PaginationRequestDto
): Promise<CollateralListResponseDto> => {
  const result = await loanLinesApiApiClient.loansControllerGetLoanLineCollateral(getClientId(), {
    pagination,
    uuid,
  });

  return result.data;
};

import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useOneGetDeposit } from '@app/core/hooks/useGetOneDeposit';
import { DepositCard } from '@app/pages/deposits/show/components/DepositCard';

export const DepositTranchePage: React.FC = () => {
  const { uuid = 'no-uuid' } = useParams();
  const { t } = useTranslation();
  const { data: depositData, mutate } = useOneGetDeposit();

  useEffect(() => {
    mutate(uuid);
  }, [mutate, uuid]);

  return (
    <PageWrapper>
      <PageHeader title={`${t('tranche')} № ${depositData?.agreementNumber || ''}`} showNavigateButton backLink={-1} />
      <Grid container direction="column">
        {depositData ? <DepositCard deposit={depositData} /> : null}
      </Grid>
    </PageWrapper>
  );
};

import React, { useState } from 'react';

import { Box, Collapse, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { GetOneMessageResponseDto } from '@app/core/api';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';

import { styles } from './style';

type MessageStatusHistoryProps = {
  message: GetOneMessageResponseDto;
};

export const MessageStatusHistory: React.FC<MessageStatusHistoryProps> = ({ message }) => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const onclickHistoryHandler = () => {
    setHistoryOpen(!historyOpen);
  };
  const { createDocumentInfo, userHistory } = message;
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={styles.historyHeaderContainer}
        onClick={onclickHistoryHandler}
        className={historyOpen ? '' : 'close'}
      >
        <Typography variant="body2" component="span" sx={styles.historyTitle}>
          {t('messageInfo_statusHistoryTitle')}
        </Typography>
        {historyOpen ? (
          <UpChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
        ) : (
          <DownChevronSmallIcon fontSize="small" sx={styles.chevronIcon} />
        )}
      </Grid>
      <Collapse in={historyOpen} unmountOnExit={false}>
        <Box sx={styles.historyBodyContainer} p={4}>
          <Box>
            <Typography variant="caption" sx={styles.historyItemTitle}>
              {t('messageInfo_letterCreated_title')}
            </Typography>
            <Box mt={2}>
              <Grid container justifyContent="space-between">
                <Typography variant="caption" sx={styles.historyItemName}>
                  {t('paymentInfo_documentCreated_date')}
                </Typography>
                <Typography variant="caption" sx={styles.historyItemValue}>
                  {format(new Date(createDocumentInfo.createDocumentDate), FULL_DATE_TIME_FORMAT)}
                </Typography>
              </Grid>
            </Box>
            <Box mt={2}>
              <>
                {createDocumentInfo.lastName && createDocumentInfo.firstName ? (
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" sx={styles.historyItemName}>
                      {t('paymentInfo_documentCreated_creator')}
                    </Typography>
                    <Typography variant="caption" sx={styles.historyItemValue}>
                      {`${createDocumentInfo.lastName} ${createDocumentInfo.firstName}`}
                    </Typography>
                  </Grid>
                ) : null}
              </>
            </Box>
          </Box>

          {userHistory.length ? (
            <Box mt={4}>
              <Typography variant="caption" sx={styles.historyItemTitle}>
                {t('letterInfo_letterChanges_title')}
              </Typography>
              {userHistory.map((historyItem) => {
                return (
                  <Box key={historyItem.id} mb={4}>
                    <Box mt={2}>
                      <Grid container justifyContent="space-between">
                        <Typography variant="caption" sx={styles.historyItemName}>
                          {t('paymentInfo_documentCreated_date')}
                        </Typography>
                        <Typography variant="caption" sx={styles.historyItemValue}>
                          {format(new Date(historyItem.createdAt), FULL_DATE_TIME_FORMAT)}
                        </Typography>
                      </Grid>
                    </Box>
                    {historyItem.lastName || historyItem.firstName ? (
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_creator')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {historyItem.lastName || ''} {historyItem.firstName || ''}
                          </Typography>
                        </Grid>
                      </Box>
                    ) : null}
                    <Box mt={2}>
                      <Grid container justifyContent="space-between">
                        <Typography variant="caption" sx={styles.historyItemName}>
                          {t('paymentInfo_documentCreated_changes')}
                        </Typography>
                        <Typography variant="caption" sx={styles.historyItemValue}>
                          {t(historyItem.actionType)}
                        </Typography>
                      </Grid>
                    </Box>
                    {historyItem?.signatureBelongsToUser ? (
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('signature')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {historyItem?.signatureBelongsToUser.lastName || ''}{' '}
                            {historyItem?.signatureBelongsToUser.firstName || ''}
                          </Typography>
                        </Grid>
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      </Collapse>
    </>
  );
};

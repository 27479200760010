import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { ApiError, dictionariesApiClient, GetBankCodesDto, GetBankCodesResponseDto } from '@app/core/api';

const getBankCodesQueryFn = async (filter: GetBankCodesDto): Promise<GetBankCodesResponseDto> => {
  const response = await dictionariesApiClient.dictionariesControllerGetBankCodes({ ...filter });

  return response.data;
};

export const useGetBankCodes = (): UseMutationResult<GetBankCodesResponseDto, ApiError, GetBankCodesDto> => {
  return useMutation<GetBankCodesResponseDto, ApiError, GetBankCodesDto>(getBankCodesQueryFn);
};

export const styles = {
  cellTitle: {
    color: 'colors.primary600',
    fontWeight: 600,
    pr: '16px',
  },
  chevronIcon: {
    color: 'colors.gray200',
  },
  info: {
    color: 'colors.primary500',
  },
};

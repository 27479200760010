import React, { useState } from 'react';

import { InternalLoanSortingRuleDto, LoanDto, PaginationResponseDto } from '@app/core/api';
import { TableColumnConfig, PaginationChangeType } from '@app/core/types';
import { LoansTablePreference } from '@app/pages/loans/components/LoansTable/components/LoansTablePreference';
import { TableContent } from '@app/pages/loans/components/LoansTable/components/TableContent';

export interface LoansTableProps {
  loans: LoanDto[];
  columnsConfig: TableColumnConfig[];
  pagination?: PaginationResponseDto;
  loading?: boolean;
  queryKey?: string;
  onPaginationChange(pagination: PaginationChangeType): void;
  columnsConfigMutationFn(loansTableColumnsData: TableColumnConfig[]): Promise<TableColumnConfig[]>;
  onSuccessConfigurationSaved(tableColumnsConfig: TableColumnConfig[]): void;
  onSort?(rule: InternalLoanSortingRuleDto): void;
}

export const LoansTable: React.FC<LoansTableProps> = ({
  loans,
  pagination,
  loading,
  queryKey,
  columnsConfig,
  onSort,
  onPaginationChange,
  columnsConfigMutationFn,
  onSuccessConfigurationSaved,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShow = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <TableContent
        loading={loading}
        columnsConfig={columnsConfig}
        loans={loans}
        onSettingsButtonClick={handleShow}
        pagination={pagination}
        onSort={onSort}
        queryKey={queryKey}
        onPaginationChange={onPaginationChange}
      />

      {showModal ? (
        <LoansTablePreference
          show={showModal}
          columnsConfig={columnsConfig}
          columnsConfigMutationFn={columnsConfigMutationFn}
          handleShow={handleShow}
          onSuccessConfigurationSaved={onSuccessConfigurationSaved}
        />
      ) : null}
    </>
  );
};

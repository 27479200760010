import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import { format, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { Checkbox } from '@app/core/components/Checkbox';
import { HeaderSortedWrapper } from '@app/core/components/ComplexTable/components/HeaderSortedWrapper';
import { Ellipsis } from '@app/core/components/Ellipsis';
import { IBanText } from '@app/core/components/IBanText';
import { SettingsButton } from '@app/core/components/SettingsButton';
import { formatAmount } from '@app/core/utils';
import { styles } from '@app/pages/payment/import/components/styles';
import { OrderFieldEnum, OrderRow } from '@app/pages/payment/import/types';
import { appConfig } from '@app/src/config';

interface MakeTableColumnsProps {
  t(text: string): string;
  onSettingsButtonClick(): void;
}

type StatusFieldProps = {
  valid: boolean;
};

const StatusField: React.FC<StatusFieldProps> = ({ valid }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Box
        component="span"
        mr={1}
        sx={{ ...styles.status, ...(valid ? styles.statusCorrect : styles.statusIncorrect) }}
      />
      <Typography variant="caption" color="textSecondary">
        {valid ? t(`paymentImport_tableColumn_status_correct`) : t(`paymentImport_tableColumn_status_incorrect`)}
      </Typography>
    </Grid>
  );
};

export const makeImportTableColumns = ({
  t,
  onSettingsButtonClick,
}: MakeTableColumnsProps): ColumnDef<OrderRow, any>[] => {
  return [
    {
      id: 'selection',
      header: ({ table }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </Grid>
      ),
      cell: ({ row }) => (
        <Grid container alignItems="center">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.paymentDocumentNumber,
      header: (props) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <div>
              <HeaderSortedWrapper {...props}>
                <Typography variant="caption">{t('paymentImport_tableColumn_paymentDocumentNumber')}</Typography>
              </HeaderSortedWrapper>
            </div>
          </Grid>
        );
      },
    },
    {
      accessorKey: OrderFieldEnum.paymentDocumentDate,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_paymentDocumentDate')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => (
        <>{isValid(parseISO(getValue())) ? format(new Date(getValue().split('T')[0]), 'dd.MM.yyyy') : null}</>
      ),
    },
    {
      accessorKey: OrderFieldEnum.senderName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_senderName')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.senderAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_senderAccount')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <IBanText text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.senderLei,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_senderLei')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.senderBank,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_senderBank')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.senderBankCode,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_senderBankCode')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.recipientName,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_recipientName')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.recipientAccount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_recipientAccount')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <IBanText text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.recipientLei,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_recipientLei')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.recipientBank,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_recipientBank')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.recipientBankCode,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_recipientBankCode')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.paymentReference,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('paymentImport_tableColumn_paymentReference')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, string>) => {
        return <Ellipsis text={getValue()} />;
      },
    },
    {
      accessorKey: OrderFieldEnum.currency,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_currency')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
    },
    {
      accessorKey: OrderFieldEnum.amount,
      header: (props) => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <HeaderSortedWrapper {...props}>
              <Typography variant="caption">{t('paymentImport_tableColumn_amount')}</Typography>
            </HeaderSortedWrapper>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, number>) => {
        return formatAmount(getValue());
      },
    },
    {
      accessorKey: OrderFieldEnum.status,
      header: () => (
        <Grid container direction="column" justifyContent="center">
          <div>
            <Typography variant="caption">{t('statementsTableColumn_documentStatus')}</Typography>
          </div>
        </Grid>
      ),
      cell: ({ getValue }: CellContext<OrderRow, boolean>) => {
        return <StatusField valid={getValue()} />;
      },
    },
    {
      id: 'actions',
      header: () => {
        return (
          <Grid container justifyContent="center">
            {appConfig.enableNextFeatures ? <SettingsButton onClick={onSettingsButtonClick} /> : null}
          </Grid>
        );
      },
      cell: ({ row }) => {
        return (
          <Grid container justifyContent="center">
            {row.original.status ? (
              <Box
                component="span"
                {...{
                  onClick: () => row.toggleExpanded(),
                }}
                sx={styles.chevron}
              >
                {row.getIsExpanded() ? (
                  <UpChevronSmallIcon fontSize="small" />
                ) : (
                  <DownChevronSmallIcon fontSize="small" />
                )}
              </Box>
            ) : null}
          </Grid>
        );
      },
    },
  ];
};

import React from 'react';

import { Grid, InputAdornment, OutlinedInput, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AccountStatusEnum } from '@app/core/api';
import { isMobile } from '@app/core/utils';
import { NavigationItem, NavigationMenu } from '@app/pages/accounts/components/FilterPanel/AccountsNavigationMenu';
import { ReactComponent as SearchIcon } from '@app/themes/default/assets/icons/search.svg';

export interface FilterPanelProps {
  activeCount: number;
  blockedCount: number;
  closedCount: number;
  selectItem: AccountStatusEnum;
  onFilterChange(filter: AccountStatusEnum): void;
  onSearchChange(query: string): void;
}

export const FilterPanel: React.FC<FilterPanelProps> = ({
  activeCount,
  blockedCount,
  closedCount,
  selectItem,
  onFilterChange,
  onSearchChange,
}) => {
  const { t } = useTranslation();

  const menuItems: NavigationItem[] = [
    {
      value: AccountStatusEnum.Active,
      text: t('activeCounts'),
    },
    {
      value: AccountStatusEnum.Blocked,
      text: t('blockedCounts'),
    },
    {
      value: AccountStatusEnum.Closed,
      text: t('closedCounts'),
    },
  ];

  const onChangeQueryFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <NavigationMenu
          items={menuItems}
          activeCount={activeCount}
          blockedCount={blockedCount}
          closedCount={closedCount}
          selectItem={selectItem}
          onSelect={onFilterChange}
        />
      </Grid>
      <Grid>
        {isMobile ? null : (
          <OutlinedInput
            onChange={onChangeQueryFilter}
            endAdornment={
              <InputAdornment position="end">
                <Box sx={{ color: 'colors.gray300' }}>
                  <SearchIcon
                    style={{
                      fontSize: '14px',
                      fill: 'currentColor',
                    }}
                  />
                </Box>
              </InputAdornment>
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

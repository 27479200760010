import { format, isLeapYear } from 'date-fns';

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const FULL_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const REGEX_INT = /\d+/g;

export const monthLength: { [key: string]: string } = {
  '01': '31',
  '02': '28',
  '03': '31',
  '04': '30',
  '05': '31',
  '06': '30',
  '07': '31',
  '08': '31',
  '09': '30',
  '10': '31',
  '11': '30',
  '12': '31',
};

export const parseDigits = (str: string): string => (str.match(REGEX_INT) || []).join('');

export const appendHyphen = (str: string, hyphen: string): string =>
  str.length === 2 || str.length === 5 ? `${str}${hyphen}` : str;

export default function limit(value: string, max: string): string {
  let newValue = value;
  if (value.length === 2) {
    if (Number(value) === 0) {
      newValue = '01';
    } else if (value > max) {
      newValue = max;
    }
  }

  return newValue;
}

export const getMaxDate = (month: string, year: string): string => {
  if (month.length === 2) {
    if (isLeapYear(Number(year)) && Number(month) === 2) {
      return '29';
    }

    return monthLength[month];
  }

  return '31';
};

export const formatDate = (str: string, hyphen: string): string => {
  const concatDate = (value: string) => (value.length ? `${hyphen}${value}` : '');
  const digits = parseDigits(str);

  const year = digits.substring(4, 8);
  const month = limit(digits.substring(2, 4), '12');
  const date = limit(digits.substring(0, 2), getMaxDate(month, year));

  return date + concatDate(month) + concatDate(year);
};

export const formatDateWithAppend = (str: string, hyphen: string): string => {
  const res = formatDate(str, hyphen);

  if (str.endsWith(hyphen)) {
    return appendHyphen(res, hyphen);
  }
  return res;
};

export const formatISODateString = (date: string, formatStr: string): string =>
  format(new Date(date.replace('Z', '')), formatStr);

// return nearest index of Date list
export const nearestDate = (dates: Date[], targetDate?: Date | number): number => {
  let target: number;
  if (!targetDate) {
    target = Date.now();
  } else if (targetDate instanceof Date) {
    target = targetDate.getTime();
  }

  let nearest = Infinity;
  let minDistIndex = -1;

  dates.forEach(function (date, index) {
    const distance = Math.abs(date.getTime() - target);
    if (distance < nearest) {
      nearest = distance;
      minDistIndex = index;
    }
  });

  return minDistIndex;
};

export enum CreatePaymentTab {
  localPayment = 'localPayment',
  betweenOwnAccounts = 'betweenOwnAccounts',
  statePayment = 'statePayment',
}

export type LocalPaymentUrlQuery = {
  copyFrom?: string;
  lid?: string; // loans id (uuid of loans payment)
  oid?: string; // overdraft id (uuid of overdraft payment)
  did?: string; // deposit id (uuid of deposit payment)
  rt?: string; // repayment type
  line?: string; // is loans line
};

import React, { JSX, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Grid, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { PaginationRequestDto, StatementConfigDto, StatementTemplateTypeEnum, StatementTypeEnum } from '@app/core/api';
import { ComplexTable, ConfirmPopup, ExpandedTableRow, TableCell, TableRow } from '@app/core/components';
import { BodyLoader } from '@app/core/components/OperationsTable/components/BodyLoader';
import {
  useCreateStatementsConfig,
  useDeleteStatementsConfig,
  useGetStatementsConfigList,
  useUpdateStatementsConfig,
} from '@app/core/hooks';
import { MonitoringStatementsForm } from '@app/pages/profile/desktop/components/Monitoring/components/StatementsConfigs/MonitoringStatementsForm';
import { StatementsConfigRow } from '@app/pages/profile/desktop/components/Monitoring/components/StatementsConfigs/StatementsConfigRow';
import { MonitoringFormData } from '@app/pages/profile/desktop/components/Monitoring/types';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

import { styles } from './styles';

interface StatementsConfigsListProps {
  onCancel(): void;
}

export const StatementsConfigList: React.FC<StatementsConfigsListProps> = ({ onCancel }) => {
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const deleteConfigurationUuidRef = useRef<string | undefined>();
  const { t } = useTranslation();
  const [createNewConfigMode, setCreateNewConfigMode] = useState<boolean>(false);
  const [expandedId, setExpandedId] = useState<string | undefined>(undefined);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const { data, mutate: mutateStatementsConfig, isLoading: isLoading1 } = useGetStatementsConfigList();
  const { mutateAsync: mutateAsyncCreateConfig, isLoading: isLoading2 } = useCreateStatementsConfig();
  const { mutateAsync: mutateAsyncUpdateConfig, isLoading: isLoading3 } = useUpdateStatementsConfig();
  const { mutateAsync: mutateAsyncDeleteConfig, isLoading: isLoading4 } = useDeleteStatementsConfig();

  const [pagination, setPagination] = useState<PaginationRequestDto>({
    rowsPerPage: 1000,
    page: 1,
  });

  useEffect(() => {
    mutateStatementsConfig({
      pagination,
    });
  }, [mutateStatementsConfig, pagination]);

  const onOpenExtendedContent = (id: string) => {
    setExpandedId(id);
    setCreateNewConfigMode(false);
  };

  useEffect(() => {
    showLoader(isLoading1 || isLoading2 || isLoading3 || isLoading4);
  }, [isLoading1, isLoading2, isLoading3, isLoading4, showLoader]);

  const handleExpandCreateNewConfig = () => {
    setCreateNewConfigMode(!createNewConfigMode);
  };

  const onSaveNewConfigHandler = (formData: MonitoringFormData) => {
    const { operationAccounts, periodType, outputType } = formData;
    const newStatementConfigDto: StatementConfigDto = {
      periodType,
      time: '12:00:00',
      requestData: {
        operationAccounts: operationAccounts.map((account) => ({
          id: account.account.id,
          currencyCode: account.account.currency,
        })),
        outputType,
        documentType: StatementTypeEnum.Statement,
        templateType: StatementTemplateTypeEnum.Default,
      },
    };

    mutateAsyncCreateConfig(newStatementConfigDto)
      .then((result) => {
        setCreateNewConfigMode(false);
        setExpandedId(undefined);
        mutateStatementsConfig({ pagination });
      })
      .catch((e) => {
        notify({
          notifyProps: {
            message: e,
            severity: 'warning',
          },
        });
      });
  };

  const onDeleteHandler = (uuid: string) => {
    deleteConfigurationUuidRef.current = uuid;
    setShowConfirmDeleteDialog(true);
  };

  const BodyContent: JSX.Element = useMemo(() => {
    const onUpdateConfig = (uuid: string, updateStatementConfigDto: StatementConfigDto) => {
      mutateAsyncUpdateConfig({ uuid, updateStatementConfigDto })
        .then((result) => {
          setExpandedId(undefined);
          mutateStatementsConfig({ pagination });
        })
        .catch((e) => {
          notify({
            notifyProps: {
              message: e,
              severity: 'warning',
            },
          });
        });
    };
    return (
      <>
        {data?.statements
          ? data.statements.map((statementsConfig) => (
              <StatementsConfigRow
                key={statementsConfig.uuid}
                open={statementsConfig.uuid === expandedId}
                statementsConfig={statementsConfig}
                onCancel={() => {}}
                onSave={onUpdateConfig}
                onOpenExtendedContent={onOpenExtendedContent}
                onDelete={onDeleteHandler}
              />
            ))
          : null}
      </>
    );
  }, [data, expandedId, mutateAsyncUpdateConfig, mutateStatementsConfig, notify, pagination]);

  const onAddNewConfigHandler = () => {
    setExpandedId(undefined);
    setCreateNewConfigMode(true);
  };

  const onCancelNewConfigHandler = () => {
    setCreateNewConfigMode(false);
  };

  const onConfirmDeleteConfigHandler = () => {
    setShowConfirmDeleteDialog(false);
    if (deleteConfigurationUuidRef.current) {
      mutateAsyncDeleteConfig(deleteConfigurationUuidRef.current)
        .then((result) => {
          deleteConfigurationUuidRef.current = undefined;
          mutateStatementsConfig({ pagination });
          notify({
            notifyProps: {
              message: t('documentChangeAction_Deleted'),
              severity: 'success',
            },
          });
        })
        .catch(() => {
          notify({
            notifyProps: {
              message: t('error'),
              severity: 'error',
            },
          });
        });
    }
  };

  const onCancelDeleteConfigHandler = () => {
    deleteConfigurationUuidRef.current = undefined;
    setShowConfirmDeleteDialog(false);
  };
  const HeadContent: JSX.Element = useMemo(() => {
    return (
      <>
        <TableRow>
          <TableCell sx={{ ...styles.tableCell, ...styles.tableCellAccounts }}>{t('accounts')}</TableCell>
          <TableCell sx={styles.tableCell}>{t('statementFor')}</TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.tableCellDelivery }}>{t('deliveryChannel')}</TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.actionCell }} />
        </TableRow>
      </>
    );
  }, [t]);

  const isLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4;

  return (
    <>
      <ComplexTable
        head={{
          content: HeadContent,
        }}
        body={{
          content: isLoading ? <BodyLoader /> : BodyContent,
          styles: {
            border: 'none',
          },
        }}
        footer={{
          content: (
            <Toolbar
              disableGutters
              sx={{
                width: '100%',
                justifyItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {createNewConfigMode ? (
                <ExpandedTableRow
                  open
                  onExpand={handleExpandCreateNewConfig}
                  extendedContend={
                    <MonitoringStatementsForm onCancel={onCancelNewConfigHandler} onSave={onSaveNewConfigHandler} />
                  }
                  sx={{
                    ...styles.tableRow,
                    ...styles.tableRowExpanded,
                    ...{ justifyContent: 'flex-end' },
                  }}
                >
                  <TableCell component="div" sx={{ ...styles.tableCell }}>
                    <DownChevronSmallIcon sx={styles.chevronIcon} fontSize="small" />
                  </TableCell>
                </ExpandedTableRow>
              ) : (
                <Grid container alignItems="center" sx={{ paddingTop: '12px' }}>
                  {!createNewConfigMode ? (
                    <>
                      <Grid item container xs={4} />
                      <Grid item container justifyContent="center" xs={4}>
                        {data?.statements.length && !isLoading1 ? null : <Typography>{t('noSettings')}</Typography>}
                      </Grid>
                      <Grid item container justifyContent="flex-end" xs={4}>
                        <Button variant="outlined" color="primary" onClick={onAddNewConfigHandler}>
                          {t('addSettings')}
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              )}
            </Toolbar>
          ),
        }}
      />
      {showConfirmDeleteDialog ? (
        <ConfirmPopup
          open
          onConfirm={onConfirmDeleteConfigHandler}
          onClose={onCancelDeleteConfigHandler}
          questionText={t('areYouSureYouWantToDelete')}
          icon={<DeleteIcoBgSVG />}
          classes={{
            questionTextContainer: {
              fontWeight: 600,
              color: 'colors.gray500',
            },
          }}
        />
      ) : null}
    </>
  );
};

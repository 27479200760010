import React from 'react';

import { ReactComponent as CloudIconSvg } from '@app/themes/default/assets/icons/cloud.svg';

import { ConfirmPopup } from '../ConfirmPopup';

interface LeavePopupProps {
  open: boolean;
  questionText: string;
  onConfirm(): void;
  onCancel(): void;
}

export const LeaveEditPopup: React.FC<LeavePopupProps> = ({ open, questionText, onConfirm, onCancel }) => {
  return (
    <ConfirmPopup
      open={open}
      onConfirm={onConfirm}
      onClose={onCancel}
      questionText={questionText}
      icon={<CloudIconSvg />}
      classes={{
        questionTextContainer: {
          width: '85%',
          textAlign: 'center',
        },
      }}
    />
  );
};

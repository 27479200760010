export const styles = {
  form: {
    width: '100%',
  },
  body: {
    position: 'relative',
    backgroundColor: 'colors.gray25',
    borderRadius: '8px',
    padding: '20px',
    minWidth: '600px',
  },
  sendButton: {
    marginLeft: '28px',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  btnIcon: {
    marginLeft: '8px',
  },
  headerFile: {
    color: 'colors.pgray500',
    fontWeight: 'bold',
    marginRight: '4px',
  },
  dropZoneContainer: {
    backgroundColor: '#FFFFFF',
    border: `1.5px dashed`,
    borderColor: 'colors.primary200',
    borderRadius: '8px',
    padding: '20px',
    minHeight: '80px',
  },
  selectFileFromDiskText: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: 'colors.primary500',
    textAlign: 'center',
    cursor: 'pointer',
    paddingLeft: '4px',
  },
  maxFileSize: {
    color: 'colors.gray300',
  },
  dropContainerActive: {
    backgroundColor: 'colors.primary25',
  },
  noAttachedFilesLabel: {
    paddingLeft: '8px',
    color: 'colors.gray400',
  },
  icon: {
    color: 'colors.gray500',
  },
  iconDelete: {
    color: 'colors.gray400',
    cursor: 'pointer',
  },
  fileSize: {
    color: 'colors.gray400',
    fontSize: '0.75rem',
    lineHeight: '18px',
    paddingLeft: '12px',
    paddingRight: '24px',
  },
  attachedFilesInfo: {
    color: 'colors.primary700',
  },
};

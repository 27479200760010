import React from 'react';

import { Grid } from '@mui/material';
import { ModalProps } from '@mui/material/Modal';

import { ModalCommon } from './ModalCommon';

interface ModalPopupCommonProps extends ModalProps {
  questionPaper?: boolean;
}

export const ModalPopupCommon: React.FC<ModalPopupCommonProps> = ({ children, questionPaper, ...props }) => {
  return (
    <ModalCommon {...props}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          backgroundColor: 'white',
          padding: (theme) => theme.spacing(7),
          outline: 0,
          borderRadius: (theme) => theme.spacing(2),
          width: '100%',
          maxWidth: questionPaper ? 500 : 716,
          maxHeight: '75vh',
        }}
      >
        {children}
      </Grid>
    </ModalCommon>
  );
};

import React from 'react';

import { Grid } from '@mui/material';
import MuiTableBody, { TableBodyProps } from '@mui/material/TableBody';

export const TableBody: React.FC<TableBodyProps<typeof Grid>> = ({ children, ...otherProps }) => {
  return (
    <MuiTableBody component={Grid} {...otherProps}>
      {children}
    </MuiTableBody>
  );
};

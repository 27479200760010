import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, profileApiClient, ApiError } from '@app/core/api';

const profileControllerSendRequestFn = async (topic: string): Promise<{}> => {
  const result = await profileApiClient.profileControllerSendRequest(getClientId(), { topic: topic });
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't send request");
  }
  return result.data;
};

export const useProfileControllerSendRequest = (topic: string): UseQueryResult<{}, ApiError> => {
  return useQuery<{}, ApiError>('productRequest', () => profileControllerSendRequestFn(topic), {
    enabled: false,
  });
};

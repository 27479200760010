import React, { useState } from 'react';

import { Box, Button, Collapse, FormControl, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  DownChevronSmallIcon,
  NotifySmallIcon,
  PaperIcon,
  SecondSignature,
  UpChevronSmallIcon,
} from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  DocumentStatusEnum,
  FrontLanguageEnum,
  GetDocumentWithShortOperationsInfoResponseDto,
  UserProfileDto,
} from '@app/core/api';
import { getCurrentPersonInfoStatusIcon } from '@app/core/helpers';
import { useSendRemindCurrencyDocumentSign } from '@app/core/hooks/useSendRemindCurrencyDocumentSign';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';
import { getActionTranslationKeyByActionTypeEnum } from '@app/pages/documents/components/DocumentHistory/helper';
import { styles } from '@app/pages/documents/components/DocumentHistory/style';
import { DocumentIconByStatus } from '@app/pages/documents/components/DocumentIconByStatus';

type DocumentHistoryProps = {
  document: GetDocumentWithShortOperationsInfoResponseDto;
  userProfile: UserProfileDto;
  edit: boolean;
};

export const DocumentHistory: React.FC<DocumentHistoryProps> = ({ document, edit, userProfile }): JSX.Element => {
  const [showAttorney, setShowAttorney] = useState<number>();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { mutate } = useSendRemindCurrencyDocumentSign();

  const { notify } = useNotify();

  const { status, signInfo, createDocumentInfo, userHistory, uuid } = document;

  const onClickHistoryHandler = () => {
    setHistoryOpen(!historyOpen);
  };

  const currentSigner = signInfo.personToSign.filter(
    (person) => person?.main?.lastName === userProfile?.lastName || person?.attorney?.lastName === userProfile?.lastName
  );
  const otherSigner = signInfo.personToSign.filter(
    (person) => person?.main?.lastName !== userProfile?.lastName && person?.attorney?.lastName !== userProfile?.lastName
  );
  const signerList = [...currentSigner, ...otherSigner];

  const openAttorney = (id: number) => {
    setShowAttorney(id);
  };

  const closeAttorney = () => {
    setShowAttorney(undefined);
  };

  const getSignAuthorityText = (authority: number) => {
    if (authority === 1) {
      return t('firstSign');
    }
    if (authority === 2) {
      return t('secondSign');
    }
  };

  const onNotifyButtonClick = () => {
    mutate(
      { uuid, language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          notify({
            notifyProps: {
              title: t('success'),
              message: t('remindSuccessMessage'),
              severity: 'success',
            },
          });
        },
        onError: () => {
          notify({
            notifyProps: {
              title: t('error'),
              message: t('remindErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };

  return (
    <Box ml={5} mr={5}>
      <Box>
        <Typography variant="body1" sx={styles.title}>
          {t('currencySaleInfo_currentStatus_title')}
        </Typography>
        <Box mt={2} mb={2}>
          <Grid container alignItems="center">
            {status ? <DocumentIconByStatus status={status} /> : <PaperIcon fontSize="small" sx={styles.statusIcon} />}
            <Box ml={2}>
              <Typography variant="body2" component="span" sx={styles.value}>
                {status ? t(status) : t('documentStatus_new')}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box sx={styles.hr} mb={4} mt={4} />

      <Box mt={6} mb={4}>
        <Typography variant="body1" sx={styles.title}>
          {t('currencySaleInfo_toSignList_title')}
        </Typography>
        <Box mt={2}>
          <Grid container alignItems="center">
            {signInfo.personToSign.map(({ main, attorney, status: personInfoStatus, signingAuthority }, idx) => {
              return (
                <Grid key={idx} sx={styles.signerContainer}>
                  <Grid container alignItems="center" justifyContent="space-between" sx={styles.signerContainer}>
                    <Grid item xs={11} container alignItems="center">
                      {getCurrentPersonInfoStatusIcon(personInfoStatus)}
                      <Box component="span" ml={2}>
                        {attorney?.lastName === userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                        <Typography variant="body2" component="span" sx={styles.value}>
                          {attorney?.lastName === userProfile.lastName
                            ? `${attorney.lastName} ${attorney.firstName}`
                            : `${main?.lastName} ${main?.firstName}`}
                        </Typography>
                      </Box>
                    </Grid>
                    {main && attorney ? (
                      <Grid item xs={1} sx={styles.toggleButton}>
                        {showAttorney === idx ? (
                          <UpChevronSmallIcon fontSize="small" onClick={closeAttorney} />
                        ) : (
                          <DownChevronSmallIcon
                            fontSize="small"
                            onClick={() => {
                              openAttorney(idx);
                            }}
                          />
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Box ml={6}>
                    <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                      {getSignAuthorityText(signingAuthority)}
                    </Typography>
                  </Box>
                  {showAttorney === idx ? (
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      flexDirection="column"
                      sx={styles.attorneyContainer}
                    >
                      <Grid item container alignItems="center" justifyContent="flex-start">
                        <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                          {t('attorneyTitle')}
                        </Typography>
                      </Grid>
                      <Grid item container alignItems="center" justifyContent="flex-start">
                        {attorney?.lastName !== userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                        <Box>
                          <Typography variant="body2" component="span" sx={styles.value}>
                            {attorney?.lastName === userProfile.lastName
                              ? `${main?.lastName} ${main?.firstName}`
                              : `${attorney?.lastName} ${attorney?.firstName}`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {status === DocumentStatusEnum.OnSigning && !edit ? (
        <>
          <Box mt={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={onNotifyButtonClick}
                endIcon={<NotifySmallIcon fontSize="small" />}
              >
                {t('paymentInfo_alarmNotifyButton')}
              </Button>
            </FormControl>
          </Box>
        </>
      ) : null}
      {createDocumentInfo ? (
        <Box pt={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={styles.headerContainer}
            className={!historyOpen ? 'close' : ''}
            onClick={onClickHistoryHandler}
          >
            <Typography variant="body2" component="span" sx={styles.collapseTitle}>
              {t('currencySaleInfo_historyTitle')}
            </Typography>
            {historyOpen ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
          </Grid>
          <Collapse in={historyOpen} unmountOnExit={false}>
            <Box sx={styles.collapseBodyContainer} p={4}>
              <Box>
                <Typography variant="caption" sx={styles.collapseItemTitle}>
                  {t('paymentInfo_documentCreated_title')}
                </Typography>
                <Box mt={2}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" sx={styles.collapseItemName}>
                      {t('paymentInfo_documentCreated_date')}
                    </Typography>
                    <Typography variant="caption" sx={styles.collapseItemValue}>
                      {format(new Date(createDocumentInfo.createDocumentDate), FULL_DATE_TIME_FORMAT)}
                    </Typography>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="caption" sx={styles.collapseItemName}>
                      {t('paymentInfo_documentCreated_creator')}
                    </Typography>
                    <Typography variant="caption" sx={styles.collapseItemValue}>
                      {`${createDocumentInfo.lastName} ${createDocumentInfo.firstName}`}
                    </Typography>
                  </Grid>
                </Box>
              </Box>

              {userHistory && userHistory.length ? (
                <Box mt={4}>
                  <Typography variant="caption" sx={styles.collapseItemTitle}>
                    {t('paymentInfo_documentChanges_title')}
                  </Typography>
                  {userHistory.map((historyItem) => {
                    return (
                      <Box key={historyItem.id} mb={4}>
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('paymentInfo_documentCreated_date')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {format(new Date(historyItem.createdAt), FULL_DATE_TIME_FORMAT)}
                            </Typography>
                          </Grid>
                        </Box>
                        {historyItem.lastName || historyItem.firstName ? (
                          <Box mt={2}>
                            <Grid container justifyContent="space-between">
                              <Typography variant="caption" sx={styles.historyItemName}>
                                {t('paymentInfo_documentCreated_creator')}
                              </Typography>
                              <Typography variant="caption" sx={styles.historyItemValue}>
                                {historyItem.lastName || ''} {historyItem.firstName || ''}
                              </Typography>
                            </Grid>
                          </Box>
                        ) : null}
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('paymentInfo_documentCreated_changes')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {t(getActionTranslationKeyByActionTypeEnum(historyItem.actionType))}
                            </Typography>
                          </Grid>
                        </Box>
                        {historyItem?.signatureBelongsToUser ? (
                          <Box mt={2}>
                            <Grid container justifyContent="space-between">
                              <Typography variant="caption" sx={styles.historyItemName}>
                                {t('signature')}
                              </Typography>
                              <Typography variant="caption" sx={styles.historyItemValue}>
                                {historyItem?.signatureBelongsToUser.lastName || ''}{' '}
                                {historyItem?.signatureBelongsToUser.firstName || ''}
                              </Typography>
                            </Grid>
                          </Box>
                        ) : null}
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Collapse>
        </Box>
      ) : null}
    </Box>
  );
};

import React, { useRef, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CrossIcon, DeleteIcon, LinkIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { DocumentOperationType, OperationsWithoutFilesDto } from '@app/core/api';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { Tooltip } from '@app/core/components/Tooltip';
import { useCurrencyUploadFiles } from '@app/core/hooks';
import { FilesEnum } from '@app/core/types';
import { OperationsTabs } from '@app/pages/documents/components/AddCurrencyDocumentModal/components/OperationsTab';
import { RelateDocumentsSelect } from '@app/pages/documents/components/AddCurrencyDocumentModal/components/RelateDocumentsSelect';
import { styles } from '@app/pages/documents/components/AddCurrencyDocumentModal/style';
import { RouteList } from '@app/src/constants/routeList';

interface AddCurrencyDocumentModalProps {
  onClose(): void;
  onSuccessImportDocument(): void;
}

export const AddCurrencyDocumentModal: React.FC<AddCurrencyDocumentModalProps> = ({
  onClose,
  onSuccessImportDocument,
}) => {
  const { notify } = useNotify();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [oveDragZone, setOveDragZone] = useState<boolean>(false);
  const [relateDocumentCheck, setRelateDocumentCheck] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [relatedOperations, setRelatedOperations] = useState<OperationsWithoutFilesDto[]>([]);
  const [operationsTab, setOperationsTab] = useState<DocumentOperationType>(DocumentOperationType.CurrencyOperation);
  const [fileToParse, setFileToParse] = useState<FormData>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const methods = useForm({ mode: 'onSubmit' });
  const { control } = methods;

  const watchComment = useWatch({
    control,
    name: 'comment',
  });

  const { mutateAsync: mutateAsyncUploadFiles } = useCurrencyUploadFiles();

  const handleFile = (file: File) => {
    if (file.name.toLocaleLowerCase().substr(-3) !== FilesEnum.pdf) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file, encodeURIComponent(file.name));
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setFileToParse(formData);
      }
    };
    reader.readAsText(file);
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(true);
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
  };

  const fileInputClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current) {
      if (fileInputRef.current.files) {
        if (fileInputRef.current.files.length > 0) {
          handleFile(fileInputRef.current.files[0]);
        }
      }
    }
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOveDragZone(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleImportClickBtn = () => {
    if (fileToParse) {
      mutateAsyncUploadFiles({
        currencyOperationIds: relatedOperations.map((operation) => operation.uuid),
        file: fileToParse,
        operationsTypes: relatedOperations.map((operation) => operation.operationType),
        comments: watchComment,
      })
        .then(() => {
          notify({
            notifyProps: {
              title: t('currencyExchange_saveSuccessTitle'),
              message: t('addCurrencyDocument_saveSuccessMessage'),
              severity: 'success',
            },
          });
          onSuccessImportDocument();
        })
        .catch(() => {
          notify({
            notifyProps: {
              title: t('attachFile_ErrorTitle'),
              message: t('attachFile_ErrorMessage'),
              severity: 'error',
            },
          });
        });
    }
  };

  const operationsTabsChangeHandler = (activeTab: DocumentOperationType) => {
    setOperationsTab(activeTab);
  };

  const onHandleClose = () => {
    onClose();
  };

  const relateDocumentCheckHandler = () => {
    setRelateDocumentCheck(!relateDocumentCheck);
    setRelatedOperations([]);
  };

  const selectOperationsHandler = (operation: OperationsWithoutFilesDto) => {
    setRelatedOperations([...relatedOperations, operation]);
  };

  const deleteOperation = (uuid: string) => {
    const operations = relatedOperations?.filter((operation) => operation.uuid !== uuid);
    setRelatedOperations(operations);
  };

  const onRelateOperationClick = (uuid: string) => {
    navigate(`${RouteList.exchange_edit}/${uuid}`);
  };

  return (
    <ModalCommon open onClose={onClose}>
      <Box sx={styles.paper}>
        <FormProvider {...methods}>
          <form style={styles.form}>
            <Box pb={5}>
              <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">
                  <b>{t('importAddCurrencyDocumentDialog_title')}</b>
                </Typography>
                <IconButton size="small" onClick={onHandleClose}>
                  <CrossIcon />
                </IconButton>
              </Grid>
              <Box mt={5}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  wrap="nowrap"
                  sx={{
                    ...styles.dropContainer,
                    ...((oveDragZone || fileName?.length) && styles.dropContainerActive),
                  }}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <Typography variant="body2" component="div" color="textPrimary" sx={styles.mainText}>
                    <input
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      id="upload"
                      name="upload"
                      type="file"
                      accept=".pdf, .PDF"
                      onChange={fileSelected}
                    />
                    {fileName ? (
                      <Typography component="span" variant="body2">
                        <Box sx={styles.fontWeight}>{fileName}</Box>
                      </Typography>
                    ) : (
                      <>
                        {t('importPaymentsDialog_dragFileInfoText')} <br />
                        <Button
                          variant="text"
                          sx={{ ...styles.label, ...styles.fontWeight }}
                          onClick={fileInputClicked}
                        >
                          {t('importPaymentsDialog_selectFileFromDiskText')}
                        </Button>
                      </>
                    )}
                  </Typography>
                  <Typography variant="caption" sx={styles.secondText}>
                    {fileName ? (
                      <Button variant="text" sx={styles.label} onClick={fileInputClicked}>
                        {t('upload_other_file')}
                      </Button>
                    ) : (
                      t('addCurrencyDialog_supportedFileText')
                    )}
                  </Typography>
                </Grid>
              </Box>
              <Box mt={5}>
                <CheckboxField
                  label={<Typography variant="caption">{t('addCurrencyDocumentRelateTitle')}</Typography>}
                  onChange={relateDocumentCheckHandler}
                  defaultChecked={false}
                  sx={styles.checkbox}
                  name="checkbox"
                />
                {relateDocumentCheck ? (
                  <>
                    <Box mt={4}>
                      <OperationsTabs defaultActiveTab={operationsTab} onChange={operationsTabsChangeHandler} />
                    </Box>
                    <Box mt={2}>
                      <RelateDocumentsSelect
                        name="relateDocumentsSelect"
                        placeholder={t('addCurrencyDocumentRelatePlaceholder')}
                        onSelectOperationChange={selectOperationsHandler}
                        operationType={operationsTab}
                      />
                    </Box>
                    {relatedOperations?.map((operation, index) => {
                      const typeText =
                        operation.operationType === DocumentOperationType.CurrencyOperation
                          ? t('currencyExchange')
                          : 'SWIFT';
                      return (
                        <Box key={index} mt={2}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            wrap="nowrap"
                            onClick={() => {
                              onRelateOperationClick(operation.uuid);
                            }}
                          >
                            <Grid container>
                              <LinkIcon fontSize="small" />
                              <Typography variant="body2">
                                {`${operation.documentDate} - ${typeText} #${operation.documentNumber} - ${operation.amount} ${operation.currency}`}
                              </Typography>
                            </Grid>
                            <Tooltip arrow placement="top" color="primary" title={t('detachOperation')}>
                              <IconButton
                                size="small"
                                color="default"
                                onClick={() => {
                                  deleteOperation(operation.uuid);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Box>
                      );
                    })}
                  </>
                ) : null}
              </Box>
              <Box mt={5}>
                <FormLabel component="legend">{t('currencyDocuments_documentComment')}</FormLabel>
                <Box pt={2}>
                  <InputField
                    name="comment"
                    placeholder={t('addCurrencyDocumentCommentPlaceholder')}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Box>
              <Grid container direction="column">
                <Box mt={6}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <Box pr={6}>
                      <Button size="small" variant="outlined" color="primary" onClick={onHandleClose}>
                        {t('cancel')}
                      </Button>
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleImportClickBtn}>
                      {t('importCurrencyDocumentsDialog_importButtonText')}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </ModalCommon>
  );
};

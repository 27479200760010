import {
  currencyDocumentsApiClient,
  getClientId,
  signFilesApiClient,
  ApiError,
  SignBatchResponseDto,
} from '@app/core/api';
import { umcaConfig } from '@app/src/config';

import { getFileNameFromPath } from '../../utils';

type CertificationStructure = {
  Certs: Record<string, unknown>[];
  Description: string;
};

type SignData = {
  certificate: string;
  data: Blob[];
  password: string;
};

export const getCertificatesQueryFn = async () => {
  try {
    const response = await fetch(`${umcaConfig.url}${umcaConfig.services.certificates}?ReadCertificates=true`);

    const certs: CertificationStructure[] = await response.json();

    const preparedCertificates = new Map();

    for (const cert of certs) {
      if (cert.Certs !== null) {
        const certName = getFileNameFromPath(cert.Description);
        preparedCertificates.set(certName, {
          key: certName,
          certs: cert.Certs,
        });
      }
    }

    return preparedCertificates;
  } catch (e) {
    return null;
  }
};

export const signDataFn = async (payload: SignData): Promise<any> => {
  const preparedResponse = {
    data: '',
    type: '',
  };

  const formData = new FormData();

  formData.append('Certificate', payload.certificate);
  formData.append('Pin', payload.password);
  formData.append('SigType', 'pdf');

  for (const blob of payload.data) {
    formData.append('MultipleData', blob);
  }

  try {
    const response = await fetch(`${umcaConfig.url}${umcaConfig.services.sign}`, {
      method: 'POST',
      body: formData,
    });

    preparedResponse.data = await response.json();

    if (response.status === 200) {
      preparedResponse.type = 'success';
    } else if (response.status === 500) {
      preparedResponse.type = 'error';
    }

    return preparedResponse;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getCurrencyDocumentFileFn = async (fileUUID: string): Promise<any> => {
  const response = await currencyDocumentsApiClient.documentsControllerGetFile(getClientId(), fileUUID, {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "documentsControllerGetFile error. Can't get document.");
  }

  return response.data;
};

export const signCurrencyDocumentFilesFn = async ({
  file,
  fileIds,
}: {
  file: Array<any>;
  fileIds: Array<string>;
}): Promise<SignBatchResponseDto> => {
  const response = await signFilesApiClient.request(getClientId(), file, fileIds);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, "signControllerSignFiles error. Can't sign documents.");
  }
  return response.data;
};

import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon } from '@app/common/icons';
import { useUIState } from '@app/context/UIContext';
import { ApiError, CreateTemplateRequestDto, DocumentTemplateDto, ApiErrorCodes } from '@app/core/api';

import { savePaymentTemplateFn } from './query';
import { validationSchema } from './validationSchema';
import { InputField } from '../Form';
import { ModalPopupCommon } from '../Modal';
import { StatusPopup } from '../StatusPopup';

export interface DocumentTemplateData extends Omit<CreateTemplateRequestDto, 'templateName'> {
  templateName?: string;
}

interface SaveTemplateDialogProps {
  open: boolean;
  documentTemplateData: DocumentTemplateData;
  onCancel(): void;
  onSuccessSaveTemplate(saveResultTemplateData: DocumentTemplateDto): void;
}

enum TemplateFieldEnum {
  templateName = 'templateName',
}

type TemplateField = {
  [TemplateFieldEnum.templateName]: string;
};

export const SaveTemplateDialog: React.FC<SaveTemplateDialogProps> = ({
  open,
  documentTemplateData,
  onCancel,
  onSuccessSaveTemplate,
}) => {
  const { showLoader } = useUIState();
  const { t } = useTranslation();
  const [showSuccessSaveModal, setShowSuccessSaveModal] = useState<boolean>(false);
  const [showErrorSaveModal, setShowErrorSaveModal] = useState<boolean>(false);
  const methods = useForm<TemplateField>({
    resolver: yupResolver(validationSchema(t)),
    mode: 'onChange',
  });
  const { handleSubmit, setError, getValues } = methods;

  const { data, mutate } = useMutation<DocumentTemplateDto, ApiError, CreateTemplateRequestDto>(savePaymentTemplateFn, {
    onSuccess: () => {
      showLoader(false);
      setShowSuccessSaveModal(true);
    },
    onError: (err) => {
      showLoader(false);
      if (err.code === ApiErrorCodes.DUPLICATE_TEMPLATE_NAME) {
        setError(
          TemplateFieldEnum.templateName,
          {
            type: 'manual',
            message: t('saveTemplateDuplicateNameErrorMessage'),
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setShowErrorSaveModal(true);
      }
    },
  });

  const submit = () => {
    showLoader(true);
    mutate({
      ...documentTemplateData,
      templateName: getValues(TemplateFieldEnum.templateName),
    });
  };

  const onSubmit = () => {
    submit();
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSuccessSaveModal(false);
    if (data) {
      onSuccessSaveTemplate(data);
    }
  };

  const onErrorPopupOkHandler = () => {
    setShowErrorSaveModal(false);
    submit();
  };

  const onErrorPopupCancelHandler = () => {
    setShowErrorSaveModal(false);
    onCancel();
  };

  return (
    <>
      <ModalPopupCommon questionPaper open={open} onClose={onCancel}>
        <>
          <Grid alignItems="center" justifyContent="space-between" container>
            <Typography variant="h6">
              <b>{t('templateName')}</b>
            </Typography>
            <IconButton onClick={onCancel} size="small">
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={6}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>
                    <Typography variant="body1">{t('payments_templ_name')}</Typography>
                  </Grid>
                  <Grid item xs>
                    <InputField
                      name={TemplateFieldEnum.templateName}
                      fullWidth
                      variant="outlined"
                      autoFocus
                      placeholder={t('saveTemplateTitle')}
                      defaultValue=""
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={6}>
                <Grid container spacing={6} justifyContent="flex-end">
                  <Grid item>
                    <Button size="small" variant="outlined" color="primary" onClick={onCancel}>
                      {t('cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="small" type="submit" variant="contained" color="primary">
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </FormProvider>
        </>
      </ModalPopupCommon>

      <StatusPopup
        show={showSuccessSaveModal}
        title={t('saveTemplateSuccessMessage')}
        onOk={onSuccessPopupCancelHandler}
        onClose={onSuccessPopupCancelHandler}
        status="success"
      />

      <StatusPopup
        show={showErrorSaveModal}
        title={t('saveTemplateErrorMessage')}
        buttonText={t('try_button')}
        onOk={onErrorPopupOkHandler}
        onClose={onErrorPopupCancelHandler}
        status="error"
      />
    </>
  );
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIconSvg } from '@app/themes/default/assets/icons/infoDialogIcon.svg';

import { ConfirmPopup } from '../ConfirmPopup';

interface ConfirmEditOperationPopupProps {
  onCancel(): void;
  onConfirm(): void;
}

export const ConfirmEditOperationPopup: React.FC<ConfirmEditOperationPopupProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ConfirmPopup
      icon={<InfoIconSvg />}
      questionText={t('confirmEditSignedOperation')}
      onConfirm={onConfirm}
      onClose={onCancel}
      classes={{
        questionText: {
          color: 'colors.gray800',
        },
      }}
      open
    >
      <Box mt={3}>
        <Typography
          variant="body1"
          component="span"
          sx={{
            color: 'colors.error',
            fontWeight: 700,
          }}
        >
          {t('attention')}
        </Typography>{' '}
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 600,
            color: 'colors.gray500',
          }}
        >
          {t('editAttentionText')}
        </Typography>
      </Box>
    </ConfirmPopup>
  );
};

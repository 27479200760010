export const styles = {
  root: {},
  deleteIcon: {
    marginLeft: '8px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: `12px 12px 28px`,
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '75vh',
  },
  title: {
    color: 'colors.gray500',
    fontWeight: 600,
  },
  text: {
    color: 'colors.gray600',
  },
  value: {
    fontWeight: 600,
  },
  container: {
    padding: `0 56px 24px`,
  },
  itemRow: {
    paddingBottom: '16px',
  },
  docsForSignCount: {
    fontWeight: 600,
    color: 'colors.primary600',
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
};

import React, { useEffect, useRef, useState } from 'react';

import { MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { DocumentOperationType, OperationsWithoutFilesDto } from '@app/core/api';
import { SelectField } from '@app/core/components/Form/controls/SelectField';
import { DEFAULT_FIRST_PAGE, DEFAULT_ROWS_PER_PAGE } from '@app/core/constants';
import { useGetCurrencyDocumentsList } from '@app/core/hooks/useGetCurrencyDocumentsList';
import { PaginationChangeType } from '@app/core/types';
import { hasNextPage } from '@app/core/utils';
import { styles } from '@app/pages/documents/components/AddCurrencyDocumentModal/style';

interface RelateDocumentsSelectProps {
  name: string;
  placeholder: string;
  onSelectOperationChange(operation: OperationsWithoutFilesDto): void;
  operationType: DocumentOperationType;
}

export const RelateDocumentsSelect: React.FC<RelateDocumentsSelectProps> = ({
  name,
  placeholder,
  onSelectOperationChange,
  operationType,
}) => {
  const paginationRef = useRef<PaginationChangeType>({
    page: DEFAULT_FIRST_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE * 2,
  });
  const [operations, setOperations] = useState<OperationsWithoutFilesDto[]>([]);
  const { data, mutateAsync, isLoading } = useGetCurrencyDocumentsList();
  const [nextPageExists, setNextPageExists] = useState<boolean>(true);
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (data?.pagination && bottomAnchorInView && !isLoading) {
      if (nextPageExists) {
        paginationRef.current = {
          page: data.pagination.page + 1,
          rowsPerPage: data.pagination.rowsPerPage,
        };
        mutateAsync({ pagination: paginationRef.current, operationType }).then((res) => {
          if (res?.operations) setOperations([...operations, ...res.operations]);
        });
      }
    }
  }, [bottomAnchorInView, data, isLoading, nextPageExists, operations, mutateAsync, operationType]);

  useEffect(() => {
    if (data?.operations && data.pagination) {
      setNextPageExists(hasNextPage(data.pagination));
    }
  }, [data]);

  useEffect(() => {
    mutateAsync({ pagination: paginationRef.current, operationType }).then((res) => {
      paginationRef.current = {
        page: DEFAULT_FIRST_PAGE,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE * 2,
      };
      if (res) {
        setOperations(res.operations);
      }
    });
  }, [mutateAsync, operationType]);

  const onChangeHandler = (
    e: (Event & { target: { value: unknown; name: string } }) | React.ChangeEvent<HTMLInputElement>
  ) => {
    onSelectOperationChange(JSON.parse(e.target.value as string) as OperationsWithoutFilesDto);
  };

  const { t } = useTranslation();

  return (
    <SelectField
      name={name}
      fullWidth
      defaultValue=""
      onChange={onChangeHandler}
      renderValue={(selected: any) => {
        if (!selected && placeholder) {
          return (
            <Typography variant="body2" sx={styles.selectPlaceholder}>
              {placeholder}
            </Typography>
          );
        }
        return `${selected.documentDate} - #${selected.documentNumber} - ${selected.amount} ${selected.currency}`;
      }}
      displayEmpty={Boolean(placeholder)}
    >
      {operations.map((operation, index) => {
        const optionValue = JSON.stringify(operation);
        const exchangeTypeText =
          operation.operationType === DocumentOperationType.CurrencyOperation ? t(operation.exchangeType) : '';
        return (
          <MenuItem key={index} value={optionValue}>
            {`${operation.documentDate} - ${exchangeTypeText} #${operation.documentNumber} - ${operation.amount} ${operation.currency}`}
          </MenuItem>
        );
      })}
      {isLoading ? null : <div ref={bottomAnchorRef} />}
    </SelectField>
  );
};

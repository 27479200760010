import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, sessionApiClient, ApiError, GetUserSessionsDto, UserSessionsResponseDto } from '@app/core/api';

const getCompanyHistoryOfVisitFn = async (filter: GetUserSessionsDto): Promise<UserSessionsResponseDto> => {
  const response = await sessionApiClient.profileControllerGetClientSessions(getClientId(), {
    ...filter,
  });
  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Cant get history');
  }
  return response.data;
};

export const useGetCompanyHistoryOfVisit = (): UseMutationResult<
  UserSessionsResponseDto,
  ApiError,
  GetUserSessionsDto
> => {
  return useMutation<UserSessionsResponseDto, ApiError, GetUserSessionsDto>(getCompanyHistoryOfVisitFn);
};

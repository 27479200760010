export type BankCodeResponse = {
  id: number;
  mfo: number;
  name: string;
};

export type BanksUAFormFields = {
  mfo?: string;
  name?: string;
};

export enum BankColumnEnum {
  mfo = 'mfo',
  name = 'name',
}

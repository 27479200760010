import React, { useEffect } from 'react';

import { Box, FormLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FilterIcon } from '@app/common/icons';
import { FrontLanguageEnum } from '@app/core/api';
import { CurrencyAutocomplete } from '@app/core/components/CurrencyAutocomplete';
import { DateRangeSelectField } from '@app/core/components/Form/controls/DateField/DateRangeSelectField';
import { ActionFiltersButtons } from '@app/pages/exchangeRate/components/NbuRates/components/NbuRateFilter/components/ActionFiltersButtons';
import { styles } from '@app/pages/exchangeRate/components/NbuRates/components/NbuRateFilter/styles';
import { nbuCurrencyListQueryFn } from '@app/pages/exchangeRate/query';
import { CurrencyRatesFilterField } from '@app/pages/exchangeRate/types';

interface NbuRatesFilterProps {
  resetFormHandler(): void;
}

export const NbuRatesFilter: React.FC<NbuRatesFilterProps> = ({ resetFormHandler }) => {
  const { t, i18n } = useTranslation();

  const { data, mutate } = useMutation(nbuCurrencyListQueryFn);

  useEffect(() => {
    if (i18n.language) {
      mutate({ language: i18n.language as unknown as FrontLanguageEnum });
    }
  }, [mutate, i18n.language]);
  return (
    <>
      <Box mt={4} mb={4}>
        <FilterIcon />
        <Typography variant="h6" component="span" sx={styles.captionFilter}>
          {t('filters')}
        </Typography>
      </Box>
      <Grid container direction="column" sx={styles.wrapper}>
        <Grid item container justifyContent="space-between" spacing={5}>
          <Grid item xs={6}>
            <FormLabel component="legend">{t('exchange_column_currency')}</FormLabel>
            <Box pt={2}>
              <CurrencyAutocomplete
                name={CurrencyRatesFilterField.exchangeCurrencyList}
                options={data?.currencyCodes || []}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormLabel component="legend">{t('exchangeRatesFilter_dateRange')}</FormLabel>
            <Box pt={2}>
              <DateRangeSelectField
                formSelectName="dateRangeSelect"
                formDateRangeName={CurrencyRatesFilterField.dateRange}
              />
            </Box>
          </Grid>
          <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
            <Box pt={6} sx={styles.actionButtonsContainer}>
              <ActionFiltersButtons resetFormHandler={resetFormHandler} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

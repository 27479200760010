import { useMutation, UseMutationResult } from 'react-query';

import {
  getClientId,
  paymentOrdersApiClient,
  ApiError,
  CreatePaymentOrderResponseDto,
  UpdateOwnPaymentRequestDto,
} from '@app/core/api';

export const useEditOwnPaymentOrder = (): UseMutationResult<
  CreatePaymentOrderResponseDto,
  ApiError,
  UpdateOwnPaymentRequestDto
> => {
  return useMutation(async (updateOwnPaymentRequestDto: UpdateOwnPaymentRequestDto) => {
    const result = await paymentOrdersApiClient.paymentOrderControllerUpdateOwnPayment(
      getClientId(),
      updateOwnPaymentRequestDto
    );

    if (result.status !== 200) {
      throw new ApiError(result.request.status, 'Error update own payment!');
    }

    return result.data;
  });
};

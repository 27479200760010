import React from 'react';

import { Box } from '@mui/material';

import { Loader } from '../../Loader';
import { styles } from '../styles';

export const BodyLoader: React.FC = () => {
  return (
    <Box sx={styles.emptyRow}>
      <Loader />
    </Box>
  );
};

import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, CardContent, FormLabel, Grid, Typography } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoIcon, KeyIcon } from '@app/common/icons';
import {
  AccountTypeEnum,
  CreateOperationAdditionalInfoDto,
  CreateOperationAdditionalInfoDtoCodeEnum,
  GetAccountResponseDto,
  SignPermissionsDto,
  SWIFTUrgencyEnum,
} from '@app/core/api';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { Tooltip } from '@app/core/components/Tooltip';
import { useGenerateSwiftOrderNumber } from '@app/core/hooks/useGenerateSwiftOrderNumber';
import { useGetAllAccounts } from '@app/core/hooks/useGetAllAccounts';
import { useGetCurrencyOperationsAccount } from '@app/core/hooks/useGetCurrencyAccounts';
import { formatAmount, formatBIC } from '@app/core/utils';
import { calculateMaxValuationDate } from '@app/pages/payment/create/LocalPayment/helpers';
import { makeSwiftData } from '@app/pages/swift/SwiftPayment/components/BetweenMyAccounts/helper';
import { validationSchema } from '@app/pages/swift/SwiftPayment/components/BetweenMyAccounts/validationSchema';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import {
  CommissionType,
  SimpleSwiftDefaultValues,
  SwiftBetweenMyAccountFormData,
  SwiftBetweenMyAccountFormFields,
  SwiftFormData,
} from '@app/pages/swift/types';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

interface SwiftPaymentBetweenMyAccountFormProps {
  signPermissions: SignPermissionsDto;
  defaultValues?: SimpleSwiftDefaultValues;
  additionalInfo?: CreateOperationAdditionalInfoDto | null;
  onSaveClick(paymentData: SwiftFormData): void;
  onSaveAndSignClick(paymentData: SwiftFormData): void;
  onSendToSign(paymentData: SwiftFormData): void;
}

export const SwiftPaymentBetweenMyAccountForm: React.FC<SwiftPaymentBetweenMyAccountFormProps> = ({
  signPermissions,
  additionalInfo,
  onSaveClick,
  onSaveAndSignClick,
  onSendToSign,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const defaultDocumentDate = new Date();
  const [currency, setCurrency] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [senderName, setSenderName] = useState<string>('');
  const [currencyAccounts, setCurrencyAccounts] = useState<GetAccountResponseDto[]>();
  const maxDocumentDate = addDays(defaultDocumentDate, 30);
  const minDocumentDate = subDays(defaultDocumentDate, 30);
  const [maxValuationDate, setMaxValuationDate] = useState<Date>(calculateMaxValuationDate(defaultDocumentDate));
  const [minValuationDate, setMinValuationDate] = useState<Date>(defaultDocumentDate);
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const documentNumberRef = useRef<HTMLElement>(null);

  const methods = useForm<SwiftBetweenMyAccountFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema({ t })),
  });

  const { trigger, setValue, control, getValues } = methods;

  const { data: allAccounts, mutate: getAllAccountsMutate } = useGetAllAccounts();

  useEffect(() => {
    getAllAccountsMutate({});
  }, [getAllAccountsMutate]);

  const recipientAccountOptions = allAccounts?.accounts.filter(
    (account) => account.account.currency !== 'UAH' && account.account.accountType === AccountTypeEnum.Loan
  );

  const watchDateFrom = useWatch({
    control,
    name: SwiftBetweenMyAccountFormFields.documentDate,
  });

  const watchBalance = useWatch({
    control,
    name: [`${SwiftBetweenMyAccountFormFields.senderAccount}`, `${SwiftBetweenMyAccountFormFields.amount}`],
  });

  useEffect(() => {
    if (watchDateFrom) {
      setMaxValuationDate(calculateMaxValuationDate(watchDateFrom));
      setMinValuationDate(watchDateFrom);
      setValue(SwiftBetweenMyAccountFormFields.documentValueDate, undefined);
    }
  }, [setValue, watchDateFrom]);

  const { clientProfile, swiftInfo } = useAppSelector((state): ProfileState => state.profile);

  useEffect(() => {
    if (clientProfile) {
      if (clientProfile.address) {
        setAddress(clientProfile.address);
      }

      const name =
        clientProfile.privateEnterpreuner === 1 ? clientProfile.shortName || '' : clientProfile.fullName || '';
      setSenderName(name);
    }
  }, [clientProfile]);

  const { data: generatedSwiftOrderNumber, mutate: mutateSwiftOrderNumber } = useGenerateSwiftOrderNumber();

  const { data: accounts, mutate } = useGetCurrencyOperationsAccount();

  useEffect(() => {
    mutate({});
    mutateSwiftOrderNumber({});
  }, [mutate, mutateSwiftOrderNumber]);

  useEffect(() => {
    if (accounts) {
      setCurrencyAccounts(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    const [watchBalanceSenderAccount] = watchBalance;
    if (watchBalanceSenderAccount) {
      const {
        account: { currency: selectedAccountCurrency },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      setCurrency(selectedAccountCurrency);
    }
  }, [watchBalance]);

  useEffect(() => {
    const [watchBalanceSenderAccount, watchBalanceAmount] = watchBalance;
    if (watchBalanceSenderAccount && watchBalanceAmount) {
      const {
        account: { amount: selectedAccountAmount },
      }: GetAccountResponseDto = JSON.parse(watchBalanceSenderAccount);
      const watchBalanceNotEmpty = selectedAccountAmount && watchBalanceAmount;
      if (watchBalanceNotEmpty) {
        setBalance(selectedAccountAmount);
        setShowBalance(true);
      }
    }
  }, [watchBalance]);

  const amount = useWatch({ control, name: SwiftBetweenMyAccountFormFields.amount });

  const countedBalance = formatAmount(balance - Number(amount));

  const onCancelHandler = () => {};
  const onSignClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveAndSignClick(
          makeSwiftData(
            getValues(),
            generatedSwiftOrderNumber || 'Auto123',
            swiftInfo,
            address,
            senderName,
            defaultValues
          )
        );
      }
    });
  };

  const onSaveClickHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveClick(
          makeSwiftData(
            getValues(),
            generatedSwiftOrderNumber || 'Auto123',
            swiftInfo,
            address,
            senderName,
            defaultValues
          )
        );
      }
    });
  };

  const onSendToSignHandler = () => {
    trigger().then((valid) => {
      if (valid) {
        onSendToSign(
          makeSwiftData(
            getValues(),
            generatedSwiftOrderNumber || 'Auto123',
            swiftInfo,
            address,
            senderName,
            defaultValues
          )
        );
      }
    });
  };

  useEffect(() => {
    if (defaultValues?.paymentReference) {
      setValue(SwiftBetweenMyAccountFormFields.paymentReference, defaultValues.paymentReference);
    }
    if (defaultValues?.amount) {
      setValue(SwiftBetweenMyAccountFormFields.amount, defaultValues.amount.toString());
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    if (defaultValues && defaultValues.senderAccountId && currencyAccounts) {
      const activeSenderAccount = currencyAccounts.find(({ account }) => account.id === defaultValues.senderAccountId);
      if (activeSenderAccount) {
        setValue(SwiftBetweenMyAccountFormFields.senderAccount, JSON.stringify(activeSenderAccount));
      }
    }
  }, [currencyAccounts, defaultValues, setValue]);

  useEffect(() => {
    if (defaultValues && defaultValues.recipientAccountId && allAccounts) {
      const activeRecipientAccount = allAccounts.accounts.find(
        ({ account }) => account.accountNumber === defaultValues.recipientAccount
      );
      if (activeRecipientAccount) {
        setValue(SwiftBetweenMyAccountFormFields.recipientAccount, JSON.stringify(activeRecipientAccount));
      }
    }
  }, [allAccounts, defaultValues, setValue]);

  useEffect(() => {
    if (additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <FormProvider {...methods}>
      <form style={styles.form}>
        <Grid sx={styles.tabBody}>
          <Grid container sx={styles.paymentNumber}>
            <Grid item container xs={8}>
              <Box ref={documentNumberRef}>
                <DocumentNumberField
                  name={SwiftBetweenMyAccountFormFields.documentNumber}
                  title={t('swiftPayment_paymentOrder')}
                  defaultValue={generatedSwiftOrderNumber || 'Auto123'}
                  error={additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed}
                  validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
                />
              </Box>
            </Grid>
            {/*{appConfig.enableNextFeatures ? (*/}
            {/*  <Grid item xs={4} sx={styles.bookmarkBlock}>*/}
            {/*    <SavePaymentTemplate />*/}
            {/*  </Grid>*/}
            {/*) : null}*/}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <CardContent>
                <Box mb={6}>
                  <Grid container>
                    <Grid item xs={3}>
                      <FormLabel component="legend">{t('swiftPayment_documentDate')}</FormLabel>
                      <Box pt={2} pr={8}>
                        <DateSelectField
                          name={SwiftBetweenMyAccountFormFields.documentDate}
                          selectedDate={new Date()}
                          datePickerProps={{
                            disabled: { after: maxDocumentDate, before: minDocumentDate },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <FormLabel component="legend">{t('swiftPayment_valuationDate')}</FormLabel>
                      <Box pt={2} pr={8}>
                        <DateSelectField
                          name={SwiftBetweenMyAccountFormFields.documentValueDate}
                          datePickerProps={{
                            disabled: { after: maxValuationDate, before: minValuationDate },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item container alignItems="center" xs={1}>
                      <Tooltip color="primary" title={t('swiftPayment_valuationDateTooltip')} arrow placement="right">
                        <Box mt={8}>
                          <InfoIcon fontSize="small" sx={styles.info} />
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={5}>
                  <FormLabel component="legend">{t('swiftPayment_senderAccount')}</FormLabel>
                  <Box pt={2}>
                    <AccountSelectField
                      name={SwiftBetweenMyAccountFormFields.senderAccount}
                      options={currencyAccounts}
                      placeholder={t('swiftPayment_senderAccountPlaceholder')}
                    />
                  </Box>
                </Box>
                <Box mb={6}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_senderName')}</FormLabel>
                      <Box pt={2} pr={8}>
                        <Typography>{defaultValues?.senderName || senderName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_senderAddress')}</FormLabel>
                      <Box pt={2}>
                        <Typography>{defaultValues?.senderAddress || address}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={styles.hr} mb={5} />
                <Box mb={5}>
                  <FormLabel component="legend">{t('swiftPayment_recipientAccount')}</FormLabel>
                  <Box pt={2}>
                    <AccountSelectField
                      name={SwiftBetweenMyAccountFormFields.recipientAccount}
                      options={recipientAccountOptions}
                      placeholder={t('swiftPayment_senderAccountPlaceholder')}
                    />
                  </Box>
                </Box>
                <Box mb={5}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_recipientName')}</FormLabel>
                      <Box pt={2} pr={8}>
                        <Typography>{defaultValues?.recipientName || senderName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_recipientAddress')}</FormLabel>
                      <Box pt={2}>
                        <Typography>{defaultValues?.recipientAddress || address}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={5}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
                      <Box pt={2} pr={8}>
                        <Typography>
                          {(defaultValues?.recipientSwiftBic
                            ? formatBIC(defaultValues?.recipientSwiftBic)
                            : undefined) || formatBIC(swiftInfo.swiftBic)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
                      <Box pt={2}>
                        <Typography>{defaultValues?.recipientBankName || swiftInfo.swiftBankName}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={5}>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormLabel component="legend">{t('swiftPayment_recipientCountry')}</FormLabel>
                      <Box pt={2}>
                        <Typography>{t('Ukraine')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={styles.hr} mb={5} />
                <Box mb={5}>
                  <Grid container>
                    <Grid item xs={2}>
                      <FormLabel component="legend">{t('swiftPayment_amount')}</FormLabel>
                      <Box pt={2}>
                        <FormatInputField
                          type={FormatType.currencyAmount}
                          name={SwiftBetweenMyAccountFormFields.amount}
                          placeholder={t('swiftPayment_amountPlaceholder')}
                          currency={currency}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box pt={6} pr={10} sx={styles.balance}>
                        <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                        <Typography sx={styles.balanceText}>
                          {showBalance ? `${countedBalance} ${currency}` : '0.00'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item container alignItems="flex-end" direction="column" xs={5}>
                      <Box mr={9}>
                        <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
                      </Box>
                      <Box pt={2} mr={9}>
                        <Typography>{t(`swiftCommission_${CommissionType.our}`)}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} container alignItems="flex-end" direction="column">
                      <FormLabel component="legend">{t('swiftPayment_urgency')}</FormLabel>
                      <Box pt={2}>
                        <Typography>{t(`swiftUrgency_${SWIFTUrgencyEnum.Standard}`)}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={6}>
                  <FormLabel component="legend">{t('swiftPayment_purpose')}</FormLabel>
                  <Box pt={2}>
                    <TextAreaField
                      name={SwiftBetweenMyAccountFormFields.paymentReference}
                      textFieldProps={{
                        multiline: true,
                        fullWidth: true,
                        rows: 2,
                      }}
                      maxLength={160}
                    />
                  </Box>
                </Box>
              </CardContent>
              <CardActions sx={styles.cardAction}>
                <Button color="primary" onClick={onCancelHandler}>
                  {t('paymentCreationFormControl_cancel')}
                </Button>
                <Box sx={styles.cardAction}>
                  <Button sx={styles.submitBtn} variant="outlined" color="primary" onClick={onSaveClickHandler}>
                    {t('paymentCreationFormControl_save')}
                  </Button>
                  {signPermissions.swift.allowToSendToSign ? (
                    <Button variant="contained" color="primary" onClick={onSendToSignHandler}>
                      {t('paymentCreationFormControl_sendToSign')}
                    </Button>
                  ) : null}
                  {signPermissions.swift.allowToSign ? (
                    <Button variant="contained" color="primary" onClick={onSignClickHandler}>
                      {t('paymentCreationFormControl_send')}
                      <KeyIcon sx={styles.keyIcon} />
                    </Button>
                  ) : null}
                </Box>
              </CardActions>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

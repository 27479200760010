import { DocumentDto, MessageStatus } from '@app/core/api';
import { SelectOptionType } from '@app/core/components';

export enum MessageFormFieldName {
  conversationId = 'conversationId',
  categoryId = 'categoryId',
  subject = 'subject',
  message = 'message',
}

export type MessageDefaultValues = {
  conversationId?: string;
  categoryId?: number;
  subject?: string;
  message?: string;
  status: MessageStatus;
  documents?: Array<DocumentDto>;
};

export type MessageFormData = {
  [MessageFormFieldName.conversationId]?: string;
  [MessageFormFieldName.categoryId]: number;
  [MessageFormFieldName.subject]: string;
  [MessageFormFieldName.message]: string;
};

export type MessageEditData = {
  messageData: MessageFormData;
  files: FormData;
};

export interface MessageEditFormProps {
  defaultValues?: MessageDefaultValues;
  categoryList: SelectOptionType[];
  onCancelClick(): void;
  onSave(messageEditData: MessageEditData): void;
  onSaveAndSign(messageEditData: MessageEditData): void;
  onSaveAndSendToSign(messageEditData: MessageEditData): void;
}

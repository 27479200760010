import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, SecondSignature, UpChevronSmallIcon } from '@app/common/icons';
import { MessageStatus, SignatureStatusDto, UserProfileDto } from '@app/core/api';

import { styles } from './style';
import { getCurrentPersonInfoStatusIcon } from '../../helpers/UIHelpers';

interface PeopleToSignMessageListProps {
  title?: string;
  signInfo: SignatureStatusDto[];
  userProfile: UserProfileDto;
}

export const PeopleToSignMessageList: React.FC<PeopleToSignMessageListProps> = ({ signInfo, userProfile, title }) => {
  const { t } = useTranslation();
  const [showAttorney, setShowAttorney] = useState<number>();

  const openAttorney = (id: number) => {
    setShowAttorney(id);
  };

  const closeAttorney = () => {
    setShowAttorney(undefined);
  };

  const getSignAuthorityText = (authority: number) => {
    if (authority === 1) {
      return t('firstSign');
    }
    if (authority === 2) {
      return t('secondSign');
    }
  };

  return (
    <>
      <Typography variant="body1" sx={styles.title}>
        {title ? title : t('paymentInfo_toSignList_title')}
      </Typography>
      <Box mt={2}>
        <Grid container alignItems="center">
          {signInfo.map(({ main, attorney, status: personInfoStatus, signingAuthority }, idx) => {
            return (
              <Grid key={idx} sx={styles.signerContainer}>
                <Grid container alignItems="center" justifyContent="space-between" sx={styles.signerContainer}>
                  <Grid item xs={11} container alignItems="center">
                    {getCurrentPersonInfoStatusIcon(personInfoStatus)}
                    <Box component="span" ml={2}>
                      {attorney?.lastName === userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                      <Typography variant="body2" component="span" sx={styles.value}>
                        {attorney?.lastName === userProfile.lastName
                          ? `${attorney.lastName} ${attorney.firstName}`
                          : `${main?.lastName} ${main?.firstName}`}
                      </Typography>
                    </Box>
                  </Grid>
                  {main && attorney ? (
                    <Grid item xs={1} sx={styles.toggleButton}>
                      {showAttorney === idx ? (
                        <UpChevronSmallIcon fontSize="small" onClick={closeAttorney} />
                      ) : (
                        <DownChevronSmallIcon
                          fontSize="small"
                          onClick={() => {
                            openAttorney(idx);
                          }}
                        />
                      )}
                    </Grid>
                  ) : null}
                </Grid>
                <Box ml={6}>
                  <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                    {getSignAuthorityText(signingAuthority)}
                  </Typography>
                </Box>
                {showAttorney === idx ? (
                  <Grid item xs={12} container alignItems="center" flexDirection="column" sx={styles.attorneyContainer}>
                    <Grid item container alignItems="center" justifyContent="flex-start">
                      <Typography variant="caption" component="span" sx={styles.signingAuthority}>
                        {t('attorneyTitle')}
                      </Typography>
                    </Grid>
                    <Grid item container alignItems="center" justifyContent="flex-start">
                      {attorney?.lastName !== userProfile.lastName ? <SecondSignature fontSize="small" /> : null}
                      <Box>
                        <Typography variant="body2" component="span" sx={styles.value}>
                          {attorney?.lastName === userProfile.lastName
                            ? `${main?.lastName} ${main?.firstName}`
                            : `${attorney?.lastName} ${attorney?.firstName}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

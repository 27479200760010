import { CurrencyOperationsExchangeTypeEnum, GetCurrencyOperationDto } from '@app/core/api';

export const relateCurrencyOperationCurrency = (operation: GetCurrencyOperationDto) => {
  if (
    operation.currencyExchangeType === CurrencyOperationsExchangeTypeEnum.Purchase ||
    CurrencyOperationsExchangeTypeEnum.Conversion
  ) {
    return operation.depositAccount.currency;
  } else {
    return operation.withdrawalAccount.currency;
  }
};

export const styles = {
  title: {
    fontWeight: 700,
    marginRight: '4px',
    color: 'colors.gray600',
  },
  statusIcon: {
    color: 'colors.primary400',
  },
  value: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
};

import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { getClientId, ApiError, swiftApiClient, GetSignersResponseDto } from '@app/core/api';

export const getSwiftSignersFn = async (): Promise<GetSignersResponseDto> => {
  const result = await swiftApiClient.swiftSignControllerGetSigners(getClientId());
  if (result.status !== 200) {
    throw new ApiError(result.request.status, "Can't get the signers");
  }
  return result.data;
};

export const useGetSwiftSigners = (): UseQueryResult<GetSignersResponseDto> => {
  return useQuery<GetSignersResponseDto, ApiError>('peopleToSigne', getSwiftSignersFn, {
    enabled: true,
    refetchOnMount: true,
  });
};

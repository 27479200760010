export const styles = {
  root: {
    padding: '11px',
  },
  switchBase: {
    padding: '13px',
    color: 'colors.gray400',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      transform: 'translateX(10px)',
      color: 'colors.primaryTextColor',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& + $track': {
        backgroundColor: 'colors.primary500',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'colors.primary500',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: '12px',
    height: '12px',
  },
  track: {
    height: '16px',
    width: '26px',
    borderRadius: '13px',
    border: `1px solid`,
    borderColor: 'colors.gray400',
    backgroundColor: 'colors.gray25',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
};

import { useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';
import { useIdleTimer } from 'react-idle-timer';
import { useQuery } from 'react-query';
import { useTimer } from 'react-timer-hook';

import { AuthResponseDto } from '@app/core/api';
import { isDevelopment } from '@app/core/utils';
import { serviceConfig } from '@app/src/config';
import { sessionExpiredQueryUrlParam } from '@app/src/constants/common';

import { getNewAccessTokenFn } from './query';
import { getTokenExpTime, getLastChanceTime } from './utils';

interface UseSessionSettings {
  onFinalCountdown(): void;
  onContinueSession(): void;
}

interface UseSessionResult {
  loading: boolean;
  seconds: number;
  minutes: number;
  hours: number;
  continueSession(): void;
  cancelSession(): void;
}

export const useSession = ({ onFinalCountdown, onContinueSession }: UseSessionSettings): UseSessionResult => {
  const [silentRelogin, setSilentRelogin] = useState<boolean>(false);
  const doSilentReloginRef = useRef<boolean>(false);

  const cancelSession = () => {
    sessionStorage.removeItem('LastLogin');
    window.location.href = `${serviceConfig.loginUrl}/?${sessionExpiredQueryUrlParam}`;
  };

  const {
    seconds,
    minutes,
    hours,
    restart: expireSessionRestart,
  } = useTimer({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expiryTimestamp: getTokenExpTime(),
    onExpire: () => {
      if (!isDevelopment()) {
        cancelSession();
      }
    },
  });

  const { restart: lastChanceTimeTimerRestart } = useTimer({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expiryTimestamp: getLastChanceTime(),
    onExpire: () => {
      if (doSilentReloginRef.current) {
        setSilentRelogin(true);
      } else {
        onFinalCountdown();
      }
    },
  });

  const handleOnIdle = () => {
    doSilentReloginRef.current = false;
  };

  const handleOnActive = () => {
    if (new Date() > getTokenExpTime()) {
      return;
    }

    doSilentReloginRef.current = new Date() < getLastChanceTime();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    expireSessionRestart(getTokenExpTime(), true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lastChanceTimeTimerRestart(getLastChanceTime(), true);
  };

  const idleTimer = useIdleTimer({
    timeout: 1000 * 10, // idle checks every 10 sec
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  useEffect(() => {
    const millisecondsToTokenExpire = getTokenExpTime().getTime() - Date.now();
    // if less then 5 seconds just cancel session
    if (millisecondsToTokenExpire > 0 && millisecondsToTokenExpire < 5000) {
      cancelSession();
    }
  }, []);

  const { refetch, isLoading, isFetching } = useQuery<AuthResponseDto>('getNewAccessTokenQuery', getNewAccessTokenFn, {
    enabled: false,
    onSuccess: (resultData) => {
      Cookies.set('UserToken', resultData.result.access_token, {
        sameSite: 'strict',
        secure: true,
        domain: serviceConfig.cookiesDomain,
      });
      Cookies.set('RefreshToken', resultData.result.refresh_token, {
        sameSite: 'strict',
        secure: true,
        domain: serviceConfig.cookiesDomain,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expireSessionRestart(getTokenExpTime(), true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lastChanceTimeTimerRestart(getLastChanceTime(), true);
      onContinueSession();
    },
  });

  useEffect(() => {
    if (silentRelogin && (!isLoading || !isFetching)) {
      setSilentRelogin(false);
      refetch();
    }
  }, [isFetching, isLoading, refetch, silentRelogin]);

  const continueSession = () => {
    refetch();
  };

  return {
    loading: isLoading || isFetching,
    seconds,
    minutes,
    hours,
    cancelSession,
    continueSession,
  };
};

export const styles = {
  sortIcon: {
    fill: 'colors.primary500',
  },
  cell: {
    flex: '1 auto',
    minWidth: '40px',
  },
  cell_body: {},
  cell_senderAccount: {
    maxWidth: '260px',
  },
  cell_templateName: {
    minWidth: '70px',
    maxWidth: '250px',
  },
  cell_recipientAccount: {
    minWidth: '180px',
  },
  cell_paymentReference: {
    maxWidth: '200px',
    '&.body': {
      color: 'colors.gray400',
    },
  },
  cell_paymentReference_body: {
    color: 'colors.gray400',
    fontSize: '0.75rem',
  },
  cell_amount: {
    maxWidth: '200px',
    minWidth: '100px',
    textAlign: 'end',
    '&.body': {
      justifyContent: 'end',
      '& div': {
        marginRight: 'auto',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        '& span': {
          fontWeight: 400,
          marginLeft: '4px',
        },
      },
    },
  },
  cell_actions: {
    width: '86px',
    flexShrink: 0,
    justifyContent: 'space-around',
  },
  emptyRow: {
    position: 'relative',
    height: '150px',
  },
  accountNumberPart1: {
    color: 'colors.gray700',
  },
  accountNumberPart2: {
    color: 'colors.gray700',
    fontWeight: 600,
  },
  emptyDataMessage: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    height: '150px',
    color: 'colors.gray200',
  },
};

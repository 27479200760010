import React from 'react';

import { Typography, Box } from '@mui/material';

import { SWIFTStatusEnum } from '@app/core/api';
import { styles } from '@app/pages/swift/components/SwiftTable/components/FilterPanel/styles';

type MenuItemProps = {
  value?: SWIFTStatusEnum;
  text: React.ReactNode;
  disabled: boolean;
  isSelected: boolean;
  divide: boolean;
  onClick(status?: SWIFTStatusEnum): void;
};

export const FilterMenuItem: React.FC<MenuItemProps> = ({ value, text, disabled, isSelected, divide, onClick }) => {
  const onClickHandler = () => {
    if (!disabled) {
      onClick(value);
    }
  };

  return (
    <>
      <Box sx={styles.itemWrapper}>
        <Typography
          sx={{ ...styles.itemText, ...(isSelected && styles.selected), ...(disabled && styles.disabled) }}
          variant={isSelected ? 'body2' : 'caption'}
          component="span"
          onClick={onClickHandler}
        >
          {text}
        </Typography>
      </Box>
      {divide ? <Box sx={styles.divider} /> : null}
    </>
  );
};

export const styles = {
  root: {},
  deleteIcon: {
    marginLeft: '8px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '12px 12px 28px',
    outline: 0,
    borderRadius: '8px',
    width: '100%',
    maxWidth: 500,
    maxHeight: '75vh',
  },
  title: {
    color: 'colors.gray800',
    fontWeight: 700,
    fontSize: '1.25rem',
    width: '100%',
    textAlign: 'center',
  },
  container: {
    width: '86%',
  },
  text: {
    color: 'colors.gray600',
    fontWeight: 600,
  },
  value: {
    fontWeight: 600,
  },
  iconBg: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
  },
  selectedDocumentsCount: {
    fontWeight: 600,
    color: 'colors.gray600',
  },
  toSignDocumentsCount: {
    fontWeight: 600,
    color: 'colors.primary500',
  },
  fileListContainer: {},
  fileRow: {
    width: '100%',
    overflow: 'hidden',
    color: 'colors.gray600',
  },
  fileName: {
    fontWeight: 600,
  },
};

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UserDto } from '@app/core/api';
import {
  DocumentSigned,
  DocumentToSign,
  SignatureModal,
  SignAnotherPersonPopup,
  SignatureStrategyEnum,
} from '@app/core/components';

export interface SignaturePopupProps {
  documentsToSign: DocumentToSign[];
  signFromAnotherPerson?: boolean;
  type: SignatureStrategyEnum;
  onSignedStringResult(signedDocuments: DocumentSigned[], signatureBelongsToUser?: number): void;
  onClose(): void;
}

export const SignaturePopup: React.FC<SignaturePopupProps> = ({
  documentsToSign,
  signFromAnotherPerson = false,
  type,
  onSignedStringResult,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showSignPopup, setShowSignPopup] = useState<boolean>(!signFromAnotherPerson);
  const [showSignAnotherPersonPopup, setShowSignAnotherPersonPopup] = useState<boolean>(signFromAnotherPerson);
  const [signFromUser, setSignFromUser] = useState<UserDto>();

  const onSignedStringResultHandler = (signedDocuments: DocumentSigned[]) => {
    onSignedStringResult(signedDocuments, signFromAnotherPerson && signFromUser ? signFromUser.id : undefined);
  };

  const onSelectAnotherPersonHandler = (user: UserDto) => {
    setSignFromUser(user);
    setShowSignAnotherPersonPopup(false);
    setShowSignPopup(true);
  };

  const onCloseAnotherPersonPopup = () => {
    setShowSignAnotherPersonPopup(false);
    onClose();
  };

  const title = signFromAnotherPerson ? `${signFromUser?.firstName} ${signFromUser?.lastName}` : t('signature');

  return (
    <>
      {showSignPopup ? (
        <SignatureModal
          title={title}
          validateUser={signFromUser}
          documentsToSign={documentsToSign}
          onSignedStringResult={onSignedStringResultHandler}
          onClose={onClose}
        />
      ) : null}

      {showSignAnotherPersonPopup ? (
        <SignAnotherPersonPopup
          type={type}
          onClose={onCloseAnotherPersonPopup}
          onSelect={onSelectAnotherPersonHandler}
        />
      ) : null}
    </>
  );
};

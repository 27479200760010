import { PaginationChangeType, SortOrder } from '@app/core/types';

export enum ExchangeSource {
  bank = 'bank',
  nbu = 'nbu',
}

export type ExchangesCurrencySelect = {
  name: string;
  currency: string;
  id: string;
  alpha3: string;
  numeric: string;
};

export type ExchangesCurrencyFilter = ExchangesCurrencySortingRule & {
  currencies: string[];
  dateRange: {
    from: string;
    to: string;
  };
  bankName: ExchangeSource;
  bankBranch?: string;
  pagination: PaginationChangeType;
};

export type BankCurrencyFilter = ExchangesCurrencySortingRule & {
  currencies: string[];
  bankName: ExchangeSource;
  bankBranch: string;
};

export type ExchangesCurrencySortingRule = {
  sort?: {
    order: SortOrder;
    column: ExchangesRateColumnEnum;
  };
};

export type ExchangesCurrencySubmit = {
  exchangeCurrencyList: ExchangesCurrencySelect[];
  dateRange: {
    from: string;
    to: string;
  };
  dateRangeSelect?: string;
  bankBranch?: string;
};

export type BunkRateSubmit = {
  exchangeCurrencyList: ExchangesCurrencySelect[];
};

export type BankBranch = {
  id: string;
  details: string;
};

export enum ExchangesRateColumnEnum {
  date = 'date',
  alpha3 = 'alpha3',
  base = 'base',
  numeric = 'numeric',
  currencyName = 'currencyName',
  rate = 'rate',
}

export enum BankRatesColumnEnum {
  date = 'date',
  currency = 'currency',
  currencyCode = 'currencyCode',
  currencyName = 'currencyName',
  exchangeRateNbu = 'exchangeRateNbu',
  exchangeRateBuy = 'exchangeRateBuy',
  exchangeRateSell = 'exchangeRateSell',
}

export type ExchangesCurrencyData = {
  date: string;
  currency: string;
  codeCurrency: string;
  nameCurrency: string;
  exchangeRate?: string;
  exchangeRateBuy?: string;
  exchangeRateSell?: string;
};

export enum CurrencyRatesFilterField {
  dateRange = 'dateRange',
  exchangeCurrencyList = 'exchangeCurrencyList',
}

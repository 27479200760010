import React from 'react';

import { Button, Link, Typography, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PrintIcon } from '@app/common/icons';

import { Tooltip } from '../../Tooltip';

export type PrintButtonProps = {
  link: string;
  withLabel?: boolean;
  styles?: SxProps<Theme>;
};

export const PrintButton: React.FC<PrintButtonProps> = ({ styles, link, withLabel = false }) => {
  const { t } = useTranslation();
  return (
    <Link target="_blank" href={link} sx={{ ...styles, textDecoration: 'none' }}>
      <Tooltip arrow placement="top" color="primary" title={t('print')}>
        <div>
          {withLabel ? (
            <Button sx={styles} startIcon={<PrintIcon />}>
              <Typography variant="body1" component="span">
                {t('print')}
              </Typography>
            </Button>
          ) : (
            <PrintIcon />
          )}
        </div>
      </Tooltip>
    </Link>
  );
};

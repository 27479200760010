import React, { useRef } from 'react';

import { FormControl, AutocompleteValue } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteQueryField } from '@app/core/components/Form/controls/AutocompleteQueryField';
import { DEFAULT_FIRST_PAGE } from '@app/core/constants';
import { useGetCounterparties } from '@app/core/hooks';
import { Counterparty } from '@app/core/types';
import { RecipientNameInfo } from '@app/pages/payment/components/RecipientNameSelect/components/RecipientNameInfo';

interface SenderAccountSelectProps {
  name: string;
  defaultValue?: string;
  onSelectCounterparty?(counterparty: Counterparty): void;
  onClear?(): void;
}

export const RecipientNameSelect: React.FC<SenderAccountSelectProps> = ({
  name,
  defaultValue,
  onSelectCounterparty,
  onClear,
}) => {
  const { t } = useTranslation();
  const valueInInput = useRef<string>('');
  const counterpartySelectedFromList = useRef<boolean>(false);
  const { setValue } = useFormContext();
  const { mutateAsync } = useGetCounterparties();

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    counterparty: Counterparty,
    state: AutocompleteRenderOptionState
  ): React.ReactNode => {
    return (
      <li {...props} key={counterparty.id}>
        <RecipientNameInfo counterparty={counterparty} />
      </li>
    );
  };

  const accountsQuery = async (nameQueryStr: string): Promise<Counterparty[]> => {
    return new Promise((resolve) => {
      mutateAsync({ name: nameQueryStr, pagination: { page: DEFAULT_FIRST_PAGE, rowsPerPage: 300 } }).then(
        (counterpartiesResponse) => {
          const { counterparties } = counterpartiesResponse;
          resolve(counterparties);
        }
      );
    });
  };

  const onInputChangeHandler = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear' && counterpartySelectedFromList.current && onClear) {
      onClear();
    }
    valueInInput.current = value;
    counterpartySelectedFromList.current = false;
  };

  const onChangeHandler = (
    e: React.ChangeEvent<{}>,
    newValue: string | AutocompleteValue<Counterparty, false, true, true>,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof newValue === 'string') {
      setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    } else if (newValue && newValue.name) {
      counterpartySelectedFromList.current = true;
      setValue(name, newValue.name, { shouldValidate: false, shouldDirty: true });
      if (onSelectCounterparty) {
        onSelectCounterparty(newValue);
      }
    }
  };

  const onBlurHandler = () => {
    if (!counterpartySelectedFromList.current) {
      setValue(name, valueInInput.current, { shouldValidate: true });
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <AutocompleteQueryField<Counterparty, false, true, true>
        freeSolo
        clearErrorOnFocus
        textFieldProps={{ placeholder: t('paymentCreationLocalPayment_recipientNamePlaceholder') }}
        name={name}
        defaultValue={defaultValue || ''}
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.name;
        }}
        renderOption={renderOption}
        isOptionEqualToValue={(account1, account2) => {
          return account1.id === account2.id;
        }}
        onInputChange={onInputChangeHandler}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        fetchFunction={accountsQuery}
      />
    </FormControl>
  );
};

import React from 'react';

import { TextField } from '@mui/material';

export interface EmailFieldProps {
  name: string;
  value: string;
  placeholder: string;
  fullWidth: boolean;
  errors?: string;
  disabled: boolean;
  onChange?(value: string): void;
}

export const EmailField: React.FC<EmailFieldProps> = ({ onChange, name, disabled, errors, ...props }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value as string);
    }
  };
  return (
    <TextField
      error={Boolean(errors)}
      helperText={errors}
      type="text"
      name={name}
      variant="outlined"
      disabled={disabled}
      onChange={handleChange}
      {...props}
    />
  );
};

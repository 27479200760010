import React from 'react';

import { ListItemButton, styled } from '@mui/material';

export const MenuItem = styled(ListItemButton)(
  ({
    theme: {
      palette: { colors },
    },
  }) => ({
    color: colors.menuItemText,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: 14,
    '& svg': {
      fill: colors.menuItemText,
    },
    '&:hover, &.selected': {
      color: colors.menuItemActiveText,
    },
    '&:hover svg path, &.selected svg path': {
      transformOrigin: 'center',
      transform: 'scale(1.15)',
      fill: colors.menuItemActiveText,
    },
    '&.selected': {
      fontWeight: 600,
    },
  })
) as typeof ListItemButton;

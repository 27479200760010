import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { getClientId, accountsApiClient, ApiError, GetAllAccountsResponseDto } from '@app/core/api';

const sendGetAllAccountsQueryFn = async (query?: string): Promise<GetAllAccountsResponseDto> => {
  const response = await accountsApiClient.accountsControllerGetAllAccounts(getClientId(), query);

  if (response.status !== 200) {
    throw new ApiError(response.request.status, 'Error accountsControllerGetAllAccounts!');
  }
  return response.data;
};

export const useGetAllAccounts = (): UseMutationResult<GetAllAccountsResponseDto, ApiError, any> => {
  return useMutation<GetAllAccountsResponseDto, ApiError, any>(sendGetAllAccountsQueryFn);
};

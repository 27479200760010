import React, { ReactElement } from 'react';

import { SuccessSmallIcon, WaitingSmallIcon, WarningSmallIcon } from '@app/common/icons';
import {
  DocumentStatusEnum,
  OperationHistoryItemDtoActionTypeEnum,
  SignatureStatusDto,
  SignatureStatusDtoStatusEnum,
} from '@app/core/api';

export const getActionTranslationKeyByActionTypeEnum = (status: OperationHistoryItemDtoActionTypeEnum): string => {
  switch (status) {
    case OperationHistoryItemDtoActionTypeEnum.AddFile:
      return 'actionType_AddFile';
    case OperationHistoryItemDtoActionTypeEnum.DetachFile:
      return 'actionType_DetachFile';
    case OperationHistoryItemDtoActionTypeEnum.Created:
      return 'documentChangeAction_Created';
    case OperationHistoryItemDtoActionTypeEnum.Edited:
      return 'documentChangeAction_Edited';
    case OperationHistoryItemDtoActionTypeEnum.Deleted:
      return 'documentChangeAction_Deleted';
    case OperationHistoryItemDtoActionTypeEnum.ToSign:
      return 'documentChangeAction_ToSign';
    case OperationHistoryItemDtoActionTypeEnum.OnSigning:
      return 'documentChangeAction_OnSigning';
    case OperationHistoryItemDtoActionTypeEnum.Signed:
      return 'documentChangeAction_Signed';
    case OperationHistoryItemDtoActionTypeEnum.Executed:
      return 'documentStatus_executed';
    case OperationHistoryItemDtoActionTypeEnum.Rejected:
      return 'documentStatus_rejected';
    case OperationHistoryItemDtoActionTypeEnum.WaitingForDocument:
      return 'documentStatus_waitingForDocument';
    case OperationHistoryItemDtoActionTypeEnum.RemovalOfSignature:
      return 'REMOVAL_OF_SIGNATURE';
    case OperationHistoryItemDtoActionTypeEnum.SignedFromAnotherUser:
      return 'SIGNED_FROM_ANOTHER_USER';
    case OperationHistoryItemDtoActionTypeEnum.Processing:
    default:
      return 'documentChangeAction_Processing';
  }
};

export const getCurrentPersonInfoStatusIcon = (status: SignatureStatusDtoStatusEnum): ReactElement => {
  switch (status) {
    case SignatureStatusDtoStatusEnum.Signed:
      return <SuccessSmallIcon fontSize="small" />;
    case SignatureStatusDtoStatusEnum.ToSign:
    case SignatureStatusDtoStatusEnum.OnSigning:
    default:
      return <WaitingSmallIcon fontSize="small" />;
  }
};

export const getDocumentStatusIcon = (status: DocumentStatusEnum): ReactElement => {
  switch (status) {
    case DocumentStatusEnum.Rejected:
    case DocumentStatusEnum.Deleted:
      return <WarningSmallIcon fontSize="small" />;
    case DocumentStatusEnum.Saved:
    case DocumentStatusEnum.ToSign:
    default:
      return <WaitingSmallIcon fontSize="small" />;
  }
};

import React from 'react';

import { Grid } from '@mui/material';

import { DownChevronSmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { OperationDetails } from '@app/core/components';

import { Operation } from '../../../../../../types';
import { Checkbox } from '../../../../../Checkbox';
import {
  TableCell,
  ExpandedTableRow,
  AccountNameItem,
  AccountNumberItem,
  AmountDocumentItem,
  CounterPartyName,
  DateItem,
  DocNumberItem,
  PaymentReference,
} from '../../../../../ComplexTable';
import { IBanText } from '../../../../../IBanText';
import { styles } from '../../../../styles';

export interface DocumentTableRowProps {
  document: Operation;
  selected?: boolean;
  onCheck(document: Operation, selected: boolean): void;
  open: boolean;
  onOpenExtendedContent(id: string | undefined): void;
}

export const DocumentTableRow: React.FC<DocumentTableRowProps> = ({
  document,
  open,
  onOpenExtendedContent,
  selected = false,
  onCheck,
}) => {
  const { documentNumber, paymentReference, amount, currency, details, uuid } = document;

  const { name: senderName, accountNumber: senderAccountNumber } = details.senderAccount;
  const { name: recipientName, accountNumber: recipientAccountNumber } = details.recipientAccount;
  const { date } = details;

  const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(document, e.target.checked);
  };

  const handleExpand = () => {
    onOpenExtendedContent(open ? undefined : uuid);
  };

  return (
    <>
      <ExpandedTableRow
        open={open}
        sx={styles.tableRow}
        extendedContend={<OperationDetails operation={document} stylesRoot={styles.documentDetailsContent} />}
      >
        <TableCell sx={{ ...styles.tableCell, ...styles.checkCell }} padding="checkbox">
          <Checkbox checked={selected} onChange={onCheckHandler} />
        </TableCell>
        <Grid container onClick={handleExpand}>
          <TableCell sx={{ ...styles.tableCell, ...styles.dateCell }}>
            <DocNumberItem text={documentNumber} />
            <DateItem text={date} />
          </TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.accountCell }}>
            <AccountNameItem text={senderName} />
            <AccountNumberItem text={<IBanText text={senderAccountNumber} />} />
          </TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.referenceCell }}>
            <CounterPartyName text={recipientName} />
            <PaymentReference text={paymentReference} />
          </TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.amountCell }}>
            <AmountDocumentItem amount={amount} currency={currency} />
          </TableCell>
          <TableCell sx={{ ...styles.tableCell, ...styles.actionCell }}>
            {open ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
          </TableCell>
        </Grid>
      </ExpandedTableRow>
    </>
  );
};

import React from 'react';

import '@app/src/common/icons/styles.scss';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import { ReactComponent as ChatOffIconSvg } from '@app/themes/default/assets/control-panel/chat-off.svg';
import { ReactComponent as NotificationOffIconSvg } from '@app/themes/default/assets/control-panel/notification-off.svg';
import { ReactComponent as SettingCompanyIconSvg } from '@app/themes/default/assets/control-panel/setting.svg';
import { ReactComponent as CheckboxCheckedSvg } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-checked-invert.svg';
import { ReactComponent as CheckboxIndeterminateSvg } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-indeterminate-invert.svg';
import { ReactComponent as CheckboxUncheckedSvg } from '@app/themes/default/assets/controls/24x24/checkbox/checkbox-unchecked-invert.svg';
import { ReactComponent as ArrowDownSvg } from '@app/themes/default/assets/icons/16x16/arrow/down.svg';
import { ReactComponent as ArrowRightSvg } from '@app/themes/default/assets/icons/16x16/arrow/right.svg';
import { ReactComponent as ArrowUpSvg } from '@app/themes/default/assets/icons/16x16/arrow/up.svg';
import { ReactComponent as ArrowDownSelectSmallIconSvg } from '@app/themes/default/assets/icons/16x16/arrow_down.svg';
import { ReactComponent as CalendarSvg } from '@app/themes/default/assets/icons/16x16/calendar.svg';
import { ReactComponent as DoneIconSvg } from '@app/themes/default/assets/icons/16x16/check.svg';
import { ReactComponent as DownChevronSmallIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/down.svg';
import { ReactComponent as Down2ChevronSmallIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/down2.svg';
import { ReactComponent as UpChevronSmallIconSvg } from '@app/themes/default/assets/icons/16x16/chevron/up.svg';
import { ReactComponent as CrossIconSvg } from '@app/themes/default/assets/icons/16x16/cross.svg';
import { ReactComponent as EraserSmallIconSvg } from '@app/themes/default/assets/icons/16x16/eraser.svg';
import { ReactComponent as ErrorSmallIconSvg } from '@app/themes/default/assets/icons/16x16/error.svg';
import { ReactComponent as FilterSmallIconSvg } from '@app/themes/default/assets/icons/16x16/filter.svg';
import { ReactComponent as InfoSmallIconSvg } from '@app/themes/default/assets/icons/16x16/info.svg';
import { ReactComponent as NotifySmallIconSvg } from '@app/themes/default/assets/icons/16x16/notif-btn.svg';
import { ReactComponent as SearchIconSvg } from '@app/themes/default/assets/icons/16x16/search.svg';
import { ReactComponent as SuccessSmallIconSvg } from '@app/themes/default/assets/icons/16x16/success.svg';
import { ReactComponent as WaitingSmallIconSvg } from '@app/themes/default/assets/icons/16x16/waiting.svg';
import { ReactComponent as WarningSmallIconSvg } from '@app/themes/default/assets/icons/16x16/warning.svg';
import { ReactComponent as ArrowLeftIconSvg } from '@app/themes/default/assets/icons/24x24/arrow/left.svg';
import { ReactComponent as ArrowRightBigSvg } from '@app/themes/default/assets/icons/24x24/arrow/right.svg';
import { ReactComponent as BackCancelIconSvg } from '@app/themes/default/assets/icons/24x24/back-cancel.svg';
import { ReactComponent as BankIconSvg } from '@app/themes/default/assets/icons/24x24/bank.svg';
import { ReactComponent as DownChevronIconSvg } from '@app/themes/default/assets/icons/24x24/chevron/down.svg';
import { ReactComponent as LeftChevronIconSvg } from '@app/themes/default/assets/icons/24x24/chevron/left.svg';
import { ReactComponent as RightChevronIconSvg } from '@app/themes/default/assets/icons/24x24/chevron/right.svg';
import { ReactComponent as UpChevronIconSvg } from '@app/themes/default/assets/icons/24x24/chevron/up.svg';
import { ReactComponent as CopyIconSvg } from '@app/themes/default/assets/icons/24x24/copy.svg';
import { ReactComponent as CsvIconSvg } from '@app/themes/default/assets/icons/24x24/csv.svg';
import { ReactComponent as FilterIconSvg } from '@app/themes/default/assets/icons/24x24/filter.svg';
import { ReactComponent as HideIconSvg } from '@app/themes/default/assets/icons/24x24/hide.svg';
import { ReactComponent as KeyIconSvg } from '@app/themes/default/assets/icons/24x24/key.svg';
import { ReactComponent as LoaderSvg } from '@app/themes/default/assets/icons/24x24/loader.svg';
import { ReactComponent as LockUserIconSvg } from '@app/themes/default/assets/icons/24x24/lockUser.svg';
import { ReactComponent as MoveIconSvg } from '@app/themes/default/assets/icons/24x24/move.svg';
import { ReactComponent as PaperIconSvg } from '@app/themes/default/assets/icons/24x24/paper.svg';
import { ReactComponent as PdfIconSvg } from '@app/themes/default/assets/icons/24x24/pdf.svg';
import { ReactComponent as PreviewIconGraySvg } from '@app/themes/default/assets/icons/24x24/preview2.svg';
import { ReactComponent as PrintIconSvg } from '@app/themes/default/assets/icons/24x24/print.svg';
import { ReactComponent as SendIconSvg } from '@app/themes/default/assets/icons/24x24/send.svg';
import { ReactComponent as ShowIconSvg } from '@app/themes/default/assets/icons/24x24/show.svg';
import { ReactComponent as SmileIconSvg } from '@app/themes/default/assets/icons/24x24/smile.svg';
import { ReactComponent as UnLockUserIconSvg } from '@app/themes/default/assets/icons/24x24/unlockUser.svg';
import { ReactComponent as XlsIconSvg } from '@app/themes/default/assets/icons/24x24/xls.svg';
import { ReactComponent as XmlIconSvg } from '@app/themes/default/assets/icons/24x24/xml.svg';
import { ReactComponent as Burger } from '@app/themes/default/assets/icons/burger.svg';
import { ReactComponent as DeleteIconSvg } from '@app/themes/default/assets/icons/delete.svg';
import { ReactComponent as DownloadIconSvg } from '@app/themes/default/assets/icons/download.svg';
import { ReactComponent as EditIconSvg } from '@app/themes/default/assets/icons/edit.svg';
import { ReactComponent as ErrorSvgIconSvg } from '@app/themes/default/assets/icons/error.svg';
import { ReactComponent as Info } from '@app/themes/default/assets/icons/info.svg';
import { ReactComponent as LinkSvg } from '@app/themes/default/assets/icons/link.svg';
import { ReactComponent as MailIconSvg } from '@app/themes/default/assets/icons/mail.svg';
import { ReactComponent as MoreIconSvg } from '@app/themes/default/assets/icons/more.svg';
import { ReactComponent as PlusSvg } from '@app/themes/default/assets/icons/plus.svg';
import { ReactComponent as PreviewIconSvg } from '@app/themes/default/assets/icons/preview.svg';
import { ReactComponent as RefreshSvg } from '@app/themes/default/assets/icons/refresh.svg';
import { ReactComponent as SendOutlinedIconSvg } from '@app/themes/default/assets/icons/send-outlined.svg';
import { ReactComponent as SettingIconSvg } from '@app/themes/default/assets/icons/setting.svg';
import { ReactComponent as ShareIconSvg } from '@app/themes/default/assets/icons/share.svg';
import { ReactComponent as SecondSignatureSvg } from '@app/themes/default/assets/icons/signature-second.svg';
import { ReactComponent as SignatureIconSvg } from '@app/themes/default/assets/icons/signature.svg';
import { ReactComponent as StarIconFilledSvg } from '@app/themes/default/assets/icons/star-filled.svg';
import { ReactComponent as StarIconSvg } from '@app/themes/default/assets/icons/star.svg';
import { ReactComponent as SuccessSmallSvgIconSvg } from '@app/themes/default/assets/icons/success-small.svg';
import { ReactComponent as SuccessSvgIconSvg } from '@app/themes/default/assets/icons/success.svg';

export const MoveIcon: React.FC = () => (
  <span className="MoveIconStyle">
    <MoveIconSvg />
  </span>
);

export const ArrowDownSmallIcon: React.FC<{ className: string }> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <ArrowDownSvg className="svg-icon" />
  </SvgIcon>
);
export const ArrowUpSmallIcon: React.FC<{ className?: string }> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <ArrowUpSvg className="svg-icon" />
  </SvgIcon>
);

export const ArrowBigRightIcon: React.FC<{ className?: string }> = (props) => (
  <SvgIcon {...props}>
    <ArrowRightBigSvg className="svg-icon" />
  </SvgIcon>
);

export const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <ArrowLeftIconSvg className="svg-icon" />
  </SvgIcon>
);

export const DownloadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <DownloadIconSvg className="svg-icon" />
  </SvgIcon>
);

export const CsvIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <CsvIconSvg className="svg-icon" />
  </SvgIcon>
);

export const PdfIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <PdfIconSvg className="svg-icon" />
  </SvgIcon>
);

export const XlsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <XlsIconSvg className="svg-icon" />
  </SvgIcon>
);

export const XmlIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <XmlIconSvg className="svg-icon" />
  </SvgIcon>
);

export const PrintIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <PrintIconSvg viewBox="0 0 24 24" className="svg-icon" />
  </SvgIcon>
);

export const CopyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <CopyIconSvg className="svg-icon" />
  </SvgIcon>
);

export const DeleteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <DeleteIconSvg className="svg-icon" />
  </SvgIcon>
);
export const SmileIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <SmileIconSvg className="svg-icon" />
  </SvgIcon>
);

export const EditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <EditIconSvg className="svg-icon" />
  </SvgIcon>
);

export const MoreIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <MoreIconSvg className="svg-icon" />
  </SvgIcon>
);

export const PreviewIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <PreviewIconSvg className="svg-icon" />
  </SvgIcon>
);

export const PreviewIconGray: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <PreviewIconGraySvg className="svg-icon" />
  </SvgIcon>
);

export const SignatureIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <SignatureIconSvg className="svg-icon" />
  </SvgIcon>
);

export const StarIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <StarIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SendIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <SendIconSvg className="svg-icon" />
  </SvgIcon>
);
export const StarFilledIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <StarIconFilledSvg className="svg-icon" />
  </SvgIcon>
);

export const KeyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <KeyIconSvg className="svg-icon" />
  </SvgIcon>
);

export const InfoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <Info className="svg-icon" />
  </SvgIcon>
);

export const CalendarIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <CalendarSvg className="svg-icon" />
  </SvgIcon>
);
export const FilterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <FilterIconSvg className="svg-icon" />
  </SvgIcon>
);

export const ShowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <ShowIconSvg className="svg-icon" />
  </SvgIcon>
);

export const HideIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <HideIconSvg className="svg-icon" />
  </SvgIcon>
);

export const CrossIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <CrossIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SendOutlinedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <SendOutlinedIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SearchSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <SearchIconSvg className="svg-icon" />
  </SvgIcon>
);

export const DoneIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <DoneIconSvg className="svg-icon" />
  </SvgIcon>
);

export const ChatOffIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <ChatOffIconSvg className="svg-icon" />
  </SvgIcon>
);

export const NotificationOffIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <NotificationOffIconSvg className="svg-icon" />
  </SvgIcon>
);

export const DownChevronSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <DownChevronSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const UpChevronSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <UpChevronSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const Down2ChevronSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <Down2ChevronSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const DownChevronIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <DownChevronIconSvg className="svg-icon" />
  </SvgIcon>
);

export const UpChevronIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <UpChevronIconSvg className="svg-icon" />
  </SvgIcon>
);

export const LeftChevronIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <LeftChevronIconSvg className="svg-icon" />
  </SvgIcon>
);

export const RightChevronIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <RightChevronIconSvg className="svg-icon" />
  </SvgIcon>
);

export const ErrorSvgIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 88 88" {...props}>
    <ErrorSvgIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SuccessSvgIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 88 88" {...props}>
    <SuccessSvgIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SuccessSmallSvgIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <SuccessSmallSvgIconSvg className="svg-icon" />
  </SvgIcon>
);

export const ArrowRightSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <ArrowRightSvg className="svg-icon" />
  </SvgIcon>
);

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <ArrowRightBigSvg className="svg-icon" />
  </SvgIcon>
);

export const SettingIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <SettingIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SettingCompanyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <SettingCompanyIconSvg className="svg-icon" />
  </SvgIcon>
);

export const LockUserIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <LockUserIconSvg className="svg-icon" />
  </SvgIcon>
);

export const UnLockUserIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <UnLockUserIconSvg className="svg-icon" />
  </SvgIcon>
);

export const MailIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <MailIconSvg className="svg-icon" />
  </SvgIcon>
);

export const RefreshIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <RefreshSvg className="svg-icon" />
  </SvgIcon>
);

export const ShareIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <ShareIconSvg className="svg-icon" />
  </SvgIcon>
);

export const CheckboxCheckedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <CheckboxCheckedSvg className="svg-icon" />
  </SvgIcon>
);

export const CheckboxIndeterminateIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <CheckboxIndeterminateSvg className="svg-icon" />
  </SvgIcon>
);

export const CheckboxUncheckedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <CheckboxUncheckedSvg className="svg-icon" />
  </SvgIcon>
);

export const LoaderIcon: React.ComponentType = () => <LoaderSvg className="svg-icon loading-spinner-animation" />;

export const ErrorSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <ErrorSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const InfoSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <InfoSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const SuccessSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <SuccessSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const WarningSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <WarningSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const ArrowDownSelectSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <ArrowDownSelectSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const BurgerIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 28 28" {...props}>
    <Burger className="svg-icon" />
  </SvgIcon>
);

export const PlusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <PlusSvg className="svg-icon" />
  </SvgIcon>
);

export const WaitingSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <WaitingSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const NotifySmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <NotifySmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const PaperIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <PaperIconSvg className="svg-icon" />
  </SvgIcon>
);

export const LinkIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <LinkSvg className="svg-icon" />
  </SvgIcon>
);

export const SecondSignature: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <SecondSignatureSvg className="svg-icon" />
  </SvgIcon>
);

export const BackCancelIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <BackCancelIconSvg className="svg-icon" />
  </SvgIcon>
);

export const BankIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <BankIconSvg className="svg-icon" />
  </SvgIcon>
);

export const EraserIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <EraserSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

export const FilterSmallIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <FilterSmallIconSvg className="svg-icon" />
  </SvgIcon>
);

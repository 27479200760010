import React from 'react';

import { uk, enUS } from 'date-fns/locale';
import { DayPicker, DayPickerProps } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import './style.css';
import { defaultFromDate, defaultToDate } from './constants';
import { YearMonthSelectCaption } from './YearMonthSelectCaption';
import { Langs } from '../../../../types';

export const DatePicker: React.FC<DayPickerProps> = (props) => {
  const { i18n } = useTranslation();
  const language: Langs = i18n.language as Langs;
  return (
    <DayPicker
      locale={language === Langs.EN ? enUS : uk}
      weekStartsOn={1}
      fromDate={defaultFromDate}
      toDate={defaultToDate}
      components={{ Caption: YearMonthSelectCaption }}
      {...props}
    />
  );
};

import React from 'react';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { CrossIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import { DocumentStatusEnum, GetDocumentWithAvailableActionsDto } from '@app/core/api';
import { ModalCommon } from '@app/core/components/Modal/ModalCommon';
import { ActionsButtons } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/components/ActionsButtons';
import { deleteCurrencyDocumentQueryFn } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/query';
import { styles } from '@app/pages/documents/components/DeleteCurrencyDocumentModal/style';
import { ReactComponent as DeleteIcoBgSVG } from '@app/themes/default/assets/icons/modalBgDelete.svg';

interface DeleteMultipleCurrencyDocumentsModalProps {
  onSubmit(): void;
  onCancelDeleteDocument(): void;
  onDeleteDocumentSuccess(): void;
  selectedDocuments: GetDocumentWithAvailableActionsDto[];
}

export const DeleteMultipleCurrencyDocumentsModal: React.FC<DeleteMultipleCurrencyDocumentsModalProps> = ({
  onDeleteDocumentSuccess,
  onCancelDeleteDocument,
  selectedDocuments,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { notify } = useNotify();

  const { showLoader } = useUIState();
  const { mutate } = useMutation(deleteCurrencyDocumentQueryFn, {
    onSuccess: () => {
      onDeleteDocumentSuccess();
      showLoader(false);
      notify({
        notifyProps: {
          title: t('success'),
          message: t('deleteDocumentsSuccessMessage'),
          severity: 'success',
        },
      });
    },
    onError: () => {
      showLoader(false);
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('deleteDocumentsErrorMessage'),
          severity: 'error',
        },
      });
    },
  });

  const docsForDelete = selectedDocuments.filter(
    (item) => item.status === DocumentStatusEnum.Saved || item.status === DocumentStatusEnum.ToSign
  );

  const docsForDeleteCount: number = docsForDelete.length;

  const uuids = docsForDelete.map((document) => {
    return document.uuid;
  });

  const onConfirmDeleteDocument = () => {
    showLoader(true);
    mutate({ uuids });
    onSubmit();
  };
  return (
    <ModalCommon onClose={onCancelDeleteDocument} open>
      <Box sx={styles.paper}>
        <Box sx={styles.iconBg}>
          <DeleteIcoBgSVG />
        </Box>
        <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
          <IconButton size="small" onClick={onCancelDeleteDocument}>
            <CrossIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Box mt={3} mb={4}>
          <Typography variant="body1" sx={styles.title}>
            {t('areYouSureYouWantToDelete')}
          </Typography>
        </Box>
        <Grid container direction="column" wrap="nowrap" sx={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center" sx={styles.itemRow}>
            <Grid item>
              <Typography variant="body2" sx={styles.text}>
                {t('deleteDocumentCount')}
                {' / '} {t('deleteDocument_documentsToSign')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={styles.value}>
                {selectedDocuments.length}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.value}>
                {' / '}
              </Typography>
              <Typography variant="body2" component="span" sx={styles.docsForDeleteCount}>
                {docsForDeleteCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <ActionsButtons onCancel={onCancelDeleteDocument} onConfirm={onConfirmDeleteDocument} />
      </Box>
    </ModalCommon>
  );
};

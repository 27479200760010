import React from 'react';

import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useFormContext, Controller } from 'react-hook-form';

import { StatusText } from '../../StatusText';

type InputFieldProps = TextFieldProps & { name: string };

export const InputField: React.FC<InputFieldProps> = ({ name, defaultValue, ...otherProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { name: controlledName, value, ...props }, fieldState: { error } }) => (
        <>
          <TextField
            name={controlledName}
            value={value}
            variant="outlined"
            {...props}
            error={Boolean(error)}
            {...otherProps}
          />
          {error && error.message ? <StatusText absolute>{error.message}</StatusText> : null}
        </>
      )}
    />
  );
};

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, CardContent, FormLabel, Grid } from '@mui/material';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StarIcon } from '@app/common/icons';
import { CurrencyEnum, GetAccountResponseDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { AccountSelectField, FormatInputField, FormatType, InputField, TextAreaField } from '@app/core/components';
import { useGetAccountsForEnrollment } from '@app/core/hooks/useGetAccountsForEnrollment';
import { useGetCurrentAccountsByCurrency } from '@app/core/hooks/useGetCurrentAccountsByCurrency';
import { styles } from '@app/pages/paymentsTemplates/components/BetweenMyAccountTemplateForm/styles';
import { validationSchema } from '@app/pages/paymentsTemplates/components/BetweenMyAccountTemplateForm/validationSchema';
import {
  FormFieldErrorsName,
  FormFieldName,
  TemplateDefaultValues,
  TemplateFormData,
} from '@app/pages/paymentsTemplates/components/LocalPaymentTemplateForm/types';

interface BetweenMyAccountTemplateFormProps {
  defaultValues: TemplateDefaultValues;
  errorFields?: { fieldName: FormFieldErrorsName; errorMessage: string }[];
  onSaveClick(templateData: TemplateFormData, templateType: PaymentTemplateTypeEnum): void;
  onCancelHandler(): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const BetweenMyAccountTemplateForm: React.FC<BetweenMyAccountTemplateFormProps> = ({
  defaultValues,
  errorFields,
  onCancelHandler,
  onSaveClick,
  onChange,
}) => {
  const { t } = useTranslation();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const methods = useForm<TemplateFormData>({
    resolver: yupResolver(validationSchema({ t })),
    mode: 'onChange',
    defaultValues: {
      amount: '',
    },
  });
  const { control, setValue, trigger, getValues, formState, setError } = methods;

  const senderAccountWatch = useWatch({
    control,
    name: FormFieldName.senderAccount,
  });

  const {
    data: senderAccountsData,
    mutate: mutateGetSenderAccounts,
    isLoading: isLoadingSenderAccounts,
  } = useGetCurrentAccountsByCurrency();
  const {
    data: recipientAccountsData,
    mutate: mutateGetRecipientAccount,
    isLoading: isLoadingRecipientAccounts,
  } = useGetAccountsForEnrollment();

  useEffect(() => {
    mutateGetSenderAccounts(CurrencyEnum.Uah);
  }, [mutateGetSenderAccounts]);

  useEffect(() => {
    if (senderAccountWatch) {
      const {
        account: { id },
      }: GetAccountResponseDto = JSON.parse(senderAccountWatch);
      mutateGetRecipientAccount({ currency: CurrencyEnum.Uah, excludeAccount: id });
    }
  }, [mutateGetRecipientAccount, senderAccountWatch]);

  const { isDirty } = formState;

  useEffect(() => {
    if (defaultValues?.templateName) {
      setValue(`${FormFieldName.templateName}`, defaultValues.templateName);
    }
    if (defaultValues?.amount) {
      setValue(`${FormFieldName.amount}`, `${defaultValues.amount}`);
    }
    if (defaultValues?.paymentReference) {
      setValue(`${FormFieldName.purpose}`, defaultValues.paymentReference);
    }
    if (senderAccountsData && defaultValues?.senderAccountId) {
      const activeAccount = senderAccountsData.accounts.find(
        ({ account }) => account.id === defaultValues.senderAccountId
      );
      if (activeAccount) {
        setValue(FormFieldName.senderAccount, JSON.stringify(activeAccount), {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    }
  }, [defaultValues, senderAccountsData, setValue]);

  useEffect(() => {
    if (errorFields?.length) {
      errorFields.forEach((error) => {
        setError(
          error.fieldName,
          {
            type: 'manual',
            message: error.errorMessage,
          },
          {
            shouldFocus: true,
          }
        );
      });
    }
  }, [errorFields, setError, t]);

  useEffect(() => {
    onChange({ formDirty: isDirty });
  }, [isDirty, onChange]);

  const onClickHandler = () => {
    if (!leavePopupOpen && isDirty) {
      setLeavePopupOpen(true);
    } else {
      onCancelHandler();
    }
  };

  const validateAndSaveTemplate = () => {
    trigger().then((valid) => {
      if (valid) {
        onSaveClick(getValues(), PaymentTemplateTypeEnum.Own);
      }
    });
  };

  const onSaveTemplate = () => {
    validateAndSaveTemplate();
  };

  return (
    <Grid sx={styles.tabBody}>
      <FormProvider {...methods}>
        <form
          style={{
            position: 'relative',
            backgroundColor: '#f6f6f7',
            borderRadius: '8px',
            padding: '4px 20px 20px',
          }}
        >
          <Grid item>
            <Grid container direction="column">
              <CardContent>
                <Box mb={6}>
                  <Grid container>
                    <Grid item xs>
                      <FormLabel component="legend">{t('templateNameLabel')}</FormLabel>
                      <Box pt={2}>
                        <InputField
                          fullWidth
                          name={FormFieldName.templateName}
                          placeholder={t('saveTemplateTitle')}
                          defaultValue={defaultValues.templateName || ''}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={6}>
                  <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
                  <Box pt={2}>
                    <AccountSelectField
                      name={FormFieldName.senderAccount}
                      options={senderAccountsData?.accounts}
                      placeholder={t('paymentCreationBetweenMyAccount_enrollment_accountPlaceholder')}
                    />
                  </Box>
                </Box>
                <Box mb={6}>
                  <FormLabel component="legend">{t('paymentCreationBetweenMyAccount_enrollment_account')}</FormLabel>
                  <Box pt={2}>
                    <AccountSelectField
                      name={FormFieldName.recipientAccount}
                      options={recipientAccountsData || []}
                      defaultSelectFirst
                      placeholder={t('paymentCreationBetweenMyAccount_enrollment_accountPlaceholder')}
                    />
                  </Box>
                </Box>
                <Box mb={6}>
                  <Grid container>
                    <Grid item xs={3}>
                      <FormLabel component="legend">{t('paymentCreationLocalPayment_amount')}</FormLabel>
                      <Box sx={styles.flexbox}>
                        <Box pt={2}>
                          <FormatInputField
                            type={FormatType.amount}
                            name={FormFieldName.amount}
                            placeholder={t('paymentCreationLocalPayment_amountPlaceholder')}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={6}>
                  <FormLabel component="legend">{t('paymentCreationLocalPayment_purpose')}</FormLabel>
                  <Box pt={2}>
                    <TextAreaField
                      defaultValue={defaultValues.paymentReference || ''}
                      name={FormFieldName.purpose}
                      maxLength={420}
                      textFieldProps={{
                        multiline: true,
                        fullWidth: true,
                        rows: 2,
                      }}
                    />
                  </Box>
                </Box>
              </CardContent>
              <CardActions sx={styles.cardAction}>
                <Box sx={styles.cardAction}>
                  <Button sx={styles.cancelBtn} variant="outlined" color="primary" onClick={onClickHandler}>
                    {t('cancel')}
                  </Button>
                  <Button variant="contained" color="primary" onClick={onSaveTemplate}>
                    {defaultValues.templateName ? (
                      t('save_changes')
                    ) : (
                      <>
                        {t('payments_templ_create')}
                        <StarIcon sx={styles.starIcon} />
                      </>
                    )}
                  </Button>
                </Box>
              </CardActions>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};

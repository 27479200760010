import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, IconButton, Box, Button, Link } from '@mui/material';
import copy from 'copy-to-clipboard';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrossIcon, CopyIcon, SendIcon, SuccessSmallSvgIcon } from '@app/common/icons';
import { CurrencyOperationsExchangeTypeEnum, EmailDocumentTypeEnum, FrontLanguageEnum } from '@app/core/api';
import { ModalPopupCommon, StatusLargePopup } from '@app/core/components';
import { useGetUserData } from '@app/core/hooks';
import { useSendToEmail } from '@app/core/hooks/useSendToEmail';

import { styles } from './style';
import { validationSchema } from './validationSchema';
import { Ellipsis } from '../Ellipsis';
import { EmailFieldControl } from '../Form';

// TODO fix props in web app

interface ShareDocumentPopupProps {
  onClose: () => void;
  link: string;
  uuid: string;
  type: EmailDocumentTypeEnum;
  subType?: CurrencyOperationsExchangeTypeEnum;
}

interface EmailFieldProps {
  email: string;
}

export const ShareDocumentPopup: React.FC<ShareDocumentPopupProps> = ({ onClose, link, uuid, type, subType }) => {
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const copyLink = () => {
    copy(`${link}`, { format: 'text/plain' });
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1500);
  };

  const { mutate, isLoading } = useSendToEmail();

  const { data: user, mutate: mutateUserData } = useGetUserData();

  useEffect(() => {
    mutateUserData({});
  }, [mutateUserData]);

  const methods = useForm<EmailFieldProps>({ mode: 'onSubmit', resolver: yupResolver(validationSchema(t)) });

  const onSubmit = (data: EmailFieldProps) => {
    mutate(
      { email: data.email, uuid, type: type, language: i18n.language as FrontLanguageEnum, subType },
      {
        onSuccess: () => {
          setSuccess(true);
          setShowStatusModal(true);
        },
        onError: () => {
          setSuccess(false);
          setShowStatusModal(true);
        },
      }
    );
  };

  const { handleSubmit, setValue } = methods;

  const addCurrentEmail = () => {
    if (user) {
      setValue('email', user?.email);
    }
  };

  const onClosePopupHandler = () => {
    setShowStatusModal(false);
    setSuccess(false);
    onClose();
  };

  const onOkErrorPopupHandler = () => {
    setShowStatusModal(false);
    setSuccess(false);
  };

  return (
    <ModalPopupCommon questionPaper open onClose={onClose}>
      <Grid>
        <Grid justifyContent="space-between" alignItems="flex-start" container>
          <Grid item>
            <Typography sx={styles.title}>{t('operationDetailShare_shareDocument')}</Typography>
            <Box pt={2}>
              <Typography sx={styles.subTitle}>{t('operationDetailShare_copyLink')}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} size="small">
              <CrossIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid sx={styles.copy} justifyContent="space-between" alignItems="center" container>
            {copyAlert ? (
              <Grid container alignItems="center" sx={styles.alertCopy}>
                <SuccessSmallSvgIcon fontSize="small" />
                <Typography variant="caption" sx={styles.alertCopyText}>
                  {t('copy_details')}
                </Typography>
              </Grid>
            ) : (
              <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                <Typography variant="body2" sx={{ ...styles.linkWrap, maxWidth: '60%' }}>
                  <Ellipsis
                    text={
                      <Link href={link} target="_blank">
                        {link}
                      </Link>
                    }
                  />
                </Typography>
                <Button size="small" onClick={copyLink} variant="contained" color="primary" sx={styles.buttonWidth}>
                  {t('copy')}
                  <CopyIcon sx={styles.buttonIcon} />
                </Button>
              </Grid>
            )}
          </Grid>
          <>
            <FormProvider {...methods}>
              <form style={{ width: '100%', marginTop: '24px' }} onSubmit={handleSubmit(onSubmit)}>
                <Grid container alignItems="flex-start">
                  <Grid item xs={7}>
                    <EmailFieldControl name="email" value="" placeholder={t('email')} disabled={isLoading} />
                  </Grid>
                  <Grid container item xs={5} justifyContent="flex-end">
                    <Button size="small" type="submit" variant="outlined" color="primary" sx={styles.buttonWidth}>
                      {t('send')}
                      <SendIcon sx={styles.buttonIcon} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ marginLeft: 'auto' }}>
                    <Button color="primary" onClick={addCurrentEmail} sx={styles.addButton}>
                      {t('addCurrentEmail')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </>
        </Grid>
        {showStatusModal && success ? (
          <StatusLargePopup
            show
            status="success"
            text={t('document_sent')}
            onClose={onClosePopupHandler}
            onOk={onClosePopupHandler}
          />
        ) : null}
        {showStatusModal && !success ? (
          <StatusLargePopup
            show
            status="error"
            text={t('document_not_sent')}
            buttonText={t('try_button')}
            onClose={onClosePopupHandler}
            onOk={onOkErrorPopupHandler}
          />
        ) : null}
      </Grid>
    </ModalPopupCommon>
  );
};

import React, { useState } from 'react';

import { Box, Chip, FormControl, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CurrencyCodeDto } from '@app/core/api';

import { CurrencySelectContent } from './components/CurrencySelectContent';
import { styles } from './styles';
import { Select } from '../Select';
import { StatusText } from '../StatusText';

export type CurrencySelectProps = {
  name: string;
  value: CurrencyCodeDto[];
  errorText?: string | undefined;
  onChange(newValue: CurrencyCodeDto[]): void;
  options: CurrencyCodeDto[];
};

export const CurrencySelectQuery: React.FC<CurrencySelectProps> = ({ name, value, errorText, onChange, options }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const currencySelectHandler = (selectedAccounts: CurrencyCodeDto[]) => {
    onChange(selectedAccounts);
  };

  const resetHandler = () => {
    onChange([]);
    setAllSelected(false);
  };

  const selectActiveAllHandler = (event: React.ChangeEvent<{}>, checked: boolean) => {
    if (checked) {
      onChange(options);
      setAllSelected(true);
    }
  };

  const hasError = Boolean(errorText);

  return (
    <FormControl fullWidth error={hasError}>
      <Select
        displayEmpty
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        fullWidth
        value={value}
        disabled={!options.length}
        MenuProps={{ keepMounted: true, disablePortal: true }}
        renderValue={(selectedCurrency: any) => {
          if (!selectedCurrency || selectedCurrency.length === 0) {
            return (
              <Typography variant="body2" sx={styles.selectPlaceholder}>
                {t('exchange_currencySelect_placeholder')}
              </Typography>
            );
          }
          return (
            <Box sx={styles.chipContainer}>
              <Box sx={styles.scrollContainer}>
                {selectedCurrency?.map((currency: CurrencyCodeDto) => {
                  return (
                    <Chip
                      key={currency.numeric}
                      label={`${currency.alpha3} ${currency.name},`}
                      size="small"
                      sx={styles.chip}
                    />
                  );
                })}
              </Box>
            </Box>
          );
        }}
      >
        {options.length ? (
          <CurrencySelectContent
            name={name}
            options={options}
            selectedValues={value}
            onChange={currencySelectHandler}
            onResetHandler={resetHandler}
            onSelectActiveAll={selectActiveAllHandler}
            allSelected={allSelected}
          />
        ) : null}
      </Select>
      {hasError ? <StatusText sx={styles.errorText}>{errorText}</StatusText> : null}
    </FormControl>
  );
};

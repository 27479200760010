import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  ApiError,
  CreateCurrencyOperationResponseDto,
  CurrencyOperationsExchangeTypeEnum,
  DetachOperationRequestDto,
  DetachOperationResponseDto,
  DocumentOperationType,
  DocumentStatusEnum,
  EditCurrencyOperationRequestDto,
  GetCurrencyOperationDto,
  GetDocumentResponseDto,
  GetOneCurrencyOperationResponseDto,
  SendToSignResponseDto,
} from '@app/core/api';
import { SignCurrencyExchangeMultiple } from '@app/core/components';
import { ConfirmSignDocuments } from '@app/core/components/ConfirmSignDocuments';
import { SignCurrencyDocumentsDialog } from '@app/core/components/SignCurrencyDocumentsDialog';
import { ddMMYYYYFormat } from '@app/core/constants';
import { useCurrencyUploadFiles } from '@app/core/hooks';
import { FileType } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { CurrencyForm, EditData } from '@app/pages/exchange/components/form';
import { makeCurrencyOperationFromFormData } from '@app/pages/exchange/components/form/helpers';
import { CurrencyFormData } from '@app/pages/exchange/components/form/types';
import {
  detachOperationFromFileFn,
  editCurrencyOperationFn,
  getCurrencyOperationFilesFn,
  getCurrencyOperationFn,
  sendToSignCurrencyOperationFn,
} from '@app/pages/exchange/query';
import { styles } from '@app/pages/exchange/styles';
import { RouteList } from '@app/src/constants/routeList';

export const EditExchangePage: React.FC = () => {
  const { uuid } = useParams();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { showLoader } = useUIState();
  const showLeavePopupRef = useRef<boolean>(false);
  const { t } = useTranslation();
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean>();
  const [defaultValues, setDefaultValues] = useState<CurrencyFormData>();
  const [showSignatureDialog, setShowSignatureDialog] = useState<boolean>(false);
  const [editedCurrencyOperation, setEditedCurrencyOperation] = useState<GetCurrencyOperationDto>();
  const [showConfirmSignDocumentsDialog, setShowConfirmSignDocumentsDialog] = useState<boolean>(false);
  const [showSignCurrencyDocumentsDialog, setShowSignCurrencyDocumentsDialog] = useState<boolean>(false);
  const { data, mutate, isLoading } = useMutation<GetOneCurrencyOperationResponseDto, ApiError, string>(
    getCurrencyOperationFn
  );

  const { data: operationFilesData, mutate: mutateOperationFiles } = useMutation<
    Array<GetDocumentResponseDto>,
    ApiError,
    string
  >(getCurrencyOperationFilesFn);

  const {
    data: editCurrencyOperationData,
    mutateAsync: mutateAsyncCurrencyOperation,
    isLoading: createCurrencyOperationLoading,
  } = useMutation<CreateCurrencyOperationResponseDto, ApiError, EditCurrencyOperationRequestDto>(
    editCurrencyOperationFn
  );

  const { mutate: sendToSignMutation } = useMutation<SendToSignResponseDto, ApiError, string>(
    sendToSignCurrencyOperationFn
  );

  const { mutateAsync: mutateDetachOperationAsync } = useMutation<
    DetachOperationResponseDto,
    ApiError,
    DetachOperationRequestDto
  >(detachOperationFromFileFn);

  const { mutateAsync: mutateAsyncUploadFiles, isLoading: uploadLoading } = useCurrencyUploadFiles();

  useEffect(() => {
    showLoader(uploadLoading);
  }, [uploadLoading, showLoader]);

  const showLeavePopup = useCallback((): boolean => {
    return showLeavePopupRef.current;
  }, []);

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    showLeavePopupRef.current = formDirty;
  };

  useEffect(() => {
    if (uuid) {
      mutate(uuid);
      mutateOperationFiles(uuid);
    }
  }, [mutate, mutateOperationFiles, uuid]);

  useEffect(() => {
    if (data) {
      setDefaultValues({
        amount: `${data.amount}`,
        documentNumber: data.documentNumber,
        depositAccount: JSON.stringify(data.depositAccount),
        withdrawalAccount: JSON.stringify(data.withdrawalAccount),
        documentDate: new Date(data.documentDate),
        temp_documentDate: format(new Date(data.documentDate), ddMMYYYYFormat.format),
        documentValueDate: data.documentValueDate ? new Date(data.documentValueDate) : undefined,
        temp_documentValueDate: data.documentValueDate
          ? format(new Date(data.documentValueDate), ddMMYYYYFormat.format)
          : undefined,
        exchangeRate: data.exchangeRate ? `${data.exchangeRate}` : undefined,
        exchangeRateType: data.exchangeRateType,
        currencyOperationReason: data.currencyOperationReason,
        _userConfirmCheck: false,
      });
    }
  }, [data]);

  useEffect(() => {
    showLoader(isLoading);
  }, [isLoading, showLoader]);

  const onSuccess = () => {
    notify({
      notifyProps: {
        title: t('currencyExchange_saveSuccessTitle'),
        message: t('currencyExchange_saveSuccessMessage'),
        severity: 'success',
      },
    });
    showLoader(false);
    navigate(RouteList.exchange);
  };

  const editOperation = ({
    operationUuid,
    formData,
    files,
    currencyExchangeType,
    onSuccessEdit,
  }: {
    operationUuid: string;
    formData: CurrencyFormData;
    files: FileType[];
    currencyExchangeType: CurrencyOperationsExchangeTypeEnum;
    onSuccessEdit(result: CreateCurrencyOperationResponseDto): void;
  }) => {
    if (uuid) {
      mutateAsyncCurrencyOperation({
        operation: { uuid: operationUuid, ...makeCurrencyOperationFromFormData(formData) },
      })
        .then((editOperationResponse) => {
          if (editOperationResponse.operation) {
            if (files.length) {
              mutateAsyncUploadFiles({
                currencyOperationIds: [uuid],
                file: files.map((file) => file.file),
                operationsTypes: [DocumentOperationType.CurrencyOperation],
              })
                .then(() => {
                  onSuccessEdit(editOperationResponse);
                })
                .catch(() => {
                  notify({
                    notifyProps: {
                      title: t('attachFile_ErrorTitle'),
                      message: t('attachFile_ErrorMessage'),
                      severity: 'error',
                    },
                  });
                  showLoader(false);
                });
            } else {
              onSuccessEdit(editOperationResponse);
            }
          }
          if (editOperationResponse.additionalInfo) {
            notify({
              notifyProps: {
                title: t('currencyExchange_saveErrorTitle'),
                message: t('currencyExchange_saveErrorMessage'),
                severity: 'error',
              },
            });
            showLoader(false);
          }
        })
        .catch((e: ApiError) => {
          notify({
            notifyProps: {
              title: t('currencyExchange_saveErrorTitle'),
              message: t('currencyExchange_saveErrorMessage'),
              severity: 'error',
            },
          });
          showLoader(false);
        });
    }
  };

  const onUploadFilesHandler = (files: FileType[], onSuccessLoad: () => void) => {
    if (uuid) {
      mutateAsyncUploadFiles({
        currencyOperationIds: [uuid],
        file: files.map((file) => file.file),
        operationsTypes: [DocumentOperationType.CurrencyOperation],
      })
        .then(() => {
          onSuccessLoad();
          if (uuid) {
            mutateOperationFiles(uuid);
          }
        })
        .catch(() => {
          notify({
            notifyProps: {
              title: t('attachFile_ErrorTitle'),
              message: t('attachFile_ErrorMessage'),
              severity: 'error',
            },
          });
        });
    }
  };

  const onSaveHandler = async (editData: EditData) => {
    if (uuid) {
      showLoader(true);
      editOperation({
        operationUuid: uuid,
        ...editData,
        onSuccessEdit: onSuccess,
      });
    }
  };

  const onSendToSignClickHandler = async (editData: EditData) => {
    if (uuid) {
      showLoader(true);
      editOperation({
        operationUuid: uuid,
        ...editData,
        onSuccessEdit: onSuccess,
      });
      sendToSignMutation(uuid);
    }
  };

  const onSaveAndSignHandler = async (editData: EditData, signFromAnotherPers?: boolean) => {
    if (uuid) {
      showLoader(true);
      editOperation({
        operationUuid: uuid,
        ...editData,
        onSuccessEdit(result: CreateCurrencyOperationResponseDto) {
          setSignFromAnotherPerson(signFromAnotherPers);
          showLoader(false);
          if (result.operation) {
            setEditedCurrencyOperation(result.operation);
            setShowSignatureDialog(true);
          }
        },
      });
    }
  };

  const onCloseSignCurrencyModalHandler = () => {
    setShowSignatureDialog(false);
  };

  const onSuccessPopupCancelHandler = () => {
    setShowSignatureDialog(false);
    navigate(RouteList.exchange);
  };

  const onDeleteFileHandler = (document: GetDocumentResponseDto) => {
    if (uuid) {
      mutateDetachOperationAsync({
        uuids: [document.uuid],
        currencyOperationId: uuid,
        operationType: DocumentOperationType.CurrencyOperation,
      })
        .then(() => {
          mutateOperationFiles(uuid);
        })
        .catch((e: ApiError) => {
          notify({
            notifyProps: {
              title: t('server_error'),
              message: t('deleteDocumentErrorMessage'),
              severity: 'error',
            },
          });
        });
    }
  };

  const toSignDocuments = operationFilesData?.filter(
    (item) => item.status === DocumentStatusEnum.Saved || item.status === DocumentStatusEnum.ToSign
  );

  const onSignDocumentsClickHandler = () => {
    if (toSignDocuments && toSignDocuments.length) {
      setShowConfirmSignDocumentsDialog(true);
    }
  };

  const onCloseSignDocumentsHandler = () => {
    setShowConfirmSignDocumentsDialog(false);
  };

  const onAgreeSignHandler = () => {
    setShowConfirmSignDocumentsDialog(false);
    setShowSignCurrencyDocumentsDialog(true);
  };

  const onCloseSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
  };

  const onSuccessSignCurrencyDocumentsDialog = () => {
    setShowSignCurrencyDocumentsDialog(false);
    if (uuid) {
      mutateOperationFiles(uuid);
    }
  };

  return (
    <>
      <PageWrapper sx={isMobile ? styles.mobilePageWrapper : undefined}>
        <PageHeader
          title={t('currencyExchange_editTitle')}
          showLeavePopup={showLeavePopup}
          backLink={-1}
          confirmQuestionText={t('confirmLeaveCreateExchangePopupMessage')}
          showNavigateButton={!isMobile}
        />
        <PageContainer>
          <Grid container direction="column" wrap="nowrap">
            <Box mt={2}>
              {data ? (
                <CurrencyForm
                  uuid={data.uuid}
                  currencyExchangeType={data.currencyExchangeType}
                  defaultValues={defaultValues}
                  status={data.status}
                  documents={operationFilesData}
                  createDocumentInfo={data.createDocumentInfo}
                  userHistory={data.userHistory}
                  personToSign={data.signInfo.personToSign}
                  signDocumentsButtonEnabled={Boolean(toSignDocuments?.length)}
                  signPermissions={data.actions}
                  additionalInfo={editCurrencyOperationData?.additionalInfo}
                  onChange={onChangeHandler}
                  onSave={onSaveHandler}
                  onSaveAndSign={onSaveAndSignHandler}
                  onUploadFiles={onUploadFilesHandler}
                  onDeleteFile={onDeleteFileHandler}
                  onSignCurrencyDocumentFiles={onSignDocumentsClickHandler}
                  onSendToSign={onSendToSignClickHandler}
                />
              ) : null}
            </Box>
          </Grid>
        </PageContainer>
      </PageWrapper>

      {showSignatureDialog && editedCurrencyOperation ? (
        <SignCurrencyExchangeMultiple
          operations={[editedCurrencyOperation]}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessPopupCancelHandler}
          onClose={onCloseSignCurrencyModalHandler}
        />
      ) : null}

      {showConfirmSignDocumentsDialog && toSignDocuments && data ? (
        <ConfirmSignDocuments
          onAgreeSign={onAgreeSignHandler}
          onClose={onCloseSignDocumentsHandler}
          selectedDocumentsFilesCount={data.documents.length}
          toSignDocuments={toSignDocuments}
        />
      ) : null}

      {showSignCurrencyDocumentsDialog && toSignDocuments ? (
        <SignCurrencyDocumentsDialog
          toSignDocuments={toSignDocuments}
          onClose={onCloseSignCurrencyDocumentsDialog}
          onSuccessPopupCancel={onSuccessSignCurrencyDocumentsDialog}
        />
      ) : null}
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNotify } from '@app/context/NotifyContext';
import { useUIState } from '@app/context/UIContext';
import {
  CreateStatusEnum,
  CreatePaymentOrderResultDto,
  DocumentTemplateDto,
  PaymentOrderAdditionalInfoDto,
  PaymentTemplateTypeEnum,
  ResultStatusEnum,
} from '@app/core/api';
import { LeaveEditPopup, PeopleToSignList } from '@app/core/components';
import { useGeneratePaymentOrderNumber, useGetOrderByUuid, useGetUahPaymentSigners } from '@app/core/hooks';
import { useCreateOwnPayment } from '@app/core/hooks/useCreateOwnPayment';
import { useSendToSignPaymentOrder } from '@app/core/hooks/useSendToSignPaymentOrder';
import { isMobile } from '@app/core/utils';
import { BetweenMyAccountEditForm } from '@app/pages/payment/components/BetweenMyAccountEditForm';
import {
  BetweenMyAccountFormData,
  BetweenMyAccountFormDefaultValues,
} from '@app/pages/payment/components/BetweenMyAccountEditForm/types';
import { SelectPaymentTemplate } from '@app/pages/payment/components/SelectPaymentTemplate';
import { makeCreateOwnPaymentSubmitData } from '@app/pages/payment/create/BetweenMyAccount/helpers';
import { LocalPaymentUrlQuery } from '@app/pages/payment/create/types';
import { ProfileState } from '@app/slices/profileSlice';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

interface CreatePaymentProps {
  paymentData: BetweenMyAccountFormData;
  onCreateSuccess(operation: CreatePaymentOrderResultDto): void;
  onCreateError(additionalInfo?: PaymentOrderAdditionalInfoDto): void;
}

export interface BetweenMyAccountProps {
  sendOnSign(operation: CreatePaymentOrderResultDto): void;
  onChange({ formDirty }: { formDirty: boolean }): void;
}

export const BetweenMyAccount: React.FC<BetweenMyAccountProps> = ({ sendOnSign, onChange }) => {
  const { permissions, userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { showLoader } = useUIState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [leavePopupOpen, setLeavePopupOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<BetweenMyAccountFormDefaultValues>({});
  const urlQueryValues = useLocation().search;
  const { copyFrom: copyFromUuid } = parse(urlQueryValues) as LocalPaymentUrlQuery;
  const { data: generatedOrderNumber } = useGeneratePaymentOrderNumber();
  const { data: peopleToSigne } = useGetUahPaymentSigners();

  const {
    data: copyFromPaymentOrder,
    refetch: refetchCopyFromPaymentOrder,
    isLoading: isLoading2,
  } = useGetOrderByUuid(copyFromUuid as string);

  useEffect(() => {
    if (copyFromUuid) {
      refetchCopyFromPaymentOrder();
    }
  }, [copyFromUuid, refetchCopyFromPaymentOrder]);

  const {
    data: createPaymentResultData,
    mutateAsync,
    reset: resetCreatePayment,
    isLoading: isLoading1,
  } = useCreateOwnPayment();

  const {
    data: sendToSignPaymentResultData,
    mutate: mutateSendToSign,
    isLoading: isSendToSignLoading,
  } = useSendToSignPaymentOrder();

  useEffect(() => {
    if (generatedOrderNumber) {
      setDefaultValues({
        documentNumber: generatedOrderNumber,
      });
    }
  }, [generatedOrderNumber]);

  const createPayment = ({ paymentData, onCreateSuccess, onCreateError }: CreatePaymentProps) => {
    mutateAsync(makeCreateOwnPaymentSubmitData(paymentData))
      .then((result) => {
        if (result.operation) {
          onCreateSuccess(result.operation);
        }
        if (result.additionalInfo) {
          notify({
            notifyProps: {
              title: t('payment_save_error'),
              message: t('save_payment_error'),
              severity: 'error',
            },
          });
          onCreateError(result.additionalInfo);
        }
      })
      .catch((error) => {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t(error),
            severity: 'error',
          },
        });
        onCreateError();
      });
  };

  const onSaveClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        navigate(RouteList.operations);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSignClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('save_payment_success'),
            severity: 'success',
          },
        });
        showLoader(false);
        resetCreatePayment();
        sendOnSign(operation);
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  const onSaveAndSendToSignClickHandler = (paymentData: BetweenMyAccountFormData) => {
    showLoader(true);
    createPayment({
      paymentData,
      onCreateSuccess(operation: CreatePaymentOrderResultDto) {
        mutateSendToSign(operation.uuid);
        resetCreatePayment();
      },
      onCreateError() {
        showLoader(false);
      },
    });
  };

  useEffect(() => {
    if (sendToSignPaymentResultData) {
      if (sendToSignPaymentResultData.status === ResultStatusEnum.Successfully) {
        notify({
          notifyProps: {
            title: t('payment_save_success'),
            message: t('send_to_sign_payment_success'),
            severity: 'success',
          },
        });
        navigate(RouteList.operations);
      }
      if (sendToSignPaymentResultData.status === ResultStatusEnum.Error) {
        notify({
          notifyProps: {
            title: t('payment_save_error'),
            message: t('send_to_sign_payment_error'),
            severity: 'error',
          },
        });
      }
    }
  }, [navigate, notify, sendToSignPaymentResultData, showLoader, t]);

  useEffect(() => {
    showLoader(isLoading1 || isLoading2 || isSendToSignLoading);
  }, [isLoading1, isLoading2, isSendToSignLoading, showLoader]);

  const onConfirmLeaveHandler = () => {
    setLeavePopupOpen(false);
    navigate(-1);
  };

  const onCancelHandler = () => {
    setLeavePopupOpen(true);
  };

  const onCancelLeaveHandler = () => {
    setLeavePopupOpen(false);
  };

  const onTemplateChangeHandler = (templateData: DocumentTemplateDto) => {
    setDefaultValues({
      documentNumber: generatedOrderNumber,
      senderAccountId: templateData.senderAccount.uuid || '',
      recipientAccountId: templateData.recipientAccount.uuid || '',
      amount: templateData.amount,
      paymentReference: templateData.paymentReference,
    });
  };

  useEffect(() => {
    if (copyFromPaymentOrder) {
      setDefaultValues({
        documentNumber: generatedOrderNumber,
        senderAccountId: copyFromPaymentOrder.account.id || '',
        recipientAccountId: copyFromPaymentOrder.details.recipientAccount.uuid || '',
        amount: copyFromPaymentOrder.amount,
        paymentReference: copyFromPaymentOrder.paymentReference,
      });
    }
  }, [copyFromPaymentOrder, generatedOrderNumber]);

  return (
    <>
      <Box p={isMobile ? 0 : 3} mb={2} width="100%">
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item xs={isMobile ? false : 9} container>
            <BetweenMyAccountEditForm
              defaultValues={defaultValues}
              signPermissions={{
                ...permissions.sign.paymentOrder,
                allowSignFromAnotherPerson: permissions.sign.paymentOrder.signFromAnotherPerson,
              }}
              additionalInfo={createPaymentResultData?.additionalInfo}
              onCancelHandler={onCancelHandler}
              onSaveClick={onSaveClickHandler}
              onSaveAndSignClick={onSaveAndSignClickHandler}
              onTemplateChangeHandler={onTemplateChangeHandler}
              onChange={onChange}
              onSaveAndSendToSignClickHandler={onSaveAndSendToSignClickHandler}
            />
          </Grid>
          {!isMobile && userProfile ? (
            <Box sx={{ width: '220px' }}>
              <Box pl={2}>
                <SelectPaymentTemplate
                  templateType={PaymentTemplateTypeEnum.Own}
                  onTemplateChange={onTemplateChangeHandler}
                  placeholder={t('enterName_placeholder')}
                />
                <Box pt={10}>
                  <PeopleToSignList signInfo={peopleToSigne?.personToSign || []} userProfile={userProfile} />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Box>
      <LeaveEditPopup
        questionText={t('confirmLeaveEditPaymentPopupMessage')}
        open={leavePopupOpen}
        onConfirm={onConfirmLeaveHandler}
        onCancel={onCancelLeaveHandler}
      />
    </>
  );
};

export const styles = {
  root: {
    background: 'none',
    boxShadow: 'none',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    fontWeight: 600,
    '& span': {
      color: 'colors.gray400',
    },
  },
  tooltipContent: {
    border: `1px solid`,
    borderColor: 'colors.gray100',
    borderRadius: '6px',
    padding: '14px 20px 20px 20px',
    width: '512px',
    backgroundColor: 'white',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '9px',
      height: '9px',
      top: '-1px',
      right: '74px',
      backgroundColor: 'white',
      borderLeft: `1px solid`,
      borderTop: `1px solid`,
      borderColor: 'colors.gray100',
      transform: 'translate(-50%, -47%) rotate(45deg)',
      borderRadius: '1px 0 0 0',
    },
  },
  row: {
    marginBottom: '10px',
    lineHeight: '1.4',
    color: 'colors.gray400',
  },
  rowRight: {
    textAlign: 'right',
    paddingLeft: '10px',
    color: 'colors.primary600',
    fontSize: '0.875rem',
  },
  buttonPopoverSvgIcon: {
    width: '17px',
    height: '17px',
    stroke: 'colors.primary500',
    marginLeft: '4px',
    cursor: 'pointer',
  },
  buttonPopoverSvgIconFill: {
    strokeWidth: 0,
    fill: 'colors.primary500',
  },
  alertCopy: {
    backgroundColor: 'colors.successBackground',
    border: `1px solid`,
    borderColor: 'colors.success',
    borderRadius: '4px',
    padding: '12px',
    color: 'colors.success',
    position: 'absolute',
    left: '20px',
    right: '20px',
    top: '20px',
    zIndex: 10,
    width: 'auto',
    animation: 'fadeIn 550ms ease-in-out normal none running',
  },
  alertCopyText: {
    fontWeight: 600,
    color: 'colors.success',
    marginLeft: '8px',
  },
};

import { CreateBudgetaryPaymentDto, CurrencyEnum, GetAccountResponseDto } from '@app/core/api';
import { StatePaymentFormData } from '@app/pages/payment/components/StatePaymentForm/types';

export const makeStatePaymentData = (paymentData: StatePaymentFormData): CreateBudgetaryPaymentDto => {
  const {
    senderAccount: senderAccountInfo,
    recipientAccount,
    paymentReference,
    recipientLei,
    amount,
    recipientName,
    documentDate,
    documentValuationDate,
    documentNumber,
  } = paymentData;

  const {
    account: { accountNumber: senderAccount },
  }: GetAccountResponseDto = JSON.parse(senderAccountInfo);

  return {
    paymentDocumentNumber: documentNumber,
    paymentDocumentDate: documentDate.toISOString(),
    paymentDocumentValueDate: documentValuationDate ? documentValuationDate.toISOString() : undefined,
    amount: parseFloat(amount),
    senderAccount,
    recipientName,
    recipientLei,
    recipientAccount,
    paymentReference,
    currency: CurrencyEnum.Uah,
    realSenderNonLeiFlag: false,
  };
};

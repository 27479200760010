import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography, Box } from '@mui/material';
import {
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  ColumnOrderState,
  VisibilityState,
  SortingState,
  ColumnSort,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { InternalLoanSortingRuleDto, LoanDto, LoanSortingFieldsEnum } from '@app/core/api';
import { ComplexTable } from '@app/core/components/ComplexTable';
import { TableHeadContent } from '@app/core/components/ComplexTable/components/TableHeadContent';
import { TablePagination } from '@app/core/components/ComplexTable/components/TablePagination';
import { TableRowContent } from '@app/core/components/ComplexTable/components/TableRowContent';
import { getColumnVisibility, getColumOrder } from '@app/core/components/ComplexTable/helpers';
import { Loader } from '@app/core/components/Loader';
import {
  statementsTableMinRowsForShowPagination,
  statementsTableRowsPerPageDefault,
  statementsTableRowsPerPageOptions,
} from '@app/core/constants';
import { TableColumnConfig, CurrencyEnum, PaginationChangeType, TablePaginationType } from '@app/core/types';
import { makeTableColumns } from '@app/pages/loans/components/LoansTable/components/TableContent/columns';
import { styles } from '@app/pages/loans/components/LoansTable/components/TableContent/styles';
import { TableToolbar } from '@app/pages/loans/components/LoansTable/components/TableToolbar';
import { appConfig } from '@app/src/config';

interface TableContentProps {
  loans: LoanDto[];
  columnsConfig: TableColumnConfig[];
  currency?: CurrencyEnum;
  pagination?: TablePaginationType;
  loading?: boolean;
  queryKey?: string;
  onSettingsButtonClick(): void;
  onSort?(rule: InternalLoanSortingRuleDto): void;
  onPaginationChange(pagination: PaginationChangeType): void;
}

export const TableContent: React.FC<TableContentProps> = ({
  loans,
  columnsConfig,
  currency,
  pagination,
  loading,
  queryKey,
  onSettingsButtonClick,
  onSort,
  onPaginationChange,
}) => {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getColumnVisibility(columnsConfig));
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(getColumOrder(columnsConfig));
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortRuleRef = useRef<ColumnSort | undefined>();
  const data = useMemo(() => {
    return [...loans];
  }, [loans]);

  const columns = useMemo(() => {
    return [
      ...makeTableColumns({
        t,
        onSettingsButtonClick,
      }),
    ];
  }, [onSettingsButtonClick, t]);

  const table = useReactTable<LoanDto>({
    columns,
    data,
    state: {
      sorting,
      columnOrder,
      columnVisibility,
    },
    manualPagination: true,
    enableSorting: loans.length > 0,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (onSort && sorting.length) {
      const sortRule = sorting[0];
      if (sortRule && (sortRule.id !== sortRuleRef.current?.id || sortRule.desc !== sortRuleRef.current?.desc)) {
        sortRuleRef.current = sortRule;
        onSort({
          field: sortRule.id as LoanSortingFieldsEnum,
          desc: sortRule.desc,
        });
      }
    }
  }, [onSort, sorting]);

  useEffect(() => {
    setColumnVisibility(getColumnVisibility(columnsConfig));
    setColumnOrder(getColumOrder(columnsConfig));
  }, [columnsConfig]);

  const buildBodyContent = (): React.ReactNode => {
    if (loading) {
      return <Box sx={styles.emptyRow}>{loading ? <Loader /> : null}</Box>;
    }
    if (!loans.length) {
      return (
        <Grid container alignItems="center" justifyContent="center" sx={styles.emptyRow}>
          <Typography variant="body2">{t('empty_table_status')}</Typography>
        </Grid>
      );
    }
    return table.getRowModel().rows.map((row) => {
      return <TableRowContent<LoanDto> key={row.id} row={row} styles={styles} />;
    });
  };

  const showPagination = Boolean(pagination && pagination.totalCount > statementsTableMinRowsForShowPagination);

  return (
    <>
      {appConfig.enableNextFeatures ? <TableToolbar queryKey={queryKey} /> : null}
      <ComplexTable
        head={{ content: <TableHeadContent<LoanDto> {...{ table, styles }} /> }}
        body={{ content: buildBodyContent() }}
        footer={{
          content: (
            <TablePagination
              totalCount={pagination?.totalCount || 0}
              page={pagination?.page || 1}
              rowsPerPage={pagination?.rowsPerPage || statementsTableRowsPerPageDefault}
              labelRowsPerPage={t('showOnPageShort')}
              rowsPerPageOptions={statementsTableRowsPerPageOptions}
              onChange={onPaginationChange}
              show={showPagination}
            />
          ),
        }}
      />
    </>
  );
};

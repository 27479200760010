import React, { useState } from 'react';

import { Box, Button, Collapse, FormControl, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DownChevronSmallIcon, NotifySmallIcon, UpChevronSmallIcon } from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import { FrontLanguageEnum, GetOneOperationResponseDto, PaymentOrderStatusEnum } from '@app/core/api';
import { PeopleToSignList } from '@app/core/components/PeopleToSignList';
import { useSendRemindPaymentOrderSign } from '@app/core/hooks/useSendRemindPaymentOrderSign';
import { FULL_DATE_TIME_FORMAT } from '@app/core/utils';
import { styles } from '@app/pages/payment/components/PaymentInfo/style';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

type PaymentInfoProps = {
  paymentOrder: GetOneOperationResponseDto;
  edit: boolean;
};

export const PaymentInfo: React.FC<PaymentInfoProps> = ({ paymentOrder, edit }): JSX.Element => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const { userProfile } = useAppSelector((state): ProfileState => state.profile);
  const { t, i18n } = useTranslation();
  const { notify } = useNotify();

  const { mutate } = useSendRemindPaymentOrderSign();

  const { status, signInfo, paymentOrdersHistory, createDocumentInfo, uuid } = paymentOrder;
  const { personToSign } = signInfo;

  const onclickHistoryHandler = () => {
    setHistoryOpen(!historyOpen);
  };

  const onNotifyButtonClick = () => {
    mutate(
      { uuid, language: i18n.language as unknown as FrontLanguageEnum },
      {
        onSuccess: () => {
          notify({
            notifyProps: {
              title: t('success'),
              message: t('remindSuccessMessage'),
              severity: 'success',
            },
          });
        },
        onError: () => {
          notify({
            notifyProps: {
              title: t('error'),
              message: t('remindErrorMessage'),
              severity: 'error',
            },
          });
        },
      }
    );
  };
  return (
    <Box>
      {personToSign.length && userProfile ? (
        <>
          <PeopleToSignList status={status} signInfo={personToSign} userProfile={userProfile} />
          {status === PaymentOrderStatusEnum.OnSigning && !edit ? (
            <>
              <Box mt={6}>
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onNotifyButtonClick}
                    endIcon={<NotifySmallIcon fontSize="small" />}
                  >
                    {t('paymentInfo_alarmNotifyButton')}
                  </Button>
                </FormControl>
              </Box>
            </>
          ) : null}
        </>
      ) : null}
      <Box pt={personToSign.length ? 6 : 0}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={styles.historyHeaderContainer}
          onClick={onclickHistoryHandler}
        >
          <Typography variant="body2" component="span" sx={styles.historyTitle}>
            {t('paymentInfo_historyTitle')}
          </Typography>
          {historyOpen ? <UpChevronSmallIcon fontSize="small" /> : <DownChevronSmallIcon fontSize="small" />}
        </Grid>
        <Collapse in={historyOpen} unmountOnExit={false}>
          <Box sx={styles.historyBodyContainer} p={4}>
            <Box>
              <Typography variant="caption" sx={styles.historyItemTitle}>
                {t('paymentInfo_documentCreated_title')}
              </Typography>
              <Box mt={2}>
                <Grid container justifyContent="space-between">
                  <Typography variant="caption" sx={styles.historyItemName}>
                    {t('paymentInfo_documentCreated_date')}
                  </Typography>
                  <Typography variant="caption" sx={styles.historyItemValue}>
                    {format(new Date(createDocumentInfo.createDocumentDate), FULL_DATE_TIME_FORMAT)}
                  </Typography>
                </Grid>
              </Box>
              <Box mt={2}>
                <>
                  {createDocumentInfo.lastName && createDocumentInfo.firstName ? (
                    <Grid container justifyContent="space-between">
                      <Typography variant="caption" sx={styles.historyItemName}>
                        {t('paymentInfo_documentCreated_creator')}
                      </Typography>
                      <Typography variant="caption" sx={styles.historyItemValue}>
                        {`${createDocumentInfo.lastName} ${createDocumentInfo.firstName}`}
                      </Typography>
                    </Grid>
                  ) : null}
                </>
              </Box>
            </Box>

            {paymentOrdersHistory.length ? (
              <Box mt={4}>
                <Typography variant="caption" sx={styles.historyItemTitle}>
                  {t('paymentInfo_documentChanges_title')}
                </Typography>
                {paymentOrdersHistory.map((historyItem) => {
                  return (
                    <Box key={historyItem.id} mb={4}>
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_date')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {format(new Date(historyItem.createdAt), FULL_DATE_TIME_FORMAT)}
                          </Typography>
                        </Grid>
                      </Box>
                      {historyItem.lastName || historyItem.firstName ? (
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('paymentInfo_documentCreated_creator')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {historyItem.lastName || ''} {historyItem.firstName || ''}
                            </Typography>
                          </Grid>
                        </Box>
                      ) : null}
                      <Box mt={2}>
                        <Grid container justifyContent="space-between">
                          <Typography variant="caption" sx={styles.historyItemName}>
                            {t('paymentInfo_documentCreated_changes')}
                          </Typography>
                          <Typography variant="caption" sx={styles.historyItemValue}>
                            {t(historyItem.actionType)}
                          </Typography>
                        </Grid>
                      </Box>
                      {historyItem?.signatureBelongsToUser ? (
                        <Box mt={2}>
                          <Grid container justifyContent="space-between">
                            <Typography variant="caption" sx={styles.historyItemName}>
                              {t('signature')}
                            </Typography>
                            <Typography variant="caption" sx={styles.historyItemValue}>
                              {historyItem?.signatureBelongsToUser.lastName || ''}{' '}
                              {historyItem?.signatureBelongsToUser.firstName || ''}
                            </Typography>
                          </Grid>
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

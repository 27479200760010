import React from 'react';

import { SxProps, Theme } from '@mui/material';
import { flexRender, RowData, Table } from '@tanstack/react-table';

import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { headerCellProps } from '../helpers';

interface BuildHeadContent<TData extends RowData> {
  table: Table<TData>;
  styles: { [name: string]: SxProps<Theme> };
  resizable?: boolean;
}
export const TableHeadContent = <T,>({ table, styles, resizable = false }: BuildHeadContent<T>): JSX.Element => {
  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableCell
              key={header.id}
              colSpan={header.colSpan}
              {...headerCellProps({ header, styles })}
              style={{
                width: resizable ? header.getSize() : undefined,
              }}
            >
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              {resizable && header.column.getCanResize() ? (
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                  }}
                />
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

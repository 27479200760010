import React from 'react';

import { Box, CardContent, FormLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CurrencySelectFilterField } from '@app/core/components/Form/controls/CurrencySelectFilterField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { ActionFiltersButtons } from '@app/pages/deposits/components/ActionFiltersButtons';
import { styles } from '@app/pages/deposits/components/DepositFilter/styles';
import { DepositTypeSelectField } from '@app/pages/deposits/components/DepositTypeSelectField';
import { DepositsFilterField } from '@app/pages/deposits/types';

interface DepositFilterProps {
  fullItems: boolean;
  namesFieldWithError?: string[];

  resetFormHandler?(): void;
}

export const DepositFilter: React.FC<DepositFilterProps> = ({ fullItems, namesFieldWithError, resetFormHandler }) => {
  const { t } = useTranslation();
  const nowDate = new Date();
  const agreementMinDate = new Date(2010, 0, 1);
  const agreementMaxDate = new Date(2045, 11, 31);
  const fromYear = 2010;
  const toYear = 2045;

  return (
    <Grid container direction="column" sx={styles.wrapper}>
      <CardContent>
        <Grid item container direction="column">
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_startDate_from')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={DepositsFilterField.startDateFrom}
                  inputProps={{ fullWidth: true }}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: nowDate,
                    },
                    fromYear: 2010,
                  }}
                />
              </Box>
            </Grid>
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_startDate')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={DepositsFilterField.startDateTo}
                      inputProps={{ fullWidth: true }}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: nowDate,
                        },
                        fromYear: 2010,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_endDate_from')}</FormLabel>
                  <Box pt={2}>
                    <DateSelectField
                      name={DepositsFilterField.endDateFrom}
                      inputProps={{ fullWidth: true }}
                      datePickerProps={{
                        disabled: {
                          before: agreementMinDate,
                          after: agreementMaxDate,
                        },
                        fromYear,
                        toYear,
                      }}
                    />
                  </Box>
                </Grid>
              </>
            ) : null}
            <Grid item xs={3}>
              <FormLabel component="legend">{t('loans_endDate_to')}</FormLabel>
              <Box pt={2}>
                <DateSelectField
                  name={DepositsFilterField.endDateTo}
                  inputProps={{ fullWidth: true }}
                  datePickerProps={{
                    disabled: {
                      before: agreementMinDate,
                      after: agreementMaxDate,
                    },
                    fromYear,
                    toYear,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend">{t('deposits_depositAgreementNumber')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={DepositsFilterField.agreementNumber}
                  fullWidth
                  error={namesFieldWithError?.includes(DepositsFilterField.agreementNumber)}
                />
              </Box>
            </Grid>
            {!fullItems ? (
              <Grid item container xs={3} alignItems="flex-start" justifyContent="flex-end">
                <Box pt={6} sx={styles.actionButtonsContainer}>
                  <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                </Box>
              </Grid>
            ) : null}
            {fullItems ? (
              <>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsTable_currency')}</FormLabel>
                  <Box pt={2}>
                    <CurrencySelectFilterField name={DepositsFilterField.currency} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel component="legend">{t('deposits_depositType')}</FormLabel>
                  <Box pt={2}>
                    <DepositTypeSelectField name={DepositsFilterField.depositType} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMin')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={DepositsFilterField.amountFrom}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('statementsFilter_amountMax')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.amount}
                      defaultValue=""
                      name={DepositsFilterField.amountTo}
                      fullWidth
                      placeholder="0.00"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRate_from')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={DepositsFilterField.interestRateFrom}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">{t('loans_interestRate_to')}</FormLabel>
                  <Box pt={2}>
                    <FormatInputField
                      type={FormatType.percent}
                      defaultValue=""
                      name={DepositsFilterField.interestRateTo}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item container alignItems="flex-end" justifyContent="flex-end" xs={12}>
                  <Box pt={2} sx={styles.actionButtonsContainer}>
                    <ActionFiltersButtons resetFormHandler={resetFormHandler} />
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

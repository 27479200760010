import { SwiftTableColumn } from '@app/pages/swift/components/SwiftTable/types';

export const fieldsCreditTable = [
  {
    columnType: SwiftTableColumn.documentDate,
    enabled: true,
    order: 0,
    edit: false,
  },
  {
    columnType: SwiftTableColumn.documentNumber,
    enabled: true,
    order: 1,
    edit: false,
  },
  {
    columnType: SwiftTableColumn.sender,
    enabled: true,
    order: 3,
    edit: true,
  },
  {
    columnType: SwiftTableColumn.recipient,
    enabled: true,
    order: 4,
    edit: false,
  },
  {
    columnType: SwiftTableColumn.paymentReference,
    enabled: true,
    order: 5,
    edit: false,
  },
  {
    columnType: SwiftTableColumn.currency,
    enabled: true,
    order: 7,
    edit: true,
  },
  {
    columnType: SwiftTableColumn.amount,
    enabled: true,
    order: 6,
    edit: true,
  },
  {
    columnType: SwiftTableColumn.status,
    enabled: true,
    order: 8,
    edit: true,
  },
];

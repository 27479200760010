export class ApiError extends Error {
  public code: number;

  constructor(code: number, msg: string) {
    super(msg);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'ApiError';
    this.code = code;
  }
}

import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

import { useGetCompanyData } from '@app/core/hooks';
import { CompanyInfoRow } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyData/components/CompanyInfoRow';
import { styles } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanyData/styles';

export const ProfileSettingsItemCompanyData: React.FC = (): JSX.Element => {
  const { data, mutate } = useGetCompanyData();

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const address = data?.address ? data?.address : undefined;

  const name = data?.fullName?.length ? data.fullName : data?.shortName;

  return (
    <Grid container direction="column" justifyContent="space-between" sx={styles.root}>
      {data ? (
        <>
          <Grid item container alignItems="center" sx={styles.item}>
            <CompanyInfoRow rowName="fullName" name={name || ''} />
          </Grid>
          <Grid item container alignItems="center" sx={styles.item}>
            <CompanyInfoRow rowName="lei" name={data.lei} />
          </Grid>
          <Grid item container alignItems="center" sx={styles.item}>
            <CompanyInfoRow rowName="address" name={address || ''} />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNotify } from '@app/context/NotifyContext';
import { DocumentNumberConfigTypeEnum, DocumentNumberResetPeriodEnum } from '@app/core/api';
import { RadioGroupField } from '@app/core/components';
import { useGetNumberConfig } from '@app/core/hooks/useGetNumbersConfig';
import { useResetNumberConfig } from '@app/core/hooks/useResetNumberConfig';
import { useUpdateNumberConfig } from '@app/core/hooks/useUpdateNumberConfig';
import { DropNumberModal } from '@app/pages/companyProfile/desktop/components/ProfileSettingsItemCompanySettings/components/DropNumberModal';
import { ProfileState } from '@app/slices/profileSlice';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { DefaultNationalityAccountFormData, filesFormatList } from './types';

export const DefaultNationalityAccountSetting: React.FC = ({}) => {
  const { t } = useTranslation();
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const methods = useForm<DefaultNationalityAccountFormData>({ mode: 'onSubmit' });
  const { getValues, setValue } = methods;
  const { permissions } = useAppSelector((state): ProfileState => state.profile);

  const { notify } = useNotify();

  const { mutate: getConfigMutate, data } = useGetNumberConfig();

  const { mutate: updateConfigMutate } = useUpdateNumberConfig();

  const { mutate: resetConfigMutate } = useResetNumberConfig();

  useEffect(() => {
    getConfigMutate({});
  }, [getConfigMutate]);

  useEffect(() => {
    if (data?.configs[0].periodType) {
      setValue('configType', data?.configs[0].periodType);
    }
  }, [data, setValue]);

  const onSaveClickHandler = () => {
    updateConfigMutate(
      {
        type: DocumentNumberConfigTypeEnum.UahOperation,
        periodType: getValues('configType') as DocumentNumberResetPeriodEnum,
      },
      {
        onSuccess: (res) => {
          setValue('configType', res.periodType);
          notify({
            notifyProps: {
              title: t('success'),
              message: t('saveNumberConfigSuccess'),
              severity: 'success',
            },
          });
        },
      }
    );
  };

  const onConfirmSaveConfig = () => {
    resetConfigMutate(
      {},
      {
        onSuccess: () => {
          setShowConfirmPopup(false);
          notify({
            notifyProps: {
              title: t('success'),
              message: t('dropNumberSuccess'),
              severity: 'success',
            },
          });
        },
      }
    );
  };

  const onDropClickHandler = () => {
    setShowConfirmPopup(true);
  };

  const onCancelResetConfig = () => {
    setShowConfirmPopup(false);
  };

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Grid container alignItems="center" sx={styles.root}>
          <Grid item container alignItems="center" justifyContent="space-between" sx={styles.rowConfig}>
            <Grid item container justifyContent="space-between">
              <Typography variant="caption" sx={styles.title}>
                {t('numerationCounter')}
              </Typography>
              <Box>
                <Typography variant="caption" sx={styles.title}>
                  {t('lastNumerationChange')}
                </Typography>
                <Typography variant="caption" sx={styles.titleData}>
                  {data?.configs[0].updatedAt &&
                    format(new Date(data?.configs[0].updatedAt.split('T')[0]), 'dd.MM.yyyy')}
                </Typography>
                <Typography variant="caption" sx={styles.titleData}>
                  {data?.configs[0].updatedByUser}
                </Typography>
              </Box>
            </Grid>
            {data?.configs[0].lastReset && data.configs[0].resetByUser ? (
              <Grid item container justifyContent="flex-end">
                <Box>
                  <Typography variant="caption" sx={styles.title}>
                    {t('lastNumerationReset')}
                  </Typography>
                  <Typography variant="caption" sx={styles.titleData}>
                    {data?.configs[0].updatedAt &&
                      format(new Date(data?.configs[0].lastReset.split('T')[0]), 'dd.MM.yyyy')}
                  </Typography>
                  <Typography variant="caption" sx={styles.titleData}>
                    {data.configs[0].resetByUser}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between" sx={styles.row}>
            <Box sx={{ width: '100%' }}>
              <Grid container justifyContent="space-between" pt={2}>
                <Grid item xs={12}>
                  <RadioGroupField
                    name={'configType'}
                    value={filesFormatList[0].value}
                    options={filesFormatList}
                    fullWidth
                    styles={{ radioLabel: styles.radioLabel, container: styles.radioContainer }}
                  />
                </Grid>
                <Grid item container justifyContent="flex-end" xs={12}>
                  <Box mt={6}>
                    <Button color="primary" disabled={!permissions.configuration.manual} onClick={onDropClickHandler}>
                      {t('dropNow')}
                    </Button>
                    <Button
                      sx={styles.submitBtn}
                      color="primary"
                      variant="contained"
                      disabled={!permissions.configuration.create}
                      onClick={onSaveClickHandler}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showConfirmPopup ? (
            <DropNumberModal onCancelDeleteDocument={onCancelResetConfig} onSubmit={onConfirmSaveConfig} />
          ) : null}
        </Grid>
      </form>
    </FormProvider>
  );
};

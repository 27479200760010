import { SubFilterDto } from '@app/core/api';

import { StatementsSubFilterType } from './types';

export const makeSubFilter = (subFilterProps: StatementsSubFilterType): SubFilterDto => {
  const {
    amount,
    documentNumber,
    documentDate,
    executionDate,
    recipientAccountName,
    LEI,
    paymentReference,
    accountNumber,
    currency,
    deposit,
    withdrawal,
    bankCode,
    bankName,
    recipientAccountNumber,
    documentStatus,
  } = subFilterProps;

  const subFilter: SubFilterDto = {};

  if (amount) {
    subFilter.documentNumber = {
      query: amount.toString(),
      strict: false,
    };
  }

  if (documentNumber) {
    subFilter.documentNumber = {
      query: documentNumber,
      strict: false,
    };
  }
  if (executionDate) {
    subFilter.operationDate = {
      query: executionDate.toISOString(),
      strict: true,
    };
  }
  if (documentDate) {
    subFilter.documentDate = {
      query: documentDate.toISOString(),
      strict: true,
    };
  }
  if (recipientAccountName) {
    subFilter.recipientAccountName = {
      query: recipientAccountName,
      strict: false,
    };
  }
  if (LEI) {
    subFilter.LEI = {
      query: LEI,
      strict: true,
    };
  }
  if (paymentReference) {
    subFilter.paymentReference = {
      query: paymentReference,
      strict: false,
    };
  }
  if (accountNumber) {
    subFilter.accountNumber = {
      query: accountNumber,
      strict: true,
    };
  }
  if (currency) {
    subFilter.currency = {
      query: currency,
      strict: true,
    };
  }
  if (deposit) {
    subFilter.deposit = {
      query: deposit,
      strict: true,
    };
  }
  if (withdrawal) {
    subFilter.withdrawal = {
      query: withdrawal,
      strict: true,
    };
  }
  if (bankCode) {
    subFilter.bankCode = {
      query: bankCode,
      strict: true,
    };
  }
  if (bankName) {
    subFilter.bankName = {
      query: bankName,
      strict: false,
    };
  }
  if (recipientAccountNumber) {
    subFilter.recipientAccountNumber = {
      query: recipientAccountNumber,
      strict: true,
    };
  }
  if (documentStatus) {
    subFilter.documentStatus = {
      query: documentStatus,
      strict: true,
    };
  }

  return subFilter;
};

// eslint-disable-next-line import/no-extraneous-dependencies
import { IBAN } from 'ibankit';
import { isValidIBAN } from 'ibantools';
import Cookies from 'js-cookie';

import { CurrencyEnum, Operation, TablePaginationType } from '../types';

export const cropName = (name: string, length: number): string => {
  return name.length > length ? `${name.slice(0, length)}...` : name;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

interface LoadScriptProps {
  src: string;
  onloadCallback(): void;
  domNode?: HTMLElement;
  async?: boolean;
}

export const loadScript = ({ src, onloadCallback, async = true, domNode = document.head }: LoadScriptProps): void => {
  const script = document.createElement('script');
  script.async = async;
  script.src = src;
  script.onload = onloadCallback;
  domNode.appendChild(script);
};

export const isDevelopment = (): boolean => {
  return Boolean(Cookies.get('development')) || false;
};

export const isValidUAIBAN = (iban: string): boolean => {
  return isValidIBAN(iban) && iban.slice(0, 2) === 'UA';
};

type CalculateTotal = {
  totalUah: number;
  totalUsd: number;
  totalEur: number;
};

export const calculateTotal = (operations: Operation[]): CalculateTotal => {
  return operations.reduce<CalculateTotal>(
    (acc, operation) => {
      if (operation.account.currency === CurrencyEnum.UAH) {
        acc.totalUah += operation.amount;
      }
      if (operation.account.currency === CurrencyEnum.USD) {
        acc.totalUsd += operation.amount;
      }
      if (operation.account.currency === CurrencyEnum.EUR) {
        acc.totalEur += operation.amount;
      }
      return acc;
    },
    { totalUah: 0, totalUsd: 0, totalEur: 0 }
  );
};

export const parseJSON = (str: string): any => {
  try {
    return JSON.parse(str) || {};
  } catch (e) {
    return {};
  }
};

export const parseIBAN = (accountNumber: string): { accountPart1: string; accountPart2: string } => {
  if (!Boolean(accountNumber)) {
    return { accountPart1: '', accountPart2: '' };
  }

  if (isValidIBAN(accountNumber)) {
    const iban = new IBAN(accountNumber);
    let accountPart2 = iban.getAccountNumber() || '';
    const countryCode = iban.getCountryCode();
    if (countryCode === 'UA') {
      accountPart2 = accountPart2.replace(/^[0]+/, '');
    }
    const accountPart1 = accountNumber.replace(accountPart2, '');

    return {
      accountPart1: accountPart2.length ? accountPart1 : '',
      accountPart2: accountPart2.length ? accountPart2 : accountPart1,
    };
  }

  return { accountPart1: accountNumber, accountPart2: '' };
};

export const hasNextPage = (newPagination: TablePaginationType): boolean => {
  const { rowsPerPage, page, totalCount } = newPagination;
  const totalPages = Math.ceil(totalCount / rowsPerPage);
  return page < totalPages;
};

import { SelectOptionType } from '../../../Select';

export enum DatePeriod {
  for_today = 'for_today',
  for_yesterday = 'for_yesterday',
  for_mounts = 'for_mounts',
  for_range = 'for_range',
}

export const dateListItems = (t: (translationKey: string) => string): SelectOptionType[] => {
  return [
    { id: DatePeriod.for_today, name: t(DatePeriod.for_today), value: DatePeriod.for_today },
    { id: DatePeriod.for_yesterday, name: t(DatePeriod.for_yesterday), value: DatePeriod.for_yesterday },
    { id: DatePeriod.for_mounts, name: t(DatePeriod.for_mounts), value: DatePeriod.for_mounts },
  ];
};

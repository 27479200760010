import React from 'react';

import { Grid, IconButton, Skeleton, SxProps, Theme } from '@mui/material';

import { SearchSmallIcon } from '@app/common/icons';

import { styles } from './styles';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';
import { Checkbox } from '../Checkbox';
import { ComplexTable, TableCell, TableRow } from '../ComplexTable';
import { SettingsButton } from '../SettingsButton';

type TableSkeletonProps = {
  columnsCount?: number;
  rowsCount?: number;
  showSelectionColumn?: boolean;
  showActionsColumn?: boolean;
};

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  columnsCount = 7,
  rowsCount = DEFAULT_ROWS_PER_PAGE,
  showSelectionColumn = false,
  showActionsColumn = false,
}) => {
  return (
    <ComplexTable
      head={{
        content: (
          <TableRow hover={false}>
            <>
              {showSelectionColumn ? (
                <TableCell sx={{ ...styles.cell, ...styles.cell_header, ...styles.cell_selection } as SxProps<Theme>}>
                  <Grid container alignItems="center">
                    <Checkbox disabled />
                  </Grid>
                </TableCell>
              ) : null}
              {Array(columnsCount)
                .fill(0)
                .map((_, id) => (
                  <TableCell
                    sx={
                      {
                        ...styles.cell,
                        ...styles.cell_header,
                        ...(styles[`cell_${id}`] && styles[`cell_${id}`]),
                      } as SxProps<Theme>
                    }
                    key={`header_cell_${id}`}
                  >
                    <Skeleton component="div" animation="wave" height={27} width="100%" />
                  </TableCell>
                ))}

              {showActionsColumn ? (
                <TableCell sx={{ ...styles.cell, ...styles.cell_header, ...styles.cell_actions } as SxProps<Theme>}>
                  <Grid container justifyContent="flex-end">
                    <IconButton sx={styles.searchIconBtn} size="large">
                      <SearchSmallIcon fontSize="small" />
                    </IconButton>
                    <SettingsButton disabled />
                  </Grid>
                </TableCell>
              ) : null}
            </>
          </TableRow>
        ),
      }}
      body={{
        content: (
          <>
            {Array(rowsCount)
              .fill(0)
              .map((rowItem, rowId) => (
                <TableRow key={`body_row_${rowId}`} hover={false}>
                  <>
                    {showSelectionColumn ? (
                      <TableCell sx={{ ...styles.cell, ...styles.cell_selection } as SxProps<Theme>}>
                        <Grid container alignItems="center">
                          <Checkbox disabled />
                        </Grid>
                      </TableCell>
                    ) : null}
                    {Array(columnsCount)
                      .fill(20)
                      .map((item, cellId) => (
                        <TableCell
                          sx={
                            {
                              ...styles.cell,
                              ...(styles[`cell_${cellId}`] && styles[`cell_${cellId}`]),
                            } as SxProps<Theme>
                          }
                          key={`body_cell_${cellId}`}
                        >
                          <Skeleton component="div" animation="wave" height={27} width="100%" />
                        </TableCell>
                      ))}
                    {showActionsColumn ? (
                      <TableCell sx={{ ...styles.cell, ...styles.cell_actions } as SxProps<Theme>}>
                        <Grid container justifyContent="flex-end">
                          <Skeleton component="div" animation="wave" height={27} width="100%" />
                        </Grid>
                      </TableCell>
                    ) : null}
                  </>
                </TableRow>
              ))}
          </>
        ),
      }}
    />
  );
};

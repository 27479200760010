export const styles = {
  container: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 7px rgba(134, 142, 150, 0.1), 0px 7px 20px rgba(222, 226, 230, 0.3)',
    borderRadius: '8px',
    padding: '20px',
  },
  hr: {
    paddingTop: '12px',
    borderBottom: `solid 1px`,
    borderColor: 'colors.gray50',
  },
  headerFile: {
    color: 'colors.gray500',
    fontWeight: 'bold',
    marginRight: '4px',
  },
  label: {
    fontWeight: 400,
    color: 'colors.gray400',
  },
  value: {
    fontWeight: 400,
    color: 'colors.gray500',
  },
  title: {
    fontWeight: 700,
    marginRight: '4px',
    color: 'colors.gray600',
  },
  hrInStatus: {
    borderBottom: `1px dashed`,
    borderColor: 'colors.gray100',
  },
  sendButton: {
    marginLeft: '28px',
  },
  keyIcon: {
    marginLeft: '8px',
  },
  attachedFilesInfo: {
    color: 'colors.primary700',
  },
  icon: {
    color: 'colors.gray500',
  },
  iconDownloadAll: {
    color: 'colors.primary500',
    marginLeft: '8px',
  },
  iconDownload: {
    color: 'colors.gray400',
    cursor: 'pointer',
  },
  noAttachedFilesLabel: {
    paddingLeft: '8px',
    color: 'colors.gray400',
  },
};

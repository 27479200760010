import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CrossIcon, InfoIcon } from '@app/common/icons';
import { CreateOperationAdditionalInfoDtoCodeEnum } from '@app/core/api';
import { AccountSelectField } from '@app/core/components/Form/controls/AccountsSelectField';
import { BICField } from '@app/core/components/Form/controls/BICField';
import { CheckboxField } from '@app/core/components/Form/controls/CheckboxField';
import { DateSelectField } from '@app/core/components/Form/controls/DateField/DateSelectField';
import { DocumentNumberField } from '@app/core/components/Form/controls/DocumentNumberField';
import { FormatInputField, FormatType } from '@app/core/components/Form/controls/FormatInputField';
import { InputField } from '@app/core/components/Form/controls/InputField';
import { TextAreaField } from '@app/core/components/Form/controls/TextAreaField';
import { Tooltip } from '@app/core/components/Tooltip';
import { CountrySelect } from '@app/pages/payment/components/CountrySelect';
import { CommissionTypeSelect } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/components/CommissionTypeSelect';
import { UrgencySelectField } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/components/UrgencySelectField';
import { styles } from '@app/pages/swift/SwiftPayment/components/SwiftPaymentForm/style';
import { SwiftFormFields, SwiftPaymentFormProps } from '@app/pages/swift/types';

export const MobileSwiftPaymentForm: React.FC<SwiftPaymentFormProps> = ({
  generatedSwiftOrderNumber,
  maxDocumentDate,
  minDocumentDate,
  maxValuationDate,
  minValuationDate,
  currencyAccounts,
  recipientCountry,
  currency,
  showBalance,
  countedBalance,
  signPermissions,
  additionalInfo,
  isShowCorrespondentBank = false,
  onCancelClick,
  onSaveClick,
  onSaveAndSignClick,
  onSendToSignClick,
}) => {
  const { t } = useTranslation();
  const [showCorrespondentBank, setShowCorrespondentBank] = useState<boolean>(isShowCorrespondentBank);
  const correspondentBankCheckHandler = () => {
    setShowCorrespondentBank(!showCorrespondentBank);
  };
  const nowDate = new Date();
  nowDate.setHours(12, 0, 0);
  const documentNumberRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setShowCorrespondentBank(isShowCorrespondentBank);
  }, [isShowCorrespondentBank]);

  useEffect(() => {
    if (additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed) {
      documentNumberRef.current?.scrollIntoView();
    }
  }, [additionalInfo]);

  return (
    <Grid sx={styles.tabBodyMobile} container wrap="nowrap" direction="column">
      <Grid item container direction="column">
        <Box mt={6}>
          <Grid container justifyContent="space-between">
            <Grid item container xs={11}>
              <Box ref={documentNumberRef}>
                <DocumentNumberField
                  name={SwiftFormFields.documentNumber}
                  title={t('swiftPayment_paymentOrder')}
                  defaultValue={generatedSwiftOrderNumber || 'Auto123'}
                  error={additionalInfo?.code === CreateOperationAdditionalInfoDtoCodeEnum.DocumentNumberAlreadyUsed}
                  validateErrorMessage={t('payment_validateDocumentNumberErrorMessage')}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={onCancelClick}>
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_documentDate')}</FormLabel>
          <Box pt={2}>
            <DateSelectField
              name={SwiftFormFields.documentDate}
              selectedDate={nowDate}
              datePickerProps={{
                disabled: { after: maxDocumentDate, before: minDocumentDate },
              }}
              inputProps={{ fullWidth: true }}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_valuationDate')}</FormLabel>
          <Box pt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>
                <DateSelectField
                  name={SwiftFormFields.documentValueDate}
                  datePickerProps={{
                    disabled: { after: maxValuationDate, before: minValuationDate },
                  }}
                  inputProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={1} container justifyContent={'flex-end'}>
                <Tooltip color="primary" title={t('swiftPayment_valuationDateTooltip')} arrow placement="bottom">
                  <Box mr={2}>
                    <InfoIcon fontSize="small" sx={styles.info} />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_senderAccount')}</FormLabel>
          <Box pt={2}>
            <AccountSelectField
              name={SwiftFormFields.senderAccount}
              options={currencyAccounts}
              placeholder={t('swiftPayment_senderAccountPlaceholder')}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_senderName')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.senderName}
              placeholder={t('swiftPayment_nameFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_senderAddress')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.senderAddress}
              placeholder={t('swiftPayment_addressFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientName')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.recipientName}
              placeholder={t('swiftPayment_nameFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientAddress')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.recipientAddress}
              placeholder={t('swiftPayment_addressFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientAccount')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.recipientAccount}
              placeholder={t('swiftPayment_recipientAccount')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientCountry')}</FormLabel>
          <Box pt={2}>
            <CountrySelect
              placeholder={t('countrySelect_placeholder')}
              name={SwiftFormFields.recipientCountry}
              defaultValue={recipientCountry}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_swiftBic')}</FormLabel>
          <Box pt={2}>
            <BICField
              name={SwiftFormFields.recipientSwiftBic}
              placeholder={t('swiftPayment_swiftBicFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.recipientBank}
              placeholder={t('swiftPayment_bankFieldPlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <CheckboxField
            label={<Typography variant="caption">{t('swiftPayment_correspondentBankCheckboxLabel')}</Typography>}
            checked={showCorrespondentBank}
            name={SwiftFormFields.correspondentBankCheck}
            sx={styles.correspondentBankCheck}
            onChange={correspondentBankCheckHandler}
          />
        </Box>

        {showCorrespondentBank ? (
          <>
            <Box mt={6}>
              <FormLabel component="legend">{t('swiftPayment_swiftBicCorrespondent')}</FormLabel>
              <Box pt={2}>
                <BICField
                  name={SwiftFormFields.recipientCorrespondentSwiftBic}
                  placeholder={t('swiftPayment_swiftBicCorrespondent')}
                  fullWidth
                />
              </Box>
            </Box>
            <Box mt={6}>
              <FormLabel component="legend">{t('swiftPayment_recipientBank')}</FormLabel>
              <Box pt={2}>
                <InputField
                  name={SwiftFormFields.recipientCorrespondentBank}
                  placeholder={t('swiftPayment_bankFieldPlaceholder')}
                  fullWidth
                />
              </Box>
            </Box>
          </>
        ) : null}

        <Box sx={styles.hr} mt={5} />
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_amount')}</FormLabel>
          <Box pt={2}>
            <Grid container>
              <Grid item xs={6}>
                <FormatInputField
                  type={FormatType.currencyAmount}
                  name={SwiftFormFields.amount}
                  placeholder={'0.00'}
                  currency={currency}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={styles.balance}>
                  <Typography sx={styles.balanceText}>{t('paymentCreationLocalPayment_balanceText')}</Typography>
                  <Typography sx={styles.balanceText}>
                    {showBalance ? `${countedBalance} ${currency}` : '0.00'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_commissionType')}</FormLabel>
          <Box pt={2}>
            <CommissionTypeSelect
              name={SwiftFormFields.commissionType}
              placeholder={t('swiftPayment_commissionTypePlaceholder')}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_operationCode')}</FormLabel>
          <Box pt={2}>
            <InputField
              name={SwiftFormFields.operationCode}
              placeholder={t('swiftPayment_operationCodePlaceholder')}
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={6}>
          <FormLabel component="legend">{t('swiftPayment_urgency')}</FormLabel>
          <Box pt={2}>
            <UrgencySelectField name={SwiftFormFields.urgency} placeholder={t('swiftPayment_urgencyPlaceholder')} />
          </Box>
        </Box>
        <Box mt={5}>
          <FormLabel component="legend">{t('swiftPayment_purpose')}</FormLabel>
          <Box pt={2}>
            <TextAreaField
              name={SwiftFormFields.paymentReference}
              textFieldProps={{
                multiline: true,
                fullWidth: true,
                rows: 2,
              }}
              maxLength={160}
            />
          </Box>
        </Box>
      </Grid>

      <Box mt={6} mb={3}>
        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          <Box pr={4} width="40%">
            <Button fullWidth variant="outlined" color="primary" onClick={onSaveClick}>
              {t('paymentCreationFormControl_save')}
            </Button>
          </Box>
          {signPermissions.allowToSign ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSaveAndSignClick}>
                {t('paymentCreationFormControl_send')}
              </Button>
            </Box>
          ) : null}
          {signPermissions.allowToSendToSign ? (
            <Box width="60%">
              <Button fullWidth variant="contained" color="primary" onClick={onSendToSignClick}>
                {t('paymentCreationFormControl_sendToSign')}
              </Button>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Hidden, IconButton, ListItemIcon, MenuItem, Popover, Typography } from '@mui/material';
import { RowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ArrowDownSelectSmallIcon,
  BackCancelIcon,
  CsvIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PdfIcon,
  PreviewIcon,
  PreviewIconGray,
  PrintIcon,
  SendIcon,
  SignatureIcon,
  XlsIcon,
  XmlIcon,
} from '@app/common/icons';
import { useNotify } from '@app/context/NotifyContext';
import {
  StatementTypeEnum,
  OutputFormatEnum,
  PaymentOrderErrorMessagesEnum,
  PaymentTemplateTypeEnum,
  PaymentOrderTypeEnum,
} from '@app/core/api';
import { useCancelDocument } from '@app/core/hooks';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

import { styles } from './styles';
import { CancelDocumentErrorPopup } from '../../../../..//CancelDocumentErrorPopup';
import { DocumentStatus, TotalAmounts, Operation } from '../../../../../../types';
import { calculateTotal, formatAmount } from '../../../../../../utils';
import { CancelDocumentDialog } from '../../../../../CancelDocumentDialog';
import { ConfirmEditOperationPopup } from '../../../../../ConfirmEditOperationPopup/ConfirmEditOperationPopup';
import { DeleteDocumentDialog } from '../../../../../DeleteDocumentDialog';
import { multipleDeleteDocumentFn } from '../../../../../DeleteDocumentDialog/query';
import { getActiveButtonsConfig } from '../../activeButtons';
import { OperationRow } from '../../types';

interface TableToolbarProps {
  operationsCount?: number;
  totalAmounts?: TotalAmounts;
  queryKey: string;
  getSelectedRowModel(): RowModel<OperationRow>;
  onSign(selectedOperations: Operation[]): void;
  onSignFromAnother(selectedOperations: Operation[]): void;
  onSendToSign(selectedOperations: Operation[]): void;
  onDeleteDocumentSuccess(): void;
  onCancelDocumentSuccess(): void;
  onDownloadOperationsFile(props: { operationsUuids: string[]; outputType: string; format?: string }): void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  getSelectedRowModel,
  operationsCount,
  totalAmounts,
  queryKey,
  onSign,
  onSignFromAnother,
  onDeleteDocumentSuccess,
  onSendToSign,
  onDownloadOperationsFile,
  onCancelDocumentSuccess,
}) => {
  const { permissions } = useAppSelector((state): ProfileState => state.profile);
  const navigate = useNavigate();
  const { notify } = useNotify();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState<boolean>(false);
  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showCancelDocumentDialog, setShowCancelDocumentDialog] = useState<boolean>(false);
  const [showCancelDocumentErrorPopup, setShowCancelDocumentErrorPopup] = useState<boolean>(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null);
  const {
    data: cancelResultData,
    mutate: mutateCancelDocument,
    status: cancelDocumentMutateStatus,
    isLoading: isLoading1,
    reset,
  } = useCancelDocument();

  useEffect(() => {
    if (cancelResultData) {
      if (cancelResultData.additionalInfo?.code === PaymentOrderErrorMessagesEnum.WrongDocumentStatus) {
        setShowCancelDocumentErrorPopup(true);
      } else {
        onCancelDocumentSuccess();
        notify({
          notifyProps: {
            title: t('success'),
            message: t('deleteDocumentSuccessMessage'),
            severity: 'success',
          },
        });
      }
      reset();
    }
  }, [cancelResultData, notify, onCancelDocumentSuccess, reset, t]);

  useEffect(() => {
    if (cancelDocumentMutateStatus === 'error') {
      notify({
        notifyProps: {
          title: t('server_error'),
          message: t('deleteDocumentSuccessMessage'),
          severity: 'error',
        },
      });
    }
  }, [cancelDocumentMutateStatus, notify, t]);
  // const { getValues, trigger } = useFormContext<StatementsFilterFormData>();
  // const { mutateAsync, isLoading } = useMutation<FileLinkResponseDto, ApiError, GetOperationsFileDto>(
  //   getStatementsFileFn
  // );
  // const { mutateAsync: mutateAsyncMultipleFiles } = useMutation<
  //   FileLinkResponseDto,
  //   ApiError,
  //   GetMultipleStatementsFilesProps
  // >(getMultipleStatementsFiles);
  const selectedOperations = getSelectedRowModel().flatRows?.map(({ original }) => original.operation);
  const operationsUuids = selectedOperations
    ?.filter((operation) => operation.documentType !== PaymentOrderTypeEnum.MemorialOrderFlag)
    .map((operation) => operation.uuid);

  // const navigateToFile = (responseData: FileLinkResponseDto, print?: boolean) => {
  //   if (
  //     responseData?.additionalInfo &&
  //     responseData.additionalInfo.code === FileLinkAdditionalInfoDtoCodeEnum.TooManyOperations
  //   ) {
  //     notify({
  //       notifyProps: {
  //         message: t('load_too_many_operations_alert'),
  //         severity: 'warning',
  //       },
  //     });
  //     return;
  //   }
  //   if (print) {
  //     window.open(`${responseData.link}?format=print&type=payment-order`);
  //   } else {
  //     window.open(`${responseData.link}?format=download&type=payment-order`);
  //   }
  // };
  //
  // const fileSelectHandler = (typeFile: OutputFormatEnum, print?: boolean) => {
  //   trigger().then((valid) => {
  //     if (isLoading) {
  //       return;
  //     }
  //     if (valid) {
  //       if (selectedOperations?.length) {
  //         mutateAsyncMultipleFiles({
  //           outputType: typeFile as unknown as OutputFormatEnum,
  //           ordersUids: operationsUids || [],
  //         }).then((responseData) => {
  //           navigateToFile(responseData, print);
  //         });
  //       } else {
  //         mutateAsync(makeDataForGetStatementsFile(typeFile, queryKey, getValues())).then((responseData) => {
  //           navigateToFile(responseData, print);
  //         });
  //       }
  //     }
  //   });
  // };

  const handleClickDownloadButton = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };

  const fileSelectMenuItemHandler = (outputType: OutputFormatEnum) => {
    setMenuAnchorEl(null);
    onDownloadOperationsFile({
      operationsUuids,
      outputType,
    });
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const onDeleteDocumentHandler = () => {
    handleCloseMoreMenu();
    setShowDeleteDocumentDialog(true);
  };

  const onCancelDeleteDocument = () => {
    setShowDeleteDocumentDialog(false);
  };

  const onDeleteDocumentSuccessHandler = () => {
    setShowDeleteDocumentDialog(false);
    onDeleteDocumentSuccess();
  };

  const onSubmitHandler = () => {
    setShowDeleteDocumentDialog(false);
  };

  const navigateToEditPayment = (uuid: string, templateType: PaymentTemplateTypeEnum) => {
    if (templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_edit.replace(':uuid', uuid));
      return;
    }
    navigate(RouteList.payment_edit.replace(':uuid', uuid));
  };

  const onEditClickHandler = () => {
    handleCloseMoreMenu();
    const { uuid, signed, templateType } = selectedOperations[0];
    if (signed) {
      setShowConfirmEditPopup(true);
    } else {
      navigateToEditPayment(uuid, templateType);
    }
  };

  const onConfirmEditPopupHandler = () => {
    setShowConfirmEditPopup(false);
    const { uuid, templateType } = selectedOperations[0];
    navigateToEditPayment(uuid, templateType);
  };

  const onConfirmCancelEditPopup = () => {
    setShowConfirmEditPopup(false);
  };

  const onPreviewClickHandler = () => {
    handleCloseMoreMenu();
    if (selectedOperations[0].templateType === PaymentTemplateTypeEnum.Own) {
      navigate(RouteList.payment_own_view.replace(':uuid', selectedOperations[0].uuid));
      return;
    }
    navigate(RouteList.payment_view.replace(':uuid', selectedOperations[0].uuid));
  };

  const { totalUah, totalEur, totalUsd } = calculateTotal(selectedOperations || []);
  const docsForDelete = selectedOperations.filter(
    (item) => item.status === DocumentStatus.ToSign || item.status === DocumentStatus.Saved
  );

  const onSignClickHandler = () => {
    onSign(selectedOperations);
  };

  const onSignFromAnotherClickHandler = () => {
    onSignFromAnother(selectedOperations);
  };

  const onSendToSignClickHandler = () => {
    onSendToSign(selectedOperations);
  };

  const onCloseCancelDocumentDialogHandler = () => {
    setShowCancelDocumentDialog(false);
  };

  const onCancelDocumentSubmitSuccessHandler = () => {
    if (selectedOperations.length && selectedOperations[0].uuid) {
      mutateCancelDocument({ uuid: selectedOperations[0].uuid });
    }
    setShowCancelDocumentDialog(false);
  };

  const onCloseCancelDocumentErrorPopup = () => {
    setShowCancelDocumentErrorPopup(false);
  };

  const onCancelDocumentClickHandler = () => {
    handleCloseMoreMenu();
    setShowCancelDocumentDialog(true);
  };

  const onMenuBtnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const activeButtonsConfig = getActiveButtonsConfig(selectedOperations, permissions, queryKey as StatementTypeEnum);
  return (
    <>
      <Box pb={1} sx={styles.sticky}>
        {selectedOperations?.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ ...styles.actionsContainer, ...styles.selectedActionsContainer }}
          >
            <Grid item container xs={7} alignItems="center" justifyContent="flex-start" wrap="nowrap">
              <Typography variant="body2" sx={styles.selectedCount}>
                {selectedOperations.length} {t('selected')}
              </Typography>
              <Box sx={styles.divider} />
              {activeButtonsConfig.sign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSignClickHandler}
                    startIcon={<SignatureIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('sign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.signFromAnother ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSignFromAnotherClickHandler}
                    startIcon={<SignatureIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('signedByPerson')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.sendToSign ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onSendToSignClickHandler}
                    startIcon={<SendIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('toSign')}</Typography>
                    </Hidden>
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.edit ? (
                <>
                  <Button variant="text" sx={styles.buttonRoot} onClick={onEditClickHandler} startIcon={<EditIcon />}>
                    {activeButtonsConfig.edit.short ? null : (
                      <Hidden lgDown>
                        <Typography>{t('change')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.download ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={handleClickDownloadButton}
                    startIcon={<DownloadIcon />}
                    endIcon={<ArrowDownSelectSmallIcon />}
                  />
                  <Popover
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {appConfig.enableFileFormat ? (
                      <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Csv)}>
                        <ListItemIcon>
                          <CsvIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">CSV</Typography>
                      </MenuItem>
                    ) : null}
                    {queryKey === StatementTypeEnum.Statement ? (
                      <>
                        <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Pdf)}>
                          <ListItemIcon>
                            <PdfIcon />
                          </ListItemIcon>
                          <Typography variant="inherit">PDF</Typography>
                        </MenuItem>
                      </>
                    ) : null}
                    <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Xlsx)}>
                      <ListItemIcon>
                        <XlsIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">XLS</Typography>
                    </MenuItem>
                    {appConfig.enableFileFormat ? (
                      <MenuItem onClick={() => fileSelectMenuItemHandler(OutputFormatEnum.Xml)}>
                        <ListItemIcon>
                          <XmlIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">XML</Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.cancel ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onCancelDocumentClickHandler}
                    startIcon={<BackCancelIcon />}
                  >
                    {activeButtonsConfig.cancel.short ? null : (
                      <Hidden lgDown>
                        <Typography>{t('reject')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.view ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onPreviewClickHandler}
                    startIcon={<PreviewIcon />}
                  >
                    {activeButtonsConfig.view.short ? null : (
                      <Hidden lgDown>
                        <Typography>{t('preview')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.delete ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onDeleteDocumentHandler}
                    startIcon={<DeleteIcon />}
                  >
                    {activeButtonsConfig.delete.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('delete')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                </>
              ) : null}
              {activeButtonsConfig.print ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={() =>
                      onDownloadOperationsFile({
                        operationsUuids,
                        outputType: OutputFormatEnum.Pdf,
                        format: 'print',
                      })
                    }
                    startIcon={<PrintIcon />}
                  >
                    {activeButtonsConfig.print.short ? null : (
                      <Hidden lgDown>
                        <Typography sx={styles.selectedText}>{t('print')}</Typography>
                      </Hidden>
                    )}
                  </Button>
                  <Box sx={styles.divider} />
                </>
              ) : null}
              {activeButtonsConfig.moreMenu ? (
                <>
                  <Button
                    variant="text"
                    sx={styles.buttonRoot}
                    onClick={onMenuBtnClickHandler}
                    startIcon={<MoreIcon />}
                  >
                    <Hidden lgDown>
                      <Typography>{t('more')}</Typography>
                    </Hidden>
                  </Button>

                  <Popover
                    anchorEl={moreMenuAnchorEl}
                    keepMounted
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={handleCloseMoreMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {activeButtonsConfig.moreMenu.edit ? (
                      <MenuItem sx={styles.menuItem} onClick={onEditClickHandler}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('change')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.cancel ? (
                      <MenuItem sx={styles.menuItem} onClick={onCancelDocumentClickHandler}>
                        <ListItemIcon>
                          <BackCancelIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('reject')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.view ? (
                      <MenuItem sx={styles.menuItem} onClick={onPreviewClickHandler}>
                        <ListItemIcon>
                          <PreviewIconGray />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('preview')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.delete ? (
                      <MenuItem sx={styles.menuItem} onClick={onDeleteDocumentHandler}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('delete')}</Typography>
                      </MenuItem>
                    ) : null}
                    {activeButtonsConfig.moreMenu.print ? (
                      <MenuItem
                        sx={styles.menuItem}
                        onClick={() => {
                          handleCloseMoreMenu();
                          onDownloadOperationsFile({
                            operationsUuids,
                            outputType: OutputFormatEnum.Pdf,
                            format: 'print',
                          });
                        }}
                      >
                        <ListItemIcon>
                          <PrintIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('print')}</Typography>
                      </MenuItem>
                    ) : null}
                  </Popover>
                </>
              ) : null}
            </Grid>
            <Grid item container xs={5} alignItems="center" justifyContent="flex-end">
              <Box ml={1}>
                <Typography variant="body2" component="span" sx={styles.selectedAmountSum}>
                  {t('statementsTablePanelTotalAmount')}
                </Typography>
                {totalUah > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountSum}>{` ${formatAmount(
                      totalUah
                    )}`}</Typography>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountCurrency}>
                      UAH
                    </Typography>
                  </>
                ) : null}
                {totalUsd > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountSum}>{` ${formatAmount(
                      totalUsd
                    )}`}</Typography>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountCurrency}>
                      USD
                    </Typography>
                  </>
                ) : null}
                {totalEur > 0 ? (
                  <>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountSum}>{` ${formatAmount(
                      totalEur
                    )}`}</Typography>
                    <Typography variant="body2" component="span" sx={styles.selectedAmountCurrency}>
                      EUR
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="space-between" sx={styles.actionsContainer}>
            <Grid item container xs={4} alignItems="center" justifyContent="flex-start">
              {appConfig.enableFileFormat ? (
                <IconButton
                  size="small"
                  color="default"
                  sx={styles.actionIcon}
                  onClick={() =>
                    onDownloadOperationsFile({
                      operationsUuids,
                      outputType: OutputFormatEnum.Csv,
                    })
                  }
                >
                  <CsvIcon />
                </IconButton>
              ) : null}
              {queryKey === StatementTypeEnum.Statement ? (
                <IconButton
                  size="small"
                  color="default"
                  sx={styles.actionIcon}
                  onClick={() =>
                    onDownloadOperationsFile({
                      operationsUuids,
                      outputType: OutputFormatEnum.Pdf,
                    })
                  }
                >
                  <PdfIcon />
                </IconButton>
              ) : null}
              <IconButton
                size="small"
                color="default"
                sx={styles.actionIcon}
                onClick={() =>
                  onDownloadOperationsFile({
                    operationsUuids,
                    outputType: OutputFormatEnum.Xlsx,
                  })
                }
              >
                <XlsIcon />
              </IconButton>
              {appConfig.enableFileFormat ? (
                <IconButton
                  size="small"
                  color="default"
                  sx={styles.actionIcon}
                  onClick={() =>
                    onDownloadOperationsFile({
                      operationsUuids,
                      outputType: OutputFormatEnum.Xml,
                    })
                  }
                >
                  <XmlIcon />
                </IconButton>
              ) : null}
              {queryKey === StatementTypeEnum.Statement ? (
                <IconButton
                  size="small"
                  color="default"
                  sx={styles.actionIcon}
                  onClick={() =>
                    onDownloadOperationsFile({
                      operationsUuids,
                      outputType: OutputFormatEnum.Pdf,
                      format: 'print',
                    })
                  }
                >
                  <PrintIcon />
                </IconButton>
              ) : null}
            </Grid>
            <Grid item container xs={8} alignItems="center" justifyContent="flex-end">
              {operationsCount ? (
                <Grid item>
                  <Typography variant="body2" component="span" sx={styles.operationsCountTitle}>
                    {t('statementsTablePanelDocuments')}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={styles.operationsCount}
                  >{` ${operationsCount}`}</Typography>
                </Grid>
              ) : null}
              {totalAmounts && operationsCount ? (
                <>
                  <Typography variant="body2" component="span" sx={styles.totalAmountTitle}>
                    {t('statementsTablePanelTotalAmount')}
                  </Typography>
                  {totalAmounts?.map((amount, i) => {
                    return (
                      <Box pl={4} key={`totalAmounts_${i.toString()}`}>
                        <Typography variant="body2" component="span" sx={styles.totalAmount}>{` ${formatAmount(
                          amount.amount
                        )}`}</Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={styles.totalAmountCurrency}
                        >{` ${amount.currency}`}</Typography>
                      </Box>
                    );
                  })}
                </>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Box>

      {showDeleteDocumentDialog ? (
        <DeleteDocumentDialog
          mutationFn={multipleDeleteDocumentFn}
          onSubmit={onSubmitHandler}
          onCancelDeleteDocument={onCancelDeleteDocument}
          onDeleteDocumentSuccess={onDeleteDocumentSuccessHandler}
          selectedDocuments={selectedOperations}
          docsForSignCount={docsForDelete.length}
          currency={docsForDelete[0].currency}
        />
      ) : null}

      {showConfirmEditPopup ? (
        <ConfirmEditOperationPopup onConfirm={onConfirmEditPopupHandler} onCancel={onConfirmCancelEditPopup} />
      ) : null}
      {showCancelDocumentDialog ? (
        <CancelDocumentDialog
          onClose={onCloseCancelDocumentDialogHandler}
          onConfirmCancelDocument={onCancelDocumentSubmitSuccessHandler}
        />
      ) : null}
      {showCancelDocumentErrorPopup ? <CancelDocumentErrorPopup onClose={onCloseCancelDocumentErrorPopup} /> : null}
    </>
  );
};

import React from 'react';

import { useFormContext, Controller } from 'react-hook-form';

import { EmailField } from '../components/EmailField';

export interface EmailFieldControlProps {
  name: string;
  value: string;
  placeholder: string;
  disabled: boolean;
}

export const EmailFieldControl: React.FC<EmailFieldControlProps> = ({ name, value, disabled, ...props }) => {
  const { control, setValue } = useFormContext();
  const onChangeHandler = (inputValue: string) => {
    setValue(name, inputValue);
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field: { value: currentValue }, fieldState: { error } }) => (
          <EmailField
            name={name}
            fullWidth
            onChange={onChangeHandler}
            value={currentValue}
            disabled={disabled}
            errors={error?.message}
            {...props}
          />
        )}
      />
    </>
  );
};

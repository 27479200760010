import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { CurrencyCodeDto } from '@app/core/api';

import { styles } from '../styles';

type CurrencyInfoProps = {
  currency: CurrencyCodeDto;
};

export const CurrencyInfo: React.FC<CurrencyInfoProps> = ({ currency }) => {
  return (
    <>
      <Grid container alignItems="flex-start" direction="column">
        <Typography variant="caption" sx={[styles.accountName]}>
          {`${currency.alpha3} ${currency.name}`}
        </Typography>
      </Grid>
    </>
  );
};

import { DATE_FORMAT, formatISODateString, formatAmount } from '@app/core/utils';
import { Turnover, TurnoversRow } from '@app/pages/accounts/selectedAccount/PageContent/Turnovers/components/types';

export const buildTableRowsData = (turnovers: Turnover[]): TurnoversRow[] => {
  return turnovers.map((turnover) => {
    return {
      deposit: formatAmount(turnover.deposit),
      withdrawal: formatAmount(turnover.withdrawal),
      date: formatISODateString(turnover.date, DATE_FORMAT),
      openingBalance: formatAmount(turnover.openingBalance),
      closingBalance: formatAmount(turnover.closingBalance),
      currency: turnover.currency,
      action: '',
    };
  });
};

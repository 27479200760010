import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { authApiClient, getClientId, ApiError, Disable2faVerifyParamsDtoType2FAEnum } from '@app/core/api';

interface UserDisable2faFnProps {
  code: string;
}

const disable2faFn = async ({ code }: UserDisable2faFnProps): Promise<any> => {
  const response = await authApiClient.authControllerDisable2faValidate(getClientId(), {
    params: {
      type2FA: Disable2faVerifyParamsDtoType2FAEnum.Totp,
      twoFactorCode: code,
    },
  });

  if (response.status !== 201) {
    throw new ApiError(response.request.status, 'Cant disable GA');
  }

  return response.data;
};

export const useDisable2fa = (): UseMutationResult<any, ApiError, UserDisable2faFnProps> => {
  return useMutation<any, ApiError, UserDisable2faFnProps>(disable2faFn);
};

import { array, object, SchemaOf, string } from 'yup';

import { SwiftFilterFieldName } from '@app/pages/swift/components/SwiftTable/types';

type SwiftFilterSchemaObject = {
  [SwiftFilterFieldName.operationAccounts]: string[];
  [SwiftFilterFieldName.dateRangeSelect]: string;
};

export const swiftFilterSchema = (t: (key: string) => string): SchemaOf<SwiftFilterSchemaObject> => {
  return object().shape({
    [SwiftFilterFieldName.operationAccounts]: array().min(1, t('statementsFilter_errorAmountField')),
    [SwiftFilterFieldName.dateRangeSelect]: string().required(t('statementsFilter_errorDateRange')),
  });
};

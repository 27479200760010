import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageContainer } from '@app/common/layout/PageContainer';
import { PageHeader } from '@app/common/layout/PageHeader';
import { PageWrapper } from '@app/common/layout/PageWrapper/PageWrapper';
import { CreatePaymentOrderResultDto, PaymentTemplateTypeEnum } from '@app/core/api';
import { HorizontalMenu } from '@app/core/components/HorizontalMenu';
import { MenuItem } from '@app/core/components/HorizontalMenu/types';
import { SignPaymentOrder } from '@app/core/components/SignPaymentOrder';
import { MutationStatus } from '@app/core/types';
import { isMobile } from '@app/core/utils';
import { BetweenMyAccount } from '@app/pages/payment/create/BetweenMyAccount';
import { LocalPayment } from '@app/pages/payment/create/LocalPayment';
import { StatePayment } from '@app/pages/payment/create/StatePayment';
import { CreatePaymentTab } from '@app/pages/payment/create/types';
import { RouteList } from '@app/src/constants/routeList';

export const PaymentCreationPage: React.FC = () => {
  const navigate = useNavigate();
  const [createPaymentResultOperation, setCreatePaymentResultOperation] =
    useState<CreatePaymentOrderResultDto | null>();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<CreatePaymentTab>(CreatePaymentTab.localPayment);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [signFromAnotherPerson, setSignFromAnotherPerson] = useState<boolean>();
  const urlQueryValues = useLocation().search;
  const { templateType: copyTemplateType } = parse(urlQueryValues);

  useEffect(() => {
    if (copyTemplateType === PaymentTemplateTypeEnum.Own) {
      setActiveTab(CreatePaymentTab.betweenOwnAccounts);
    }
  }, [copyTemplateType]);

  const handleChange = (currentTab: CreatePaymentTab): void => {
    setActiveTab(currentTab);
  };
  const onCloseModalHandler = (status: MutationStatus) => {
    setCreatePaymentResultOperation(null);
    if (createPaymentResultOperation && status !== 'success') {
      const { uuid, templateType } = createPaymentResultOperation;
      if (templateType === PaymentTemplateTypeEnum.Own) {
        navigate(RouteList.payment_own_edit.replace(':uuid', uuid));
        return;
      }
      navigate(RouteList.payment_edit.replace(':uuid', uuid));
    } else {
      navigate(-1);
    }
  };

  const onSignHandler = (createPaymentResultData: CreatePaymentOrderResultDto, signFromAnother?: boolean) => {
    setSignFromAnotherPerson(signFromAnother);
    setCreatePaymentResultOperation(createPaymentResultData);
  };

  const onSuccessSignHandler = () => {
    setCreatePaymentResultOperation(null);
    navigate(-1);
  };

  const newPaymentTabsItems: MenuItem<CreatePaymentTab>[] = [
    {
      id: 1,
      labelText: t('paymentCreationPaymentTab_localPaymentLabel'),
      value: CreatePaymentTab.localPayment,
    },
    {
      id: 2,
      labelText: t('paymentCreationPaymentTab_betweenMyAccountLabel'),
      value: CreatePaymentTab.betweenOwnAccounts,
    },
    {
      id: 3,
      labelText: t('paymentCreationPaymentTab_statePaymentLabel'),
      value: CreatePaymentTab.statePayment,
    },
  ];

  const onChangeHandler = ({ formDirty }: { formDirty: boolean }) => {
    setShowConfirmPopup(formDirty);
  };

  return (
    <>
      <PageWrapper
        sx={{
          ...(isMobile && {
            backgroundColor: 'initial',
            borderRadius: 0,
            height: '100vh',
            padding: 0,
          }),
        }}
      >
        <PageHeader
          title={t('paymentCreationHeaderContent_title')}
          showNavigateButton={!isMobile}
          showLeavePopup={showConfirmPopup}
          confirmQuestionText={t('confirmLeaveEditPaymentPopupMessage')}
          backLink={-1}
        />
        <PageContainer sx={isMobile ? { position: 'absolute', left: 0 } : undefined}>
          <Grid container direction="column" wrap="nowrap">
            <Box pl={3} pt={4} mb={isMobile ? 4 : 0}>
              <HorizontalMenu<CreatePaymentTab>
                onChange={handleChange}
                menuItems={newPaymentTabsItems}
                showConfirmPopup={showConfirmPopup}
                confirmPopupMessage={t('payment_confirmChangeTab')}
              />
            </Box>
            {activeTab === CreatePaymentTab.localPayment && (
              <LocalPayment sendOnSign={onSignHandler} onChange={onChangeHandler} />
            )}
            {activeTab === CreatePaymentTab.betweenOwnAccounts && (
              <BetweenMyAccount sendOnSign={onSignHandler} onChange={onChangeHandler} />
            )}
            {activeTab === CreatePaymentTab.statePayment && (
              <StatePayment sendOnSign={onSignHandler} onChange={onChangeHandler} />
            )}
          </Grid>
        </PageContainer>
      </PageWrapper>

      {createPaymentResultOperation ? (
        <SignPaymentOrder
          uuid={createPaymentResultOperation.uuid}
          documentToSign={JSON.stringify(createPaymentResultOperation)}
          signFromAnotherPerson={signFromAnotherPerson}
          onSuccessPopupCancel={onSuccessSignHandler}
          onClose={onCloseModalHandler}
        />
      ) : null}
    </>
  );
};

import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useScreenOrientation } from '@app/core/hooks';
import { useGetAvailableFunds } from '@app/core/hooks/useGetAvalibleFunds';
import { formatAmount, isMobile } from '@app/core/utils';
import { FundItem } from '@app/pages/dashboard/view/PageContent/AvailableFunds/FundItem';
import { FundLoadingItem } from '@app/pages/dashboard/view/PageContent/AvailableFunds/FundLoadingItem';
import { TotalFundItem } from '@app/pages/dashboard/view/PageContent/AvailableFunds/TotalFundItem';

export const AvailableFunds: React.FC = () => {
  const { t } = useTranslation();
  const { isPortrait } = useScreenOrientation();
  const { data, isLoading, mutate } = useGetAvailableFunds();
  const fundsLCY = data?.fundsLCY;
  const funds = data?.funds;
  const totalFundsLCY = data?.totalFundsLCY;

  useEffect(() => {
    mutate({});
  }, [mutate]);

  const container: boolean = isMobile && isPortrait;

  return (
    <Box pt={7} width="100%">
      <Grid container direction="column" justifyContent="space-between">
        {!isMobile ? (
          <Typography variant="h6" paragraph>
            {t('available_funds_header')}
          </Typography>
        ) : null}

        <Grid container justifyContent="space-between">
          {isLoading ? (
            <>
              <FundLoadingItem container={container} />
              {[0, 1].map((v) => (
                <Grid key={v} container={container} item xs={container ? 6 : false}>
                  <Box width="100%" ml={v % 2 ? 1 : 0} mr={v % 2 ? 0 : 1}>
                    <FundLoadingItem container={container} />
                  </Box>
                </Grid>
              ))}
              <FundLoadingItem container={container} />
            </>
          ) : null}
          {fundsLCY ? (
            <FundItem
              amount={fundsLCY.amount}
              currency={fundsLCY.currency}
              description={`${t('lcy_funds_description')}: ${fundsLCY.count}`}
              containerProps={{ container }}
            />
          ) : null}

          {funds?.map((fund, index) => {
            return (
              <Grid key={index.toString()} container={container} item xs={container ? 6 : false}>
                <Box width="100%" ml={index % 2 ? 1 : 0} mr={index % 2 ? 0 : 1}>
                  <FundItem
                    amount={fund.amount}
                    currency={fund.currency}
                    description={`${formatAmount(fund.equivalentAmount)} ${fund.equivalentCurrency}`}
                  />
                </Box>
              </Grid>
            );
          })}
          {totalFundsLCY ? <TotalFundItem {...totalFundsLCY} /> : null}
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { createContext, useContext, ReactNode, useCallback } from 'react';

import { SharedProps, SnackbarKey, useSnackbar } from 'notistack';

import { getIconBySeverity } from '@app/common/icons/utils';
import { NotifyPopUp, NotifyPopUpProps } from '@app/core/components';

type NotifyOptions = {
  snackbarOptions?: SharedProps;
  notifyProps: Omit<NotifyPopUpProps, 'handleDismiss'>;
};

interface NotifyState {
  notify(props: NotifyOptions): SnackbarKey;
  dismiss(key: SnackbarKey): void;
  dismissAll(): void;
}

const NotifyContext = createContext<NotifyState | undefined>(undefined);

const NotifyProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(
    (id: string | undefined) => {
      closeSnackbar(id);
    },
    [closeSnackbar]
  );

  const showNotification = useCallback(
    (options: NotifyOptions): SnackbarKey => {
      const {
        snackbarOptions,
        notifyProps: { title, message, ref, severity, ...otherProps },
      } = options;

      const linkClickHandler = () => {};

      const severityVal = severity || 'info';

      return enqueueSnackbar(null, {
        persist: false,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        content: (key) => (
          <NotifyPopUp
            key={key}
            variant="filled"
            severity={severityVal}
            icon={getIconBySeverity(severityVal)}
            message={message}
            title={title}
            linkClickHandler={linkClickHandler}
            {...otherProps}
            handleDismiss={handleDismiss}
          />
        ),
        ...snackbarOptions,
      });
    },
    [enqueueSnackbar, handleDismiss]
  );

  const notify = (props: NotifyOptions): SnackbarKey => {
    return showNotification(props);
  };

  const dismiss = (key: string | number) => {
    closeSnackbar(key);
  };

  const dismissAll = () => {
    closeSnackbar();
  };

  return <NotifyContext.Provider value={{ notify, dismiss, dismissAll }}>{children}</NotifyContext.Provider>;
};

function useNotify(): NotifyState {
  const state = useContext(NotifyContext);
  if (state === undefined) {
    throw new Error('useNotify must be used within a NotifyProvider');
  }
  return state;
}

export { NotifyProvider, useNotify };

import React from 'react';

import { Box, Grid, List, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LeftChevronIcon, RightChevronIcon } from '@app/common/icons';
import { MenuDivider } from '@app/common/layout/Sidebar/components/MenuDivider';
import { MenuDrawer } from '@app/common/layout/Sidebar/components/MenuDrawer';
import { MenuListItem } from '@app/common/layout/Sidebar/components/MenuListItem';
import { MultipleMenuItem } from '@app/common/layout/Sidebar/components/MultipleMenuItem';
import { styles } from '@app/common/layout/Sidebar/styles';
import { useUIState } from '@app/context/UIContext';
import { Company, Currency, Logo, SupportChat, Tooltip } from '@app/core/components';
import { isMobile } from '@app/core/utils';
import { ProfileState } from '@app/slices/profileSlice';
import { appConfig } from '@app/src/config';
import { geMenuList } from '@app/src/constants/menuList';
import { RouteList } from '@app/src/constants/routeList';
import { useAppSelector } from '@app/src/store';

export const Sidebar: React.FC = () => {
  const { sidebarOpen, setSidebarOpen } = useUIState();
  const { appVersion, userProfile, clientConfig, unreadMessageCounter } = useAppSelector(
    (state): ProfileState => state.profile
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const menuList = geMenuList(clientConfig).sort((a, b) => a.id - b.id);

  const onLogoClickHandler = () => {
    navigate(RouteList.dashboard);
  };

  return (
    <MenuDrawer open={sidebarOpen} variant="permanent">
      <Box
        sx={{
          height: '80px',
          display: 'flex',
          flexShrink: 0,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={onLogoClickHandler}
      >
        <Logo size={sidebarOpen ? 'large' : 'small'} />
      </Box>
      <MenuDivider />
      <Box
        sx={{
          height: '188px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Company open={sidebarOpen} logoLink={userProfile?.photo} support={<SupportChat />} />
      </Box>
      <MenuDivider />
      <List component="nav" disablePadding sx={styles.scrollable}>
        {menuList.map(({ name, to, Icon, subMenu }) =>
          subMenu ? (
            <MultipleMenuItem key={name} name={name} to={to} Icon={Icon} hideTooltip={sidebarOpen} subMenu={subMenu} />
          ) : (
            <MenuListItem
              key={name}
              name={name}
              to={to}
              Icon={Icon}
              hideTooltip={sidebarOpen}
              unreadMessageCounter={unreadMessageCounter}
            />
          )
        )}
      </List>
      <List disablePadding sx={styles.toolbar}>
        {sidebarOpen ? <Currency /> : null}
        <Grid container item alignItems="center" sx={styles.collapseContainer}>
          {isMobile ? null : (
            <IconButton onClick={handleDrawerClose} sx={styles.collapseButton} size="large">
              {sidebarOpen ? (
                <>
                  <LeftChevronIcon sx={styles.arrowIcon} />
                  <Typography component="span" sx={styles.collapseText}>
                    {t('collapse')}
                  </Typography>
                </>
              ) : (
                <RightChevronIcon sx={styles.arrowIcon} />
              )}
            </IconButton>
          )}

          {sidebarOpen ? (
            <Tooltip arrow placement="top" color="primary" title={appConfig.version}>
              <Typography variant="body2" sx={styles.versionText}>
                {appVersion}
              </Typography>
            </Tooltip>
          ) : null}
        </Grid>
      </List>
    </MenuDrawer>
  );
};

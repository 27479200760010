export const styles = {
  root: {
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: 600,
    padding: '0 32px',
  },
  wrapperAnimation: {
    height: '100%',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64px',
    height: '64px',
    margin: '12px auto 8px',
    borderRadius: '50%',
    '& span': {
      color: 'colors.companyLogoTextColor',
      textTransform: 'uppercase',
      fontSize: '24px',
    },
  },
  logoSizeSmall: {
    width: '52px',
    height: '52px',
  },
  controlPanel: {
    flexGrow: 1,
  },
  close: {
    padding: 0,
  },
  baseIcon: {
    color: 'colors.companyIconsColor',
  },
  statusIcon: {
    color: 'colors.settingsMenuTextColor',
    display: 'inline-block',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '2px',
      right: '6px',
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: 'colors.warning',
    },
  },
  linkZeroSize: {
    fontSize: 0,
  },
  infoContainer: {
    textAlign: 'center',
    fontSize: '12px',
    padding: '4px 0',
  },
  name: {
    marginBottom: '4px',
    fontSize: '14px',
    color: 'colors.userNameTextColor',
    fontWeight: 600,
  },
  companyName: {
    whiteSpace: 'normal',
    marginBottom: '4px',
    color: 'colors.companyNameTextColor',
  },
  edrpou: {
    color: 'colors.companyEdrpouLabelTextColor',
    span: {
      color: 'colors.companyEdrpouTextColor',
    },
  },
  fadeAnimate: {
    animation: 'fadeIn 250ms cubic-bezier(0, -0.05, 1, -0.15) normal none running',
  },
  logoIcon: {
    width: '100%',
    height: '100%',
    margin: '12px auto 8px',
    borderRadius: '50%',
    objectFit: 'cover',
    backgroundColor: 'colors.gray600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImage: {
    width: '100%',
    height: '100%',
    margin: '12px auto 8px',
    borderRadius: '50%',
    objectFit: 'cover',
    backgroundColor: 'colors.gray600',
  },
  editIcon: {
    position: 'absolute',
    top: '10px',
    right: '35%',
    color: 'colors.closeOpenArrowIconColor',
  },
  hideEditIcon: {
    display: 'none',
  },
};
